import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppHeader from './cmps/AppHeader';
import About from './pages/About';
import BibleApp from './pages/BibleApp';
import AppFooter from './cmps/AppFooter'

function App() {
  return (
    <section className="App">
      <AppHeader />
      <main className="app-main">
        <Switch>
          <Route component={About} path='/about' />
          <Route component={BibleApp} path='/text-only' />
          <Route component={BibleApp} path='/' />
        </Switch>
      </main>
      <AppFooter />
    </section>
  );
}

export default App;
