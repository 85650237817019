import React, { useState } from 'react'
import {getChaptersNum} from '../services/bibleService'

export default function BibleFilter({ filters, onFiltersUpdate }) {
    
    const chapters = getChaptersNum(filters.book)
    const [currChapters, setCurrChapters] = useState(chapters)

    const onBookChanged = ({ target }) => {
        const { value: book } = target
        const chapters = getChaptersNum(book)
        setCurrChapters(chapters)
        onFiltersUpdate({ ...filters, book })
    }

    const onFilterChanged = ({ target }) => {
        const { name, value } = target
        onFiltersUpdate({ ...filters, [name]: value })
    }

    return (
        <form className="bible-filter flex justify-center">
            <select name="book" onChange={onBookChanged} value={filters.book}>
                <option>בראשית</option>
                <option>שמות</option>
                <option>ויקרא</option>
                <option>במדבר</option>
                <option>דברים</option>
            </select>
            <select name="chapter" onChange={onFilterChanged} value={filters.chapter}>
                {
                    currChapters.map(chapter => <option key={chapter}>{chapter}</option>)
                }
            </select>
            <input type="search" name="term"
                placeholder="חיפוש בפרק"
                value={filters.term}
                onChange={ev => onFilterChanged(ev)} />
        </form>
    )
}
