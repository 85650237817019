import Axios from 'axios'

const BASE_URL = process.env.NODE_ENV === 'production'
    ? '/api/'
    : '//localhost:3030/api/'


var axios = Axios.create({
    withCredentials: true
})


export async function query(filterBy) {
    const res = await axios.get(`${BASE_URL}`, {params: filterBy})
    return res.data
}