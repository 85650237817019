import { tora } from '../data/torahTeamimDB'

const numeroMap = {
    "א": 1,
    "ב": 2,
    "ג": 3,
    "ד": 4,
    "ה": 5,
    "ו": 6,
    "ז": 7,
    "ח": 8,
    "ט": 9,
    "י": 10,
    "כ": 20,
    "ל": 30,
    "מ": 40,
    "נ": 50,
    "ס": 60,
    "ע": 70,
    "פ": 80,
    "צ": 90,
    "ק": 100,
    "ר": 200,
    "ש": 300,
    "ת": 400,
    "ם": 40,
    "ך": 20,
    "ן": 50,
    "ף": 80,
    "ץ": 90,
}

export function getChaptersNum(book) {
    return tora[book].chapters.reduce((chapters, chapter) => {
        chapters.push(chapter.num)
        return chapters
    }, [])
}

export function getGima(txt) {
    const numeroTxt = txt.split('').reduce((sumAcc, char) => {
        sumAcc += numeroMap[char] || 0
        return sumAcc
    }, 0)
    return numeroTxt
}

export function getChapterTXT(chapter) {
    const chapterTxts = chapter.verses.map(verse => verse.txt)
    return chapterTxts.join(' ')
}
