export const tora = {
  "בראשית": {
    "chapters": [
      {
        "num": "א",
        "verses": [
          {
            "num": "א",
            "txt": "בְּרֵאשִׁ֖ית בָּרָ֣א אֱלֹהִ֑ים אֵ֥ת הַשָּׁמַ֖יִם וְאֵ֥ת הָאָֽרֶץ׃"
          },
          {
            "num": "ב",
            "txt": "וְהָאָ֗רֶץ הָֽיְתָ֥ה תֹ֨הוּ֙ וָבֹ֔הוּ וְחֹ֖שֶׁךְ עַל־פְּנֵ֣י תְה֑וֹם וְר֣וּחַ אֱלֹהִ֔ים מְרַחֶ֖פֶת עַל־פְּנֵ֥י הַמָּֽיִם׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֥אמֶר אֱלֹהִ֖ים יְהִ֣י א֑וֹר וַֽיְהִי־אֽוֹר׃"
          },
          {
            "num": "ד",
            "txt": "וַיַּ֧רְא אֱלֹהִ֛ים אֶת־הָא֖וֹר כִּי־ט֑וֹב וַיַּבְדֵּ֣ל אֱלֹהִ֔ים בֵּ֥ין הָא֖וֹר וּבֵ֥ין הַחֹֽשֶׁךְ׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּקְרָ֨א אֱלֹהִ֤ים ׀ לָאוֹר֙ י֔וֹם וְלַחֹ֖שֶׁךְ קָ֣רָא לָ֑יְלָה וַֽיְהִי־עֶ֥רֶב וַֽיְהִי־בֹ֖קֶר י֥וֹם אֶחָֽד׃ {פ}"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֔ים יְהִ֥י רָקִ֖יעַ בְּת֣וֹךְ הַמָּ֑יִם וִיהִ֣י מַבְדִּ֔יל בֵּ֥ין מַ֖יִם לָמָֽיִם׃"
          },
          {
            "num": "ז",
            "txt": "וַיַּ֣עַשׂ אֱלֹהִים֮ אֶת־הָֽרָקִיעַ֒ וַיַּבְדֵּ֗ל בֵּ֤ין הַמַּ֨יִם֙ אֲשֶׁר֙ מִתַּ֣חַת לָֽרָקִ֔יעַ וּבֵ֣ין הַמַּ֔יִם אֲשֶׁ֖ר מֵעַ֣ל לָֽרָקִ֑יעַ וַֽיְהִי־כֵֽן׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּקְרָ֧א אֱלֹהִ֛ים לָֽרָקִ֖יעַ שָׁמָ֑יִם וַֽיְהִי־עֶ֥רֶב וַֽיְהִי־בֹ֖קֶר י֥וֹם שֵׁנִֽי׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֗ים יִקָּו֨וּ הַמַּ֜יִם מִתַּ֤חַת הַשָּׁמַ֨יִם֙ אֶל־מָק֣וֹם אֶחָ֔ד וְתֵֽרָאֶ֖ה הַיַּבָּשָׁ֑ה וַֽיְהִי־כֵֽן׃"
          },
          {
            "num": "י",
            "txt": "וַיִּקְרָ֨א אֱלֹהִ֤ים ׀ לַיַּבָּשָׁה֙ אֶ֔רֶץ וּלְמִקְוֵ֥ה הַמַּ֖יִם קָרָ֣א יַמִּ֑ים וַיַּ֥רְא אֱלֹהִ֖ים כִּי־טֽוֹב׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֗ים תַּֽדְשֵׁ֤א הָאָ֨רֶץ֙ דֶּ֗שֶׁא עֵ֚שֶׂב מַזְרִ֣יעַ זֶ֔רַע עֵ֣ץ פְּרִ֞י עֹ֤שֶׂה פְּרִי֙ לְמִינ֔וֹ אֲשֶׁ֥ר זַרְעוֹ־ב֖וֹ עַל־הָאָ֑רֶץ וַֽיְהִי־כֵֽן׃"
          },
          {
            "num": "יב",
            "txt": "וַתּוֹצֵ֨א הָאָ֜רֶץ דֶּ֠שֶׁא עֵ֣שֶׂב מַזְרִ֤יעַ זֶ֨רַע֙ לְמִינֵ֔הוּ וְעֵ֧ץ עֹֽשֶׂה־פְּרִ֛י אֲשֶׁ֥ר זַרְעוֹ־ב֖וֹ לְמִינֵ֑הוּ וַיַּ֥רְא אֱלֹהִ֖ים כִּי־טֽוֹב׃"
          },
          {
            "num": "יג",
            "txt": "וַֽיְהִי־עֶ֥רֶב וַֽיְהִי־בֹ֖קֶר י֥וֹם שְׁלִישִֽׁי׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֗ים יְהִ֤י מְאֹרֹת֙ בִּרְקִ֣יעַ הַשָּׁמַ֔יִם לְהַבְדִּ֕יל בֵּ֥ין הַיּ֖וֹם וּבֵ֣ין הַלָּ֑יְלָה וְהָי֤וּ לְאֹתֹת֙ וּלְמ֣וֹעֲדִ֔ים וּלְיָמִ֖ים וְשָׁנִֽים׃"
          },
          {
            "num": "טו",
            "txt": "וְהָי֤וּ לִמְאוֹרֹת֙ בִּרְקִ֣יעַ הַשָּׁמַ֔יִם לְהָאִ֖יר עַל־הָאָ֑רֶץ וַֽיְהִי־כֵֽן׃"
          },
          {
            "num": "טז",
            "txt": "וַיַּ֣עַשׂ אֱלֹהִ֔ים אֶת־שְׁנֵ֥י הַמְּאֹרֹ֖ת הַגְּדֹלִ֑ים אֶת־הַמָּא֤וֹר הַגָּדֹל֙ לְמֶמְשֶׁ֣לֶת הַיּ֔וֹם וְאֶת־הַמָּא֤וֹר הַקָּטֹן֙ לְמֶמְשֶׁ֣לֶת הַלַּ֔יְלָה וְאֵ֖ת הַכּֽוֹכָבִֽים׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּתֵּ֥ן אֹתָ֛ם אֱלֹהִ֖ים בִּרְקִ֣יעַ הַשָּׁמָ֑יִם לְהָאִ֖יר עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "יח",
            "txt": "וְלִמְשֹׁל֙ בַּיּ֣וֹם וּבַלַּ֔יְלָה וּֽלְהַבְדִּ֔יל בֵּ֥ין הָא֖וֹר וּבֵ֣ין הַחֹ֑שֶׁךְ וַיַּ֥רְא אֱלֹהִ֖ים כִּי־טֽוֹב׃"
          },
          {
            "num": "יט",
            "txt": "וַֽיְהִי־עֶ֥רֶב וַֽיְהִי־בֹ֖קֶר י֥וֹם רְבִיעִֽי׃ {פ}"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֔ים יִשְׁרְצ֣וּ הַמַּ֔יִם שֶׁ֖רֶץ נֶ֣פֶשׁ חַיָּ֑ה וְעוֹף֙ יְעוֹפֵ֣ף עַל־הָאָ֔רֶץ עַל־פְּנֵ֖י רְקִ֥יעַ הַשָּׁמָֽיִם׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּבְרָ֣א אֱלֹהִ֔ים אֶת־הַתַּנִּינִ֖ם הַגְּדֹלִ֑ים וְאֵ֣ת כָּל־נֶ֣פֶשׁ הַֽחַיָּ֣ה ׀ הָֽרֹמֶ֡שֶׂת אֲשֶׁר֩ שָֽׁרְצ֨וּ הַמַּ֜יִם לְמִֽינֵהֶ֗ם וְאֵ֨ת כָּל־ע֤וֹף כָּנָף֙ לְמִינֵ֔הוּ וַיַּ֥רְא אֱלֹהִ֖ים כִּי־טֽוֹב׃"
          },
          {
            "num": "כב",
            "txt": "וַיְבָ֧רֶךְ אֹתָ֛ם אֱלֹהִ֖ים לֵאמֹ֑ר פְּר֣וּ וּרְב֗וּ וּמִלְא֤וּ אֶת־הַמַּ֨יִם֙ בַּיַּמִּ֔ים וְהָע֖וֹף יִ֥רֶב בָּאָֽרֶץ׃"
          },
          {
            "num": "כג",
            "txt": "וַֽיְהִי־עֶ֥רֶב וַֽיְהִי־בֹ֖קֶר י֥וֹם חֲמִישִֽׁי׃ {פ}"
          },
          {
            "num": "כד",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֗ים תּוֹצֵ֨א הָאָ֜רֶץ נֶ֤פֶשׁ חַיָּה֙ לְמִינָ֔הּ בְּהֵמָ֥ה וָרֶ֛מֶשׂ וְחַֽיְתוֹ־אֶ֖רֶץ לְמִינָ֑הּ וַֽיְהִי־כֵֽן׃"
          },
          {
            "num": "כה",
            "txt": "וַיַּ֣עַשׂ אֱלֹהִים֩ אֶת־חַיַּ֨ת הָאָ֜רֶץ לְמִינָ֗הּ וְאֶת־הַבְּהֵמָה֙ לְמִינָ֔הּ וְאֵ֛ת כָּל־רֶ֥מֶשׂ הָֽאֲדָמָ֖ה לְמִינֵ֑הוּ וַיַּ֥רְא אֱלֹהִ֖ים כִּי־טֽוֹב׃"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֔ים נַֽעֲשֶׂ֥ה אָדָ֛ם בְּצַלְמֵ֖נוּ כִּדְמוּתֵ֑נוּ וְיִרְדּוּ֩ בִדְגַ֨ת הַיָּ֜ם וּבְע֣וֹף הַשָּׁמַ֗יִם וּבַבְּהֵמָה֙ וּבְכָל־הָאָ֔רֶץ וּבְכָל־הָרֶ֖מֶשׂ הָֽרֹמֵ֥שׂ עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּבְרָ֨א אֱלֹהִ֤ים ׀ אֶת־הָֽאָדָם֙ בְּצַלְמ֔וֹ בְּצֶ֥לֶם אֱלֹהִ֖ים בָּרָ֣א אֹת֑וֹ זָכָ֥ר וּנְקֵבָ֖ה בָּרָ֥א אֹתָֽם׃"
          },
          {
            "num": "כח",
            "txt": "וַיְבָ֣רֶךְ אֹתָם֮ אֱלֹהִים֒ וַיֹּ֨אמֶר לָהֶ֜ם אֱלֹהִ֗ים פְּר֥וּ וּרְב֛וּ וּמִלְא֥וּ אֶת־הָאָ֖רֶץ וְכִבְשֻׁ֑הָ וּרְד֞וּ בִּדְגַ֤ת הַיָּם֙ וּבְע֣וֹף הַשָּׁמַ֔יִם וּבְכָל־חַיָּ֖ה הָֽרֹמֶ֥שֶׂת עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֗ים הִנֵּה֩ נָתַ֨תִּי לָכֶ֜ם אֶת־כָּל־עֵ֣שֶׂב ׀ זֹרֵ֣עַ זֶ֗רַע אֲשֶׁר֙ עַל־פְּנֵ֣י כָל־הָאָ֔רֶץ וְאֶת־כָּל־הָעֵ֛ץ אֲשֶׁר־בּ֥וֹ פְרִי־עֵ֖ץ זֹרֵ֣עַ זָ֑רַע לָכֶ֥ם יִֽהְיֶ֖ה לְאָכְלָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וּֽלְכָל־חַיַּ֣ת הָ֠אָרֶץ וּלְכָל־ע֨וֹף הַשָּׁמַ֜יִם וּלְכֹ֣ל ׀ רוֹמֵ֣שׂ עַל־הָאָ֗רֶץ אֲשֶׁר־בּוֹ֙ נֶ֣פֶשׁ חַיָּ֔ה אֶת־כָּל־יֶ֥רֶק עֵ֖שֶׂב לְאָכְלָ֑ה וַֽיְהִי־כֵֽן׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּ֤רְא אֱלֹהִים֙ אֶת־כָּל־אֲשֶׁ֣ר עָשָׂ֔ה וְהִנֵּה־ט֖וֹב מְאֹ֑ד וַֽיְהִי־עֶ֥רֶב וַֽיְהִי־בֹ֖קֶר י֥וֹם הַשִּׁשִּֽׁי׃ {פ}"
          }
        ]
      },
      {
        "num": "ב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְכֻלּ֛וּ הַשָּׁמַ֥יִם וְהָאָ֖רֶץ וְכָל־צְבָאָֽם׃"
          },
          {
            "num": "ב",
            "txt": "וַיְכַ֤ל אֱלֹהִים֙ בַּיּ֣וֹם הַשְּׁבִיעִ֔י מְלַאכְתּ֖וֹ אֲשֶׁ֣ר עָשָׂ֑ה וַיִּשְׁבֹּת֙ בַּיּ֣וֹם הַשְּׁבִיעִ֔י מִכָּל־מְלַאכְתּ֖וֹ אֲשֶׁ֥ר עָשָֽׂה׃"
          },
          {
            "num": "ג",
            "txt": "וַיְבָ֤רֶךְ אֱלֹהִים֙ אֶת־י֣וֹם הַשְּׁבִיעִ֔י וַיְקַדֵּ֖שׁ אֹת֑וֹ כִּ֣י ב֤וֹ שָׁבַת֙ מִכָּל־מְלַאכְתּ֔וֹ אֲשֶׁר־בָּרָ֥א אֱלֹהִ֖ים לַֽעֲשֽׂוֹת׃"
          },
          {
            "num": "ד",
            "txt": "אֵ֣לֶּה תֽוֹלְד֧וֹת הַשָּׁמַ֛יִם וְהָאָ֖רֶץ בְּבָּֽרְאָ֑ם בְּי֗וֹם עֲשׂ֛וֹת יְהוָ֥ה אֱלֹהִ֖ים אֶ֥רֶץ וְשָׁמָֽיִם׃"
          },
          {
            "num": "ה",
            "txt": "וְכֹ֣ל ׀ שִׂ֣יחַ הַשָּׂדֶ֗ה טֶ֚רֶם יִֽהְיֶ֣ה בָאָ֔רֶץ וְכָל־עֵ֥שֶׂב הַשָּׂדֶ֖ה טֶ֣רֶם יִצְמָ֑ח כִּי֩ לֹ֨א הִמְטִ֜יר יְהוָ֤ה אֱלֹהִים֙ עַל־הָאָ֔רֶץ וְאָדָ֣ם אַ֔יִן לַֽעֲבֹ֖ד אֶת־הָֽאֲדָמָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וְאֵ֖ד יַֽעֲלֶ֣ה מִן־הָאָ֑רֶץ וְהִשְׁקָ֖ה אֶֽת־כָּל־פְּנֵ֥י הָֽאֲדָמָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּיצֶר֩ יְהוָ֨ה אֱלֹהִ֜ים אֶת־הָֽאָדָ֗ם עָפָר֙ מִן־הָ֣אֲדָמָ֔ה וַיִּפַּ֥ח בְּאַפָּ֖יו נִשְׁמַ֣ת חַיִּ֑ים וַיְהִ֥י הָֽאָדָ֖ם לְנֶ֥פֶשׁ חַיָּֽה׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּטַּ֞ע יְהוָ֧ה אֱלֹהִ֛ים גַּן־בְּעֵ֖דֶן מִקֶּ֑דֶם וַיָּ֣שֶׂם שָׁ֔ם אֶת־הָֽאָדָ֖ם אֲשֶׁ֥ר יָצָֽר׃"
          },
          {
            "num": "ט",
            "txt": "וַיַּצְמַ֞ח יְהוָ֤ה אֱלֹהִים֙ מִן־הָ֣אֲדָמָ֔ה כָּל־עֵ֛ץ נֶחְמָ֥ד לְמַרְאֶ֖ה וְט֣וֹב לְמַֽאֲכָ֑ל וְעֵ֤ץ הַֽחַיִּים֙ בְּת֣וֹךְ הַגָּ֔ן וְעֵ֕ץ הַדַּ֖עַת ט֥וֹב וָרָֽע׃"
          },
          {
            "num": "י",
            "txt": "וְנָהָר֙ יֹצֵ֣א מֵעֵ֔דֶן לְהַשְׁק֖וֹת אֶת־הַגָּ֑ן וּמִשָּׁם֙ יִפָּרֵ֔ד וְהָיָ֖ה לְאַרְבָּעָ֥ה רָאשִֽׁים׃"
          },
          {
            "num": "יא",
            "txt": "שֵׁ֥ם הָֽאֶחָ֖ד פִּישׁ֑וֹן ה֣וּא הַסֹּבֵ֗ב אֵ֚ת כָּל־אֶ֣רֶץ הַֽחֲוִילָ֔ה אֲשֶׁר־שָׁ֖ם הַזָּהָֽב׃"
          },
          {
            "num": "יב",
            "txt": "וּֽזְהַ֛ב הָאָ֥רֶץ הַהִ֖וא ט֑וֹב שָׁ֥ם הַבְּדֹ֖לַח וְאֶ֥בֶן הַשֹּֽׁהַם׃"
          },
          {
            "num": "יג",
            "txt": "וְשֵֽׁם־הַנָּהָ֥ר הַשֵּׁנִ֖י גִּיח֑וֹן ה֣וּא הַסּוֹבֵ֔ב אֵ֖ת כָּל־אֶ֥רֶץ כּֽוּשׁ׃"
          },
          {
            "num": "יד",
            "txt": "וְשֵׁ֨ם הַנָּהָ֤ר הַשְּׁלִישִׁי֙ חִדֶּ֔קֶל ה֥וּא הַֽהֹלֵ֖ךְ קִדְמַ֣ת אַשּׁ֑וּר וְהַנָּהָ֥ר הָֽרְבִיעִ֖י ה֥וּא פְרָֽת׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּקַּ֛ח יְהוָ֥ה אֱלֹהִ֖ים אֶת־הָֽאָדָ֑ם וַיַּנִּחֵ֣הוּ בְגַן־עֵ֔דֶן לְעָבְדָ֖הּ וּלְשָׁמְרָֽהּ׃"
          },
          {
            "num": "טז",
            "txt": "וַיְצַו֙ יְהוָ֣ה אֱלֹהִ֔ים עַל־הָֽאָדָ֖ם לֵאמֹ֑ר מִכֹּ֥ל עֵֽץ־הַגָּ֖ן אָכֹ֥ל תֹּאכֵֽל׃"
          },
          {
            "num": "יז",
            "txt": "וּמֵעֵ֗ץ הַדַּ֨עַת֙ ט֣וֹב וָרָ֔ע לֹ֥א תֹאכַ֖ל מִמֶּ֑נּוּ כִּ֗י בְּי֛וֹם אֲכָלְךָ֥ מִמֶּ֖נּוּ מ֥וֹת תָּמֽוּת׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֨אמֶר֙ יְהוָ֣ה אֱלֹהִ֔ים לֹא־ט֛וֹב הֱי֥וֹת הָֽאָדָ֖ם לְבַדּ֑וֹ אֶֽעֱשֶׂה־לּ֥וֹ עֵ֖זֶר כְּנֶגְדּֽוֹ׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּצֶר֩ יְהוָ֨ה אֱלֹהִ֜ים מִן־הָֽאֲדָמָ֗ה כָּל־חַיַּ֤ת הַשָּׂדֶה֙ וְאֵת֙ כָּל־ע֣וֹף הַשָּׁמַ֔יִם וַיָּבֵא֙ אֶל־הָ֣אָדָ֔ם לִרְא֖וֹת מַה־יִּקְרָא־ל֑וֹ וְכֹל֩ אֲשֶׁ֨ר יִקְרָא־ל֧וֹ הָֽאָדָ֛ם נֶ֥פֶשׁ חַיָּ֖ה ה֥וּא שְׁמֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּקְרָ֨א הָֽאָדָ֜ם שֵׁמ֗וֹת לְכָל־הַבְּהֵמָה֙ וּלְע֣וֹף הַשָּׁמַ֔יִם וּלְכֹ֖ל חַיַּ֣ת הַשָּׂדֶ֑ה וּלְאָדָ֕ם לֹֽא־מָצָ֥א עֵ֖זֶר כְּנֶגְדּֽוֹ׃"
          },
          {
            "num": "כא",
            "txt": "וַיַּפֵּל֩ יְהוָ֨ה אֱלֹהִ֧ים ׀ תַּרְדֵּמָ֛ה עַל־הָֽאָדָ֖ם וַיִּישָׁ֑ן וַיִּקַּ֗ח אַחַת֙ מִצַּלְעֹתָ֔יו וַיִּסְגֹּ֥ר בָּשָׂ֖ר תַּחְתֶּֽנָּה׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּבֶן֩ יְהוָ֨ה אֱלֹהִ֧ים ׀ אֶֽת־הַצֵּלָ֛ע אֲשֶׁר־לָקַ֥ח מִן־הָֽאָדָ֖ם לְאִשָּׁ֑ה וַיְבִאֶ֖הָ אֶל־הָֽאָדָֽם׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּאמֶר֮ הָֽאָדָם֒ זֹ֣את הַפַּ֗עַם עֶ֚צֶם מֵֽעֲצָמַ֔י וּבָשָׂ֖ר מִבְּשָׂרִ֑י לְזֹאת֙ יִקָּרֵ֣א אִשָּׁ֔ה כִּ֥י מֵאִ֖ישׁ לֻֽקְחָה־זֹּֽאת׃"
          },
          {
            "num": "כד",
            "txt": "עַל־כֵּן֙ יַֽעֲזָב־אִ֔ישׁ אֶת־אָבִ֖יו וְאֶת־אִמּ֑וֹ וְדָבַ֣ק בְּאִשְׁתּ֔וֹ וְהָי֖וּ לְבָשָׂ֥ר אֶחָֽד׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּֽהְי֤וּ שְׁנֵיהֶם֙ עֲרוּמִּ֔ים הָֽאָדָ֖ם וְאִשְׁתּ֑וֹ וְלֹ֖א יִתְבֹּשָֽׁשׁוּ׃"
          }
        ]
      },
      {
        "num": "ג",
        "verses": [
          {
            "num": "א",
            "txt": "וְהַנָּחָשׁ֙ הָיָ֣ה עָר֔וּם מִכֹּל֙ חַיַּ֣ת הַשָּׂדֶ֔ה אֲשֶׁ֥ר עָשָׂ֖ה יְהוָ֣ה אֱלֹהִ֑ים וַיֹּ֨אמֶר֙ אֶל־הָ֣אִשָּׁ֔ה אַ֚ף כִּֽי־אָמַ֣ר אֱלֹהִ֔ים לֹ֣א תֹֽאכְל֔וּ מִכֹּ֖ל עֵ֥ץ הַגָּֽן׃"
          },
          {
            "num": "ב",
            "txt": "וַתֹּ֥אמֶר הָֽאִשָּׁ֖ה אֶל־הַנָּחָ֑שׁ מִפְּרִ֥י עֵֽץ־הַגָּ֖ן נֹאכֵֽל׃"
          },
          {
            "num": "ג",
            "txt": "וּמִפְּרִ֣י הָעֵץ֮ אֲשֶׁ֣ר בְּתוֹךְ־הַגָּן֒ אָמַ֣ר אֱלֹהִ֗ים לֹ֤א תֹֽאכְלוּ֙ מִמֶּ֔נּוּ וְלֹ֥א תִגְּע֖וּ בּ֑וֹ פֶּן־תְּמֻתֽוּן׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֥אמֶר הַנָּחָ֖שׁ אֶל־הָֽאִשָּׁ֑ה לֹֽא־מ֖וֹת תְּמֻתֽוּן׃"
          },
          {
            "num": "ה",
            "txt": "כִּ֚י יֹדֵ֣עַ אֱלֹהִ֔ים כִּ֗י בְּיוֹם֙ אֲכָלְכֶ֣ם מִמֶּ֔נּוּ וְנִפְקְח֖וּ עֵֽינֵיכֶ֑ם וִֽהְיִיתֶם֙ כֵּֽאלֹהִ֔ים יֹֽדְעֵ֖י ט֥וֹב וָרָֽע׃"
          },
          {
            "num": "ו",
            "txt": "וַתֵּ֣רֶא הָֽאִשָּׁ֡ה כִּ֣י טוֹב֩ הָעֵ֨ץ לְמַֽאֲכָ֜ל וְכִ֧י תַֽאֲוָה־ה֣וּא לָֽעֵינַ֗יִם וְנֶחְמָ֤ד הָעֵץ֙ לְהַשְׂכִּ֔יל וַתִּקַּ֥ח מִפִּרְי֖וֹ וַתֹּאכַ֑ל וַתִּתֵּ֧ן גַּם־לְאִישָׁ֛הּ עִמָּ֖הּ וַיֹּאכַֽל׃"
          },
          {
            "num": "ז",
            "txt": "וַתִּפָּקַ֨חְנָה֙ עֵינֵ֣י שְׁנֵיהֶ֔ם וַיֵּ֣דְע֔וּ כִּ֥י עֵֽירֻמִּ֖ם הֵ֑ם וַֽיִּתְפְּרוּ֙ עֲלֵ֣ה תְאֵנָ֔ה וַיַּֽעֲשׂ֥וּ לָהֶ֖ם חֲגֹרֹֽת׃"
          },
          {
            "num": "ח",
            "txt": "וַֽיִּשְׁמְע֞וּ אֶת־ק֨וֹל יְהוָ֧ה אֱלֹהִ֛ים מִתְהַלֵּ֥ךְ בַּגָּ֖ן לְר֣וּחַ הַיּ֑וֹם וַיִּתְחַבֵּ֨א הָֽאָדָ֜ם וְאִשְׁתּ֗וֹ מִפְּנֵי֙ יְהוָ֣ה אֱלֹהִ֔ים בְּת֖וֹךְ עֵ֥ץ הַגָּֽן׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּקְרָ֛א יְהוָ֥ה אֱלֹהִ֖ים אֶל־הָֽאָדָ֑ם וַיֹּ֥אמֶר ל֖וֹ אַיֶּֽכָּה׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֕אמֶר אֶת־קֹֽלְךָ֥ שָׁמַ֖עְתִּי בַּגָּ֑ן וָֽאִירָ֛א כִּֽי־עֵירֹ֥ם אָנֹ֖כִי וָאֵֽחָבֵֽא׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֕אמֶר מִ֚י הִגִּ֣יד לְךָ֔ כִּ֥י עֵירֹ֖ם אָ֑תָּה הֲמִן־הָעֵ֗ץ אֲשֶׁ֧ר צִוִּיתִ֛יךָ לְבִלְתִּ֥י אֲכָל־מִמֶּ֖נּוּ אָכָֽלְתָּ׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֖אמֶר הָֽאָדָ֑ם הָֽאִשָּׁה֙ אֲשֶׁ֣ר נָתַ֣תָּה עִמָּדִ֔י הִ֛וא נָֽתְנָה־לִּ֥י מִן־הָעֵ֖ץ וָֽאֹכֵֽל׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֨אמֶר יְהוָ֧ה אֱלֹהִ֛ים לָֽאִשָּׁ֖ה מַה־זֹּ֣את עָשִׂ֑ית וַתֹּ֨אמֶר֙ הָֽאִשָּׁ֔ה הַנָּחָ֥שׁ הִשִּׁיאַ֖נִי וָֽאֹכֵֽל׃"
          },
          {
            "num": "יד",
            "txt": "וַיֹּאמֶר֩ יְהוָ֨ה אֱלֹהִ֥ים ׀ אֶֽל־הַנָּחָשׁ֮ כִּ֣י עָשִׂ֣יתָ זֹּאת֒ אָר֤וּר אַתָּה֙ מִכָּל־הַבְּהֵמָ֔ה וּמִכֹּ֖ל חַיַּ֣ת הַשָּׂדֶ֑ה עַל־גְּחֹֽנְךָ֣ תֵלֵ֔ךְ וְעָפָ֥ר תֹּאכַ֖ל כָּל־יְמֵ֥י חַיֶּֽיךָ׃"
          },
          {
            "num": "טו",
            "txt": "וְאֵיבָ֣ה ׀ אָשִׁ֗ית בֵּֽינְךָ֙ וּבֵ֣ין הָֽאִשָּׁ֔ה וּבֵ֥ין זַרְעֲךָ֖ וּבֵ֣ין זַרְעָ֑הּ ה֚וּא יְשֽׁוּפְךָ֣ רֹ֔אשׁ וְאַתָּ֖ה תְּשׁוּפֶ֥נּוּ עָקֵֽב׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "אֶֽל־הָאִשָּׁ֣ה אָמַ֗ר הַרְבָּ֤ה אַרְבֶּה֙ עִצְּבוֹנֵ֣ךְ וְהֵֽרֹנֵ֔ךְ בְּעֶ֖צֶב תֵּֽלְדִ֣י בָנִ֑ים וְאֶל־אִישֵׁךְ֙ תְּשׁ֣וּקָתֵ֔ךְ וְה֖וּא יִמְשָׁל־בָּֽךְ׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וּלְאָדָ֣ם אָמַ֗ר כִּֽי־שָׁמַעְתָּ֮ לְק֣וֹל אִשְׁתֶּךָ֒ וַתֹּ֨אכַל֙ מִן־הָעֵ֔ץ אֲשֶׁ֤ר צִוִּיתִ֨יךָ֙ לֵאמֹ֔ר לֹ֥א תֹאכַ֖ל מִמֶּ֑נּוּ אֲרוּרָ֤ה הָֽאֲדָמָה֙ בַּֽעֲבוּרֶ֔ךָ בְּעִצָּבוֹן֙ תֹּֽאכְלֶ֔נָּה כֹּ֖ל יְמֵ֥י חַיֶּֽיךָ׃"
          },
          {
            "num": "יח",
            "txt": "וְק֥וֹץ וְדַרְדַּ֖ר תַּצְמִ֣יחַֽ לָ֑ךְ וְאָֽכַלְתָּ֖ אֶת־עֵ֥שֶׂב הַשָּׂדֶֽה׃"
          },
          {
            "num": "יט",
            "txt": "בְּזֵעַ֤ת אַפֶּ֨יךָ֙ תֹּ֣אכַל לֶ֔חֶם עַ֤ד שֽׁוּבְךָ֙ אֶל־הָ֣אֲדָמָ֔ה כִּ֥י מִמֶּ֖נָּה לֻקָּ֑חְתָּ כִּֽי־עָפָ֣ר אַ֔תָּה וְאֶל־עָפָ֖ר תָּשֽׁוּב׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּקְרָ֧א הָֽאָדָ֛ם שֵׁ֥ם אִשְׁתּ֖וֹ חַוָּ֑ה כִּ֛י הִ֥וא הָֽיְתָ֖ה אֵ֥ם כָּל־חָֽי׃"
          },
          {
            "num": "כא",
            "txt": "וַיַּעַשׂ֩ יְהוָ֨ה אֱלֹהִ֜ים לְאָדָ֧ם וּלְאִשְׁתּ֛וֹ כָּתְנ֥וֹת ע֖וֹר וַיַּלְבִּשֵֽׁם׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "וַיֹּ֣אמֶר ׀ יְהוָ֣ה אֱלֹהִ֗ים הֵ֤ן הָֽאָדָם֙ הָיָה֙ כְּאַחַ֣ד מִמֶּ֔נּוּ לָדַ֖עַת ט֣וֹב וָרָ֑ע וְעַתָּ֣ה ׀ פֶּן־יִשְׁלַ֣ח יָד֗וֹ וְלָקַח֙ גַּ֚ם מֵעֵ֣ץ הַֽחַיִּ֔ים וְאָכַ֖ל וָחַ֥י לְעֹלָֽם׃"
          },
          {
            "num": "כג",
            "txt": "וַֽיְשַׁלְּחֵ֛הוּ יְהוָ֥ה אֱלֹהִ֖ים מִגַּן־עֵ֑דֶן לַֽעֲבֹד֙ אֶת־הָ֣אֲדָמָ֔ה אֲשֶׁ֥ר לֻקַּ֖ח מִשָּֽׁם׃"
          },
          {
            "num": "כד",
            "txt": "וַיְגָ֖רֶשׁ אֶת־הָֽאָדָ֑ם וַיַּשְׁכֵּן֩ מִקֶּ֨דֶם לְגַן־עֵ֜דֶן אֶת־הַכְּרֻבִ֗ים וְאֵ֨ת לַ֤הַט הַחֶ֨רֶב֙ הַמִּתְהַפֶּ֔כֶת לִשְׁמֹ֕ר אֶת־דֶּ֖רֶךְ עֵ֥ץ הַֽחַיִּֽים׃ {ס}"
          }
        ]
      },
      {
        "num": "ד",
        "verses": [
          {
            "num": "א",
            "txt": "וְהָ֣אָדָ֔ם יָדַ֖ע אֶת־חַוָּ֣ה אִשְׁתּ֑וֹ וַתַּ֨הַר֙ וַתֵּ֣לֶד אֶת־קַ֔יִן וַתֹּ֕אמֶר קָנִ֥יתִי אִ֖ישׁ אֶת־יְהוָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַתֹּ֣סֶף לָלֶ֔דֶת אֶת־אָחִ֖יו אֶת־הָ֑בֶל וַֽיְהִי־הֶ֨בֶל֙ רֹ֣עֵה צֹ֔אן וְקַ֕יִן הָיָ֖ה עֹבֵ֥ד אֲדָמָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַֽיְהִ֖י מִקֵּ֣ץ יָמִ֑ים וַיָּבֵ֨א קַ֜יִן מִפְּרִ֧י הָֽאֲדָמָ֛ה מִנְחָ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וְהֶ֨בֶל הֵבִ֥יא גַם־ה֛וּא מִבְּכֹר֥וֹת צֹאנ֖וֹ וּמֵֽחֶלְבֵהֶ֑ן וַיִּ֣שַׁע יְהוָ֔ה אֶל־הֶ֖בֶל וְאֶל־מִנְחָתֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "וְאֶל־קַ֥יִן וְאֶל־מִנְחָת֖וֹ לֹ֣א שָׁעָ֑ה וַיִּ֤חַר לְקַ֨יִן֙ מְאֹ֔ד וַֽיִּפְּל֖וּ פָּנָֽיו׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־קָ֑יִן לָ֚מָּה חָ֣רָה לָ֔ךְ וְלָ֖מָּה נָֽפְל֥וּ פָנֶֽיךָ׃"
          },
          {
            "num": "ז",
            "txt": "הֲל֤וֹא אִם־תֵּיטִיב֙ שְׂאֵ֔ת וְאִם֙ לֹ֣א תֵיטִ֔יב לַפֶּ֖תַח חַטָּ֣את רֹבֵ֑ץ וְאֵלֶ֨יךָ֙ תְּשׁ֣וּקָת֔וֹ וְאַתָּ֖ה תִּמְשָׁל־בּֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֥אמֶר קַ֖יִן אֶל־הֶ֣בֶל אָחִ֑יו וַֽיְהִי֙ בִּֽהְיוֹתָ֣ם בַּשָּׂדֶ֔ה וַיָּ֥קָם קַ֛יִן אֶל־הֶ֥בֶל אָחִ֖יו וַיַּֽהַרְגֵֽהוּ׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־קַ֔יִן אֵ֖י הֶ֣בֶל אָחִ֑יךָ וַיֹּ֨אמֶר֙ לֹ֣א יָדַ֔עְתִּי הֲשֹׁמֵ֥ר אָחִ֖י אָנֹֽכִי׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֖אמֶר מֶ֣ה עָשִׂ֑יתָ ק֚וֹל דְּמֵ֣י אָחִ֔יךָ צֹֽעֲקִ֥ים אֵלַ֖י מִן־הָֽאֲדָמָֽה׃"
          },
          {
            "num": "יא",
            "txt": "וְעַתָּ֖ה אָר֣וּר אָ֑תָּה מִן־הָֽאֲדָמָה֙ אֲשֶׁ֣ר פָּֽצְתָ֣ה אֶת־פִּ֔יהָ לָקַ֛חַת אֶת־דְּמֵ֥י אָחִ֖יךָ מִיָּדֶֽךָ׃"
          },
          {
            "num": "יב",
            "txt": "כִּ֤י תַֽעֲבֹד֙ אֶת־הָ֣אֲדָמָ֔ה לֹֽא־תֹסֵ֥ף תֵּת־כֹּחָ֖הּ לָ֑ךְ נָ֥ע וָנָ֖ד תִּֽהְיֶ֥ה בָאָֽרֶץ׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֥אמֶר קַ֖יִן אֶל־יְהוָ֑ה גָּד֥וֹל עֲוֺנִ֖י מִנְּשֹֽׂא׃"
          },
          {
            "num": "יד",
            "txt": "הֵן֩ גֵּרַ֨שְׁתָּ אֹתִ֜י הַיּ֗וֹם מֵעַל֙ פְּנֵ֣י הָֽאֲדָמָ֔ה וּמִפָּנֶ֖יךָ אֶסָּתֵ֑ר וְהָיִ֜יתִי נָ֤ע וָנָד֙ בָּאָ֔רֶץ וְהָיָ֥ה כָל־מֹֽצְאִ֖י יַֽהַרְגֵֽנִי׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֧אמֶר ל֣וֹ יְהוָ֗ה לָכֵן֙ כָּל־הֹרֵ֣ג קַ֔יִן שִׁבְעָתַ֖יִם יֻקָּ֑ם וַיָּ֨שֶׂם יְהוָ֤ה לְקַ֨יִן֙ א֔וֹת לְבִלְתִּ֥י הַכּוֹת־אֹת֖וֹ כָּל־מֹֽצְאֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "וַיֵּ֥צֵא קַ֖יִן מִלִּפְנֵ֣י יְהוָ֑ה וַיֵּ֥שֶׁב בְּאֶֽרֶץ־נ֖וֹד קִדְמַת־עֵֽדֶן׃"
          },
          {
            "num": "יז",
            "txt": "וַיֵּ֤דַע קַ֨יִן֙ אֶת־אִשְׁתּ֔וֹ וַתַּ֖הַר וַתֵּ֣לֶד אֶת־חֲנ֑וֹךְ וַֽיְהִי֙ בֹּ֣נֶה עִ֔יר וַיִּקְרָא֙ שֵׁ֣ם הָעִ֔יר כְּשֵׁ֖ם בְּנ֥וֹ חֲנֽוֹךְ׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּוָּלֵ֤ד לַֽחֲנוֹךְ֙ אֶת־עִירָ֔ד וְעִירָ֕ד יָלַ֖ד אֶת־מְחֽוּיָאֵ֑ל וּמְחִיָּיאֵ֗ל יָלַד֙ אֶת־מְת֣וּשָׁאֵ֔ל וּמְתֽוּשָׁאֵ֖ל יָלַ֥ד אֶת־לָֽמֶךְ׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּֽקַּֽח־ל֥וֹ לֶ֖מֶךְ שְׁתֵּ֣י נָשִׁ֑ים שֵׁ֤ם הָֽאַחַת֙ עָדָ֔ה וְשֵׁ֥ם הַשֵּׁנִ֖ית צִלָּֽה׃"
          },
          {
            "num": "כ",
            "txt": "וַתֵּ֥לֶד עָדָ֖ה אֶת־יָבָ֑ל ה֣וּא הָיָ֔ה אֲבִ֕י יֹשֵׁ֥ב אֹ֖הֶל וּמִקְנֶֽה׃"
          },
          {
            "num": "כא",
            "txt": "וְשֵׁ֥ם אָחִ֖יו יוּבָ֑ל ה֣וּא הָיָ֔ה אֲבִ֕י כָּל־תֹּפֵ֥שׂ כִּנּ֖וֹר וְעוּגָֽב׃"
          },
          {
            "num": "כב",
            "txt": "וְצִלָּ֣ה גַם־הִ֗וא יָֽלְדָה֙ אֶת־תּ֣וּבַל קַ֔יִן לֹטֵ֕שׁ כָּל־חֹרֵ֥שׁ נְחֹ֖שֶׁת וּבַרְזֶ֑ל וַֽאֲח֥וֹת תּֽוּבַל־קַ֖יִן נַֽעֲמָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֨אמֶר לֶ֜מֶךְ לְנָשָׁ֗יו עָדָ֤ה וְצִלָּה֙ שְׁמַ֣עַן קוֹלִ֔י נְשֵׁ֣י לֶ֔מֶךְ הַאְזֵ֖נָּה אִמְרָתִ֑י כִּ֣י אִ֤ישׁ הָרַ֨גְתִּי֙ לְפִצְעִ֔י וְיֶ֖לֶד לְחַבֻּֽרָתִֽי׃"
          },
          {
            "num": "כד",
            "txt": "כִּ֥י שִׁבְעָתַ֖יִם יֻקַּם־קָ֑יִן וְלֶ֖מֶךְ שִׁבְעִ֥ים וְשִׁבְעָֽה׃"
          },
          {
            "num": "כה",
            "txt": "וַיֵּ֨דַע אָדָ֥ם עוֹד֙ אֶת־אִשְׁתּ֔וֹ וַתֵּ֣לֶד בֵּ֔ן וַתִּקְרָ֥א אֶת־שְׁמ֖וֹ שֵׁ֑ת כִּ֣י שָֽׁת־לִ֤י אֱלֹהִים֙ זֶ֣רַע אַחֵ֔ר תַּ֣חַת הֶ֔בֶל כִּ֥י הֲרָג֖וֹ קָֽיִן׃"
          },
          {
            "num": "כו",
            "txt": "וּלְשֵׁ֤ת גַּם־הוּא֙ יֻלַּד־בֵּ֔ן וַיִּקְרָ֥א אֶת־שְׁמ֖וֹ אֱנ֑וֹשׁ אָ֣ז הוּחַ֔ל לִקְרֹ֖א בְּשֵׁ֥ם יְהוָֽה׃ {ס}"
          }
        ]
      },
      {
        "num": "ה",
        "verses": [
          {
            "num": "א",
            "txt": "זֶ֣ה סֵ֔פֶר תּֽוֹלְדֹ֖ת אָדָ֑ם בְּי֗וֹם בְּרֹ֤א אֱלֹהִים֙ אָדָ֔ם בִּדְמ֥וּת אֱלֹהִ֖ים עָשָׂ֥ה אֹתֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "זָכָ֥ר וּנְקֵבָ֖ה בְּרָאָ֑ם וַיְבָ֣רֶךְ אֹתָ֗ם וַיִּקְרָ֤א אֶת־שְׁמָם֙ אָדָ֔ם בְּי֖וֹם הִבָּֽרְאָֽם׃"
          },
          {
            "num": "ג",
            "txt": "וַיְחִ֣י אָדָ֗ם שְׁלֹשִׁ֤ים וּמְאַת֙ שָׁנָ֔ה וַיּ֥וֹלֶד בִּדְמוּת֖וֹ כְּצַלְמ֑וֹ וַיִּקְרָ֥א אֶת־שְׁמ֖וֹ שֵֽׁת׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּֽהְי֣וּ יְמֵֽי־אָדָ֗ם אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־שֵׁ֔ת שְׁמֹנֶ֥ה מֵאֹ֖ת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּֽהְי֞וּ כָּל־יְמֵ֤י אָדָם֙ אֲשֶׁר־חַ֔י תְּשַׁ֤ע מֵאוֹת֙ שָׁנָ֔ה וּשְׁלֹשִׁ֖ים שָׁנָ֑ה וַיָּמֹֽת׃ {ס}"
          },
          {
            "num": "ו",
            "txt": "וַֽיְחִי־שֵׁ֕ת חָמֵ֥שׁ שָׁנִ֖ים וּמְאַ֣ת שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־אֱנֽוֹשׁ׃"
          },
          {
            "num": "ז",
            "txt": "וַֽיְחִי־שֵׁ֗ת אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־אֱנ֔וֹשׁ שֶׁ֣בַע שָׁנִ֔ים וּשְׁמֹנֶ֥ה מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּֽהְיוּ֙ כָּל־יְמֵי־שֵׁ֔ת שְׁתֵּ֤ים עֶשְׂרֵה֙ שָׁנָ֔ה וּתְשַׁ֥ע מֵא֖וֹת שָׁנָ֑ה וַיָּמֹֽת׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "וַיְחִ֥י אֱנ֖וֹשׁ תִּשְׁעִ֣ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־קֵינָֽן׃"
          },
          {
            "num": "י",
            "txt": "וַיְחִ֣י אֱנ֗וֹשׁ אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־קֵינָ֔ן חֲמֵ֤שׁ עֶשְׂרֵה֙ שָׁנָ֔ה וּשְׁמֹנֶ֥ה מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּֽהְיוּ֙ כָּל־יְמֵ֣י אֱנ֔וֹשׁ חָמֵ֣שׁ שָׁנִ֔ים וּתְשַׁ֥ע מֵא֖וֹת שָׁנָ֑ה וַיָּמֹֽת׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וַיְחִ֥י קֵינָ֖ן שִׁבְעִ֣ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־מַֽהֲלַלְאֵֽל׃"
          },
          {
            "num": "יג",
            "txt": "וַיְחִ֣י קֵינָ֗ן אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־מַֽהֲלַלְאֵ֔ל אַרְבָּעִ֣ים שָׁנָ֔ה וּשְׁמֹנֶ֥ה מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּֽהְיוּ֙ כָּל־יְמֵ֣י קֵינָ֔ן עֶ֣שֶׂר שָׁנִ֔ים וּתְשַׁ֥ע מֵא֖וֹת שָׁנָ֑ה וַיָּמֹֽת׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וַיְחִ֣י מַֽהֲלַלְאֵ֔ל חָמֵ֥שׁ שָׁנִ֖ים וְשִׁשִּׁ֣ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־יָֽרֶד׃"
          },
          {
            "num": "טז",
            "txt": "וַיְחִ֣י מַֽהֲלַלְאֵ֗ל אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־יֶ֔רֶד שְׁלֹשִׁ֣ים שָׁנָ֔ה וּשְׁמֹנֶ֥ה מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּֽהְיוּ֙ כָּל־יְמֵ֣י מַֽהֲלַלְאֵ֔ל חָמֵ֤שׁ וְתִשְׁעִים֙ שָׁנָ֔ה וּשְׁמֹנֶ֥ה מֵא֖וֹת שָׁנָ֑ה וַיָּמֹֽת׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "וַֽיְחִי־יֶ֕רֶד שְׁתַּ֧יִם וְשִׁשִּׁ֛ים שָׁנָ֖ה וּמְאַ֣ת שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־חֲנֽוֹךְ׃"
          },
          {
            "num": "יט",
            "txt": "וַֽיְחִי־יֶ֗רֶד אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־חֲנ֔וֹךְ שְׁמֹנֶ֥ה מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּֽהְיוּ֙ כָּל־יְמֵי־יֶ֔רֶד שְׁתַּ֤יִם וְשִׁשִּׁים֙ שָׁנָ֔ה וּתְשַׁ֥ע מֵא֖וֹת שָׁנָ֑ה וַיָּמֹֽת׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "וַיְחִ֣י חֲנ֔וֹךְ חָמֵ֥שׁ וְשִׁשִּׁ֖ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־מְתוּשָֽׁלַח׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּתְהַלֵּ֨ךְ חֲנ֜וֹךְ אֶת־הָֽאֱלֹהִ֗ים אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־מְתוּשֶׁ֔לַח שְׁלֹ֥שׁ מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "כג",
            "txt": "וַיְהִ֖י כָּל־יְמֵ֣י חֲנ֑וֹךְ חָמֵ֤שׁ וְשִׁשִּׁים֙ שָׁנָ֔ה וּשְׁלֹ֥שׁ מֵא֖וֹת שָׁנָֽה׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּתְהַלֵּ֥ךְ חֲנ֖וֹךְ אֶת־הָֽאֱלֹהִ֑ים וְאֵינֶ֕נּוּ כִּֽי־לָקַ֥ח אֹת֖וֹ אֱלֹהִֽים׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "וַיְחִ֣י מְתוּשֶׁ֔לַח שֶׁ֧בַע וּשְׁמֹנִ֛ים שָׁנָ֖ה וּמְאַ֣ת שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־לָֽמֶךְ׃"
          },
          {
            "num": "כו",
            "txt": "וַיְחִ֣י מְתוּשֶׁ֗לַח אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־לֶ֔מֶךְ שְׁתַּ֤יִם וּשְׁמוֹנִים֙ שָׁנָ֔ה וּשְׁבַ֥ע מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּֽהְיוּ֙ כָּל־יְמֵ֣י מְתוּשֶׁ֔לַח תֵּ֤שַׁע וְשִׁשִּׁים֙ שָׁנָ֔ה וּתְשַׁ֥ע מֵא֖וֹת שָׁנָ֑ה וַיָּמֹֽת׃ {ס}"
          },
          {
            "num": "כח",
            "txt": "וַֽיְחִי־לֶ֕מֶךְ שְׁתַּ֧יִם וּשְׁמֹנִ֛ים שָׁנָ֖ה וּמְאַ֣ת שָׁנָ֑ה וַיּ֖וֹלֶד בֵּֽן׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּקְרָ֧א אֶת־שְׁמ֛וֹ נֹ֖חַ לֵאמֹ֑ר זֶ֠֞ה יְנַֽחֲמֵ֤נוּ מִֽמַּעֲשֵׂ֨נוּ֙ וּמֵֽעִצְּב֣וֹן יָדֵ֔ינוּ מִן־הָ֣אֲדָמָ֔ה אֲשֶׁ֥ר אֵֽרְרָ֖הּ יְהוָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וַֽיְחִי־לֶ֗מֶךְ אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־נֹ֔חַ חָמֵ֤שׁ וְתִשְׁעִים֙ שָׁנָ֔ה וַֽחֲמֵ֥שׁ מֵאֹ֖ת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃"
          },
          {
            "num": "לא",
            "txt": "וַֽיְהִי֙ כָּל־יְמֵי־לֶ֔מֶךְ שֶׁ֤בַע וְשִׁבְעִים֙ שָׁנָ֔ה וּשְׁבַ֥ע מֵא֖וֹת שָׁנָ֑ה וַיָּמֹֽת׃ {ס}"
          },
          {
            "num": "לב",
            "txt": "וַֽיְהִי־נֹ֕חַ בֶּן־חֲמֵ֥שׁ מֵא֖וֹת שָׁנָ֑ה וַיּ֣וֹלֶד נֹ֔חַ אֶת־שֵׁ֖ם אֶת־חָ֥ם וְאֶת־יָֽפֶת׃"
          }
        ]
      },
      {
        "num": "ו",
        "verses": [
          {
            "num": "א",
            "txt": "וַֽיְהִי֙ כִּֽי־הֵחֵ֣ל הָֽאָדָ֔ם לָרֹ֖ב עַל־פְּנֵ֣י הָֽאֲדָמָ֑ה וּבָנ֖וֹת יֻלְּד֥וּ לָהֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּרְא֤וּ בְנֵי־הָֽאֱלֹהִים֙ אֶת־בְּנ֣וֹת הָֽאָדָ֔ם כִּ֥י טֹבֹ֖ת הֵ֑נָּה וַיִּקְח֤וּ לָהֶם֙ נָשִׁ֔ים מִכֹּ֖ל אֲשֶׁ֥ר בָּחָֽרוּ׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֣אמֶר יְהוָ֗ה לֹֽא־יָד֨וֹן רוּחִ֤י בָֽאָדָם֙ לְעֹלָ֔ם בְּשַׁגַּ֖ם ה֣וּא בָשָׂ֑ר וְהָי֣וּ יָמָ֔יו מֵאָ֥ה וְעֶשְׂרִ֖ים שָׁנָֽה׃"
          },
          {
            "num": "ד",
            "txt": "הַנְּפִלִ֞ים הָי֣וּ בָאָרֶץ֮ בַּיָּמִ֣ים הָהֵם֒ וְגַ֣ם אַֽחֲרֵי־כֵ֗ן אֲשֶׁ֨ר יָבֹ֜אוּ בְּנֵ֤י הָֽאֱלֹהִים֙ אֶל־בְּנ֣וֹת הָֽאָדָ֔ם וְיָֽלְד֖וּ לָהֶ֑ם הֵ֧מָּה הַגִּבֹּרִ֛ים אֲשֶׁ֥ר מֵֽעוֹלָ֖ם אַנְשֵׁ֥י הַשֵּֽׁם׃ {פ}"
          },
          {
            "num": "ה",
            "txt": "וַיַּ֣רְא יְהוָ֔ה כִּ֥י רַבָּ֛ה רָעַ֥ת הָֽאָדָ֖ם בָּאָ֑רֶץ וְכָל־יֵ֨צֶר֙ מַחְשְׁבֹ֣ת לִבּ֔וֹ רַ֥ק רַ֖ע כָּל־הַיּֽוֹם׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּנָּ֣חֶם יְהוָ֔ה כִּֽי־עָשָׂ֥ה אֶת־הָֽאָדָ֖ם בָּאָ֑רֶץ וַיִּתְעַצֵּ֖ב אֶל־לִבּֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֣אמֶר יְהוָ֗ה אֶמְחֶ֨ה אֶת־הָֽאָדָ֤ם אֲשֶׁר־בָּרָ֨אתִי֙ מֵעַל֙ פְּנֵ֣י הָֽאֲדָמָ֔ה מֵֽאָדָם֙ עַד־בְּהֵמָ֔ה עַד־רֶ֖מֶשׂ וְעַד־ע֣וֹף הַשָּׁמָ֑יִם כִּ֥י נִחַ֖מְתִּי כִּ֥י עֲשִׂיתִֽם׃"
          },
          {
            "num": "ח",
            "txt": "וְנֹ֕חַ מָ֥צָא חֵ֖ן בְּעֵינֵ֥י יְהוָֽה׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "אֵ֚לֶּה תּֽוֹלְדֹ֣ת נֹ֔חַ נֹ֗חַ אִ֥ישׁ צַדִּ֛יק תָּמִ֥ים הָיָ֖ה בְּדֹֽרֹתָ֑יו אֶת־הָֽאֱלֹהִ֖ים הִֽתְהַלֶּךְ־נֹֽחַ׃"
          },
          {
            "num": "י",
            "txt": "וַיּ֥וֹלֶד נֹ֖חַ שְׁלֹשָׁ֣ה בָנִ֑ים אֶת־שֵׁ֖ם אֶת־חָ֥ם וְאֶת־יָֽפֶת׃"
          },
          {
            "num": "יא",
            "txt": "וַתִּשָּׁחֵ֥ת הָאָ֖רֶץ לִפְנֵ֣י הָֽאֱלֹהִ֑ים וַתִּמָּלֵ֥א הָאָ֖רֶץ חָמָֽס׃"
          },
          {
            "num": "יב",
            "txt": "וַיַּ֧רְא אֱלֹהִ֛ים אֶת־הָאָ֖רֶץ וְהִנֵּ֣ה נִשְׁחָ֑תָה כִּֽי־הִשְׁחִ֧ית כָּל־בָּשָׂ֛ר אֶת־דַּרְכּ֖וֹ עַל־הָאָֽרֶץ׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֨אמֶר אֱלֹהִ֜ים לְנֹ֗חַ קֵ֤ץ כָּל־בָּשָׂר֙ בָּ֣א לְפָנַ֔י כִּֽי־מָלְאָ֥ה הָאָ֛רֶץ חָמָ֖ס מִפְּנֵיהֶ֑ם וְהִנְנִ֥י מַשְׁחִיתָ֖ם אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "יד",
            "txt": "עֲשֵׂ֤ה לְךָ֙ תֵּבַ֣ת עֲצֵי־גֹ֔פֶר קִנִּ֖ים תַּֽעֲשֶׂ֣ה אֶת־הַתֵּבָ֑ה וְכָֽפַרְתָּ֥ אֹתָ֛הּ מִבַּ֥יִת וּמִח֖וּץ בַּכֹּֽפֶר׃"
          },
          {
            "num": "טו",
            "txt": "וְזֶ֕ה אֲשֶׁ֥ר תַּֽעֲשֶׂ֖ה אֹתָ֑הּ שְׁלֹ֧שׁ מֵא֣וֹת אַמָּ֗ה אֹ֚רֶךְ הַתֵּבָ֔ה חֲמִשִּׁ֤ים אַמָּה֙ רָחְבָּ֔הּ וּשְׁלֹשִׁ֥ים אַמָּ֖ה קֽוֹמָתָֽהּ׃"
          },
          {
            "num": "טז",
            "txt": "צֹ֣הַר ׀ תַּֽעֲשֶׂ֣ה לַתֵּבָ֗ה וְאֶל־אַמָּה֙ תְּכַלֶּ֣נָּה מִלְמַ֔עְלָה וּפֶ֥תַח הַתֵּבָ֖ה בְּצִדָּ֣הּ תָּשִׂ֑ים תַּחְתִּיִּ֛ם שְׁנִיִּ֥ם וּשְׁלִשִׁ֖ים תַּֽעֲשֶֽׂהָ׃"
          },
          {
            "num": "יז",
            "txt": "וַֽאֲנִ֗י הִנְנִי֩ מֵבִ֨יא אֶת־הַמַּבּ֥וּל מַ֨יִם֙ עַל־הָאָ֔רֶץ לְשַׁחֵ֣ת כָּל־בָּשָׂ֗ר אֲשֶׁר־בּוֹ֙ ר֣וּחַ חַיִּ֔ים מִתַּ֖חַת הַשָּׁמָ֑יִם כֹּ֥ל אֲשֶׁר־בָּאָ֖רֶץ יִגְוָֽע׃"
          },
          {
            "num": "יח",
            "txt": "וַהֲקִֽמֹתִ֥י אֶת־בְּרִיתִ֖י אִתָּ֑ךְ וּבָאתָ֙ אֶל־הַתֵּבָ֔ה אַתָּ֕ה וּבָנֶ֛יךָ וְאִשְׁתְּךָ֥ וּנְשֵֽׁי־בָנֶ֖יךָ אִתָּֽךְ׃"
          },
          {
            "num": "יט",
            "txt": "וּמִכָּל־הָ֠חַי מִֽכָּל־בָּשָׂ֞ר שְׁנַ֧יִם מִכֹּ֛ל תָּבִ֥יא אֶל־הַתֵּבָ֖ה לְהַֽחֲיֹ֣ת אִתָּ֑ךְ זָכָ֥ר וּנְקֵבָ֖ה יִֽהְיֽוּ׃"
          },
          {
            "num": "כ",
            "txt": "מֵֽהָע֣וֹף לְמִינֵ֗הוּ וּמִן־הַבְּהֵמָה֙ לְמִינָ֔הּ מִכֹּ֛ל רֶ֥מֶשׂ הָֽאֲדָמָ֖ה לְמִינֵ֑הוּ שְׁנַ֧יִם מִכֹּ֛ל יָבֹ֥אוּ אֵלֶ֖יךָ לְהַֽחֲיֽוֹת׃"
          },
          {
            "num": "כא",
            "txt": "וְאַתָּ֣ה קַח־לְךָ֗ מִכָּל־מַֽאֲכָל֙ אֲשֶׁ֣ר יֵֽאָכֵ֔ל וְאָֽסַפְתָּ֖ אֵלֶ֑יךָ וְהָיָ֥ה לְךָ֛ וְלָהֶ֖ם לְאָכְלָֽה׃"
          },
          {
            "num": "כב",
            "txt": "וַיַּ֖עַשׂ נֹ֑חַ כְּ֠כֹל אֲשֶׁ֨ר צִוָּ֥ה אֹת֛וֹ אֱלֹהִ֖ים כֵּ֥ן עָשָֽׂה׃"
          }
        ]
      },
      {
        "num": "ז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ לְנֹ֔חַ בֹּֽא־אַתָּ֥ה וְכָל־בֵּֽיתְךָ֖ אֶל־הַתֵּבָ֑ה כִּֽי־אֹתְךָ֥ רָאִ֛יתִי צַדִּ֥יק לְפָנַ֖י בַּדּ֥וֹר הַזֶּֽה׃"
          },
          {
            "num": "ב",
            "txt": "מִכֹּ֣ל ׀ הַבְּהֵמָ֣ה הַטְּהוֹרָ֗ה תִּֽקַּח־לְךָ֛ שִׁבְעָ֥ה שִׁבְעָ֖ה אִ֣ישׁ וְאִשְׁתּ֑וֹ וּמִן־הַבְּהֵמָ֡ה אֲ֠שֶׁר לֹ֣א טְהֹרָ֥ה הִ֛וא שְׁנַ֖יִם אִ֥ישׁ וְאִשְׁתּֽוֹ׃"
          },
          {
            "num": "ג",
            "txt": "גַּ֣ם מֵע֧וֹף הַשָּׁמַ֛יִם שִׁבְעָ֥ה שִׁבְעָ֖ה זָכָ֣ר וּנְקֵבָ֑ה לְחַיּ֥וֹת זֶ֖רַע עַל־פְּנֵ֥י כָל־הָאָֽרֶץ׃"
          },
          {
            "num": "ד",
            "txt": "כִּי֩ לְיָמִ֨ים ע֜וֹד שִׁבְעָ֗ה אָֽנֹכִי֙ מַמְטִ֣יר עַל־הָאָ֔רֶץ אַרְבָּעִ֣ים י֔וֹם וְאַרְבָּעִ֖ים לָ֑יְלָה וּמָחִ֗יתִי אֶֽת־כָּל־הַיְקוּם֙ אֲשֶׁ֣ר עָשִׂ֔יתִי מֵעַ֖ל פְּנֵ֥י הָֽאֲדָמָֽה׃"
          },
          {
            "num": "ה",
            "txt": "וַיַּ֖עַשׂ נֹ֑חַ כְּכֹ֥ל אֲשֶׁר־צִוָּ֖הוּ יְהוָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וְנֹ֕חַ בֶּן־שֵׁ֥שׁ מֵא֖וֹת שָׁנָ֑ה וְהַמַּבּ֣וּל הָיָ֔ה מַ֖יִם עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "ז",
            "txt": "וַיָּ֣בֹא נֹ֗חַ וּ֠בָנָיו וְאִשְׁתּ֧וֹ וּנְשֵֽׁי־בָנָ֛יו אִתּ֖וֹ אֶל־הַתֵּבָ֑ה מִפְּנֵ֖י מֵ֥י הַמַּבּֽוּל׃"
          },
          {
            "num": "ח",
            "txt": "מִן־הַבְּהֵמָה֙ הַטְּהוֹרָ֔ה וּמִ֨ן־הַבְּהֵמָ֔ה אֲשֶׁ֥ר אֵינֶ֖נָּה טְהֹרָ֑ה וּמִ֨ן־הָע֔וֹף וְכֹ֥ל אֲשֶׁר־רֹמֵ֖שׂ עַל־הָֽאֲדָמָֽה׃"
          },
          {
            "num": "ט",
            "txt": "שְׁנַ֨יִם שְׁנַ֜יִם בָּ֧אוּ אֶל־נֹ֛חַ אֶל־הַתֵּבָ֖ה זָכָ֣ר וּנְקֵבָ֑ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה אֱלֹהִ֖ים אֶת־נֹֽחַ׃"
          },
          {
            "num": "י",
            "txt": "וַיְהִ֖י לְשִׁבְעַ֣ת הַיָּמִ֑ים וּמֵ֣י הַמַּבּ֔וּל הָי֖וּ עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "יא",
            "txt": "בִּשְׁנַ֨ת שֵׁשׁ־מֵא֤וֹת שָׁנָה֙ לְחַיֵּי־נֹ֔חַ בַּחֹ֨דֶשׁ֙ הַשֵּׁנִ֔י בְּשִׁבְעָֽה־עָשָׂ֥ר י֖וֹם לַחֹ֑דֶשׁ בַּיּ֣וֹם הַזֶּ֗ה נִבְקְעוּ֙ כָּֽל־מַעְיְנֹת֙ תְּה֣וֹם רַבָּ֔ה וַֽאֲרֻבֹּ֥ת הַשָּׁמַ֖יִם נִפְתָּֽחוּ׃"
          },
          {
            "num": "יב",
            "txt": "וַיְהִ֥י הַגֶּ֖שֶׁם עַל־הָאָ֑רֶץ אַרְבָּעִ֣ים י֔וֹם וְאַרְבָּעִ֖ים לָֽיְלָה׃"
          },
          {
            "num": "יג",
            "txt": "בְּעֶ֨צֶם הַיּ֤וֹם הַזֶּה֙ בָּ֣א נֹ֔חַ וְשֵׁם־וְחָ֥ם וָיֶ֖פֶת בְּנֵי־נֹ֑חַ וְאֵ֣שֶׁת נֹ֗חַ וּשְׁלֹ֧שֶׁת נְשֵֽׁי־בָנָ֛יו אִתָּ֖ם אֶל־הַתֵּבָֽה׃"
          },
          {
            "num": "יד",
            "txt": "הֵ֜מָּה וְכָל־הַֽחַיָּ֣ה לְמִינָ֗הּ וְכָל־הַבְּהֵמָה֙ לְמִינָ֔הּ וְכָל־הָרֶ֛מֶשׂ הָֽרֹמֵ֥שׂ עַל־הָאָ֖רֶץ לְמִינֵ֑הוּ וְכָל־הָע֣וֹף לְמִינֵ֔הוּ כֹּ֖ל צִפּ֥וֹר כָּל־כָּנָֽף׃"
          },
          {
            "num": "טו",
            "txt": "וַיָּבֹ֥אוּ אֶל־נֹ֖חַ אֶל־הַתֵּבָ֑ה שְׁנַ֤יִם שְׁנַ֨יִם֙ מִכָּל־הַבָּשָׂ֔ר אֲשֶׁר־בּ֖וֹ ר֥וּחַ חַיִּֽים׃"
          },
          {
            "num": "טז",
            "txt": "וְהַבָּאִ֗ים זָכָ֨ר וּנְקֵבָ֤ה מִכָּל־בָּשָׂר֙ בָּ֔אוּ כַּֽאֲשֶׁ֛ר צִוָּ֥ה אֹת֖וֹ אֱלֹהִ֑ים וַיִּסְגֹּ֥ר יְהוָ֖ה בַּֽעֲדֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "וַיְהִ֧י הַמַּבּ֛וּל אַרְבָּעִ֥ים י֖וֹם עַל־הָאָ֑רֶץ וַיִּרְבּ֣וּ הַמַּ֗יִם וַיִּשְׂאוּ֙ אֶת־הַתֵּבָ֔ה וַתָּ֖רָם מֵעַ֥ל הָאָֽרֶץ׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּגְבְּר֥וּ הַמַּ֛יִם וַיִּרְבּ֥וּ מְאֹ֖ד עַל־הָאָ֑רֶץ וַתֵּ֥לֶךְ הַתֵּבָ֖ה עַל־פְּנֵ֥י הַמָּֽיִם׃"
          },
          {
            "num": "יט",
            "txt": "וְהַמַּ֗יִם גָּ֥בְר֛וּ מְאֹ֥ד מְאֹ֖ד עַל־הָאָ֑רֶץ וַיְכֻסּ֗וּ כָּל־הֶֽהָרִים֙ הַגְּבֹהִ֔ים אֲשֶׁר־תַּ֖חַת כָּל־הַשָּׁמָֽיִם׃"
          },
          {
            "num": "כ",
            "txt": "חֲמֵ֨שׁ עֶשְׂרֵ֤ה אַמָּה֙ מִלְמַ֔עְלָה גָּֽבְר֖וּ הַמָּ֑יִם וַיְכֻסּ֖וּ הֶֽהָרִֽים׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּגְוַ֞ע כָּל־בָּשָׂ֣ר ׀ הָֽרֹמֵ֣שׂ עַל־הָאָ֗רֶץ בָּע֤וֹף וּבַבְּהֵמָה֙ וּבַ֣חַיָּ֔ה וּבְכָל־הַשֶּׁ֖רֶץ הַשֹּׁרֵ֣ץ עַל־הָאָ֑רֶץ וְכֹ֖ל הָֽאָדָֽם׃"
          },
          {
            "num": "כב",
            "txt": "כֹּ֡ל אֲשֶׁר֩ נִשְׁמַת־ר֨וּחַ חַיִּ֜ים בְּאַפָּ֗יו מִכֹּ֛ל אֲשֶׁ֥ר בֶּחָֽרָבָ֖ה מֵֽתוּ׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּ֜מַח אֶֽת־כָּל־הַיְק֣וּם ׀ אֲשֶׁ֣ר ׀ עַל־פְּנֵ֣י הָֽאֲדָמָ֗ה מֵֽאָדָ֤ם עַד־בְּהֵמָה֙ עַד־רֶ֨מֶשׂ֙ וְעַד־ע֣וֹף הַשָּׁמַ֔יִם וַיִּמָּח֖וּ מִן־הָאָ֑רֶץ וַיִּשָּׁ֧אֶר אַךְ־נֹ֛חַ וַֽאֲשֶׁ֥ר אִתּ֖וֹ בַּתֵּבָֽה׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּגְבְּר֥וּ הַמַּ֖יִם עַל־הָאָ֑רֶץ חֲמִשִּׁ֥ים וּמְאַ֖ת יֽוֹם׃"
          }
        ]
      },
      {
        "num": "ח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּזְכֹּ֤ר אֱלֹהִים֙ אֶת־נֹ֔חַ וְאֵ֤ת כָּל־הַֽחַיָּה֙ וְאֶת־כָּל־הַבְּהֵמָ֔ה אֲשֶׁ֥ר אִתּ֖וֹ בַּתֵּבָ֑ה וַיַּֽעֲבֵ֨ר אֱלֹהִ֥ים ר֨וּחַ֙ עַל־הָאָ֔רֶץ וַיָּשֹׁ֖כּוּ הַמָּֽיִם׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּסָּֽכְרוּ֙ מַעְיְנֹ֣ת תְּה֔וֹם וַֽאֲרֻבֹּ֖ת הַשָּׁמָ֑יִם וַיִּכָּלֵ֥א הַגֶּ֖שֶׁם מִן־הַשָּׁמָֽיִם׃"
          },
          {
            "num": "ג",
            "txt": "וַיָּשֻׁ֧בוּ הַמַּ֛יִם מֵעַ֥ל הָאָ֖רֶץ הָל֣וֹךְ וָשׁ֑וֹב וַיַּחְסְר֣וּ הַמַּ֔יִם מִקְצֵ֕ה חֲמִשִּׁ֥ים וּמְאַ֖ת יֽוֹם׃"
          },
          {
            "num": "ד",
            "txt": "וַתָּ֤נַח הַתֵּבָה֙ בַּחֹ֣דֶשׁ הַשְּׁבִיעִ֔י בְּשִׁבְעָֽה־עָשָׂ֥ר י֖וֹם לַחֹ֑דֶשׁ עַ֖ל הָרֵ֥י אֲרָרָֽט׃"
          },
          {
            "num": "ה",
            "txt": "וְהַמַּ֗יִם הָיוּ֙ הָל֣וֹךְ וְחָס֔וֹר עַ֖ד הַחֹ֣דֶשׁ הָֽעֲשִׂירִ֑י בָּֽעֲשִׂירִי֙ בְּאֶחָ֣ד לַחֹ֔דֶשׁ נִרְא֖וּ רָאשֵׁ֥י הֶֽהָרִֽים׃"
          },
          {
            "num": "ו",
            "txt": "וַיְהִ֕י מִקֵּ֖ץ אַרְבָּעִ֣ים י֑וֹם וַיִּפְתַּ֣ח נֹ֔חַ אֶת־חַלּ֥וֹן הַתֵּבָ֖ה אֲשֶׁ֥ר עָשָֽׂה׃"
          },
          {
            "num": "ז",
            "txt": "וַיְשַׁלַּ֖ח אֶת־הָֽעֹרֵ֑ב וַיֵּצֵ֤א יָצוֹא֙ וָשׁ֔וֹב עַד־יְבֹ֥שֶׁת הַמַּ֖יִם מֵעַ֥ל הָאָֽרֶץ׃"
          },
          {
            "num": "ח",
            "txt": "וַיְשַׁלַּ֥ח אֶת־הַיּוֹנָ֖ה מֵֽאִתּ֑וֹ לִרְאוֹת֙ הֲקַ֣לּוּ הַמַּ֔יִם מֵעַ֖ל פְּנֵ֥י הָֽאֲדָמָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וְלֹֽא־מָצְאָה֩ הַיּוֹנָ֨ה מָנ֜וֹחַ לְכַף־רַגְלָ֗הּ וַתָּ֤שָׁב אֵלָיו֙ אֶל־הַתֵּבָ֔ה כִּי־מַ֖יִם עַל־פְּנֵ֣י כָל־הָאָ֑רֶץ וַיִּשְׁלַ֤ח יָדוֹ֙ וַיִּקָּחֶ֔הָ וַיָּבֵ֥א אֹתָ֛הּ אֵלָ֖יו אֶל־הַתֵּבָֽה׃"
          },
          {
            "num": "י",
            "txt": "וַיָּ֣חֶל ע֔וֹד שִׁבְעַ֥ת יָמִ֖ים אֲחֵרִ֑ים וַיֹּ֛סֶף שַׁלַּ֥ח אֶת־הַיּוֹנָ֖ה מִן־הַתֵּבָֽה׃"
          },
          {
            "num": "יא",
            "txt": "וַתָּבֹ֨א אֵלָ֤יו הַיּוֹנָה֙ לְעֵ֣ת עֶ֔רֶב וְהִנֵּ֥ה עֲלֵה־זַ֖יִת טָרָ֣ף בְּפִ֑יהָ וַיֵּ֣דַע נֹ֔חַ כִּי־קַ֥לּוּ הַמַּ֖יִם מֵעַ֥ל הָאָֽרֶץ׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּיָּ֣חֶל ע֔וֹד שִׁבְעַ֥ת יָמִ֖ים אֲחֵרִ֑ים וַיְשַׁלַּח֙ אֶת־הַיּוֹנָ֔ה וְלֹֽא־יָסְפָ֥ה שׁוּב־אֵלָ֖יו עֽוֹד׃"
          },
          {
            "num": "יג",
            "txt": "וַ֠יְהִי בְּאַחַ֨ת וְשֵׁשׁ־מֵא֜וֹת שָׁנָ֗ה בָּֽרִאשׁוֹן֙ בְּאֶחָ֣ד לַחֹ֔דֶשׁ חָֽרְב֥וּ הַמַּ֖יִם מֵעַ֣ל הָאָ֑רֶץ וַיָּ֤סַר נֹ֨חַ֙ אֶת־מִכְסֵ֣ה הַתֵּבָ֔ה וַיַּ֕רְא וְהִנֵּ֥ה חָֽרְב֖וּ פְּנֵ֥י הָֽאֲדָמָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וּבַחֹ֨דֶשׁ֙ הַשֵּׁנִ֔י בְּשִׁבְעָ֧ה וְעֶשְׂרִ֛ים י֖וֹם לַחֹ֑דֶשׁ יָֽבְשָׁ֖ה הָאָֽרֶץ׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וַיְדַבֵּ֥ר אֱלֹהִ֖ים אֶל־נֹ֥חַ לֵאמֹֽר׃"
          },
          {
            "num": "טז",
            "txt": "צֵ֖א מִן־הַתֵּבָ֑ה אַתָּ֕ה וְאִשְׁתְּךָ֛ וּבָנֶ֥יךָ וּנְשֵֽׁי־בָנֶ֖יךָ אִתָּֽךְ׃"
          },
          {
            "num": "יז",
            "txt": "כָּל־הַֽחַיָּ֨ה אֲשֶֽׁר־אִתְּךָ֜ מִכָּל־בָּשָׂ֗ר בָּע֧וֹף וּבַבְּהֵמָ֛ה וּבְכָל־הָרֶ֛מֶשׂ הָֽרֹמֵ֥שׂ עַל־הָאָ֖רֶץ הוצא (הַיְצֵ֣א) אִתָּ֑ךְ וְשָֽׁרְצ֣וּ בָאָ֔רֶץ וּפָר֥וּ וְרָב֖וּ עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "יח",
            "txt": "וַיֵּ֖צֵא־נֹ֑חַ וּבָנָ֛יו וְאִשְׁתּ֥וֹ וּנְשֵֽׁי־בָנָ֖יו אִתּֽוֹ׃"
          },
          {
            "num": "יט",
            "txt": "כָּל־הַֽחַיָּ֗ה כָּל־הָרֶ֨מֶשׂ֙ וְכָל־הָע֔וֹף כֹּ֖ל רוֹמֵ֣שׂ עַל־הָאָ֑רֶץ לְמִשְׁפְּחֹ֣תֵיהֶ֔ם יָֽצְא֖וּ מִן־הַתֵּבָֽה׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּ֥בֶן נֹ֛חַ מִזְבֵּ֖חַ לַֽיהוָ֑ה וַיִּקַּ֞ח מִכֹּ֣ל ׀ הַבְּהֵמָ֣ה הַטְּהֹרָ֗ה וּמִכֹּל֙ הָע֣וֹף הַטָּה֔וֹר וַיַּ֥עַל עֹלֹ֖ת בַּמִּזְבֵּֽחַ׃"
          },
          {
            "num": "כא",
            "txt": "וַיָּ֣רַח יְהוָה֮ אֶת־רֵ֣יחַ הַנִּיחֹחַ֒ וַיֹּ֨אמֶר יְהוָ֜ה אֶל־לִבּ֗וֹ לֹֽא־אֹ֠סִף לְקַלֵּ֨ל ע֤וֹד אֶת־הָֽאֲדָמָה֙ בַּֽעֲב֣וּר הָֽאָדָ֔ם כִּ֠י יֵ֣צֶר לֵ֧ב הָֽאָדָ֛ם רַ֖ע מִנְּעֻרָ֑יו וְלֹֽא־אֹסִ֥ף ע֛וֹד לְהַכּ֥וֹת אֶת־כָּל־חַ֖י כַּֽאֲשֶׁ֥ר עָשִֽׂיתִי׃"
          },
          {
            "num": "כב",
            "txt": "עֹ֖ד כָּל־יְמֵ֣י הָאָ֑רֶץ זֶ֡רַע וְ֠קָצִיר וְקֹ֨ר וָחֹ֜ם וְקַ֧יִץ וָחֹ֛רֶף וְי֥וֹם וָלַ֖יְלָה לֹ֥א יִשְׁבֹּֽתוּ׃"
          }
        ]
      },
      {
        "num": "ט",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְבָ֣רֶךְ אֱלֹהִ֔ים אֶת־נֹ֖חַ וְאֶת־בָּנָ֑יו וַיֹּ֧אמֶר לָהֶ֛ם פְּר֥וּ וּרְב֖וּ וּמִלְא֥וּ אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "ב",
            "txt": "וּמוֹרַֽאֲכֶ֤ם וְחִתְּכֶם֙ יִֽהְיֶ֔ה עַ֚ל כָּל־חַיַּ֣ת הָאָ֔רֶץ וְעַ֖ל כָּל־ע֣וֹף הַשָּׁמָ֑יִם בְּכֹל֩ אֲשֶׁ֨ר תִּרְמֹ֧שׂ הָֽאֲדָמָ֛ה וּֽבְכָל־דְּגֵ֥י הַיָּ֖ם בְּיֶדְכֶ֥ם נִתָּֽנוּ׃"
          },
          {
            "num": "ג",
            "txt": "כָּל־רֶ֨מֶשׂ֙ אֲשֶׁ֣ר הוּא־חַ֔י לָכֶ֥ם יִֽהְיֶ֖ה לְאָכְלָ֑ה כְּיֶ֣רֶק עֵ֔שֶׂב נָתַ֥תִּי לָכֶ֖ם אֶת־כֹּֽל׃"
          },
          {
            "num": "ד",
            "txt": "אַךְ־בָּשָׂ֕ר בְּנַפְשׁ֥וֹ דָמ֖וֹ לֹ֥א תֹאכֵֽלוּ׃"
          },
          {
            "num": "ה",
            "txt": "וְאַ֨ךְ אֶת־דִּמְכֶ֤ם לְנַפְשֹֽׁתֵיכֶם֙ אֶדְרֹ֔שׁ מִיַּ֥ד כָּל־חַיָּ֖ה אֶדְרְשֶׁ֑נּוּ וּמִיַּ֣ד הָֽאָדָ֗ם מִיַּד֙ אִ֣ישׁ אָחִ֔יו אֶדְרֹ֖שׁ אֶת־נֶ֥פֶשׁ הָֽאָדָֽם׃"
          },
          {
            "num": "ו",
            "txt": "שֹׁפֵךְ֙ דַּ֣ם הָֽאָדָ֔ם בָּֽאָדָ֖ם דָּמ֣וֹ יִשָּׁפֵ֑ךְ כִּ֚י בְּצֶ֣לֶם אֱלֹהִ֔ים עָשָׂ֖ה אֶת־הָֽאָדָֽם׃"
          },
          {
            "num": "ז",
            "txt": "וְאַתֶּ֖ם פְּר֣וּ וּרְב֑וּ שִׁרְצ֥וּ בָאָ֖רֶץ וּרְבוּ־בָֽהּ׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֤אמֶר אֱלֹהִים֙ אֶל־נֹ֔חַ וְאֶל־בָּנָ֥יו אִתּ֖וֹ לֵאמֹֽר׃"
          },
          {
            "num": "ט",
            "txt": "וַֽאֲנִ֕י הִנְנִ֥י מֵקִ֛ים אֶת־בְּרִיתִ֖י אִתְּכֶ֑ם וְאֶֽת־זַרְעֲכֶ֖ם אַֽחֲרֵיכֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וְאֵ֨ת כָּל־נֶ֤פֶשׁ הַֽחַיָּה֙ אֲשֶׁ֣ר אִתְּכֶ֔ם בָּע֧וֹף בַּבְּהֵמָ֛ה וּֽבְכָל־חַיַּ֥ת הָאָ֖רֶץ אִתְּכֶ֑ם מִכֹּל֙ יֹֽצְאֵ֣י הַתֵּבָ֔ה לְכֹ֖ל חַיַּ֥ת הָאָֽרֶץ׃"
          },
          {
            "num": "יא",
            "txt": "וַהֲקִֽמֹתִ֤י אֶת־בְּרִיתִי֙ אִתְּכֶ֔ם וְלֹֽא־יִכָּרֵ֧ת כָּל־בָּשָׂ֛ר ע֖וֹד מִמֵּ֣י הַמַּבּ֑וּל וְלֹֽא־יִהְיֶ֥ה ע֛וֹד מַבּ֖וּל לְשַׁחֵ֥ת הָאָֽרֶץ׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֗ים זֹ֤את אֽוֹת־הַבְּרִית֙ אֲשֶׁר־אֲנִ֣י נֹתֵ֗ן בֵּינִי֙ וּבֵ֣ינֵיכֶ֔ם וּבֵ֛ין כָּל־נֶ֥פֶשׁ חַיָּ֖ה אֲשֶׁ֣ר אִתְּכֶ֑ם לְדֹרֹ֖ת עוֹלָֽם׃"
          },
          {
            "num": "יג",
            "txt": "אֶת־קַשְׁתִּ֕י נָתַ֖תִּי בֶּֽעָנָ֑ן וְהָֽיְתָה֙ לְא֣וֹת בְּרִ֔ית בֵּינִ֖י וּבֵ֥ין הָאָֽרֶץ׃"
          },
          {
            "num": "יד",
            "txt": "וְהָיָ֕ה בְּעַֽנְנִ֥י עָנָ֖ן עַל־הָאָ֑רֶץ וְנִרְאֲתָ֥ה הַקֶּ֖שֶׁת בֶּֽעָנָֽן׃"
          },
          {
            "num": "טו",
            "txt": "וְזָֽכַרְתִּ֣י אֶת־בְּרִיתִ֗י אֲשֶׁ֤ר בֵּינִי֙ וּבֵ֣ינֵיכֶ֔ם וּבֵ֛ין כָּל־נֶ֥פֶשׁ חַיָּ֖ה בְּכָל־בָּשָׂ֑ר וְלֹֽא־יִֽהְיֶ֨ה ע֤וֹד הַמַּ֨יִם֙ לְמַבּ֔וּל לְשַׁחֵ֖ת כָּל־בָּשָֽׂר׃"
          },
          {
            "num": "טז",
            "txt": "וְהָֽיְתָ֥ה הַקֶּ֖שֶׁת בֶּֽעָנָ֑ן וּרְאִיתִ֗יהָ לִזְכֹּר֙ בְּרִ֣ית עוֹלָ֔ם בֵּ֣ין אֱלֹהִ֔ים וּבֵין֙ כָּל־נֶ֣פֶשׁ חַיָּ֔ה בְּכָל־בָּשָׂ֖ר אֲשֶׁ֥ר עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֥אמֶר אֱלֹהִ֖ים אֶל־נֹ֑חַ זֹ֤את אֽוֹת־הַבְּרִית֙ אֲשֶׁ֣ר הֲקִמֹ֔תִי בֵּינִ֕י וּבֵ֥ין כָּל־בָּשָׂ֖ר אֲשֶׁ֥ר עַל־הָאָֽרֶץ׃ {פ}"
          },
          {
            "num": "יח",
            "txt": "וַיִּֽהְי֣וּ בְנֵי־נֹ֗חַ הַיֹּֽצְאִים֙ מִן־הַתֵּבָ֔ה שֵׁ֖ם וְחָ֣ם וָיָ֑פֶת וְחָ֕ם ה֖וּא אֲבִ֥י כְנָֽעַן׃"
          },
          {
            "num": "יט",
            "txt": "שְׁלֹשָׁ֥ה אֵ֖לֶּה בְּנֵי־נֹ֑חַ וּמֵאֵ֖לֶּה נָֽפְצָ֥ה כָל־הָאָֽרֶץ׃"
          },
          {
            "num": "כ",
            "txt": "וַיָּ֥חֶל נֹ֖חַ אִ֣ישׁ הָֽאֲדָמָ֑ה וַיִּטַּ֖ע כָּֽרֶם׃"
          },
          {
            "num": "כא",
            "txt": "וַיֵּ֥שְׁתְּ מִן־הַיַּ֖יִן וַיִּשְׁכָּ֑ר וַיִּתְגַּ֖ל בְּת֥וֹךְ אָֽהֳלֹֽה׃"
          },
          {
            "num": "כב",
            "txt": "וַיַּ֗רְא חָ֚ם אֲבִ֣י כְנַ֔עַן אֵ֖ת עֶרְוַ֣ת אָבִ֑יו וַיַּגֵּ֥ד לִשְׁנֵֽי־אֶחָ֖יו בַּחֽוּץ׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּקַּח֩ שֵׁ֨ם וָיֶ֜פֶת אֶת־הַשִּׂמְלָ֗ה וַיָּשִׂ֨ימוּ֙ עַל־שְׁכֶ֣ם שְׁנֵיהֶ֔ם וַיֵּֽלְכוּ֙ אֲחֹ֣רַנִּ֔ית וַיְכַסּ֕וּ אֵ֖ת עֶרְוַ֣ת אֲבִיהֶ֑ם וּפְנֵיהֶם֙ אֲחֹ֣רַנִּ֔ית וְעֶרְוַ֥ת אֲבִיהֶ֖ם לֹ֥א רָאֽוּ׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּ֥יקֶץ נֹ֖חַ מִיֵּינ֑וֹ וַיֵּ֕דַע אֵ֛ת אֲשֶׁר־עָ֥שָׂה ל֖וֹ בְּנ֥וֹ הַקָּטָֽן׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֖אמֶר אָר֣וּר כְּנָ֑עַן עֶ֥בֶד עֲבָדִ֖ים יִֽהְיֶ֥ה לְאֶחָֽיו׃"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֕אמֶר בָּר֥וּךְ יְהוָ֖ה אֱלֹ֣הֵי שֵׁ֑ם וִיהִ֥י כְנַ֖עַן עֶ֥בֶד לָֽמוֹ׃"
          },
          {
            "num": "כז",
            "txt": "יַ֤פְתְּ אֱלֹהִים֙ לְיֶ֔פֶת וְיִשְׁכֹּ֖ן בְּאָֽהֳלֵי־שֵׁ֑ם וִיהִ֥י כְנַ֖עַן עֶ֥בֶד לָֽמוֹ׃"
          },
          {
            "num": "כח",
            "txt": "וַֽיְחִי־נֹ֖חַ אַחַ֣ר הַמַּבּ֑וּל שְׁלֹ֤שׁ מֵאוֹת֙ שָׁנָ֔ה וַֽחֲמִשִּׁ֖ים שָׁנָֽה׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּֽהְיוּ֙ כָּל־יְמֵי־נֹ֔חַ תְּשַׁ֤ע מֵאוֹת֙ שָׁנָ֔ה וַֽחֲמִשִּׁ֖ים שָׁנָ֑ה וַיָּמֹֽת׃ {פ}"
          }
        ]
      },
      {
        "num": "י",
        "verses": [
          {
            "num": "א",
            "txt": "וְאֵ֨לֶּה֙ תּֽוֹלְדֹ֣ת בְּנֵי־נֹ֔חַ שֵׁ֖ם חָ֣ם וָיָ֑פֶת וַיִּוָּֽלְד֥וּ לָהֶ֛ם בָּנִ֖ים אַחַ֥ר הַמַּבּֽוּל׃"
          },
          {
            "num": "ב",
            "txt": "בְּנֵ֣י יֶ֔פֶת גֹּ֣מֶר וּמָג֔וֹג וּמָדַ֖י וְיָוָ֣ן וְתֻבָ֑ל וּמֶ֖שֶׁךְ וְתִירָֽס׃"
          },
          {
            "num": "ג",
            "txt": "וּבְנֵ֖י גֹּ֑מֶר אַשְׁכְּנַ֥ז וְרִיפַ֖ת וְתֹֽגַרְמָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וּבְנֵ֥י יָוָ֖ן אֱלִישָׁ֣ה וְתַרְשִׁ֑ישׁ כִּתִּ֖ים וְדֹֽדָנִֽים׃"
          },
          {
            "num": "ה",
            "txt": "מֵ֠אֵלֶּה נִפְרְד֞וּ אִיֵּ֤י הַגּוֹיִם֙ בְּאַרְצֹתָ֔ם אִ֖ישׁ לִלְשֹׁנ֑וֹ לְמִשְׁפְּחֹתָ֖ם בְּגֽוֹיֵהֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "וּבְנֵ֖י חָ֑ם כּ֥וּשׁ וּמִצְרַ֖יִם וּפ֥וּט וּכְנָֽעַן׃"
          },
          {
            "num": "ז",
            "txt": "וּבְנֵ֣י כ֔וּשׁ סְבָא֙ וַֽחֲוִילָ֔ה וְסַבְתָּ֥ה וְרַעְמָ֖ה וְסַבְתְּכָ֑א וּבְנֵ֥י רַעְמָ֖ה שְׁבָ֥א וּדְדָֽן׃"
          },
          {
            "num": "ח",
            "txt": "וְכ֖וּשׁ יָלַ֣ד אֶת־נִמְרֹ֑ד ה֣וּא הֵחֵ֔ל לִֽהְי֥וֹת גִּבֹּ֖ר בָּאָֽרֶץ׃"
          },
          {
            "num": "ט",
            "txt": "הֽוּא־הָיָ֥ה גִבֹּֽר־צַ֖יִד לִפְנֵ֣י יְהוָ֑ה עַל־כֵּן֙ יֵֽאָמַ֔ר כְּנִמְרֹ֛ד גִּבּ֥וֹר צַ֖יִד לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "י",
            "txt": "וַתְּהִ֨י רֵאשִׁ֤ית מַמְלַכְתּוֹ֙ בָּבֶ֔ל וְאֶ֖רֶךְ וְאַכַּ֣ד וְכַלְנֵ֑ה בְּאֶ֖רֶץ שִׁנְעָֽר׃"
          },
          {
            "num": "יא",
            "txt": "מִן־הָאָ֥רֶץ הַהִ֖וא יָצָ֣א אַשּׁ֑וּר וַיִּ֨בֶן֙ אֶת־נִ֣ינְוֵ֔ה וְאֶת־רְחֹבֹ֥ת עִ֖יר וְאֶת־כָּֽלַח׃"
          },
          {
            "num": "יב",
            "txt": "וְֽאֶת־רֶ֔סֶן בֵּ֥ין נִֽינְוֵ֖ה וּבֵ֣ין כָּ֑לַח הִ֖וא הָעִ֥יר הַגְּדֹלָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וּמִצְרַ֡יִם יָלַ֞ד אֶת־לוּדִ֧ים וְאֶת־עֲנָמִ֛ים וְאֶת־לְהָבִ֖ים וְאֶת־נַפְתֻּחִֽים׃"
          },
          {
            "num": "יד",
            "txt": "וְֽאֶת־פַּתְרֻסִ֞ים וְאֶת־כַּסְלֻחִ֗ים אֲשֶׁ֨ר יָֽצְא֥וּ מִשָּׁ֛ם פְּלִשְׁתִּ֖ים וְאֶת־כַּפְתֹּרִֽים׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וּכְנַ֗עַן יָלַ֛ד אֶת־צִידֹ֥ן בְּכֹר֖וֹ וְאֶת־חֵֽת׃"
          },
          {
            "num": "טז",
            "txt": "וְאֶת־הַיְבוּסִי֙ וְאֶת־הָ֣אֱמֹרִ֔י וְאֵ֖ת הַגִּרְגָּשִֽׁי׃"
          },
          {
            "num": "יז",
            "txt": "וְאֶת־הַֽחִוִּ֥י וְאֶת־הַֽעַרְקִ֖י וְאֶת־הַסִּינִֽי׃"
          },
          {
            "num": "יח",
            "txt": "וְאֶת־הָֽאַרְוָדִ֥י וְאֶת־הַצְּמָרִ֖י וְאֶת־הַֽחֲמָתִ֑י וְאַחַ֣ר נָפֹ֔צוּ מִשְׁפְּח֖וֹת הַֽכְּנַעֲנִֽי׃"
          },
          {
            "num": "יט",
            "txt": "וַיְהִ֞י גְּב֤וּל הַֽכְּנַעֲנִי֙ מִצִּידֹ֔ן בֹּֽאֲכָ֥ה גְרָ֖רָה עַד־עַזָּ֑ה בֹּֽאֲכָ֞ה סְדֹ֧מָה וַֽעֲמֹרָ֛ה וְאַדְמָ֥ה וּצְבֹיִ֖ם עַד־לָֽשַׁע׃"
          },
          {
            "num": "כ",
            "txt": "אֵ֣לֶּה בְנֵי־חָ֔ם לְמִשְׁפְּחֹתָ֖ם לִלְשֹֽׁנֹתָ֑ם בְּאַרְצֹתָ֖ם בְּגֽוֹיֵהֶֽם׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "וּלְשֵׁ֥ם יֻלַּ֖ד גַּם־ה֑וּא אֲבִי֙ כָּל־בְּנֵי־עֵ֔בֶר אֲחִ֖י יֶ֥פֶת הַגָּדֽוֹל׃"
          },
          {
            "num": "כב",
            "txt": "בְּנֵ֥י שֵׁ֖ם עֵילָ֣ם וְאַשּׁ֑וּר וְאַרְפַּכְשַׁ֖ד וְל֥וּד וַֽאֲרָֽם׃"
          },
          {
            "num": "כג",
            "txt": "וּבְנֵ֖י אֲרָ֑ם ע֥וּץ וְח֖וּל וְגֶ֥תֶר וָמַֽשׁ׃"
          },
          {
            "num": "כד",
            "txt": "וְאַרְפַּכְשַׁ֖ד יָלַ֣ד אֶת־שָׁ֑לַח וְשֶׁ֖לַח יָלַ֥ד אֶת־עֵֽבֶר׃"
          },
          {
            "num": "כה",
            "txt": "וּלְעֵ֥בֶר יֻלַּ֖ד שְׁנֵ֣י בָנִ֑ים שֵׁ֣ם הָֽאֶחָ֞ד פֶּ֗לֶג כִּ֤י בְיָמָיו֙ נִפְלְגָ֣ה הָאָ֔רֶץ וְשֵׁ֥ם אָחִ֖יו יָקְטָֽן׃"
          },
          {
            "num": "כו",
            "txt": "וְיָקְטָ֣ן יָלַ֔ד אֶת־אַלְמוֹדָ֖ד וְאֶת־שָׁ֑לֶף וְאֶת־חֲצַרְמָ֖וֶת וְאֶת־יָֽרַח׃"
          },
          {
            "num": "כז",
            "txt": "וְאֶת־הֲדוֹרָ֥ם וְאֶת־אוּזָ֖ל וְאֶת־דִּקְלָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וְאֶת־עוֹבָ֥ל וְאֶת־אֲבִֽימָאֵ֖ל וְאֶת־שְׁבָֽא׃"
          },
          {
            "num": "כט",
            "txt": "וְאֶת־אוֹפִ֥ר וְאֶת־חֲוִילָ֖ה וְאֶת־יוֹבָ֑ב כָּל־אֵ֖לֶּה בְּנֵ֥י יָקְטָֽן׃"
          },
          {
            "num": "ל",
            "txt": "וַיְהִ֥י מֽוֹשָׁבָ֖ם מִמֵּשָׁ֑א בֹּֽאֲכָ֥ה סְפָ֖רָה הַ֥ר הַקֶּֽדֶם׃"
          },
          {
            "num": "לא",
            "txt": "אֵ֣לֶּה בְנֵי־שֵׁ֔ם לְמִשְׁפְּחֹתָ֖ם לִלְשֹֽׁנֹתָ֑ם בְּאַרְצֹתָ֖ם לְגֽוֹיֵהֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "אֵ֣לֶּה מִשְׁפְּחֹ֧ת בְּנֵי־נֹ֛חַ לְתֽוֹלְדֹתָ֖ם בְּגֽוֹיֵהֶ֑ם וּמֵאֵ֜לֶּה נִפְרְד֧וּ הַגּוֹיִ֛ם בָּאָ֖רֶץ אַחַ֥ר הַמַּבּֽוּל׃ {פ}"
          }
        ]
      },
      {
        "num": "יא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֥י כָל־הָאָ֖רֶץ שָׂפָ֣ה אֶחָ֑ת וּדְבָרִ֖ים אֲחָדִֽים׃"
          },
          {
            "num": "ב",
            "txt": "וַיְהִ֖י בְּנָסְעָ֣ם מִקֶּ֑דֶם וַֽיִּמְצְא֥וּ בִקְעָ֛ה בְּאֶ֥רֶץ שִׁנְעָ֖ר וַיֵּ֥שְׁבוּ שָֽׁם׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּֽאמְר֞וּ אִ֣ישׁ אֶל־רֵעֵ֗הוּ הָ֚בָה נִלְבְּנָ֣ה לְבֵנִ֔ים וְנִשְׂרְפָ֖ה לִשְׂרֵפָ֑ה וַתְּהִ֨י לָהֶ֤ם הַלְּבֵנָה֙ לְאָ֔בֶן וְהַ֣חֵמָ֔ר הָיָ֥ה לָהֶ֖ם לַחֹֽמֶר׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּֽאמְר֞וּ הָ֣בָה ׀ נִבְנֶה־לָּ֣נוּ עִ֗יר וּמִגְדָּל֙ וְרֹאשׁ֣וֹ בַשָּׁמַ֔יִם וְנַֽעֲשֶׂה־לָּ֖נוּ שֵׁ֑ם פֶּן־נָפ֖וּץ עַל־פְּנֵ֥י כָל־הָאָֽרֶץ׃"
          },
          {
            "num": "ה",
            "txt": "וַיֵּ֣רֶד יְהוָ֔ה לִרְאֹ֥ת אֶת־הָעִ֖יר וְאֶת־הַמִּגְדָּ֑ל אֲשֶׁ֥ר בָּנ֖וּ בְּנֵ֥י הָֽאָדָֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֣אמֶר יְהוָ֗ה הֵ֣ן עַ֤ם אֶחָד֙ וְשָׂפָ֤ה אַחַת֙ לְכֻלָּ֔ם וְזֶ֖ה הַֽחִלָּ֣ם לַֽעֲשׂ֑וֹת וְעַתָּה֙ לֹֽא־יִבָּצֵ֣ר מֵהֶ֔ם כֹּ֛ל אֲשֶׁ֥ר יָֽזְמ֖וּ לַֽעֲשֽׂוֹת׃"
          },
          {
            "num": "ז",
            "txt": "הָ֚בָה נֵֽרְדָ֔ה וְנָֽבְלָ֥ה שָׁ֖ם שְׂפָתָ֑ם אֲשֶׁר֙ לֹ֣א יִשְׁמְע֔וּ אִ֖ישׁ שְׂפַ֥ת רֵעֵֽהוּ׃"
          },
          {
            "num": "ח",
            "txt": "וַיָּ֨פֶץ יְהוָ֥ה אֹתָ֛ם מִשָּׁ֖ם עַל־פְּנֵ֣י כָל־הָאָ֑רֶץ וַֽיַּחְדְּל֖וּ לִבְנֹ֥ת הָעִֽיר׃"
          },
          {
            "num": "ט",
            "txt": "עַל־כֵּ֞ן קָרָ֤א שְׁמָהּ֙ בָּבֶ֔ל כִּי־שָׁ֛ם בָּלַ֥ל יְהוָ֖ה שְׂפַ֣ת כָּל־הָאָ֑רֶץ וּמִשָּׁם֙ הֱפִיצָ֣ם יְהוָ֔ה עַל־פְּנֵ֖י כָּל־הָאָֽרֶץ׃ {פ}"
          },
          {
            "num": "י",
            "txt": "אֵ֚לֶּה תּֽוֹלְדֹ֣ת שֵׁ֔ם שֵׁ֚ם בֶּן־מְאַ֣ת שָׁנָ֔ה וַיּ֖וֹלֶד אֶת־אַרְפַּכְשָׁ֑ד שְׁנָתַ֖יִם אַחַ֥ר הַמַּבּֽוּל׃"
          },
          {
            "num": "יא",
            "txt": "וַֽיְחִי־שֵׁ֗ם אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־אַרְפַּכְשָׁ֔ד חֲמֵ֥שׁ מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וְאַרְפַּכְשַׁ֣ד חַ֔י חָמֵ֥שׁ וּשְׁלֹשִׁ֖ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־שָֽׁלַח׃"
          },
          {
            "num": "יג",
            "txt": "וַיְחִ֣י אַרְפַּכְשַׁ֗ד אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־שֶׁ֔לַח שָׁלֹ֣שׁ שָׁנִ֔ים וְאַרְבַּ֥ע מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "וְשֶׁ֥לַח חַ֖י שְׁלֹשִׁ֣ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־עֵֽבֶר׃"
          },
          {
            "num": "טו",
            "txt": "וַֽיְחִי־שֶׁ֗לַח אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־עֵ֔בֶר שָׁלֹ֣שׁ שָׁנִ֔ים וְאַרְבַּ֥ע מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "וַֽיְחִי־עֵ֕בֶר אַרְבַּ֥ע וּשְׁלֹשִׁ֖ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־פָּֽלֶג׃"
          },
          {
            "num": "יז",
            "txt": "וַֽיְחִי־עֵ֗בֶר אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־פֶּ֔לֶג שְׁלֹשִׁ֣ים שָׁנָ֔ה וְאַרְבַּ֥ע מֵא֖וֹת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "וַֽיְחִי־פֶ֖לֶג שְׁלֹשִׁ֣ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־רְעֽוּ׃"
          },
          {
            "num": "יט",
            "txt": "וַֽיְחִי־פֶ֗לֶג אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־רְע֔וּ תֵּ֥שַׁע שָׁנִ֖ים וּמָאתַ֣יִם שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וַיְחִ֣י רְע֔וּ שְׁתַּ֥יִם וּשְׁלֹשִׁ֖ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־שְׂרֽוּג׃"
          },
          {
            "num": "כא",
            "txt": "וַיְחִ֣י רְע֗וּ אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־שְׂר֔וּג שֶׁ֥בַע שָׁנִ֖ים וּמָאתַ֣יִם שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "וַיְחִ֥י שְׂר֖וּג שְׁלֹשִׁ֣ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־נָחֽוֹר׃"
          },
          {
            "num": "כג",
            "txt": "וַיְחִ֣י שְׂר֗וּג אַֽחֲרֵ֛י הֽוֹלִיד֥וֹ אֶת־נָח֖וֹר מָאתַ֣יִם שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃ {ס}"
          },
          {
            "num": "כד",
            "txt": "וַיְחִ֣י נָח֔וֹר תֵּ֥שַׁע וְעֶשְׂרִ֖ים שָׁנָ֑ה וַיּ֖וֹלֶד אֶת־תָּֽרַח׃"
          },
          {
            "num": "כה",
            "txt": "וַיְחִ֣י נָח֗וֹר אַֽחֲרֵי֙ הֽוֹלִיד֣וֹ אֶת־תֶּ֔רַח תְּשַֽׁע־עֶשְׂרֵ֥ה שָׁנָ֖ה וּמְאַ֣ת שָׁנָ֑ה וַיּ֥וֹלֶד בָּנִ֖ים וּבָנֽוֹת׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "וַֽיְחִי־תֶ֖רַח שִׁבְעִ֣ים שָׁנָ֑ה וַיּ֨וֹלֶד֙ אֶת־אַבְרָ֔ם אֶת־נָח֖וֹר וְאֶת־הָרָֽן׃"
          },
          {
            "num": "כז",
            "txt": "וְאֵ֨לֶּה֙ תּֽוֹלְדֹ֣ת תֶּ֔רַח תֶּ֚רַח הוֹלִ֣יד אֶת־אַבְרָ֔ם אֶת־נָח֖וֹר וְאֶת־הָרָ֑ן וְהָרָ֖ן הוֹלִ֥יד אֶת־לֽוֹט׃"
          },
          {
            "num": "כח",
            "txt": "וַיָּ֣מָת הָרָ֔ן עַל־פְּנֵ֖י תֶּ֣רַח אָבִ֑יו בְּאֶ֥רֶץ מֽוֹלַדְתּ֖וֹ בְּא֥וּר כַּשְׂדִּֽים׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּקַּ֨ח אַבְרָ֧ם וְנָח֛וֹר לָהֶ֖ם נָשִׁ֑ים שֵׁ֤ם אֵֽשֶׁת־אַבְרָם֙ שָׂרָ֔י וְשֵׁ֤ם אֵֽשֶׁת־נָחוֹר֙ מִלְכָּ֔ה בַּת־הָרָ֥ן אֲבִֽי־מִלְכָּ֖ה וַֽאֲבִ֥י יִסְכָּֽה׃"
          },
          {
            "num": "ל",
            "txt": "וַתְּהִ֥י שָׂרַ֖י עֲקָרָ֑ה אֵ֥ין לָ֖הּ וָלָֽד׃"
          },
          {
            "num": "לא",
            "txt": "וַיִּקַּ֨ח תֶּ֜רַח אֶת־אַבְרָ֣ם בְּנ֗וֹ וְאֶת־ל֤וֹט בֶּן־הָרָן֙ בֶּן־בְּנ֔וֹ וְאֵת֙ שָׂרַ֣י כַּלָּת֔וֹ אֵ֖שֶׁת אַבְרָ֣ם בְּנ֑וֹ וַיֵּֽצְא֨וּ אִתָּ֜ם מֵא֣וּר כַּשְׂדִּ֗ים לָלֶ֨כֶת֙ אַ֣רְצָה כְּנַ֔עַן וַיָּבֹ֥אוּ עַד־חָרָ֖ן וַיֵּ֥שְׁבוּ שָֽׁם׃"
          },
          {
            "num": "לב",
            "txt": "וַיִּֽהְי֣וּ יְמֵי־תֶ֔רַח חָמֵ֥שׁ שָׁנִ֖ים וּמָאתַ֣יִם שָׁנָ֑ה וַיָּ֥מָת תֶּ֖רַח בְּחָרָֽן׃ {פ}"
          }
        ]
      },
      {
        "num": "יב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־אַבְרָ֔ם לֶךְ־לְךָ֛ מֵֽאַרְצְךָ֥ וּמִמּֽוֹלַדְתְּךָ֖ וּמִבֵּ֣ית אָבִ֑יךָ אֶל־הָאָ֖רֶץ אֲשֶׁ֥ר אַרְאֶֽךָּ׃"
          },
          {
            "num": "ב",
            "txt": "וְאֶֽעֶשְׂךָ֙ לְג֣וֹי גָּד֔וֹל וַאֲבָ֣רֶכְךָ֔ וַֽאֲגַדְּלָ֖ה שְׁמֶ֑ךָ וֶֽהְיֵ֖ה בְּרָכָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַאֲבָֽרְכָה֙ מְבָ֣רְכֶ֔יךָ וּמְקַלֶּלְךָ֖ אָאֹ֑ר וְנִבְרְכ֣וּ בְךָ֔ כֹּ֖ל מִשְׁפְּחֹ֥ת הָֽאֲדָמָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וַיֵּ֣לֶךְ אַבְרָ֗ם כַּֽאֲשֶׁ֨ר דִּבֶּ֤ר אֵלָיו֙ יְהוָ֔ה וַיֵּ֥לֶךְ אִתּ֖וֹ ל֑וֹט וְאַבְרָ֗ם בֶּן־חָמֵ֤שׁ שָׁנִים֙ וְשִׁבְעִ֣ים שָׁנָ֔ה בְּצֵאת֖וֹ מֵֽחָרָֽן׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּקַּ֣ח אַבְרָם֩ אֶת־שָׂרַ֨י אִשְׁתּ֜וֹ וְאֶת־ל֣וֹט בֶּן־אָחִ֗יו וְאֶת־כָּל־רְכוּשָׁם֙ אֲשֶׁ֣ר רָכָ֔שׁוּ וְאֶת־הַנֶּ֖פֶשׁ אֲשֶׁר־עָשׂ֣וּ בְחָרָ֑ן וַיֵּֽצְא֗וּ לָלֶ֨כֶת֙ אַ֣רְצָה כְּנַ֔עַן וַיָּבֹ֖אוּ אַ֥רְצָה כְּנָֽעַן׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּֽעֲבֹ֤ר אַבְרָם֙ בָּאָ֔רֶץ עַ֚ד מְק֣וֹם שְׁכֶ֔ם עַ֖ד אֵל֣וֹן מוֹרֶ֑ה וְהַֽכְּנַעֲנִ֖י אָ֥ז בָּאָֽרֶץ׃"
          },
          {
            "num": "ז",
            "txt": "וַיֵּרָ֤א יְהוָה֙ אֶל־אַבְרָ֔ם וַיֹּ֕אמֶר לְזַ֨רְעֲךָ֔ אֶתֵּ֖ן אֶת־הָאָ֣רֶץ הַזֹּ֑את וַיִּ֤בֶן שָׁם֙ מִזְבֵּ֔חַ לַֽיהוָ֖ה הַנִּרְאֶ֥ה אֵלָֽיו׃"
          },
          {
            "num": "ח",
            "txt": "וַיַּעְתֵּ֨ק מִשָּׁ֜ם הָהָ֗רָה מִקֶּ֛דֶם לְבֵֽית־אֵ֖ל וַיֵּ֣ט אָֽהֳלֹ֑ה בֵּֽית־אֵ֤ל מִיָּם֙ וְהָעַ֣י מִקֶּ֔דֶם וַיִּֽבֶן־שָׁ֤ם מִזְבֵּ֨חַ֙ לַֽיהוָ֔ה וַיִּקְרָ֖א בְּשֵׁ֥ם יְהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּסַּ֣ע אַבְרָ֔ם הָל֥וֹךְ וְנָס֖וֹעַ הַנֶּֽגְבָּה׃ {פ}"
          },
          {
            "num": "י",
            "txt": "וַיְהִ֥י רָעָ֖ב בָּאָ֑רֶץ וַיֵּ֨רֶד אַבְרָ֤ם מִצְרַ֨יְמָה֙ לָג֣וּר שָׁ֔ם כִּֽי־כָבֵ֥ד הָֽרָעָ֖ב בָּאָֽרֶץ׃"
          },
          {
            "num": "יא",
            "txt": "וַיְהִ֕י כַּֽאֲשֶׁ֥ר הִקְרִ֖יב לָב֣וֹא מִצְרָ֑יְמָה וַיֹּ֨אמֶר֙ אֶל־שָׂרַ֣י אִשְׁתּ֔וֹ הִנֵּה־נָ֣א יָדַ֔עְתִּי כִּ֛י אִשָּׁ֥ה יְפַת־מַרְאֶ֖ה אָֽתְּ׃"
          },
          {
            "num": "יב",
            "txt": "וְהָיָ֗ה כִּֽי־יִרְא֤וּ אֹתָךְ֙ הַמִּצְרִ֔ים וְאָֽמְר֖וּ אִשְׁתּ֣וֹ זֹ֑את וְהָֽרְג֥וּ אֹתִ֖י וְאֹתָ֥ךְ יְחַיּֽוּ׃"
          },
          {
            "num": "יג",
            "txt": "אִמְרִי־נָ֖א אֲחֹ֣תִי אָ֑תְּ לְמַ֨עַן֙ יִֽיטַב־לִ֣י בַֽעֲבוּרֵ֔ךְ וְחָֽיְתָ֥ה נַפְשִׁ֖י בִּגְלָלֵֽךְ׃"
          },
          {
            "num": "יד",
            "txt": "וַיְהִ֕י כְּב֥וֹא אַבְרָ֖ם מִצְרָ֑יְמָה וַיִּרְא֤וּ הַמִּצְרִים֙ אֶת־הָ֣אִשָּׁ֔ה כִּֽי־יָפָ֥ה הִ֖וא מְאֹֽד׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּרְא֤וּ אֹתָהּ֙ שָׂרֵ֣י פַרְעֹ֔ה וַיְהַֽלְל֥וּ אֹתָ֖הּ אֶל־פַּרְעֹ֑ה וַתֻּקַּ֥ח הָֽאִשָּׁ֖ה בֵּ֥ית פַּרְעֹֽה׃"
          },
          {
            "num": "טז",
            "txt": "וּלְאַבְרָ֥ם הֵיטִ֖יב בַּֽעֲבוּרָ֑הּ וַֽיְהִי־ל֤וֹ צֹאן־וּבָקָר֙ וַֽחֲמֹרִ֔ים וַֽעֲבָדִים֙ וּשְׁפָחֹ֔ת וַֽאֲתֹנֹ֖ת וּגְמַלִּֽים׃"
          },
          {
            "num": "יז",
            "txt": "וַיְנַגַּ֨ע יְהוָ֧ה ׀ אֶת־פַּרְעֹ֛ה נְגָעִ֥ים גְּדֹלִ֖ים וְאֶת־בֵּית֑וֹ עַל־דְּבַ֥ר שָׂרַ֖י אֵ֥שֶׁת אַבְרָֽם׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּקְרָ֤א פַרְעֹה֙ לְאַבְרָ֔ם וַיֹּ֕אמֶר מַה־זֹּ֖את עָשִׂ֣יתָ לִּ֑י לָ֚מָּה לֹֽא־הִגַּ֣דְתָּ לִּ֔י כִּ֥י אִשְׁתְּךָ֖ הִֽוא׃"
          },
          {
            "num": "יט",
            "txt": "לָמָ֤ה אָמַ֨רְתָּ֙ אֲחֹ֣תִי הִ֔וא וָֽאֶקַּ֥ח אֹתָ֛הּ לִ֖י לְאִשָּׁ֑ה וְעַתָּ֕ה הִנֵּ֥ה אִשְׁתְּךָ֖ קַ֥ח וָלֵֽךְ׃"
          },
          {
            "num": "כ",
            "txt": "וַיְצַ֥ו עָלָ֛יו פַּרְעֹ֖ה אֲנָשִׁ֑ים וַֽיְשַׁלְּח֥וּ אֹת֛וֹ וְאֶת־אִשְׁתּ֖וֹ וְאֶת־כָּל־אֲשֶׁר־לֽוֹ׃"
          }
        ]
      },
      {
        "num": "יג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּעַל֩ אַבְרָ֨ם מִמִּצְרַ֜יִם ה֠וּא וְאִשְׁתּ֧וֹ וְכָל־אֲשֶׁר־ל֛וֹ וְל֥וֹט עִמּ֖וֹ הַנֶּֽגְבָּה׃"
          },
          {
            "num": "ב",
            "txt": "וְאַבְרָ֖ם כָּבֵ֣ד מְאֹ֑ד בַּמִּקְנֶ֕ה בַּכֶּ֖סֶף וּבַזָּהָֽב׃"
          },
          {
            "num": "ג",
            "txt": "וַיֵּ֨לֶךְ֙ לְמַסָּעָ֔יו מִנֶּ֖גֶב וְעַד־בֵּֽית־אֵ֑ל עַד־הַמָּק֗וֹם אֲשֶׁר־הָ֨יָה שָׁ֤ם אָֽהֳלֹה֙ בַּתְּחִלָּ֔ה בֵּ֥ין בֵּֽית־אֵ֖ל וּבֵ֥ין הָעָֽי׃"
          },
          {
            "num": "ד",
            "txt": "אֶל־מְקוֹם֙ הַמִּזְבֵּ֔חַ אֲשֶׁר־עָ֥שָׂה שָׁ֖ם בָּרִֽאשֹׁנָ֑ה וַיִּקְרָ֥א שָׁ֛ם אַבְרָ֖ם בְּשֵׁ֥ם יְהוָֽה׃"
          },
          {
            "num": "ה",
            "txt": "וְגַ֨ם־לְל֔וֹט הַֽהֹלֵ֖ךְ אֶת־אַבְרָ֑ם הָיָ֥ה צֹאן־וּבָקָ֖ר וְאֹֽהָלִֽים׃"
          },
          {
            "num": "ו",
            "txt": "וְלֹֽא־נָשָׂ֥א אֹתָ֛ם הָאָ֖רֶץ לָשֶׁ֣בֶת יַחְדָּ֑ו כִּֽי־הָיָ֤ה רְכוּשָׁם֙ רָ֔ב וְלֹ֥א יָֽכְל֖וּ לָשֶׁ֥בֶת יַחְדָּֽו׃"
          },
          {
            "num": "ז",
            "txt": "וַֽיְהִי־רִ֗יב בֵּ֚ין רֹעֵ֣י מִקְנֵֽה־אַבְרָ֔ם וּבֵ֖ין רֹעֵ֣י מִקְנֵה־ל֑וֹט וְהַֽכְּנַעֲנִי֙ וְהַפְּרִזִּ֔י אָ֖ז יֹשֵׁ֥ב בָּאָֽרֶץ׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֨אמֶר אַבְרָ֜ם אֶל־ל֗וֹט אַל־נָ֨א תְהִ֤י מְרִיבָה֙ בֵּינִ֣י וּבֵינֶ֔ךָ וּבֵ֥ין רֹעַ֖י וּבֵ֣ין רֹעֶ֑יךָ כִּֽי־אֲנָשִׁ֥ים אַחִ֖ים אֲנָֽחְנוּ׃"
          },
          {
            "num": "ט",
            "txt": "הֲלֹ֤א כָל־הָאָ֨רֶץ֙ לְפָנֶ֔יךָ הִפָּ֥רֶד נָ֖א מֵֽעָלָ֑י אִם־הַשְּׂמֹ֣אל וְאֵימִ֔נָה וְאִם־הַיָּמִ֖ין וְאַשְׂמְאִֽילָה׃"
          },
          {
            "num": "י",
            "txt": "וַיִּשָּׂא־ל֣וֹט אֶת־עֵינָ֗יו וַיַּרְא֙ אֶת־כָּל־כִּכַּ֣ר הַיַּרְדֵּ֔ן כִּ֥י כֻלָּ֖הּ מַשְׁקֶ֑ה לִפְנֵ֣י ׀ שַׁחֵ֣ת יְהוָ֗ה אֶת־סְדֹם֙ וְאֶת־עֲמֹרָ֔ה כְּגַן־יְהוָה֙ כְּאֶ֣רֶץ מִצְרַ֔יִם בֹּֽאֲכָ֖ה צֹֽעַר׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּבְחַר־ל֣וֹ ל֗וֹט אֵ֚ת כָּל־כִּכַּ֣ר הַיַּרְדֵּ֔ן וַיִּסַּ֥ע ל֖וֹט מִקֶּ֑דֶם וַיִּפָּ֣רְד֔וּ אִ֖ישׁ מֵעַ֥ל אָחִֽיו׃"
          },
          {
            "num": "יב",
            "txt": "אַבְרָ֖ם יָשַׁ֣ב בְּאֶֽרֶץ־כְּנָ֑עַן וְל֗וֹט יָשַׁב֙ בְּעָרֵ֣י הַכִּכָּ֔ר וַיֶּֽאֱהַ֖ל עַד־סְדֹֽם׃"
          },
          {
            "num": "יג",
            "txt": "וְאַנְשֵׁ֣י סְדֹ֔ם רָעִ֖ים וְחַטָּאִ֑ים לַֽיהוָ֖ה מְאֹֽד׃"
          },
          {
            "num": "יד",
            "txt": "וַֽיהוָ֞ה אָמַ֣ר אֶל־אַבְרָ֗ם אַֽחֲרֵי֙ הִפָּֽרֶד־ל֣וֹט מֵֽעִמּ֔וֹ שָׂ֣א נָ֤א עֵינֶ֨יךָ֙ וּרְאֵ֔ה מִן־הַמָּק֖וֹם אֲשֶׁר־אַתָּ֣ה שָׁ֑ם צָפֹ֥נָה וָנֶ֖גְבָּה וָקֵ֥דְמָה וָיָֽמָּה׃"
          },
          {
            "num": "טו",
            "txt": "כִּ֧י אֶת־כָּל־הָאָ֛רֶץ אֲשֶׁר־אַתָּ֥ה רֹאֶ֖ה לְךָ֣ אֶתְּנֶ֑נָּה וּֽלְזַרְעֲךָ֖ עַד־עוֹלָֽם׃"
          },
          {
            "num": "טז",
            "txt": "וְשַׂמְתִּ֥י אֶֽת־זַרְעֲךָ֖ כַּֽעֲפַ֣ר הָאָ֑רֶץ אֲשֶׁ֣ר ׀ אִם־יוּכַ֣ל אִ֗ישׁ לִמְנוֹת֙ אֶת־עֲפַ֣ר הָאָ֔רֶץ גַּֽם־זַרְעֲךָ֖ יִמָּנֶֽה׃"
          },
          {
            "num": "יז",
            "txt": "ק֚וּם הִתְהַלֵּ֣ךְ בָּאָ֔רֶץ לְאָרְכָּ֖הּ וּלְרָחְבָּ֑הּ כִּ֥י לְךָ֖ אֶתְּנֶֽנָּה׃"
          },
          {
            "num": "יח",
            "txt": "וַיֶּֽאֱהַ֣ל אַבְרָ֗ם וַיָּבֹ֛א וַיֵּ֛שֶׁב בְּאֵֽלֹנֵ֥י מַמְרֵ֖א אֲשֶׁ֣ר בְּחֶבְר֑וֹן וַיִּֽבֶן־שָׁ֥ם מִזְבֵּ֖חַ לַֽיהוָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "יד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֗י בִּימֵי֙ אַמְרָפֶ֣ל מֶֽלֶךְ־שִׁנְעָ֔ר אַרְי֖וֹךְ מֶ֣לֶךְ אֶלָּסָ֑ר כְּדָרְלָעֹ֨מֶר֙ מֶ֣לֶךְ עֵילָ֔ם וְתִדְעָ֖ל מֶ֥לֶךְ גּוֹיִֽם׃"
          },
          {
            "num": "ב",
            "txt": "עָשׂ֣וּ מִלְחָמָ֗ה אֶת־בֶּ֨רַע֙ מֶ֣לֶךְ סְדֹ֔ם וְאֶת־בִּרְשַׁ֖ע מֶ֣לֶךְ עֲמֹרָ֑ה שִׁנְאָ֣ב ׀ מֶ֣לֶךְ אַדְמָ֗ה וְשֶׁמְאֵ֨בֶר֙ מֶ֣לֶךְ צְבֹיִ֔ים וּמֶ֥לֶךְ בֶּ֖לַע הִיא־צֹֽעַר׃"
          },
          {
            "num": "ג",
            "txt": "כָּל־אֵ֨לֶּה֙ חָֽבְר֔וּ אֶל־עֵ֖מֶק הַשִּׂדִּ֑ים ה֖וּא יָ֥ם הַמֶּֽלַח׃"
          },
          {
            "num": "ד",
            "txt": "שְׁתֵּ֤ים עֶשְׂרֵה֙ שָׁנָ֔ה עָֽבְד֖וּ אֶת־כְּדָרְלָעֹ֑מֶר וּשְׁלֹשׁ־עֶשְׂרֵ֥ה שָׁנָ֖ה מָרָֽדוּ׃"
          },
          {
            "num": "ה",
            "txt": "וּבְאַרְבַּע֩ עֶשְׂרֵ֨ה שָׁנָ֜ה בָּ֣א כְדָרְלָעֹ֗מֶר וְהַמְּלָכִים֙ אֲשֶׁ֣ר אִתּ֔וֹ וַיַּכּ֤וּ אֶת־רְפָאִים֙ בְּעַשְׁתְּרֹ֣ת קַרְנַ֔יִם וְאֶת־הַזּוּזִ֖ים בְּהָ֑ם וְאֵת֙ הָֽאֵימִ֔ים בְּשָׁוֵ֖ה קִרְיָתָֽיִם׃"
          },
          {
            "num": "ו",
            "txt": "וְאֶת־הַֽחֹרִ֖י בְּהַרְרָ֣ם שֵׂעִ֑יר עַ֚ד אֵ֣יל פָּארָ֔ן אֲשֶׁ֖ר עַל־הַמִּדְבָּֽר׃"
          },
          {
            "num": "ז",
            "txt": "וַ֠יָּשֻׁבוּ וַיָּבֹ֜אוּ אֶל־עֵ֤ין מִשְׁפָּט֙ הִ֣וא קָדֵ֔שׁ וַיַּכּ֕וּ אֶֽת־כָּל־שְׂדֵ֖ה הָעֲמָֽלֵקִ֑י וְגַם֙ אֶת־הָ֣אֱמֹרִ֔י הַיֹּשֵׁ֖ב בְּחַֽצְצֹ֥ן תָּמָֽר׃"
          },
          {
            "num": "ח",
            "txt": "וַיֵּצֵ֨א מֶֽלֶךְ־סְדֹ֜ם וּמֶ֣לֶךְ עֲמֹרָ֗ה וּמֶ֤לֶךְ אַדְמָה֙ וּמֶ֣לֶךְ צְבֹיִ֔ים וּמֶ֥לֶךְ בֶּ֖לַע הִוא־צֹ֑עַר וַיַּֽעַרְכ֤וּ אִתָּם֙ מִלְחָמָ֔ה בְּעֵ֖מֶק הַשִּׂדִּֽים׃"
          },
          {
            "num": "ט",
            "txt": "אֵ֣ת כְּדָרְלָעֹ֜מֶר מֶ֣לֶךְ עֵילָ֗ם וְתִדְעָל֙ מֶ֣לֶךְ גּוֹיִ֔ם וְאַמְרָפֶל֙ מֶ֣לֶךְ שִׁנְעָ֔ר וְאַרְי֖וֹךְ מֶ֣לֶךְ אֶלָּסָ֑ר אַרְבָּעָ֥ה מְלָכִ֖ים אֶת־הַֽחֲמִשָּֽׁה׃"
          },
          {
            "num": "י",
            "txt": "וְעֵ֣מֶק הַשִּׂדִּ֗ים בֶּֽאֱרֹ֤ת בֶּֽאֱרֹת֙ חֵמָ֔ר וַיָּנֻ֛סוּ מֶֽלֶךְ־סְדֹ֥ם וַֽעֲמֹרָ֖ה וַיִּפְּלוּ־שָׁ֑מָּה וְהַנִּשְׁאָרִ֖ים הֶ֥רָה נָּֽסוּ׃"
          },
          {
            "num": "יא",
            "txt": "וַ֠יִּקְחוּ אֶת־כָּל־רְכֻ֨שׁ סְדֹ֧ם וַֽעֲמֹרָ֛ה וְאֶת־כָּל־אָכְלָ֖ם וַיֵּלֵֽכוּ׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּקְח֨וּ אֶת־ל֧וֹט וְאֶת־רְכֻשׁ֛וֹ בֶּן־אֲחִ֥י אַבְרָ֖ם וַיֵּלֵ֑כוּ וְה֥וּא יֹשֵׁ֖ב בִּסְדֹֽם׃"
          },
          {
            "num": "יג",
            "txt": "וַיָּבֹא֙ הַפָּלִ֔יט וַיַּגֵּ֖ד לְאַבְרָ֣ם הָֽעִבְרִ֑י וְהוּא֩ שֹׁכֵ֨ן בְּאֵֽלֹנֵ֜י מַמְרֵ֣א הָֽאֱמֹרִ֗י אֲחִ֤י אֶשְׁכֹּל֙ וַֽאֲחִ֣י עָנֵ֔ר וְהֵ֖ם בַּֽעֲלֵ֥י בְרִית־אַבְרָֽם׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּשְׁמַ֣ע אַבְרָ֔ם כִּ֥י נִשְׁבָּ֖ה אָחִ֑יו וַיָּ֨רֶק אֶת־חֲנִיכָ֜יו יְלִידֵ֣י בֵית֗וֹ שְׁמֹנָ֤ה עָשָׂר֙ וּשְׁלֹ֣שׁ מֵא֔וֹת וַיִּרְדֹּ֖ף עַד־דָּֽן׃"
          },
          {
            "num": "טו",
            "txt": "וַיֵּֽחָלֵ֨ק עֲלֵיהֶ֧ם ׀ לַ֛יְלָה ה֥וּא וַֽעֲבָדָ֖יו וַיַּכֵּ֑ם וַֽיִּרְדְּפֵם֙ עַד־חוֹבָ֔ה אֲשֶׁ֥ר מִשְּׂמֹ֖אל לְדַמָּֽשֶׂק׃"
          },
          {
            "num": "טז",
            "txt": "וַיָּ֕שֶׁב אֵ֖ת כָּל־הָֽרְכֻ֑שׁ וְגַם֩ אֶת־ל֨וֹט אָחִ֤יו וּרְכֻשׁוֹ֙ הֵשִׁ֔יב וְגַ֥ם אֶת־הַנָּשִׁ֖ים וְאֶת־הָעָֽם׃"
          },
          {
            "num": "יז",
            "txt": "וַיֵּצֵ֣א מֶֽלֶךְ־סְדֹם֮ לִקְרָאתוֹ֒ אַֽחֲרֵ֣י שׁוּב֗וֹ מֵֽהַכּוֹת֙ אֶת־כְּדָרְלָעֹ֔מֶר וְאֶת־הַמְּלָכִ֖ים אֲשֶׁ֣ר אִתּ֑וֹ אֶל־עֵ֣מֶק שָׁוֵ֔ה ה֖וּא עֵ֥מֶק הַמֶּֽלֶךְ׃"
          },
          {
            "num": "יח",
            "txt": "וּמַלְכִּי־צֶ֨דֶק֙ מֶ֣לֶךְ שָׁלֵ֔ם הוֹצִ֖יא לֶ֣חֶם וָיָ֑יִן וְה֥וּא כֹהֵ֖ן לְאֵ֥ל עֶלְיֽוֹן׃"
          },
          {
            "num": "יט",
            "txt": "וַֽיְבָרְכֵ֖הוּ וַיֹּאמַ֑ר בָּר֤וּךְ אַבְרָם֙ לְאֵ֣ל עֶלְי֔וֹן קֹנֵ֖ה שָׁמַ֥יִם וָאָֽרֶץ׃"
          },
          {
            "num": "כ",
            "txt": "וּבָרוּךְ֙ אֵ֣ל עֶלְי֔וֹן אֲשֶׁר־מִגֵּ֥ן צָרֶ֖יךָ בְּיָדֶ֑ךָ וַיִּתֶּן־ל֥וֹ מַֽעֲשֵׂ֖ר מִכֹּֽל׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֥אמֶר מֶֽלֶךְ־סְדֹ֖ם אֶל־אַבְרָ֑ם תֶּן־לִ֣י הַנֶּ֔פֶשׁ וְהָֽרְכֻ֖שׁ קַֽח־לָֽךְ׃"
          },
          {
            "num": "כב",
            "txt": "וַיֹּ֥אמֶר אַבְרָ֖ם אֶל־מֶ֣לֶךְ סְדֹ֑ם הֲרִמֹ֨תִי יָדִ֤י אֶל־יְהוָה֙ אֵ֣ל עֶלְי֔וֹן קֹנֵ֖ה שָׁמַ֥יִם וָאָֽרֶץ׃"
          },
          {
            "num": "כג",
            "txt": "אִם־מִחוּט֙ וְעַ֣ד שְׂרֽוֹךְ־נַ֔עַל וְאִם־אֶקַּ֖ח מִכָּל־אֲשֶׁר־לָ֑ךְ וְלֹ֣א תֹאמַ֔ר אֲנִ֖י הֶֽעֱשַׁ֥רְתִּי אֶת־אַבְרָֽם׃"
          },
          {
            "num": "כד",
            "txt": "בִּלְעָדַ֗י רַ֚ק אֲשֶׁ֣ר אָֽכְל֣וּ הַנְּעָרִ֔ים וְחֵ֨לֶק֙ הָֽאֲנָשִׁ֔ים אֲשֶׁ֥ר הָֽלְכ֖וּ אִתִּ֑י עָנֵר֙ אֶשְׁכֹּ֣ל וּמַמְרֵ֔א הֵ֖ם יִקְח֥וּ חֶלְקָֽם׃ {ס}"
          }
        ]
      },
      {
        "num": "טו",
        "verses": [
          {
            "num": "א",
            "txt": "אַחַ֣ר ׀ הַדְּבָרִ֣ים הָאֵ֗לֶּה הָיָ֤ה דְבַר־יְהוָה֙ אֶל־אַבְרָ֔ם בַּֽמַּחֲזֶ֖ה לֵאמֹ֑ר אַל־תִּירָ֣א אַבְרָ֗ם אָֽנֹכִי֙ מָגֵ֣ן לָ֔ךְ שְׂכָֽרְךָ֖ הַרְבֵּ֥ה מְאֹֽד׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֣אמֶר אַבְרָ֗ם אֲדֹנָ֤י יְהוִה֙ מַה־תִּתֶּן־לִ֔י וְאָֽנֹכִ֖י הוֹלֵ֣ךְ עֲרִירִ֑י וּבֶן־מֶ֣שֶׁק בֵּיתִ֔י ה֖וּא דַּמֶּ֥שֶׂק אֱלִיעֶֽזֶר׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֣אמֶר אַבְרָ֔ם הֵ֣ן לִ֔י לֹ֥א נָתַ֖תָּה זָ֑רַע וְהִנֵּ֥ה בֶן־בֵּיתִ֖י יוֹרֵ֥שׁ אֹתִֽי׃"
          },
          {
            "num": "ד",
            "txt": "וְהִנֵּ֨ה דְבַר־יְהוָ֤ה אֵלָיו֙ לֵאמֹ֔ר לֹ֥א יִֽירָשְׁךָ֖ זֶ֑ה כִּי־אִם֙ אֲשֶׁ֣ר יֵצֵ֣א מִמֵּעֶ֔יךָ ה֖וּא יִֽירָשֶֽׁךָ׃"
          },
          {
            "num": "ה",
            "txt": "וַיּוֹצֵ֨א אֹת֜וֹ הַח֗וּצָה וַיֹּ֨אמֶר֙ הַבֶּט־נָ֣א הַשָּׁמַ֗יְמָה וּסְפֹר֙ הַכּ֣וֹכָבִ֔ים אִם־תּוּכַ֖ל לִסְפֹּ֣ר אֹתָ֑ם וַיֹּ֣אמֶר ל֔וֹ כֹּ֥ה יִֽהְיֶ֖ה זַרְעֶֽךָ׃"
          },
          {
            "num": "ו",
            "txt": "וְהֶֽאֱמִ֖ן בַּֽיהוָ֑ה וַיַּחְשְׁבֶ֥הָ לּ֖וֹ צְדָקָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֖אמֶר אֵלָ֑יו אֲנִ֣י יְהוָ֗ה אֲשֶׁ֤ר הֽוֹצֵאתִ֨יךָ֙ מֵא֣וּר כַּשְׂדִּ֔ים לָ֧תֶת לְךָ֛ אֶת־הָאָ֥רֶץ הַזֹּ֖את לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּאמַ֑ר אֲדֹנָ֣י יְהוִ֔ה בַּמָּ֥ה אֵדַ֖ע כִּ֥י אִֽירָשֶֽׁנָּה׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֣אמֶר אֵלָ֗יו קְחָ֥ה לִי֙ עֶגְלָ֣ה מְשֻׁלֶּ֔שֶׁת וְעֵ֥ז מְשֻׁלֶּ֖שֶׁת וְאַ֣יִל מְשֻׁלָּ֑שׁ וְתֹ֖ר וְגוֹזָֽל׃"
          },
          {
            "num": "י",
            "txt": "וַיִּֽקַּֽח־ל֣וֹ אֶת־כָּל־אֵ֗לֶּה וַיְבַתֵּ֤ר אֹתָם֙ בַּתָּ֔וֶךְ וַיִּתֵּ֥ן אִישׁ־בִּתְר֖וֹ לִקְרַ֣את רֵעֵ֑הוּ וְאֶת־הַצִּפֹּ֖ר לֹ֥א בָתָֽר׃"
          },
          {
            "num": "יא",
            "txt": "וַיֵּ֥רֶד הָעַ֖יִט עַל־הַפְּגָרִ֑ים וַיַּשֵּׁ֥ב אֹתָ֖ם אַבְרָֽם׃"
          },
          {
            "num": "יב",
            "txt": "וַיְהִ֤י הַשֶּׁ֨מֶשׁ֙ לָב֔וֹא וְתַרְדֵּמָ֖ה נָֽפְלָ֣ה עַל־אַבְרָ֑ם וְהִנֵּ֥ה אֵימָ֛ה חֲשֵׁכָ֥ה גְדֹלָ֖ה נֹפֶ֥לֶת עָלָֽיו׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֣אמֶר לְאַבְרָ֗ם יָדֹ֨עַ תֵּדַ֜ע כִּי־גֵ֣ר ׀ יִֽהְיֶ֣ה זַרְעֲךָ֗ בְּאֶ֨רֶץ֙ לֹ֣א לָהֶ֔ם וַֽעֲבָד֖וּם וְעִנּ֣וּ אֹתָ֑ם אַרְבַּ֥ע מֵא֖וֹת שָׁנָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְגַ֧ם אֶת־הַגּ֛וֹי אֲשֶׁ֥ר יַֽעֲבֹ֖דוּ דָּ֣ן אָנֹ֑כִי וְאַֽחֲרֵי־כֵ֥ן יֵֽצְא֖וּ בִּרְכֻ֥שׁ גָּדֽוֹל׃"
          },
          {
            "num": "טו",
            "txt": "וְאַתָּ֛ה תָּב֥וֹא אֶל־אֲבֹתֶ֖יךָ בְּשָׁל֑וֹם תִּקָּבֵ֖ר בְּשֵׂיבָ֥ה טוֹבָֽה׃"
          },
          {
            "num": "טז",
            "txt": "וְד֥וֹר רְבִיעִ֖י יָשׁ֣וּבוּ הֵ֑נָּה כִּ֧י לֹֽא־שָׁלֵ֛ם עֲוֺ֥ן הָֽאֱמֹרִ֖י עַד־הֵֽנָּה׃"
          },
          {
            "num": "יז",
            "txt": "וַיְהִ֤י הַשֶּׁ֨מֶשׁ֙ בָּ֔אָה וַֽעֲלָטָ֖ה הָיָ֑ה וְהִנֵּ֨ה תַנּ֤וּר עָשָׁן֙ וְלַפִּ֣יד אֵ֔שׁ אֲשֶׁ֣ר עָבַ֔ר בֵּ֖ין הַגְּזָרִ֥ים הָאֵֽלֶּה׃"
          },
          {
            "num": "יח",
            "txt": "בַּיּ֣וֹם הַה֗וּא כָּרַ֧ת יְהוָ֛ה אֶת־אַבְרָ֖ם בְּרִ֣ית לֵאמֹ֑ר לְזַרְעֲךָ֗ נָתַ֨תִּי֙ אֶת־הָאָ֣רֶץ הַזֹּ֔את מִנְּהַ֣ר מִצְרַ֔יִם עַד־הַנָּהָ֥ר הַגָּדֹ֖ל נְהַר־פְּרָֽת׃"
          },
          {
            "num": "יט",
            "txt": "אֶת־הַקֵּינִי֙ וְאֶת־הַקְּנִזִּ֔י וְאֵ֖ת הַקַּדְמֹנִֽי׃"
          },
          {
            "num": "כ",
            "txt": "וְאֶת־הַֽחִתִּ֥י וְאֶת־הַפְּרִזִּ֖י וְאֶת־הָֽרְפָאִֽים׃"
          },
          {
            "num": "כא",
            "txt": "וְאֶת־הָֽאֱמֹרִי֙ וְאֶת־הַֽכְּנַעֲנִ֔י וְאֶת־הַגִּרְגָּשִׁ֖י וְאֶת־הַיְבוּסִֽי׃ {ס}"
          }
        ]
      },
      {
        "num": "טז",
        "verses": [
          {
            "num": "א",
            "txt": "וְשָׂרַי֙ אֵ֣שֶׁת אַבְרָ֔ם לֹ֥א יָֽלְדָ֖ה ל֑וֹ וְלָ֛הּ שִׁפְחָ֥ה מִצְרִ֖ית וּשְׁמָ֥הּ הָגָֽר׃"
          },
          {
            "num": "ב",
            "txt": "וַתֹּ֨אמֶר שָׂרַ֜י אֶל־אַבְרָ֗ם הִנֵּה־נָ֞א עֲצָרַ֤נִי יְהוָה֙ מִלֶּ֔דֶת בֹּא־נָא֙ אֶל־שִׁפְחָתִ֔י אוּלַ֥י אִבָּנֶ֖ה מִמֶּ֑נָּה וַיִּשְׁמַ֥ע אַבְרָ֖ם לְק֥וֹל שָׂרָֽי׃"
          },
          {
            "num": "ג",
            "txt": "וַתִּקַּ֞ח שָׂרַ֣י אֵֽשֶׁת־אַבְרָ֗ם אֶת־הָגָ֤ר הַמִּצְרִית֙ שִׁפְחָתָ֔הּ מִקֵּץ֙ עֶ֣שֶׂר שָׁנִ֔ים לְשֶׁ֥בֶת אַבְרָ֖ם בְּאֶ֣רֶץ כְּנָ֑עַן וַתִּתֵּ֥ן אֹתָ֛הּ לְאַבְרָ֥ם אִישָׁ֖הּ ל֥וֹ לְאִשָּֽׁה׃"
          },
          {
            "num": "ד",
            "txt": "וַיָּבֹ֥א אֶל־הָגָ֖ר וַתַּ֑הַר וַתֵּ֨רֶא֙ כִּ֣י הָרָ֔תָה וַתֵּקַ֥ל גְּבִרְתָּ֖הּ בְּעֵינֶֽיהָ׃"
          },
          {
            "num": "ה",
            "txt": "וַתֹּ֨אמֶר שָׂרַ֣י אֶל־אַבְרָם֮ חֲמָסִ֣י עָלֶיךָ֒ אָֽנֹכִ֗י נָתַ֤תִּי שִׁפְחָתִי֙ בְּחֵיקֶ֔ךָ וַתֵּ֨רֶא֙ כִּ֣י הָרָ֔תָה וָֽאֵקַ֖ל בְּעֵינֶ֑יהָ יִשְׁפֹּ֥ט יְהוָ֖ה בֵּינִ֥י וּבֵינֶֽיׄךָ׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֨אמֶר אַבְרָ֜ם אֶל־שָׂרַ֗י הִנֵּ֤ה שִׁפְחָתֵךְ֙ בְּיָדֵ֔ךְ עֲשִׂי־לָ֖הּ הַטּ֣וֹב בְּעֵינָ֑יִךְ וַתְּעַנֶּ֣הָ שָׂרַ֔י וַתִּבְרַ֖ח מִפָּנֶֽיהָ׃"
          },
          {
            "num": "ז",
            "txt": "וַֽיִּמְצָאָ֞הּ מַלְאַ֧ךְ יְהוָ֛ה עַל־עֵ֥ין הַמַּ֖יִם בַּמִּדְבָּ֑ר עַל־הָעַ֖יִן בְּדֶ֥רֶךְ שֽׁוּר׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּאמַ֗ר הָגָ֞ר שִׁפְחַ֥ת שָׂרַ֛י אֵֽי־מִזֶּ֥ה בָ֖את וְאָ֣נָה תֵלֵ֑כִי וַתֹּ֕אמֶר מִפְּנֵי֙ שָׂרַ֣י גְּבִרְתִּ֔י אָֽנֹכִ֖י בֹּרַֽחַת׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֤אמֶר לָהּ֙ מַלְאַ֣ךְ יְהוָ֔ה שׁ֖וּבִי אֶל־גְּבִרְתֵּ֑ךְ וְהִתְעַנִּ֖י תַּ֥חַת יָדֶֽיהָ׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֤אמֶר לָהּ֙ מַלְאַ֣ךְ יְהוָ֔ה הַרְבָּ֥ה אַרְבֶּ֖ה אֶת־זַרְעֵ֑ךְ וְלֹ֥א יִסָּפֵ֖ר מֵרֹֽב׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֤אמֶר לָהּ֙ מַלְאַ֣ךְ יְהוָ֔ה הִנָּ֥ךְ הָרָ֖ה וְיֹלַ֣דְתְּ בֵּ֑ן וְקָרָ֤את שְׁמוֹ֙ יִשְׁמָעֵ֔אל כִּֽי־שָׁמַ֥ע יְהוָ֖ה אֶל־עָנְיֵֽךְ׃"
          },
          {
            "num": "יב",
            "txt": "וְה֤וּא יִֽהְיֶה֙ פֶּ֣רֶא אָדָ֔ם יָד֣וֹ בַכֹּ֔ל וְיַ֥ד כֹּ֖ל בּ֑וֹ וְעַל־פְּנֵ֥י כָל־אֶחָ֖יו יִשְׁכֹּֽן׃"
          },
          {
            "num": "יג",
            "txt": "וַתִּקְרָ֤א שֵׁם־יְהוָה֙ הַדֹּבֵ֣ר אֵלֶ֔יהָ אַתָּ֖ה אֵ֣ל רֳאִ֑י כִּ֣י אָֽמְרָ֗ה הֲגַ֥ם הֲלֹ֛ם רָאִ֖יתִי אַֽחֲרֵ֥י רֹאִֽי׃"
          },
          {
            "num": "יד",
            "txt": "עַל־כֵּן֙ קָרָ֣א לַבְּאֵ֔ר בְּאֵ֥ר לַחַ֖י רֹאִ֑י הִנֵּ֥ה בֵין־קָדֵ֖שׁ וּבֵ֥ין בָּֽרֶד׃"
          },
          {
            "num": "טו",
            "txt": "וַתֵּ֧לֶד הָגָ֛ר לְאַבְרָ֖ם בֵּ֑ן וַיִּקְרָ֨א אַבְרָ֧ם שֶׁם־בְּנ֛וֹ אֲשֶׁר־יָֽלְדָ֥ה הָגָ֖ר יִשְׁמָעֵֽאל׃"
          },
          {
            "num": "טז",
            "txt": "וְאַבְרָ֕ם בֶּן־שְׁמֹנִ֥ים שָׁנָ֖ה וְשֵׁ֣שׁ שָׁנִ֑ים בְּלֶֽדֶת־הָגָ֥ר אֶת־יִשְׁמָעֵ֖אל לְאַבְרָֽם׃ {ס}"
          }
        ]
      },
      {
        "num": "יז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֣י אַבְרָ֔ם בֶּן־תִּשְׁעִ֥ים שָׁנָ֖ה וְתֵ֣שַׁע שָׁנִ֑ים וַיֵּרָ֨א יְהוָ֜ה אֶל־אַבְרָ֗ם וַיֹּ֤אמֶר אֵלָיו֙ אֲנִי־אֵ֣ל שַׁדַּ֔י הִתְהַלֵּ֥ךְ לְפָנַ֖י וֶֽהְיֵ֥ה תָמִֽים׃"
          },
          {
            "num": "ב",
            "txt": "וְאֶתְּנָ֥ה בְרִיתִ֖י בֵּינִ֣י וּבֵינֶ֑ךָ וְאַרְבֶּ֥ה אֽוֹתְךָ֖ בִּמְאֹ֥ד מְאֹֽד׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּפֹּ֥ל אַבְרָ֖ם עַל־פָּנָ֑יו וַיְדַבֵּ֥ר אִתּ֛וֹ אֱלֹהִ֖ים לֵאמֹֽר׃"
          },
          {
            "num": "ד",
            "txt": "אֲנִ֕י הִנֵּ֥ה בְרִיתִ֖י אִתָּ֑ךְ וְהָיִ֕יתָ לְאַ֖ב הֲמ֥וֹן גּוֹיִֽם׃"
          },
          {
            "num": "ה",
            "txt": "וְלֹֽא־יִקָּרֵ֥א ע֛וֹד אֶת־שִׁמְךָ֖ אַבְרָ֑ם וְהָיָ֤ה שִׁמְךָ֙ אַבְרָהָ֔ם כִּ֛י אַב־הֲמ֥וֹן גּוֹיִ֖ם נְתַתִּֽיךָ׃"
          },
          {
            "num": "ו",
            "txt": "וְהִפְרֵתִ֤י אֹֽתְךָ֙ בִּמְאֹ֣ד מְאֹ֔ד וּנְתַתִּ֖יךָ לְגוֹיִ֑ם וּמְלָכִ֖ים מִמְּךָ֥ יֵצֵֽאוּ׃"
          },
          {
            "num": "ז",
            "txt": "וַהֲקִֽמֹתִ֨י אֶת־בְּרִיתִ֜י בֵּינִ֣י וּבֵינֶ֗ךָ וּבֵ֨ין זַרְעֲךָ֧ אַֽחֲרֶ֛יךָ לְדֹֽרֹתָ֖ם לִבְרִ֣ית עוֹלָ֑ם לִֽהְי֤וֹת לְךָ֙ לֵֽאלֹהִ֔ים וּֽלְזַרְעֲךָ֖ אַֽחֲרֶֽיךָ׃"
          },
          {
            "num": "ח",
            "txt": "וְנָֽתַתִּ֣י לְ֠ךָ וּלְזַרְעֲךָ֨ אַֽחֲרֶ֜יךָ אֵ֣ת ׀ אֶ֣רֶץ מְגֻרֶ֗יךָ אֵ֚ת כָּל־אֶ֣רֶץ כְּנַ֔עַן לַֽאֲחֻזַּ֖ת עוֹלָ֑ם וְהָיִ֥יתִי לָהֶ֖ם לֵֽאלֹהִֽים׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֤אמֶר אֱלֹהִים֙ אֶל־אַבְרָהָ֔ם וְאַתָּ֖ה אֶת־בְּרִיתִ֣י תִשְׁמֹ֑ר אַתָּ֛ה וְזַרְעֲךָ֥ אַֽחֲרֶ֖יךָ לְדֹֽרֹתָֽם׃"
          },
          {
            "num": "י",
            "txt": "זֹ֣את בְּרִיתִ֞י אֲשֶׁ֣ר תִּשְׁמְר֗וּ בֵּינִי֙ וּבֵ֣ינֵיכֶ֔ם וּבֵ֥ין זַרְעֲךָ֖ אַֽחֲרֶ֑יךָ הִמּ֥וֹל לָכֶ֖ם כָּל־זָכָֽר׃"
          },
          {
            "num": "יא",
            "txt": "וּנְמַלְתֶּ֕ם אֵ֖ת בְּשַׂ֣ר עָרְלַתְכֶ֑ם וְהָיָה֙ לְא֣וֹת בְּרִ֔ית בֵּינִ֖י וּבֵֽינֵיכֶֽם׃"
          },
          {
            "num": "יב",
            "txt": "וּבֶן־שְׁמֹנַ֣ת יָמִ֗ים יִמּ֥וֹל לָכֶ֛ם כָּל־זָכָ֖ר לְדֹרֹֽתֵיכֶ֑ם יְלִ֣יד בָּ֔יִת וּמִקְנַת־כֶּ֨סֶף֙ מִכֹּ֣ל בֶּן־נֵכָ֔ר אֲשֶׁ֛ר לֹ֥א מִֽזַּרְעֲךָ֖ הֽוּא׃"
          },
          {
            "num": "יג",
            "txt": "הִמּ֧וֹל ׀ יִמּ֛וֹל יְלִ֥יד בֵּֽיתְךָ֖ וּמִקְנַ֣ת כַּסְפֶּ֑ךָ וְהָֽיְתָ֧ה בְרִיתִ֛י בִּבְשַׂרְכֶ֖ם לִבְרִ֥ית עוֹלָֽם׃"
          },
          {
            "num": "יד",
            "txt": "וְעָרֵ֣ל ׀ זָכָ֗ר אֲשֶׁ֤ר לֹֽא־יִמּוֹל֙ אֶת־בְּשַׂ֣ר עָרְלָת֔וֹ וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מֵֽעַמֶּ֑יהָ אֶת־בְּרִיתִ֖י הֵפַֽר׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֤אמֶר אֱלֹהִים֙ אֶל־אַבְרָהָ֔ם שָׂרַ֣י אִשְׁתְּךָ֔ לֹֽא־תִקְרָ֥א אֶת־שְׁמָ֖הּ שָׂרָ֑י כִּ֥י שָׂרָ֖ה שְׁמָֽהּ׃"
          },
          {
            "num": "טז",
            "txt": "וּבֵֽרַכְתִּ֣י אֹתָ֔הּ וְגַ֨ם נָתַ֧תִּי מִמֶּ֛נָּה לְךָ֖ בֵּ֑ן וּבֵֽרַכְתִּ֨יהָ֙ וְהָֽיְתָ֣ה לְגוֹיִ֔ם מַלְכֵ֥י עַמִּ֖ים מִמֶּ֥נָּה יִֽהְיֽוּ׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּפֹּ֧ל אַבְרָהָ֛ם עַל־פָּנָ֖יו וַיִּצְחָ֑ק וַיֹּ֣אמֶר בְּלִבּ֗וֹ הַלְּבֶ֤ן מֵאָֽה־שָׁנָה֙ יִוָּלֵ֔ד וְאִ֨ם־שָׂרָ֔ה הֲבַת־תִּשְׁעִ֥ים שָׁנָ֖ה תֵּלֵֽד׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֥אמֶר אַבְרָהָ֖ם אֶל־הָֽאֱלֹהִ֑ים ל֥וּ יִשְׁמָעֵ֖אל יִֽחְיֶ֥ה לְפָנֶֽיךָ׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֣אמֶר אֱלֹהִ֗ים אֲבָל֙ שָׂרָ֣ה אִשְׁתְּךָ֗ יֹלֶ֤דֶת לְךָ֙ בֵּ֔ן וְקָרָ֥אתָ אֶת־שְׁמ֖וֹ יִצְחָ֑ק וַהֲקִֽמֹתִ֨י אֶת־בְּרִיתִ֥י אִתּ֛וֹ לִבְרִ֥ית עוֹלָ֖ם לְזַרְע֥וֹ אַֽחֲרָֽיו׃"
          },
          {
            "num": "כ",
            "txt": "וּֽלְיִשְׁמָעֵאל֮ שְׁמַעְתִּיךָ֒ הִנֵּ֣ה ׀ בֵּרַ֣כְתִּי אֹת֗וֹ וְהִפְרֵיתִ֥י אֹת֛וֹ וְהִרְבֵּיתִ֥י אֹת֖וֹ בִּמְאֹ֣ד מְאֹ֑ד שְׁנֵים־עָשָׂ֤ר נְשִׂיאִם֙ יוֹלִ֔יד וּנְתַתִּ֖יו לְג֥וֹי גָּדֽוֹל׃"
          },
          {
            "num": "כא",
            "txt": "וְאֶת־בְּרִיתִ֖י אָקִ֣ים אֶת־יִצְחָ֑ק אֲשֶׁר֩ תֵּלֵ֨ד לְךָ֤ שָׂרָה֙ לַמּוֹעֵ֣ד הַזֶּ֔ה בַּשָּׁנָ֖ה הָֽאַחֶֽרֶת׃"
          },
          {
            "num": "כב",
            "txt": "וַיְכַ֖ל לְדַבֵּ֣ר אִתּ֑וֹ וַיַּ֣עַל אֱלֹהִ֔ים מֵעַ֖ל אַבְרָהָֽם׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּקַּ֨ח אַבְרָהָ֜ם אֶת־יִשְׁמָעֵ֣אל בְּנ֗וֹ וְאֵ֨ת כָּל־יְלִידֵ֤י בֵיתוֹ֙ וְאֵת֙ כָּל־מִקְנַ֣ת כַּסְפּ֔וֹ כָּל־זָכָ֕ר בְּאַנְשֵׁ֖י בֵּ֣ית אַבְרָהָ֑ם וַיָּ֜מָל אֶת־בְּשַׂ֣ר עָרְלָתָ֗ם בְּעֶ֨צֶם֙ הַיּ֣וֹם הַזֶּ֔ה כַּֽאֲשֶׁ֛ר דִּבֶּ֥ר אִתּ֖וֹ אֱלֹהִֽים׃"
          },
          {
            "num": "כד",
            "txt": "וְאַ֨בְרָהָ֔ם בֶּן־תִּשְׁעִ֥ים וָתֵ֖שַׁע שָׁנָ֑ה בְּהִמֹּל֖וֹ בְּשַׂ֥ר עָרְלָתֽוֹ׃"
          },
          {
            "num": "כה",
            "txt": "וְיִשְׁמָעֵ֣אל בְּנ֔וֹ בֶּן־שְׁלֹ֥שׁ עֶשְׂרֵ֖ה שָׁנָ֑ה בְּהִ֨מֹּל֔וֹ אֵ֖ת בְּשַׂ֥ר עָרְלָתֽוֹ׃"
          },
          {
            "num": "כו",
            "txt": "בְּעֶ֨צֶם֙ הַיּ֣וֹם הַזֶּ֔ה נִמּ֖וֹל אַבְרָהָ֑ם וְיִשְׁמָעֵ֖אל בְּנֽוֹ׃"
          },
          {
            "num": "כז",
            "txt": "וְכָל־אַנְשֵׁ֤י בֵיתוֹ֙ יְלִ֣יד בָּ֔יִת וּמִקְנַת־כֶּ֖סֶף מֵאֵ֣ת בֶּן־נֵכָ֑ר נִמֹּ֖לוּ אִתּֽוֹ׃ {פ}"
          }
        ]
      },
      {
        "num": "יח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֵּרָ֤א אֵלָיו֙ יְהוָ֔ה בְּאֵֽלֹנֵ֖י מַמְרֵ֑א וְה֛וּא יֹשֵׁ֥ב פֶּֽתַח־הָאֹ֖הֶל כְּחֹ֥ם הַיּֽוֹם׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּשָּׂ֤א עֵינָיו֙ וַיַּ֔רְא וְהִנֵּה֙ שְׁלֹשָׁ֣ה אֲנָשִׁ֔ים נִצָּבִ֖ים עָלָ֑יו וַיַּ֗רְא וַיָּ֤רָץ לִקְרָאתָם֙ מִפֶּ֣תַח הָאֹ֔הֶל וַיִּשְׁתַּ֖חוּ אָֽרְצָה׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּאמַ֑ר אֲדֹנָ֗י אִם־נָ֨א מָצָ֤אתִי חֵן֙ בְּעֵינֶ֔יךָ אַל־נָ֥א תַֽעֲבֹ֖ר מֵעַ֥ל עַבְדֶּֽךָ׃"
          },
          {
            "num": "ד",
            "txt": "יֻקַּֽח־נָ֣א מְעַט־מַ֔יִם וְרַֽחֲצ֖וּ רַגְלֵיכֶ֑ם וְהִֽשָּׁעֲנ֖וּ תַּ֥חַת הָעֵֽץ׃"
          },
          {
            "num": "ה",
            "txt": "וְאֶקְחָ֨ה פַת־לֶ֜חֶם וְסַֽעֲד֤וּ לִבְּכֶם֙ אַחַ֣ר תַּֽעֲבֹ֔רוּ כִּֽי־עַל־כֵּ֥ן עֲבַרְתֶּ֖ם עַֽל־עַבְדְּכֶ֑ם וַיֹּ֣אמְר֔וּ כֵּ֥ן תַּֽעֲשֶׂ֖ה כַּֽאֲשֶׁ֥ר דִּבַּֽרְתָּ׃"
          },
          {
            "num": "ו",
            "txt": "וַיְמַהֵ֧ר אַבְרָהָ֛ם הָאֹ֖הֱלָה אֶל־שָׂרָ֑ה וַיֹּ֗אמֶר מַֽהֲרִ֞י שְׁלֹ֤שׁ סְאִים֙ קֶ֣מַח סֹ֔לֶת ל֖וּשִׁי וַֽעֲשִׂ֥י עֻגֽוֹת׃"
          },
          {
            "num": "ז",
            "txt": "וְאֶל־הַבָּקָ֖ר רָ֣ץ אַבְרָהָ֑ם וַיִּקַּ֨ח בֶּן־בָּקָ֜ר רַ֤ךְ וָטוֹב֙ וַיִּתֵּ֣ן אֶל־הַנַּ֔עַר וַיְמַהֵ֖ר לַֽעֲשׂ֥וֹת אֹתֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּקַּ֨ח חֶמְאָ֜ה וְחָלָ֗ב וּבֶן־הַבָּקָר֙ אֲשֶׁ֣ר עָשָׂ֔ה וַיִּתֵּ֖ן לִפְנֵיהֶ֑ם וְהֽוּא־עֹמֵ֧ד עֲלֵיהֶ֛ם תַּ֥חַת הָעֵ֖ץ וַיֹּאכֵֽלוּ׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּֽאמְר֣וּ אֵׄלָ֔יׄוׄ אַיֵּ֖ה שָׂרָ֣ה אִשְׁתֶּ֑ךָ וַיֹּ֖אמֶר הִנֵּ֥ה בָאֹֽהֶל׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֗אמֶר שׁ֣וֹב אָשׁ֤וּב אֵלֶ֨יךָ֙ כָּעֵ֣ת חַיָּ֔ה וְהִנֵּה־בֵ֖ן לְשָׂרָ֣ה אִשְׁתֶּ֑ךָ וְשָׂרָ֥ה שֹׁמַ֛עַת פֶּ֥תַח הָאֹ֖הֶל וְה֥וּא אַֽחֲרָֽיו׃"
          },
          {
            "num": "יא",
            "txt": "וְאַבְרָהָ֤ם וְשָׂרָה֙ זְקֵנִ֔ים בָּאִ֖ים בַּיָּמִ֑ים חָדַל֙ לִֽהְי֣וֹת לְשָׂרָ֔ה אֹ֖רַח כַּנָּשִֽׁים׃"
          },
          {
            "num": "יב",
            "txt": "וַתִּצְחַ֥ק שָׂרָ֖ה בְּקִרְבָּ֣הּ לֵאמֹ֑ר אַֽחֲרֵ֤י בְלֹתִי֙ הָֽיְתָה־לִּ֣י עֶדְנָ֔ה וַֽאדֹנִ֖י זָקֵֽן׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־אַבְרָהָ֑ם לָ֣מָּה זֶּה֩ צָֽחֲקָ֨ה שָׂרָ֜ה לֵאמֹ֗ר הַאַ֥ף אֻמְנָ֛ם אֵלֵ֖ד וַֽאֲנִ֥י זָקַֽנְתִּי׃"
          },
          {
            "num": "יד",
            "txt": "הֲיִפָּלֵ֥א מֵֽיְהוָ֖ה דָּבָ֑ר לַמּוֹעֵ֞ד אָשׁ֥וּב אֵלֶ֛יךָ כָּעֵ֥ת חַיָּ֖ה וּלְשָׂרָ֥ה בֵֽן׃"
          },
          {
            "num": "טו",
            "txt": "וַתְּכַחֵ֨שׁ שָׂרָ֧ה ׀ לֵאמֹ֛ר לֹ֥א צָחַ֖קְתִּי כִּ֣י ׀ יָרֵ֑אָה וַיֹּ֥אמֶר ׀ לֹ֖א כִּ֥י צָחָֽקְתְּ׃"
          },
          {
            "num": "טז",
            "txt": "וַיָּקֻ֤מוּ מִשָּׁם֙ הָֽאֲנָשִׁ֔ים וַיַּשְׁקִ֖פוּ עַל־פְּנֵ֣י סְדֹ֑ם וְאַ֨בְרָהָ֔ם הֹלֵ֥ךְ עִמָּ֖ם לְשַׁלְּחָֽם׃"
          },
          {
            "num": "יז",
            "txt": "וַֽיהוָ֖ה אָמָ֑ר הַֽמְכַסֶּ֤ה אֲנִי֙ מֵֽאַבְרָהָ֔ם אֲשֶׁ֖ר אֲנִ֥י עֹשֶֽׂה׃"
          },
          {
            "num": "יח",
            "txt": "וְאַ֨בְרָהָ֔ם הָי֧וֹ יִֽהְיֶ֛ה לְג֥וֹי גָּד֖וֹל וְעָצ֑וּם וְנִ֨בְרְכוּ־ב֔וֹ כֹּ֖ל גּוֹיֵ֥י הָאָֽרֶץ׃"
          },
          {
            "num": "יט",
            "txt": "כִּ֣י יְדַעְתִּ֗יו לְמַעַן֩ אֲשֶׁ֨ר יְצַוֶּ֜ה אֶת־בָּנָ֤יו וְאֶת־בֵּיתוֹ֙ אַֽחֲרָ֔יו וְשָֽׁמְרוּ֙ דֶּ֣רֶךְ יְהוָ֔ה לַֽעֲשׂ֥וֹת צְדָקָ֖ה וּמִשְׁפָּ֑ט לְמַ֗עַן הָבִ֤יא יְהוָה֙ עַל־אַבְרָהָ֔ם אֵ֥ת אֲשֶׁר־דִּבֶּ֖ר עָלָֽיו׃"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֣אמֶר יְהוָ֔ה זַֽעֲקַ֛ת סְדֹ֥ם וַֽעֲמֹרָ֖ה כִּי־רָ֑בָּה וְחַ֨טָּאתָ֔ם כִּ֥י כָֽבְדָ֖ה מְאֹֽד׃"
          },
          {
            "num": "כא",
            "txt": "אֵֽרְדָה־נָּ֣א וְאֶרְאֶ֔ה הַכְּצַֽעֲקָתָ֛הּ הַבָּ֥אָה אֵלַ֖י עָשׂ֣וּ ׀ כָּלָ֑ה וְאִם־לֹ֖א אֵדָֽעָה׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּפְנ֤וּ מִשָּׁם֙ הָֽאֲנָשִׁ֔ים וַיֵּֽלְכ֖וּ סְדֹ֑מָה וְאַ֨בְרָהָ֔ם עוֹדֶ֥נּוּ עֹמֵ֖ד לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּגַּ֥שׁ אַבְרָהָ֖ם וַיֹּאמַ֑ר הַאַ֣ף תִּסְפֶּ֔ה צַדִּ֖יק עִם־רָשָֽׁע׃"
          },
          {
            "num": "כד",
            "txt": "אוּלַ֥י יֵ֛שׁ חֲמִשִּׁ֥ים צַדִּיקִ֖ם בְּת֣וֹךְ הָעִ֑יר הַאַ֤ף תִּסְפֶּה֙ וְלֹֽא־תִשָּׂ֣א לַמָּק֔וֹם לְמַ֛עַן חֲמִשִּׁ֥ים הַצַּדִּיקִ֖ם אֲשֶׁ֥ר בְּקִרְבָּֽהּ׃"
          },
          {
            "num": "כה",
            "txt": "חָלִ֨לָה לְּךָ֜ מֵֽעֲשֹׂ֣ת ׀ כַּדָּבָ֣ר הַזֶּ֗ה לְהָמִ֤ית צַדִּיק֙ עִם־רָשָׁ֔ע וְהָיָ֥ה כַצַּדִּ֖יק כָּֽרָשָׁ֑ע חָלִ֣לָה לָּ֔ךְ הֲשֹׁפֵט֙ כָּל־הָאָ֔רֶץ לֹ֥א יַֽעֲשֶׂ֖ה מִשְׁפָּֽט׃"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֣אמֶר יְהוָ֔ה אִם־אֶמְצָ֥א בִסְדֹ֛ם חֲמִשִּׁ֥ים צַדִּיקִ֖ם בְּת֣וֹךְ הָעִ֑יר וְנָשָׂ֥אתִי לְכָל־הַמָּק֖וֹם בַּֽעֲבוּרָֽם׃"
          },
          {
            "num": "כז",
            "txt": "וַיַּ֥עַן אַבְרָהָ֖ם וַיֹּאמַ֑ר הִנֵּה־נָ֤א הוֹאַ֨לְתִּי֙ לְדַבֵּ֣ר אֶל־אֲדֹנָ֔י וְאָֽנֹכִ֖י עָפָ֥ר וָאֵֽפֶר׃"
          },
          {
            "num": "כח",
            "txt": "א֠וּלַי יַחְסְר֞וּן חֲמִשִּׁ֤ים הַצַּדִּיקִם֙ חֲמִשָּׁ֔ה הֲתַשְׁחִ֥ית בַּֽחֲמִשָּׁ֖ה אֶת־כָּל־הָעִ֑יר וַיֹּ֨אמֶר֙ לֹ֣א אַשְׁחִ֔ית אִם־אֶמְצָ֣א שָׁ֔ם אַרְבָּעִ֖ים וַֽחֲמִשָּֽׁה׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֨סֶף ע֜וֹד לְדַבֵּ֤ר אֵלָיו֙ וַיֹּאמַ֔ר אוּלַ֛י יִמָּֽצְא֥וּן שָׁ֖ם אַרְבָּעִ֑ים וַיֹּ֨אמֶר֙ לֹ֣א אֶֽעֱשֶׂ֔ה בַּֽעֲב֖וּר הָֽאַרְבָּעִֽים׃"
          },
          {
            "num": "ל",
            "txt": "וַ֠יֹּאמֶר אַל־נָ֞א יִ֤חַר לַֽאדֹנָי֙ וַֽאֲדַבֵּ֔רָה אוּלַ֛י יִמָּֽצְא֥וּן שָׁ֖ם שְׁלֹשִׁ֑ים וַיֹּ֨אמֶר֙ לֹ֣א אֶֽעֱשֶׂ֔ה אִם־אֶמְצָ֥א שָׁ֖ם שְׁלֹשִֽׁים׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֗אמֶר הִנֵּֽה־נָ֤א הוֹאַ֨לְתִּי֙ לְדַבֵּ֣ר אֶל־אֲדֹנָ֔י אוּלַ֛י יִמָּֽצְא֥וּן שָׁ֖ם עֶשְׂרִ֑ים וַיֹּ֨אמֶר֙ לֹ֣א אַשְׁחִ֔ית בַּֽעֲב֖וּר הָֽעֶשְׂרִֽים׃"
          },
          {
            "num": "לב",
            "txt": "וַ֠יֹּאמֶר אַל־נָ֞א יִ֤חַר לַֽאדֹנָי֙ וַֽאֲדַבְּרָ֣ה אַךְ־הַפַּ֔עַם אוּלַ֛י יִמָּֽצְא֥וּן שָׁ֖ם עֲשָׂרָ֑ה וַיֹּ֨אמֶר֙ לֹ֣א אַשְׁחִ֔ית בַּֽעֲב֖וּר הָֽעֲשָׂרָֽה׃"
          },
          {
            "num": "לג",
            "txt": "וַיֵּ֣לֶךְ יְהוָ֔ה כַּֽאֲשֶׁ֣ר כִּלָּ֔ה לְדַבֵּ֖ר אֶל־אַבְרָהָ֑ם וְאַבְרָהָ֖ם שָׁ֥ב לִמְקֹמֽוֹ׃"
          }
        ]
      },
      {
        "num": "יט",
        "verses": [
          {
            "num": "א",
            "txt": "וַ֠יָּבֹאוּ שְׁנֵ֨י הַמַּלְאָכִ֤ים סְדֹ֨מָה֙ בָּעֶ֔רֶב וְל֖וֹט יֹשֵׁ֣ב בְּשַֽׁעַר־סְדֹ֑ם וַיַּרְא־לוֹט֙ וַיָּ֣קָם לִקְרָאתָ֔ם וַיִּשְׁתַּ֥חוּ אַפַּ֖יִם אָֽרְצָה׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֜אמֶר הִנֶּ֣ה נָּֽא־אֲדֹנַ֗י ס֣וּרוּ נָ֠א אֶל־בֵּ֨ית עַבְדְּכֶ֤ם וְלִ֨ינוּ֙ וְרַֽחֲצ֣וּ רַגְלֵיכֶ֔ם וְהִשְׁכַּמְתֶּ֖ם וַֽהֲלַכְתֶּ֣ם לְדַרְכְּכֶ֑ם וַיֹּֽאמְר֣וּ לֹּ֔א כִּ֥י בָֽרְח֖וֹב נָלִֽין׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּפְצַר־בָּ֣ם מְאֹ֔ד וַיָּסֻ֣רוּ אֵלָ֔יו וַיָּבֹ֖אוּ אֶל־בֵּית֑וֹ וַיַּ֤עַשׂ לָהֶם֙ מִשְׁתֶּ֔ה וּמַצּ֥וֹת אָפָ֖ה וַיֹּאכֵֽלוּ׃"
          },
          {
            "num": "ד",
            "txt": "טֶרֶם֮ יִשְׁכָּבוּ֒ וְאַנְשֵׁ֨י הָעִ֜יר אַנְשֵׁ֤י סְדֹם֙ נָסַ֣בּוּ עַל־הַבַּ֔יִת מִנַּ֖עַר וְעַד־זָקֵ֑ן כָּל־הָעָ֖ם מִקָּצֶֽה׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּקְרְא֤וּ אֶל־לוֹט֙ וַיֹּ֣אמְרוּ ל֔וֹ אַיֵּ֧ה הָֽאֲנָשִׁ֛ים אֲשֶׁר־בָּ֥אוּ אֵלֶ֖יךָ הַלָּ֑יְלָה הֽוֹצִיאֵ֣ם אֵלֵ֔ינוּ וְנֵֽדְעָ֖ה אֹתָֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַיֵּצֵ֧א אֲלֵהֶ֛ם ל֖וֹט הַפֶּ֑תְחָה וְהַדֶּ֖לֶת סָגַ֥ר אַֽחֲרָֽיו׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּאמַ֑ר אַל־נָ֥א אַחַ֖י תָּרֵֽעוּ׃"
          },
          {
            "num": "ח",
            "txt": "הִנֵּה־נָ֨א לִ֜י שְׁתֵּ֣י בָנ֗וֹת אֲשֶׁ֤ר לֹֽא־יָדְעוּ֙ אִ֔ישׁ אוֹצִֽיאָה־נָּ֤א אֶתְהֶן֙ אֲלֵיכֶ֔ם וַֽעֲשׂ֣וּ לָהֶ֔ן כַּטּ֖וֹב בְּעֵֽינֵיכֶ֑ם רַ֠ק לָֽאֲנָשִׁ֤ים הָאֵל֙ אַל־תַּֽעֲשׂ֣וּ דָבָ֔ר כִּֽי־עַל־כֵּ֥ן בָּ֖אוּ בְּצֵ֥ל קֹֽרָתִֽי׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּֽאמְר֣וּ ׀ גֶּשׁ־הָ֗לְאָה וַיֹּֽאמְרוּ֙ הָֽאֶחָ֤ד בָּֽא־לָגוּר֙ וַיִּשְׁפֹּ֣ט שָׁפ֔וֹט עַתָּ֕ה נָרַ֥ע לְךָ֖ מֵהֶ֑ם וַיִּפְצְר֨וּ בָאִ֤ישׁ בְּלוֹט֙ מְאֹ֔ד וַֽיִּגְּשׁ֖וּ לִשְׁבֹּ֥ר הַדָּֽלֶת׃"
          },
          {
            "num": "י",
            "txt": "וַיִּשְׁלְח֤וּ הָֽאֲנָשִׁים֙ אֶת־יָדָ֔ם וַיָּבִ֧יאוּ אֶת־ל֛וֹט אֲלֵיהֶ֖ם הַבָּ֑יְתָה וְאֶת־הַדֶּ֖לֶת סָגָֽרוּ׃"
          },
          {
            "num": "יא",
            "txt": "וְֽאֶת־הָאֲנָשִׁ֞ים אֲשֶׁר־פֶּ֣תַח הַבַּ֗יִת הִכּוּ֙ בַּסַּנְוֵרִ֔ים מִקָּטֹ֖ן וְעַד־גָּד֑וֹל וַיִּלְא֖וּ לִמְצֹ֥א הַפָּֽתַח׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּֽאמְר֨וּ הָֽאֲנָשִׁ֜ים אֶל־ל֗וֹט עֹ֚ד מִֽי־לְךָ֣ פֹ֔ה חָתָן֙ וּבָנֶ֣יךָ וּבְנֹתֶ֔יךָ וְכֹ֥ל אֲשֶׁר־לְךָ֖ בָּעִ֑יר הוֹצֵ֖א מִן־הַמָּקֽוֹם׃"
          },
          {
            "num": "יג",
            "txt": "כִּֽי־מַשְׁחִתִ֣ים אֲנַ֔חְנוּ אֶת־הַמָּק֖וֹם הַזֶּ֑ה כִּֽי־גָדְלָ֤ה צַֽעֲקָתָם֙ אֶת־פְּנֵ֣י יְהוָ֔ה וַיְשַׁלְּחֵ֥נוּ יְהוָ֖ה לְשַֽׁחֲתָֽהּ׃"
          },
          {
            "num": "יד",
            "txt": "וַיֵּצֵ֨א ל֜וֹט וַיְדַבֵּ֣ר ׀ אֶל־חֲתָנָ֣יו ׀ לֹֽקְחֵ֣י בְנֹתָ֗יו וַיֹּ֨אמֶר֙ ק֤וּמוּ צְּאוּ֙ מִן־הַמָּק֣וֹם הַזֶּ֔ה כִּֽי־מַשְׁחִ֥ית יְהוָ֖ה אֶת־הָעִ֑יר וַיְהִ֥י כִמְצַחֵ֖ק בְּעֵינֵ֥י חֲתָנָֽיו׃"
          },
          {
            "num": "טו",
            "txt": "וּכְמוֹ֙ הַשַּׁ֣חַר עָלָ֔ה וַיָּאִ֥יצוּ הַמַּלְאָכִ֖ים בְּל֣וֹט לֵאמֹ֑ר קוּם֩ קַ֨ח אֶֽת־אִשְׁתְּךָ֜ וְאֶת־שְׁתֵּ֤י בְנֹתֶ֨יךָ֙ הַנִּמְצָאֹ֔ת פֶּן־תִּסָּפֶ֖ה בַּֽעֲוֺ֥ן הָעִֽיר׃"
          },
          {
            "num": "טז",
            "txt": "וַֽיִּתְמַהְמָ֓הּ ׀ וַיַּֽחֲזִ֨יקוּ הָֽאֲנָשִׁ֜ים בְּיָד֣וֹ וּבְיַד־אִשְׁתּ֗וֹ וּבְיַד֙ שְׁתֵּ֣י בְנֹתָ֔יו בְּחֶמְלַ֥ת יְהוָ֖ה עָלָ֑יו וַיֹּֽצִאֻ֥הוּ וַיַּנִּחֻ֖הוּ מִח֥וּץ לָעִֽיר׃"
          },
          {
            "num": "יז",
            "txt": "וַיְהִי֩ כְהֽוֹצִיאָ֨ם אֹתָ֜ם הַח֗וּצָה וַיֹּ֨אמֶר֙ הִמָּלֵ֣ט עַל־נַפְשֶׁ֔ךָ אַל־תַּבִּ֣יט אַֽחֲרֶ֔יךָ וְאַֽל־תַּעֲמֹ֖ד בְּכָל־הַכִּכָּ֑ר הָהָ֥רָה הִמָּלֵ֖ט פֶּן־תִּסָּפֶֽה׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֥אמֶר ל֖וֹט אֲלֵהֶ֑ם אַל־נָ֖א אֲדֹנָֽי׃"
          },
          {
            "num": "יט",
            "txt": "הִנֵּה־נָ֠א מָצָ֨א עַבְדְּךָ֣ חֵן֮ בְּעֵינֶיךָ֒ וַתַּגְדֵּ֣ל חַסְדְּךָ֗ אֲשֶׁ֤ר עָשִׂ֨יתָ֙ עִמָּדִ֔י לְהַֽחֲי֖וֹת אֶת־נַפְשִׁ֑י וְאָֽנֹכִ֗י לֹ֤א אוּכַל֙ לְהִמָּלֵ֣ט הָהָ֔רָה פֶּן־תִּדְבָּקַ֥נִי הָֽרָעָ֖ה וָמַֽתִּי׃"
          },
          {
            "num": "כ",
            "txt": "הִנֵּה־נָ֠א הָעִ֨יר הַזֹּ֧את קְרֹבָ֛ה לָנ֥וּס שָׁ֖מָּה וְהִ֣וא מִצְעָ֑ר אִמָּֽלְטָ֨ה נָּ֜א שָׁ֗מָּה הֲלֹ֥א מִצְעָ֛ר הִ֖וא וּתְחִ֥י נַפְשִֽׁי׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֣אמֶר אֵלָ֔יו הִנֵּה֙ נָשָׂ֣אתִי פָנֶ֔יךָ גַּ֖ם לַדָּבָ֣ר הַזֶּ֑ה לְבִלְתִּ֛י הָפְכִּ֥י אֶת־הָעִ֖יר אֲשֶׁ֥ר דִּבַּֽרְתָּ׃"
          },
          {
            "num": "כב",
            "txt": "מַהֵר֙ הִמָּלֵ֣ט שָׁ֔מָּה כִּ֣י לֹ֤א אוּכַל֙ לַֽעֲשׂ֣וֹת דָּבָ֔ר עַד־בֹּֽאֲךָ֖ שָׁ֑מָּה עַל־כֵּ֛ן קָרָ֥א שֵׁם־הָעִ֖יר צֽוֹעַר׃"
          },
          {
            "num": "כג",
            "txt": "הַשֶּׁ֖מֶשׁ יָצָ֣א עַל־הָאָ֑רֶץ וְל֖וֹט בָּ֥א צֹֽעֲרָה׃"
          },
          {
            "num": "כד",
            "txt": "וַֽיהוָ֗ה הִמְטִ֧יר עַל־סְדֹ֛ם וְעַל־עֲמֹרָ֖ה גָּפְרִ֣ית וָאֵ֑שׁ מֵאֵ֥ת יְהוָ֖ה מִן־הַשָּׁמָֽיִם׃"
          },
          {
            "num": "כה",
            "txt": "וַֽיַּהֲפֹךְ֙ אֶת־הֶֽעָרִ֣ים הָאֵ֔ל וְאֵ֖ת כָּל־הַכִּכָּ֑ר וְאֵת֙ כָּל־יֹֽשְׁבֵ֣י הֶֽעָרִ֔ים וְצֶ֖מַח הָֽאֲדָמָֽה׃"
          },
          {
            "num": "כו",
            "txt": "וַתַּבֵּ֥ט אִשְׁתּ֖וֹ מֵאַֽחֲרָ֑יו וַתְּהִ֖י נְצִ֥יב מֶֽלַח׃"
          },
          {
            "num": "כז",
            "txt": "וַיַּשְׁכֵּ֥ם אַבְרָהָ֖ם בַּבֹּ֑קֶר אֶ֨ל־הַמָּק֔וֹם אֲשֶׁר־עָ֥מַד שָׁ֖ם אֶת־פְּנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּשְׁקֵ֗ף עַל־פְּנֵ֤י סְדֹם֙ וַֽעֲמֹרָ֔ה וְעַֽל־כָּל־פְּנֵ֖י אֶ֣רֶץ הַכִּכָּ֑ר וַיַּ֗רְא וְהִנֵּ֤ה עָלָה֙ קִיטֹ֣ר הָאָ֔רֶץ כְּקִיטֹ֖ר הַכִּבְשָֽׁן׃"
          },
          {
            "num": "כט",
            "txt": "וַיְהִ֗י בְּשַׁחֵ֤ת אֱלֹהִים֙ אֶת־עָרֵ֣י הַכִּכָּ֔ר וַיִּזְכֹּ֥ר אֱלֹהִ֖ים אֶת־אַבְרָהָ֑ם וַיְשַׁלַּ֤ח אֶת־לוֹט֙ מִתּ֣וֹךְ הַֽהֲפֵכָ֔ה בַּֽהֲפֹךְ֙ אֶת־הֶ֣עָרִ֔ים אֲשֶׁר־יָשַׁ֥ב בָּהֵ֖ן לֽוֹט׃"
          },
          {
            "num": "ל",
            "txt": "וַיַּעַל֩ ל֨וֹט מִצּ֜וֹעַר וַיֵּ֣שֶׁב בָּהָ֗ר וּשְׁתֵּ֤י בְנֹתָיו֙ עִמּ֔וֹ כִּ֥י יָרֵ֖א לָשֶׁ֣בֶת בְּצ֑וֹעַר וַיֵּ֨שֶׁב֙ בַּמְּעָרָ֔ה ה֖וּא וּשְׁתֵּ֥י בְנֹתָֽיו׃"
          },
          {
            "num": "לא",
            "txt": "וַתֹּ֧אמֶר הַבְּכִירָ֛ה אֶל־הַצְּעִירָ֖ה אָבִ֣ינוּ זָקֵ֑ן וְאִ֨ישׁ אֵ֤ין בָּאָ֨רֶץ֙ לָב֣וֹא עָלֵ֔ינוּ כְּדֶ֖רֶךְ כָּל־הָאָֽרֶץ׃"
          },
          {
            "num": "לב",
            "txt": "לְכָ֨ה נַשְׁקֶ֧ה אֶת־אָבִ֛ינוּ יַ֖יִן וְנִשְׁכְּבָ֣ה עִמּ֑וֹ וּנְחַיֶּ֥ה מֵֽאָבִ֖ינוּ זָֽרַע׃"
          },
          {
            "num": "לג",
            "txt": "וַתַּשְׁקֶ֧יןָ אֶת־אֲבִיהֶ֛ן יַ֖יִן בַּלַּ֣יְלָה ה֑וּא וַתָּבֹ֤א הַבְּכִירָה֙ וַתִּשְׁכַּ֣ב אֶת־אָבִ֔יהָ וְלֹֽא־יָדַ֥ע בְּשִׁכְבָ֖הּ וּבְקוּׄמָֽהּ׃"
          },
          {
            "num": "לד",
            "txt": "וַֽיְהִי֙ מִֽמָּחֳרָ֔ת וַתֹּ֤אמֶר הַבְּכִירָה֙ אֶל־הַצְּעִירָ֔ה הֵן־שָׁכַ֥בְתִּי אֶ֖מֶשׁ אֶת־אָבִ֑י נַשְׁקֶ֨נּוּ יַ֜יִן גַּם־הַלַּ֗יְלָה וּבֹ֨אִי֙ שִׁכְבִ֣י עִמּ֔וֹ וּנְחַיֶּ֥ה מֵֽאָבִ֖ינוּ זָֽרַע׃"
          },
          {
            "num": "לה",
            "txt": "וַתַּשְׁקֶ֜יןָ גַּ֣ם בַּלַּ֧יְלָה הַה֛וּא אֶת־אֲבִיהֶ֖ן יָ֑יִן וַתָּ֤קָם הַצְּעִירָה֙ וַתִּשְׁכַּ֣ב עִמּ֔וֹ וְלֹֽא־יָדַ֥ע בְּשִׁכְבָ֖הּ וּבְקֻמָֽהּ׃"
          },
          {
            "num": "לו",
            "txt": "וַֽתַּהֲרֶ֛יןָ שְׁתֵּ֥י בְנֽוֹת־ל֖וֹט מֵֽאֲבִיהֶֽן׃"
          },
          {
            "num": "לז",
            "txt": "וַתֵּ֤לֶד הַבְּכִירָה֙ בֵּ֔ן וַתִּקְרָ֥א שְׁמ֖וֹ מוֹאָ֑ב ה֥וּא אֲבִֽי־מוֹאָ֖ב עַד־הַיּֽוֹם׃"
          },
          {
            "num": "לח",
            "txt": "וְהַצְּעִירָ֤ה גַם־הִוא֙ יָ֣לְדָה בֵּ֔ן וַתִּקְרָ֥א שְׁמ֖וֹ בֶּן־עַמִּ֑י ה֛וּא אֲבִ֥י בְנֵֽי־עַמּ֖וֹן עַד־הַיּֽוֹם׃ {ס}"
          }
        ]
      },
      {
        "num": "כ",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּסַּ֨ע מִשָּׁ֤ם אַבְרָהָם֙ אַ֣רְצָה הַנֶּ֔גֶב וַיֵּ֥שֶׁב בֵּין־קָדֵ֖שׁ וּבֵ֣ין שׁ֑וּר וַיָּ֖גָר בִּגְרָֽר׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֧אמֶר אַבְרָהָ֛ם אֶל־שָׂרָ֥ה אִשְׁתּ֖וֹ אֲחֹ֣תִי הִ֑וא וַיִּשְׁלַ֗ח אֲבִימֶ֨לֶךְ֙ מֶ֣לֶךְ גְּרָ֔ר וַיִּקַּ֖ח אֶת־שָׂרָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַיָּבֹ֧א אֱלֹהִ֛ים אֶל־אֲבִימֶ֖לֶךְ בַּֽחֲל֣וֹם הַלָּ֑יְלָה וַיֹּ֣אמֶר ל֗וֹ הִנְּךָ֥ מֵת֙ עַל־הָֽאִשָּׁ֣ה אֲשֶׁר־לָקַ֔חְתָּ וְהִ֖וא בְּעֻ֥לַת בָּֽעַל׃"
          },
          {
            "num": "ד",
            "txt": "וַֽאֲבִימֶ֕לֶךְ לֹ֥א קָרַ֖ב אֵלֶ֑יהָ וַיֹּאמַ֕ר אֲדֹנָ֕י הֲג֥וֹי גַּם־צַדִּ֖יק תַּֽהֲרֹֽג׃"
          },
          {
            "num": "ה",
            "txt": "הֲלֹ֨א ה֤וּא אָֽמַר־לִי֙ אֲחֹ֣תִי הִ֔וא וְהִֽיא־גַם־הִ֥וא אָֽמְרָ֖ה אָחִ֣י ה֑וּא בְּתָם־לְבָבִ֛י וּבְנִקְיֹ֥ן כַּפַּ֖י עָשִׂ֥יתִי זֹֽאת׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּאמֶר֩ אֵלָ֨יו הָֽאֱלֹהִ֜ים בַּֽחֲלֹ֗ם גַּ֣ם אָֽנֹכִ֤י יָדַ֨עְתִּי֙ כִּ֤י בְתָם־לְבָֽבְךָ֙ עָשִׂ֣יתָ זֹּ֔את וָֽאֶחְשֹׂ֧ךְ גַּם־אָֽנֹכִ֛י אֽוֹתְךָ֖ מֵֽחֲטוֹ־לִ֑י עַל־כֵּ֥ן לֹֽא־נְתַתִּ֖יךָ לִנְגֹּ֥עַ אֵלֶֽיהָ׃"
          },
          {
            "num": "ז",
            "txt": "וְעַתָּ֗ה הָשֵׁ֤ב אֵֽשֶׁת־הָאִישׁ֙ כִּֽי־נָבִ֣יא ה֔וּא וְיִתְפַּלֵּ֥ל בַּֽעַדְךָ֖ וֶֽחְיֵ֑ה וְאִם־אֵֽינְךָ֣ מֵשִׁ֔יב דַּ֚ע כִּי־מ֣וֹת תָּמ֔וּת אַתָּ֖ה וְכָל־אֲשֶׁר־לָֽךְ׃"
          },
          {
            "num": "ח",
            "txt": "וַיַּשְׁכֵּ֨ם אֲבִימֶ֜לֶךְ בַּבֹּ֗קֶר וַיִּקְרָא֙ לְכָל־עֲבָדָ֔יו וַיְדַבֵּ֛ר אֶת־כָּל־הַדְּבָרִ֥ים הָאֵ֖לֶּה בְּאָזְנֵיהֶ֑ם וַיִּֽירְא֥וּ הָֽאֲנָשִׁ֖ים מְאֹֽד׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּקְרָ֨א אֲבִימֶ֜לֶךְ לְאַבְרָהָ֗ם וַיֹּ֨אמֶר ל֜וֹ מֶֽה־עָשִׂ֤יתָ לָּ֨נוּ֙ וּמֶֽה־חָטָ֣אתִי לָ֔ךְ כִּֽי־הֵבֵ֧אתָ עָלַ֛י וְעַל־מַמְלַכְתִּ֖י חֲטָאָ֣ה גְדֹלָ֑ה מַֽעֲשִׂים֙ אֲשֶׁ֣ר לֹא־יֵֽעָשׂ֔וּ עָשִׂ֖יתָ עִמָּדִֽי׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֥אמֶר אֲבִימֶ֖לֶךְ אֶל־אַבְרָהָ֑ם מָ֣ה רָאִ֔יתָ כִּ֥י עָשִׂ֖יתָ אֶת־הַדָּבָ֥ר הַזֶּֽה׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֨אמֶר֙ אַבְרָהָ֔ם כִּ֣י אָמַ֗רְתִּי רַ֚ק אֵין־יִרְאַ֣ת אֱלֹהִ֔ים בַּמָּק֖וֹם הַזֶּ֑ה וַֽהֲרָג֖וּנִי עַל־דְּבַ֥ר אִשְׁתִּֽי׃"
          },
          {
            "num": "יב",
            "txt": "וְגַם־אָמְנָ֗ה אֲחֹתִ֤י בַת־אָבִי֙ הִ֔וא אַ֖ךְ לֹ֣א בַת־אִמִּ֑י וַתְּהִי־לִ֖י לְאִשָּֽׁה׃"
          },
          {
            "num": "יג",
            "txt": "וַיְהִ֞י כַּֽאֲשֶׁ֧ר הִתְע֣וּ אֹתִ֗י אֱלֹהִים֮ מִבֵּ֣ית אָבִי֒ וָֽאֹמַ֣ר לָ֔הּ זֶ֣ה חַסְדֵּ֔ךְ אֲשֶׁ֥ר תַּֽעֲשִׂ֖י עִמָּדִ֑י אֶ֤ל כָּל־הַמָּקוֹם֙ אֲשֶׁ֣ר נָב֣וֹא שָׁ֔מָּה אִמְרִי־לִ֖י אָחִ֥י הֽוּא׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּקַּ֨ח אֲבִימֶ֜לֶךְ צֹ֣אן וּבָקָ֗ר וַֽעֲבָדִים֙ וּשְׁפָחֹ֔ת וַיִּתֵּ֖ן לְאַבְרָהָ֑ם וַיָּ֣שֶׁב ל֔וֹ אֵ֖ת שָׂרָ֥ה אִשְׁתּֽוֹ׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֣אמֶר אֲבִימֶ֔לֶךְ הִנֵּ֥ה אַרְצִ֖י לְפָנֶ֑יךָ בַּטּ֥וֹב בְּעֵינֶ֖יךָ שֵֽׁב׃"
          },
          {
            "num": "טז",
            "txt": "וּלְשָׂרָ֣ה אָמַ֗ר הִנֵּ֨ה נָתַ֜תִּי אֶ֤לֶף כֶּ֨סֶף֙ לְאָחִ֔יךְ הִנֵּ֤ה הוּא־לָךְ֙ כְּס֣וּת עֵינַ֔יִם לְכֹ֖ל אֲשֶׁ֣ר אִתָּ֑ךְ וְאֵ֥ת כֹּ֖ל וְנֹכָֽחַת׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּתְפַּלֵּ֥ל אַבְרָהָ֖ם אֶל־הָֽאֱלֹהִ֑ים וַיִּרְפָּ֨א אֱלֹהִ֜ים אֶת־אֲבִימֶ֧לֶךְ וְאֶת־אִשְׁתּ֛וֹ וְאַמְהֹתָ֖יו וַיֵּלֵֽדוּ׃"
          },
          {
            "num": "יח",
            "txt": "כִּֽי־עָצֹ֤ר עָצַר֙ יְהוָ֔ה בְּעַ֥ד כָּל־רֶ֖חֶם לְבֵ֣ית אֲבִימֶ֑לֶךְ עַל־דְּבַ֥ר שָׂרָ֖ה אֵ֥שֶׁת אַבְרָהָֽם׃ {ס}"
          }
        ]
      },
      {
        "num": "כא",
        "verses": [
          {
            "num": "א",
            "txt": "וַֽיהוָ֛ה פָּקַ֥ד אֶת־שָׂרָ֖ה כַּֽאֲשֶׁ֣ר אָמָ֑ר וַיַּ֧עַשׂ יְהוָ֛ה לְשָׂרָ֖ה כַּֽאֲשֶׁ֥ר דִּבֵּֽר׃"
          },
          {
            "num": "ב",
            "txt": "וַתַּהַר֩ וַתֵּ֨לֶד שָׂרָ֧ה לְאַבְרָהָ֛ם בֵּ֖ן לִזְקֻנָ֑יו לַמּוֹעֵ֕ד אֲשֶׁר־דִּבֶּ֥ר אֹת֖וֹ אֱלֹהִֽים׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּקְרָ֨א אַבְרָהָ֜ם אֶֽת־שֶׁם־בְּנ֧וֹ הַנּֽוֹלַד־ל֛וֹ אֲשֶׁר־יָֽלְדָה־לּ֥וֹ שָׂרָ֖ה יִצְחָֽק׃"
          },
          {
            "num": "ד",
            "txt": "וַיָּ֤מָל אַבְרָהָם֙ אֶת־יִצְחָ֣ק בְּנ֔וֹ בֶּן־שְׁמֹנַ֖ת יָמִ֑ים כַּֽאֲשֶׁ֛ר צִוָּ֥ה אֹת֖וֹ אֱלֹהִֽים׃"
          },
          {
            "num": "ה",
            "txt": "וְאַבְרָהָ֖ם בֶּן־מְאַ֣ת שָׁנָ֑ה בְּהִוָּ֣לֶד ל֔וֹ אֵ֖ת יִצְחָ֥ק בְּנֽוֹ׃"
          },
          {
            "num": "ו",
            "txt": "וַתֹּ֣אמֶר שָׂרָ֔ה צְחֹ֕ק עָ֥שָׂה לִ֖י אֱלֹהִ֑ים כָּל־הַשֹּׁמֵ֖עַ יִֽצְחַק־לִֽי׃"
          },
          {
            "num": "ז",
            "txt": "וַתֹּ֗אמֶר מִ֤י מִלֵּל֙ לְאַבְרָהָ֔ם הֵינִ֥יקָה בָנִ֖ים שָׂרָ֑ה כִּֽי־יָלַ֥דְתִּי בֵ֖ן לִזְקֻנָֽיו׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּגְדַּ֥ל הַיֶּ֖לֶד וַיִּגָּמַ֑ל וַיַּ֤עַשׂ אַבְרָהָם֙ מִשְׁתֶּ֣ה גָד֔וֹל בְּי֖וֹם הִגָּמֵ֥ל אֶת־יִצְחָֽק׃"
          },
          {
            "num": "ט",
            "txt": "וַתֵּ֨רֶא שָׂרָ֜ה אֶֽת־בֶּן־הָגָ֧ר הַמִּצְרִ֛ית אֲשֶׁר־יָֽלְדָ֥ה לְאַבְרָהָ֖ם מְצַחֵֽק׃"
          },
          {
            "num": "י",
            "txt": "וַתֹּ֨אמֶר֙ לְאַבְרָהָ֔ם גָּרֵ֛שׁ הָֽאָמָ֥ה הַזֹּ֖את וְאֶת־בְּנָ֑הּ כִּ֣י לֹ֤א יִירַשׁ֙ בֶּן־הָֽאָמָ֣ה הַזֹּ֔את עִם־בְּנִ֖י עִם־יִצְחָֽק׃"
          },
          {
            "num": "יא",
            "txt": "וַיֵּ֧רַע הַדָּבָ֛ר מְאֹ֖ד בְּעֵינֵ֣י אַבְרָהָ֑ם עַ֖ל אוֹדֹ֥ת בְּנֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֨אמֶר אֱלֹהִ֜ים אֶל־אַבְרָהָ֗ם אַל־יֵרַ֤ע בְּעֵינֶ֨יךָ֙ עַל־הַנַּ֣עַר וְעַל־אֲמָתֶ֔ךָ כֹּל֩ אֲשֶׁ֨ר תֹּאמַ֥ר אֵלֶ֛יךָ שָׂרָ֖ה שְׁמַ֣ע בְּקֹלָ֑הּ כִּ֣י בְיִצְחָ֔ק יִקָּרֵ֥א לְךָ֖ זָֽרַע׃"
          },
          {
            "num": "יג",
            "txt": "וְגַ֥ם אֶת־בֶּן־הָֽאָמָ֖ה לְג֣וֹי אֲשִׂימֶ֑נּוּ כִּ֥י זַרְעֲךָ֖ הֽוּא׃"
          },
          {
            "num": "יד",
            "txt": "וַיַּשְׁכֵּ֣ם אַבְרָהָ֣ם ׀ בַּבֹּ֡קֶר וַיִּֽקַּֽח־לֶחֶם֩ וְחֵ֨מַת מַ֜יִם וַיִּתֵּ֣ן אֶל־הָ֠גָר שָׂ֧ם עַל־שִׁכְמָ֛הּ וְאֶת־הַיֶּ֖לֶד וַֽיְשַׁלְּחֶ֑הָ וַתֵּ֣לֶךְ וַתֵּ֔תַע בְּמִדְבַּ֖ר בְּאֵ֥ר שָֽׁבַע׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּכְל֥וּ הַמַּ֖יִם מִן־הַחֵ֑מֶת וַתַּשְׁלֵ֣ךְ אֶת־הַיֶּ֔לֶד תַּ֖חַת אַחַ֥ד הַשִּׂיחִֽם׃"
          },
          {
            "num": "טז",
            "txt": "וַתֵּלֶךְ֩ וַתֵּ֨שֶׁב לָ֜הּ מִנֶּ֗גֶד הַרְחֵק֙ כִּמְטַֽחֲוֵ֣י קֶ֔שֶׁת כִּ֣י אָֽמְרָ֔ה אַל־אֶרְאֶ֖ה בְּמ֣וֹת הַיָּ֑לֶד וַתֵּ֣שֶׁב מִנֶּ֔גֶד וַתִּשָּׂ֥א אֶת־קֹלָ֖הּ וַתֵּֽבְךְּ׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּשְׁמַ֣ע אֱלֹהִים֮ אֶת־ק֣וֹל הַנַּעַר֒ וַיִּקְרָא֩ מַלְאַ֨ךְ אֱלֹהִ֤ים ׀ אֶל־הָגָר֙ מִן־הַשָּׁמַ֔יִם וַיֹּ֥אמֶר לָ֖הּ מַה־לָּ֣ךְ הָגָ֑ר אַל־תִּ֣ירְאִ֔י כִּֽי־שָׁמַ֧ע אֱלֹהִ֛ים אֶל־ק֥וֹל הַנַּ֖עַר בַּֽאֲשֶׁ֥ר הוּא־שָֽׁם׃"
          },
          {
            "num": "יח",
            "txt": "ק֚וּמִי שְׂאִ֣י אֶת־הַנַּ֔עַר וְהַֽחֲזִ֥יקִי אֶת־יָדֵ֖ךְ בּ֑וֹ כִּֽי־לְג֥וֹי גָּד֖וֹל אֲשִׂימֶֽנּוּ׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּפְקַ֤ח אֱלֹהִים֙ אֶת־עֵינֶ֔יהָ וַתֵּ֖רֶא בְּאֵ֣ר מָ֑יִם וַתֵּ֜לֶךְ וַתְּמַלֵּ֤א אֶת־הַחֵ֨מֶת֙ מַ֔יִם וַתַּ֖שְׁקְ אֶת־הַנָּֽעַר׃"
          },
          {
            "num": "כ",
            "txt": "וַיְהִ֧י אֱלֹהִ֛ים אֶת־הַנַּ֖עַר וַיִּגְדָּ֑ל וַיֵּ֨שֶׁב֙ בַּמִּדְבָּ֔ר וַיְהִ֖י רֹבֶ֥ה קַשָּֽׁת׃"
          },
          {
            "num": "כא",
            "txt": "וַיֵּ֖שֶׁב בְּמִדְבַּ֣ר פָּארָ֑ן וַתִּֽקַּֽח־ל֥וֹ אִמּ֛וֹ אִשָּׁ֖ה מֵאֶ֥רֶץ מִצְרָֽיִם׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "וַֽיְהִי֙ בָּעֵ֣ת הַהִ֔וא וַיֹּ֣אמֶר אֲבִימֶ֗לֶךְ וּפִיכֹל֙ שַׂר־צְבָא֔וֹ אֶל־אַבְרָהָ֖ם לֵאמֹ֑ר אֱלֹהִ֣ים עִמְּךָ֔ בְּכֹ֥ל אֲשֶׁר־אַתָּ֖ה עֹשֶֽׂה׃"
          },
          {
            "num": "כג",
            "txt": "וְעַתָּ֗ה הִשָּׁ֨בְעָה לִּ֤י בֵֽאלֹהִים֙ הֵ֔נָּה אִם־תִּשְׁקֹ֣ר לִ֔י וּלְנִינִ֖י וּלְנֶכְדִּ֑י כַּחֶ֜סֶד אֲשֶׁר־עָשִׂ֤יתִי עִמְּךָ֙ תַּֽעֲשֶׂ֣ה עִמָּדִ֔י וְעִם־הָאָ֖רֶץ אֲשֶׁר־גַּ֥רְתָּה בָּֽהּ׃"
          },
          {
            "num": "כד",
            "txt": "וַיֹּ֨אמֶר֙ אַבְרָהָ֔ם אָֽנֹכִ֖י אִשָּׁבֵֽעַ׃"
          },
          {
            "num": "כה",
            "txt": "וְהוֹכִ֥חַ אַבְרָהָ֖ם אֶת־אֲבִימֶ֑לֶךְ עַל־אֹדוֹת֙ בְּאֵ֣ר הַמַּ֔יִם אֲשֶׁ֥ר גָּֽזְל֖וּ עַבְדֵ֥י אֲבִימֶֽלֶךְ׃"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֣אמֶר אֲבִימֶ֔לֶךְ לֹ֣א יָדַ֔עְתִּי מִ֥י עָשָׂ֖ה אֶת־הַדָּבָ֣ר הַזֶּ֑ה וְגַם־אַתָּ֞ה לֹֽא־הִגַּ֣דְתָּ לִּ֗י וְגַ֧ם אָֽנֹכִ֛י לֹ֥א שָׁמַ֖עְתִּי בִּלְתִּ֥י הַיּֽוֹם׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּקַּ֤ח אַבְרָהָם֙ צֹ֣אן וּבָקָ֔ר וַיִּתֵּ֖ן לַֽאֲבִימֶ֑לֶךְ וַיִּכְרְת֥וּ שְׁנֵיהֶ֖ם בְּרִֽית׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּצֵּ֣ב אַבְרָהָ֗ם אֶת־שֶׁ֛בַע כִּבְשֹׂ֥ת הַצֹּ֖אן לְבַדְּהֶֽן׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֥אמֶר אֲבִימֶ֖לֶךְ אֶל־אַבְרָהָ֑ם מָ֣ה הֵ֗נָּה שֶׁ֤בַע כְּבָשֹׂת֙ הָאֵ֔לֶּה אֲשֶׁ֥ר הִצַּ֖בְתָּ לְבַדָּֽנָה׃"
          },
          {
            "num": "ל",
            "txt": "וַיֹּ֕אמֶר כִּ֚י אֶת־שֶׁ֣בַע כְּבָשֹׂ֔ת תִּקַּ֖ח מִיָּדִ֑י בַּֽעֲבוּר֙ תִּֽהְיֶה־לִּ֣י לְעֵדָ֔ה כִּ֥י חָפַ֖רְתִּי אֶת־הַבְּאֵ֥ר הַזֹּֽאת׃"
          },
          {
            "num": "לא",
            "txt": "עַל־כֵּ֗ן קָרָ֛א לַמָּק֥וֹם הַה֖וּא בְּאֵ֣ר שָׁ֑בַע כִּ֛י שָׁ֥ם נִשְׁבְּע֖וּ שְׁנֵיהֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "וַיִּכְרְת֥וּ בְרִ֖ית בִּבְאֵ֣ר שָׁ֑בַע וַיָּ֣קָם אֲבִימֶ֗לֶךְ וּפִיכֹל֙ שַׂר־צְבָא֔וֹ וַיָּשֻׁ֖בוּ אֶל־אֶ֥רֶץ פְּלִשְׁתִּֽים׃"
          },
          {
            "num": "לג",
            "txt": "וַיִּטַּ֥ע אֶ֖שֶׁל בִּבְאֵ֣ר שָׁ֑בַע וַיִּ֨קְרָא־שָׁ֔ם בְּשֵׁ֥ם יְהוָ֖ה אֵ֥ל עוֹלָֽם׃"
          },
          {
            "num": "לד",
            "txt": "וַיָּ֧גָר אַבְרָהָ֛ם בְּאֶ֥רֶץ פְּלִשְׁתִּ֖ים יָמִ֥ים רַבִּֽים׃ {פ}"
          }
        ]
      },
      {
        "num": "כב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֗י אַחַר֙ הַדְּבָרִ֣ים הָאֵ֔לֶּה וְהָ֣אֱלֹהִ֔ים נִסָּ֖ה אֶת־אַבְרָהָ֑ם וַיֹּ֣אמֶר אֵלָ֔יו אַבְרָהָ֖ם וַיֹּ֥אמֶר הִנֵּֽנִי׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֡אמֶר קַח־נָ֠א אֶת־בִּנְךָ֨ אֶת־יְחִֽידְךָ֤ אֲשֶׁר־אָהַ֨בְתָּ֙ אֶת־יִצְחָ֔ק וְלֶ֨ךְ־לְךָ֔ אֶל־אֶ֖רֶץ הַמֹּֽרִיָּ֑ה וְהַֽעֲלֵ֤הוּ שָׁם֙ לְעֹלָ֔ה עַ֚ל אַחַ֣ד הֶֽהָרִ֔ים אֲשֶׁ֖ר אֹמַ֥ר אֵלֶֽיךָ׃"
          },
          {
            "num": "ג",
            "txt": "וַיַּשְׁכֵּ֨ם אַבְרָהָ֜ם בַּבֹּ֗קֶר וַֽיַּחֲבֹשׁ֙ אֶת־חֲמֹר֔וֹ וַיִּקַּ֞ח אֶת־שְׁנֵ֤י נְעָרָיו֙ אִתּ֔וֹ וְאֵ֖ת יִצְחָ֣ק בְּנ֑וֹ וַיְבַקַּע֙ עֲצֵ֣י עֹלָ֔ה וַיָּ֣קָם וַיֵּ֔לֶךְ אֶל־הַמָּק֖וֹם אֲשֶׁר־אָֽמַר־ל֥וֹ הָֽאֱלֹהִֽים׃"
          },
          {
            "num": "ד",
            "txt": "בַּיּ֣וֹם הַשְּׁלִישִׁ֗י וַיִּשָּׂ֨א אַבְרָהָ֧ם אֶת־עֵינָ֛יו וַיַּ֥רְא אֶת־הַמָּק֖וֹם מֵֽרָחֹֽק׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֨אמֶר אַבְרָהָ֜ם אֶל־נְעָרָ֗יו שְׁבוּ־לָכֶ֥ם פֹּה֙ עִֽם־הַחֲמ֔וֹר וַֽאֲנִ֣י וְהַנַּ֔עַר נֵֽלְכָ֖ה עַד־כֹּ֑ה וְנִֽשְׁתַּחֲוֶ֖ה וְנָשׁ֥וּבָה אֲלֵיכֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּקַּ֨ח אַבְרָהָ֜ם אֶת־עֲצֵ֣י הָֽעֹלָ֗ה וַיָּ֨שֶׂם֙ עַל־יִצְחָ֣ק בְּנ֔וֹ וַיִּקַּ֣ח בְּיָד֔וֹ אֶת־הָאֵ֖שׁ וְאֶת־הַֽמַּאֲכֶ֑לֶת וַיֵּֽלְכ֥וּ שְׁנֵיהֶ֖ם יַחְדָּֽו׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֨אמֶר יִצְחָ֜ק אֶל־אַבְרָהָ֤ם אָבִיו֙ וַיֹּ֣אמֶר אָבִ֔י וַיֹּ֖אמֶר הִנֶּ֣נִּֽי בְנִ֑י וַיֹּ֗אמֶר הִנֵּ֤ה הָאֵשׁ֙ וְהָ֣עֵצִ֔ים וְאַיֵּ֥ה הַשֶּׂ֖ה לְעֹלָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֨אמֶר֙ אַבְרָהָ֔ם אֱלֹהִ֞ים יִרְאֶה־לּ֥וֹ הַשֶּׂ֛ה לְעֹלָ֖ה בְּנִ֑י וַיֵּֽלְכ֥וּ שְׁנֵיהֶ֖ם יַחְדָּֽו׃"
          },
          {
            "num": "ט",
            "txt": "וַיָּבֹ֗אוּ אֶֽל־הַמָּקוֹם֮ אֲשֶׁ֣ר אָֽמַר־ל֣וֹ הָֽאֱלֹהִים֒ וַיִּ֨בֶן שָׁ֤ם אַבְרָהָם֙ אֶת־הַמִּזְבֵּ֔חַ וַֽיַּעֲרֹ֖ךְ אֶת־הָֽעֵצִ֑ים וַֽיַּעֲקֹד֙ אֶת־יִצְחָ֣ק בְּנ֔וֹ וַיָּ֤שֶׂם אֹתוֹ֙ עַל־הַמִּזְבֵּ֔חַ מִמַּ֖עַל לָֽעֵצִֽים׃"
          },
          {
            "num": "י",
            "txt": "וַיִּשְׁלַ֤ח אַבְרָהָם֙ אֶת־יָד֔וֹ וַיִּקַּ֖ח אֶת־הַֽמַּאֲכֶ֑לֶת לִשְׁחֹ֖ט אֶת־בְּנֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּקְרָ֨א אֵלָ֜יו מַלְאַ֤ךְ יְהוָה֙ מִן־הַשָּׁמַ֔יִם וַיֹּ֖אמֶר אַבְרָהָ֣ם ׀ אַבְרָהָ֑ם וַיֹּ֖אמֶר הִנֵּֽנִי׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֗אמֶר אַל־תִּשְׁלַ֤ח יָֽדְךָ֙ אֶל־הַנַּ֔עַר וְאַל־תַּ֥עַשׂ ל֖וֹ מְא֑וּמָה כִּ֣י ׀ עַתָּ֣ה יָדַ֗עְתִּי כִּֽי־יְרֵ֤א אֱלֹהִים֙ אַ֔תָּה וְלֹ֥א חָשַׂ֛כְתָּ אֶת־בִּנְךָ֥ אֶת־יְחִֽידְךָ֖ מִמֶּֽנִּי׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּשָּׂ֨א אַבְרָהָ֜ם אֶת־עֵינָ֗יו וַיַּרְא֙ וְהִנֵּה־אַ֔יִל אַחַ֕ר נֶֽאֱחַ֥ז בַּסְּבַ֖ךְ בְּקַרְנָ֑יו וַיֵּ֤לֶךְ אַבְרָהָם֙ וַיִּקַּ֣ח אֶת־הָאַ֔יִל וַיַּֽעֲלֵ֥הוּ לְעֹלָ֖ה תַּ֥חַת בְּנֽוֹ׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּקְרָ֧א אַבְרָהָ֛ם שֵֽׁם־הַמָּק֥וֹם הַה֖וּא יְהוָ֣ה ׀ יִרְאֶ֑ה אֲשֶׁר֙ יֵֽאָמֵ֣ר הַיּ֔וֹם בְּהַ֥ר יְהוָ֖ה יֵֽרָאֶֽה׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּקְרָ֛א מַלְאַ֥ךְ יְהוָ֖ה אֶל־אַבְרָהָ֑ם שֵׁנִ֖ית מִן־הַשָּׁמָֽיִם׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֕אמֶר בִּ֥י נִשְׁבַּ֖עְתִּי נְאֻם־יְהוָ֑ה כִּ֗י יַ֚עַן אֲשֶׁ֤ר עָשִׂ֨יתָ֙ אֶת־הַדָּבָ֣ר הַזֶּ֔ה וְלֹ֥א חָשַׂ֖כְתָּ אֶת־בִּנְךָ֥ אֶת־יְחִידֶֽךָ׃"
          },
          {
            "num": "יז",
            "txt": "כִּֽי־בָרֵ֣ךְ אֲבָֽרֶכְךָ֗ וְהַרְבָּ֨ה אַרְבֶּ֤ה אֶֽת־זַרְעֲךָ֙ כְּכֽוֹכְבֵ֣י הַשָּׁמַ֔יִם וְכַח֕וֹל אֲשֶׁ֖ר עַל־שְׂפַ֣ת הַיָּ֑ם וְיִרַ֣שׁ זַרְעֲךָ֔ אֵ֖ת שַׁ֥עַר אֹֽיְבָֽיו׃"
          },
          {
            "num": "יח",
            "txt": "וְהִתְבָּֽרְכ֣וּ בְזַרְעֲךָ֔ כֹּ֖ל גּוֹיֵ֣י הָאָ֑רֶץ עֵ֕קֶב אֲשֶׁ֥ר שָׁמַ֖עְתָּ בְּקֹלִֽי׃"
          },
          {
            "num": "יט",
            "txt": "וַיָּ֤שָׁב אַבְרָהָם֙ אֶל־נְעָרָ֔יו וַיָּקֻ֛מוּ וַיֵּֽלְכ֥וּ יַחְדָּ֖ו אֶל־בְּאֵ֣ר שָׁ֑בַע וַיֵּ֥שֶׁב אַבְרָהָ֖ם בִּבְאֵ֥ר שָֽׁבַע׃ {פ}"
          },
          {
            "num": "כ",
            "txt": "וַיְהִ֗י אַֽחֲרֵי֙ הַדְּבָרִ֣ים הָאֵ֔לֶּה וַיֻּגַּ֥ד לְאַבְרָהָ֖ם לֵאמֹ֑ר הִ֠נֵּה יָֽלְדָ֨ה מִלְכָּ֥ה גַם־הִ֛וא בָּנִ֖ים לְנָח֥וֹר אָחִֽיךָ׃"
          },
          {
            "num": "כא",
            "txt": "אֶת־ע֥וּץ בְּכֹר֖וֹ וְאֶת־בּ֣וּז אָחִ֑יו וְאֶת־קְמוּאֵ֖ל אֲבִ֥י אֲרָֽם׃"
          },
          {
            "num": "כב",
            "txt": "וְאֶת־כֶּ֣שֶׂד וְאֶת־חֲז֔וֹ וְאֶת־פִּלְדָּ֖שׁ וְאֶת־יִדְלָ֑ף וְאֵ֖ת בְּתוּאֵֽל׃"
          },
          {
            "num": "כג",
            "txt": "וּבְתוּאֵ֖ל יָלַ֣ד אֶת־רִבְקָ֑ה שְׁמֹנָ֥ה אֵ֨לֶּה֙ יָֽלְדָ֣ה מִלְכָּ֔ה לְנָח֖וֹר אֲחִ֥י אַבְרָהָֽם׃"
          },
          {
            "num": "כד",
            "txt": "וּפִֽילַגְשׁ֖וֹ וּשְׁמָ֣הּ רְאוּמָ֑ה וַתֵּ֤לֶד גַּם־הִוא֙ אֶת־טֶ֣בַח וְאֶת־גַּ֔חַם וְאֶת־תַּ֖חַשׁ וְאֶֽת־מַעֲכָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "כג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּֽהְיוּ֙ חַיֵּ֣י שָׂרָ֔ה מֵאָ֥ה שָׁנָ֛ה וְעֶשְׂרִ֥ים שָׁנָ֖ה וְשֶׁ֣בַע שָׁנִ֑ים שְׁנֵ֖י חַיֵּ֥י שָׂרָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַתָּ֣מָת שָׂרָ֗ה בְּקִרְיַ֥ת אַרְבַּ֛ע הִ֥וא חֶבְר֖וֹן בְּאֶ֣רֶץ כְּנָ֑עַן וַיָּבֹא֙ אַבְרָהָ֔ם לִסְפֹּ֥ד לְשָׂרָ֖ה וְלִבְ"
          },
          {
            "num": "ג",
            "txt": "תָֽהּ׃"
          },
          {
            "num": "ד",
            "txt": "וַיָּ֨קָם֙ אַבְרָהָ֔ם מֵעַ֖ל פְּנֵ֣י מֵת֑וֹ וַיְדַבֵּ֥ר אֶל־בְּנֵי־חֵ֖ת לֵאמֹֽר׃"
          },
          {
            "num": "ה",
            "txt": "גֵּר־וְתוֹשָׁ֥ב אָֽנֹכִ֖י עִמָּכֶ֑ם תְּנ֨וּ לִ֤י אֲחֻזַּת־קֶ֨בֶר֙ עִמָּכֶ֔ם וְאֶקְבְּרָ֥ה מֵתִ֖י מִלְּפָנָֽי׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּֽעֲנ֧וּ בְנֵי־חֵ֛ת אֶת־אַבְרָהָ֖ם לֵאמֹ֥ר לֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "שְׁמָעֵ֣נוּ ׀ אֲדֹנִ֗י נְשִׂ֨יא אֱלֹהִ֤ים אַתָּה֙ בְּתוֹכֵ֔נוּ בְּמִבְחַ֣ר קְבָרֵ֔ינוּ קְבֹ֖ר אֶת־מֵתֶ֑ךָ אִ֣ישׁ מִמֶּ֔נּוּ אֶת־קִבְר֛וֹ לֹֽא־יִכְלֶ֥ה מִמְּךָ֖ מִקְּבֹ֥ר מֵתֶֽךָ׃"
          },
          {
            "num": "ח",
            "txt": "וַיָּ֧קָם אַבְרָהָ֛ם וַיִּשְׁתַּ֥חוּ לְעַם־הָאָ֖רֶץ לִבְנֵי־חֵֽת׃"
          },
          {
            "num": "ט",
            "txt": "וַיְדַבֵּ֥ר אִתָּ֖ם לֵאמֹ֑ר אִם־יֵ֣שׁ אֶֽת־נַפְשְׁכֶ֗ם לִקְבֹּ֤ר אֶת־מֵתִי֙ מִלְּפָנַ֔י שְׁמָע֕וּנִי וּפִגְעוּ־לִ֖י בְּעֶפְר֥וֹן בֶּן־צֹֽחַר׃"
          },
          {
            "num": "י",
            "txt": "וְיִתֶּן־לִ֗י אֶת־מְעָרַ֤ת הַמַּכְפֵּלָה֙ אֲשֶׁר־ל֔וֹ אֲשֶׁ֖ר בִּקְצֵ֣ה שָׂדֵ֑הוּ בְּכֶ֨סֶף מָלֵ֜א יִתְּנֶ֥נָּה לִּ֛י בְּתֽוֹכְכֶ֖ם לַֽאֲחֻזַּת־קָֽבֶר׃"
          },
          {
            "num": "יא",
            "txt": "וְעֶפְר֥וֹן יֹשֵׁ֖ב בְּת֣וֹךְ בְּנֵי־חֵ֑ת וַיַּעַן֩ עֶפְר֨וֹן הַֽחִתִּ֤י אֶת־אַבְרָהָם֙ בְּאָזְנֵ֣י בְנֵי־חֵ֔ת לְכֹ֛ל בָּאֵ֥י שַֽׁעַר־עִיר֖וֹ לֵאמֹֽר׃"
          },
          {
            "num": "יב",
            "txt": "לֹֽא־אֲדֹנִ֣י שְׁמָעֵ֔נִי הַשָּׂדֶה֙ נָתַ֣תִּי לָ֔ךְ וְהַמְּעָרָ֥ה אֲשֶׁר־בּ֖וֹ לְךָ֣ נְתַתִּ֑יהָ לְעֵינֵ֧י בְנֵֽי־עַמִּ֛י נְתַתִּ֥יהָ לָּ֖ךְ קְבֹ֥ר מֵתֶֽךָ׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּשְׁתַּ֨חוּ֙ אַבְרָהָ֔ם לִפְנֵ֖י עַ֥ם הָאָֽרֶץ׃"
          },
          {
            "num": "יד",
            "txt": "וַיְדַבֵּ֨ר אֶל־עֶפְר֜וֹן בְּאָזְנֵ֤י עַם־הָאָ֨רֶץ֙ לֵאמֹ֔ר אַ֛ךְ אִם־אַתָּ֥ה ל֖וּ שְׁמָעֵ֑נִי נָתַ֜תִּי כֶּ֤סֶף הַשָּׂדֶה֙ קַ֣ח מִמֶּ֔נִּי וְאֶקְבְּרָ֥ה אֶת־מֵתִ֖י שָֽׁמָּה׃"
          },
          {
            "num": "טו",
            "txt": "וַיַּ֧עַן עֶפְר֛וֹן אֶת־אַבְרָהָ֖ם לֵאמֹ֥ר לֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "אֲדֹנִ֣י שְׁמָעֵ֔נִי אֶרֶץ֩ אַרְבַּ֨ע מֵאֹ֧ת שֶֽׁקֶל־כֶּ֛סֶף בֵּינִ֥י וּבֵֽינְךָ֖ מַה־הִ֑וא וְאֶת־מֵֽתְךָ֖ קְבֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּשְׁמַ֣ע אַבְרָהָם֮ אֶל־עֶפְרוֹן֒ וַיִּשְׁקֹ֤ל אַבְרָהָם֙ לְעֶפְרֹ֔ן אֶת־הַכֶּ֕סֶף אֲשֶׁ֥ר דִּבֶּ֖ר בְּאָזְנֵ֣י בְנֵי־חֵ֑ת אַרְבַּ֤ע מֵאוֹת֙ שֶׁ֣קֶל כֶּ֔סֶף עֹבֵ֖ר לַסֹּחֵֽר׃"
          },
          {
            "num": "יח",
            "txt": "וַיָּ֣קָם ׀ שְׂדֵ֣ה עֶפְר֗וֹן אֲשֶׁר֙ בַּמַּכְפֵּלָ֔ה אֲשֶׁ֖ר לִפְנֵ֣י מַמְרֵ֑א הַשָּׂדֶה֙ וְהַמְּעָרָ֣ה אֲשֶׁר־בּ֔וֹ וְכָל־הָעֵץ֙ אֲשֶׁ֣ר בַּשָּׂדֶ֔ה אֲשֶׁ֥ר בְּכָל־גְּבֻל֖וֹ סָבִֽיב׃"
          },
          {
            "num": "יט",
            "txt": "לְאַבְרָהָ֥ם לְמִקְנָ֖ה לְעֵינֵ֣י בְנֵי־חֵ֑ת בְּכֹ֖ל בָּאֵ֥י שַֽׁעַר־עִירֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וְאַֽחֲרֵי־כֵן֩ קָבַ֨ר אַבְרָהָ֜ם אֶת־שָׂרָ֣ה אִשְׁתּ֗וֹ אֶל־מְעָרַ֞ת שְׂדֵ֧ה הַמַּכְפֵּלָ֛ה עַל־פְּנֵ֥י מַמְרֵ֖א הִ֣וא חֶבְר֑וֹן בְּאֶ֖רֶץ כְּנָֽעַן׃"
          },
          {
            "txt": "וַיָּ֨קָם הַשָּׂדֶ֜ה וְהַמְּעָרָ֧ה אֲשֶׁר־בּ֛וֹ לְאַבְרָהָ֖ם לַֽאֲחֻזַּת־קָ֑בֶר מֵאֵ֖ת בְּנֵי־חֵֽת׃ {ס}"
          }
        ]
      },
      {
        "num": "כד",
        "verses": [
          {
            "num": "א",
            "txt": "וְאַבְרָהָ֣ם זָקֵ֔ן בָּ֖א בַּיָּמִ֑ים וַֽיהוָ֛ה בֵּרַ֥ךְ אֶת־אַבְרָהָ֖ם בַּכֹּֽל׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֣אמֶר אַבְרָהָ֗ם אֶל־עַבְדּוֹ֙ זְקַ֣ן בֵּית֔וֹ הַמֹּשֵׁ֖ל בְּכָל־אֲשֶׁר־ל֑וֹ שִֽׂים־נָ֥א יָֽדְךָ֖ תַּ֥חַת יְרֵכִֽי׃"
          },
          {
            "num": "ג",
            "txt": "וְאַשְׁבִּ֣יעֲךָ֔ בַּֽיהוָה֙ אֱלֹהֵ֣י הַשָּׁמַ֔יִם וֵֽאלֹהֵ֖י הָאָ֑רֶץ אֲשֶׁ֨ר לֹֽא־תִקַּ֤ח אִשָּׁה֙ לִבְנִ֔י מִבְּנוֹת֙ הַֽכְּנַעֲנִ֔י אֲשֶׁ֥ר אָֽנֹכִ֖י יוֹשֵׁ֥ב בְּקִרְבּֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "כִּ֧י אֶל־אַרְצִ֛י וְאֶל־מֽוֹלַדְתִּ֖י תֵּלֵ֑ךְ וְלָֽקַחְתָּ֥ אִשָּׁ֖ה לִבְנִ֥י לְיִצְחָֽק׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֤אמֶר אֵלָיו֙ הָעֶ֔בֶד אוּלַי֙ לֹֽא־תֹאבֶ֣ה הָֽאִשָּׁ֔ה לָלֶ֥כֶת אַֽחֲרַ֖י אֶל־הָאָ֣רֶץ הַזֹּ֑את הֶֽהָשֵׁ֤ב אָשִׁיב֙ אֶת־בִּנְךָ֔ אֶל־הָאָ֖רֶץ אֲשֶׁר־יָצָ֥אתָ מִשָּֽׁם׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֥אמֶר אֵלָ֖יו אַבְרָהָ֑ם הִשָּׁ֣מֶר לְךָ֔ פֶּן־תָּשִׁ֥יב אֶת־בְּנִ֖י שָֽׁמָּה׃"
          },
          {
            "num": "ז",
            "txt": "יְהוָ֣ה ׀ אֱלֹהֵ֣י הַשָּׁמַ֗יִם אֲשֶׁ֨ר לְקָחַ֜נִי מִבֵּ֣ית אָבִי֮ וּמֵאֶ֣רֶץ מֽוֹלַדְתִּי֒ וַֽאֲשֶׁ֨ר דִּבֶּר־לִ֜י וַֽאֲשֶׁ֤ר נִֽשְׁבַּֽע־לִי֙ לֵאמֹ֔ר לְזַ֨רְעֲךָ֔ אֶתֵּ֖ן אֶת־הָאָ֣רֶץ הַזֹּ֑את ה֗וּא יִשְׁלַ֤ח מַלְאָכוֹ֙ לְפָנֶ֔יךָ וְלָֽקַחְתָּ֥ אִשָּׁ֛ה לִבְנִ֖י מִשָּֽׁם׃"
          },
          {
            "num": "ח",
            "txt": "וְאִם־לֹ֨א תֹאבֶ֤ה הָֽאִשָּׁה֙ לָלֶ֣כֶת אַֽחֲרֶ֔יךָ וְנִקִּ֕יתָ מִשְּׁבֻֽעָתִ֖י זֹ֑את רַ֣ק אֶת־בְּנִ֔י לֹ֥א תָשֵׁ֖ב שָֽׁמָּה׃"
          },
          {
            "num": "ט",
            "txt": "וַיָּ֤שֶׂם הָעֶ֨בֶד֙ אֶת־יָד֔וֹ תַּ֛חַת יֶ֥רֶךְ אַבְרָהָ֖ם אֲדֹנָ֑יו וַיִּשָּׁ֣בַֽע ל֔וֹ עַל־הַדָּבָ֖ר הַזֶּֽה׃"
          },
          {
            "num": "י",
            "txt": "וַיִּקַּ֣ח הָ֠עֶבֶד עֲשָׂרָ֨ה גְמַלִּ֜ים מִגְּמַלֵּ֤י אֲדֹנָיו֙ וַיֵּ֔לֶךְ וְכָל־ט֥וּב אֲדֹנָ֖יו בְּיָד֑וֹ וַיָּ֗קָם וַיֵּ֛לֶךְ אֶל־אֲרַ֥ם נַֽהֲרַ֖יִם אֶל־עִ֥יר נָחֽוֹר׃"
          },
          {
            "num": "יא",
            "txt": "וַיַּבְרֵ֧ךְ הַגְּמַלִּ֛ים מִח֥וּץ לָעִ֖יר אֶל־בְּאֵ֣ר הַמָּ֑יִם לְעֵ֣ת עֶ֔רֶב לְעֵ֖ת צֵ֥את הַשֹּֽׁאֲבֹֽת׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּאמַ֓ר ׀ יְהוָ֗ה אֱלֹהֵי֙ אֲדֹנִ֣י אַבְרָהָ֔ם הַקְרֵה־נָ֥א לְפָנַ֖י הַיּ֑וֹם וַֽעֲשֵׂה־חֶ֕סֶד עִ֖ם אֲדֹנִ֥י אַבְרָהָֽם׃"
          },
          {
            "num": "יג",
            "txt": "הִנֵּ֛ה אָֽנֹכִ֥י נִצָּ֖ב עַל־עֵ֣ין הַמָּ֑יִם וּבְנוֹת֙ אַנְשֵׁ֣י הָעִ֔יר יֹֽצְאֹ֖ת לִשְׁאֹ֥ב מָֽיִם׃"
          },
          {
            "num": "יד",
            "txt": "וְהָיָ֣ה הַֽנַּעֲרָ֗ אֲשֶׁ֨ר אֹמַ֤ר אֵלֶ֨יהָ֙ הַטִּי־נָ֤א כַדֵּךְ֙ וְאֶשְׁתֶּ֔ה וְאָֽמְרָ֣ה שְׁתֵ֔ה וְגַם־גְּמַלֶּ֖יךָ אַשְׁקֶ֑ה אֹתָ֤הּ הֹכַ֨חְתָּ֙ לְעַבְדְּךָ֣ לְיִצְחָ֔ק וּבָ֣הּ אֵדַ֔ע כִּֽי־עָשִׂ֥יתָ חֶ֖סֶד עִם־אֲדֹנִֽי׃"
          },
          {
            "num": "טו",
            "txt": "וַֽיְהִי־ה֗וּא טֶרֶם֮ כִּלָּ֣ה לְדַבֵּר֒ וְהִנֵּ֧ה רִבְקָ֣ה יֹצֵ֗את אֲשֶׁ֤ר יֻלְּדָה֙ לִבְתוּאֵ֣ל בֶּן־מִלְכָּ֔ה אֵ֥שֶׁת נָח֖וֹר אֲחִ֣י אַבְרָהָ֑ם וְכַדָּ֖הּ עַל־שִׁכְמָֽהּ׃"
          },
          {
            "num": "טז",
            "txt": "וְהַֽנַּעֲרָ֗ טֹבַ֤ת מַרְאֶה֙ מְאֹ֔ד בְּתוּלָ֕ה וְאִ֖ישׁ לֹ֣א יְדָעָ֑הּ וַתֵּ֣רֶד הָעַ֔יְנָה וַתְּמַלֵּ֥א כַדָּ֖הּ וַתָּֽעַל׃"
          },
          {
            "num": "יז",
            "txt": "וַיָּ֥רָץ הָעֶ֖בֶד לִקְרָאתָ֑הּ וַיֹּ֕אמֶר הַגְמִיאִ֥ינִי נָ֛א מְעַט־מַ֖יִם מִכַּדֵּֽךְ׃"
          },
          {
            "num": "יח",
            "txt": "וַתֹּ֖אמֶר שְׁתֵ֣ה אֲדֹנִ֑י וַתְּמַהֵ֗ר וַתֹּ֧רֶד כַּדָּ֛הּ עַל־יָדָ֖הּ וַתַּשְׁקֵֽהוּ׃"
          },
          {
            "num": "יט",
            "txt": "וַתְּכַ֖ל לְהַשְׁקֹת֑וֹ וַתֹּ֗אמֶר גַּ֤ם לִגְמַלֶּ֨יךָ֙ אֶשְׁאָ֔ב עַ֥ד אִם־כִּלּ֖וּ לִשְׁתֹּֽת׃"
          },
          {
            "num": "כ",
            "txt": "וַתְּמַהֵ֗ר וַתְּעַ֤ר כַּדָּהּ֙ אֶל־הַשֹּׁ֔קֶת וַתָּ֥רָץ ע֛וֹד אֶֽל־הַבְּאֵ֖ר לִשְׁאֹ֑ב וַתִּשְׁאַ֖ב לְכָל־גְּמַלָּֽיו׃"
          },
          {
            "num": "כא",
            "txt": "וְהָאִ֥ישׁ מִשְׁתָּאֵ֖ה לָ֑הּ מַֽחֲרִ֕ישׁ לָדַ֗עַת הַֽהִצְלִ֧יחַ יְהוָ֛ה דַּרְכּ֖וֹ אִם־לֹֽא׃"
          },
          {
            "num": "כב",
            "txt": "וַיְהִ֗י כַּֽאֲשֶׁ֨ר כִּלּ֤וּ הַגְּמַלִּים֙ לִשְׁתּ֔וֹת וַיִּקַּ֤ח הָאִישׁ֙ נֶ֣זֶם זָהָ֔ב בֶּ֖קַע מִשְׁקָל֑וֹ וּשְׁנֵ֤י צְמִידִים֙ עַל־יָדֶ֔יהָ עֲשָׂרָ֥ה זָהָ֖ב מִשְׁקָלָֽם׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֨אמֶר֙ בַּת־מִ֣י אַ֔תְּ הַגִּ֥ידִי נָ֖א לִ֑י הֲיֵ֧שׁ בֵּית־אָבִ֛יךְ מָק֥וֹם לָ֖נוּ לָלִֽין׃"
          },
          {
            "num": "כד",
            "txt": "וַתֹּ֣אמֶר אֵלָ֔יו בַּת־בְּתוּאֵ֖ל אָנֹ֑כִי בֶּן־מִלְכָּ֕ה אֲשֶׁ֥ר יָֽלְדָ֖ה לְנָחֽוֹר׃"
          },
          {
            "num": "כה",
            "txt": "וַתֹּ֣אמֶר אֵלָ֔יו גַּם־תֶּ֥בֶן גַּם־מִסְפּ֖וֹא רַ֣ב עִמָּ֑נוּ גַּם־מָק֖וֹם לָלֽוּן׃"
          },
          {
            "num": "כו",
            "txt": "וַיִּקֹּ֣ד הָאִ֔ישׁ וַיִּשְׁתַּ֖חוּ לַֽיהוָֽה׃"
          },
          {
            "num": "כז",
            "txt": "וַיֹּ֗אמֶר בָּר֤וּךְ יְהוָה֙ אֱלֹהֵי֙ אֲדֹנִ֣י אַבְרָהָ֔ם אֲ֠שֶׁר לֹֽא־עָזַ֥ב חַסְדּ֛וֹ וַֽאֲמִתּ֖וֹ מֵעִ֣ם אֲדֹנִ֑י אָֽנֹכִ֗י בַּדֶּ֨רֶךְ֙ נָחַ֣נִי יְהוָ֔ה בֵּ֖ית אֲחֵ֥י אֲדֹנִֽי׃"
          },
          {
            "num": "כח",
            "txt": "וַתָּ֨רָץ֙ הַֽנַּעֲרָ֔ וַתַּגֵּ֖ד לְבֵ֣ית אִמָּ֑הּ כַּדְּבָרִ֖ים הָאֵֽלֶּה׃"
          },
          {
            "num": "כט",
            "txt": "וּלְרִבְקָ֥ה אָ֖ח וּשְׁמ֣וֹ לָבָ֑ן וַיָּ֨רָץ לָבָ֧ן אֶל־הָאִ֛ישׁ הַח֖וּצָה אֶל־הָעָֽיִן׃"
          },
          {
            "num": "ל",
            "txt": "וַיְהִ֣י ׀ כִּרְאֹ֣ת אֶת־הַנֶּ֗זֶם וְֽאֶת־הַצְּמִדִים֮ עַל־יְדֵ֣י אֲחֹתוֹ֒ וּכְשָׁמְע֗וֹ אֶת־דִּבְרֵ֞י רִבְקָ֤ה אֲחֹתוֹ֙ לֵאמֹ֔ר כֹּֽה־דִבֶּ֥ר אֵלַ֖י הָאִ֑ישׁ וַיָּבֹא֙ אֶל־הָאִ֔ישׁ וְהִנֵּ֛ה עֹמֵ֥ד עַל־הַגְּמַלִּ֖ים עַל־הָעָֽיִן׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֕אמֶר בּ֖וֹא בְּר֣וּךְ יְהוָ֑ה לָ֤מָּה תַֽעֲמֹד֙ בַּח֔וּץ וְאָֽנֹכִי֙ פִּנִּ֣יתִי הַבַּ֔יִת וּמָק֖וֹם לַגְּמַלִּֽים׃"
          },
          {
            "num": "לב",
            "txt": "וַיָּבֹ֤א הָאִישׁ֙ הַבַּ֔יְתָה וַיְפַתַּ֖ח הַגְּמַלִּ֑ים וַיִּתֵּ֨ן תֶּ֤בֶן וּמִסְפּוֹא֙ לַגְּמַלִּ֔ים וּמַ֨יִם֙ לִרְחֹ֣ץ רַגְלָ֔יו וְרַגְלֵ֥י הָֽאֲנָשִׁ֖ים אֲשֶׁ֥ר אִתּֽוֹ׃"
          },
          {
            "num": "לג",
            "txt": "ויישם (וַיּוּשַׂ֤ם) לְפָנָיו֙ לֶֽאֱכֹ֔ל וַיֹּ֨אמֶר֙ לֹ֣א אֹכַ֔ל עַ֥ד אִם־דִּבַּ֖רְתִּי דְּבָרָ֑י וַיֹּ֖אמֶר דַּבֵּֽר׃"
          },
          {
            "num": "לד",
            "txt": "וַיֹּאמַ֑ר עֶ֥בֶד אַבְרָהָ֖ם אָנֹֽכִי׃"
          },
          {
            "num": "לה",
            "txt": "וַֽיהוָ֞ה בֵּרַ֧ךְ אֶת־אֲדֹנִ֛י מְאֹ֖ד וַיִּגְדָּ֑ל וַיִּתֶּן־ל֞וֹ צֹ֤אן וּבָקָר֙ וְכֶ֣סֶף וְזָהָ֔ב וַֽעֲבָדִם֙ וּשְׁפָחֹ֔ת וּגְמַלִּ֖ים וַֽחֲמֹרִֽים׃"
          },
          {
            "num": "לו",
            "txt": "וַתֵּ֡לֶד שָׂרָה֩ אֵ֨שֶׁת אֲדֹנִ֥י בֵן֙ לַֽאדֹנִ֔י אַֽחֲרֵ֖י זִקְנָתָ֑הּ וַיִּתֶּן־ל֖וֹ אֶת־כָּל־אֲשֶׁר־לֽוֹ׃"
          },
          {
            "num": "לז",
            "txt": "וַיַּשְׁבִּעֵ֥נִי אֲדֹנִ֖י לֵאמֹ֑ר לֹֽא־תִקַּ֤ח אִשָּׁה֙ לִבְנִ֔י מִבְּנוֹת֙ הַֽכְּנַעֲנִ֔י אֲשֶׁ֥ר אָֽנֹכִ֖י יֹשֵׁ֥ב בְּאַרְצֽוֹ׃"
          },
          {
            "num": "לח",
            "txt": "אִם־לֹ֧א אֶל־בֵּית־אָבִ֛י תֵּלֵ֖ךְ וְאֶל־מִשְׁפַּחְתִּ֑י וְלָֽקַחְתָּ֥ אִשָּׁ֖ה לִבְנִֽי׃"
          },
          {
            "num": "לט",
            "txt": "וָֽאֹמַ֖ר אֶל־אֲדֹנִ֑י אֻלַ֛י לֹֽא־תֵלֵ֥ךְ הָֽאִשָּׁ֖ה אַֽחֲרָֽי׃"
          },
          {
            "num": "מ",
            "txt": "וַיֹּ֖אמֶר אֵלָ֑י יְהוָ֞ה אֲשֶׁר־הִתְהַלַּ֣כְתִּי לְפָנָ֗יו יִשְׁלַ֨ח מַלְאָכ֤וֹ אִתָּךְ֙ וְהִצְלִ֣יחַ דַּרְכֶּ֔ךָ וְלָֽקַחְתָּ֤ אִשָּׁה֙ לִבְנִ֔י מִמִּשְׁפַּחְתִּ֖י וּמִבֵּ֥ית אָבִֽי׃"
          },
          {
            "num": "מא",
            "txt": "אָ֤ז תִּנָּקֶה֙ מֵאָ֣לָתִ֔י כִּ֥י תָב֖וֹא אֶל־מִשְׁפַּחְתִּ֑י וְאִם־לֹ֤א יִתְּנוּ֙ לָ֔ךְ וְהָיִ֥יתָ נָקִ֖י מֵאָֽלָתִֽי׃"
          },
          {
            "num": "מב",
            "txt": "וָֽאָבֹ֥א הַיּ֖וֹם אֶל־הָעָ֑יִן וָֽאֹמַ֗ר יְהוָה֙ אֱלֹהֵי֙ אֲדֹנִ֣י אַבְרָהָ֔ם אִם־יֶשְׁךָ־נָּא֙ מַצְלִ֣יחַ דַּרְכִּ֔י אֲשֶׁ֥ר אָֽנֹכִ֖י הֹלֵ֥ךְ עָלֶֽיהָ׃"
          },
          {
            "num": "מג",
            "txt": "הִנֵּ֛ה אָֽנֹכִ֥י נִצָּ֖ב עַל־עֵ֣ין הַמָּ֑יִם וְהָיָ֤ה הָֽעַלְמָה֙ הַיֹּצֵ֣את לִשְׁאֹ֔ב וְאָֽמַרְתִּ֣י אֵלֶ֔יהָ הַשְׁקִֽינִי־נָ֥א מְעַט־מַ֖יִם מִכַּדֵּֽךְ׃"
          },
          {
            "num": "מד",
            "txt": "וְאָֽמְרָ֤ה אֵלַי֙ גַּם־אַתָּ֣ה שְׁתֵ֔ה וְגַ֥ם לִגְמַלֶּ֖יךָ אֶשְׁאָ֑ב הִ֣וא הָֽאִשָּׁ֔ה אֲשֶׁר־הֹכִ֥יחַ יְהוָ֖ה לְבֶן־אֲדֹנִֽי׃"
          },
          {
            "num": "מה",
            "txt": "אֲנִי֩ טֶ֨רֶם אֲכַלֶּ֜ה לְדַבֵּ֣ר אֶל־לִבִּ֗י וְהִנֵּ֨ה רִבְקָ֤ה יֹצֵאת֙ וְכַדָּ֣הּ עַל־שִׁכְמָ֔הּ וַתֵּ֥רֶד הָעַ֖יְנָה וַתִּשְׁאָ֑ב וָֽאֹמַ֥ר אֵלֶ֖יהָ הַשְׁקִ֥ינִי נָֽא׃"
          },
          {
            "num": "מו",
            "txt": "וַתְּמַהֵ֗ר וַתּ֤וֹרֶד כַּדָּהּ֙ מֵֽעָלֶ֔יהָ וַתֹּ֣אמֶר שְׁתֵ֔ה וְגַם־גְּמַלֶּ֖יךָ אַשְׁקֶ֑ה וָאֵ֕שְׁתְּ וְגַ֥ם הַגְּמַלִּ֖ים הִשְׁקָֽתָה׃"
          },
          {
            "num": "מז",
            "txt": "וָֽאֶשְׁאַ֣ל אֹתָ֗הּ וָֽאֹמַר֮ בַּת־מִ֣י אַתְּ֒ וַתֹּ֗אמֶר בַּת־בְּתוּאֵל֙ בֶּן־נָח֔וֹר אֲשֶׁ֥ר יָֽלְדָה־לּ֖וֹ מִלְכָּ֑ה וָֽאָשִׂ֤ם הַנֶּ֨זֶם֙ עַל־אַפָּ֔הּ וְהַצְּמִידִ֖ים עַל־יָדֶֽיהָ׃"
          },
          {
            "num": "מח",
            "txt": "וָֽאֶקֹּ֥ד וָֽאֶשְׁתַּחֲוֶ֖ה לַֽיהוָ֑ה וָֽאֲבָרֵ֗ךְ אֶת־יְהוָה֙ אֱלֹהֵי֙ אֲדֹנִ֣י אַבְרָהָ֔ם אֲשֶׁ֤ר הִנְחַ֨נִי֙ בְּדֶ֣רֶךְ אֱמֶ֔ת לָקַ֛חַת אֶת־בַּת־אֲחִ֥י אֲדֹנִ֖י לִבְנֽוֹ׃"
          },
          {
            "num": "מט",
            "txt": "וְ֠עַתָּה אִם־יֶשְׁכֶ֨ם עֹשִׂ֜ים חֶ֧סֶד וֶֽאֱמֶ֛ת אֶת־אֲדֹנִ֖י הַגִּ֣ידוּ לִ֑י וְאִם־לֹ֕א הַגִּ֣ידוּ לִ֔י וְאֶפְנֶ֥ה עַל־יָמִ֖ין א֥וֹ עַל־שְׂמֹֽאל׃"
          },
          {
            "num": "נ",
            "txt": "וַיַּ֨עַן לָבָ֤ן וּבְתוּאֵל֙ וַיֹּ֣אמְר֔וּ מֵֽיְהוָ֖ה יָצָ֣א הַדָּבָ֑ר לֹ֥א נוּכַ֛ל דַּבֵּ֥ר אֵלֶ֖יךָ רַ֥ע אוֹ־טֽוֹב׃"
          },
          {
            "num": "נא",
            "txt": "הִנֵּֽה־רִבְקָ֥ה לְפָנֶ֖יךָ קַ֣ח וָלֵ֑ךְ וּתְהִ֤י אִשָּׁה֙ לְבֶן־אֲדֹנֶ֔יךָ כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר יְהוָֽה׃"
          },
          {
            "num": "נב",
            "txt": "וַיְהִ֕י כַּֽאֲשֶׁ֥ר שָׁמַ֛ע עֶ֥בֶד אַבְרָהָ֖ם אֶת־דִּבְרֵיהֶ֑ם וַיִּשְׁתַּ֥חוּ אַ֖רְצָה לַֽיהוָֽה׃"
          },
          {
            "num": "נג",
            "txt": "וַיּוֹצֵ֨א הָעֶ֜בֶד כְּלֵי־כֶ֨סֶף וּכְלֵ֤י זָהָב֙ וּבְגָדִ֔ים וַיִּתֵּ֖ן לְרִבְקָ֑ה וּמִ֨גְדָּנֹ֔ת נָתַ֥ן לְאָחִ֖יהָ וּלְאִמָּֽהּ׃"
          },
          {
            "num": "נד",
            "txt": "וַיֹּֽאכְל֣וּ וַיִּשְׁתּ֗וּ ה֛וּא וְהָֽאֲנָשִׁ֥ים אֲשֶׁר־עִמּ֖וֹ וַיָּלִ֑ינוּ וַיָּק֣וּמוּ בַבֹּ֔קֶר וַיֹּ֖אמֶר שַׁלְּחֻ֥נִי לַֽאדֹנִֽי׃"
          },
          {
            "num": "נה",
            "txt": "וַיֹּ֤אמֶר אָחִ֨יהָ֙ וְאִמָּ֔הּ תֵּשֵׁ֨ב הַנַּֽעֲרָ֥ אִתָּ֛נוּ יָמִ֖ים א֣וֹ עָשׂ֑וֹר אַחַ֖ר תֵּלֵֽךְ׃"
          },
          {
            "num": "נו",
            "txt": "וַיֹּ֤אמֶר אֲלֵהֶם֙ אַל־תְּאַֽחֲר֣וּ אֹתִ֔י וַֽיהוָ֖ה הִצְלִ֣יחַ דַּרְכִּ֑י שַׁלְּח֕וּנִי וְאֵֽלְכָ֖ה לַֽאדֹנִֽי׃"
          },
          {
            "num": "נז",
            "txt": "וַיֹּֽאמְר֖וּ נִקְרָ֣א לַֽנַּעֲרָ֑ וְנִשְׁאֲלָ֖ה אֶת־פִּֽיהָ׃"
          },
          {
            "num": "נח",
            "txt": "וַיִּקְרְא֤וּ לְרִבְקָה֙ וַיֹּֽאמְר֣וּ אֵלֶ֔יהָ הֲתֵֽלְכִ֖י עִם־הָאִ֣ישׁ הַזֶּ֑ה וַתֹּ֖אמֶר אֵלֵֽךְ׃"
          },
          {
            "num": "נט",
            "txt": "וַֽיְשַׁלְּח֛וּ אֶת־רִבְקָ֥ה אֲחֹתָ֖ם וְאֶת־מֵֽנִקְתָּ֑הּ וְאֶת־עֶ֥בֶד אַבְרָהָ֖ם וְאֶת־אֲנָשָֽׁיו׃"
          },
          {
            "num": "ס",
            "txt": "וַיְבָֽרְכ֤וּ אֶת־רִבְקָה֙ וַיֹּ֣אמְרוּ לָ֔הּ אֲחֹתֵ֕נוּ אַ֥תְּ הֲיִ֖י לְאַלְפֵ֣י רְבָבָ֑ה וְיִירַ֣שׁ זַרְעֵ֔ךְ אֵ֖ת שַׁ֥עַר שֹֽׂנְאָֽיו׃"
          },
          {
            "num": "סא",
            "txt": "וַתָּ֨קָם רִבְקָ֜ה וְנַֽעֲרֹתֶ֗יהָ וַתִּרְכַּ֨בְנָה֙ עַל־הַגְּמַלִּ֔ים וַתֵּלַ֖כְנָה אַֽחֲרֵ֣י הָאִ֑ישׁ וַיִּקַּ֥ח הָעֶ֛בֶד אֶת־רִבְקָ֖ה וַיֵּלַֽךְ׃"
          },
          {
            "num": "סב",
            "txt": "וְיִצְחָק֙ בָּ֣א מִבּ֔וֹא בְּאֵ֥ר לַחַ֖י רֹאִ֑י וְה֥וּא יוֹשֵׁ֖ב בְּאֶ֥רֶץ הַנֶּֽגֶב׃"
          },
          {
            "num": "סג",
            "txt": "וַיֵּצֵ֥א יִצְחָ֛ק לָשׂ֥וּחַ בַּשָּׂדֶ֖ה לִפְנ֣וֹת עָ֑רֶב וַיִּשָּׂ֤א עֵינָיו֙ וַיַּ֔רְא וְהִנֵּ֥ה גְמַלִּ֖ים בָּאִֽים׃"
          },
          {
            "num": "סד",
            "txt": "וַתִּשָּׂ֤א רִבְקָה֙ אֶת־עֵינֶ֔יהָ וַתֵּ֖רֶא אֶת־יִצְחָ֑ק וַתִּפֹּ֖ל מֵעַ֥ל הַגָּמָֽל׃"
          },
          {
            "num": "סה",
            "txt": "וַתֹּ֣אמֶר אֶל־הָעֶ֗בֶד מִֽי־הָאִ֤ישׁ הַלָּזֶה֙ הַֽהֹלֵ֤ךְ בַּשָּׂדֶה֙ לִקְרָאתֵ֔נוּ וַיֹּ֥אמֶר הָעֶ֖בֶד ה֣וּא אֲדֹנִ֑י וַתִּקַּ֥ח הַצָּעִ֖יף וַתִּתְכָּֽס׃"
          },
          {
            "num": "סו",
            "txt": "וַיְסַפֵּ֥ר הָעֶ֖בֶד לְיִצְחָ֑ק אֵ֥ת כָּל־הַדְּבָרִ֖ים אֲשֶׁ֥ר עָשָֽׂה׃"
          },
          {
            "num": "סז",
            "txt": "וַיְבִאֶ֣הָ יִצְחָ֗ק הָאֹ֨הֱלָה֙ שָׂרָ֣ה אִמּ֔וֹ וַיִּקַּ֧ח אֶת־רִבְקָ֛ה וַתְּהִי־ל֥וֹ לְאִשָּׁ֖ה וַיֶּֽאֱהָבֶ֑הָ וַיִּנָּחֵ֥ם יִצְחָ֖ק אַֽחֲרֵ֥י אִמּֽוֹ׃ {פ}"
          }
        ]
      },
      {
        "num": "כה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֧סֶף אַבְרָהָ֛ם וַיִּקַּ֥ח אִשָּׁ֖ה וּשְׁמָ֥הּ קְטוּרָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַתֵּ֣לֶד ל֗וֹ אֶת־זִמְרָן֙ וְאֶת־יָקְשָׁ֔ן וְאֶת־מְדָ֖ן וְאֶת־מִדְיָ֑ן וְאֶת־יִשְׁבָּ֖ק וְאֶת־שֽׁוּחַ׃"
          },
          {
            "num": "ג",
            "txt": "וְיָקְשָׁ֣ן יָלַ֔ד אֶת־שְׁבָ֖א וְאֶת־דְּדָ֑ן וּבְנֵ֣י דְדָ֔ן הָי֛וּ אַשּׁוּרִ֥ם וּלְטוּשִׁ֖ם וּלְאֻמִּֽים׃"
          },
          {
            "num": "ד",
            "txt": "וּבְנֵ֣י מִדְיָ֗ן עֵיפָ֤ה וָעֵ֨פֶר֙ וַֽחֲנֹ֔ךְ וַֽאֲבִידָ֖ע וְאֶלְדָּעָ֑ה כָּל־אֵ֖לֶּה בְּנֵ֥י קְטוּרָֽה׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּתֵּ֧ן אַבְרָהָ֛ם אֶת־כָּל־אֲשֶׁר־ל֖וֹ לְיִצְחָֽק׃"
          },
          {
            "num": "ו",
            "txt": "וְלִבְנֵ֤י הַפִּֽילַגְשִׁים֙ אֲשֶׁ֣ר לְאַבְרָהָ֔ם נָתַ֥ן אַבְרָהָ֖ם מַתָּנֹ֑ת וַֽיְשַׁלְּחֵ֞ם מֵעַ֨ל יִצְחָ֤ק בְּנוֹ֙ בְּעוֹדֶ֣נּוּ חַ֔י קֵ֖דְמָה אֶל־אֶ֥רֶץ קֶֽדֶם׃"
          },
          {
            "num": "ז",
            "txt": "וְאֵ֗לֶּה יְמֵ֛י שְׁנֵֽי־חַיֵּ֥י אַבְרָהָ֖ם אֲשֶׁר־חָ֑י מְאַ֥ת שָׁנָ֛ה וְשִׁבְעִ֥ים שָׁנָ֖ה וְחָמֵ֥שׁ שָׁנִֽים׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּגְוַ֨ע וַיָּ֧מָת אַבְרָהָ֛ם בְּשֵׂיבָ֥ה טוֹבָ֖ה זָקֵ֣ן וְשָׂבֵ֑עַ וַיֵּאָ֖סֶף אֶל־עַמָּֽיו׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּקְבְּר֨וּ אֹת֜וֹ יִצְחָ֤ק וְיִשְׁמָעֵאל֙ בָּנָ֔יו אֶל־מְעָרַ֖ת הַמַּכְפֵּלָ֑ה אֶל־שְׂדֵ֞ה עֶפְרֹ֤ן בֶּן־צֹ֨חַר֙ הַֽחִתִּ֔י אֲשֶׁ֖ר עַל־פְּנֵ֥י מַמְרֵֽא׃"
          },
          {
            "num": "י",
            "txt": "הַשָּׂדֶ֛ה אֲשֶׁר־קָנָ֥ה אַבְרָהָ֖ם מֵאֵ֣ת בְּנֵי־חֵ֑ת שָׁ֛מָּה קֻבַּ֥ר אַבְרָהָ֖ם וְשָׂרָ֥ה אִשְׁתּֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וַיְהִ֗י אַֽחֲרֵי֙ מ֣וֹת אַבְרָהָ֔ם וַיְבָ֥רֶךְ אֱלֹהִ֖ים אֶת־יִצְחָ֣ק בְּנ֑וֹ וַיֵּ֣שֶׁב יִצְחָ֔ק עִם־בְּאֵ֥ר לַחַ֖י רֹאִֽי׃ {פ}"
          },
          {
            "num": "יב",
            "txt": "וְאֵ֛לֶּה תֹּֽלְדֹ֥ת יִשְׁמָעֵ֖אל בֶּן־אַבְרָהָ֑ם אֲשֶׁ֨ר יָֽלְדָ֜ה הָגָ֧ר הַמִּצְרִ֛ית שִׁפְחַ֥ת שָׂרָ֖ה לְאַבְרָהָֽם׃"
          },
          {
            "num": "יג",
            "txt": "וְאֵ֗לֶּה שְׁמוֹת֙ בְּנֵ֣י יִשְׁמָעֵ֔אל בִּשְׁמֹתָ֖ם לְתֽוֹלְדֹתָ֑ם בְּכֹ֤ר יִשְׁמָעֵאל֙ נְבָיֹ֔ת וְקֵדָ֥ר וְאַדְבְּאֵ֖ל וּמִבְשָֽׂם׃"
          },
          {
            "num": "יד",
            "txt": "וּמִשְׁמָ֥ע וְדוּמָ֖ה וּמַשָּֽׂא׃"
          },
          {
            "num": "טו",
            "txt": "חֲדַ֣ד וְתֵימָ֔א יְט֥וּר נָפִ֖ישׁ וָקֵֽדְמָה׃"
          },
          {
            "num": "טז",
            "txt": "אֵ֣לֶּה הֵ֞ם בְּנֵ֤י יִשְׁמָעֵאל֙ וְאֵ֣לֶּה שְׁמֹתָ֔ם בְּחַצְרֵיהֶ֖ם וּבְטִֽירֹתָ֑ם שְׁנֵים־עָשָׂ֥ר נְשִׂיאִ֖ם לְאֻמֹּתָֽם׃"
          },
          {
            "num": "יז",
            "txt": "וְאֵ֗לֶּה שְׁנֵי֙ חַיֵּ֣י יִשְׁמָעֵ֔אל מְאַ֥ת שָׁנָ֛ה וּשְׁלֹשִׁ֥ים שָׁנָ֖ה וְשֶׁ֣בַע שָׁנִ֑ים וַיִּגְוַ֣ע וַיָּ֔מָת וַיֵּאָ֖סֶף אֶל־עַמָּֽיו׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּשְׁכְּנ֨וּ מֵֽחֲוִילָ֜ה עַד־שׁ֗וּר אֲשֶׁר֙ עַל־פְּנֵ֣י מִצְרַ֔יִם בֹּֽאֲכָ֖ה אַשּׁ֑וּרָה עַל־פְּנֵ֥י כָל־אֶחָ֖יו נָפָֽל׃ {פ}"
          },
          {
            "num": "יט",
            "txt": "וְאֵ֛לֶּה תּֽוֹלְדֹ֥ת יִצְחָ֖ק בֶּן־אַבְרָהָ֑ם אַבְרָהָ֖ם הוֹלִ֥יד אֶת־יִצְחָֽק׃"
          },
          {
            "num": "כ",
            "txt": "וַיְהִ֤י יִצְחָק֙ בֶּן־אַרְבָּעִ֣ים שָׁנָ֔ה בְּקַחְתּ֣וֹ אֶת־רִבְקָ֗ה בַּת־בְּתוּאֵל֙ הָֽאֲרַמִּ֔י מִפַּדַּ֖ן אֲרָ֑ם אֲח֛וֹת לָבָ֥ן הָֽאֲרַמִּ֖י ל֥וֹ לְאִשָּֽׁה׃"
          },
          {
            "num": "כא",
            "txt": "וַיֶּעְתַּ֨ר יִצְחָ֤ק לַֽיהוָה֙ לְנֹ֣כַח אִשְׁתּ֔וֹ כִּ֥י עֲקָרָ֖ה הִ֑וא וַיֵּעָ֤תֶר לוֹ֙ יְהוָ֔ה וַתַּ֖הַר רִבְקָ֥ה אִשְׁתּֽוֹ׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּתְרֹֽצְצ֤וּ הַבָּנִים֙ בְּקִרְבָּ֔הּ וַתֹּ֣אמֶר אִם־כֵּ֔ן לָ֥מָּה זֶּ֖ה אָנֹ֑כִי וַתֵּ֖לֶךְ לִדְרֹ֥שׁ אֶת־יְהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה לָ֗הּ שְׁנֵ֤י גֹיִים֙ בְּבִטְנֵ֔ךְ וּשְׁנֵ֣י לְאֻמִּ֔ים מִמֵּעַ֖יִךְ יִפָּרֵ֑דוּ וּלְאֹם֙ מִלְאֹ֣ם יֶֽאֱמָ֔ץ וְרַ֖ב יַֽעֲבֹ֥ד צָעִֽיר׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּמְלְא֥וּ יָמֶ֖יהָ לָלֶ֑דֶת וְהִנֵּ֥ה תוֹמִ֖ם בְּבִטְנָֽהּ׃"
          },
          {
            "num": "כה",
            "txt": "וַיֵּצֵ֤א הָֽרִאשׁוֹן֙ אַדְמוֹנִ֔י כֻּלּ֖וֹ כְּאַדֶּ֣רֶת שֵׂעָ֑ר וַיִּקְרְא֥וּ שְׁמ֖וֹ עֵשָֽׂו׃"
          },
          {
            "num": "כו",
            "txt": "וְאַֽחֲרֵי־כֵ֞ן יָצָ֣א אָחִ֗יו וְיָד֤וֹ אֹחֶ֨זֶת֙ בַּֽעֲקֵ֣ב עֵשָׂ֔ו וַיִּקְרָ֥א שְׁמ֖וֹ יַֽעֲקֹ֑ב וְיִצְחָ֛ק בֶּן־שִׁשִּׁ֥ים שָׁנָ֖ה בְּלֶ֥דֶת אֹתָֽם׃"
          },
          {
            "num": "כז",
            "txt": "וַֽיִּגְדְּלוּ֙ הַנְּעָרִ֔ים וַיְהִ֣י עֵשָׂ֗ו אִ֛ישׁ יֹדֵ֥עַ צַ֖יִד אִ֣ישׁ שָׂדֶ֑ה וְיַֽעֲקֹב֙ אִ֣ישׁ תָּ֔ם יֹשֵׁ֖ב אֹֽהָלִֽים׃"
          },
          {
            "num": "כח",
            "txt": "וַיֶּֽאֱהַ֥ב יִצְחָ֛ק אֶת־עֵשָׂ֖ו כִּי־צַ֣יִד בְּפִ֑יו וְרִבְקָ֖ה אֹהֶ֥בֶת אֶֽת־יַעֲקֹֽב׃"
          },
          {
            "num": "כט",
            "txt": "וַיָּ֥זֶד יַֽעֲקֹ֖ב נָזִ֑יד וַיָּבֹ֥א עֵשָׂ֛ו מִן־הַשָּׂדֶ֖ה וְה֥וּא עָיֵֽף׃"
          },
          {
            "num": "ל",
            "txt": "וַיֹּ֨אמֶר עֵשָׂ֜ו אֶֽל־יַעֲקֹ֗ב הַלְעִיטֵ֤נִי נָא֙ מִן־הָֽאָדֹ֤ם הָֽאָדֹם֙ הַזֶּ֔ה כִּ֥י עָיֵ֖ף אָנֹ֑כִי עַל־כֵּ֥ן קָרָֽא־שְׁמ֖וֹ אֱדֽוֹם׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֖אמֶר יַֽעֲקֹ֑ב מִכְרָ֥ה כַיּ֛וֹם אֶת־בְּכֹֽרָתְךָ֖ לִֽי׃"
          },
          {
            "num": "לב",
            "txt": "וַיֹּ֣אמֶר עֵשָׂ֔ו הִנֵּ֛ה אָֽנֹכִ֥י הוֹלֵ֖ךְ לָמ֑וּת וְלָמָּה־זֶּ֥ה לִ֖י בְּכֹרָֽה׃"
          },
          {
            "num": "לג",
            "txt": "וַיֹּ֣אמֶר יַֽעֲקֹ֗ב הִשָּׁ֤בְעָה לִּי֙ כַּיּ֔וֹם וַיִּשָּׁבַ֖ע ל֑וֹ וַיִּמְכֹּ֥ר אֶת־בְּכֹֽרָת֖וֹ לְיַֽעֲקֹֽב׃"
          },
          {
            "num": "לד",
            "txt": "וְיַֽעֲקֹ֞ב נָתַ֣ן לְעֵשָׂ֗ו לֶ֚חֶם וּנְזִ֣יד עֲדָשִׁ֔ים וַיֹּ֣אכַל וַיֵּ֔שְׁתְּ וַיָּ֖קָם וַיֵּלַ֑ךְ וַיִּ֥בֶז עֵשָׂ֖ו אֶת־הַבְּכֹרָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "כו",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֤י רָעָב֙ בָּאָ֔רֶץ מִלְּבַד֙ הָֽרָעָ֣ב הָֽרִאשׁ֔וֹן אֲשֶׁ֥ר הָיָ֖ה בִּימֵ֣י אַבְרָהָ֑ם וַיֵּ֧לֶךְ יִצְחָ֛ק אֶל־אֲבִימֶ֥לֶךְ מֶֽלֶךְ־פְּלִשְׁתִּ֖ים גְּרָֽרָה׃"
          },
          {
            "num": "ב",
            "txt": "וַיֵּרָ֤א אֵלָיו֙ יְהוָ֔ה וַיֹּ֖אמֶר אַל־תֵּרֵ֣ד מִצְרָ֑יְמָה שְׁכֹ֣ן בָּאָ֔רֶץ אֲשֶׁ֖ר אֹמַ֥ר אֵלֶֽיךָ׃"
          },
          {
            "num": "ג",
            "txt": "גּ֚וּר בָּאָ֣רֶץ הַזֹּ֔את וְאֶֽהְיֶ֥ה עִמְּךָ֖ וַאֲבָֽרְכֶ֑ךָּ כִּֽי־לְךָ֣ וּֽלְזַרְעֲךָ֗ אֶתֵּן֙ אֶת־כָּל־הָֽאֲרָצֹ֣ת הָאֵ֔ל וַהֲקִֽמֹתִי֙ אֶת־הַשְּׁבֻעָ֔ה אֲשֶׁ֥ר נִשְׁבַּ֖עְתִּי לְאַבְרָהָ֥ם אָבִֽיךָ׃"
          },
          {
            "num": "ד",
            "txt": "וְהִרְבֵּיתִ֤י אֶֽת־זַרְעֲךָ֙ כְּכֽוֹכְבֵ֣י הַשָּׁמַ֔יִם וְנָֽתַתִּ֣י לְזַרְעֲךָ֔ אֵ֥ת כָּל־הָֽאֲרָצֹ֖ת הָאֵ֑ל וְהִתְבָּֽרְכ֣וּ בְזַרְעֲךָ֔ כֹּ֖ל גּוֹיֵ֥י הָאָֽרֶץ׃"
          },
          {
            "num": "ה",
            "txt": "עֵ֕קֶב אֲשֶׁר־שָׁמַ֥ע אַבְרָהָ֖ם בְּקֹלִ֑י וַיִּשְׁמֹר֙ מִשְׁמַרְתִּ֔י מִצְוֺתַ֖י חֻקּוֹתַ֥י וְתֽוֹרֹתָֽי׃"
          },
          {
            "num": "ו",
            "txt": "וַיֵּ֥שֶׁב יִצְחָ֖ק בִּגְרָֽר׃"
          },
          {
            "num": "ז",
            "txt": "וַֽיִּשְׁאֲל֞וּ אַנְשֵׁ֤י הַמָּקוֹם֙ לְאִשְׁתּ֔וֹ וַיֹּ֖אמֶר אֲחֹ֣תִי הִ֑וא כִּ֤י יָרֵא֙ לֵאמֹ֣ר אִשְׁתִּ֔י פֶּן־יַֽהַרְגֻ֜נִי אַנְשֵׁ֤י הַמָּקוֹם֙ עַל־רִבְקָ֔ה כִּֽי־טוֹבַ֥ת מַרְאֶ֖ה הִֽוא׃"
          },
          {
            "num": "ח",
            "txt": "וַיְהִ֗י כִּ֣י אָֽרְכוּ־ל֥וֹ שָׁם֙ הַיָּמִ֔ים וַיַּשְׁקֵ֗ף אֲבִימֶ֨לֶךְ֙ מֶ֣לֶךְ פְּלִשְׁתִּ֔ים בְּעַ֖ד הַֽחַלּ֑וֹן וַיַּ֗רְא וְהִנֵּ֤ה יִצְחָק֙ מְצַחֵ֔ק אֵ֖ת רִבְקָ֥ה אִשְׁתּֽוֹ׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּקְרָ֨א אֲבִימֶ֜לֶךְ לְיִצְחָ֗ק וַיֹּ֨אמֶר֙ אַ֣ךְ הִנֵּ֤ה אִשְׁתְּךָ֙ הִ֔וא וְאֵ֥יךְ אָמַ֖רְתָּ אֲחֹ֣תִי הִ֑וא וַיֹּ֤אמֶר אֵלָיו֙ יִצְחָ֔ק כִּ֣י אָמַ֔רְתִּי פֶּן־אָמ֖וּת עָלֶֽיהָ׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֣אמֶר אֲבִימֶ֔לֶךְ מַה־זֹּ֖את עָשִׂ֣יתָ לָּ֑נוּ כִּ֠מְעַט שָׁכַ֞ב אַחַ֤ד הָעָם֙ אֶת־אִשְׁתֶּ֔ךָ וְהֵֽבֵאתָ֥ עָלֵ֖ינוּ אָשָֽׁם׃"
          },
          {
            "num": "יא",
            "txt": "וַיְצַ֣ו אֲבִימֶ֔לֶךְ אֶת־כָּל־הָעָ֖ם לֵאמֹ֑ר הַנֹּגֵ֜עַ בָּאִ֥ישׁ הַזֶּ֛ה וּבְאִשְׁתּ֖וֹ מ֥וֹת יוּמָֽת׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּזְרַ֤ע יִצְחָק֙ בָּאָ֣רֶץ הַהִ֔וא וַיִּמְצָ֛א בַּשָּׁנָ֥ה הַהִ֖וא מֵאָ֣ה שְׁעָרִ֑ים וַֽיְבָרְכֵ֖הוּ יְהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּגְדַּ֖ל הָאִ֑ישׁ וַיֵּ֤לֶךְ הָלוֹךְ֙ וְגָדֵ֔ל עַ֥ד כִּֽי־גָדַ֖ל מְאֹֽד׃"
          },
          {
            "num": "יד",
            "txt": "וַֽיְהִי־ל֤וֹ מִקְנֵה־צֹאן֙ וּמִקְנֵ֣ה בָקָ֔ר וַֽעֲבֻדָּ֖ה רַבָּ֑ה וַיְקַנְא֥וּ אֹת֖וֹ פְּלִשְׁתִּֽים׃"
          },
          {
            "num": "טו",
            "txt": "וְכָל־הַבְּאֵרֹ֗ת אֲשֶׁ֤ר חָֽפְרוּ֙ עַבְדֵ֣י אָבִ֔יו בִּימֵ֖י אַבְרָהָ֣ם אָבִ֑יו סִתְּמ֣וּם פְּלִשְׁתִּ֔ים וַיְמַלְא֖וּם עָפָֽר׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֥אמֶר אֲבִימֶ֖לֶךְ אֶל־יִצְחָ֑ק לֵ֚ךְ מֵֽעִמָּ֔נוּ כִּֽי־עָצַ֥מְתָּ מִמֶּ֖נּוּ מְאֹֽד׃"
          },
          {
            "num": "יז",
            "txt": "וַיֵּ֥לֶךְ מִשָּׁ֖ם יִצְחָ֑ק וַיִּ֥חַן בְּנַֽחַל־גְּרָ֖ר וַיֵּ֥שֶׁב שָֽׁם׃"
          },
          {
            "num": "יח",
            "txt": "וַיָּ֨שָׁב יִצְחָ֜ק וַיַּחְפֹּ֣ר ׀ אֶת־בְּאֵרֹ֣ת הַמַּ֗יִם אֲשֶׁ֤ר חָֽפְרוּ֙ בִּימֵי֙ אַבְרָהָ֣ם אָבִ֔יו וַיְסַתְּמ֣וּם פְּלִשְׁתִּ֔ים אַֽחֲרֵ֖י מ֣וֹת אַבְרָהָ֑ם וַיִּקְרָ֤א לָהֶן֙ שֵׁמ֔וֹת כַּשֵּׁמֹ֕ת אֲשֶׁר־קָרָ֥א לָהֶ֖ן אָבִֽיו׃"
          },
          {
            "num": "יט",
            "txt": "וַיַּחְפְּר֥וּ עַבְדֵֽי־יִצְחָ֖ק בַּנָּ֑חַל וַיִּ֨מְצְאוּ־שָׁ֔ם בְּאֵ֖ר מַ֥יִם חַיִּֽים׃"
          },
          {
            "num": "כ",
            "txt": "וַיָּרִ֜יבוּ רֹעֵ֣י גְרָ֗ר עִם־רֹעֵ֥י יִצְחָ֛ק לֵאמֹ֖ר לָ֣נוּ הַמָּ֑יִם וַיִּקְרָ֤א שֵֽׁם־הַבְּאֵר֙ עֵ֔שֶׂק כִּ֥י הִֽתְעַשְּׂק֖וּ עִמּֽוֹ׃"
          },
          {
            "num": "כא",
            "txt": "וַֽיַּחְפְּרוּ֙ בְּאֵ֣ר אַחֶ֔רֶת וַיָּרִ֖יבוּ גַּם־עָלֶ֑יהָ וַיִּקְרָ֥א שְׁמָ֖הּ שִׂטְנָֽה׃"
          },
          {
            "num": "כב",
            "txt": "וַיַּעְתֵּ֣ק מִשָּׁ֗ם וַיַּחְפֹּר֙ בְּאֵ֣ר אַחֶ֔רֶת וְלֹ֥א רָב֖וּ עָלֶ֑יהָ וַיִּקְרָ֤א שְׁמָהּ֙ רְחֹב֔וֹת וַיֹּ֗אמֶר כִּֽי־עַתָּ֞ה הִרְחִ֧יב יְהוָ֛ה לָ֖נוּ וּפָרִ֥ינוּ בָאָֽרֶץ׃"
          },
          {
            "num": "כג",
            "txt": "וַיַּ֥עַל מִשָּׁ֖ם בְּאֵ֥ר שָֽׁבַע׃"
          },
          {
            "num": "כד",
            "txt": "וַיֵּרָ֨א אֵלָ֤יו יְהוָה֙ בַּלַּ֣יְלָה הַה֔וּא וַיֹּ֕אמֶר אָֽנֹכִ֕י אֱלֹהֵ֖י אַבְרָהָ֣ם אָבִ֑יךָ אַל־תִּירָא֙ כִּֽי־אִתְּךָ֣ אָנֹ֔כִי וּבֵֽרַכְתִּ֨יךָ֙ וְהִרְבֵּיתִ֣י אֶֽת־זַרְעֲךָ֔ בַּֽעֲב֖וּר אַבְרָהָ֥ם עַבְדִּֽי׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּ֧בֶן שָׁ֣ם מִזְבֵּ֗חַ וַיִּקְרָא֙ בְּשֵׁ֣ם יְהוָ֔ה וַיֶּט־שָׁ֖ם אָֽהֳל֑וֹ וַיִּכְרוּ־שָׁ֥ם עַבְדֵֽי־יִצְחָ֖ק בְּאֵֽר׃"
          },
          {
            "num": "כו",
            "txt": "וַֽאֲבִימֶ֕לֶךְ הָלַ֥ךְ אֵלָ֖יו מִגְּרָ֑ר וַֽאֲחֻזַּת֙ מֵֽרֵעֵ֔הוּ וּפִיכֹ֖ל שַׂר־צְבָאֽוֹ׃"
          },
          {
            "num": "כז",
            "txt": "וַיֹּ֤אמֶר אֲלֵהֶם֙ יִצְחָ֔ק מַדּ֖וּעַ בָּאתֶ֣ם אֵלָ֑י וְאַתֶּם֙ שְׂנֵאתֶ֣ם אֹתִ֔י וַתְּשַׁלְּח֖וּנִי מֵֽאִתְּכֶֽם׃"
          },
          {
            "num": "כח",
            "txt": "וַיֹּֽאמְר֗וּ רָא֣וֹ רָאִינוּ֮ כִּֽי־הָיָ֣ה יְהוָ֣ה ׀ עִמָּךְ֒ וַנֹּ֗אמֶר תְּהִ֨י נָ֥א אָלָ֛ה בֵּֽינוֹתֵ֖ינוּ בֵּינֵ֣ינוּ וּבֵינֶ֑ךָ וְנִכְרְתָ֥ה בְרִ֖ית עִמָּֽךְ׃"
          },
          {
            "num": "כט",
            "txt": "אִם־תַּֽעֲשֵׂ֨ה עִמָּ֜נוּ רָעָ֗ה כַּֽאֲשֶׁר֙ לֹ֣א נְגַֽעֲנ֔וּךָ וְכַֽאֲשֶׁ֨ר עָשִׂ֤ינוּ עִמְּךָ֙ רַק־ט֔וֹב וַנְּשַׁלֵּֽחֲךָ֖ בְּשָׁל֑וֹם אַתָּ֥ה עַתָּ֖ה בְּר֥וּךְ יְהוָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וַיַּ֤עַשׂ לָהֶם֙ מִשְׁתֶּ֔ה וַיֹּֽאכְל֖וּ וַיִּשְׁתּֽוּ׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּשְׁכִּ֣ימוּ בַבֹּ֔קֶר וַיִּשָּֽׁבְע֖וּ אִ֣ישׁ לְאָחִ֑יו וַיְשַׁלְּחֵ֣ם יִצְחָ֔ק וַיֵּֽלְכ֥וּ מֵֽאִתּ֖וֹ בְּשָׁלֽוֹם׃"
          },
          {
            "num": "לב",
            "txt": "וַיְהִ֣י ׀ בַּיּ֣וֹם הַה֗וּא וַיָּבֹ֨אוּ֙ עַבְדֵ֣י יִצְחָ֔ק וַיַּגִּ֣דוּ ל֔וֹ עַל־אֹד֥וֹת הַבְּאֵ֖ר אֲשֶׁ֣ר חָפָ֑רוּ וַיֹּ֥אמְרוּ ל֖וֹ מָצָ֥אנוּ מָֽיִם׃"
          },
          {
            "num": "לג",
            "txt": "וַיִּקְרָ֥א אֹתָ֖הּ שִׁבְעָ֑ה עַל־כֵּ֤ן שֵׁם־הָעִיר֙ בְּאֵ֣ר שֶׁ֔בַע עַ֖ד הַיּ֥וֹם הַזֶּֽה׃ {ס}"
          },
          {
            "num": "לד",
            "txt": "וַיְהִ֤י עֵשָׂו֙ בֶּן־אַרְבָּעִ֣ים שָׁנָ֔ה וַיִּקַּ֤ח אִשָּׁה֙ אֶת־יְהוּדִ֔ית בַּת־בְּאֵרִ֖י הַֽחִתִּ֑י וְאֶת־בָּ֣שְׂמַ֔ת בַּת־אֵילֹ֖ן הַֽחִתִּֽי׃"
          },
          {
            "num": "לה",
            "txt": "וַתִּֽהְיֶ֖יןָ מֹ֣רַת ר֑וּחַ לְיִצְחָ֖ק וּלְרִבְקָֽה׃ {ס}"
          }
        ]
      },
      {
        "num": "כז",
        "verses": [
          {
            "num": "א",
            "txt": "וַֽיְהִי֙ כִּֽי־זָקֵ֣ן יִצְחָ֔ק וַתִּכְהֶ֥יןָ עֵינָ֖יו מֵֽרְאֹ֑ת וַיִּקְרָ֞א אֶת־עֵשָׂ֣ו ׀ בְּנ֣וֹ הַגָּדֹ֗ל וַיֹּ֤אמֶר אֵלָיו֙ בְּנִ֔י וַיֹּ֥אמֶר אֵלָ֖יו הִנֵּֽנִי׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֕אמֶר הִנֵּה־נָ֖א זָקַ֑נְתִּי לֹ֥א יָדַ֖עְתִּי י֥וֹם מוֹתִֽי׃"
          },
          {
            "num": "ג",
            "txt": "וְעַתָּה֙ שָׂא־נָ֣א כֵלֶ֔יךָ תֶּלְיְךָ֖ וְקַשְׁתֶּ֑ךָ וְצֵא֙ הַשָּׂדֶ֔ה וְצ֥וּדָה לִּ֖י צידה (צָֽיִד)׃"
          },
          {
            "num": "ד",
            "txt": "וַֽעֲשֵׂה־לִ֨י מַטְעַמִּ֜ים כַּֽאֲשֶׁ֥ר אָהַ֛בְתִּי וְהָבִ֥יאָה לִּ֖י וְאֹכֵ֑לָה בַּֽעֲב֛וּר תְּבָֽרֶכְךָ֥ נַפְשִׁ֖י בְּטֶ֥רֶם אָמֽוּת׃"
          },
          {
            "num": "ה",
            "txt": "וְרִבְקָ֣ה שֹׁמַ֔עַת בְּדַבֵּ֣ר יִצְחָ֔ק אֶל־עֵשָׂ֖ו בְּנ֑וֹ וַיֵּ֤לֶךְ עֵשָׂו֙ הַשָּׂדֶ֔ה לָצ֥וּד צַ֖יִד לְהָבִֽיא׃"
          },
          {
            "num": "ו",
            "txt": "וְרִבְקָה֙ אָֽמְרָ֔ה אֶל־יַֽעֲקֹ֥ב בְּנָ֖הּ לֵאמֹ֑ר הִנֵּ֤ה שָׁמַ֨עְתִּי֙ אֶת־אָבִ֔יךָ מְדַבֵּ֛ר אֶל־עֵשָׂ֥ו אָחִ֖יךָ לֵאמֹֽר׃"
          },
          {
            "num": "ז",
            "txt": "הָבִ֨יאָה לִּ֥י צַ֛יִד וַֽעֲשֵׂה־לִ֥י מַטְעַמִּ֖ים וְאֹכֵ֑לָה וַאֲבָֽרֶכְכָ֛ה לִפְנֵ֥י יְהוָ֖ה לִפְנֵ֥י מוֹתִֽי׃"
          },
          {
            "num": "ח",
            "txt": "וְעַתָּ֥ה בְנִ֖י שְׁמַ֣ע בְּקֹלִ֑י לַֽאֲשֶׁ֥ר אֲנִ֖י מְצַוָּ֥ה אֹתָֽךְ׃"
          },
          {
            "num": "ט",
            "txt": "לֶךְ־נָא֙ אֶל־הַצֹּ֔אן וְקַֽח־לִ֣י מִשָּׁ֗ם שְׁנֵ֛י גְּדָיֵ֥י עִזִּ֖ים טֹבִ֑ים וְאֶֽעֱשֶׂ֨ה אֹתָ֧ם מַטְעַמִּ֛ים לְאָבִ֖יךָ כַּֽאֲשֶׁ֥ר אָהֵֽב׃"
          },
          {
            "num": "י",
            "txt": "וְהֵֽבֵאתָ֥ לְאָבִ֖יךָ וְאָכָ֑ל בַּֽעֲבֻ֛ר אֲשֶׁ֥ר יְבָֽרֶכְךָ֖ לִפְנֵ֥י מוֹתֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֣אמֶר יַֽעֲקֹ֔ב אֶל־רִבְקָ֖ה אִמּ֑וֹ הֵ֣ן עֵשָׂ֤ו אָחִי֙ אִ֣ישׁ שָׂעִ֔ר וְאָֽנֹכִ֖י אִ֥ישׁ חָלָֽק׃"
          },
          {
            "num": "יב",
            "txt": "אוּלַ֤י יְמֻשֵּׁ֨נִי֙ אָבִ֔י וְהָיִ֥יתִי בְעֵינָ֖יו כִּמְתַעְתֵּ֑עַ וְהֵֽבֵאתִ֥י עָלַ֛י קְלָלָ֖ה וְלֹ֥א בְרָכָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וַתֹּ֤אמֶר לוֹ֙ אִמּ֔וֹ עָלַ֥י קִלְלָֽתְךָ֖ בְּנִ֑י אַ֛ךְ שְׁמַ֥ע בְּקֹלִ֖י וְלֵ֥ךְ קַֽח־לִֽי׃"
          },
          {
            "num": "יד",
            "txt": "וַיֵּ֨לֶךְ֙ וַיִּקַּ֔ח וַיָּבֵ֖א לְאִמּ֑וֹ וַתַּ֤עַשׂ אִמּוֹ֙ מַטְעַמִּ֔ים כַּֽאֲשֶׁ֖ר אָהֵ֥ב אָבִֽיו׃"
          },
          {
            "num": "טו",
            "txt": "וַתִּקַּ֣ח רִ֠בְקָה אֶת־בִּגְדֵ֨י עֵשָׂ֜ו בְּנָ֤הּ הַגָּדֹל֙ הַֽחֲמֻדֹ֔ת אֲשֶׁ֥ר אִתָּ֖הּ בַּבָּ֑יִת וַתַּלְבֵּ֥שׁ אֶֽת־יַעֲקֹ֖ב בְּנָ֥הּ הַקָּטָֽן׃"
          },
          {
            "num": "טז",
            "txt": "וְאֵ֗ת עֹרֹת֙ גְּדָיֵ֣י הָֽעִזִּ֔ים הִלְבִּ֖ישָׁה עַל־יָדָ֑יו וְעַ֖ל חֶלְקַ֥ת צַוָּארָֽיו׃"
          },
          {
            "num": "יז",
            "txt": "וַתִּתֵּ֧ן אֶת־הַמַּטְעַמִּ֛ים וְאֶת־הַלֶּ֖חֶם אֲשֶׁ֣ר עָשָׂ֑תָה בְּיַ֖ד יַֽעֲקֹ֥ב בְּנָֽהּ׃"
          },
          {
            "num": "יח",
            "txt": "וַיָּבֹ֥א אֶל־אָבִ֖יו וַיֹּ֣אמֶר אָבִ֑י וַיֹּ֣אמֶר הִנֶּ֔נִּי מִ֥י אַתָּ֖ה בְּנִֽי׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֨אמֶר יַֽעֲקֹ֜ב אֶל־אָבִ֗יו אָֽנֹכִי֙ עֵשָׂ֣ו בְּכֹרֶ֔ךָ עָשִׂ֕יתִי כַּֽאֲשֶׁ֥ר דִּבַּ֖רְתָּ אֵלָ֑י קֽוּם־נָ֣א שְׁבָ֗ה וְאָכְלָה֙ מִצֵּידִ֔י בַּֽעֲב֖וּר תְּבָֽרְכַ֥נִּי נַפְשֶֽׁךָ׃"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֤אמֶר יִצְחָק֙ אֶל־בְּנ֔וֹ מַה־זֶּ֛ה מִהַ֥רְתָּ לִמְצֹ֖א בְּנִ֑י וַיֹּ֕אמֶר כִּ֥י הִקְרָ֛ה יְהוָ֥ה אֱלֹהֶ֖יךָ לְפָנָֽי׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֤אמֶר יִצְחָק֙ אֶֽל־יַעֲקֹ֔ב גְּשָׁה־נָּ֥א וַאֲמֻֽשְׁךָ֖ בְּנִ֑י הַֽאַתָּ֥ה זֶ֛ה בְּנִ֥י עֵשָׂ֖ו אִם־לֹֽא׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּגַּ֧שׁ יַֽעֲקֹ֛ב אֶל־יִצְחָ֥ק אָבִ֖יו וַיְמֻשֵּׁ֑הוּ וַיֹּ֗אמֶר הַקֹּל֙ ק֣וֹל יַֽעֲקֹ֔ב וְהַיָּדַ֖יִם יְדֵ֥י עֵשָֽׂו׃"
          },
          {
            "num": "כג",
            "txt": "וְלֹ֣א הִכִּיר֔וֹ כִּֽי־הָי֣וּ יָדָ֗יו כִּידֵ֛י עֵשָׂ֥ו אָחִ֖יו שְׂעִרֹ֑ת וַֽיְבָרְכֵֽהוּ׃"
          },
          {
            "num": "כד",
            "txt": "וַיֹּ֕אמֶר אַתָּ֥ה זֶ֖ה בְּנִ֣י עֵשָׂ֑ו וַיֹּ֖אמֶר אָֽנִי׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֗אמֶר הַגִּ֤שָׁה לִּי֙ וְאֹֽכְלָה֙ מִצֵּ֣יד בְּנִ֔י לְמַ֥עַן תְּבָֽרֶכְךָ֖ נַפְשִׁ֑י וַיַּגֶּשׁ־לוֹ֙ וַיֹּאכַ֔ל וַיָּ֧בֵא ל֦וֹ יַ֖יִן וַיֵּֽשְׁתְּ׃"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֥אמֶר אֵלָ֖יו יִצְחָ֣ק אָבִ֑יו גְּשָׁה־נָּ֥א וּשְׁקָה־לִּ֖י בְּנִֽי׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּגַּשׁ֙ וַיִּשַּׁק־ל֔וֹ וַיָּ֛רַח אֶת־רֵ֥יחַ בְּגָדָ֖יו וַֽיְבָרְכֵ֑הוּ וַיֹּ֗אמֶר רְאֵה֙ רֵ֣יחַ בְּנִ֔י כְּרֵ֣יחַ שָׂדֶ֔ה אֲשֶׁ֥ר בֵּֽרְכ֖וֹ יְהוָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וְיִֽתֶּן־לְךָ֙ הָֽאֱלֹהִ֔ים מִטַּל֙ הַשָּׁמַ֔יִם וּמִשְׁמַנֵּ֖י הָאָ֑רֶץ וְרֹ֥ב דָּגָ֖ן וְתִירֹֽשׁ׃"
          },
          {
            "num": "כט",
            "txt": "יַֽעַבְד֣וּךָ עַמִּ֗ים וישתחו (וְיִֽשְׁתַּחֲו֤וּ) לְךָ֙ לְאֻמִּ֔ים הֱוֵ֤ה גְבִיר֙ לְאַחֶ֔יךָ וְיִשְׁתַּֽחֲו֥וּ לְךָ֖ בְּנֵ֣י אִמֶּ֑ךָ אֹֽרְרֶ֣יךָ אָר֔וּר וּֽמְבָרְכֶ֖יךָ בָּרֽוּךְ׃"
          },
          {
            "num": "ל",
            "txt": "וַיְהִ֗י כַּֽאֲשֶׁ֨ר כִּלָּ֣ה יִצְחָק֮ לְבָרֵ֣ךְ אֶֽת־יַעֲקֹב֒ וַיְהִ֗י אַ֣ךְ יָצֹ֤א יָצָא֙ יַֽעֲקֹ֔ב מֵאֵ֥ת פְּנֵ֖י יִצְחָ֣ק אָבִ֑יו וְעֵשָׂ֣ו אָחִ֔יו בָּ֖א מִצֵּידֽוֹ׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּ֤עַשׂ גַּם־הוּא֙ מַטְעַמִּ֔ים וַיָּבֵ֖א לְאָבִ֑יו וַיֹּ֣אמֶר לְאָבִ֗יו יָקֻ֤ם אָבִי֙ וְיֹאכַל֙ מִצֵּ֣יד בְּנ֔וֹ בַּֽעֲבֻ֖ר תְּבָֽרְכַ֥נִּי נַפְשֶֽׁךָ׃"
          },
          {
            "num": "לב",
            "txt": "וַיֹּ֥אמֶר ל֛וֹ יִצְחָ֥ק אָבִ֖יו מִי־אָ֑תָּה וַיֹּ֕אמֶר אֲנִ֛י בִּנְךָ֥ בְכֹֽרְךָ֖ עֵשָֽׂו׃"
          },
          {
            "num": "לג",
            "txt": "וַיֶּֽחֱרַ֨ד יִצְחָ֣ק חֲרָדָה֮ גְּדֹלָ֣ה עַד־מְאֹד֒ וַיֹּ֡אמֶר מִֽי־אֵפ֡וֹא ה֣וּא הַצָּֽד־צַיִד֩ וַיָּ֨בֵא לִ֜י וָֽאֹכַ֥ל מִכֹּ֛ל בְּטֶ֥רֶם תָּב֖וֹא וָאֲבָֽרְכֵ֑הוּ גַּם־בָּר֖וּךְ יִֽהְיֶֽה׃"
          },
          {
            "num": "לד",
            "txt": "כִּשְׁמֹ֤עַ עֵשָׂו֙ אֶת־דִּבְרֵ֣י אָבִ֔יו וַיִּצְעַ֣ק צְעָקָ֔ה גְּדֹלָ֥ה וּמָרָ֖ה עַד־מְאֹ֑ד וַיֹּ֣אמֶר לְאָבִ֔יו בָּֽרְכֵ֥נִי גַם־אָ֖נִי אָבִֽי׃"
          },
          {
            "num": "לה",
            "txt": "וַיֹּ֕אמֶר בָּ֥א אָחִ֖יךָ בְּמִרְמָ֑ה וַיִּקַּ֖ח בִּרְכָתֶֽךָ׃"
          },
          {
            "num": "לו",
            "txt": "וַיֹּ֡אמֶר הֲכִי֩ קָרָ֨א שְׁמ֜וֹ יַֽעֲקֹ֗ב וַֽיַּעְקְבֵ֨נִי֙ זֶ֣ה פַֽעֲמַ֔יִם אֶת־בְּכֹֽרָתִ֣י לָקָ֔ח וְהִנֵּ֥ה עַתָּ֖ה לָקַ֣ח בִּרְכָתִ֑י וַיֹּאמַ֕ר הֲלֹֽא־אָצַ֥לְתָּ לִּ֖י בְּרָכָֽה׃"
          },
          {
            "num": "לז",
            "txt": "וַיַּ֨עַן יִצְחָ֜ק וַיֹּ֣אמֶר לְעֵשָׂ֗ו הֵ֣ן גְּבִ֞יר שַׂמְתִּ֥יו לָךְ֙ וְאֶת־כָּל־אֶחָ֗יו נָתַ֤תִּי לוֹ֙ לַֽעֲבָדִ֔ים וְדָגָ֥ן וְתִירֹ֖שׁ סְמַכְתִּ֑יו וּלְכָ֣ה אֵפ֔וֹא מָ֥ה אֶֽעֱשֶׂ֖ה בְּנִֽי׃"
          },
          {
            "num": "לח",
            "txt": "וַיֹּ֨אמֶר עֵשָׂ֜ו אֶל־אָבִ֗יו הַֽבְרָכָ֨ה אַחַ֤ת הִֽוא־לְךָ֙ אָבִ֔י בָּֽרְכֵ֥נִי גַם־אָ֖נִי אָבִ֑י וַיִּשָּׂ֥א עֵשָׂ֛ו קֹל֖וֹ וַיֵּֽבְךְּ׃"
          },
          {
            "num": "לט",
            "txt": "וַיַּ֛עַן יִצְחָ֥ק אָבִ֖יו וַיֹּ֣אמֶר אֵלָ֑יו הִנֵּ֞ה מִשְׁמַנֵּ֤י הָאָ֨רֶץ֙ יִֽהְיֶ֣ה מֽוֹשָׁבֶ֔ךָ וּמִטַּ֥ל הַשָּׁמַ֖יִם מֵעָֽל׃"
          },
          {
            "num": "מ",
            "txt": "וְעַל־חַרְבְּךָ֣ תִֽחְיֶ֔ה וְאֶת־אָחִ֖יךָ תַּֽעֲבֹ֑ד וְהָיָה֙ כַּֽאֲשֶׁ֣ר תָּרִ֔יד וּפָֽרַקְתָּ֥ עֻלּ֖וֹ מֵעַ֥ל צַוָּארֶֽךָ׃"
          },
          {
            "num": "מא",
            "txt": "וַיִּשְׂטֹ֤ם עֵשָׂו֙ אֶֽת־יַעֲקֹ֔ב עַ֨ל־הַבְּרָכָ֔ה אֲשֶׁ֥ר בֵּֽרְכ֖וֹ אָבִ֑יו וַיֹּ֨אמֶר עֵשָׂ֜ו בְּלִבּ֗וֹ יִקְרְבוּ֙ יְמֵי֙ אֵ֣בֶל אָבִ֔י וְאַֽהַרְגָ֖ה אֶת־יַֽעֲקֹ֥ב אָחִֽי׃"
          },
          {
            "num": "מב",
            "txt": "וַיֻּגַּ֣ד לְרִבְקָ֔ה אֶת־דִּבְרֵ֥י עֵשָׂ֖ו בְּנָ֣הּ הַגָּדֹ֑ל וַתִּשְׁלַ֞ח וַתִּקְרָ֤א לְיַֽעֲקֹב֙ בְּנָ֣הּ הַקָּטָ֔ן וַתֹּ֣אמֶר אֵלָ֔יו הִנֵּה֙ עֵשָׂ֣ו אָחִ֔יךָ מִתְנַחֵ֥ם לְךָ֖ לְהָרְגֶֽךָ׃"
          },
          {
            "num": "מג",
            "txt": "וְעַתָּ֥ה בְנִ֖י שְׁמַ֣ע בְּקֹלִ֑י וְק֧וּם בְּרַח־לְךָ֛ אֶל־לָבָ֥ן אָחִ֖י חָרָֽנָה׃"
          },
          {
            "num": "מד",
            "txt": "וְיָֽשַׁבְתָּ֥ עִמּ֖וֹ יָמִ֣ים אֲחָדִ֑ים עַ֥ד אֲשֶׁר־תָּשׁ֖וּב חֲמַ֥ת אָחִֽיךָ׃"
          },
          {
            "num": "מה",
            "txt": "עַד־שׁ֨וּב אַף־אָחִ֜יךָ מִמְּךָ֗ וְשָׁכַח֙ אֵ֣ת אֲשֶׁר־עָשִׂ֣יתָ לּ֔וֹ וְשָֽׁלַחְתִּ֖י וּלְקַחְתִּ֣יךָ מִשָּׁ֑ם לָמָ֥ה אֶשְׁכַּ֛ל גַּם־שְׁנֵיכֶ֖ם י֥וֹם אֶחָֽד׃"
          },
          {
            "num": "מו",
            "txt": "וַתֹּ֤אמֶר רִבְקָה֙ אֶל־יִצְחָ֔ק"
          },
          {
            "txt": "צְתִּי בְחַיַּ֔י מִפְּנֵ֖י בְּנ֣וֹת חֵ֑ת אִם־לֹקֵ֣חַ יַֽ֠עֲקֹב אִשָּׁ֨ה מִבְּנֽוֹת־חֵ֤ת כָּאֵ֨לֶּה֙ מִבְּנ֣וֹת הָאָ֔רֶץ לָ֥מָּה לִּ֖י חַיִּֽים׃"
          }
        ]
      },
      {
        "num": "כח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּקְרָ֥א יִצְחָ֛ק אֶֽל־יַעֲקֹ֖ב וַיְבָ֣רֶךְ אֹת֑וֹ וַיְצַוֵּ֨הוּ֙ וַיֹּ֣אמֶר ל֔וֹ לֹֽא־תִקַּ֥ח אִשָּׁ֖ה מִבְּנ֥וֹת כְּנָֽעַן׃"
          },
          {
            "num": "ב",
            "txt": "ק֥וּם לֵךְ֙ פַּדֶּ֣נָֽה אֲרָ֔ם בֵּ֥יתָה בְתוּאֵ֖ל אֲבִ֣י אִמֶּ֑ךָ וְקַח־לְךָ֤ מִשָּׁם֙ אִשָּׁ֔ה מִבְּנ֥וֹת לָבָ֖ן אֲחִ֥י אִמֶּֽךָ׃"
          },
          {
            "num": "ג",
            "txt": "וְאֵ֤ל שַׁדַּי֙ יְבָרֵ֣ךְ אֹֽתְךָ֔ וְיַפְרְךָ֖ וְיַרְבֶּ֑ךָ וְהָיִ֖יתָ לִקְהַ֥ל עַמִּֽים׃"
          },
          {
            "num": "ד",
            "txt": "וְיִֽתֶּן־לְךָ֙ אֶת־בִּרְכַּ֣ת אַבְרָהָ֔ם לְךָ֖ וּלְזַרְעֲךָ֣ אִתָּ֑ךְ לְרִשְׁתְּךָ֙ אֶת־אֶ֣רֶץ מְגֻרֶ֔יךָ אֲשֶׁר־נָתַ֥ן אֱלֹהִ֖ים לְאַבְרָהָֽם׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּשְׁלַ֤ח יִצְחָק֙ אֶֽת־יַעֲקֹ֔ב וַיֵּ֖לֶךְ פַּדֶּ֣נָֽה אֲרָ֑ם אֶל־לָבָ֤ן בֶּן־בְּתוּאֵל֙ הָֽאֲרַמִּ֔י אֲחִ֣י רִבְקָ֔ה אֵ֥ם יַֽעֲקֹ֖ב וְעֵשָֽׂו׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּ֣רְא עֵשָׂ֗ו כִּֽי־בֵרַ֣ךְ יִצְחָק֮ אֶֽת־יַעֲקֹב֒ וְשִׁלַּ֤ח אֹתוֹ֙ פַּדֶּ֣נָֽה אֲרָ֔ם לָקַֽחַת־ל֥וֹ מִשָּׁ֖ם אִשָּׁ֑ה בְּבָֽרְכ֣וֹ אֹת֔וֹ וַיְצַ֤ו עָלָיו֙ לֵאמֹ֔ר לֹֽא־תִקַּ֥ח אִשָּׁ֖ה מִבְּנ֥וֹת כְּנָֽעַן׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּשְׁמַ֣ע יַֽעֲקֹ֔ב אֶל־אָבִ֖יו וְאֶל־אִמּ֑וֹ וַיֵּ֖לֶךְ פַּדֶּ֥נָֽה אֲרָֽם׃"
          },
          {
            "num": "ח",
            "txt": "וַיַּ֣רְא עֵשָׂ֔ו כִּ֥י רָע֖וֹת בְּנ֣וֹת כְּנָ֑עַן בְּעֵינֵ֖י יִצְחָ֥ק אָבִֽיו׃"
          },
          {
            "num": "ט",
            "txt": "וַיֵּ֥לֶךְ עֵשָׂ֖ו אֶל־יִשְׁמָעֵ֑אל וַיִּקַּ֡ח אֶֽת־מָחֲלַ֣ת ׀ בַּת־יִשְׁמָעֵ֨אל בֶּן־אַבְרָהָ֜ם אֲח֧וֹת נְבָי֛וֹת עַל־נָשָׁ֖יו ל֥וֹ לְאִשָּֽׁה׃ {ס}"
          },
          {
            "num": "י",
            "txt": "וַיֵּצֵ֥א יַֽעֲקֹ֖ב מִבְּאֵ֣ר שָׁ֑בַע וַיֵּ֖לֶךְ חָרָֽנָה׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּפְגַּ֨ע בַּמָּק֜וֹם וַיָּ֤לֶן שָׁם֙ כִּי־בָ֣א הַשֶּׁ֔מֶשׁ וַיִּקַּח֙ מֵֽאַבְנֵ֣י הַמָּק֔וֹם וַיָּ֖שֶׂם מְרַֽאֲשֹׁתָ֑יו וַיִּשְׁכַּ֖ב בַּמָּק֥וֹם הַהֽוּא׃"
          },
          {
            "num": "יב",
            "txt": "וַֽיַּחֲלֹ֗ם וְהִנֵּ֤ה סֻלָּם֙ מֻצָּ֣ב אַ֔רְצָה וְרֹאשׁ֖וֹ מַגִּ֣יעַ הַשָּׁמָ֑יְמָה וְהִנֵּה֙ מַלְאֲכֵ֣י אֱלֹהִ֔ים עֹלִ֥ים וְיֹֽרְדִ֖ים בּֽוֹ׃"
          },
          {
            "num": "יג",
            "txt": "וְהִנֵּ֨ה יְהוָ֜ה נִצָּ֣ב עָלָיו֮ וַיֹּאמַר֒ אֲנִ֣י יְהוָ֗ה אֱלֹהֵי֙ אַבְרָהָ֣ם אָבִ֔יךָ וֵֽאלֹהֵ֖י יִצְחָ֑ק הָאָ֗רֶץ אֲשֶׁ֤ר אַתָּה֙ שֹׁכֵ֣ב עָלֶ֔יהָ לְךָ֥ אֶתְּנֶ֖נָּה וּלְזַרְעֶֽךָ׃"
          },
          {
            "num": "יד",
            "txt": "וְהָיָ֤ה זַרְעֲךָ֙ כַּֽעֲפַ֣ר הָאָ֔רֶץ וּפָֽרַצְתָּ֛ יָ֥מָּה וָקֵ֖דְמָה וְצָפֹ֣נָה וָנֶ֑גְבָּה וְנִבְרְכ֥וּ בְךָ֛ כָּל־מִשְׁפְּחֹ֥ת הָֽאֲדָמָ֖ה וּבְזַרְעֶֽךָ׃"
          },
          {
            "num": "טו",
            "txt": "וְהִנֵּ֨ה אָֽנֹכִ֜י עִמָּ֗ךְ וּשְׁמַרְתִּ֨יךָ֙ בְּכֹ֣ל אֲשֶׁר־תֵּלֵ֔ךְ וַהֲשִׁ֣בֹתִ֔יךָ אֶל־הָֽאֲדָמָ֖ה הַזֹּ֑את כִּ֚י לֹ֣א אֶֽעֱזָבְךָ֔ עַ֚ד אֲשֶׁ֣ר אִם־עָשִׂ֔יתִי אֵ֥ת אֲשֶׁר־דִּבַּ֖רְתִּי לָֽךְ׃"
          },
          {
            "num": "טז",
            "txt": "וַיִּיקַ֣ץ יַֽעֲקֹב֮ מִשְּׁנָתוֹ֒ וַיֹּ֕אמֶר אָכֵן֙ יֵ֣שׁ יְהוָ֔ה בַּמָּק֖וֹם הַזֶּ֑ה וְאָֽנֹכִ֖י לֹ֥א יָדָֽעְתִּי׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּירָא֙ וַיֹּאמַ֔ר מַה־נּוֹרָ֖א הַמָּק֣וֹם הַזֶּ֑ה אֵ֣ין זֶ֗ה כִּ֚י אִם־בֵּ֣ית אֱלֹהִ֔ים וְזֶ֖ה שַׁ֥עַר הַשָּׁמָֽיִם׃"
          },
          {
            "num": "יח",
            "txt": "וַיַּשְׁכֵּ֨ם יַֽעֲקֹ֜ב בַּבֹּ֗קֶר וַיִּקַּ֤ח אֶת־הָאֶ֨בֶן֙ אֲשֶׁר־שָׂ֣ם מְרַֽאֲשֹׁתָ֔יו וַיָּ֥שֶׂם אֹתָ֖הּ מַצֵּבָ֑ה וַיִּצֹ֥ק שֶׁ֖מֶן עַל־רֹאשָֽׁהּ׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּקְרָ֛א אֶת־שֵֽׁם־הַמָּק֥וֹם הַה֖וּא בֵּֽית־אֵ֑ל וְאוּלָ֛ם ל֥וּז שֵׁם־הָעִ֖יר לָרִֽאשֹׁנָֽה׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּדַּ֥ר יַֽעֲקֹ֖ב נֶ֣דֶר לֵאמֹ֑ר אִם־יִֽהְיֶ֨ה אֱלֹהִ֜ים עִמָּדִ֗י וּשְׁמָרַ֨נִי֙ בַּדֶּ֤רֶךְ הַזֶּה֙ אֲשֶׁ֣ר אָֽנֹכִ֣י הוֹלֵ֔ךְ וְנָֽתַן־לִ֥י לֶ֛חֶם לֶֽאֱכֹ֖ל וּבֶ֥גֶד לִלְבֹּֽשׁ׃"
          },
          {
            "num": "כא",
            "txt": "וְשַׁבְתִּ֥י בְשָׁל֖וֹם אֶל־בֵּ֣ית אָבִ֑י וְהָיָ֧ה יְהוָ֛ה לִ֖י לֵֽאלֹהִֽים׃"
          },
          {
            "num": "כב",
            "txt": "וְהָאֶ֣בֶן הַזֹּ֗את אֲשֶׁר־שַׂ֨מְתִּי֙ מַצֵּבָ֔ה יִֽהְיֶ֖ה בֵּ֣ית אֱלֹהִ֑ים וְכֹל֙ אֲשֶׁ֣ר תִּתֶּן־לִ֔י עַשֵּׂ֖ר אֲעַשְּׂרֶ֥נּוּ לָֽךְ׃"
          }
        ]
      },
      {
        "num": "כט",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּשָּׂ֥א יַֽעֲקֹ֖ב רַגְלָ֑יו וַיֵּ֖לֶךְ אַ֥רְצָה בְנֵי־קֶֽדֶם׃"
          },
          {
            "num": "ב",
            "txt": "וַיַּ֞רְא וְהִנֵּ֧ה בְאֵ֣ר בַּשָּׂדֶ֗ה וְהִנֵּה־שָׁ֞ם שְׁלֹשָׁ֤ה עֶדְרֵי־צֹאן֙ רֹֽבְצִ֣ים עָלֶ֔יהָ כִּ֚י מִן־הַבְּאֵ֣ר הַהִ֔וא יַשְׁק֖וּ הָֽעֲדָרִ֑ים וְהָאֶ֥בֶן גְּדֹלָ֖ה עַל־פִּ֥י הַבְּאֵֽר׃"
          },
          {
            "num": "ג",
            "txt": "וְנֶֽאֶסְפוּ־שָׁ֣מָּה כָל־הָֽעֲדָרִ֗ים וְגָֽלְל֤וּ אֶת־הָאֶ֨בֶן֙ מֵעַל֙ פִּ֣י הַבְּאֵ֔ר וְהִשְׁק֖וּ אֶת־הַצֹּ֑אן וְהֵשִׁ֧יבוּ אֶת־הָאֶ֛בֶן עַל־פִּ֥י הַבְּאֵ֖ר לִמְקֹמָֽהּ׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֤אמֶר לָהֶם֙ יַֽעֲקֹ֔ב אַחַ֖י מֵאַ֣יִן אַתֶּ֑ם וַיֹּ֣אמְר֔וּ מֵֽחָרָ֖ן אֲנָֽחְנוּ׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֣אמֶר לָהֶ֔ם הַיְדַעְתֶּ֖ם אֶת־לָבָ֣ן בֶּן־נָח֑וֹר וַיֹּֽאמְר֖וּ יָדָֽעְנוּ׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֥אמֶר לָהֶ֖ם הֲשָׁל֣וֹם ל֑וֹ וַיֹּֽאמְר֣וּ שָׁל֔וֹם וְהִנֵּה֙ רָחֵ֣ל בִּתּ֔וֹ בָּאָ֖ה עִם־הַצֹּֽאן׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֗אמֶר הֵ֥ן עוֹד֙ הַיּ֣וֹם גָּד֔וֹל לֹא־עֵ֖ת הֵֽאָסֵ֣ף הַמִּקְנֶ֑ה הַשְׁק֥וּ הַצֹּ֖אן וּלְכ֥וּ רְעֽוּ׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּֽאמְרוּ֮ לֹ֣א נוּכַל֒ עַ֣ד אֲשֶׁ֤ר יֵאָֽסְפוּ֙ כָּל־הָ֣עֲדָרִ֔ים וְגָֽלְלוּ֙ אֶת־הָאֶ֔בֶן מֵעַ֖ל פִּ֣י הַבְּאֵ֑ר וְהִשְׁקִ֖ינוּ הַצֹּֽאן׃"
          },
          {
            "num": "ט",
            "txt": "עוֹדֶ֖נּוּ מְדַבֵּ֣ר עִמָּ֑ם וְרָחֵ֣ל ׀ בָּ֗אָה עִם־הַצֹּאן֙ אֲשֶׁ֣ר לְאָבִ֔יהָ כִּ֥י רֹעָ֖ה הִֽוא׃"
          },
          {
            "num": "י",
            "txt": "וַיְהִ֡י כַּֽאֲשֶׁר֩ רָאָ֨ה יַֽעֲקֹ֜ב אֶת־רָחֵ֗ל בַּת־לָבָן֙ אֲחִ֣י אִמּ֔וֹ וְאֶת־צֹ֥אן לָבָ֖ן אֲחִ֣י אִמּ֑וֹ וַיִּגַּ֣שׁ יַֽעֲקֹ֗ב וַיָּ֤גֶל אֶת־הָאֶ֨בֶן֙ מֵעַל֙ פִּ֣י הַבְּאֵ֔ר וַיַּ֕שְׁקְ אֶת־צֹ֥אן לָבָ֖ן אֲחִ֥י אִמּֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּשַּׁ֥ק יַֽעֲקֹ֖ב לְרָחֵ֑ל וַיִּשָּׂ֥א אֶת־קֹל֖וֹ וַיֵּֽבְךְּ׃"
          },
          {
            "num": "יב",
            "txt": "וַיַּגֵּ֨ד יַֽעֲקֹ֜ב לְרָחֵ֗ל כִּ֣י אֲחִ֤י אָבִ֨יהָ֙ ה֔וּא וְכִ֥י בֶן־רִבְקָ֖ה ה֑וּא וַתָּ֖רָץ וַתַּגֵּ֥ד לְאָבִֽיהָ׃"
          },
          {
            "num": "יג",
            "txt": "וַיְהִי֩ כִשְׁמֹ֨עַ לָבָ֜ן אֶת־שֵׁ֣מַע ׀ יַֽעֲקֹ֣ב בֶּן־אֲחֹת֗וֹ וַיָּ֤רָץ לִקְרָאתוֹ֙ וַיְחַבֶּק־לוֹ֙ וַיְנַשֶּׁק־ל֔וֹ וַיְבִיאֵ֖הוּ אֶל־בֵּית֑וֹ וַיְסַפֵּ֣ר לְלָבָ֔ן אֵ֥ת כָּל־הַדְּבָרִ֖ים הָאֵֽלֶּה׃"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֤אמֶר לוֹ֙ לָבָ֔ן אַ֛ךְ עַצְמִ֥י וּבְשָׂרִ֖י אָ֑תָּה וַיֵּ֥שֶׁב עִמּ֖וֹ חֹ֥דֶשׁ יָמִֽים׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֤אמֶר לָבָן֙ לְיַֽעֲקֹ֔ב הֲכִֽי־אָחִ֣י אַ֔תָּה וַֽעֲבַדְתַּ֖נִי חִנָּ֑ם הַגִּ֥ידָה לִּ֖י מַה־מַּשְׂכֻּרְתֶּֽךָ׃"
          },
          {
            "num": "טז",
            "txt": "וּלְלָבָ֖ן שְׁתֵּ֣י בָנ֑וֹת שֵׁ֤ם הַגְּדֹלָה֙ לֵאָ֔ה וְשֵׁ֥ם הַקְּטַנָּ֖ה רָחֵֽל׃"
          },
          {
            "num": "יז",
            "txt": "וְעֵינֵ֥י לֵאָ֖ה רַכּ֑וֹת וְרָחֵל֙ הָֽיְתָ֔ה יְפַת־תֹּ֖אַר וִיפַ֥ת מַרְאֶֽה׃"
          },
          {
            "num": "יח",
            "txt": "וַיֶּֽאֱהַ֥ב יַֽעֲקֹ֖ב אֶת־רָחֵ֑ל וַיֹּ֗אמֶר אֶֽעֱבָדְךָ֙ שֶׁ֣בַע שָׁנִ֔ים בְּרָחֵ֥ל בִּתְּךָ֖ הַקְּטַנָּֽה׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֣אמֶר לָבָ֗ן ט֚וֹב תִּתִּ֣י אֹתָ֣הּ לָ֔ךְ מִתִּתִּ֥י אֹתָ֖הּ לְאִ֣ישׁ אַחֵ֑ר שְׁבָ֖ה עִמָּדִֽי׃"
          },
          {
            "num": "כ",
            "txt": "וַיַּֽעֲבֹ֧ד יַֽעֲקֹ֛ב בְּרָחֵ֖ל שֶׁ֣בַע שָׁנִ֑ים וַיִּֽהְי֤וּ בְעֵינָיו֙ כְּיָמִ֣ים אֲחָדִ֔ים בְּאַֽהֲבָת֖וֹ אֹתָֽהּ׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֨אמֶר יַֽעֲקֹ֤ב אֶל־לָבָן֙ הָבָ֣ה אֶת־אִשְׁתִּ֔י כִּ֥י מָֽלְא֖וּ יָמָ֑י וְאָב֖וֹאָה אֵלֶֽיהָ׃"
          },
          {
            "num": "כב",
            "txt": "וַיֶּֽאֱסֹ֥ף לָבָ֛ן אֶת־כָּל־אַנְשֵׁ֥י הַמָּק֖וֹם וַיַּ֥עַשׂ מִשְׁתֶּֽה׃"
          },
          {
            "num": "כג",
            "txt": "וַיְהִ֣י בָעֶ֔רֶב וַיִּקַּח֙ אֶת־לֵאָ֣ה בִתּ֔וֹ וַיָּבֵ֥א אֹתָ֖הּ אֵלָ֑יו וַיָּבֹ֖א אֵלֶֽיהָ׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּתֵּ֤ן לָבָן֙ לָ֔הּ אֶת־זִלְפָּ֖ה שִׁפְחָת֑וֹ לְלֵאָ֥ה בִתּ֖וֹ שִׁפְחָֽה׃"
          },
          {
            "num": "כה",
            "txt": "וַיְהִ֣י בַבֹּ֔קֶר וְהִנֵּה־הִ֖וא לֵאָ֑ה וַיֹּ֣אמֶר אֶל־לָבָ֗ן מַה־זֹּאת֙ עָשִׂ֣יתָ לִּ֔י הֲלֹ֤א בְרָחֵל֙ עָבַ֣דְתִּי עִמָּ֔ךְ וְלָ֖מָּה רִמִּיתָֽנִי׃"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֣אמֶר לָבָ֔ן לֹֽא־יֵעָשֶׂ֥ה כֵ֖ן בִּמְקוֹמֵ֑נוּ לָתֵ֥ת הַצְּעִירָ֖ה לִפְנֵ֥י הַבְּכִירָֽה׃"
          },
          {
            "num": "כז",
            "txt": "מַלֵּ֖א שְׁבֻ֣עַ זֹ֑את וְנִתְּנָ֨ה לְךָ֜ גַּם־אֶת־זֹ֗את בַּֽעֲבֹדָה֙ אֲשֶׁ֣ר תַּֽעֲבֹ֣ד עִמָּדִ֔י ע֖וֹד שֶֽׁבַע־שָׁנִ֥ים אֲחֵרֽוֹת׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּ֤עַשׂ יַֽעֲקֹב֙ כֵּ֔ן וַיְמַלֵּ֖א שְׁבֻ֣עַ זֹ֑את וַיִּתֶּן־ל֛וֹ אֶת־רָחֵ֥ל בִּתּ֖וֹ ל֥וֹ לְאִשָּֽׁה׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּתֵּ֤ן לָבָן֙ לְרָחֵ֣ל בִּתּ֔וֹ אֶת־בִּלְהָ֖ה שִׁפְחָת֑וֹ לָ֖הּ לְשִׁפְחָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וַיָּבֹא֙ גַּ֣ם אֶל־רָחֵ֔ל וַיֶּֽאֱהַ֥ב גַּֽם־אֶת־רָחֵ֖ל מִלֵּאָ֑ה וַיַּֽעֲבֹ֣ד עִמּ֔וֹ ע֖וֹד שֶֽׁבַע־שָׁנִ֥ים אֲחֵרֽוֹת׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּ֤רְא יְהוָה֙ כִּֽי־שְׂנוּאָ֣ה לֵאָ֔ה וַיִּפְתַּ֖ח אֶת־רַחְמָ֑הּ וְרָחֵ֖ל עֲקָרָֽה׃"
          },
          {
            "num": "לב",
            "txt": "וַתַּ֤הַר לֵאָה֙ וַתֵּ֣לֶד בֵּ֔ן וַתִּקְרָ֥א שְׁמ֖וֹ רְאוּבֵ֑ן כִּ֣י אָֽמְרָ֗ה כִּֽי־רָאָ֤ה יְהוָה֙ בְּעָנְיִ֔י כִּ֥י עַתָּ֖ה יֶֽאֱהָבַ֥נִי אִישִֽׁי׃"
          },
          {
            "num": "לג",
            "txt": "וַתַּ֣הַר עוֹד֮ וַתֵּ֣לֶד בֵּן֒ וַתֹּ֗אמֶר כִּֽי־שָׁמַ֤ע יְהוָה֙ כִּֽי־שְׂנוּאָ֣ה אָנֹ֔כִי וַיִּתֶּן־לִ֖י גַּם־אֶת־זֶ֑ה וַתִּקְרָ֥א שְׁמ֖וֹ שִׁמְעֽוֹן׃"
          },
          {
            "num": "לד",
            "txt": "וַתַּ֣הַר עוֹד֮ וַתֵּ֣לֶד בֵּן֒ וַתֹּ֗אמֶר עַתָּ֤ה הַפַּ֨עַם֙ יִלָּוֶ֤ה אִישִׁי֙ אֵלַ֔י כִּֽי־יָלַ֥דְתִּי ל֖וֹ שְׁלֹשָׁ֣ה בָנִ֑ים עַל־כֵּ֥ן קָרָֽא־שְׁמ֖וֹ לֵוִֽי׃"
          },
          {
            "num": "לה",
            "txt": "וַתַּ֨הַר ע֜וֹד וַתֵּ֣לֶד בֵּ֗ן וַתֹּ֨אמֶר֙ הַפַּ֨עַם֙ אוֹדֶ֣ה אֶת־יְהוָ֔ה עַל־כֵּ֛ן קָֽרְאָ֥ה שְׁמ֖וֹ יְהוּדָ֑ה וַֽתַּעֲמֹ֖ד מִלֶּֽדֶת׃"
          }
        ]
      },
      {
        "num": "ל",
        "verses": [
          {
            "num": "א",
            "txt": "וַתֵּ֣רֶא רָחֵ֗ל כִּ֣י לֹ֤א יָֽלְדָה֙ לְיַֽעֲקֹ֔ב וַתְּקַנֵּ֥א רָחֵ֖ל בַּֽאֲחֹתָ֑הּ וַתֹּ֤אמֶר אֶֽל־יַעֲקֹב֙ הָֽבָה־לִּ֣י בָנִ֔ים וְאִם־אַ֖יִן מֵתָ֥ה אָנֹֽכִי׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּֽחַר־אַ֥ף יַֽעֲקֹ֖ב בְּרָחֵ֑ל וַיֹּ֗אמֶר הֲתַ֤חַת אֱלֹהִים֙ אָנֹ֔כִי אֲשֶׁר־מָנַ֥ע מִמֵּ֖ךְ פְּרִי־בָֽטֶן׃"
          },
          {
            "num": "ג",
            "txt": "וַתֹּ֕אמֶר הִנֵּ֛ה אֲמָתִ֥י בִלְהָ֖ה בֹּ֣א אֵלֶ֑יהָ וְתֵלֵד֙ עַל־בִּרְכַּ֔י וְאִבָּנֶ֥ה גַם־אָֽנֹכִ֖י מִמֶּֽנָּה׃"
          },
          {
            "num": "ד",
            "txt": "וַתִּתֶּן־ל֛וֹ אֶת־בִּלְהָ֥ה שִׁפְחָתָ֖הּ לְאִשָּׁ֑ה וַיָּבֹ֥א אֵלֶ֖יהָ יַֽעֲקֹֽב׃"
          },
          {
            "num": "ה",
            "txt": "וַתַּ֣הַר בִּלְהָ֔ה וַתֵּ֥לֶד לְיַֽעֲקֹ֖ב בֵּֽן׃"
          },
          {
            "num": "ו",
            "txt": "וַתֹּ֤אמֶר רָחֵל֙ דָּנַ֣נִּי אֱלֹהִ֔ים וְגַם֙ שָׁמַ֣ע בְּקֹלִ֔י וַיִּתֶּן־לִ֖י בֵּ֑ן עַל־כֵּ֛ן קָֽרְאָ֥ה שְׁמ֖וֹ דָּֽן׃"
          },
          {
            "num": "ז",
            "txt": "וַתַּ֣הַר ע֔וֹד וַתֵּ֕לֶד בִּלְהָ֖ה שִׁפְחַ֣ת רָחֵ֑ל בֵּ֥ן שֵׁנִ֖י לְיַֽעֲקֹֽב׃"
          },
          {
            "num": "ח",
            "txt": "וַתֹּ֣אמֶר רָחֵ֗ל נַפְתּוּלֵ֨י אֱלֹהִ֧ים ׀ נִפְתַּ֛לְתִּי עִם־אֲחֹתִ֖י גַּם־יָכֹ֑לְתִּי וַתִּקְרָ֥א שְׁמ֖וֹ נַפְתָּלִֽי׃"
          },
          {
            "num": "ט",
            "txt": "וַתֵּ֣רֶא לֵאָ֔ה כִּ֥י עָֽמְדָ֖ה מִלֶּ֑דֶת וַתִּקַּח֙ אֶת־זִלְפָּ֣ה שִׁפְחָתָ֔הּ וַתִּתֵּ֥ן אֹתָ֛הּ לְיַֽעֲקֹ֖ב לְאִשָּֽׁה׃"
          },
          {
            "num": "י",
            "txt": "וַתֵּ֗לֶד זִלְפָּ֛ה שִׁפְחַ֥ת לֵאָ֖ה לְיַֽעֲקֹ֥ב בֵּֽן׃"
          },
          {
            "num": "יא",
            "txt": "וַתֹּ֥אמֶר לֵאָ֖ה בגד (בָּ֣א גָ֑ד) וַתִּקְרָ֥א אֶת־שְׁמ֖וֹ גָּֽד׃"
          },
          {
            "num": "יב",
            "txt": "וַתֵּ֗לֶד זִלְפָּה֙ שִׁפְחַ֣ת לֵאָ֔ה בֵּ֥ן שֵׁנִ֖י לְיַֽעֲקֹֽב׃"
          },
          {
            "num": "יג",
            "txt": "וַתֹּ֣אמֶר לֵאָ֔ה בְּאָשְׁרִ֕י כִּ֥י אִשְּׁר֖וּנִי בָּנ֑וֹת וַתִּקְרָ֥א אֶת־שְׁמ֖וֹ אָשֵֽׁר׃"
          },
          {
            "num": "יד",
            "txt": "וַיֵּ֨לֶךְ רְאוּבֵ֜ן בִּימֵ֣י קְצִיר־חִטִּ֗ים וַיִּמְצָ֤א דֽוּדָאִים֙ בַּשָּׂדֶ֔ה וַיָּבֵ֣א אֹתָ֔ם אֶל־לֵאָ֖ה אִמּ֑וֹ וַתֹּ֤אמֶר רָחֵל֙ אֶל־לֵאָ֔ה תְּנִי־נָ֣א לִ֔י מִדּֽוּדָאֵ֖י בְּנֵֽךְ׃"
          },
          {
            "num": "טו",
            "txt": "וַתֹּ֣אמֶר לָ֗הּ הַמְעַט֙ קַחְתֵּ֣ךְ אֶת־אִישִׁ֔י וְלָקַ֕חַת גַּ֥ם אֶת־דּֽוּדָאֵ֖י בְּנִ֑י וַתֹּ֣אמֶר רָחֵ֗ל לָכֵן֙ יִשְׁכַּ֤ב עִמָּךְ֙ הַלַּ֔יְלָה תַּ֖חַת דּֽוּדָאֵ֥י בְנֵֽךְ׃"
          },
          {
            "num": "טז",
            "txt": "וַיָּבֹ֨א יַֽעֲקֹ֣ב מִן־הַשָּׂדֶה֮ בָּעֶרֶב֒ וַתֵּצֵ֨א לֵאָ֜ה לִקְרָאת֗וֹ וַתֹּ֨אמֶר֙ אֵלַ֣י תָּב֔וֹא כִּ֚י שָׂכֹ֣ר שְׂכַרְתִּ֔יךָ בְּדֽוּדָאֵ֖י בְּנִ֑י וַיִּשְׁכַּ֥ב עִמָּ֖הּ בַּלַּ֥יְלָה הֽוּא׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּשְׁמַ֥ע אֱלֹהִ֖ים אֶל־לֵאָ֑ה וַתַּ֛הַר וַתֵּ֥לֶד לְיַֽעֲקֹ֖ב בֵּ֥ן חֲמִישִֽׁי׃"
          },
          {
            "num": "יח",
            "txt": "וַתֹּ֣אמֶר לֵאָ֗ה נָתַ֤ן אֱלֹהִים֙ שְׂכָרִ֔י אֲשֶׁר־נָתַ֥תִּי שִׁפְחָתִ֖י לְאִישִׁ֑י וַתִּקְרָ֥א שְׁמ֖וֹ יִשָּׂשכָֽר׃"
          },
          {
            "num": "יט",
            "txt": "וַתַּ֤הַר עוֹד֙ לֵאָ֔ה וַתֵּ֥לֶד בֵּן־שִׁשִּׁ֖י לְיַֽעֲקֹֽב׃"
          },
          {
            "num": "כ",
            "txt": "וַתֹּ֣אמֶר לֵאָ֗ה זְבָדַ֨נִי אֱלֹהִ֥ים ׀ אֹתִי֮ זֵ֣בֶד טוֹב֒ הַפַּ֨עַם֙ יִזְבְּלֵ֣נִי אִישִׁ֔י כִּֽי־יָלַ֥דְתִּי ל֖וֹ שִׁשָּׁ֣ה בָנִ֑ים וַתִּקְרָ֥א אֶת־שְׁמ֖וֹ זְבֻלֽוּן׃"
          },
          {
            "num": "כא",
            "txt": "וְאַחַ֖ר יָ֣לְדָה בַּ֑ת וַתִּקְרָ֥א אֶת־שְׁמָ֖הּ דִּינָֽה׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּזְכֹּ֥ר אֱלֹהִ֖ים אֶת־רָחֵ֑ל וַיִּשְׁמַ֤ע אֵלֶ֨יהָ֙ אֱלֹהִ֔ים וַיִּפְתַּ֖ח אֶת־רַחְמָֽהּ׃"
          },
          {
            "num": "כג",
            "txt": "וַתַּ֖הַר וַתֵּ֣לֶד בֵּ֑ן וַתֹּ֕אמֶר אָסַ֥ף אֱלֹהִ֖ים אֶת־חֶרְפָּתִֽי׃"
          },
          {
            "num": "כד",
            "txt": "וַתִּקְרָ֧א אֶת־שְׁמ֛וֹ יוֹסֵ֖ף לֵאמֹ֑ר יֹסֵ֧ף יְהוָ֛ה לִ֖י בֵּ֥ן אַחֵֽר׃"
          },
          {
            "num": "כה",
            "txt": "וַיְהִ֕י כַּֽאֲשֶׁ֛ר יָֽלְדָ֥ה רָחֵ֖ל אֶת־יוֹסֵ֑ף וַיֹּ֤אמֶר יַֽעֲקֹב֙ אֶל־לָבָ֔ן שַׁלְּחֵ֨נִי֙ וְאֵ֣לְכָ֔ה אֶל־מְקוֹמִ֖י וּלְאַרְצִֽי׃"
          },
          {
            "num": "כו",
            "txt": "תְּנָ֞ה אֶת־נָשַׁ֣י וְאֶת־יְלָדַ֗י אֲשֶׁ֨ר עָבַ֧דְתִּי אֹֽתְךָ֛ בָּהֵ֖ן וְאֵלֵ֑כָה כִּ֚י אַתָּ֣ה יָדַ֔עְתָּ אֶת־עֲבֹֽדָתִ֖י אֲשֶׁ֥ר עֲבַדְתִּֽיךָ׃"
          },
          {
            "num": "כז",
            "txt": "וַיֹּ֤אמֶר אֵלָיו֙ לָבָ֔ן אִם־נָ֛א מָצָ֥אתִי חֵ֖ן בְּעֵינֶ֑יךָ נִחַ֕שְׁתִּי וַיְבָֽרְכֵ֥נִי יְהוָ֖ה בִּגְלָלֶֽךָ׃"
          },
          {
            "num": "כח",
            "txt": "וַיֹּאמַ֑ר נָקְבָ֧ה שְׂכָֽרְךָ֛ עָלַ֖י וְאֶתֵּֽנָה׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֣אמֶר אֵלָ֔יו אַתָּ֣ה יָדַ֔עְתָּ אֵ֖ת אֲשֶׁ֣ר עֲבַדְתִּ֑יךָ וְאֵ֛ת אֲשֶׁר־הָיָ֥ה מִקְנְךָ֖ אִתִּֽי׃"
          },
          {
            "num": "ל",
            "txt": "כִּ֡י מְעַט֩ אֲשֶׁר־הָיָ֨ה לְךָ֤ לְפָנַי֙ וַיִּפְרֹ֣ץ לָרֹ֔ב וַיְבָ֧רֶךְ יְהוָ֛ה אֹֽתְךָ֖ לְרַגְלִ֑י וְעַתָּ֗ה מָתַ֛י אֶֽעֱשֶׂ֥ה גַם־אָֽנֹכִ֖י לְבֵיתִֽי׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֖אמֶר מָ֣ה אֶתֶּן־לָ֑ךְ וַיֹּ֤אמֶר יַֽעֲקֹב֙ לֹֽא־תִתֶּן־לִ֣י מְא֔וּמָה אִם־תַּֽעֲשֶׂה־לִּי֙ הַדָּבָ֣ר הַזֶּ֔ה אָשׁ֛וּבָה אֶרְעֶ֥ה צֹֽאנְךָ֖ אֶשְׁמֹֽר׃"
          },
          {
            "num": "לב",
            "txt": "אֶֽעֱבֹ֨ר בְּכָל־צֹֽאנְךָ֜ הַיּ֗וֹם הָסֵ֨ר מִשָּׁ֜ם כָּל־שֶׂ֣ה ׀ נָקֹ֣ד וְטָל֗וּא וְכָל־שֶׂה־חוּם֙ בַּכְּשָׂבִ֔ים וְטָל֥וּא וְנָקֹ֖ד בָּֽעִזִּ֑ים וְהָיָ֖ה שְׂכָרִֽי׃"
          },
          {
            "num": "לג",
            "txt": "וְעָֽנְתָה־בִּ֤י צִדְקָתִי֙ בְּי֣וֹם מָחָ֔ר כִּֽי־תָב֥וֹא עַל־שְׂכָרִ֖י לְפָנֶ֑יךָ כֹּ֣ל אֲשֶׁר־אֵינֶנּוּ֩ נָקֹ֨ד וְטָל֜וּא בָּֽעִזִּ֗ים וְחוּם֙ בַּכְּשָׂבִ֔ים גָּנ֥וּב ה֖וּא אִתִּֽי׃"
          },
          {
            "num": "לד",
            "txt": "וַיֹּ֥אמֶר לָבָ֖ן הֵ֑ן ל֖וּ יְהִ֥י כִדְבָרֶֽךָ׃"
          },
          {
            "num": "לה",
            "txt": "וַיָּ֣סַר בַּיּוֹם֩ הַה֨וּא אֶת־הַתְּיָשִׁ֜ים הָֽעֲקֻדִּ֣ים וְהַטְּלֻאִ֗ים וְאֵ֤ת כָּל־הָֽעִזִּים֙ הַנְּקֻדּ֣וֹת וְהַטְּלֻאֹ֔ת כֹּ֤ל אֲשֶׁר־לָבָן֙ בּ֔וֹ וְכָל־ח֖וּם בַּכְּשָׂבִ֑ים וַיִּתֵּ֖ן בְּיַד־בָּנָֽיו׃"
          },
          {
            "num": "לו",
            "txt": "וַיָּ֗שֶׂם דֶּ֚רֶךְ שְׁלֹ֣שֶׁת יָמִ֔ים בֵּינ֖וֹ וּבֵ֣ין יַֽעֲקֹ֑ב וְיַֽעֲקֹ֗ב רֹעֶ֛ה אֶת־צֹ֥אן לָבָ֖ן הַנּֽוֹתָרֹֽת׃"
          },
          {
            "num": "לז",
            "txt": "וַיִּֽקַּֽח־ל֣וֹ יַֽעֲקֹ֗ב מַקַּ֥ל לִבְנֶ֛ה לַ֖ח וְל֣וּז וְעַרְמ֑וֹן וַיְפַצֵּ֤ל בָּהֵן֙ פְּצָל֣וֹת לְבָנ֔וֹת מַחְשֹׂף֙ הַלָּבָ֔ן אֲשֶׁ֖ר עַל־הַמַּקְלֽוֹת׃"
          },
          {
            "num": "לח",
            "txt": "וַיַּצֵּ֗ג אֶת־הַמַּקְלוֹת֙ אֲשֶׁ֣ר פִּצֵּ֔ל בָּֽרְהָטִ֖ים בְּשִֽׁקְת֣וֹת הַמָּ֑יִם אֲשֶׁר֩ תָּבֹ֨אןָ הַצֹּ֤אן לִשְׁתּוֹת֙ לְנֹ֣כַח הַצֹּ֔אן וַיֵּחַ֖מְנָה בְּבֹאָ֥ן לִשְׁתּֽוֹת׃"
          },
          {
            "num": "לט",
            "txt": "וַיֶּֽחֱמ֥וּ הַצֹּ֖אן אֶל־הַמַּקְל֑וֹת וַתֵּלַ֣דְןָ הַצֹּ֔אן עֲקֻדִּ֥ים נְקֻדִּ֖ים וּטְלֻאִֽים׃"
          },
          {
            "num": "מ",
            "txt": "וְהַכְּשָׂבִים֮ הִפְרִ֣יד יַֽעֲקֹב֒ וַ֠יִּתֵּן פְּנֵ֨י הַצֹּ֧אן אֶל־עָקֹ֛ד וְכָל־ח֖וּם בְּצֹ֣אן לָבָ֑ן וַיָּֽשֶׁת־ל֤וֹ עֲדָרִים֙ לְבַדּ֔וֹ וְלֹ֥א שָׁתָ֖ם עַל־צֹ֥אן לָבָֽן׃"
          },
          {
            "num": "מא",
            "txt": "וְהָיָ֗ה בְּכָל־יַחֵם֮ הַצֹּ֣אן הַמְקֻשָּׁרוֹת֒ וְשָׂ֨ם יַֽעֲקֹ֧ב אֶת־הַמַּקְל֛וֹת לְעֵינֵ֥י הַצֹּ֖אן בָּֽרְהָטִ֑ים לְיַחְמֵ֖נָּה בַּמַּקְלֽוֹת׃"
          },
          {
            "num": "מב",
            "txt": "וּבְהַֽעֲטִ֥יף הַצֹּ֖אן לֹ֣א יָשִׂ֑ים וְהָיָ֤ה הָֽעֲטֻפִים֙ לְלָבָ֔ן וְהַקְּשֻׁרִ֖ים לְיַֽעֲקֹֽב׃"
          },
          {
            "num": "מג",
            "txt": "וַיִּפְרֹ֥ץ הָאִ֖ישׁ מְאֹ֣ד מְאֹ֑ד וַֽיְהִי־לוֹ֙ צֹ֣אן רַבּ֔וֹת וּשְׁפָחוֹת֙ וַֽעֲבָדִ֔ים וּגְמַלִּ֖ים וַֽחֲמֹרִֽים׃"
          }
        ]
      },
      {
        "num": "לא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּשְׁמַ֗ע אֶת־דִּבְרֵ֤י בְנֵֽי־לָבָן֙ לֵאמֹ֔ר לָקַ֣ח יַֽעֲקֹ֔ב אֵ֖ת כָּל־אֲשֶׁ֣ר לְאָבִ֑ינוּ וּמֵֽאֲשֶׁ֣ר לְאָבִ֔ינוּ עָשָׂ֕ה אֵ֥ת כָּל־הַכָּבֹ֖ד הַזֶּֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַיַּ֥רְא יַֽעֲקֹ֖ב אֶת־פְּנֵ֣י לָבָ֑ן וְהִנֵּ֥ה אֵינֶ֛נּוּ עִמּ֖וֹ כִּתְמ֥וֹל שִׁלְשֽׁוֹם׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶֽל־יַעֲקֹ֔ב שׁ֛וּב אֶל־אֶ֥רֶץ אֲבוֹתֶ֖יךָ וּלְמֽוֹלַדְתֶּ֑ךָ וְאֶֽהְיֶ֖ה עִמָּֽךְ׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּשְׁלַ֣ח יַֽעֲקֹ֔ב וַיִּקְרָ֖א לְרָחֵ֣ל וּלְלֵאָ֑ה הַשָּׂדֶ֖ה אֶל־צֹאנֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֣אמֶר לָהֶ֗ן רֹאֶ֤ה אָֽנֹכִי֙ אֶת־פְּנֵ֣י אֲבִיכֶ֔ן כִּֽי־אֵינֶ֥נּוּ אֵלַ֖י כִּתְמֹ֣ל שִׁלְשֹׁ֑ם וֵֽאלֹהֵ֣י אָבִ֔י הָיָ֖ה עִמָּדִֽי׃"
          },
          {
            "num": "ו",
            "txt": "וְאַתֵּ֖נָה יְדַעְתֶּ֑ן כִּ֚י בְּכָל־כֹּחִ֔י עָבַ֖דְתִּי אֶת־אֲבִיכֶֽן׃"
          },
          {
            "num": "ז",
            "txt": "וַֽאֲבִיכֶן֙ הֵ֣תֶל בִּ֔י וְהֶֽחֱלִ֥ף אֶת־מַשְׂכֻּרְתִּ֖י עֲשֶׂ֣רֶת מֹנִ֑ים וְלֹֽא־נְתָנ֣וֹ אֱלֹהִ֔ים לְהָרַ֖ע עִמָּדִֽי׃"
          },
          {
            "num": "ח",
            "txt": "אִם־כֹּ֣ה יֹאמַ֗ר נְקֻדִּים֙ יִֽהְיֶ֣ה שְׂכָרֶ֔ךָ וְיָֽלְד֥וּ כָל־הַצֹּ֖אן נְקֻדִּ֑ים וְאִם־כֹּ֣ה יֹאמַ֗ר עֲקֻדִּים֙ יִֽהְיֶ֣ה שְׂכָרֶ֔ךָ וְיָֽלְד֥וּ כָל־הַצֹּ֖אן עֲקֻדִּֽים׃"
          },
          {
            "num": "ט",
            "txt": "וַיַּצֵּ֧ל אֱלֹהִ֛ים אֶת־מִקְנֵ֥ה אֲבִיכֶ֖ם וַיִּתֶּן־לִֽי׃"
          },
          {
            "num": "י",
            "txt": "וַיְהִ֗י בְּעֵת֙ יַחֵ֣ם הַצֹּ֔אן וָֽאֶשָּׂ֥א עֵינַ֛י וָאֵ֖רֶא בַּֽחֲל֑וֹם וְהִנֵּ֤ה הָֽעַתֻּדִים֙ הָֽעֹלִ֣ים עַל־הַצֹּ֔אן עֲקֻדִּ֥ים נְקֻדִּ֖ים וּבְרֻדִּֽים׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֨אמֶר אֵלַ֜י מַלְאַ֧ךְ הָֽאֱלֹהִ֛ים בַּֽחֲל֖וֹם יַֽעֲקֹ֑ב וָֽאֹמַ֖ר הִנֵּֽנִי׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֗אמֶר שָׂא־נָ֨א עֵינֶ֤יךָ וּרְאֵה֙ כָּל־הָֽעַתֻּדִים֙ הָֽעֹלִ֣ים עַל־הַצֹּ֔אן עֲקֻדִּ֥ים נְקֻדִּ֖ים וּבְרֻדִּ֑ים כִּ֣י רָאִ֔יתִי אֵ֛ת כָּל־אֲשֶׁ֥ר לָבָ֖ן עֹ֥שֶׂה לָּֽךְ׃"
          },
          {
            "num": "יג",
            "txt": "אָֽנֹכִ֤י הָאֵל֙ בֵּֽית־אֵ֔ל אֲשֶׁ֨ר מָשַׁ֤חְתָּ שָּׁם֙ מַצֵּבָ֔ה אֲשֶׁ֨ר נָדַ֥רְתָּ לִּ֛י שָׁ֖ם נֶ֑דֶר עַתָּ֗ה ק֥וּם צֵא֙ מִן־הָאָ֣רֶץ הַזֹּ֔את וְשׁ֖וּב אֶל־אֶ֥רֶץ מֽוֹלַדְתֶּֽךָ׃"
          },
          {
            "num": "יד",
            "txt": "וַתַּ֤עַן רָחֵל֙ וְלֵאָ֔ה וַתֹּאמַ֖רְנָה ל֑וֹ הַע֥וֹד לָ֛נוּ חֵ֥לֶק וְנַֽחֲלָ֖ה בְּבֵ֥ית אָבִֽינוּ׃"
          },
          {
            "num": "טו",
            "txt": "הֲל֧וֹא נָכְרִיּ֛וֹת נֶחְשַׁ֥בְנוּ ל֖וֹ כִּ֣י מְכָרָ֑נוּ וַיֹּ֥אכַל גַּם־אָכ֖וֹל אֶת־כַּסְפֵּֽנוּ׃"
          },
          {
            "num": "טז",
            "txt": "כִּ֣י כָל־הָעֹ֗שֶׁר אֲשֶׁ֨ר הִצִּ֤יל אֱלֹהִים֙ מֵֽאָבִ֔ינוּ לָ֥נוּ ה֖וּא וּלְבָנֵ֑ינוּ וְעַתָּ֗ה כֹּל֩ אֲשֶׁ֨ר אָמַ֧ר אֱלֹהִ֛ים אֵלֶ֖יךָ עֲשֵֽׂה׃"
          },
          {
            "num": "יז",
            "txt": "וַיָּ֖קָם יַֽעֲקֹ֑ב וַיִּשָּׂ֛א אֶת־בָּנָ֥יו וְאֶת־נָשָׁ֖יו עַל־הַגְּמַלִּֽים׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּנְהַ֣ג אֶת־כָּל־מִקְנֵ֗הוּ וְאֶת־כָּל־רְכֻשׁוֹ֙ אֲשֶׁ֣ר רָכָ֔שׁ מִקְנֵה֙ קִנְיָנ֔וֹ אֲשֶׁ֥ר רָכַ֖שׁ בְּפַדַּ֣ן אֲרָ֑ם לָב֛וֹא אֶל־יִצְחָ֥ק אָבִ֖יו אַ֥רְצָה כְּנָֽעַן׃"
          },
          {
            "num": "יט",
            "txt": "וְלָבָ֣ן הָלַ֔ךְ לִגְזֹ֖ז אֶת־צֹאנ֑וֹ וַתִּגְנֹ֣ב רָחֵ֔ל אֶת־הַתְּרָפִ֖ים אֲשֶׁ֥ר לְאָבִֽיהָ׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּגְנֹ֣ב יַֽעֲקֹ֔ב אֶת־לֵ֥ב לָבָ֖ן הָֽאֲרַמִּ֑י עַל־בְּלִי֙ הִגִּ֣יד ל֔וֹ כִּ֥י בֹרֵ֖חַ הֽוּא׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּבְרַ֥ח הוּא֙ וְכָל־אֲשֶׁר־ל֔וֹ וַיָּ֖קָם וַיַּֽעֲבֹ֣ר אֶת־הַנָּהָ֑ר וַיָּ֥שֶׂם אֶת־פָּנָ֖יו הַ֥ר הַגִּלְעָֽד׃"
          },
          {
            "num": "כב",
            "txt": "וַיֻּגַּ֥ד לְלָבָ֖ן בַּיּ֣וֹם הַשְּׁלִישִׁ֑י כִּ֥י בָרַ֖ח יַֽעֲקֹֽב׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּקַּ֤ח אֶת־אֶחָיו֙ עִמּ֔וֹ וַיִּרְדֹּ֣ף אַֽחֲרָ֔יו דֶּ֖רֶךְ שִׁבְעַ֣ת יָמִ֑ים וַיַּדְבֵּ֥ק אֹת֖וֹ בְּהַ֥ר הַגִּלְעָֽד׃"
          },
          {
            "num": "כד",
            "txt": "וַיָּבֹ֧א אֱלֹהִ֛ים אֶל־לָבָ֥ן הָֽאֲרַמִּ֖י בַּֽחֲלֹ֣ם הַלָּ֑יְלָה וַיֹּ֣אמֶר ל֗וֹ הִשָּׁ֧מֶר לְךָ֛ פֶּן־תְּדַבֵּ֥ר עִֽם־יַעֲקֹ֖ב מִטּ֥וֹב עַד־רָֽע׃"
          },
          {
            "num": "כה",
            "txt": "וַיַּשֵּׂ֥ג לָבָ֖ן אֶֽת־יַעֲקֹ֑ב וְיַֽעֲקֹ֗ב תָּקַ֤ע אֶֽת־אָהֳלוֹ֙ בָּהָ֔ר וְלָבָ֛ן תָּקַ֥ע אֶת־אֶחָ֖יו בְּהַ֥ר הַגִּלְעָֽד׃"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֤אמֶר לָבָן֙ לְיַֽעֲקֹ֔ב מֶ֣ה עָשִׂ֔יתָ וַתִּגְנֹ֖ב אֶת־לְבָבִ֑י וַתְּנַהֵג֙ אֶת־בְּנֹתַ֔י כִּשְׁבֻי֖וֹת חָֽרֶב׃"
          },
          {
            "num": "כז",
            "txt": "לָ֤מָּה נַחְבֵּ֨אתָ֙ לִבְרֹ֔חַ וַתִּגְנֹ֖ב אֹתִ֑י וְלֹֽא־הִגַּ֣דְתָּ לִּ֔י וָֽאֲשַׁלֵּחֲךָ֛ בְּשִׂמְחָ֥ה וּבְשִׁרִ֖ים בְּתֹ֥ף וּבְכִנּֽוֹר׃"
          },
          {
            "num": "כח",
            "txt": "וְלֹ֣א נְטַשְׁתַּ֔נִי לְנַשֵּׁ֥ק לְבָנַ֖י וְלִבְנֹתָ֑י עַתָּ֖ה הִסְכַּ֥לְתָּֽ עֲשֽׂוֹ׃"
          },
          {
            "num": "כט",
            "txt": "יֶשׁ־לְאֵ֣ל יָדִ֔י לַֽעֲשׂ֥וֹת עִמָּכֶ֖ם רָ֑ע וֵֽאלֹהֵ֨י אֲבִיכֶ֜ם אֶ֣מֶשׁ ׀ אָמַ֧ר אֵלַ֣י לֵאמֹ֗ר הִשָּׁ֧מֶר לְךָ֛ מִדַּבֵּ֥ר עִֽם־יַעֲקֹ֖ב מִטּ֥וֹב עַד־רָֽע׃"
          },
          {
            "num": "ל",
            "txt": "וְעַתָּה֙ הָלֹ֣ךְ הָלַ֔כְתָּ כִּֽי־נִכְסֹ֥ף נִכְסַ֖פְתָּה לְבֵ֣ית אָבִ֑יךָ לָ֥מָּה גָנַ֖בְתָּ אֶת־אֱלֹהָֽי׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּ֥עַן יַֽעֲקֹ֖ב וַיֹּ֣אמֶר לְלָבָ֑ן כִּ֣י יָרֵ֔אתִי כִּ֣י אָמַ֔רְתִּי פֶּן־תִּגְזֹ֥ל אֶת־בְּנוֹתֶ֖יךָ מֵֽעִמִּֽי׃"
          },
          {
            "num": "לב",
            "txt": "עִ֠ם אֲשֶׁ֨ר תִּמְצָ֣א אֶת־אֱלֹהֶיךָ֮ לֹ֣א יִֽחְיֶה֒ נֶ֣גֶד אַחֵ֧ינוּ הַֽכֶּר־לְךָ֛ מָ֥ה עִמָּדִ֖י וְקַֽח־לָ֑ךְ וְלֹֽא־יָדַ֣ע יַֽעֲקֹ֔ב כִּ֥י רָחֵ֖ל גְּנָבָֽתַם׃"
          },
          {
            "num": "לג",
            "txt": "וַיָּבֹ֨א לָבָ֜ן בְּאֹ֥הֶל יַֽעֲקֹ֣ב ׀ וּבְאֹ֣הֶל לֵאָ֗ה וּבְאֹ֛הֶל שְׁתֵּ֥י הָֽאֲמָהֹ֖ת וְלֹ֣א מָצָ֑א וַיֵּצֵא֙ מֵאֹ֣הֶל לֵאָ֔ה וַיָּבֹ֖א בְּאֹ֥הֶל רָחֵֽל׃"
          },
          {
            "num": "לד",
            "txt": "וְרָחֵ֞ל לָֽקְחָ֣ה אֶת־הַתְּרָפִ֗ים וַתְּשִׂמֵ֛ם בְּכַ֥ר הַגָּמָ֖ל וַתֵּ֣שֶׁב עֲלֵיהֶ֑ם וַיְמַשֵּׁ֥שׁ לָבָ֛ן אֶת־כָּל־הָאֹ֖הֶל וְלֹ֥א מָצָֽא׃"
          },
          {
            "num": "לה",
            "txt": "וַתֹּ֣אמֶר אֶל־אָבִ֗יהָ אַל־יִ֨חַר֙ בְּעֵינֵ֣י אֲדֹנִ֔י כִּ֣י ל֤וֹא אוּכַל֙ לָק֣וּם מִפָּנֶ֔יךָ כִּי־דֶ֥רֶךְ נָשִׁ֖ים לִ֑י וַיְחַפֵּ֕שׂ וְלֹ֥א מָצָ֖א אֶת־הַתְּרָפִֽים׃"
          },
          {
            "num": "לו",
            "txt": "וַיִּ֥חַר לְיַֽעֲקֹ֖ב וַיָּ֣רֶב בְּלָבָ֑ן וַיַּ֤עַן יַֽעֲקֹב֙ וַיֹּ֣אמֶר לְלָבָ֔ן מַה־פִּשְׁעִי֙ מַ֣ה חַטָּאתִ֔י כִּ֥י דָלַ֖קְתָּ אַֽחֲרָֽי׃"
          },
          {
            "num": "לז",
            "txt": "כִּֽי־מִשַּׁ֣שְׁתָּ אֶת־כָּל־כֵּלַ֗י מַה־מָּצָ֨אתָ֙ מִכֹּ֣ל כְּלֵֽי־בֵיתֶ֔ךָ שִׂ֣ים כֹּ֔ה נֶ֥גֶד אַחַ֖י וְאַחֶ֑יךָ וְיוֹכִ֖יחוּ בֵּ֥ין שְׁנֵֽינוּ׃"
          },
          {
            "num": "לח",
            "txt": "זֶה֩ עֶשְׂרִ֨ים שָׁנָ֤ה אָֽנֹכִי֙ עִמָּ֔ךְ רְחֵלֶ֥יךָ וְעִזֶּ֖יךָ לֹ֣א שִׁכֵּ֑לוּ וְאֵילֵ֥י צֹֽאנְךָ֖ לֹ֥א אָכָֽלְתִּי׃"
          },
          {
            "num": "לט",
            "txt": "טְרֵפָה֙ לֹֽא־הֵבֵ֣אתִי אֵלֶ֔יךָ אָֽנֹכִ֣י אֲחַטֶּ֔נָּה מִיָּדִ֖י תְּבַקְשֶׁ֑נָּה גְּנֻֽבְתִ֣י י֔וֹם וּגְנֻֽבְתִ֖י לָֽיְלָה׃"
          },
          {
            "num": "מ",
            "txt": "הָיִ֧יתִי בַיּ֛וֹם אֲכָלַ֥נִי חֹ֖רֶב וְקֶ֣רַח בַּלָּ֑יְלָה וַתִּדַּ֥ד שְׁנָתִ֖י מֵֽעֵינָֽי׃"
          },
          {
            "num": "מא",
            "txt": "זֶה־לִּ֞י עֶשְׂרִ֣ים שָׁנָה֮ בְּבֵיתֶךָ֒ עֲבַדְתִּ֜יךָ אַרְבַּֽע־עֶשְׂרֵ֤ה שָׁנָה֙ בִּשְׁתֵּ֣י בְנֹתֶ֔יךָ וְשֵׁ֥שׁ שָׁנִ֖ים בְּצֹאנֶ֑ךָ וַתַּֽחֲלֵ֥ף אֶת־מַשְׂכֻּרְתִּ֖י עֲשֶׂ֥רֶת מֹנִֽים׃"
          },
          {
            "num": "מב",
            "txt": "לוּלֵ֡י אֱלֹהֵ֣י אָבִי֩ אֱלֹהֵ֨י אַבְרָהָ֜ם וּפַ֤חַד יִצְחָק֙ הָ֣יָה לִ֔י כִּ֥י עַתָּ֖ה רֵיקָ֣ם שִׁלַּחְתָּ֑נִי אֶת־עָנְיִ֞י וְאֶת־יְגִ֧יעַ כַּפַּ֛י רָאָ֥ה אֱלֹהִ֖ים וַיּ֥וֹכַח אָֽמֶשׁ׃"
          },
          {
            "num": "מג",
            "txt": "וַיַּ֨עַן לָבָ֜ן וַיֹּ֣אמֶר אֶֽל־יַעֲקֹ֗ב הַבָּנ֨וֹת בְּנֹתַ֜י וְהַבָּנִ֤ים בָּנַי֙ וְהַצֹּ֣אן צֹאנִ֔י וְכֹ֛ל אֲשֶׁר־אַתָּ֥ה רֹאֶ֖ה לִי־ה֑וּא וְלִבְנֹתַ֞י מָֽה־אֶעֱשֶׂ֤ה לָאֵ֨לֶּה֙ הַיּ֔וֹם א֥וֹ לִבְנֵיהֶ֖ן אֲשֶׁ֥ר יָלָֽדוּ׃"
          },
          {
            "num": "מד",
            "txt": "וְעַתָּ֗ה לְכָ֛ה נִכְרְתָ֥ה בְרִ֖ית אֲנִ֣י וָאָ֑תָּה וְהָיָ֥ה לְעֵ֖ד בֵּינִ֥י וּבֵינֶֽךָ׃"
          },
          {
            "num": "מה",
            "txt": "וַיִּקַּ֥ח יַֽעֲקֹ֖ב אָ֑בֶן וַיְרִימֶ֖הָ מַצֵּבָֽה׃"
          },
          {
            "num": "מו",
            "txt": "וַיֹּ֨אמֶר יַֽעֲקֹ֤ב לְאֶחָיו֙ לִקְט֣וּ אֲבָנִ֔ים וַיִּקְח֥וּ אֲבָנִ֖ים וַיַּֽעֲשׂוּ־גָ֑ל וַיֹּ֥אכְלוּ שָׁ֖ם עַל־הַגָּֽל׃"
          },
          {
            "num": "מז",
            "txt": "וַיִּקְרָא־ל֣וֹ לָבָ֔ן יְגַ֖ר שָֽׂהֲדוּתָ֑א וְיַֽעֲקֹ֔ב קָ֥רָא ל֖וֹ גַּלְעֵֽד׃"
          },
          {
            "num": "מח",
            "txt": "וַיֹּ֣אמֶר לָבָ֔ן הַגַּ֨ל הַזֶּ֥ה עֵ֛ד בֵּינִ֥י וּבֵֽינְךָ֖ הַיּ֑וֹם עַל־כֵּ֥ן קָרָֽא־שְׁמ֖וֹ גַּלְעֵֽד׃"
          },
          {
            "num": "מט",
            "txt": "וְהַמִּצְפָּה֙ אֲשֶׁ֣ר אָמַ֔ר יִ֥צֶף יְהוָ֖ה בֵּינִ֣י וּבֵינֶ֑ךָ כִּ֥י נִסָּתֵ֖ר אִ֥ישׁ מֵֽרֵעֵֽהוּ׃"
          },
          {
            "num": "נ",
            "txt": "אִם־תְּעַנֶּ֣ה אֶת־בְּנֹתַ֗י וְאִם־תִּקַּ֤ח נָשִׁים֙ עַל־בְּנֹתַ֔י אֵ֥ין אִ֖ישׁ עִמָּ֑נוּ רְאֵ֕ה אֱלֹהִ֥ים עֵ֖ד בֵּינִ֥י וּבֵינֶֽךָ׃"
          },
          {
            "num": "נא",
            "txt": "וַיֹּ֥אמֶר לָבָ֖ן לְיַֽעֲקֹ֑ב הִנֵּ֣ה ׀ הַגַּ֣ל הַזֶּ֗ה וְהִנֵּה֙ הַמַּצֵּבָ֔ה אֲשֶׁ֥ר יָרִ֖יתִי בֵּינִ֥י וּבֵינֶֽךָ׃"
          },
          {
            "num": "נב",
            "txt": "עֵ֚ד הַגַּ֣ל הַזֶּ֔ה וְעֵדָ֖ה הַמַּצֵּבָ֑ה אִם־אָ֗נִי לֹֽא־אֶעֱבֹ֤ר אֵלֶ֨יךָ֙ אֶת־הַגַּ֣ל הַזֶּ֔ה וְאִם־אַ֠תָּה לֹֽא־תַעֲבֹ֨ר אֵלַ֜י אֶת־הַגַּ֥ל הַזֶּ֛ה וְאֶת־הַמַּצֵּבָ֥ה הַזֹּ֖את לְרָעָֽה׃"
          },
          {
            "num": "נג",
            "txt": "אֱלֹהֵ֨י אַבְרָהָ֜ם וֵֽאלֹהֵ֤י נָחוֹר֙ יִשְׁפְּט֣וּ בֵינֵ֔ינוּ אֱלֹהֵ֖י אֲבִיהֶ֑ם וַיִּשָּׁבַ֣ע יַֽעֲקֹ֔ב בְּפַ֖חַד אָבִ֥יו יִצְחָֽק׃"
          },
          {
            "num": "נד",
            "txt": "וַיִּזְבַּ֨ח יַֽעֲקֹ֥ב זֶ֨בַח֙ בָּהָ֔ר וַיִּקְרָ֥א לְאֶחָ֖יו לֶֽאֱכָל־לָ֑חֶם וַיֹּ֣אכְלוּ לֶ֔חֶם וַיָּלִ֖ינוּ בָּהָֽר׃"
          }
        ]
      },
      {
        "num": "לב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּשְׁכֵּ֨ם לָבָ֜ן בַּבֹּ֗קֶר וַיְנַשֵּׁ֧ק לְבָנָ֛יו וְלִבְנוֹתָ֖יו וַיְבָ֣רֶךְ אֶתְהֶ֑ם וַיֵּ֛לֶךְ וַיָּ֥שָׁב לָבָ֖ן לִמְקֹמֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וְיַֽעֲקֹ֖ב הָלַ֣ךְ לְדַרְכּ֑וֹ וַיִּפְגְּעוּ־ב֖וֹ מַלְאֲכֵ֥י אֱלֹהִֽים׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֤אמֶר יַֽעֲקֹב֙ כַּֽאֲשֶׁ֣ר רָאָ֔ם מַֽחֲנֵ֥ה אֱלֹהִ֖ים זֶ֑ה וַיִּקְרָ֛א שֵֽׁם־הַמָּק֥וֹם הַה֖וּא מַֽחֲנָֽיִם׃ {פ}"
          },
          {
            "num": "ד",
            "txt": "וַיִּשְׁלַ֨ח יַֽעֲקֹ֤ב מַלְאָכִים֙ לְפָנָ֔יו אֶל־עֵשָׂ֖ו אָחִ֑יו אַ֥רְצָה שֵׂעִ֖יר שְׂדֵ֥ה אֱדֽוֹם׃"
          },
          {
            "num": "ה",
            "txt": "וַיְצַ֤ו אֹתָם֙ לֵאמֹ֔ר כֹּ֣ה תֹֽאמְר֔וּן לַֽאדֹנִ֖י לְעֵשָׂ֑ו כֹּ֤ה אָמַר֙ עַבְדְּךָ֣ יַֽעֲקֹ֔ב עִם־לָבָ֣ן גַּ֔רְתִּי וָֽאֵחַ֖ר עַד־עָֽתָּה׃"
          },
          {
            "num": "ו",
            "txt": "וַֽיְהִי־לִי֙ שׁ֣וֹר וַֽחֲמ֔וֹר צֹ֖אן וְעֶ֣בֶד וְשִׁפְחָ֑ה וָֽאֶשְׁלְחָה֙ לְהַגִּ֣יד לַֽאדֹנִ֔י לִמְצֹא־חֵ֖ן בְּעֵינֶֽיךָ׃"
          },
          {
            "num": "ז",
            "txt": "וַיָּשֻׁ֨בוּ֙ הַמַּלְאָכִ֔ים אֶֽל־יַעֲקֹ֖ב לֵאמֹ֑ר בָּ֤אנוּ אֶל־אָחִ֨יךָ֙ אֶל־עֵשָׂ֔ו וְגַם֙ הֹלֵ֣ךְ לִקְרָֽאתְךָ֔ וְאַרְבַּע־מֵא֥וֹת אִ֖ישׁ עִמּֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּירָ֧א יַֽעֲקֹ֛ב מְאֹ֖ד וַיֵּ֣צֶר ל֑וֹ וַיַּ֜חַץ אֶת־הָעָ֣ם אֲשֶׁר־אִתּ֗וֹ וְאֶת־הַצֹּ֧אן וְאֶת־הַבָּקָ֛ר וְהַגְּמַלִּ֖ים לִשְׁנֵ֥י מַֽחֲנֽוֹת׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֕אמֶר אִם־יָב֥וֹא עֵשָׂ֛ו אֶל־הַמַּֽחֲנֶ֥ה הָֽאַחַ֖ת וְהִכָּ֑הוּ וְהָיָ֛ה הַמַּֽחֲנֶ֥ה הַנִּשְׁאָ֖ר לִפְלֵיטָֽה׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּאמֶר֮ יַֽעֲקֹב֒ אֱלֹהֵי֙ אָבִ֣י אַבְרָהָ֔ם וֵֽאלֹהֵ֖י אָבִ֣י יִצְחָ֑ק יְהוָ֞ה הָֽאֹמֵ֣ר אֵלַ֗י שׁ֧וּב לְאַרְצְךָ֛ וּלְמֽוֹלַדְתְּךָ֖ וְאֵיטִ֥יבָה עִמָּֽךְ׃"
          },
          {
            "num": "יא",
            "txt": "קָטֹ֜נְתִּי מִכֹּ֤ל הַֽחֲסָדִים֙ וּמִכָּל־הָ֣אֱמֶ֔ת אֲשֶׁ֥ר עָשִׂ֖יתָ אֶת־עַבְדֶּ֑ךָ כִּ֣י בְמַקְלִ֗י עָבַ֨רְתִּי֙ אֶת־הַיַּרְדֵּ֣ן הַזֶּ֔ה וְעַתָּ֥ה הָיִ֖יתִי לִשְׁנֵ֥י מַֽחֲנֽוֹת׃"
          },
          {
            "num": "יב",
            "txt": "הַצִּילֵ֥נִי נָ֛א מִיַּ֥ד אָחִ֖י מִיַּ֣ד עֵשָׂ֑ו כִּֽי־יָרֵ֤א אָֽנֹכִי֙ אֹת֔וֹ פֶּן־יָב֣וֹא וְהִכַּ֔נִי אֵ֖ם עַל־בָּנִֽים׃"
          },
          {
            "num": "יג",
            "txt": "וְאַתָּ֣ה אָמַ֔רְתָּ הֵיטֵ֥ב אֵיטִ֖יב עִמָּ֑ךְ וְשַׂמְתִּ֤י אֶֽת־זַרְעֲךָ֙ כְּח֣וֹל הַיָּ֔ם אֲשֶׁ֥ר לֹֽא־יִסָּפֵ֖ר מֵרֹֽב׃"
          },
          {
            "num": "יד",
            "txt": "וַיָּ֥לֶן שָׁ֖ם בַּלַּ֣יְלָה הַה֑וּא וַיִּקַּ֞ח מִן־הַבָּ֧א בְיָד֛וֹ מִנְחָ֖ה לְעֵשָׂ֥ו אָחִֽיו׃"
          },
          {
            "num": "טו",
            "txt": "עִזִּ֣ים מָאתַ֔יִם וּתְיָשִׁ֖ים עֶשְׂרִ֑ים רְחֵלִ֥ים מָאתַ֖יִם וְאֵילִ֥ים עֶשְׂרִֽים׃"
          },
          {
            "num": "טז",
            "txt": "גְּמַלִּ֧ים מֵֽינִיק֛וֹת וּבְנֵיהֶ֖ם שְׁלֹשִׁ֑ים פָּר֤וֹת אַרְבָּעִים֙ וּפָרִ֣ים עֲשָׂרָ֔ה אֲתֹנֹ֣ת עֶשְׂרִ֔ים וַעְיָרִ֖ם עֲשָׂרָֽה׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּתֵּן֙ בְּיַד־עֲבָדָ֔יו עֵ֥דֶר עֵ֖דֶר לְבַדּ֑וֹ וַ֤יֹּאמֶר אֶל־עֲבָדָיו֙ עִבְר֣וּ לְפָנַ֔י וְרֶ֣וַח תָּשִׂ֔ימוּ בֵּ֥ין עֵ֖דֶר וּבֵ֥ין עֵֽדֶר׃"
          },
          {
            "num": "יח",
            "txt": "וַיְצַ֥ו אֶת־הָֽרִאשׁ֖וֹן לֵאמֹ֑ר כִּ֣י יִֽפְגָשְׁךָ֞ עֵשָׂ֣ו אָחִ֗י וּשְׁאֵֽלְךָ֙ לֵאמֹ֔ר לְמִי־אַ֨תָּה֙ וְאָ֣נָה תֵלֵ֔ךְ וּלְמִ֖י אֵ֥לֶּה לְפָנֶֽיךָ׃"
          },
          {
            "num": "יט",
            "txt": "וְאָֽמַרְתָּ֙ לְעַבְדְּךָ֣ לְיַֽעֲקֹ֔ב מִנְחָ֥ה הִוא֙ שְׁלוּחָ֔ה לַֽאדֹנִ֖י לְעֵשָׂ֑ו וְהִנֵּ֥ה גַם־ה֖וּא אַֽחֲרֵֽינוּ׃"
          },
          {
            "num": "כ",
            "txt": "וַיְצַ֞ו גַּ֣ם אֶת־הַשֵּׁנִ֗י גַּ֚ם אֶת־הַשְּׁלִישִׁ֔י גַּ֚ם אֶת־כָּל־הַהֹ֣לְכִ֔ים אַֽחֲרֵ֥י הָֽעֲדָרִ֖ים לֵאמֹ֑ר כַּדָּבָ֤ר הַזֶּה֙ תְּדַבְּר֣וּן אֶל־עֵשָׂ֔ו בְּמֹצַֽאֲכֶ֖ם אֹתֽוֹ׃"
          },
          {
            "num": "כא",
            "txt": "וַֽאֲמַרְתֶּ֕ם גַּ֗ם הִנֵּ֛ה עַבְדְּךָ֥ יַֽעֲקֹ֖ב אַֽחֲרֵ֑ינוּ כִּֽי־אָמַ֞ר אֲכַפְּרָ֣ה פָנָ֗יו בַּמִּנְחָה֙ הַֽהֹלֶ֣כֶת לְפָנָ֔י וְאַֽחֲרֵי־כֵן֙ אֶרְאֶ֣ה פָנָ֔יו אוּלַ֖י יִשָּׂ֥א פָנָֽי׃"
          },
          {
            "num": "כב",
            "txt": "וַתַּֽעֲבֹ֥ר הַמִּנְחָ֖ה עַל־פָּנָ֑יו וְה֛וּא לָ֥ן בַּלַּֽיְלָה־הַה֖וּא בַּֽמַּחֲנֶֽה׃"
          },
          {
            "num": "כג",
            "txt": "וַיָּ֣קָם ׀ בַּלַּ֣יְלָה ה֗וּא וַיִּקַּ֞ח אֶת־שְׁתֵּ֤י נָשָׁיו֙ וְאֶת־שְׁתֵּ֣י שִׁפְחֹתָ֔יו וְאֶת־אַחַ֥ד עָשָׂ֖ר יְלָדָ֑יו וַֽיַּעֲבֹ֔ר אֵ֖ת מַֽעֲבַ֥ר יַבֹּֽק׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּ֨קָּחֵ֔ם וַיַּֽעֲבִרֵ֖ם אֶת־הַנָּ֑חַל וַֽיַּעֲבֵ֖ר אֶת־אֲשֶׁר־לֽוֹ׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּוָּתֵ֥ר יַֽעֲקֹ֖ב לְבַדּ֑וֹ וַיֵּֽאָבֵ֥ק אִישׁ֙ עִמּ֔וֹ עַ֖ד עֲל֥וֹת הַשָּֽׁחַר׃"
          },
          {
            "num": "כו",
            "txt": "וַיַּ֗רְא כִּ֣י לֹ֤א יָכֹל֙ ל֔וֹ וַיִּגַּ֖ע בְּכַף־יְרֵכ֑וֹ וַתֵּ֨קַע֙ כַּף־יֶ֣רֶךְ יַֽעֲקֹ֔ב בְּהֵֽאָבְק֖וֹ עִמּֽוֹ׃"
          },
          {
            "num": "כז",
            "txt": "וַיֹּ֣אמֶר שַׁלְּחֵ֔נִי כִּ֥י עָלָ֖ה הַשָּׁ֑חַר וַיֹּ֨אמֶר֙ לֹ֣א אֲשַֽׁלֵּחֲךָ֔ כִּ֖י אִם־בֵּֽרַכְתָּֽנִי׃"
          },
          {
            "num": "כח",
            "txt": "וַיֹּ֥אמֶר אֵלָ֖יו מַה־שְּׁמֶ֑ךָ וַיֹּ֖אמֶר יַֽעֲקֹֽב׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֗אמֶר לֹ֤א יַֽעֲקֹב֙ יֵֽאָמֵ֥ר עוֹד֙ שִׁמְךָ֔ כִּ֖י אִם־יִשְׂרָאֵ֑ל כִּֽי־שָׂרִ֧יתָ עִם־אֱלֹהִ֛ים וְעִם־אֲנָשִׁ֖ים וַתּוּכָֽל׃"
          },
          {
            "num": "ל",
            "txt": "וַיִּשְׁאַ֣ל יַֽעֲקֹ֗ב וַיֹּ֨אמֶר֙ הַגִּֽידָה־נָּ֣א שְׁמֶ֔ךָ וַיֹּ֕אמֶר לָ֥מָּה זֶּ֖ה תִּשְׁאַ֣ל לִשְׁמִ֑י וַיְבָ֥רֶךְ אֹת֖וֹ שָֽׁם׃"
          },
          {
            "num": "לא",
            "txt": "וַיִּקְרָ֧א יַֽעֲקֹ֛ב שֵׁ֥ם הַמָּק֖וֹם פְּנִיאֵ֑ל כִּֽי־רָאִ֤יתִי אֱלֹהִים֙ פָּנִ֣ים אֶל־פָּנִ֔ים וַתִּנָּצֵ֖ל נַפְשִֽׁי׃"
          },
          {
            "num": "לב",
            "txt": "וַיִּֽזְרַֽח־ל֣וֹ הַשֶּׁ֔מֶשׁ כַּֽאֲשֶׁ֥ר עָבַ֖ר אֶת־פְּנוּאֵ֑ל וְה֥וּא צֹלֵ֖עַ עַל־יְרֵכֽוֹ׃"
          },
          {
            "num": "לג",
            "txt": "עַל־כֵּ֡ן לֹֽא־יֹאכְל֨וּ בְנֵֽי־יִשְׂרָאֵ֜ל אֶת־גִּ֣יד הַנָּשֶׁ֗ה אֲשֶׁר֙ עַל־כַּ֣ף הַיָּרֵ֔ךְ עַ֖ד הַיּ֣וֹם הַזֶּ֑ה כִּ֤י נָגַע֙ בְּכַף־יֶ֣רֶךְ יַֽעֲקֹ֔ב בְּגִ֖יד הַנָּשֶֽׁה׃"
          }
        ]
      },
      {
        "num": "לג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּשָּׂ֨א יַֽעֲקֹ֜ב עֵינָ֗יו וַיַּרְא֙ וְהִנֵּ֣ה עֵשָׂ֣ו בָּ֔א וְעִמּ֕וֹ אַרְבַּ֥ע מֵא֖וֹת אִ֑ישׁ וַיַּ֣חַץ אֶת־הַיְלָדִ֗ים עַל־לֵאָה֙ וְעַל־רָחֵ֔ל וְעַ֖ל שְׁתֵּ֥י הַשְּׁפָחֽוֹת׃"
          },
          {
            "num": "ב",
            "txt": "וַיָּ֧שֶׂם אֶת־הַשְּׁפָח֛וֹת וְאֶת־יַלְדֵיהֶ֖ן רִֽאשֹׁנָ֑ה וְאֶת־לֵאָ֤ה וִֽילָדֶ֨יהָ֙ אַֽחֲרֹנִ֔ים וְאֶת־רָחֵ֥ל וְאֶת־יוֹסֵ֖ף אַֽחֲרֹנִֽים׃"
          },
          {
            "num": "ג",
            "txt": "וְה֖וּא עָבַ֣ר לִפְנֵיהֶ֑ם וַיִּשְׁתַּ֤חוּ אַ֨רְצָה֙ שֶׁ֣בַע פְּעָמִ֔ים עַד־גִּשְׁתּ֖וֹ עַד־אָחִֽיו׃"
          },
          {
            "num": "ד",
            "txt": "וַיָּ֨רָץ עֵשָׂ֤ו לִקְרָאתוֹ֙ וַֽיְחַבְּקֵ֔הוּ וַיִּפֹּ֥ל עַל־צַוָּארָ֖ו וַׄיִּׄשָּׁׄקֵ֑ׄהׄוּׄ וַיִּבְכּֽוּ׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּשָּׂ֣א אֶת־עֵינָ֗יו וַיַּ֤רְא אֶת־הַנָּשִׁים֙ וְאֶת־הַיְלָדִ֔ים וַיֹּ֖אמֶר מִי־אֵ֣לֶּה לָּ֑ךְ וַיֹּאמַ֕ר הַיְלָדִ֕ים אֲשֶׁר־חָנַ֥ן אֱלֹהִ֖ים אֶת־עַבְדֶּֽךָ׃"
          },
          {
            "num": "ו",
            "txt": "וַתִּגַּ֧שְׁןָ הַשְּׁפָח֛וֹת הֵ֥נָּה וְיַלְדֵיהֶ֖ן וַתִּֽשְׁתַּחֲוֶֽיןָ׃"
          },
          {
            "num": "ז",
            "txt": "וַתִּגַּ֧שׁ גַּם־לֵאָ֛ה וִֽילָדֶ֖יהָ וַיִּֽשְׁתַּחֲו֑וּ וְאַחַ֗ר נִגַּ֥שׁ יוֹסֵ֛ף וְרָחֵ֖ל וַיִּֽשְׁתַּחֲוֽוּ׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֕אמֶר מִ֥י לְךָ֛ כָּל־הַמַּֽחֲנֶ֥ה הַזֶּ֖ה אֲשֶׁ֣ר פָּגָ֑שְׁתִּי וַיֹּ֕אמֶר לִמְצֹא־חֵ֖ן בְּעֵינֵ֥י אֲדֹנִֽי׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֥אמֶר עֵשָׂ֖ו יֶשׁ־לִ֣י רָ֑ב אָחִ֕י יְהִ֥י לְךָ֖ אֲשֶׁר־לָֽךְ׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֣אמֶר יַֽעֲקֹ֗ב אַל־נָא֙ אִם־נָ֨א מָצָ֤אתִי חֵן֙ בְּעֵינֶ֔יךָ וְלָֽקַחְתָּ֥ מִנְחָתִ֖י מִיָּדִ֑י כִּ֣י עַל־כֵּ֞ן רָאִ֣יתִי פָנֶ֗יךָ כִּרְאֹ֛ת פְּנֵ֥י אֱלֹהִ֖ים וַתִּרְצֵֽנִי׃"
          },
          {
            "num": "יא",
            "txt": "קַח־נָ֤א אֶת־בִּרְכָתִי֙ אֲשֶׁ֣ר הֻבָ֣את לָ֔ךְ כִּֽי־חַנַּ֥נִי אֱלֹהִ֖ים וְכִ֣י יֶשׁ־לִי־כֹ֑ל וַיִּפְצַר־בּ֖וֹ וַיִּקָּֽח׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֖אמֶר נִסְעָ֣ה וְנֵלֵ֑כָה וְאֵֽלְכָ֖ה לְנֶגְדֶּֽךָ׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֣אמֶר אֵלָ֗יו אֲדֹנִ֤י יֹדֵ֨עַ֙ כִּֽי־הַיְלָדִ֣ים רַכִּ֔ים וְהַצֹּ֥אן וְהַבָּקָ֖ר עָל֣וֹת עָלָ֑י וּדְפָקוּם֙ י֣וֹם אֶחָ֔ד וָמֵ֖תוּ כָּל־הַצֹּֽאן׃"
          },
          {
            "num": "יד",
            "txt": "יַֽעֲבָר־נָ֥א אֲדֹנִ֖י לִפְנֵ֣י עַבְדּ֑וֹ וַֽאֲנִ֞י אֶֽתְנָהֲלָ֣ה לְאִטִּ֗י לְרֶ֨גֶל הַמְּלָאכָ֤ה אֲשֶׁר־לְפָנַי֙ וּלְרֶ֣גֶל הַיְלָדִ֔ים עַ֛ד אֲשֶׁר־אָבֹ֥א אֶל־אֲדֹנִ֖י שֵׂעִֽירָה׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֣אמֶר עֵשָׂ֔ו אַצִּֽיגָה־נָּ֣א עִמְּךָ֔ מִן־הָעָ֖ם אֲשֶׁ֣ר אִתִּ֑י וַיֹּ֨אמֶר֙ לָ֣מָּה זֶּ֔ה אֶמְצָא־חֵ֖ן בְּעֵינֵ֥י אֲדֹנִֽי׃"
          },
          {
            "num": "טז",
            "txt": "וַיָּשָׁב֩ בַּיּ֨וֹם הַה֥וּא עֵשָׂ֛ו לְדַרְכּ֖וֹ שֵׂעִֽירָה׃"
          },
          {
            "num": "יז",
            "txt": "וְיַֽעֲקֹב֙ נָסַ֣ע סֻכֹּ֔תָה וַיִּ֥בֶן ל֖וֹ בָּ֑יִת וּלְמִקְנֵ֨הוּ֙ עָשָׂ֣ה סֻכֹּ֔ת עַל־כֵּ֛ן קָרָ֥א שֵׁם־הַמָּק֖וֹם סֻכּֽוֹת׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "וַיָּבֹא֩ יַֽעֲקֹ֨ב שָׁלֵ֜ם עִ֣יר שְׁכֶ֗ם אֲשֶׁר֙ בְּאֶ֣רֶץ כְּנַ֔עַן בְּבֹא֖וֹ מִפַּדַּ֣ן אֲרָ֑ם וַיִּ֖חַן אֶת־פְּנֵ֥י הָעִֽיר׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּ֜קֶן אֶת־חֶלְקַ֣ת הַשָּׂדֶ֗ה אֲשֶׁ֤ר נָֽטָה־שָׁם֙ אָֽהֳל֔וֹ מִיַּ֥ד בְּנֵֽי־חֲמ֖וֹר אֲבִ֣י שְׁכֶ֑ם בְּמֵאָ֖ה קְשִׂיטָֽה׃"
          },
          {
            "num": "כ",
            "txt": "וַיַּצֶּב־שָׁ֖ם מִזְבֵּ֑חַ וַיִּ֨קְרָא־ל֔וֹ אֵ֖ל אֱלֹהֵ֥י יִשְׂרָאֵֽל׃ {ס}"
          }
        ]
      },
      {
        "num": "לד",
        "verses": [
          {
            "num": "א",
            "txt": "וַתֵּצֵ֤א דִינָה֙ בַּת־לֵאָ֔ה אֲשֶׁ֥ר יָֽלְדָ֖ה לְיַֽעֲקֹ֑ב לִרְא֖וֹת בִּבְנ֥וֹת הָאָֽרֶץ׃"
          },
          {
            "num": "ב",
            "txt": "וַיַּ֨רְא אֹתָ֜הּ שְׁכֶ֧ם בֶּן־חֲמ֛וֹר הַֽחִוִּ֖י נְשִׂ֣יא הָאָ֑רֶץ וַיִּקַּ֥ח אֹתָ֛הּ וַיִּשְׁכַּ֥ב אֹתָ֖הּ וַיְעַנֶּֽהָ׃"
          },
          {
            "num": "ג",
            "txt": "וַתִּדְבַּ֣ק נַפְשׁ֔וֹ בְּדִינָ֖ה בַּֽת־יַעֲקֹ֑ב וַיֶּֽאֱהַב֙ אֶת־הַֽנַּעֲרָ֔ וַיְדַבֵּ֖ר עַל־לֵ֥ב הַֽנַּעֲרָֽ׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֣אמֶר שְׁכֶ֔ם אֶל־חֲמ֥וֹר אָבִ֖יו לֵאמֹ֑ר קַֽח־לִ֛י אֶת־הַיַּלְדָּ֥ה הַזֹּ֖את לְאִשָּֽׁה׃"
          },
          {
            "num": "ה",
            "txt": "וְיַֽעֲקֹ֣ב שָׁמַ֗ע כִּ֤י טִמֵּא֙ אֶת־דִּינָ֣ה בִתּ֔וֹ וּבָנָ֛יו הָי֥וּ אֶת־מִקְנֵ֖הוּ בַּשָּׂדֶ֑ה וְהֶֽחֱרִ֥שׁ יַֽעֲקֹ֖ב עַד־בֹּאָֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַיֵּצֵ֛א חֲמ֥וֹר אֲבִֽי־שְׁכֶ֖ם אֶֽל־יַעֲקֹ֑ב לְדַבֵּ֖ר אִתּֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וּבְנֵ֨י יַֽעֲקֹ֜ב בָּ֤אוּ מִן־הַשָּׂדֶה֙ כְּשָׁמְעָ֔ם וַיִּֽתְעַצְּבוּ֙ הָֽאֲנָשִׁ֔ים וַיִּ֥חַר לָהֶ֖ם מְאֹ֑ד כִּֽי־נְבָלָ֞ה עָשָׂ֣ה בְיִשְׂרָאֵ֗ל לִשְׁכַּב֙ אֶת־בַּֽת־יַעֲקֹ֔ב וְכֵ֖ן לֹ֥א יֵֽעָשֶֽׂה׃"
          },
          {
            "num": "ח",
            "txt": "וַיְדַבֵּ֥ר חֲמ֖וֹר אִתָּ֣ם לֵאמֹ֑ר שְׁכֶ֣ם בְּנִ֗י חָֽשְׁקָ֤ה נַפְשׁוֹ֙ בְּבִתְּכֶ֔ם תְּנ֨וּ נָ֥א אֹתָ֛הּ ל֖וֹ לְאִשָּֽׁה׃"
          },
          {
            "num": "ט",
            "txt": "וְהִֽתְחַתְּנ֖וּ אֹתָ֑נוּ בְּנֹֽתֵיכֶם֙ תִּתְּנוּ־לָ֔נוּ וְאֶת־בְּנֹתֵ֖ינוּ תִּקְח֥וּ לָכֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וְאִתָּ֖נוּ תֵּשֵׁ֑בוּ וְהָאָ֨רֶץ֙ תִּֽהְיֶ֣ה לִפְנֵיכֶ֔ם שְׁבוּ֙ וּסְחָר֔וּהָ וְהֵֽאָחֲז֖וּ בָּֽהּ׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֤אמֶר שְׁכֶם֙ אֶל־אָבִ֣יהָ וְאֶל־אַחֶ֔יהָ אֶמְצָא־חֵ֖ן בְּעֵֽינֵיכֶ֑ם וַֽאֲשֶׁ֥ר תֹּֽאמְר֛וּ אֵלַ֖י אֶתֵּֽן׃"
          },
          {
            "num": "יב",
            "txt": "הַרְבּ֨וּ עָלַ֤י מְאֹד֙ מֹ֣הַר וּמַתָּ֔ן וְאֶ֨תְּנָ֔ה כַּֽאֲשֶׁ֥ר תֹּֽאמְר֖וּ אֵלָ֑י וּתְנוּ־לִ֥י אֶת־הַֽנַּעֲרָ֖ לְאִשָּֽׁה׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּֽעֲנ֨וּ בְנֵֽי־יַעֲקֹ֜ב אֶת־שְׁכֶ֨ם וְאֶת־חֲמ֥וֹר אָבִ֛יו בְּמִרְמָ֖ה וַיְדַבֵּ֑רוּ אֲשֶׁ֣ר טִמֵּ֔א אֵ֖ת דִּינָ֥ה אֲחֹתָֽם׃"
          },
          {
            "num": "יד",
            "txt": "וַיֹּֽאמְר֣וּ אֲלֵיהֶ֗ם לֹ֤א נוּכַל֙ לַֽעֲשׂוֹת֙ הַדָּבָ֣ר הַזֶּ֔ה לָתֵת֙ אֶת־אֲחֹתֵ֔נוּ לְאִ֖ישׁ אֲשֶׁר־ל֣וֹ עָרְלָ֑ה כִּֽי־חֶרְפָּ֥ה הִ֖וא לָֽנוּ׃"
          },
          {
            "num": "טו",
            "txt": "אַךְ־בְּזֹ֖את נֵא֣וֹת לָכֶ֑ם אִ֚ם תִּֽהְי֣וּ כָמֹ֔נוּ לְהִמֹּ֥ל לָכֶ֖ם כָּל־זָכָֽר׃"
          },
          {
            "num": "טז",
            "txt": "וְנָתַ֤נּוּ אֶת־בְּנֹתֵ֨ינוּ֙ לָכֶ֔ם וְאֶת־בְּנֹֽתֵיכֶ֖ם נִֽקַּֽח־לָ֑נוּ וְיָשַׁ֣בְנוּ אִתְּכֶ֔ם וְהָיִ֖ינוּ לְעַ֥ם אֶחָֽד׃"
          },
          {
            "num": "יז",
            "txt": "וְאִם־לֹ֧א תִשְׁמְע֛וּ אֵלֵ֖ינוּ לְהִמּ֑וֹל וְלָקַ֥חְנוּ אֶת־בִּתֵּ֖נוּ וְהָלָֽכְנוּ׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּֽיטְב֥וּ דִבְרֵיהֶ֖ם בְּעֵינֵ֣י חֲמ֑וֹר וּבְעֵינֵ֖י שְׁכֶ֥ם בֶּן־חֲמֽוֹר׃"
          },
          {
            "num": "יט",
            "txt": "וְלֹֽא־אֵחַ֤ר הַנַּ֨עַר֙ לַֽעֲשׂ֣וֹת הַדָּבָ֔ר כִּ֥י חָפֵ֖ץ בְּבַֽת־יַעֲקֹ֑ב וְה֣וּא נִכְבָּ֔ד מִכֹּ֖ל בֵּ֥ית אָבִֽיו׃"
          },
          {
            "num": "כ",
            "txt": "וַיָּבֹ֥א חֲמ֛וֹר וּשְׁכֶ֥ם בְּנ֖וֹ אֶל־שַׁ֣עַר עִירָ֑ם וַֽיְדַבְּר֛וּ אֶל־אַנְשֵׁ֥י עִירָ֖ם לֵאמֹֽר׃"
          },
          {
            "num": "כא",
            "txt": "הָֽאֲנָשִׁ֨ים הָאֵ֜לֶּה שְֽׁלֵמִ֧ים הֵ֣ם אִתָּ֗נוּ וְיֵֽשְׁב֤וּ בָאָ֨רֶץ֙ וְיִסְחֲר֣וּ אֹתָ֔הּ וְהָאָ֛רֶץ הִנֵּ֥ה רַֽחֲבַת־יָדַ֖יִם לִפְנֵיהֶ֑ם אֶת־בְּנֹתָם֙ נִקַּֽח־לָ֣נוּ לְנָשִׁ֔ים וְאֶת־בְּנֹתֵ֖ינוּ נִתֵּ֥ן לָהֶֽם׃"
          },
          {
            "num": "כב",
            "txt": "אַךְ־בְּ֠זֹאת יֵאֹ֨תוּ לָ֤נוּ הָֽאֲנָשִׁים֙ לָשֶׁ֣בֶת אִתָּ֔נוּ לִֽהְי֖וֹת לְעַ֣ם אֶחָ֑ד בְּהִמּ֥וֹל לָ֨נוּ֙ כָּל־זָכָ֔ר כַּֽאֲשֶׁ֖ר הֵ֥ם נִמֹּלִֽים׃"
          },
          {
            "num": "כג",
            "txt": "מִקְנֵהֶ֤ם וְקִנְיָנָם֙ וְכָל־בְּהֶמְתָּ֔ם הֲל֥וֹא לָ֖נוּ הֵ֑ם אַ֚ךְ נֵא֣וֹתָה לָהֶ֔ם וְיֵֽשְׁב֖וּ אִתָּֽנוּ׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּשְׁמְע֤וּ אֶל־חֲמוֹר֙ וְאֶל־שְׁכֶ֣ם בְּנ֔וֹ כָּל־יֹֽצְאֵ֖י שַׁ֣עַר עִיר֑וֹ וַיִּמֹּ֨לוּ֙ כָּל־זָכָ֔ר כָּל־יֹֽצְאֵ֖י שַׁ֥עַר עִירֽוֹ׃"
          },
          {
            "num": "כה",
            "txt": "וַיְהִי֩ בַיּ֨וֹם הַשְּׁלִישִׁ֜י בִּֽהְיוֹתָ֣ם כֹּֽאֲבִ֗ים וַיִּקְח֣וּ שְׁנֵֽי־בְנֵי־יַ֠עֲקֹב שִׁמְע֨וֹן וְלֵוִ֜י אֲחֵ֤י דִינָה֙ אִ֣ישׁ חַרְבּ֔וֹ וַיָּבֹ֥אוּ עַל־הָעִ֖יר בֶּ֑טַח וַיַּֽהַרְג֖וּ כָּל־זָכָֽר׃"
          },
          {
            "num": "כו",
            "txt": "וְאֶת־חֲמוֹר֙ וְאֶת־שְׁכֶ֣ם בְּנ֔וֹ הָֽרְג֖וּ לְפִי־חָ֑רֶב וַיִּקְח֧וּ אֶת־דִּינָ֛ה מִבֵּ֥ית שְׁכֶ֖ם וַיֵּצֵֽאוּ׃"
          },
          {
            "num": "כז",
            "txt": "בְּנֵ֣י יַֽעֲקֹ֗ב בָּ֚אוּ עַל־הַ֣חֲלָלִ֔ים וַיָּבֹ֖זּוּ הָעִ֑יר אֲשֶׁ֥ר טִמְּא֖וּ אֲחוֹתָֽם׃"
          },
          {
            "num": "כח",
            "txt": "אֶת־צֹאנָ֥ם וְאֶת־בְּקָרָ֖ם וְאֶת־חֲמֹֽרֵיהֶ֑ם וְאֵ֧ת אֲשֶׁר־בָּעִ֛יר וְאֶת־אֲשֶׁ֥ר בַּשָּׂדֶ֖ה לָקָֽחוּ׃"
          },
          {
            "num": "כט",
            "txt": "וְאֶת־כָּל־חֵילָ֤ם וְאֶת־כָּל־טַפָּם֙ וְאֶת־נְשֵׁיהֶ֔ם שָׁב֖וּ וַיָּבֹ֑זּוּ וְאֵ֖ת כָּל־אֲשֶׁ֥ר בַּבָּֽיִת׃"
          },
          {
            "num": "ל",
            "txt": "וַיֹּ֨אמֶר יַֽעֲקֹ֜ב אֶל־שִׁמְע֣וֹן וְאֶל־לֵוִי֮ עֲכַרְתֶּ֣ם אֹתִי֒ לְהַבְאִישֵׁ֨נִי֙ בְּיֹשֵׁ֣ב הָאָ֔רֶץ בַּֽכְּנַעֲנִ֖י וּבַפְּרִזִּ֑י וַֽאֲנִי֙ מְתֵ֣י מִסְפָּ֔ר וְנֶֽאֶסְפ֤וּ עָלַי֙ וְהִכּ֔וּנִי וְנִשְׁמַדְתִּ֖י אֲנִ֥י וּבֵיתִֽי׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּֽאמְר֑וּ הַכְזוֹנָ֕ה יַֽעֲשֶׂ֖ה אֶת־אֲחוֹתֵֽנוּ׃ {פ}"
          }
        ]
      },
      {
        "num": "לה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר אֱלֹהִים֙ אֶֽל־יַעֲקֹ֔ב ק֛וּם עֲלֵ֥ה בֵֽית־אֵ֖ל וְשֶׁב־שָׁ֑ם וַֽעֲשֵׂה־שָׁ֣ם מִזְבֵּ֔חַ לָאֵל֙ הַנִּרְאֶ֣ה אֵלֶ֔יךָ בְּבָ֨רְחֲךָ֔ מִפְּנֵ֖י עֵשָׂ֥ו אָחִֽיךָ׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֤אמֶר יַֽעֲקֹב֙ אֶל־בֵּית֔וֹ וְאֶ֖ל כָּל־אֲשֶׁ֣ר עִמּ֑וֹ הָסִ֜רוּ אֶת־אֱלֹהֵ֤י הַנֵּכָר֙ אֲשֶׁ֣ר בְּתֹֽכְכֶ֔ם וְהִֽטַּהֲר֔וּ וְהַֽחֲלִ֖יפוּ שִׂמְלֹֽתֵיכֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "וְנָק֥וּמָה וְנַֽעֲלֶ֖ה בֵּֽית־אֵ֑ל וְאֶֽעֱשֶׂה־שָּׁ֣ם מִזְבֵּ֗חַ לָאֵ֞ל הָֽעֹנֶ֤ה אֹתִי֙ בְּי֣וֹם צָֽרָתִ֔י וַֽיְהִי֙ עִמָּדִ֔י בַּדֶּ֖רֶךְ אֲשֶׁ֥ר הָלָֽכְתִּי׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּתְּנ֣וּ אֶֽל־יַעֲקֹ֗ב אֵ֣ת כָּל־אֱלֹהֵ֤י הַנֵּכָר֙ אֲשֶׁ֣ר בְּיָדָ֔ם וְאֶת־הַנְּזָמִ֖ים אֲשֶׁ֣ר בְּאָזְנֵיהֶ֑ם וַיִּטְמֹ֤ן אֹתָם֙ יַֽעֲקֹ֔ב תַּ֥חַת הָֽאֵלָ֖ה אֲשֶׁ֥ר עִם־שְׁכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּסָּ֑עוּ וַיְהִ֣י ׀ חִתַּ֣ת אֱלֹהִ֗ים עַל־הֶֽעָרִים֙ אֲשֶׁר֙ סְבִיב֣וֹתֵיהֶ֔ם וְלֹ֣א רָֽדְפ֔וּ אַֽחֲרֵ֖י בְּנֵ֥י יַֽעֲקֹֽב׃"
          },
          {
            "num": "ו",
            "txt": "וַיָּבֹ֨א יַֽעֲקֹ֜ב ל֗וּזָה אֲשֶׁר֙ בְּאֶ֣רֶץ כְּנַ֔עַן הִ֖וא בֵּֽית־אֵ֑ל ה֖וּא וְכָל־הָעָ֥ם אֲשֶׁר־עִמּֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּ֤בֶן שָׁם֙ מִזְבֵּ֔חַ וַיִּקְרָא֙ לַמָּק֔וֹם אֵ֖ל בֵּֽית־אֵ֑ל כִּ֣י שָׁ֗ם נִגְל֤וּ אֵלָיו֙ הָֽאֱלֹהִ֔ים בְּבָרְח֖וֹ מִפְּנֵ֥י אָחִֽיו׃"
          },
          {
            "num": "ח",
            "txt": "וַתָּ֤מָת דְּבֹרָה֙ מֵינֶ֣קֶת רִבְקָ֔ה וַתִּקָּבֵ֛ר מִתַּ֥חַת לְבֵֽית־אֵ֖ל תַּ֣חַת הָֽאַלּ֑וֹן וַיִּקְרָ֥א שְׁמ֖וֹ אַלּ֥וֹן בָּכֽוּת׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "וַיֵּרָ֨א אֱלֹהִ֤ים אֶֽל־יַעֲקֹב֙ ע֔וֹד בְּבֹא֖וֹ מִפַּדַּ֣ן אֲרָ֑ם וַיְבָ֖רֶךְ אֹתֽוֹ׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּֽאמֶר־ל֥וֹ אֱלֹהִ֖ים שִׁמְךָ֣ יַֽעֲקֹ֑ב לֹֽא־יִקָּרֵא֩ שִׁמְךָ֨ ע֜וֹד יַֽעֲקֹ֗ב כִּ֤י אִם־יִשְׂרָאֵל֙ יִֽהְיֶ֣ה שְׁמֶ֔ךָ וַיִּקְרָ֥א אֶת־שְׁמ֖וֹ יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּאמֶר֩ ל֨וֹ אֱלֹהִ֜ים אֲנִ֨י אֵ֤ל שַׁדַּי֙ פְּרֵ֣ה וּרְבֵ֔ה גּ֛וֹי וּקְהַ֥ל גּוֹיִ֖ם יִֽהְיֶ֣ה מִמֶּ֑ךָּ וּמְלָכִ֖ים מֵֽחֲלָצֶ֥יךָ יֵצֵֽאוּ׃"
          },
          {
            "num": "יב",
            "txt": "וְאֶת־הָאָ֗רֶץ אֲשֶׁ֥ר נָתַ֛תִּי לְאַבְרָהָ֥ם וּלְיִצְחָ֖ק לְךָ֣ אֶתְּנֶ֑נָּה וּֽלְזַרְעֲךָ֥ אַֽחֲרֶ֖יךָ אֶתֵּ֥ן אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּ֥עַל מֵֽעָלָ֖יו אֱלֹהִ֑ים בַּמָּק֖וֹם אֲשֶׁר־דִּבֶּ֥ר אִתּֽוֹ׃"
          },
          {
            "num": "יד",
            "txt": "וַיַּצֵּ֨ב יַֽעֲקֹ֜ב מַצֵּבָ֗ה בַּמָּק֛וֹם אֲשֶׁר־דִּבֶּ֥ר אִתּ֖וֹ מַצֶּ֣בֶת אָ֑בֶן וַיַּסֵּ֤ךְ עָלֶ֨יהָ֙ נֶ֔סֶךְ וַיִּצֹ֥ק עָלֶ֖יהָ שָֽׁמֶן׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּקְרָ֨א יַֽעֲקֹ֜ב אֶת־שֵׁ֣ם הַמָּק֗וֹם אֲשֶׁר֩ דִּבֶּ֨ר אִתּ֥וֹ שָׁ֛ם אֱלֹהִ֖ים בֵּֽית־אֵֽל׃"
          },
          {
            "num": "טז",
            "txt": "וַיִּסְעוּ֙ מִבֵּ֣ית אֵ֔ל וַֽיְהִי־ע֥וֹד כִּבְרַת־הָאָ֖רֶץ לָב֣וֹא אֶפְרָ֑תָה וַתֵּ֥לֶד רָחֵ֖ל וַתְּקַ֥שׁ בְּלִדְתָּֽהּ׃"
          },
          {
            "num": "יז",
            "txt": "וַיְהִ֥י בְהַקְשֹׁתָ֖הּ בְּלִדְתָּ֑הּ וַתֹּ֨אמֶר לָ֤הּ הַמְיַלֶּ֨דֶת֙ אַל־תִּ֣ירְאִ֔י כִּֽי־גַם־זֶ֥ה לָ֖ךְ בֵּֽן׃"
          },
          {
            "num": "יח",
            "txt": "וַיְהִ֞י בְּצֵ֤את נַפְשָׁהּ֙ כִּ֣י מֵ֔תָה וַתִּקְרָ֥א שְׁמ֖וֹ בֶּן־אוֹנִ֑י וְאָבִ֖יו קָֽרָא־ל֥וֹ בִנְיָמִֽין׃"
          },
          {
            "num": "יט",
            "txt": "וַתָּ֖מָת רָחֵ֑ל וַתִּקָּבֵר֙ בְּדֶ֣רֶךְ אֶפְרָ֔תָה הִ֖וא בֵּ֥ית לָֽחֶם׃"
          },
          {
            "num": "כ",
            "txt": "וַיַּצֵּ֧ב יַֽעֲקֹ֛ב מַצֵּבָ֖ה עַל־קְבֻֽרָתָ֑הּ הִ֛וא מַצֶּ֥בֶת קְבֻֽרַת־רָחֵ֖ל עַד־הַיּֽוֹם׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּסַּ֖ע יִשְׂרָאֵ֑ל וַיֵּ֣ט אָֽהֳלֹ֔ה מֵהָ֖לְאָה לְמִגְדַּל־עֵֽדֶר׃"
          },
          {
            "num": "כב",
            "txt": "וַיְהִ֗י בִּשְׁכֹּ֤ן יִשְׂרָאֵל֙ בָּאָ֣רֶץ הַהִ֔וא וַיֵּ֣לֶךְ רְאוּבֵ֗ן וַיִּשְׁכַּב֙ אֶת־בִּלְהָה֙ פִּילֶ֣גֶשׁ אָבִ֔יו וַיִּשְׁמַ֖ע יִשְׂרָאֵ֑ל {פ}"
          },
          {
            "num": "כג",
            "txt": "וַיִּֽהְי֥וּ בְנֵֽי־יַעֲקֹ֖ב שְׁנֵ֥ים עָשָֽׂר׃"
          },
          {
            "num": "כד",
            "txt": "בְּנֵ֣י לֵאָ֔ה בְּכ֥וֹר יַֽעֲקֹ֖ב רְאוּבֵ֑ן וְשִׁמְעוֹן֙ וְלֵוִ֣י וִֽיהוּדָ֔ה וְיִשָּׂשכָ֖ר וּזְבֻלֽוּן׃"
          },
          {
            "num": "כה",
            "txt": "בְּנֵ֣י רָחֵ֔ל יוֹסֵ֖ף וּבִנְיָמִֽן׃"
          },
          {
            "num": "כו",
            "txt": "וּבְנֵ֤י בִלְהָה֙ שִׁפְחַ֣ת רָחֵ֔ל דָּ֖ן וְנַפְתָּלִֽי׃"
          },
          {
            "num": "כז",
            "txt": "וּבְנֵ֥י זִלְפָּ֛ה שִׁפְחַ֥ת לֵאָ֖ה גָּ֣ד וְאָשֵׁ֑ר אֵ֚לֶּה בְּנֵ֣י יַֽעֲקֹ֔ב אֲשֶׁ֥ר יֻלַּד־ל֖וֹ בְּפַדַּ֥ן אֲרָֽם׃"
          },
          {
            "num": "כח",
            "txt": "וַיָּבֹ֤א יַֽעֲקֹב֙ אֶל־יִצְחָ֣ק אָבִ֔יו מַמְרֵ֖א קִרְיַ֣ת הָֽאַרְבַּ֑ע הִ֣וא חֶבְר֔וֹן אֲשֶׁר־גָּֽר־שָׁ֥ם אַבְרָהָ֖ם וְיִצְחָֽק׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּֽהְי֖וּ יְמֵ֣י יִצְחָ֑ק מְאַ֥ת שָׁנָ֖ה וּשְׁמֹנִ֥ים שָׁנָֽה׃"
          },
          {
            "txt": "וַיִּגְוַ֨ע יִצְחָ֤ק וַיָּ֨מָת֙ וַיֵּאָ֣סֶף אֶל־עַמָּ֔יו זָקֵ֖ן וּשְׂבַ֣ע יָמִ֑ים וַיִּקְבְּר֣וּ אֹת֔וֹ עֵשָׂ֥ו וְיַֽעֲקֹ֖ב בָּנָֽיו׃ {פ}"
          }
        ]
      },
      {
        "num": "לו",
        "verses": [
          {
            "num": "א",
            "txt": "וְאֵ֛לֶּה תֹּֽלְד֥וֹת עֵשָׂ֖ו ה֥וּא אֱדֽוֹם׃"
          },
          {
            "num": "ב",
            "txt": "עֵשָׂ֛ו לָקַ֥ח אֶת־נָשָׁ֖יו מִבְּנ֣וֹת כְּנָ֑עַן אֶת־עָדָ֗ה בַּת־אֵילוֹן֙ הַֽחִתִּ֔י וְאֶת־אָהֳלִֽיבָמָה֙ בַּת־עֲנָ֔ה בַּת־צִבְע֖וֹן הַֽחִוִּֽי׃"
          },
          {
            "num": "ג",
            "txt": "וְאֶת־בָּֽשְׂמַ֥ת בַּת־יִשְׁמָעֵ֖אל אֲח֥וֹת נְבָיֽוֹת׃"
          },
          {
            "num": "ד",
            "txt": "וַתֵּ֧לֶד עָדָ֛ה לְעֵשָׂ֖ו אֶת־אֱלִיפָ֑ז וּבָ֣שְׂמַ֔ת יָֽלְדָ֖ה אֶת־רְעוּאֵֽל׃"
          },
          {
            "num": "ה",
            "txt": "וְאָהֳלִֽיבָמָה֙ יָֽלְדָ֔ה אֶת־יעיש (יְע֥וּשׁ) וְאֶת־יַעְלָ֖ם וְאֶת־קֹ֑רַח אֵ֚לֶּה בְּנֵ֣י עֵשָׂ֔ו אֲשֶׁ֥ר יֻלְּדוּ־ל֖וֹ בְּאֶ֥רֶץ כְּנָֽעַן׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּקַּ֣ח עֵשָׂ֡ו אֶת־נָ֠שָׁיו וְאֶת־בָּנָ֣יו וְאֶת־בְּנֹתָיו֮ וְאֶת־כָּל־נַפְשׁ֣וֹת בֵּיתוֹ֒ וְאֶת־מִקְנֵ֣הוּ וְאֶת־כָּל־בְּהֶמְתּ֗וֹ וְאֵת֙ כָּל־קִנְיָנ֔וֹ אֲשֶׁ֥ר רָכַ֖שׁ בְּאֶ֣רֶץ כְּנָ֑עַן וַיֵּ֣לֶךְ אֶל־אֶ֔רֶץ מִפְּנֵ֖י יַֽעֲקֹ֥ב אָחִֽיו׃"
          },
          {
            "num": "ז",
            "txt": "כִּֽי־הָיָ֧ה רְכוּשָׁ֛ם רָ֖ב מִשֶּׁ֣בֶת יַחְדָּ֑ו וְלֹ֨א יָֽכְלָ֜ה אֶ֤רֶץ מְגֽוּרֵיהֶם֙ לָשֵׂ֣את אֹתָ֔ם מִפְּנֵ֖י מִקְנֵיהֶֽם׃"
          },
          {
            "num": "ח",
            "txt": "וַיֵּ֤שֶׁב עֵשָׂו֙ בְּהַ֣ר שֵׂעִ֔יר עֵשָׂ֖ו ה֥וּא אֱדֽוֹם׃"
          },
          {
            "num": "ט",
            "txt": "וְאֵ֛לֶּה תֹּֽלְד֥וֹת עֵשָׂ֖ו אֲבִ֣י אֱד֑וֹם בְּהַ֖ר שֵׂעִֽיר׃"
          },
          {
            "num": "י",
            "txt": "אֵ֖לֶּה שְׁמ֣וֹת בְּנֵֽי־עֵשָׂ֑ו אֱלִיפַ֗ז בֶּן־עָדָה֙ אֵ֣שֶׁת עֵשָׂ֔ו רְעוּאֵ֕ל בֶּן־בָּֽשְׂמַ֖ת אֵ֥שֶׁת עֵשָֽׂו׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּֽהְי֖וּ בְּנֵ֣י אֱלִיפָ֑ז תֵּימָ֣ן אוֹמָ֔ר צְפ֥וֹ וְגַעְתָּ֖ם וּקְנַֽז׃"
          },
          {
            "num": "יב",
            "txt": "וְתִמְנַ֣ע ׀ הָֽיְתָ֣ה פִילֶ֗גֶשׁ לֶֽאֱלִיפַז֙ בֶּן־עֵשָׂ֔ו וַתֵּ֥לֶד לֶֽאֱלִיפַ֖ז אֶת־עֲמָלֵ֑ק אֵ֕לֶּה בְּנֵ֥י עָדָ֖ה אֵ֥שֶׁת עֵשָֽׂו׃"
          },
          {
            "num": "יג",
            "txt": "וְאֵ֨לֶּה֙ בְּנֵ֣י רְעוּאֵ֔ל נַ֥חַת וָזֶ֖רַח שַׁמָּ֣ה וּמִזָּ֑ה אֵ֣לֶּה הָי֔וּ בְּנֵ֥י בָֽשְׂמַ֖ת אֵ֥שֶׁת עֵשָֽׂו׃"
          },
          {
            "num": "יד",
            "txt": "וְאֵ֣לֶּה הָי֗וּ בְּנֵ֨י אָהֳלִֽיבָמָ֧ה בַת־עֲנָ֛ה בַּת־צִבְע֖וֹן אֵ֣שֶׁת עֵשָׂ֑ו וַתֵּ֣לֶד לְעֵשָׂ֔ו אֶת־יעיש (יְע֥וּשׁ) וְאֶת־יַעְלָ֖ם וְאֶת־קֹֽרַח׃"
          },
          {
            "num": "טו",
            "txt": "אֵ֖לֶּה אַלּוּפֵ֣י בְנֵֽי־עֵשָׂ֑ו בְּנֵ֤י אֱלִיפַז֙ בְּכ֣וֹר עֵשָׂ֔ו אַלּ֤וּף תֵּימָן֙ אַלּ֣וּף אוֹמָ֔ר אַלּ֥וּף צְפ֖וֹ אַלּ֥וּף קְנַֽז׃"
          },
          {
            "num": "טז",
            "txt": "אַלּֽוּף־קֹ֛רַח אַלּ֥וּף גַּעְתָּ֖ם אַלּ֣וּף עֲמָלֵ֑ק אֵ֣לֶּה אַלּוּפֵ֤י אֱלִיפַז֙ בְּאֶ֣רֶץ אֱד֔וֹם אֵ֖לֶּה בְּנֵ֥י עָדָֽה׃"
          },
          {
            "num": "יז",
            "txt": "וְאֵ֗לֶּה בְּנֵ֤י רְעוּאֵל֙ בֶּן־עֵשָׂ֔ו אַלּ֥וּף נַ֨חַת֙ אַלּ֣וּף זֶ֔רַח אַלּ֥וּף שַׁמָּ֖ה אַלּ֣וּף מִזָּ֑ה אֵ֣לֶּה אַלּוּפֵ֤י רְעוּאֵל֙ בְּאֶ֣רֶץ אֱד֔וֹם אֵ֕לֶּה בְּנֵ֥י בָֽשְׂמַ֖ת אֵ֥שֶׁת עֵשָֽׂו׃"
          },
          {
            "num": "יח",
            "txt": "וְאֵ֗לֶּה בְּנֵ֤י אָהֳלִֽיבָמָה֙ אֵ֣שֶׁת עֵשָׂ֔ו אַלּ֥וּף יְע֛וּשׁ אַלּ֥וּף יַעְלָ֖ם אַלּ֣וּף קֹ֑רַח אֵ֣לֶּה אַלּוּפֵ֞י אָֽהֳלִיבָמָ֛ה בַּת־עֲנָ֖ה אֵ֥שֶׁת עֵשָֽׂו׃"
          },
          {
            "num": "יט",
            "txt": "אֵ֧לֶּה בְנֵֽי־עֵשָׂ֛ו וְאֵ֥לֶּה אַלּֽוּפֵיהֶ֖ם ה֥וּא אֱדֽוֹם׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "אֵ֤לֶּה בְנֵֽי־שֵׂעִיר֙ הַֽחֹרִ֔י יֹֽשְׁבֵ֖י הָאָ֑רֶץ לוֹטָ֥ן וְשׁוֹבָ֖ל וְצִבְע֥וֹן וַֽעֲנָֽה׃"
          },
          {
            "num": "כא",
            "txt": "וְדִשׁ֥וֹן וְאֵ֖צֶר וְדִישָׁ֑ן אֵ֣לֶּה אַלּוּפֵ֧י הַֽחֹרִ֛י בְּנֵ֥י שֵׂעִ֖יר בְּאֶ֥רֶץ אֱדֽוֹם׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּֽהְי֥וּ בְנֵֽי־לוֹטָ֖ן חֹרִ֣י וְהֵימָ֑ם וַֽאֲח֥וֹת לוֹטָ֖ן תִּמְנָֽע׃"
          },
          {
            "num": "כג",
            "txt": "וְאֵ֨לֶּה֙ בְּנֵ֣י שׁוֹבָ֔ל עַלְוָ֥ן וּמָנַ֖חַת וְעֵיבָ֑ל שְׁפ֖וֹ וְאוֹנָֽם׃"
          },
          {
            "num": "כד",
            "txt": "וְאֵ֥לֶּה בְנֵֽי־צִבְע֖וֹן וְאַיָּ֣ה וַֽעֲנָ֑ה ה֣וּא עֲנָ֗ה אֲשֶׁ֨ר מָצָ֤א אֶת־הַיֵּמִם֙ בַּמִּדְבָּ֔ר בִּרְעֹת֥וֹ אֶת־הַֽחֲמֹרִ֖ים לְצִבְע֥וֹן אָבִֽיו׃"
          },
          {
            "num": "כה",
            "txt": "וְאֵ֥לֶּה בְנֵֽי־עֲנָ֖ה דִּשֹׁ֑ן וְאָהֳלִֽיבָמָ֖ה בַּת־עֲנָֽה׃"
          },
          {
            "num": "כו",
            "txt": "וְאֵ֖לֶּה בְּנֵ֣י דִישָׁ֑ן חֶמְדָּ֥ן וְאֶשְׁבָּ֖ן וְיִתְרָ֥ן וּכְרָֽן׃"
          },
          {
            "num": "כז",
            "txt": "אֵ֖לֶּה בְּנֵי־אֵ֑צֶר בִּלְהָ֥ן וְזַֽעֲוָ֖ן וַֽעֲקָֽן׃"
          },
          {
            "num": "כח",
            "txt": "אֵ֥לֶּה בְנֵֽי־דִישָׁ֖ן ע֥וּץ וַֽאֲרָֽן׃"
          },
          {
            "num": "כט",
            "txt": "אֵ֖לֶּה אַלּוּפֵ֣י הַֽחֹרִ֑י אַלּ֤וּף לוֹטָן֙ אַלּ֣וּף שׁוֹבָ֔ל אַלּ֥וּף צִבְע֖וֹן אַלּ֥וּף עֲנָֽה׃"
          },
          {
            "num": "ל",
            "txt": "אַלּ֥וּף דִּשֹׁ֛ן אַלּ֥וּף אֵ֖צֶר אַלּ֣וּף דִּישָׁ֑ן אֵ֣לֶּה אַלּוּפֵ֧י הַֽחֹרִ֛י לְאַלֻּֽפֵיהֶ֖ם בְּאֶ֥רֶץ שֵׂעִֽיר׃ {פ}"
          },
          {
            "num": "לא",
            "txt": "וְאֵ֨לֶּה֙ הַמְּלָכִ֔ים אֲשֶׁ֥ר מָֽלְכ֖וּ בְּאֶ֣רֶץ אֱד֑וֹם לִפְנֵ֥י מְלָךְ־מֶ֖לֶךְ לִבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "לב",
            "txt": "וַיִּמְלֹ֣ךְ בֶּֽאֱד֔וֹם בֶּ֖לַע בֶּן־בְּע֑וֹר וְשֵׁ֥ם עִיר֖וֹ דִּנְהָֽבָה׃"
          },
          {
            "num": "לג",
            "txt": "וַיָּ֖מָת בָּ֑לַע וַיִּמְלֹ֣ךְ תַּחְתָּ֔יו יוֹבָ֥ב בֶּן־זֶ֖רַח מִבָּצְרָֽה׃"
          },
          {
            "num": "לד",
            "txt": "וַיָּ֖מָת יוֹבָ֑ב וַיִּמְלֹ֣ךְ תַּחְתָּ֔יו חֻשָׁ֖ם מֵאֶ֥רֶץ הַתֵּֽימָנִֽי׃"
          },
          {
            "num": "לה",
            "txt": "וַיָּ֖מָת חֻשָׁ֑ם וַיִּמְלֹ֨ךְ תַּחְתָּ֜יו הֲדַ֣ד בֶּן־בְּדַ֗ד הַמַּכֶּ֤ה אֶת־מִדְיָן֙ בִּשְׂדֵ֣ה מוֹאָ֔ב וְשֵׁ֥ם עִיר֖וֹ עֲוִֽית׃"
          },
          {
            "num": "לו",
            "txt": "וַיָּ֖מָת הֲדָ֑ד וַיִּמְלֹ֣ךְ תַּחְתָּ֔יו שַׂמְלָ֖ה מִמַּשְׂרֵקָֽה׃"
          },
          {
            "num": "לז",
            "txt": "וַיָּ֖מָת שַׂמְלָ֑ה וַיִּמְלֹ֣ךְ תַּחְתָּ֔יו שָׁא֖וּל מֵֽרְחֹב֥וֹת הַנָּהָֽר׃"
          },
          {
            "num": "לח",
            "txt": "וַיָּ֖מָת שָׁא֑וּל וַיִּמְלֹ֣ךְ תַּחְתָּ֔יו בַּ֥עַל חָנָ֖ן בֶּן־עַכְבּֽוֹר׃"
          },
          {
            "num": "לט",
            "txt": "וַיָּמָת֮ בַּ֣עַל חָנָ֣ן בֶּן־עַכְבּוֹר֒ וַיִּמְלֹ֤ךְ תַּחְתָּיו֙ הֲדַ֔ר וְשֵׁ֥ם עִיר֖וֹ פָּ֑עוּ וְשֵׁ֨ם אִשְׁתּ֤וֹ מְהֵֽיטַבְאֵל֙ בַּת־מַטְרֵ֔ד בַּ֖ת מֵ֥י זָהָֽב׃"
          },
          {
            "num": "מ",
            "txt": "וְ֠אֵלֶּה שְׁמ֞וֹת אַלּוּפֵ֤י עֵשָׂו֙ לְמִשְׁפְּחֹתָ֔ם לִמְקֹֽמֹתָ֖ם בִּשְׁמֹתָ֑ם אַלּ֥וּף תִּמְנָ֛ע אַלּ֥וּף עַֽלְוָ֖ה אַלּ֥וּף יְתֵֽת׃"
          },
          {
            "num": "מא",
            "txt": "אַלּ֧וּף אָהֳלִֽיבָמָ֛ה אַלּ֥וּף אֵלָ֖ה אַלּ֥וּף פִּינֹֽן׃"
          },
          {
            "num": "מב",
            "txt": "אַלּ֥וּף קְנַ֛ז אַלּ֥וּף תֵּימָ֖ן אַלּ֥וּף מִבְצָֽר׃"
          },
          {
            "num": "מג",
            "txt": "אַלּ֥וּף מַגְדִּיאֵ֖ל אַלּ֣וּף עִירָ֑ם אֵ֣לֶּה ׀ אַלּוּפֵ֣י אֱד֗וֹם לְמֹֽשְׁבֹתָם֙ בְּאֶ֣רֶץ אֲחֻזָּתָ֔ם ה֥וּא עֵשָׂ֖ו אֲבִ֥י אֱדֽוֹם׃ {פ}"
          }
        ]
      },
      {
        "num": "לז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֵּ֣שֶׁב יַֽעֲקֹ֔ב בְּאֶ֖רֶץ מְגוּרֵ֣י אָבִ֑יו בְּאֶ֖רֶץ כְּנָֽעַן׃"
          },
          {
            "num": "ב",
            "txt": "אֵ֣לֶּה ׀ תֹּֽלְד֣וֹת יַֽעֲקֹ֗ב יוֹסֵ֞ף בֶּן־שְׁבַֽע־עֶשְׂרֵ֤ה שָׁנָה֙ הָיָ֨ה רֹעֶ֤ה אֶת־אֶחָיו֙ בַּצֹּ֔אן וְה֣וּא נַ֗עַר אֶת־בְּנֵ֥י בִלְהָ֛ה וְאֶת־בְּנֵ֥י זִלְפָּ֖ה נְשֵׁ֣י אָבִ֑יו וַיָּבֵ֥א יוֹסֵ֛ף אֶת־דִּבָּתָ֥ם רָעָ֖ה אֶל־אֲבִיהֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "וְיִשְׂרָאֵ֗ל אָהַ֤ב אֶת־יוֹסֵף֙ מִכָּל־בָּנָ֔יו כִּֽי־בֶן־זְקֻנִ֥ים ה֖וּא ל֑וֹ וְעָ֥שָׂה ל֖וֹ כְּתֹ֥נֶת פַּסִּֽים׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּרְא֣וּ אֶחָ֗יו כִּֽי־אֹת֞וֹ אָהַ֤ב אֲבִיהֶם֙ מִכָּל־אֶחָ֔יו וַֽיִּשְׂנְא֖וּ אֹת֑וֹ וְלֹ֥א יָֽכְל֖וּ דַּבְּר֥וֹ לְשָׁלֹֽם׃"
          },
          {
            "num": "ה",
            "txt": "וַיַּֽחֲלֹ֤ם יוֹסֵף֙ חֲל֔וֹם וַיַּגֵּ֖ד לְאֶחָ֑יו וַיּוֹסִ֥פוּ ע֖וֹד שְׂנֹ֥א אֹתֽוֹ׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֖אמֶר אֲלֵיהֶ֑ם שִׁמְעוּ־נָ֕א הַֽחֲל֥וֹם הַזֶּ֖ה אֲשֶׁ֥ר חָלָֽמְתִּי׃"
          },
          {
            "num": "ז",
            "txt": "וְ֠הִנֵּה אֲנַ֜חְנוּ מְאַלְּמִ֤ים אֲלֻמִּים֙ בְּת֣וֹךְ הַשָּׂדֶ֔ה וְהִנֵּ֛ה קָ֥מָה אֲלֻמָּתִ֖י וְגַם־נִצָּ֑בָה וְהִנֵּ֤ה תְסֻבֶּ֨ינָה֙ אֲלֻמֹּ֣תֵיכֶ֔ם וַתִּֽשְׁתַּחֲוֶ֖יןָ לַֽאֲלֻמָּתִֽי׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֤אמְרוּ לוֹ֙ אֶחָ֔יו הֲמָלֹ֤ךְ תִּמְלֹךְ֙ עָלֵ֔ינוּ אִם־מָשׁ֥וֹל תִּמְשֹׁ֖ל בָּ֑נוּ וַיּוֹסִ֤פוּ עוֹד֙ שְׂנֹ֣א אֹת֔וֹ עַל־חֲלֹֽמֹתָ֖יו וְעַל־דְּבָרָֽיו׃"
          },
          {
            "num": "ט",
            "txt": "וַיַּֽחֲלֹ֥ם עוֹד֙ חֲל֣וֹם אַחֵ֔ר וַיְסַפֵּ֥ר אֹת֖וֹ לְאֶחָ֑יו וַיֹּ֗אמֶר הִנֵּ֨ה חָלַ֤מְתִּֽי חֲלוֹם֙ ע֔וֹד וְהִנֵּ֧ה הַשֶּׁ֣מֶשׁ וְהַיָּרֵ֗חַ וְאַחַ֤ד עָשָׂר֙ כּֽוֹכָבִ֔ים מִֽשְׁתַּחֲוִ֖ים לִֽי׃"
          },
          {
            "num": "י",
            "txt": "וַיְסַפֵּ֣ר אֶל־אָבִיו֮ וְאֶל־אֶחָיו֒ וַיִּגְעַר־בּ֣וֹ אָבִ֔יו וַיֹּ֣אמֶר ל֔וֹ מָ֛ה הַֽחֲל֥וֹם הַזֶּ֖ה אֲשֶׁ֣ר חָלָ֑מְתָּ הֲב֣וֹא נָב֗וֹא אֲנִי֙ וְאִמְּךָ֣ וְאַחֶ֔יךָ לְהִשְׁתַּֽחֲוֺ֥ת לְךָ֖ אָֽרְצָה׃"
          },
          {
            "num": "יא",
            "txt": "וַיְקַנְאוּ־ב֖וֹ אֶחָ֑יו וְאָבִ֖יו שָׁמַ֥ר אֶת־הַדָּבָֽר׃"
          },
          {
            "num": "יב",
            "txt": "וַיֵּֽלְכ֖וּ אֶחָ֑יו לִרְע֛וֹת אֶׄתׄ־צֹ֥אן אֲבִיהֶ֖ם בִּשְׁכֶֽם׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֨אמֶר יִשְׂרָאֵ֜ל אֶל־יוֹסֵ֗ף הֲל֤וֹא אַחֶ֨יךָ֙ רֹעִ֣ים בִּשְׁכֶ֔ם לְכָ֖ה וְאֶשְׁלָֽחֲךָ֣ אֲלֵיהֶ֑ם וַיֹּ֥אמֶר ל֖וֹ הִנֵּֽנִי׃"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֣אמֶר ל֗וֹ לֶךְ־נָ֨א רְאֵ֜ה אֶת־שְׁל֤וֹם אַחֶ֨יךָ֙ וְאֶת־שְׁל֣וֹם הַצֹּ֔אן וַֽהֲשִׁבֵ֖נִי דָּבָ֑ר וַיִּשְׁלָחֵ֨הוּ֙ מֵעֵ֣מֶק חֶבְר֔וֹן וַיָּבֹ֖א שְׁכֶֽמָה׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּמְצָאֵ֣הוּ אִ֔ישׁ וְהִנֵּ֥ה תֹעֶ֖ה בַּשָּׂדֶ֑ה וַיִּשְׁאָלֵ֧הוּ הָאִ֛ישׁ לֵאמֹ֖ר מַה־תְּבַקֵּֽשׁ׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֕אמֶר אֶת־אַחַ֖י אָֽנֹכִ֣י מְבַקֵּ֑שׁ הַגִּֽידָה־נָּ֣א לִ֔י אֵיפֹ֖ה הֵ֥ם רֹעִֽים׃"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֤אמֶר הָאִישׁ֙ נָֽסְע֣וּ מִזֶּ֔ה כִּ֤י שָׁמַ֨עְתִּי֙ אֹֽמְרִ֔ים נֵֽלְכָ֖ה דֹּתָ֑יְנָה וַיֵּ֤לֶךְ יוֹסֵף֙ אַחַ֣ר אֶחָ֔יו וַיִּמְצָאֵ֖ם בְּדֹתָֽן׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּרְא֥וּ אֹת֖וֹ מֵֽרָחֹ֑ק וּבְטֶ֨רֶם֙ יִקְרַ֣ב אֲלֵיהֶ֔ם וַיִּֽתְנַכְּל֥וּ אֹת֖וֹ לַֽהֲמִיתֽוֹ׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּֽאמְר֖וּ אִ֣ישׁ אֶל־אָחִ֑יו הִנֵּ֗ה בַּ֛עַל הַֽחֲלֹמ֥וֹת הַלָּזֶ֖ה בָּֽא׃"
          },
          {
            "num": "כ",
            "txt": "וְעַתָּ֣ה ׀ לְכ֣וּ וְנַֽהַרְגֵ֗הוּ וְנַשְׁלִכֵ֨הוּ֙ בְּאַחַ֣ד הַבֹּר֔וֹת וְאָמַ֕רְנוּ חַיָּ֥ה רָעָ֖ה אֲכָלָ֑תְהוּ וְנִרְאֶ֕ה מַה־יִּֽהְי֖וּ חֲלֹֽמֹתָֽיו׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּשְׁמַ֣ע רְאוּבֵ֔ן וַיַּצִּלֵ֖הוּ מִיָּדָ֑ם וַיֹּ֕אמֶר לֹ֥א נַכֶּ֖נּוּ נָֽפֶשׁ׃"
          },
          {
            "num": "כב",
            "txt": "וַיֹּ֨אמֶר אֲלֵהֶ֣ם ׀ רְאוּבֵן֮ אַל־תִּשְׁפְּכוּ־דָם֒ הַשְׁלִ֣יכוּ אֹת֗וֹ אֶל־הַבּ֤וֹר הַזֶּה֙ אֲשֶׁ֣ר בַּמִּדְבָּ֔ר וְיָ֖ד אַל־תִּשְׁלְחוּ־ב֑וֹ לְמַ֗עַן הַצִּ֤יל אֹתוֹ֙ מִיָּדָ֔ם לַֽהֲשִׁיב֖וֹ אֶל־אָבִֽיו׃"
          },
          {
            "num": "כג",
            "txt": "וַיְהִ֕י כַּֽאֲשֶׁר־בָּ֥א יוֹסֵ֖ף אֶל־אֶחָ֑יו וַיַּפְשִׁ֤יטוּ אֶת־יוֹסֵף֙ אֶת־כֻּתָּנְתּ֔וֹ אֶת־כְּתֹ֥נֶת הַפַּסִּ֖ים אֲשֶׁ֥ר עָלָֽיו׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּ֨קָּחֻ֔הוּ וַיַּשְׁלִ֥כוּ אֹת֖וֹ הַבֹּ֑רָה וְהַבּ֣וֹר רֵ֔ק אֵ֥ין בּ֖וֹ מָֽיִם׃"
          },
          {
            "num": "כה",
            "txt": "וַיֵּֽשְׁבוּ֮ לֶֽאֱכָל־לֶחֶם֒ וַיִּשְׂא֤וּ עֵֽינֵיהֶם֙ וַיִּרְא֔וּ וְהִנֵּה֙ אֹֽרְחַ֣ת יִשְׁמְעֵאלִ֔ים בָּאָ֖ה מִגִּלְעָ֑ד וּגְמַלֵּיהֶ֣ם נֹֽשְׂאִ֗ים נְכֹאת֙ וּצְרִ֣י וָלֹ֔ט הֽוֹלְכִ֖ים לְהוֹרִ֥יד מִצְרָֽיְמָה׃"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֥אמֶר יְהוּדָ֖ה אֶל־אֶחָ֑יו מַה־בֶּ֗צַע כִּ֤י נַֽהֲרֹג֙ אֶת־אָחִ֔ינוּ וְכִסִּ֖ינוּ אֶת־דָּמֽוֹ׃"
          },
          {
            "num": "כז",
            "txt": "לְכ֞וּ וְנִמְכְּרֶ֣נּוּ לַיִּשְׁמְעֵאלִ֗ים וְיָדֵ֨נוּ֙ אַל־תְּהִי־ב֔וֹ כִּֽי־אָחִ֥ינוּ בְשָׂרֵ֖נוּ ה֑וּא וַֽיִּשְׁמְע֖וּ אֶחָֽיו׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּֽעַבְרוּ֩ אֲנָשִׁ֨ים מִדְיָנִ֜ים סֹֽחֲרִ֗ים וַֽיִּמְשְׁכוּ֙ וַיַּֽעֲל֤וּ אֶת־יוֹסֵף֙ מִן־הַבּ֔וֹר וַיִּמְכְּר֧וּ אֶת־יוֹסֵ֛ף לַיִּשְׁמְעֵאלִ֖ים בְּעֶשְׂרִ֣ים כָּ֑סֶף וַיָּבִ֥יאוּ אֶת־יוֹסֵ֖ף מִצְרָֽיְמָה׃"
          },
          {
            "num": "כט",
            "txt": "וַיָּ֤שָׁב רְאוּבֵן֙ אֶל־הַבּ֔וֹר וְהִנֵּ֥ה אֵין־יוֹסֵ֖ף בַּבּ֑וֹר וַיִּקְרַ֖ע אֶת־בְּגָדָֽיו׃"
          },
          {
            "num": "ל",
            "txt": "וַיָּ֥שָׁב אֶל־אֶחָ֖יו וַיֹּאמַ֑ר הַיֶּ֣לֶד אֵינֶ֔נּוּ וַֽאֲנִ֖י אָ֥נָה אֲנִי־בָֽא׃"
          },
          {
            "num": "לא",
            "txt": "וַיִּקְח֖וּ אֶת־כְּתֹ֣נֶת יוֹסֵ֑ף וַֽיִּשְׁחֲטוּ֙ שְׂעִ֣יר עִזִּ֔ים וַיִּטְבְּל֥וּ אֶת־הַכֻּתֹּ֖נֶת בַּדָּֽם׃"
          },
          {
            "num": "לב",
            "txt": "וַֽיְשַׁלְּח֞וּ אֶת־כְּתֹ֣נֶת הַפַּסִּ֗ים וַיָּבִ֨יאוּ֙ אֶל־אֲבִיהֶ֔ם וַיֹּֽאמְר֖וּ זֹ֣את מָצָ֑אנוּ הַכֶּר־נָ֗א הַכְּתֹ֧נֶת בִּנְךָ֛ הִ֖וא אִם־לֹֽא׃"
          },
          {
            "num": "לג",
            "txt": "וַיַּכִּירָ֤הּ וַיֹּ֨אמֶר֙ כְּתֹ֣נֶת בְּנִ֔י חַיָּ֥ה רָעָ֖ה אֲכָלָ֑תְהוּ טָרֹ֥ף טֹרַ֖ף יוֹסֵֽף׃"
          },
          {
            "num": "לד",
            "txt": "וַיִּקְרַ֤ע יַֽעֲקֹב֙ שִׂמְלֹתָ֔יו וַיָּ֥שֶׂם שַׂ֖ק בְּמָתְנָ֑יו וַיִּתְאַבֵּ֥ל עַל־בְּנ֖וֹ יָמִ֥ים רַבִּֽים׃"
          },
          {
            "num": "לה",
            "txt": "וַיָּקֻמוּ֩ כָל־בָּנָ֨יו וְכָל־בְּנֹתָ֜יו לְנַֽחֲמ֗וֹ וַיְמָאֵן֙ לְהִתְנַחֵ֔ם וַיֹּ֕אמֶר כִּֽי־אֵרֵ֧ד אֶל־בְּנִ֛י אָבֵ֖ל שְׁאֹ֑לָה וַיֵּ֥בְךְּ אֹת֖וֹ אָבִֽיו׃"
          },
          {
            "num": "לו",
            "txt": "וְהַ֨מְּדָנִ֔ים מָֽכְר֥וּ אֹת֖וֹ אֶל־מִצְרָ֑יִם לְפֽוֹטִיפַר֙ סְרִ֣יס פַּרְעֹ֔ה שַׂ֖ר הַטַּבָּחִֽים׃ {פ}"
          }
        ]
      },
      {
        "num": "לח",
        "verses": [
          {
            "num": "א",
            "txt": "וַֽיְהִי֙ בָּעֵ֣ת הַהִ֔וא וַיֵּ֥רֶד יְהוּדָ֖ה מֵאֵ֣ת אֶחָ֑יו וַיֵּ֛ט עַד־אִ֥ישׁ עֲדֻלָּמִ֖י וּשְׁמ֥וֹ חִירָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַיַּרְא־שָׁ֧ם יְהוּדָ֛ה בַּת־אִ֥ישׁ כְּנַֽעֲנִ֖י וּשְׁמ֣וֹ שׁ֑וּעַ וַיִּקָּחֶ֖הָ וַיָּבֹ֥א אֵלֶֽיהָ׃"
          },
          {
            "num": "ג",
            "txt": "וַתַּ֖הַר וַתֵּ֣לֶד בֵּ֑ן וַיִּקְרָ֥א אֶת־שְׁמ֖וֹ עֵֽר׃"
          },
          {
            "num": "ד",
            "txt": "וַתַּ֥הַר ע֖וֹד וַתֵּ֣לֶד בֵּ֑ן וַתִּקְרָ֥א אֶת־שְׁמ֖וֹ אוֹנָֽן׃"
          },
          {
            "num": "ה",
            "txt": "וַתֹּ֤סֶף עוֹד֙ וַתֵּ֣לֶד בֵּ֔ן וַתִּקְרָ֥א אֶת־שְׁמ֖וֹ שֵׁלָ֑ה וְהָיָ֥ה בִכְזִ֖יב בְּלִדְתָּ֥הּ אֹתֽוֹ׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּקַּ֧ח יְהוּדָ֛ה אִשָּׁ֖ה לְעֵ֣ר בְּכוֹר֑וֹ וּשְׁמָ֖הּ תָּמָֽר׃"
          },
          {
            "num": "ז",
            "txt": "וַיְהִ֗י עֵ֚ר בְּכ֣וֹר יְהוּדָ֔ה רַ֖ע בְּעֵינֵ֣י יְהוָ֑ה וַיְמִתֵ֖הוּ יְהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֤אמֶר יְהוּדָה֙ לְאוֹנָ֔ן בֹּ֛א אֶל־אֵ֥שֶׁת אָחִ֖יךָ וְיַבֵּ֣ם אֹתָ֑הּ וְהָקֵ֥ם זֶ֖רַע לְאָחִֽיךָ׃"
          },
          {
            "num": "ט",
            "txt": "וַיֵּ֣דַע אוֹנָ֔ן כִּ֛י לֹּ֥א ל֖וֹ יִֽהְיֶ֣ה הַזָּ֑רַע וְהָיָ֞ה אִם־בָּ֨א אֶל־אֵ֤שֶׁת אָחִיו֙ וְשִׁחֵ֣ת אַ֔רְצָה לְבִלְתִּ֥י נְתָן־זֶ֖רַע לְאָחִֽיו׃"
          },
          {
            "num": "י",
            "txt": "וַיֵּ֛רַע בְּעֵינֵ֥י יְהוָ֖ה אֲשֶׁ֣ר עָשָׂ֑ה וַיָּ֖מֶת גַּם־אֹתֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֣אמֶר יְהוּדָה֩ לְתָמָ֨ר כַּלָּת֜וֹ שְׁבִ֧י אַלְמָנָ֣ה בֵית־אָבִ֗יךְ עַד־יִגְדַּל֙ שֵׁלָ֣ה בְנִ֔י כִּ֣י אָמַ֔ר פֶּן־יָמ֥וּת גַּם־ה֖וּא כְּאֶחָ֑יו וַתֵּ֣לֶךְ תָּמָ֔ר וַתֵּ֖שֶׁב בֵּ֥ית אָבִֽיהָ׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּרְבּוּ֙ הַיָּמִ֔ים וַתָּ֖מָת בַּת־שׁ֣וּעַ אֵֽשֶׁת־יְהוּדָ֑ה וַיִּנָּ֣חֶם יְהוּדָ֗ה וַיַּ֜עַל עַל־גֹּֽזְזֵ֤י צֹאנוֹ֙ ה֗וּא וְחִירָ֛ה רֵעֵ֥הוּ הָֽעֲדֻלָּמִ֖י תִּמְנָֽתָה׃"
          },
          {
            "num": "יג",
            "txt": "וַיֻּגַּ֥ד לְתָמָ֖ר לֵאמֹ֑ר הִנֵּ֥ה חָמִ֛יךְ עֹלֶ֥ה תִמְנָ֖תָה לָגֹ֥ז צֹאנֽוֹ׃"
          },
          {
            "num": "יד",
            "txt": "וַתָּסַר֩ בִּגְדֵ֨י אַלְמְנוּתָ֜הּ מֵֽעָלֶ֗יהָ וַתְּכַ֤ס בַּצָּעִיף֙ וַתִּתְעַלָּ֔ף וַתֵּ֨שֶׁב֙ בְּפֶ֣תַח עֵינַ֔יִם אֲשֶׁ֖ר עַל־דֶּ֣רֶךְ תִּמְנָ֑תָה כִּ֤י רָֽאֲתָה֙ כִּֽי־גָדַ֣ל שֵׁלָ֔ה וְהִ֕וא לֹֽא־נִתְּנָ֥ה ל֖וֹ לְאִשָּֽׁה׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּרְאֶ֣הָ יְהוּדָ֔ה וַֽיַּחְשְׁבֶ֖הָ לְזוֹנָ֑ה כִּ֥י כִסְּתָ֖ה פָּנֶֽיהָ׃"
          },
          {
            "num": "טז",
            "txt": "וַיֵּ֨ט אֵלֶ֜יהָ אֶל־הַדֶּ֗רֶךְ וַיֹּ֨אמֶר֙ הָֽבָה־נָּא֙ אָב֣וֹא אֵלַ֔יִךְ כִּ֚י לֹ֣א יָדַ֔ע כִּ֥י כַלָּת֖וֹ הִ֑וא וַתֹּ֨אמֶר֙ מַה־תִּתֶּן־לִ֔י כִּ֥י תָב֖וֹא אֵלָֽי׃"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֕אמֶר אָֽנֹכִ֛י אֲשַׁלַּ֥ח גְּדִֽי־עִזִּ֖ים מִן־הַצֹּ֑אן וַתֹּ֕אמֶר אִם־תִּתֵּ֥ן עֵֽרָב֖וֹן עַ֥ד שָׁלְחֶֽךָ׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֗אמֶר מָ֣ה הָֽעֵרָבוֹן֮ אֲשֶׁ֣ר אֶתֶּן־לָךְ֒ וַתֹּ֗אמֶר חֹתָֽמְךָ֙ וּפְתִילֶ֔ךָ וּמַטְּךָ֖ אֲשֶׁ֣ר בְּיָדֶ֑ךָ וַיִּתֶּן־לָ֛הּ וַיָּבֹ֥א אֵלֶ֖יהָ וַתַּ֥הַר לֽוֹ׃"
          },
          {
            "num": "יט",
            "txt": "וַתָּ֣קָם וַתֵּ֔לֶךְ וַתָּ֥סַר צְעִיפָ֖הּ מֵֽעָלֶ֑יהָ וַתִּלְבַּ֖שׁ בִּגְדֵ֥י אַלְמְנוּתָֽהּ׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּשְׁלַ֨ח יְהוּדָ֜ה אֶת־גְּדִ֣י הָֽעִזִּ֗ים בְּיַד֙ רֵעֵ֣הוּ הָֽעֲדֻלָּמִ֔י לָקַ֥חַת הָעֵֽרָב֖וֹן מִיַּ֣ד הָֽאִשָּׁ֑ה וְלֹ֖א מְצָאָֽהּ׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּשְׁאַ֞ל אֶת־אַנְשֵׁ֤י מְקֹמָהּ֙ לֵאמֹ֔ר אַיֵּ֧ה הַקְּדֵשָׁ֛ה הִ֥וא בָֽעֵינַ֖יִם עַל־הַדָּ֑רֶךְ וַיֹּ֣אמְר֔וּ לֹֽא־הָיְתָ֥ה בָזֶ֖ה קְדֵשָֽׁה׃"
          },
          {
            "num": "כב",
            "txt": "וַיָּ֨שָׁב֙ אֶל־יְהוּדָ֔ה וַיֹּ֖אמֶר לֹ֣א מְצָאתִ֑יהָ וְגַ֨ם אַנְשֵׁ֤י הַמָּקוֹם֙ אָֽמְר֔וּ לֹֽא־הָיְתָ֥ה בָזֶ֖ה קְדֵשָֽׁה׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֤אמֶר יְהוּדָה֙ תִּֽקַּֽח־לָ֔הּ פֶּ֖ן נִֽהְיֶ֣ה לָב֑וּז הִנֵּ֤ה שָׁלַ֨חְתִּי֙ הַגְּדִ֣י הַזֶּ֔ה וְאַתָּ֖ה לֹ֥א מְצָאתָֽהּ׃"
          },
          {
            "num": "כד",
            "txt": "וַיְהִ֣י ׀ כְּמִשְׁלֹ֣שׁ חֳדָשִׁ֗ים וַיֻּגַּ֨ד לִֽיהוּדָ֤ה לֵאמֹר֙ זָֽנְתָה֙ תָּמָ֣ר כַּלָּתֶ֔ךָ וְגַ֛ם הִנֵּ֥ה הָרָ֖ה לִזְנוּנִ֑ים וַיֹּ֣אמֶר יְהוּדָ֔ה הֽוֹצִיא֖וּהָ וְתִשָּׂרֵֽף׃"
          },
          {
            "num": "כה",
            "txt": "הִ֣וא מוּצֵ֗את וְהִ֨יא שָֽׁלְחָ֤ה אֶל־חָמִ֨יהָ֙ לֵאמֹ֔ר לְאִישׁ֙ אֲשֶׁר־אֵ֣לֶּה לּ֔וֹ אָֽנֹכִ֖י הָרָ֑ה וַתֹּ֨אמֶר֙ הַכֶּר־נָ֔א לְמִ֞י הַֽחֹתֶ֧מֶת וְהַפְּתִילִ֛ים וְהַמַּטֶּ֖ה הָאֵֽלֶּה׃"
          },
          {
            "num": "כו",
            "txt": "וַיַּכֵּ֣ר יְהוּדָ֗ה וַיֹּ֨אמֶר֙ צָֽדְקָ֣ה מִמֶּ֔נִּי כִּֽי־עַל־כֵּ֥ן לֹֽא־נְתַתִּ֖יהָ לְשֵׁלָ֣ה בְנִ֑י וְלֹֽא־יָסַ֥ף ע֖וֹד לְדַעְתָּֽהּ׃"
          },
          {
            "num": "כז",
            "txt": "וַיְהִ֖י בְּעֵ֣ת לִדְתָּ֑הּ וְהִנֵּ֥ה תְאוֹמִ֖ים בְּבִטְנָֽהּ׃"
          },
          {
            "num": "כח",
            "txt": "וַיְהִ֥י בְלִדְתָּ֖הּ וַיִּתֶּן־יָ֑ד וַתִּקַּ֣ח הַמְיַלֶּ֗דֶת וַתִּקְשֹׁ֨ר עַל־יָד֤וֹ שָׁנִי֙ לֵאמֹ֔ר זֶ֖ה יָצָ֥א רִֽאשֹׁנָֽה׃"
          },
          {
            "num": "כט",
            "txt": "וַיְהִ֣י ׀ כְּמֵשִׁ֣יב יָד֗וֹ וְהִנֵּה֙ יָצָ֣א אָחִ֔יו וַתֹּ֕אמֶר מַה־פָּרַ֖צְתָּ עָלֶ֣יךָ פָּ֑רֶץ וַיִּקְרָ֥א שְׁמ֖וֹ פָּֽרֶץ׃"
          },
          {
            "num": "ל",
            "txt": "וְאַחַר֙ יָצָ֣א אָחִ֔יו אֲשֶׁ֥ר עַל־יָד֖וֹ הַשָּׁנִ֑י וַיִּקְרָ֥א שְׁמ֖וֹ זָֽרַח׃ {ס}"
          }
        ]
      },
      {
        "num": "לט",
        "verses": [
          {
            "num": "א",
            "txt": "וְיוֹסֵ֖ף הוּרַ֣ד מִצְרָ֑יְמָה וַיִּקְנֵ֡הוּ פּֽוֹטִיפַר֩ סְרִ֨יס פַּרְעֹ֜ה שַׂ֤ר הַטַּבָּחִים֙ אִ֣ישׁ מִצְרִ֔י מִיַּד֙ הַיִּשְׁמְעֵאלִ֔ים אֲשֶׁ֥ר הֽוֹרִדֻ֖הוּ שָֽׁמָּה׃"
          },
          {
            "num": "ב",
            "txt": "וַיְהִ֤י יְהוָה֙ אֶת־יוֹסֵ֔ף וַיְהִ֖י אִ֣ישׁ מַצְלִ֑יחַ וַיְהִ֕י בְּבֵ֖ית אֲדֹנָ֥יו הַמִּצְרִֽי׃"
          },
          {
            "num": "ג",
            "txt": "וַיַּ֣רְא אֲדֹנָ֔יו כִּ֥י יְהוָ֖ה אִתּ֑וֹ וְכֹל֙ אֲשֶׁר־ה֣וּא עֹשֶׂ֔ה יְהוָ֖ה מַצְלִ֥יחַ בְּיָדֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּמְצָ֨א יוֹסֵ֥ף חֵ֛ן בְּעֵינָ֖יו וַיְשָׁ֣רֶת אֹת֑וֹ וַיַּפְקִדֵ֨הוּ֙ עַל־בֵּית֔וֹ וְכָל־יֶשׁ־ל֖וֹ נָתַ֥ן בְּיָדֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "וַיְהִ֡י מֵאָז֩ הִפְקִ֨יד אֹת֜וֹ בְּבֵית֗וֹ וְעַל֙ כָּל־אֲשֶׁ֣ר יֶשׁ־ל֔וֹ וַיְבָ֧רֶךְ יְהוָ֛ה אֶת־בֵּ֥ית הַמִּצְרִ֖י בִּגְלַ֣ל יוֹסֵ֑ף וַיְהִ֞י בִּרְכַּ֤ת יְהוָה֙ בְּכָל־אֲשֶׁ֣ר יֶשׁ־ל֔וֹ בַּבַּ֖יִת וּבַשָּׂדֶֽה׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּֽעֲזֹ֣ב כָּל־אֲשֶׁר־לוֹ֮ בְּיַד־יוֹסֵף֒ וְלֹֽא־יָדַ֤ע אִתּוֹ֙ מְא֔וּמָה כִּ֥י אִם־הַלֶּ֖חֶם אֲשֶׁר־ה֣וּא אוֹכֵ֑ל וַיְהִ֣י יוֹסֵ֔ף יְפֵה־תֹ֖אַר וִיפֵ֥ה מַרְאֶֽה׃"
          },
          {
            "num": "ז",
            "txt": "וַיְהִ֗י אַחַר֙ הַדְּבָרִ֣ים הָאֵ֔לֶּה וַתִּשָּׂ֧א אֵֽשֶׁת־אֲדֹנָ֛יו אֶת־עֵינֶ֖יהָ אֶל־יוֹסֵ֑ף וַתֹּ֖אמֶר שִׁכְבָ֥ה עִמִּֽי׃"
          },
          {
            "num": "ח",
            "txt": "וַיְמָאֵ֓ן ׀ וַיֹּ֨אמֶר֙ אֶל־אֵ֣שֶׁת אֲדֹנָ֔יו הֵ֣ן אֲדֹנִ֔י לֹֽא־יָדַ֥ע אִתִּ֖י מַה־בַּבָּ֑יִת וְכֹ֥ל אֲשֶׁר־יֶשׁ־ל֖וֹ נָתַ֥ן בְּיָדִֽי׃"
          },
          {
            "num": "ט",
            "txt": "אֵינֶ֨נּוּ גָד֜וֹל בַּבַּ֣יִת הַזֶּה֮ מִמֶּנִּי֒ וְלֹֽא־חָשַׂ֤ךְ מִמֶּ֨נִּי֙ מְא֔וּמָה כִּ֥י אִם־אוֹתָ֖ךְ בַּֽאֲשֶׁ֣ר אַתְּ־אִשְׁתּ֑וֹ וְאֵ֨יךְ אֶֽעֱשֶׂ֜ה הָֽרָעָ֤ה הַגְּדֹלָה֙ הַזֹּ֔את וְחָטָ֖אתִי לֵֽאלֹהִֽים׃"
          },
          {
            "num": "י",
            "txt": "וַיְהִ֕י כְּדַבְּרָ֥הּ אֶל־יוֹסֵ֖ף י֣וֹם ׀ י֑וֹם וְלֹֽא־שָׁמַ֥ע אֵלֶ֛יהָ לִשְׁכַּ֥ב אֶצְלָ֖הּ לִֽהְי֥וֹת עִמָּֽהּ׃"
          },
          {
            "num": "יא",
            "txt": "וַֽיְהִי֙ כְּהַיּ֣וֹם הַזֶּ֔ה וַיָּבֹ֥א הַבַּ֖יְתָה לַֽעֲשׂ֣וֹת מְלַאכְתּ֑וֹ וְאֵ֨ין אִ֜ישׁ מֵֽאַנְשֵׁ֥י הַבַּ֛יִת שָׁ֖ם בַּבָּֽיִת׃"
          },
          {
            "num": "יב",
            "txt": "וַתִּתְפְּשֵׂ֧הוּ בְּבִגְד֛וֹ לֵאמֹ֖ר שִׁכְבָ֣ה עִמִּ֑י וַיַּֽעֲזֹ֤ב בִּגְדוֹ֙ בְּיָדָ֔הּ וַיָּ֖נָס וַיֵּצֵ֥א הַחֽוּצָה׃"
          },
          {
            "num": "יג",
            "txt": "וַֽיְהִי֙ כִּרְאוֹתָ֔הּ כִּֽי־עָזַ֥ב בִּגְד֖וֹ בְּיָדָ֑הּ וַיָּ֖נָס הַחֽוּצָה׃"
          },
          {
            "num": "יד",
            "txt": "וַתִּקְרָ֞א לְאַנְשֵׁ֣י בֵיתָ֗הּ וַתֹּ֤אמֶר לָהֶם֙ לֵאמֹ֔ר רְא֗וּ הֵ֥בִיא לָ֛נוּ אִ֥ישׁ עִבְרִ֖י לְצַ֣חֶק בָּ֑נוּ בָּ֤א אֵלַי֙ לִשְׁכַּ֣ב עִמִּ֔י וָֽאֶקְרָ֖א בְּק֥וֹל גָּדֽוֹל׃"
          },
          {
            "num": "טו",
            "txt": "וַיְהִ֣י כְשָׁמְע֔וֹ כִּֽי־הֲרִימֹ֥תִי קוֹלִ֖י וָֽאֶקְרָ֑א וַיַּֽעֲזֹ֤ב בִּגְדוֹ֙ אֶצְלִ֔י וַיָּ֖נָס וַיֵּצֵ֥א הַחֽוּצָה׃"
          },
          {
            "num": "טז",
            "txt": "וַתַּנַּ֥ח בִּגְד֖וֹ אֶצְלָ֑הּ עַד־בּ֥וֹא אֲדֹנָ֖יו אֶל־בֵּיתֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "וַתְּדַבֵּ֣ר אֵלָ֔יו כַּדְּבָרִ֥ים הָאֵ֖לֶּה לֵאמֹ֑ר בָּֽא־אֵלַ֞י הָעֶ֧בֶד הָֽעִבְרִ֛י אֲשֶׁר־הֵבֵ֥אתָ לָּ֖נוּ לְצַ֥חֶק בִּֽי׃"
          },
          {
            "num": "יח",
            "txt": "וַיְהִ֕י כַּֽהֲרִימִ֥י קוֹלִ֖י וָֽאֶקְרָ֑א וַיַּֽעֲזֹ֥ב בִּגְד֛וֹ אֶצְלִ֖י וַיָּ֥נָס הַחֽוּצָה׃"
          },
          {
            "num": "יט",
            "txt": "וַיְהִי֩ כִשְׁמֹ֨עַ אֲדֹנָ֜יו אֶת־דִּבְרֵ֣י אִשְׁתּ֗וֹ אֲשֶׁ֨ר דִּבְּרָ֤ה אֵלָיו֙ לֵאמֹ֔ר כַּדְּבָרִ֣ים הָאֵ֔לֶּה עָ֥שָׂה לִ֖י עַבְדֶּ֑ךָ וַיִּ֖חַר אַפּֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּקַּח֩ אֲדֹנֵ֨י יוֹסֵ֜ף אֹת֗וֹ וַֽיִּתְּנֵ֨הוּ֙ אֶל־בֵּ֣ית הַסֹּ֔הַר מְק֕וֹם אֲשֶׁר־אסורי (אֲסִירֵ֥י) הַמֶּ֖לֶךְ אֲסוּרִ֑ים וַֽיְהִי־שָׁ֖ם בְּבֵ֥ית הַסֹּֽהַר׃"
          },
          {
            "num": "כא",
            "txt": "וַיְהִ֤י יְהוָה֙ אֶת־יוֹסֵ֔ף וַיֵּ֥ט אֵלָ֖יו חָ֑סֶד וַיִּתֵּ֣ן חִנּ֔וֹ בְּעֵינֵ֖י שַׂ֥ר בֵּית־הַסֹּֽהַר׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּתֵּ֞ן שַׂ֤ר בֵּית־הַסֹּ֨הַר֙ בְּיַד־יוֹסֵ֔ף אֵ֚ת כָּל־הָ֣אֲסִירִ֔ם אֲשֶׁ֖ר בְּבֵ֣ית הַסֹּ֑הַר וְאֵ֨ת כָּל־אֲשֶׁ֤ר עֹשִׂים֙ שָׁ֔ם ה֖וּא הָיָ֥ה עֹשֶֽׂה׃"
          },
          {
            "num": "כג",
            "txt": "אֵ֣ין ׀ שַׂ֣ר בֵּית־הַסֹּ֗הַר רֹאֶ֤ה אֶֽת־כָּל־מְא֨וּמָה֙ בְּיָד֔וֹ בַּֽאֲשֶׁ֥ר יְהוָ֖ה אִתּ֑וֹ וַֽאֲשֶׁר־ה֥וּא עֹשֶׂ֖ה יְהוָ֥ה מַצְלִֽיחַ׃ {פ}"
          }
        ]
      },
      {
        "num": "מ",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֗י אַחַר֙ הַדְּבָרִ֣ים הָאֵ֔לֶּה חָ֥טְא֛וּ מַשְׁקֵ֥ה מֶֽלֶךְ־מִצְרַ֖יִם וְהָֽאֹפֶ֑ה לַאֲדֹֽנֵיהֶ֖ם לְמֶ֥לֶךְ מִצְרָֽיִם׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּקְצֹ֣ף פַּרְעֹ֔ה עַ֖ל שְׁנֵ֣י סָֽרִיסָ֑יו עַ֚ל שַׂ֣ר הַמַּשְׁקִ֔ים וְעַ֖ל שַׂ֥ר הָֽאוֹפִֽים׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּתֵּ֨ן אֹתָ֜ם בְּמִשְׁמַ֗ר בֵּ֛ית שַׂ֥ר הַטַּבָּחִ֖ים אֶל־בֵּ֣ית הַסֹּ֑הַר מְק֕וֹם אֲשֶׁ֥ר יוֹסֵ֖ף אָס֥וּר שָֽׁם׃"
          },
          {
            "num": "ד",
            "txt": "וַ֠יִּפְקֹד שַׂ֣ר הַטַּבָּחִ֧ים אֶת־יוֹסֵ֛ף אִתָּ֖ם וַיְשָׁ֣רֶת אֹתָ֑ם וַיִּֽהְי֥וּ יָמִ֖ים בְּמִשְׁמָֽר׃"
          },
          {
            "num": "ה",
            "txt": "וַיַּֽחַלְמוּ֩ חֲל֨וֹם שְׁנֵיהֶ֜ם אִ֤ישׁ חֲלֹמוֹ֙ בְּלַ֣יְלָה אֶחָ֔ד אִ֖ישׁ כְּפִתְר֣וֹן חֲלֹמ֑וֹ הַמַּשְׁקֶ֣ה וְהָֽאֹפֶ֗ה אֲשֶׁר֙ לְמֶ֣לֶךְ מִצְרַ֔יִם אֲשֶׁ֥ר אֲסוּרִ֖ים בְּבֵ֥ית הַסֹּֽהַר׃"
          },
          {
            "num": "ו",
            "txt": "וַיָּבֹ֧א אֲלֵיהֶ֛ם יוֹסֵ֖ף בַּבֹּ֑קֶר וַיַּ֣רְא אֹתָ֔ם וְהִנָּ֖ם זֹֽעֲפִֽים׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּשְׁאַ֞ל אֶת־סְרִיסֵ֣י פַרְעֹ֗ה אֲשֶׁ֨ר אִתּ֧וֹ בְמִשְׁמַ֛ר בֵּ֥ית אֲדֹנָ֖יו לֵאמֹ֑ר מַדּ֛וּעַ פְּנֵיכֶ֥ם רָעִ֖ים הַיּֽוֹם׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּֽאמְר֣וּ אֵלָ֔יו חֲל֣וֹם חָלַ֔מְנוּ וּפֹתֵ֖ר אֵ֣ין אֹת֑וֹ וַיֹּ֨אמֶר אֲלֵהֶ֜ם יוֹסֵ֗ף הֲל֤וֹא לֵֽאלֹהִים֙ פִּתְרֹנִ֔ים סַפְּרוּ־נָ֖א לִֽי׃"
          },
          {
            "num": "ט",
            "txt": "וַיְסַפֵּ֧ר שַֽׂר־הַמַּשְׁקִ֛ים אֶת־חֲלֹמ֖וֹ לְיוֹסֵ֑ף וַיֹּ֣אמֶר ל֔וֹ בַּֽחֲלוֹמִ֕י וְהִנֵּה־גֶ֖פֶן לְפָנָֽי׃"
          },
          {
            "num": "י",
            "txt": "וּבַגֶּ֖פֶן שְׁלֹשָׁ֣ה שָֽׂרִיגִ֑ם וְהִ֤וא כְפֹרַ֨חַת֙ עָֽלְתָ֣ה נִצָּ֔הּ הִבְשִׁ֥ילוּ אַשְׁכְּלֹתֶ֖יהָ עֲנָבִֽים׃"
          },
          {
            "num": "יא",
            "txt": "וְכ֥וֹס פַּרְעֹ֖ה בְּיָדִ֑י וָֽאֶקַּ֣ח אֶת־הָֽעֲנָבִ֗ים וָֽאֶשְׂחַ֤ט אֹתָם֙ אֶל־כּ֣וֹס פַּרְעֹ֔ה וָֽאֶתֵּ֥ן אֶת־הַכּ֖וֹס עַל־כַּ֥ף פַּרְעֹֽה׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֤אמֶר לוֹ֙ יוֹסֵ֔ף זֶ֖ה פִּתְרֹנ֑וֹ שְׁלֹ֨שֶׁת֙ הַשָּׂ֣רִגִ֔ים שְׁלֹ֥שֶׁת יָמִ֖ים הֵֽם׃"
          },
          {
            "num": "יג",
            "txt": "בְּע֣וֹד ׀ שְׁלֹ֣שֶׁת יָמִ֗ים יִשָּׂ֤א פַרְעֹה֙ אֶת־רֹאשֶׁ֔ךָ וַהֲשִֽׁיבְךָ֖ עַל־כַּנֶּ֑ךָ וְנָֽתַתָּ֤ כוֹס־פַּרְעֹה֙ בְּיָד֔וֹ כַּמִּשְׁפָּט֙ הָֽרִאשׁ֔וֹן אֲשֶׁ֥ר הָיִ֖יתָ מַשְׁקֵֽהוּ׃"
          },
          {
            "num": "יד",
            "txt": "כִּ֧י אִם־זְכַרְתַּ֣נִי אִתְּךָ֗ כַּֽאֲשֶׁר֙ יִ֣יטַב לָ֔ךְ וְעָשִֽׂיתָ־נָּ֥א עִמָּדִ֖י חָ֑סֶד וְהִזְכַּרְתַּ֨נִי֙ אֶל־פַּרְעֹ֔ה וְהֽוֹצֵאתַ֖נִי מִן־הַבַּ֥יִת הַזֶּֽה׃"
          },
          {
            "num": "טו",
            "txt": "כִּֽי־גֻנֹּ֣ב גֻּנַּ֔בְתִּי מֵאֶ֖רֶץ הָֽעִבְרִ֑ים וְגַם־פֹּה֙ לֹֽא־עָשִׂ֣יתִֽי מְא֔וּמָה כִּֽי־שָׂמ֥וּ אֹתִ֖י בַּבּֽוֹר׃"
          },
          {
            "num": "טז",
            "txt": "וַיַּ֥רְא שַׂר־הָֽאֹפִ֖ים כִּ֣י ט֣וֹב פָּתָ֑ר וַיֹּ֨אמֶר֙ אֶל־יוֹסֵ֔ף אַף־אֲנִי֙ בַּֽחֲלוֹמִ֔י וְהִנֵּ֗ה שְׁלֹשָׁ֛ה סַלֵּ֥י חֹרִ֖י עַל־רֹאשִֽׁי׃"
          },
          {
            "num": "יז",
            "txt": "וּבַסַּ֣ל הָֽעֶלְי֗וֹן מִכֹּ֛ל מַֽאֲכַ֥ל פַּרְעֹ֖ה מַֽעֲשֵׂ֣ה אֹפֶ֑ה וְהָע֗וֹף אֹכֵ֥ל אֹתָ֛ם מִן־הַסַּ֖ל מֵעַ֥ל רֹאשִֽׁי׃"
          },
          {
            "num": "יח",
            "txt": "וַיַּ֤עַן יוֹסֵף֙ וַיֹּ֔אמֶר זֶ֖ה פִּתְרֹנ֑וֹ שְׁלֹ֨שֶׁת֙ הַסַּלִּ֔ים שְׁלֹ֥שֶׁת יָמִ֖ים הֵֽם׃"
          },
          {
            "num": "יט",
            "txt": "בְּע֣וֹד ׀ שְׁלֹ֣שֶׁת יָמִ֗ים יִשָּׂ֨א פַרְעֹ֤ה אֶת־רֹֽאשְׁךָ֙ מֵֽעָלֶ֔יךָ וְתָלָ֥ה אֽוֹתְךָ֖ עַל־עֵ֑ץ וְאָכַ֥ל הָע֛וֹף אֶת־בְּשָֽׂרְךָ֖ מֵֽעָלֶֽיךָ׃"
          },
          {
            "num": "כ",
            "txt": "וַיְהִ֣י ׀ בַּיּ֣וֹם הַשְּׁלִישִׁ֗י י֚וֹם הֻלֶּ֣דֶת אֶת־פַּרְעֹ֔ה וַיַּ֥עַשׂ מִשְׁתֶּ֖ה לְכָל־עֲבָדָ֑יו וַיִּשָּׂ֞א אֶת־רֹ֣אשׁ ׀ שַׂ֣ר הַמַּשְׁקִ֗ים וְאֶת־רֹ֛אשׁ שַׂ֥ר הָֽאֹפִ֖ים בְּת֥וֹךְ עֲבָדָֽיו׃"
          },
          {
            "num": "כא",
            "txt": "וַיָּ֛שֶׁב אֶת־שַׂ֥ר הַמַּשְׁקִ֖ים עַל־מַשְׁקֵ֑הוּ וַיִּתֵּ֥ן הַכּ֖וֹס עַל־כַּ֥ף פַּרְעֹֽה׃"
          },
          {
            "num": "כב",
            "txt": "וְאֵ֛ת שַׂ֥ר הָֽאֹפִ֖ים תָּלָ֑ה כַּֽאֲשֶׁ֥ר פָּתַ֛ר לָהֶ֖ם יוֹסֵֽף׃"
          },
          {
            "num": "כג",
            "txt": "וְלֹֽא־זָכַ֧ר שַֽׂר־הַמַּשְׁקִ֛ים אֶת־יוֹסֵ֖ף וַיִּשְׁכָּחֵֽהוּ׃ {פ}"
          }
        ]
      },
      {
        "num": "מא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֕י מִקֵּ֖ץ שְׁנָתַ֣יִם יָמִ֑ים וּפַרְעֹ֣ה חֹלֵ֔ם וְהִנֵּ֖ה עֹמֵ֥ד עַל־הַיְאֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "וְהִנֵּ֣ה מִן־הַיְאֹ֗ר עֹלֹת֙ שֶׁ֣בַע פָּר֔וֹת יְפ֥וֹת מַרְאֶ֖ה וּבְרִיאֹ֣ת בָּשָׂ֑ר וַתִּרְעֶ֖ינָה בָּאָֽחוּ׃"
          },
          {
            "num": "ג",
            "txt": "וְהִנֵּ֞ה שֶׁ֧בַע פָּר֣וֹת אֲחֵר֗וֹת עֹל֤וֹת אַֽחֲרֵיהֶן֙ מִן־הַיְאֹ֔ר רָע֥וֹת מַרְאֶ֖ה וְדַקּ֣וֹת בָּשָׂ֑ר וַֽתַּעֲמֹ֛דְנָה אֵ֥צֶל הַפָּר֖וֹת עַל־שְׂפַ֥ת הַיְאֹֽר׃"
          },
          {
            "num": "ד",
            "txt": "וַתֹּאכַ֣לְנָה הַפָּר֗וֹת רָע֤וֹת הַמַּרְאֶה֙ וְדַקֹּ֣ת הַבָּשָׂ֔ר אֵ֚ת שֶׁ֣בַע הַפָּר֔וֹת יְפֹ֥ת הַמַּרְאֶ֖ה וְהַבְּרִיאֹ֑ת וַיִּיקַ֖ץ פַּרְעֹֽה׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּישָׁ֕ן וַֽיַּחֲלֹ֖ם שֵׁנִ֑ית וְהִנֵּ֣ה ׀ שֶׁ֣בַע שִׁבֳּלִ֗ים עֹל֛וֹת בְּקָנֶ֥ה אֶחָ֖ד בְּרִיא֥וֹת וְטֹבֽוֹת׃"
          },
          {
            "num": "ו",
            "txt": "וְהִנֵּה֙ שֶׁ֣בַע שִׁבֳּלִ֔ים דַּקּ֖וֹת וּשְׁדוּפֹ֣ת קָדִ֑ים צֹֽמְח֖וֹת אַֽחֲרֵיהֶֽן׃"
          },
          {
            "num": "ז",
            "txt": "וַתִּבְלַ֨עְנָה֙ הַשִּׁבֳּלִ֣ים הַדַּקּ֔וֹת אֵ֚ת שֶׁ֣בַע הַֽשִּׁבֳּלִ֔ים הַבְּרִיא֖וֹת וְהַמְּלֵא֑וֹת וַיִּיקַ֥ץ פַּרְעֹ֖ה וְהִנֵּ֥ה חֲלֽוֹם׃"
          },
          {
            "num": "ח",
            "txt": "וַיְהִ֤י בַבֹּ֨קֶר֙ וַתִּפָּ֣עֶם רוּח֔וֹ וַיִּשְׁלַ֗ח וַיִּקְרָ֛א אֶת־כָּל־חַרְטֻמֵּ֥י מִצְרַ֖יִם וְאֶת־כָּל־חֲכָמֶ֑יהָ וַיְסַפֵּ֨ר פַּרְעֹ֤ה לָהֶם֙ אֶת־חֲלֹמ֔וֹ וְאֵין־פּוֹתֵ֥ר אוֹתָ֖ם לְפַרְעֹֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיְדַבֵּר֙ שַׂ֣ר הַמַּשְׁקִ֔ים אֶת־פַּרְעֹ֖ה לֵאמֹ֑ר אֶת־חֲטָאַ֕י אֲנִ֖י מַזְכִּ֥יר הַיּֽוֹם׃"
          },
          {
            "num": "י",
            "txt": "פַּרְעֹ֖ה קָצַ֣ף עַל־עֲבָדָ֑יו וַיִּתֵּ֨ן אֹתִ֜י בְּמִשְׁמַ֗ר בֵּ֚ית שַׂ֣ר הַטַּבָּחִ֔ים אֹתִ֕י וְאֵ֖ת שַׂ֥ר הָֽאֹפִֽים׃"
          },
          {
            "num": "יא",
            "txt": "וַנַּֽחַלְמָ֥ה חֲל֛וֹם בְּלַ֥יְלָה אֶחָ֖ד אֲנִ֣י וָה֑וּא אִ֛ישׁ כְּפִתְר֥וֹן חֲלֹמ֖וֹ חָלָֽמְנוּ׃"
          },
          {
            "num": "יב",
            "txt": "וְשָׁ֨ם אִתָּ֜נוּ נַ֣עַר עִבְרִ֗י עֶ֚בֶד לְשַׂ֣ר הַטַּבָּחִ֔ים וַ֨נְּסַפֶּר־ל֔וֹ וַיִּפְתָּר־לָ֖נוּ אֶת־חֲלֹֽמֹתֵ֑ינוּ אִ֥ישׁ כַּֽחֲלֹמ֖וֹ פָּתָֽר׃"
          },
          {
            "num": "יג",
            "txt": "וַיְהִ֛י כַּֽאֲשֶׁ֥ר פָּֽתַר־לָ֖נוּ כֵּ֣ן הָיָ֑ה אֹתִ֛י הֵשִׁ֥יב עַל־כַּנִּ֖י וְאֹת֥וֹ תָלָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּשְׁלַ֤ח פַּרְעֹה֙ וַיִּקְרָ֣א אֶת־יוֹסֵ֔ף וַיְרִיצֻ֖הוּ מִן־הַבּ֑וֹר וַיְגַלַּח֙ וַיְחַלֵּ֣ף שִׂמְלֹתָ֔יו וַיָּבֹ֖א אֶל־פַּרְעֹֽה׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֤אמֶר פַּרְעֹה֙ אֶל־יוֹסֵ֔ף חֲל֣וֹם חָלַ֔מְתִּי וּפֹתֵ֖ר אֵ֣ין אֹת֑וֹ וַֽאֲנִ֗י שָׁמַ֤עְתִּי עָלֶ֨יךָ֙ לֵאמֹ֔ר תִּשְׁמַ֥ע חֲל֖וֹם לִפְתֹּ֥ר אֹתֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "וַיַּ֨עַן יוֹסֵ֧ף אֶת־פַּרְעֹ֛ה לֵאמֹ֖ר בִּלְעָדָ֑י אֱלֹהִ֕ים יַֽעֲנֶ֖ה אֶת־שְׁל֥וֹם פַּרְעֹֽה׃"
          },
          {
            "num": "יז",
            "txt": "וַיְדַבֵּ֥ר פַּרְעֹ֖ה אֶל־יוֹסֵ֑ף בַּֽחֲלֹמִ֕י הִנְנִ֥י עֹמֵ֖ד עַל־שְׂפַ֥ת הַיְאֹֽר׃"
          },
          {
            "num": "יח",
            "txt": "וְהִנֵּ֣ה מִן־הַיְאֹ֗ר עֹלֹת֙ שֶׁ֣בַע פָּר֔וֹת בְּרִיא֥וֹת בָּשָׂ֖ר וִיפֹ֣ת תֹּ֑אַר וַתִּרְעֶ֖ינָה בָּאָֽחוּ׃"
          },
          {
            "num": "יט",
            "txt": "וְהִנֵּ֞ה שֶֽׁבַע־פָּר֤וֹת אֲחֵרוֹת֙ עֹל֣וֹת אַֽחֲרֵיהֶ֔ן דַּלּ֨וֹת וְרָע֥וֹת תֹּ֛אַר מְאֹ֖ד וְרַקּ֣וֹת בָּשָׂ֑ר לֹֽא־רָאִ֧יתִי כָהֵ֛נָּה בְּכָל־אֶ֥רֶץ מִצְרַ֖יִם לָרֹֽעַ׃"
          },
          {
            "num": "כ",
            "txt": "וַתֹּאכַ֨לְנָה֙ הַפָּר֔וֹת הָֽרַקּ֖וֹת וְהָֽרָע֑וֹת אֵ֣ת שֶׁ֧בַע הַפָּר֛וֹת הָרִֽאשֹׁנ֖וֹת הַבְּרִיאֹֽת׃"
          },
          {
            "num": "כא",
            "txt": "וַתָּבֹ֣אנָה אֶל־קִרְבֶּ֗נָה וְלֹ֤א נוֹדַע֙ כִּי־בָ֣אוּ אֶל־קִרְבֶּ֔נָה וּמַרְאֵיהֶ֣ן רַ֔ע כַּֽאֲשֶׁ֖ר בַּתְּחִלָּ֑ה וָֽאִיקָֽץ׃"
          },
          {
            "num": "כב",
            "txt": "וָאֵ֖רֶא בַּֽחֲלֹמִ֑י וְהִנֵּ֣ה ׀ שֶׁ֣בַע שִׁבֳּלִ֗ים עֹלֹ֛ת בְּקָנֶ֥ה אֶחָ֖ד מְלֵאֹ֥ת וְטֹבֽוֹת׃"
          },
          {
            "num": "כג",
            "txt": "וְהִנֵּה֙ שֶׁ֣בַע שִׁבֳּלִ֔ים צְנֻמ֥וֹת דַּקּ֖וֹת שְׁדֻפ֣וֹת קָדִ֑ים צֹֽמְח֖וֹת אַֽחֲרֵיהֶֽם׃"
          },
          {
            "num": "כד",
            "txt": "וַתִּבְלַ֨עְןָ֙ הַשִּׁבֳּלִ֣ים הַדַּקֹּ֔ת אֵ֛ת שֶׁ֥בַע הַֽשִּׁבֳּלִ֖ים הַטֹּב֑וֹת וָֽאֹמַר֙ אֶל־הַֽחַרְטֻמִּ֔ים וְאֵ֥ין מַגִּ֖יד לִֽי׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֤אמֶר יוֹסֵף֙ אֶל־פַּרְעֹ֔ה חֲל֥וֹם פַּרְעֹ֖ה אֶחָ֣ד ה֑וּא אֵ֣ת אֲשֶׁ֧ר הָֽאֱלֹהִ֛ים עֹשֶׂ֖ה הִגִּ֥יד לְפַרְעֹֽה׃"
          },
          {
            "num": "כו",
            "txt": "שֶׁ֧בַע פָּרֹ֣ת הַטֹּבֹ֗ת שֶׁ֤בַע שָׁנִים֙ הֵ֔נָּה וְשֶׁ֤בַע הַֽשִּׁבֳּלִים֙ הַטֹּבֹ֔ת שֶׁ֥בַע שָׁנִ֖ים הֵ֑נָּה חֲל֖וֹם אֶחָ֥ד הֽוּא׃"
          },
          {
            "num": "כז",
            "txt": "וְשֶׁ֣בַע הַ֠פָּרוֹת הָֽרַקּ֨וֹת וְהָֽרָעֹ֜ת הָֽעֹלֹ֣ת אַֽחֲרֵיהֶ֗ן שֶׁ֤בַע שָׁנִים֙ הֵ֔נָּה וְשֶׁ֤בַע הַֽשִּׁבֳּלִים֙ הָֽרֵק֔וֹת שְׁדֻפ֖וֹת הַקָּדִ֑ים יִֽהְי֕וּ שֶׁ֖בַע שְׁנֵ֥י רָעָֽב׃"
          },
          {
            "num": "כח",
            "txt": "ה֣וּא הַדָּבָ֔ר אֲשֶׁ֥ר דִּבַּ֖רְתִּי אֶל־פַּרְעֹ֑ה אֲשֶׁ֧ר הָֽאֱלֹהִ֛ים עֹשֶׂ֖ה הֶרְאָ֥ה אֶת־פַּרְעֹֽה׃"
          },
          {
            "num": "כט",
            "txt": "הִנֵּ֛ה שֶׁ֥בַע שָׁנִ֖ים בָּא֑וֹת שָׂבָ֥ע גָּד֖וֹל בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ל",
            "txt": "וְ֠קָמוּ שֶׁ֜בַע שְׁנֵ֤י רָעָב֙ אַֽחֲרֵיהֶ֔ן וְנִשְׁכַּ֥ח כָּל־הַשָּׂבָ֖ע בְּאֶ֣רֶץ מִצְרָ֑יִם וְכִלָּ֥ה הָֽרָעָ֖ב אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "לא",
            "txt": "וְלֹֽא־יִוָּדַ֤ע הַשָּׂבָע֙ בָּאָ֔רֶץ מִפְּנֵ֛י הָֽרָעָ֥ב הַה֖וּא אַֽחֲרֵי־כֵ֑ן כִּֽי־כָבֵ֥ד ה֖וּא מְאֹֽד׃"
          },
          {
            "num": "לב",
            "txt": "וְעַ֨ל הִשָּׁנ֧וֹת הַֽחֲל֛וֹם אֶל־פַּרְעֹ֖ה פַּֽעֲמָ֑יִם כִּֽי־נָכ֤וֹן הַדָּבָר֙ מֵעִ֣ם הָֽאֱלֹהִ֔ים וּמְמַהֵ֥ר הָֽאֱלֹהִ֖ים לַֽעֲשֹׂתֽוֹ׃"
          },
          {
            "num": "לג",
            "txt": "וְעַתָּה֙ יֵרֶ֣א פַרְעֹ֔ה אִ֖ישׁ נָב֣וֹן וְחָכָ֑ם וִֽישִׁיתֵ֖הוּ עַל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "לד",
            "txt": "יַֽעֲשֶׂ֣ה פַרְעֹ֔ה וְיַפְקֵ֥ד פְּקִדִ֖ים עַל־הָאָ֑רֶץ וְחִמֵּשׁ֙ אֶת־אֶ֣רֶץ מִצְרַ֔יִם בְּשֶׁ֖בַע שְׁנֵ֥י הַשָּׂבָֽע׃"
          },
          {
            "num": "לה",
            "txt": "וְיִקְבְּצ֗וּ אֶת־כָּל־אֹ֨כֶל֙ הַשָּׁנִ֣ים הַטֹּב֔וֹת הַבָּאֹ֖ת הָאֵ֑לֶּה וְיִצְבְּרוּ־בָ֞ר תַּ֧חַת יַד־פַּרְעֹ֛ה אֹ֥כֶל בֶּֽעָרִ֖ים וְשָׁמָֽרוּ׃"
          },
          {
            "num": "לו",
            "txt": "וְהָיָ֨ה הָאֹ֤כֶל לְפִקָּדוֹן֙ לָאָ֔רֶץ לְשֶׁ֨בַע֙ שְׁנֵ֣י הָֽרָעָ֔ב אֲשֶׁ֥ר תִּֽהְיֶ֖יןָ בְּאֶ֣רֶץ מִצְרָ֑יִם וְלֹֽא־תִכָּרֵ֥ת הָאָ֖רֶץ בָּֽרָעָֽב׃"
          },
          {
            "num": "לז",
            "txt": "וַיִּיטַ֥ב הַדָּבָ֖ר בְּעֵינֵ֣י פַרְעֹ֑ה וּבְעֵינֵ֖י כָּל־עֲבָדָֽיו׃"
          },
          {
            "num": "לח",
            "txt": "וַיֹּ֥אמֶר פַּרְעֹ֖ה אֶל־עֲבָדָ֑יו הֲנִמְצָ֣א כָזֶ֔ה אִ֕ישׁ אֲשֶׁ֛ר ר֥וּחַ אֱלֹהִ֖ים בּֽוֹ׃"
          },
          {
            "num": "לט",
            "txt": "וַיֹּ֤אמֶר פַּרְעֹה֙ אֶל־יוֹסֵ֔ף אַֽחֲרֵ֨י הוֹדִ֧יעַ אֱלֹהִ֛ים אֽוֹתְךָ֖ אֶת־כָּל־זֹ֑את אֵין־נָב֥וֹן וְחָכָ֖ם כָּמֽוֹךָ׃"
          },
          {
            "num": "מ",
            "txt": "אַתָּה֙ תִּֽהְיֶ֣ה עַל־בֵּיתִ֔י וְעַל־פִּ֖יךָ יִשַּׁ֣ק כָּל־עַמִּ֑י רַ֥ק הַכִּסֵּ֖א אֶגְדַּ֥ל מִמֶּֽךָּ׃"
          },
          {
            "num": "מא",
            "txt": "וַיֹּ֥אמֶר פַּרְעֹ֖ה אֶל־יוֹסֵ֑ף רְאֵה֙ נָתַ֣תִּי אֹֽתְךָ֔ עַ֖ל כָּל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "מב",
            "txt": "וַיָּ֨סַר פַּרְעֹ֤ה אֶת־טַבַּעְתּוֹ֙ מֵעַ֣ל יָד֔וֹ וַיִּתֵּ֥ן אֹתָ֖הּ עַל־יַ֣ד יוֹסֵ֑ף וַיַּלְבֵּ֤שׁ אֹתוֹ֙ בִּגְדֵי־שֵׁ֔שׁ וַיָּ֛שֶׂם רְבִ֥ד הַזָּהָ֖ב עַל־צַוָּארֽוֹ׃"
          },
          {
            "num": "מג",
            "txt": "וַיַּרְכֵּ֣ב אֹת֗וֹ בְּמִרְכֶּ֤בֶת הַמִּשְׁנֶה֙ אֲשֶׁר־ל֔וֹ וַיִּקְרְא֥וּ לְפָנָ֖יו אַבְרֵ֑ךְ וְנָת֣וֹן אֹת֔וֹ עַ֖ל כָּל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "מד",
            "txt": "וַיֹּ֧אמֶר פַּרְעֹ֛ה אֶל־יוֹסֵ֖ף אֲנִ֣י פַרְעֹ֑ה וּבִלְעָדֶ֗יךָ לֹֽא־יָרִ֨ים אִ֧ישׁ אֶת־יָד֛וֹ וְאֶת־רַגְל֖וֹ בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "מה",
            "txt": "וַיִּקְרָ֨א פַרְעֹ֣ה שֵׁם־יוֹסֵף֮ צָֽפְנַ֣ת פַּעְנֵחַ֒ וַיִּתֶּן־ל֣וֹ אֶת־אָֽסְנַ֗ת בַּת־פּ֥וֹטִי פֶ֛רַע כֹּהֵ֥ן אֹ֖ן לְאִשָּׁ֑ה וַיֵּצֵ֥א יוֹסֵ֖ף עַל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "מו",
            "txt": "וְיוֹסֵף֙ בֶּן־שְׁלֹשִׁ֣ים שָׁנָ֔ה בְּעָמְד֕וֹ לִפְנֵ֖י פַּרְעֹ֣ה מֶֽלֶךְ־מִצְרָ֑יִם וַיֵּצֵ֤א יוֹסֵף֙ מִלִּפְנֵ֣י פַרְעֹ֔ה וַֽיַּעֲבֹ֖ר בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "מז",
            "txt": "וַתַּ֣עַשׂ הָאָ֔רֶץ בְּשֶׁ֖בַע שְׁנֵ֣י הַשָּׂבָ֑ע לִקְמָצִֽים׃"
          },
          {
            "num": "מח",
            "txt": "וַיִּקְבֹּ֞ץ אֶת־כָּל־אֹ֣כֶל ׀ שֶׁ֣בַע שָׁנִ֗ים אֲשֶׁ֤ר הָיוּ֙ בְּאֶ֣רֶץ מִצְרַ֔יִם וַיִּתֶּן־אֹ֖כֶל בֶּֽעָרִ֑ים אֹ֧כֶל שְׂדֵֽה־הָעִ֛יר אֲשֶׁ֥ר סְבִֽיבֹתֶ֖יהָ נָתַ֥ן בְּתוֹכָֽהּ׃"
          },
          {
            "num": "מט",
            "txt": "וַיִּצְבֹּ֨ר יוֹסֵ֥ף בָּ֛ר כְּח֥וֹל הַיָּ֖ם הַרְבֵּ֣ה מְאֹ֑ד עַ֛ד כִּֽי־חָדַ֥ל לִסְפֹּ֖ר כִּי־אֵ֥ין מִסְפָּֽר׃"
          },
          {
            "num": "נ",
            "txt": "וּלְיוֹסֵ֤ף יֻלַּד֙ שְׁנֵ֣י בָנִ֔ים בְּטֶ֥רֶם תָּב֖וֹא שְׁנַ֣ת הָֽרָעָ֑ב אֲשֶׁ֤ר יָֽלְדָה־לּוֹ֙ אָֽסְנַ֔ת בַּת־פּ֥וֹטִי פֶ֖רַע כֹּהֵ֥ן אֽוֹן׃"
          },
          {
            "num": "נא",
            "txt": "וַיִּקְרָ֥א יוֹסֵ֛ף אֶת־שֵׁ֥ם הַבְּכ֖וֹר מְנַשֶּׁ֑ה כִּֽי־נַשַּׁ֤נִי אֱלֹהִים֙ אֶת־כָּל־עֲמָלִ֔י וְאֵ֖ת כָּל־בֵּ֥ית אָבִֽי׃"
          },
          {
            "num": "נב",
            "txt": "וְאֵ֛ת שֵׁ֥ם הַשֵּׁנִ֖י קָרָ֣א אֶפְרָ֑יִם כִּֽי־הִפְרַ֥נִי אֱלֹהִ֖ים בְּאֶ֥רֶץ עָנְיִֽי׃"
          },
          {
            "num": "נג",
            "txt": "וַתִּכְלֶ֕ינָה שֶׁ֖בַע שְׁנֵ֣י הַשָּׂבָ֑ע אֲשֶׁ֥ר הָיָ֖ה בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "נד",
            "txt": "וַתְּחִלֶּ֜ינָה שֶׁ֣בַע שְׁנֵ֤י הָֽרָעָב֙ לָב֔וֹא כַּֽאֲשֶׁ֖ר אָמַ֣ר יוֹסֵ֑ף וַיְהִ֤י רָעָב֙ בְּכָל־הָ֣אֲרָצ֔וֹת וּבְכָל־אֶ֥רֶץ מִצְרַ֖יִם הָ֥יָה לָֽחֶם׃"
          },
          {
            "num": "נה",
            "txt": "וַתִּרְעַב֙ כָּל־אֶ֣רֶץ מִצְרַ֔יִם וַיִּצְעַ֥ק הָעָ֛ם אֶל־פַּרְעֹ֖ה לַלָּ֑חֶם וַיֹּ֨אמֶר פַּרְעֹ֤ה לְכָל־מִצְרַ֨יִם֙ לְכ֣וּ אֶל־יוֹסֵ֔ף אֲשֶׁר־יֹאמַ֥ר לָכֶ֖ם תַּֽעֲשֽׂוּ׃"
          },
          {
            "num": "נו",
            "txt": "וְהָֽרָעָ֣ב הָיָ֔ה עַ֖ל כָּל־פְּנֵ֣י הָאָ֑רֶץ וַיִּפְתַּ֨ח יוֹסֵ֜ף אֶֽת־כָּל־אֲשֶׁ֤ר בָּהֶם֙ וַיִּשְׁבֹּ֣ר לְמִצְרַ֔יִם וַיֶּֽחֱזַ֥ק הָֽרָעָ֖ב בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "נז",
            "txt": "וְכָל־הָאָ֨רֶץ֙ בָּ֣אוּ מִצְרַ֔יְמָה לִשְׁבֹּ֖ר אֶל־יוֹסֵ֑ף כִּֽי־חָזַ֥ק הָֽרָעָ֖ב בְּכָל־הָאָֽרֶץ׃"
          }
        ]
      },
      {
        "num": "מב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּ֣רְא יַֽעֲקֹ֔ב כִּ֥י יֶשׁ־שֶׁ֖בֶר בְּמִצְרָ֑יִם וַיֹּ֤אמֶר יַֽעֲקֹב֙ לְבָנָ֔יו לָ֖מָּה תִּתְרָאֽוּ׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֕אמֶר הִנֵּ֣ה שָׁמַ֔עְתִּי כִּ֥י יֶשׁ־שֶׁ֖בֶר בְּמִצְרָ֑יִם רְדוּ־שָׁ֨מָּה֙ וְשִׁבְרוּ־לָ֣נוּ מִשָּׁ֔ם וְנִֽחְיֶ֖ה וְלֹ֥א נָמֽוּת׃"
          },
          {
            "num": "ג",
            "txt": "וַיֵּֽרְד֥וּ אֲחֵֽי־יוֹסֵ֖ף עֲשָׂרָ֑ה לִשְׁבֹּ֥ר בָּ֖ר מִמִּצְרָֽיִם׃"
          },
          {
            "num": "ד",
            "txt": "וְאֶת־בִּנְיָמִין֙ אֲחִ֣י יוֹסֵ֔ף לֹֽא־שָׁלַ֥ח יַֽעֲקֹ֖ב אֶת־אֶחָ֑יו כִּ֣י אָמַ֔ר פֶּן־יִקְרָאֶ֖נּוּ אָסֽוֹן׃"
          },
          {
            "num": "ה",
            "txt": "וַיָּבֹ֨אוּ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל לִשְׁבֹּ֖ר בְּת֣וֹךְ הַבָּאִ֑ים כִּֽי־הָיָ֥ה הָֽרָעָ֖ב בְּאֶ֥רֶץ כְּנָֽעַן׃"
          },
          {
            "num": "ו",
            "txt": "וְיוֹסֵ֗ף ה֚וּא הַשַּׁלִּ֣יט עַל־הָאָ֔רֶץ ה֥וּא הַמַּשְׁבִּ֖יר לְכָל־עַ֣ם הָאָ֑רֶץ וַיָּבֹ֨אוּ֙ אֲחֵ֣י יוֹסֵ֔ף וַיִּשְׁתַּֽחֲווּ־ל֥וֹ אַפַּ֖יִם אָֽרְצָה׃"
          },
          {
            "num": "ז",
            "txt": "וַיַּ֥רְא יוֹסֵ֛ף אֶת־אֶחָ֖יו וַיַּכִּרֵ֑ם וַיִּתְנַכֵּ֨ר אֲלֵיהֶ֜ם וַיְדַבֵּ֧ר אִתָּ֣ם קָשׁ֗וֹת וַיֹּ֤אמֶר אֲלֵהֶם֙ מֵאַ֣יִן בָּאתֶ֔ם וַיֹּ֣אמְר֔וּ מֵאֶ֥רֶץ כְּנַ֖עַן לִשְׁבָּר־אֹֽכֶל׃"
          },
          {
            "num": "ח",
            "txt": "וַיַּכֵּ֥ר יוֹסֵ֖ף אֶת־אֶחָ֑יו וְהֵ֖ם לֹ֥א הִכִּרֻֽהוּ׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּזְכֹּ֣ר יוֹסֵ֔ף אֵ֚ת הַֽחֲלֹמ֔וֹת אֲשֶׁ֥ר חָלַ֖ם לָהֶ֑ם וַיֹּ֤אמֶר אֲלֵהֶם֙ מְרַגְּלִ֣ים אַתֶּ֔ם לִרְא֛וֹת אֶת־עֶרְוַ֥ת הָאָ֖רֶץ בָּאתֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּֽאמְר֥וּ אֵלָ֖יו לֹ֣א אֲדֹנִ֑י וַֽעֲבָדֶ֥יךָ בָּ֖אוּ לִשְׁבָּר־אֹֽכֶל׃"
          },
          {
            "num": "יא",
            "txt": "כֻּלָּ֕נוּ בְּנֵ֥י אִישׁ־אֶחָ֖ד נָ֑חְנוּ כֵּנִ֣ים אֲנַ֔חְנוּ לֹֽא־הָי֥וּ עֲבָדֶ֖יךָ מְרַגְּלִֽים׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֖אמֶר אֲלֵהֶ֑ם לֹ֕א כִּֽי־עֶרְוַ֥ת הָאָ֖רֶץ בָּאתֶ֥ם לִרְאֽוֹת׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּֽאמְר֗וּ שְׁנֵ֣ים עָשָׂר֩ עֲבָדֶ֨יךָ אַחִ֧ים ׀ אֲנַ֛חְנוּ בְּנֵ֥י אִישׁ־אֶחָ֖ד בְּאֶ֣רֶץ כְּנָ֑עַן וְהִנֵּ֨ה הַקָּטֹ֤ן אֶת־אָבִ֨ינוּ֙ הַיּ֔וֹם וְהָֽאֶחָ֖ד אֵינֶֽנּוּ׃"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֥אמֶר אֲלֵהֶ֖ם יוֹסֵ֑ף ה֗וּא אֲשֶׁ֨ר דִּבַּ֧רְתִּי אֲלֵכֶ֛ם לֵאמֹ֖ר מְרַגְּלִ֥ים אַתֶּֽם׃"
          },
          {
            "num": "טו",
            "txt": "בְּזֹ֖את תִּבָּחֵ֑נוּ חֵ֤י פַרְעֹה֙ אִם־תֵּֽצְא֣וּ מִזֶּ֔ה כִּ֧י אִם־בְּב֛וֹא אֲחִיכֶ֥ם הַקָּטֹ֖ן הֵֽנָּה׃"
          },
          {
            "num": "טז",
            "txt": "שִׁלְח֨וּ מִכֶּ֣ם אֶחָד֮ וְיִקַּ֣ח אֶת־אֲחִיכֶם֒ וְאַתֶּם֙ הֵאָ֣סְר֔וּ וְיִבָּֽחֲנוּ֙ דִּבְרֵיכֶ֔ם הַֽאֱמֶ֖ת אִתְּכֶ֑ם וְאִם־לֹ֕א חֵ֣י פַרְעֹ֔ה כִּ֥י מְרַגְּלִ֖ים אַתֶּֽם׃"
          },
          {
            "num": "יז",
            "txt": "וַיֶּֽאֱסֹ֥ף אֹתָ֛ם אֶל־מִשְׁמָ֖ר שְׁלֹ֥שֶׁת יָמִֽים׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֨אמֶר אֲלֵהֶ֤ם יוֹסֵף֙ בַּיּ֣וֹם הַשְּׁלִישִׁ֔י זֹ֥את עֲשׂ֖וּ וִֽחְי֑וּ אֶת־הָֽאֱלֹהִ֖ים אֲנִ֥י יָרֵֽא׃"
          },
          {
            "num": "יט",
            "txt": "אִם־כֵּנִ֣ים אַתֶּ֔ם אֲחִיכֶ֣ם אֶחָ֔ד יֵֽאָסֵ֖ר בְּבֵ֣ית מִשְׁמַרְכֶ֑ם וְאַתֶּם֙ לְכ֣וּ הָבִ֔יאוּ שֶׁ֖בֶר רַֽעֲב֥וֹן בָּֽתֵּיכֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "וְאֶת־אֲחִיכֶ֤ם הַקָּטֹן֙ תָּבִ֣יאוּ אֵלַ֔י וְיֵאָֽמְנ֥וּ דִבְרֵיכֶ֖ם וְלֹ֣א תָמ֑וּתוּ וַיַּֽעֲשׂוּ־כֵֽן׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּֽאמְר֞וּ אִ֣ישׁ אֶל־אָחִ֗יו אֲבָל֮ אֲשֵׁמִ֣ים ׀ אֲנַחְנוּ֮ עַל־אָחִינוּ֒ אֲשֶׁ֨ר רָאִ֜ינוּ צָרַ֥ת נַפְשׁ֛וֹ בְּהִתְחַֽנְנ֥וֹ אֵלֵ֖ינוּ וְלֹ֣א שָׁמָ֑עְנוּ עַל־כֵּן֙ בָּ֣אָה אֵלֵ֔ינוּ הַצָּרָ֖ה הַזֹּֽאת׃"
          },
          {
            "num": "כב",
            "txt": "וַיַּעַן֩ רְאוּבֵ֨ן אֹתָ֜ם לֵאמֹ֗ר הֲלוֹא֩ אָמַ֨רְתִּי אֲלֵיכֶ֧ם ׀ לֵאמֹ֛ר אַל־תֶּֽחֶטְא֥וּ בַיֶּ֖לֶד וְלֹ֣א שְׁמַעְתֶּ֑ם וְגַם־דָּמ֖וֹ הִנֵּ֥ה נִדְרָֽשׁ׃"
          },
          {
            "num": "כג",
            "txt": "וְהֵם֙ לֹ֣א יָֽדְע֔וּ כִּ֥י שֹׁמֵ֖עַ יוֹסֵ֑ף כִּ֥י הַמֵּלִ֖יץ בֵּֽינֹתָֽם׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּסֹּ֥ב מֵֽעֲלֵיהֶ֖ם וַיֵּ֑בְךְּ וַיָּ֤שָׁב אֲלֵהֶם֙ וַיְדַבֵּ֣ר אֲלֵהֶ֔ם וַיִּקַּ֤ח מֵֽאִתָּם֙ אֶת־שִׁמְע֔וֹן וַיֶּֽאֱסֹ֥ר אֹת֖וֹ לְעֵֽינֵיהֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַיְצַ֣ו יוֹסֵ֗ף וַיְמַלְא֣וּ אֶת־כְּלֵיהֶם֮ בָּר֒ וּלְהָשִׁ֤יב כַּסְפֵּיהֶם֙ אִ֣ישׁ אֶל־שַׂקּ֔וֹ וְלָתֵ֥ת לָהֶ֛ם צֵדָ֖ה לַדָּ֑רֶךְ וַיַּ֥עַשׂ לָהֶ֖ם כֵּֽן׃"
          },
          {
            "num": "כו",
            "txt": "וַיִּשְׂא֥וּ אֶת־שִׁבְרָ֖ם עַל־חֲמֹֽרֵיהֶ֑ם וַיֵּֽלְכ֖וּ מִשָּֽׁם׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּפְתַּ֨ח הָֽאֶחָ֜ד אֶת־שַׂקּ֗וֹ לָתֵ֥ת מִסְפּ֛וֹא לַֽחֲמֹר֖וֹ בַּמָּל֑וֹן וַיַּרְא֙ אֶת־כַּסְפּ֔וֹ וְהִנֵּה־ה֖וּא בְּפִ֥י אַמְתַּחְתּֽוֹ׃"
          },
          {
            "num": "כח",
            "txt": "וַיֹּ֤אמֶר אֶל־אֶחָיו֙ הוּשַׁ֣ב כַּסְפִּ֔י וְגַ֖ם הִנֵּ֣ה בְאַמְתַּחְתִּ֑י וַיֵּצֵ֣א לִבָּ֗ם וַיֶּֽחֶרְד֞וּ אִ֤ישׁ אֶל־אָחִיו֙ לֵאמֹ֔ר מַה־זֹּ֛את עָשָׂ֥ה אֱלֹהִ֖ים לָֽנוּ׃"
          },
          {
            "num": "כט",
            "txt": "וַיָּבֹ֛אוּ אֶל־יַֽעֲקֹ֥ב אֲבִיהֶ֖ם אַ֣רְצָה כְּנָ֑עַן וַיַּגִּ֣ידוּ ל֔וֹ אֵ֛ת כָּל־הַקֹּרֹ֥ת אֹתָ֖ם לֵאמֹֽר׃"
          },
          {
            "num": "ל",
            "txt": "דִּ֠בֶּר הָאִ֨ישׁ אֲדֹנֵ֥י הָאָ֛רֶץ אִתָּ֖נוּ קָשׁ֑וֹת וַיִּתֵּ֣ן אֹתָ֔נוּ כִּֽמְרַגְּלִ֖ים אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "לא",
            "txt": "וַנֹּ֥אמֶר אֵלָ֖יו כֵּנִ֣ים אֲנָ֑חְנוּ לֹ֥א הָיִ֖ינוּ מְרַגְּלִֽים׃"
          },
          {
            "num": "לב",
            "txt": "שְׁנֵים־עָשָׂ֥ר אֲנַ֛חְנוּ אַחִ֖ים בְּנֵ֣י אָבִ֑ינוּ הָֽאֶחָ֣ד אֵינֶ֔נּוּ וְהַקָּטֹ֥ן הַיּ֛וֹם אֶת־אָבִ֖ינוּ בְּאֶ֥רֶץ כְּנָֽעַן׃"
          },
          {
            "num": "לג",
            "txt": "וַיֹּ֣אמֶר אֵלֵ֗ינוּ הָאִישׁ֙ אֲדֹנֵ֣י הָאָ֔רֶץ בְּזֹ֣את אֵדַ֔ע כִּ֥י כֵנִ֖ים אַתֶּ֑ם אֲחִיכֶ֤ם הָֽאֶחָד֙ הַנִּ֣יחוּ אִתִּ֔י וְאֶת־רַֽעֲב֥וֹן בָּֽתֵּיכֶ֖ם קְח֥וּ וָלֵֽכוּ׃"
          },
          {
            "num": "לד",
            "txt": "וְ֠הָבִיאוּ אֶת־אֲחִיכֶ֣ם הַקָּטֹן֮ אֵלַי֒ וְאֵֽדְעָ֗ה כִּ֣י לֹ֤א מְרַגְּלִים֙ אַתֶּ֔ם כִּ֥י כֵנִ֖ים אַתֶּ֑ם אֶת־אֲחִיכֶם֙ אֶתֵּ֣ן לָכֶ֔ם וְאֶת־הָאָ֖רֶץ תִּסְחָֽרוּ׃"
          },
          {
            "num": "לה",
            "txt": "וַיְהִ֗י הֵ֚ם מְרִיקִ֣ים שַׂקֵּיהֶ֔ם וְהִנֵּה־אִ֥ישׁ צְרוֹר־כַּסְפּ֖וֹ בְּשַׂקּ֑וֹ וַיִּרְא֞וּ אֶת־צְרֹר֧וֹת כַּסְפֵּיהֶ֛ם הֵ֥מָּה וַֽאֲבִיהֶ֖ם וַיִּירָֽאוּ׃"
          },
          {
            "num": "לו",
            "txt": "וַיֹּ֤אמֶר אֲלֵהֶם֙ יַֽעֲקֹ֣ב אֲבִיהֶ֔ם אֹתִ֖י שִׁכַּלְתֶּ֑ם יוֹסֵ֤ף אֵינֶ֨נּוּ֙ וְשִׁמְע֣וֹן אֵינֶ֔נּוּ וְאֶת־בִּנְיָמִ֣ן תִּקָּ֔חוּ עָלַ֖י הָי֥וּ כֻלָּֽנָה׃"
          },
          {
            "num": "לז",
            "txt": "וַיֹּ֤אמֶר רְאוּבֵן֙ אֶל־אָבִ֣יו לֵאמֹ֔ר אֶת־שְׁנֵ֤י בָנַי֙ תָּמִ֔ית אִם־לֹ֥א אֲבִיאֶ֖נּוּ אֵלֶ֑יךָ תְּנָ֤ה אֹתוֹ֙ עַל־יָדִ֔י וַֽאֲנִ֖י אֲשִׁיבֶ֥נּוּ אֵלֶֽיךָ׃"
          },
          {
            "num": "לח",
            "txt": "וַיֹּ֕אמֶר לֹֽא־יֵרֵ֥ד בְּנִ֖י עִמָּכֶ֑ם כִּֽי־אָחִ֨יו מֵ֜ת וְה֧וּא לְבַדּ֣וֹ נִשְׁאָ֗ר וּקְרָאָ֤הוּ אָסוֹן֙ בַּדֶּ֨רֶךְ֙ אֲשֶׁ֣ר תֵּֽלְכוּ־בָ֔הּ וְהֽוֹרַדְתֶּ֧ם אֶת־שֵֽׂיבָתִ֛י בְּיָג֖וֹן שְׁאֽוֹלָה׃"
          }
        ]
      },
      {
        "num": "מג",
        "verses": [
          {
            "num": "א",
            "txt": "וְהָֽרָעָ֖ב כָּבֵ֥ד בָּאָֽרֶץ׃"
          },
          {
            "num": "ב",
            "txt": "וַיְהִ֗י כַּֽאֲשֶׁ֤ר כִּלּוּ֙ לֶֽאֱכֹ֣ל אֶת־הַשֶּׁ֔בֶר אֲשֶׁ֥ר הֵבִ֖יאוּ מִמִּצְרָ֑יִם וַיֹּ֤אמֶר אֲלֵיהֶם֙ אֲבִיהֶ֔ם שֻׁ֖בוּ שִׁבְרוּ־לָ֥נוּ מְעַט־אֹֽכֶל׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֧אמֶר אֵלָ֛יו יְהוּדָ֖ה לֵאמֹ֑ר הָעֵ֣ד הֵעִד֩ בָּ֨נוּ הָאִ֤ישׁ לֵאמֹר֙ לֹֽא־תִרְא֣וּ פָנַ֔י בִּלְתִּ֖י אֲחִיכֶ֥ם אִתְּכֶֽם׃"
          },
          {
            "num": "ד",
            "txt": "אִם־יֶשְׁךָ֛ מְשַׁלֵּ֥חַ אֶת־אָחִ֖ינוּ אִתָּ֑נוּ נֵֽרְדָ֕ה וְנִשְׁבְּרָ֥ה לְךָ֖ אֹֽכֶל׃"
          },
          {
            "num": "ה",
            "txt": "וְאִם־אֵֽינְךָ֥ מְשַׁלֵּ֖חַ לֹ֣א נֵרֵ֑ד כִּֽי־הָאִ֞ישׁ אָמַ֤ר אֵלֵ֨ינוּ֙ לֹֽא־תִרְא֣וּ פָנַ֔י בִּלְתִּ֖י אֲחִיכֶ֥ם אִתְּכֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֨אמֶר֙ יִשְׂרָאֵ֔ל לָמָ֥ה הֲרֵֽעֹתֶ֖ם לִ֑י לְהַגִּ֣יד לָאִ֔ישׁ הַע֥וֹד לָכֶ֖ם אָֽח׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּֽאמְר֡וּ שָׁא֣וֹל שָֽׁאַל־הָ֠אִישׁ לָ֣נוּ וּלְמֽוֹלַדְתֵּ֜נוּ לֵאמֹ֗ר הַע֨וֹד אֲבִיכֶ֥ם חַי֙ הֲיֵ֣שׁ לָכֶ֣ם אָ֔ח וַנַּ֨גֶּד־ל֔וֹ עַל־פִּ֖י הַדְּבָרִ֣ים הָאֵ֑לֶּה הֲיָד֣וֹעַ נֵדַ֔ע כִּ֣י יֹאמַ֔ר הוֹרִ֖ידוּ אֶת־אֲחִיכֶֽם׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֨אמֶר יְהוּדָ֜ה אֶל־יִשְׂרָאֵ֣ל אָבִ֗יו שִׁלְחָ֥ה הַנַּ֛עַר אִתִּ֖י וְנָק֣וּמָה וְנֵלֵ֑כָה וְנִֽחְיֶה֙ וְלֹ֣א נָמ֔וּת גַּם־אֲנַ֥חְנוּ גַם־אַתָּ֖ה גַּם־טַפֵּֽנוּ׃"
          },
          {
            "num": "ט",
            "txt": "אָֽנֹכִי֙ אֶֽעֶרְבֶ֔נּוּ מִיָּדִ֖י תְּבַקְשֶׁ֑נּוּ אִם־לֹ֨א הֲבִֽיאֹתִ֤יו אֵלֶ֨יךָ֙ וְהִצַּגְתִּ֣יו לְפָנֶ֔יךָ וְחָטָ֥אתִֽי לְךָ֖ כָּל־הַיָּמִֽים׃"
          },
          {
            "num": "י",
            "txt": "כִּ֖י לוּלֵ֣א הִתְמַהְמָ֑הְנוּ כִּֽי־עַתָּ֥ה שַׁ֖בְנוּ זֶ֥ה פַֽעֲמָֽיִם׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֨אמֶר אֲלֵהֶ֜ם יִשְׂרָאֵ֣ל אֲבִיהֶ֗ם אִם־כֵּ֣ן ׀ אֵפוֹא֮ זֹ֣את עֲשׂוּ֒ קְח֞וּ מִזִּמְרַ֤ת הָאָ֨רֶץ֙ בִּכְלֵיכֶ֔ם וְהוֹרִ֥ידוּ לָאִ֖ישׁ מִנְחָ֑ה מְעַ֤ט צֳרִי֙ וּמְעַ֣ט דְּבַ֔שׁ נְכֹ֣את וָלֹ֔ט בָּטְנִ֖ים וּשְׁקֵדִֽים׃"
          },
          {
            "num": "יב",
            "txt": "וְכֶ֥סֶף מִשְׁנֶ֖ה קְח֣וּ בְיֶדְכֶ֑ם וְאֶת־הַכֶּ֜סֶף הַמּוּשָׁ֨ב בְּפִ֤י אַמְתְּחֹֽתֵיכֶם֙ תָּשִׁ֣יבוּ בְיֶדְכֶ֔ם אוּלַ֥י מִשְׁגֶּ֖ה הֽוּא׃"
          },
          {
            "num": "יג",
            "txt": "וְאֶת־אֲחִיכֶ֖ם קָ֑חוּ וְק֖וּמוּ שׁ֥וּבוּ אֶל־הָאִֽישׁ׃"
          },
          {
            "num": "יד",
            "txt": "וְאֵ֣ל שַׁדַּ֗י יִתֵּ֨ן לָכֶ֤ם רַֽחֲמִים֙ לִפְנֵ֣י הָאִ֔ישׁ וְשִׁלַּ֥ח לָכֶ֛ם אֶת־אֲחִיכֶ֥ם אַחֵ֖ר וְאֶת־בִּנְיָמִ֑ין וַֽאֲנִ֕י כַּֽאֲשֶׁ֥ר שָׁכֹ֖לְתִּי שָׁכָֽלְתִּי׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּקְח֤וּ הָֽאֲנָשִׁים֙ אֶת־הַמִּנְחָ֣ה הַזֹּ֔את וּמִשְׁנֶה־כֶּ֛סֶף לָֽקְח֥וּ בְיָדָ֖ם וְאֶת־בִּנְיָמִ֑ן וַיָּקֻ֨מוּ֙ וַיֵּֽרְד֣וּ מִצְרַ֔יִם וַיַּֽעַמְד֖וּ לִפְנֵ֥י יוֹסֵֽף׃"
          },
          {
            "num": "טז",
            "txt": "וַיַּ֨רְא יוֹסֵ֣ף אִתָּם֮ אֶת־בִּנְיָמִין֒ וַיֹּ֨אמֶר֙ לַֽאֲשֶׁ֣ר עַל־בֵּית֔וֹ הָבֵ֥א אֶת־הָֽאֲנָשִׁ֖ים הַבָּ֑יְתָה וּטְבֹ֤חַ טֶ֨בַח֙ וְהָכֵ֔ן כִּ֥י אִתִּ֛י יֹֽאכְל֥וּ הָֽאֲנָשִׁ֖ים בַּֽצָּהֳרָֽיִם׃"
          },
          {
            "num": "יז",
            "txt": "וַיַּ֣עַשׂ הָאִ֔ישׁ כַּֽאֲשֶׁ֖ר אָמַ֣ר יוֹסֵ֑ף וַיָּבֵ֥א הָאִ֛ישׁ אֶת־הָֽאֲנָשִׁ֖ים בֵּ֥יתָה יוֹסֵֽף׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּֽירְא֣וּ הָֽאֲנָשִׁ֗ים כִּ֣י הֽוּבְאוּ֮ בֵּ֣ית יוֹסֵף֒ וַיֹּֽאמְר֗וּ עַל־דְּבַ֤ר הַכֶּ֨סֶף֙ הַשָּׁ֤ב בְּאַמְתְּחֹתֵ֨ינוּ֙ בַּתְּחִלָּ֔ה אֲנַ֖חְנוּ מֽוּבָאִ֑ים לְהִתְגֹּלֵ֤ל עָלֵ֨ינוּ֙ וּלְהִתְנַפֵּ֣ל עָלֵ֔ינוּ וְלָקַ֧חַת אֹתָ֛נוּ לַֽעֲבָדִ֖ים וְאֶת־חֲמֹרֵֽינוּ׃"
          },
          {
            "num": "יט",
            "txt": "וַֽיִּגְּשׁוּ֙ אֶל־הָאִ֔ישׁ אֲשֶׁ֖ר עַל־בֵּ֣ית יוֹסֵ֑ף וַיְדַבְּר֥וּ אֵלָ֖יו פֶּ֥תַח הַבָּֽיִת׃"
          },
          {
            "num": "כ",
            "txt": "וַיֹּֽאמְר֖וּ בִּ֣י אֲדֹנִ֑י יָרֹ֥ד יָרַ֛דְנוּ בַּתְּחִלָּ֖ה לִשְׁבָּר־אֹֽכֶל׃"
          },
          {
            "num": "כא",
            "txt": "וַיְהִ֞י כִּי־בָ֣אנוּ אֶל־הַמָּל֗וֹן וַֽנִּפְתְּחָה֙ אֶת־אַמְתְּחֹתֵ֔ינוּ וְהִנֵּ֤ה כֶֽסֶף־אִישׁ֙ בְּפִ֣י אַמְתַּחְתּ֔וֹ כַּסְפֵּ֖נוּ בְּמִשְׁקָל֑וֹ וַנָּ֥שֶׁב אֹת֖וֹ בְּיָדֵֽנוּ׃"
          },
          {
            "num": "כב",
            "txt": "וְכֶ֧סֶף אַחֵ֛ר הוֹרַ֥דְנוּ בְיָדֵ֖נוּ לִשְׁבָּר־אֹ֑כֶל לֹ֣א יָדַ֔עְנוּ מִי־שָׂ֥ם כַּסְפֵּ֖נוּ בְּאַמְתְּחֹתֵֽינוּ׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּאמֶר֩ שָׁל֨וֹם לָכֶ֜ם אַל־תִּירָ֗אוּ אֱלֹ֨הֵיכֶ֜ם וֵֽאלֹהֵ֤י אֲבִיכֶם֙ נָתַ֨ן לָכֶ֤ם מַטְמוֹן֙ בְּאַמְתְּחֹ֣תֵיכֶ֔ם כַּסְפְּכֶ֖ם בָּ֣א אֵלָ֑י וַיּוֹצֵ֥א אֲלֵהֶ֖ם אֶת־שִׁמְעֽוֹן׃"
          },
          {
            "num": "כד",
            "txt": "וַיָּבֵ֥א הָאִ֛ישׁ אֶת־הָֽאֲנָשִׁ֖ים בֵּ֣יתָה יוֹסֵ֑ף וַיִּתֶּן־מַ֨יִם֙ וַיִּרְחֲצ֣וּ רַגְלֵיהֶ֔ם וַיִּתֵּ֥ן מִסְפּ֖וֹא לַחֲמֹֽרֵיהֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַיָּכִ֨ינוּ֙ אֶת־הַמִּנְחָ֔ה עַד־בּ֥וֹא יוֹסֵ֖ף בַּֽצָּהֳרָ֑יִם כִּ֣י שָֽׁמְע֔וּ כִּי־שָׁ֖ם יֹ֥אכְלוּ לָֽחֶם׃"
          },
          {
            "num": "כו",
            "txt": "וַיָּבֹ֤א יוֹסֵף֙ הַבַּ֔יְתָה וַיָּבִ֥יאּוּ ל֛וֹ אֶת־הַמִּנְחָ֥ה אֲשֶׁר־בְּיָדָ֖ם הַבָּ֑יְתָה וַיִּשְׁתַּֽחֲווּ־ל֖וֹ אָֽרְצָה׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּשְׁאַ֤ל לָהֶם֙ לְשָׁל֔וֹם וַיֹּ֗אמֶר הֲשָׁל֛וֹם אֲבִיכֶ֥ם הַזָּקֵ֖ן אֲשֶׁ֣ר אֲמַרְתֶּ֑ם הַֽעוֹדֶ֖נּוּ חָֽי׃"
          },
          {
            "num": "כח",
            "txt": "וַיֹּֽאמְר֗וּ שָׁל֛וֹם לְעַבְדְּךָ֥ לְאָבִ֖ינוּ עוֹדֶ֣נּוּ חָ֑י וַֽיִּקְּד֖וּ וישתחו (וַיִּֽשְׁתַּחֲוֽוּ)׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּשָּׂ֣א עֵינָ֗יו וַיַּ֞רְא אֶת־בִּנְיָמִ֣ין אָחִיו֮ בֶּן־אִמּוֹ֒ וַיֹּ֗אמֶר הֲזֶה֙ אֲחִיכֶ֣ם הַקָּטֹ֔ן אֲשֶׁ֥ר אֲמַרְתֶּ֖ם אֵלָ֑י וַיֹּאמַ֕ר אֱלֹהִ֥ים יָחְנְךָ֖ בְּנִֽי׃"
          },
          {
            "num": "ל",
            "txt": "וַיְמַהֵ֣ר יוֹסֵ֗ף כִּֽי־נִכְמְר֤וּ רַֽחֲמָיו֙ אֶל־אָחִ֔יו וַיְבַקֵּ֖שׁ לִבְכּ֑וֹת וַיָּבֹ֥א הַחַ֖דְרָה וַיֵּ֥בְךְּ שָֽׁמָּה׃"
          },
          {
            "num": "לא",
            "txt": "וַיִּרְחַ֥ץ פָּנָ֖יו וַיֵּצֵ֑א וַיִּ֨תְאַפַּ֔ק וַיֹּ֖אמֶר שִׂ֥ימוּ לָֽחֶם׃"
          },
          {
            "num": "לב",
            "txt": "וַיָּשִׂ֥ימוּ ל֛וֹ לְבַדּ֖וֹ וְלָהֶ֣ם לְבַדָּ֑ם וְלַמִּצְרִ֞ים הָאֹֽכְלִ֤ים אִתּוֹ֙ לְבַדָּ֔ם כִּי֩ לֹ֨א יֽוּכְל֜וּן הַמִּצְרִ֗ים לֶֽאֱכֹ֤ל אֶת־הָֽעִבְרִים֙ לֶ֔חֶם כִּֽי־תוֹעֵבָ֥ה הִ֖וא לְמִצְרָֽיִם׃"
          },
          {
            "num": "לג",
            "txt": "וַיֵּֽשְׁב֣וּ לְפָנָ֔יו הַבְּכֹר֙ כִּבְכֹ֣רָת֔וֹ וְהַצָּעִ֖יר כִּצְעִֽרָת֑וֹ וַיִּתְמְה֥וּ הָֽאֲנָשִׁ֖ים אִ֥ישׁ אֶל־רֵעֵֽהוּ׃"
          },
          {
            "num": "לד",
            "txt": "וַיִּשָּׂ֨א מַשְׂאֹ֜ת מֵאֵ֣ת פָּנָיו֮ אֲלֵהֶם֒ וַתֵּ֜רֶב מַשְׂאַ֧ת בִּנְיָמִ֛ן מִמַּשְׂאֹ֥ת כֻּלָּ֖ם חָמֵ֣שׁ יָד֑וֹת וַיִּשְׁתּ֥וּ וַֽיִּשְׁכְּר֖וּ עִמּֽוֹ׃"
          }
        ]
      },
      {
        "num": "מד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְצַ֞ו אֶת־אֲשֶׁ֣ר עַל־בֵּיתוֹ֮ לֵאמֹר֒ מַלֵּ֞א אֶת־אַמְתְּחֹ֤ת הָֽאֲנָשִׁים֙ אֹ֔כֶל כַּֽאֲשֶׁ֥ר יֽוּכְל֖וּן שְׂאֵ֑ת וְשִׂ֥ים כֶּֽסֶף־אִ֖ישׁ בְּפִ֥י אַמְתַּחְתּֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וְאֶת־גְּבִיעִ֞י גְּבִ֣יעַ הַכֶּ֗סֶף תָּשִׂים֙ בְּפִי֙ אַמְתַּ֣חַת הַקָּטֹ֔ן וְאֵ֖ת כֶּ֣סֶף שִׁבְר֑וֹ וַיַּ֕עַשׂ כִּדְבַ֥ר יוֹסֵ֖ף אֲשֶׁ֥ר דִּבֵּֽר׃"
          },
          {
            "num": "ג",
            "txt": "הַבֹּ֖קֶר א֑וֹר וְהָֽאֲנָשִׁ֣ים שֻׁלְּח֔וּ הֵ֖מָּה וַחֲמֹֽרֵיהֶֽם׃"
          },
          {
            "num": "ד",
            "txt": "הֵ֠ם יָֽצְא֣וּ אֶת־הָעִיר֮ לֹ֣א הִרְחִיקוּ֒ וְיוֹסֵ֤ף אָמַר֙ לַֽאֲשֶׁ֣ר עַל־בֵּית֔וֹ ק֥וּם רְדֹ֖ף אַֽחֲרֵ֣י הָֽאֲנָשִׁ֑ים וְהִשַּׂגְתָּם֙ וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם לָ֛מָּה שִׁלַּמְתֶּ֥ם רָעָ֖ה תַּ֥חַת טוֹבָֽה׃"
          },
          {
            "num": "ה",
            "txt": "הֲל֣וֹא זֶ֗ה אֲשֶׁ֨ר יִשְׁתֶּ֤ה אֲדֹנִי֙ בּ֔וֹ וְה֕וּא נַחֵ֥שׁ יְנַחֵ֖שׁ בּ֑וֹ הֲרֵֽעֹתֶ֖ם אֲשֶׁ֥ר עֲשִׂיתֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַֽיַּשִּׂגֵ֑ם וַיְדַבֵּ֣ר אֲלֵהֶ֔ם אֶת־הַדְּבָרִ֖ים הָאֵֽלֶּה׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּֽאמְר֣וּ אֵלָ֔יו לָ֚מָּה יְדַבֵּ֣ר אֲדֹנִ֔י כַּדְּבָרִ֖ים הָאֵ֑לֶּה חָלִ֨ילָה֙ לַֽעֲבָדֶ֔יךָ מֵֽעֲשׂ֖וֹת כַּדָּבָ֥ר הַזֶּֽה׃"
          },
          {
            "num": "ח",
            "txt": "הֵ֣ן כֶּ֗סֶף אֲשֶׁ֤ר מָצָ֨אנוּ֙ בְּפִ֣י אַמְתְּחֹתֵ֔ינוּ הֱשִׁיבֹ֥נוּ אֵלֶ֖יךָ מֵאֶ֣רֶץ כְּנָ֑עַן וְאֵ֗יךְ נִגְנֹב֙ מִבֵּ֣ית אֲדֹנֶ֔יךָ כֶּ֖סֶף א֥וֹ זָהָֽב׃"
          },
          {
            "num": "ט",
            "txt": "אֲשֶׁ֨ר יִמָּצֵ֥א אִתּ֛וֹ מֵֽעֲבָדֶ֖יךָ וָמֵ֑ת וְגַם־אֲנַ֕חְנוּ נִֽהְיֶ֥ה לַֽאדֹנִ֖י לַֽעֲבָדִֽים׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֕אמֶר גַּם־עַתָּ֥ה כְדִבְרֵיכֶ֖ם כֶּן־ה֑וּא אֲשֶׁ֨ר יִמָּצֵ֤א אִתּוֹ֙ יִֽהְיֶה־לִּ֣י עָ֔בֶד וְאַתֶּ֖ם תִּֽהְי֥וּ נְקִיִּֽם׃"
          },
          {
            "num": "יא",
            "txt": "וַֽיְמַהֲר֗וּ וַיּוֹרִ֛דוּ אִ֥ישׁ אֶת־אַמְתַּחְתּ֖וֹ אָ֑רְצָה וַֽיִּפְתְּח֖וּ אִ֥ישׁ אַמְתַּחְתּֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וַיְחַפֵּ֕שׂ בַּגָּד֣וֹל הֵחֵ֔ל וּבַקָּטֹ֖ן כִּלָּ֑ה וַיִּמָּצֵא֙ הַגָּבִ֔יעַ בְּאַמְתַּ֖חַת בִּנְיָמִֽן׃"
          },
          {
            "num": "יג",
            "txt": "וַֽיִּקְרְע֖וּ שִׂמְלֹתָ֑ם וַֽיַּעֲמֹס֙ אִ֣ישׁ עַל־חֲמֹר֔וֹ וַיָּשֻׁ֖בוּ הָעִֽירָה׃"
          },
          {
            "num": "יד",
            "txt": "וַיָּבֹ֨א יְהוּדָ֤ה וְאֶחָיו֙ בֵּ֣יתָה יוֹסֵ֔ף וְה֖וּא עוֹדֶ֣נּוּ שָׁ֑ם וַיִּפְּל֥וּ לְפָנָ֖יו אָֽרְצָה׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֤אמֶר לָהֶם֙ יוֹסֵ֔ף מָֽה־הַמַּעֲשֶׂ֥ה הַזֶּ֖ה אֲשֶׁ֣ר עֲשִׂיתֶ֑ם הֲל֣וֹא יְדַעְתֶּ֔ם כִּֽי־נַחֵ֧שׁ יְנַחֵ֛שׁ אִ֖ישׁ אֲשֶׁ֥ר כָּמֹֽנִי׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֣אמֶר יְהוּדָ֗ה מַה־נֹּאמַר֙ לַֽאדֹנִ֔י מַה־נְּדַבֵּ֖ר וּמַה־נִּצְטַדָּ֑ק הָֽאֱלֹהִ֗ים מָצָא֙ אֶת־עֲוֺ֣ן עֲבָדֶ֔יךָ הִנֶּ֤נּוּ עֲבָדִים֙ לַֽאדֹנִ֔י גַּם־אֲנַ֕חְנוּ גַּ֛ם אֲשֶׁר־נִמְצָ֥א הַגָּבִ֖יעַ בְּיָדֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֕אמֶר חָלִ֣ילָה לִּ֔י מֵֽעֲשׂ֖וֹת זֹ֑את הָאִ֡ישׁ אֲשֶׁר֩ נִמְצָ֨א הַגָּבִ֜יעַ בְּיָד֗וֹ ה֚וּא יִֽהְיֶה־לִּ֣י עָ֔בֶד וְאַתֶּ֕ם עֲל֥וּ לְשָׁל֖וֹם אֶל־אֲבִיכֶֽם׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "וַיִּגַּ֨שׁ אֵלָ֜יו יְהוּדָ֗ה וַיֹּאמֶר֮ בִּ֣י אֲדֹנִי֒ יְדַבֶּר־נָ֨א עַבְדְּךָ֤ דָבָר֙ בְּאָזְנֵ֣י אֲדֹנִ֔י וְאַל־יִ֥חַר אַפְּךָ֖ בְּעַבְדֶּ֑ךָ כִּ֥י כָמ֖וֹךָ כְּפַרְעֹֽה׃"
          },
          {
            "num": "יט",
            "txt": "אֲדֹנִ֣י שָׁאַ֔ל אֶת־עֲבָדָ֖יו לֵאמֹ֑ר הֲיֵשׁ־לָכֶ֥ם אָ֖ב אוֹ־אָֽח׃"
          },
          {
            "num": "כ",
            "txt": "וַנֹּ֨אמֶר֙ אֶל־אֲדֹנִ֔י יֶשׁ־לָ֨נוּ֙ אָ֣ב זָקֵ֔ן וְיֶ֥לֶד זְקֻנִ֖ים קָטָ֑ן וְאָחִ֣יו מֵ֔ת וַיִּוָּתֵ֨ר ה֧וּא לְבַדּ֛וֹ לְאִמּ֖וֹ וְאָבִ֥יו אֲהֵבֽוֹ׃"
          },
          {
            "num": "כא",
            "txt": "וַתֹּ֨אמֶר֙ אֶל־עֲבָדֶ֔יךָ הֽוֹרִדֻ֖הוּ אֵלָ֑י וְאָשִׂ֥ימָה עֵינִ֖י עָלָֽיו׃"
          },
          {
            "num": "כב",
            "txt": "וַנֹּ֨אמֶר֙ אֶל־אֲדֹנִ֔י לֹֽא־יוּכַ֥ל הַנַּ֖עַר לַֽעֲזֹ֣ב אֶת־אָבִ֑יו וְעָזַ֥ב אֶת־אָבִ֖יו וָמֵֽת׃"
          },
          {
            "num": "כג",
            "txt": "וַתֹּ֨אמֶר֙ אֶל־עֲבָדֶ֔יךָ אִם־לֹ֥א יֵרֵ֛ד אֲחִיכֶ֥ם הַקָּטֹ֖ן אִתְּכֶ֑ם לֹ֥א תֹֽסִפ֖וּן לִרְא֥וֹת פָּנָֽי׃"
          },
          {
            "num": "כד",
            "txt": "וַֽיְהִי֙ כִּ֣י עָלִ֔ינוּ אֶֽל־עַבְדְּךָ֖ אָבִ֑י וַנַּ֨גֶּד־ל֔וֹ אֵ֖ת דִּבְרֵ֥י אֲדֹנִֽי׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֖אמֶר אָבִ֑ינוּ שֻׁ֖בוּ שִׁבְרוּ־לָ֥נוּ מְעַט־אֹֽכֶל׃"
          },
          {
            "num": "כו",
            "txt": "וַנֹּ֕אמֶר לֹ֥א נוּכַ֖ל לָרֶ֑דֶת אִם־יֵשׁ֩ אָחִ֨ינוּ הַקָּטֹ֤ן אִתָּ֨נוּ֙ וְיָרַ֔דְנוּ כִּי־לֹ֣א נוּכַ֗ל לִרְאוֹת֙ פְּנֵ֣י הָאִ֔ישׁ וְאָחִ֥ינוּ הַקָּטֹ֖ן אֵינֶ֥נּוּ אִתָּֽנוּ׃"
          },
          {
            "num": "כז",
            "txt": "וַיֹּ֛אמֶר עַבְדְּךָ֥ אָבִ֖י אֵלֵ֑ינוּ אַתֶּ֣ם יְדַעְתֶּ֔ם כִּ֥י שְׁנַ֖יִם יָֽלְדָה־לִּ֥י אִשְׁתִּֽי׃"
          },
          {
            "num": "כח",
            "txt": "וַיֵּצֵ֤א הָֽאֶחָד֙ מֵֽאִתִּ֔י וָֽאֹמַ֕ר אַ֖ךְ טָרֹ֣ף טֹרָ֑ף וְלֹ֥א רְאִיתִ֖יו עַד־הֵֽנָּה׃"
          },
          {
            "num": "כט",
            "txt": "וּלְקַחְתֶּ֧ם גַּם־אֶת־זֶ֛ה מֵעִ֥ם פָּנַ֖י וְקָרָ֣הוּ אָס֑וֹן וְהֽוֹרַדְתֶּ֧ם אֶת־שֵֽׂיבָתִ֛י בְּרָעָ֖ה שְׁאֹֽלָה׃"
          },
          {
            "num": "ל",
            "txt": "וְעַתָּ֗ה כְּבֹאִי֙ אֶל־עַבְדְּךָ֣ אָבִ֔י וְהַנַּ֖עַר אֵינֶ֣נּוּ אִתָּ֑נוּ וְנַפְשׁ֖וֹ קְשׁוּרָ֥ה בְנַפְשֽׁוֹ׃"
          },
          {
            "num": "לא",
            "txt": "וְהָיָ֗ה כִּרְאוֹת֛וֹ כִּי־אֵ֥ין הַנַּ֖עַר וָמֵ֑ת וְהוֹרִ֨ידוּ עֲבָדֶ֜יךָ אֶת־שֵׂיבַ֨ת עַבְדְּךָ֥ אָבִ֛ינוּ בְּיָג֖וֹן שְׁאֹֽלָה׃"
          },
          {
            "num": "לב",
            "txt": "כִּ֤י עַבְדְּךָ֙ עָרַ֣ב אֶת־הַנַּ֔עַר מֵעִ֥ם אָבִ֖י לֵאמֹ֑ר אִם־לֹ֤א אֲבִיאֶ֨נּוּ֙ אֵלֶ֔יךָ וְחָטָ֥אתִי לְאָבִ֖י כָּל־הַיָּמִֽים׃"
          },
          {
            "num": "לג",
            "txt": "וְעַתָּ֗ה יֵֽשֶׁב־נָ֤א עַבְדְּךָ֙ תַּ֣חַת הַנַּ֔עַר עֶ֖בֶד לַֽאדֹנִ֑י וְהַנַּ֖עַר יַ֥עַל עִם־אֶחָֽיו׃"
          },
          {
            "num": "לד",
            "txt": "כִּי־אֵיךְ֙ אֶֽעֱלֶ֣ה אֶל־אָבִ֔י וְהַנַּ֖עַר אֵינֶ֣נּוּ אִתִּ֑י פֶּ֚ן אֶרְאֶ֣ה בָרָ֔ע אֲשֶׁ֥ר יִמְצָ֖א אֶת־אָבִֽי׃"
          }
        ]
      },
      {
        "num": "מה",
        "verses": [
          {
            "num": "א",
            "txt": "וְלֹֽא־יָכֹ֨ל יוֹסֵ֜ף לְהִתְאַפֵּ֗ק לְכֹ֤ל הַנִּצָּבִים֙ עָלָ֔יו וַיִּקְרָ֕א הוֹצִ֥יאוּ כָל־אִ֖ישׁ מֵֽעָלָ֑י וְלֹא־עָ֤מַד אִישׁ֙ אִתּ֔וֹ בְּהִתְוַדַּ֥ע יוֹסֵ֖ף אֶל־אֶחָֽיו׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּתֵּ֥ן אֶת־קֹל֖וֹ בִּבְכִ֑י וַיִּשְׁמְע֣וּ מִצְרַ֔יִם וַיִּשְׁמַ֖ע בֵּ֥ית פַּרְעֹֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֨אמֶר יוֹסֵ֤ף אֶל־אֶחָיו֙ אֲנִ֣י יוֹסֵ֔ף הַע֥וֹד אָבִ֖י חָ֑י וְלֹֽא־יָכְל֤וּ אֶחָיו֙ לַֽעֲנ֣וֹת אֹת֔וֹ כִּ֥י נִבְהֲל֖וּ מִפָּנָֽיו׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֨אמֶר יוֹסֵ֧ף אֶל־אֶחָ֛יו גְּשׁוּ־נָ֥א אֵלַ֖י וַיִּגָּ֑שׁוּ וַיֹּ֗אמֶר אֲנִי֙ יוֹסֵ֣ף אֲחִיכֶ֔ם אֲשֶׁר־מְכַרְתֶּ֥ם אֹתִ֖י מִצְרָֽיְמָה׃"
          },
          {
            "num": "ה",
            "txt": "וְעַתָּ֣ה ׀ אַל־תֵּעָ֣צְב֗וּ וְאַל־יִ֨חַר֙ בְּעֵ֣ינֵיכֶ֔ם כִּֽי־מְכַרְתֶּ֥ם אֹתִ֖י הֵ֑נָּה כִּ֣י לְמִֽחְיָ֔ה שְׁלָחַ֥נִי אֱלֹהִ֖ים לִפְנֵיכֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "כִּי־זֶ֛ה שְׁנָתַ֥יִם הָֽרָעָ֖ב בְּקֶ֣רֶב הָאָ֑רֶץ וְעוֹד֙ חָמֵ֣שׁ שָׁנִ֔ים אֲשֶׁ֥ר אֵין־חָרִ֖ישׁ וְקָצִֽיר׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּשְׁלָחֵ֤נִי אֱלֹהִים֙ לִפְנֵיכֶ֔ם לָשׂ֥וּם לָכֶ֛ם שְׁאֵרִ֖ית בָּאָ֑רֶץ וּלְהַֽחֲי֣וֹת לָכֶ֔ם לִפְלֵיטָ֖ה גְּדֹלָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְעַתָּ֗ה לֹֽא־אַתֶּ֞ם שְׁלַחְתֶּ֤ם אֹתִי֙ הֵ֔נָּה כִּ֖י הָֽאֱלֹהִ֑ים וַיְשִׂימֵ֨נִֽי לְאָ֜ב לְפַרְעֹ֗ה וּלְאָדוֹן֙ לְכָל־בֵּית֔וֹ וּמֹשֵׁ֖ל בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ט",
            "txt": "מַֽהֲרוּ֮ וַֽעֲל֣וּ אֶל־אָבִי֒ וַֽאֲמַרְתֶּ֣ם אֵלָ֗יו כֹּ֤ה אָמַר֙ בִּנְךָ֣ יוֹסֵ֔ף שָׂמַ֧נִי אֱלֹהִ֛ים לְאָד֖וֹן לְכָל־מִצְרָ֑יִם רְדָ֥ה אֵלַ֖י אַֽל־תַּעֲמֹֽד׃"
          },
          {
            "num": "י",
            "txt": "וְיָֽשַׁבְתָּ֣ בְאֶֽרֶץ־גֹּ֗שֶׁן וְהָיִ֤יתָ קָרוֹב֙ אֵלַ֔י אַתָּ֕ה וּבָנֶ֖יךָ וּבְנֵ֣י בָנֶ֑יךָ וְצֹֽאנְךָ֥ וּבְקָֽרְךָ֖ וְכָל־אֲשֶׁר־לָֽךְ׃"
          },
          {
            "num": "יא",
            "txt": "וְכִלְכַּלְתִּ֤י אֹֽתְךָ֙ שָׁ֔ם כִּי־ע֛וֹד חָמֵ֥שׁ שָׁנִ֖ים רָעָ֑ב פֶּן־תִּוָּרֵ֛שׁ אַתָּ֥ה וּבֵֽיתְךָ֖ וְכָל־אֲשֶׁר־לָֽךְ׃"
          },
          {
            "num": "יב",
            "txt": "וְהִנֵּ֤ה עֵֽינֵיכֶם֙ רֹא֔וֹת וְעֵינֵ֖י אָחִ֣י בִנְיָמִ֑ין כִּי־פִ֖י הַֽמְדַבֵּ֥ר אֲלֵיכֶֽם׃"
          },
          {
            "num": "יג",
            "txt": "וְהִגַּדְתֶּ֣ם לְאָבִ֗י אֶת־כָּל־כְּבוֹדִי֙ בְּמִצְרַ֔יִם וְאֵ֖ת כָּל־אֲשֶׁ֣ר רְאִיתֶ֑ם וּמִֽהַרְתֶּ֛ם וְהֽוֹרַדְתֶּ֥ם אֶת־אָבִ֖י הֵֽנָּה׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּפֹּ֛ל עַל־צַוְּארֵ֥י בִנְיָֽמִן־אָחִ֖יו וַיֵּ֑בְךְּ וּבִ֨נְיָמִ֔ן בָּכָ֖ה עַל־צַוָּארָֽיו׃"
          },
          {
            "num": "טו",
            "txt": "וַיְנַשֵּׁ֥ק לְכָל־אֶחָ֖יו וַיֵּ֣בְךְּ עֲלֵהֶ֑ם וְאַ֣חֲרֵי כֵ֔ן דִּבְּר֥וּ אֶחָ֖יו אִתּֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "וְהַקֹּ֣ל נִשְׁמַ֗ע בֵּ֤ית פַּרְעֹה֙ לֵאמֹ֔ר בָּ֖אוּ אֲחֵ֣י יוֹסֵ֑ף וַיִּיטַב֙ בְּעֵינֵ֣י פַרְעֹ֔ה וּבְעֵינֵ֖י עֲבָדָֽיו׃"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֤אמֶר פַּרְעֹה֙ אֶל־יוֹסֵ֔ף אֱמֹ֥ר אֶל־אַחֶ֖יךָ זֹ֣את עֲשׂ֑וּ טַֽעֲנוּ֙ אֶת־בְּעִ֣ירְכֶ֔ם וּלְכוּ־בֹ֖אוּ אַ֥רְצָה כְּנָֽעַן׃"
          },
          {
            "num": "יח",
            "txt": "וּקְח֧וּ אֶת־אֲבִיכֶ֛ם וְאֶת־בָּֽתֵּיכֶ֖ם וּבֹ֣אוּ אֵלָ֑י וְאֶתְּנָ֣ה לָכֶ֗ם אֶת־טוּב֙ אֶ֣רֶץ מִצְרַ֔יִם וְאִכְל֖וּ אֶת־חֵ֥לֶב הָאָֽרֶץ׃"
          },
          {
            "num": "יט",
            "txt": "וְאַתָּ֥ה צֻוֵּ֖יתָה זֹ֣את עֲשׂ֑וּ קְחוּ־לָכֶם֩ מֵאֶ֨רֶץ מִצְרַ֜יִם עֲגָל֗וֹת לְטַפְּכֶם֙ וְלִנְשֵׁיכֶ֔ם וּנְשָׂאתֶ֥ם אֶת־אֲבִיכֶ֖ם וּבָאתֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "וְעֵ֣ינְכֶ֔ם אַל־תָּחֹ֖ס עַל־כְּלֵיכֶ֑ם כִּי־ט֛וּב כָּל־אֶ֥רֶץ מִצְרַ֖יִם לָכֶ֥ם הֽוּא׃"
          },
          {
            "num": "כא",
            "txt": "וַיַּֽעֲשׂוּ־כֵן֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וַיִּתֵּ֨ן לָהֶ֥ם יוֹסֵ֛ף עֲגָל֖וֹת עַל־פִּ֣י פַרְעֹ֑ה וַיִּתֵּ֥ן לָהֶ֛ם צֵדָ֖ה לַדָּֽרֶךְ׃"
          },
          {
            "num": "כב",
            "txt": "לְכֻלָּ֥ם נָתַ֛ן לָאִ֖ישׁ חֲלִפ֣וֹת שְׂמָלֹ֑ת וּלְבִנְיָמִ֤ן נָתַן֙ שְׁלֹ֣שׁ מֵא֣וֹת כֶּ֔סֶף וְחָמֵ֖שׁ חֲלִפֹ֥ת שְׂמָלֹֽת׃"
          },
          {
            "num": "כג",
            "txt": "וּלְאָבִ֞יו שָׁלַ֤ח כְּזֹאת֙ עֲשָׂרָ֣ה חֲמֹרִ֔ים נֹֽשְׂאִ֖ים מִטּ֣וּב מִצְרָ֑יִם וְעֶ֣שֶׂר אֲתֹנֹ֡ת נֹֽ֠שְׂאֹת בָּ֣ר וָלֶ֧חֶם וּמָז֛וֹן לְאָבִ֖יו לַדָּֽרֶךְ׃"
          },
          {
            "num": "כד",
            "txt": "וַיְשַׁלַּ֥ח אֶת־אֶחָ֖יו וַיֵּלֵ֑כוּ וַיֹּ֣אמֶר אֲלֵהֶ֔ם אַֽל־תִּרְגְּז֖וּ בַּדָּֽרֶךְ׃"
          },
          {
            "num": "כה",
            "txt": "וַֽיַּעֲל֖וּ מִמִּצְרָ֑יִם וַיָּבֹ֨אוּ֙ אֶ֣רֶץ כְּנַ֔עַן אֶֽל־יַעֲקֹ֖ב אֲבִיהֶֽם׃"
          },
          {
            "num": "כו",
            "txt": "וַיַּגִּ֨דוּ ל֜וֹ לֵאמֹ֗ר ע֚וֹד יוֹסֵ֣ף חַ֔י וְכִֽי־ה֥וּא מֹשֵׁ֖ל בְּכָל־אֶ֣רֶץ מִצְרָ֑יִם וַיָּ֣פָג לִבּ֔וֹ כִּ֥י לֹֽא־הֶאֱמִ֖ין לָהֶֽם׃"
          },
          {
            "num": "כז",
            "txt": "וַיְדַבְּר֣וּ אֵלָ֗יו אֵ֣ת כָּל־דִּבְרֵ֤י יוֹסֵף֙ אֲשֶׁ֣ר דִּבֶּ֣ר אֲלֵהֶ֔ם וַיַּרְא֙ אֶת־הָ֣עֲגָל֔וֹת אֲשֶׁר־שָׁלַ֥ח יוֹסֵ֖ף לָשֵׂ֣את אֹת֑וֹ וַתְּחִ֕י ר֖וּחַ יַֽעֲקֹ֥ב אֲבִיהֶֽם׃"
          },
          {
            "num": "כח",
            "txt": "וַיֹּ֨אמֶר֙ יִשְׂרָאֵ֔ל רַ֛ב עוֹד־יוֹסֵ֥ף בְּנִ֖י חָ֑י אֵֽלְכָ֥ה וְאֶרְאֶ֖נּוּ בְּטֶ֥רֶם אָמֽוּת׃"
          }
        ]
      },
      {
        "num": "מו",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּסַּ֤ע יִשְׂרָאֵל֙ וְכָל־אֲשֶׁר־ל֔וֹ וַיָּבֹ֖א בְּאֵ֣רָה שָּׁ֑בַע וַיִּזְבַּ֣ח זְבָחִ֔ים לֵֽאלֹהֵ֖י אָבִ֥יו יִצְחָֽק׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֨אמֶר אֱלֹהִ֤ים ׀ לְיִשְׂרָאֵל֙ בְּמַרְאֹ֣ת הַלַּ֔יְלָה וַיֹּ֖אמֶר יַֽעֲקֹ֣ב ׀ יַֽעֲקֹ֑ב וַיֹּ֖אמֶר הִנֵּֽנִי׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֕אמֶר אָֽנֹכִ֥י הָאֵ֖ל אֱלֹהֵ֣י אָבִ֑יךָ אַל־תִּירָא֙ מֵֽרְדָ֣ה מִצְרַ֔יְמָה כִּֽי־לְג֥וֹי גָּד֖וֹל אֲשִֽׂימְךָ֥ שָֽׁם׃"
          },
          {
            "num": "ד",
            "txt": "אָֽנֹכִ֗י אֵרֵ֤ד עִמְּךָ֙ מִצְרַ֔יְמָה וְאָֽנֹכִ֖י אַֽעַלְךָ֣ גַם־עָלֹ֑ה וְיוֹסֵ֕ף יָשִׁ֥ית יָד֖וֹ עַל־עֵינֶֽיךָ׃"
          },
          {
            "num": "ה",
            "txt": "וַיָּ֥קָם יַֽעֲקֹ֖ב מִבְּאֵ֣ר שָׁ֑בַע וַיִּשְׂא֨וּ בְנֵֽי־יִשְׂרָאֵ֜ל אֶת־יַֽעֲקֹ֣ב אֲבִיהֶ֗ם וְאֶת־טַפָּם֙ וְאֶת־נְשֵׁיהֶ֔ם בָּֽעֲגָל֕וֹת אֲשֶׁר־שָׁלַ֥ח פַּרְעֹ֖ה לָשֵׂ֥את אֹתֽוֹ׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּקְח֣וּ אֶת־מִקְנֵיהֶ֗ם וְאֶת־רְכוּשָׁם֙ אֲשֶׁ֤ר רָֽכְשׁוּ֙ בְּאֶ֣רֶץ כְּנַ֔עַן וַיָּבֹ֖אוּ מִצְרָ֑יְמָה יַֽעֲקֹ֖ב וְכָל־זַרְע֥וֹ אִתּֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "בָּנָ֞יו וּבְנֵ֤י בָנָיו֙ אִתּ֔וֹ בְּנֹתָ֛יו וּבְנ֥וֹת בָּנָ֖יו וְכָל־זַרְע֑וֹ הֵבִ֥יא אִתּ֖וֹ מִצְרָֽיְמָה׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "וְאֵ֨לֶּה שְׁמ֧וֹת בְּנֵֽי־יִשְׂרָאֵ֛ל הַבָּאִ֥ים מִצְרַ֖יְמָה יַֽעֲקֹ֣ב וּבָנָ֑יו בְּכֹ֥ר יַֽעֲקֹ֖ב רְאוּבֵֽן׃"
          },
          {
            "num": "ט",
            "txt": "וּבְנֵ֖י רְאוּבֵ֑ן חֲנ֥וֹךְ וּפַלּ֖וּא וְחֶצְרֹ֥ן וְכַרְמִֽי׃"
          },
          {
            "num": "י",
            "txt": "וּבְנֵ֣י שִׁמְע֗וֹן יְמוּאֵ֧ל וְיָמִ֛ין וְאֹ֖הַד וְיָכִ֣ין וְצֹ֑חַר וְשָׁא֖וּל בֶּן־הַֽכְּנַעֲנִֽית׃"
          },
          {
            "num": "יא",
            "txt": "וּבְנֵ֖י לֵוִ֑י גֵּֽרְשׁ֕וֹן קְהָ֖ת וּמְרָרִֽי׃"
          },
          {
            "num": "יב",
            "txt": "וּבְנֵ֣י יְהוּדָ֗ה עֵ֧ר וְאוֹנָ֛ן וְשֵׁלָ֖ה וָפֶ֣רֶץ וָזָ֑רַח וַיָּ֨מָת עֵ֤ר וְאוֹנָן֙ בְּאֶ֣רֶץ כְּנַ֔עַן וַיִּֽהְי֥וּ בְנֵי־פֶ֖רֶץ חֶצְרֹ֥ן וְחָמֽוּל׃"
          },
          {
            "num": "יג",
            "txt": "וּבְנֵ֖י יִשָּׂשכָ֑ר תּוֹלָ֥ע וּפֻוָ֖ה וְי֥וֹב וְשִׁמְרֹֽן׃"
          },
          {
            "num": "יד",
            "txt": "וּבְנֵ֖י זְבֻל֑וּן סֶ֥רֶד וְאֵל֖וֹן וְיַחְלְאֵֽל׃"
          },
          {
            "num": "טו",
            "txt": "אֵ֣לֶּה ׀ בְּנֵ֣י לֵאָ֗ה אֲשֶׁ֨ר יָֽלְדָ֤ה לְיַֽעֲקֹב֙ בְּפַדַּ֣ן אֲרָ֔ם וְאֵ֖ת דִּינָ֣ה בִתּ֑וֹ כָּל־נֶ֧פֶשׁ בָּנָ֛יו וּבְנוֹתָ֖יו שְׁלֹשִׁ֥ים וְשָׁלֹֽשׁ׃"
          },
          {
            "num": "טז",
            "txt": "וּבְנֵ֣י גָ֔ד צִפְי֥וֹן וְחַגִּ֖י שׁוּנִ֣י וְאֶצְבֹּ֑ן עֵרִ֥י וַֽאֲרוֹדִ֖י וְאַרְאֵלִֽי׃"
          },
          {
            "num": "יז",
            "txt": "וּבְנֵ֣י אָשֵׁ֗ר יִמְנָ֧ה וְיִשְׁוָ֛ה וְיִשְׁוִ֥י וּבְרִיעָ֖ה וְשֶׂ֣רַח אֲחֹתָ֑ם וּבְנֵ֣י בְרִיעָ֔ה חֶ֖בֶר וּמַלְכִּיאֵֽל׃"
          },
          {
            "num": "יח",
            "txt": "אֵ֚לֶּה בְּנֵ֣י זִלְפָּ֔ה אֲשֶׁר־נָתַ֥ן לָבָ֖ן לְלֵאָ֣ה בִתּ֑וֹ וַתֵּ֤לֶד אֶת־אֵ֨לֶּה֙ לְיַֽעֲקֹ֔ב שֵׁ֥שׁ עֶשְׂרֵ֖ה נָֽפֶשׁ׃"
          },
          {
            "num": "יט",
            "txt": "בְּנֵ֤י רָחֵל֙ אֵ֣שֶׁת יַֽעֲקֹ֔ב יוֹסֵ֖ף וּבִנְיָמִֽן׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּוָּלֵ֣ד לְיוֹסֵף֮ בְּאֶ֣רֶץ מִצְרַיִם֒ אֲשֶׁ֤ר יָֽלְדָה־לּוֹ֙ אָֽסְנַ֔ת בַּת־פּ֥וֹטִי פֶ֖רַע כֹּהֵ֣ן אֹ֑ן אֶת־מְנַשֶּׁ֖ה וְאֶת־אֶפְרָֽיִם׃"
          },
          {
            "num": "כא",
            "txt": "וּבְנֵ֣י בִנְיָמִ֗ן בֶּ֤לַע וָבֶ֨כֶר֙ וְאַשְׁבֵּ֔ל גֵּרָ֥א וְנַֽעֲמָ֖ן אֵחִ֣י וָרֹ֑אשׁ מֻפִּ֥ים וְחֻפִּ֖ים וָאָֽרְדְּ׃"
          },
          {
            "num": "כב",
            "txt": "אֵ֚לֶּה בְּנֵ֣י רָחֵ֔ל אֲשֶׁ֥ר יֻלַּ֖ד לְיַֽעֲקֹ֑ב כָּל־נֶ֖פֶשׁ אַרְבָּעָ֥ה עָשָֽׂר׃"
          },
          {
            "num": "כג",
            "txt": "וּבְנֵי־דָ֖ן חֻשִֽׁים׃"
          },
          {
            "num": "כד",
            "txt": "וּבְנֵ֖י נַפְתָּלִ֑י יַחְצְאֵ֥ל וְגוּנִ֖י וְיֵ֥צֶר וְשִׁלֵּֽם׃"
          },
          {
            "num": "כה",
            "txt": "אֵ֚לֶּה בְּנֵ֣י בִלְהָ֔ה אֲשֶׁר־נָתַ֥ן לָבָ֖ן לְרָחֵ֣ל בִּתּ֑וֹ וַתֵּ֧לֶד אֶת־אֵ֛לֶּה לְיַֽעֲקֹ֖ב כָּל־נֶ֥פֶשׁ שִׁבְעָֽה׃"
          },
          {
            "num": "כו",
            "txt": "כָּל־הַ֠נֶּפֶשׁ הַבָּאָ֨ה לְיַֽעֲקֹ֤ב מִצְרַ֨יְמָה֙ יֹֽצְאֵ֣י יְרֵכ֔וֹ מִלְּבַ֖ד נְשֵׁ֣י בְנֵֽי־יַעֲקֹ֑ב כָּל־נֶ֖פֶשׁ שִׁשִּׁ֥ים וָשֵֽׁשׁ׃"
          },
          {
            "num": "כז",
            "txt": "וּבְנֵ֥י יוֹסֵ֛ף אֲשֶׁר־יֻלַּד־ל֥וֹ בְמִצְרַ֖יִם נֶ֣פֶשׁ שְׁנָ֑יִם כָּל־הַנֶּ֧פֶשׁ לְבֵֽית־יַעֲקֹ֛ב הַבָּ֥אָה מִצְרַ֖יְמָה שִׁבְעִֽים׃ {ס}"
          },
          {
            "num": "כח",
            "txt": "וְאֶת־יְהוּדָ֞ה שָׁלַ֤ח לְפָנָיו֙ אֶל־יוֹסֵ֔ף לְהוֹרֹ֥ת לְפָנָ֖יו גֹּ֑שְׁנָה וַיָּבֹ֖אוּ אַ֥רְצָה גֹּֽשֶׁן׃"
          },
          {
            "num": "כט",
            "txt": "וַיֶּאְסֹ֤ר יוֹסֵף֙ מֶרְכַּבְתּ֔וֹ וַיַּ֛עַל לִקְרַֽאת־יִשְׂרָאֵ֥ל אָבִ֖יו גֹּ֑שְׁנָה וַיֵּרָ֣א אֵלָ֗יו וַיִּפֹּל֙ עַל־צַוָּארָ֔יו וַיֵּ֥בְךְּ עַל־צַוָּארָ֖יו עֽוֹד׃"
          },
          {
            "num": "ל",
            "txt": "וַיֹּ֧אמֶר יִשְׂרָאֵ֛ל אֶל־יוֹסֵ֖ף אָמ֣וּתָה הַפָּ֑עַם אַֽחֲרֵי֙ רְאוֹתִ֣י אֶת־פָּנֶ֔יךָ כִּ֥י עֽוֹדְךָ֖ חָֽי׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֨אמֶר יוֹסֵ֤ף אֶל־אֶחָיו֙ וְאֶל־בֵּ֣ית אָבִ֔יו אֶֽעֱלֶ֖ה וְאַגִּ֣ידָה לְפַרְעֹ֑ה וְאֹֽמְרָ֣ה אֵלָ֔יו אַחַ֧י וּבֵית־אָבִ֛י אֲשֶׁ֥ר בְּאֶֽרֶץ־כְּנַ֖עַן בָּ֥אוּ אֵלָֽי׃"
          },
          {
            "num": "לב",
            "txt": "וְהָֽאֲנָשִׁים֙ רֹ֣עֵי צֹ֔אן כִּֽי־אַנְשֵׁ֥י מִקְנֶ֖ה הָי֑וּ וְצֹאנָ֧ם וּבְקָרָ֛ם וְכָל־אֲשֶׁ֥ר לָהֶ֖ם הֵבִֽיאוּ׃"
          },
          {
            "num": "לג",
            "txt": "וְהָיָ֕ה כִּֽי־יִקְרָ֥א לָכֶ֖ם פַּרְעֹ֑ה וְאָמַ֖ר מַה־מַּֽעֲשֵׂיכֶֽם׃"
          },
          {
            "num": "לד",
            "txt": "וַֽאֲמַרְתֶּ֗ם אַנְשֵׁ֨י מִקְנֶ֜ה הָי֤וּ עֲבָדֶ֨יךָ֙ מִנְּעוּרֵ֣ינוּ וְעַד־עַ֔תָּה גַּם־אֲנַ֖חְנוּ גַּם־אֲבֹתֵ֑ינוּ בַּֽעֲב֗וּר תֵּֽשְׁבוּ֙ בְּאֶ֣רֶץ גֹּ֔שֶׁן כִּֽי־תוֹעֲבַ֥ת מִצְרַ֖יִם כָּל־רֹ֥עֵה צֹֽאן׃"
          }
        ]
      },
      {
        "num": "מז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיָּבֹ֣א יוֹסֵף֮ וַיַּגֵּ֣ד לְפַרְעֹה֒ וַיֹּ֗אמֶר אָבִ֨י וְאַחַ֜י וְצֹאנָ֤ם וּבְקָרָם֙ וְכָל־אֲשֶׁ֣ר לָהֶ֔ם בָּ֖אוּ מֵאֶ֣רֶץ כְּנָ֑עַן וְהִנָּ֖ם בְּאֶ֥רֶץ גֹּֽשֶׁן׃"
          },
          {
            "num": "ב",
            "txt": "וּמִקְצֵ֣ה אֶחָ֔יו לָקַ֖ח חֲמִשָּׁ֣ה אֲנָשִׁ֑ים וַיַּצִּגֵ֖ם לִפְנֵ֥י פַרְעֹֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֧אמֶר פַּרְעֹ֛ה אֶל־אֶחָ֖יו מַה־מַּֽעֲשֵׂיכֶ֑ם וַיֹּֽאמְר֣וּ אֶל־פַּרְעֹ֗ה רֹעֵ֥ה צֹאן֙ עֲבָדֶ֔יךָ גַּם־אֲנַ֖חְנוּ גַּם־אֲבוֹתֵֽינוּ׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּֽאמְר֣וּ אֶל־פַּרְעֹ֗ה לָג֣וּר בָּאָרֶץ֮ בָּאנוּ֒ כִּי־אֵ֣ין מִרְעֶ֗ה לַצֹּאן֙ אֲשֶׁ֣ר לַֽעֲבָדֶ֔יךָ כִּֽי־כָבֵ֥ד הָֽרָעָ֖ב בְּאֶ֣רֶץ כְּנָ֑עַן וְעַתָּ֛ה יֵֽשְׁבוּ־נָ֥א עֲבָדֶ֖יךָ בְּאֶ֥רֶץ גֹּֽשֶׁן׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֣אמֶר פַּרְעֹ֔ה אֶל־יוֹסֵ֖ף לֵאמֹ֑ר אָבִ֥יךָ וְאַחֶ֖יךָ בָּ֥אוּ אֵלֶֽיךָ׃"
          },
          {
            "num": "ו",
            "txt": "אֶ֤רֶץ מִצְרַ֨יִם֙ לְפָנֶ֣יךָ הִ֔וא בְּמֵיטַ֣ב הָאָ֔רֶץ הוֹשֵׁ֥ב אֶת־אָבִ֖יךָ וְאֶת־אַחֶ֑יךָ יֵֽשְׁבוּ֙ בְּאֶ֣רֶץ גֹּ֔שֶׁן וְאִם־יָדַ֗עְתָּ וְיֶשׁ־בָּם֙ אַנְשֵׁי־חַ֔יִל וְשַׂמְתָּ֛ם שָׂרֵ֥י מִקְנֶ֖ה עַל־אֲשֶׁר־לִֽי׃"
          },
          {
            "num": "ז",
            "txt": "וַיָּבֵ֤א יוֹסֵף֙ אֶת־יַֽעֲקֹ֣ב אָבִ֔יו וַיַּֽעֲמִדֵ֖הוּ לִפְנֵ֣י פַרְעֹ֑ה וַיְבָ֥רֶךְ יַֽעֲקֹ֖ב אֶת־פַּרְעֹֽה׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֥אמֶר פַּרְעֹ֖ה אֶֽל־יַעֲקֹ֑ב כַּמָּ֕ה יְמֵ֖י שְׁנֵ֥י חַיֶּֽיךָ׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֤אמֶר יַֽעֲקֹב֙ אֶל־פַּרְעֹ֔ה יְמֵי֙ שְׁנֵ֣י מְגוּרַ֔י שְׁלֹשִׁ֥ים וּמְאַ֖ת שָׁנָ֑ה מְעַ֣ט וְרָעִ֗ים הָיוּ֙ יְמֵי֙ שְׁנֵ֣י חַיַּ֔י וְלֹ֣א הִשִּׂ֗יגוּ אֶת־יְמֵי֙ שְׁנֵי֙ חַיֵּ֣י אֲבֹתַ֔י בִּימֵ֖י מְגֽוּרֵיהֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וַיְבָ֥רֶךְ יַֽעֲקֹ֖ב אֶת־פַּרְעֹ֑ה וַיֵּצֵ֖א מִלִּפְנֵ֥י פַרְעֹֽה׃"
          },
          {
            "num": "יא",
            "txt": "וַיּוֹשֵׁ֣ב יוֹסֵף֮ אֶת־אָבִ֣יו וְאֶת־אֶחָיו֒ וַיִּתֵּ֨ן לָהֶ֤ם אֲחֻזָּה֙ בְּאֶ֣רֶץ מִצְרַ֔יִם בְּמֵיטַ֥ב הָאָ֖רֶץ בְּאֶ֣רֶץ רַעְמְסֵ֑ס כַּֽאֲשֶׁ֖ר צִוָּ֥ה פַרְעֹֽה׃"
          },
          {
            "num": "יב",
            "txt": "וַיְכַלְכֵּ֤ל יוֹסֵף֙ אֶת־אָבִ֣יו וְאֶת־אֶחָ֔יו וְאֵ֖ת כָּל־בֵּ֣ית אָבִ֑יו לֶ֖חֶם לְפִ֥י הַטָּֽף׃"
          },
          {
            "num": "יג",
            "txt": "וְלֶ֤חֶם אֵין֙ בְּכָל־הָאָ֔רֶץ כִּֽי־כָבֵ֥ד הָֽרָעָ֖ב מְאֹ֑ד וַתֵּ֜לַהּ אֶ֤רֶץ מִצְרַ֨יִם֙ וְאֶ֣רֶץ כְּנַ֔עַן מִפְּנֵ֖י הָֽרָעָֽב׃"
          },
          {
            "num": "יד",
            "txt": "וַיְלַקֵּ֣ט יוֹסֵ֗ף אֶת־כָּל־הַכֶּ֨סֶף֙ הַנִּמְצָ֤א בְאֶֽרֶץ־מִצְרַ֨יִם֙ וּבְאֶ֣רֶץ כְּנַ֔עַן בַּשֶּׁ֖בֶר אֲשֶׁר־הֵ֣ם שֹֽׁבְרִ֑ים וַיָּבֵ֥א יוֹסֵ֛ף אֶת־הַכֶּ֖סֶף בֵּ֥יתָה פַרְעֹֽה׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּתֹּ֣ם הַכֶּ֗סֶף מֵאֶ֣רֶץ מִצְרַיִם֮ וּמֵאֶ֣רֶץ כְּנַעַן֒ וַיָּבֹאוּ֩ כָל־מִצְרַ֨יִם אֶל־יוֹסֵ֤ף לֵאמֹר֙ הָֽבָה־לָּ֣נוּ לֶ֔חֶם וְלָ֥מָּה נָמ֖וּת נֶגְדֶּ֑ךָ כִּ֥י אָפֵ֖ס כָּֽסֶף׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֤אמֶר יוֹסֵף֙ הָב֣וּ מִקְנֵיכֶ֔ם וְאֶתְּנָ֥ה לָכֶ֖ם בְּמִקְנֵיכֶ֑ם אִם־אָפֵ֖ס כָּֽסֶף׃"
          },
          {
            "num": "יז",
            "txt": "וַיָּבִ֣יאוּ אֶת־מִקְנֵיהֶם֮ אֶל־יוֹסֵף֒ וַיִּתֵּ֣ן לָהֶם֩ יוֹסֵ֨ף לֶ֜חֶם בַּסּוּסִ֗ים וּבְמִקְנֵ֥ה הַצֹּ֛אן וּבְמִקְנֵ֥ה הַבָּקָ֖ר וּבַֽחֲמֹרִ֑ים וַיְנַֽהֲלֵ֤ם בַּלֶּ֨חֶם֙ בְּכָל־מִקְנֵהֶ֔ם בַּשָּׁנָ֖ה הַהִֽוא׃"
          },
          {
            "num": "יח",
            "txt": "וַתִּתֹּם֮ הַשָּׁנָ֣ה הַהִוא֒ וַיָּבֹ֨אוּ אֵלָ֜יו בַּשָּׁנָ֣ה הַשֵּׁנִ֗ית וַיֹּ֤אמְרוּ לוֹ֙ לֹֽא־נְכַחֵ֣ד מֵֽאֲדֹנִ֔י כִּ֚י אִם־תַּ֣ם הַכֶּ֔סֶף וּמִקְנֵ֥ה הַבְּהֵמָ֖ה אֶל־אֲדֹנִ֑י לֹ֤א נִשְׁאַר֙ לִפְנֵ֣י אֲדֹנִ֔י בִּלְתִּ֥י אִם־גְּוִיָּתֵ֖נוּ וְאַדְמָתֵֽנוּ׃"
          },
          {
            "num": "יט",
            "txt": "לָ֧מָּה נָמ֣וּת לְעֵינֶ֗יךָ גַּם־אֲנַ֨חְנוּ֙ גַּ֣ם אַדְמָתֵ֔נוּ קְנֵֽה־אֹתָ֥נוּ וְאֶת־אַדְמָתֵ֖נוּ בַּלָּ֑חֶם וְנִֽהְיֶ֞ה אֲנַ֤חְנוּ וְאַדְמָתֵ֨נוּ֙ עֲבָדִ֣ים לְפַרְעֹ֔ה וְתֶן־זֶ֗רַע וְנִֽחְיֶה֙ וְלֹ֣א נָמ֔וּת וְהָֽאֲדָמָ֖ה לֹ֥א תֵשָֽׁם׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּ֨קֶן יוֹסֵ֜ף אֶת־כָּל־אַדְמַ֤ת מִצְרַ֨יִם֙ לְפַרְעֹ֔ה כִּֽי־מָכְר֤וּ מִצְרַ֨יִם֙ אִ֣ישׁ שָׂדֵ֔הוּ כִּֽי־חָזַ֥ק עֲלֵהֶ֖ם הָֽרָעָ֑ב וַתְּהִ֥י הָאָ֖רֶץ לְפַרְעֹֽה׃"
          },
          {
            "num": "כא",
            "txt": "וְאֶ֨ת־הָעָ֔ם הֶֽעֱבִ֥יר אֹת֖וֹ לֶֽעָרִ֑ים מִקְצֵ֥ה גְבוּל־מִצְרַ֖יִם וְעַד־קָצֵֽהוּ׃"
          },
          {
            "num": "כב",
            "txt": "רַ֛ק אַדְמַ֥ת הַכֹּֽהֲנִ֖ים לֹ֣א קָנָ֑ה כִּי֩ חֹ֨ק לַכֹּֽהֲנִ֜ים מֵאֵ֣ת פַּרְעֹ֗ה וְאָֽכְל֤וּ אֶת־חֻקָּם֙ אֲשֶׁ֨ר נָתַ֤ן לָהֶם֙ פַּרְעֹ֔ה עַל־כֵּ֕ן לֹ֥א מָֽכְר֖וּ אֶת־אַדְמָתָֽם׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֤אמֶר יוֹסֵף֙ אֶל־הָעָ֔ם הֵן֩ קָנִ֨יתִי אֶתְכֶ֥ם הַיּ֛וֹם וְאֶת־אַדְמַתְכֶ֖ם לְפַרְעֹ֑ה הֵֽא־לָכֶ֣ם זֶ֔רַע וּזְרַעְתֶּ֖ם אֶת־הָֽאֲדָמָֽה׃"
          },
          {
            "num": "כד",
            "txt": "וְהָיָה֙ בַּתְּבוּאֹ֔ת וּנְתַתֶּ֥ם חֲמִישִׁ֖ית לְפַרְעֹ֑ה וְאַרְבַּ֣ע הַיָּדֹ֡ת יִֽהְיֶ֣ה לָכֶם֩ לְזֶ֨רַע הַשָּׂדֶ֧ה וּֽלְאָכְלְכֶ֛ם וְלַֽאֲשֶׁ֥ר בְּבָֽתֵּיכֶ֖ם וְלֶֽאֱכֹ֥ל לְטַפְּכֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּֽאמְר֖וּ הֶֽחֱיִתָ֑נוּ נִמְצָא־חֵן֙ בְּעֵינֵ֣י אֲדֹנִ֔י וְהָיִ֥ינוּ עֲבָדִ֖ים לְפַרְעֹֽה׃"
          },
          {
            "num": "כו",
            "txt": "וַיָּ֣שֶׂם אֹתָ֣הּ יוֹסֵ֡ף לְחֹק֩ עַד־הַיּ֨וֹם הַזֶּ֜ה עַל־אַדְמַ֥ת מִצְרַ֛יִם לְפַרְעֹ֖ה לַחֹ֑מֶשׁ רַ֞ק אַדְמַ֤ת הַכֹּֽהֲנִים֙ לְבַדָּ֔ם לֹ֥א הָֽיְתָ֖ה לְפַרְעֹֽה׃"
          },
          {
            "num": "כז",
            "txt": "וַיֵּ֧שֶׁב יִשְׂרָאֵ֛ל בְּאֶ֥רֶץ מִצְרַ֖יִם בְּאֶ֣רֶץ גֹּ֑שֶׁן וַיֵּאָֽחֲז֣וּ בָ֔הּ וַיִּפְר֥וּ וַיִּרְבּ֖וּ מְאֹֽד׃"
          },
          {
            "num": "כח",
            "txt": "וַיְחִ֤י יַֽעֲקֹב֙ בְּאֶ֣רֶץ מִצְרַ֔יִם שְׁבַ֥ע עֶשְׂרֵ֖ה שָׁנָ֑ה וַיְהִ֤י יְמֵֽי־יַעֲקֹב֙ שְׁנֵ֣י חַיָּ֔יו שֶׁ֣בַע שָׁנִ֔ים וְאַרְבָּעִ֥ים וּמְאַ֖ת שָׁנָֽה׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּקְרְב֣וּ יְמֵֽי־יִשְׂרָאֵל֮ לָמוּת֒ וַיִּקְרָ֣א ׀ לִבְנ֣וֹ לְיוֹסֵ֗ף וַיֹּ֤אמֶר לוֹ֙ אִם־נָ֨א מָצָ֤אתִי חֵן֙ בְּעֵינֶ֔יךָ שִֽׂים־נָ֥א יָֽדְךָ֖ תַּ֣חַת יְרֵכִ֑י וְעָשִׂ֤יתָ עִמָּדִי֙ חֶ֣סֶד וֶֽאֱמֶ֔ת אַל־נָ֥א תִקְבְּרֵ֖נִי בְּמִצְרָֽיִם׃"
          },
          {
            "num": "ל",
            "txt": "וְשָֽׁכַבְתִּי֙ עִם־אֲבֹתַ֔י וּנְשָׂאתַ֨נִי֙ מִמִּצְרַ֔יִם וּקְבַרְתַּ֖נִי בִּקְבֻֽרָתָ֑ם וַיֹּאמַ֕ר אָֽנֹכִ֖י אֶֽעֱשֶׂ֥ה כִדְבָרֶֽךָ׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֗אמֶר הִשָּֽׁבְעָה֙ לִ֔י וַיִּשָּׁבַ֖ע ל֑וֹ וַיִּשְׁתַּ֥חוּ יִשְׂרָאֵ֖ל עַל־רֹ֥אשׁ הַמִּטָּֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "מח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֗י אַֽחֲרֵי֙ הַדְּבָרִ֣ים הָאֵ֔לֶּה וַיֹּ֣אמֶר לְיוֹסֵ֔ף הִנֵּ֥ה אָבִ֖יךָ חֹלֶ֑ה וַיִּקַּ֞ח אֶת־שְׁנֵ֤י בָנָיו֙ עִמּ֔וֹ אֶת־מְנַשֶּׁ֖ה וְאֶת־אֶפְרָֽיִם׃"
          },
          {
            "num": "ב",
            "txt": "וַיַּגֵּ֣ד לְיַֽעֲקֹ֔ב וַיֹּ֕אמֶר הִנֵּ֛ה בִּנְךָ֥ יוֹסֵ֖ף בָּ֣א אֵלֶ֑יךָ וַיִּתְחַזֵּק֙ יִשְׂרָאֵ֔ל וַיֵּ֖שֶׁב עַל־הַמִּטָּֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֤אמֶר יַֽעֲקֹב֙ אֶל־יוֹסֵ֔ף אֵ֥ל שַׁדַּ֛י נִרְאָֽה־אֵלַ֥י בְּל֖וּז בְּאֶ֣רֶץ כְּנָ֑עַן וַיְבָ֖רֶךְ אֹתִֽי׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֣אמֶר אֵלַ֗י הִנְנִ֤י מַפְרְךָ֙ וְהִרְבִּיתִ֔ךָ וּנְתַתִּ֖יךָ לִקְהַ֣ל עַמִּ֑ים וְנָ֨תַתִּ֜י אֶת־הָאָ֧רֶץ הַזֹּ֛את לְזַרְעֲךָ֥ אַֽחֲרֶ֖יךָ אֲחֻזַּ֥ת עוֹלָֽם׃"
          },
          {
            "num": "ה",
            "txt": "וְעַתָּ֡ה שְׁנֵֽי־בָנֶיךָ֩ הַנּֽוֹלָדִ֨ים לְךָ֜ בְּאֶ֣רֶץ מִצְרַ֗יִם עַד־בֹּאִ֥י אֵלֶ֛יךָ מִצְרַ֖יְמָה לִי־הֵ֑ם אֶפְרַ֨יִם֙ וּמְנַשֶּׁ֔ה כִּרְאוּבֵ֥ן וְשִׁמְע֖וֹן יִֽהְיוּ־לִֽי׃"
          },
          {
            "num": "ו",
            "txt": "וּמֽוֹלַדְתְּךָ֛ אֲשֶׁר־הוֹלַ֥דְתָּ אַֽחֲרֵיהֶ֖ם לְךָ֣ יִֽהְי֑וּ עַ֣ל שֵׁ֧ם אֲחֵיהֶ֛ם יִקָּֽרְא֖וּ בְּנַֽחֲלָתָֽם׃"
          },
          {
            "num": "ז",
            "txt": "וַֽאֲנִ֣י ׀ בְּבֹאִ֣י מִפַּדָּ֗ן מֵתָה֩ עָלַ֨י רָחֵ֜ל בְּאֶ֤רֶץ כְּנַ֨עַן֙ בַּדֶּ֔רֶךְ בְּע֥וֹד כִּבְרַת־אֶ֖רֶץ לָבֹ֣א אֶפְרָ֑תָה וָֽאֶקְבְּרֶ֤הָ שָּׁם֙ בְּדֶ֣רֶךְ אֶפְרָ֔ת הִ֖וא בֵּ֥ית לָֽחֶם׃"
          },
          {
            "num": "ח",
            "txt": "וַיַּ֥רְא יִשְׂרָאֵ֖ל אֶת־בְּנֵ֣י יוֹסֵ֑ף וַיֹּ֖אמֶר מִי־אֵֽלֶּה׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֤אמֶר יוֹסֵף֙ אֶל־אָבִ֔יו בָּנַ֣י הֵ֔ם אֲשֶׁר־נָֽתַן־לִ֥י אֱלֹהִ֖ים בָּזֶ֑ה וַיֹּאמַ֕ר קָֽחֶם־נָ֥א אֵלַ֖י וַאֲבָֽרְכֵֽם׃"
          },
          {
            "num": "י",
            "txt": "וְעֵינֵ֤י יִשְׂרָאֵל֙ כָּֽבְד֣וּ מִזֹּ֔קֶן לֹ֥א יוּכַ֖ל לִרְא֑וֹת וַיַּגֵּ֤שׁ אֹתָם֙ אֵלָ֔יו וַיִּשַּׁ֥ק לָהֶ֖ם וַיְחַבֵּ֥ק לָהֶֽם׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֤אמֶר יִשְׂרָאֵל֙ אֶל־יוֹסֵ֔ף רְאֹ֥ה פָנֶ֖יךָ לֹ֣א פִלָּ֑לְתִּי וְהִנֵּ֨ה הֶרְאָ֥ה אֹתִ֛י אֱלֹהִ֖ים גַּ֥ם אֶת־זַרְעֶֽךָ׃"
          },
          {
            "num": "יב",
            "txt": "וַיּוֹצֵ֥א יוֹסֵ֛ף אֹתָ֖ם מֵעִ֣ם בִּרְכָּ֑יו וַיִּשְׁתַּ֥חוּ לְאַפָּ֖יו אָֽרְצָה׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּקַּ֣ח יוֹסֵף֮ אֶת־שְׁנֵיהֶם֒ אֶת־אֶפְרַ֤יִם בִּֽימִינוֹ֙ מִשְּׂמֹ֣אל יִשְׂרָאֵ֔ל וְאֶת־מְנַשֶּׁ֥ה בִשְׂמֹאל֖וֹ מִימִ֣ין יִשְׂרָאֵ֑ל וַיַּגֵּ֖שׁ אֵלָֽיו׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּשְׁלַח֩ יִשְׂרָאֵ֨ל אֶת־יְמִינ֜וֹ וַיָּ֨שֶׁת עַל־רֹ֤אשׁ אֶפְרַ֨יִם֙ וְה֣וּא הַצָּעִ֔יר וְאֶת־שְׂמֹאל֖וֹ עַל־רֹ֣אשׁ מְנַשֶּׁ֑ה שִׂכֵּל֙ אֶת־יָדָ֔יו כִּ֥י מְנַשֶּׁ֖ה הַבְּכֽוֹר׃"
          },
          {
            "num": "טו",
            "txt": "וַיְבָ֥רֶךְ אֶת־יוֹסֵ֖ף וַיֹּאמַ֑ר הָֽאֱלֹהִ֡ים אֲשֶׁר֩ הִתְהַלְּכ֨וּ אֲבֹתַ֤י לְפָנָיו֙ אַבְרָהָ֣ם וְיִצְחָ֔ק הָֽאֱלֹהִים֙ הָֽרֹעֶ֣ה אֹתִ֔י מֵֽעוֹדִ֖י עַד־הַיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "טז",
            "txt": "הַמַּלְאָךְ֩ הַגֹּאֵ֨ל אֹתִ֜י מִכָּל־רָ֗ע יְבָרֵךְ֮ אֶת־הַנְּעָרִים֒ וְיִקָּרֵ֤א בָהֶם֙ שְׁמִ֔י וְשֵׁ֥ם אֲבֹתַ֖י אַבְרָהָ֣ם וְיִצְחָ֑ק וְיִדְגּ֥וּ לָרֹ֖ב בְּקֶ֥רֶב הָאָֽרֶץ׃"
          },
          {
            "num": "יז",
            "txt": "וַיַּ֣רְא יוֹסֵ֗ף כִּֽי־יָשִׁ֨ית אָבִ֧יו יַד־יְמִינ֛וֹ עַל־רֹ֥אשׁ אֶפְרַ֖יִם וַיֵּ֣רַע בְּעֵינָ֑יו וַיִּתְמֹ֣ךְ יַד־אָבִ֗יו לְהָסִ֥יר אֹתָ֛הּ מֵעַ֥ל רֹאשׁ־אֶפְרַ֖יִם עַל־רֹ֥אשׁ מְנַשֶּֽׁה׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֧אמֶר יוֹסֵ֛ף אֶל־אָבִ֖יו לֹא־כֵ֣ן אָבִ֑י כִּי־זֶ֣ה הַבְּכֹ֔ר שִׂ֥ים יְמִֽינְךָ֖ עַל־רֹאשֽׁוֹ׃"
          },
          {
            "num": "יט",
            "txt": "וַיְמָאֵ֣ן אָבִ֗יו וַיֹּ֨אמֶר֙ יָדַ֤עְתִּֽי בְנִי֙ יָדַ֔עְתִּי גַּם־ה֥וּא יִֽהְיֶה־לְּעָ֖ם וְגַם־ה֣וּא יִגְדָּ֑ל וְאוּלָ֗ם אָחִ֤יו הַקָּטֹן֙ יִגְדַּ֣ל מִמֶּ֔נּוּ וְזַרְע֖וֹ יִֽהְיֶ֥ה מְלֹֽא־הַגּוֹיִֽם׃"
          },
          {
            "num": "כ",
            "txt": "וַיְבָ֨רְכֵ֜ם בַּיּ֣וֹם הַהוּא֮ לֵאמוֹר֒ בְּךָ֗ יְבָרֵ֤ךְ יִשְׂרָאֵל֙ לֵאמֹ֔ר יְשִֽׂמְךָ֣ אֱלֹהִ֔ים כְּאֶפְרַ֖יִם וְכִמְנַשֶּׁ֑ה וַיָּ֥שֶׂם אֶת־אֶפְרַ֖יִם לִפְנֵ֥י מְנַשֶּֽׁה׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֤אמֶר יִשְׂרָאֵל֙ אֶל־יוֹסֵ֔ף הִנֵּ֥ה אָֽנֹכִ֖י מֵ֑ת וְהָיָ֤ה אֱלֹהִים֙ עִמָּכֶ֔ם וְהֵשִׁ֣יב אֶתְכֶ֔ם אֶל־אֶ֖רֶץ אֲבֹֽתֵיכֶֽם׃"
          },
          {
            "num": "כב",
            "txt": "וַֽאֲנִ֞י נָתַ֧תִּֽי לְךָ֛ שְׁכֶ֥ם אַחַ֖ד עַל־אַחֶ֑יךָ אֲשֶׁ֤ר לָקַ֨חְתִּי֙ מִיַּ֣ד הָֽאֱמֹרִ֔י בְּחַרְבִּ֖י וּבְקַשְׁתִּֽי׃ {פ}"
          }
        ]
      },
      {
        "num": "מט",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּקְרָ֥א יַֽעֲקֹ֖ב אֶל־בָּנָ֑יו וַיֹּ֗אמֶר הֵאָֽסְפוּ֙ וְאַגִּ֣ידָה לָכֶ֔ם אֵ֛ת אֲשֶׁר־יִקְרָ֥א אֶתְכֶ֖ם בְּאַֽחֲרִ֥ית הַיָּמִֽים׃"
          },
          {
            "num": "ב",
            "txt": "הִקָּֽבְצ֥וּ וְשִׁמְע֖וּ בְּנֵ֣י יַֽעֲקֹ֑ב וְשִׁמְע֖וּ אֶל־יִשְׂרָאֵ֥ל אֲבִיכֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "רְאוּבֵן֙ בְּכֹ֣רִי אַ֔תָּה כֹּחִ֖י וְרֵאשִׁ֣ית אוֹנִ֑י יֶ֥תֶר שְׂאֵ֖ת וְיֶ֥תֶר עָֽז׃"
          },
          {
            "num": "ד",
            "txt": "פַּ֤חַז כַּמַּ֨יִם֙ אַל־תּוֹתַ֔ר כִּ֥י עָלִ֖יתָ מִשְׁכְּבֵ֣י אָבִ֑יךָ אָ֥ז חִלַּ֖לְתָּ יְצוּעִ֥י עָלָֽה׃ {פ}"
          },
          {
            "num": "ה",
            "txt": "שִׁמְע֥וֹן וְלֵוִ֖י אַחִ֑ים כְּלֵ֥י חָמָ֖ס מְכֵרֹֽתֵיהֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "בְּסֹדָם֙ אַל־תָּבֹ֣א נַפְשִׁ֔י בִּקְהָלָ֖ם אַל־תֵּחַ֣ד כְּבֹדִ֑י כִּ֤י בְאַפָּם֙ הָ֣רְגוּ אִ֔ישׁ וּבִרְצֹנָ֖ם עִקְּרוּ־שֽׁוֹר׃"
          },
          {
            "num": "ז",
            "txt": "אָר֤וּר אַפָּם֙ כִּ֣י עָ֔ז וְעֶבְרָתָ֖ם כִּ֣י קָשָׁ֑תָה אֲחַלְּקֵ֣ם בְּיַֽעֲקֹ֔ב וַֽאֲפִיצֵ֖ם בְּיִשְׂרָאֵֽל׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "יְהוּדָ֗ה אַתָּה֙ יוֹד֣וּךָ אַחֶ֔יךָ יָֽדְךָ֖ בְּעֹ֣רֶף אֹֽיְבֶ֑יךָ יִשְׁתַּֽחֲו֥וּ לְךָ֖ בְּנֵ֥י אָבִֽיךָ׃"
          },
          {
            "num": "ט",
            "txt": "גּ֤וּר אַרְיֵה֙ יְהוּדָ֔ה מִטֶּ֖רֶף בְּנִ֣י עָלִ֑יתָ כָּרַ֨ע רָבַ֧ץ כְּאַרְיֵ֛ה וּכְלָבִ֖יא מִ֥י יְקִימֶֽנּוּ׃"
          },
          {
            "num": "י",
            "txt": "לֹֽא־יָס֥וּר שֵׁ֨בֶט֙ מִֽיהוּדָ֔ה וּמְחֹקֵ֖ק מִבֵּ֣ין רַגְלָ֑יו עַ֚ד כִּֽי־יָבֹ֣א שִׁילֹ֔ה וְל֖וֹ יִקְּהַ֥ת עַמִּֽים׃"
          },
          {
            "num": "יא",
            "txt": "אֹֽסְרִ֤י לַגֶּ֨פֶן֙ עִירֹ֔ה וְלַשֹּֽׂרֵקָ֖ה בְּנִ֣י אֲתֹנ֑וֹ כִּבֵּ֤ס בַּיַּ֨יִן֙ לְבֻשׁ֔וֹ וּבְדַם־עֲנָבִ֖ים סוּתֹֽה׃"
          },
          {
            "num": "יב",
            "txt": "חַכְלִילִ֥י עֵינַ֖יִם מִיָּ֑יִן וּלְבֶן־שִׁנַּ֖יִם מֵֽחָלָֽב׃ {פ}"
          },
          {
            "num": "יג",
            "txt": "זְבוּלֻ֕ן לְח֥וֹף יַמִּ֖ים יִשְׁכֹּ֑ן וְהוּא֙ לְח֣וֹף אֳנִיֹּ֔ת וְיַרְכָת֖וֹ עַל־צִידֹֽן׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "יִשָּׂשכָ֖ר חֲמֹ֣ר גָּ֑רֶם רֹבֵ֖ץ בֵּ֥ין הַֽמִּשְׁפְּתָֽיִם׃"
          },
          {
            "num": "טו",
            "txt": "וַיַּ֤רְא מְנֻחָה֙ כִּ֣י ט֔וֹב וְאֶת־הָאָ֖רֶץ כִּ֣י נָעֵ֑מָה וַיֵּ֤ט שִׁכְמוֹ֙ לִסְבֹּ֔ל וַיְהִ֖י לְמַס־עֹבֵֽד׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "דָּ֖ן יָדִ֣ין עַמּ֑וֹ כְּאַחַ֖ד שִׁבְטֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יז",
            "txt": "יְהִי־דָן֙ נָחָ֣שׁ עֲלֵי־דֶ֔רֶךְ שְׁפִיפֹ֖ן עֲלֵי־אֹ֑רַח הַנֹּשֵׁךְ֙ עִקְּבֵי־ס֔וּס וַיִּפֹּ֥ל רֹֽכְב֖וֹ אָחֽוֹר׃"
          },
          {
            "num": "יח",
            "txt": "לִֽישׁוּעָתְךָ֖ קִוִּ֥יתִי יְהוָֽה׃ {ס}"
          },
          {
            "num": "יט",
            "txt": "גָּ֖ד גְּד֣וּד יְגוּדֶ֑נּוּ וְה֖וּא יָגֻ֥ד עָקֵֽב׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "מֵֽאָשֵׁ֖ר שְׁמֵנָ֣ה לַחְמ֑וֹ וְה֥וּא יִתֵּ֖ן מַֽעֲדַנֵּי־מֶֽלֶךְ׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "נַפְתָּלִ֖י אַיָּלָ֣ה שְׁלֻחָ֑ה הַנֹּתֵ֖ן אִמְרֵי־שָֽׁפֶר׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "בֵּ֤ן פֹּרָת֙ יוֹסֵ֔ף בֵּ֥ן פֹּרָ֖ת עֲלֵי־עָ֑יִן בָּנ֕וֹת צָֽעֲדָ֖ה עֲלֵי־שֽׁוּר׃"
          },
          {
            "num": "כג",
            "txt": "וַֽיְמָרְרֻ֖הוּ וָרֹ֑בּוּ וַֽיִּשְׂטְמֻ֖הוּ בַּֽעֲלֵ֥י חִצִּֽים׃"
          },
          {
            "num": "כד",
            "txt": "וַתֵּ֤שֶׁב בְּאֵיתָן֙ קַשְׁתּ֔וֹ וַיָּפֹ֖זּוּ זְרֹעֵ֣י יָדָ֑יו מִידֵי֙ אֲבִ֣יר יַֽעֲקֹ֔ב מִשָּׁ֥ם רֹעֶ֖ה אֶ֥בֶן יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כה",
            "txt": "מֵאֵ֨ל אָבִ֜יךָ וְיַעְזְרֶ֗ךָּ וְאֵ֤ת שַׁדַּי֙ וִיבָ֣רְכֶ֔ךָּ בִּרְכֹ֤ת שָׁמַ֨יִם֙ מֵעָ֔ל בִּרְכֹ֥ת תְּה֖וֹם רֹבֶ֣צֶת תָּ֑חַת בִּרְכֹ֥ת שָׁדַ֖יִם וָרָֽחַם׃"
          },
          {
            "num": "כו",
            "txt": "בִּרְכֹ֣ת אָבִ֗יךָ גָּֽבְרוּ֙ עַל־בִּרְכֹ֣ת הוֹרַ֔י עַֽד־תַּאֲוַ֖ת גִּבְעֹ֣ת עוֹלָ֑ם תִּֽהְיֶ֨יןָ֙ לְרֹ֣אשׁ יוֹסֵ֔ף וּלְקָדְקֹ֖ד נְזִ֥יר אֶחָֽיו׃ {פ}"
          },
          {
            "num": "כז",
            "txt": "בִּנְיָמִין֙ זְאֵ֣ב יִטְרָ֔ף בַּבֹּ֖קֶר יֹ֣אכַל עַ֑ד וְלָעֶ֖רֶב יְחַלֵּ֥ק שָׁלָֽל׃"
          },
          {
            "num": "כח",
            "txt": "כָּל־אֵ֛לֶּה שִׁבְטֵ֥י יִשְׂרָאֵ֖ל שְׁנֵ֣ים עָשָׂ֑ר וְ֠זֹאת אֲשֶׁר־דִּבֶּ֨ר לָהֶ֤ם אֲבִיהֶם֙ וַיְבָ֣רֶךְ אוֹתָ֔ם אִ֛ישׁ אֲשֶׁ֥ר כְּבִרְכָת֖וֹ בֵּרַ֥ךְ אֹתָֽם׃"
          },
          {
            "num": "כט",
            "txt": "וַיְצַ֣ו אוֹתָ֗ם וַיֹּ֤אמֶר אֲלֵהֶם֙ אֲנִי֙ נֶֽאֱסָ֣ף אֶל־עַמִּ֔י קִבְר֥וּ אֹתִ֖י אֶל־אֲבֹתָ֑י אֶ֨ל־הַמְּעָרָ֔ה אֲשֶׁ֥ר בִּשְׂדֵ֖ה עֶפְר֥וֹן הַֽחִתִּֽי׃"
          },
          {
            "num": "ל",
            "txt": "בַּמְּעָרָ֞ה אֲשֶׁ֨ר בִּשְׂדֵ֧ה הַמַּכְפֵּלָ֛ה אֲשֶׁ֥ר עַל־פְּנֵֽי־מַמְרֵ֖א בְּאֶ֣רֶץ כְּנָ֑עַן אֲשֶׁר֩ קָנָ֨ה אַבְרָהָ֜ם אֶת־הַשָּׂדֶ֗ה מֵאֵ֛ת עֶפְרֹ֥ן הַֽחִתִּ֖י לַֽאֲחֻזַּת־קָֽבֶר׃"
          },
          {
            "num": "לא",
            "txt": "שָׁ֣מָּה קָֽבְר֞וּ אֶת־אַבְרָהָ֗ם וְאֵת֙ שָׂרָ֣ה אִשְׁתּ֔וֹ שָׁ֚מָּה קָֽבְר֣וּ אֶת־יִצְחָ֔ק וְאֵ֖ת רִבְקָ֣ה אִשְׁתּ֑וֹ וְשָׁ֥מָּה קָבַ֖רְתִּי אֶת־לֵאָֽה׃"
          },
          {
            "num": "לב",
            "txt": "מִקְנֵ֧ה הַשָּׂדֶ֛ה וְהַמְּעָרָ֥ה אֲשֶׁר־בּ֖וֹ מֵאֵ֥ת בְּנֵי־חֵֽת׃"
          },
          {
            "num": "לג",
            "txt": "וַיְכַ֤ל יַֽעֲקֹב֙ לְצַוֺּ֣ת אֶת־בָּנָ֔יו וַיֶּֽאֱסֹ֥ף רַגְלָ֖יו אֶל־הַמִּטָּ֑ה וַיִּגְוַ֖ע וַיֵּאָ֥סֶף אֶל־עַמָּֽיו׃"
          }
        ]
      },
      {
        "num": "נ",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּפֹּ֥ל יוֹסֵ֖ף עַל־פְּנֵ֣י אָבִ֑יו וַיֵּ֥בְךְּ עָלָ֖יו וַיִּשַּׁק־לֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וַיְצַ֨ו יוֹסֵ֤ף אֶת־עֲבָדָיו֙ אֶת־הָרֹ֣פְאִ֔ים לַֽחֲנֹ֖ט אֶת־אָבִ֑יו וַיַּֽחַנְט֥וּ הָרֹֽפְאִ֖ים אֶת־יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּמְלְאוּ־לוֹ֙ אַרְבָּעִ֣ים י֔וֹם כִּ֛י כֵּ֥ן יִמְלְא֖וּ יְמֵ֣י הַֽחֲנֻטִ֑ים וַיִּבְכּ֥וּ אֹת֛וֹ מִצְרַ֖יִם שִׁבְעִ֥ים יֽוֹם׃"
          },
          {
            "num": "ד",
            "txt": "וַיַּֽעַבְרוּ֙ יְמֵ֣י בְכִית֔וֹ וַיְדַבֵּ֣ר יוֹסֵ֔ף אֶל־בֵּ֥ית פַּרְעֹ֖ה לֵאמֹ֑ר אִם־נָ֨א מָצָ֤אתִי חֵן֙ בְּעֵ֣ינֵיכֶ֔ם דַּבְּרוּ־נָ֕א בְּאָזְנֵ֥י פַרְעֹ֖ה לֵאמֹֽר׃"
          },
          {
            "num": "ה",
            "txt": "אָבִ֞י הִשְׁבִּיעַ֣נִי לֵאמֹ֗ר הִנֵּ֣ה אָֽנֹכִי֮ מֵת֒ בְּקִבְרִ֗י אֲשֶׁ֨ר כָּרִ֤יתִי לִי֙ בְּאֶ֣רֶץ כְּנַ֔עַן שָׁ֖מָּה תִּקְבְּרֵ֑נִי וְעַתָּ֗ה אֶֽעֱלֶה־נָּ֛א וְאֶקְבְּרָ֥ה אֶת־אָבִ֖י וְאָשֽׁוּבָה׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֖אמֶר פַּרְעֹ֑ה עֲלֵ֛ה וּקְבֹ֥ר אֶת־אָבִ֖יךָ כַּֽאֲשֶׁ֥ר הִשְׁבִּיעֶֽךָ׃"
          },
          {
            "num": "ז",
            "txt": "וַיַּ֥עַל יוֹסֵ֖ף לִקְבֹּ֣ר אֶת־אָבִ֑יו וַיַּֽעֲל֨וּ אִתּ֜וֹ כָּל־עַבְדֵ֤י פַרְעֹה֙ זִקְנֵ֣י בֵית֔וֹ וְכֹ֖ל זִקְנֵ֥י אֶֽרֶץ־מִצְרָֽיִם׃"
          },
          {
            "num": "ח",
            "txt": "וְכֹל֙ בֵּ֣ית יוֹסֵ֔ף וְאֶחָ֖יו וּבֵ֣ית אָבִ֑יו רַ֗ק טַפָּם֙ וְצֹאנָ֣ם וּבְקָרָ֔ם עָֽזְב֖וּ בְּאֶ֥רֶץ גֹּֽשֶׁן׃"
          },
          {
            "num": "ט",
            "txt": "וַיַּ֣עַל עִמּ֔וֹ גַּם־רֶ֖כֶב גַּם־פָּֽרָשִׁ֑ים וַיְהִ֥י הַֽמַּחֲנֶ֖ה כָּבֵ֥ד מְאֹֽד׃"
          },
          {
            "num": "י",
            "txt": "וַיָּבֹ֜אוּ עַד־גֹּ֣רֶן הָֽאָטָ֗ד אֲשֶׁר֙ בְּעֵ֣בֶר הַיַּרְדֵּ֔ן וַיִּ֨סְפְּדוּ־שָׁ֔ם מִסְפֵּ֛ד גָּד֥וֹל וְכָבֵ֖ד מְאֹ֑ד וַיַּ֧עַשׂ לְאָבִ֛יו אֵ֖בֶל שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "יא",
            "txt": "וַיַּ֡רְא יוֹשֵׁב֩ הָאָ֨רֶץ הַֽכְּנַעֲנִ֜י אֶת־הָאֵ֗בֶל בְּגֹ֨רֶן֙ הָֽאָטָ֔ד וַיֹּ֣אמְר֔וּ אֵֽבֶל־כָּבֵ֥ד זֶ֖ה לְמִצְרָ֑יִם עַל־כֵּ֞ן קָרָ֤א שְׁמָהּ֙ אָבֵ֣ל מִצְרַ֔יִם אֲשֶׁ֖ר בְּעֵ֥בֶר הַיַּרְדֵּֽן׃"
          },
          {
            "num": "יב",
            "txt": "וַיַּֽעֲשׂ֥וּ בָנָ֖יו ל֑וֹ כֵּ֖ן כַּֽאֲשֶׁ֥ר צִוָּֽם׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּשְׂא֨וּ אֹת֤וֹ בָנָיו֙ אַ֣רְצָה כְּנַ֔עַן וַיִּקְבְּר֣וּ אֹת֔וֹ בִּמְעָרַ֖ת שְׂדֵ֣ה הַמַּכְפֵּלָ֑ה אֲשֶׁ֣ר קָנָה֩ אַבְרָהָ֨ם אֶת־הַשָּׂדֶ֜ה לַֽאֲחֻזַּת־קֶ֗בֶר מֵאֵ֛ת עֶפְרֹ֥ן הַֽחִתִּ֖י עַל־פְּנֵ֥י מַמְרֵֽא׃"
          },
          {
            "num": "יד",
            "txt": "וַיָּ֨שָׁב יוֹסֵ֤ף מִצְרַ֨יְמָה֙ ה֣וּא וְאֶחָ֔יו וְכָל־הָֽעֹלִ֥ים אִתּ֖וֹ לִקְבֹּ֣ר אֶת־אָבִ֑יו אַֽחֲרֵ֖י קָבְר֥וֹ אֶת־אָבִֽיו׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּרְא֤וּ אֲחֵֽי־יוֹסֵף֙ כִּי־מֵ֣ת אֲבִיהֶ֔ם וַיֹּ֣אמְר֔וּ ל֥וּ יִשְׂטְמֵ֖נוּ יוֹסֵ֑ף וְהָשֵׁ֤ב יָשִׁיב֙ לָ֔נוּ אֵ֚ת כָּל־הָ֣רָעָ֔ה אֲשֶׁ֥ר גָּמַ֖לְנוּ אֹתֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "וַיְצַוּ֕וּ אֶל־יוֹסֵ֖ף לֵאמֹ֑ר אָבִ֣יךָ צִוָּ֔ה לִפְנֵ֥י מוֹת֖וֹ לֵאמֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "כֹּֽה־תֹאמְר֣וּ לְיוֹסֵ֗ף אָ֣נָּ֡א שָׂ֣א נָ֠א פֶּ֣שַׁע אַחֶ֤יךָ וְחַטָּאתָם֙ כִּֽי־רָעָ֣ה גְמָל֔וּךָ וְעַתָּה֙ שָׂ֣א נָ֔א לְפֶ֥שַׁע עַבְדֵ֖י אֱלֹהֵ֣י אָבִ֑יךָ וַיֵּ֥בְךְּ יוֹסֵ֖ף בְּדַבְּרָ֥ם אֵלָֽיו׃"
          },
          {
            "num": "יח",
            "txt": "וַיֵּֽלְכוּ֙ גַּם־אֶחָ֔יו וַֽיִּפְּל֖וּ לְפָנָ֑יו וַיֹּ֣אמְר֔וּ הִנֶּ֥נּֽוּ לְךָ֖ לַֽעֲבָדִֽים׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֧אמֶר אֲלֵהֶ֛ם יוֹסֵ֖ף אַל־תִּירָ֑אוּ כִּ֛י הֲתַ֥חַת אֱלֹהִ֖ים אָֽנִי׃"
          },
          {
            "num": "כ",
            "txt": "וְאַתֶּ֕ם חֲשַׁבְתֶּ֥ם עָלַ֖י רָעָ֑ה אֱלֹהִים֙ חֲשָׁבָ֣הּ לְטֹבָ֔ה לְמַ֗עַן עֲשֹׂ֛ה כַּיּ֥וֹם הַזֶּ֖ה לְהַֽחֲיֹ֥ת עַם־רָֽב׃"
          },
          {
            "num": "כא",
            "txt": "וְעַתָּה֙ אַל־תִּירָ֔אוּ אָֽנֹכִ֛י אֲכַלְכֵּ֥ל אֶתְכֶ֖ם וְאֶֽת־טַפְּכֶ֑ם וַיְנַחֵ֣ם אוֹתָ֔ם וַיְדַבֵּ֖ר עַל־לִבָּֽם׃"
          },
          {
            "num": "כב",
            "txt": "וַיֵּ֤שֶׁב יוֹסֵף֙ בְּמִצְרַ֔יִם ה֖וּא וּבֵ֣ית אָבִ֑יו וַיְחִ֣י יוֹסֵ֔ף מֵאָ֥ה וָעֶ֖שֶׂר שָׁנִֽים׃"
          },
          {
            "num": "כג",
            "txt": "וַיַּ֤רְא יוֹסֵף֙ לְאֶפְרַ֔יִם בְּנֵ֖י שִׁלֵּשִׁ֑ים גַּ֗ם בְּנֵ֤י מָכִיר֙ בֶּן־מְנַשֶּׁ֔ה יֻלְּד֖וּ עַל־בִּרְכֵּ֥י יוֹסֵֽף׃"
          },
          {
            "num": "כד",
            "txt": "וַיֹּ֤אמֶר יוֹסֵף֙ אֶל־אֶחָ֔יו אָֽנֹכִ֖י מֵ֑ת וֵֽאלֹהִ֞ים פָּקֹ֧ד יִפְקֹ֣ד אֶתְכֶ֗ם וְהֶֽעֱלָ֤ה אֶתְכֶם֙ מִן־הָאָ֣רֶץ הַזֹּ֔את אֶל־הָאָ֕רֶץ אֲשֶׁ֥ר נִשְׁבַּ֛ע לְאַבְרָהָ֥ם לְיִצְחָ֖ק וּֽלְיַעֲקֹֽב׃"
          },
          {
            "num": "כה",
            "txt": "וַיַּשְׁבַּ֣ע יוֹסֵ֔ף אֶת־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר פָּקֹ֨ד יִפְקֹ֤ד אֱלֹהִים֙ אֶתְכֶ֔ם וְהַֽעֲלִתֶ֥ם אֶת־עַצְמֹתַ֖י מִזֶּֽה׃"
          },
          {
            "num": "כו",
            "txt": "וַיָּ֣מָת יוֹסֵ֔ף בֶּן־מֵאָ֥ה וָעֶ֖שֶׂר שָׁנִ֑ים וַיַּֽחַנְט֣וּ אֹת֔וֹ וַיִּ֥ישֶׂם בָּֽאָר֖וֹן בְּמִצְרָֽיִם׃ {ש}"
          }
        ]
      }
    ]
  },
  "שמות": {
    "chapters": [
      {
        "num": "א",
        "verses": [
          {
            "num": "א",
            "txt": "וְאֵ֗לֶּה שְׁמוֹת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל הַבָּאִ֖ים מִצְרָ֑יְמָה אֵ֣ת יַֽעֲקֹ֔ב אִ֥ישׁ וּבֵית֖וֹ בָּֽאוּ׃"
          },
          {
            "num": "ב",
            "txt": "רְאוּבֵ֣ן שִׁמְע֔וֹן לֵוִ֖י וִֽיהוּדָֽה׃"
          },
          {
            "num": "ג",
            "txt": "יִשָּׂשכָ֥ר זְבוּלֻ֖ן וּבִנְיָמִֽן׃"
          },
          {
            "num": "ד",
            "txt": "דָּ֥ן וְנַפְתָּלִ֖י גָּ֥ד וְאָשֵֽׁר׃"
          },
          {
            "num": "ה",
            "txt": "וַֽיְהִ֗י כָּל־נֶ֛פֶשׁ יֹֽצְאֵ֥י יֶֽרֶךְ־יַעֲקֹ֖ב שִׁבְעִ֣ים נָ֑פֶשׁ וְיוֹסֵ֖ף הָיָ֥ה בְמִצְרָֽיִם׃"
          },
          {
            "num": "ו",
            "txt": "וַיָּ֤מָת יוֹסֵף֙ וְכָל־אֶחָ֔יו וְכֹ֖ל הַדּ֥וֹר הַהֽוּא׃"
          },
          {
            "num": "ז",
            "txt": "וּבְנֵ֣י יִשְׂרָאֵ֗ל פָּר֧וּ וַֽיִּשְׁרְצ֛וּ וַיִּרְבּ֥וּ וַיַּֽעַצְמ֖וּ בִּמְאֹ֣ד מְאֹ֑ד וַתִּמָּלֵ֥א הָאָ֖רֶץ אֹתָֽם׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "וַיָּ֥קָם מֶֽלֶךְ־חָדָ֖שׁ עַל־מִצְרָ֑יִם אֲשֶׁ֥ר לֹֽא־יָדַ֖ע אֶת־יוֹסֵֽף׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֖אמֶר אֶל־עַמּ֑וֹ הִנֵּ֗ה עַ֚ם בְּנֵ֣י יִשְׂרָאֵ֔ל רַ֥ב וְעָצ֖וּם מִמֶּֽנּוּ׃"
          },
          {
            "num": "י",
            "txt": "הָ֥בָה נִֽתְחַכְּמָ֖ה ל֑וֹ פֶּן־יִרְבֶּ֗ה וְהָיָ֞ה כִּֽי־תִקְרֶ֤אנָה מִלְחָמָה֙ וְנוֹסַ֤ף גַּם־הוּא֙ עַל־שֹׂ֣נְאֵ֔ינוּ וְנִלְחַם־בָּ֖נוּ וְעָלָ֥ה מִן־הָאָֽרֶץ׃"
          },
          {
            "num": "יא",
            "txt": "וַיָּשִׂ֤ימוּ עָלָיו֙ שָׂרֵ֣י מִסִּ֔ים לְמַ֥עַן עַנֹּת֖וֹ בְּסִבְלֹתָ֑ם וַיִּ֜בֶן עָרֵ֤י מִסְכְּנוֹת֙ לְפַרְעֹ֔ה אֶת־פִּתֹ֖ם וְאֶת־רַֽעַמְסֵֽס׃"
          },
          {
            "num": "יב",
            "txt": "וְכַֽאֲשֶׁר֙ יְעַנּ֣וּ אֹת֔וֹ כֵּ֥ן יִרְבֶּ֖ה וְכֵ֣ן יִפְרֹ֑ץ וַיָּקֻ֕צוּ מִפְּנֵ֖י בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּֽעֲבִ֧דוּ מִצְרַ֛יִם אֶת־בְּנֵ֥י יִשְׂרָאֵ֖ל בְּפָֽרֶךְ׃"
          },
          {
            "num": "יד",
            "txt": "וַיְמָֽרְר֨וּ אֶת־חַיֵּיהֶ֜ם בַּֽעֲבֹדָ֣ה קָשָׁ֗ה בְּחֹ֨מֶר֙ וּבִלְבֵנִ֔ים וּבְכָל־עֲבֹדָ֖ה בַּשָּׂדֶ֑ה אֵ֚ת כָּל־עֲבֹ֣דָתָ֔ם אֲשֶׁר־עָֽבְד֥וּ בָהֶ֖ם בְּפָֽרֶךְ׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֨אמֶר֙ מֶ֣לֶךְ מִצְרַ֔יִם לַֽמְיַלְּדֹ֖ת הָֽעִבְרִיֹּ֑ת אֲשֶׁ֨ר שֵׁ֤ם הָֽאַחַת֙ שִׁפְרָ֔ה וְשֵׁ֥ם הַשֵּׁנִ֖ית פּוּעָֽה׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֗אמֶר בְּיַלֶּדְכֶן֙ אֶת־הָֽעִבְרִיּ֔וֹת וּרְאִיתֶ֖ן עַל־הָֽאָבְנָ֑יִם אִם־בֵּ֥ן הוּא֙ וַֽהֲמִתֶּ֣ן אֹת֔וֹ וְאִם־בַּ֥ת הִ֖וא וָחָֽיָה׃"
          },
          {
            "num": "יז",
            "txt": "וַתִּירֶ֤אןָ הַֽמְיַלְּדֹת֙ אֶת־הָ֣אֱלֹהִ֔ים וְלֹ֣א עָשׂ֔וּ כַּֽאֲשֶׁ֛ר דִּבֶּ֥ר אֲלֵיהֶ֖ן מֶ֣לֶךְ מִצְרָ֑יִם וַתְּחַיֶּ֖יןָ אֶת־הַיְלָדִֽים׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּקְרָ֤א מֶֽלֶךְ־מִצְרַ֨יִם֙ לַֽמְיַלְּדֹ֔ת וַיֹּ֣אמֶר לָהֶ֔ן מַדּ֥וּעַ עֲשִׂיתֶ֖ן הַדָּבָ֣ר הַזֶּ֑ה וַתְּחַיֶּ֖יןָ אֶת־הַיְלָדִֽים׃"
          },
          {
            "num": "יט",
            "txt": "וַתֹּאמַ֤רְןָ הַֽמְיַלְּדֹת֙ אֶל־פַּרְעֹ֔ה כִּ֣י לֹ֧א כַנָּשִׁ֛ים הַמִּצְרִיֹּ֖ת הָֽעִבְרִיֹּ֑ת כִּֽי־חָי֣וֹת הֵ֔נָּה בְּטֶ֨רֶם תָּב֧וֹא אֲלֵהֶ֛ן הַמְיַלֶּ֖דֶת וְיָלָֽדוּ׃"
          },
          {
            "num": "כ",
            "txt": "וַיֵּ֥יטֶב אֱלֹהִ֖ים לַֽמְיַלְּדֹ֑ת וַיִּ֧רֶב הָעָ֛ם וַיַּֽעַצְמ֖וּ מְאֹֽד׃"
          },
          {
            "num": "כא",
            "txt": "וַיְהִ֕י כִּֽי־יָרְא֥וּ הַֽמְיַלְּדֹ֖ת אֶת־הָֽאֱלֹהִ֑ים וַיַּ֥עַשׂ לָהֶ֖ם בָּתִּֽים׃"
          },
          {
            "num": "כב",
            "txt": "וַיְצַ֣ו פַּרְעֹ֔ה לְכָל־עַמּ֖וֹ לֵאמֹ֑ר כָּל־הַבֵּ֣ן הַיִּלּ֗וֹד הַיְאֹ֨רָה֙ תַּשְׁלִיכֻ֔הוּ וְכָל־הַבַּ֖ת תְּחַיּֽוּן׃ {פ}"
          }
        ]
      },
      {
        "num": "ב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֵּ֥לֶךְ אִ֖ישׁ מִבֵּ֣ית לֵוִ֑י וַיִּקַּ֖ח אֶת־בַּת־לֵוִֽי׃"
          },
          {
            "num": "ב",
            "txt": "וַתַּ֥הַר הָֽאִשָּׁ֖ה וַתֵּ֣לֶד בֵּ֑ן וַתֵּ֤רֶא אֹתוֹ֙ כִּי־ט֣וֹב ה֔וּא וַֽתִּצְפְּנֵ֖הוּ שְׁלֹשָׁ֥ה יְרָחִֽים׃"
          },
          {
            "num": "ג",
            "txt": "וְלֹֽא־יָכְלָ֣ה עוֹד֮ הַצְּפִינוֹ֒ וַתִּֽקַּֽח־לוֹ֙ תֵּ֣בַת גֹּ֔מֶא וַתַּחְמְרָ֥ה בַֽחֵמָ֖ר וּבַזָּ֑פֶת וַתָּ֤שֶׂם בָּהּ֙ אֶת־הַיֶּ֔לֶד וַתָּ֥שֶׂם בַּסּ֖וּף עַל־שְׂפַ֥ת הַיְאֹֽר׃"
          },
          {
            "num": "ד",
            "txt": "וַתֵּֽתַצַּ֥ב אֲחֹת֖וֹ מֵֽרָחֹ֑ק לְדֵעָ֕ה מַה־יֵּֽעָשֶׂ֖ה לֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "וַתֵּ֤רֶד בַּת־פַּרְעֹה֙ לִרְחֹ֣ץ עַל־הַיְאֹ֔ר וְנַֽעֲרֹתֶ֥יהָ הֹֽלְכֹ֖ת עַל־יַ֣ד הַיְאֹ֑ר וַתֵּ֤רֶא אֶת־הַתֵּבָה֙ בְּת֣וֹךְ הַסּ֔וּף וַתִּשְׁלַ֥ח אֶת־אֲמָתָ֖הּ וַתִּקָּחֶֽהָ׃"
          },
          {
            "num": "ו",
            "txt": "וַתִּפְתַּח֙ וַתִּרְאֵ֣הוּ אֶת־הַיֶּ֔לֶד וְהִנֵּה־נַ֖עַר בֹּכֶ֑ה וַתַּחְמֹ֣ל עָלָ֔יו וַתֹּ֕אמֶר מִיַּלְדֵ֥י הָֽעִבְרִ֖ים זֶֽה׃"
          },
          {
            "num": "ז",
            "txt": "וַתֹּ֣אמֶר אֲחֹתוֹ֮ אֶל־בַּת־פַּרְעֹה֒ הַֽאֵלֵ֗ךְ וְקָרָ֤אתִי לָךְ֙ אִשָּׁ֣ה מֵינֶ֔קֶת מִ֖ן הָֽעִבְרִיֹּ֑ת וְתֵינִ֥ק לָ֖ךְ אֶת־הַיָּֽלֶד׃"
          },
          {
            "num": "ח",
            "txt": "וַתֹּֽאמֶר־לָ֥הּ בַּת־פַּרְעֹ֖ה לֵ֑כִי וַתֵּ֨לֶךְ֙ הָֽעַלְמָ֔ה וַתִּקְרָ֖א אֶת־אֵ֥ם הַיָּֽלֶד׃"
          },
          {
            "num": "ט",
            "txt": "וַתֹּ֧אמֶר לָ֣הּ בַּת־פַּרְעֹ֗ה הֵילִ֜יכִי אֶת־הַיֶּ֤לֶד הַזֶּה֙ וְהֵֽינִקִ֣הוּ לִ֔י וַֽאֲנִ֖י אֶתֵּ֣ן אֶת־שְׂכָרֵ֑ךְ וַתִּקַּ֧ח הָֽאִשָּׁ֛ה הַיֶּ֖לֶד וַתְּנִיקֵֽהוּ׃"
          },
          {
            "num": "י",
            "txt": "וַיִּגְדַּ֣ל הַיֶּ֗לֶד וַתְּבִאֵ֨הוּ֙ לְבַת־פַּרְעֹ֔ה וַֽיְהִי־לָ֖הּ לְבֵ֑ן וַתִּקְרָ֤א שְׁמוֹ֙ מֹשֶׁ֔ה וַתֹּ֕אמֶר כִּ֥י מִן־הַמַּ֖יִם מְשִׁיתִֽהוּ׃"
          },
          {
            "num": "יא",
            "txt": "וַיְהִ֣י ׀ בַּיָּמִ֣ים הָהֵ֗ם וַיִּגְדַּ֤ל מֹשֶׁה֙ וַיֵּצֵ֣א אֶל־אֶחָ֔יו וַיַּ֖רְא בְּסִבְלֹתָ֑ם וַיַּרְא֙ אִ֣ישׁ מִצְרִ֔י מַכֶּ֥ה אִישׁ־עִבְרִ֖י מֵֽאֶחָֽיו׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּ֤פֶן כֹּה֙ וָכֹ֔ה וַיַּ֖רְא כִּ֣י אֵ֣ין אִ֑ישׁ וַיַּךְ֙ אֶת־הַמִּצְרִ֔י וַֽיִּטְמְנֵ֖הוּ בַּחֽוֹל׃"
          },
          {
            "num": "יג",
            "txt": "וַיֵּצֵא֙ בַּיּ֣וֹם הַשֵּׁנִ֔י וְהִנֵּ֛ה שְׁנֵֽי־אֲנָשִׁ֥ים עִבְרִ֖ים נִצִּ֑ים וַיֹּ֨אמֶר֙ לָֽרָשָׁ֔ע לָ֥מָּה תַכֶּ֖ה רֵעֶֽךָ׃"
          },
          {
            "num": "יד",
            "txt": "וַ֠יֹּאמֶר מִ֣י שָֽׂמְךָ֞ לְאִ֨ישׁ שַׂ֤ר וְשֹׁפֵט֙ עָלֵ֔ינוּ הַלְהָרְגֵ֨נִי֙ אַתָּ֣ה אֹמֵ֔ר כַּֽאֲשֶׁ֥ר הָרַ֖גְתָּ אֶת־הַמִּצְרִ֑י וַיִּירָ֤א מֹשֶׁה֙ וַיֹּאמַ֔ר אָכֵ֖ן נוֹדַ֥ע הַדָּבָֽר׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּשְׁמַ֤ע פַּרְעֹה֙ אֶת־הַדָּבָ֣ר הַזֶּ֔ה וַיְבַקֵּ֖שׁ לַֽהֲרֹ֣ג אֶת־מֹשֶׁ֑ה וַיִּבְרַ֤ח מֹשֶׁה֙ מִפְּנֵ֣י פַרְעֹ֔ה וַיֵּ֥שֶׁב בְּאֶֽרֶץ־מִדְיָ֖ן וַיֵּ֥שֶׁב עַֽל־הַבְּאֵֽר׃"
          },
          {
            "num": "טז",
            "txt": "וּלְכֹהֵ֥ן מִדְיָ֖ן שֶׁ֣בַע בָּנ֑וֹת וַתָּבֹ֣אנָה וַתִּדְלֶ֗נָה וַתְּמַלֶּ֨אנָה֙ אֶת־הָ֣רְהָטִ֔ים לְהַשְׁק֖וֹת צֹ֥אן אֲבִיהֶֽן׃"
          },
          {
            "num": "יז",
            "txt": "וַיָּבֹ֥אוּ הָֽרֹעִ֖ים וַיְגָֽרְשׁ֑וּם וַיָּ֤קָם מֹשֶׁה֙ וַיּ֣וֹשִׁעָ֔ן וַיַּ֖שְׁקְ אֶת־צֹאנָֽם׃"
          },
          {
            "num": "יח",
            "txt": "וַתָּבֹ֕אנָה אֶל־רְעוּאֵ֖ל אֲבִיהֶ֑ן וַיֹּ֕אמֶר מַדּ֛וּעַ מִֽהַרְתֶּ֥ן בֹּ֖א הַיּֽוֹם׃"
          },
          {
            "num": "יט",
            "txt": "וַתֹּאמַ֕רְןָ אִ֣ישׁ מִצְרִ֔י הִצִּילָ֖נוּ מִיַּ֣ד הָֽרֹעִ֑ים וְגַם־דָּלֹ֤ה דָלָה֙ לָ֔נוּ וַיַּ֖שְׁקְ אֶת־הַצֹּֽאן׃"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֥אמֶר אֶל־בְּנֹתָ֖יו וְאַיּ֑וֹ לָ֤מָּה זֶּה֙ עֲזַבְתֶּ֣ן אֶת־הָאִ֔ישׁ קִרְאֶ֥ן ל֖וֹ וְיֹ֥אכַל לָֽחֶם׃"
          },
          {
            "num": "כא",
            "txt": "וַיּ֥וֹאֶל מֹשֶׁ֖ה לָשֶׁ֣בֶת אֶת־הָאִ֑ישׁ וַיִּתֵּ֛ן אֶת־צִפֹּרָ֥ה בִתּ֖וֹ לְמֹשֶֽׁה׃"
          },
          {
            "num": "כב",
            "txt": "וַתֵּ֣לֶד בֵּ֔ן וַיִּקְרָ֥א אֶת־שְׁמ֖וֹ גֵּֽרְשֹׁ֑ם כִּ֣י אָמַ֔ר גֵּ֣ר הָיִ֔יתִי בְּאֶ֖רֶץ נָכְרִיָּֽה׃ {פ}"
          },
          {
            "num": "כג",
            "txt": "וַיְהִי֩ בַיָּמִ֨ים הָֽרַבִּ֜ים הָהֵ֗ם וַיָּ֨מָת֙ מֶ֣לֶךְ מִצְרַ֔יִם וַיֵּאָֽנְח֧וּ בְנֵֽי־יִשְׂרָאֵ֛ל מִן־הָֽעֲבֹדָ֖ה וַיִּזְעָ֑קוּ וַתַּ֧עַל שַׁוְעָתָ֛ם אֶל־הָֽאֱלֹהִ֖ים מִן־הָֽעֲבֹדָֽה׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּשְׁמַ֥ע אֱלֹהִ֖ים אֶת־נַֽאֲקָתָ֑ם וַיִּזְכֹּ֤ר אֱלֹהִים֙ אֶת־בְּרִית֔וֹ אֶת־אַבְרָהָ֖ם אֶת־יִצְחָ֥ק וְאֶֽת־יַעֲקֹֽב׃"
          },
          {
            "num": "כה",
            "txt": "וַיַּ֥רְא אֱלֹהִ֖ים אֶת־בְּנֵ֣י יִשְׂרָאֵ֑ל וַיֵּ֖דַע אֱלֹהִֽים׃ {ס}"
          }
        ]
      },
      {
        "num": "ג",
        "verses": [
          {
            "num": "א",
            "txt": "וּמֹשֶׁ֗ה הָיָ֥ה רֹעֶ֛ה אֶת־צֹ֛אן יִתְר֥וֹ חֹֽתְנ֖וֹ כֹּהֵ֣ן מִדְיָ֑ן וַיִּנְהַ֤ג אֶת־הַצֹּאן֙ אַחַ֣ר הַמִּדְבָּ֔ר וַיָּבֹ֛א אֶל־הַ֥ר הָֽאֱלֹהִ֖ים חֹרֵֽבָה׃"
          },
          {
            "num": "ב",
            "txt": "וַ֠יֵּרָא מַלְאַ֨ךְ יְהוָ֥ה אֵלָ֛יו בְּלַבַּת־אֵ֖שׁ מִתּ֣וֹךְ הַסְּנֶ֑ה וַיַּ֗רְא וְהִנֵּ֤ה הַסְּנֶה֙ בֹּעֵ֣ר בָּאֵ֔שׁ וְהַסְּנֶ֖ה אֵינֶ֥נּוּ אֻכָּֽל׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה אָסֻֽרָה־נָּ֣א וְאֶרְאֶ֔ה אֶת־הַמַּרְאֶ֥ה הַגָּדֹ֖ל הַזֶּ֑ה מַדּ֖וּעַ לֹֽא־יִבְעַ֥ר הַסְּנֶֽה׃"
          },
          {
            "num": "ד",
            "txt": "וַיַּ֥רְא יְהוָ֖ה כִּ֣י סָ֣ר לִרְא֑וֹת וַיִּקְרָא֩ אֵלָ֨יו אֱלֹהִ֜ים מִתּ֣וֹךְ הַסְּנֶ֗ה וַיֹּ֛אמֶר מֹשֶׁ֥ה מֹשֶׁ֖ה וַיֹּ֥אמֶר הִנֵּֽנִי׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֖אמֶר אַל־תִּקְרַ֣ב הֲלֹ֑ם שַׁל־נְעָלֶ֨יךָ֙ מֵעַ֣ל רַגְלֶ֔יךָ כִּ֣י הַמָּק֗וֹם אֲשֶׁ֤ר אַתָּה֙ עוֹמֵ֣ד עָלָ֔יו אַדְמַת־קֹ֖דֶשׁ הֽוּא׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֗אמֶר אָֽנֹכִי֙ אֱלֹהֵ֣י אָבִ֔יךָ אֱלֹהֵ֧י אַבְרָהָ֛ם אֱלֹהֵ֥י יִצְחָ֖ק וֵֽאלֹהֵ֣י יַֽעֲקֹ֑ב וַיַּסְתֵּ֤ר מֹשֶׁה֙ פָּנָ֔יו כִּ֣י יָרֵ֔א מֵֽהַבִּ֖יט אֶל־הָֽאֱלֹהִֽים׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֣אמֶר יְהוָ֔ה רָאֹ֥ה רָאִ֛יתִי אֶת־עֳנִ֥י עַמִּ֖י אֲשֶׁ֣ר בְּמִצְרָ֑יִם וְאֶת־צַֽעֲקָתָ֤ם שָׁמַ֨עְתִּי֙ מִפְּנֵ֣י נֹֽגְשָׂ֔יו כִּ֥י יָדַ֖עְתִּי אֶת־מַכְאֹבָֽיו׃"
          },
          {
            "num": "ח",
            "txt": "וָֽאֵרֵ֞ד לְהַצִּיל֣וֹ ׀ מִיַּ֣ד מִצְרַ֗יִם וּֽלְהַעֲלֹתוֹ֮ מִן־הָאָ֣רֶץ הַהִוא֒ אֶל־אֶ֤רֶץ טוֹבָה֙ וּרְחָבָ֔ה אֶל־אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָ֑שׁ אֶל־מְק֤וֹם הַֽכְּנַעֲנִי֙ וְהַ֣חִתִּ֔י וְהָֽאֱמֹרִי֙ וְהַפְּרִזִּ֔י וְהַֽחִוִּ֖י וְהַיְבוּסִֽי׃"
          },
          {
            "num": "ט",
            "txt": "וְעַתָּ֕ה הִנֵּ֛ה צַֽעֲקַ֥ת בְּנֵֽי־יִשְׂרָאֵ֖ל בָּ֣אָה אֵלָ֑י וְגַם־רָאִ֨יתִי֙ אֶת־הַלַּ֔חַץ אֲשֶׁ֥ר מִצְרַ֖יִם לֹֽחֲצִ֥ים אֹתָֽם׃"
          },
          {
            "num": "י",
            "txt": "וְעַתָּ֣ה לְכָ֔ה וְאֶֽשְׁלָחֲךָ֖ אֶל־פַּרְעֹ֑ה וְהוֹצֵ֛א אֶת־עַמִּ֥י בְנֵֽי־יִשְׂרָאֵ֖ל מִמִּצְרָֽיִם׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֤אמֶר מֹשֶׁה֙ אֶל־הָ֣אֱלֹהִ֔ים מִ֣י אָנֹ֔כִי כִּ֥י אֵלֵ֖ךְ אֶל־פַּרְעֹ֑ה וְכִ֥י אוֹצִ֛יא אֶת־בְּנֵ֥י יִשְׂרָאֵ֖ל מִמִּצְרָֽיִם׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֨אמֶר֙ כִּֽי־אֶֽהְיֶ֣ה עִמָּ֔ךְ וְזֶה־לְּךָ֣ הָא֔וֹת כִּ֥י אָֽנֹכִ֖י שְׁלַחְתִּ֑יךָ בְּהוֹצִֽיאֲךָ֤ אֶת־הָעָם֙ מִמִּצְרַ֔יִם תַּֽעַבְדוּן֙ אֶת־הָ֣אֱלֹהִ֔ים עַ֖ל הָהָ֥ר הַזֶּֽה׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶל־הָֽאֱלֹהִ֗ים הִנֵּ֨ה אָֽנֹכִ֣י בָא֮ אֶל־בְּנֵ֣י יִשְׂרָאֵל֒ וְאָֽמַרְתִּ֣י לָהֶ֔ם אֱלֹהֵ֥י אֲבֽוֹתֵיכֶ֖ם שְׁלָחַ֣נִי אֲלֵיכֶ֑ם וְאָֽמְרוּ־לִ֣י מַה־שְּׁמ֔וֹ מָ֥ה אֹמַ֖ר אֲלֵהֶֽם׃"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֤אמֶר אֱלֹהִים֙ אֶל־מֹשֶׁ֔ה אֶֽהְיֶ֖ה אֲשֶׁ֣ר אֶֽהְיֶ֑ה וַיֹּ֗אמֶר כֹּ֤ה תֹאמַר֙ לִבְנֵ֣י יִשְׂרָאֵ֔ל אֶֽהְיֶ֖ה שְׁלָחַ֥נִי אֲלֵיכֶֽם׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּאמֶר֩ ע֨וֹד אֱלֹהִ֜ים אֶל־מֹשֶׁ֗ה כֹּֽה־תֹאמַר֮ אֶל־בְּנֵ֣י יִשְׂרָאֵל֒ יְהוָ֞ה אֱלֹהֵ֣י אֲבֹֽתֵיכֶ֗ם אֱלֹהֵ֨י אַבְרָהָ֜ם אֱלֹהֵ֥י יִצְחָ֛ק וֵֽאלֹהֵ֥י יַֽעֲקֹ֖ב שְׁלָחַ֣נִי אֲלֵיכֶ֑ם זֶה־שְּׁמִ֣י לְעֹלָ֔ם וְזֶ֥ה זִכְרִ֖י לְדֹ֥ר דֹּֽר׃"
          },
          {
            "num": "טז",
            "txt": "לֵ֣ךְ וְאָֽסַפְתָּ֞ אֶת־זִקְנֵ֣י יִשְׂרָאֵ֗ל וְאָֽמַרְתָּ֤ אֲלֵהֶם֙ יְהוָ֞ה אֱלֹהֵ֤י אֲבֹֽתֵיכֶם֙ נִרְאָ֣ה אֵלַ֔י אֱלֹהֵ֧י אַבְרָהָ֛ם יִצְחָ֥ק וְיַֽעֲקֹ֖ב לֵאמֹ֑ר פָּקֹ֤ד פָּקַ֨דְתִּי֙ אֶתְכֶ֔ם וְאֶת־הֶֽעָשׂ֥וּי לָכֶ֖ם בְּמִצְרָֽיִם׃"
          },
          {
            "num": "יז",
            "txt": "וָֽאֹמַ֗ר אַֽעֲלֶ֣ה אֶתְכֶם֮ מֵֽעֳנִ֣י מִצְרַיִם֒ אֶל־אֶ֤רֶץ הַֽכְּנַעֲנִי֙ וְהַ֣חִתִּ֔י וְהָֽאֱמֹרִי֙ וְהַפְּרִזִּ֔י וְהַֽחִוִּ֖י וְהַיְבוּסִ֑י אֶל־אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָֽשׁ׃"
          },
          {
            "num": "יח",
            "txt": "וְשָֽׁמְע֖וּ לְקֹלֶ֑ךָ וּבָאתָ֡ אַתָּה֩ וְזִקְנֵ֨י יִשְׂרָאֵ֜ל אֶל־מֶ֣לֶךְ מִצְרַ֗יִם וַֽאֲמַרְתֶּ֤ם אֵלָיו֙ יְהוָ֞ה אֱלֹהֵ֤י הָֽעִבְרִיִּים֙ נִקְרָ֣ה עָלֵ֔ינוּ וְעַתָּ֗ה נֵֽלְכָה־נָּ֞א דֶּ֣רֶךְ שְׁלֹ֤שֶׁת יָמִים֙ בַּמִּדְבָּ֔ר וְנִזְבְּחָ֖ה לַֽיהוָ֥ה אֱלֹהֵֽינוּ׃"
          },
          {
            "num": "יט",
            "txt": "וַֽאֲנִ֣י יָדַ֔עְתִּי כִּ֠י לֹֽא־יִתֵּ֥ן אֶתְכֶ֛ם מֶ֥לֶךְ מִצְרַ֖יִם לַֽהֲלֹ֑ךְ וְלֹ֖א בְּיָ֥ד חֲזָקָֽה׃"
          },
          {
            "num": "כ",
            "txt": "וְשָֽׁלַחְתִּ֤י אֶת־יָדִי֙ וְהִכֵּיתִ֣י אֶת־מִצְרַ֔יִם בְּכֹל֙ נִפְלְאֹתַ֔י אֲשֶׁ֥ר אֶֽעֱשֶׂ֖ה בְּקִרְבּ֑וֹ וְאַֽחֲרֵי־כֵ֖ן יְשַׁלַּ֥ח אֶתְכֶֽם׃"
          },
          {
            "num": "כא",
            "txt": "וְנָֽתַתִּ֛י אֶת־חֵ֥ן הָֽעָם־הַזֶּ֖ה בְּעֵינֵ֣י מִצְרָ֑יִם וְהָיָה֙ כִּ֣י תֵֽלֵכ֔וּן לֹ֥א תֵֽלְכ֖וּ רֵיקָֽם׃"
          },
          {
            "num": "כב",
            "txt": "וְשָֽׁאֲלָ֨ה אִשָּׁ֤ה מִשְּׁכֶנְתָּהּ֙ וּמִגָּרַ֣ת בֵּיתָ֔הּ כְּלֵי־כֶ֛סֶף וּכְלֵ֥י זָהָ֖ב וּשְׂמָלֹ֑ת וְשַׂמְתֶּ֗ם עַל־בְּנֵיכֶם֙ וְעַל־בְּנֹ֣תֵיכֶ֔ם וְנִצַּלְתֶּ֖ם אֶת־מִצְרָֽיִם׃"
          }
        ]
      },
      {
        "num": "ד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּ֤עַן מֹשֶׁה֙ וַיֹּ֔אמֶר וְהֵן֙ לֹֽא־יַאֲמִ֣ינוּ לִ֔י וְלֹ֥א יִשְׁמְע֖וּ בְּקֹלִ֑י כִּ֣י יֹֽאמְר֔וּ לֹֽא־נִרְאָ֥ה אֵלֶ֖יךָ יְהוָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֧אמֶר אֵלָ֛יו יְהוָ֖ה מזה (מַה־זֶּ֣ה) בְיָדֶ֑ךָ וַיֹּ֖אמֶר מַטֶּֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֨אמֶר֙ הַשְׁלִיכֵ֣הוּ אַ֔רְצָה וַיַּשְׁלִכֵ֥הוּ אַ֖רְצָה וַיְהִ֣י לְנָחָ֑שׁ וַיָּ֥נָס מֹשֶׁ֖ה מִפָּנָֽיו׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה שְׁלַח֙ יָֽדְךָ֔ וֶֽאֱחֹ֖ז בִּזְנָב֑וֹ וַיִּשְׁלַ֤ח יָדוֹ֙ וַיַּ֣חֲזֶק בּ֔וֹ וַיְהִ֥י לְמַטֶּ֖ה בְּכַפּֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "לְמַ֣עַן יַֽאֲמִ֔ינוּ כִּֽי־נִרְאָ֥ה אֵלֶ֛יךָ יְהוָ֖ה אֱלֹהֵ֣י אֲבֹתָ֑ם אֱלֹהֵ֧י אַבְרָהָ֛ם אֱלֹהֵ֥י יִצְחָ֖ק וֵֽאלֹהֵ֥י יַֽעֲקֹֽב׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּאמֶר֩ יְהוָ֨ה ל֜וֹ ע֗וֹד הָֽבֵא־נָ֤א יָֽדְךָ֙ בְּחֵיקֶ֔ךָ וַיָּבֵ֥א יָד֖וֹ בְּחֵיק֑וֹ וַיּ֣וֹצִאָ֔הּ וְהִנֵּ֥ה יָד֖וֹ מְצֹרַ֥עַת כַּשָּֽׁלֶג׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֗אמֶר הָשֵׁ֤ב יָֽדְךָ֙ אֶל־חֵיקֶ֔ךָ וַיָּ֥שֶׁב יָד֖וֹ אֶל־חֵיק֑וֹ וַיּֽוֹצִאָהּ֙ מֵֽחֵיק֔וֹ וְהִנֵּה־שָׁ֖בָה כִּבְשָׂרֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וְהָיָה֙ אִם־לֹ֣א יַֽאֲמִ֣ינוּ לָ֔ךְ וְלֹ֣א יִשְׁמְע֔וּ לְקֹ֖ל הָאֹ֣ת הָֽרִאשׁ֑וֹן וְהֶֽאֱמִ֔ינוּ לְקֹ֖ל הָאֹ֥ת הָאַֽחֲרֽוֹן׃"
          },
          {
            "num": "ט",
            "txt": "וְהָיָ֡ה אִם־לֹ֣א יַֽאֲמִ֡ינוּ גַּם֩ לִשְׁנֵ֨י הָֽאֹת֜וֹת הָאֵ֗לֶּה וְלֹ֤א יִשְׁמְעוּן֙ לְקֹלֶ֔ךָ וְלָֽקַחְתָּ֙ מִמֵּימֵ֣י הַיְאֹ֔ר וְשָֽׁפַכְתָּ֖ הַיַּבָּשָׁ֑ה וְהָי֤וּ הַמַּ֨יִם֙ אֲשֶׁ֣ר תִּקַּ֣ח מִן־הַיְאֹ֔ר וְהָי֥וּ לְדָ֖ם בַּיַּבָּֽשֶׁת׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֣ה אֶל־יְהוָה֮ בִּ֣י אֲדֹנָי֒ לֹא֩ אִ֨ישׁ דְּבָרִ֜ים אָנֹ֗כִי גַּ֤ם מִתְּמוֹל֙ גַּ֣ם מִשִּׁלְשֹׁ֔ם גַּ֛ם מֵאָ֥ז דַּבֶּרְךָ֖ אֶל־עַבְדֶּ֑ךָ כִּ֧י כְבַד־פֶּ֛ה וּכְבַ֥ד לָשׁ֖וֹן אָנֹֽכִי׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֵלָ֗יו מִ֣י שָׂ֣ם פֶּה֮ לָֽאָדָם֒ א֚וֹ מִֽי־יָשׂ֣וּם אִלֵּ֔ם א֣וֹ חֵרֵ֔שׁ א֥וֹ פִקֵּ֖חַ א֣וֹ עִוֵּ֑ר הֲלֹ֥א אָֽנֹכִ֖י יְהוָֽה׃"
          },
          {
            "num": "יב",
            "txt": "וְעַתָּ֖ה לֵ֑ךְ וְאָֽנֹכִי֙ אֶֽהְיֶ֣ה עִם־פִּ֔יךָ וְהֽוֹרֵיתִ֖יךָ אֲשֶׁ֥ר תְּדַבֵּֽר׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֖אמֶר בִּ֣י אֲדֹנָ֑י שְֽׁלַֽח־נָ֖א בְּיַד־תִּשְׁלָֽח׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּֽחַר־אַ֨ף יְהוָ֜ה בְּמֹשֶׁ֗ה וַיֹּ֨אמֶר֙ הֲלֹ֨א אַֽהֲרֹ֤ן אָחִ֨יךָ֙ הַלֵּוִ֔י יָדַ֕עְתִּי כִּֽי־דַבֵּ֥ר יְדַבֵּ֖ר ה֑וּא וְגַ֤ם הִנֵּה־הוּא֙ יֹצֵ֣א לִקְרָאתֶ֔ךָ וְרָֽאֲךָ֖ וְשָׂמַ֥ח בְּלִבּֽוֹ׃"
          },
          {
            "num": "טו",
            "txt": "וְדִבַּרְתָּ֣ אֵלָ֔יו וְשַׂמְתָּ֥ אֶת־הַדְּבָרִ֖ים בְּפִ֑יו וְאָֽנֹכִ֗י אֶֽהְיֶ֤ה עִם־פִּ֨יךָ֙ וְעִם־פִּ֔יהוּ וְהֽוֹרֵיתִ֣י אֶתְכֶ֔ם אֵ֖ת אֲשֶׁ֥ר תַּֽעֲשֽׂוּן׃"
          },
          {
            "num": "טז",
            "txt": "וְדִבֶּר־ה֥וּא לְךָ֖ אֶל־הָעָ֑ם וְהָ֤יָה הוּא֙ יִֽהְיֶה־לְּךָ֣ לְפֶ֔ה וְאַתָּ֖ה תִּֽהְיֶה־לּ֥וֹ לֵֽאלֹהִֽים׃"
          },
          {
            "num": "יז",
            "txt": "וְאֶת־הַמַּטֶּ֥ה הַזֶּ֖ה תִּקַּ֣ח בְּיָדֶ֑ךָ אֲשֶׁ֥ר תַּֽעֲשֶׂה־בּ֖וֹ אֶת־הָֽאֹתֹֽת׃ {פ}"
          },
          {
            "num": "יח",
            "txt": "וַיֵּ֨לֶךְ מֹשֶׁ֜ה וַיָּ֣שָׁב ׀ אֶל־יֶ֣תֶר חֹֽתְנ֗וֹ וַיֹּ֤אמֶר לוֹ֙ אֵ֣לְכָה נָּ֗א וְאָשׁ֨וּבָה֙ אֶל־אַחַ֣י אֲשֶׁר־בְּמִצְרַ֔יִם וְאֶרְאֶ֖ה הַֽעוֹדָ֣ם חַיִּ֑ים וַיֹּ֧אמֶר יִתְר֛וֹ לְמֹשֶׁ֖ה לֵ֥ךְ לְשָׁלֽוֹם׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֨אמֶר יְהוָ֤ה אֶל־מֹשֶׁה֙ בְּמִדְיָ֔ן לֵ֖ךְ שֻׁ֣ב מִצְרָ֑יִם כִּי־מֵ֨תוּ֙ כָּל־הָ֣אֲנָשִׁ֔ים הַֽמְבַקְשִׁ֖ים אֶת־נַפְשֶֽׁךָ׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּקַּ֨ח מֹשֶׁ֜ה אֶת־אִשְׁתּ֣וֹ וְאֶת־בָּנָ֗יו וַיַּרְכִּבֵם֙ עַֽל־הַחֲמֹ֔ר וַיָּ֖שָׁב אַ֣רְצָה מִצְרָ֑יִם וַיִּקַּ֥ח מֹשֶׁ֛ה אֶת־מַטֵּ֥ה הָֽאֱלֹהִ֖ים בְּיָדֽוֹ׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֣אמֶר יְהוָה֮ אֶל־מֹשֶׁה֒ בְּלֶכְתְּךָ֙ לָשׁ֣וּב מִצְרַ֔יְמָה רְאֵ֗ה כָּל־הַמֹּֽפְתִים֙ אֲשֶׁר־שַׂ֣מְתִּי בְיָדֶ֔ךָ וַֽעֲשִׂיתָ֖ם לִפְנֵ֣י פַרְעֹ֑ה וַֽאֲנִי֙ אֲחַזֵּ֣ק אֶת־לִבּ֔וֹ וְלֹ֥א יְשַׁלַּ֖ח אֶת־הָעָֽם׃"
          },
          {
            "num": "כב",
            "txt": "וְאָֽמַרְתָּ֖ אֶל־פַּרְעֹ֑ה כֹּ֚ה אָמַ֣ר יְהוָ֔ה בְּנִ֥י בְכֹרִ֖י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כג",
            "txt": "וָֽאֹמַ֣ר אֵלֶ֗יךָ שַׁלַּ֤ח אֶת־בְּנִי֙ וְיַֽעַבְדֵ֔נִי וַתְּמָאֵ֖ן לְשַׁלְּח֑וֹ הִנֵּה֙ אָֽנֹכִ֣י הֹרֵ֔ג אֶת־בִּנְךָ֖ בְּכֹרֶֽךָ׃"
          },
          {
            "num": "כד",
            "txt": "וַיְהִ֥י בַדֶּ֖רֶךְ בַּמָּל֑וֹן וַיִּפְגְּשֵׁ֣הוּ יְהוָ֔ה וַיְבַקֵּ֖שׁ הֲמִיתֽוֹ׃"
          },
          {
            "num": "כה",
            "txt": "וַתִּקַּ֨ח צִפֹּרָ֜ה צֹ֗ר וַתִּכְרֹת֙ אֶת־עָרְלַ֣ת בְּנָ֔הּ וַתַּגַּ֖ע לְרַגְלָ֑יו וַתֹּ֕אמֶר כִּ֧י חֲתַן־דָּמִ֛ים אַתָּ֖ה לִֽי׃"
          },
          {
            "num": "כו",
            "txt": "וַיִּ֖רֶף מִמֶּ֑נּוּ אָ֚ז אָֽמְרָ֔ה חֲתַ֥ן דָּמִ֖ים לַמּוּלֹֽת׃ {פ}"
          },
          {
            "num": "כז",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶֽל־אַהֲרֹ֔ן לֵ֛ךְ לִקְרַ֥את מֹשֶׁ֖ה הַמִּדְבָּ֑רָה וַיֵּ֗לֶךְ וַֽיִּפְגְּשֵׁ֛הוּ בְּהַ֥ר הָֽאֱלֹהִ֖ים וַיִּשַּׁק־לֽוֹ׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּגֵּ֤ד מֹשֶׁה֙ לְאַֽהֲרֹ֔ן אֵ֛ת כָּל־דִּבְרֵ֥י יְהוָ֖ה אֲשֶׁ֣ר שְׁלָח֑וֹ וְאֵ֥ת כָּל־הָֽאֹתֹ֖ת אֲשֶׁ֥ר צִוָּֽהוּ׃"
          },
          {
            "num": "כט",
            "txt": "וַיֵּ֥לֶךְ מֹשֶׁ֖ה וְאַֽהֲרֹ֑ן וַיַּ֣אַסְפ֔וּ אֶת־כָּל־זִקְנֵ֖י בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ל",
            "txt": "וַיְדַבֵּ֣ר אַֽהֲרֹ֔ן אֵ֚ת כָּל־הַדְּבָרִ֔ים אֲשֶׁר־דִּבֶּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֑ה וַיַּ֥עַשׂ הָֽאֹתֹ֖ת לְעֵינֵ֥י הָעָֽם׃"
          },
          {
            "num": "לא",
            "txt": "וַֽיַּאֲמֵ֖ן הָעָ֑ם וַֽיִּשְׁמְע֡וּ כִּֽי־פָקַ֨ד יְהוָ֜ה אֶת־בְּנֵ֣י יִשְׂרָאֵ֗ל וְכִ֤י רָאָה֙ אֶת־עָנְיָ֔ם וַֽיִּקְּד֖וּ וַיִּֽשְׁתַּחֲוֽוּ׃"
          }
        ]
      },
      {
        "num": "ה",
        "verses": [
          {
            "num": "א",
            "txt": "וְאַחַ֗ר בָּ֚אוּ מֹשֶׁ֣ה וְאַֽהֲרֹ֔ן וַיֹּֽאמְר֖וּ אֶל־פַּרְעֹ֑ה כֹּֽה־אָמַ֤ר יְהוָה֙ אֱלֹהֵ֣י יִשְׂרָאֵ֔ל שַׁלַּח֙ אֶת־עַמִּ֔י וְיָחֹ֥גּוּ לִ֖י בַּמִּדְבָּֽר׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֣אמֶר פַּרְעֹ֔ה מִ֤י יְהוָה֙ אֲשֶׁ֣ר אֶשְׁמַ֣ע בְּקֹל֔וֹ לְשַׁלַּ֖ח אֶת־יִשְׂרָאֵ֑ל לֹ֤א יָדַ֨עְתִּי֙ אֶת־יְהוָ֔ה וְגַ֥ם אֶת־יִשְׂרָאֵ֖ל לֹ֥א אֲשַׁלֵּֽחַ׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֣אמְר֔וּ אֱלֹהֵ֥י הָֽעִבְרִ֖ים נִקְרָ֣א עָלֵ֑ינוּ נֵ֣לְכָה נָּ֡א דֶּרֶךְ֩ שְׁלֹ֨שֶׁת יָמִ֜ים בַּמִּדְבָּ֗ר וְנִזְבְּחָה֙ לַֽיהוָ֣ה אֱלֹהֵ֔ינוּ פֶּ֨ן־יִפְגָּעֵ֔נוּ בַּדֶּ֖בֶר א֥וֹ בֶחָֽרֶב׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֤אמֶר אֲלֵהֶם֙ מֶ֣לֶךְ מִצְרַ֔יִם לָ֚מָּה מֹשֶׁ֣ה וְאַֽהֲרֹ֔ן תַּפְרִ֥יעוּ אֶת־הָעָ֖ם מִמַּֽעֲשָׂ֑יו לְכ֖וּ לְסִבְלֹֽתֵיכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֣אמֶר פַּרְעֹ֔ה הֵן־רַבִּ֥ים עַתָּ֖ה עַ֣ם הָאָ֑רֶץ וְהִשְׁבַּתֶּ֥ם אֹתָ֖ם מִסִּבְלֹתָֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַיְצַ֥ו פַּרְעֹ֖ה בַּיּ֣וֹם הַה֑וּא אֶת־הַנֹּֽגְשִׂ֣ים בָּעָ֔ם וְאֶת־שֹֽׁטְרָ֖יו לֵאמֹֽר׃"
          },
          {
            "num": "ז",
            "txt": "לֹ֣א תֹֽאסִפ֞וּן לָתֵ֨ת תֶּ֧בֶן לָעָ֛ם לִלְבֹּ֥ן הַלְּבֵנִ֖ים כִּתְמ֣וֹל שִׁלְשֹׁ֑ם הֵ֚ם יֵֽלְכ֔וּ וְקֹֽשְׁשׁ֥וּ לָהֶ֖ם תֶּֽבֶן׃"
          },
          {
            "num": "ח",
            "txt": "וְאֶת־מַתְכֹּ֨נֶת הַלְּבֵנִ֜ים אֲשֶׁ֣ר הֵם֩ עֹשִׂ֨ים תְּמ֤וֹל שִׁלְשֹׁם֙ תָּשִׂ֣ימוּ עֲלֵיהֶ֔ם לֹ֥א תִגְרְע֖וּ מִמֶּ֑נּוּ כִּֽי־נִרְפִּ֣ים הֵ֔ם עַל־כֵּ֗ן הֵ֤ם צֹֽעֲקִים֙ לֵאמֹ֔ר נֵֽלְכָ֖ה נִזְבְּחָ֥ה לֵֽאלֹהֵֽינוּ׃"
          },
          {
            "num": "ט",
            "txt": "תִּכְבַּ֧ד הָֽעֲבֹדָ֛ה עַל־הָֽאֲנָשִׁ֖ים וְיַֽעֲשׂוּ־בָ֑הּ וְאַל־יִשְׁע֖וּ בְּדִבְרֵי־שָֽׁקֶר׃"
          },
          {
            "num": "י",
            "txt": "וַיֵּ֨צְא֜וּ נֹֽגְשֵׂ֤י הָעָם֙ וְשֹׁ֣טְרָ֔יו וַיֹּֽאמְר֥וּ אֶל־הָעָ֖ם לֵאמֹ֑ר כֹּ֚ה אָמַ֣ר פַּרְעֹ֔ה אֵינֶ֛נִּי נֹתֵ֥ן לָכֶ֖ם תֶּֽבֶן׃"
          },
          {
            "num": "יא",
            "txt": "אַתֶּ֗ם לְכ֨וּ קְח֤וּ לָכֶם֙ תֶּ֔בֶן מֵֽאֲשֶׁ֖ר תִּמְצָ֑אוּ כִּ֣י אֵ֥ין נִגְרָ֛ע מֵעֲבֹֽדַתְכֶ֖ם דָּבָֽר׃"
          },
          {
            "num": "יב",
            "txt": "וַיָּ֥פֶץ הָעָ֖ם בְּכָל־אֶ֣רֶץ מִצְרָ֑יִם לְקֹשֵׁ֥שׁ קַ֖שׁ לַתֶּֽבֶן׃"
          },
          {
            "num": "יג",
            "txt": "וְהַנֹּֽגְשִׂ֖ים אָצִ֣ים לֵאמֹ֑ר כַּלּ֤וּ מַֽעֲשֵׂיכֶם֙ דְּבַר־י֣וֹם בְּיוֹמ֔וֹ כַּֽאֲשֶׁ֖ר בִּֽהְי֥וֹת הַתֶּֽבֶן׃"
          },
          {
            "num": "יד",
            "txt": "וַיֻּכּ֗וּ שֹֽׁטְרֵי֙ בְּנֵ֣י יִשְׂרָאֵ֔ל אֲשֶׁר־שָׂ֣מוּ עֲלֵהֶ֔ם נֹֽגְשֵׂ֥י פַרְעֹ֖ה לֵאמֹ֑ר מַדּ֡וּעַ לֹא֩ כִלִּיתֶ֨ם חָקְכֶ֤ם לִלְבֹּן֙ כִּתְמ֣וֹל שִׁלְשֹׁ֔ם גַּם־תְּמ֖וֹל גַּם־הַיּֽוֹם׃"
          },
          {
            "num": "טו",
            "txt": "וַיָּבֹ֗אוּ שֹֽׁטְרֵי֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וַיִּצְעֲק֥וּ אֶל־פַּרְעֹ֖ה לֵאמֹ֑ר לָ֧מָּה תַֽעֲשֶׂ֦ה כֹ֖ה לַֽעֲבָדֶֽיךָ׃"
          },
          {
            "num": "טז",
            "txt": "תֶּ֗בֶן אֵ֤ין נִתָּן֙ לַֽעֲבָדֶ֔יךָ וּלְבֵנִ֛ים אֹֽמְרִ֥ים לָ֖נוּ עֲשׂ֑וּ וְהִנֵּ֧ה עֲבָדֶ֛יךָ מֻכִּ֖ים וְחָטָ֥את עַמֶּֽךָ׃"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֛אמֶר נִרְפִּ֥ים אַתֶּ֖ם נִרְפִּ֑ים עַל־כֵּן֙ אַתֶּ֣ם אֹֽמְרִ֔ים נֵֽלְכָ֖ה נִזְבְּחָ֥ה לַֽיהוָֽה׃"
          },
          {
            "num": "יח",
            "txt": "וְעַתָּה֙ לְכ֣וּ עִבְד֔וּ וְתֶ֖בֶן לֹֽא־יִנָּתֵ֣ן לָכֶ֑ם וְתֹ֥כֶן לְבֵנִ֖ים תִּתֵּֽנוּ׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּרְא֞וּ שֹֽׁטְרֵ֧י בְנֵֽי־יִשְׂרָאֵ֛ל אֹתָ֖ם בְּרָ֣ע לֵאמֹ֑ר לֹֽא־תִגְרְע֥וּ מִלִּבְנֵיכֶ֖ם דְּבַר־י֥וֹם בְּיוֹמֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וַֽיִּפְגְּעוּ֙ אֶת־מֹשֶׁ֣ה וְאֶֽת־אַהֲרֹ֔ן נִצָּבִ֖ים לִקְרָאתָ֑ם בְּצֵאתָ֖ם מֵאֵ֥ת פַּרְעֹֽה׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּֽאמְר֣וּ אֲלֵהֶ֔ם יֵ֧רֶא יְהוָ֛ה עֲלֵיכֶ֖ם וְיִשְׁפֹּ֑ט אֲשֶׁ֧ר הִבְאַשְׁתֶּ֣ם אֶת־רֵיחֵ֗נוּ בְּעֵינֵ֤י פַרְעֹה֙ וּבְעֵינֵ֣י עֲבָדָ֔יו לָֽתֶת־חֶ֥רֶב בְּיָדָ֖ם לְהָרְגֵֽנוּ׃"
          },
          {
            "num": "כב",
            "txt": "וַיָּ֧שָׁב מֹשֶׁ֛ה אֶל־יְהוָ֖ה וַיֹּאמַ֑ר אֲדֹנָ֗י לָמָ֤ה הֲרֵעֹ֨תָה֙ לָעָ֣ם הַזֶּ֔ה לָ֥מָּה זֶּ֖ה שְׁלַחְתָּֽנִי׃"
          },
          {
            "num": "כג",
            "txt": "וּמֵאָ֞ז בָּ֤אתִי אֶל־פַּרְעֹה֙ לְדַבֵּ֣ר בִּשְׁמֶ֔ךָ הֵרַ֖ע לָעָ֣ם הַזֶּ֑ה וְהַצֵּ֥ל לֹֽא־הִצַּ֖לְתָּ אֶת־עַמֶּֽךָ׃"
          }
        ]
      },
      {
        "num": "ו",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה עַתָּ֣ה תִרְאֶ֔ה אֲשֶׁ֥ר אֶֽעֱשֶׂ֖ה לְפַרְעֹ֑ה כִּ֣י בְיָ֤ד חֲזָקָה֙ יְשַׁלְּחֵ֔ם וּבְיָ֣ד חֲזָקָ֔ה יְגָֽרְשֵׁ֖ם מֵֽאַרְצֽוֹ׃ {ס}"
          },
          {
            "num": "ב",
            "txt": "וַיְדַבֵּ֥ר אֱלֹהִ֖ים אֶל־מֹשֶׁ֑ה וַיֹּ֥אמֶר אֵלָ֖יו אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וָֽאֵרָ֗א אֶל־אַבְרָהָ֛ם אֶל־יִצְחָ֥ק וְאֶֽל־יַעֲקֹ֖ב בְּאֵ֣ל שַׁדָּ֑י וּשְׁמִ֣י יְהוָ֔ה לֹ֥א נוֹדַ֖עְתִּי לָהֶֽם׃"
          },
          {
            "num": "ד",
            "txt": "וְגַ֨ם הֲקִמֹ֤תִי אֶת־בְּרִיתִי֙ אִתָּ֔ם לָתֵ֥ת לָהֶ֖ם אֶת־אֶ֣רֶץ כְּנָ֑עַן אֵ֛ת אֶ֥רֶץ מְגֻֽרֵיהֶ֖ם אֲשֶׁר־גָּ֥רוּ בָֽהּ׃"
          },
          {
            "num": "ה",
            "txt": "וְגַ֣ם ׀ אֲנִ֣י שָׁמַ֗עְתִּי אֶֽת־נַאֲקַת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל אֲשֶׁ֥ר מִצְרַ֖יִם מַֽעֲבִדִ֣ים אֹתָ֑ם וָֽאֶזְכֹּ֖ר אֶת־בְּרִיתִֽי׃"
          },
          {
            "num": "ו",
            "txt": "לָכֵ֞ן אֱמֹ֥ר לִבְנֵֽי־יִשְׂרָאֵל֮ אֲנִ֣י יְהוָה֒ וְהֽוֹצֵאתִ֣י אֶתְכֶ֗ם מִתַּ֨חַת֙ סִבְלֹ֣ת מִצְרַ֔יִם וְהִצַּלְתִּ֥י אֶתְכֶ֖ם מֵעֲבֹֽדָתָ֑ם וְגָֽאַלְתִּ֤י אֶתְכֶם֙ בִּזְר֣וֹעַ נְטוּיָ֔ה וּבִשְׁפָטִ֖ים גְּדֹלִֽים׃"
          },
          {
            "num": "ז",
            "txt": "וְלָֽקַחְתִּ֨י אֶתְכֶ֥ם לִי֙ לְעָ֔ם וְהָיִ֥יתִי לָכֶ֖ם לֵֽאלֹהִ֑ים וִֽידַעְתֶּ֗ם כִּ֣י אֲנִ֤י יְהוָה֙ אֱלֹ֣הֵיכֶ֔ם הַמּוֹצִ֣יא אֶתְכֶ֔ם מִתַּ֖חַת סִבְל֥וֹת מִצְרָֽיִם׃"
          },
          {
            "num": "ח",
            "txt": "וְהֵֽבֵאתִ֤י אֶתְכֶם֙ אֶל־הָאָ֔רֶץ אֲשֶׁ֤ר נָשָׂ֨אתִי֙ אֶת־יָדִ֔י לָתֵ֣ת אֹתָ֔הּ לְאַבְרָהָ֥ם לְיִצְחָ֖ק וּֽלְיַעֲקֹ֑ב וְנָֽתַתִּ֨י אֹתָ֥הּ לָכֶ֛ם מֽוֹרָשָׁ֖ה אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיְדַבֵּ֥ר מֹשֶׁ֛ה כֵּ֖ן אֶל־בְּנֵ֣י יִשְׂרָאֵ֑ל וְלֹ֤א שָֽׁמְעוּ֙ אֶל־מֹשֶׁ֔ה מִקֹּ֣צֶר ר֔וּחַ וּמֵֽעֲבֹדָ֖ה קָשָֽׁה׃ {פ}"
          },
          {
            "num": "י",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יא",
            "txt": "בֹּ֣א דַבֵּ֔ר אֶל־פַּרְעֹ֖ה מֶ֣לֶךְ מִצְרָ֑יִם וִֽישַׁלַּ֥ח אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל מֵֽאַרְצֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וַיְדַבֵּ֣ר מֹשֶׁ֔ה לִפְנֵ֥י יְהוָ֖ה לֵאמֹ֑ר הֵ֤ן בְּנֵֽי־יִשְׂרָאֵל֙ לֹֽא־שָׁמְע֣וּ אֵלַ֔י וְאֵיךְ֙ יִשְׁמָעֵ֣נִי פַרְעֹ֔ה וַֽאֲנִ֖י עֲרַ֥ל שְׂפָתָֽיִם׃ {פ}"
          },
          {
            "num": "יג",
            "txt": "וַיְדַבֵּ֣ר יְהוָה֮ אֶל־מֹשֶׁ֣ה וְאֶֽל־אַהֲרֹן֒ וַיְצַוֵּם֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאֶל־פַּרְעֹ֖ה מֶ֣לֶךְ מִצְרָ֑יִם לְהוֹצִ֥יא אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל מֵאֶ֥רֶץ מִצְרָֽיִם׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "אֵ֖לֶּה רָאשֵׁ֣י בֵית־אֲבֹתָ֑ם בְּנֵ֨י רְאוּבֵ֜ן בְּכֹ֣ר יִשְׂרָאֵ֗ל חֲנ֤וֹךְ וּפַלּוּא֙ חֶצְרֹ֣ן וְכַרְמִ֔י אֵ֖לֶּה מִשְׁפְּחֹ֥ת רְאוּבֵֽן׃"
          },
          {
            "num": "טו",
            "txt": "וּבְנֵ֣י שִׁמְע֗וֹן יְמוּאֵ֨ל וְיָמִ֤ין וְאֹ֨הַד֙ וְיָכִ֣ין וְצֹ֔חַר וְשָׁא֖וּל בֶּן־הַֽכְּנַעֲנִ֑ית אֵ֖לֶּה מִשְׁפְּחֹ֥ת שִׁמְעֽוֹן׃"
          },
          {
            "num": "טז",
            "txt": "וְאֵ֨לֶּה שְׁמ֤וֹת בְּנֵֽי־לֵוִי֙ לְתֹ֣לְדֹתָ֔ם גֵּֽרְשׁ֕וֹן וּקְהָ֖ת וּמְרָרִ֑י וּשְׁנֵי֙ חַיֵּ֣י לֵוִ֔י שֶׁ֧בַע וּשְׁלֹשִׁ֛ים וּמְאַ֖ת שָׁנָֽה׃"
          },
          {
            "num": "יז",
            "txt": "בְּנֵ֥י גֵֽרְשׁ֛וֹן לִבְנִ֥י וְשִׁמְעִ֖י לְמִשְׁפְּחֹתָֽם׃"
          },
          {
            "num": "יח",
            "txt": "וּבְנֵ֣י קְהָ֔ת עַמְרָ֣ם וְיִצְהָ֔ר וְחֶבְר֖וֹן וְעֻזִּיאֵ֑ל וּשְׁנֵי֙ חַיֵּ֣י קְהָ֔ת שָׁלֹ֧שׁ וּשְׁלֹשִׁ֛ים וּמְאַ֖ת שָׁנָֽה׃"
          },
          {
            "num": "יט",
            "txt": "וּבְנֵ֥י מְרָרִ֖י מַחְלִ֣י וּמוּשִׁ֑י אֵ֛לֶּה מִשְׁפְּחֹ֥ת הַלֵּוִ֖י לְתֹֽלְדֹתָֽם׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּקַּ֨ח עַמְרָ֜ם אֶת־יוֹכֶ֤בֶד דֹּֽדָתוֹ֙ ל֣וֹ לְאִשָּׁ֔ה וַתֵּ֣לֶד ל֔וֹ אֶֽת־אַהֲרֹ֖ן וְאֶת־מֹשֶׁ֑ה וּשְׁנֵי֙ חַיֵּ֣י עַמְרָ֔ם שֶׁ֧בַע וּשְׁלֹשִׁ֛ים וּמְאַ֖ת שָׁנָֽה׃"
          },
          {
            "num": "כא",
            "txt": "וּבְנֵ֖י יִצְהָ֑ר קֹ֥רַח וָנֶ֖פֶג וְזִכְרִֽי׃"
          },
          {
            "num": "כב",
            "txt": "וּבְנֵ֖י עֻזִּיאֵ֑ל מִֽישָׁאֵ֥ל וְאֶלְצָפָ֖ן וְסִתְרִֽי׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּקַּ֨ח אַֽהֲרֹ֜ן אֶת־אֱלִישֶׁ֧בַע בַּת־עַמִּֽינָדָ֛ב אֲח֥וֹת נַחְשׁ֖וֹן ל֣וֹ לְאִשָּׁ֑ה וַתֵּ֣לֶד ל֗וֹ אֶת־נָדָב֙ וְאֶת־אֲבִיה֔וּא אֶת־אֶלְעָזָ֖ר וְאֶת־אִֽיתָמָֽר׃"
          },
          {
            "num": "כד",
            "txt": "וּבְנֵ֣י קֹ֔רַח אַסִּ֥יר וְאֶלְקָנָ֖ה וַאֲבִֽיאָסָ֑ף אֵ֖לֶּה מִשְׁפְּחֹ֥ת הַקָּרְחִֽי׃"
          },
          {
            "num": "כה",
            "txt": "וְאֶלְעָזָ֨ר בֶּֽן־אַהֲרֹ֜ן לָקַֽח־ל֨וֹ מִבְּנ֤וֹת פּֽוּטִיאֵל֙ ל֣וֹ לְאִשָּׁ֔ה וַתֵּ֥לֶד ל֖וֹ אֶת־פִּֽינְחָ֑ס אֵ֗לֶּה רָאשֵׁ֛י אֲב֥וֹת הַלְוִיִּ֖ם לְמִשְׁפְּחֹתָֽם׃"
          },
          {
            "num": "כו",
            "txt": "ה֥וּא אַֽהֲרֹ֖ן וּמֹשֶׁ֑ה אֲשֶׁ֨ר אָמַ֤ר יְהוָה֙ לָהֶ֔ם הוֹצִ֜יאוּ אֶת־בְּנֵ֧י יִשְׂרָאֵ֛ל מֵאֶ֥רֶץ מִצְרַ֖יִם עַל־צִבְאֹתָֽם׃"
          },
          {
            "num": "כז",
            "txt": "הֵ֗ם הַֽמְדַבְּרִים֙ אֶל־פַּרְעֹ֣ה מֶֽלֶךְ־מִצְרַ֔יִם לְהוֹצִ֥יא אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל מִמִּצְרָ֑יִם ה֥וּא מֹשֶׁ֖ה וְאַֽהֲרֹֽן׃"
          },
          {
            "num": "כח",
            "txt": "וַיְהִ֗י בְּי֨וֹם דִּבֶּ֧ר יְהוָ֛ה אֶל־מֹשֶׁ֖ה בְּאֶ֥רֶץ מִצְרָֽיִם׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "וַיְדַבֵּ֧ר יְהוָ֛ה אֶל־מֹשֶׁ֥ה לֵּאמֹ֖ר אֲנִ֣י יְהוָ֑ה דַּבֵּ֗ר אֶל־פַּרְעֹה֙ מֶ֣לֶךְ מִצְרַ֔יִם אֵ֛ת כָּל־אֲשֶׁ֥ר אֲנִ֖י דֹּבֵ֥ר אֵלֶֽיךָ׃"
          },
          {
            "num": "ל",
            "txt": "וַיֹּ֥אמֶר מֹשֶׁ֖ה לִפְנֵ֣י יְהוָ֑ה הֵ֤ן אֲנִי֙ עֲרַ֣ל שְׂפָתַ֔יִם וְאֵ֕יךְ יִשְׁמַ֥ע אֵלַ֖י פַּרְעֹֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "ז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה רְאֵ֛ה נְתַתִּ֥יךָ אֱלֹהִ֖ים לְפַרְעֹ֑ה וְאַֽהֲרֹ֥ן אָחִ֖יךָ יִֽהְיֶ֥ה נְבִיאֶֽךָ׃"
          },
          {
            "num": "ב",
            "txt": "אַתָּ֣ה תְדַבֵּ֔ר אֵ֖ת כָּל־אֲשֶׁ֣ר אֲצַוֶּ֑ךָּ וְאַֽהֲרֹ֤ן אָחִ֨יךָ֙ יְדַבֵּ֣ר אֶל־פַּרְעֹ֔ה וְשִׁלַּ֥ח אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל מֵֽאַרְצֽוֹ׃"
          },
          {
            "num": "ג",
            "txt": "וַֽאֲנִ֥י אַקְשֶׁ֖ה אֶת־לֵ֣ב פַּרְעֹ֑ה וְהִרְבֵּיתִ֧י אֶת־אֹֽתֹתַ֛י וְאֶת־מֽוֹפְתַ֖י בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ד",
            "txt": "וְלֹֽא־יִשְׁמַ֤ע אֲלֵכֶם֙ פַּרְעֹ֔ה וְנָֽתַתִּ֥י אֶת־יָדִ֖י בְּמִצְרָ֑יִם וְהֽוֹצֵאתִ֨י אֶת־צִבְאֹתַ֜י אֶת־עַמִּ֤י בְנֵֽי־יִשְׂרָאֵל֙ מֵאֶ֣רֶץ מִצְרַ֔יִם בִּשְׁפָטִ֖ים גְּדֹלִֽים׃"
          },
          {
            "num": "ה",
            "txt": "וְיָֽדְע֤וּ מִצְרַ֨יִם֙ כִּֽי־אֲנִ֣י יְהוָ֔ה בִּנְטֹתִ֥י אֶת־יָדִ֖י עַל־מִצְרָ֑יִם וְהֽוֹצֵאתִ֥י אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל מִתּוֹכָֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּ֥עַשׂ מֹשֶׁ֖ה וְאַֽהֲרֹ֑ן כַּֽאֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֹתָ֖ם כֵּ֥ן עָשֽׂוּ׃"
          },
          {
            "num": "ז",
            "txt": "וּמֹשֶׁה֙ בֶּן־שְׁמֹנִ֣ים שָׁנָ֔ה וְאַֽהֲרֹ֔ן בֶּן־שָׁלֹ֥שׁ וּשְׁמֹנִ֖ים שָׁנָ֑ה בְּדַבְּרָ֖ם אֶל־פַּרְעֹֽה׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֣אמֶר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "ט",
            "txt": "כִּי֩ יְדַבֵּ֨ר אֲלֵכֶ֤ם פַּרְעֹה֙ לֵאמֹ֔ר תְּנ֥וּ לָכֶ֖ם מוֹפֵ֑ת וְאָֽמַרְתָּ֣ אֶֽל־אַהֲרֹ֗ן קַ֧ח אֶֽת־מַטְּךָ֛ וְהַשְׁלֵ֥ךְ לִפְנֵֽי־פַרְעֹ֖ה יְהִ֥י לְתַנִּֽין׃"
          },
          {
            "num": "י",
            "txt": "וַיָּבֹ֨א מֹשֶׁ֤ה וְאַֽהֲרֹן֙ אֶל־פַּרְעֹ֔ה וַיַּ֣עֲשׂוּ כֵ֔ן כַּֽאֲשֶׁ֖ר צִוָּ֣ה יְהוָ֑ה וַיַּשְׁלֵ֨ךְ אַֽהֲרֹ֜ן אֶת־מַטֵּ֗הוּ לִפְנֵ֥י פַרְעֹ֛ה וְלִפְנֵ֥י עֲבָדָ֖יו וַיְהִ֥י לְתַנִּֽין׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּקְרָא֙ גַּם־פַּרְעֹ֔ה לַֽחֲכָמִ֖ים וְלַֽמְכַשְּׁפִ֑ים וַיַּֽעֲשׂ֨וּ גַם־הֵ֜ם חַרְטֻמֵּ֥י מִצְרַ֛יִם בְּלַֽהֲטֵיהֶ֖ם כֵּֽן׃"
          },
          {
            "num": "יב",
            "txt": "וַיַּשְׁלִ֨יכוּ֙ אִ֣ישׁ מַטֵּ֔הוּ וַיִּֽהְי֖וּ לְתַנִּינִ֑ם וַיִּבְלַ֥ע מַטֵּֽה־אַהֲרֹ֖ן אֶת־מַטֹּתָֽם׃"
          },
          {
            "num": "יג",
            "txt": "וַיֶּֽחֱזַק֙ לֵ֣ב פַּרְעֹ֔ה וְלֹ֥א שָׁמַ֖ע אֲלֵהֶ֑ם כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר יְהוָֽה׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה כָּבֵ֖ד לֵ֣ב פַּרְעֹ֑ה מֵאֵ֖ן לְשַׁלַּ֥ח הָעָֽם׃"
          },
          {
            "num": "טו",
            "txt": "לֵ֣ךְ אֶל־פַּרְעֹ֞ה בַּבֹּ֗קֶר הִנֵּה֙ יֹצֵ֣א הַמַּ֔יְמָה וְנִצַּבְתָּ֥ לִקְרָאת֖וֹ עַל־שְׂפַ֣ת הַיְאֹ֑ר וְהַמַּטֶּ֛ה אֲשֶׁר־נֶהְפַּ֥ךְ לְנָחָ֖שׁ תִּקַּ֥ח בְּיָדֶֽךָ׃"
          },
          {
            "num": "טז",
            "txt": "וְאָֽמַרְתָּ֣ אֵלָ֗יו יְהוָ֞ה אֱלֹהֵ֤י הָֽעִבְרִים֙ שְׁלָחַ֤נִי אֵלֶ֨יךָ֙ לֵאמֹ֔ר שַׁלַּח֙ אֶת־עַמִּ֔י וְיַֽעַבְדֻ֖נִי בַּמִּדְבָּ֑ר וְהִנֵּ֥ה לֹֽא־שָׁמַ֖עְתָּ עַד־כֹּֽה׃"
          },
          {
            "num": "יז",
            "txt": "כֹּ֚ה אָמַ֣ר יְהוָ֔ה בְּזֹ֣את תֵּדַ֔ע כִּ֖י אֲנִ֣י יְהוָ֑ה הִנֵּ֨ה אָֽנֹכִ֜י מַכֶּ֣ה ׀ בַּמַּטֶּ֣ה אֲשֶׁר־בְּיָדִ֗י עַל־הַמַּ֛יִם אֲשֶׁ֥ר בַּיְאֹ֖ר וְנֶֽהֶפְכ֥וּ לְדָֽם׃"
          },
          {
            "num": "יח",
            "txt": "וְהַדָּגָ֧ה אֲשֶׁר־בַּיְאֹ֛ר תָּמ֖וּת וּבָאַ֣שׁ הַיְאֹ֑ר וְנִלְא֣וּ מִצְרַ֔יִם לִשְׁתּ֥וֹת מַ֖יִם מִן־הַיְאֹֽר׃ {ס}"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה אֱמֹ֣ר אֶֽל־אַהֲרֹ֡ן קַ֣ח מַטְּךָ֣ וּנְטֵֽה־יָדְךָ֩ עַל־מֵימֵ֨י מִצְרַ֜יִם עַֽל־נַהֲרֹתָ֣ם ׀ עַל־יְאֹֽרֵיהֶ֣ם וְעַל־אַגְמֵיהֶ֗ם וְעַ֛ל כָּל־מִקְוֵ֥ה מֵֽימֵיהֶ֖ם וְיִֽהְיוּ־דָ֑ם וְהָ֤יָה דָם֙ בְּכָל־אֶ֣רֶץ מִצְרַ֔יִם וּבָֽעֵצִ֖ים וּבָֽאֲבָנִֽים׃"
          },
          {
            "num": "כ",
            "txt": "וַיַּֽעֲשׂוּ־כֵן֩ מֹשֶׁ֨ה וְאַֽהֲרֹ֜ן כַּֽאֲשֶׁ֣ר ׀ צִוָּ֣ה יְהוָ֗ה וַיָּ֤רֶם בַּמַּטֶּה֙ וַיַּ֤ךְ אֶת־הַמַּ֨יִם֙ אֲשֶׁ֣ר בַּיְאֹ֔ר לְעֵינֵ֣י פַרְעֹ֔ה וּלְעֵינֵ֖י עֲבָדָ֑יו וַיֵּהָ֥פְכ֛וּ כָּל־הַמַּ֥יִם אֲשֶׁר־בַּיְאֹ֖ר לְדָֽם׃"
          },
          {
            "num": "כא",
            "txt": "וְהַדָּגָ֨ה אֲשֶׁר־בַּיְאֹ֥ר מֵ֨תָה֙ וַיִּבְאַ֣שׁ הַיְאֹ֔ר וְלֹֽא־יָכְל֣וּ מִצְרַ֔יִם לִשְׁתּ֥וֹת מַ֖יִם מִן־הַיְאֹ֑ר וַיְהִ֥י הַדָּ֖ם בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "כב",
            "txt": "וַיַּֽעֲשׂוּ־כֵ֛ן חַרְטֻמֵּ֥י מִצְרַ֖יִם בְּלָֽטֵיהֶ֑ם וַיֶּֽחֱזַ֤ק לֵב־פַּרְעֹה֙ וְלֹֽא־שָׁמַ֣ע אֲלֵהֶ֔ם כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר יְהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּ֣פֶן פַּרְעֹ֔ה וַיָּבֹ֖א אֶל־בֵּית֑וֹ וְלֹא־שָׁ֥ת לִבּ֖וֹ גַּם־לָזֹֽאת׃"
          },
          {
            "num": "כד",
            "txt": "וַיַּחְפְּר֧וּ כָל־מִצְרַ֛יִם סְבִיבֹ֥ת הַיְאֹ֖ר מַ֣יִם לִשְׁתּ֑וֹת כִּ֣י לֹ֤א יָֽכְלוּ֙ לִשְׁתֹּ֔ת מִמֵּימֵ֖י הַיְאֹֽר׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּמָּלֵ֖א שִׁבְעַ֣ת יָמִ֑ים אַֽחֲרֵ֥י הַכּוֹת־יְהוָ֖ה אֶת־הַיְאֹֽר׃ {פ}"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה בֹּ֖א אֶל־פַּרְעֹ֑ה וְאָֽמַרְתָּ֣ אֵלָ֗יו כֹּ֚ה אָמַ֣ר יְהוָ֔ה שַׁלַּ֥ח אֶת־עַמִּ֖י וְיַֽעַבְדֻֽנִי׃"
          },
          {
            "num": "כז",
            "txt": "וְאִם־מָאֵ֥ן אַתָּ֖ה לְשַׁלֵּ֑חַ הִנֵּ֣ה אָֽנֹכִ֗י נֹגֵ֛ף אֶת־כָּל־גְּבֽוּלְךָ֖ בַּֽצְפַרְדְּעִֽים׃"
          },
          {
            "num": "כח",
            "txt": "וְשָׁרַ֣ץ הַיְאֹר֮ צְפַרְדְּעִים֒ וְעָלוּ֙ וּבָ֣אוּ בְּבֵיתֶ֔ךָ וּבַֽחֲדַ֥ר מִשְׁכָּֽבְךָ֖ וְעַל־מִטָּתֶ֑ךָ וּבְבֵ֤ית עֲבָדֶ֨יךָ֙ וּבְעַמֶּ֔ךָ וּבְתַנּוּרֶ֖יךָ וּבְמִשְׁאֲרוֹתֶֽיךָ׃"
          },
          {
            "num": "כט",
            "txt": "וּבְכָ֥ה וּֽבְעַמְּךָ֖ וּבְכָל־עֲבָדֶ֑יךָ יַֽעֲל֖וּ הַֽצְפַרְדְּעִֽים׃"
          }
        ]
      },
      {
        "num": "ח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֣אמֶר יְהוָה֮ אֶל־מֹשֶׁה֒ אֱמֹ֣ר אֶֽל־אַהֲרֹ֗ן נְטֵ֤ה אֶת־יָֽדְךָ֙ בְּמַטֶּ֔ךָ עַ֨ל־הַנְּהָרֹ֔ת עַל־הַיְאֹרִ֖ים וְעַל־הָֽאֲגַמִּ֑ים וְהַ֥עַל אֶת־הַֽצְפַרְדְּעִ֖ים עַל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ב",
            "txt": "וַיֵּ֤ט אַֽהֲרֹן֙ אֶת־יָד֔וֹ עַ֖ל מֵימֵ֣י מִצְרָ֑יִם וַתַּ֨עַל֙ הַצְּפַרְדֵּ֔עַ וַתְּכַ֖ס אֶת־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ג",
            "txt": "וַיַּֽעֲשׂוּ־כֵ֥ן הַֽחַרְטֻמִּ֖ים בְּלָֽטֵיהֶ֑ם וַיַּֽעֲל֥וּ אֶת־הַֽצְפַרְדְּעִ֖ים עַל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּקְרָ֨א פַרְעֹ֜ה לְמֹשֶׁ֣ה וּֽלְאַהֲרֹ֗ן וַיֹּ֨אמֶר֙ הַעְתִּ֣ירוּ אֶל־יְהוָ֔ה וְיָסֵר֙ הַֽצְפַרְדְּעִ֔ים מִמֶּ֖נִּי וּמֵֽעַמִּ֑י וַֽאֲשַׁלְּחָה֙ אֶת־הָעָ֔ם וְיִזְבְּח֖וּ לַֽיהוָֽה׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֣ה לְפַרְעֹה֮ הִתְפָּאֵ֣ר עָלַי֒ לְמָתַ֣י ׀ אַעְתִּ֣יר לְךָ֗ וְלַֽעֲבָדֶ֨יךָ֙ וּֽלְעַמְּךָ֔ לְהַכְרִית֙ הַֽצְפַרְדְּעִ֔ים מִמְּךָ֖ וּמִבָּתֶּ֑יךָ רַ֥ק בַּיְאֹ֖ר תִּשָּׁאַֽרְנָה׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֖אמֶר לְמָחָ֑ר וַיֹּ֨אמֶר֙ כִּדְבָ֣רְךָ֔ לְמַ֣עַן תֵּדַ֔ע כִּי־אֵ֖ין כַּֽיהוָ֥ה אֱלֹהֵֽינוּ׃"
          },
          {
            "num": "ז",
            "txt": "וְסָר֣וּ הַֽצְפַרְדְּעִ֗ים מִמְּךָ֙ וּמִבָּ֣תֶּ֔יךָ וּמֵֽעֲבָדֶ֖יךָ וּמֵֽעַמֶּ֑ךָ רַ֥ק בַּיְאֹ֖ר תִּשָּׁאַֽרְנָה׃"
          },
          {
            "num": "ח",
            "txt": "וַיֵּצֵ֥א מֹשֶׁ֛ה וְאַֽהֲרֹ֖ן מֵעִ֣ם פַּרְעֹ֑ה וַיִּצְעַ֤ק מֹשֶׁה֙ אֶל־יְהוָ֔ה עַל־דְּבַ֥ר הַֽצְפַרְדְּעִ֖ים אֲשֶׁר־שָׂ֥ם לְפַרְעֹֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיַּ֥עַשׂ יְהוָ֖ה כִּדְבַ֣ר מֹשֶׁ֑ה וַיָּמֻ֨תוּ֙ הַֽצְפַרְדְּעִ֔ים מִן־הַבָּתִּ֥ים מִן־הַֽחֲצֵרֹ֖ת וּמִן־הַשָּׂדֹֽת׃"
          },
          {
            "num": "י",
            "txt": "וַיִּצְבְּר֥וּ אֹתָ֖ם חֳמָרִ֣ם חֳמָרִ֑ם וַתִּבְאַ֖שׁ הָאָֽרֶץ׃"
          },
          {
            "num": "יא",
            "txt": "וַיַּ֣רְא פַּרְעֹ֗ה כִּ֤י הָֽיְתָה֙ הָֽרְוָחָ֔ה וְהַכְבֵּד֙ אֶת־לִבּ֔וֹ וְלֹ֥א שָׁמַ֖ע אֲלֵהֶ֑ם כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר יְהוָֽה׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֣אמֶר יְהוָה֮ אֶל־מֹשֶׁה֒ אֱמֹר֙ אֶֽל־אַהֲרֹ֔ן נְטֵ֣ה אֶֽת־מַטְּךָ֔ וְהַ֖ךְ אֶת־עֲפַ֣ר הָאָ֑רֶץ וְהָיָ֥ה לְכִנִּ֖ם בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּֽעֲשׂוּ־כֵ֗ן וַיֵּט֩ אַֽהֲרֹ֨ן אֶת־יָד֤וֹ בְמַטֵּ֨הוּ֙ וַיַּךְ֙ אֶת־עֲפַ֣ר הָאָ֔רֶץ וַתְּהִי֙ הַכִּנָּ֔ם בָּֽאָדָ֖ם וּבַבְּהֵמָ֑ה כָּל־עֲפַ֥ר הָאָ֛רֶץ הָיָ֥ה כִנִּ֖ים בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "יד",
            "txt": "וַיַּֽעֲשׂוּ־כֵ֨ן הַֽחַרְטֻמִּ֧ים בְּלָֽטֵיהֶ֛ם לְהוֹצִ֥יא אֶת־הַכִּנִּ֖ים וְלֹ֣א יָכֹ֑לוּ וַתְּהִי֙ הַכִּנָּ֔ם בָּֽאָדָ֖ם וּבַבְּהֵמָֽה׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּֽאמְר֤וּ הַֽחַרְטֻמִּם֙ אֶל־פַּרְעֹ֔ה אֶצְבַּ֥ע אֱלֹהִ֖ים הִ֑וא וַיֶּֽחֱזַ֤ק לֵב־פַּרְעֹה֙ וְלֹֽא־שָׁמַ֣ע אֲלֵהֶ֔ם כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר יְהוָֽה׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה הַשְׁכֵּ֤ם בַּבֹּ֨קֶר֙ וְהִתְיַצֵּב֙ לִפְנֵ֣י פַרְעֹ֔ה הִנֵּ֖ה יוֹצֵ֣א הַמָּ֑יְמָה וְאָֽמַרְתָּ֣ אֵלָ֗יו כֹּ֚ה אָמַ֣ר יְהוָ֔ה שַׁלַּ֥ח עַמִּ֖י וְיַֽעַבְדֻֽנִי׃"
          },
          {
            "num": "יז",
            "txt": "כִּ֣י אִם־אֵֽינְךָ֮ מְשַׁלֵּ֣חַ אֶת־עַמִּי֒ הִנְנִי֩ מַשְׁלִ֨יחַ בְּךָ֜ וּבַֽעֲבָדֶ֧יךָ וּֽבְעַמְּךָ֛ וּבְבָתֶּ֖יךָ אֶת־הֶֽעָרֹ֑ב וּמָ֨לְא֜וּ בָּתֵּ֤י מִצְרַ֨יִם֙ אֶת־הֶ֣עָרֹ֔ב וְגַ֥ם הָֽאֲדָמָ֖ה אֲשֶׁר־הֵ֥ם עָלֶֽיהָ׃"
          },
          {
            "num": "יח",
            "txt": "וְהִפְלֵיתִי֩ בַיּ֨וֹם הַה֜וּא אֶת־אֶ֣רֶץ גֹּ֗שֶׁן אֲשֶׁ֤ר עַמִּי֙ עֹמֵ֣ד עָלֶ֔יהָ לְבִלְתִּ֥י הֱיֽוֹת־שָׁ֖ם עָרֹ֑ב לְמַ֣עַן תֵּדַ֔ע כִּ֛י אֲנִ֥י יְהוָ֖ה בְּקֶ֥רֶב הָאָֽרֶץ׃"
          },
          {
            "num": "יט",
            "txt": "וְשַׂמְתִּ֣י פְדֻ֔ת בֵּ֥ין עַמִּ֖י וּבֵ֣ין עַמֶּ֑ךָ לְמָחָ֥ר יִֽהְיֶ֖ה הָאֹ֥ת הַזֶּֽה׃"
          },
          {
            "num": "כ",
            "txt": "וַיַּ֤עַשׂ יְהוָה֙ כֵּ֔ן וַיָּבֹא֙ עָרֹ֣ב כָּבֵ֔ד בֵּ֥יתָה פַרְעֹ֖ה וּבֵ֣ית עֲבָדָ֑יו וּבְכָל־אֶ֧רֶץ מִצְרַ֛יִם תִּשָּׁחֵ֥ת הָאָ֖רֶץ מִפְּנֵ֥י הֶֽעָרֹֽב׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּקְרָ֣א פַרְעֹ֔ה אֶל־מֹשֶׁ֖ה וּֽלְאַהֲרֹ֑ן וַיֹּ֗אמֶר לְכ֛וּ זִבְח֥וּ לֵֽאלֹהֵיכֶ֖ם בָּאָֽרֶץ׃"
          },
          {
            "num": "כב",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֗ה לֹ֤א נָכוֹן֙ לַֽעֲשׂ֣וֹת כֵּ֔ן כִּ֚י תּֽוֹעֲבַ֣ת מִצְרַ֔יִם נִזְבַּ֖ח לַֽיהוָ֣ה אֱלֹהֵ֑ינוּ הֵ֣ן נִזְבַּ֞ח אֶת־תּֽוֹעֲבַ֥ת מִצְרַ֛יִם לְעֵֽינֵיהֶ֖ם וְלֹ֥א יִסְקְלֻֽנוּ׃"
          },
          {
            "num": "כג",
            "txt": "דֶּ֚רֶךְ שְׁלֹ֣שֶׁת יָמִ֔ים נֵלֵ֖ךְ בַּמִּדְבָּ֑ר וְזָבַ֨חְנוּ֙ לַֽיהוָ֣ה אֱלֹהֵ֔ינוּ כַּֽאֲשֶׁ֖ר יֹאמַ֥ר אֵלֵֽינוּ׃"
          },
          {
            "num": "כד",
            "txt": "וַיֹּ֣אמֶר פַּרְעֹ֗ה אָֽנֹכִ֞י אֲשַׁלַּ֤ח אֶתְכֶם֙ וּזְבַחְתֶּ֞ם לַֽיהוָ֤ה אֱלֹֽהֵיכֶם֙ בַּמִּדְבָּ֔ר רַ֛ק הַרְחֵ֥ק לֹֽא־תַרְחִ֖יקוּ לָלֶ֑כֶת הַעְתִּ֖ירוּ בַּֽעֲדִֽי׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֗ה הִנֵּ֨ה אָֽנֹכִ֜י יוֹצֵ֤א מֵֽעִמָּךְ֙ וְהַעְתַּרְתִּ֣י אֶל־יְהוָ֔ה וְסָ֣ר הֶֽעָרֹ֗ב מִפַּרְעֹ֛ה מֵֽעֲבָדָ֥יו וּמֵֽעַמּ֖וֹ מָחָ֑ר רַ֗ק אַל־יֹסֵ֤ף פַּרְעֹה֙ הָתֵ֔ל לְבִלְתִּי֙ שַׁלַּ֣ח אֶת־הָעָ֔ם לִזְבֹּ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "כו",
            "txt": "וַיֵּצֵ֥א מֹשֶׁ֖ה מֵעִ֣ם פַּרְעֹ֑ה וַיֶּעְתַּ֖ר אֶל־יְהוָֽה׃"
          },
          {
            "num": "כז",
            "txt": "וַיַּ֤עַשׂ יְהוָה֙ כִּדְבַ֣ר מֹשֶׁ֔ה וַיָּ֨סַר֙ הֶֽעָרֹ֔ב מִפַּרְעֹ֖ה מֵֽעֲבָדָ֣יו וּמֵֽעַמּ֑וֹ לֹ֥א נִשְׁאַ֖ר אֶחָֽד׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּכְבֵּ֤ד פַּרְעֹה֙ אֶת־לִבּ֔וֹ גַּ֖ם בַּפַּ֣עַם הַזֹּ֑את וְלֹ֥א שִׁלַּ֖ח אֶת־הָעָֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "ט",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה בֹּ֖א אֶל־פַּרְעֹ֑ה וְדִבַּרְתָּ֣ אֵלָ֗יו כֹּֽה־אָמַ֤ר יְהוָה֙ אֱלֹהֵ֣י הָֽעִבְרִ֔ים שַׁלַּ֥ח אֶת־עַמִּ֖י וְיַֽעַבְדֻֽנִי׃"
          },
          {
            "num": "ב",
            "txt": "כִּ֛י אִם־מָאֵ֥ן אַתָּ֖ה לְשַׁלֵּ֑חַ וְעֽוֹדְךָ֖ מַֽחֲזִ֥יק בָּֽם׃"
          },
          {
            "num": "ג",
            "txt": "הִנֵּ֨ה יַד־יְהוָ֜ה הוֹיָ֗ה בְּמִקְנְךָ֙ אֲשֶׁ֣ר בַּשָּׂדֶ֔ה בַּסּוּסִ֤ים בַּֽחֲמֹרִים֙ בַּגְּמַלִּ֔ים בַּבָּקָ֖ר וּבַצֹּ֑אן דֶּ֖בֶר כָּבֵ֥ד מְאֹֽד׃"
          },
          {
            "num": "ד",
            "txt": "וְהִפְלָ֣ה יְהוָ֔ה בֵּ֚ין מִקְנֵ֣ה יִשְׂרָאֵ֔ל וּבֵ֖ין מִקְנֵ֣ה מִצְרָ֑יִם וְלֹ֥א יָמ֛וּת מִכָּל־לִבְנֵ֥י יִשְׂרָאֵ֖ל דָּבָֽר׃"
          },
          {
            "num": "ה",
            "txt": "וַיָּ֥שֶׂם יְהוָ֖ה מוֹעֵ֣ד לֵאמֹ֑ר מָחָ֗ר יַֽעֲשֶׂ֧ה יְהוָ֛ה הַדָּבָ֥ר הַזֶּ֖ה בָּאָֽרֶץ׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּ֨עַשׂ יְהוָ֜ה אֶת־הַדָּבָ֤ר הַזֶּה֙ מִֽמָּחֳרָ֔ת וַיָּ֕מָת כֹּ֖ל מִקְנֵ֣ה מִצְרָ֑יִם וּמִמִּקְנֵ֥ה בְנֵֽי־יִשְׂרָאֵ֖ל לֹא־מֵ֥ת אֶחָֽד׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּשְׁלַ֣ח פַּרְעֹ֔ה וְהִנֵּ֗ה לֹא־מֵ֛ת מִמִּקְנֵ֥ה יִשְׂרָאֵ֖ל עַד־אֶחָ֑ד וַיִּכְבַּד֙ לֵ֣ב פַּרְעֹ֔ה וְלֹ֥א שִׁלַּ֖ח אֶת־הָעָֽם׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֣אמֶר יְהוָה֮ אֶל־מֹשֶׁ֣ה וְאֶֽל־אַהֲרֹן֒ קְח֤וּ לָכֶם֙ מְלֹ֣א חָפְנֵיכֶ֔ם פִּ֖יחַ כִּבְשָׁ֑ן וּזְרָק֥וֹ מֹשֶׁ֛ה הַשָּׁמַ֖יְמָה לְעֵינֵ֥י פַרְעֹֽה׃"
          },
          {
            "num": "ט",
            "txt": "וְהָיָ֣ה לְאָבָ֔ק עַ֖ל כָּל־אֶ֣רֶץ מִצְרָ֑יִם וְהָיָ֨ה עַל־הָֽאָדָ֜ם וְעַל־הַבְּהֵמָ֗ה לִשְׁחִ֥ין פֹּרֵ֛חַ אֲבַעְבֻּעֹ֖ת בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "י",
            "txt": "וַיִּקְח֞וּ אֶת־פִּ֣יחַ הַכִּבְשָׁ֗ן וַיַּֽעַמְדוּ֙ לִפְנֵ֣י פַרְעֹ֔ה וַיִּזְרֹ֥ק אֹת֛וֹ מֹשֶׁ֖ה הַשָּׁמָ֑יְמָה וַיְהִ֗י שְׁחִין֙ אֲבַעְבֻּעֹ֔ת פֹּרֵ֕חַ בָּֽאָדָ֖ם וּבַבְּהֵמָֽה׃"
          },
          {
            "num": "יא",
            "txt": "וְלֹֽא־יָכְל֣וּ הַֽחַרְטֻמִּ֗ים לַֽעֲמֹ֛ד לִפְנֵ֥י מֹשֶׁ֖ה מִפְּנֵ֣י הַשְּׁחִ֑ין כִּֽי־הָיָ֣ה הַשְּׁחִ֔ין בַּֽחַרְטֻמִּ֖ם וּבְכָל־מִצְרָֽיִם׃"
          },
          {
            "num": "יב",
            "txt": "וַיְחַזֵּ֤ק יְהוָה֙ אֶת־לֵ֣ב פַּרְעֹ֔ה וְלֹ֥א שָׁמַ֖ע אֲלֵהֶ֑ם כַּֽאֲשֶׁ֛ר דִּבֶּ֥ר יְהוָ֖ה אֶל־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה הַשְׁכֵּ֣ם בַּבֹּ֔קֶר וְהִתְיַצֵּ֖ב לִפְנֵ֣י פַרְעֹ֑ה וְאָֽמַרְתָּ֣ אֵלָ֗יו כֹּֽה־אָמַ֤ר יְהוָה֙ אֱלֹהֵ֣י הָֽעִבְרִ֔ים שַׁלַּ֥ח אֶת־עַמִּ֖י וְיַֽעַבְדֻֽנִי׃"
          },
          {
            "num": "יד",
            "txt": "כִּ֣י ׀ בַּפַּ֣עַם הַזֹּ֗את אֲנִ֨י שֹׁלֵ֜חַ אֶת־כָּל־מַגֵּֽפֹתַי֙ אֶֽל־לִבְּךָ֔ וּבַֽעֲבָדֶ֖יךָ וּבְעַמֶּ֑ךָ בַּֽעֲב֣וּר תֵּדַ֔ע כִּ֛י אֵ֥ין כָּמֹ֖נִי בְּכָל־הָאָֽרֶץ׃"
          },
          {
            "num": "טו",
            "txt": "כִּ֤י עַתָּה֙ שָׁלַ֣חְתִּי אֶת־יָדִ֔י וָאַ֥ךְ אֽוֹתְךָ֛ וְאֶֽת־עַמְּךָ֖ בַּדָּ֑בֶר וַתִּכָּחֵ֖ד מִן־הָאָֽרֶץ׃"
          },
          {
            "num": "טז",
            "txt": "וְאוּלָ֗ם בַּֽעֲב֥וּר זֹאת֙ הֶֽעֱמַדְתִּ֔יךָ בַּֽעֲב֖וּר הַרְאֹֽתְךָ֣ אֶת־כֹּחִ֑י וּלְמַ֛עַן סַפֵּ֥ר שְׁמִ֖י בְּכָל־הָאָֽרֶץ׃"
          },
          {
            "num": "יז",
            "txt": "עֽוֹדְךָ֖ מִסְתּוֹלֵ֣ל בְּעַמִּ֑י לְבִלְתִּ֖י שַׁלְּחָֽם׃"
          },
          {
            "num": "יח",
            "txt": "הִנְנִ֤י מַמְטִיר֙ כָּעֵ֣ת מָחָ֔ר בָּרָ֖ד כָּבֵ֣ד מְאֹ֑ד אֲשֶׁ֨ר לֹֽא־הָיָ֤ה כָמֹ֨הוּ֙ בְּמִצְרַ֔יִם לְמִן־הַיּ֥וֹם הִוָּֽסְדָ֖ה וְעַד־עָֽתָּה׃"
          },
          {
            "num": "יט",
            "txt": "וְעַתָּ֗ה שְׁלַ֤ח הָעֵז֙ אֶֽת־מִקְנְךָ֔ וְאֵ֛ת כָּל־אֲשֶׁ֥ר לְךָ֖ בַּשָּׂדֶ֑ה כָּל־הָֽאָדָ֨ם וְהַבְּהֵמָ֜ה אֲשֶֽׁר־יִמָּצֵ֣א בַשָּׂדֶ֗ה וְלֹ֤א יֵֽאָסֵף֙ הַבַּ֔יְתָה וְיָרַ֧ד עֲלֵהֶ֛ם הַבָּרָ֖ד וָמֵֽתוּ׃"
          },
          {
            "num": "כ",
            "txt": "הַיָּרֵא֙ אֶת־דְּבַ֣ר יְהוָ֔ה מֵֽעַבְדֵ֖י פַּרְעֹ֑ה הֵנִ֛יס אֶת־עֲבָדָ֥יו וְאֶת־מִקְנֵ֖הוּ אֶל־הַבָּתִּֽים׃"
          },
          {
            "num": "כא",
            "txt": "וַֽאֲשֶׁ֥ר לֹא־שָׂ֛ם לִבּ֖וֹ אֶל־דְּבַ֣ר יְהוָ֑ה וַֽיַּעֲזֹ֛ב אֶת־עֲבָדָ֥יו וְאֶת־מִקְנֵ֖הוּ בַּשָּׂדֶֽה׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה נְטֵ֤ה אֶת־יָֽדְךָ֙ עַל־הַשָּׁמַ֔יִם וִיהִ֥י בָרָ֖ד בְּכָל־אֶ֣רֶץ מִצְרָ֑יִם עַל־הָֽאָדָ֣ם וְעַל־הַבְּהֵמָ֗ה וְעַ֛ל כָּל־עֵ֥שֶׂב הַשָּׂדֶ֖ה בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "כג",
            "txt": "וַיֵּ֨ט מֹשֶׁ֣ה אֶת־מַטֵּהוּ֮ עַל־הַשָּׁמַיִם֒ וַֽיהוָ֗ה נָתַ֤ן קֹלֹת֙ וּבָרָ֔ד וַתִּ֥הֲלַךְ אֵ֖שׁ אָ֑רְצָה וַיַּמְטֵ֧ר יְהוָ֛ה בָּרָ֖ד עַל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "כד",
            "txt": "וַיְהִ֣י בָרָ֔ד וְאֵ֕שׁ מִתְלַקַּ֖חַת בְּת֣וֹךְ הַבָּרָ֑ד כָּבֵ֣ד מְאֹ֔ד אֲ֠שֶׁר לֹֽא־הָיָ֤ה כָמֹ֨הוּ֙ בְּכָל־אֶ֣רֶץ מִצְרַ֔יִם מֵאָ֖ז הָֽיְתָ֥ה לְגֽוֹי׃"
          },
          {
            "num": "כה",
            "txt": "וַיַּ֨ךְ הַבָּרָ֜ד בְּכָל־אֶ֣רֶץ מִצְרַ֗יִם אֵ֚ת כָּל־אֲשֶׁ֣ר בַּשָּׂדֶ֔ה מֵֽאָדָ֖ם וְעַד־בְּהֵמָ֑ה וְאֵ֨ת כָּל־עֵ֤שֶׂב הַשָּׂדֶה֙ הִכָּ֣ה הַבָּרָ֔ד וְאֶת־כָּל־עֵ֥ץ הַשָּׂדֶ֖ה שִׁבֵּֽר׃"
          },
          {
            "num": "כו",
            "txt": "רַ֚ק בְּאֶ֣רֶץ גֹּ֔שֶׁן אֲשֶׁר־שָׁ֖ם בְּנֵ֣י יִשְׂרָאֵ֑ל לֹ֥א הָיָ֖ה בָּרָֽד׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּשְׁלַ֣ח פַּרְעֹ֗ה וַיִּקְרָא֙ לְמֹשֶׁ֣ה וּֽלְאַהֲרֹ֔ן וַיֹּ֥אמֶר אֲלֵהֶ֖ם חָטָ֣אתִי הַפָּ֑עַם יְהוָה֙ הַצַּדִּ֔יק וַֽאֲנִ֥י וְעַמִּ֖י הָֽרְשָׁעִֽים׃"
          },
          {
            "num": "כח",
            "txt": "הַעְתִּ֨ירוּ֙ אֶל־יְהוָ֔ה וְרַ֕ב מִֽהְיֹ֛ת קֹלֹ֥ת אֱלֹהִ֖ים וּבָרָ֑ד וַֽאֲשַׁלְּחָ֣ה אֶתְכֶ֔ם וְלֹ֥א תֹֽסִפ֖וּן לַֽעֲמֹֽד׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֤אמֶר אֵלָיו֙ מֹשֶׁ֔ה כְּצֵאתִי֙ אֶת־הָעִ֔יר אֶפְרֹ֥שׂ אֶת־כַּפַּ֖י אֶל־יְהוָ֑ה הַקֹּל֣וֹת יֶחְדָּל֗וּן וְהַבָּרָד֙ לֹ֣א יִֽהְיֶה־ע֔וֹד לְמַ֣עַן תֵּדַ֔ע כִּ֥י לַֽיהוָ֖ה הָאָֽרֶץ׃"
          },
          {
            "num": "ל",
            "txt": "וְאַתָּ֖ה וַֽעֲבָדֶ֑יךָ יָדַ֕עְתִּי כִּ֚י טֶ֣רֶם תִּֽירְא֔וּן מִפְּנֵ֖י יְהוָ֥ה אֱלֹהִֽים׃"
          },
          {
            "num": "לא",
            "txt": "וְהַפִּשְׁתָּ֥ה וְהַשְּׂעֹרָ֖ה נֻכָּ֑תָה כִּ֤י הַשְּׂעֹרָה֙ אָבִ֔יב וְהַפִּשְׁתָּ֖ה גִּבְעֹֽל׃"
          },
          {
            "num": "לב",
            "txt": "וְהַחִטָּ֥ה וְהַכֻּסֶּ֖מֶת לֹ֣א נֻכּ֑וּ כִּ֥י אֲפִילֹ֖ת הֵֽנָּה׃"
          },
          {
            "num": "לג",
            "txt": "וַיֵּצֵ֨א מֹשֶׁ֜ה מֵעִ֤ם פַּרְעֹה֙ אֶת־הָעִ֔יר וַיִּפְרֹ֥שׂ כַּפָּ֖יו אֶל־יְהוָ֑ה וַֽיַּחְדְּל֤וּ הַקֹּלוֹת֙ וְהַבָּרָ֔ד וּמָטָ֖ר לֹֽא־נִתַּ֥ךְ אָֽרְצָה׃"
          },
          {
            "num": "לד",
            "txt": "וַיַּ֣רְא פַּרְעֹ֗ה כִּֽי־חָדַ֨ל הַמָּטָ֧ר וְהַבָּרָ֛ד וְהַקֹּלֹ֖ת וַיֹּ֣סֶף לַֽחֲטֹ֑א וַיַּכְבֵּ֥ד לִבּ֖וֹ ה֥וּא וַֽעֲבָדָֽיו׃"
          },
          {
            "num": "לה",
            "txt": "וַֽיֶּחֱזַק֙ לֵ֣ב פַּרְעֹ֔ה וְלֹ֥א שִׁלַּ֖ח אֶת־בְּנֵ֣י יִשְׂרָאֵ֑ל כַּֽאֲשֶׁ֛ר דִּבֶּ֥ר יְהוָ֖ה בְּיַד־מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "י",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה בֹּ֖א אֶל־פַּרְעֹ֑ה כִּֽי־אֲנִ֞י הִכְבַּ֤דְתִּי אֶת־לִבּוֹ֙ וְאֶת־לֵ֣ב עֲבָדָ֔יו לְמַ֗עַן שִׁתִ֛י אֹֽתֹתַ֥י אֵ֖לֶּה בְּקִרְבּֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וּלְמַ֡עַן תְּסַפֵּר֩ בְּאָזְנֵ֨י בִנְךָ֜ וּבֶן־בִּנְךָ֗ אֵ֣ת אֲשֶׁ֤ר הִתְעַלַּ֨לְתִּי֙ בְּמִצְרַ֔יִם וְאֶת־אֹֽתֹתַ֖י אֲשֶׁר־שַׂ֣מְתִּי בָ֑ם וִֽידַעְתֶּ֖ם כִּֽי־אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַיָּבֹ֨א מֹשֶׁ֣ה וְאַֽהֲרֹן֮ אֶל־פַּרְעֹה֒ וַיֹּֽאמְר֣וּ אֵלָ֗יו כֹּֽה־אָמַ֤ר יְהוָה֙ אֱלֹהֵ֣י הָֽעִבְרִ֔ים עַד־מָתַ֣י מֵאַ֔נְתָּ לֵֽעָנֹ֖ת מִפָּנָ֑י שַׁלַּ֥ח עַמִּ֖י וְיַֽעַבְדֻֽנִי׃"
          },
          {
            "num": "ד",
            "txt": "כִּ֛י אִם־מָאֵ֥ן אַתָּ֖ה לְשַׁלֵּ֣חַ אֶת־עַמִּ֑י הִנְנִ֨י מֵבִ֥יא מָחָ֛ר אַרְבֶּ֖ה בִּגְבֻלֶֽךָ׃"
          },
          {
            "num": "ה",
            "txt": "וְכִסָּה֙ אֶת־עֵ֣ין הָאָ֔רֶץ וְלֹ֥א יוּכַ֖ל לִרְאֹ֣ת אֶת־הָאָ֑רֶץ וְאָכַ֣ל ׀ אֶת־יֶ֣תֶר הַפְּלֵטָ֗ה הַנִּשְׁאֶ֤רֶת לָכֶם֙ מִן־הַבָּרָ֔ד וְאָכַל֙ אֶת־כָּל־הָעֵ֔ץ הַצֹּמֵ֥חַ לָכֶ֖ם מִן־הַשָּׂדֶֽה׃"
          },
          {
            "num": "ו",
            "txt": "וּמָֽלְא֨וּ בָתֶּ֜יךָ וּבָתֵּ֣י כָל־עֲבָדֶיךָ֮ וּבָתֵּ֣י כָל־מִצְרַיִם֒ אֲשֶׁ֨ר לֹֽא־רָא֤וּ אֲבֹתֶ֨יךָ֙ וַֽאֲב֣וֹת אֲבֹתֶ֔יךָ מִיּ֗וֹם הֱיוֹתָם֙ עַל־הָ֣אֲדָמָ֔ה עַ֖ד הַיּ֣וֹם הַזֶּ֑ה וַיִּ֥פֶן וַיֵּצֵ֖א מֵעִ֥ם פַּרְעֹֽה׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּֽאמְרוּ֩ עַבְדֵ֨י פַרְעֹ֜ה אֵלָ֗יו עַד־מָתַי֙ יִֽהְיֶ֨ה זֶ֥ה לָ֨נוּ֙ לְמוֹקֵ֔שׁ שַׁלַּח֙ אֶת־הָ֣אֲנָשִׁ֔ים וְיַֽעַבְד֖וּ אֶת־יְהוָ֣ה אֱלֹֽהֵיהֶ֑ם הֲטֶ֣רֶם תֵּדַ֔ע כִּ֥י אָֽבְדָ֖ה מִצְרָֽיִם׃"
          },
          {
            "num": "ח",
            "txt": "וַיּוּשַׁ֞ב אֶת־מֹשֶׁ֤ה וְאֶֽת־אַהֲרֹן֙ אֶל־פַּרְעֹ֔ה וַיֹּ֣אמֶר אֲלֵהֶ֔ם לְכ֥וּ עִבְד֖וּ אֶת־יְהוָ֣ה אֱלֹֽהֵיכֶ֑ם מִ֥י וָמִ֖י הַהֹֽלְכִֽים׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה בִּנְעָרֵ֥ינוּ וּבִזְקֵנֵ֖ינוּ נֵלֵ֑ךְ בְּבָנֵ֨ינוּ וּבִבְנוֹתֵ֜נוּ בְּצֹאנֵ֤נוּ וּבִבְקָרֵ֨נוּ֙ נֵלֵ֔ךְ כִּ֥י חַג־יְהוָ֖ה לָֽנוּ׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֣אמֶר אֲלֵהֶ֗ם יְהִ֨י כֵ֤ן יְהוָה֙ עִמָּכֶ֔ם כַּֽאֲשֶׁ֛ר אֲשַׁלַּ֥ח אֶתְכֶ֖ם וְאֶֽת־טַפְּכֶ֑ם רְא֕וּ כִּ֥י רָעָ֖ה נֶ֥גֶד פְּנֵיכֶֽם׃"
          },
          {
            "num": "יא",
            "txt": "לֹ֣א כֵ֗ן לְכֽוּ־נָ֤א הַגְּבָרִים֙ וְעִבְד֣וּ אֶת־יְהוָ֔ה כִּ֥י אֹתָ֖הּ אַתֶּ֣ם מְבַקְשִׁ֑ים וַיְגָ֣רֶשׁ אֹתָ֔ם מֵאֵ֖ת פְּנֵ֥י פַרְעֹֽה׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה נְטֵ֨ה יָֽדְךָ֜ עַל־אֶ֤רֶץ מִצְרַ֨יִם֙ בָּֽאַרְבֶּ֔ה וְיַ֖עַל עַל־אֶ֣רֶץ מִצְרָ֑יִם וְיֹאכַל֙ אֶת־כָּל־עֵ֣שֶׂב הָאָ֔רֶץ אֵ֛ת כָּל־אֲשֶׁ֥ר הִשְׁאִ֖יר הַבָּרָֽד׃"
          },
          {
            "num": "יג",
            "txt": "וַיֵּ֨ט מֹשֶׁ֣ה אֶת־מַטֵּהוּ֮ עַל־אֶ֣רֶץ מִצְרַיִם֒ וַֽיהוָ֗ה נִהַ֤ג רֽוּחַ־קָדִים֙ בָּאָ֔רֶץ כָּל־הַיּ֥וֹם הַה֖וּא וְכָל־הַלָּ֑יְלָה הַבֹּ֣קֶר הָיָ֔ה וְר֨וּחַ֙ הַקָּדִ֔ים נָשָׂ֖א אֶת־הָֽאַרְבֶּֽה׃"
          },
          {
            "num": "יד",
            "txt": "וַיַּ֣עַל הָֽאַרְבֶּ֗ה עַ֚ל כָּל־אֶ֣רֶץ מִצְרַ֔יִם וַיָּ֕נַח בְּכֹ֖ל גְּב֣וּל מִצְרָ֑יִם כָּבֵ֣ד מְאֹ֔ד לְ֠פָנָיו לֹא־הָ֨יָה כֵ֤ן אַרְבֶּה֙ כָּמֹ֔הוּ וְאַֽחֲרָ֖יו לֹ֥א יִֽהְיֶה־כֵּֽן׃"
          },
          {
            "num": "טו",
            "txt": "וַיְכַ֞ס אֶת־עֵ֣ין כָּל־הָאָרֶץ֮ וַתֶּחְשַׁ֣ךְ הָאָרֶץ֒ וַיֹּ֜אכַל אֶת־כָּל־עֵ֣שֶׂב הָאָ֗רֶץ וְאֵת֙ כָּל־פְּרִ֣י הָעֵ֔ץ אֲשֶׁ֥ר הוֹתִ֖יר הַבָּרָ֑ד וְלֹֽא־נוֹתַ֨ר כָּל־יֶ֧רֶק בָּעֵ֛ץ וּבְעֵ֥שֶׂב הַשָּׂדֶ֖ה בְּכָל־אֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "טז",
            "txt": "וַיְמַהֵ֣ר פַּרְעֹ֔ה לִקְרֹ֖א לְמֹשֶׁ֣ה וּֽלְאַהֲרֹ֑ן וַיֹּ֗אמֶר חָטָ֛אתִי לַֽיהוָ֥ה אֱלֹֽהֵיכֶ֖ם וְלָכֶֽם׃"
          },
          {
            "num": "יז",
            "txt": "וְעַתָּ֗ה שָׂ֣א נָ֤א חַטָּאתִי֙ אַ֣ךְ הַפַּ֔עַם וְהַעְתִּ֖ירוּ לַֽיהוָ֣ה אֱלֹֽהֵיכֶ֑ם וְיָסֵר֙ מֵֽעָלַ֔י רַ֖ק אֶת־הַמָּ֥וֶת הַזֶּֽה׃"
          },
          {
            "num": "יח",
            "txt": "וַיֵּצֵ֖א מֵעִ֣ם פַּרְעֹ֑ה וַיֶּעְתַּ֖ר אֶל־יְהוָֽה׃"
          },
          {
            "num": "יט",
            "txt": "וַיַּֽהֲפֹ֨ךְ יְהוָ֤ה רֽוּחַ־יָם֙ חָזָ֣ק מְאֹ֔ד וַיִּשָּׂא֙ אֶת־הָ֣אַרְבֶּ֔ה וַיִּתְקָעֵ֖הוּ יָ֣מָּה סּ֑וּף לֹ֤א נִשְׁאַר֙ אַרְבֶּ֣ה אֶחָ֔ד בְּכֹ֖ל גְּב֥וּל מִצְרָֽיִם׃"
          },
          {
            "num": "כ",
            "txt": "וַיְחַזֵּ֥ק יְהוָ֖ה אֶת־לֵ֣ב פַּרְעֹ֑ה וְלֹ֥א שִׁלַּ֖ח אֶת־בְּנֵ֥י יִשְׂרָאֵֽל׃ {פ}"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה נְטֵ֤ה יָֽדְךָ֙ עַל־הַשָּׁמַ֔יִם וִ֥יהִי חֹ֖שֶׁךְ עַל־אֶ֣רֶץ מִצְרָ֑יִם וְיָמֵ֖שׁ חֹֽשֶׁךְ׃"
          },
          {
            "num": "כב",
            "txt": "וַיֵּ֥ט מֹשֶׁ֛ה אֶת־יָד֖וֹ עַל־הַשָּׁמָ֑יִם וַיְהִ֧י חֹֽשֶׁךְ־אֲפֵלָ֛ה בְּכָל־אֶ֥רֶץ מִצְרַ֖יִם שְׁלֹ֥שֶׁת יָמִֽים׃"
          },
          {
            "num": "כג",
            "txt": "לֹֽא־רָא֞וּ אִ֣ישׁ אֶת־אָחִ֗יו וְלֹא־קָ֛מוּ אִ֥ישׁ מִתַּחְתָּ֖יו שְׁלֹ֣שֶׁת יָמִ֑ים וּֽלְכָל־בְּנֵ֧י יִשְׂרָאֵ֛ל הָ֥יָה א֖וֹר בְּמֽוֹשְׁבֹתָֽם׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּקְרָ֨א פַרְעֹ֜ה אֶל־מֹשֶׁ֗ה וַיֹּ֨אמֶר֙ לְכוּ֙ עִבְד֣וּ אֶת־יְהוָ֔ה רַ֛ק צֹֽאנְכֶ֥ם וּבְקַרְכֶ֖ם יֻצָּ֑ג גַּֽם־טַפְּכֶ֖ם יֵלֵ֥ךְ עִמָּכֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה גַּם־אַתָּ֛ה תִּתֵּ֥ן בְּיָדֵ֖נוּ זְבָחִ֣ים וְעֹלֹ֑ת וְעָשִׂ֖ינוּ לַֽיהוָ֥ה אֱלֹהֵֽינוּ׃"
          },
          {
            "num": "כו",
            "txt": "וְגַם־מִקְנֵ֜נוּ יֵלֵ֣ךְ עִמָּ֗נוּ לֹ֤א תִשָּׁאֵר֙ פַּרְסָ֔ה כִּ֚י מִמֶּ֣נּוּ נִקַּ֔ח לַֽעֲבֹ֖ד אֶת־יְהוָ֣ה אֱלֹהֵ֑ינוּ וַֽאֲנַ֣חְנוּ לֹֽא־נֵדַ֗ע מַֽה־נַּעֲבֹד֙ אֶת־יְהוָ֔ה עַד־בֹּאֵ֖נוּ שָֽׁמָּה׃"
          },
          {
            "num": "כז",
            "txt": "וַיְחַזֵּ֥ק יְהוָ֖ה אֶת־לֵ֣ב פַּרְעֹ֑ה וְלֹ֥א אָבָ֖ה לְשַׁלְּחָֽם׃"
          },
          {
            "num": "כח",
            "txt": "וַיֹּֽאמֶר־ל֥וֹ פַרְעֹ֖ה לֵ֣ךְ מֵֽעָלָ֑י הִשָּׁ֣מֶר לְךָ֗ אַל־תֹּ֨סֶף֙ רְא֣וֹת פָּנַ֔י כִּ֗י בְּי֛וֹם רְאֹֽתְךָ֥ פָנַ֖י תָּמֽוּת׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֥אמֶר מֹשֶׁ֖ה כֵּ֣ן דִּבַּ֑רְתָּ לֹֽא־אֹסִ֥ף ע֖וֹד רְא֥וֹת פָּנֶֽיךָ׃ {פ}"
          }
        ]
      },
      {
        "num": "יא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה ע֣וֹד נֶ֤גַע אֶחָד֙ אָבִ֤יא עַל־פַּרְעֹה֙ וְעַל־מִצְרַ֔יִם אַֽחֲרֵי־כֵ֕ן יְשַׁלַּ֥ח אֶתְכֶ֖ם מִזֶּ֑ה כְּשַׁ֨לְּח֔וֹ כָּלָ֕ה גָּרֵ֛שׁ יְגָרֵ֥שׁ אֶתְכֶ֖ם מִזֶּֽה׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֶּר־נָ֖א בְּאָזְנֵ֣י הָעָ֑ם וְיִשְׁאֲל֞וּ אִ֣ישׁ ׀ מֵאֵ֣ת רֵעֵ֗הוּ וְאִשָּׁה֙ מֵאֵ֣ת רְעוּתָ֔הּ כְּלֵי־כֶ֖סֶף וּכְלֵ֥י זָהָֽב׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּתֵּ֧ן יְהוָ֛ה אֶת־חֵ֥ן הָעָ֖ם בְּעֵינֵ֣י מִצְרָ֑יִם גַּ֣ם ׀ הָאִ֣ישׁ מֹשֶׁ֗ה גָּד֤וֹל מְאֹד֙ בְּאֶ֣רֶץ מִצְרַ֔יִם בְּעֵינֵ֥י עַבְדֵֽי־פַרְעֹ֖ה וּבְעֵינֵ֥י הָעָֽם׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה כֹּ֖ה אָמַ֣ר יְהוָ֑ה כַּֽחֲצֹ֣ת הַלַּ֔יְלָה אֲנִ֥י יוֹצֵ֖א בְּת֥וֹךְ מִצְרָֽיִם׃"
          },
          {
            "num": "ה",
            "txt": "וּמֵ֣ת כָּל־בְּכוֹר֮ בְּאֶ֣רֶץ מִצְרַיִם֒ מִבְּכ֤וֹר פַּרְעֹה֙ הַיֹּשֵׁ֣ב עַל־כִּסְא֔וֹ עַ֚ד בְּכ֣וֹר הַשִּׁפְחָ֔ה אֲשֶׁ֖ר אַחַ֣ר הָֽרֵחָ֑יִם וְכֹ֖ל בְּכ֥וֹר בְּהֵמָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וְהָ֥יְתָ֛ה צְעָקָ֥ה גְדֹלָ֖ה בְּכָל־אֶ֣רֶץ מִצְרָ֑יִם אֲשֶׁ֤ר כָּמֹ֨הוּ֙ לֹ֣א נִֽהְיָ֔תָה וְכָמֹ֖הוּ לֹ֥א תֹסִֽף׃"
          },
          {
            "num": "ז",
            "txt": "וּלְכֹ֣ל ׀ בְּנֵ֣י יִשְׂרָאֵ֗ל לֹ֤א יֶֽחֱרַץ־כֶּ֨לֶב֙ לְשֹׁנ֔וֹ לְמֵאִ֖ישׁ וְעַד־בְּהֵמָ֑ה לְמַ֨עַן֙ תֵּֽדְע֔וּן אֲשֶׁר֙ יַפְלֶ֣ה יְהוָ֔ה בֵּ֥ין מִצְרַ֖יִם וּבֵ֥ין יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ח",
            "txt": "וְיָֽרְד֣וּ כָל־עֲבָדֶיךָ֩ אֵ֨לֶּה אֵלַ֜י וְהִשְׁתַּֽחֲווּ־לִ֣י לֵאמֹ֗ר צֵ֤א אַתָּה֙ וְכָל־הָעָ֣ם אֲשֶׁר־בְּרַגְלֶ֔יךָ וְאַֽחֲרֵי־כֵ֖ן אֵצֵ֑א וַיֵּצֵ֥א מֵֽעִם־פַּרְעֹ֖ה בָּֽחֳרִי־אָֽף׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה לֹֽא־יִשְׁמַ֥ע אֲלֵיכֶ֖ם פַּרְעֹ֑ה לְמַ֛עַן רְב֥וֹת מֽוֹפְתַ֖י בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "י",
            "txt": "וּמֹשֶׁ֣ה וְאַֽהֲרֹ֗ן עָשׂ֛וּ אֶת־כָּל־הַמֹּֽפְתִ֥ים הָאֵ֖לֶּה לִפְנֵ֣י פַרְעֹ֑ה וַיְחַזֵּ֤ק יְהוָה֙ אֶת־לֵ֣ב פַּרְעֹ֔ה וְלֹֽא־שִׁלַּ֥ח אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל מֵֽאַרְצֽוֹ׃ {ס}"
          }
        ]
      },
      {
        "num": "יב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֣ה וְאֶֽל־אַהֲרֹ֔ן בְּאֶ֥רֶץ מִצְרַ֖יִם לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "הַחֹ֧דֶשׁ הַזֶּ֛ה לָכֶ֖ם רֹ֣אשׁ חֳדָשִׁ֑ים רִאשׁ֥וֹן הוּא֙ לָכֶ֔ם לְחָדְשֵׁ֖י הַשָּׁנָֽה׃"
          },
          {
            "num": "ג",
            "txt": "דַּבְּר֗וּ אֶֽל־כָּל־עֲדַ֤ת יִשְׂרָאֵל֙ לֵאמֹ֔ר בֶּֽעָשֹׂ֖ר לַחֹ֣דֶשׁ הַזֶּ֑ה וְיִקְח֣וּ לָהֶ֗ם אִ֛ישׁ שֶׂ֥ה לְבֵית־אָבֹ֖ת שֶׂ֥ה לַבָּֽיִת׃"
          },
          {
            "num": "ד",
            "txt": "וְאִם־יִמְעַ֣ט הַבַּיִת֮ מִֽהְי֣וֹת מִשֶּׂה֒ וְלָקַ֣ח ה֗וּא וּשְׁכֵנ֛וֹ הַקָּרֹ֥ב אֶל־בֵּית֖וֹ בְּמִכְסַ֣ת נְפָשֹׁ֑ת אִ֚ישׁ לְפִ֣י אָכְל֔וֹ תָּכֹ֖סּוּ עַל־הַשֶּֽׂה׃"
          },
          {
            "num": "ה",
            "txt": "שֶׂ֥ה תָמִ֛ים זָכָ֥ר בֶּן־שָׁנָ֖ה יִֽהְיֶ֣ה לָכֶ֑ם מִן־הַכְּבָשִׂ֥ים וּמִן־הָֽעִזִּ֖ים תִּקָּֽחוּ׃"
          },
          {
            "num": "ו",
            "txt": "וְהָיָ֤ה לָכֶם֙ לְמִשְׁמֶ֔רֶת עַ֣ד אַרְבָּעָ֥ה עָשָׂ֛ר י֖וֹם לַחֹ֣דֶשׁ הַזֶּ֑ה וְשָֽׁחֲט֣וּ אֹת֗וֹ כֹּ֛ל קְהַ֥ל עֲדַֽת־יִשְׂרָאֵ֖ל בֵּ֥ין הָֽעַרְבָּֽיִם׃"
          },
          {
            "num": "ז",
            "txt": "וְלָֽקְחוּ֙ מִן־הַדָּ֔ם וְנָ֥תְנ֛וּ עַל־שְׁתֵּ֥י הַמְּזוּזֹ֖ת וְעַל־הַמַּשְׁק֑וֹף עַ֚ל הַבָּ֣תִּ֔ים אֲשֶׁר־יֹֽאכְל֥וּ אֹת֖וֹ בָּהֶֽם׃"
          },
          {
            "num": "ח",
            "txt": "וְאָֽכְל֥וּ אֶת־הַבָּשָׂ֖ר בַּלַּ֣יְלָה הַזֶּ֑ה צְלִי־אֵ֣שׁ וּמַצּ֔וֹת עַל־מְרֹרִ֖ים יֹֽאכְלֻֽהוּ׃"
          },
          {
            "num": "ט",
            "txt": "אַל־תֹּֽאכְל֤וּ מִמֶּ֨נּוּ֙ נָ֔א וּבָשֵׁ֥ל מְבֻשָּׁ֖ל בַּמָּ֑יִם כִּ֣י אִם־צְלִי־אֵ֔שׁ רֹאשׁ֥וֹ עַל־כְּרָעָ֖יו וְעַל־קִרְבּֽוֹ׃"
          },
          {
            "num": "י",
            "txt": "וְלֹֽא־תוֹתִ֥ירוּ מִמֶּ֖נּוּ עַד־בֹּ֑קֶר וְהַנֹּתָ֥ר מִמֶּ֛נּוּ עַד־בֹּ֖קֶר בָּאֵ֥שׁ תִּשְׂרֹֽפוּ׃"
          },
          {
            "num": "יא",
            "txt": "וְכָכָה֮ תֹּֽאכְל֣וּ אֹתוֹ֒ מָתְנֵיכֶ֣ם חֲגֻרִ֔ים נַֽעֲלֵיכֶם֙ בְּרַגְלֵיכֶ֔ם וּמַקֶּלְכֶ֖ם בְּיֶדְכֶ֑ם וַֽאֲכַלְתֶּ֤ם אֹתוֹ֙ בְּחִפָּז֔וֹן פֶּ֥סַח ה֖וּא לַֽיהוָֽה׃"
          },
          {
            "num": "יב",
            "txt": "וְעָֽבַרְתִּ֣י בְאֶֽרֶץ־מִצְרַיִם֮ בַּלַּ֣יְלָה הַזֶּה֒ וְהִכֵּיתִ֤י כָל־בְּכוֹר֙ בְּאֶ֣רֶץ מִצְרַ֔יִם מֵֽאָדָ֖ם וְעַד־בְּהֵמָ֑ה וּבְכָל־אֱלֹהֵ֥י מִצְרַ֛יִם אֶֽעֱשֶׂ֥ה שְׁפָטִ֖ים אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְהָיָה֩ הַדָּ֨ם לָכֶ֜ם לְאֹ֗ת עַ֤ל הַבָּתִּים֙ אֲשֶׁ֣ר אַתֶּ֣ם שָׁ֔ם וְרָאִ֨יתִי֙ אֶת־הַדָּ֔ם וּפָֽסַחְתִּ֖י עֲלֵכֶ֑ם וְלֹֽא־יִֽהְיֶ֨ה בָכֶ֥ם נֶ֨גֶף֙ לְמַשְׁחִ֔ית בְּהַכֹּתִ֖י בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "יד",
            "txt": "וְהָיָה֩ הַיּ֨וֹם הַזֶּ֤ה לָכֶם֙ לְזִכָּר֔וֹן וְחַגֹּתֶ֥ם אֹת֖וֹ חַ֣ג לַֽיהוָ֑ה לְדֹרֹ֣תֵיכֶ֔ם חֻקַּ֥ת עוֹלָ֖ם תְּחָגֻּֽהוּ׃"
          },
          {
            "num": "טו",
            "txt": "שִׁבְעַ֤ת יָמִים֙ מַצּ֣וֹת תֹּאכֵ֔לוּ אַ֚ךְ בַּיּ֣וֹם הָֽרִאשׁ֔וֹן תַּשְׁבִּ֥יתוּ שְּׂאֹ֖ר מִבָּֽתֵּיכֶ֑ם כִּ֣י ׀ כָּל־אֹכֵ֣ל חָמֵ֗ץ וְנִכְרְתָ֞ה הַנֶּ֤פֶשׁ הַהִוא֙ מִיִּשְׂרָאֵ֔ל מִיּ֥וֹם הָֽרִאשֹׁ֖ן עַד־י֥וֹם הַשְּׁבִעִֽי׃"
          },
          {
            "num": "טז",
            "txt": "וּבַיּ֤וֹם הָֽרִאשׁוֹן֙ מִקְרָא־קֹ֔דֶשׁ וּבַיּוֹם֙ הַשְּׁבִיעִ֔י מִקְרָא־קֹ֖דֶשׁ יִֽהְיֶ֣ה לָכֶ֑ם כָּל־מְלָאכָה֙ לֹֽא־יֵעָשֶׂ֣ה בָהֶ֔ם אַ֚ךְ אֲשֶׁ֣ר יֵֽאָכֵ֣ל לְכָל־נֶ֔פֶשׁ ה֥וּא לְבַדּ֖וֹ יֵֽעָשֶׂ֥ה לָכֶֽם׃"
          },
          {
            "num": "יז",
            "txt": "וּשְׁמַרְתֶּם֮ אֶת־הַמַּצּוֹת֒ כִּ֗י בְּעֶ֨צֶם֙ הַיּ֣וֹם הַזֶּ֔ה הוֹצֵ֥אתִי אֶת־צִבְאֽוֹתֵיכֶ֖ם מֵאֶ֣רֶץ מִצְרָ֑יִם וּשְׁמַרְתֶּ֞ם אֶת־הַיּ֥וֹם הַזֶּ֛ה לְדֹרֹֽתֵיכֶ֖ם חֻקַּ֥ת עוֹלָֽם׃"
          },
          {
            "num": "יח",
            "txt": "בָּֽרִאשֹׁ֡ן בְּאַרְבָּעָה֩ עָשָׂ֨ר י֤וֹם לַחֹ֨דֶשׁ֙ בָּעֶ֔רֶב תֹּֽאכְל֖וּ מַצֹּ֑ת עַ֠ד י֣וֹם הָֽאֶחָ֧ד וְעֶשְׂרִ֛ים לַחֹ֖דֶשׁ בָּעָֽרֶב׃"
          },
          {
            "num": "יט",
            "txt": "שִׁבְעַ֣ת יָמִ֔ים שְׂאֹ֕ר לֹ֥א יִמָּצֵ֖א בְּבָֽתֵּיכֶ֑ם כִּ֣י ׀ כָּל־אֹכֵ֣ל מַחְמֶ֗צֶת וְנִכְרְתָ֞ה הַנֶּ֤פֶשׁ הַהִוא֙ מֵֽעֲדַ֣ת יִשְׂרָאֵ֔ל בַּגֵּ֖ר וּבְאֶזְרַ֥ח הָאָֽרֶץ׃"
          },
          {
            "num": "כ",
            "txt": "כָּל־מַחְמֶ֖צֶת לֹ֣א תֹאכֵ֑לוּ בְּכֹל֙ מוֹשְׁבֹ֣תֵיכֶ֔ם תֹּֽאכְל֖וּ מַצּֽוֹת׃ {פ}"
          },
          {
            "num": "כא",
            "txt": "וַיִּקְרָ֥א מֹשֶׁ֛ה לְכָל־זִקְנֵ֥י יִשְׂרָאֵ֖ל וַיֹּ֣אמֶר אֲלֵהֶ֑ם מִֽשְׁכ֗וּ וּקְח֨וּ לָכֶ֥ם צֹ֛אן לְמִשְׁפְּחֹֽתֵיכֶ֖ם וְשַֽׁחֲט֥וּ הַפָּֽסַח׃"
          },
          {
            "num": "כב",
            "txt": "וּלְקַחְתֶּ֞ם אֲגֻדַּ֣ת אֵז֗וֹב וּטְבַלְתֶּם֮ בַּדָּ֣ם אֲשֶׁר־בַּסַּף֒ וְהִגַּעְתֶּ֤ם אֶל־הַמַּשְׁקוֹף֙ וְאֶל־שְׁתֵּ֣י הַמְּזוּזֹ֔ת מִן־הַדָּ֖ם אֲשֶׁ֣ר בַּסָּ֑ף וְאַתֶּ֗ם לֹ֥א תֵֽצְא֛וּ אִ֥ישׁ מִפֶּֽתַח־בֵּית֖וֹ עַד־בֹּֽקֶר׃"
          },
          {
            "num": "כג",
            "txt": "וְעָבַ֣ר יְהוָה֮ לִנְגֹּ֣ף אֶת־מִצְרַיִם֒ וְרָאָ֤ה אֶת־הַדָּם֙ עַל־הַמַּשְׁק֔וֹף וְעַ֖ל שְׁתֵּ֣י הַמְּזוּזֹ֑ת וּפָסַ֤ח יְהוָה֙ עַל־הַפֶּ֔תַח וְלֹ֤א יִתֵּן֙ הַמַּשְׁחִ֔ית לָבֹ֥א אֶל־בָּֽתֵּיכֶ֖ם לִנְגֹּֽף׃"
          },
          {
            "num": "כד",
            "txt": "וּשְׁמַרְתֶּ֖ם אֶת־הַדָּבָ֣ר הַזֶּ֑ה לְחָק־לְךָ֥ וּלְבָנֶ֖יךָ עַד־עוֹלָֽם׃"
          },
          {
            "num": "כה",
            "txt": "וְהָיָ֞ה כִּֽי־תָבֹ֣אוּ אֶל־הָאָ֗רֶץ אֲשֶׁ֨ר יִתֵּ֧ן יְהוָ֛ה לָכֶ֖ם כַּֽאֲשֶׁ֣ר דִּבֵּ֑ר וּשְׁמַרְתֶּ֖ם אֶת־הָֽעֲבֹדָ֥ה הַזֹּֽאת׃"
          },
          {
            "num": "כו",
            "txt": "וְהָיָ֕ה כִּֽי־יֹאמְר֥וּ אֲלֵיכֶ֖ם בְּנֵיכֶ֑ם מָ֛ה הָֽעֲבֹדָ֥ה הַזֹּ֖את לָכֶֽם׃"
          },
          {
            "num": "כז",
            "txt": "וַֽאֲמַרְתֶּ֡ם זֶֽבַח־פֶּ֨סַח ה֜וּא לַֽיהוָ֗ה אֲשֶׁ֣ר פָּ֠סַח עַל־בָּתֵּ֤י בְנֵֽי־יִשְׂרָאֵל֙ בְּמִצְרַ֔יִם בְּנָגְפּ֥וֹ אֶת־מִצְרַ֖יִם וְאֶת־בָּתֵּ֣ינוּ הִצִּ֑יל וַיִּקֹּ֥ד הָעָ֖ם וַיִּֽשְׁתַּחֲוֽוּ׃"
          },
          {
            "num": "כח",
            "txt": "וַיֵּֽלְכ֥וּ וַֽיַּעֲשׂ֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל כַּֽאֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֶת־מֹשֶׁ֥ה וְאַֽהֲרֹ֖ן כֵּ֥ן עָשֽׂוּ׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "וַיְהִ֣י ׀ בַּֽחֲצִ֣י הַלַּ֗יְלָה וַֽיהוָה֮ הִכָּ֣ה כָל־בְּכוֹר֮ בְּאֶ֣רֶץ מִצְרַיִם֒ מִבְּכֹ֤ר פַּרְעֹה֙ הַיֹּשֵׁ֣ב עַל־כִּסְא֔וֹ עַ֚ד בְּכ֣וֹר הַשְּׁבִ֔י אֲשֶׁ֖ר בְּבֵ֣ית הַבּ֑וֹר וְכֹ֖ל בְּכ֥וֹר בְּהֵמָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וַיָּ֨קָם פַּרְעֹ֜ה לַ֗יְלָה ה֤וּא וְכָל־עֲבָדָיו֙ וְכָל־מִצְרַ֔יִם וַתְּהִ֛י צְעָקָ֥ה גְדֹלָ֖ה בְּמִצְרָ֑יִם כִּֽי־אֵ֣ין בַּ֔יִת אֲשֶׁ֥ר אֵֽין־שָׁ֖ם מֵֽת׃"
          },
          {
            "num": "לא",
            "txt": "וַיִּקְרָא֩ לְמֹשֶׁ֨ה וּֽלְאַהֲרֹ֜ן לַ֗יְלָה וַיֹּ֨אמֶר֙ ק֤וּמוּ צְּאוּ֙ מִתּ֣וֹךְ עַמִּ֔י גַּם־אַתֶּ֖ם גַּם־בְּנֵ֣י יִשְׂרָאֵ֑ל וּלְכ֛וּ עִבְד֥וּ אֶת־יְהוָ֖ה כְּדַבֶּרְכֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "גַּם־צֹֽאנְכֶ֨ם גַּם־בְּקַרְכֶ֥ם קְח֛וּ כַּֽאֲשֶׁ֥ר דִּבַּרְתֶּ֖ם וָלֵ֑כוּ וּבֵֽרַכְתֶּ֖ם גַּם־אֹתִֽי׃"
          },
          {
            "num": "לג",
            "txt": "וַתֶּֽחֱזַ֤ק מִצְרַ֨יִם֙ עַל־הָעָ֔ם לְמַהֵ֖ר לְשַׁלְּחָ֣ם מִן־הָאָ֑רֶץ כִּ֥י אָֽמְר֖וּ כֻּלָּ֥נוּ מֵתִֽים׃"
          },
          {
            "num": "לד",
            "txt": "וַיִּשָּׂ֥א הָעָ֛ם אֶת־בְּצֵק֖וֹ טֶ֣רֶם יֶחְמָ֑ץ מִשְׁאֲרֹתָ֛ם צְרֻרֹ֥ת בְּשִׂמְלֹתָ֖ם עַל־שִׁכְמָֽם׃"
          },
          {
            "num": "לה",
            "txt": "וּבְנֵֽי־יִשְׂרָאֵ֥ל עָשׂ֖וּ כִּדְבַ֣ר מֹשֶׁ֑ה וַֽיִּשְׁאֲלוּ֙ מִמִּצְרַ֔יִם כְּלֵי־כֶ֛סֶף וּכְלֵ֥י זָהָ֖ב וּשְׂמָלֹֽת׃"
          },
          {
            "num": "לו",
            "txt": "וַֽיהוָ֞ה נָתַ֨ן אֶת־חֵ֥ן הָעָ֛ם בְּעֵינֵ֥י מִצְרַ֖יִם וַיַּשְׁאִל֑וּם וַֽיְנַצְּל֖וּ אֶת־מִצְרָֽיִם׃ {פ}"
          },
          {
            "num": "לז",
            "txt": "וַיִּסְע֧וּ בְנֵֽי־יִשְׂרָאֵ֛ל מֵֽרַעְמְסֵ֖ס סֻכֹּ֑תָה כְּשֵׁשׁ־מֵא֨וֹת אֶ֧לֶף רַגְלִ֛י הַגְּבָרִ֖ים לְבַ֥ד מִטָּֽף׃"
          },
          {
            "num": "לח",
            "txt": "וְגַם־עֵ֥רֶב רַ֖ב עָלָ֣ה אִתָּ֑ם וְצֹ֣אן וּבָקָ֔ר מִקְנֶ֖ה כָּבֵ֥ד מְאֹֽד׃"
          },
          {
            "num": "לט",
            "txt": "וַיֹּאפ֨וּ אֶת־הַבָּצֵ֜ק אֲשֶׁ֨ר הוֹצִ֧יאוּ מִמִּצְרַ֛יִם עֻגֹ֥ת מַצּ֖וֹת כִּ֣י לֹ֣א חָמֵ֑ץ כִּֽי־גֹרְשׁ֣וּ מִמִּצְרַ֗יִם וְלֹ֤א יָֽכְלוּ֙ לְהִתְמַהְמֵ֔הַּ וְגַם־צֵדָ֖ה לֹֽא־עָשׂ֥וּ לָהֶֽם׃"
          },
          {
            "num": "מ",
            "txt": "וּמוֹשַׁב֙ בְּנֵ֣י יִשְׂרָאֵ֔ל אֲשֶׁ֥ר יָֽשְׁב֖וּ בְּמִצְרָ֑יִם שְׁלֹשִׁ֣ים שָׁנָ֔ה וְאַרְבַּ֥ע מֵא֖וֹת שָׁנָֽה׃"
          },
          {
            "num": "מא",
            "txt": "וַיְהִ֗י מִקֵּץ֙ שְׁלֹשִׁ֣ים שָׁנָ֔ה וְאַרְבַּ֥ע מֵא֖וֹת שָׁנָ֑ה וַיְהִ֗י בְּעֶ֨צֶם֙ הַיּ֣וֹם הַזֶּ֔ה יָ֥צְא֛וּ כָּל־צִבְא֥וֹת יְהוָ֖ה מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "מב",
            "txt": "לֵ֣יל שִׁמֻּרִ֥ים הוּא֙ לַֽיהוָ֔ה לְהֽוֹצִיאָ֖ם מֵאֶ֣רֶץ מִצְרָ֑יִם הֽוּא־הַלַּ֤יְלָה הַזֶּה֙ לַֽיהוָ֔ה שִׁמֻּרִ֛ים לְכָל־בְּנֵ֥י יִשְׂרָאֵ֖ל לְדֹֽרֹתָֽם׃ {פ}"
          },
          {
            "num": "מג",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֣ה וְאַֽהֲרֹ֔ן זֹ֖את חֻקַּ֣ת הַפָּ֑סַח כָּל־בֶּן־נֵכָ֖ר לֹא־יֹ֥אכַל בּֽוֹ׃"
          },
          {
            "num": "מד",
            "txt": "וְכָל־עֶ֥בֶד אִ֖ישׁ מִקְנַת־כָּ֑סֶף וּמַלְתָּ֣ה אֹת֔וֹ אָ֖ז יֹ֥אכַל בּֽוֹ׃"
          },
          {
            "num": "מה",
            "txt": "תּוֹשָׁ֥ב וְשָׂכִ֖יר לֹא־יֹ֥אכַל בּֽוֹ׃"
          },
          {
            "num": "מו",
            "txt": "בְּבַ֤יִת אֶחָד֙ יֵֽאָכֵ֔ל לֹֽא־תוֹצִ֧יא מִן־הַבַּ֛יִת מִן־הַבָּשָׂ֖ר ח֑וּצָה וְעֶ֖צֶם לֹ֥א תִשְׁבְּרוּ־בֽוֹ׃"
          },
          {
            "num": "מז",
            "txt": "כָּל־עֲדַ֥ת יִשְׂרָאֵ֖ל יַֽעֲשׂ֥וּ אֹתֽוֹ׃"
          },
          {
            "num": "מח",
            "txt": "וְכִֽי־יָג֨וּר אִתְּךָ֜ גֵּ֗ר וְעָ֣שָׂה פֶסַח֮ לַֽיהוָה֒ הִמּ֧וֹל ל֣וֹ כָל־זָכָ֗ר וְאָז֙ יִקְרַ֣ב לַֽעֲשֹׂת֔וֹ וְהָיָ֖ה כְּאֶזְרַ֣ח הָאָ֑רֶץ וְכָל־עָרֵ֖ל לֹֽא־יֹ֥אכַל בּֽוֹ׃"
          },
          {
            "num": "מט",
            "txt": "תּוֹרָ֣ה אַחַ֔ת יִֽהְיֶ֖ה לָֽאֶזְרָ֑ח וְלַגֵּ֖ר הַגָּ֥ר בְּתֽוֹכְכֶֽם׃"
          },
          {
            "num": "נ",
            "txt": "וַֽיַּעֲשׂ֖וּ כָּל־בְּנֵ֣י יִשְׂרָאֵ֑ל כַּֽאֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֶת־מֹשֶׁ֥ה וְאֶֽת־אַהֲרֹ֖ן כֵּ֥ן עָשֽׂוּ׃ {ס}"
          },
          {
            "num": "נא",
            "txt": "וַיְהִ֕י בְּעֶ֖צֶם הַיּ֣וֹם הַזֶּ֑ה הוֹצִ֨יא יְהוָ֜ה אֶת־בְּנֵ֧י יִשְׂרָאֵ֛ל מֵאֶ֥רֶץ מִצְרַ֖יִם עַל־צִבְאֹתָֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "יג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "קַדֶּשׁ־לִ֨י כָל־בְּכ֜וֹר פֶּ֤טֶר כָּל־רֶ֨חֶם֙ בִּבְנֵ֣י יִשְׂרָאֵ֔ל בָּֽאָדָ֖ם וּבַבְּהֵמָ֑ה לִ֖י הֽוּא׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶל־הָעָ֗ם זָכ֞וֹר אֶת־הַיּ֤וֹם הַזֶּה֙ אֲשֶׁ֨ר יְצָאתֶ֤ם מִמִּצְרַ֨יִם֙ מִבֵּ֣ית עֲבָדִ֔ים כִּ֚י בְּחֹ֣זֶק יָ֔ד הוֹצִ֧יא יְהוָ֛ה אֶתְכֶ֖ם מִזֶּ֑ה וְלֹ֥א יֵֽאָכֵ֖ל חָמֵֽץ׃"
          },
          {
            "num": "ד",
            "txt": "הַיּ֖וֹם אַתֶּ֣ם יֹֽצְאִ֑ים בְּחֹ֖דֶשׁ הָֽאָבִֽיב׃"
          },
          {
            "num": "ה",
            "txt": "וְהָיָ֣ה כִֽי־יְבִֽיאֲךָ֣ יְהוָ֡ה אֶל־אֶ֣רֶץ הַֽ֠כְּנַעֲנִי וְהַֽחִתִּ֨י וְהָֽאֱמֹרִ֜י וְהַֽחִוִּ֣י וְהַיְבוּסִ֗י אֲשֶׁ֨ר נִשְׁבַּ֤ע לַֽאֲבֹתֶ֨יךָ֙ לָ֣תֶת לָ֔ךְ אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָ֑שׁ וְעָֽבַדְתָּ֛ אֶת־הָֽעֲבֹדָ֥ה הַזֹּ֖את בַּחֹ֥דֶשׁ הַזֶּֽה׃"
          },
          {
            "num": "ו",
            "txt": "שִׁבְעַ֥ת יָמִ֖ים תֹּאכַ֣ל מַצֹּ֑ת וּבַיּוֹם֙ הַשְּׁבִיעִ֔י חַ֖ג לַֽיהוָֽה׃"
          },
          {
            "num": "ז",
            "txt": "מַצּוֹת֙ יֵֽאָכֵ֔ל אֵ֖ת שִׁבְעַ֣ת הַיָּמִ֑ים וְלֹֽא־יֵרָאֶ֨ה לְךָ֜ חָמֵ֗ץ וְלֹֽא־יֵרָאֶ֥ה לְךָ֛ שְׂאֹ֖ר בְּכָל־גְּבֻלֶֽךָ׃"
          },
          {
            "num": "ח",
            "txt": "וְהִגַּדְתָּ֣ לְבִנְךָ֔ בַּיּ֥וֹם הַה֖וּא לֵאמֹ֑ר בַּֽעֲב֣וּר זֶ֗ה עָשָׂ֤ה יְהוָה֙ לִ֔י בְּצֵאתִ֖י מִמִּצְרָֽיִם׃"
          },
          {
            "num": "ט",
            "txt": "וְהָיָה֩ לְךָ֨ לְא֜וֹת עַל־יָֽדְךָ֗ וּלְזִכָּרוֹן֙ בֵּ֣ין עֵינֶ֔יךָ לְמַ֗עַן תִּֽהְיֶ֛ה תּוֹרַ֥ת יְהוָ֖ה בְּפִ֑יךָ כִּ֚י בְּיָ֣ד חֲזָקָ֔ה הוֹצִֽאֲךָ֥ יְהוָ֖ה מִמִּצְרָֽיִם׃"
          },
          {
            "num": "י",
            "txt": "וְשָֽׁמַרְתָּ֛ אֶת־הַחֻקָּ֥ה הַזֹּ֖את לְמֽוֹעֲדָ֑הּ מִיָּמִ֖ים יָמִֽימָה׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וְהָיָ֞ה כִּֽי־יְבִֽאֲךָ֤ יְהוָה֙ אֶל־אֶ֣רֶץ הַֽכְּנַעֲנִ֔י כַּֽאֲשֶׁ֛ר נִשְׁבַּ֥ע לְךָ֖ וְלַֽאֲבֹתֶ֑יךָ וּנְתָנָ֖הּ לָֽךְ׃"
          },
          {
            "num": "יב",
            "txt": "וְהַֽעֲבַרְתָּ֥ כָל־פֶּֽטֶר־רֶ֖חֶם לַֽיהוָ֑ה וְכָל־פֶּ֣טֶר ׀ שֶׁ֣גֶר בְּהֵמָ֗ה אֲשֶׁ֨ר יִֽהְיֶ֥ה לְךָ֛ הַזְּכָרִ֖ים לַֽיהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְכָל־פֶּ֤טֶר חֲמֹר֙ תִּפְדֶּ֣ה בְשֶׂ֔ה וְאִם־לֹ֥א תִפְדֶּ֖ה וַֽעֲרַפְתּ֑וֹ וְכֹ֨ל בְּכ֥וֹר אָדָ֛ם בְּבָנֶ֖יךָ תִּפְדֶּֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְהָיָ֞ה כִּֽי־יִשְׁאָלְךָ֥ בִנְךָ֛ מָחָ֖ר לֵאמֹ֣ר מַה־זֹּ֑את וְאָֽמַרְתָּ֣ אֵלָ֔יו בְּחֹ֣זֶק יָ֗ד הֽוֹצִיאָ֧נוּ יְהוָ֛ה מִמִּצְרַ֖יִם מִבֵּ֥ית עֲבָדִֽים׃"
          },
          {
            "num": "טו",
            "txt": "וַיְהִ֗י כִּֽי־הִקְשָׁ֣ה פַרְעֹה֮ לְשַׁלְּחֵנוּ֒ וַיַּֽהֲרֹ֨ג יְהוָ֤ה כָּל־בְּכוֹר֙ בְּאֶ֣רֶץ מִצְרַ֔יִם מִבְּכֹ֥ר אָדָ֖ם וְעַד־בְּכ֣וֹר בְּהֵמָ֑ה עַל־כֵּן֩ אֲנִ֨י זֹבֵ֜חַ לַֽיהוָ֗ה כָּל־פֶּ֤טֶר רֶ֨חֶם֙ הַזְּכָרִ֔ים וְכָל־בְּכ֥וֹר בָּנַ֖י אֶפְדֶּֽה׃"
          },
          {
            "num": "טז",
            "txt": "וְהָיָ֤ה לְאוֹת֙ עַל־יָ֣דְכָ֔ה וּלְטֽוֹטָפֹ֖ת בֵּ֣ין עֵינֶ֑יךָ כִּ֚י בְּחֹ֣זֶק יָ֔ד הֽוֹצִיאָ֥נוּ יְהוָ֖ה מִמִּצְרָֽיִם׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וַיְהִ֗י בְּשַׁלַּ֣ח פַּרְעֹה֮ אֶת־הָעָם֒ וְלֹֽא־נָחָ֣ם אֱלֹהִ֗ים דֶּ֚רֶךְ אֶ֣רֶץ פְּלִשְׁתִּ֔ים כִּ֥י קָר֖וֹב ה֑וּא כִּ֣י ׀ אָמַ֣ר אֱלֹהִ֗ים פֶּֽן־יִנָּחֵ֥ם הָעָ֛ם בִּרְאֹתָ֥ם מִלְחָמָ֖ה וְשָׁ֥בוּ מִצְרָֽיְמָה׃"
          },
          {
            "num": "יח",
            "txt": "וַיַּסֵּ֨ב אֱלֹהִ֧ים ׀ אֶת־הָעָ֛ם דֶּ֥רֶךְ הַמִּדְבָּ֖ר יַם־ס֑וּף וַֽחֲמֻשִׁ֛ים עָל֥וּ בְנֵֽי־יִשְׂרָאֵ֖ל מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּקַּ֥ח מֹשֶׁ֛ה אֶת־עַצְמ֥וֹת יוֹסֵ֖ף עִמּ֑וֹ כִּי֩ הַשְׁבֵּ֨עַ הִשְׁבִּ֜יעַ אֶת־בְּנֵ֤י יִשְׂרָאֵל֙ לֵאמֹ֔ר פָּקֹ֨ד יִפְקֹ֤ד אֱלֹהִים֙ אֶתְכֶ֔ם וְהַֽעֲלִיתֶ֧ם אֶת־עַצְמֹתַ֛י מִזֶּ֖ה אִתְּכֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּסְע֖וּ מִסֻּכֹּ֑ת וַיַּֽחֲנ֣וּ בְאֵתָ֔ם בִּקְצֵ֖ה הַמִּדְבָּֽר׃"
          },
          {
            "num": "כא",
            "txt": "וַֽיהוָ֡ה הֹלֵךְ֩ לִפְנֵיהֶ֨ם יוֹמָ֜ם בְּעַמּ֤וּד עָנָן֙ לַנְחֹתָ֣ם הַדֶּ֔רֶךְ וְלַ֛יְלָה בְּעַמּ֥וּד אֵ֖שׁ לְהָאִ֣יר לָהֶ֑ם לָלֶ֖כֶת יוֹמָ֥ם וָלָֽיְלָה׃"
          },
          {
            "num": "כב",
            "txt": "לֹֽא־יָמִ֞ישׁ עַמּ֤וּד הֶֽעָנָן֙ יוֹמָ֔ם וְעַמּ֥וּד הָאֵ֖שׁ לָ֑יְלָה לִפְנֵ֖י הָעָֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "יד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּר֮ אֶל־בְּנֵ֣י יִשְׂרָאֵל֒ וְיָשֻׁ֗בוּ וְיַֽחֲנוּ֙ לִפְנֵי֙ פִּ֣י הַֽחִירֹ֔ת בֵּ֥ין מִגְדֹּ֖ל וּבֵ֣ין הַיָּ֑ם לִפְנֵי֙ בַּ֣עַל צְפֹ֔ן נִכְח֥וֹ תַֽחֲנ֖וּ עַל־הַיָּֽם׃"
          },
          {
            "num": "ג",
            "txt": "וְאָמַ֤ר פַּרְעֹה֙ לִבְנֵ֣י יִשְׂרָאֵ֔ל נְבֻכִ֥ים הֵ֖ם בָּאָ֑רֶץ סָגַ֥ר עֲלֵיהֶ֖ם הַמִּדְבָּֽר׃"
          },
          {
            "num": "ד",
            "txt": "וְחִזַּקְתִּ֣י אֶת־לֵב־פַּרְעֹה֮ וְרָדַ֣ף אַֽחֲרֵיהֶם֒ וְאִכָּֽבְדָ֤ה בְּפַרְעֹה֙ וּבְכָל־חֵיל֔וֹ וְיָֽדְע֥וּ מִצְרַ֖יִם כִּֽי־אֲנִ֣י יְהוָ֑ה וַיַּֽעֲשׂוּ־כֵֽן׃"
          },
          {
            "num": "ה",
            "txt": "וַיֻּגַּד֙ לְמֶ֣לֶךְ מִצְרַ֔יִם כִּ֥י בָרַ֖ח הָעָ֑ם וַ֠יֵּֽהָפֵךְ לְבַ֨ב פַּרְעֹ֤ה וַֽעֲבָדָיו֙ אֶל־הָעָ֔ם וַיֹּֽאמְרוּ֙ מַה־זֹּ֣את עָשִׂ֔ינוּ כִּֽי־שִׁלַּ֥חְנוּ אֶת־יִשְׂרָאֵ֖ל מֵֽעָבְדֵֽנוּ׃"
          },
          {
            "num": "ו",
            "txt": "וַיֶּאְסֹ֖ר אֶת־רִכְבּ֑וֹ וְאֶת־עַמּ֖וֹ לָקַ֥ח עִמּֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּקַּ֗ח שֵׁשׁ־מֵא֥וֹת רֶ֨כֶב֙ בָּח֔וּר וְכֹ֖ל רֶ֣כֶב מִצְרָ֑יִם וְשָֽׁלִשִׁ֖ם עַל־כֻּלּֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וַיְחַזֵּ֣ק יְהוָ֗ה אֶת־לֵ֤ב פַּרְעֹה֙ מֶ֣לֶךְ מִצְרַ֔יִם וַיִּרְדֹּ֕ף אַֽחֲרֵ֖י בְּנֵ֣י יִשְׂרָאֵ֑ל וּבְנֵ֣י יִשְׂרָאֵ֔ל יֹֽצְאִ֖ים בְּיָ֥ד רָמָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּרְדְּפ֨וּ מִצְרַ֜יִם אַֽחֲרֵיהֶ֗ם וַיַּשִּׂ֤יגוּ אוֹתָם֙ חֹנִ֣ים עַל־הַיָּ֔ם כָּל־סוּס֙ רֶ֣כֶב פַּרְעֹ֔ה וּפָֽרָשָׁ֖יו וְחֵיל֑וֹ עַל־פִּי֙ הַֽחִירֹ֔ת לִפְנֵ֖י בַּ֥עַל צְפֹֽן׃"
          },
          {
            "num": "י",
            "txt": "וּפַרְעֹ֖ה הִקְרִ֑יב וַיִּשְׂאוּ֩ בְנֵֽי־יִשְׂרָאֵ֨ל אֶת־עֵֽינֵיהֶ֜ם וְהִנֵּ֥ה מִצְרַ֣יִם ׀ נֹסֵ֣עַ אַֽחֲרֵיהֶ֗ם וַיִּֽירְאוּ֙ מְאֹ֔ד וַיִּצְעֲק֥וּ בְנֵֽי־יִשְׂרָאֵ֖ל אֶל־יְהוָֽה׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּֽאמְרוּ֮ אֶל־מֹשֶׁה֒ הֲֽמִבְּלִ֤י אֵין־קְבָרִים֙ בְּמִצְרַ֔יִם לְקַחְתָּ֖נוּ לָמ֣וּת בַּמִּדְבָּ֑ר מַה־זֹּאת֙ עָשִׂ֣יתָ לָּ֔נוּ לְהֽוֹצִיאָ֖נוּ מִמִּצְרָֽיִם׃"
          },
          {
            "num": "יב",
            "txt": "הֲלֹא־זֶ֣ה הַדָּבָ֗ר אֲשֶׁר֩ דִּבַּ֨רְנוּ אֵלֶ֤יךָ בְמִצְרַ֨יִם֙ לֵאמֹ֔ר חֲדַ֥ל מִמֶּ֖נּוּ וְנַֽעַבְדָ֣ה אֶת־מִצְרָ֑יִם כִּ֣י ט֥וֹב לָ֨נוּ֙ עֲבֹ֣ד אֶת־מִצְרַ֔יִם מִמֻּתֵ֖נוּ בַּמִּדְבָּֽר׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֣ה אֶל־הָעָם֮ אַל־תִּירָאוּ֒ הִֽתְיַצְּב֗וּ וּרְאוּ֙ אֶת־יְשׁוּעַ֣ת יְהוָ֔ה אֲשֶׁר־יַֽעֲשֶׂ֥ה לָכֶ֖ם הַיּ֑וֹם כִּ֗י אֲשֶׁ֨ר רְאִיתֶ֤ם אֶת־מִצְרַ֨יִם֙ הַיּ֔וֹם לֹ֥א תֹסִ֛פוּ לִרְאֹתָ֥ם ע֖וֹד עַד־עוֹלָֽם׃"
          },
          {
            "num": "יד",
            "txt": "יְהוָ֖ה יִלָּחֵ֣ם לָכֶ֑ם וְאַתֶּ֖ם תַּֽחֲרִשֽׁוּן׃ {פ}"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה מַה־תִּצְעַ֖ק אֵלָ֑י דַּבֵּ֥ר אֶל־בְּנֵֽי־יִשְׂרָאֵ֖ל וְיִסָּֽעוּ׃"
          },
          {
            "num": "טז",
            "txt": "וְאַתָּ֞ה הָרֵ֣ם אֶֽת־מַטְּךָ֗ וּנְטֵ֧ה אֶת־יָֽדְךָ֛ עַל־הַיָּ֖ם וּבְקָעֵ֑הוּ וְיָבֹ֧אוּ בְנֵֽי־יִשְׂרָאֵ֛ל בְּת֥וֹךְ הַיָּ֖ם בַּיַּבָּשָֽׁה׃"
          },
          {
            "num": "יז",
            "txt": "וַֽאֲנִ֗י הִנְנִ֤י מְחַזֵּק֙ אֶת־לֵ֣ב מִצְרַ֔יִם וְיָבֹ֖אוּ אַֽחֲרֵיהֶ֑ם וְאִכָּֽבְדָ֤ה בְּפַרְעֹה֙ וּבְכָל־חֵיל֔וֹ בְּרִכְבּ֖וֹ וּבְפָֽרָשָֽׁיו׃"
          },
          {
            "num": "יח",
            "txt": "וְיָֽדְע֥וּ מִצְרַ֖יִם כִּֽי־אֲנִ֣י יְהוָ֑ה בְּהִכָּֽבְדִ֣י בְּפַרְעֹ֔ה בְּרִכְבּ֖וֹ וּבְפָֽרָשָֽׁיו׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּסַּ֞ע מַלְאַ֣ךְ הָֽאֱלֹהִ֗ים הַֽהֹלֵךְ֙ לִפְנֵי֙ מַֽחֲנֵ֣ה יִשְׂרָאֵ֔ל וַיֵּ֖לֶךְ מֵאַֽחֲרֵיהֶ֑ם וַיִּסַּ֞ע עַמּ֤וּד הֶֽעָנָן֙ מִפְּנֵיהֶ֔ם וַֽיַּעֲמֹ֖ד מֵאַֽחֲרֵיהֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "וַיָּבֹ֞א בֵּ֣ין ׀ מַֽחֲנֵ֣ה מִצְרַ֗יִם וּבֵין֙ מַֽחֲנֵ֣ה יִשְׂרָאֵ֔ל וַיְהִ֤י הֶֽעָנָן֙ וְהַחֹ֔שֶׁךְ וַיָּ֖אֶר אֶת־הַלָּ֑יְלָה וְלֹֽא־קָרַ֥ב זֶ֛ה אֶל־זֶ֖ה כָּל־הַלָּֽיְלָה׃"
          },
          {
            "num": "כא",
            "txt": "וַיֵּ֨ט מֹשֶׁ֣ה אֶת־יָדוֹ֮ עַל־הַיָּם֒ וַיּ֣וֹלֶךְ יְהוָ֣ה ׀ אֶת־הַ֠יָּם בְּר֨וּחַ קָדִ֤ים עַזָּה֙ כָּל־הַלַּ֔יְלָה וַיָּ֥שֶׂם אֶת־הַיָּ֖ם לֶחָֽרָבָ֑ה וַיִּבָּֽקְע֖וּ הַמָּֽיִם׃"
          },
          {
            "num": "כב",
            "txt": "וַיָּבֹ֧אוּ בְנֵֽי־יִשְׂרָאֵ֛ל בְּת֥וֹךְ הַיָּ֖ם בַּיַּבָּשָׁ֑ה וְהַמַּ֤יִם לָהֶם֙ חוֹמָ֔ה מִֽימִינָ֖ם וּמִשְּׂמֹאלָֽם׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּרְדְּפ֤וּ מִצְרַ֨יִם֙ וַיָּבֹ֣אוּ אַֽחֲרֵיהֶ֔ם כֹּ֚ל ס֣וּס פַּרְעֹ֔ה רִכְבּ֖וֹ וּפָֽרָשָׁ֑יו אֶל־תּ֖וֹךְ הַיָּֽם׃"
          },
          {
            "num": "כד",
            "txt": "וַֽיְהִי֙ בְּאַשְׁמֹ֣רֶת הַבֹּ֔קֶר וַיַּשְׁקֵ֤ף יְהוָה֙ אֶל־מַֽחֲנֵ֣ה מִצְרַ֔יִם בְּעַמּ֥וּד אֵ֖שׁ וְעָנָ֑ן וַיָּ֕הָם אֵ֖ת מַֽחֲנֵ֥ה מִצְרָֽיִם׃"
          },
          {
            "num": "כה",
            "txt": "וַיָּ֗סַר אֵ֚ת אֹפַ֣ן מַרְכְּבֹתָ֔יו וַֽיְנַהֲגֵ֖הוּ בִּכְבֵדֻ֑ת וַיֹּ֣אמֶר מִצְרַ֗יִם אָנ֨וּסָה֙ מִפְּנֵ֣י יִשְׂרָאֵ֔ל כִּ֣י יְהוָ֔ה נִלְחָ֥ם לָהֶ֖ם בְּמִצְרָֽיִם׃ {פ}"
          },
          {
            "num": "כו",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה נְטֵ֥ה אֶת־יָֽדְךָ֖ עַל־הַיָּ֑ם וְיָשֻׁ֤בוּ הַמַּ֨יִם֙ עַל־מִצְרַ֔יִם עַל־רִכְבּ֖וֹ וְעַל־פָּֽרָשָֽׁיו׃"
          },
          {
            "num": "כז",
            "txt": "וַיֵּט֩ מֹשֶׁ֨ה אֶת־יָד֜וֹ עַל־הַיָּ֗ם וַיָּ֨שָׁב הַיָּ֜ם לִפְנ֥וֹת בֹּ֨קֶר֙ לְאֵ֣יתָנ֔וֹ וּמִצְרַ֖יִם נָסִ֣ים לִקְרָאת֑וֹ וַיְנַעֵ֧ר יְהוָ֛ה אֶת־מִצְרַ֖יִם בְּת֥וֹךְ הַיָּֽם׃"
          },
          {
            "num": "כח",
            "txt": "וַיָּשֻׁ֣בוּ הַמַּ֗יִם וַיְכַסּ֤וּ אֶת־הָרֶ֨כֶב֙ וְאֶת־הַפָּ֣רָשִׁ֔ים לְכֹל֙ חֵ֣יל פַּרְעֹ֔ה הַבָּאִ֥ים אַֽחֲרֵיהֶ֖ם בַּיָּ֑ם לֹֽא־נִשְׁאַ֥ר בָּהֶ֖ם עַד־אֶחָֽד׃"
          },
          {
            "num": "כט",
            "txt": "וּבְנֵ֧י יִשְׂרָאֵ֛ל הָֽלְכ֥וּ בַיַּבָּשָׁ֖ה בְּת֣וֹךְ הַיָּ֑ם וְהַמַּ֤יִם לָהֶם֙ חֹמָ֔ה מִֽימִינָ֖ם וּמִשְּׂמֹאלָֽם׃"
          },
          {
            "num": "ל",
            "txt": "וַיּ֨וֹשַׁע יְהוָ֜ה בַּיּ֥וֹם הַה֛וּא אֶת־יִשְׂרָאֵ֖ל מִיַּ֣ד מִצְרָ֑יִם וַיַּ֤רְא יִשְׂרָאֵל֙ אֶת־מִצְרַ֔יִם מֵ֖ת עַל־שְׂפַ֥ת הַיָּֽם׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּ֨רְא יִשְׂרָאֵ֜ל אֶת־הַיָּ֣ד הַגְּדֹלָ֗ה אֲשֶׁ֨ר עָשָׂ֤ה יְהוָה֙ בְּמִצְרַ֔יִם וַיִּֽירְא֥וּ הָעָ֖ם אֶת־יְהוָ֑ה וַֽיַּאֲמִ֨ינוּ֙ בַּֽיהוָ֔ה וּבְמֹשֶׁ֖ה עַבְדּֽוֹ׃ {ר}{ש}"
          }
        ]
      },
      {
        "num": "טו",
        "verses": [
          {
            "num": "א",
            "txt": "אָ֣ז יָשִֽׁיר־מֹשֶׁה֩ וּבְנֵ֨י יִשְׂרָאֵ֜ל אֶת־הַשִּׁירָ֤ה הַזֹּאת֙ לַֽיהוָ֔ה וַיֹּֽאמְר֖וּ {ר}"
          },
          {
            "num": "ב",
            "txt": "לֵאמֹ֑ר {ס} אָשִׁ֤ירָה לַֽיהוָה֙ כִּֽי־גָאֹ֣ה גָּאָ֔ה {ס} ס֥וּס {ר}"
          },
          {
            "num": "ג",
            "txt": "וְרֹֽכְב֖וֹ רָמָ֥ה בַיָּֽם׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "עָזִּ֤י וְזִמְרָת֙ יָ֔הּ וַֽיְהִי־לִ֖י {ר}"
          },
          {
            "num": "ה",
            "txt": "לִֽישׁוּעָ֑ה {ס} זֶ֤ה אֵלִי֙ וְאַנְוֵ֔הוּ {ס} אֱלֹהֵ֥י {ר}"
          },
          {
            "num": "ו",
            "txt": "אָבִ֖י וַאֲרֹֽמְמֶֽנְהוּ׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "יְהוָ֖ה אִ֣ישׁ מִלְחָמָ֑ה יְהוָ֖ה {ר}"
          },
          {
            "num": "ח",
            "txt": "שְׁמֽוֹ׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "מַרְכְּבֹ֥ת פַּרְעֹ֛ה וְחֵיל֖וֹ יָרָ֣ה בַיָּ֑ם {ס} וּמִבְחַ֥ר {ר}"
          },
          {
            "num": "י",
            "txt": "שָֽׁלִשָׁ֖יו טֻבְּע֥וּ בְיַם־סֽוּף׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "תְּהֹמֹ֖ת יְכַסְיֻ֑מוּ יָֽרְד֥וּ בִמְצוֹלֹ֖ת כְּמוֹ־ {ר}"
          },
          {
            "num": "יב",
            "txt": "אָֽבֶן׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "יְמִֽינְךָ֣ יְהוָ֔ה נֶאְדָּרִ֖י בַּכֹּ֑חַ {ס} יְמִֽינְךָ֥ {ר}"
          },
          {
            "num": "יד",
            "txt": "יְהוָ֖ה תִּרְעַ֥ץ אוֹיֵֽב׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וּבְרֹ֥ב גְּאֽוֹנְךָ֖ תַּֽהֲרֹ֣ס {ר}"
          },
          {
            "num": "טז",
            "txt": "קָמֶ֑יךָ {ס} תְּשַׁלַּח֙ חֲרֹ֣נְךָ֔ יֹֽאכְלֵ֖מוֹ כַּקַּֽשׁ׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וּבְר֤וּחַ {ר}"
          },
          {
            "num": "יח",
            "txt": "אַפֶּ֨יךָ֙ נֶ֣עֶרְמוּ מַ֔יִם {ס} נִצְּב֥וּ כְמוֹ־נֵ֖ד {ר}"
          },
          {
            "num": "יט",
            "txt": "נֹֽזְלִ֑ים {ס} קָֽפְא֥וּ תְהֹמֹ֖ת בְּלֶב־יָֽם׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "אָמַ֥ר {ר}"
          },
          {
            "num": "כא",
            "txt": "אוֹיֵ֛ב אֶרְדֹּ֥ף אַשִּׂ֖יג {ס} אֲחַלֵּ֣ק שָׁלָ֑ל תִּמְלָאֵ֣מוֹ {ר}"
          },
          {
            "num": "כב",
            "txt": "נַפְשִׁ֔י {ס} אָרִ֣יק חַרְבִּ֔י תּֽוֹרִישֵׁ֖מוֹ יָדִֽי׃ {ס}"
          },
          {
            "num": "כג",
            "txt": "נָשַׁ֥פְתָּ {ר}"
          },
          {
            "num": "כד",
            "txt": "בְרֽוּחֲךָ֖ כִּסָּ֣מוֹ יָ֑ם {ס} צָֽלְלוּ֙ כַּֽעוֹפֶ֔רֶת בְּמַ֖יִם {ר}"
          },
          {
            "num": "כה",
            "txt": "אַדִּירִֽים׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "מִֽי־כָמֹ֤כָה בָּֽאֵלִם֙ יְהוָ֔ה {ס} מִ֥י {ר}"
          },
          {
            "num": "כז",
            "txt": "כָּמֹ֖כָה נֶאְדָּ֣ר בַּקֹּ֑דֶשׁ {ס} נוֹרָ֥א תְהִלֹּ֖ת עֹ֥שֵׂה {ר}"
          },
          {
            "txt": "פֶֽלֶא׃ {ס}"
          },
          {
            "txt": "נָטִ֨יתָ֙ יְמִ֣ינְךָ֔ תִּבְלָעֵ֖מוֹ אָֽרֶץ׃ {ס}"
          },
          {
            "txt": "נָחִ֥יתָ {ר}"
          },
          {
            "txt": "בְחַסְדְּךָ֖ עַם־ז֣וּ גָּאָ֑לְתָּ {ס} נֵהַ֥לְתָּ בְעָזְּךָ֖ אֶל־נְוֵ֥ה {ר}"
          },
          {
            "txt": "קָדְשֶֽׁךָ׃ {ס}"
          },
          {
            "txt": "שָֽׁמְע֥וּ עַמִּ֖ים יִרְגָּז֑וּן {ס} חִ֣יל {ר}"
          },
          {
            "txt": "אָחַ֔ז יֹֽשְׁבֵ֖י פְּלָֽשֶׁת׃ {ס}"
          },
          {
            "txt": "אָ֤ז נִבְהֲלוּ֙ אַלּוּפֵ֣י {ר}"
          },
          {
            "txt": "אֱד֔וֹם {ס} אֵילֵ֣י מוֹאָ֔ב יֹֽאחֲזֵ֖מוֹ רָ֑עַד {ס} נָמֹ֕גוּ {ר}"
          },
          {
            "txt": "כֹּ֖ל יֹֽשְׁבֵ֥י כְנָֽעַן׃ {ס}"
          },
          {
            "txt": "תִּפֹּ֨ל עֲלֵיהֶ֤ם אֵימָ֨תָה֙ {ר}"
          },
          {
            "txt": "וָפַ֔חַד {ס} בִּגְדֹ֥ל זְרֽוֹעֲךָ֖ יִדְּמ֣וּ כָּאָ֑בֶן {ס} עַד־ {ר}"
          },
          {
            "txt": "יַֽעֲבֹ֤ר עַמְּךָ֙ יְהוָ֔ה {ס} עַֽד־יַעֲבֹ֖ר עַם־ז֥וּ {ר}"
          },
          {
            "txt": "קָנִֽיתָ׃ {ס}"
          },
          {
            "txt": "תְּבִאֵ֗מוֹ וְתִטָּעֵ֨מוֹ֙ בְּהַ֣ר נַחֲלָֽתְךָ֔ {ס} מָכ֧וֹן {ר}"
          },
          {
            "txt": "לְשִׁבְתְּךָ֛ פָּעַ֖לְתָּ יְהוָ֑ה {ס} מִקְּדָ֕שׁ אֲדֹנָ֖י כּֽוֹנְנ֥וּ {ר}"
          },
          {
            "txt": "יָדֶֽיךָ׃ {ס}"
          },
          {
            "txt": "יְהוָ֥ה ׀ יִמְלֹ֖ךְ לְעֹלָ֥ם וָעֶֽד׃ {ס}"
          },
          {
            "txt": "כִּ֣י {ר}"
          },
          {
            "txt": "בָא֩ ס֨וּס פַּרְעֹ֜ה בְּרִכְבּ֤וֹ וּבְפָֽרָשָׁיו֙ בַּיָּ֔ם {ס} וַיָּ֧שֶׁב יְהוָ֛ה עֲלֵהֶ֖ם {ר}"
          },
          {
            "txt": "אֶת־מֵ֣י הַיָּ֑ם {ס} וּבְנֵ֧י יִשְׂרָאֵ֛ל הָֽלְכ֥וּ בַיַּבָּשָׁ֖ה בְּת֥וֹךְ הַיָּֽם׃ {ר}{ש}"
          },
          {
            "txt": "וַתִּקַּח֩ מִרְיָ֨ם הַנְּבִיאָ֜ה אֲח֧וֹת אַֽהֲרֹ֛ן אֶת־הַתֹּ֖ף בְּיָדָ֑הּ וַתֵּצֶ֤אןָ כָֽל־הַנָּשִׁים֙ אַֽחֲרֶ֔יהָ בְּתֻפִּ֖ים וּבִמְחֹלֹֽת׃"
          },
          {
            "txt": "וַתַּ֥עַן לָהֶ֖ם מִרְיָ֑ם שִׁ֤ירוּ לַֽיהוָה֙ כִּֽי־גָאֹ֣ה גָּאָ֔ה ס֥וּס וְרֹֽכְב֖וֹ רָמָ֥ה בַיָּֽם׃ {ס}"
          },
          {
            "txt": "וַיַּסַּ֨ע מֹשֶׁ֤ה אֶת־יִשְׂרָאֵל֙ מִיַּם־ס֔וּף וַיֵּֽצְא֖וּ אֶל־מִדְבַּר־שׁ֑וּר וַיֵּֽלְכ֧וּ שְׁלֹֽשֶׁת־יָמִ֛ים בַּמִּדְבָּ֖ר וְלֹא־מָ֥צְאוּ מָֽיִם׃"
          },
          {
            "txt": "וַיָּבֹ֣אוּ מָרָ֔תָה וְלֹ֣א יָֽכְל֗וּ לִשְׁתֹּ֥ת מַ֨יִם֙ מִמָּרָ֔ה כִּ֥י מָרִ֖ים הֵ֑ם עַל־כֵּ֥ן קָרָֽא־שְׁמָ֖הּ מָרָֽה׃"
          },
          {
            "txt": "וַיִּלֹּ֧נוּ הָעָ֛ם עַל־מֹשֶׁ֥ה לֵּאמֹ֖ר מַה־נִּשְׁתֶּֽה׃"
          },
          {
            "txt": "וַיִּצְעַ֣ק אֶל־יְהוָ֗ה וַיּוֹרֵ֤הוּ יְהוָה֙ עֵ֔ץ וַיַּשְׁלֵךְ֙ אֶל־הַמַּ֔יִם וַֽיִּמְתְּק֖וּ הַמָּ֑יִם שָׁ֣ם שָׂ֥ם ל֛וֹ חֹ֥ק וּמִשְׁפָּ֖ט וְשָׁ֥ם נִסָּֽהוּ׃"
          },
          {
            "txt": "וַיֹּאמֶר֩ אִם־שָׁמ֨וֹעַ תִּשְׁמַ֜ע לְק֣וֹל ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ וְהַיָּשָׁ֤ר בְּעֵינָיו֙ תַּֽעֲשֶׂ֔ה וְהַֽאֲזַנְתָּ֙ לְמִצְוֺתָ֔יו וְשָֽׁמַרְתָּ֖ כָּל־חֻקָּ֑יו כָּֽל־הַמַּחֲלָ֞ה אֲשֶׁר־שַׂ֤מְתִּי בְמִצְרַ֨יִם֙ לֹֽא־אָשִׂ֣ים עָלֶ֔יךָ כִּ֛י אֲנִ֥י יְהוָ֖ה רֹֽפְאֶֽךָ׃ {ס}"
          },
          {
            "txt": "וַיָּבֹ֣אוּ אֵילִ֔מָה וְשָׁ֗ם שְׁתֵּ֥ים עֶשְׂרֵ֛ה עֵינֹ֥ת מַ֖יִם וְשִׁבְעִ֣ים תְּמָרִ֑ים וַיַּֽחֲנוּ־שָׁ֖ם עַל־הַמָּֽיִם׃"
          }
        ]
      },
      {
        "num": "טז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּסְעוּ֙ מֵֽאֵילִ֔ם וַיָּבֹ֜אוּ כָּל־עֲדַ֤ת בְּנֵֽי־יִשְׂרָאֵל֙ אֶל־מִדְבַּר־סִ֔ין אֲשֶׁ֥ר בֵּין־אֵילִ֖ם וּבֵ֣ין סִינָ֑י בַּֽחֲמִשָּׁ֨ה עָשָׂ֥ר יוֹם֙ לַחֹ֣דֶשׁ הַשֵּׁנִ֔י לְצֵאתָ֖ם מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ב",
            "txt": "וילינו (וַיִּלּ֜וֹנוּ) כָּל־עֲדַ֧ת בְּנֵֽי־יִשְׂרָאֵ֛ל עַל־מֹשֶׁ֥ה וְעַֽל־אַהֲרֹ֖ן בַּמִּדְבָּֽר׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּֽאמְר֨וּ אֲלֵהֶ֜ם בְּנֵ֣י יִשְׂרָאֵ֗ל מִֽי־יִתֵּ֨ן מוּתֵ֤נוּ בְיַד־יְהוָה֙ בְּאֶ֣רֶץ מִצְרַ֔יִם בְּשִׁבְתֵּ֨נוּ֙ עַל־סִ֣יר הַבָּשָׂ֔ר בְּאָכְלֵ֥נוּ לֶ֖חֶם לָשֹׂ֑בַע כִּֽי־הוֹצֵאתֶ֤ם אֹתָ֨נוּ֙ אֶל־הַמִּדְבָּ֣ר הַזֶּ֔ה לְהָמִ֛ית אֶת־כָּל־הַקָּהָ֥ל הַזֶּ֖ה בָּֽרָעָֽב׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה הִנְנִ֨י מַמְטִ֥יר לָכֶ֛ם לֶ֖חֶם מִן־הַשָּׁמָ֑יִם וְיָצָ֨א הָעָ֤ם וְלָֽקְטוּ֙ דְּבַר־י֣וֹם בְּיוֹמ֔וֹ לְמַ֧עַן אֲנַסֶּ֛נּוּ הֲיֵלֵ֥ךְ בְּתֽוֹרָתִ֖י אִם־לֹֽא׃"
          },
          {
            "num": "ה",
            "txt": "וְהָיָה֙ בַּיּ֣וֹם הַשִּׁשִּׁ֔י וְהֵכִ֖ינוּ אֵ֣ת אֲשֶׁר־יָבִ֑יאוּ וְהָיָ֣ה מִשְׁנֶ֔ה עַ֥ל אֲשֶֽׁר־יִלְקְט֖וּ י֥וֹם ׀ יֽוֹם׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֤אמֶר מֹשֶׁה֙ וְאַֽהֲרֹ֔ן אֶֽל־כָּל־בְּנֵ֖י יִשְׂרָאֵ֑ל עֶ֕רֶב וִֽידַעְתֶּ֕ם כִּ֧י יְהוָ֛ה הוֹצִ֥יא אֶתְכֶ֖ם מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ז",
            "txt": "וּבֹ֗קֶר וּרְאִיתֶם֙ אֶת־כְּב֣וֹד יְהוָ֔ה בְּשָׁמְע֥וֹ אֶת־תְּלֻנֹּֽתֵיכֶ֖ם עַל־יְהוָ֑ה וְנַ֣חְנוּ מָ֔ה כִּ֥י תלונו (תַלִּ֖ינוּ) עָלֵֽינוּ׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֗ה בְּתֵ֣ת יְהוָה֩ לָכֶ֨ם בָּעֶ֜רֶב בָּשָׂ֣ר לֶֽאֱכֹ֗ל וְלֶ֤חֶם בַּבֹּ֨קֶר֙ לִשְׂבֹּ֔עַ בִּשְׁמֹ֤עַ יְהוָה֙ אֶת־תְּלֻנֹּ֣תֵיכֶ֔ם אֲשֶׁר־אַתֶּ֥ם מַלִּינִ֖ם עָלָ֑יו וְנַ֣חְנוּ מָ֔ה לֹֽא־עָלֵ֥ינוּ תְלֻנֹּֽתֵיכֶ֖ם כִּ֥י עַל־יְהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֤אמֶר מֹשֶׁה֙ אֶֽל־אַהֲרֹ֔ן אֱמֹ֗ר אֶֽל־כָּל־עֲדַת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל קִרְב֖וּ לִפְנֵ֣י יְהוָ֑ה כִּ֣י שָׁמַ֔ע אֵ֖ת תְּלֻנֹּֽתֵיכֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וַיְהִ֗י כְּדַבֵּ֤ר אַֽהֲרֹן֙ אֶל־כָּל־עֲדַ֣ת בְּנֵֽי־יִשְׂרָאֵ֔ל וַיִּפְנ֖וּ אֶל־הַמִּדְבָּ֑ר וְהִנֵּה֙ כְּב֣וֹד יְהוָ֔ה נִרְאָ֖ה בֶּֽעָנָֽן׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יב",
            "txt": "שָׁמַ֗עְתִּי אֶת־תְּלוּנֹּת֮ בְּנֵ֣י יִשְׂרָאֵל֒ דַּבֵּ֨ר אֲלֵהֶ֜ם לֵאמֹ֗ר בֵּ֤ין הָֽעַרְבַּ֨יִם֙ תֹּֽאכְל֣וּ בָשָׂ֔ר וּבַבֹּ֖קֶר תִּשְׂבְּעוּ־לָ֑חֶם וִֽידַעְתֶּ֕ם כִּ֛י אֲנִ֥י יְהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "יג",
            "txt": "וַיְהִ֣י בָעֶ֔רֶב וַתַּ֣עַל הַשְּׂלָ֔ו וַתְּכַ֖ס אֶת־הַֽמַּחֲנֶ֑ה וּבַבֹּ֗קֶר הָֽיְתָה֙ שִׁכְבַ֣ת הַטַּ֔ל סָבִ֖יב לַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "יד",
            "txt": "וַתַּ֖עַל שִׁכְבַ֣ת הַטָּ֑ל וְהִנֵּ֞ה עַל־פְּנֵ֤י הַמִּדְבָּר֙ דַּ֣ק מְחֻסְפָּ֔ס דַּ֥ק כַּכְּפֹ֖ר עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּרְא֣וּ בְנֵֽי־יִשְׂרָאֵ֗ל וַיֹּ֨אמְר֜וּ אִ֤ישׁ אֶל־אָחִיו֙ מָ֣ן ה֔וּא כִּ֛י לֹ֥א יָֽדְע֖וּ מַה־ה֑וּא וַיֹּ֤אמֶר מֹשֶׁה֙ אֲלֵהֶ֔ם ה֣וּא הַלֶּ֔חֶם אֲשֶׁ֨ר נָתַ֧ן יְהוָ֛ה לָכֶ֖ם לְאָכְלָֽה׃"
          },
          {
            "num": "טז",
            "txt": "זֶ֤ה הַדָּבָר֙ אֲשֶׁ֣ר צִוָּ֣ה יְהוָ֔ה לִקְט֣וּ מִמֶּ֔נּוּ אִ֖ישׁ לְפִ֣י אָכְל֑וֹ עֹ֣מֶר לַגֻּלְגֹּ֗לֶת מִסְפַּר֙ נַפְשֹׁ֣תֵיכֶ֔ם אִ֛ישׁ לַֽאֲשֶׁ֥ר בְּאָֽהֳל֖וֹ תִּקָּֽחוּ׃"
          },
          {
            "num": "יז",
            "txt": "וַיַּֽעֲשׂוּ־כֵ֖ן בְּנֵ֣י יִשְׂרָאֵ֑ל וַֽיִּלְקְט֔וּ הַמַּרְבֶּ֖ה וְהַמַּמְעִֽיט׃"
          },
          {
            "num": "יח",
            "txt": "וַיָּמֹ֣דּוּ בָעֹ֔מֶר וְלֹ֤א הֶעְדִּיף֙ הַמַּרְבֶּ֔ה וְהַמַּמְעִ֖יט לֹ֣א הֶחְסִ֑יר אִ֥ישׁ לְפִֽי־אָכְל֖וֹ לָקָֽטוּ׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֥אמֶר מֹשֶׁ֖ה אֲלֵהֶ֑ם אִ֕ישׁ אַל־יוֹתֵ֥ר מִמֶּ֖נּוּ עַד־בֹּֽקֶר׃"
          },
          {
            "num": "כ",
            "txt": "וְלֹֽא־שָׁמְע֣וּ אֶל־מֹשֶׁ֗ה וַיּוֹתִ֨רוּ אֲנָשִׁ֤ים מִמֶּ֨נּוּ֙ עַד־בֹּ֔קֶר וַיָּ֥רֻם תּֽוֹלָעִ֖ים וַיִּבְאַ֑שׁ וַיִּקְצֹ֥ף עֲלֵהֶ֖ם מֹשֶֽׁה׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּלְקְט֤וּ אֹתוֹ֙ בַּבֹּ֣קֶר בַּבֹּ֔קֶר אִ֖ישׁ כְּפִ֣י אָכְל֑וֹ וְחַ֥ם הַשֶּׁ֖מֶשׁ וְנָמָֽס׃"
          },
          {
            "num": "כב",
            "txt": "וַיְהִ֣י ׀ בַּיּ֣וֹם הַשִּׁשִּׁ֗י לָֽקְט֥וּ לֶ֨חֶם֙ מִשְׁנֶ֔ה שְׁנֵ֥י הָעֹ֖מֶר לָֽאֶחָ֑ד וַיָּבֹ֨אוּ֙ כָּל־נְשִׂיאֵ֣י הָֽעֵדָ֔ה וַיַּגִּ֖ידוּ לְמֹשֶֽׁה׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֣אמֶר אֲלֵהֶ֗ם ה֚וּא אֲשֶׁ֣ר דִּבֶּ֣ר יְהוָ֔ה שַׁבָּת֧וֹן שַׁבַּת־קֹ֛דֶשׁ לַֽיהוָ֖ה מָחָ֑ר אֵ֣ת אֲשֶׁר־תֹּאפ֞וּ אֵפ֗וּ וְאֵ֤ת אֲשֶֽׁר־תְּבַשְּׁלוּ֙ בַּשֵּׁ֔לוּ וְאֵת֙ כָּל־הָ֣עֹדֵ֔ף הַנִּ֧יחוּ לָכֶ֛ם לְמִשְׁמֶ֖רֶת עַד־הַבֹּֽקֶר׃"
          },
          {
            "num": "כד",
            "txt": "וַיַּנִּ֤יחוּ אֹתוֹ֙ עַד־הַבֹּ֔קֶר כַּֽאֲשֶׁ֖ר צִוָּ֣ה מֹשֶׁ֑ה וְלֹ֣א הִבְאִ֔ישׁ וְרִמָּ֖ה לֹא־הָ֥יְתָה בּֽוֹ׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֤אמֶר מֹשֶׁה֙ אִכְלֻ֣הוּ הַיּ֔וֹם כִּֽי־שַׁבָּ֥ת הַיּ֖וֹם לַֽיהוָ֑ה הַיּ֕וֹם לֹ֥א תִמְצָאֻ֖הוּ בַּשָּׂדֶֽה׃"
          },
          {
            "num": "כו",
            "txt": "שֵׁ֥שֶׁת יָמִ֖ים תִּלְקְטֻ֑הוּ וּבַיּ֧וֹם הַשְּׁבִיעִ֛י שַׁבָּ֖ת לֹ֥א יִֽהְיֶה־בּֽוֹ׃"
          },
          {
            "num": "כז",
            "txt": "וַֽיְהִי֙ בַּיּ֣וֹם הַשְּׁבִיעִ֔י יָֽצְא֥וּ מִן־הָעָ֖ם לִלְקֹ֑ט וְלֹ֖א מָצָֽאוּ׃ {ס}"
          },
          {
            "num": "כח",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֑ה עַד־אָ֨נָה֙ מֵֽאַנְתֶּ֔ם לִשְׁמֹ֥ר מִצְוֺתַ֖י וְתֽוֹרֹתָֽי׃"
          },
          {
            "num": "כט",
            "txt": "רְא֗וּ כִּֽי־יְהוָה֮ נָתַ֣ן לָכֶ֣ם הַשַּׁבָּת֒ עַל־כֵּ֠ן ה֣וּא נֹתֵ֥ן לָכֶ֛ם בַּיּ֥וֹם הַשִּׁשִּׁ֖י לֶ֣חֶם יוֹמָ֑יִם שְׁב֣וּ ׀ אִ֣ישׁ תַּחְתָּ֗יו אַל־יֵ֥צֵא אִ֛ישׁ מִמְּקֹמ֖וֹ בַּיּ֥וֹם הַשְּׁבִיעִֽי׃"
          },
          {
            "num": "ל",
            "txt": "וַיִּשְׁבְּת֥וּ הָעָ֖ם בַּיּ֥וֹם הַשְּׁבִעִֽי׃"
          },
          {
            "num": "לא",
            "txt": "וַיִּקְרְא֧וּ בֵֽית־יִשְׂרָאֵ֛ל אֶת־שְׁמ֖וֹ מָ֑ן וְה֗וּא כְּזֶ֤רַע גַּד֙ לָבָ֔ן וְטַעְמ֖וֹ כְּצַפִּיחִ֥ת בִּדְבָֽשׁ׃"
          },
          {
            "num": "לב",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֗ה זֶ֤ה הַדָּבָר֙ אֲשֶׁ֣ר צִוָּ֣ה יְהוָ֔ה מְלֹ֤א הָעֹ֨מֶר֙ מִמֶּ֔נּוּ לְמִשְׁמֶ֖רֶת לְדֹרֹֽתֵיכֶ֑ם לְמַ֣עַן ׀ יִרְא֣וּ אֶת־הַלֶּ֗חֶם אֲשֶׁ֨ר הֶֽאֱכַ֤לְתִּי אֶתְכֶם֙ בַּמִּדְבָּ֔ר בְּהֽוֹצִיאִ֥י אֶתְכֶ֖ם מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "לג",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶֽל־אַהֲרֹ֗ן קַ֚ח צִנְצֶ֣נֶת אַחַ֔ת וְתֶן־שָׁ֥מָּה מְלֹֽא־הָעֹ֖מֶר מָ֑ן וְהַנַּ֤ח אֹתוֹ֙ לִפְנֵ֣י יְהוָ֔ה לְמִשְׁמֶ֖רֶת לְדֹרֹֽתֵיכֶֽם׃"
          },
          {
            "num": "לד",
            "txt": "כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶל־מֹשֶׁ֑ה וַיַּנִּיחֵ֧הוּ אַֽהֲרֹ֛ן לִפְנֵ֥י הָֽעֵדֻ֖ת לְמִשְׁמָֽרֶת׃"
          },
          {
            "num": "לה",
            "txt": "וּבְנֵ֣י יִשְׂרָאֵ֗ל אָֽכְל֤וּ אֶת־הַמָּן֙ אַרְבָּעִ֣ים שָׁנָ֔ה עַד־בֹּאָ֖ם אֶל־אֶ֣רֶץ נוֹשָׁ֑בֶת אֶת־הַמָּן֙ אָֽכְל֔וּ עַד־בֹּאָ֕ם אֶל־קְצֵ֖ה אֶ֥רֶץ כְּנָֽעַן׃"
          },
          {
            "num": "לו",
            "txt": "וְהָעֹ֕מֶר עֲשִׂרִ֥ית הָֽאֵיפָ֖ה הֽוּא׃ {פ}"
          }
        ]
      },
      {
        "num": "יז",
        "verses": [
          {
            "num": "א",
            "txt": "וַ֠יִּסְעוּ כָּל־עֲדַ֨ת בְּנֵֽי־יִשְׂרָאֵ֧ל מִמִּדְבַּר־סִ֛ין לְמַסְעֵיהֶ֖ם עַל־פִּ֣י יְהוָ֑ה וַֽיַּחֲנוּ֙ בִּרְפִידִ֔ים וְאֵ֥ין מַ֖יִם לִשְׁתֹּ֥ת הָעָֽם׃"
          },
          {
            "num": "ב",
            "txt": "וַיָּ֤רֶב הָעָם֙ עִם־מֹשֶׁ֔ה וַיֹּ֣אמְר֔וּ תְּנוּ־לָ֥נוּ מַ֖יִם וְנִשְׁתֶּ֑ה וַיֹּ֤אמֶר לָהֶם֙ מֹשֶׁ֔ה מַה־תְּרִיבוּן֙ עִמָּדִ֔י מַה־תְּנַסּ֖וּן אֶת־יְהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּצְמָ֨א שָׁ֤ם הָעָם֙ לַמַּ֔יִם וַיָּ֥לֶן הָעָ֖ם עַל־מֹשֶׁ֑ה וַיֹּ֗אמֶר לָ֤מָּה זֶּה֙ הֶֽעֱלִיתָ֣נוּ מִמִּצְרַ֔יִם לְהָמִ֥ית אֹתִ֛י וְאֶת־בָּנַ֥י וְאֶת־מִקְנַ֖י בַּצָּמָֽא׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּצְעַ֤ק מֹשֶׁה֙ אֶל־יְהוָ֣ה לֵאמֹ֔ר מָ֥ה אֶֽעֱשֶׂ֖ה לָעָ֣ם הַזֶּ֑ה ע֥וֹד מְעַ֖ט וּסְקָלֻֽנִי׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה עֲבֹר֙ לִפְנֵ֣י הָעָ֔ם וְקַ֥ח אִתְּךָ֖ מִזִּקְנֵ֣י יִשְׂרָאֵ֑ל וּמַטְּךָ֗ אֲשֶׁ֨ר הִכִּ֤יתָ בּוֹ֙ אֶת־הַיְאֹ֔ר קַ֥ח בְּיָֽדְךָ֖ וְהָלָֽכְתָּ׃"
          },
          {
            "num": "ו",
            "txt": "הִנְנִ֣י עֹמֵד֩ לְפָנֶ֨יךָ שָּׁ֥ם ׀ עַֽל־הַצּוּר֮ בְּחֹרֵב֒ וְהִכִּ֣יתָ בַצּ֗וּר וְיָֽצְא֥וּ מִמֶּ֛נּוּ מַ֖יִם וְשָׁתָ֣ה הָעָ֑ם וַיַּ֤עַשׂ כֵּן֙ מֹשֶׁ֔ה לְעֵינֵ֖י זִקְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּקְרָא֙ שֵׁ֣ם הַמָּק֔וֹם מַסָּ֖ה וּמְרִיבָ֑ה עַל־רִ֣יב ׀ בְּנֵ֣י יִשְׂרָאֵ֗ל וְעַ֨ל נַסֹּתָ֤ם אֶת־יְהוָה֙ לֵאמֹ֔ר הֲיֵ֧שׁ יְהוָ֛ה בְּקִרְבֵּ֖נוּ אִם־אָֽיִן׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "וַיָּבֹ֖א עֲמָלֵ֑ק וַיִּלָּ֥חֶם עִם־יִשְׂרָאֵ֖ל בִּרְפִידִֽם׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֤ה אֶל־יְהוֹשֻׁ֨עַ֙ בְּחַר־לָ֣נוּ אֲנָשִׁ֔ים וְצֵ֖א הִלָּחֵ֣ם בַּֽעֲמָלֵ֑ק מָחָ֗ר אָֽנֹכִ֤י נִצָּב֙ עַל־רֹ֣אשׁ הַגִּבְעָ֔ה וּמַטֵּ֥ה הָֽאֱלֹהִ֖ים בְּיָדִֽי׃"
          },
          {
            "num": "י",
            "txt": "וַיַּ֣עַשׂ יְהוֹשֻׁ֗עַ כַּֽאֲשֶׁ֤ר אָֽמַר־לוֹ֙ מֹשֶׁ֔ה לְהִלָּחֵ֖ם בַּֽעֲמָלֵ֑ק וּמֹשֶׁה֙ אַֽהֲרֹ֣ן וְח֔וּר עָל֖וּ רֹ֥אשׁ הַגִּבְעָֽה׃"
          },
          {
            "num": "יא",
            "txt": "וְהָיָ֗ה כַּֽאֲשֶׁ֨ר יָרִ֥ים מֹשֶׁ֛ה יָד֖וֹ וְגָבַ֣ר יִשְׂרָאֵ֑ל וְכַֽאֲשֶׁ֥ר יָנִ֛יחַ יָד֖וֹ וְגָבַ֥ר עֲמָלֵֽק׃"
          },
          {
            "num": "יב",
            "txt": "וִידֵ֤י מֹשֶׁה֙ כְּבֵדִ֔ים וַיִּקְחוּ־אֶ֛בֶן וַיָּשִׂ֥ימוּ תַחְתָּ֖יו וַיֵּ֣שֶׁב עָלֶ֑יהָ וְאַֽהֲרֹ֨ן וְח֜וּר תָּֽמְכ֣וּ בְיָדָ֗יו מִזֶּ֤ה אֶחָד֙ וּמִזֶּ֣ה אֶחָ֔ד וַיְהִ֥י יָדָ֛יו אֱמוּנָ֖ה עַד־בֹּ֥א הַשָּֽׁמֶשׁ׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּֽחֲלֹ֧שׁ יְהוֹשֻׁ֛עַ אֶת־עֲמָלֵ֥ק וְאֶת־עַמּ֖וֹ לְפִי־חָֽרֶב׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה כְּתֹ֨ב זֹ֤את זִכָּרוֹן֙ בַּסֵּ֔פֶר וְשִׂ֖ים בְּאָזְנֵ֣י יְהוֹשֻׁ֑עַ כִּֽי־מָחֹ֤ה אֶמְחֶה֙ אֶת־זֵ֣כֶר עֲמָלֵ֔ק מִתַּ֖חַת הַשָּׁמָֽיִם׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּ֥בֶן מֹשֶׁ֖ה מִזְבֵּ֑חַ וַיִּקְרָ֥א שְׁמ֖וֹ יְהוָ֥ה ׀ נִסִּֽי׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֗אמֶר כִּֽי־יָד֙ עַל־כֵּ֣ס יָ֔הּ מִלְחָמָ֥ה לַֽיהוָ֖ה בַּֽעֲמָלֵ֑ק מִדֹּ֖ר דֹּֽר׃ {פ}"
          }
        ]
      },
      {
        "num": "יח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּשְׁמַ֞ע יִתְר֨וֹ כֹהֵ֤ן מִדְיָן֙ חֹתֵ֣ן מֹשֶׁ֔ה אֵת֩ כָּל־אֲשֶׁ֨ר עָשָׂ֤ה אֱלֹהִים֙ לְמֹשֶׁ֔ה וּלְיִשְׂרָאֵ֖ל עַמּ֑וֹ כִּֽי־הוֹצִ֧יא יְהוָ֛ה אֶת־יִשְׂרָאֵ֖ל מִמִּצְרָֽיִם׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּקַּ֗ח יִתְרוֹ֙ חֹתֵ֣ן מֹשֶׁ֔ה אֶת־צִפֹּרָ֖ה אֵ֣שֶׁת מֹשֶׁ֑ה אַחַ֖ר שִׁלּוּחֶֽיהָ׃"
          },
          {
            "num": "ג",
            "txt": "וְאֵ֖ת שְׁנֵ֣י בָנֶ֑יהָ אֲשֶׁ֨ר שֵׁ֤ם הָֽאֶחָד֙ גֵּֽרְשֹׁ֔ם כִּ֣י אָמַ֔ר גֵּ֣ר הָיִ֔יתִי בְּאֶ֖רֶץ נָכְרִיָּֽה׃"
          },
          {
            "num": "ד",
            "txt": "וְשֵׁ֥ם הָֽאֶחָ֖ד אֱלִיעֶ֑זֶר כִּֽי־אֱלֹהֵ֤י אָבִי֙ בְּעֶזְרִ֔י וַיַּצִּלֵ֖נִי מֵחֶ֥רֶב פַּרְעֹֽה׃"
          },
          {
            "num": "ה",
            "txt": "וַיָּבֹ֞א יִתְר֨וֹ חֹתֵ֥ן מֹשֶׁ֛ה וּבָנָ֥יו וְאִשְׁתּ֖וֹ אֶל־מֹשֶׁ֑ה אֶל־הַמִּדְבָּ֗ר אֲשֶׁר־ה֛וּא חֹנֶ֥ה שָׁ֖ם הַ֥ר הָֽאֱלֹהִֽים׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֨אמֶר֙ אֶל־מֹשֶׁ֔ה אֲנִ֛י חֹֽתֶנְךָ֥ יִתְר֖וֹ בָּ֣א אֵלֶ֑יךָ וְאִ֨שְׁתְּךָ֔ וּשְׁנֵ֥י בָנֶ֖יהָ עִמָּֽהּ׃"
          },
          {
            "num": "ז",
            "txt": "וַיֵּצֵ֨א מֹשֶׁ֜ה לִקְרַ֣את חֹֽתְנ֗וֹ וַיִּשְׁתַּ֨חוּ֙ וַיִּשַּׁק־ל֔וֹ וַיִּשְׁאֲל֥וּ אִישׁ־לְרֵעֵ֖הוּ לְשָׁל֑וֹם וַיָּבֹ֖אוּ הָאֹֽהֱלָה׃"
          },
          {
            "num": "ח",
            "txt": "וַיְסַפֵּ֤ר מֹשֶׁה֙ לְחֹ֣תְנ֔וֹ אֵת֩ כָּל־אֲשֶׁ֨ר עָשָׂ֤ה יְהוָה֙ לְפַרְעֹ֣ה וּלְמִצְרַ֔יִם עַ֖ל אוֹדֹ֣ת יִשְׂרָאֵ֑ל אֵ֤ת כָּל־הַתְּלָאָה֙ אֲשֶׁ֣ר מְצָאָ֣תַם בַּדֶּ֔רֶךְ וַיַּצִּלֵ֖ם יְהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּ֣חַדְּ יִתְר֔וֹ עַ֚ל כָּל־הַטּוֹבָ֔ה אֲשֶׁר־עָשָׂ֥ה יְהוָ֖ה לְיִשְׂרָאֵ֑ל אֲשֶׁ֥ר הִצִּיל֖וֹ מִיַּ֥ד מִצְרָֽיִם׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּאמֶר֮ יִתְרוֹ֒ בָּר֣וּךְ יְהוָ֔ה אֲשֶׁ֨ר הִצִּ֥יל אֶתְכֶ֛ם מִיַּ֥ד מִצְרַ֖יִם וּמִיַּ֣ד פַּרְעֹ֑ה אֲשֶׁ֤ר הִצִּיל֙ אֶת־הָעָ֔ם מִתַּ֖חַת יַד־מִצְרָֽיִם׃"
          },
          {
            "num": "יא",
            "txt": "עַתָּ֣ה יָדַ֔עְתִּי כִּֽי־גָד֥וֹל יְהוָ֖ה מִכָּל־הָֽאֱלֹהִ֑ים כִּ֣י בַדָּבָ֔ר אֲשֶׁ֥ר זָד֖וּ עֲלֵיהֶֽם׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּקַּ֞ח יִתְר֨וֹ חֹתֵ֥ן מֹשֶׁ֛ה עֹלָ֥ה וּזְבָחִ֖ים לֵֽאלֹהִ֑ים וַיָּבֹ֨א אַֽהֲרֹ֜ן וְכֹ֣ל ׀ זִקְנֵ֣י יִשְׂרָאֵ֗ל לֶֽאֱכָל־לֶ֛חֶם עִם־חֹתֵ֥ן מֹשֶׁ֖ה לִפְנֵ֥י הָֽאֱלֹהִֽים׃"
          },
          {
            "num": "יג",
            "txt": "וַֽיְהִי֙ מִֽמָּחֳרָ֔ת וַיֵּ֥שֶׁב מֹשֶׁ֖ה לִשְׁפֹּ֣ט אֶת־הָעָ֑ם וַיַּֽעֲמֹ֤ד הָעָם֙ עַל־מֹשֶׁ֔ה מִן־הַבֹּ֖קֶר עַד־הָעָֽרֶב׃"
          },
          {
            "num": "יד",
            "txt": "וַיַּרְא֙ חֹתֵ֣ן מֹשֶׁ֔ה אֵ֛ת כָּל־אֲשֶׁר־ה֥וּא עֹשֶׂ֖ה לָעָ֑ם וַיֹּ֗אמֶר מָֽה־הַדָּבָ֤ר הַזֶּה֙ אֲשֶׁ֨ר אַתָּ֤ה עֹשֶׂה֙ לָעָ֔ם מַדּ֗וּעַ אַתָּ֤ה יוֹשֵׁב֙ לְבַדֶּ֔ךָ וְכָל־הָעָ֛ם נִצָּ֥ב עָלֶ֖יךָ מִן־בֹּ֥קֶר עַד־עָֽרֶב׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֥אמֶר מֹשֶׁ֖ה לְחֹֽתְנ֑וֹ כִּֽי־יָבֹ֥א אֵלַ֛י הָעָ֖ם לִדְרֹ֥שׁ אֱלֹהִֽים׃"
          },
          {
            "num": "טז",
            "txt": "כִּֽי־יִהְיֶ֨ה לָהֶ֤ם דָּבָר֙ בָּ֣א אֵלַ֔י וְשָׁ֣פַטְתִּ֔י בֵּ֥ין אִ֖ישׁ וּבֵ֣ין רֵעֵ֑הוּ וְהֽוֹדַעְתִּ֛י אֶת־חֻקֵּ֥י הָֽאֱלֹהִ֖ים וְאֶת־תּֽוֹרֹתָֽיו׃"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֛אמֶר חֹתֵ֥ן מֹשֶׁ֖ה אֵלָ֑יו לֹא־טוֹב֙ הַדָּבָ֔ר אֲשֶׁ֥ר אַתָּ֖ה עֹשֶֽׂה׃"
          },
          {
            "num": "יח",
            "txt": "נָבֹ֣ל תִּבֹּ֔ל גַּם־אַתָּ֕ה גַּם־הָעָ֥ם הַזֶּ֖ה אֲשֶׁ֣ר עִמָּ֑ךְ כִּֽי־כָבֵ֤ד מִמְּךָ֙ הַדָּבָ֔ר לֹֽא־תוּכַ֥ל עֲשֹׂ֖הוּ לְבַדֶּֽךָ׃"
          },
          {
            "num": "יט",
            "txt": "עַתָּ֞ה שְׁמַ֤ע בְּקֹלִי֙ אִיעָ֣צְךָ֔ וִיהִ֥י אֱלֹהִ֖ים עִמָּ֑ךְ הֱיֵ֧ה אַתָּ֣ה לָעָ֗ם מ֚וּל הָֽאֱלֹהִ֔ים וְהֵֽבֵאתָ֥ אַתָּ֛ה אֶת־הַדְּבָרִ֖ים אֶל־הָֽאֱלֹהִֽים׃"
          },
          {
            "num": "כ",
            "txt": "וְהִזְהַרְתָּ֣ה אֶתְהֶ֔ם אֶת־הַֽחֻקִּ֖ים וְאֶת־הַתּוֹרֹ֑ת וְהֽוֹדַעְתָּ֣ לָהֶ֗ם אֶת־הַדֶּ֨רֶךְ֙ יֵ֣לְכוּ בָ֔הּ וְאֶת־הַֽמַּעֲשֶׂ֖ה אֲשֶׁ֥ר יַֽעֲשֽׂוּן׃"
          },
          {
            "num": "כא",
            "txt": "וְאַתָּ֣ה תֶֽחֱזֶ֣ה מִכָּל־הָ֠עָם אַנְשֵׁי־חַ֜יִל יִרְאֵ֧י אֱלֹהִ֛ים אַנְשֵׁ֥י אֱמֶ֖ת שֹׂ֣נְאֵי בָ֑צַע וְשַׂמְתָּ֣ עֲלֵהֶ֗ם שָׂרֵ֤י אֲלָפִים֙ שָׂרֵ֣י מֵא֔וֹת שָׂרֵ֥י חֲמִשִּׁ֖ים וְשָׂרֵ֥י עֲשָׂרֹֽת׃"
          },
          {
            "num": "כב",
            "txt": "וְשָֽׁפְט֣וּ אֶת־הָעָם֮ בְּכָל־עֵת֒ וְהָיָ֞ה כָּל־הַדָּבָ֤ר הַגָּדֹל֙ יָבִ֣יאוּ אֵלֶ֔יךָ וְכָל־הַדָּבָ֥ר הַקָּטֹ֖ן יִשְׁפְּטוּ־הֵ֑ם וְהָקֵל֙ מֵֽעָלֶ֔יךָ וְנָֽשְׂא֖וּ אִתָּֽךְ׃"
          },
          {
            "num": "כג",
            "txt": "אִ֣ם אֶת־הַדָּבָ֤ר הַזֶּה֙ תַּֽעֲשֶׂ֔ה וְצִוְּךָ֣ אֱלֹהִ֔ים וְיָֽכָלְתָּ֖ עֲמֹ֑ד וְגַם֙ כָּל־הָעָ֣ם הַזֶּ֔ה עַל־מְקֹמ֖וֹ יָבֹ֥א בְשָׁלֽוֹם׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּשְׁמַ֥ע מֹשֶׁ֖ה לְק֣וֹל חֹֽתְנ֑וֹ וַיַּ֕עַשׂ כֹּ֖ל אֲשֶׁ֥ר אָמָֽר׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּבְחַ֨ר מֹשֶׁ֤ה אַנְשֵׁי־חַ֨יִל֙ מִכָּל־יִשְׂרָאֵ֔ל וַיִּתֵּ֥ן אֹתָ֛ם רָאשִׁ֖ים עַל־הָעָ֑ם שָׂרֵ֤י אֲלָפִים֙ שָׂרֵ֣י מֵא֔וֹת שָׂרֵ֥י חֲמִשִּׁ֖ים וְשָׂרֵ֥י עֲשָׂרֹֽת׃"
          },
          {
            "num": "כו",
            "txt": "וְשָֽׁפְט֥וּ אֶת־הָעָ֖ם בְּכָל־עֵ֑ת אֶת־הַדָּבָ֤ר הַקָּשֶׁה֙ יְבִיא֣וּן אֶל־מֹשֶׁ֔ה וְכָל־הַדָּבָ֥ר הַקָּטֹ֖ן יִשְׁפּוּט֥וּ הֵֽם׃"
          },
          {
            "num": "כז",
            "txt": "וַיְשַׁלַּ֥ח מֹשֶׁ֖ה אֶת־חֹֽתְנ֑וֹ וַיֵּ֥לֶךְ ל֖וֹ אֶל־אַרְצֽוֹ׃ {פ}"
          }
        ]
      },
      {
        "num": "יט",
        "verses": [
          {
            "num": "א",
            "txt": "בַּחֹ֨דֶשׁ֙ הַשְּׁלִישִׁ֔י לְצֵ֥את בְּנֵֽי־יִשְׂרָאֵ֖ל מֵאֶ֣רֶץ מִצְרָ֑יִם בַּיּ֣וֹם הַזֶּ֔ה בָּ֖אוּ מִדְבַּ֥ר סִינָֽי׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּסְע֣וּ מֵֽרְפִידִ֗ים וַיָּבֹ֨אוּ֙ מִדְבַּ֣ר סִינַ֔י וַֽיַּחֲנ֖וּ בַּמִּדְבָּ֑ר וַיִּֽחַן־שָׁ֥ם יִשְׂרָאֵ֖ל נֶ֥גֶד הָהָֽר׃"
          },
          {
            "num": "ג",
            "txt": "וּמֹשֶׁ֥ה עָלָ֖ה אֶל־הָֽאֱלֹהִ֑ים וַיִּקְרָ֨א אֵלָ֤יו יְהוָה֙ מִן־הָהָ֣ר לֵאמֹ֔ר כֹּ֤ה תֹאמַר֙ לְבֵ֣ית יַֽעֲקֹ֔ב וְתַגֵּ֖יד לִבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ד",
            "txt": "אַתֶּ֣ם רְאִיתֶ֔ם אֲשֶׁ֥ר עָשִׂ֖יתִי לְמִצְרָ֑יִם וָֽאֶשָּׂ֤א אֶתְכֶם֙ עַל־כַּנְפֵ֣י נְשָׁרִ֔ים וָֽאָבִ֥א אֶתְכֶ֖ם אֵלָֽי׃"
          },
          {
            "num": "ה",
            "txt": "וְעַתָּ֗ה אִם־שָׁמ֤וֹעַ תִּשְׁמְעוּ֙ בְּקֹלִ֔י וּשְׁמַרְתֶּ֖ם אֶת־בְּרִיתִ֑י וִֽהְיִ֨יתֶם לִ֤י סְגֻלָּה֙ מִכָּל־הָ֣עַמִּ֔ים כִּי־לִ֖י כָּל־הָאָֽרֶץ׃"
          },
          {
            "num": "ו",
            "txt": "וְאַתֶּ֧ם תִּֽהְיוּ־לִ֛י מַמְלֶ֥כֶת כֹּֽהֲנִ֖ים וְג֣וֹי קָד֑וֹשׁ אֵ֚לֶּה הַדְּבָרִ֔ים אֲשֶׁ֥ר תְּדַבֵּ֖ר אֶל־בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ז",
            "txt": "וַיָּבֹ֣א מֹשֶׁ֔ה וַיִּקְרָ֖א לְזִקְנֵ֣י הָעָ֑ם וַיָּ֣שֶׂם לִפְנֵיהֶ֗ם אֵ֚ת כָּל־הַדְּבָרִ֣ים הָאֵ֔לֶּה אֲשֶׁ֥ר צִוָּ֖הוּ יְהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וַיַּֽעֲנ֨וּ כָל־הָעָ֤ם יַחְדָּו֙ וַיֹּ֣אמְר֔וּ כֹּ֛ל אֲשֶׁר־דִּבֶּ֥ר יְהוָ֖ה נַֽעֲשֶׂ֑ה וַיָּ֧שֶׁב מֹשֶׁ֛ה אֶת־דִּבְרֵ֥י הָעָ֖ם אֶל־יְהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה הִנֵּ֨ה אָֽנֹכִ֜י בָּ֣א אֵלֶיךָ֮ בְּעַ֣ב הֶֽעָנָן֒ בַּֽעֲב֞וּר יִשְׁמַ֤ע הָעָם֙ בְּדַבְּרִ֣י עִמָּ֔ךְ וְגַם־בְּךָ֖ יַֽאֲמִ֣ינוּ לְעוֹלָ֑ם וַיַּגֵּ֥ד מֹשֶׁ֛ה אֶת־דִּבְרֵ֥י הָעָ֖ם אֶל־יְהוָֽה׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֨אמֶר יְהוָ֤ה אֶל־מֹשֶׁה֙ לֵ֣ךְ אֶל־הָעָ֔ם וְקִדַּשְׁתָּ֥ם הַיּ֖וֹם וּמָחָ֑ר וְכִבְּס֖וּ שִׂמְלֹתָֽם׃"
          },
          {
            "num": "יא",
            "txt": "וְהָי֥וּ נְכֹנִ֖ים לַיּ֣וֹם הַשְּׁלִישִׁ֑י כִּ֣י ׀ בַּיּ֣וֹם הַשְּׁלִשִׁ֗י יֵרֵ֧ד יְהוָ֛ה לְעֵינֵ֥י כָל־הָעָ֖ם עַל־הַ֥ר סִינָֽי׃"
          },
          {
            "num": "יב",
            "txt": "וְהִגְבַּלְתָּ֤ אֶת־הָעָם֙ סָבִ֣יב לֵאמֹ֔ר הִשָּֽׁמְר֥וּ לָכֶ֛ם עֲל֥וֹת בָּהָ֖ר וּנְגֹ֣עַ בְּקָצֵ֑הוּ כָּל־הַנֹּגֵ֥עַ בָּהָ֖ר מ֥וֹת יוּמָֽת׃"
          },
          {
            "num": "יג",
            "txt": "לֹֽא־תִגַּ֨ע בּ֜וֹ יָ֗ד כִּֽי־סָק֤וֹל יִסָּקֵל֙ אֽוֹ־יָרֹ֣ה יִיָּרֶ֔ה אִם־בְּהֵמָ֥ה אִם־אִ֖ישׁ לֹ֣א יִֽחְיֶ֑ה בִּמְשֹׁךְ֙ הַיֹּבֵ֔ל הֵ֖מָּה יַֽעֲל֥וּ בָהָֽר׃"
          },
          {
            "num": "יד",
            "txt": "וַיֵּ֧רֶד מֹשֶׁ֛ה מִן־הָהָ֖ר אֶל־הָעָ֑ם וַיְקַדֵּשׁ֙ אֶת־הָעָ֔ם וַֽיְכַבְּס֖וּ שִׂמְלֹתָֽם׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֨אמֶר֙ אֶל־הָעָ֔ם הֱי֥וּ נְכֹנִ֖ים לִשְׁלֹ֣שֶׁת יָמִ֑ים אַֽל־תִּגְּשׁ֖וּ אֶל־אִשָּֽׁה׃"
          },
          {
            "num": "טז",
            "txt": "וַיְהִי֩ בַיּ֨וֹם הַשְּׁלִישִׁ֜י בִּֽהְיֹ֣ת הַבֹּ֗קֶר וַיְהִי֩ קֹלֹ֨ת וּבְרָקִ֜ים וְעָנָ֤ן כָּבֵד֙ עַל־הָהָ֔ר וְקֹ֥ל שֹׁפָ֖ר חָזָ֣ק מְאֹ֑ד וַיֶּֽחֱרַ֥ד כָּל־הָעָ֖ם אֲשֶׁ֥ר בַּֽמַּחֲנֶֽה׃"
          },
          {
            "num": "יז",
            "txt": "וַיּוֹצֵ֨א מֹשֶׁ֧ה אֶת־הָעָ֛ם לִקְרַ֥את הָֽאֱלֹהִ֖ים מִן־הַֽמַּחֲנֶ֑ה וַיִּֽתְיַצְּב֖וּ בְּתַחְתִּ֥ית הָהָֽר׃"
          },
          {
            "num": "יח",
            "txt": "וְהַ֤ר סִינַי֙ עָשַׁ֣ן כֻּלּ֔וֹ מִ֠פְּנֵי אֲשֶׁ֨ר יָרַ֥ד עָלָ֛יו יְהוָ֖ה בָּאֵ֑שׁ וַיַּ֤עַל עֲשָׁנוֹ֙ כְּעֶ֣שֶׁן הַכִּבְשָׁ֔ן וַיֶּֽחֱרַ֥ד כָּל־הָהָ֖ר מְאֹֽד׃"
          },
          {
            "num": "יט",
            "txt": "וַֽיְהִי֙ ק֣וֹל הַשֹּׁפָ֔ר הוֹלֵ֖ךְ וְחָזֵ֣ק מְאֹ֑ד מֹשֶׁ֣ה יְדַבֵּ֔ר וְהָֽאֱלֹהִ֖ים יַֽעֲנֶ֥נּֽוּ בְקֽוֹל׃"
          },
          {
            "num": "כ",
            "txt": "וַיֵּ֧רֶד יְהוָ֛ה עַל־הַ֥ר סִינַ֖י אֶל־רֹ֣אשׁ הָהָ֑ר וַיִּקְרָ֨א יְהוָ֧ה לְמֹשֶׁ֛ה אֶל־רֹ֥אשׁ הָהָ֖ר וַיַּ֥עַל מֹשֶֽׁה׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה רֵ֖ד הָעֵ֣ד בָּעָ֑ם פֶּן־יֶֽהֶרְס֤וּ אֶל־יְהוָה֙ לִרְא֔וֹת וְנָפַ֥ל מִמֶּ֖נּוּ רָֽב׃"
          },
          {
            "num": "כב",
            "txt": "וְגַ֧ם הַכֹּֽהֲנִ֛ים הַנִּגָּשִׁ֥ים אֶל־יְהוָ֖ה יִתְקַדָּ֑שׁוּ פֶּן־יִפְרֹ֥ץ בָּהֶ֖ם יְהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֤אמֶר מֹשֶׁה֙ אֶל־יְהוָ֔ה לֹֽא־יוּכַ֣ל הָעָ֔ם לַֽעֲלֹ֖ת אֶל־הַ֣ר סִינָ֑י כִּֽי־אַתָּ֞ה הַֽעֵדֹ֤תָה בָּ֨נוּ֙ לֵאמֹ֔ר הַגְבֵּ֥ל אֶת־הָהָ֖ר וְקִדַּשְׁתּֽוֹ׃"
          },
          {
            "num": "כד",
            "txt": "וַיֹּ֨אמֶר אֵלָ֤יו יְהוָה֙ לֶךְ־רֵ֔ד וְעָלִ֥יתָ אַתָּ֖ה וְאַֽהֲרֹ֣ן עִמָּ֑ךְ וְהַכֹּֽהֲנִ֣ים וְהָעָ֗ם אַל־יֶֽהֶרְס֛וּ לַֽעֲלֹ֥ת אֶל־יְהוָ֖ה פֶּן־יִפְרָץ־בָּֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַיֵּ֥רֶד מֹשֶׁ֖ה אֶל־הָעָ֑ם וַיֹּ֖אמֶר אֲלֵהֶֽם׃ {ס}"
          }
        ]
      },
      {
        "num": "כ",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֣ר אֱלֹהִ֔ים אֵ֛ת כָּל־הַדְּבָרִ֥ים הָאֵ֖לֶּה לֵאמֹֽר׃ {ס}"
          },
          {
            "num": "ב",
            "txt": "אָֽנֹכִי֙ יְהוָ֣ה אֱלֹהֶ֔יךָ אֲשֶׁ֧ר הֽוֹצֵאתִ֛יךָ מֵאֶ֥רֶץ מִצְרַ֖יִם מִבֵּ֣ית עֲבָדִ֑ים לֹֽא־יִהְיֶ֥ה לְךָ֛ אֱלֹהִ֥ים אֲחֵרִ֖ים עַל־פָּנָֽי׃"
          },
          {
            "num": "ג",
            "txt": "לֹֽא־תַעֲשֶׂ֨ה לְךָ֥ פֶ֨סֶל֙ וְכָל־תְּמוּנָ֔ה אֲשֶׁ֤ר בַּשָּׁמַ֨יִם֙ מִמַּ֔עַל וַֽאֲשֶׁ֥ר בָּאָ֖רֶץ מִתָּ֑חַת וַֽאֲשֶׁ֥ר בַּמַּ֖יִם מִתַּ֥חַת לָאָֽרֶץ׃"
          },
          {
            "num": "ד",
            "txt": "לֹֽא־תִשְׁתַּחֲוֶ֥ה לָהֶ֖ם וְלֹ֣א תָֽעָבְדֵ֑ם כִּ֣י אָֽנֹכִ֞י יְהוָ֤ה אֱלֹהֶ֨יךָ֙ אֵ֣ל קַנָּ֔א פֹּ֠קֵד עֲוֺ֨ן אָבֹ֧ת עַל־בָּנִ֛ים עַל־שִׁלֵּשִׁ֥ים וְעַל־רִבֵּעִ֖ים לְשֹֽׂנְאָֽי׃"
          },
          {
            "num": "ה",
            "txt": "וְעֹ֥שֶׂה חֶ֖סֶד לַֽאֲלָפִ֑ים לְאֹֽהֲבַ֖י וּלְשֹֽׁמְרֵ֥י מִצְוֺתָֽי׃ {ס}"
          },
          {
            "num": "ו",
            "txt": "לֹ֥א תִשָּׂ֛א אֶת־שֵֽׁם־יְהוָ֥ה אֱלֹהֶ֖יךָ לַשָּׁ֑וְא כִּ֣י לֹ֤א יְנַקֶּה֙ יְהוָ֔ה אֵ֛ת אֲשֶׁר־יִשָּׂ֥א אֶת־שְׁמ֖וֹ לַשָּֽׁוְא׃ {פ}"
          },
          {
            "num": "ז",
            "txt": "זָכ֛וֹר אֶת־י֥וֹם הַשַּׁבָּ֖ת לְקַדְּשֽׁוֹ׃"
          },
          {
            "num": "ח",
            "txt": "שֵׁ֤שֶׁת יָמִים֙ תַּֽעֲבֹ֔ד וְעָשִׂ֖יתָ כָּל־מְלַאכְתֶּֽךָ׃"
          },
          {
            "num": "ט",
            "txt": "וְיוֹם֙ הַשְּׁבִיעִ֔י שַׁבָּ֖ת לַֽיהוָ֣ה אֱלֹהֶ֑יךָ לֹֽא־תַעֲשֶׂ֨ה כָל־מְלָאכָ֜ה אַתָּ֣ה ׀ וּבִנְךָ֣ וּבִתֶּ֗ךָ עַבְדְּךָ֤ וַאֲמָֽתְךָ֙ וּבְהֶמְתֶּ֔ךָ וְגֵֽרְךָ֖ אֲשֶׁ֥ר בִּשְׁעָרֶֽיךָ׃"
          },
          {
            "num": "י",
            "txt": "כִּ֣י שֵֽׁשֶׁת־יָמִים֩ עָשָׂ֨ה יְהוָ֜ה אֶת־הַשָּׁמַ֣יִם וְאֶת־הָאָ֗רֶץ אֶת־הַיָּם֙ וְאֶת־כָּל־אֲשֶׁר־בָּ֔ם וַיָּ֖נַח בַּיּ֣וֹם הַשְּׁבִיעִ֑י עַל־כֵּ֗ן בֵּרַ֧ךְ יְהוָ֛ה אֶת־י֥וֹם הַשַּׁבָּ֖ת וַֽיְקַדְּשֵֽׁהוּ׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "כַּבֵּ֥ד אֶת־אָבִ֖יךָ וְאֶת־אִמֶּ֑ךָ לְמַ֨עַן֙ יַֽאֲרִכ֣וּן יָמֶ֔יךָ עַ֚ל הָֽאֲדָמָ֔ה אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֥ן לָֽךְ׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "לֹ֥א תִרְצָ֖ח {ס} לֹ֣א תִנְאָ֑ף {ס} לֹ֣א תִגְנֹ֔ב {ס} לֹֽא־תַעֲנֶ֥ה בְרֵֽעֲךָ֖ עֵ֥ד שָֽׁקֶר׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "לֹ֥א תַחְמֹ֖ד בֵּ֣ית רֵעֶ֑ךָ {ס} לֹֽא־תַחְמֹ֞ד אֵ֣שֶׁת רֵעֶ֗ךָ וְעַבְדּ֤וֹ וַֽאֲמָתוֹ֙ וְשׁוֹר֣וֹ וַֽחֲמֹר֔וֹ וְכֹ֖ל אֲשֶׁ֥ר לְרֵעֶֽךָ׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "וְכָל־הָעָם֩ רֹאִ֨ים אֶת־הַקּוֹלֹ֜ת וְאֶת־הַלַּפִּידִ֗ם וְאֵת֙ ק֣וֹל הַשֹּׁפָ֔ר וְאֶת־הָהָ֖ר עָשֵׁ֑ן וַיַּ֤רְא הָעָם֙ וַיָּנֻ֔עוּ וַיַּֽעַמְד֖וּ מֵֽרָחֹֽק׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּֽאמְרוּ֙ אֶל־מֹשֶׁ֔ה דַּבֵּר־אַתָּ֥ה עִמָּ֖נוּ וְנִשְׁמָ֑עָה וְאַל־יְדַבֵּ֥ר עִמָּ֛נוּ אֱלֹהִ֖ים פֶּן־נָמֽוּת׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֣ה אֶל־הָעָם֮ אַל־תִּירָאוּ֒ כִּ֗י לְבַֽעֲבוּר֙ נַסּ֣וֹת אֶתְכֶ֔ם בָּ֖א הָֽאֱלֹהִ֑ים וּבַֽעֲב֗וּר תִּֽהְיֶ֧ה יִרְאָת֛וֹ עַל־פְּנֵיכֶ֖ם לְבִלְתִּ֥י תֶֽחֱטָֽאוּ׃"
          },
          {
            "num": "יז",
            "txt": "וַיַּֽעֲמֹ֥ד הָעָ֖ם מֵֽרָחֹ֑ק וּמֹשֶׁה֙ נִגַּ֣שׁ אֶל־הָֽעֲרָפֶ֔ל אֲשֶׁר־שָׁ֖ם הָֽאֱלֹהִֽים׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה כֹּ֥ה תֹאמַ֖ר אֶל־בְּנֵ֣י יִשְׂרָאֵ֑ל אַתֶּ֣ם רְאִיתֶ֔ם כִּ֚י מִן־הַשָּׁמַ֔יִם דִּבַּ֖רְתִּי עִמָּכֶֽם׃"
          },
          {
            "num": "יט",
            "txt": "לֹ֥א תַֽעֲשׂ֖וּן אִתִּ֑י אֱלֹ֤הֵי כֶ֨סֶף֙ וֵֽאלֹהֵ֣י זָהָ֔ב לֹ֥א תַֽעֲשׂ֖וּ לָכֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "מִזְבַּ֣ח אֲדָמָה֮ תַּֽעֲשֶׂה־לִּי֒ וְזָֽבַחְתָּ֣ עָלָ֗יו אֶת־עֹֽלֹתֶ֨יךָ֙ וְאֶת־שְׁלָמֶ֔יךָ אֶת־צֹֽאנְךָ֖ וְאֶת־בְּקָרֶ֑ךָ בְּכָל־הַמָּקוֹם֙ אֲשֶׁ֣ר אַזְכִּ֣יר אֶת־שְׁמִ֔י אָב֥וֹא אֵלֶ֖יךָ וּבֵֽרַכְתִּֽיךָ׃"
          },
          {
            "num": "כא",
            "txt": "וְאִם־מִזְבַּ֤ח אֲבָנִים֙ תַּֽעֲשֶׂה־לִּ֔י לֹֽא־תִבְנֶ֥ה אֶתְהֶ֖ן גָּזִ֑ית כִּ֧י חַרְבְּךָ֛ הֵנַ֥פְתָּ עָלֶ֖יהָ וַתְּחַֽלְלֶֽהָ׃"
          },
          {
            "num": "כב",
            "txt": "וְלֹֽא־תַעֲלֶ֥ה בְמַֽעֲלֹ֖ת עַֽל־מִזְבְּחִ֑י אֲשֶׁ֛ר לֹֽא־תִגָּלֶ֥ה עֶרְוָֽתְךָ֖ עָלָֽיו׃ {פ}"
          }
        ]
      },
      {
        "num": "כא",
        "verses": [
          {
            "num": "א",
            "txt": "וְאֵ֨לֶּה֙ הַמִּשְׁפָּטִ֔ים אֲשֶׁ֥ר תָּשִׂ֖ים לִפְנֵיהֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "כִּ֤י תִקְנֶה֙ עֶ֣בֶד עִבְרִ֔י שֵׁ֥שׁ שָׁנִ֖ים יַֽעֲבֹ֑ד וּבַ֨שְּׁבִעִ֔ת יֵצֵ֥א לַֽחָפְשִׁ֖י חִנָּֽם׃"
          },
          {
            "num": "ג",
            "txt": "אִם־בְּגַפּ֥וֹ יָבֹ֖א בְּגַפּ֣וֹ יֵצֵ֑א אִם־בַּ֤עַל אִשָּׁה֙ ה֔וּא וְיָֽצְאָ֥ה אִשְׁתּ֖וֹ עִמּֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "אִם־אֲדֹנָיו֙ יִתֶּן־ל֣וֹ אִשָּׁ֔ה וְיָֽלְדָה־ל֥וֹ בָנִ֖ים א֣וֹ בָנ֑וֹת הָֽאִשָּׁ֣ה וִֽילָדֶ֗יהָ תִּֽהְיֶה֙ לַֽאדֹנֶ֔יהָ וְה֖וּא יֵצֵ֥א בְגַפּֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "וְאִם־אָמֹ֤ר יֹאמַר֙ הָעֶ֔בֶד אָהַ֨בְתִּי֙ אֶת־אֲדֹנִ֔י אֶת־אִשְׁתִּ֖י וְאֶת־בָּנָ֑י לֹ֥א אֵצֵ֖א חָפְשִֽׁי׃"
          },
          {
            "num": "ו",
            "txt": "וְהִגִּישׁ֤וֹ אֲדֹנָיו֙ אֶל־הָ֣אֱלֹהִ֔ים וְהִגִּישׁוֹ֙ אֶל־הַדֶּ֔לֶת א֖וֹ אֶל־הַמְּזוּזָ֑ה וְרָצַ֨ע אֲדֹנָ֤יו אֶת־אָזְנוֹ֙ בַּמַּרְצֵ֔עַ וַֽעֲבָד֖וֹ לְעֹלָֽם׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "וְכִֽי־יִמְכֹּ֥ר אִ֛ישׁ אֶת־בִּתּ֖וֹ לְאָמָ֑ה לֹ֥א תֵצֵ֖א כְּצֵ֥את הָֽעֲבָדִֽים׃"
          },
          {
            "num": "ח",
            "txt": "אִם־רָעָ֞ה בְּעֵינֵ֧י אֲדֹנֶ֛יהָ אֲשֶׁר־לא (ל֥וֹ) יְעָדָ֖הּ וְהֶפְדָּ֑הּ לְעַ֥ם נָכְרִ֛י לֹֽא־יִמְשֹׁ֥ל לְמָכְרָ֖הּ בְּבִגְדוֹ־בָֽהּ׃"
          },
          {
            "num": "ט",
            "txt": "וְאִם־לִבְנ֖וֹ יִֽיעָדֶ֑נָּה כְּמִשְׁפַּ֥ט הַבָּנ֖וֹת יַֽעֲשֶׂה־לָּֽהּ׃"
          },
          {
            "num": "י",
            "txt": "אִם־אַחֶ֖רֶת יִֽקַּֽח־ל֑וֹ שְׁאֵרָ֛הּ כְּסוּתָ֥הּ וְעֹֽנָתָ֖הּ לֹ֥א יִגְרָֽע׃"
          },
          {
            "num": "יא",
            "txt": "וְאִ֨ם־שְׁלָשׁ־אֵ֔לֶּה לֹ֥א יַֽעֲשֶׂ֖ה לָ֑הּ וְיָֽצְאָ֥ה חִנָּ֖ם אֵ֥ין כָּֽסֶף׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "מַכֵּ֥ה אִ֛ישׁ וָמֵ֖ת מ֥וֹת יוּמָֽת׃"
          },
          {
            "num": "יג",
            "txt": "וַֽאֲשֶׁר֙ לֹ֣א צָדָ֔ה וְהָֽאֱלֹהִ֖ים אִנָּ֣ה לְיָד֑וֹ וְשַׂמְתִּ֤י לְךָ֙ מָק֔וֹם אֲשֶׁ֥ר יָנ֖וּס שָֽׁמָּה׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "וְכִֽי־יָזִ֥ד אִ֛ישׁ עַל־רֵעֵ֖הוּ לְהָרְג֣וֹ בְעָרְמָ֑ה מֵעִ֣ם מִזְבְּחִ֔י תִּקָּחֶ֖נּוּ לָמֽוּת׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וּמַכֵּ֥ה אָבִ֛יו וְאִמּ֖וֹ מ֥וֹת יוּמָֽת׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "וְגֹנֵ֨ב אִ֧ישׁ וּמְכָר֛וֹ וְנִמְצָ֥א בְיָד֖וֹ מ֥וֹת יוּמָֽת׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וּמְקַלֵּ֥ל אָבִ֛יו וְאִמּ֖וֹ מ֥וֹת יוּמָֽת׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "וְכִֽי־יְרִיבֻ֣ן אֲנָשִׁ֔ים וְהִכָּה־אִישׁ֙ אֶת־רֵעֵ֔הוּ בְּאֶ֖בֶן א֣וֹ בְאֶגְרֹ֑ף וְלֹ֥א יָמ֖וּת וְנָפַ֥ל לְמִשְׁכָּֽב׃"
          },
          {
            "num": "יט",
            "txt": "אִם־יָק֞וּם וְהִתְהַלֵּ֥ךְ בַּח֛וּץ עַל־מִשְׁעַנְתּ֖וֹ וְנִקָּ֣ה הַמַּכֶּ֑ה רַ֥ק שִׁבְתּ֛וֹ יִתֵּ֖ן וְרַפֹּ֥א יְרַפֵּֽא׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וְכִֽי־יַכֶּה֩ אִ֨ישׁ אֶת־עַבְדּ֜וֹ א֤וֹ אֶת־אֲמָתוֹ֙ בַּשֵּׁ֔בֶט וּמֵ֖ת תַּ֣חַת יָד֑וֹ נָקֹ֖ם יִנָּקֵֽם׃"
          },
          {
            "num": "כא",
            "txt": "אַ֥ךְ אִם־י֛וֹם א֥וֹ יוֹמַ֖יִם יַֽעֲמֹ֑ד לֹ֣א יֻקַּ֔ם כִּ֥י כַסְפּ֖וֹ הֽוּא׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "וְכִֽי־יִנָּצ֣וּ אֲנָשִׁ֗ים וְנָ֨גְפ֜וּ אִשָּׁ֤ה הָרָה֙ וְיָֽצְא֣וּ יְלָדֶ֔יהָ וְלֹ֥א יִֽהְיֶ֖ה אָס֑וֹן עָנ֣וֹשׁ יֵֽעָנֵ֗שׁ כַּֽאֲשֶׁ֨ר יָשִׁ֤ית עָלָיו֙ בַּ֣עַל הָֽאִשָּׁ֔ה וְנָתַ֖ן בִּפְלִלִֽים׃"
          },
          {
            "num": "כג",
            "txt": "וְאִם־אָס֖וֹן יִֽהְיֶ֑ה וְנָֽתַתָּ֥ה נֶ֖פֶשׁ תַּ֥חַת נָֽפֶשׁ׃"
          },
          {
            "num": "כד",
            "txt": "עַ֚יִן תַּ֣חַת עַ֔יִן שֵׁ֖ן תַּ֣חַת שֵׁ֑ן יָ֚ד תַּ֣חַת יָ֔ד רֶ֖גֶל תַּ֥חַת רָֽגֶל׃"
          },
          {
            "num": "כה",
            "txt": "כְּוִיָּה֙ תַּ֣חַת כְּוִיָּ֔ה פֶּ֖צַע תַּ֣חַת פָּ֑צַע חַבּוּרָ֕ה תַּ֖חַת חַבּוּרָֽה׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "וְכִֽי־יַכֶּ֨ה אִ֜ישׁ אֶת־עֵ֥ין עַבְדּ֛וֹ אֽוֹ־אֶת־עֵ֥ין אֲמָת֖וֹ וְשִֽׁחֲתָ֑הּ לַֽחָפְשִׁ֥י יְשַׁלְּחֶ֖נּוּ תַּ֥חַת עֵינֽוֹ׃"
          },
          {
            "num": "כז",
            "txt": "וְאִם־שֵׁ֥ן עַבְדּ֛וֹ אֽוֹ־שֵׁ֥ן אֲמָת֖וֹ יַפִּ֑יל לַֽחָפְשִׁ֥י יְשַׁלְּחֶ֖נּוּ תַּ֥חַת שִׁנּֽוֹ׃ {פ}"
          },
          {
            "num": "כח",
            "txt": "וְכִֽי־יִגַּ֨ח שׁ֥וֹר אֶת־אִ֛ישׁ א֥וֹ אֶת־אִשָּׁ֖ה וָמֵ֑ת סָק֨וֹל יִסָּקֵ֜ל הַשּׁ֗וֹר וְלֹ֤א יֵֽאָכֵל֙ אֶת־בְּשָׂר֔וֹ וּבַ֥עַל הַשּׁ֖וֹר נָקִֽי׃"
          },
          {
            "num": "כט",
            "txt": "וְאִ֡ם שׁוֹר֩ נַגָּ֨ח ה֜וּא מִתְּמֹ֣ל שִׁלְשֹׁ֗ם וְהוּעַ֤ד בִּבְעָלָיו֙ וְלֹ֣א יִשְׁמְרֶ֔נּוּ וְהֵמִ֥ית אִ֖ישׁ א֣וֹ אִשָּׁ֑ה הַשּׁוֹר֙ יִסָּקֵ֔ל וְגַם־בְּעָלָ֖יו יוּמָֽת׃"
          },
          {
            "num": "ל",
            "txt": "אִם־כֹּ֖פֶר יוּשַׁ֣ת עָלָ֑יו וְנָתַן֙ פִּדְיֹ֣ן נַפְשׁ֔וֹ כְּכֹ֥ל אֲשֶׁר־יוּשַׁ֖ת עָלָֽיו׃"
          },
          {
            "num": "לא",
            "txt": "אוֹ־בֵ֥ן יִגָּ֖ח אוֹ־בַ֣ת יִגָּ֑ח כַּמִּשְׁפָּ֥ט הַזֶּ֖ה יֵעָ֥שֶׂה לּֽוֹ׃"
          },
          {
            "num": "לב",
            "txt": "אִם־עֶ֛בֶד יִגַּ֥ח הַשּׁ֖וֹר א֣וֹ אָמָ֑ה כֶּ֣סֶף ׀ שְׁלֹשִׁ֣ים שְׁקָלִ֗ים יִתֵּן֙ לַֽאדֹנָ֔יו וְהַשּׁ֖וֹר יִסָּקֵֽל׃ {ס}"
          },
          {
            "num": "לג",
            "txt": "וְכִֽי־יִפְתַּ֨ח אִ֜ישׁ בּ֗וֹר א֠וֹ כִּֽי־יִכְרֶ֥ה אִ֛ישׁ בֹּ֖ר וְלֹ֣א יְכַסֶּ֑נּוּ וְנָֽפַל־שָׁ֥מָּה שּׁ֖וֹר א֥וֹ חֲמֽוֹר׃"
          },
          {
            "num": "לד",
            "txt": "בַּ֤עַל הַבּוֹר֙ יְשַׁלֵּ֔ם כֶּ֖סֶף יָשִׁ֣יב לִבְעָלָ֑יו וְהַמֵּ֖ת יִֽהְיֶה־לּֽוֹ׃ {ס}"
          },
          {
            "num": "לה",
            "txt": "וְכִֽי־יִגֹּ֧ף שֽׁוֹר־אִ֛ישׁ אֶת־שׁ֥וֹר רֵעֵ֖הוּ וָמֵ֑ת וּמָ֨כְר֜וּ אֶת־הַשּׁ֤וֹר הַחַי֙ וְחָצ֣וּ אֶת־כַּסְפּ֔וֹ וְגַ֥ם אֶת־הַמֵּ֖ת יֶֽחֱצֽוּן׃"
          },
          {
            "num": "לו",
            "txt": "א֣וֹ נוֹדַ֗ע כִּ֠י שׁ֣וֹר נַגָּ֥ח הוּא֙ מִתְּמ֣וֹל שִׁלְשֹׁ֔ם וְלֹ֥א יִשְׁמְרֶ֖נּוּ בְּעָלָ֑יו שַׁלֵּ֨ם יְשַׁלֵּ֥ם שׁוֹר֙ תַּ֣חַת הַשּׁ֔וֹר וְהַמֵּ֖ת יִֽהְיֶה־לּֽוֹ׃ {ס}"
          },
          {
            "num": "לז",
            "txt": "כִּ֤י יִגְנֹֽב־אִישׁ֙ שׁ֣וֹר אוֹ־שֶׂ֔ה וּטְבָח֖וֹ א֣וֹ מְכָר֑וֹ חֲמִשָּׁ֣ה בָקָ֗ר יְשַׁלֵּם֙ תַּ֣חַת הַשּׁ֔וֹר וְאַרְבַּע־צֹ֖אן תַּ֥חַת הַשֶּֽׂה׃"
          }
        ]
      },
      {
        "num": "כב",
        "verses": [
          {
            "num": "א",
            "txt": "אִם־בַּמַּחְתֶּ֛רֶת יִמָּצֵ֥א הַגַּנָּ֖ב וְהֻכָּ֣ה וָמֵ֑ת אֵ֥ין ל֖וֹ דָּמִֽים׃"
          },
          {
            "num": "ב",
            "txt": "אִם־זָֽרְחָ֥ה הַשֶּׁ֛מֶשׁ עָלָ֖יו דָּמִ֣ים ל֑וֹ שַׁלֵּ֣ם יְשַׁלֵּ֔ם אִם־אֵ֣ין ל֔וֹ וְנִמְכַּ֖ר בִּגְנֵֽבָתֽוֹ׃"
          },
          {
            "num": "ג",
            "txt": "אִֽם־הִמָּצֵא֩ תִמָּצֵ֨א בְיָד֜וֹ הַגְּנֵבָ֗ה מִשּׁ֧וֹר עַד־חֲמ֛וֹר עַד־שֶׂ֖ה חַיִּ֑ים שְׁנַ֖יִם יְשַׁלֵּֽם׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "כִּ֤י יַבְעֶר־אִישׁ֙ שָׂדֶ֣ה אוֹ־כֶ֔רֶם וְשִׁלַּח֙ אֶת־בְּעִירֹ֔ה וּבִעֵ֖ר בִּשְׂדֵ֣ה אַחֵ֑ר מֵיטַ֥ב שָׂדֵ֛הוּ וּמֵיטַ֥ב כַּרְמ֖וֹ יְשַׁלֵּֽם׃ {ס}"
          },
          {
            "num": "ה",
            "txt": "כִּֽי־תֵצֵ֨א אֵ֜שׁ וּמָֽצְאָ֤ה קֹצִים֙ וְנֶֽאֱכַ֣ל גָּדִ֔ישׁ א֥וֹ הַקָּמָ֖ה א֣וֹ הַשָּׂדֶ֑ה שַׁלֵּ֣ם יְשַׁלֵּ֔ם הַמַּבְעִ֖ר אֶת־הַבְּעֵרָֽה׃ {ס}"
          },
          {
            "num": "ו",
            "txt": "כִּֽי־יִתֵּן֩ אִ֨ישׁ אֶל־רֵעֵ֜הוּ כֶּ֤סֶף אֽוֹ־כֵלִים֙ לִשְׁמֹ֔ר וְגֻנַּ֖ב מִבֵּ֣ית הָאִ֑ישׁ אִם־יִמָּצֵ֥א הַגַּנָּ֖ב יְשַׁלֵּ֥ם שְׁנָֽיִם׃"
          },
          {
            "num": "ז",
            "txt": "אִם־לֹ֤א יִמָּצֵא֙ הַגַּנָּ֔ב וְנִקְרַ֥ב בַּֽעַל־הַבַּ֖יִת אֶל־הָֽאֱלֹהִ֑ים אִם־לֹ֥א שָׁלַ֛ח יָד֖וֹ בִּמְלֶ֥אכֶת רֵעֵֽהוּ׃"
          },
          {
            "num": "ח",
            "txt": "עַֽל־כָּל־דְּבַר־פֶּ֡שַׁע עַל־שׁ֡וֹר עַל־חֲ֠מוֹר עַל־שֶׂ֨ה עַל־שַׂלְמָ֜ה עַל־כָּל־אֲבֵדָ֗ה אֲשֶׁ֤ר יֹאמַר֙ כִּי־ה֣וּא זֶ֔ה עַ֚ד הָֽאֱלֹהִ֔ים יָבֹ֖א דְּבַר־שְׁנֵיהֶ֑ם אֲשֶׁ֤ר יַרְשִׁיעֻן֙ אֱלֹהִ֔ים יְשַׁלֵּ֥ם שְׁנַ֖יִם לְרֵעֵֽהוּ׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "כִּֽי־יִתֵּן֩ אִ֨ישׁ אֶל־רֵעֵ֜הוּ חֲמ֨וֹר אוֹ־שׁ֥וֹר אוֹ־שֶׂ֛ה וְכָל־בְּהֵמָ֖ה לִשְׁמֹ֑ר וּמֵ֛ת אֽוֹ־נִשְׁבַּ֥ר אֽוֹ־נִשְׁבָּ֖ה אֵ֥ין רֹאֶֽה׃"
          },
          {
            "num": "י",
            "txt": "שְׁבֻעַ֣ת יְהוָ֗ה תִּֽהְיֶה֙ בֵּ֣ין שְׁנֵיהֶ֔ם אִם־לֹ֥א שָׁלַ֛ח יָד֖וֹ בִּמְלֶ֣אכֶת רֵעֵ֑הוּ וְלָקַ֥ח בְּעָלָ֖יו וְלֹ֥א יְשַׁלֵּֽם׃"
          },
          {
            "num": "יא",
            "txt": "וְאִם־גָּנֹ֥ב יִגָּנֵ֖ב מֵֽעִמּ֑וֹ יְשַׁלֵּ֖ם לִבְעָלָֽיו׃"
          },
          {
            "num": "יב",
            "txt": "אִם־טָרֹ֥ף יִטָּרֵ֖ף יְבִאֵ֣הוּ עֵ֑ד הַטְּרֵפָ֖ה לֹ֥א יְשַׁלֵּֽם׃ {פ}"
          },
          {
            "num": "יג",
            "txt": "וְכִֽי־יִשְׁאַ֥ל אִ֛ישׁ מֵעִ֥ם רֵעֵ֖הוּ וְנִשְׁבַּ֣ר אוֹ־מֵ֑ת בְּעָלָ֥יו אֵין־עִמּ֖וֹ שַׁלֵּ֥ם יְשַׁלֵּֽם׃"
          },
          {
            "num": "יד",
            "txt": "אִם־בְּעָלָ֥יו עִמּ֖וֹ לֹ֣א יְשַׁלֵּ֑ם אִם־שָׂכִ֣יר ה֔וּא בָּ֖א בִּשְׂכָרֽוֹ׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וְכִֽי־יְפַתֶּ֣ה אִ֗ישׁ בְּתוּלָ֛ה אֲשֶׁ֥ר לֹֽא־אֹרָ֖שָׂה וְשָׁכַ֣ב עִמָּ֑הּ מָהֹ֛ר יִמְהָרֶ֥נָּה לּ֖וֹ לְאִשָּֽׁה׃"
          },
          {
            "num": "טז",
            "txt": "אִם־מָאֵ֧ן יְמָאֵ֛ן אָבִ֖יהָ לְתִתָּ֣הּ ל֑וֹ כֶּ֣סֶף יִשְׁקֹ֔ל כְּמֹ֖הַר הַבְּתוּלֹֽת׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "מְכַשֵּׁפָ֖ה לֹ֥א תְחַיֶּֽה׃"
          },
          {
            "num": "יח",
            "txt": "כָּל־שֹׁכֵ֥ב עִם־בְּהֵמָ֖ה מ֥וֹת יוּמָֽת׃ {ס}"
          },
          {
            "num": "יט",
            "txt": "זֹבֵ֥חַ לָֽאֱלֹהִ֖ים יָֽחֳרָ֑ם בִּלְתִּ֥י לַֽיהוָ֖ה לְבַדּֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וְגֵ֥ר לֹֽא־תוֹנֶ֖ה וְלֹ֣א תִלְחָצֶ֑נּוּ כִּֽי־גֵרִ֥ים הֱיִיתֶ֖ם בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "כא",
            "txt": "כָּל־אַלְמָנָ֥ה וְיָת֖וֹם לֹ֥א תְעַנּֽוּן׃"
          },
          {
            "num": "כב",
            "txt": "אִם־עַנֵּ֥ה תְעַנֶּ֖ה אֹת֑וֹ כִּ֣י אִם־צָעֹ֤ק יִצְעַק֙ אֵלַ֔י שָׁמֹ֥עַ אֶשְׁמַ֖ע צַֽעֲקָתֽוֹ׃"
          },
          {
            "num": "כג",
            "txt": "וְחָרָ֣ה אַפִּ֔י וְהָֽרַגְתִּ֥י אֶתְכֶ֖ם בֶּחָ֑רֶב וְהָי֤וּ נְשֵׁיכֶם֙ אַלְמָנ֔וֹת וּבְנֵיכֶ֖ם יְתֹמִֽים׃ {פ}"
          },
          {
            "num": "כד",
            "txt": "אִם־כֶּ֣סֶף ׀ תַּלְוֶ֣ה אֶת־עַמִּ֗י אֶת־הֶֽעָנִי֙ עִמָּ֔ךְ לֹֽא־תִהְיֶ֥ה ל֖וֹ כְּנֹשֶׁ֑ה לֹֽא־תְשִׂימ֥וּן עָלָ֖יו נֶֽשֶׁךְ׃"
          },
          {
            "num": "כה",
            "txt": "אִם־חָבֹ֥ל תַּחְבֹּ֖ל שַׂלְמַ֣ת רֵעֶ֑ךָ עַד־בֹּ֥א הַשֶּׁ֖מֶשׁ תְּשִׁיבֶ֥נּוּ לֽוֹ׃"
          },
          {
            "num": "כו",
            "txt": "כִּ֣י הִ֤וא כְסוּתֹה֙ לְבַדָּ֔הּ הִ֥וא שִׂמְלָת֖וֹ לְעֹר֑וֹ בַּמֶּ֣ה יִשְׁכָּ֔ב וְהָיָה֙ כִּֽי־יִצְעַ֣ק אֵלַ֔י וְשָֽׁמַעְתִּ֖י כִּֽי־חַנּ֥וּן אָֽנִי׃ {ס}"
          },
          {
            "num": "כז",
            "txt": "אֱלֹהִ֖ים לֹ֣א תְקַלֵּ֑ל וְנָשִׂ֥יא בְעַמְּךָ֖ לֹ֥א תָאֹֽר׃"
          },
          {
            "num": "כח",
            "txt": "מְלֵאָֽתְךָ֥ וְדִמְעֲךָ֖ לֹ֣א תְאַחֵ֑ר בְּכ֥וֹר בָּנֶ֖יךָ תִּתֶּן־לִֽי׃"
          },
          {
            "num": "כט",
            "txt": "כֵּֽן־תַּעֲשֶׂ֥ה לְשֹֽׁרְךָ֖ לְצֹאנֶ֑ךָ שִׁבְעַ֤ת יָמִים֙ יִֽהְיֶ֣ה עִם־אִמּ֔וֹ בַּיּ֥וֹם הַשְּׁמִינִ֖י תִּתְּנוֹ־לִֽי׃"
          },
          {
            "num": "ל",
            "txt": "וְאַנְשֵׁי־קֹ֖דֶשׁ תִּֽהְי֣וּן לִ֑י וּבָשָׂ֨ר בַּשָּׂדֶ֤ה טְרֵפָה֙ לֹ֣א תֹאכֵ֔לוּ לַכֶּ֖לֶב תַּשְׁלִכ֥וּן אֹתֽוֹ׃ {ס}"
          }
        ]
      },
      {
        "num": "כג",
        "verses": [
          {
            "num": "א",
            "txt": "לֹ֥א תִשָּׂ֖א שֵׁ֣מַע שָׁ֑וְא אַל־תָּ֤שֶׁת יָֽדְךָ֙ עִם־רָשָׁ֔ע לִֽהְיֹ֖ת עֵ֥ד חָמָֽס׃"
          },
          {
            "num": "ב",
            "txt": "לֹֽא־תִהְיֶ֥ה אַחֲרֵֽי־רַבִּ֖ים לְרָעֹ֑ת וְלֹֽא־תַעֲנֶ֣ה עַל־רִ֗ב לִנְטֹ֛ת אַֽחֲרֵ֥י רַבִּ֖ים לְהַטֹּֽת׃"
          },
          {
            "num": "ג",
            "txt": "וְדָ֕ל לֹ֥א תֶהְדַּ֖ר בְּרִיבֽוֹ׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "כִּ֣י תִפְגַּ֞ע שׁ֧וֹר אֹֽיִבְךָ֛ א֥וֹ חֲמֹר֖וֹ תֹּעֶ֑ה הָשֵׁ֥ב תְּשִׁיבֶ֖נּוּ לֽוֹ׃ {ס}"
          },
          {
            "num": "ה",
            "txt": "כִּֽי־תִרְאֶ֞ה חֲמ֣וֹר שֹׂנַֽאֲךָ֗ רֹבֵץ֙ תַּ֣חַת מַשָּׂא֔וֹ וְחָֽדַלְתָּ֖ מֵֽעֲזֹ֣ב ל֑וֹ עָזֹ֥ב תַּֽעֲזֹ֖ב עִמּֽוֹ׃ {ס}"
          },
          {
            "num": "ו",
            "txt": "לֹ֥א תַטֶּ֛ה מִשְׁפַּ֥ט אֶבְיֹֽנְךָ֖ בְּרִיבֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "מִדְּבַר־שֶׁ֖קֶר תִּרְחָ֑ק וְנָקִ֤י וְצַדִּיק֙ אַֽל־תַּהֲרֹ֔ג כִּ֥י לֹֽא־אַצְדִּ֖יק רָשָֽׁע׃"
          },
          {
            "num": "ח",
            "txt": "וְשֹׁ֖חַד לֹ֣א תִקָּ֑ח כִּ֤י הַשֹּׁ֨חַד֙ יְעַוֵּ֣ר פִּקְחִ֔ים וִֽיסַלֵּ֖ף דִּבְרֵ֥י צַדִּיקִֽים׃"
          },
          {
            "num": "ט",
            "txt": "וְגֵ֖ר לֹ֣א תִלְחָ֑ץ וְאַתֶּ֗ם יְדַעְתֶּם֙ אֶת־נֶ֣פֶשׁ הַגֵּ֔ר כִּֽי־גֵרִ֥ים הֱיִיתֶ֖ם בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "י",
            "txt": "וְשֵׁ֥שׁ שָׁנִ֖ים תִּזְרַ֣ע אֶת־אַרְצֶ֑ךָ וְאָֽסַפְתָּ֖ אֶת־תְּבֽוּאָתָֽהּ׃"
          },
          {
            "num": "יא",
            "txt": "וְהַשְּׁבִיעִ֞ת תִּשְׁמְטֶ֣נָּה וּנְטַשְׁתָּ֗הּ וְאָֽכְלוּ֙ אֶבְיֹנֵ֣י עַמֶּ֔ךָ וְיִתְרָ֕ם תֹּאכַ֖ל חַיַּ֣ת הַשָּׂדֶ֑ה כֵּֽן־תַּעֲשֶׂ֥ה לְכַרְמְךָ֖ לְזֵיתֶֽךָ׃"
          },
          {
            "num": "יב",
            "txt": "שֵׁ֤שֶׁת יָמִים֙ תַּֽעֲשֶׂ֣ה מַֽעֲשֶׂ֔יךָ וּבַיּ֥וֹם הַשְּׁבִיעִ֖י תִּשְׁבֹּ֑ת לְמַ֣עַן יָנ֗וּחַ שֽׁוֹרְךָ֙ וַֽחֲמֹרֶ֔ךָ וְיִנָּפֵ֥שׁ בֶּן־אֲמָֽתְךָ֖ וְהַגֵּֽר׃"
          },
          {
            "num": "יג",
            "txt": "וּבְכֹ֛ל אֲשֶׁר־אָמַ֥רְתִּי אֲלֵיכֶ֖ם תִּשָּׁמֵ֑רוּ וְשֵׁ֨ם אֱלֹהִ֤ים אֲחֵרִים֙ לֹ֣א תַזְכִּ֔ירוּ לֹ֥א יִשָּׁמַ֖ע עַל־פִּֽיךָ׃"
          },
          {
            "num": "יד",
            "txt": "שָׁלֹ֣שׁ רְגָלִ֔ים תָּחֹ֥ג לִ֖י בַּשָּׁנָֽה׃"
          },
          {
            "num": "טו",
            "txt": "אֶת־חַ֣ג הַמַּצּוֹת֮ תִּשְׁמֹר֒ שִׁבְעַ֣ת יָמִים֩ תֹּאכַ֨ל מַצּ֜וֹת כַּֽאֲשֶׁ֣ר צִוִּיתִ֗ךָ לְמוֹעֵד֙ חֹ֣דֶשׁ הָֽאָבִ֔יב כִּי־ב֖וֹ יָצָ֣אתָ מִמִּצְרָ֑יִם וְלֹֽא־יֵרָא֥וּ פָנַ֖י רֵיקָֽם׃"
          },
          {
            "num": "טז",
            "txt": "וְחַ֤ג הַקָּצִיר֙ בִּכּוּרֵ֣י מַֽעֲשֶׂ֔יךָ אֲשֶׁ֥ר תִּזְרַ֖ע בַּשָּׂדֶ֑ה וְחַ֤ג הָֽאָסִף֙ בְּצֵ֣את הַשָּׁנָ֔ה בְּאָסְפְּךָ֥ אֶֽת־מַעֲשֶׂ֖יךָ מִן־הַשָּׂדֶֽה׃"
          },
          {
            "num": "יז",
            "txt": "שָׁלֹ֥שׁ פְּעָמִ֖ים בַּשָּׁנָ֑ה יֵֽרָאֶה֙ כָּל־זְכ֣וּרְךָ֔ אֶל־פְּנֵ֖י הָֽאָדֹ֥ן ׀ יְהוָֽה׃"
          },
          {
            "num": "יח",
            "txt": "לֹֽא־תִזְבַּ֥ח עַל־חָמֵ֖ץ דַּם־זִבְחִ֑י וְלֹֽא־יָלִ֥ין חֵֽלֶב־חַגִּ֖י עַד־בֹּֽקֶר׃"
          },
          {
            "num": "יט",
            "txt": "רֵאשִׁ֗ית בִּכּוּרֵי֙ אַדְמָ֣תְךָ֔ תָּבִ֕יא בֵּ֖ית יְהוָ֣ה אֱלֹהֶ֑יךָ לֹֽא־תְבַשֵּׁ֥ל גְּדִ֖י בַּֽחֲלֵ֥ב אִמּֽוֹ׃ {פ}"
          },
          {
            "num": "כ",
            "txt": "הִנֵּ֨ה אָֽנֹכִ֜י שֹׁלֵ֤חַ מַלְאָךְ֙ לְפָנֶ֔יךָ לִשְׁמָרְךָ֖ בַּדָּ֑רֶךְ וְלַהֲבִ֣יאֲךָ֔ אֶל־הַמָּק֖וֹם אֲשֶׁ֥ר הֲכִנֹֽתִי׃"
          },
          {
            "num": "כא",
            "txt": "הִשָּׁ֧מֶר מִפָּנָ֛יו וּשְׁמַ֥ע בְּקֹל֖וֹ אַל־תַּמֵּ֣ר בּ֑וֹ כִּ֣י לֹ֤א יִשָּׂא֙ לְפִשְׁעֲכֶ֔ם כִּ֥י שְׁמִ֖י בְּקִרְבּֽוֹ׃"
          },
          {
            "num": "כב",
            "txt": "כִּ֣י אִם־שָׁמ֤וֹעַ תִּשְׁמַע֙ בְּקֹל֔וֹ וְעָשִׂ֕יתָ כֹּ֖ל אֲשֶׁ֣ר אֲדַבֵּ֑ר וְאָֽיַבְתִּי֙ אֶת־אֹ֣יְבֶ֔יךָ וְצַרְתִּ֖י אֶת־צֹֽרְרֶֽיךָ׃"
          },
          {
            "num": "כג",
            "txt": "כִּֽי־יֵלֵ֣ךְ מַלְאָכִי֮ לְפָנֶיךָ֒ וֶהֱבִֽיאֲךָ֗ אֶל־הָֽאֱמֹרִי֙ וְהַ֣חִתִּ֔י וְהַפְּרִזִּי֙ וְהַֽכְּנַעֲנִ֔י הַֽחִוִּ֖י וְהַיְבוּסִ֑י וְהִכְחַדְתִּֽיו׃"
          },
          {
            "num": "כד",
            "txt": "לֹֽא־תִשְׁתַּחֲוֶ֤ה לֵאלֹֽהֵיהֶם֙ וְלֹ֣א תָֽעָבְדֵ֔ם וְלֹ֥א תַֽעֲשֶׂ֖ה כְּמַֽעֲשֵׂיהֶ֑ם כִּ֤י הָרֵס֙ תְּהָ֣רְסֵ֔ם וְשַׁבֵּ֥ר תְּשַׁבֵּ֖ר מַצֵּבֹֽתֵיהֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַֽעֲבַדְתֶּ֗ם אֵ֚ת יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם וּבֵרַ֥ךְ אֶֽת־לַחְמְךָ֖ וְאֶת־מֵימֶ֑יךָ וַהֲסִֽרֹתִ֥י מַֽחֲלָ֖ה מִקִּרְבֶּֽךָ׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "לֹ֥א תִֽהְיֶ֛ה מְשַׁכֵּלָ֥ה וַֽעֲקָרָ֖ה בְּאַרְצֶ֑ךָ אֶת־מִסְפַּ֥ר יָמֶ֖יךָ אֲמַלֵּֽא׃"
          },
          {
            "num": "כז",
            "txt": "אֶת־אֵֽימָתִי֙ אֲשַׁלַּ֣ח לְפָנֶ֔יךָ וְהַמֹּתִי֙ אֶת־כָּל־הָעָ֔ם אֲשֶׁ֥ר תָּבֹ֖א בָּהֶ֑ם וְנָֽתַתִּ֧י אֶת־כָּל־אֹֽיְבֶ֛יךָ אֵלֶ֖יךָ עֹֽרֶף׃"
          },
          {
            "num": "כח",
            "txt": "וְשָֽׁלַחְתִּ֥י אֶת־הַצִּרְעָ֖ה לְפָנֶ֑יךָ וְגֵֽרְשָׁ֗ה אֶת־הַֽחִוִּ֧י אֶת־הַֽכְּנַעֲנִ֛י וְאֶת־הַֽחִתִּ֖י מִלְּפָנֶֽיךָ׃"
          },
          {
            "num": "כט",
            "txt": "לֹ֧א אֲגָֽרְשֶׁ֛נּוּ מִפָּנֶ֖יךָ בְּשָׁנָ֣ה אֶחָ֑ת פֶּן־תִּֽהְיֶ֤ה הָאָ֨רֶץ֙ שְׁמָמָ֔ה וְרַבָּ֥ה עָלֶ֖יךָ חַיַּ֥ת הַשָּׂדֶֽה׃"
          },
          {
            "num": "ל",
            "txt": "מְעַ֥ט מְעַ֛ט אֲגָֽרְשֶׁ֖נּוּ מִפָּנֶ֑יךָ עַ֚ד אֲשֶׁ֣ר תִּפְרֶ֔ה וְנָֽחַלְתָּ֖ אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "לא",
            "txt": "וְשַׁתִּ֣י אֶת־גְּבֻֽלְךָ֗ מִיַּם־סוּף֙ וְעַד־יָ֣ם פְּלִשְׁתִּ֔ים וּמִמִּדְבָּ֖ר עַד־הַנָּהָ֑ר כִּ֣י ׀ אֶתֵּ֣ן בְּיֶדְכֶ֗ם אֵ֚ת יֹֽשְׁבֵ֣י הָאָ֔רֶץ וְגֵֽרַשְׁתָּ֖מוֹ מִפָּנֶֽיךָ׃"
          },
          {
            "num": "לב",
            "txt": "לֹֽא־תִכְרֹ֥ת לָהֶ֛ם וְלֵאלֹֽהֵיהֶ֖ם בְּרִֽית׃"
          },
          {
            "num": "לג",
            "txt": "לֹ֤א יֵֽשְׁבוּ֙ בְּאַרְצְךָ֔ פֶּן־יַֽחֲטִ֥יאוּ אֹֽתְךָ֖ לִ֑י כִּ֤י תַֽעֲבֹד֙ אֶת־אֱלֹ֣הֵיהֶ֔ם כִּֽי־יִהְיֶ֥ה לְךָ֖ לְמוֹקֵֽשׁ׃ {פ}"
          }
        ]
      },
      {
        "num": "כד",
        "verses": [
          {
            "num": "א",
            "txt": "וְאֶל־מֹשֶׁ֨ה אָמַ֜ר עֲלֵ֣ה אֶל־יְהוָ֗ה אַתָּה֙ וְאַֽהֲרֹן֙ נָדָ֣ב וַֽאֲבִיה֔וּא וְשִׁבְעִ֖ים מִזִּקְנֵ֣י יִשְׂרָאֵ֑ל וְהִשְׁתַּֽחֲוִיתֶ֖ם מֵֽרָחֹֽק׃"
          },
          {
            "num": "ב",
            "txt": "וְנִגַּ֨שׁ מֹשֶׁ֤ה לְבַדּוֹ֙ אֶל־יְהוָ֔ה וְהֵ֖ם לֹ֣א יִגָּ֑שׁוּ וְהָעָ֕ם לֹ֥א יַֽעֲל֖וּ עִמּֽוֹ׃"
          },
          {
            "num": "ג",
            "txt": "וַיָּבֹ֣א מֹשֶׁ֗ה וַיְסַפֵּ֤ר לָעָם֙ אֵ֚ת כָּל־דִּבְרֵ֣י יְהוָ֔ה וְאֵ֖ת כָּל־הַמִּשְׁפָּטִ֑ים וַיַּ֨עַן כָּל־הָעָ֜ם ק֤וֹל אֶחָד֙ וַיֹּ֣אמְר֔וּ כָּל־הַדְּבָרִ֛ים אֲשֶׁר־דִּבֶּ֥ר יְהוָ֖ה נַֽעֲשֶֽׂה׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּכְתֹּ֣ב מֹשֶׁ֗ה אֵ֚ת כָּל־דִּבְרֵ֣י יְהוָ֔ה וַיַּשְׁכֵּ֣ם בַּבֹּ֔קֶר וַיִּ֥בֶן מִזְבֵּ֖חַ תַּ֣חַת הָהָ֑ר וּשְׁתֵּ֤ים עֶשְׂרֵה֙ מַצֵּבָ֔ה לִשְׁנֵ֥ים עָשָׂ֖ר שִׁבְטֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּשְׁלַ֗ח אֶֽת־נַעֲרֵי֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וַֽיַּעֲל֖וּ עֹלֹ֑ת וַֽיִּזְבְּח֞וּ זְבָחִ֧ים שְׁלָמִ֛ים לַֽיהוָ֖ה פָּרִֽים׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּקַּ֤ח מֹשֶׁה֙ חֲצִ֣י הַדָּ֔ם וַיָּ֖שֶׂם בָּֽאַגָּנֹ֑ת וַֽחֲצִ֣י הַדָּ֔ם זָרַ֖ק עַל־הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּקַּח֙ סֵ֣פֶר הַבְּרִ֔ית וַיִּקְרָ֖א בְּאָזְנֵ֣י הָעָ֑ם וַיֹּ֣אמְר֔וּ כֹּ֛ל אֲשֶׁר־דִּבֶּ֥ר יְהוָ֖ה נַֽעֲשֶׂ֥ה וְנִשְׁמָֽע׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּקַּ֤ח מֹשֶׁה֙ אֶת־הַדָּ֔ם וַיִּזְרֹ֖ק עַל־הָעָ֑ם וַיֹּ֗אמֶר הִנֵּ֤ה דַֽם־הַבְּרִית֙ אֲשֶׁ֨ר כָּרַ֤ת יְהוָה֙ עִמָּכֶ֔ם עַ֥ל כָּל־הַדְּבָרִ֖ים הָאֵֽלֶּה׃"
          },
          {
            "num": "ט",
            "txt": "וַיַּ֥עַל מֹשֶׁ֖ה וְאַֽהֲרֹ֑ן נָדָב֙ וַֽאֲבִיה֔וּא וְשִׁבְעִ֖ים מִזִּקְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "י",
            "txt": "וַיִּרְא֕וּ אֵ֖ת אֱלֹהֵ֣י יִשְׂרָאֵ֑ל וְתַ֣חַת רַגְלָ֗יו כְּמַֽעֲשֵׂה֙ לִבְנַ֣ת הַסַּפִּ֔יר וּכְעֶ֥צֶם הַשָּׁמַ֖יִם לָטֹֽהַר׃"
          },
          {
            "num": "יא",
            "txt": "וְאֶל־אֲצִילֵי֙ בְּנֵ֣י יִשְׂרָאֵ֔ל לֹ֥א שָׁלַ֖ח יָד֑וֹ וַֽיֶּחֱזוּ֙ אֶת־הָ֣אֱלֹהִ֔ים וַיֹּֽאכְל֖וּ וַיִּשְׁתּֽוּ׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה עֲלֵ֥ה אֵלַ֛י הָהָ֖רָה וֶֽהְיֵה־שָׁ֑ם וְאֶתְּנָ֨ה לְךָ֜ אֶת־לֻחֹ֣ת הָאֶ֗בֶן וְהַתּוֹרָה֙ וְהַמִּצְוָ֔ה אֲשֶׁ֥ר כָּתַ֖בְתִּי לְהֽוֹרֹתָֽם׃"
          },
          {
            "num": "יג",
            "txt": "וַיָּ֣קָם מֹשֶׁ֔ה וִֽיהוֹשֻׁ֖עַ מְשָֽׁרְת֑וֹ וַיַּ֥עַל מֹשֶׁ֖ה אֶל־הַ֥ר הָֽאֱלֹהִֽים׃"
          },
          {
            "num": "יד",
            "txt": "וְאֶל־הַזְּקֵנִ֤ים אָמַר֙ שְׁבוּ־לָ֣נוּ בָזֶ֔ה עַ֥ד אֲשֶׁר־נָשׁ֖וּב אֲלֵיכֶ֑ם וְהִנֵּ֨ה אַֽהֲרֹ֤ן וְחוּר֙ עִמָּכֶ֔ם מִי־בַ֥עַל דְּבָרִ֖ים יִגַּ֥שׁ אֲלֵהֶֽם׃"
          },
          {
            "num": "טו",
            "txt": "וַיַּ֥עַל מֹשֶׁ֖ה אֶל־הָהָ֑ר וַיְכַ֥ס הֶֽעָנָ֖ן אֶת־הָהָֽר׃"
          },
          {
            "num": "טז",
            "txt": "וַיִּשְׁכֹּ֤ן כְּבוֹד־יְהוָה֙ עַל־הַ֣ר סִינַ֔י וַיְכַסֵּ֥הוּ הֶֽעָנָ֖ן שֵׁ֣שֶׁת יָמִ֑ים וַיִּקְרָ֧א אֶל־מֹשֶׁ֛ה בַּיּ֥וֹם הַשְּׁבִיעִ֖י מִתּ֥וֹךְ הֶֽעָנָֽן׃"
          },
          {
            "num": "יז",
            "txt": "וּמַרְאֵה֙ כְּב֣וֹד יְהוָ֔ה כְּאֵ֥שׁ אֹכֶ֖לֶת בְּרֹ֣אשׁ הָהָ֑ר לְעֵינֵ֖י בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יח",
            "txt": "וַיָּבֹ֥א מֹשֶׁ֛ה בְּת֥וֹךְ הֶֽעָנָ֖ן וַיַּ֣עַל אֶל־הָהָ֑ר וַיְהִ֤י מֹשֶׁה֙ בָּהָ֔ר אַרְבָּעִ֣ים י֔וֹם וְאַרְבָּעִ֖ים לָֽיְלָה׃ {פ}"
          }
        ]
      },
      {
        "num": "כה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְיִקְחוּ־לִ֖י תְּרוּמָ֑ה מֵאֵ֤ת כָּל־אִישׁ֙ אֲשֶׁ֣ר יִדְּבֶ֣נּוּ לִבּ֔וֹ תִּקְח֖וּ אֶת־תְּרֽוּמָתִֽי׃"
          },
          {
            "num": "ג",
            "txt": "וְזֹאת֙ הַתְּרוּמָ֔ה אֲשֶׁ֥ר תִּקְח֖וּ מֵֽאִתָּ֑ם זָהָ֥ב וָכֶ֖סֶף וּנְחֹֽשֶׁת׃"
          },
          {
            "num": "ד",
            "txt": "וּתְכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֥שׁ וְעִזִּֽים׃"
          },
          {
            "num": "ה",
            "txt": "וְעֹרֹ֨ת אֵילִ֧ם מְאָדָּמִ֛ים וְעֹרֹ֥ת תְּחָשִׁ֖ים וַֽעֲצֵ֥י שִׁטִּֽים׃"
          },
          {
            "num": "ו",
            "txt": "שֶׁ֖מֶן לַמָּאֹ֑ר בְּשָׂמִים֙ לְשֶׁ֣מֶן הַמִּשְׁחָ֔ה וְלִקְטֹ֖רֶת הַסַּמִּֽים׃"
          },
          {
            "num": "ז",
            "txt": "אַבְנֵי־שֹׁ֕הַם וְאַבְנֵ֖י מִלֻּאִ֑ים לָֽאֵפֹ֖ד וְלַחֹֽשֶׁן׃"
          },
          {
            "num": "ח",
            "txt": "וְעָ֥שׂוּ לִ֖י מִקְדָּ֑שׁ וְשָֽׁכַנְתִּ֖י בְּתוֹכָֽם׃"
          },
          {
            "num": "ט",
            "txt": "כְּכֹ֗ל אֲשֶׁ֤ר אֲנִי֙ מַרְאֶ֣ה אֽוֹתְךָ֔ אֵ֚ת תַּבְנִ֣ית הַמִּשְׁכָּ֔ן וְאֵ֖ת תַּבְנִ֣ית כָּל־כֵּלָ֑יו וְכֵ֖ן תַּֽעֲשֽׂוּ׃ {ס}"
          },
          {
            "num": "י",
            "txt": "וְעָשׂ֥וּ אֲר֖וֹן עֲצֵ֣י שִׁטִּ֑ים אַמָּתַ֨יִם וָחֵ֜צִי אָרְכּ֗וֹ וְאַמָּ֤ה וָחֵ֨צִי֙ רָחְבּ֔וֹ וְאַמָּ֥ה וָחֵ֖צִי קֹֽמָתֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וְצִפִּיתָ֤ אֹתוֹ֙ זָהָ֣ב טָה֔וֹר מִבַּ֥יִת וּמִח֖וּץ תְּצַפֶּ֑נּוּ וְעָשִׂ֧יתָ עָלָ֛יו זֵ֥ר זָהָ֖ב סָבִֽיב׃"
          },
          {
            "num": "יב",
            "txt": "וְיָצַ֣קְתָּ לּ֗וֹ אַרְבַּע֙ טַבְּעֹ֣ת זָהָ֔ב וְנָ֣תַתָּ֔ה עַ֖ל אַרְבַּ֣ע פַּֽעֲמֹתָ֑יו וּשְׁתֵּ֣י טַבָּעֹ֗ת עַל־צַלְעוֹ֙ הָֽאֶחָ֔ת וּשְׁתֵּי֙ טַבָּעֹ֔ת עַל־צַלְע֖וֹ הַשֵּׁנִֽית׃"
          },
          {
            "num": "יג",
            "txt": "וְעָשִׂ֥יתָ בַדֵּ֖י עֲצֵ֣י שִׁטִּ֑ים וְצִפִּיתָ֥ אֹתָ֖ם זָהָֽב׃"
          },
          {
            "num": "יד",
            "txt": "וְהֵֽבֵאתָ֤ אֶת־הַבַּדִּים֙ בַּטַּבָּעֹ֔ת עַ֖ל צַלְעֹ֣ת הָֽאָרֹ֑ן לָשֵׂ֥את אֶת־הָֽאָרֹ֖ן בָּהֶֽם׃"
          },
          {
            "num": "טו",
            "txt": "בְּטַבְּעֹת֙ הָֽאָרֹ֔ן יִֽהְי֖וּ הַבַּדִּ֑ים לֹ֥א יָסֻ֖רוּ מִמֶּֽנּוּ׃"
          },
          {
            "num": "טז",
            "txt": "וְנָֽתַתָּ֖ אֶל־הָֽאָרֹ֑ן אֵ֚ת הָֽעֵדֻ֔ת אֲשֶׁ֥ר אֶתֵּ֖ן אֵלֶֽיךָ׃"
          },
          {
            "num": "יז",
            "txt": "וְעָשִׂ֥יתָ כַפֹּ֖רֶת זָהָ֣ב טָה֑וֹר אַמָּתַ֤יִם וָחֵ֨צִי֙ אָרְכָּ֔הּ וְאַמָּ֥ה וָחֵ֖צִי רָחְבָּֽהּ׃"
          },
          {
            "num": "יח",
            "txt": "וְעָשִׂ֛יתָ שְׁנַ֥יִם כְּרֻבִ֖ים זָהָ֑ב מִקְשָׁה֙ תַּֽעֲשֶׂ֣ה אֹתָ֔ם מִשְּׁנֵ֖י קְצ֥וֹת הַכַּפֹּֽרֶת׃"
          },
          {
            "num": "יט",
            "txt": "וַֽ֠עֲשֵׂה כְּר֨וּב אֶחָ֤ד מִקָּצָה֙ מִזֶּ֔ה וּכְרוּב־אֶחָ֥ד מִקָּצָ֖ה מִזֶּ֑ה מִן־הַכַּפֹּ֛רֶת תַּֽעֲשׂ֥וּ אֶת־הַכְּרֻבִ֖ים עַל־שְׁנֵ֥י קְצוֹתָֽיו׃"
          },
          {
            "num": "כ",
            "txt": "וְהָי֣וּ הַכְּרֻבִים֩ פֹּֽרְשֵׂ֨י כְנָפַ֜יִם לְמַ֗עְלָה סֹֽכְכִ֤ים בְּכַנְפֵיהֶם֙ עַל־הַכַּפֹּ֔רֶת וּפְנֵיהֶ֖ם אִ֣ישׁ אֶל־אָחִ֑יו אֶ֨ל־הַכַּפֹּ֔רֶת יִֽהְי֖וּ פְּנֵ֥י הַכְּרֻבִֽים׃"
          },
          {
            "num": "כא",
            "txt": "וְנָֽתַתָּ֧ אֶת־הַכַּפֹּ֛רֶת עַל־הָֽאָרֹ֖ן מִלְמָ֑עְלָה וְאֶל־הָ֣אָרֹ֔ן תִּתֵּן֙ אֶת־הָ֣עֵדֻ֔ת אֲשֶׁ֥ר אֶתֵּ֖ן אֵלֶֽיךָ׃"
          },
          {
            "num": "כב",
            "txt": "וְנֽוֹעַדְתִּ֣י לְךָ֮ שָׁם֒ וְדִבַּרְתִּ֨י אִתְּךָ֜ מֵעַ֣ל הַכַּפֹּ֗רֶת מִבֵּין֙ שְׁנֵ֣י הַכְּרֻבִ֔ים אֲשֶׁ֖ר עַל־אֲר֣וֹן הָֽעֵדֻ֑ת אֵ֣ת כָּל־אֲשֶׁ֧ר אֲצַוֶּ֛ה אֽוֹתְךָ֖ אֶל־בְּנֵ֥י יִשְׂרָאֵֽל׃ {פ}"
          },
          {
            "num": "כג",
            "txt": "וְעָשִׂ֥יתָ שֻׁלְחָ֖ן עֲצֵ֣י שִׁטִּ֑ים אַמָּתַ֤יִם אָרְכּוֹ֙ וְאַמָּ֣ה רָחְבּ֔וֹ וְאַמָּ֥ה וָחֵ֖צִי קֹֽמָתֽוֹ׃"
          },
          {
            "num": "כד",
            "txt": "וְצִפִּיתָ֥ אֹת֖וֹ זָהָ֣ב טָה֑וֹר וְעָשִׂ֥יתָ לּ֛וֹ זֵ֥ר זָהָ֖ב סָבִֽיב׃"
          },
          {
            "num": "כה",
            "txt": "וְעָשִׂ֨יתָ לּ֥וֹ מִסְגֶּ֛רֶת טֹ֖פַח סָבִ֑יב וְעָשִׂ֧יתָ זֵר־זָהָ֛ב לְמִסְגַּרְתּ֖וֹ סָבִֽיב׃"
          },
          {
            "num": "כו",
            "txt": "וְעָשִׂ֣יתָ לּ֔וֹ אַרְבַּ֖ע טַבְּעֹ֣ת זָהָ֑ב וְנָֽתַתָּ֙ אֶת־הַטַּבָּעֹ֔ת עַ֚ל אַרְבַּ֣ע הַפֵּאֹ֔ת אֲשֶׁ֖ר לְאַרְבַּ֥ע רַגְלָֽיו׃"
          },
          {
            "num": "כז",
            "txt": "לְעֻמַּת֙ הַמִּסְגֶּ֔רֶת תִּֽהְיֶ֖יןָ הַטַּבָּעֹ֑ת לְבָתִּ֣ים לְבַדִּ֔ים לָשֵׂ֖את אֶת־הַשֻּׁלְחָֽן׃"
          },
          {
            "num": "כח",
            "txt": "וְעָשִׂ֤יתָ אֶת־הַבַּדִּים֙ עֲצֵ֣י שִׁטִּ֔ים וְצִפִּיתָ֥ אֹתָ֖ם זָהָ֑ב וְנִשָּׂא־בָ֖ם אֶת־הַשֻּׁלְחָֽן׃"
          },
          {
            "num": "כט",
            "txt": "וְעָשִׂ֨יתָ קְּעָֽרֹתָ֜יו וְכַפֹּתָ֗יו וּקְשׂוֹתָיו֙ וּמְנַקִּיֹּתָ֔יו אֲשֶׁ֥ר יֻסַּ֖ךְ בָּהֵ֑ן זָהָ֥ב טָה֖וֹר תַּֽעֲשֶׂ֥ה אֹתָֽם׃"
          },
          {
            "num": "ל",
            "txt": "וְנָֽתַתָּ֧ עַֽל־הַשֻּׁלְחָ֛ן לֶ֥חֶם פָּנִ֖ים לְפָנַ֥י תָּמִֽיד׃ {פ}"
          },
          {
            "num": "לא",
            "txt": "וְעָשִׂ֥יתָ מְנֹרַ֖ת זָהָ֣ב טָה֑וֹר מִקְשָׁ֞ה תֵּֽעָשֶׂ֤ה הַמְּנוֹרָה֙ יְרֵכָ֣הּ וְקָנָ֔הּ גְּבִיעֶ֛יהָ כַּפְתֹּרֶ֥יהָ וּפְרָחֶ֖יהָ מִמֶּ֥נָּה יִֽהְיֽוּ׃"
          },
          {
            "num": "לב",
            "txt": "וְשִׁשָּׁ֣ה קָנִ֔ים יֹֽצְאִ֖ים מִצִּדֶּ֑יהָ שְׁלֹשָׁ֣ה ׀ קְנֵ֣י מְנֹרָ֗ה מִצִּדָּהּ֙ הָֽאֶחָ֔ד וּשְׁלֹשָׁה֙ קְנֵ֣י מְנֹרָ֔ה מִצִּדָּ֖הּ הַשֵּׁנִֽי׃"
          },
          {
            "num": "לג",
            "txt": "שְׁלֹשָׁ֣ה גְ֠בִעִים מְֽשֻׁקָּדִ֞ים בַּקָּנֶ֣ה הָֽאֶחָד֮ כַּפְתֹּ֣ר וָפֶרַח֒ וּשְׁלֹשָׁ֣ה גְבִעִ֗ים מְשֻׁקָּדִ֛ים בַּקָּנֶ֥ה הָֽאֶחָ֖ד כַּפְתֹּ֣ר וָפָ֑רַח כֵּ֚ן לְשֵׁ֣שֶׁת הַקָּנִ֔ים הַיֹּֽצְאִ֖ים מִן־הַמְּנֹרָֽה׃"
          },
          {
            "num": "לד",
            "txt": "וּבַמְּנֹרָ֖ה אַרְבָּעָ֣ה גְבִעִ֑ים מְשֻׁ֨קָּדִ֔ים כַּפְתֹּרֶ֖יהָ וּפְרָחֶֽיהָ׃"
          },
          {
            "num": "לה",
            "txt": "וְכַפְתֹּ֡ר תַּחַת֩ שְׁנֵ֨י הַקָּנִ֜ים מִמֶּ֗נָּה וְכַפְתֹּר֙ תַּ֣חַת שְׁנֵ֤י הַקָּנִים֙ מִמֶּ֔נָּה וְכַפְתֹּ֕ר תַּֽחַת־שְׁנֵ֥י הַקָּנִ֖ים מִמֶּ֑נָּה לְשֵׁ֨שֶׁת֙ הַקָּנִ֔ים הַיֹּֽצְאִ֖ים מִן־הַמְּנֹרָֽה׃"
          },
          {
            "num": "לו",
            "txt": "כַּפְתֹּֽרֵיהֶ֥ם וּקְנֹתָ֖ם מִמֶּ֣נָּה יִֽהְי֑וּ כֻּלָּ֛הּ מִקְשָׁ֥ה אַחַ֖ת זָהָ֥ב טָהֽוֹר׃"
          },
          {
            "num": "לז",
            "txt": "וְעָשִׂ֥יתָ אֶת־נֵֽרֹתֶ֖יהָ שִׁבְעָ֑ה וְהֶֽעֱלָה֙ אֶת־נֵ֣רֹתֶ֔יהָ וְהֵאִ֖יר עַל־עֵ֥בֶר פָּנֶֽיהָ׃"
          },
          {
            "num": "לח",
            "txt": "וּמַלְקָחֶ֥יהָ וּמַחְתֹּתֶ֖יהָ זָהָ֥ב טָהֽוֹר׃"
          },
          {
            "num": "לט",
            "txt": "כִּכָּ֛ר זָהָ֥ב טָה֖וֹר יַֽעֲשֶׂ֣ה אֹתָ֑הּ אֵ֥ת כָּל־הַכֵּלִ֖ים הָאֵֽלֶּה׃"
          },
          {
            "num": "מ",
            "txt": "וּרְאֵ֖ה וַֽעֲשֵׂ֑ה בְּתַ֨בְנִיתָ֔ם אֲשֶׁר־אַתָּ֥ה מָרְאֶ֖ה בָּהָֽר׃ {ס}"
          }
        ]
      },
      {
        "num": "כו",
        "verses": [
          {
            "num": "א",
            "txt": "וְאֶת־הַמִּשְׁכָּ֥ן תַּֽעֲשֶׂ֖ה עֶ֣שֶׂר יְרִיעֹ֑ת שֵׁ֣שׁ מָשְׁזָ֗ר וּתְכֵ֤לֶת וְאַרְגָּמָן֙ וְתֹלַ֣עַת שָׁנִ֔י כְּרֻבִ֛ים מַֽעֲשֵׂ֥ה חֹשֵׁ֖ב תַּֽעֲשֶׂ֥ה אֹתָֽם׃"
          },
          {
            "num": "ב",
            "txt": "אֹ֣רֶךְ ׀ הַיְרִיעָ֣ה הָֽאַחַ֗ת שְׁמֹנֶ֤ה וְעֶשְׂרִים֙ בָּֽאַמָּ֔ה וְרֹ֨חַב֙ אַרְבַּ֣ע בָּֽאַמָּ֔ה הַיְרִיעָ֖ה הָֽאֶחָ֑ת מִדָּ֥ה אַחַ֖ת לְכָל־הַיְרִיעֹֽת׃"
          },
          {
            "num": "ג",
            "txt": "חֲמֵ֣שׁ הַיְרִיעֹ֗ת תִּֽהְיֶ֨יןָ֙ חֹֽבְרֹ֔ת אִשָּׁ֖ה אֶל־אֲחֹתָ֑הּ וְחָמֵ֤שׁ יְרִיעֹת֙ חֹֽבְרֹ֔ת אִשָּׁ֖ה אֶל־אֲחֹתָֽהּ׃"
          },
          {
            "num": "ד",
            "txt": "וְעָשִׂ֜יתָ לֻֽלְאֹ֣ת תְּכֵ֗לֶת עַ֣ל שְׂפַ֤ת הַיְרִיעָה֙ הָֽאֶחָ֔ת מִקָּצָ֖ה בַּֽחֹבָ֑רֶת וְכֵ֤ן תַּֽעֲשֶׂה֙ בִּשְׂפַ֣ת הַיְרִיעָ֔ה הַקִּ֣יצוֹנָ֔ה בַּמַּחְבֶּ֖רֶת הַשֵּׁנִֽית׃"
          },
          {
            "num": "ה",
            "txt": "חֲמִשִּׁ֣ים לֻֽלָאֹ֗ת תַּֽעֲשֶׂה֮ בַּיְרִיעָ֣ה הָֽאֶחָת֒ וַֽחֲמִשִּׁ֣ים לֻֽלָאֹ֗ת תַּֽעֲשֶׂה֙ בִּקְצֵ֣ה הַיְרִיעָ֔ה אֲשֶׁ֖ר בַּמַּחְבֶּ֣רֶת הַשֵּׁנִ֑ית מַקְבִּילֹת֙ הַלֻּ֣לָאֹ֔ת אִשָּׁ֖ה אֶל־אֲחֹתָֽהּ׃"
          },
          {
            "num": "ו",
            "txt": "וְעָשִׂ֕יתָ חֲמִשִּׁ֖ים קַרְסֵ֣י זָהָ֑ב וְחִבַּרְתָּ֨ אֶת־הַיְרִיעֹ֜ת אִשָּׁ֤ה אֶל־אֲחֹתָהּ֙ בַּקְּרָסִ֔ים וְהָיָ֥ה הַמִּשְׁכָּ֖ן אֶחָֽד׃"
          },
          {
            "num": "ז",
            "txt": "וְעָשִׂ֨יתָ֙ יְרִיעֹ֣ת עִזִּ֔ים לְאֹ֖הֶל עַל־הַמִּשְׁכָּ֑ן עַשְׁתֵּֽי־עֶשְׂרֵ֥ה יְרִיעֹ֖ת תַּֽעֲשֶׂ֥ה אֹתָֽם׃"
          },
          {
            "num": "ח",
            "txt": "אֹ֣רֶךְ ׀ הַיְרִיעָ֣ה הָֽאַחַ֗ת שְׁלֹשִׁים֙ בָּֽאַמָּ֔ה וְרֹ֨חַב֙ אַרְבַּ֣ע בָּֽאַמָּ֔ה הַיְרִיעָ֖ה הָֽאֶחָ֑ת מִדָּ֣ה אַחַ֔ת לְעַשְׁתֵּ֥י עֶשְׂרֵ֖ה יְרִיעֹֽת׃"
          },
          {
            "num": "ט",
            "txt": "וְחִבַּרְתָּ֞ אֶת־חֲמֵ֤שׁ הַיְרִיעֹת֙ לְבָ֔ד וְאֶת־שֵׁ֥שׁ הַיְרִיעֹ֖ת לְבָ֑ד וְכָֽפַלְתָּ֙ אֶת־הַיְרִיעָ֣ה הַשִּׁשִּׁ֔ית אֶל־מ֖וּל פְּנֵ֥י הָאֹֽהֶל׃"
          },
          {
            "num": "י",
            "txt": "וְעָשִׂ֜יתָ חֲמִשִּׁ֣ים לֻֽלָאֹ֗ת עַ֣ל שְׂפַ֤ת הַיְרִיעָה֙ הָֽאֶחָ֔ת הַקִּֽיצֹנָ֖ה בַּֽחֹבָ֑רֶת וַֽחֲמִשִּׁ֣ים לֻֽלָאֹ֗ת עַ֚ל שְׂפַ֣ת הַיְרִיעָ֔ה הַֽחֹבֶ֖רֶת הַשֵּׁנִֽית׃"
          },
          {
            "num": "יא",
            "txt": "וְעָשִׂ֛יתָ קַרְסֵ֥י נְחֹ֖שֶׁת חֲמִשִּׁ֑ים וְהֵֽבֵאתָ֤ אֶת־הַקְּרָסִים֙ בַּלֻּ֣לָאֹ֔ת וְחִבַּרְתָּ֥ אֶת־הָאֹ֖הֶל וְהָיָ֥ה אֶחָֽד׃"
          },
          {
            "num": "יב",
            "txt": "וְסֶ֨רַח֙ הָֽעֹדֵ֔ף בִּֽירִיעֹ֖ת הָאֹ֑הֶל חֲצִ֤י הַיְרִיעָה֙ הָֽעֹדֶ֔פֶת תִּסְרַ֕ח עַ֖ל אֲחֹרֵ֥י הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "יג",
            "txt": "וְהָֽאַמָּ֨ה מִזֶּ֜ה וְהָֽאַמָּ֤ה מִזֶּה֙ בָּֽעֹדֵ֔ף בְּאֹ֖רֶךְ יְרִיעֹ֣ת הָאֹ֑הֶל יִֽהְיֶ֨ה סָר֜וּחַ עַל־צִדֵּ֧י הַמִּשְׁכָּ֛ן מִזֶּ֥ה וּמִזֶּ֖ה לְכַסֹּתֽוֹ׃"
          },
          {
            "num": "יד",
            "txt": "וְעָשִׂ֤יתָ מִכְסֶה֙ לָאֹ֔הֶל עֹרֹ֥ת אֵילִ֖ם מְאָדָּמִ֑ים וּמִכְסֵ֛ה עֹרֹ֥ת תְּחָשִׁ֖ים מִלְמָֽעְלָה׃ {פ}"
          },
          {
            "num": "טו",
            "txt": "וְעָשִׂ֥יתָ אֶת־הַקְּרָשִׁ֖ים לַמִּשְׁכָּ֑ן עֲצֵ֥י שִׁטִּ֖ים עֹֽמְדִֽים׃"
          },
          {
            "num": "טז",
            "txt": "עֶ֥שֶׂר אַמּ֖וֹת אֹ֣רֶךְ הַקָּ֑רֶשׁ וְאַמָּה֙ וַֽחֲצִ֣י הָֽאַמָּ֔ה רֹ֖חַב הַקֶּ֥רֶשׁ הָֽאֶחָֽד׃"
          },
          {
            "num": "יז",
            "txt": "שְׁתֵּ֣י יָד֗וֹת לַקֶּ֨רֶשׁ֙ הָֽאֶחָ֔ד מְשֻׁ֨לָּבֹ֔ת אִשָּׁ֖ה אֶל־אֲחֹתָ֑הּ כֵּ֣ן תַּֽעֲשֶׂ֔ה לְכֹ֖ל קַרְשֵׁ֥י הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "יח",
            "txt": "וְעָשִׂ֥יתָ אֶת־הַקְּרָשִׁ֖ים לַמִּשְׁכָּ֑ן עֶשְׂרִ֣ים קֶ֔רֶשׁ לִפְאַ֖ת נֶ֥גְבָּה תֵימָֽנָה׃"
          },
          {
            "num": "יט",
            "txt": "וְאַרְבָּעִים֙ אַדְנֵי־כֶ֔סֶף תַּֽעֲשֶׂ֕ה תַּ֖חַת עֶשְׂרִ֣ים הַקָּ֑רֶשׁ שְׁנֵ֨י אֲדָנִ֜ים תַּֽחַת־הַקֶּ֤רֶשׁ הָֽאֶחָד֙ לִשְׁתֵּ֣י יְדֹתָ֔יו וּשְׁנֵ֧י אֲדָנִ֛ים תַּֽחַת־הַקֶּ֥רֶשׁ הָֽאֶחָ֖ד לִשְׁתֵּ֥י יְדֹתָֽיו׃"
          },
          {
            "num": "כ",
            "txt": "וּלְצֶ֧לַע הַמִּשְׁכָּ֛ן הַשֵּׁנִ֖ית לִפְאַ֣ת צָפ֑וֹן עֶשְׂרִ֖ים קָֽרֶשׁ׃"
          },
          {
            "num": "כא",
            "txt": "וְאַרְבָּעִ֥ים אַדְנֵיהֶ֖ם כָּ֑סֶף שְׁנֵ֣י אֲדָנִ֗ים תַּ֚חַת הַקֶּ֣רֶשׁ הָֽאֶחָ֔ד וּשְׁנֵ֣י אֲדָנִ֔ים תַּ֖חַת הַקֶּ֥רֶשׁ הָֽאֶחָֽד׃"
          },
          {
            "num": "כב",
            "txt": "וּֽלְיַרְכְּתֵ֥י הַמִּשְׁכָּ֖ן יָ֑מָּה תַּֽעֲשֶׂ֖ה שִׁשָּׁ֥ה קְרָשִֽׁים׃"
          },
          {
            "num": "כג",
            "txt": "וּשְׁנֵ֤י קְרָשִׁים֙ תַּֽעֲשֶׂ֔ה לִמְקֻצְעֹ֖ת הַמִּשְׁכָּ֑ן בַּיַּרְכָתָֽיִם׃"
          },
          {
            "num": "כד",
            "txt": "וְיִֽהְי֣וּ תֹֽאֲמִם֮ מִלְּמַטָּה֒ וְיַחְדָּ֗ו יִֽהְי֤וּ תַמִּים֙ עַל־רֹאשׁ֔וֹ אֶל־הַטַּבַּ֖עַת הָֽאֶחָ֑ת כֵּ֚ן יִֽהְיֶ֣ה לִשְׁנֵיהֶ֔ם לִשְׁנֵ֥י הַמִּקְצֹעֹ֖ת יִֽהְיֽוּ׃"
          },
          {
            "num": "כה",
            "txt": "וְהָיוּ֙ שְׁמֹנָ֣ה קְרָשִׁ֔ים וְאַדְנֵיהֶ֣ם כֶּ֔סֶף שִׁשָּׁ֥ה עָשָׂ֖ר אֲדָנִ֑ים שְׁנֵ֣י אֲדָנִ֗ים תַּ֚חַת הַקֶּ֣רֶשׁ הָֽאֶחָ֔ד וּשְׁנֵ֣י אֲדָנִ֔ים תַּ֖חַת הַקֶּ֥רֶשׁ הָֽאֶחָֽד׃"
          },
          {
            "num": "כו",
            "txt": "וְעָשִׂ֥יתָ בְרִיחִ֖ם עֲצֵ֣י שִׁטִּ֑ים חֲמִשָּׁ֕ה לְקַרְשֵׁ֥י צֶֽלַע־הַמִּשְׁכָּ֖ן הָֽאֶחָֽד׃"
          },
          {
            "num": "כז",
            "txt": "וַֽחֲמִשָּׁ֣ה בְרִיחִ֔ם לְקַרְשֵׁ֥י צֶֽלַע־הַמִּשְׁכָּ֖ן הַשֵּׁנִ֑ית וַֽחֲמִשָּׁ֣ה בְרִיחִ֗ם לְקַרְשֵׁי֙ צֶ֣לַע הַמִּשְׁכָּ֔ן לַיַּרְכָתַ֖יִם יָֽמָּה׃"
          },
          {
            "num": "כח",
            "txt": "וְהַבְּרִ֥יחַ הַתִּיכֹ֖ן בְּת֣וֹךְ הַקְּרָשִׁ֑ים מַבְרִ֕חַ מִן־הַקָּצֶ֖ה אֶל־הַקָּצֶֽה׃"
          },
          {
            "num": "כט",
            "txt": "וְֽאֶת־הַקְּרָשִׁ֞ים תְּצַפֶּ֣ה זָהָ֗ב וְאֶת־טַבְּעֹֽתֵיהֶם֙ תַּֽעֲשֶׂ֣ה זָהָ֔ב בָּתִּ֖ים לַבְּרִיחִ֑ם וְצִפִּיתָ֥ אֶת־הַבְּרִיחִ֖ם זָהָֽב׃"
          },
          {
            "num": "ל",
            "txt": "וַהֲקֵֽמֹתָ֖ אֶת־הַמִּשְׁכָּ֑ן כְּמִ֨שְׁפָּט֔וֹ אֲשֶׁ֥ר הָרְאֵ֖יתָ בָּהָֽר׃ {ס}"
          },
          {
            "num": "לא",
            "txt": "וְעָשִׂ֣יתָ פָרֹ֗כֶת תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֣שׁ מָשְׁזָ֑ר מַֽעֲשֵׂ֥ה חֹשֵׁ֛ב יַֽעֲשֶׂ֥ה אֹתָ֖הּ כְּרֻבִֽים׃"
          },
          {
            "num": "לב",
            "txt": "וְנָֽתַתָּ֣ה אֹתָ֗הּ עַל־אַרְבָּעָה֙ עַמּוּדֵ֣י שִׁטִּ֔ים מְצֻפִּ֣ים זָהָ֔ב וָֽוֵיהֶ֖ם זָהָ֑ב עַל־אַרְבָּעָ֖ה אַדְנֵי־כָֽסֶף׃"
          },
          {
            "num": "לג",
            "txt": "וְנָֽתַתָּ֣ה אֶת־הַפָּרֹכֶת֮ תַּ֣חַת הַקְּרָסִים֒ וְהֵֽבֵאתָ֥ שָׁ֨מָּה֙ מִבֵּ֣ית לַפָּרֹ֔כֶת אֵ֖ת אֲר֣וֹן הָֽעֵד֑וּת וְהִבְדִּילָ֤ה הַפָּרֹ֨כֶת֙ לָכֶ֔ם בֵּ֣ין הַקֹּ֔דֶשׁ וּבֵ֖ין קֹ֥דֶשׁ הַקֳּדָשִֽׁים׃"
          },
          {
            "num": "לד",
            "txt": "וְנָֽתַתָּ֙ אֶת־הַכַּפֹּ֔רֶת עַ֖ל אֲר֣וֹן הָֽעֵדֻ֑ת בְּקֹ֖דֶשׁ הַקֳּדָשִֽׁים׃"
          },
          {
            "num": "לה",
            "txt": "וְשַׂמְתָּ֤ אֶת־הַשֻּׁלְחָן֙ מִח֣וּץ לַפָּרֹ֔כֶת וְאֶת־הַמְּנֹרָה֙ נֹ֣כַח הַשֻּׁלְחָ֔ן עַ֛ל צֶ֥לַע הַמִּשְׁכָּ֖ן תֵּימָ֑נָה וְהַ֨שֻּׁלְחָ֔ן תִּתֵּ֖ן עַל־צֶ֥לַע צָפֽוֹן׃"
          },
          {
            "num": "לו",
            "txt": "וְעָשִׂ֤יתָ מָסָךְ֙ לְפֶ֣תַח הָאֹ֔הֶל תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֣שׁ מָשְׁזָ֑ר מַֽעֲשֵׂ֖ה רֹקֵֽם׃"
          },
          {
            "num": "לז",
            "txt": "וְעָשִׂ֣יתָ לַמָּסָ֗ךְ חֲמִשָּׁה֙ עַמּוּדֵ֣י שִׁטִּ֔ים וְצִפִּיתָ֤ אֹתָם֙ זָהָ֔ב וָֽוֵיהֶ֖ם זָהָ֑ב וְיָֽצַקְתָּ֣ לָהֶ֔ם חֲמִשָּׁ֖ה אַדְנֵ֥י נְחֹֽשֶׁת׃ {ס}"
          }
        ]
      },
      {
        "num": "כז",
        "verses": [
          {
            "num": "א",
            "txt": "וְעָשִׂ֥יתָ אֶת־הַמִּזְבֵּ֖חַ עֲצֵ֣י שִׁטִּ֑ים חָמֵשׁ֩ אַמּ֨וֹת אֹ֜רֶךְ וְחָמֵ֧שׁ אַמּ֣וֹת רֹ֗חַב רָב֤וּעַ יִֽהְיֶה֙ הַמִּזְבֵּ֔חַ וְשָׁלֹ֥שׁ אַמּ֖וֹת קֹֽמָתֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וְעָשִׂ֣יתָ קַרְנֹתָ֗יו עַ֚ל אַרְבַּ֣ע פִּנֹּתָ֔יו מִמֶּ֖נּוּ תִּֽהְיֶ֣יןָ קַרְנֹתָ֑יו וְצִפִּיתָ֥ אֹת֖וֹ נְחֹֽשֶׁת׃"
          },
          {
            "num": "ג",
            "txt": "וְעָשִׂ֤יתָ סִּֽירֹתָיו֙ לְדַשְּׁנ֔וֹ וְיָעָיו֙ וּמִזְרְקֹתָ֔יו וּמִזְלְגֹתָ֖יו וּמַחְתֹּתָ֑יו לְכָל־כֵּלָ֖יו תַּֽעֲשֶׂ֥ה נְחֹֽשֶׁת׃"
          },
          {
            "num": "ד",
            "txt": "וְעָשִׂ֤יתָ לּוֹ֙ מִכְבָּ֔ר מַֽעֲשֵׂ֖ה רֶ֣שֶׁת נְחֹ֑שֶׁת וְעָשִׂ֣יתָ עַל־הָרֶ֗שֶׁת אַרְבַּע֙ טַבְּעֹ֣ת נְחֹ֔שֶׁת עַ֖ל אַרְבַּ֥ע קְצוֹתָֽיו׃"
          },
          {
            "num": "ה",
            "txt": "וְנָֽתַתָּ֣ה אֹתָ֗הּ תַּ֛חַת כַּרְכֹּ֥ב הַמִּזְבֵּ֖חַ מִלְּמָ֑טָּה וְהָֽיְתָ֣ה הָרֶ֔שֶׁת עַ֖ד חֲצִ֥י הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ו",
            "txt": "וְעָשִׂ֤יתָ בַדִּים֙ לַמִּזְבֵּ֔חַ בַּדֵּ֖י עֲצֵ֣י שִׁטִּ֑ים וְצִפִּיתָ֥ אֹתָ֖ם נְחֹֽשֶׁת׃"
          },
          {
            "num": "ז",
            "txt": "וְהוּבָ֥א אֶת־בַּדָּ֖יו בַּטַּבָּעֹ֑ת וְהָי֣וּ הַבַּדִּ֗ים עַל־שְׁתֵּ֛י צַלְעֹ֥ת הַמִּזְבֵּ֖חַ בִּשְׂאֵ֥ת אֹתֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "נְב֥וּב לֻחֹ֖ת תַּֽעֲשֶׂ֣ה אֹת֑וֹ כַּֽאֲשֶׁ֨ר הֶרְאָ֥ה אֹֽתְךָ֛ בָּהָ֖ר כֵּ֥ן יַֽעֲשֽׂוּ׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "וְעָשִׂ֕יתָ אֵ֖ת חֲצַ֣ר הַמִּשְׁכָּ֑ן לִפְאַ֣ת נֶֽגֶב־תֵּ֠ימָנָה קְלָעִ֨ים לֶֽחָצֵ֜ר שֵׁ֣שׁ מָשְׁזָ֗ר מֵאָ֤ה בָֽאַמָּה֙ אֹ֔רֶךְ לַפֵּאָ֖ה הָֽאֶחָֽת׃"
          },
          {
            "num": "י",
            "txt": "וְעַמֻּדָ֣יו עֶשְׂרִ֔ים וְאַדְנֵיהֶ֥ם עֶשְׂרִ֖ים נְחֹ֑שֶׁת וָוֵ֧י הָֽעַמֻּדִ֛ים וַחֲשֻֽׁקֵיהֶ֖ם כָּֽסֶף׃"
          },
          {
            "num": "יא",
            "txt": "וְכֵ֨ן לִפְאַ֤ת צָפוֹן֙ בָּאֹ֔רֶךְ קְלָעִ֖ים מֵ֣אָה אֹ֑רֶךְ וְעַמֻּדָ֣ו עֶשְׂרִ֗ים וְאַדְנֵיהֶ֤ם עֶשְׂרִים֙ נְחֹ֔שֶׁת וָוֵ֧י הָֽעַמֻּדִ֛ים וַחֲשֻֽׁקֵיהֶ֖ם כָּֽסֶף׃"
          },
          {
            "num": "יב",
            "txt": "וְרֹ֤חַב הֶֽחָצֵר֙ לִפְאַת־יָ֔ם קְלָעִ֖ים חֲמִשִּׁ֣ים אַמָּ֑ה עַמֻּֽדֵיהֶ֣ם עֲשָׂרָ֔ה וְאַדְנֵיהֶ֖ם עֲשָׂרָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְרֹ֣חַב הֶֽחָצֵ֗ר לִפְאַ֛ת קֵ֥דְמָה מִזְרָ֖חָה חֲמִשִּׁ֥ים אַמָּֽה׃"
          },
          {
            "num": "יד",
            "txt": "וַֽחֲמֵ֨שׁ עֶשְׂרֵ֥ה אַמָּ֛ה קְלָעִ֖ים לַכָּתֵ֑ף עַמֻּֽדֵיהֶ֣ם שְׁלֹשָׁ֔ה וְאַדְנֵיהֶ֖ם שְׁלֹשָֽׁה׃"
          },
          {
            "num": "טו",
            "txt": "וְלַכָּתֵף֙ הַשֵּׁנִ֔ית חֲמֵ֥שׁ עֶשְׂרֵ֖ה קְלָעִ֑ים עַמֻּֽדֵיהֶ֣ם שְׁלֹשָׁ֔ה וְאַדְנֵיהֶ֖ם שְׁלֹשָֽׁה׃"
          },
          {
            "num": "טז",
            "txt": "וּלְשַׁ֨עַר הֶֽחָצֵ֜ר מָסָ֣ךְ ׀ עֶשְׂרִ֣ים אַמָּ֗ה תְּכֵ֨לֶת וְאַרְגָּמָ֜ן וְתוֹלַ֧עַת שָׁנִ֛י וְשֵׁ֥שׁ מָשְׁזָ֖ר מַֽעֲשֵׂ֣ה רֹקֵ֑ם עַמֻּֽדֵיהֶם֙ אַרְבָּעָ֔ה וְאַדְנֵיהֶ֖ם אַרְבָּעָֽה׃"
          },
          {
            "num": "יז",
            "txt": "כָּל־עַמּוּדֵ֨י הֶֽחָצֵ֤ר סָבִיב֙ מְחֻשָּׁקִ֣ים כֶּ֔סֶף וָֽוֵיהֶ֖ם כָּ֑סֶף וְאַדְנֵיהֶ֖ם נְחֹֽשֶׁת׃"
          },
          {
            "num": "יח",
            "txt": "אֹ֣רֶךְ הֶֽחָצֵר֩ מֵאָ֨ה בָֽאַמָּ֜ה וְרֹ֣חַב ׀ חֲמִשִּׁ֣ים בַּֽחֲמִשִּׁ֗ים וְקֹמָ֛ה חָמֵ֥שׁ אַמּ֖וֹת שֵׁ֣שׁ מָשְׁזָ֑ר וְאַדְנֵיהֶ֖ם נְחֹֽשֶׁת׃"
          },
          {
            "num": "יט",
            "txt": "לְכֹל֙ כְּלֵ֣י הַמִּשְׁכָּ֔ן בְּכֹ֖ל עֲבֹֽדָת֑וֹ וְכָל־יְתֵֽדֹתָ֛יו וְכָל־יִתְדֹ֥ת הֶֽחָצֵ֖ר נְחֹֽשֶׁת׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וְאַתָּ֞ה תְּצַוֶּ֣ה ׀ אֶת־בְּנֵ֣י יִשְׂרָאֵ֗ל וְיִקְח֨וּ אֵלֶ֜יךָ שֶׁ֣מֶן זַ֥יִת זָ֛ךְ כָּתִ֖ית לַמָּא֑וֹר לְהַֽעֲלֹ֥ת נֵ֖ר תָּמִֽיד׃"
          },
          {
            "num": "כא",
            "txt": "בְּאֹ֣הֶל מוֹעֵד֩ מִח֨וּץ לַפָּרֹ֜כֶת אֲשֶׁ֣ר עַל־הָֽעֵדֻ֗ת יַֽעֲרֹךְ֩ אֹת֨וֹ אַֽהֲרֹ֧ן וּבָנָ֛יו מֵעֶ֥רֶב עַד־בֹּ֖קֶר לִפְנֵ֣י יְהוָ֑ה חֻקַּ֤ת עוֹלָם֙ לְדֹ֣רֹתָ֔ם מֵאֵ֖ת בְּנֵ֥י יִשְׂרָאֵֽל׃ {ס}"
          }
        ]
      },
      {
        "num": "כח",
        "verses": [
          {
            "num": "א",
            "txt": "וְאַתָּ֡ה הַקְרֵ֣ב אֵלֶיךָ֩ אֶת־אַֽהֲרֹ֨ן אָחִ֜יךָ וְאֶת־בָּנָ֣יו אִתּ֗וֹ מִתּ֛וֹךְ בְּנֵ֥י יִשְׂרָאֵ֖ל לְכַֽהֲנוֹ־לִ֑י אַֽהֲרֹ֕ן נָדָ֧ב וַֽאֲבִיה֛וּא אֶלְעָזָ֥ר וְאִֽיתָמָ֖ר בְּנֵ֥י אַֽהֲרֹֽן׃"
          },
          {
            "num": "ב",
            "txt": "וְעָשִׂ֥יתָ בִגְדֵי־קֹ֖דֶשׁ לְאַֽהֲרֹ֣ן אָחִ֑יךָ לְכָב֖וֹד וּלְתִפְאָֽרֶת׃"
          },
          {
            "num": "ג",
            "txt": "וְאַתָּ֗ה תְּדַבֵּר֙ אֶל־כָּל־חַכְמֵי־לֵ֔ב אֲשֶׁ֥ר מִלֵּאתִ֖יו ר֣וּחַ חָכְמָ֑ה וְעָשׂ֞וּ אֶת־בִּגְדֵ֧י אַֽהֲרֹ֛ן לְקַדְּשׁ֖וֹ לְכַֽהֲנוֹ־לִֽי׃"
          },
          {
            "num": "ד",
            "txt": "וְאֵ֨לֶּה הַבְּגָדִ֜ים אֲשֶׁ֣ר יַֽעֲשׂ֗וּ חֹ֤שֶׁן וְאֵפוֹד֙ וּמְעִ֔יל וּכְתֹ֥נֶת תַּשְׁבֵּ֖ץ מִצְנֶ֣פֶת וְאַבְנֵ֑ט וְעָשׂ֨וּ בִגְדֵי־קֹ֜דֶשׁ לְאַֽהֲרֹ֥ן אָחִ֛יךָ וּלְבָנָ֖יו לְכַֽהֲנוֹ־לִֽי׃"
          },
          {
            "num": "ה",
            "txt": "וְהֵם֙ יִקְח֣וּ אֶת־הַזָּהָ֔ב וְאֶת־הַתְּכֵ֖לֶת וְאֶת־הָֽאַרְגָּמָ֑ן וְאֶת־תּוֹלַ֥עַת הַשָּׁנִ֖י וְאֶת־הַשֵּֽׁשׁ׃ {פ}"
          },
          {
            "num": "ו",
            "txt": "וְעָשׂ֖וּ אֶת־הָֽאֵפֹ֑ד זָ֠הָב תְּכֵ֨לֶת וְאַרְגָּמָ֜ן תּוֹלַ֧עַת שָׁנִ֛י וְשֵׁ֥שׁ מָשְׁזָ֖ר מַֽעֲשֵׂ֥ה חֹשֵֽׁב׃"
          },
          {
            "num": "ז",
            "txt": "שְׁתֵּ֧י כְתֵפֹ֣ת חֹֽבְרֹ֗ת יִֽהְיֶה־לּ֛וֹ אֶל־שְׁנֵ֥י קְצוֹתָ֖יו וְחֻבָּֽר׃"
          },
          {
            "num": "ח",
            "txt": "וְחֵ֤שֶׁב אֲפֻדָּתוֹ֙ אֲשֶׁ֣ר עָלָ֔יו כְּמַֽעֲשֵׂ֖הוּ מִמֶּ֣נּוּ יִֽהְיֶ֑ה זָהָ֗ב תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֥שׁ מָשְׁזָֽר׃"
          },
          {
            "num": "ט",
            "txt": "וְלָ֣קַחְתָּ֔ אֶת־שְׁתֵּ֖י אַבְנֵי־שֹׁ֑הַם וּפִתַּחְתָּ֣ עֲלֵיהֶ֔ם שְׁמ֖וֹת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "י",
            "txt": "שִׁשָּׁה֙ מִשְּׁמֹתָ֔ם עַ֖ל הָאֶ֣בֶן הָֽאֶחָ֑ת וְאֶת־שְׁמ֞וֹת הַשִּׁשָּׁ֧ה הַנּֽוֹתָרִ֛ים עַל־הָאֶ֥בֶן הַשֵּׁנִ֖ית כְּתֽוֹלְדֹתָֽם׃"
          },
          {
            "num": "יא",
            "txt": "מַֽעֲשֵׂ֣ה חָרַשׁ֮ אֶבֶן֒ פִּתּוּחֵ֣י חֹתָ֗ם תְּפַתַּח֙ אֶת־שְׁתֵּ֣י הָֽאֲבָנִ֔ים עַל־שְׁמֹ֖ת בְּנֵ֣י יִשְׂרָאֵ֑ל מֻֽסַבֹּ֛ת מִשְׁבְּצ֥וֹת זָהָ֖ב תַּֽעֲשֶׂ֥ה אֹתָֽם׃"
          },
          {
            "num": "יב",
            "txt": "וְשַׂמְתָּ֞ אֶת־שְׁתֵּ֣י הָֽאֲבָנִ֗ים עַ֚ל כִּתְפֹ֣ת הָֽאֵפֹ֔ד אַבְנֵ֥י זִכָּרֹ֖ן לִבְנֵ֣י יִשְׂרָאֵ֑ל וְנָשָׂא֩ אַֽהֲרֹ֨ן אֶת־שְׁמוֹתָ֜ם לִפְנֵ֧י יְהוָ֛ה עַל־שְׁתֵּ֥י כְתֵפָ֖יו לְזִכָּרֹֽן׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "וְעָשִׂ֥יתָ מִשְׁבְּצֹ֖ת זָהָֽב׃"
          },
          {
            "num": "יד",
            "txt": "וּשְׁתֵּ֤י שַׁרְשְׁרֹת֙ זָהָ֣ב טָה֔וֹר מִגְבָּלֹ֛ת תַּֽעֲשֶׂ֥ה אֹתָ֖ם מַֽעֲשֵׂ֣ה עֲבֹ֑ת וְנָֽתַתָּ֛ה אֶת־שַׁרְשְׁרֹ֥ת הָֽעֲבֹתֹ֖ת עַל־הַֽמִּשְׁבְּצֹֽת׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וְעָשִׂ֜יתָ חֹ֤שֶׁן מִשְׁפָּט֙ מַֽעֲשֵׂ֣ה חֹשֵׁ֔ב כְּמַֽעֲשֵׂ֥ה אֵפֹ֖ד תַּֽעֲשֶׂ֑נּוּ זָ֠הָב תְּכֵ֨לֶת וְאַרְגָּמָ֜ן וְתוֹלַ֧עַת שָׁנִ֛י וְשֵׁ֥שׁ מָשְׁזָ֖ר תַּֽעֲשֶׂ֥ה אֹתֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "רָב֥וּעַ יִֽהְיֶ֖ה כָּפ֑וּל זֶ֥רֶת אָרְכּ֖וֹ וְזֶ֥רֶת רָחְבּֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "וּמִלֵּאתָ֥ בוֹ֙ מִלֻּ֣אַת אֶ֔בֶן אַרְבָּעָ֖ה טוּרִ֣ים אָ֑בֶן ט֗וּר אֹ֤דֶם פִּטְדָה֙ וּבָרֶ֔קֶת הַטּ֖וּר הָֽאֶחָֽד׃"
          },
          {
            "num": "יח",
            "txt": "וְהַטּ֖וּר הַשֵּׁנִ֑י נֹ֥פֶךְ סַפִּ֖יר וְיָֽהֲלֹֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְהַטּ֖וּר הַשְּׁלִישִׁ֑י לֶ֥שֶׁם שְׁב֖וֹ וְאַחְלָֽמָה׃"
          },
          {
            "num": "כ",
            "txt": "וְהַטּוּר֙ הָֽרְבִיעִ֔י תַּרְשִׁ֥ישׁ וְשֹׁ֖הַם וְיָֽשְׁפֵ֑ה מְשֻׁבָּצִ֥ים זָהָ֛ב יִֽהְי֖וּ בְּמִלּֽוּאֹתָֽם׃"
          },
          {
            "num": "כא",
            "txt": "וְ֠הָֽאֲבָנִים תִּֽהְיֶ֜יןָ עַל־שְׁמֹ֧ת בְּנֵֽי־יִשְׂרָאֵ֛ל שְׁתֵּ֥ים עֶשְׂרֵ֖ה עַל־שְׁמֹתָ֑ם פִּתּוּחֵ֤י חוֹתָם֙ אִ֣ישׁ עַל־שְׁמ֔וֹ תִּֽהְיֶ֕יןָ לִשְׁנֵ֥י עָשָׂ֖ר שָֽׁבֶט׃"
          },
          {
            "num": "כב",
            "txt": "וְעָשִׂ֧יתָ עַל־הַחֹ֛שֶׁן שַֽׁרְשֹׁ֥ת גַּבְלֻ֖ת מַֽעֲשֵׂ֣ה עֲבֹ֑ת זָהָ֖ב טָהֽוֹר׃"
          },
          {
            "num": "כג",
            "txt": "וְעָשִׂ֨יתָ֙ עַל־הַחֹ֔שֶׁן שְׁתֵּ֖י טַבְּע֣וֹת זָהָ֑ב וְנָֽתַתָּ֗ אֶת־שְׁתֵּי֙ הַטַּבָּע֔וֹת עַל־שְׁנֵ֖י קְצ֥וֹת הַחֹֽשֶׁן׃"
          },
          {
            "num": "כד",
            "txt": "וְנָֽתַתָּ֗ה אֶת־שְׁתֵּי֙ עֲבֹתֹ֣ת הַזָּהָ֔ב עַל־שְׁתֵּ֖י הַטַּבָּעֹ֑ת אֶל־קְצ֖וֹת הַחֹֽשֶׁן׃"
          },
          {
            "num": "כה",
            "txt": "וְאֵ֨ת שְׁתֵּ֤י קְצוֹת֙ שְׁתֵּ֣י הָֽעֲבֹתֹ֔ת תִּתֵּ֖ן עַל־שְׁתֵּ֣י הַֽמִּשְׁבְּצ֑וֹת וְנָֽתַתָּ֛ה עַל־כִּתְפ֥וֹת הָֽאֵפֹ֖ד אֶל־מ֥וּל פָּנָֽיו׃"
          },
          {
            "num": "כו",
            "txt": "וְעָשִׂ֗יתָ שְׁתֵּי֙ טַבְּע֣וֹת זָהָ֔ב וְשַׂמְתָּ֣ אֹתָ֔ם עַל־שְׁנֵ֖י קְצ֣וֹת הַחֹ֑שֶׁן עַל־שְׂפָת֕וֹ אֲשֶׁ֛ר אֶל־עֵ֥בֶר הָֽאֵפֹ֖ד בָּֽיְתָה׃"
          },
          {
            "num": "כז",
            "txt": "וְעָשִׂיתָ֮ שְׁתֵּ֣י טַבְּע֣וֹת זָהָב֒ וְנָֽתַתָּ֣ה אֹתָ֡ם עַל־שְׁתֵּי֩ כִתְפ֨וֹת הָֽאֵפ֤וֹד מִלְּמַ֨טָּה֙ מִמּ֣וּל פָּנָ֔יו לְעֻמַּ֖ת מַחְבַּרְתּ֑וֹ מִמַּ֕עַל לְחֵ֖שֶׁב הָֽאֵפֽוֹד׃"
          },
          {
            "num": "כח",
            "txt": "וְיִרְכְּס֣וּ אֶת־הַ֠חֹשֶׁן מִֽטַּבְּעֹתָ֞ו אֶל־טַבְּעֹ֤ת הָֽאֵפוֹד֙ בִּפְתִ֣יל תְּכֵ֔לֶת לִֽהְי֖וֹת עַל־חֵ֣שֶׁב הָֽאֵפ֑וֹד וְלֹֽא־יִזַּ֣ח הַחֹ֔שֶׁן מֵעַ֖ל הָֽאֵפֽוֹד׃"
          },
          {
            "num": "כט",
            "txt": "וְנָשָׂ֣א אַֽ֠הֲרֹן אֶת־שְׁמ֨וֹת בְּנֵֽי־יִשְׂרָאֵ֜ל בְּחֹ֧שֶׁן הַמִּשְׁפָּ֛ט עַל־לִבּ֖וֹ בְּבֹא֣וֹ אֶל־הַקֹּ֑דֶשׁ לְזִכָּרֹ֥ן לִפְנֵֽי־יְהוָ֖ה תָּמִֽיד׃"
          },
          {
            "num": "ל",
            "txt": "וְנָֽתַתָּ֞ אֶל־חֹ֣שֶׁן הַמִּשְׁפָּ֗ט אֶת־הָֽאוּרִים֙ וְאֶת־הַתֻּמִּ֔ים וְהָיוּ֙ עַל־לֵ֣ב אַֽהֲרֹ֔ן בְּבֹא֖וֹ לִפְנֵ֣י יְהוָ֑ה וְנָשָׂ֣א אַֽ֠הֲרֹן אֶת־מִשְׁפַּ֨ט בְּנֵֽי־יִשְׂרָאֵ֧ל עַל־לִבּ֛וֹ לִפְנֵ֥י יְהוָ֖ה תָּמִֽיד׃ {ס}"
          },
          {
            "num": "לא",
            "txt": "וְעָשִׂ֛יתָ אֶת־מְעִ֥יל הָֽאֵפ֖וֹד כְּלִ֥יל תְּכֵֽלֶת׃"
          },
          {
            "num": "לב",
            "txt": "וְהָיָ֥ה פִֽי־רֹאשׁ֖וֹ בְּתוֹכ֑וֹ שָׂפָ֡ה יִֽהְיֶה֩ לְפִ֨יו סָבִ֜יב מַֽעֲשֵׂ֣ה אֹרֵ֗ג כְּפִ֥י תַחְרָ֛א יִֽהְיֶה־לּ֖וֹ לֹ֥א יִקָּרֵֽעַ׃"
          },
          {
            "num": "לג",
            "txt": "וְעָשִׂ֣יתָ עַל־שׁוּלָ֗יו רִמֹּנֵי֙ תְּכֵ֤לֶת וְאַרְגָּמָן֙ וְתוֹלַ֣עַת שָׁנִ֔י עַל־שׁוּלָ֖יו סָבִ֑יב וּפַֽעֲמֹנֵ֥י זָהָ֛ב בְּתוֹכָ֖ם סָבִֽיב׃"
          },
          {
            "num": "לד",
            "txt": "פַּֽעֲמֹ֤ן זָהָב֙ וְרִמּ֔וֹן פַּֽעֲמֹ֥ן זָהָ֖ב וְרִמּ֑וֹן עַל־שׁוּלֵ֥י הַמְּעִ֖יל סָבִֽיב׃"
          },
          {
            "num": "לה",
            "txt": "וְהָיָ֥ה עַֽל־אַהֲרֹ֖ן לְשָׁרֵ֑ת וְנִשְׁמַ֣ע ק֠וֹלוֹ בְּבֹא֨וֹ אֶל־הַקֹּ֜דֶשׁ לִפְנֵ֧י יְהוָ֛ה וּבְצֵאת֖וֹ וְלֹ֥א יָמֽוּת׃ {ס}"
          },
          {
            "num": "לו",
            "txt": "וְעָשִׂ֥יתָ צִּ֖יץ זָהָ֣ב טָה֑וֹר וּפִתַּחְתָּ֤ עָלָיו֙ פִּתּוּחֵ֣י חֹתָ֔ם קֹ֖דֶשׁ לַֽיהוָֽה׃"
          },
          {
            "num": "לז",
            "txt": "וְשַׂמְתָּ֤ אֹתוֹ֙ עַל־פְּתִ֣יל תְּכֵ֔לֶת וְהָיָ֖ה עַל־הַמִּצְנָ֑פֶת אֶל־מ֥וּל פְּנֵֽי־הַמִּצְנֶ֖פֶת יִֽהְיֶֽה׃"
          },
          {
            "num": "לח",
            "txt": "וְהָיָה֮ עַל־מֵ֣צַח אַֽהֲרֹן֒ וְנָשָׂ֨א אַֽהֲרֹ֜ן אֶת־עֲוֺ֣ן הַקֳּדָשִׁ֗ים אֲשֶׁ֤ר יַקְדִּ֨ישׁוּ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל לְכָֽל־מַתְּנֹ֖ת קָדְשֵׁיהֶ֑ם וְהָיָ֤ה עַל־מִצְחוֹ֙ תָּמִ֔יד לְרָצ֥וֹן לָהֶ֖ם לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "לט",
            "txt": "וְשִׁבַּצְתָּ֙ הַכְּתֹ֣נֶת שֵׁ֔שׁ וְעָשִׂ֖יתָ מִצְנֶ֣פֶת שֵׁ֑שׁ וְאַבְנֵ֥ט תַּֽעֲשֶׂ֖ה מַֽעֲשֵׂ֥ה רֹקֵֽם׃"
          },
          {
            "num": "מ",
            "txt": "וְלִבְנֵ֤י אַֽהֲרֹן֙ תַּֽעֲשֶׂ֣ה כֻתֳּנֹ֔ת וְעָשִׂ֥יתָ לָהֶ֖ם אַבְנֵטִ֑ים וּמִגְבָּעוֹת֙ תַּֽעֲשֶׂ֣ה לָהֶ֔ם לְכָב֖וֹד וּלְתִפְאָֽרֶת׃"
          },
          {
            "num": "מא",
            "txt": "וְהִלְבַּשְׁתָּ֤ אֹתָם֙ אֶת־אַֽהֲרֹ֣ן אָחִ֔יךָ וְאֶת־בָּנָ֖יו אִתּ֑וֹ וּמָֽשַׁחְתָּ֨ אֹתָ֜ם וּמִלֵּאתָ֧ אֶת־יָדָ֛ם וְקִדַּשְׁתָּ֥ אֹתָ֖ם וְכִֽהֲנ֥וּ לִֽי׃"
          },
          {
            "num": "מב",
            "txt": "וַֽעֲשֵׂ֤ה לָהֶם֙ מִכְנְסֵי־בָ֔ד לְכַסּ֖וֹת בְּשַׂ֣ר עֶרְוָ֑ה מִמָּתְנַ֥יִם וְעַד־יְרֵכַ֖יִם יִֽהְיֽוּ׃"
          },
          {
            "num": "מג",
            "txt": "וְהָיוּ֩ עַל־אַֽהֲרֹ֨ן וְעַל־בָּנָ֜יו בְּבֹאָ֣ם ׀ אֶל־אֹ֣הֶל מוֹעֵ֗ד א֣וֹ בְגִשְׁתָּ֤ם אֶל־הַמִּזְבֵּ֨חַ֙ לְשָׁרֵ֣ת בַּקֹּ֔דֶשׁ וְלֹֽא־יִשְׂא֥וּ עָוֺ֖ן וָמֵ֑תוּ חֻקַּ֥ת עוֹלָ֛ם ל֖וֹ וּלְזַרְע֥וֹ אַֽחֲרָֽיו׃ {ס}"
          }
        ]
      },
      {
        "num": "כט",
        "verses": [
          {
            "num": "א",
            "txt": "וְזֶ֨ה הַדָּבָ֜ר אֲשֶֽׁר־תַּעֲשֶׂ֥ה לָהֶ֛ם לְקַדֵּ֥שׁ אֹתָ֖ם לְכַהֵ֣ן לִ֑י לְ֠קַח פַּ֣ר אֶחָ֧ד בֶּן־בָּקָ֛ר וְאֵילִ֥ם שְׁנַ֖יִם תְּמִימִֽם׃"
          },
          {
            "num": "ב",
            "txt": "וְלֶ֣חֶם מַצּ֗וֹת וְחַלֹּ֤ת מַצֹּת֙ בְּלוּלֹ֣ת בַּשֶּׁ֔מֶן וּרְקִיקֵ֥י מַצּ֖וֹת מְשֻׁחִ֣ים בַּשָּׁ֑מֶן סֹ֥לֶת חִטִּ֖ים תַּֽעֲשֶׂ֥ה אֹתָֽם׃"
          },
          {
            "num": "ג",
            "txt": "וְנָֽתַתָּ֤ אוֹתָם֙ עַל־סַ֣ל אֶחָ֔ד וְהִקְרַבְתָּ֥ אֹתָ֖ם בַּסָּ֑ל וְאֶ֨ת־הַפָּ֔ר וְאֵ֖ת שְׁנֵ֥י הָֽאֵילִֽם׃"
          },
          {
            "num": "ד",
            "txt": "וְאֶת־אַֽהֲרֹ֤ן וְאֶת־בָּנָיו֙ תַּקְרִ֔יב אֶל־פֶּ֖תַח אֹ֣הֶל מוֹעֵ֑ד וְרָֽחַצְתָּ֥ אֹתָ֖ם בַּמָּֽיִם׃"
          },
          {
            "num": "ה",
            "txt": "וְלָֽקַחְתָּ֣ אֶת־הַבְּגָדִ֗ים וְהִלְבַּשְׁתָּ֤ אֶֽת־אַהֲרֹן֙ אֶת־הַכֻּתֹּ֔נֶת וְאֵת֙ מְעִ֣יל הָֽאֵפֹ֔ד וְאֶת־הָֽאֵפֹ֖ד וְאֶת־הַחֹ֑שֶׁן וְאָֽפַדְתָּ֣ ל֔וֹ בְּחֵ֖שֶׁב הָֽאֵפֹֽד׃"
          },
          {
            "num": "ו",
            "txt": "וְשַׂמְתָּ֥ הַמִּצְנֶ֖פֶת עַל־רֹאשׁ֑וֹ וְנָֽתַתָּ֛ אֶת־נֵ֥זֶר הַקֹּ֖דֶשׁ עַל־הַמִּצְנָֽפֶת׃"
          },
          {
            "num": "ז",
            "txt": "וְלָֽקַחְתָּ֙ אֶת־שֶׁ֣מֶן הַמִּשְׁחָ֔ה וְיָֽצַקְתָּ֖ עַל־רֹאשׁ֑וֹ וּמָֽשַׁחְתָּ֖ אֹתֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וְאֶת־בָּנָ֖יו תַּקְרִ֑יב וְהִלְבַּשְׁתָּ֖ם כֻּתֳּנֹֽת׃"
          },
          {
            "num": "ט",
            "txt": "וְחָֽגַרְתָּ֩ אֹתָ֨ם אַבְנֵ֜ט אַֽהֲרֹ֣ן וּבָנָ֗יו וְחָֽבַשְׁתָּ֤ לָהֶם֙ מִגְבָּעֹ֔ת וְהָֽיְתָ֥ה לָהֶ֛ם כְּהֻנָּ֖ה לְחֻקַּ֣ת עוֹלָ֑ם וּמִלֵּאתָ֥ יַֽד־אַהֲרֹ֖ן וְיַד־בָּנָֽיו׃"
          },
          {
            "num": "י",
            "txt": "וְהִקְרַבְתָּ֙ אֶת־הַפָּ֔ר לִפְנֵ֖י אֹ֣הֶל מוֹעֵ֑ד וְסָמַ֨ךְ אַֽהֲרֹ֧ן וּבָנָ֛יו אֶת־יְדֵיהֶ֖ם עַל־רֹ֥אשׁ הַפָּֽר׃"
          },
          {
            "num": "יא",
            "txt": "וְשָֽׁחַטְתָּ֥ אֶת־הַפָּ֖ר לִפְנֵ֣י יְהוָ֑ה פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "יב",
            "txt": "וְלָֽקַחְתָּ֙ מִדַּ֣ם הַפָּ֔ר וְנָֽתַתָּ֛ה עַל־קַרְנֹ֥ת הַמִּזְבֵּ֖חַ בְּאֶצְבָּעֶ֑ךָ וְאֶת־כָּל־הַדָּ֣ם תִּשְׁפֹּ֔ךְ אֶל־יְס֖וֹד הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "יג",
            "txt": "וְלָֽקַחְתָּ֗ אֶֽת־כָּל־הַחֵלֶב֮ הַֽמְכַסֶּ֣ה אֶת־הַקֶּרֶב֒ וְאֵ֗ת הַיֹּתֶ֨רֶת֙ עַל־הַכָּבֵ֔ד וְאֵת֙ שְׁתֵּ֣י הַכְּלָיֹ֔ת וְאֶת־הַחֵ֖לֶב אֲשֶׁ֣ר עֲלֵיהֶ֑ן וְהִקְטַרְתָּ֖ הַמִּזְבֵּֽחָה׃"
          },
          {
            "num": "יד",
            "txt": "וְאֶת־בְּשַׂ֤ר הַפָּר֙ וְאֶת־עֹר֣וֹ וְאֶת־פִּרְשׁ֔וֹ תִּשְׂרֹ֣ף בָּאֵ֔שׁ מִח֖וּץ לַֽמַּחֲנֶ֑ה חַטָּ֖את הֽוּא׃"
          },
          {
            "num": "טו",
            "txt": "וְאֶת־הָאַ֥יִל הָֽאֶחָ֖ד תִּקָּ֑ח וְסָ֨מְכ֜וּ אַֽהֲרֹ֧ן וּבָנָ֛יו אֶת־יְדֵיהֶ֖ם עַל־רֹ֥אשׁ הָאָֽיִל׃"
          },
          {
            "num": "טז",
            "txt": "וְשָֽׁחַטְתָּ֖ אֶת־הָאָ֑יִל וְלָֽקַחְתָּ֙ אֶת־דָּמ֔וֹ וְזָֽרַקְתָּ֥ עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "יז",
            "txt": "וְאֶ֨ת־הָאַ֔יִל תְּנַתֵּ֖חַ לִנְתָחָ֑יו וְרָֽחַצְתָּ֤ קִרְבּוֹ֙ וּכְרָעָ֔יו וְנָֽתַתָּ֥ עַל־נְתָחָ֖יו וְעַל־רֹאשֽׁוֹ׃"
          },
          {
            "num": "יח",
            "txt": "וְהִקְטַרְתָּ֤ אֶת־כָּל־הָאַ֨יִל֙ הַמִּזְבֵּ֔חָה עֹלָ֥ה ה֖וּא לַֽיהוָ֑ה רֵ֣יחַ נִיח֔וֹחַ אִשֶּׁ֥ה לַֽיהוָ֖ה הֽוּא׃"
          },
          {
            "num": "יט",
            "txt": "וְלָ֣קַחְתָּ֔ אֵ֖ת הָאַ֣יִל הַשֵּׁנִ֑י וְסָמַ֨ךְ אַֽהֲרֹ֧ן וּבָנָ֛יו אֶת־יְדֵיהֶ֖ם עַל־רֹ֥אשׁ הָאָֽיִל׃"
          },
          {
            "num": "כ",
            "txt": "וְשָֽׁחַטְתָּ֣ אֶת־הָאַ֗יִל וְלָֽקַחְתָּ֤ מִדָּמוֹ֙ וְנָֽתַתָּ֡ה עַל־תְּנוּךְ֩ אֹ֨זֶן אַֽהֲרֹ֜ן וְעַל־תְּנ֨וּךְ אֹ֤זֶן בָּנָיו֙ הַיְמָנִ֔ית וְעַל־בֹּ֤הֶן יָדָם֙ הַיְמָנִ֔ית וְעַל־בֹּ֥הֶן רַגְלָ֖ם הַיְמָנִ֑ית וְזָֽרַקְתָּ֧ אֶת־הַדָּ֛ם עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "כא",
            "txt": "וְלָֽקַחְתָּ֞ מִן־הַדָּ֨ם אֲשֶׁ֥ר עַֽל־הַמִּזְבֵּחַ֮ וּמִשֶּׁ֣מֶן הַמִּשְׁחָה֒ וְהִזֵּיתָ֤ עַֽל־אַהֲרֹן֙ וְעַל־בְּגָדָ֔יו וְעַל־בָּנָ֛יו וְעַל־בִּגְדֵ֥י בָנָ֖יו אִתּ֑וֹ וְקָדַ֥שׁ הוּא֙ וּבְגָדָ֔יו וּבָנָ֛יו וּבִגְדֵ֥י בָנָ֖יו אִתּֽוֹ׃"
          },
          {
            "num": "כב",
            "txt": "וְלָֽקַחְתָּ֣ מִן־הָ֠אַיִל הַחֵ֨לֶב וְהָֽאַלְיָ֜ה וְאֶת־הַחֵ֣לֶב ׀ הַֽמְכַסֶּ֣ה אֶת־הַקֶּ֗רֶב וְאֵ֨ת יֹתֶ֤רֶת הַכָּבֵד֙ וְאֵ֣ת ׀ שְׁתֵּ֣י הַכְּלָיֹ֗ת וְאֶת־הַחֵ֨לֶב֙ אֲשֶׁ֣ר עֲלֵיהֶ֔ן וְאֵ֖ת שׁ֣וֹק הַיָּמִ֑ין כִּ֛י אֵ֥יל מִלֻּאִ֖ים הֽוּא׃"
          },
          {
            "num": "כג",
            "txt": "וְכִכַּ֨ר לֶ֜חֶם אַחַ֗ת וְֽחַלַּ֨ת לֶ֥חֶם שֶׁ֛מֶן אַחַ֖ת וְרָקִ֣יק אֶחָ֑ד מִסַּל֙ הַמַּצּ֔וֹת אֲשֶׁ֖ר לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כד",
            "txt": "וְשַׂמְתָּ֣ הַכֹּ֔ל עַ֚ל כַּפֵּ֣י אַֽהֲרֹ֔ן וְעַ֖ל כַּפֵּ֣י בָנָ֑יו וְהֵֽנַפְתָּ֥ אֹתָ֛ם תְּנוּפָ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כה",
            "txt": "וְלָֽקַחְתָּ֤ אֹתָם֙ מִיָּדָ֔ם וְהִקְטַרְתָּ֥ הַמִּזְבֵּ֖חָה עַל־הָֽעֹלָ֑ה לְרֵ֤יחַ נִיח֨וֹחַ֙ לִפְנֵ֣י יְהוָ֔ה אִשֶּׁ֥ה ה֖וּא לַֽיהוָֽה׃"
          },
          {
            "num": "כו",
            "txt": "וְלָֽקַחְתָּ֣ אֶת־הֶֽחָזֶ֗ה מֵאֵ֤יל הַמִּלֻּאִים֙ אֲשֶׁ֣ר לְאַֽהֲרֹ֔ן וְהֵֽנַפְתָּ֥ אֹת֛וֹ תְּנוּפָ֖ה לִפְנֵ֣י יְהוָ֑ה וְהָיָ֥ה לְךָ֖ לְמָנָֽה׃"
          },
          {
            "num": "כז",
            "txt": "וְקִדַּשְׁתָּ֞ אֵ֣ת ׀ חֲזֵ֣ה הַתְּנוּפָ֗ה וְאֵת֙ שׁ֣וֹק הַתְּרוּמָ֔ה אֲשֶׁ֥ר הוּנַ֖ף וַֽאֲשֶׁ֣ר הוּרָ֑ם מֵאֵיל֙ הַמִּלֻּאִ֔ים מֵֽאֲשֶׁ֥ר לְאַֽהֲרֹ֖ן וּמֵֽאֲשֶׁ֥ר לְבָנָֽיו׃"
          },
          {
            "num": "כח",
            "txt": "וְהָיָה֩ לְאַֽהֲרֹ֨ן וּלְבָנָ֜יו לְחָק־עוֹלָ֗ם מֵאֵת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל כִּ֥י תְרוּמָ֖ה ה֑וּא וּתְרוּמָ֞ה יִֽהְיֶ֨ה מֵאֵ֤ת בְּנֵֽי־יִשְׂרָאֵל֙ מִזִּבְחֵ֣י שַׁלְמֵיהֶ֔ם תְּרֽוּמָתָ֖ם לַֽיהוָֽה׃"
          },
          {
            "num": "כט",
            "txt": "וּבִגְדֵ֤י הַקֹּ֨דֶשׁ֙ אֲשֶׁ֣ר לְאַֽהֲרֹ֔ן יִֽהְי֥וּ לְבָנָ֖יו אַֽחֲרָ֑יו לְמָשְׁחָ֣ה בָהֶ֔ם וּלְמַלֵּא־בָ֖ם אֶת־יָדָֽם׃"
          },
          {
            "num": "ל",
            "txt": "שִׁבְעַ֣ת יָמִ֗ים יִלְבָּשָׁ֧ם הַכֹּהֵ֛ן תַּחְתָּ֖יו מִבָּנָ֑יו אֲשֶׁ֥ר יָבֹ֛א אֶל־אֹ֥הֶל מוֹעֵ֖ד לְשָׁרֵ֥ת בַּקֹּֽדֶשׁ׃"
          },
          {
            "num": "לא",
            "txt": "וְאֵ֛ת אֵ֥יל הַמִּלֻּאִ֖ים תִּקָּ֑ח וּבִשַּׁלְתָּ֥ אֶת־בְּשָׂר֖וֹ בְּמָקֹ֥ם קָדֹֽשׁ׃"
          },
          {
            "num": "לב",
            "txt": "וְאָכַ֨ל אַֽהֲרֹ֤ן וּבָנָיו֙ אֶת־בְּשַׂ֣ר הָאַ֔יִל וְאֶת־הַלֶּ֖חֶם אֲשֶׁ֣ר בַּסָּ֑ל פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "לג",
            "txt": "וְאָֽכְל֤וּ אֹתָם֙ אֲשֶׁ֣ר כֻּפַּ֣ר בָּהֶ֔ם לְמַלֵּ֥א אֶת־יָדָ֖ם לְקַדֵּ֣שׁ אֹתָ֑ם וְזָ֥ר לֹֽא־יֹאכַ֖ל כִּי־קֹ֥דֶשׁ הֵֽם׃"
          },
          {
            "num": "לד",
            "txt": "וְֽאִם־יִוָּתֵ֞ר מִבְּשַׂ֧ר הַמִּלֻּאִ֛ים וּמִן־הַלֶּ֖חֶם עַד־הַבֹּ֑קֶר וְשָֽׂרַפְתָּ֤ אֶת־הַנּוֹתָר֙ בָּאֵ֔שׁ לֹ֥א יֵֽאָכֵ֖ל כִּי־קֹ֥דֶשׁ הֽוּא׃"
          },
          {
            "num": "לה",
            "txt": "וְעָשִׂ֜יתָ לְאַֽהֲרֹ֤ן וּלְבָנָיו֙ כָּ֔כָה כְּכֹ֥ל אֲשֶׁר־צִוִּ֖יתִי אֹתָ֑כָה שִׁבְעַ֥ת יָמִ֖ים תְּמַלֵּ֥א יָדָֽם׃"
          },
          {
            "num": "לו",
            "txt": "וּפַ֨ר חַטָּ֜את תַּֽעֲשֶׂ֤ה לַיּוֹם֙ עַל־הַכִּפֻּרִ֔ים וְחִטֵּאתָ֙ עַל־הַמִּזְבֵּ֔חַ בְּכַפֶּרְךָ֖ עָלָ֑יו וּמָֽשַׁחְתָּ֥ אֹת֖וֹ לְקַדְּשֽׁוֹ׃"
          },
          {
            "num": "לז",
            "txt": "שִׁבְעַ֣ת יָמִ֗ים תְּכַפֵּר֙ עַל־הַמִּזְבֵּ֔חַ וְקִדַּשְׁתָּ֖ אֹת֑וֹ וְהָיָ֤ה הַמִּזְבֵּ֨חַ֙ קֹ֣דֶשׁ קָֽדָשִׁ֔ים כָּל־הַנֹּגֵ֥עַ בַּמִּזְבֵּ֖חַ יִקְדָּֽשׁ׃ {ס}"
          },
          {
            "num": "לח",
            "txt": "וְזֶ֕ה אֲשֶׁ֥ר תַּֽעֲשֶׂ֖ה עַל־הַמִּזְבֵּ֑חַ כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה שְׁנַ֥יִם לַיּ֖וֹם תָּמִֽיד׃"
          },
          {
            "num": "לט",
            "txt": "אֶת־הַכֶּ֥בֶשׂ הָֽאֶחָ֖ד תַּֽעֲשֶׂ֣ה בַבֹּ֑קֶר וְאֵת֙ הַכֶּ֣בֶשׂ הַשֵּׁנִ֔י תַּֽעֲשֶׂ֖ה בֵּ֥ין הָֽעַרְבָּֽיִם׃"
          },
          {
            "num": "מ",
            "txt": "וְעִשָּׂרֹ֨ן סֹ֜לֶת בָּל֨וּל בְּשֶׁ֤מֶן כָּתִית֙ רֶ֣בַע הַהִ֔ין וְנֵ֕סֶךְ רְבִיעִ֥ת הַהִ֖ין יָ֑יִן לַכֶּ֖בֶשׂ הָֽאֶחָֽד׃"
          },
          {
            "num": "מא",
            "txt": "וְאֵת֙ הַכֶּ֣בֶשׂ הַשֵּׁנִ֔י תַּֽעֲשֶׂ֖ה בֵּ֣ין הָֽעַרְבָּ֑יִם כְּמִנְחַ֨ת הַבֹּ֤קֶר וּכְנִסְכָּהּ֙ תַּֽעֲשֶׂה־לָּ֔הּ לְרֵ֣יחַ נִיחֹ֔חַ אִשֶּׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "מב",
            "txt": "עֹלַ֤ת תָּמִיד֙ לְדֹרֹ֣תֵיכֶ֔ם פֶּ֥תַח אֹֽהֶל־מוֹעֵ֖ד לִפְנֵ֣י יְהוָ֑ה אֲשֶׁ֨ר אִוָּעֵ֤ד לָכֶם֙ שָׁ֔מָּה לְדַבֵּ֥ר אֵלֶ֖יךָ שָֽׁם׃"
          },
          {
            "num": "מג",
            "txt": "וְנֹֽעַדְתִּ֥י שָׁ֖מָּה לִבְנֵ֣י יִשְׂרָאֵ֑ל וְנִקְדַּ֖שׁ בִּכְבֹדִֽי׃"
          },
          {
            "num": "מד",
            "txt": "וְקִדַּשְׁתִּ֛י אֶת־אֹ֥הֶל מוֹעֵ֖ד וְאֶת־הַמִּזְבֵּ֑חַ וְאֶת־אַֽהֲרֹ֧ן וְאֶת־בָּנָ֛יו אֲקַדֵּ֖שׁ לְכַהֵ֥ן לִֽי׃"
          },
          {
            "num": "מה",
            "txt": "וְשָׁ֣כַנְתִּ֔י בְּת֖וֹךְ בְּנֵ֣י יִשְׂרָאֵ֑ל וְהָיִ֥יתִי לָהֶ֖ם לֵֽאלֹהִֽים׃"
          },
          {
            "num": "מו",
            "txt": "וְיָֽדְע֗וּ כִּ֣י אֲנִ֤י יְהוָה֙ אֱלֹ֣הֵיהֶ֔ם אֲשֶׁ֨ר הוֹצֵ֧אתִי אֹתָ֛ם מֵאֶ֥רֶץ מִצְרַ֖יִם לְשָׁכְנִ֣י בְתוֹכָ֑ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיהֶֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "ל",
        "verses": [
          {
            "num": "א",
            "txt": "וְעָשִׂ֥יתָ מִזְבֵּ֖חַ מִקְטַ֣ר קְטֹ֑רֶת עֲצֵ֥י שִׁטִּ֖ים תַּֽעֲשֶׂ֥ה אֹתֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "אַמָּ֨ה אָרְכּ֜וֹ וְאַמָּ֤ה רָחְבּוֹ֙ רָב֣וּעַ יִֽהְיֶ֔ה וְאַמָּתַ֖יִם קֹֽמָת֑וֹ מִמֶּ֖נּוּ קַרְנֹתָֽיו׃"
          },
          {
            "num": "ג",
            "txt": "וְצִפִּיתָ֨ אֹת֜וֹ זָהָ֣ב טָה֗וֹר אֶת־גַּגּ֧וֹ וְאֶת־קִֽירֹתָ֛יו סָבִ֖יב וְאֶת־קַרְנֹתָ֑יו וְעָשִׂ֥יתָ לּ֛וֹ זֵ֥ר זָהָ֖ב סָבִֽיב׃"
          },
          {
            "num": "ד",
            "txt": "וּשְׁתֵּי֩ טַבְּעֹ֨ת זָהָ֜ב תַּֽעֲשֶׂה־לּ֣וֹ ׀ מִתַּ֣חַת לְזֵר֗וֹ עַ֚ל שְׁתֵּ֣י צַלְעֹתָ֔יו תַּֽעֲשֶׂ֖ה עַל־שְׁנֵ֣י צִדָּ֑יו וְהָיָה֙ לְבָתִּ֣ים לְבַדִּ֔ים לָשֵׂ֥את אֹת֖וֹ בָּהֵֽמָּה׃"
          },
          {
            "num": "ה",
            "txt": "וְעָשִׂ֥יתָ אֶת־הַבַּדִּ֖ים עֲצֵ֣י שִׁטִּ֑ים וְצִפִּיתָ֥ אֹתָ֖ם זָהָֽב׃"
          },
          {
            "num": "ו",
            "txt": "וְנָֽתַתָּ֤ה אֹתוֹ֙ לִפְנֵ֣י הַפָּרֹ֔כֶת אֲשֶׁ֖ר עַל־אֲרֹ֣ן הָֽעֵדֻ֑ת לִפְנֵ֣י הַכַּפֹּ֗רֶת אֲשֶׁר֙ עַל־הָ֣עֵדֻ֔ת אֲשֶׁ֛ר אִוָּעֵ֥ד לְךָ֖ שָֽׁמָּה׃"
          },
          {
            "num": "ז",
            "txt": "וְהִקְטִ֥יר עָלָ֛יו אַֽהֲרֹ֖ן קְטֹ֣רֶת סַמִּ֑ים בַּבֹּ֣קֶר בַּבֹּ֗קֶר בְּהֵֽיטִיב֛וֹ אֶת־הַנֵּרֹ֖ת יַקְטִירֶֽנָּה׃"
          },
          {
            "num": "ח",
            "txt": "וּבְהַֽעֲלֹ֨ת אַֽהֲרֹ֧ן אֶת־הַנֵּרֹ֛ת בֵּ֥ין הָֽעַרְבַּ֖יִם יַקְטִירֶ֑נָּה קְטֹ֧רֶת תָּמִ֛יד לִפְנֵ֥י יְהוָ֖ה לְדֹרֹֽתֵיכֶֽם׃"
          },
          {
            "num": "ט",
            "txt": "לֹֽא־תַעֲל֥וּ עָלָ֛יו קְטֹ֥רֶת זָרָ֖ה וְעֹלָ֣ה וּמִנְחָ֑ה וְנֵ֕סֶךְ לֹ֥א תִסְּכ֖וּ עָלָֽיו׃"
          },
          {
            "num": "י",
            "txt": "וְכִפֶּ֤ר אַֽהֲרֹן֙ עַל־קַרְנֹתָ֔יו אַחַ֖ת בַּשָּׁנָ֑ה מִדַּ֞ם חַטַּ֣את הַכִּפֻּרִ֗ים אַחַ֤ת בַּשָּׁנָה֙ יְכַפֵּ֤ר עָלָיו֙ לְדֹרֹ֣תֵיכֶ֔ם קֹֽדֶשׁ־קָֽדָשִׁ֥ים ה֖וּא לַֽיהוָֽה׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יב",
            "txt": "כִּ֣י תִשָּׂ֞א אֶת־רֹ֥אשׁ בְּנֵֽי־יִשְׂרָאֵל֮ לִפְקֻֽדֵיהֶם֒ וְנָ֨תְנ֜וּ אִ֣ישׁ כֹּ֧פֶר נַפְשׁ֛וֹ לַֽיהוָ֖ה בִּפְקֹ֣ד אֹתָ֑ם וְלֹֽא־יִהְיֶ֥ה בָהֶ֛ם נֶ֖גֶף בִּפְקֹ֥ד אֹתָֽם׃"
          },
          {
            "num": "יג",
            "txt": "זֶ֣ה ׀ יִתְּנ֗וּ כָּל־הָֽעֹבֵר֙ עַל־הַפְּקֻדִ֔ים מַֽחֲצִ֥ית הַשֶּׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ עֶשְׂרִ֤ים גֵּרָה֙ הַשֶּׁ֔קֶל מַֽחֲצִ֣ית הַשֶּׁ֔קֶל תְּרוּמָ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "יד",
            "txt": "כֹּ֗ל הָֽעֹבֵר֙ עַל־הַפְּקֻדִ֔ים מִבֶּ֛ן עֶשְׂרִ֥ים שָׁנָ֖ה וָמָ֑עְלָה יִתֵּ֖ן תְּרוּמַ֥ת יְהוָֽה׃"
          },
          {
            "num": "טו",
            "txt": "הֶֽעָשִׁ֣יר לֹֽא־יַרְבֶּ֗ה וְהַדַּל֙ לֹ֣א יַמְעִ֔יט מִֽמַּחֲצִ֖ית הַשָּׁ֑קֶל לָתֵת֙ אֶת־תְּרוּמַ֣ת יְהוָ֔ה לְכַפֵּ֖ר עַל־נַפְשֹֽׁתֵיכֶֽם׃"
          },
          {
            "num": "טז",
            "txt": "וְלָֽקַחְתָּ֞ אֶת־כֶּ֣סֶף הַכִּפֻּרִ֗ים מֵאֵת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וְנָֽתַתָּ֣ אֹת֔וֹ עַל־עֲבֹדַ֖ת אֹ֣הֶל מוֹעֵ֑ד וְהָיָה֩ לִבְנֵ֨י יִשְׂרָאֵ֤ל לְזִכָּרוֹן֙ לִפְנֵ֣י יְהוָ֔ה לְכַפֵּ֖ר עַל־נַפְשֹֽׁתֵיכֶֽם׃ {פ}"
          },
          {
            "num": "יז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יח",
            "txt": "וְעָשִׂ֜יתָ כִּיּ֥וֹר נְחֹ֛שֶׁת וְכַנּ֥וֹ נְחֹ֖שֶׁת לְרָחְצָ֑ה וְנָֽתַתָּ֣ אֹת֗וֹ בֵּֽין־אֹ֤הֶל מוֹעֵד֙ וּבֵ֣ין הַמִּזְבֵּ֔חַ וְנָֽתַתָּ֥ שָׁ֖מָּה מָֽיִם׃"
          },
          {
            "num": "יט",
            "txt": "וְרָֽחֲצ֛וּ אַֽהֲרֹ֥ן וּבָנָ֖יו מִמֶּ֑נּוּ אֶת־יְדֵיהֶ֖ם וְאֶת־רַגְלֵיהֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "בְּבֹאָ֞ם אֶל־אֹ֧הֶל מוֹעֵ֛ד יִרְחֲצוּ־מַ֖יִם וְלֹ֣א יָמֻ֑תוּ א֣וֹ בְגִשְׁתָּ֤ם אֶל־הַמִּזְבֵּ֨חַ֙ לְשָׁרֵ֔ת לְהַקְטִ֥יר אִשֶּׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "כא",
            "txt": "וְרָֽחֲצ֛וּ יְדֵיהֶ֥ם וְרַגְלֵיהֶ֖ם וְלֹ֣א יָמֻ֑תוּ וְהָֽיְתָ֨ה לָהֶ֧ם חָק־עוֹלָ֛ם ל֥וֹ וּלְזַרְע֖וֹ לְדֹֽרֹתָֽם׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כג",
            "txt": "וְאַתָּ֣ה קַח־לְךָ֮ בְּשָׂמִ֣ים רֹאשׁ֒ מָר־דְּרוֹר֙ חֲמֵ֣שׁ מֵא֔וֹת וְקִנְּמָן־בֶּ֥שֶׂם מַֽחֲצִית֖וֹ חֲמִשִּׁ֣ים וּמָאתָ֑יִם וּקְנֵה־בֹ֖שֶׂם חֲמִשִּׁ֥ים וּמָאתָֽיִם׃"
          },
          {
            "num": "כד",
            "txt": "וְקִדָּ֕ה חֲמֵ֥שׁ מֵא֖וֹת בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ וְשֶׁ֥מֶן זַ֖יִת הִֽין׃"
          },
          {
            "num": "כה",
            "txt": "וְעָשִׂ֣יתָ אֹת֗וֹ שֶׁ֚מֶן מִשְׁחַת־קֹ֔דֶשׁ רֹ֥קַח מִרְקַ֖חַת מַֽעֲשֵׂ֣ה רֹקֵ֑חַ שֶׁ֥מֶן מִשְׁחַת־קֹ֖דֶשׁ יִֽהְיֶֽה׃"
          },
          {
            "num": "כו",
            "txt": "וּמָֽשַׁחְתָּ֥ ב֖וֹ אֶת־אֹ֣הֶל מוֹעֵ֑ד וְאֵ֖ת אֲר֥וֹן הָֽעֵדֻֽת׃"
          },
          {
            "num": "כז",
            "txt": "וְאֶת־הַשֻּׁלְחָן֙ וְאֶת־כָּל־כֵּלָ֔יו וְאֶת־הַמְּנֹרָ֖ה וְאֶת־כֵּלֶ֑יהָ וְאֵ֖ת מִזְבַּ֥ח הַקְּטֹֽרֶת׃"
          },
          {
            "num": "כח",
            "txt": "וְאֶת־מִזְבַּ֥ח הָֽעֹלָ֖ה וְאֶת־כָּל־כֵּלָ֑יו וְאֶת־הַכִּיֹּ֖ר וְאֶת־כַּנּֽוֹ׃"
          },
          {
            "num": "כט",
            "txt": "וְקִדַּשְׁתָּ֣ אֹתָ֔ם וְהָי֖וּ קֹ֣דֶשׁ קָֽדָשִׁ֑ים כָּל־הַנֹּגֵ֥עַ בָּהֶ֖ם יִקְדָּֽשׁ׃"
          },
          {
            "num": "ל",
            "txt": "וְאֶת־אַֽהֲרֹ֥ן וְאֶת־בָּנָ֖יו תִּמְשָׁ֑ח וְקִדַּשְׁתָּ֥ אֹתָ֖ם לְכַהֵ֥ן לִֽי׃"
          },
          {
            "num": "לא",
            "txt": "וְאֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל תְּדַבֵּ֣ר לֵאמֹ֑ר שֶׁ֠מֶן מִשְׁחַת־קֹ֨דֶשׁ יִֽהְיֶ֥ה זֶ֛ה לִ֖י לְדֹרֹֽתֵיכֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "עַל־בְּשַׂ֤ר אָדָם֙ לֹ֣א יִיסָ֔ךְ וּ֨בְמַתְכֻּנְתּ֔וֹ לֹ֥א תַֽעֲשׂ֖וּ כָּמֹ֑הוּ קֹ֣דֶשׁ ה֔וּא קֹ֖דֶשׁ יִֽהְיֶ֥ה לָכֶֽם׃"
          },
          {
            "num": "לג",
            "txt": "אִ֚ישׁ אֲשֶׁ֣ר יִרְקַ֣ח כָּמֹ֔הוּ וַֽאֲשֶׁ֥ר יִתֵּ֛ן מִמֶּ֖נּוּ עַל־זָ֑ר וְנִכְרַ֖ת מֵֽעַמָּֽיו׃ {ס}"
          },
          {
            "num": "לד",
            "txt": "וַיֹּאמֶר֩ יְהוָ֨ה אֶל־מֹשֶׁ֜ה קַח־לְךָ֣ סַמִּ֗ים נָטָ֤ף ׀ וּשְׁחֵ֨לֶת֙ וְחֶלְבְּנָ֔ה סַמִּ֖ים וּלְבֹנָ֣ה זַכָּ֑ה בַּ֥ד בְּבַ֖ד יִֽהְיֶֽה׃"
          },
          {
            "num": "לה",
            "txt": "וְעָשִׂ֤יתָ אֹתָהּ֙ קְטֹ֔רֶת רֹ֖קַח מַֽעֲשֵׂ֣ה רוֹקֵ֑חַ מְמֻלָּ֖ח טָה֥וֹר קֹֽדֶשׁ׃"
          },
          {
            "num": "לו",
            "txt": "וְשָֽׁחַקְתָּ֣ מִמֶּנָּה֮ הָדֵק֒ וְנָֽתַתָּ֨ה מִמֶּ֜נָּה לִפְנֵ֤י הָֽעֵדֻת֙ בְּאֹ֣הֶל מוֹעֵ֔ד אֲשֶׁ֛ר אִוָּעֵ֥ד לְךָ֖ שָׁ֑מָּה קֹ֥דֶשׁ קָֽדָשִׁ֖ים תִּֽהְיֶ֥ה לָכֶֽם׃"
          },
          {
            "num": "לז",
            "txt": "וְהַקְּטֹ֨רֶת֙ אֲשֶׁ֣ר תַּֽעֲשֶׂ֔ה בְּמַ֨תְכֻּנְתָּ֔הּ לֹ֥א תַֽעֲשׂ֖וּ לָכֶ֑ם קֹ֛דֶשׁ תִּֽהְיֶ֥ה לְךָ֖ לַֽיהוָֽה׃"
          },
          {
            "num": "לח",
            "txt": "אִ֛ישׁ אֲשֶׁר־יַֽעֲשֶׂ֥ה כָמ֖וֹהָ לְהָרִ֣יחַ בָּ֑הּ וְנִכְרַ֖ת מֵֽעַמָּֽיו׃ {ס}"
          }
        ]
      },
      {
        "num": "לא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "רְאֵ֖ה קָרָ֣אתִֽי בְשֵׁ֑ם בְּצַלְאֵ֛ל בֶּן־אוּרִ֥י בֶן־ח֖וּר לְמַטֵּ֥ה יְהוּדָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וָֽאֲמַלֵּ֥א אֹת֖וֹ ר֣וּחַ אֱלֹהִ֑ים בְּחָכְמָ֛ה וּבִתְבוּנָ֥ה וּבְדַ֖עַת וּבְכָל־מְלָאכָֽה׃"
          },
          {
            "num": "ד",
            "txt": "לַחְשֹׁ֖ב מַֽחֲשָׁבֹ֑ת לַֽעֲשׂ֛וֹת בַּזָּהָ֥ב וּבַכֶּ֖סֶף וּבַנְּחֹֽשֶׁת׃"
          },
          {
            "num": "ה",
            "txt": "וּבַֽחֲרֹ֥שֶׁת אֶ֛בֶן לְמַלֹּ֖את וּבַֽחֲרֹ֣שֶׁת עֵ֑ץ לַֽעֲשׂ֖וֹת בְּכָל־מְלָאכָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וַֽאֲנִ֞י הִנֵּ֧ה נָתַ֣תִּי אִתּ֗וֹ אֵ֣ת אָֽהֳלִיאָ֞ב בֶּן־אֲחִֽיסָמָךְ֙ לְמַטֵּה־דָ֔ן וּבְלֵ֥ב כָּל־חֲכַם־לֵ֖ב נָתַ֣תִּי חָכְמָ֑ה וְעָשׂ֕וּ אֵ֖ת כָּל־אֲשֶׁ֥ר צִוִּיתִֽךָ׃"
          },
          {
            "num": "ז",
            "txt": "אֵ֣ת ׀ אֹ֣הֶל מוֹעֵ֗ד וְאֶת־הָֽאָרֹן֙ לָֽעֵדֻ֔ת וְאֶת־הַכַּפֹּ֖רֶת אֲשֶׁ֣ר עָלָ֑יו וְאֵ֖ת כָּל־כְּלֵ֥י הָאֹֽהֶל׃"
          },
          {
            "num": "ח",
            "txt": "וְאֶת־הַשֻּׁלְחָן֙ וְאֶת־כֵּלָ֔יו וְאֶת־הַמְּנֹרָ֥ה הַטְּהֹרָ֖ה וְאֶת־כָּל־כֵּלֶ֑יהָ וְאֵ֖ת מִזְבַּ֥ח הַקְּטֹֽרֶת׃"
          },
          {
            "num": "ט",
            "txt": "וְאֶת־מִזְבַּ֥ח הָֽעֹלָ֖ה וְאֶת־כָּל־כֵּלָ֑יו וְאֶת־הַכִּיּ֖וֹר וְאֶת־כַּנּֽוֹ׃"
          },
          {
            "num": "י",
            "txt": "וְאֵ֖ת בִּגְדֵ֣י הַשְּׂרָ֑ד וְאֶת־בִּגְדֵ֤י הַקֹּ֨דֶשׁ֙ לְאַֽהֲרֹ֣ן הַכֹּהֵ֔ן וְאֶת־בִּגְדֵ֥י בָנָ֖יו לְכַהֵֽן׃"
          },
          {
            "num": "יא",
            "txt": "וְאֵ֨ת שֶׁ֧מֶן הַמִּשְׁחָ֛ה וְאֶת־קְטֹ֥רֶת הַסַּמִּ֖ים לַקֹּ֑דֶשׁ כְּכֹ֥ל אֲשֶׁר־צִוִּיתִ֖ךָ יַֽעֲשֽׂוּ׃ {פ}"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יג",
            "txt": "וְאַתָּ֞ה דַּבֵּ֨ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ לֵאמֹ֔ר אַ֥ךְ אֶת־שַׁבְּתֹתַ֖י תִּשְׁמֹ֑רוּ כִּי֩ א֨וֹת הִ֜וא בֵּינִ֤י וּבֵֽינֵיכֶם֙ לְדֹרֹ֣תֵיכֶ֔ם לָדַ֕עַת כִּ֛י אֲנִ֥י יְהוָ֖ה מְקַדִּשְׁכֶֽם׃"
          },
          {
            "num": "יד",
            "txt": "וּשְׁמַרְתֶּם֙ אֶת־הַשַּׁבָּ֔ת כִּ֛י קֹ֥דֶשׁ הִ֖וא לָכֶ֑ם מְחַֽלְלֶ֨יהָ֙ מ֣וֹת יוּמָ֔ת כִּ֗י כָּל־הָֽעֹשֶׂ֥ה בָהּ֙ מְלָאכָ֔ה וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מִקֶּ֥רֶב עַמֶּֽיהָ׃"
          },
          {
            "num": "טו",
            "txt": "שֵׁ֣שֶׁת יָמִים֮ יֵֽעָשֶׂ֣ה מְלָאכָה֒ וּבַיּ֣וֹם הַשְּׁבִיעִ֗י שַׁבַּ֧ת שַׁבָּת֛וֹן קֹ֖דֶשׁ לַֽיהוָ֑ה כָּל־הָֽעֹשֶׂ֧ה מְלָאכָ֛ה בְּי֥וֹם הַשַּׁבָּ֖ת מ֥וֹת יוּמָֽת׃"
          },
          {
            "num": "טז",
            "txt": "וְשָֽׁמְר֥וּ בְנֵֽי־יִשְׂרָאֵ֖ל אֶת־הַשַּׁבָּ֑ת לַֽעֲשׂ֧וֹת אֶת־הַשַּׁבָּ֛ת לְדֹֽרֹתָ֖ם בְּרִ֥ית עוֹלָֽם׃"
          },
          {
            "num": "יז",
            "txt": "בֵּינִ֗י וּבֵין֙ בְּנֵ֣י יִשְׂרָאֵ֔ל א֥וֹת הִ֖וא לְעֹלָ֑ם כִּי־שֵׁ֣שֶׁת יָמִ֗ים עָשָׂ֤ה יְהוָה֙ אֶת־הַשָּׁמַ֣יִם וְאֶת־הָאָ֔רֶץ וּבַיּוֹם֙ הַשְּׁבִיעִ֔י שָׁבַ֖ת וַיִּנָּפַֽשׁ׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "וַיִּתֵּ֣ן אֶל־מֹשֶׁ֗ה כְּכַלֹּתוֹ֙ לְדַבֵּ֤ר אִתּוֹ֙ בְּהַ֣ר סִינַ֔י שְׁנֵ֖י לֻחֹ֣ת הָֽעֵדֻ֑ת לֻחֹ֣ת אֶ֔בֶן כְּתֻבִ֖ים בְּאֶצְבַּ֥ע אֱלֹהִֽים׃"
          }
        ]
      },
      {
        "num": "לב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּ֣רְא הָעָ֔ם כִּֽי־בֹשֵׁ֥שׁ מֹשֶׁ֖ה לָרֶ֣דֶת מִן־הָהָ֑ר וַיִּקָּהֵ֨ל הָעָ֜ם עַֽל־אַהֲרֹ֗ן וַיֹּֽאמְר֤וּ אֵלָיו֙ ק֣וּם ׀ עֲשֵׂה־לָ֣נוּ אֱלֹהִ֗ים אֲשֶׁ֤ר יֵֽלְכוּ֙ לְפָנֵ֔ינוּ כִּי־זֶ֣ה ׀ מֹשֶׁ֣ה הָאִ֗ישׁ אֲשֶׁ֤ר הֶֽעֱלָ֨נוּ֙ מֵאֶ֣רֶץ מִצְרַ֔יִם לֹ֥א יָדַ֖עְנוּ מֶה־הָ֥יָה לֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֤אמֶר אֲלֵהֶם֙ אַֽהֲרֹ֔ן פָּֽרְקוּ֙ נִזְמֵ֣י הַזָּהָ֔ב אֲשֶׁר֙ בְּאָזְנֵ֣י נְשֵׁיכֶ֔ם בְּנֵיכֶ֖ם וּבְנֹֽתֵיכֶ֑ם וְהָבִ֖יאוּ אֵלָֽי׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּתְפָּֽרְקוּ֙ כָּל־הָעָ֔ם אֶת־נִזְמֵ֥י הַזָּהָ֖ב אֲשֶׁ֣ר בְּאָזְנֵיהֶ֑ם וַיָּבִ֖יאוּ אֶֽל־אַהֲרֹֽן׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּקַּ֣ח מִיָּדָ֗ם וַיָּ֤צַר אֹתוֹ֙ בַּחֶ֔רֶט וַֽיַּעֲשֵׂ֖הוּ עֵ֣גֶל מַסֵּכָ֑ה וַיֹּ֣אמְר֔וּ אֵ֤לֶּה אֱלֹהֶ֨יךָ֙ יִשְׂרָאֵ֔ל אֲשֶׁ֥ר הֶֽעֱל֖וּךָ מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ה",
            "txt": "וַיַּ֣רְא אַֽהֲרֹ֔ן וַיִּ֥בֶן מִזְבֵּ֖חַ לְפָנָ֑יו וַיִּקְרָ֤א אַֽהֲרֹן֙ וַיֹּאמַ֔ר חַ֥ג לַֽיהוָ֖ה מָחָֽר׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּשְׁכִּ֨ימוּ֙ מִֽמָּחֳרָ֔ת וַיַּֽעֲל֣וּ עֹלֹ֔ת וַיַּגִּ֖שׁוּ שְׁלָמִ֑ים וַיֵּ֤שֶׁב הָעָם֙ לֶֽאֱכֹ֣ל וְשָׁת֔וֹ וַיָּקֻ֖מוּ לְצַחֵֽק׃ {פ}"
          },
          {
            "num": "ז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֑ה לֶךְ־רֵ֕ד כִּ֚י שִׁחֵ֣ת עַמְּךָ֔ אֲשֶׁ֥ר הֶֽעֱלֵ֖יתָ מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ח",
            "txt": "סָ֣רוּ מַהֵ֗ר מִן־הַדֶּ֨רֶךְ֙ אֲשֶׁ֣ר צִוִּיתִ֔ם עָשׂ֣וּ לָהֶ֔ם עֵ֖גֶל מַסֵּכָ֑ה וַיִּשְׁתַּֽחֲווּ־לוֹ֙ וַיִּזְבְּחוּ־ל֔וֹ וַיֹּ֣אמְר֔וּ אֵ֤לֶּה אֱלֹהֶ֨יךָ֙ יִשְׂרָאֵ֔ל אֲשֶׁ֥ר הֶֽעֱל֖וּךָ מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֑ה רָאִ֨יתִי֙ אֶת־הָעָ֣ם הַזֶּ֔ה וְהִנֵּ֥ה עַם־קְשֵׁה־עֹ֖רֶף הֽוּא׃"
          },
          {
            "num": "י",
            "txt": "וְעַתָּה֙ הַנִּ֣יחָה לִּ֔י וְיִֽחַר־אַפִּ֥י בָהֶ֖ם וַֽאֲכַלֵּ֑ם וְאֶֽעֱשֶׂ֥ה אֽוֹתְךָ֖ לְג֥וֹי גָּדֽוֹל׃"
          },
          {
            "num": "יא",
            "txt": "וַיְחַ֣ל מֹשֶׁ֔ה אֶת־פְּנֵ֖י יְהוָ֣ה אֱלֹהָ֑יו וַיֹּ֗אמֶר לָמָ֤ה יְהוָה֙ יֶֽחֱרֶ֤ה אַפְּךָ֙ בְּעַמֶּ֔ךָ אֲשֶׁ֤ר הוֹצֵ֨אתָ֙ מֵאֶ֣רֶץ מִצְרַ֔יִם בְּכֹ֥חַ גָּד֖וֹל וּבְיָ֥ד חֲזָקָֽה׃"
          },
          {
            "num": "יב",
            "txt": "לָמָּה֩ יֹֽאמְר֨וּ מִצְרַ֜יִם לֵאמֹ֗ר בְּרָעָ֤ה הֽוֹצִיאָם֙ לַֽהֲרֹ֤ג אֹתָם֙ בֶּֽהָרִ֔ים וּ֨לְכַלֹּתָ֔ם מֵעַ֖ל פְּנֵ֣י הָֽאֲדָמָ֑ה שׁ֚וּב מֵֽחֲר֣וֹן אַפֶּ֔ךָ וְהִנָּחֵ֥ם עַל־הָֽרָעָ֖ה לְעַמֶּֽךָ׃"
          },
          {
            "num": "יג",
            "txt": "זְכֹ֡ר לְאַבְרָהָם֩ לְיִצְחָ֨ק וּלְיִשְׂרָאֵ֜ל עֲבָדֶ֗יךָ אֲשֶׁ֨ר נִשְׁבַּ֣עְתָּ לָהֶם֮ בָּךְ֒ וַתְּדַבֵּ֣ר אֲלֵהֶ֔ם אַרְבֶּה֙ אֶֽת־זַרְעֲכֶ֔ם כְּכֽוֹכְבֵ֖י הַשָּׁמָ֑יִם וְכָל־הָאָ֨רֶץ הַזֹּ֜את אֲשֶׁ֣ר אָמַ֗רְתִּי אֶתֵּן֙ לְזַרְעֲכֶ֔ם וְנָֽחֲל֖וּ לְעֹלָֽם׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּנָּ֖חֶם יְהוָ֑ה עַל־הָ֣רָעָ֔ה אֲשֶׁ֥ר דִּבֶּ֖ר לַֽעֲשׂ֥וֹת לְעַמּֽוֹ׃ {פ}"
          },
          {
            "num": "טו",
            "txt": "וַיִּ֜פֶן וַיֵּ֤רֶד מֹשֶׁה֙ מִן־הָהָ֔ר וּשְׁנֵ֛י לֻחֹ֥ת הָֽעֵדֻ֖ת בְּיָד֑וֹ לֻחֹ֗ת כְּתֻבִים֙ מִשְּׁנֵ֣י עֶבְרֵיהֶ֔ם מִזֶּ֥ה וּמִזֶּ֖ה הֵ֥ם כְּתֻבִֽים׃"
          },
          {
            "num": "טז",
            "txt": "וְהַ֨לֻּחֹ֔ת מַֽעֲשֵׂ֥ה אֱלֹהִ֖ים הֵ֑מָּה וְהַמִּכְתָּ֗ב מִכְתַּ֤ב אֱלֹהִים֙ ה֔וּא חָר֖וּת עַל־הַלֻּחֹֽת׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּשְׁמַ֧ע יְהוֹשֻׁ֛עַ אֶת־ק֥וֹל הָעָ֖ם בְּרֵעֹ֑ה וַיֹּ֨אמֶר֙ אֶל־מֹשֶׁ֔ה ק֥וֹל מִלְחָמָ֖ה בַּֽמַּחֲנֶה׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֗אמֶר אֵ֥ין קוֹל֙ עֲנ֣וֹת גְּבוּרָ֔ה וְאֵ֥ין ק֖וֹל עֲנ֣וֹת חֲלוּשָׁ֑ה ק֣וֹל עַנּ֔וֹת אָֽנֹכִ֖י שֹׁמֵֽעַ׃"
          },
          {
            "num": "יט",
            "txt": "וַֽיְהִ֗י כַּֽאֲשֶׁ֤ר קָרַב֙ אֶל־הַֽמַּחֲנֶ֔ה וַיַּ֥רְא אֶת־הָעֵ֖גֶל וּמְחֹלֹ֑ת וַיִּֽחַר־אַ֣ף מֹשֶׁ֗ה וַיַּשְׁלֵ֤ךְ מִיָּדָו֙ אֶת־הַלֻּחֹ֔ת וַיְשַׁבֵּ֥ר אֹתָ֖ם תַּ֥חַת הָהָֽר׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּקַּ֞ח אֶת־הָעֵ֨גֶל אֲשֶׁ֤ר עָשׂוּ֙ וַיִּשְׂרֹ֣ף בָּאֵ֔שׁ וַיִּטְחַ֖ן עַ֣ד אֲשֶׁר־דָּ֑ק וַיִּ֨זֶר֙ עַל־פְּנֵ֣י הַמַּ֔יִם וַיַּ֖שְׁקְ אֶת־בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֤אמֶר מֹשֶׁה֙ אֶֽל־אַהֲרֹ֔ן מֶֽה־עָשָׂ֥ה לְךָ֖ הָעָ֣ם הַזֶּ֑ה כִּֽי־הֵבֵ֥אתָ עָלָ֖יו חֲטָאָ֥ה גְדֹלָֽה׃"
          },
          {
            "num": "כב",
            "txt": "וַיֹּ֣אמֶר אַֽהֲרֹ֔ן אַל־יִ֥חַר אַ֖ף אֲדֹנִ֑י אַתָּה֙ יָדַ֣עְתָּ אֶת־הָעָ֔ם כִּ֥י בְרָ֖ע הֽוּא׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֣אמְרוּ לִ֔י עֲשֵׂה־לָ֣נוּ אֱלֹהִ֔ים אֲשֶׁ֥ר יֵֽלְכ֖וּ לְפָנֵ֑ינוּ כִּי־זֶ֣ה ׀ מֹשֶׁ֣ה הָאִ֗ישׁ אֲשֶׁ֤ר הֶֽעֱלָ֨נוּ֙ מֵאֶ֣רֶץ מִצְרַ֔יִם לֹ֥א יָדַ֖עְנוּ מֶה־הָ֥יָה לֽוֹ׃"
          },
          {
            "num": "כד",
            "txt": "וָֽאֹמַ֤ר לָהֶם֙ לְמִ֣י זָהָ֔ב הִתְפָּרָ֖קוּ וַיִּתְּנוּ־לִ֑י וָֽאַשְׁלִכֵ֣הוּ בָאֵ֔שׁ וַיֵּצֵ֖א הָעֵ֥גֶל הַזֶּֽה׃"
          },
          {
            "num": "כה",
            "txt": "וַיַּ֤רְא מֹשֶׁה֙ אֶת־הָעָ֔ם כִּ֥י פָרֻ֖עַ ה֑וּא כִּֽי־פְרָעֹ֣ה אַֽהֲרֹ֔ן לְשִׁמְצָ֖ה בְּקָֽמֵיהֶֽם׃"
          },
          {
            "num": "כו",
            "txt": "וַיַּֽעֲמֹ֤ד מֹשֶׁה֙ בְּשַׁ֣עַר הַֽמַּחֲנֶ֔ה וַיֹּ֕אמֶר מִ֥י לַֽיהוָ֖ה אֵלָ֑י וַיֵּאָֽסְפ֥וּ אֵלָ֖יו כָּל־בְּנֵ֥י לֵוִֽי׃"
          },
          {
            "num": "כז",
            "txt": "וַיֹּ֣אמֶר לָהֶ֗ם כֹּֽה־אָמַ֤ר יְהוָה֙ אֱלֹהֵ֣י יִשְׂרָאֵ֔ל שִׂ֥ימוּ אִישׁ־חַרְבּ֖וֹ עַל־יְרֵכ֑וֹ עִבְר֨וּ וָשׁ֜וּבוּ מִשַּׁ֤עַר לָשַׁ֨עַר֙ בַּֽמַּחֲנֶ֔ה וְהִרְג֧וּ אִֽישׁ־אֶת־אָחִ֛יו וְאִ֥ישׁ אֶת־רֵעֵ֖הוּ וְאִ֥ישׁ אֶת־קְרֹבֽוֹ׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּֽעֲשׂ֥וּ בְנֵֽי־לֵוִ֖י כִּדְבַ֣ר מֹשֶׁ֑ה וַיִּפֹּ֤ל מִן־הָעָם֙ בַּיּ֣וֹם הַה֔וּא כִּשְׁלֹ֥שֶׁת אַלְפֵ֖י אִֽישׁ׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֗ה מִלְא֨וּ יֶדְכֶ֤ם הַיּוֹם֙ לַֽיהוָ֔ה כִּ֛י אִ֥ישׁ בִּבְנ֖וֹ וּבְאָחִ֑יו וְלָתֵ֧ת עֲלֵיכֶ֛ם הַיּ֖וֹם בְּרָכָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וַֽיְהִי֙ מִֽמָּחֳרָ֔ת וַיֹּ֤אמֶר מֹשֶׁה֙ אֶל־הָעָ֔ם אַתֶּ֥ם חֲטָאתֶ֖ם חֲטָאָ֣ה גְדֹלָ֑ה וְעַתָּה֙ אֶֽעֱלֶ֣ה אֶל־יְהוָ֔ה אוּלַ֥י אֲכַפְּרָ֖ה בְּעַ֥ד חַטַּאתְכֶֽם׃"
          },
          {
            "num": "לא",
            "txt": "וַיָּ֧שָׁב מֹשֶׁ֛ה אֶל־יְהוָ֖ה וַיֹּאמַ֑ר אָ֣נָּ֗א חָטָ֞א הָעָ֤ם הַזֶּה֙ חֲטָאָ֣ה גְדֹלָ֔ה וַיַּֽעֲשׂ֥וּ לָהֶ֖ם אֱלֹהֵ֥י זָהָֽב׃"
          },
          {
            "num": "לב",
            "txt": "וְעַתָּ֖ה אִם־תִּשָּׂ֣א חַטָּאתָ֑ם וְאִם־אַ֕יִן מְחֵ֣נִי נָ֔א מִֽסִּפְרְךָ֖ אֲשֶׁ֥ר כָּתָֽבְתָּ׃"
          },
          {
            "num": "לג",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֑ה מִ֚י אֲשֶׁ֣ר חָֽטָא־לִ֔י אֶמְחֶ֖נּוּ מִסִּפְרִֽי׃"
          },
          {
            "num": "לד",
            "txt": "וְעַתָּ֞ה לֵ֣ךְ ׀ נְחֵ֣ה אֶת־הָעָ֗ם אֶ֤ל אֲשֶׁר־דִּבַּ֨רְתִּי֙ לָ֔ךְ הִנֵּ֥ה מַלְאָכִ֖י יֵלֵ֣ךְ לְפָנֶ֑יךָ וּבְי֣וֹם פָּקְדִ֔י וּפָֽקַדְתִּ֥י עֲלֵהֶ֖ם חַטָּאתָֽם׃"
          },
          {
            "num": "לה",
            "txt": "וַיִּגֹּ֥ף יְהוָ֖ה אֶת־הָעָ֑ם עַ֚ל אֲשֶׁ֣ר עָשׂ֣וּ אֶת־הָעֵ֔גֶל אֲשֶׁ֥ר עָשָׂ֖ה אַֽהֲרֹֽן׃ {ס}"
          }
        ]
      },
      {
        "num": "לג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֨ר יְהוָ֤ה אֶל־מֹשֶׁה֙ לֵ֣ךְ עֲלֵ֣ה מִזֶּ֔ה אַתָּ֣ה וְהָעָ֔ם אֲשֶׁ֥ר הֶֽעֱלִ֖יתָ מֵאֶ֣רֶץ מִצְרָ֑יִם אֶל־הָאָ֗רֶץ אֲשֶׁ֣ר נִ֠שְׁבַּעְתִּי לְאַבְרָהָ֨ם לְיִצְחָ֤ק וּֽלְיַעֲקֹב֙ לֵאמֹ֔ר לְזַרְעֲךָ֖ אֶתְּנֶֽנָּה׃"
          },
          {
            "num": "ב",
            "txt": "וְשָֽׁלַחְתִּ֥י לְפָנֶ֖יךָ מַלְאָ֑ךְ וְגֵֽרַשְׁתִּ֗י אֶת־הַֽכְּנַעֲנִי֙ הָֽאֱמֹרִ֔י וְהַֽחִתִּי֙ וְהַפְּרִזִּ֔י הַֽחִוִּ֖י וְהַיְבוּסִֽי׃"
          },
          {
            "num": "ג",
            "txt": "אֶל־אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָ֑שׁ כִּי֩ לֹ֨א אֶֽעֱלֶ֜ה בְּקִרְבְּךָ֗ כִּ֤י עַם־קְשֵׁה־עֹ֨רֶף֙ אַ֔תָּה פֶּן־אֲכֶלְךָ֖ בַּדָּֽרֶךְ׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּשְׁמַ֣ע הָעָ֗ם אֶת־הַדָּבָ֥ר הָרָ֛ע הַזֶּ֖ה וַיִּתְאַבָּ֑לוּ וְלֹא־שָׁ֛תוּ אִ֥ישׁ עֶדְי֖וֹ עָלָֽיו׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה אֱמֹ֤ר אֶל־בְּנֵֽי־יִשְׂרָאֵל֙ אַתֶּ֣ם עַם־קְשֵׁה־עֹ֔רֶף רֶ֧גַע אֶחָ֛ד אֶֽעֱלֶ֥ה בְקִרְבְּךָ֖ וְכִלִּיתִ֑יךָ וְעַתָּ֗ה הוֹרֵ֤ד עֶדְיְךָ֙ מֵֽעָלֶ֔יךָ וְאֵֽדְעָ֖ה מָ֥ה אֶֽעֱשֶׂה־לָּֽךְ׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּֽתְנַצְּל֧וּ בְנֵֽי־יִשְׂרָאֵ֛ל אֶת־עֶדְיָ֖ם מֵהַ֥ר חוֹרֵֽב׃"
          },
          {
            "num": "ז",
            "txt": "וּמֹשֶׁה֩ יִקַּ֨ח אֶת־הָאֹ֜הֶל וְנָֽטָה־ל֣וֹ ׀ מִח֣וּץ לַֽמַּחֲנֶ֗ה הַרְחֵק֙ מִן־הַֽמַּחֲנֶ֔ה וְקָ֥רָא ל֖וֹ אֹ֣הֶל מוֹעֵ֑ד וְהָיָה֙ כָּל־מְבַקֵּ֣שׁ יְהוָ֔ה יֵצֵא֙ אֶל־אֹ֣הֶל מוֹעֵ֔ד אֲשֶׁ֖ר מִח֥וּץ לַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְהָיָ֗ה כְּצֵ֤את מֹשֶׁה֙ אֶל־הָאֹ֔הֶל יָק֨וּמוּ֙ כָּל־הָעָ֔ם וְנִ֨צְּב֔וּ אִ֖ישׁ פֶּ֣תַח אָֽהֳל֑וֹ וְהִבִּ֨יטוּ֙ אַֽחֲרֵ֣י מֹשֶׁ֔ה עַד־בֹּא֖וֹ הָאֹֽהֱלָה׃"
          },
          {
            "num": "ט",
            "txt": "וְהָיָ֗ה כְּבֹ֤א מֹשֶׁה֙ הָאֹ֔הֱלָה יֵרֵד֙ עַמּ֣וּד הֶֽעָנָ֔ן וְעָמַ֖ד פֶּ֣תַח הָאֹ֑הֶל וְדִבֶּ֖ר עִם־מֹשֶֽׁה׃"
          },
          {
            "num": "י",
            "txt": "וְרָאָ֤ה כָל־הָעָם֙ אֶת־עַמּ֣וּד הֶֽעָנָ֔ן עֹמֵ֖ד פֶּ֣תַח הָאֹ֑הֶל וְקָ֤ם כָּל־הָעָם֙ וְהִֽשְׁתַּחֲו֔וּ אִ֖ישׁ פֶּ֥תַח אָֽהֳלֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וְדִבֶּ֨ר יְהוָ֤ה אֶל־מֹשֶׁה֙ פָּנִ֣ים אֶל־פָּנִ֔ים כַּֽאֲשֶׁ֛ר יְדַבֵּ֥ר אִ֖ישׁ אֶל־רֵעֵ֑הוּ וְשָׁב֙ אֶל־הַֽמַּחֲנֶ֔ה וּמְשָׁ֨רְת֜וֹ יְהוֹשֻׁ֤עַ בִּן־נוּן֙ נַ֔עַר לֹ֥א יָמִ֖ישׁ מִתּ֥וֹךְ הָאֹֽהֶל׃ {פ}"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶל־יְהוָ֗ה רְ֠אֵה אַתָּ֞ה אֹמֵ֤ר אֵלַי֙ הַ֚עַל אֶת־הָעָ֣ם הַזֶּ֔ה וְאַתָּה֙ לֹ֣א הֽוֹדַעְתַּ֔נִי אֵ֥ת אֲשֶׁר־תִּשְׁלַ֖ח עִמִּ֑י וְאַתָּ֤ה אָמַ֨רְתָּ֙ יְדַעְתִּ֣יךָֽ בְשֵׁ֔ם וְגַם־מָצָ֥אתָ חֵ֖ן בְּעֵינָֽי׃"
          },
          {
            "num": "יג",
            "txt": "וְעַתָּ֡ה אִם־נָא֩ מָצָ֨אתִי חֵ֜ן בְּעֵינֶ֗יךָ הֽוֹדִעֵ֤נִי נָא֙ אֶת־דְּרָכֶ֔ךָ וְאֵדָ֣עֲךָ֔ לְמַ֥עַן אֶמְצָא־חֵ֖ן בְּעֵינֶ֑יךָ וּרְאֵ֕ה כִּ֥י עַמְּךָ֖ הַגּ֥וֹי הַזֶּֽה׃"
          },
          {
            "num": "יד",
            "txt": "וַיֹּאמַ֑ר פָּנַ֥י יֵלֵ֖כוּ וַֽהֲנִחֹ֥תִי לָֽךְ׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֖אמֶר אֵלָ֑יו אִם־אֵ֤ין פָּנֶ֨יךָ֙ הֹֽלְכִ֔ים אַֽל־תַּעֲלֵ֖נוּ מִזֶּֽה׃"
          },
          {
            "num": "טז",
            "txt": "וּבַמֶּ֣ה ׀ יִוָּדַ֣ע אֵפ֗וֹא כִּֽי־מָצָ֨אתִי חֵ֤ן בְּעֵינֶ֨יךָ֙ אֲנִ֣י וְעַמֶּ֔ךָ הֲל֖וֹא בְּלֶכְתְּךָ֣ עִמָּ֑נוּ וְנִפְלִ֨ינוּ֙ אֲנִ֣י וְעַמְּךָ֔ מִכָּ֨ל־הָעָ֔ם אֲשֶׁ֖ר עַל־פְּנֵ֥י הָֽאֲדָמָֽה׃ {פ}"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה גַּ֣ם אֶת־הַדָּבָ֥ר הַזֶּ֛ה אֲשֶׁ֥ר דִּבַּ֖רְתָּ אֶֽעֱשֶׂ֑ה כִּֽי־מָצָ֤אתָ חֵן֙ בְּעֵינַ֔י וָאֵדָֽעֲךָ֖ בְּשֵֽׁם׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּאמַ֑ר הַרְאֵ֥נִי נָ֖א אֶת־כְּבֹדֶֽךָ׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֗אמֶר אֲנִ֨י אַֽעֲבִ֤יר כָּל־טוּבִי֙ עַל־פָּנֶ֔יךָ וְקָרָ֧אתִֽי בְשֵׁ֛ם יְהוָ֖ה לְפָנֶ֑יךָ וְחַנֹּתִי֙ אֶת־אֲשֶׁ֣ר אָחֹ֔ן וְרִֽחַמְתִּ֖י אֶת־אֲשֶׁ֥ר אֲרַחֵֽם׃"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֕אמֶר לֹ֥א תוּכַ֖ל לִרְאֹ֣ת אֶת־פָּנָ֑י כִּ֛י לֹֽא־יִרְאַ֥נִי הָֽאָדָ֖ם וָחָֽי׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֣אמֶר יְהוָ֔ה הִנֵּ֥ה מָק֖וֹם אִתִּ֑י וְנִצַּבְתָּ֖ עַל־הַצּֽוּר׃"
          },
          {
            "num": "כב",
            "txt": "וְהָיָה֙ בַּֽעֲבֹ֣ר כְּבֹדִ֔י וְשַׂמְתִּ֖יךָ בְּנִקְרַ֣ת הַצּ֑וּר וְשַׂכֹּתִ֥י כַפִּ֛י עָלֶ֖יךָ עַד־עָבְרִֽי׃"
          },
          {
            "num": "כג",
            "txt": "וַהֲסִֽרֹתִי֙ אֶת־כַּפִּ֔י וְרָאִ֖יתָ אֶת־אֲחֹרָ֑י וּפָנַ֖י לֹ֥א יֵֽרָאֽוּ׃ {פ}"
          }
        ]
      },
      {
        "num": "לד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה פְּסָל־לְךָ֛ שְׁנֵֽי־לֻחֹ֥ת אֲבָנִ֖ים כָּרִֽאשֹׁנִ֑ים וְכָֽתַבְתִּי֙ עַל־הַלֻּחֹ֔ת אֶ֨ת־הַדְּבָרִ֔ים אֲשֶׁ֥ר הָי֛וּ עַל־הַלֻּחֹ֥ת הָרִֽאשֹׁנִ֖ים אֲשֶׁ֥ר שִׁבַּֽרְתָּ׃"
          },
          {
            "num": "ב",
            "txt": "וֶֽהְיֵ֥ה נָכ֖וֹן לַבֹּ֑קֶר וְעָלִ֤יתָ בַבֹּ֨קֶר֙ אֶל־הַ֣ר סִינַ֔י וְנִצַּבְתָּ֥ לִ֛י שָׁ֖ם עַל־רֹ֥אשׁ הָהָֽר׃"
          },
          {
            "num": "ג",
            "txt": "וְאִישׁ֙ לֹֽא־יַעֲלֶ֣ה עִמָּ֔ךְ וְגַם־אִ֥ישׁ אַל־יֵרָ֖א בְּכָל־הָהָ֑ר גַּם־הַצֹּ֤אן וְהַבָּקָר֙ אַל־יִרְע֔וּ אֶל־מ֖וּל הָהָ֥ר הַהֽוּא׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּפְסֹ֡ל שְׁנֵֽי־לֻחֹ֨ת אֲבָנִ֜ים כָּרִֽאשֹׁנִ֗ים וַיַּשְׁכֵּ֨ם מֹשֶׁ֤ה בַבֹּ֨קֶר֙ וַיַּ֨עַל֙ אֶל־הַ֣ר סִינַ֔י כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֹת֑וֹ וַיִּקַּ֣ח בְּיָד֔וֹ שְׁנֵ֖י לֻחֹ֥ת אֲבָנִֽים׃"
          },
          {
            "num": "ה",
            "txt": "וַיֵּ֤רֶד יְהוָה֙ בֶּֽעָנָ֔ן וַיִּתְיַצֵּ֥ב עִמּ֖וֹ שָׁ֑ם וַיִּקְרָ֥א בְשֵׁ֖ם יְהוָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּֽעֲבֹ֨ר יְהוָ֥ה ׀ עַל־פָּנָיו֮ וַיִּקְרָא֒ יְהוָ֣ה ׀ יְהוָ֔ה אֵ֥ל רַח֖וּם וְחַנּ֑וּן אֶ֥רֶךְ אַפַּ֖יִם וְרַב־חֶ֥סֶד וֶֽאֱמֶֽת׃"
          },
          {
            "num": "ז",
            "txt": "צֵ֥ר חֶ֨סֶד֙ לָֽאֲלָפִ֔ים נֹשֵׂ֥א עָוֺ֛ן וָפֶ֖שַׁע וְחַטָּאָ֑ה וְנַקֵּה֙ לֹ֣א יְנַקֶּ֔ה פֹּקֵ֣ד ׀ עֲוֺ֣ן אָב֗וֹת עַל־בָּנִים֙ וְעַל־בְּנֵ֣י בָנִ֔ים עַל־שִׁלֵּשִׁ֖ים וְעַל־רִבֵּעִֽים׃"
          },
          {
            "num": "ח",
            "txt": "וַיְמַהֵ֖ר מֹשֶׁ֑ה וַיִּקֹּ֥ד אַ֖רְצָה וַיִּשְׁתָּֽחוּ׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֡אמֶר אִם־נָא֩ מָצָ֨אתִי חֵ֤ן בְּעֵינֶ֨יךָ֙ אֲדֹנָ֔י יֵֽלֶךְ־נָ֥א אֲדֹנָ֖י בְּקִרְבֵּ֑נוּ כִּ֤י עַם־קְשֵׁה־עֹ֨רֶף֙ ה֔וּא וְסָֽלַחְתָּ֛ לַֽעֲוֺנֵ֥נוּ וּלְחַטָּאתֵ֖נוּ וּנְחַלְתָּֽנוּ׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֗אמֶר הִנֵּ֣ה אָֽנֹכִי֮ כֹּרֵ֣ת בְּרִית֒ נֶ֤גֶד כָּֽל־עַמְּךָ֙ אֶֽעֱשֶׂ֣ה נִפְלָאֹ֔ת אֲשֶׁ֛ר לֹֽא־נִבְרְא֥וּ בְכָל־הָאָ֖רֶץ וּבְכָל־הַגּוֹיִ֑ם וְרָאָ֣ה כָל־הָ֠עָם אֲשֶׁר־אַתָּ֨ה בְקִרְבּ֜וֹ אֶת־מַֽעֲשֵׂ֤ה יְהוָה֙ כִּֽי־נוֹרָ֣א ה֔וּא אֲשֶׁ֥ר אֲנִ֖י עֹשֶׂ֥ה עִמָּֽךְ׃"
          },
          {
            "num": "יא",
            "txt": "שְׁמָ֨ר־לְךָ֔ אֵ֛ת אֲשֶׁ֥ר אָֽנֹכִ֖י מְצַוְּךָ֣ הַיּ֑וֹם הִנְנִ֧י גֹרֵ֣שׁ מִפָּנֶ֗יךָ אֶת־הָֽאֱמֹרִי֙ וְהַֽכְּנַעֲנִ֔י וְהַֽחִתִּי֙ וְהַפְּרִזִּ֔י וְהַֽחִוִּ֖י וְהַיְבוּסִֽי׃"
          },
          {
            "num": "יב",
            "txt": "הִשָּׁ֣מֶר לְךָ֗ פֶּן־תִּכְרֹ֤ת בְּרִית֙ לְיוֹשֵׁ֣ב הָאָ֔רֶץ אֲשֶׁ֥ר אַתָּ֖ה בָּ֣א עָלֶ֑יהָ פֶּן־יִֽהְיֶ֥ה לְמוֹקֵ֖שׁ בְּקִרְבֶּֽךָ׃"
          },
          {
            "num": "יג",
            "txt": "כִּ֤י אֶת־מִזְבְּחֹתָם֙ תִּתֹּצ֔וּן וְאֶת־מַצֵּֽבֹתָ֖ם תְּשַׁבֵּר֑וּן וְאֶת־אֲשֵׁרָ֖יו תִּכְרֹתֽוּן׃"
          },
          {
            "num": "יד",
            "txt": "כִּ֛י לֹ֥א תִֽשְׁתַּחֲוֶ֖ה לְאֵ֣ל אַחֵ֑"
          },
          {
            "num": "טו",
            "txt": "כִּ֤י יְהוָה֙ קַנָּ֣א שְׁמ֔וֹ אֵ֥ל קַנָּ֖א הֽוּא׃"
          },
          {
            "num": "טז",
            "txt": "פֶּן־תִּכְרֹ֥ת בְּרִ֖ית לְיוֹשֵׁ֣ב הָאָ֑רֶץ וְזָנ֣וּ ׀ אַֽחֲרֵ֣י אֱלֹֽהֵיהֶ֗ם וְזָֽבְחוּ֙ לֵאלֹ֣הֵיהֶ֔ם וְקָרָ֣א לְךָ֔ וְאָֽכַלְתָּ֖ מִזִּבְחֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "וְלָֽקַחְתָּ֥ מִבְּנֹתָ֖יו לְבָנֶ֑יךָ וְזָנ֣וּ בְנֹתָ֗יו אַֽחֲרֵי֙ אֱלֹ֣הֵיהֶ֔ן וְהִזְנוּ֙ אֶת־בָּנֶ֔יךָ אַֽחֲרֵ֖י אֱלֹֽהֵיהֶֽן׃"
          },
          {
            "num": "יח",
            "txt": "אֱלֹהֵ֥י מַסֵּכָ֖ה לֹ֥א תַֽעֲשֶׂה־לָּֽךְ׃"
          },
          {
            "num": "יט",
            "txt": "אֶת־חַ֣ג הַמַּצּוֹת֮ תִּשְׁמֹר֒ שִׁבְעַ֨ת יָמִ֜ים תֹּאכַ֤ל מַצּוֹת֙ אֲשֶׁ֣ר צִוִּיתִ֔ךָ לְמוֹעֵ֖ד חֹ֣דֶשׁ הָֽאָבִ֑יב כִּ֚י בְּחֹ֣דֶשׁ הָֽאָבִ֔יב יָצָ֖אתָ מִמִּצְרָֽיִם׃"
          },
          {
            "num": "כ",
            "txt": "כָּל־פֶּ֥טֶר רֶ֖חֶם לִ֑י וְכָֽל־מִקְנְךָ֙ תִּזָּכָ֔ר פֶּ֖טֶר שׁ֥וֹר וָשֶֽׂה׃"
          },
          {
            "num": "כא",
            "txt": "וּפֶ֤טֶר חֲמוֹר֙ תִּפְדֶּ֣ה בְשֶׂ֔ה וְאִם־לֹ֥א תִפְדֶּ֖ה וַֽעֲרַפְתּ֑וֹ כֹּ֣ל בְּכ֤וֹר בָּנֶ֨יךָ֙ תִּפְדֶּ֔ה וְלֹֽא־יֵרָא֥וּ פָנַ֖י רֵיקָֽם׃"
          },
          {
            "num": "כב",
            "txt": "שֵׁ֤שֶׁת יָמִים֙ תַּֽעֲבֹ֔ד וּבַיּ֥וֹם הַשְּׁבִיעִ֖י תִּשְׁבֹּ֑ת בֶּֽחָרִ֥ישׁ וּבַקָּצִ֖יר תִּשְׁבֹּֽת׃"
          },
          {
            "num": "כג",
            "txt": "וְחַ֤ג שָֽׁבֻעֹת֙ תַּֽעֲשֶׂ֣ה לְךָ֔ בִּכּוּרֵ֖י קְצִ֣יר חִטִּ֑ים וְחַג֙ הָֽאָסִ֔יף תְּקוּפַ֖ת הַשָּׁנָֽה׃"
          },
          {
            "num": "כד",
            "txt": "שָׁלֹ֥שׁ פְּעָמִ֖ים בַּשָּׁנָ֑ה יֵֽרָאֶה֙ כָּל־זְכ֣וּרְךָ֔ אֶת־פְּנֵ֛י הָֽאָדֹ֥ן ׀ יְהוָ֖ה אֱלֹהֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כה",
            "txt": "כִּֽי־אוֹרִ֤ישׁ גּוֹיִם֙ מִפָּנֶ֔יךָ וְהִרְחַבְתִּ֖י אֶת־גְּבֻלֶ֑ךָ וְלֹֽא־יַחְמֹ֥ד אִישׁ֙ אֶֽת־אַרְצְךָ֔ בַּעֲלֹֽתְךָ֗ לֵֽרָאוֹת֙ אֶת־פְּנֵי֙ יְהוָ֣ה אֱלֹהֶ֔יךָ שָׁלֹ֥שׁ פְּעָמִ֖ים בַּשָּׁנָֽה׃"
          },
          {
            "num": "כו",
            "txt": "לֹֽא־תִשְׁחַ֥ט עַל־חָמֵ֖ץ דַּם־זִבְחִ֑י וְלֹֽא־יָלִ֣ין לַבֹּ֔קֶר זֶ֖בַח חַ֥ג הַפָּֽסַח׃"
          },
          {
            "num": "כז",
            "txt": "רֵאשִׁ֗ית בִּכּוּרֵי֙ אַדְמָ֣תְךָ֔ תָּבִ֕יא בֵּ֖ית יְהוָ֣ה אֱלֹהֶ֑יךָ לֹֽא־תְבַשֵּׁ֥ל גְּדִ֖י בַּֽחֲלֵ֥ב אִמּֽוֹ׃ {פ}"
          },
          {
            "num": "כח",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה כְּתָב־לְךָ֖ אֶת־הַדְּבָרִ֣ים הָאֵ֑לֶּה כִּ֞י עַל־פִּ֣י ׀ הַדְּבָרִ֣ים הָאֵ֗לֶּה כָּרַ֧תִּי אִתְּךָ֛ בְּרִ֖ית וְאֶת־יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כט",
            "txt": "וַֽיְהִי־שָׁ֣ם עִם־יְהוָ֗ה אַרְבָּעִ֥ים יוֹם֙ וְאַרְבָּעִ֣ים לַ֔יְלָה לֶ֚חֶם לֹ֣א אָכַ֔ל וּמַ֖יִם לֹ֣א שָׁתָ֑ה וַיִּכְתֹּ֣ב עַל־הַלֻּחֹ֗ת אֵ֚ת דִּבְרֵ֣י הַבְּרִ֔ית עֲשֶׂ֖רֶת הַדְּבָרִֽים׃"
          },
          {
            "num": "ל",
            "txt": "וַיְהִ֗י בְּרֶ֤דֶת מֹשֶׁה֙ מֵהַ֣ר סִינַ֔י וּשְׁנֵ֨י לֻחֹ֤ת הָֽעֵדֻת֙ בְּיַד־מֹשֶׁ֔ה בְּרִדְתּ֖וֹ מִן־הָהָ֑ר וּמֹשֶׁ֣ה לֹֽא־יָדַ֗ע כִּ֥י קָרַ֛ן ע֥וֹר פָּנָ֖יו בְּדַבְּר֥וֹ אִתּֽוֹ׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּ֨רְא אַֽהֲרֹ֜ן וְכָל־בְּנֵ֤י יִשְׂרָאֵל֙ אֶת־מֹשֶׁ֔ה וְהִנֵּ֥ה קָרַ֖ן ע֣וֹר פָּנָ֑יו וַיִּֽירְא֖וּ מִגֶּ֥שֶׁת אֵלָֽיו׃"
          },
          {
            "num": "לב",
            "txt": "וַיִּקְרָ֤א אֲלֵהֶם֙ מֹשֶׁ֔ה וַיָּשֻׁ֧בוּ אֵלָ֛יו אַֽהֲרֹ֥ן וְכָל־הַנְּשִׂאִ֖ים בָּֽעֵדָ֑ה וַיְדַבֵּ֥ר מֹשֶׁ֖ה אֲלֵהֶֽם׃"
          },
          {
            "num": "לג",
            "txt": "וְאַֽחֲרֵי־כֵ֥ן נִגְּשׁ֖וּ כָּל־בְּנֵ֣י יִשְׂרָאֵ֑ל וַיְצַוֵּ֕ם אֵת֩ כָּל־אֲשֶׁ֨ר דִּבֶּ֧ר יְהוָ֛ה אִתּ֖וֹ בְּהַ֥ר סִינָֽי׃"
          },
          {
            "num": "לד",
            "txt": "וַיְכַ֣ל מֹשֶׁ֔ה מִדַּבֵּ֖ר אִתָּ֑ם וַיִּתֵּ֥ן עַל־פָּנָ֖יו מַסְוֶֽה׃"
          },
          {
            "num": "לה",
            "txt": "וּבְבֹ֨א מֹשֶׁ֜ה לִפְנֵ֤י יְהוָה֙ לְדַבֵּ֣ר אִתּ֔וֹ יָסִ֥יר אֶת־הַמַּסְוֶ֖ה עַד־צֵאת֑וֹ וְיָצָ֗א וְדִבֶּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל אֵ֖ת אֲשֶׁ֥ר יְצֻוֶּֽה׃"
          },
          {
            "txt": "וְרָא֤וּ בְנֵֽי־יִשְׂרָאֵל֙ אֶת־פְּנֵ֣י מֹשֶׁ֔ה כִּ֣י קָרַ֔ן ע֖וֹר פְּנֵ֣י מֹשֶׁ֑ה וְהֵשִׁ֨יב מֹשֶׁ֤ה אֶת־הַמַּסְוֶה֙ עַל־פָּנָ֔יו עַד־בֹּא֖וֹ לְדַבֵּ֥ר אִתּֽוֹ׃ {ס}"
          }
        ]
      },
      {
        "num": "לה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּקְהֵ֣ל מֹשֶׁ֗ה אֶֽת־כָּל־עֲדַ֛ת בְּנֵ֥י יִשְׂרָאֵ֖ל וַיֹּ֣אמֶר אֲלֵהֶ֑ם אֵ֚לֶּה הַדְּבָרִ֔ים אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה לַֽעֲשֹׂ֥ת אֹתָֽם׃"
          },
          {
            "num": "ב",
            "txt": "שֵׁ֣שֶׁת יָמִים֮ תֵּֽעָשֶׂ֣ה מְלָאכָה֒ וּבַיּ֣וֹם הַשְּׁבִיעִ֗י יִֽהְיֶ֨ה לָכֶ֥ם קֹ֛דֶשׁ שַׁבַּ֥ת שַׁבָּת֖וֹן לַֽיהוָ֑ה כָּל־הָֽעֹשֶׂ֥ה ב֛וֹ מְלָאכָ֖ה יוּמָֽת׃"
          },
          {
            "num": "ג",
            "txt": "לֹֽא־תְבַעֲר֣וּ אֵ֔שׁ בְּכֹ֖ל מֹשְׁבֹֽתֵיכֶ֑ם בְּי֖וֹם הַשַּׁבָּֽת׃ {פ}"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה אֶל־כָּל־עֲדַ֥ת בְּנֵֽי־יִשְׂרָאֵ֖ל לֵאמֹ֑ר זֶ֣ה הַדָּבָ֔ר אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה לֵאמֹֽר׃"
          },
          {
            "num": "ה",
            "txt": "קְח֨וּ מֵֽאִתְּכֶ֤ם תְּרוּמָה֙ לַֽיהוָ֔ה כֹּ֚ל נְדִ֣יב לִבּ֔וֹ יְבִיאֶ֕הָ אֵ֖ת תְּרוּמַ֣ת יְהוָ֑ה זָהָ֥ב וָכֶ֖סֶף וּנְחֹֽשֶׁת׃"
          },
          {
            "num": "ו",
            "txt": "וּתְכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֥שׁ וְעִזִּֽים׃"
          },
          {
            "num": "ז",
            "txt": "וְעֹרֹ֨ת אֵילִ֧ם מְאָדָּמִ֛ים וְעֹרֹ֥ת תְּחָשִׁ֖ים וַֽעֲצֵ֥י שִׁטִּֽים׃"
          },
          {
            "num": "ח",
            "txt": "וְשֶׁ֖מֶן לַמָּא֑וֹר וּבְשָׂמִים֙ לְשֶׁ֣מֶן הַמִּשְׁחָ֔ה וְלִקְטֹ֖רֶת הַסַּמִּֽים׃"
          },
          {
            "num": "ט",
            "txt": "וְאַ֨בְנֵי־שֹׁ֔הַם וְאַבְנֵ֖י מִלֻּאִ֑ים לָֽאֵפ֖וֹד וְלַחֹֽשֶׁן׃"
          },
          {
            "num": "י",
            "txt": "וְכָל־חֲכַם־לֵ֖ב בָּכֶ֑ם יָבֹ֣אוּ וְיַֽעֲשׂ֔וּ אֵ֛ת כָּל־אֲשֶׁ֥ר צִוָּ֖ה יְהוָֽה׃"
          },
          {
            "num": "יא",
            "txt": "אֶ֨ת־הַמִּשְׁכָּ֔ן אֶֽת־אָהֳל֖וֹ וְאֶת־מִכְסֵ֑הוּ אֶת־קְרָסָיו֙ וְאֶת־קְרָשָׁ֔יו אֶת־בְּרִיחָ֕ו אֶת־עַמֻּדָ֖יו וְאֶת־אֲדָנָֽיו׃"
          },
          {
            "num": "יב",
            "txt": "אֶת־הָֽאָרֹ֥ן וְאֶת־בַּדָּ֖יו אֶת־הַכַּפֹּ֑רֶת וְאֵ֖ת פָּרֹ֥כֶת הַמָּסָֽךְ׃"
          },
          {
            "num": "יג",
            "txt": "אֶת־הַשֻּׁלְחָ֥ן וְאֶת־בַּדָּ֖יו וְאֶת־כָּל־כֵּלָ֑יו וְאֵ֖ת לֶ֥חֶם הַפָּנִֽים׃"
          },
          {
            "num": "יד",
            "txt": "וְאֶת־מְנֹרַ֧ת הַמָּא֛וֹר וְאֶת־כֵּלֶ֖יהָ וְאֶת־נֵֽרֹתֶ֑יהָ וְאֵ֖ת שֶׁ֥מֶן הַמָּאֽוֹר׃"
          },
          {
            "num": "טו",
            "txt": "וְאֶת־מִזְבַּ֤ח הַקְּטֹ֨רֶת֙ וְאֶת־בַּדָּ֔יו וְאֵת֙ שֶׁ֣מֶן הַמִּשְׁחָ֔ה וְאֵ֖ת קְטֹ֣רֶת הַסַּמִּ֑ים וְאֶת־מָסַ֥ךְ הַפֶּ֖תַח לְפֶ֥תַח הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "טז",
            "txt": "אֵ֣ת ׀ מִזְבַּ֣ח הָֽעֹלָ֗ה וְאֶת־מִכְבַּ֤ר הַנְּחֹ֨שֶׁת֙ אֲשֶׁר־ל֔וֹ אֶת־בַּדָּ֖יו וְאֶת־כָּל־כֵּלָ֑יו אֶת־הַכִּיֹּ֖ר וְאֶת־כַּנּֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "אֵ֚ת קַלְעֵ֣י הֶֽחָצֵ֔ר אֶת־עַמֻּדָ֖יו וְאֶת־אֲדָנֶ֑יהָ וְאֵ֕ת מָסַ֖ךְ שַׁ֥עַר הֶֽחָצֵֽר׃"
          },
          {
            "num": "יח",
            "txt": "אֶת־יִתְדֹ֧ת הַמִּשְׁכָּ֛ן וְאֶת־יִתְדֹ֥ת הֶֽחָצֵ֖ר וְאֶת־מֵֽיתְרֵיהֶֽם׃"
          },
          {
            "num": "יט",
            "txt": "אֶת־בִּגְדֵ֥י הַשְּׂרָ֖ד לְשָׁרֵ֣ת בַּקֹּ֑דֶשׁ אֶת־בִּגְדֵ֤י הַקֹּ֨דֶשׁ֙ לְאַֽהֲרֹ֣ן הַכֹּהֵ֔ן וְאֶת־בִּגְדֵ֥י בָנָ֖יו לְכַהֵֽן׃"
          },
          {
            "num": "כ",
            "txt": "וַיֵּ֥צְא֛וּ כָּל־עֲדַ֥ת בְּנֵֽי־יִשְׂרָאֵ֖ל מִלִּפְנֵ֥י מֹשֶֽׁה׃"
          },
          {
            "num": "כא",
            "txt": "וַיָּבֹ֕אוּ כָּל־אִ֖ישׁ אֲשֶׁר־נְשָׂא֣וֹ לִבּ֑וֹ וְכֹ֡ל אֲשֶׁר֩ נָֽדְבָ֨ה רוּח֜וֹ אֹת֗וֹ הֵ֠בִיאוּ אֶת־תְּרוּמַ֨ת יְהוָ֜ה לִמְלֶ֨אכֶת אֹ֤הֶל מוֹעֵד֙ וּלְכָל־עֲבֹ֣דָת֔וֹ וּלְבִגְדֵ֖י הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "כב",
            "txt": "וַיָּבֹ֥אוּ הָֽאֲנָשִׁ֖ים עַל־הַנָּשִׁ֑ים כֹּ֣ל ׀ נְדִ֣יב לֵ֗ב הֵ֠בִיאוּ חָ֣ח וָנֶ֜זֶם וְטַבַּ֤עַת וְכוּמָז֙ כָּל־כְּלִ֣י זָהָ֔ב וְכָל־אִ֕ישׁ אֲשֶׁ֥ר הֵנִ֛יף תְּנוּפַ֥ת זָהָ֖ב לַֽיהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וְכָל־אִ֞ישׁ אֲשֶׁר־נִמְצָ֣א אִתּ֗וֹ תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֣שׁ וְעִזִּ֑ים וְעֹרֹ֨ת אֵילִ֧ם מְאָדָּמִ֛ים וְעֹרֹ֥ת תְּחָשִׁ֖ים הֵבִֽיאוּ׃"
          },
          {
            "num": "כד",
            "txt": "כָּל־מֵרִ֗ים תְּר֤וּמַת כֶּ֨סֶף֙ וּנְחֹ֔שֶׁת הֵבִ֕יאוּ אֵ֖ת תְּרוּמַ֣ת יְהוָ֑ה וְכֹ֡ל אֲשֶׁר֩ נִמְצָ֨א אִתּ֜וֹ עֲצֵ֥י שִׁטִּ֛ים לְכָל־מְלֶ֥אכֶת הָֽעֲבֹדָ֖ה הֵבִֽיאוּ׃"
          },
          {
            "num": "כה",
            "txt": "וְכָל־אִשָּׁ֥ה חַכְמַת־לֵ֖ב בְּיָדֶ֣יהָ טָו֑וּ וַיָּבִ֣יאוּ מַטְוֶ֗ה אֶֽת־הַתְּכֵ֨לֶת֙ וְאֶת־הָֽאַרְגָּמָ֔ן אֶת־תּוֹלַ֥עַת הַשָּׁנִ֖י וְאֶת־הַשֵּֽׁשׁ׃"
          },
          {
            "num": "כו",
            "txt": "וְכָ֨ל־הַנָּשִׁ֔ים אֲשֶׁ֨ר נָשָׂ֥א לִבָּ֛ן אֹתָ֖נָה בְּחָכְמָ֑ה טָו֖וּ אֶת־הָֽעִזִּֽים׃"
          },
          {
            "num": "כז",
            "txt": "וְהַנְּשִׂאִ֣ם הֵבִ֔יאוּ אֵ֚ת אַבְנֵ֣י הַשֹּׁ֔הַם וְאֵ֖ת אַבְנֵ֣י הַמִּלֻּאִ֑ים לָֽאֵפ֖וֹד וְלַחֹֽשֶׁן׃"
          },
          {
            "num": "כח",
            "txt": "וְאֶת־הַבֹּ֖שֶׂם וְאֶת־הַשָּׁ֑מֶן לְמָא֕וֹר וּלְשֶׁ֨מֶן֙ הַמִּשְׁחָ֔ה וְלִקְטֹ֖רֶת הַסַּמִּֽים׃"
          },
          {
            "num": "כט",
            "txt": "כָּל־אִ֣ישׁ וְאִשָּׁ֗ה אֲשֶׁ֨ר נָדַ֣ב לִבָּם֮ אֹתָם֒ לְהָבִיא֙ לְכָל־הַמְּלָאכָ֔ה אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה לַֽעֲשׂ֖וֹת בְּיַד־מֹשֶׁ֑ה הֵבִ֧יאוּ בְנֵֽי־יִשְׂרָאֵ֛ל נְדָבָ֖ה לַֽיהוָֽה׃ {פ}"
          },
          {
            "num": "ל",
            "txt": "וַיֹּ֤אמֶר מֹשֶׁה֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל רְא֛וּ קָרָ֥א יְהוָ֖ה בְּשֵׁ֑ם בְּצַלְאֵ֛ל בֶּן־אוּרִ֥י בֶן־ח֖וּר לְמַטֵּ֥ה יְהוּדָֽה׃"
          },
          {
            "num": "לא",
            "txt": "וַיְמַלֵּ֥א אֹת֖וֹ ר֣וּחַ אֱלֹהִ֑ים בְּחָכְמָ֛ה בִּתְבוּנָ֥ה וּבְדַ֖עַת וּבְכָל־מְלָאכָֽה׃"
          },
          {
            "num": "לב",
            "txt": "וְלַחְשֹׁ֖ב מַֽחֲשָׁבֹ֑ת לַֽעֲשֹׂ֛ת בַּזָּהָ֥ב וּבַכֶּ֖סֶף וּבַנְּחֹֽשֶׁת׃"
          },
          {
            "num": "לג",
            "txt": "וּבַֽחֲרֹ֥שֶׁת אֶ֛בֶן לְמַלֹּ֖את וּבַֽחֲרֹ֣שֶׁת עֵ֑ץ לַֽעֲשׂ֖וֹת בְּכָל־מְלֶ֥אכֶת מַֽחֲשָֽׁבֶת׃"
          },
          {
            "num": "לד",
            "txt": "וּלְהוֹרֹ֖ת נָתַ֣ן בְּלִבּ֑וֹ ה֕וּא וְאָֽהֳלִיאָ֥ב בֶּן־אֲחִֽיסָמָ֖ךְ לְמַטֵּה־דָֽן׃"
          },
          {
            "num": "לה",
            "txt": "מִלֵּ֨א אֹתָ֜ם חָכְמַת־לֵ֗ב לַֽעֲשׂוֹת֮ כָּל־מְלֶ֣אכֶת חָרָ֣שׁ ׀ וְחֹשֵׁב֒ וְרֹקֵ֞ם בַּתְּכֵ֣לֶת וּבָֽאַרְגָּמָ֗ן בְּתוֹלַ֧עַת הַשָּׁנִ֛י וּבַשֵּׁ֖שׁ וְאֹרֵ֑ג עֹשֵׂי֙ כָּל־מְלָאכָ֔ה וְחֹֽשְׁבֵ֖י מַֽחֲשָׁבֹֽת׃"
          }
        ]
      },
      {
        "num": "לו",
        "verses": [
          {
            "num": "א",
            "txt": "וְעָשָׂה֩ בְצַלְאֵ֨ל וְאָֽהֳלִיאָ֜ב וְכֹ֣ל ׀ אִ֣ישׁ חֲכַם־לֵ֗ב אֲשֶׁר֩ נָתַ֨ן יְהוָ֜ה חָכְמָ֤ה וּתְבוּנָה֙ בָּהֵ֔מָּה לָדַ֣עַת לַֽעֲשֹׂ֔ת אֶֽת־כָּל־מְלֶ֖אכֶת עֲבֹדַ֣ת הַקֹּ֑דֶשׁ לְכֹ֥ל אֲשֶׁר־צִוָּ֖ה יְהוָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּקְרָ֣א מֹשֶׁ֗ה אֶל־בְּצַלְאֵל֮ וְאֶל־אָֽהֳלִיאָב֒ וְאֶל֙ כָּל־אִ֣ישׁ חֲכַם־לֵ֔ב אֲשֶׁ֨ר נָתַ֧ן יְהוָ֛ה חָכְמָ֖ה בְּלִבּ֑וֹ כֹּ֚ל אֲשֶׁ֣ר נְשָׂא֣וֹ לִבּ֔וֹ לְקָרְבָ֥ה אֶל־הַמְּלָאכָ֖ה לַֽעֲשֹׂ֥ת אֹתָֽהּ׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּקְח֞וּ מִלִּפְנֵ֣י מֹשֶׁ֗ה אֵ֤ת כָּל־הַתְּרוּמָה֙ אֲשֶׁ֨ר הֵבִ֜יאוּ בְּנֵ֣י יִשְׂרָאֵ֗ל לִמְלֶ֛אכֶת עֲבֹדַ֥ת הַקֹּ֖דֶשׁ לַֽעֲשֹׂ֣ת אֹתָ֑הּ וְ֠הֵם הֵבִ֨יאוּ אֵלָ֥יו ע֛וֹד נְדָבָ֖ה בַּבֹּ֥קֶר בַּבֹּֽקֶר׃"
          },
          {
            "num": "ד",
            "txt": "וַיָּבֹ֨אוּ֙ כָּל־הַ֣חֲכָמִ֔ים הָֽעֹשִׂ֕ים אֵ֖ת כָּל־מְלֶ֣אכֶת הַקֹּ֑דֶשׁ אִֽישׁ־אִ֥ישׁ מִמְּלַאכְתּ֖וֹ אֲשֶׁר־הֵ֥מָּה עֹשִֽׂים׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּֽאמְרוּ֙ אֶל־מֹשֶׁ֣ה לֵּאמֹ֔ר מַרְבִּ֥ים הָעָ֖ם לְהָבִ֑יא מִדֵּ֤י הָֽעֲבֹדָה֙ לַמְּלָאכָ֔ה אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה לַֽעֲשֹׂ֥ת אֹתָֽהּ׃"
          },
          {
            "num": "ו",
            "txt": "וַיְצַ֣ו מֹשֶׁ֗ה וַיַּֽעֲבִ֨ירוּ ק֥וֹל בַּֽמַּחֲנֶה֮ לֵאמֹר֒ אִ֣ישׁ וְאִשָּׁ֗ה אַל־יַֽעֲשׂוּ־ע֛וֹד מְלָאכָ֖ה לִתְרוּמַ֣ת הַקֹּ֑דֶשׁ וַיִּכָּלֵ֥א הָעָ֖ם מֵֽהָבִֽיא׃"
          },
          {
            "num": "ז",
            "txt": "וְהַמְּלָאכָ֗ה הָֽיְתָ֥ה דַיָּ֛ם לְכָל־הַמְּלָאכָ֖ה לַֽעֲשׂ֣וֹת אֹתָ֑הּ וְהוֹתֵֽר׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "וַיַּֽעֲשׂ֨וּ כָל־חֲכַם־לֵ֜ב בְּעֹשֵׂ֧י הַמְּלָאכָ֛ה אֶת־הַמִּשְׁכָּ֖ן עֶ֣שֶׂר יְרִיעֹ֑ת שֵׁ֣שׁ מָשְׁזָ֗ר וּתְכֵ֤לֶת וְאַרְגָּמָן֙ וְתוֹלַ֣עַת שָׁנִ֔י כְּרֻבִ֛ים מַֽעֲשֵׂ֥ה חֹשֵׁ֖ב עָשָׂ֥ה אֹתָֽם׃"
          },
          {
            "num": "ט",
            "txt": "אֹ֜רֶךְ הַיְרִיעָ֣ה הָֽאַחַ֗ת שְׁמֹנֶ֤ה וְעֶשְׂרִים֙ בָּֽאַמָּ֔ה וְרֹ֨חַב֙ אַרְבַּ֣ע בָּֽאַמָּ֔ה הַיְרִיעָ֖ה הָֽאֶחָ֑ת מִדָּ֥ה אַחַ֖ת לְכָל־הַיְרִיעֹֽת׃"
          },
          {
            "num": "י",
            "txt": "וַיְחַבֵּר֙ אֶת־חֲמֵ֣שׁ הַיְרִיעֹ֔ת אַחַ֖ת אֶל־אֶחָ֑ת וְחָמֵ֤שׁ יְרִיעֹת֙ חִבַּ֔ר אַחַ֖ת אֶל־אֶחָֽת׃"
          },
          {
            "num": "יא",
            "txt": "וַיַּ֜עַשׂ לֻֽלְאֹ֣ת תְּכֵ֗לֶת עַ֣ל שְׂפַ֤ת הַיְרִיעָה֙ הָֽאֶחָ֔ת מִקָּצָ֖ה בַּמַּחְבָּ֑רֶת כֵּ֤ן עָשָׂה֙ בִּשְׂפַ֣ת הַיְרִיעָ֔ה הַקִּ֣יצוֹנָ֔ה בַּמַּחְבֶּ֖רֶת הַשֵּׁנִֽית׃"
          },
          {
            "num": "יב",
            "txt": "חֲמִשִּׁ֣ים לֻֽלָאֹ֗ת עָשָׂה֮ בַּיְרִיעָ֣ה הָֽאֶחָת֒ וַֽחֲמִשִּׁ֣ים לֻֽלָאֹ֗ת עָשָׂה֙ בִּקְצֵ֣ה הַיְרִיעָ֔ה אֲשֶׁ֖ר בַּמַּחְבֶּ֣רֶת הַשֵּׁנִ֑ית מַקְבִּילֹת֙ הַלֻּ֣לָאֹ֔ת אַחַ֖ת אֶל־אֶחָֽת׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּ֕עַשׂ חֲמִשִּׁ֖ים קַרְסֵ֣י זָהָ֑ב וַיְחַבֵּ֨ר אֶת־הַיְרִיעֹ֜ת אַחַ֤ת אֶל־אַחַת֙ בַּקְּרָסִ֔ים וַיְהִ֥י הַמִּשְׁכָּ֖ן אֶחָֽד׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "וַיַּ֨עַשׂ֙ יְרִיעֹ֣ת עִזִּ֔ים לְאֹ֖הֶל עַל־הַמִּשְׁכָּ֑ן עַשְׁתֵּֽי־עֶשְׂרֵ֥ה יְרִיעֹ֖ת עָשָׂ֥ה אֹתָֽם׃"
          },
          {
            "num": "טו",
            "txt": "אֹ֜רֶךְ הַיְרִיעָ֣ה הָֽאַחַ֗ת שְׁלֹשִׁים֙ בָּֽאַמָּ֔ה וְאַרְבַּ֣ע אַמּ֔וֹת רֹ֖חַב הַיְרִיעָ֣ה הָֽאֶחָ֑ת מִדָּ֣ה אַחַ֔ת לְעַשְׁתֵּ֥י עֶשְׂרֵ֖ה יְרִיעֹֽת׃"
          },
          {
            "num": "טז",
            "txt": "וַיְחַבֵּ֛ר אֶת־חֲמֵ֥שׁ הַיְרִיעֹ֖ת לְבָ֑ד וְאֶת־שֵׁ֥שׁ הַיְרִיעֹ֖ת לְבָֽד׃"
          },
          {
            "num": "יז",
            "txt": "וַיַּ֜עַשׂ לֻֽלָאֹ֣ת חֲמִשִּׁ֗ים עַ֚ל שְׂפַ֣ת הַיְרִיעָ֔ה הַקִּֽיצֹנָ֖ה בַּמַּחְבָּ֑רֶת וַֽחֲמִשִּׁ֣ים לֻֽלָאֹ֗ת עָשָׂה֙ עַל־שְׂפַ֣ת הַיְרִיעָ֔ה הַֽחֹבֶ֖רֶת הַשֵּׁנִֽית׃"
          },
          {
            "num": "יח",
            "txt": "וַיַּ֛עַשׂ קַרְסֵ֥י נְחֹ֖שֶׁת חֲמִשִּׁ֑ים לְחַבֵּ֥ר אֶת־הָאֹ֖הֶל לִֽהְיֹ֥ת אֶחָֽד׃"
          },
          {
            "num": "יט",
            "txt": "וַיַּ֤עַשׂ מִכְסֶה֙ לָאֹ֔הֶל עֹרֹ֥ת אֵילִ֖ם מְאָדָּמִ֑ים וּמִכְסֵ֛ה עֹרֹ֥ת תְּחָשִׁ֖ים מִלְמָֽעְלָה׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וַיַּ֥עַשׂ אֶת־הַקְּרָשִׁ֖ים לַמִּשְׁכָּ֑ן עֲצֵ֥י שִׁטִּ֖ים עֹֽמְדִֽים׃"
          },
          {
            "num": "כא",
            "txt": "עֶ֥שֶׂר אַמֹּ֖ת אֹ֣רֶךְ הַקָּ֑רֶשׁ וְאַמָּה֙ וַֽחֲצִ֣י הָֽאַמָּ֔ה רֹ֖חַב הַקֶּ֥רֶשׁ הָֽאֶחָֽד׃"
          },
          {
            "num": "כב",
            "txt": "שְׁתֵּ֣י יָדֹ֗ת לַקֶּ֨רֶשׁ֙ הָֽאֶחָ֔ד מְשֻׁ֨לָּבֹ֔ת אַחַ֖ת אֶל־אֶחָ֑ת כֵּ֣ן עָשָׂ֔ה לְכֹ֖ל קַרְשֵׁ֥י הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "כג",
            "txt": "וַיַּ֥עַשׂ אֶת־הַקְּרָשִׁ֖ים לַמִּשְׁכָּ֑ן עֶשְׂרִ֣ים קְרָשִׁ֔ים לִפְאַ֖ת נֶ֥גֶב תֵּימָֽנָה׃"
          },
          {
            "num": "כד",
            "txt": "וְאַרְבָּעִים֙ אַדְנֵי־כֶ֔סֶף עָשָׂ֕ה תַּ֖חַת עֶשְׂרִ֣ים הַקְּרָשִׁ֑ים שְׁנֵ֨י אֲדָנִ֜ים תַּֽחַת־הַקֶּ֤רֶשׁ הָֽאֶחָד֙ לִשְׁתֵּ֣י יְדֹתָ֔יו וּשְׁנֵ֧י אֲדָנִ֛ים תַּֽחַת־הַקֶּ֥רֶשׁ הָֽאֶחָ֖ד לִשְׁתֵּ֥י יְדֹתָֽיו׃"
          },
          {
            "num": "כה",
            "txt": "וּלְצֶ֧לַע הַמִּשְׁכָּ֛ן הַשֵּׁנִ֖ית לִפְאַ֣ת צָפ֑וֹן עָשָׂ֖ה עֶשְׂרִ֥ים קְרָשִֽׁים׃"
          },
          {
            "num": "כו",
            "txt": "וְאַרְבָּעִ֥ים אַדְנֵיהֶ֖ם כָּ֑סֶף שְׁנֵ֣י אֲדָנִ֗ים תַּ֚חַת הַקֶּ֣רֶשׁ הָֽאֶחָ֔ד וּשְׁנֵ֣י אֲדָנִ֔ים תַּ֖חַת הַקֶּ֥רֶשׁ הָֽאֶחָֽד׃"
          },
          {
            "num": "כז",
            "txt": "וּֽלְיַרְכְּתֵ֥י הַמִּשְׁכָּ֖ן יָ֑מָּה עָשָׂ֖ה שִׁשָּׁ֥ה קְרָשִֽׁים׃"
          },
          {
            "num": "כח",
            "txt": "וּשְׁנֵ֤י קְרָשִׁים֙ עָשָׂ֔ה לִמְקֻצְעֹ֖ת הַמִּשְׁכָּ֑ן בַּיַּרְכָתָֽיִם׃"
          },
          {
            "num": "כט",
            "txt": "וְהָי֣וּ תֽוֹאֲמִם֮ מִלְּמַטָּה֒ וְיַחְדָּ֗ו יִֽהְי֤וּ תַמִּים֙ אֶל־רֹאשׁ֔וֹ אֶל־הַטַּבַּ֖עַת הָֽאֶחָ֑ת כֵּ֚ן עָשָׂ֣ה לִשְׁנֵיהֶ֔ם לִשְׁנֵ֖י הַמִּקְצֹעֹֽת׃"
          },
          {
            "num": "ל",
            "txt": "וְהָיוּ֙ שְׁמֹנָ֣ה קְרָשִׁ֔ים וְאַדְנֵיהֶ֣ם כֶּ֔סֶף שִׁשָּׁ֥ה עָשָׂ֖ר אֲדָנִ֑ים שְׁנֵ֤י אֲדָנִים֙ שְׁנֵ֣י אֲדָנִ֔ים תַּ֖חַת הַקֶּ֥רֶשׁ הָֽאֶחָֽד׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּ֥עַשׂ בְּרִיחֵ֖י עֲצֵ֣י שִׁטִּ֑ים חֲמִשָּׁ֕ה לְקַרְשֵׁ֥י צֶֽלַע־הַמִּשְׁכָּ֖ן הָֽאֶחָֽת׃"
          },
          {
            "num": "לב",
            "txt": "וַֽחֲמִשָּׁ֣ה בְרִיחִ֔ם לְקַרְשֵׁ֥י צֶֽלַע־הַמִּשְׁכָּ֖ן הַשֵּׁנִ֑ית וַֽחֲמִשָּׁ֤ה בְרִיחִם֙ לְקַרְשֵׁ֣י הַמִּשְׁכָּ֔ן לַיַּרְכָתַ֖יִם יָֽמָּה׃"
          },
          {
            "num": "לג",
            "txt": "וַיַּ֖עַשׂ אֶת־הַבְּרִ֣יחַ הַתִּיכֹ֑ן לִבְרֹ֨חַ֙ בְּת֣וֹךְ הַקְּרָשִׁ֔ים מִן־הַקָּצֶ֖ה אֶל־הַקָּצֶֽה׃"
          },
          {
            "num": "לד",
            "txt": "וְֽאֶת־הַקְּרָשִׁ֞ים צִפָּ֣ה זָהָ֗ב וְאֶת־טַבְּעֹתָם֙ עָשָׂ֣ה זָהָ֔ב בָּתִּ֖ים לַבְּרִיחִ֑ם וַיְצַ֥ף אֶת־הַבְּרִיחִ֖ם זָהָֽב׃"
          },
          {
            "num": "לה",
            "txt": "וַיַּ֨עַשׂ֙ אֶת־הַפָּרֹ֔כֶת תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֣שׁ מָשְׁזָ֑ר מַֽעֲשֵׂ֥ה חֹשֵׁ֛ב עָשָׂ֥ה אֹתָ֖הּ כְּרֻבִֽים׃"
          },
          {
            "num": "לו",
            "txt": "וַיַּ֣עַשׂ לָ֗הּ אַרְבָּעָה֙ עַמּוּדֵ֣י שִׁטִּ֔ים וַיְצַפֵּ֣ם זָהָ֔ב וָֽוֵיהֶ֖ם זָהָ֑ב וַיִּצֹ֣ק לָהֶ֔ם אַרְבָּעָ֖ה אַדְנֵי־כָֽסֶף׃"
          },
          {
            "num": "לז",
            "txt": "וַיַּ֤עַשׂ מָסָךְ֙ לְפֶ֣תַח הָאֹ֔הֶל תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֣שׁ מָשְׁזָ֑ר מַֽעֲשֵׂ֖ה רֹקֵֽם׃"
          },
          {
            "num": "לח",
            "txt": "וְאֶת־עַמּוּדָ֤יו חֲמִשָּׁה֙ וְאֶת־וָ֣וֵיהֶ֔ם וְצִפָּ֧ה רָֽאשֵׁיהֶ֛ם וַחֲשֻֽׁקֵיהֶ֖ם זָהָ֑ב וְאַדְנֵיהֶ֥ם חֲמִשָּׁ֖ה נְחֹֽשֶׁת׃ {פ}"
          }
        ]
      },
      {
        "num": "לז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּ֧עַשׂ בְּצַלְאֵ֛ל אֶת־הָֽאָרֹ֖ן עֲצֵ֣י שִׁטִּ֑ים אַמָּתַ֨יִם וָחֵ֜צִי אָרְכּ֗וֹ וְאַמָּ֤ה וָחֵ֨צִי֙ רָחְבּ֔וֹ וְאַמָּ֥ה וָחֵ֖צִי קֹֽמָתֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וַיְצַפֵּ֛הוּ זָהָ֥ב טָה֖וֹר מִבַּ֣יִת וּמִח֑וּץ וַיַּ֥עַשׂ ל֛וֹ זֵ֥ר זָהָ֖ב סָבִֽיב׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּצֹ֣ק ל֗וֹ אַרְבַּע֙ טַבְּעֹ֣ת זָהָ֔ב עַ֖ל אַרְבַּ֣ע פַּֽעֲמֹתָ֑יו וּשְׁתֵּ֣י טַבָּעֹ֗ת עַל־צַלְעוֹ֙ הָֽאֶחָ֔ת וּשְׁתֵּי֙ טַבָּעֹ֔ת עַל־צַלְע֖וֹ הַשֵּׁנִֽית׃"
          },
          {
            "num": "ד",
            "txt": "וַיַּ֥עַשׂ בַּדֵּ֖י עֲצֵ֣י שִׁטִּ֑ים וַיְצַ֥ף אֹתָ֖ם זָהָֽב׃"
          },
          {
            "num": "ה",
            "txt": "וַיָּבֵ֤א אֶת־הַבַּדִּים֙ בַּטַּבָּעֹ֔ת עַ֖ל צַלְעֹ֣ת הָֽאָרֹ֑ן לָשֵׂ֖את אֶת־הָֽאָרֹֽן׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּ֥עַשׂ כַּפֹּ֖רֶת זָהָ֣ב טָה֑וֹר אַמָּתַ֤יִם וָחֵ֨צִי֙ אָרְכָּ֔הּ וְאַמָּ֥ה וָחֵ֖צִי רָחְבָּֽהּ׃"
          },
          {
            "num": "ז",
            "txt": "וַיַּ֛עַשׂ שְׁנֵ֥י כְרֻבִ֖ים זָהָ֑ב מִקְשָׁה֙ עָשָׂ֣ה אֹתָ֔ם מִשְּׁנֵ֖י קְצ֥וֹת הַכַּפֹּֽרֶת׃"
          },
          {
            "num": "ח",
            "txt": "כְּרוּב־אֶחָ֤ד מִקָּצָה֙ מִזֶּ֔ה וּכְרוּב־אֶחָ֥ד מִקָּצָ֖ה מִזֶּ֑ה מִן־הַכַּפֹּ֛רֶת עָשָׂ֥ה אֶת־הַכְּרֻבִ֖ים מִשְּׁנֵ֥י קצוותו (קְצוֹתָֽיו)׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּֽהְי֣וּ הַכְּרֻבִים֩ פֹּֽרְשֵׂ֨י כְנָפַ֜יִם לְמַ֗עְלָה סֹֽכְכִ֤ים בְּכַנְפֵיהֶם֙ עַל־הַכַּפֹּ֔רֶת וּפְנֵיהֶ֖ם אִ֣ישׁ אֶל־אָחִ֑יו אֶ֨ל־הַכַּפֹּ֔רֶת הָי֖וּ פְּנֵ֥י הַכְּרֻבִֽים׃ {פ}"
          },
          {
            "num": "י",
            "txt": "וַיַּ֥עַשׂ אֶת־הַשֻּׁלְחָ֖ן עֲצֵ֣י שִׁטִּ֑ים אַמָּתַ֤יִם אָרְכּוֹ֙ וְאַמָּ֣ה רָחְבּ֔וֹ וְאַמָּ֥ה וָחֵ֖צִי קֹֽמָתֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וַיְצַ֥ף אֹת֖וֹ זָהָ֣ב טָה֑וֹר וַיַּ֥עַשׂ ל֛וֹ זֵ֥ר זָהָ֖ב סָבִֽיב׃"
          },
          {
            "num": "יב",
            "txt": "וַיַּ֨עַשׂ ל֥וֹ מִסְגֶּ֛רֶת טֹ֖פַח סָבִ֑יב וַיַּ֧עַשׂ זֵר־זָהָ֛ב לְמִסְגַּרְתּ֖וֹ סָבִֽיב׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּצֹ֣ק ל֔וֹ אַרְבַּ֖ע טַבְּעֹ֣ת זָהָ֑ב וַיִּתֵּן֙ אֶת־הַטַּבָּעֹ֔ת עַ֚ל אַרְבַּ֣ע הַפֵּאֹ֔ת אֲשֶׁ֖ר לְאַרְבַּ֥ע רַגְלָֽיו׃"
          },
          {
            "num": "יד",
            "txt": "לְעֻמַּת֙ הַמִּסְגֶּ֔רֶת הָי֖וּ הַטַּבָּעֹ֑ת בָּתִּים֙ לַבַּדִּ֔ים לָשֵׂ֖את אֶת־הַשֻּׁלְחָֽן׃"
          },
          {
            "num": "טו",
            "txt": "וַיַּ֤עַשׂ אֶת־הַבַּדִּים֙ עֲצֵ֣י שִׁטִּ֔ים וַיְצַ֥ף אֹתָ֖ם זָהָ֑ב לָשֵׂ֖את אֶת־הַשֻּׁלְחָֽן׃"
          },
          {
            "num": "טז",
            "txt": "וַיַּ֜עַשׂ אֶֽת־הַכֵּלִ֣ים ׀ אֲשֶׁ֣ר עַל־הַשֻּׁלְחָ֗ן אֶת־קְעָֽרֹתָ֤יו וְאֶת־כַּפֹּתָיו֙ וְאֵת֙ מְנַקִּיֹּתָ֔יו וְאֶ֨ת־הַקְּשָׂוֺ֔ת אֲשֶׁ֥ר יֻסַּ֖ךְ בָּהֵ֑ן זָהָ֖ב טָהֽוֹר׃ {פ}"
          },
          {
            "num": "יז",
            "txt": "וַיַּ֥עַשׂ אֶת־הַמְּנֹרָ֖ה זָהָ֣ב טָה֑וֹר מִקְשָׁ֞ה עָשָׂ֤ה אֶת־הַמְּנֹרָה֙ יְרֵכָ֣הּ וְקָנָ֔הּ גְּבִיעֶ֛יהָ כַּפְתֹּרֶ֥יהָ וּפְרָחֶ֖יהָ מִמֶּ֥נָּה הָיֽוּ׃"
          },
          {
            "num": "יח",
            "txt": "וְשִׁשָּׁ֣ה קָנִ֔ים יֹֽצְאִ֖ים מִצִּדֶּ֑יהָ שְׁלֹשָׁ֣ה ׀ קְנֵ֣י מְנֹרָ֗ה מִצִּדָּהּ֙ הָֽאֶחָ֔ד וּשְׁלֹשָׁה֙ קְנֵ֣י מְנֹרָ֔ה מִצִּדָּ֖הּ הַשֵּׁנִֽי׃"
          },
          {
            "num": "יט",
            "txt": "שְׁלֹשָׁ֣ה גְ֠בִעִים מְֽשֻׁקָּדִ֞ים בַּקָּנֶ֣ה הָֽאֶחָד֮ כַּפְתֹּ֣ר וָפֶרַח֒ וּשְׁלֹשָׁ֣ה גְבִעִ֗ים מְשֻׁקָּדִ֛ים בְּקָנֶ֥ה אֶחָ֖ד כַּפְתֹּ֣ר וָפָ֑רַח כֵּ֚ן לְשֵׁ֣שֶׁת הַקָּנִ֔ים הַיֹּֽצְאִ֖ים מִן־הַמְּנֹרָֽה׃"
          },
          {
            "num": "כ",
            "txt": "וּבַמְּנֹרָ֖ה אַרְבָּעָ֣ה גְבִעִ֑ים מְשֻׁ֨קָּדִ֔ים כַּפְתֹּרֶ֖יהָ וּפְרָחֶֽיהָ׃"
          },
          {
            "num": "כא",
            "txt": "וְכַפְתֹּ֡ר תַּחַת֩ שְׁנֵ֨י הַקָּנִ֜ים מִמֶּ֗נָּה וְכַפְתֹּר֙ תַּ֣חַת שְׁנֵ֤י הַקָּנִים֙ מִמֶּ֔נָּה וְכַפְתֹּ֕ר תַּֽחַת־שְׁנֵ֥י הַקָּנִ֖ים מִמֶּ֑נָּה לְשֵׁ֨שֶׁת֙ הַקָּנִ֔ים הַיֹּֽצְאִ֖ים מִמֶּֽנָּה׃"
          },
          {
            "num": "כב",
            "txt": "כַּפְתֹּֽרֵיהֶ֥ם וּקְנֹתָ֖ם מִמֶּ֣נָּה הָי֑וּ כֻּלָּ֛הּ מִקְשָׁ֥ה אַחַ֖ת זָהָ֥ב טָהֽוֹר׃"
          },
          {
            "num": "כג",
            "txt": "וַיַּ֥עַשׂ אֶת־נֵֽרֹתֶ֖יהָ שִׁבְעָ֑ה וּמַלְקָחֶ֥יהָ וּמַחְתֹּתֶ֖יהָ זָהָ֥ב טָהֽוֹר׃"
          },
          {
            "num": "כד",
            "txt": "כִּכָּ֛ר זָהָ֥ב טָה֖וֹר עָשָׂ֣ה אֹתָ֑הּ וְאֵ֖ת כָּל־כֵּלֶֽיהָ׃ {פ}"
          },
          {
            "num": "כה",
            "txt": "וַיַּ֛עַשׂ אֶת־מִזְבַּ֥ח הַקְּטֹ֖רֶת עֲצֵ֣י שִׁטִּ֑ים אַמָּ֣ה אָרְכּוֹ֩ וְאַמָּ֨ה רָחְבּ֜וֹ רָב֗וּעַ וְאַמָּתַ֨יִם֙ קֹֽמָת֔וֹ מִמֶּ֖נּוּ הָי֥וּ קַרְנֹתָֽיו׃"
          },
          {
            "num": "כו",
            "txt": "וַיְצַ֨ף אֹת֜וֹ זָהָ֣ב טָה֗וֹר אֶת־גַּגּ֧וֹ וְאֶת־קִֽירֹתָ֛יו סָבִ֖יב וְאֶת־קַרְנֹתָ֑יו וַיַּ֥עַשׂ ל֛וֹ זֵ֥ר זָהָ֖ב סָבִֽיב׃"
          },
          {
            "num": "כז",
            "txt": "וּשְׁתֵּי֩ טַבְּעֹ֨ת זָהָ֜ב עָֽשָׂה־ל֣וֹ ׀ מִתַּ֣חַת לְזֵר֗וֹ עַ֚ל שְׁתֵּ֣י צַלְעֹתָ֔יו עַ֖ל שְׁנֵ֣י צִדָּ֑יו לְבָתִּ֣ים לְבַדִּ֔ים לָשֵׂ֥את אֹת֖וֹ בָּהֶֽם׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּ֥עַשׂ אֶת־הַבַּדִּ֖ים עֲצֵ֣י שִׁטִּ֑ים וַיְצַ֥ף אֹתָ֖ם זָהָֽב׃"
          },
          {
            "num": "כט",
            "txt": "וַיַּ֜עַשׂ אֶת־שֶׁ֤מֶן הַמִּשְׁחָה֙ קֹ֔דֶשׁ וְאֶת־קְטֹ֥רֶת הַסַּמִּ֖ים טָה֑וֹר מַֽעֲשֵׂ֖ה רֹקֵֽחַ׃ {ס}"
          }
        ]
      },
      {
        "num": "לח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּ֛עַשׂ אֶת־מִזְבַּ֥ח הָֽעֹלָ֖ה עֲצֵ֣י שִׁטִּ֑ים חָמֵשׁ֩ אַמּ֨וֹת אָרְכּ֜וֹ וְחָֽמֵשׁ־אַמּ֤וֹת רָחְבּוֹ֙ רָב֔וּעַ וְשָׁלֹ֥שׁ אַמּ֖וֹת קֹֽמָתֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וַיַּ֣עַשׂ קַרְנֹתָ֗יו עַ֚ל אַרְבַּ֣ע פִּנֹּתָ֔יו מִמֶּ֖נּוּ הָי֣וּ קַרְנֹתָ֑יו וַיְצַ֥ף אֹת֖וֹ נְחֹֽשֶׁת׃"
          },
          {
            "num": "ג",
            "txt": "וַיַּ֜עַשׂ אֶֽת־כָּל־כְּלֵ֣י הַמִּזְבֵּ֗חַ אֶת־הַסִּירֹ֤ת וְאֶת־הַיָּעִים֙ וְאֶת־הַמִּזְרָקֹ֔ת אֶת־הַמִּזְלָגֹ֖ת וְאֶת־הַמַּחְתֹּ֑ת כָּל־כֵּלָ֖יו עָשָׂ֥ה נְחֹֽשֶׁת׃"
          },
          {
            "num": "ד",
            "txt": "וַיַּ֤עַשׂ לַמִּזְבֵּ֨חַ֙ מִכְבָּ֔ר מַֽעֲשֵׂ֖ה רֶ֣שֶׁת נְחֹ֑שֶׁת תַּ֧חַת כַּרְכֻּבּ֛וֹ מִלְּמַ֖טָּה עַד־חֶצְיֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּצֹ֞ק אַרְבַּ֧ע טַבָּעֹ֛ת בְּאַרְבַּ֥ע הַקְּצָוֺ֖ת לְמִכְבַּ֣ר הַנְּחֹ֑שֶׁת בָּתִּ֖ים לַבַּדִּֽים׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּ֥עַשׂ אֶת־הַבַּדִּ֖ים עֲצֵ֣י שִׁטִּ֑ים וַיְצַ֥ף אֹתָ֖ם נְחֹֽשֶׁת׃"
          },
          {
            "num": "ז",
            "txt": "וַיָּבֵ֨א אֶת־הַבַּדִּ֜ים בַּטַּבָּעֹ֗ת עַ֚ל צַלְעֹ֣ת הַמִּזְבֵּ֔חַ לָשֵׂ֥את אֹת֖וֹ בָּהֶ֑ם נְב֥וּב לֻחֹ֖ת עָשָׂ֥ה אֹתֽוֹ׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "וַיַּ֗עַשׂ אֵ֚ת הַכִּיּ֣וֹר נְחֹ֔שֶׁת וְאֵ֖ת כַּנּ֣וֹ נְחֹ֑שֶׁת בְּמַרְאֹת֙ הַצֹּ֣בְאֹ֔ת אֲשֶׁ֣ר צָֽבְא֔וּ פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "וַיַּ֖עַשׂ אֶת־הֶֽחָצֵ֑ר לִפְאַ֣ת ׀ נֶ֣גֶב תֵּימָ֗נָה קַלְעֵ֤י הֶֽחָצֵר֙ שֵׁ֣שׁ מָשְׁזָ֔ר מֵאָ֖ה בָּֽאַמָּֽה׃"
          },
          {
            "num": "י",
            "txt": "עַמּֽוּדֵיהֶ֣ם עֶשְׂרִ֔ים וְאַדְנֵיהֶ֥ם עֶשְׂרִ֖ים נְחֹ֑שֶׁת וָוֵ֧י הָֽעַמּוּדִ֛ים וַחֲשֻֽׁקֵיהֶ֖ם כָּֽסֶף׃"
          },
          {
            "num": "יא",
            "txt": "וְלִפְאַ֤ת צָפוֹן֙ מֵאָ֣ה בָֽאַמָּ֔ה עַמּֽוּדֵיהֶ֣ם עֶשְׂרִ֔ים וְאַדְנֵיהֶ֥ם עֶשְׂרִ֖ים נְחֹ֑שֶׁת וָוֵ֧י הָֽעַמּוּדִ֛ים וַחֲשֻֽׁקֵיהֶ֖ם כָּֽסֶף׃"
          },
          {
            "num": "יב",
            "txt": "וְלִפְאַת־יָ֗ם קְלָעִים֙ חֲמִשִּׁ֣ים בָּֽאַמָּ֔ה עַמּֽוּדֵיהֶ֣ם עֲשָׂרָ֔ה וְאַדְנֵיהֶ֖ם עֲשָׂרָ֑ה וָוֵ֧י הָֽעַמֻּדִ֛ים וַחֲשֽׁוּקֵיהֶ֖ם כָּֽסֶף׃"
          },
          {
            "num": "יג",
            "txt": "וְלִפְאַ֛ת קֵ֥דְמָה מִזְרָ֖חָה חֲמִשִּׁ֥ים אַמָּֽה׃"
          },
          {
            "num": "יד",
            "txt": "קְלָעִ֛ים חֲמֵשׁ־עֶשְׂרֵ֥ה אַמָּ֖ה אֶל־הַכָּתֵ֑ף עַמּֽוּדֵיהֶ֣ם שְׁלֹשָׁ֔ה וְאַדְנֵיהֶ֖ם שְׁלֹשָֽׁה׃"
          },
          {
            "num": "טו",
            "txt": "וְלַכָּתֵ֣ף הַשֵּׁנִ֗ית מִזֶּ֤ה וּמִזֶּה֙ לְשַׁ֣עַר הֶֽחָצֵ֔ר קְלָעִ֕ים חֲמֵ֥שׁ עֶשְׂרֵ֖ה אַמָּ֑ה עַמֻּֽדֵיהֶ֣ם שְׁלֹשָׁ֔ה וְאַדְנֵיהֶ֖ם שְׁלֹשָֽׁה׃"
          },
          {
            "num": "טז",
            "txt": "כָּל־קַלְעֵ֧י הֶֽחָצֵ֛ר סָבִ֖יב שֵׁ֥שׁ מָשְׁזָֽר׃"
          },
          {
            "num": "יז",
            "txt": "וְהָֽאֲדָנִ֣ים לָֽעַמֻּדִים֮ נְחֹשֶׁת֒ וָוֵ֨י הָֽעַמּוּדִ֜ים וַחֲשֽׁוּקֵיהֶם֙ כֶּ֔סֶף וְצִפּ֥וּי רָֽאשֵׁיהֶ֖ם כָּ֑סֶף וְהֵם֙ מְחֻשָּׁקִ֣ים כֶּ֔סֶף כֹּ֖ל עַמֻּדֵ֥י הֶֽחָצֵֽר׃"
          },
          {
            "num": "יח",
            "txt": "וּמָסַ֞ךְ שַׁ֤עַר הֶֽחָצֵר֙ מַֽעֲשֵׂ֣ה רֹקֵ֔ם תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֣שׁ מָשְׁזָ֑ר וְעֶשְׂרִ֤ים אַמָּה֙ אֹ֔רֶךְ וְקוֹמָ֤ה בְרֹ֨חַב֙ חָמֵ֣שׁ אַמּ֔וֹת לְעֻמַּ֖ת קַלְעֵ֥י הֶֽחָצֵֽר׃"
          },
          {
            "num": "יט",
            "txt": "וְעַמֻּֽדֵיהֶם֙ אַרְבָּעָ֔ה וְאַדְנֵיהֶ֥ם אַרְבָּעָ֖ה נְחֹ֑שֶׁת וָֽוֵיהֶ֣ם כֶּ֔סֶף וְצִפּ֧וּי רָֽאשֵׁיהֶ֛ם וַחֲשֻֽׁקֵיהֶ֖ם כָּֽסֶף׃"
          },
          {
            "num": "כ",
            "txt": "וְֽכָל־הַיְתֵדֹ֞ת לַמִּשְׁכָּ֧ן וְלֶֽחָצֵ֛ר סָבִ֖יב נְחֹֽשֶׁת׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "אֵ֣לֶּה פְקוּדֵ֤י הַמִּשְׁכָּן֙ מִשְׁכַּ֣ן הָֽעֵדֻ֔ת אֲשֶׁ֥ר פֻּקַּ֖ד עַל־פִּ֣י מֹשֶׁ֑ה עֲבֹדַת֙ הַלְוִיִּ֔ם בְּיַד֙ אִֽיתָמָ֔ר בֶּֽן־אַהֲרֹ֖ן הַכֹּהֵֽן׃"
          },
          {
            "num": "כב",
            "txt": "וּבְצַלְאֵ֛ל בֶּן־אוּרִ֥י בֶן־ח֖וּר לְמַטֵּ֣ה יְהוּדָ֑ה עָשָׂ֕ה אֵ֛ת כָּל־אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "כג",
            "txt": "וְאִתּ֗וֹ אָֽהֳלִיאָ֞ב בֶּן־אֲחִֽיסָמָ֛ךְ לְמַטֵּה־דָ֖ן חָרָ֣שׁ וְחֹשֵׁ֑ב וְרֹקֵ֗ם בַּתְּכֵ֨לֶת֙ וּבָֽאַרְגָּמָ֔ן וּבְתוֹלַ֥עַת הַשָּׁנִ֖י וּבַשֵּֽׁשׁ׃ {ס}"
          },
          {
            "num": "כד",
            "txt": "כָּל־הַזָּהָ֗ב הֶֽעָשׂוּי֙ לַמְּלָאכָ֔ה בְּכֹ֖ל מְלֶ֣אכֶת הַקֹּ֑דֶשׁ וַיְהִ֣י ׀ זְהַ֣ב הַתְּנוּפָ֗ה תֵּ֤שַׁע וְעֶשְׂרִים֙ כִּכָּ֔ר וּשְׁבַ֨ע מֵא֧וֹת וּשְׁלֹשִׁ֛ים שֶׁ֖קֶל בְּשֶׁ֥קֶל הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "כה",
            "txt": "וְכֶ֛סֶף פְּקוּדֵ֥י הָֽעֵדָ֖ה מְאַ֣ת כִּכָּ֑ר וְאֶלֶף֩ וּשְׁבַ֨ע מֵא֜וֹת וַֽחֲמִשָּׁ֧ה וְשִׁבְעִ֛ים שֶׁ֖קֶל בְּשֶׁ֥קֶל הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "כו",
            "txt": "בֶּ֚קַע לַגֻּלְגֹּ֔לֶת מַֽחֲצִ֥ית הַשֶּׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ לְכֹ֨ל הָֽעֹבֵ֜ר עַל־הַפְּקֻדִ֗ים מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה לְשֵׁשׁ־מֵא֥וֹת אֶ֨לֶף֙ וּשְׁלֹ֣שֶׁת אֲלָפִ֔ים וַֽחֲמֵ֥שׁ מֵא֖וֹת וַֽחֲמִשִּֽׁים׃"
          },
          {
            "num": "כז",
            "txt": "וַיְהִ֗י מְאַת֙ כִּכַּ֣ר הַכֶּ֔סֶף לָצֶ֗קֶת אֵ֚ת אַדְנֵ֣י הַקֹּ֔דֶשׁ וְאֵ֖ת אַדְנֵ֣י הַפָּרֹ֑כֶת מְאַ֧ת אֲדָנִ֛ים לִמְאַ֥ת הַכִּכָּ֖ר כִּכָּ֥ר לָאָֽדֶן׃"
          },
          {
            "num": "כח",
            "txt": "וְאֶת־הָאֶ֜לֶף וּשְׁבַ֤ע הַמֵּאוֹת֙ וַֽחֲמִשָּׁ֣ה וְשִׁבְעִ֔ים עָשָׂ֥ה וָוִ֖ים לָֽעַמּוּדִ֑ים וְצִפָּ֥ה רָֽאשֵׁיהֶ֖ם וְחִשַּׁ֥ק אֹתָֽם׃"
          },
          {
            "num": "כט",
            "txt": "וּנְחֹ֥שֶׁת הַתְּנוּפָ֖ה שִׁבְעִ֣ים כִּכָּ֑ר וְאַלְפַּ֥יִם וְאַרְבַּע־מֵא֖וֹת שָֽׁקֶל׃"
          },
          {
            "num": "ל",
            "txt": "וַיַּ֣עַשׂ בָּ֗הּ אֶת־אַדְנֵי֙ פֶּ֚תַח אֹ֣הֶל מוֹעֵ֔ד וְאֵת֙ מִזְבַּ֣ח הַנְּחֹ֔שֶׁת וְאֶת־מִכְבַּ֥ר הַנְּחֹ֖שֶׁת אֲשֶׁר־ל֑וֹ וְאֵ֖ת כָּל־כְּלֵ֥י הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "לא",
            "txt": "וְאֶת־אַדְנֵ֤י הֶֽחָצֵר֙ סָבִ֔יב וְאֶת־אַדְנֵ֖י שַׁ֣עַר הֶֽחָצֵ֑ר וְאֵ֨ת כָּל־יִתְדֹ֧ת הַמִּשְׁכָּ֛ן וְאֶת־כָּל־יִתְדֹ֥ת הֶֽחָצֵ֖ר סָבִֽיב׃"
          }
        ]
      },
      {
        "num": "לט",
        "verses": [
          {
            "num": "א",
            "txt": "וּמִן־הַתְּכֵ֤לֶת וְהָֽאַרְגָּמָן֙ וְתוֹלַ֣עַת הַשָּׁנִ֔י עָשׂ֥וּ בִגְדֵֽי־שְׂרָ֖ד לְשָׁרֵ֣ת בַּקֹּ֑דֶשׁ וַֽיַּעֲשׂ֞וּ אֶת־בִּגְדֵ֤י הַקֹּ֨דֶשׁ֙ אֲשֶׁ֣ר לְאַֽהֲרֹ֔ן כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          },
          {
            "num": "ב",
            "txt": "וַיַּ֖עַשׂ אֶת־הָֽאֵפֹ֑ד זָהָ֗ב תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֥שׁ מָשְׁזָֽר׃"
          },
          {
            "num": "ג",
            "txt": "וַֽיְרַקְּע֞וּ אֶת־פַּחֵ֣י הַזָּהָב֮ וְקִצֵּ֣ץ פְּתִילִם֒ לַֽעֲשׂ֗וֹת בְּת֤וֹךְ הַתְּכֵ֨לֶת֙ וּבְת֣וֹךְ הָֽאַרְגָּמָ֔ן וּבְת֛וֹךְ תּוֹלַ֥עַת הַשָּׁנִ֖י וּבְת֣וֹךְ הַשֵּׁ֑שׁ מַֽעֲשֵׂ֖ה חֹשֵֽׁב׃"
          },
          {
            "num": "ד",
            "txt": "כְּתֵפֹ֥ת עָֽשׂוּ־ל֖וֹ חֹֽבְרֹ֑ת עַל־שְׁנֵ֥י קצוותו (קְצוֹתָ֖יו) חֻבָּֽר׃"
          },
          {
            "num": "ה",
            "txt": "וְחֵ֨שֶׁב אֲפֻדָּת֜וֹ אֲשֶׁ֣ר עָלָ֗יו מִמֶּ֣נּוּ הוּא֮ כְּמַֽעֲשֵׂהוּ֒ זָהָ֗ב תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֣שׁ מָשְׁזָ֑ר כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "ו",
            "txt": "וַֽיַּעֲשׂוּ֙ אֶת־אַבְנֵ֣י הַשֹּׁ֔הַם מֻֽסַבֹּ֖ת מִשְׁבְּצֹ֣ת זָהָ֑ב מְפֻתָּחֹת֙ פִּתּוּחֵ֣י חוֹתָ֔ם עַל־שְׁמ֖וֹת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ז",
            "txt": "וַיָּ֣שֶׂם אֹתָ֗ם עַ֚ל כִּתְפֹ֣ת הָֽאֵפֹ֔ד אַבְנֵ֥י זִכָּר֖וֹן לִבְנֵ֣י יִשְׂרָאֵ֑ל כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "וַיַּ֧עַשׂ אֶת־הַחֹ֛שֶׁן מַֽעֲשֵׂ֥ה חֹשֵׁ֖ב כְּמַֽעֲשֵׂ֣ה אֵפֹ֑ד זָהָ֗ב תְּכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י וְשֵׁ֥שׁ מָשְׁזָֽר׃"
          },
          {
            "num": "ט",
            "txt": "רָב֧וּעַ הָיָ֛ה כָּפ֖וּל עָשׂ֣וּ אֶת־הַחֹ֑שֶׁן זֶ֧רֶת אָרְכּ֛וֹ וְזֶ֥רֶת רָחְבּ֖וֹ כָּפֽוּל׃"
          },
          {
            "num": "י",
            "txt": "וַיְמַ֨לְאוּ־ב֔וֹ אַרְבָּעָ֖ה ט֣וּרֵי אָ֑בֶן ט֗וּר אֹ֤דֶם פִּטְדָה֙ וּבָרֶ֔קֶת הַטּ֖וּר הָֽאֶחָֽד׃"
          },
          {
            "num": "יא",
            "txt": "וְהַטּ֖וּר הַשֵּׁנִ֑י נֹ֥פֶךְ סַפִּ֖יר וְיָֽהֲלֹֽם׃"
          },
          {
            "num": "יב",
            "txt": "וְהַטּ֖וּר הַשְּׁלִישִׁ֑י לֶ֥שֶׁם שְׁב֖וֹ וְאַחְלָֽמָה׃"
          },
          {
            "num": "יג",
            "txt": "וְהַטּוּר֙ הָֽרְבִיעִ֔י תַּרְשִׁ֥ישׁ שֹׁ֖הַם וְיָֽשְׁפֵ֑ה מֽוּסַבֹּ֛ת מִשְׁבְּצֹ֥ת זָהָ֖ב בְּמִלֻּֽאֹתָֽם׃"
          },
          {
            "num": "יד",
            "txt": "וְ֠הָֽאֲבָנִים עַל־שְׁמֹ֨ת בְּנֵֽי־יִשְׂרָאֵ֥ל הֵ֛נָּה שְׁתֵּ֥ים עֶשְׂרֵ֖ה עַל־שְׁמֹתָ֑ם פִּתּוּחֵ֤י חֹתָם֙ אִ֣ישׁ עַל־שְׁמ֔וֹ לִשְׁנֵ֥ים עָשָׂ֖ר שָֽׁבֶט׃"
          },
          {
            "num": "טו",
            "txt": "וַיַּֽעֲשׂ֧וּ עַל־הַחֹ֛שֶׁן שַׁרְשְׁרֹ֥ת גַּבְלֻ֖ת מַֽעֲשֵׂ֣ה עֲבֹ֑ת זָהָ֖ב טָהֽוֹר׃"
          },
          {
            "num": "טז",
            "txt": "וַֽיַּעֲשׂ֗וּ שְׁתֵּי֙ מִשְׁבְּצֹ֣ת זָהָ֔ב וּשְׁתֵּ֖י טַבְּעֹ֣ת זָהָ֑ב וַֽיִּתְּנ֗וּ אֶת־שְׁתֵּי֙ הַטַּבָּעֹ֔ת עַל־שְׁנֵ֖י קְצ֥וֹת הַחֹֽשֶׁן׃"
          },
          {
            "num": "יז",
            "txt": "וַֽיִּתְּנ֗וּ שְׁתֵּי֙ הָֽעֲבֹתֹ֣ת הַזָּהָ֔ב עַל־שְׁתֵּ֖י הַטַּבָּעֹ֑ת עַל־קְצ֖וֹת הַחֹֽשֶׁן׃"
          },
          {
            "num": "יח",
            "txt": "וְאֵ֨ת שְׁתֵּ֤י קְצוֹת֙ שְׁתֵּ֣י הָֽעֲבֹתֹ֔ת נָֽתְנ֖וּ עַל־שְׁתֵּ֣י הַֽמִּשְׁבְּצֹ֑ת וַֽיִּתְּנֻ֛ם עַל־כִּתְפֹ֥ת הָֽאֵפֹ֖ד אֶל־מ֥וּל פָּנָֽיו׃"
          },
          {
            "num": "יט",
            "txt": "וַֽיַּעֲשׂ֗וּ שְׁתֵּי֙ טַבְּעֹ֣ת זָהָ֔ב וַיָּשִׂ֕ימוּ עַל־שְׁנֵ֖י קְצ֣וֹת הַחֹ֑שֶׁן עַל־שְׂפָת֕וֹ אֲשֶׁ֛ר אֶל־עֵ֥בֶר הָֽאֵפֹ֖ד בָּֽיְתָה׃"
          },
          {
            "num": "כ",
            "txt": "וַֽיַּעֲשׂוּ֮ שְׁתֵּ֣י טַבְּעֹ֣ת זָהָב֒ וַֽיִּתְּנֻ֡ם עַל־שְׁתֵּי֩ כִתְפֹ֨ת הָֽאֵפֹ֤ד מִלְּמַ֨טָּה֙ מִמּ֣וּל פָּנָ֔יו לְעֻמַּ֖ת מַחְבַּרְתּ֑וֹ מִמַּ֕עַל לְחֵ֖שֶׁב הָֽאֵפֹֽד׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּרְכְּס֣וּ אֶת־הַחֹ֡שֶׁן מִטַּבְּעֹתָיו֩ אֶל־טַבְּעֹ֨ת הָֽאֵפֹ֜ד בִּפְתִ֣יל תְּכֵ֗לֶת לִֽהְיֹת֙ עַל־חֵ֣שֶׁב הָֽאֵפֹ֔ד וְלֹֽא־יִזַּ֣ח הַחֹ֔שֶׁן מֵעַ֖ל הָֽאֵפֹ֑ד כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "וַיַּ֛עַשׂ אֶת־מְעִ֥יל הָֽאֵפֹ֖ד מַֽעֲשֵׂ֣ה אֹרֵ֑ג כְּלִ֖יל תְּכֵֽלֶת׃"
          },
          {
            "num": "כג",
            "txt": "וּפִֽי־הַמְּעִ֥יל בְּתוֹכ֖וֹ כְּפִ֣י תַחְרָ֑א שָׂפָ֥ה לְפִ֛יו סָבִ֖יב לֹ֥א יִקָּרֵֽעַ׃"
          },
          {
            "num": "כד",
            "txt": "וַֽיַּעֲשׂוּ֙ עַל־שׁוּלֵ֣י הַמְּעִ֔יל רִמּוֹנֵ֕י תְּכֵ֥לֶת וְאַרְגָּמָ֖ן וְתוֹלַ֣עַת שָׁנִ֑י מָשְׁזָֽר׃"
          },
          {
            "num": "כה",
            "txt": "וַיַּֽעֲשׂ֥וּ פַֽעֲמֹנֵ֖י זָהָ֣ב טָה֑וֹר וַיִּתְּנ֨וּ אֶת־הַפַּֽעֲמֹנִ֜ים בְּת֣וֹךְ הָֽרִמֹּנִ֗ים עַל־שׁוּלֵ֤י הַמְּעִיל֙ סָבִ֔יב בְּת֖וֹךְ הָֽרִמֹּנִֽים׃"
          },
          {
            "num": "כו",
            "txt": "פַּֽעֲמֹ֤ן וְרִמֹּן֙ פַּֽעֲמֹ֣ן וְרִמֹּ֔ן עַל־שׁוּלֵ֥י הַמְּעִ֖יל סָבִ֑יב לְשָׁרֵ֕ת כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "כז",
            "txt": "וַֽיַּעֲשׂ֛וּ אֶת־הַכָּתְנֹ֥ת שֵׁ֖שׁ מַֽעֲשֵׂ֣ה אֹרֵ֑ג לְאַֽהֲרֹ֖ן וּלְבָנָֽיו׃"
          },
          {
            "num": "כח",
            "txt": "וְאֵת֙ הַמִּצְנֶ֣פֶת שֵׁ֔שׁ וְאֶת־פַּֽאֲרֵ֥י הַמִּגְבָּעֹ֖ת שֵׁ֑שׁ וְאֶת־מִכְנְסֵ֥י הַבָּ֖ד שֵׁ֥שׁ מָשְׁזָֽר׃"
          },
          {
            "num": "כט",
            "txt": "וְֽאֶת־הָאַבְנֵ֞ט שֵׁ֣שׁ מָשְׁזָ֗ר וּתְכֵ֧לֶת וְאַרְגָּמָ֛ן וְתוֹלַ֥עַת שָׁנִ֖י מַֽעֲשֵׂ֣ה רֹקֵ֑ם כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "ל",
            "txt": "וַֽיַּעֲשׂ֛וּ אֶת־צִ֥יץ נֵֽזֶר־הַקֹּ֖דֶשׁ זָהָ֣ב טָה֑וֹר וַיִּכְתְּב֣וּ עָלָ֗יו מִכְתַּב֙ פִּתּוּחֵ֣י חוֹתָ֔ם קֹ֖דֶשׁ לַֽיהוָֽה׃"
          },
          {
            "num": "לא",
            "txt": "וַיִּתְּנ֤וּ עָלָיו֙ פְּתִ֣יל תְּכֵ֔לֶת לָתֵ֥ת עַל־הַמִּצְנֶ֖פֶת מִלְמָ֑עְלָה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "לב",
            "txt": "וַתֵּ֕כֶל כָּל־עֲבֹדַ֕ת מִשְׁכַּ֖ן אֹ֣הֶל מוֹעֵ֑ד וַֽיַּעֲשׂוּ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל כְּ֠כֹל אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֶת־מֹשֶׁ֖ה כֵּ֥ן עָשֽׂוּ׃ {פ}"
          },
          {
            "num": "לג",
            "txt": "וַיָּבִ֤יאוּ אֶת־הַמִּשְׁכָּן֙ אֶל־מֹשֶׁ֔ה אֶת־הָאֹ֖הֶל וְאֶת־כָּל־כֵּלָ֑יו קְרָסָ֣יו קְרָשָׁ֔יו בְּרִיחָ֖ו וְעַמֻּדָ֥יו וַֽאֲדָנָֽיו׃"
          },
          {
            "num": "לד",
            "txt": "וְאֶת־מִכְסֵ֞ה עוֹרֹ֤ת הָֽאֵילִם֙ הַמְאָדָּמִ֔ים וְאֶת־מִכְסֵ֖ה עֹרֹ֣ת הַתְּחָשִׁ֑ים וְאֵ֖ת פָּרֹ֥כֶת הַמָּסָֽךְ׃"
          },
          {
            "num": "לה",
            "txt": "אֶת־אֲר֥וֹן הָֽעֵדֻ֖ת וְאֶת־בַּדָּ֑יו וְאֵ֖ת הַכַּפֹּֽרֶת׃"
          },
          {
            "num": "לו",
            "txt": "אֶת־הַשֻּׁלְחָן֙ אֶת־כָּל־כֵּלָ֔יו וְאֵ֖ת לֶ֥חֶם הַפָּנִֽים׃"
          },
          {
            "num": "לז",
            "txt": "אֶת־הַמְּנֹרָ֨ה הַטְּהֹרָ֜ה אֶת־נֵֽרֹתֶ֗יהָ נֵרֹ֛ת הַמַּֽעֲרָכָ֖ה וְאֶת־כָּל־כֵּלֶ֑יהָ וְאֵ֖ת שֶׁ֥מֶן הַמָּאֽוֹר׃"
          },
          {
            "num": "לח",
            "txt": "וְאֵת֙ מִזְבַּ֣ח הַזָּהָ֔ב וְאֵת֙ שֶׁ֣מֶן הַמִּשְׁחָ֔ה וְאֵ֖ת קְטֹ֣רֶת הַסַּמִּ֑ים וְאֵ֕ת מָסַ֖ךְ פֶּ֥תַח הָאֹֽהֶל׃"
          },
          {
            "num": "לט",
            "txt": "אֵ֣ת ׀ מִזְבַּ֣ח הַנְּחֹ֗שֶׁת וְאֶת־מִכְבַּ֤ר הַנְּחֹ֨שֶׁת֙ אֲשֶׁר־ל֔וֹ אֶת־בַּדָּ֖יו וְאֶת־כָּל־כֵּלָ֑יו אֶת־הַכִּיֹּ֖ר וְאֶת־כַּנּֽוֹ׃"
          },
          {
            "num": "מ",
            "txt": "אֵת֩ קַלְעֵ֨י הֶֽחָצֵ֜ר אֶת־עַמֻּדֶ֣יהָ וְאֶת־אֲדָנֶ֗יהָ וְאֶת־הַמָּסָךְ֙ לְשַׁ֣עַר הֶֽחָצֵ֔ר אֶת־מֵֽיתָרָ֖יו וִיתֵֽדֹתֶ֑יהָ וְאֵ֗ת כָּל־כְּלֵ֛י עֲבֹדַ֥ת הַמִּשְׁכָּ֖ן לְאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "מא",
            "txt": "אֶת־בִּגְדֵ֥י הַשְּׂרָ֖ד לְשָׁרֵ֣ת בַּקֹּ֑דֶשׁ אֶת־בִּגְדֵ֤י הַקֹּ֨דֶשׁ֙ לְאַֽהֲרֹ֣ן הַכֹּהֵ֔ן וְאֶת־בִּגְדֵ֥י בָנָ֖יו לְכַהֵֽן׃"
          },
          {
            "num": "מב",
            "txt": "כְּכֹ֛ל אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶׁ֑ה כֵּ֤ן עָשׂוּ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל אֵ֖ת כָּל־הָֽעֲבֹדָֽה׃"
          },
          {
            "num": "מג",
            "txt": "וַיַּ֨רְא מֹשֶׁ֜ה אֶת־כָּל־הַמְּלָאכָ֗ה וְהִנֵּה֙ עָשׂ֣וּ אֹתָ֔הּ כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה כֵּ֣ן עָשׂ֑וּ וַיְבָ֥רֶךְ אֹתָ֖ם מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "מ",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "בְּיוֹם־הַחֹ֥דֶשׁ הָֽרִאשׁ֖וֹן בְּאֶחָ֣ד לַחֹ֑דֶשׁ תָּקִ֕ים אֶת־מִשְׁכַּ֖ן אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ג",
            "txt": "וְשַׂמְתָּ֣ שָׁ֔ם אֵ֖ת אֲר֣וֹן הָֽעֵד֑וּת וְסַכֹּתָ֥ עַל־הָֽאָרֹ֖ן אֶת־הַפָּרֹֽכֶת׃"
          },
          {
            "num": "ד",
            "txt": "וְהֵֽבֵאתָ֙ אֶת־הַשֻּׁלְחָ֔ן וְעָֽרַכְתָּ֖ אֶת־עֶרְכּ֑וֹ וְהֵֽבֵאתָ֙ אֶת־הַמְּנֹרָ֔ה וְהַֽעֲלֵיתָ֖ אֶת־נֵֽרֹתֶֽיהָ׃"
          },
          {
            "num": "ה",
            "txt": "וְנָֽתַתָּ֞ה אֶת־מִזְבַּ֤ח הַזָּהָב֙ לִקְטֹ֔רֶת לִפְנֵ֖י אֲר֣וֹן הָֽעֵדֻ֑ת וְשַׂמְתָּ֛ אֶת־מָסַ֥ךְ הַפֶּ֖תַח לַמִּשְׁכָּֽן׃"
          },
          {
            "num": "ו",
            "txt": "וְנָ֣תַתָּ֔ה אֵ֖ת מִזְבַּ֣ח הָֽעֹלָ֑ה לִפְנֵ֕י פֶּ֖תַח מִשְׁכַּ֥ן אֹֽהֶל־מוֹעֵֽד׃"
          },
          {
            "num": "ז",
            "txt": "וְנָֽתַתָּ֙ אֶת־הַכִּיֹּ֔ר בֵּֽין־אֹ֥הֶל מוֹעֵ֖ד וּבֵ֣ין הַמִּזְבֵּ֑חַ וְנָֽתַתָּ֥ שָׁ֖ם מָֽיִם׃"
          },
          {
            "num": "ח",
            "txt": "וְשַׂמְתָּ֥ אֶת־הֶֽחָצֵ֖ר סָבִ֑יב וְנָ֣תַתָּ֔ אֶת־מָסַ֖ךְ שַׁ֥עַר הֶֽחָצֵֽר׃"
          },
          {
            "num": "ט",
            "txt": "וְלָֽקַחְתָּ֙ אֶת־שֶׁ֣מֶן הַמִּשְׁחָ֔ה וּמָֽשַׁחְתָּ֥ אֶת־הַמִּשְׁכָּ֖ן וְאֶת־כָּל־אֲשֶׁר־בּ֑וֹ וְקִדַּשְׁתָּ֥ אֹת֛וֹ וְאֶת־כָּל־כֵּלָ֖יו וְהָ֥יָה קֹֽדֶשׁ׃"
          },
          {
            "num": "י",
            "txt": "וּמָֽשַׁחְתָּ֛ אֶת־מִזְבַּ֥ח הָֽעֹלָ֖ה וְאֶת־כָּל־כֵּלָ֑יו וְקִדַּשְׁתָּ֙ אֶת־הַמִּזְבֵּ֔חַ וְהָיָ֥ה הַמִּזְבֵּ֖חַ קֹ֥דֶשׁ קָֽדָשִֽׁים׃"
          },
          {
            "num": "יא",
            "txt": "וּמָֽשַׁחְתָּ֥ אֶת־הַכִּיֹּ֖ר וְאֶת־כַּנּ֑וֹ וְקִדַּשְׁתָּ֖ אֹתֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וְהִקְרַבְתָּ֤ אֶֽת־אַהֲרֹן֙ וְאֶת־בָּנָ֔יו אֶל־פֶּ֖תַח אֹ֣הֶל מוֹעֵ֑ד וְרָֽחַצְתָּ֥ אֹתָ֖ם בַּמָּֽיִם׃"
          },
          {
            "num": "יג",
            "txt": "וְהִלְבַּשְׁתָּ֙ אֶֽת־אַהֲרֹ֔ן אֵ֖ת בִּגְדֵ֣י הַקֹּ֑דֶשׁ וּמָֽשַׁחְתָּ֥ אֹת֛וֹ וְקִדַּשְׁתָּ֥ אֹת֖וֹ וְכִהֵ֥ן לִֽי׃"
          },
          {
            "num": "יד",
            "txt": "וְאֶת־בָּנָ֖יו תַּקְרִ֑יב וְהִלְבַּשְׁתָּ֥ אֹתָ֖ם כֻּתֳּנֹֽת׃"
          },
          {
            "num": "טו",
            "txt": "וּמָֽשַׁחְתָּ֣ אֹתָ֗ם כַּֽאֲשֶׁ֤ר מָשַׁ֨חְתָּ֙ אֶת־אֲבִיהֶ֔ם וְכִֽהֲנ֖וּ לִ֑י וְ֠הָֽיְתָה לִֽהְיֹ֨ת לָהֶ֧ם מָשְׁחָתָ֛ם לִכְהֻנַּ֥ת עוֹלָ֖ם לְדֹֽרֹתָֽם׃"
          },
          {
            "num": "טז",
            "txt": "וַיַּ֖עַשׂ מֹשֶׁ֑ה כְּ֠כֹל אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֹת֖וֹ כֵּ֥ן עָשָֽׂה׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וַיְהִ֞י בַּחֹ֧דֶשׁ הָֽרִאשׁ֛וֹן בַּשָּׁנָ֥ה הַשֵּׁנִ֖ית בְּאֶחָ֣ד לַחֹ֑דֶשׁ הוּקַ֖ם הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "יח",
            "txt": "וַיָּ֨קֶם מֹשֶׁ֜ה אֶת־הַמִּשְׁכָּ֗ן וַיִּתֵּן֙ אֶת־אֲדָנָ֔יו וַיָּ֨שֶׂם֙ אֶת־קְרָשָׁ֔יו וַיִּתֵּ֖ן אֶת־בְּרִיחָ֑יו וַיָּ֖קֶם אֶת־עַמּוּדָֽיו׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּפְרֹ֤שׂ אֶת־הָאֹ֨הֶל֙ עַל־הַמִּשְׁכָּ֔ן וַיָּ֜שֶׂם אֶת־מִכְסֵ֥ה הָאֹ֛הֶל עָלָ֖יו מִלְמָ֑עְלָה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וַיִּקַּ֞ח וַיִּתֵּ֤ן אֶת־הָֽעֵדֻת֙ אֶל־הָ֣אָרֹ֔ן וַיָּ֥שֶׂם אֶת־הַבַּדִּ֖ים עַל־הָֽאָרֹ֑ן וַיִּתֵּ֧ן אֶת־הַכַּפֹּ֛רֶת עַל־הָֽאָרֹ֖ן מִלְמָֽעְלָה׃"
          },
          {
            "num": "כא",
            "txt": "וַיָּבֵ֣א אֶת־הָֽאָרֹן֮ אֶל־הַמִּשְׁכָּן֒ וַיָּ֗שֶׂם אֵ֚ת פָּרֹ֣כֶת הַמָּסָ֔ךְ וַיָּ֕סֶךְ עַ֖ל אֲר֣וֹן הָֽעֵד֑וּת כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "וַיִּתֵּ֤ן אֶת־הַשֻּׁלְחָן֙ בְּאֹ֣הֶל מוֹעֵ֔ד עַ֛ל יֶ֥רֶךְ הַמִּשְׁכָּ֖ן צָפֹ֑נָה מִח֖וּץ לַפָּרֹֽכֶת׃"
          },
          {
            "num": "כג",
            "txt": "וַיַּֽעֲרֹ֥ךְ עָלָ֛יו עֵ֥רֶךְ לֶ֖חֶם לִפְנֵ֣י יְהוָ֑ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "כד",
            "txt": "וַיָּ֤שֶׂם אֶת־הַמְּנֹרָה֙ בְּאֹ֣הֶל מוֹעֵ֔ד נֹ֖כַח הַשֻּׁלְחָ֑ן עַ֛ל יֶ֥רֶךְ הַמִּשְׁכָּ֖ן נֶֽגְבָּה׃"
          },
          {
            "num": "כה",
            "txt": "וַיַּ֥עַל הַנֵּרֹ֖ת לִפְנֵ֣י יְהוָ֑ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "וַיָּ֛שֶׂם אֶת־מִזְבַּ֥ח הַזָּהָ֖ב בְּאֹ֣הֶל מוֹעֵ֑ד לִפְנֵ֖י הַפָּרֹֽכֶת׃"
          },
          {
            "num": "כז",
            "txt": "וַיַּקְטֵ֥ר עָלָ֖יו קְטֹ֣רֶת סַמִּ֑ים כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "כח",
            "txt": "וַיָּ֛שֶׂם אֶת־מָסַ֥ךְ הַפֶּ֖תַח לַמִּשְׁכָּֽן׃"
          },
          {
            "num": "כט",
            "txt": "וְאֵת֙ מִזְבַּ֣ח הָֽעֹלָ֔ה שָׂ֕ם פֶּ֖תַח מִשְׁכַּ֣ן אֹֽהֶל־מוֹעֵ֑ד וַיַּ֣עַל עָלָ֗יו אֶת־הָֽעֹלָה֙ וְאֶת־הַמִּנְחָ֔ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "ל",
            "txt": "וַיָּ֨שֶׂם֙ אֶת־הַכִּיֹּ֔ר בֵּֽין־אֹ֥הֶל מוֹעֵ֖ד וּבֵ֣ין הַמִּזְבֵּ֑חַ וַיִּתֵּ֥ן שָׁ֛מָּה מַ֖יִם לְרָחְצָֽה׃"
          },
          {
            "num": "לא",
            "txt": "וְרָֽחֲצ֣וּ מִמֶּ֔נּוּ מֹשֶׁ֖ה וְאַֽהֲרֹ֣ן וּבָנָ֑יו אֶת־יְדֵיהֶ֖ם וְאֶת־רַגְלֵיהֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "בְּבֹאָ֞ם אֶל־אֹ֣הֶל מוֹעֵ֗ד וּבְקָרְבָתָ֛ם אֶל־הַמִּזְבֵּ֖חַ יִרְחָ֑צוּ כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "לג",
            "txt": "וַיָּ֣קֶם אֶת־הֶֽחָצֵ֗ר סָבִיב֙ לַמִּשְׁכָּ֣ן וְלַמִּזְבֵּ֔חַ וַיִּתֵּ֕ן אֶת־מָסַ֖ךְ שַׁ֣עַר הֶֽחָצֵ֑ר וַיְכַ֥ל מֹשֶׁ֖ה אֶת־הַמְּלָאכָֽה׃ {פ}"
          },
          {
            "num": "לד",
            "txt": "וַיְכַ֥ס הֶֽעָנָ֖ן אֶת־אֹ֣הֶל מוֹעֵ֑ד וּכְב֣וֹד יְהוָ֔ה מָלֵ֖א אֶת־הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "לה",
            "txt": "וְלֹֽא־יָכֹ֣ל מֹשֶׁ֗ה לָבוֹא֙ אֶל־אֹ֣הֶל מוֹעֵ֔ד כִּֽי־שָׁכַ֥ן עָלָ֖יו הֶֽעָנָ֑ן וּכְב֣וֹד יְהוָ֔ה מָלֵ֖א אֶת־הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "לו",
            "txt": "וּבְהֵֽעָל֤וֹת הֶֽעָנָן֙ מֵעַ֣ל הַמִּשְׁכָּ֔ן יִסְע֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל בְּכֹ֖ל מַסְעֵיהֶֽם׃"
          },
          {
            "num": "לז",
            "txt": "וְאִם־לֹ֥א יֵֽעָלֶ֖ה הֶֽעָנָ֑ן וְלֹ֣א יִסְע֔וּ עַד־י֖וֹם הֵעָֽלֹתֽוֹ׃"
          },
          {
            "num": "לח",
            "txt": "כִּי֩ עֲנַ֨ן יְהוָ֤ה עַֽל־הַמִּשְׁכָּן֙ יוֹמָ֔ם וְאֵ֕שׁ תִּֽהְיֶ֥ה לַ֖יְלָה בּ֑וֹ לְעֵינֵ֥י כָל־בֵּֽית־יִשְׂרָאֵ֖ל בְּכָל־מַסְעֵיהֶֽם׃ {ש}"
          }
        ]
      }
    ]
  },
  "ויקרא": {
    "chapters": [
      {
        "num": "א",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּקְרָ֖א אֶל־מֹשֶׁ֑ה וַיְדַבֵּ֤ר יְהוָה֙ אֵלָ֔יו מֵאֹ֥הֶל מוֹעֵ֖ד לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֞ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם אָדָ֗ם כִּֽי־יַקְרִ֥יב מִכֶּ֛ם קָרְבָּ֖ן לַֽיהוָ֑ה מִן־הַבְּהֵמָ֗ה מִן־הַבָּקָר֙ וּמִן־הַצֹּ֔אן תַּקְרִ֖יבוּ אֶת־קָרְבַּנְכֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "אִם־עֹלָ֤ה קָרְבָּנוֹ֙ מִן־הַבָּקָ֔ר זָכָ֥ר תָּמִ֖ים יַקְרִיבֶ֑נּוּ אֶל־פֶּ֜תַח אֹ֤הֶל מוֹעֵד֙ יַקְרִ֣יב אֹת֔וֹ לִרְצֹנ֖וֹ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וְסָמַ֣ךְ יָד֔וֹ עַ֖ל רֹ֣אשׁ הָֽעֹלָ֑ה וְנִרְצָ֥ה ל֖וֹ לְכַפֵּ֥ר עָלָֽיו׃"
          },
          {
            "num": "ה",
            "txt": "וְשָׁחַ֛ט אֶת־בֶּ֥ן הַבָּקָ֖ר לִפְנֵ֣י יְהוָ֑ה וְ֠הִקְרִיבוּ בְּנֵ֨י אַֽהֲרֹ֤ן הַכֹּֽהֲנִים֙ אֶת־הַדָּ֔ם וְזָֽרְק֨וּ אֶת־הַדָּ֤ם עַל־הַמִּזְבֵּ֨חַ֙ סָבִ֔יב אֲשֶׁר־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ו",
            "txt": "וְהִפְשִׁ֖יט אֶת־הָֽעֹלָ֑ה וְנִתַּ֥ח אֹתָ֖הּ לִנְתָחֶֽיהָ׃"
          },
          {
            "num": "ז",
            "txt": "וְ֠נָֽתְנוּ בְּנֵ֨י אַֽהֲרֹ֧ן הַכֹּהֵ֛ן אֵ֖שׁ עַל־הַמִּזְבֵּ֑חַ וְעָֽרְכ֥וּ עֵצִ֖ים עַל־הָאֵֽשׁ׃"
          },
          {
            "num": "ח",
            "txt": "וְעָֽרְכ֗וּ בְּנֵ֤י אַֽהֲרֹן֙ הַכֹּ֣הֲנִ֔ים אֵ֚ת הַנְּתָחִ֔ים אֶת־הָרֹ֖אשׁ וְאֶת־הַפָּ֑דֶר עַל־הָֽעֵצִים֙ אֲשֶׁ֣ר עַל־הָאֵ֔שׁ אֲשֶׁ֖ר עַל־הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ט",
            "txt": "וְקִרְבּ֥וֹ וּכְרָעָ֖יו יִרְחַ֣ץ בַּמָּ֑יִם וְהִקְטִ֨יר הַכֹּהֵ֤ן אֶת־הַכֹּל֙ הַמִּזְבֵּ֔חָה עֹלָ֛ה אִשֵּׁ֥ה רֵֽיחַ־נִיח֖וֹחַ לַֽיהוָֽה׃"
          },
          {
            "num": "י",
            "txt": "וְאִם־מִן־הַצֹּ֨אן קָרְבָּנ֧וֹ מִן־הַכְּשָׂבִ֛ים א֥וֹ מִן־הָֽעִזִּ֖ים לְעֹלָ֑ה זָכָ֥ר תָּמִ֖ים יַקְרִיבֶֽנּוּ׃"
          },
          {
            "num": "יא",
            "txt": "וְשָׁחַ֨ט אֹת֜וֹ עַ֣ל יֶ֧רֶךְ הַמִּזְבֵּ֛חַ צָפֹ֖נָה לִפְנֵ֣י יְהוָ֑ה וְזָֽרְק֡וּ בְּנֵי֩ אַֽהֲרֹ֨ן הַכֹּֽהֲנִ֧ים אֶת־דָּמ֛וֹ עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "יב",
            "txt": "וְנִתַּ֤ח אֹתוֹ֙ לִנְתָחָ֔יו וְאֶת־רֹאשׁ֖וֹ וְאֶת־פִּדְר֑וֹ וְעָרַ֤ךְ הַכֹּהֵן֙ אֹתָ֔ם עַל־הָֽעֵצִים֙ אֲשֶׁ֣ר עַל־הָאֵ֔שׁ אֲשֶׁ֖ר עַל־הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "יג",
            "txt": "וְהַקֶּ֥רֶב וְהַכְּרָעַ֖יִם יִרְחַ֣ץ בַּמָּ֑יִם וְהִקְרִ֨יב הַכֹּהֵ֤ן אֶת־הַכֹּל֙ וְהִקְטִ֣יר הַמִּזְבֵּ֔חָה עֹלָ֣ה ה֗וּא אִשֵּׁ֛ה רֵ֥יחַ נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְאִ֧ם מִן־הָע֛וֹף עֹלָ֥ה קָרְבָּנ֖וֹ לַֽיהוָ֑ה וְהִקְרִ֣יב מִן־הַתֹּרִ֗ים א֛וֹ מִן־בְּנֵ֥י הַיּוֹנָ֖ה אֶת־קָרְבָּנֽוֹ׃"
          },
          {
            "num": "טו",
            "txt": "וְהִקְרִיב֤וֹ הַכֹּהֵן֙ אֶל־הַמִּזְבֵּ֔חַ וּמָלַק֙ אֶת־רֹאשׁ֔וֹ וְהִקְטִ֖יר הַמִּזְבֵּ֑חָה וְנִמְצָ֣ה דָמ֔וֹ עַ֖ל קִ֥יר הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "טז",
            "txt": "וְהֵסִ֥יר אֶת־מֻרְאָת֖וֹ בְּנֹֽצָתָ֑הּ וְהִשְׁלִ֨יךְ אֹתָ֜הּ אֵ֤צֶל הַמִּזְבֵּ֨חַ֙ קֵ֔דְמָה אֶל־מְק֖וֹם הַדָּֽשֶׁן׃"
          },
          {
            "num": "יז",
            "txt": "וְשִׁסַּ֨ע אֹת֣וֹ בִכְנָפָיו֮ לֹ֣א יַבְדִּיל֒ וְהִקְטִ֨יר אֹת֤וֹ הַכֹּהֵן֙ הַמִּזְבֵּ֔חָה עַל־הָֽעֵצִ֖ים אֲשֶׁ֣ר עַל־הָאֵ֑שׁ עֹלָ֣ה ה֗וּא אִשֵּׁ֛ה רֵ֥יחַ נִיחֹ֖חַ לַֽיהוָֽה׃"
          }
        ]
      },
      {
        "num": "ב",
        "verses": [
          {
            "num": "א",
            "txt": "וְנֶ֗פֶשׁ כִּֽי־תַקְרִ֞יב קָרְבַּ֤ן מִנְחָה֙ לַֽיהוָ֔ה סֹ֖לֶת יִֽהְיֶ֣ה קָרְבָּנ֑וֹ וְיָצַ֤ק עָלֶ֨יהָ֙ שֶׁ֔מֶן וְנָתַ֥ן עָלֶ֖יהָ לְבֹנָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וֶֽהֱבִיאָ֗הּ אֶל־בְּנֵ֣י אַֽהֲרֹן֮ הַכֹּֽהֲנִים֒ וְקָמַ֨ץ מִשָּׁ֜ם מְלֹ֣א קֻמְצ֗וֹ מִסָּלְתָּהּ֙ וּמִשַּׁמְנָ֔הּ עַ֖ל כָּל־לְבֹֽנָתָ֑הּ וְהִקְטִ֨יר הַכֹּהֵ֜ן אֶת־אַזְכָּֽרָתָהּ֙ הַמִּזְבֵּ֔חָה אִשֵּׁ֛ה רֵ֥יחַ נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וְהַנּוֹתֶ֨רֶת֙ מִן־הַמִּנְחָ֔ה לְאַֽהֲרֹ֖ן וּלְבָנָ֑יו קֹ֥דֶשׁ קָֽדָשִׁ֖ים מֵֽאִשֵּׁ֥י יְהוָֽה׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "וְכִ֥י תַקְרִ֛ב קָרְבַּ֥ן מִנְחָ֖ה מַֽאֲפֵ֣ה תַנּ֑וּר סֹ֣לֶת חַלּ֤וֹת מַצֹּת֙ בְּלוּלֹ֣ת בַּשֶּׁ֔מֶן וּרְקִיקֵ֥י מַצּ֖וֹת מְשֻׁחִ֥ים בַּשָּֽׁמֶן׃ {ס}"
          },
          {
            "num": "ה",
            "txt": "וְאִם־מִנְחָ֥ה עַל־הַֽמַּחֲבַ֖ת קָרְבָּנֶ֑ךָ סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן מַצָּ֥ה תִֽהְיֶֽה׃"
          },
          {
            "num": "ו",
            "txt": "פָּת֤וֹת אֹתָהּ֙ פִּתִּ֔ים וְיָֽצַקְתָּ֥ עָלֶ֖יהָ שָׁ֑מֶן מִנְחָ֖ה הִֽוא׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "וְאִם־מִנְחַ֥ת מַרְחֶ֖שֶׁת קָרְבָּנֶ֑ךָ סֹ֥לֶת בַּשֶּׁ֖מֶן תֵּֽעָשֶֽׂה׃"
          },
          {
            "num": "ח",
            "txt": "וְהֵֽבֵאתָ֣ אֶת־הַמִּנְחָ֗ה אֲשֶׁ֧ר יֵֽעָשֶׂ֛ה מֵאֵ֖לֶּה לַֽיהוָ֑ה וְהִקְרִיבָהּ֙ אֶל־הַכֹּהֵ֔ן וְהִגִּישָׁ֖הּ אֶל־הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ט",
            "txt": "וְהֵרִ֨ים הַכֹּהֵ֤ן מִן־הַמִּנְחָה֙ אֶת־אַזְכָּ֣רָתָ֔הּ וְהִקְטִ֖יר הַמִּזְבֵּ֑חָה אִשֵּׁ֛ה רֵ֥יחַ נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "י",
            "txt": "וְהַנּוֹתֶ֨רֶת֙ מִן־הַמִּנְחָ֔ה לְאַֽהֲרֹ֖ן וּלְבָנָ֑יו קֹ֥דֶשׁ קָֽדָשִׁ֖ים מֵֽאִשֵּׁ֥י יְהוָֽה׃"
          },
          {
            "num": "יא",
            "txt": "כָּל־הַמִּנְחָ֗ה אֲשֶׁ֤ר תַּקְרִ֨יבוּ֙ לַֽיהוָ֔ה לֹ֥א תֵֽעָשֶׂ֖ה חָמֵ֑ץ כִּ֤י כָל־שְׂאֹר֙ וְכָל־דְּבַ֔שׁ לֹֽא־תַקְטִ֧ירוּ מִמֶּ֛נּוּ אִשֶּׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "יב",
            "txt": "קָרְבַּ֥ן רֵאשִׁ֛ית תַּקְרִ֥יבוּ אֹתָ֖ם לַֽיהוָ֑ה וְאֶל־הַמִּזְבֵּ֥חַ לֹֽא־יַעֲל֖וּ לְרֵ֥יחַ נִיחֹֽחַ׃"
          },
          {
            "num": "יג",
            "txt": "וְכָל־קָרְבַּ֣ן מִנְחָֽתְךָ֮ בַּמֶּ֣לַח תִּמְלָח֒ וְלֹ֣א תַשְׁבִּ֗ית מֶ֚לַח בְּרִ֣ית אֱלֹהֶ֔יךָ מֵעַ֖ל מִנְחָתֶ֑ךָ עַ֥ל כָּל־קָרְבָּֽנְךָ֖ תַּקְרִ֥יב מֶֽלַח׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "וְאִם־תַּקְרִ֛יב מִנְחַ֥ת בִּכּוּרִ֖ים לַֽיהוָ֑ה אָבִ֞יב קָל֤וּי בָּאֵשׁ֙ גֶּ֣רֶשׂ כַּרְמֶ֔ל תַּקְרִ֕יב אֵ֖ת מִנְחַ֥ת בִּכּוּרֶֽיךָ׃"
          },
          {
            "num": "טו",
            "txt": "וְנָֽתַתָּ֤ עָלֶ֨יהָ֙ שֶׁ֔מֶן וְשַׂמְתָּ֥ עָלֶ֖יהָ לְבֹנָ֑ה מִנְחָ֖ה הִֽוא׃"
          },
          {
            "num": "טז",
            "txt": "וְהִקְטִ֨יר הַכֹּהֵ֜ן אֶת־אַזְכָּֽרָתָ֗הּ מִגִּרְשָׂהּ֙ וּמִשַּׁמְנָ֔הּ עַ֖ל כָּל־לְבֹֽנָתָ֑הּ אִשֶּׁ֖ה לַֽיהוָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "ג",
        "verses": [
          {
            "num": "א",
            "txt": "וְאִם־זֶ֥בַח שְׁלָמִ֖ים קָרְבָּנ֑וֹ אִ֤ם מִן־הַבָּקָר֙ ה֣וּא מַקְרִ֔יב אִם־זָכָר֙ אִם־נְקֵבָ֔ה תָּמִ֥ים יַקְרִיבֶ֖נּוּ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וְסָמַ֤ךְ יָדוֹ֙ עַל־רֹ֣אשׁ קָרְבָּנ֔וֹ וּשְׁחָט֕וֹ פֶּ֖תַח אֹ֣הֶל מוֹעֵ֑ד וְזָֽרְק֡וּ בְּנֵי֩ אַֽהֲרֹ֨ן הַכֹּֽהֲנִ֧ים אֶת־הַדָּ֛ם עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "ג",
            "txt": "וְהִקְרִיב֙ מִזֶּ֣בַח הַשְּׁלָמִ֔ים אִשֶּׁ֖ה לַֽיהוָ֑ה אֶת־הַחֵ֨לֶב֙ הַֽמְכַסֶּ֣ה אֶת־הַקֶּ֔רֶב וְאֵת֙ כָּל־הַחֵ֔לֶב אֲשֶׁ֖ר עַל־הַקֶּֽרֶב׃"
          },
          {
            "num": "ד",
            "txt": "וְאֵת֙ שְׁתֵּ֣י הַכְּלָיֹ֔ת וְאֶת־הַחֵ֨לֶב֙ אֲשֶׁ֣ר עֲלֵהֶ֔ן אֲשֶׁ֖ר עַל־הַכְּסָלִ֑ים וְאֶת־הַיֹּתֶ֨רֶת֙ עַל־הַכָּבֵ֔ד עַל־הַכְּלָי֖וֹת יְסִירֶֽנָּה׃"
          },
          {
            "num": "ה",
            "txt": "וְהִקְטִ֨ירוּ אֹת֤וֹ בְנֵֽי־אַהֲרֹן֙ הַמִּזְבֵּ֔חָה עַל־הָ֣עֹלָ֔ה אֲשֶׁ֥ר עַל־הָֽעֵצִ֖ים אֲשֶׁ֣ר עַל־הָאֵ֑שׁ אִשֵּׁ֛ה רֵ֥יחַ נִיחֹ֖חַ לַֽיהוָֽה׃ {פ}"
          },
          {
            "num": "ו",
            "txt": "וְאִם־מִן־הַצֹּ֧אן קָרְבָּנ֛וֹ לְזֶ֥בַח שְׁלָמִ֖ים לַֽיהוָ֑ה זָכָר֙ א֣וֹ נְקֵבָ֔ה תָּמִ֖ים יַקְרִיבֶֽנּוּ׃"
          },
          {
            "num": "ז",
            "txt": "אִם־כֶּ֥שֶׂב הֽוּא־מַקְרִ֖יב אֶת־קָרְבָּנ֑וֹ וְהִקְרִ֥יב אֹת֖וֹ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְסָמַ֤ךְ אֶת־יָדוֹ֙ עַל־רֹ֣אשׁ קָרְבָּנ֔וֹ וְשָׁחַ֣ט אֹת֔וֹ לִפְנֵ֖י אֹ֣הֶל מוֹעֵ֑ד וְ֠זָֽרְקוּ בְּנֵ֨י אַֽהֲרֹ֧ן אֶת־דָּמ֛וֹ עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "ט",
            "txt": "וְהִקְרִ֨יב מִזֶּ֣בַח הַשְּׁלָמִים֮ אִשֶּׁ֣ה לַֽיהוָה֒ חֶלְבּוֹ֙ הָֽאַלְיָ֣ה תְמִימָ֔ה לְעֻמַּ֥ת הֶֽעָצֶ֖ה יְסִירֶ֑נָּה וְאֶת־הַחֵ֨לֶב֙ הַֽמְכַסֶּ֣ה אֶת־הַקֶּ֔רֶב וְאֵת֙ כָּל־הַחֵ֔לֶב אֲשֶׁ֖ר עַל־הַקֶּֽרֶב׃"
          },
          {
            "num": "י",
            "txt": "וְאֵת֙ שְׁתֵּ֣י הַכְּלָיֹ֔ת וְאֶת־הַחֵ֨לֶב֙ אֲשֶׁ֣ר עֲלֵהֶ֔ן אֲשֶׁ֖ר עַל־הַכְּסָלִ֑ים וְאֶת־הַיֹּתֶ֨רֶת֙ עַל־הַכָּבֵ֔ד עַל־הַכְּלָיֹ֖ת יְסִירֶֽנָּה׃"
          },
          {
            "num": "יא",
            "txt": "וְהִקְטִיר֥וֹ הַכֹּהֵ֖ן הַמִּזְבֵּ֑חָה לֶ֥חֶם אִשֶּׁ֖ה לַֽיהוָֽה׃ {פ}"
          },
          {
            "num": "יב",
            "txt": "וְאִ֥ם עֵ֖ז קָרְבָּנ֑וֹ וְהִקְרִיב֖וֹ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְסָמַ֤ךְ אֶת־יָדוֹ֙ עַל־רֹאשׁ֔וֹ וְשָׁחַ֣ט אֹת֔וֹ לִפְנֵ֖י אֹ֣הֶל מוֹעֵ֑ד וְ֠זָֽרְקוּ בְּנֵ֨י אַֽהֲרֹ֧ן אֶת־דָּמ֛וֹ עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "יד",
            "txt": "וְהִקְרִ֤יב מִמֶּ֨נּוּ֙ קָרְבָּנ֔וֹ אִשֶּׁ֖ה לַֽיהוָ֑ה אֶת־הַחֵ֨לֶב֙ הַֽמְכַסֶּ֣ה אֶת־הַקֶּ֔רֶב וְאֵת֙ כָּל־הַחֵ֔לֶב אֲשֶׁ֖ר עַל־הַקֶּֽרֶב׃"
          },
          {
            "num": "טו",
            "txt": "וְאֵת֙ שְׁתֵּ֣י הַכְּלָיֹ֔ת וְאֶת־הַחֵ֨לֶב֙ אֲשֶׁ֣ר עֲלֵהֶ֔ן אֲשֶׁ֖ר עַל־הַכְּסָלִ֑ים וְאֶת־הַיֹּתֶ֨רֶת֙ עַל־הַכָּבֵ֔ד עַל־הַכְּלָיֹ֖ת יְסִירֶֽנָּה׃"
          },
          {
            "num": "טז",
            "txt": "וְהִקְטִירָ֥ם הַכֹּהֵ֖ן הַמִּזְבֵּ֑חָה לֶ֤חֶם אִשֶּׁה֙ לְרֵ֣יחַ נִיחֹ֔חַ כָּל־חֵ֖לֶב לַֽיהוָֽה׃"
          },
          {
            "num": "יז",
            "txt": "חֻקַּ֤ת עוֹלָם֙ לְדֹרֹ֣תֵיכֶ֔ם בְּכֹ֖ל מוֹשְׁבֹֽתֵיכֶ֑ם כָּל־חֵ֥לֶב וְכָל־דָּ֖ם לֹ֥א תֹאכֵֽלוּ׃ {פ}"
          }
        ]
      },
      {
        "num": "ד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֞ר אֶל־בְּנֵ֣י יִשְׂרָאֵל֮ לֵאמֹר֒ נֶ֗פֶשׁ כִּֽי־תֶחֱטָ֤א בִשְׁגָגָה֙ מִכֹּל֙ מִצְוֺ֣ת יְהוָ֔ה אֲשֶׁ֖ר לֹ֣א תֵֽעָשֶׂ֑ינָה וְעָשָׂ֕ה מֵֽאַחַ֖ת מֵהֵֽנָּה׃"
          },
          {
            "num": "ג",
            "txt": "אִ֣ם הַכֹּהֵ֧ן הַמָּשִׁ֛יחַ יֶֽחֱטָ֖א לְאַשְׁמַ֣ת הָעָ֑ם וְהִקְרִ֡יב עַ֣ל חַטָּאתוֹ֩ אֲשֶׁ֨ר חָטָ֜א פַּ֣ר בֶּן־בָּקָ֥ר תָּמִ֛ים לַֽיהוָ֖ה לְחַטָּֽאת׃"
          },
          {
            "num": "ד",
            "txt": "וְהֵבִ֣יא אֶת־הַפָּ֗ר אֶל־פֶּ֛תַח אֹ֥הֶל מוֹעֵ֖ד לִפְנֵ֣י יְהוָ֑ה וְסָמַ֤ךְ אֶת־יָדוֹ֙ עַל־רֹ֣אשׁ הַפָּ֔ר וְשָׁחַ֥ט אֶת־הַפָּ֖ר לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ה",
            "txt": "וְלָקַ֛ח הַכֹּהֵ֥ן הַמָּשִׁ֖יחַ מִדַּ֣ם הַפָּ֑ר וְהֵבִ֥יא אֹת֖וֹ אֶל־אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ו",
            "txt": "וְטָבַ֧ל הַכֹּהֵ֛ן אֶת־אֶצְבָּע֖וֹ בַּדָּ֑ם וְהִזָּ֨ה מִן־הַדָּ֜ם שֶׁ֤בַע פְּעָמִים֙ לִפְנֵ֣י יְהוָ֔ה אֶת־פְּנֵ֖י פָּרֹ֥כֶת הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "ז",
            "txt": "וְנָתַן֩ הַכֹּהֵ֨ן מִן־הַדָּ֜ם עַל־קַ֠רְנוֹת מִזְבַּ֨ח קְטֹ֤רֶת הַסַּמִּים֙ לִפְנֵ֣י יְהוָ֔ה אֲשֶׁ֖ר בְּאֹ֣הֶל מוֹעֵ֑ד וְאֵ֣ת ׀ כָּל־דַּ֣ם הַפָּ֗ר יִשְׁפֹּךְ֙ אֶל־יְסוֹד֙ מִזְבַּ֣ח הָֽעֹלָ֔ה אֲשֶׁר־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ח",
            "txt": "וְאֶת־כָּל־חֵ֛לֶב פַּ֥ר הַֽחַטָּ֖את יָרִ֣ים מִמֶּ֑נּוּ אֶת־הַחֵ֨לֶב֙ הַֽמְכַסֶּ֣ה עַל־הַקֶּ֔רֶב וְאֵת֙ כָּל־הַחֵ֔לֶב אֲשֶׁ֖ר עַל־הַקֶּֽרֶב׃"
          },
          {
            "num": "ט",
            "txt": "וְאֵת֙ שְׁתֵּ֣י הַכְּלָיֹ֔ת וְאֶת־הַחֵ֨לֶב֙ אֲשֶׁ֣ר עֲלֵיהֶ֔ן אֲשֶׁ֖ר עַל־הַכְּסָלִ֑ים וְאֶת־הַיֹּתֶ֨רֶת֙ עַל־הַכָּבֵ֔ד עַל־הַכְּלָי֖וֹת יְסִירֶֽנָּה׃"
          },
          {
            "num": "י",
            "txt": "כַּֽאֲשֶׁ֣ר יוּרַ֔ם מִשּׁ֖וֹר זֶ֣בַח הַשְּׁלָמִ֑ים וְהִקְטִירָם֙ הַכֹּהֵ֔ן עַ֖ל מִזְבַּ֥ח הָֽעֹלָֽה׃"
          },
          {
            "num": "יא",
            "txt": "וְאֶת־ע֤וֹר הַפָּר֙ וְאֶת־כָּל־בְּשָׂר֔וֹ עַל־רֹאשׁ֖וֹ וְעַל־כְּרָעָ֑יו וְקִרְבּ֖וֹ וּפִרְשֽׁוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וְהוֹצִ֣יא אֶת־כָּל־הַ֠פָּר אֶל־מִח֨וּץ לַֽמַּחֲנֶ֜ה אֶל־מָק֤וֹם טָהוֹר֙ אֶל־שֶׁ֣פֶךְ הַדֶּ֔שֶׁן וְשָׂרַ֥ף אֹת֛וֹ עַל־עֵצִ֖ים בָּאֵ֑שׁ עַל־שֶׁ֥פֶךְ הַדֶּ֖שֶׁן יִשָּׂרֵֽף׃ {פ}"
          },
          {
            "num": "יג",
            "txt": "וְאִ֨ם כָּל־עֲדַ֤ת יִשְׂרָאֵל֙ יִשְׁגּ֔וּ וְנֶעְלַ֣ם דָּבָ֔ר מֵֽעֵינֵ֖י הַקָּהָ֑ל וְ֠עָשׂוּ אַחַ֨ת מִכָּל־מִצְוֺ֧ת יְהוָ֛ה אֲשֶׁ֥ר לֹֽא־תֵעָשֶׂ֖ינָה וְאָשֵֽׁמוּ׃"
          },
          {
            "num": "יד",
            "txt": "וְנֽוֹדְעָה֙ הַֽחַטָּ֔את אֲשֶׁ֥ר חָֽטְא֖וּ עָלֶ֑יהָ וְהִקְרִ֨יבוּ הַקָּהָ֜ל פַּ֤ר בֶּן־בָּקָר֙ לְחַטָּ֔את וְהֵבִ֣יאוּ אֹת֔וֹ לִפְנֵ֖י אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "טו",
            "txt": "וְ֠סָֽמְכוּ זִקְנֵ֨י הָֽעֵדָ֧ה אֶת־יְדֵיהֶ֛ם עַל־רֹ֥אשׁ הַפָּ֖ר לִפְנֵ֣י יְהוָ֑ה וְשָׁחַ֥ט אֶת־הַפָּ֖ר לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "טז",
            "txt": "וְהֵבִ֛יא הַכֹּהֵ֥ן הַמָּשִׁ֖יחַ מִדַּ֣ם הַפָּ֑ר אֶל־אֹ֖הֶל מוֹעֵֽד׃"
          },
          {
            "num": "יז",
            "txt": "וְטָבַ֧ל הַכֹּהֵ֛ן אֶצְבָּע֖וֹ מִן־הַדָּ֑ם וְהִזָּ֞ה שֶׁ֤בַע פְּעָמִים֙ לִפְנֵ֣י יְהוָ֔ה אֵ֖ת פְּנֵ֥י הַפָּרֹֽכֶת׃"
          },
          {
            "num": "יח",
            "txt": "וּמִן־הַדָּ֞ם יִתֵּ֣ן ׀ עַל־קַרְנֹ֣ת הַמִּזְבֵּ֗חַ אֲשֶׁר֙ לִפְנֵ֣י יְהוָ֔ה אֲשֶׁ֖ר בְּאֹ֣הֶל מוֹעֵ֑ד וְאֵ֣ת כָּל־הַדָּ֗ם יִשְׁפֹּךְ֙ אֶל־יְסוֹד֙ מִזְבַּ֣ח הָֽעֹלָ֔ה אֲשֶׁר־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "יט",
            "txt": "וְאֵ֥ת כָּל־חֶלְבּ֖וֹ יָרִ֣ים מִמֶּ֑נּוּ וְהִקְטִ֖יר הַמִּזְבֵּֽחָה׃"
          },
          {
            "num": "כ",
            "txt": "וְעָשָׂ֣ה לַפָּ֔ר כַּֽאֲשֶׁ֤ר עָשָׂה֙ לְפַ֣ר הַֽחַטָּ֔את כֵּ֖ן יַֽעֲשֶׂה־לּ֑וֹ וְכִפֶּ֧ר עֲלֵהֶ֛ם הַכֹּהֵ֖ן וְנִסְלַ֥ח לָהֶֽם׃"
          },
          {
            "num": "כא",
            "txt": "וְהוֹצִ֣יא אֶת־הַפָּ֗ר אֶל־מִחוּץ֙ לַֽמַּחֲנֶ֔ה וְשָׂרַ֣ף אֹת֔וֹ כַּֽאֲשֶׁ֣ר שָׂרַ֔ף אֵ֖ת הַפָּ֣ר הָֽרִאשׁ֑וֹן חַטַּ֥את הַקָּהָ֖ל הֽוּא׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "אֲשֶׁ֥ר נָשִׂ֖יא יֶֽחֱטָ֑א וְעָשָׂ֡ה אַחַ֣ת מִכָּל־מִצְוֺת֩ יְהוָ֨ה אֱלֹהָ֜יו אֲשֶׁ֧ר לֹֽא־תֵעָשֶׂ֛ינָה בִּשְׁגָגָ֖ה וְאָשֵֽׁם׃"
          },
          {
            "num": "כג",
            "txt": "אֽוֹ־הוֹדַ֤ע אֵלָיו֙ חַטָּאת֔וֹ אֲשֶׁ֥ר חָטָ֖א בָּ֑הּ וְהֵבִ֧יא אֶת־קָרְבָּנ֛וֹ שְׂעִ֥יר עִזִּ֖ים זָכָ֥ר תָּמִֽים׃"
          },
          {
            "num": "כד",
            "txt": "וְסָמַ֤ךְ יָדוֹ֙ עַל־רֹ֣אשׁ הַשָּׂעִ֔יר וְשָׁחַ֣ט אֹת֗וֹ בִּמְק֛וֹם אֲשֶׁר־יִשְׁחַ֥ט אֶת־הָֽעֹלָ֖ה לִפְנֵ֣י יְהוָ֑ה חַטָּ֖את הֽוּא׃"
          },
          {
            "num": "כה",
            "txt": "וְלָקַ֨ח הַכֹּהֵ֜ן מִדַּ֤ם הַֽחַטָּאת֙ בְּאֶצְבָּע֔וֹ וְנָתַ֕ן עַל־קַרְנֹ֖ת מִזְבַּ֣ח הָֽעֹלָ֑ה וְאֶת־דָּמ֣וֹ יִשְׁפֹּ֔ךְ אֶל־יְס֖וֹד מִזְבַּ֥ח הָֽעֹלָֽה׃"
          },
          {
            "num": "כו",
            "txt": "וְאֶת־כָּל־חֶלְבּוֹ֙ יַקְטִ֣יר הַמִּזְבֵּ֔חָה כְּחֵ֖לֶב זֶ֣בַח הַשְּׁלָמִ֑ים וְכִפֶּ֨ר עָלָ֧יו הַכֹּהֵ֛ן מֵֽחַטָּאת֖וֹ וְנִסְלַ֥ח לֽוֹ׃ {פ}"
          },
          {
            "num": "כז",
            "txt": "וְאִם־נֶ֧פֶשׁ אַחַ֛ת תֶּֽחֱטָ֥א בִשְׁגָגָ֖ה מֵעַ֣ם הָאָ֑רֶץ בַּֽ֠עֲשֹׂתָהּ אַחַ֨ת מִמִּצְוֺ֧ת יְהוָ֛ה אֲשֶׁ֥ר לֹֽא־תֵעָשֶׂ֖ינָה וְאָשֵֽׁם׃"
          },
          {
            "num": "כח",
            "txt": "א֚וֹ הוֹדַ֣ע אֵלָ֔יו חַטָּאת֖וֹ אֲשֶׁ֣ר חָטָ֑א וְהֵבִ֨יא קָרְבָּנ֜וֹ שְׂעִירַ֤ת עִזִּים֙ תְּמִימָ֣ה נְקֵבָ֔ה עַל־חַטָּאת֖וֹ אֲשֶׁ֥ר חָטָֽא׃"
          },
          {
            "num": "כט",
            "txt": "וְסָמַךְ֙ אֶת־יָד֔וֹ עַ֖ל רֹ֣אשׁ הַֽחַטָּ֑את וְשָׁחַט֙ אֶת־הַ֣חַטָּ֔את בִּמְק֖וֹם הָֽעֹלָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וְלָקַ֨ח הַכֹּהֵ֤ן מִדָּמָהּ֙ בְּאֶצְבָּע֔וֹ וְנָתַ֕ן עַל־קַרְנֹ֖ת מִזְבַּ֣ח הָֽעֹלָ֑ה וְאֶת־כָּל־דָּמָ֣הּ יִשְׁפֹּ֔ךְ אֶל־יְס֖וֹד הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "לא",
            "txt": "וְאֶת־כָּל־חֶלְבָּ֣הּ יָסִ֗יר כַּֽאֲשֶׁ֨ר הוּסַ֣ר חֵלֶב֮ מֵעַ֣ל זֶ֣בַח הַשְּׁלָמִים֒ וְהִקְטִ֤יר הַכֹּהֵן֙ הַמִּזְבֵּ֔חָה לְרֵ֥יחַ נִיחֹ֖חַ לַֽיהוָ֑ה וְכִפֶּ֥ר עָלָ֛יו הַכֹּהֵ֖ן וְנִסְלַ֥ח לֽוֹ׃ {פ}"
          },
          {
            "num": "לב",
            "txt": "וְאִם־כֶּ֛בֶשׂ יָבִ֥יא קָרְבָּנ֖וֹ לְחַטָּ֑את נְקֵבָ֥ה תְמִימָ֖ה יְבִיאֶֽנָּה׃"
          },
          {
            "num": "לג",
            "txt": "וְסָמַךְ֙ אֶת־יָד֔וֹ עַ֖ל רֹ֣אשׁ הַֽחַטָּ֑את וְשָׁחַ֤ט אֹתָהּ֙ לְחַטָּ֔את בִּמְק֕וֹם אֲשֶׁ֥ר יִשְׁחַ֖ט אֶת־הָֽעֹלָֽה׃"
          },
          {
            "num": "לד",
            "txt": "וְלָקַ֨ח הַכֹּהֵ֜ן מִדַּ֤ם הַֽחַטָּאת֙ בְּאֶצְבָּע֔וֹ וְנָתַ֕ן עַל־קַרְנֹ֖ת מִזְבַּ֣ח הָֽעֹלָ֑ה וְאֶת־כָּל־דָּמָ֣הּ יִשְׁפֹּ֔ךְ אֶל־יְס֖וֹד הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "לה",
            "txt": "וְאֶת־כָּל־חֶלְבָּ֣הּ יָסִ֗יר כַּֽאֲשֶׁ֨ר יוּסַ֥ר חֵֽלֶב־הַכֶּשֶׂב֮ מִזֶּ֣בַח הַשְּׁלָמִים֒ וְהִקְטִ֨יר הַכֹּהֵ֤ן אֹתָם֙ הַמִּזְבֵּ֔חָה עַ֖ל אִשֵּׁ֣י יְהוָ֑ה וְכִפֶּ֨ר עָלָ֧יו הַכֹּהֵ֛ן עַל־חַטָּאת֥וֹ אֲשֶׁר־חָטָ֖א וְנִסְלַ֥ח לֽוֹ׃ {פ}"
          }
        ]
      },
      {
        "num": "ה",
        "verses": [
          {
            "num": "א",
            "txt": "וְנֶ֣פֶשׁ כִּֽי־תֶחֱטָ֗א וְשָֽׁמְעָה֙ ק֣וֹל אָלָ֔ה וְה֣וּא עֵ֔ד א֥וֹ רָאָ֖ה א֣וֹ יָדָ֑ע אִם־ל֥וֹא יַגִּ֖יד וְנָשָׂ֥א עֲוֺנֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "א֣וֹ נֶ֗פֶשׁ אֲשֶׁ֣ר תִּגַּע֮ בְּכָל־דָּבָ֣ר טָמֵא֒ אוֹ֩ בְנִבְלַ֨ת חַיָּ֜ה טְמֵאָ֗ה א֚וֹ בְּנִבְלַת֙ בְּהֵמָ֣ה טְמֵאָ֔ה א֕וֹ בְּנִבְלַ֖ת שֶׁ֣רֶץ טָמֵ֑א וְנֶעְלַ֣ם מִמֶּ֔נּוּ וְה֥וּא טָמֵ֖א וְאָשֵֽׁם׃"
          },
          {
            "num": "ג",
            "txt": "א֣וֹ כִ֤י יִגַּע֙ בְּטֻמְאַ֣ת אָדָ֔ם לְכֹל֙ טֻמְאָת֔וֹ אֲשֶׁ֥ר יִטְמָ֖א בָּ֑הּ וְנֶעְלַ֣ם מִמֶּ֔נּוּ וְה֥וּא יָדַ֖ע וְאָשֵֽׁם׃"
          },
          {
            "num": "ד",
            "txt": "א֣וֹ נֶ֡פֶשׁ כִּ֣י תִשָּׁבַע֩ לְבַטֵּ֨א בִשְׂפָתַ֜יִם לְהָרַ֣ע ׀ א֣וֹ לְהֵיטִ֗יב לְ֠כֹל אֲשֶׁ֨ר יְבַטֵּ֧א הָֽאָדָ֛ם בִּשְׁבֻעָ֖ה וְנֶעְלַ֣ם מִמֶּ֑נּוּ וְהֽוּא־יָדַ֥ע וְאָשֵׁ֖ם לְאַחַ֥ת מֵאֵֽלֶּה׃"
          },
          {
            "num": "ה",
            "txt": "וְהָיָ֥ה כִֽי־יֶאְשַׁ֖ם לְאַחַ֣ת מֵאֵ֑לֶּה וְהִ֨תְוַדָּ֔ה אֲשֶׁ֥ר חָטָ֖א עָלֶֽיהָ׃"
          },
          {
            "num": "ו",
            "txt": "וְהֵבִ֣יא אֶת־אֲשָׁמ֣וֹ לַֽיהוָ֡ה עַ֣ל חַטָּאתוֹ֩ אֲשֶׁ֨ר חָטָ֜א נְקֵבָ֨ה מִן־הַצֹּ֥אן כִּשְׂבָּ֛ה אֽוֹ־שְׂעִירַ֥ת עִזִּ֖ים לְחַטָּ֑את וְכִפֶּ֥ר עָלָ֛יו הַכֹּהֵ֖ן מֵֽחַטָּאתֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וְאִם־לֹ֨א תַגִּ֣יעַ יָדוֹ֮ דֵּ֣י שֶׂה֒ וְהֵבִ֨יא אֶת־אֲשָׁמ֜וֹ אֲשֶׁ֣ר חָטָ֗א שְׁתֵּ֥י תֹרִ֛ים אֽוֹ־שְׁנֵ֥י בְנֵֽי־יוֹנָ֖ה לַֽיהוָ֑ה אֶחָ֥ד לְחַטָּ֖את וְאֶחָ֥ד לְעֹלָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְהֵבִ֤יא אֹתָם֙ אֶל־הַכֹּהֵ֔ן וְהִקְרִ֛יב אֶת־אֲשֶׁ֥ר לַֽחַטָּ֖את רִֽאשׁוֹנָ֑ה וּמָלַ֧ק אֶת־רֹאשׁ֛וֹ מִמּ֥וּל עָרְפּ֖וֹ וְלֹ֥א יַבְדִּֽיל׃"
          },
          {
            "num": "ט",
            "txt": "וְהִזָּ֞ה מִדַּ֤ם הַֽחַטָּאת֙ עַל־קִ֣יר הַמִּזְבֵּ֔חַ וְהַנִּשְׁאָ֣ר בַּדָּ֔ם יִמָּצֵ֖ה אֶל־יְס֣וֹד הַמִּזְבֵּ֑חַ חַטָּ֖את הֽוּא׃"
          },
          {
            "num": "י",
            "txt": "וְאֶת־הַשֵּׁנִ֛י יַֽעֲשֶׂ֥ה עֹלָ֖ה כַּמִּשְׁפָּ֑ט וְכִפֶּ֨ר עָלָ֧יו הַכֹּהֵ֛ן מֵֽחַטָּאת֥וֹ אֲשֶׁר־חָטָ֖א וְנִסְלַ֥ח לֽוֹ׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "וְאִם־לֹא֩ תַשִּׂ֨יג יָד֜וֹ לִשְׁתֵּ֣י תֹרִ֗ים אוֹ֮ לִשְׁנֵ֣י בְנֵֽי־יוֹנָה֒ וְהֵבִ֨יא אֶת־קָרְבָּנ֜וֹ אֲשֶׁ֣ר חָטָ֗א עֲשִׂירִ֧ת הָֽאֵפָ֛ה סֹ֖לֶת לְחַטָּ֑את לֹֽא־יָשִׂ֨ים עָלֶ֜יהָ שֶׁ֗מֶן וְלֹֽא־יִתֵּ֤ן עָלֶ֨יהָ֙ לְבֹנָ֔ה כִּ֥י חַטָּ֖את הִֽוא׃"
          },
          {
            "num": "יב",
            "txt": "וֶֽהֱבִיאָהּ֮ אֶל־הַכֹּהֵן֒ וְקָמַ֣ץ הַכֹּהֵ֣ן ׀ מִ֠מֶּנָּה מְל֨וֹא קֻמְצ֜וֹ אֶת־אַזְכָּֽרָתָהּ֙ וְהִקְטִ֣יר הַמִּזְבֵּ֔חָה עַ֖ל אִשֵּׁ֣י יְהוָ֑ה חַטָּ֖את הִֽוא׃"
          },
          {
            "num": "יג",
            "txt": "וְכִפֶּר֩ עָלָ֨יו הַכֹּהֵ֜ן עַל־חַטָּאת֧וֹ אֲשֶׁר־חָטָ֛א מֵֽאַחַ֥ת מֵאֵ֖לֶּה וְנִסְלַ֣ח ל֑וֹ וְהָֽיְתָ֥ה לַכֹּהֵ֖ן כַּמִּנְחָֽה׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "טו",
            "txt": "נֶ֚פֶשׁ כִּֽי־תִמְעֹ֣ל מַ֔עַל וְחָֽטְאָה֙ בִּשְׁגָגָ֔ה מִקָּדְשֵׁ֖י יְהוָ֑ה וְהֵבִיא֩ אֶת־אֲשָׁמ֨וֹ לַֽיהוָ֜ה אַ֧יִל תָּמִ֣ים מִן־הַצֹּ֗אן בְּעֶרְכְּךָ֛ כֶּֽסֶף־שְׁקָלִ֥ים בְּשֶֽׁקֶל־הַקֹּ֖דֶשׁ לְאָשָֽׁם׃"
          },
          {
            "num": "טז",
            "txt": "וְאֵ֣ת אֲשֶׁר֩ חָטָ֨א מִן־הַקֹּ֜דֶשׁ יְשַׁלֵּ֗ם וְאֶת־חֲמִֽישִׁתוֹ֙ יוֹסֵ֣ף עָלָ֔יו וְנָתַ֥ן אֹת֖וֹ לַכֹּהֵ֑ן וְהַכֹּהֵ֗ן יְכַפֵּ֥ר עָלָ֛יו בְּאֵ֥יל הָֽאָשָׁ֖ם וְנִסְלַ֥ח לֽוֹ׃ {פ}"
          },
          {
            "num": "יז",
            "txt": "וְאִם־נֶ֨פֶשׁ֙ כִּ֣י תֶֽחֱטָ֔א וְעָֽשְׂתָ֗ה אַחַת֙ מִכָּל־מִצְוֺ֣ת יְהוָ֔ה אֲשֶׁ֖ר לֹ֣א תֵֽעָשֶׂ֑ינָה וְלֹֽא־יָדַ֥ע וְאָשֵׁ֖ם וְנָשָׂ֥א עֲוֺנֽוֹ׃"
          },
          {
            "num": "יח",
            "txt": "וְ֠הֵבִיא אַ֣יִל תָּמִ֧ים מִן־הַצֹּ֛אן בְּעֶרְכְּךָ֥ לְאָשָׁ֖ם אֶל־הַכֹּהֵ֑ן וְכִפֶּר֩ עָלָ֨יו הַכֹּהֵ֜ן עַ֣ל שִׁגְגָת֧וֹ אֲשֶׁר־שָׁגָ֛ג וְה֥וּא לֹֽא־יָדַ֖ע וְנִסְלַ֥ח לֽוֹ׃"
          },
          {
            "num": "יט",
            "txt": "אָשָׁ֖ם ה֑וּא אָשֹׁ֥ם אָשַׁ֖ם לַֽיהוָֽה׃ {פ}"
          },
          {
            "num": "כ",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כא",
            "txt": "נֶ֚פֶשׁ כִּ֣י תֶֽחֱטָ֔א וּמָֽעֲלָ֥ה מַ֖עַל בַּֽיהוָ֑ה וְכִחֵ֨שׁ בַּֽעֲמִית֜וֹ בְּפִקָּד֗וֹן אֽוֹ־בִתְשׂ֤וּמֶת יָד֙ א֣וֹ בְגָזֵ֔ל א֖וֹ עָשַׁ֥ק אֶת־עֲמִיתֽוֹ׃"
          },
          {
            "num": "כב",
            "txt": "אֽוֹ־מָצָ֧א אֲבֵדָ֛ה וְכִ֥חֶשׁ בָּ֖הּ וְנִשְׁבַּ֣ע עַל־שָׁ֑קֶר עַל־אַחַ֗ת מִכֹּ֛ל אֲשֶׁר־יַֽעֲשֶׂ֥ה הָֽאָדָ֖ם לַֽחֲטֹ֥א בָהֵֽנָּה׃"
          },
          {
            "num": "כג",
            "txt": "וְהָיָה֮ כִּֽי־יֶחֱטָ֣א וְאָשֵׁם֒ וְהֵשִׁ֨יב אֶת־הַגְּזֵלָ֜ה אֲשֶׁ֣ר גָּזָ֗ל א֤וֹ אֶת־הָעֹ֨שֶׁק֙ אֲשֶׁ֣ר עָשָׁ֔ק א֚וֹ אֶת־הַפִּקָּד֔וֹן אֲשֶׁ֥ר הָפְקַ֖ד אִתּ֑וֹ א֥וֹ אֶת־הָֽאֲבֵדָ֖ה אֲשֶׁ֥ר מָצָֽא׃"
          },
          {
            "num": "כד",
            "txt": "א֠וֹ מִכֹּ֞ל אֲשֶׁר־יִשָּׁבַ֣ע עָלָיו֮ לַשֶּׁקֶר֒ וְשִׁלַּ֤ם אֹתוֹ֙ בְּרֹאשׁ֔וֹ וַחֲמִֽשִׁתָ֖יו יֹסֵ֣ף עָלָ֑יו לַֽאֲשֶׁ֨ר ה֥וּא ל֛וֹ יִתְּנֶ֖נּוּ בְּי֥וֹם אַשְׁמָתֽוֹ׃"
          },
          {
            "num": "כה",
            "txt": "וְאֶת־אֲשָׁמ֥וֹ יָבִ֖יא לַֽיהוָ֑ה אַ֣יִל תָּמִ֧ים מִן־הַצֹּ֛אן בְּעֶרְכְּךָ֥ לְאָשָׁ֖ם אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "כו",
            "txt": "וְכִפֶּ֨ר עָלָ֧יו הַכֹּהֵ֛ן לִפְנֵ֥י יְהוָ֖ה וְנִסְלַ֣ח ל֑וֹ עַל־אַחַ֛ת מִכֹּ֥ל אֲשֶֽׁר־יַעֲשֶׂ֖ה לְאַשְׁמָ֥ה בָֽהּ׃ {פ}"
          }
        ]
      },
      {
        "num": "ו",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "צַ֤ו אֶֽת־אַהֲרֹן֙ וְאֶת־בָּנָ֣יו לֵאמֹ֔ר זֹ֥את תּוֹרַ֖ת הָֽעֹלָ֑ה הִ֣וא הָֽעֹלָ֡ה עַל֩"
          },
          {
            "num": "ג",
            "txt": "וֹקְדָ֨ה עַל־הַמִּזְבֵּ֤חַ כָּל־הַלַּ֨יְלָה֙ עַד־הַבֹּ֔קֶר וְאֵ֥שׁ הַמִּזְבֵּ֖חַ תּ֥וּקַד בּֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "וְלָבַ֨שׁ הַכֹּהֵ֜ן מִדּ֣וֹ בַ֗ד וּמִֽכְנְסֵי־בַד֮ יִלְבַּ֣שׁ עַל־בְּשָׂרוֹ֒ וְהֵרִ֣ים אֶת־הַדֶּ֗שֶׁן אֲשֶׁ֨ר תֹּאכַ֥ל הָאֵ֛שׁ אֶת־הָֽעֹלָ֖ה עַל־הַמִּזְבֵּ֑חַ וְשָׂמ֕וֹ אֵ֖צֶל הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ה",
            "txt": "וּפָשַׁט֙ אֶת־בְּגָדָ֔יו וְלָבַ֖שׁ בְּגָדִ֣ים אֲחֵרִ֑ים וְהוֹצִ֤יא אֶת־הַדֶּ֨שֶׁן֙ אֶל־מִח֣וּץ לַֽמַּחֲנֶ֔ה אֶל־מָק֖וֹם טָהֽוֹר׃"
          },
          {
            "num": "ו",
            "txt": "וְהָאֵ֨שׁ עַל־הַמִּזְבֵּ֤חַ תּֽוּקַד־בּוֹ֙ לֹ֣א תִכְבֶּ֔ה וּבִעֵ֨ר עָלֶ֧יהָ הַכֹּהֵ֛ן עֵצִ֖ים בַּבֹּ֣קֶר בַּבֹּ֑קֶר וְעָרַ֤ךְ עָלֶ֨יהָ֙ הָֽעֹלָ֔ה וְהִקְטִ֥יר עָלֶ֖יהָ חֶלְבֵ֥י הַשְּׁלָמִֽים׃"
          },
          {
            "num": "ז",
            "txt": "אֵ֗שׁ תָּמִ֛יד תּוּקַ֥ד עַל־הַמִּזְבֵּ֖חַ לֹ֥א תִכְבֶּֽה׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "וְזֹ֥את תּוֹרַ֖ת הַמִּנְחָ֑ה הַקְרֵ֨ב אֹתָ֤הּ בְּנֵֽי־אַהֲרֹן֙ לִפְנֵ֣י יְהוָ֔ה אֶל־פְּנֵ֖י הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ט",
            "txt": "וְהֵרִ֨ים מִמֶּ֜נּוּ בְּקֻמְצ֗וֹ מִסֹּ֤לֶת הַמִּנְחָה֙ וּמִשַּׁמְנָ֔הּ וְאֵת֙ כָּל־הַלְּבֹנָ֔ה אֲשֶׁ֖ר עַל־הַמִּנְחָ֑ה וְהִקְטִ֣יר הַמִּזְבֵּ֗חַ רֵ֧יחַ נִיחֹ֛חַ אַזְכָּֽרָתָ֖הּ לַֽיהוָֽה׃"
          },
          {
            "num": "י",
            "txt": "וְהַנּוֹתֶ֣רֶת מִמֶּ֔נָּה יֹֽאכְל֖וּ אַֽהֲרֹ֣ן וּבָנָ֑יו מַצּ֤וֹת תֵּֽאָכֵל֙ בְּמָק֣וֹם קָדֹ֔שׁ בַּֽחֲצַ֥ר אֹֽהֶל־מוֹעֵ֖ד יֹֽאכְלֽוּהָ׃"
          },
          {
            "num": "יא",
            "txt": "לֹ֤א תֵֽאָפֶה֙ חָמֵ֔ץ חֶלְקָ֛ם נָתַ֥תִּי אֹתָ֖הּ מֵֽאִשָּׁ֑י קֹ֤דֶשׁ קָֽדָשִׁים֙ הִ֔וא כַּֽחַטָּ֖את וְכָֽאָשָֽׁם׃"
          },
          {
            "num": "יב",
            "txt": "כָּל־זָכָ֞ר בִּבְנֵ֤י אַֽהֲרֹן֙ יֹֽאכְלֶ֔נָּה חָק־עוֹלָם֙ לְדֹרֹ֣תֵיכֶ֔ם מֵֽאִשֵּׁ֖י יְהוָ֑ה כֹּ֛ל אֲשֶׁר־יִגַּ֥ע בָּהֶ֖ם יִקְדָּֽשׁ׃ {פ}"
          },
          {
            "num": "יג",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יד",
            "txt": "זֶ֡ה קָרְבַּן֩ אַֽהֲרֹ֨ן וּבָנָ֜יו אֲשֶׁר־יַקְרִ֣יבוּ לַֽיהוָ֗ה בְּיוֹם֙ הִמָּשַׁ֣ח אֹת֔וֹ עֲשִׂירִ֨ת הָֽאֵפָ֥ה סֹ֛לֶת מִנְחָ֖ה תָּמִ֑יד מַֽחֲצִיתָ֣הּ בַּבֹּ֔קֶר וּמַֽחֲצִיתָ֖הּ בָּעָֽרֶב׃"
          },
          {
            "num": "טו",
            "txt": "עַֽל־מַחֲבַ֗ת בַּשֶּׁ֛מֶן תֵּֽעָשֶׂ֖ה מֻרְבֶּ֣כֶת תְּבִיאֶ֑נָּה תֻּֽפִינֵי֙ מִנְחַ֣ת פִּתִּ֔ים תַּקְרִ֥יב רֵֽיחַ־נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "טז",
            "txt": "וְהַכֹּהֵ֨ן הַמָּשִׁ֧יחַ תַּחְתָּ֛יו מִבָּנָ֖יו יַֽעֲשֶׂ֣ה אֹתָ֑הּ חָק־עוֹלָ֕ם לַֽיהוָ֖ה כָּלִ֥יל תָּקְטָֽר׃"
          },
          {
            "num": "יז",
            "txt": "וְכָל־מִנְחַ֥ת כֹּהֵ֛ן כָּלִ֥יל תִּֽהְיֶ֖ה לֹ֥א תֵֽאָכֵֽל׃ {פ}"
          },
          {
            "num": "יח",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יט",
            "txt": "דַּבֵּ֤ר אֶֽל־אַהֲרֹן֙ וְאֶל־בָּנָ֣יו לֵאמֹ֔ר זֹ֥את תּוֹרַ֖ת הַֽחַטָּ֑את בִּמְק֡וֹם אֲשֶׁר֩ תִּשָּׁחֵ֨ט הָֽעֹלָ֜ה תִּשָּׁחֵ֤ט הַֽחַטָּאת֙ לִפְנֵ֣י יְהוָ֔ה קֹ֥דֶשׁ קָֽדָשִׁ֖ים הִֽוא׃"
          },
          {
            "num": "כ",
            "txt": "הַכֹּהֵ֛ן הַֽמְחַטֵּ֥א אֹתָ֖הּ יֹֽאכְלֶ֑נָּה בְּמָק֤וֹם קָדֹשׁ֙ תֵּֽאָכֵ֔ל בַּֽחֲצַ֖ר אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "כא",
            "txt": "כֹּ֛ל אֲשֶׁר־יִגַּ֥ע בִּבְשָׂרָ֖הּ יִקְדָּ֑שׁ וַֽאֲשֶׁ֨ר יִזֶּ֤ה מִדָּמָהּ֙ עַל־הַבֶּ֔גֶד אֲשֶׁר֙ יִזֶּ֣ה עָלֶ֔יהָ תְּכַבֵּ֖ס בְּמָק֥וֹם קָדֹֽשׁ׃"
          },
          {
            "num": "כב",
            "txt": "וּכְלִי־חֶ֛רֶשׂ אֲשֶׁ֥ר תְּבֻשַּׁל־בּ֖וֹ יִשָּׁבֵ֑ר וְאִם־בִּכְלִ֤י נְחֹ֨שֶׁת֙ בֻּשָּׁ֔לָה וּמֹרַ֥ק וְשֻׁטַּ֖ף בַּמָּֽיִם׃"
          },
          {
            "num": "כג",
            "txt": "כָּל־זָכָ֥ר בַּכֹּֽהֲנִ֖ים יֹאכַ֣ל אֹתָ֑הּ קֹ֥דֶשׁ קָֽדָשִׁ֖ים הִֽוא׃"
          },
          {
            "txt": "וְכָל־חַטָּ֡את אֲשֶׁר֩ יוּבָ֨א מִדָּמָ֜הּ אֶל־אֹ֧הֶל מוֹעֵ֛ד לְכַפֵּ֥ר בַּקֹּ֖דֶשׁ לֹ֣א תֵֽאָכֵ֑ל בָּאֵ֖שׁ תִּשָּׂרֵֽף׃ {פ}"
          }
        ]
      },
      {
        "num": "ז",
        "verses": [
          {
            "num": "א",
            "txt": "וְזֹ֥את תּוֹרַ֖ת הָֽאָשָׁ֑ם קֹ֥דֶשׁ קָֽדָשִׁ֖ים הֽוּא׃"
          },
          {
            "num": "ב",
            "txt": "בִּמְק֗וֹם אֲשֶׁ֤ר יִשְׁחֲטוּ֙ אֶת־הָ֣עֹלָ֔ה יִשְׁחֲט֖וּ אֶת־הָֽאָשָׁ֑ם וְאֶת־דָּמ֛וֹ יִזְרֹ֥ק עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "ג",
            "txt": "וְאֵ֥ת כָּל־חֶלְבּ֖וֹ יַקְרִ֣יב מִמֶּ֑נּוּ אֵ֚ת הָֽאַלְיָ֔ה וְאֶת־הַחֵ֖לֶב הַֽמְכַסֶּ֥ה אֶת־הַקֶּֽרֶב׃"
          },
          {
            "num": "ד",
            "txt": "וְאֵת֙ שְׁתֵּ֣י הַכְּלָיֹ֔ת וְאֶת־הַחֵ֨לֶב֙ אֲשֶׁ֣ר עֲלֵיהֶ֔ן אֲשֶׁ֖ר עַל־הַכְּסָלִ֑ים וְאֶת־הַיֹּתֶ֨רֶת֙ עַל־הַכָּבֵ֔ד עַל־הַכְּלָיֹ֖ת יְסִירֶֽנָּה׃"
          },
          {
            "num": "ה",
            "txt": "וְהִקְטִ֨יר אֹתָ֤ם הַכֹּהֵן֙ הַמִּזְבֵּ֔חָה אִשֶּׁ֖ה לַֽיהוָ֑ה אָשָׁ֖ם הֽוּא׃"
          },
          {
            "num": "ו",
            "txt": "כָּל־זָכָ֥ר בַּכֹּֽהֲנִ֖ים יֹֽאכְלֶ֑נּוּ בְּמָק֤וֹם קָדוֹשׁ֙ יֵֽאָכֵ֔ל קֹ֥דֶשׁ קָֽדָשִׁ֖ים הֽוּא׃"
          },
          {
            "num": "ז",
            "txt": "כַּֽחַטָּאת֙ כָּֽאָשָׁ֔ם תּוֹרָ֥ה אַחַ֖ת לָהֶ֑ם הַכֹּהֵ֛ן אֲשֶׁ֥ר יְכַפֶּר־בּ֖וֹ ל֥וֹ יִֽהְיֶֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְהַ֨כֹּהֵ֔ן הַמַּקְרִ֖יב אֶת־עֹ֣לַת אִ֑ישׁ ע֤וֹר הָֽעֹלָה֙ אֲשֶׁ֣ר הִקְרִ֔יב לַכֹּהֵ֖ן ל֥וֹ יִֽהְיֶֽה׃"
          },
          {
            "num": "ט",
            "txt": "וְכָל־מִנְחָ֗ה אֲשֶׁ֤ר תֵּֽאָפֶה֙ בַּתַּנּ֔וּר וְכָל־נַֽעֲשָׂ֥ה בַמַּרְחֶ֖שֶׁת וְעַֽל־מַחֲבַ֑ת לַכֹּהֵ֛ן הַמַּקְרִ֥יב אֹתָ֖הּ ל֥וֹ תִֽהְיֶֽה׃"
          },
          {
            "num": "י",
            "txt": "וְכָל־מִנְחָ֥ה בְלוּלָֽה־בַשֶּׁ֖מֶן וַֽחֲרֵבָ֑ה לְכָל־בְּנֵ֧י אַֽהֲרֹ֛ן תִּֽהְיֶ֖ה אִ֥ישׁ כְּאָחִֽיו׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וְזֹ֥את תּוֹרַ֖ת זֶ֣בַח הַשְּׁלָמִ֑ים אֲשֶׁ֥ר יַקְרִ֖יב לַֽיהוָֽה׃"
          },
          {
            "num": "יב",
            "txt": "אִ֣ם עַל־תּוֹדָה֮ יַקְרִיבֶנּוּ֒ וְהִקְרִ֣יב ׀ עַל־זֶ֣בַח הַתּוֹדָ֗ה חַלּ֤וֹת מַצּוֹת֙ בְּלוּלֹ֣ת בַּשֶּׁ֔מֶן וּרְקִיקֵ֥י מַצּ֖וֹת מְשֻׁחִ֣ים בַּשָּׁ֑מֶן וְסֹ֣לֶת מֻרְבֶּ֔כֶת חַלֹּ֖ת בְּלוּלֹ֥ת בַּשָּֽׁמֶן׃"
          },
          {
            "num": "יג",
            "txt": "עַל־חַלֹּת֙ לֶ֣חֶם חָמֵ֔ץ יַקְרִ֖יב קָרְבָּנ֑וֹ עַל־זֶ֖בַח תּוֹדַ֥ת שְׁלָמָֽיו׃"
          },
          {
            "num": "יד",
            "txt": "וְהִקְרִ֨יב מִמֶּ֤נּוּ אֶחָד֙ מִכָּל־קָרְבָּ֔ן תְּרוּמָ֖ה לַֽיהוָ֑ה לַכֹּהֵ֗ן הַזֹּרֵ֛ק אֶת־דַּ֥ם הַשְּׁלָמִ֖ים ל֥וֹ יִֽהְיֶֽה׃"
          },
          {
            "num": "טו",
            "txt": "וּבְשַׂ֗ר זֶ֚בַח תּוֹדַ֣ת שְׁלָמָ֔יו בְּי֥וֹם קָרְבָּנ֖וֹ יֵֽאָכֵ֑ל לֹֽא־יַנִּ֥יחַ מִמֶּ֖נּוּ עַד־בֹּֽקֶר׃"
          },
          {
            "num": "טז",
            "txt": "וְאִם־נֶ֣דֶר ׀ א֣וֹ נְדָבָ֗ה זֶ֚בַח קָרְבָּנ֔וֹ בְּי֛וֹם הַקְרִיב֥וֹ אֶת־זִבְח֖וֹ יֵֽאָכֵ֑ל וּמִֽמָּחֳרָ֔ת וְהַנּוֹתָ֥ר מִמֶּ֖נּוּ יֵֽאָכֵֽל׃"
          },
          {
            "num": "יז",
            "txt": "וְהַנּוֹתָ֖ר מִבְּשַׂ֣ר הַזָּ֑בַח בַּיּוֹם֙ הַשְּׁלִישִׁ֔י בָּאֵ֖שׁ יִשָּׂרֵֽף׃"
          },
          {
            "num": "יח",
            "txt": "וְאִ֣ם הֵֽאָכֹ֣ל יֵֽ֠אָכֵל מִבְּשַׂר־זֶ֨בַח שְׁלָמָ֜יו בַּיּ֣וֹם הַשְּׁלִישִׁי֮ לֹ֣א יֵֽרָצֶה֒ הַמַּקְרִ֣יב אֹת֗וֹ לֹ֧א יֵֽחָשֵׁ֛ב ל֖וֹ פִּגּ֣וּל יִֽהְיֶ֑ה וְהַנֶּ֛פֶשׁ הָֽאֹכֶ֥לֶת מִמֶּ֖נּוּ עֲוֺנָ֥הּ תִּשָּֽׂא׃"
          },
          {
            "num": "יט",
            "txt": "וְהַבָּשָׂ֞ר אֲשֶׁר־יִגַּ֤ע בְּכָל־טָמֵא֙ לֹ֣א יֵֽאָכֵ֔ל בָּאֵ֖שׁ יִשָּׂרֵ֑ף וְהַ֨בָּשָׂ֔ר כָּל־טָה֖וֹר יֹאכַ֥ל בָּשָֽׂר׃"
          },
          {
            "num": "כ",
            "txt": "וְהַנֶּ֜פֶשׁ אֲשֶׁר־תֹּאכַ֣ל בָּשָׂ֗ר מִזֶּ֤בַח הַשְּׁלָמִים֙ אֲשֶׁ֣ר לַֽיהוָ֔ה וְטֻמְאָת֖וֹ עָלָ֑יו וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מֵֽעַמֶּֽיהָ׃"
          },
          {
            "num": "כא",
            "txt": "וְנֶ֜פֶשׁ כִּֽי־תִגַּ֣ע בְּכָל־טָמֵ֗א בְּטֻמְאַ֤ת אָדָם֙ א֣וֹ ׀ בִּבְהֵמָ֣ה טְמֵאָ֗ה א֚וֹ בְּכָל־שֶׁ֣קֶץ טָמֵ֔א וְאָכַ֛ל מִבְּשַׂר־זֶ֥בַח הַשְּׁלָמִ֖ים אֲשֶׁ֣ר לַֽיהוָ֑ה וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מֵֽעַמֶּֽיהָ׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כג",
            "txt": "דַּבֵּ֛ר אֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר כָּל־חֵ֜לֶב שׁ֥וֹר וְכֶ֛שֶׂב וָעֵ֖ז לֹ֥א תֹאכֵֽלוּ׃"
          },
          {
            "num": "כד",
            "txt": "וְחֵ֤לֶב נְבֵלָה֙ וְחֵ֣לֶב טְרֵפָ֔ה יֵֽעָשֶׂ֖ה לְכָל־מְלָאכָ֑ה וְאָכֹ֖ל לֹ֥א תֹֽאכְלֻֽהוּ׃"
          },
          {
            "num": "כה",
            "txt": "כִּ֚י כָּל־אֹכֵ֣ל חֵ֔לֶב מִ֨ן־הַבְּהֵמָ֔ה אֲשֶׁ֨ר יַקְרִ֥יב מִמֶּ֛נָּה אִשֶּׁ֖ה לַֽיהוָ֑ה וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הָֽאֹכֶ֖לֶת מֵֽעַמֶּֽיהָ׃"
          },
          {
            "num": "כו",
            "txt": "וְכָל־דָּם֙ לֹ֣א תֹֽאכְל֔וּ בְּכֹ֖ל מוֹשְׁבֹֽתֵיכֶ֑ם לָע֖וֹף וְלַבְּהֵמָֽה׃"
          },
          {
            "num": "כז",
            "txt": "כָּל־נֶ֖פֶשׁ אֲשֶׁר־תֹּאכַ֣ל כָּל־דָּ֑ם וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מֵֽעַמֶּֽיהָ׃"
          },
          {
            "num": "כח",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כט",
            "txt": "דַּבֵּ֛ר אֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר הַמַּקְרִ֞יב אֶת־זֶ֤בַח שְׁלָמָיו֙ לַֽיהוָ֔ה יָבִ֧יא אֶת־קָרְבָּנ֛וֹ לַֽיהוָ֖ה מִזֶּ֥בַח שְׁלָמָֽיו׃"
          },
          {
            "num": "ל",
            "txt": "יָדָ֣יו תְּבִיאֶ֔ינָה אֵ֖ת אִשֵּׁ֣י יְהוָ֑ה אֶת־הַחֵ֤לֶב עַל־הֶֽחָזֶה֙ יְבִיאֶ֔נּוּ אֵ֣ת הֶֽחָזֶ֗ה לְהָנִ֥יף אֹת֛וֹ תְּנוּפָ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "לא",
            "txt": "וְהִקְטִ֧יר הַכֹּהֵ֛ן אֶת־הַחֵ֖לֶב הַמִּזְבֵּ֑חָה וְהָיָה֙ הֶֽחָזֶ֔ה לְאַֽהֲרֹ֖ן וּלְבָנָֽיו׃"
          },
          {
            "num": "לב",
            "txt": "וְאֵת֙ שׁ֣וֹק הַיָּמִ֔ין תִּתְּנ֥וּ תְרוּמָ֖ה לַכֹּהֵ֑ן מִזִּבְחֵ֖י שַׁלְמֵיכֶֽם׃"
          },
          {
            "num": "לג",
            "txt": "הַמַּקְרִ֞יב אֶת־דַּ֧ם הַשְּׁלָמִ֛ים וְאֶת־הַחֵ֖לֶב מִבְּנֵ֣י אַֽהֲרֹ֑ן ל֧וֹ תִֽהְיֶ֛ה שׁ֥וֹק הַיָּמִ֖ין לְמָנָֽה׃"
          },
          {
            "num": "לד",
            "txt": "כִּי֩ אֶת־חֲזֵ֨ה הַתְּנוּפָ֜ה וְאֵ֣ת ׀ שׁ֣וֹק הַתְּרוּמָ֗ה לָקַ֨חְתִּי֙ מֵאֵ֣ת בְּנֵֽי־יִשְׂרָאֵ֔ל מִזִּבְחֵ֖י שַׁלְמֵיהֶ֑ם וָֽאֶתֵּ֣ן אֹ֠תָם לְאַֽהֲרֹ֨ן הַכֹּהֵ֤ן וּלְבָנָיו֙ לְחָק־עוֹלָ֔ם מֵאֵ֖ת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "לה",
            "txt": "זֹ֣את מִשְׁחַ֤ת אַֽהֲרֹן֙ וּמִשְׁחַ֣ת בָּנָ֔יו מֵֽאִשֵּׁ֖י יְהוָ֑ה בְּיוֹם֙ הִקְרִ֣יב אֹתָ֔ם לְכַהֵ֖ן לַֽיהוָֽה׃"
          },
          {
            "num": "לו",
            "txt": "אֲשֶׁר֩ צִוָּ֨ה יְהוָ֜ה לָתֵ֣ת לָהֶ֗ם בְּיוֹם֙ מָשְׁח֣וֹ אֹתָ֔ם מֵאֵ֖ת בְּנֵ֣י יִשְׂרָאֵ֑ל חֻקַּ֥ת עוֹלָ֖ם לְדֹֽרֹתָֽם׃"
          },
          {
            "num": "לז",
            "txt": "זֹ֣את הַתּוֹרָ֗ה לָֽעֹלָה֙ לַמִּנְחָ֔ה וְלַֽחַטָּ֖את וְלָֽאָשָׁ֑ם וְלַ֨מִּלּוּאִ֔ים וּלְזֶ֖בַח הַשְּׁלָמִֽים׃"
          },
          {
            "num": "לח",
            "txt": "אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֶת־מֹשֶׁ֖ה בְּהַ֣ר סִינָ֑י בְּי֨וֹם צַוֺּת֜וֹ אֶת־בְּנֵ֣י יִשְׂרָאֵ֗ל לְהַקְרִ֧יב אֶת־קָרְבְּנֵיהֶ֛ם לַֽיהוָ֖ה בְּמִדְבַּ֥ר סִינָֽי׃ {פ}"
          }
        ]
      },
      {
        "num": "ח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "קַ֤ח אֶֽת־אַהֲרֹן֙ וְאֶת־בָּנָ֣יו אִתּ֔וֹ וְאֵת֙ הַבְּגָדִ֔ים וְאֵ֖ת שֶׁ֣מֶן הַמִּשְׁחָ֑ה וְאֵ֣ת ׀ פַּ֣ר הַֽחַטָּ֗את וְאֵת֙ שְׁנֵ֣י הָֽאֵילִ֔ים וְאֵ֖ת סַ֥ל הַמַּצּֽוֹת׃"
          },
          {
            "num": "ג",
            "txt": "וְאֵ֥ת כָּל־הָֽעֵדָ֖ה הַקְהֵ֑ל אֶל־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ד",
            "txt": "וַיַּ֣עַשׂ מֹשֶׁ֔ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֹת֑וֹ וַתִּקָּהֵל֙ הָֽעֵדָ֔ה אֶל־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֥אמֶר מֹשֶׁ֖ה אֶל־הָֽעֵדָ֑ה זֶ֣ה הַדָּבָ֔ר אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה לַֽעֲשֽׂוֹת׃"
          },
          {
            "num": "ו",
            "txt": "וַיַּקְרֵ֣ב מֹשֶׁ֔ה אֶֽת־אַהֲרֹ֖ן וְאֶת־בָּנָ֑יו וַיִּרְחַ֥ץ אֹתָ֖ם בַּמָּֽיִם׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּתֵּ֨ן עָלָ֜יו אֶת־הַכֻּתֹּ֗נֶת וַיַּחְגֹּ֤ר אֹתוֹ֙ בָּֽאַבְנֵ֔ט וַיַּלְבֵּ֤שׁ אֹתוֹ֙ אֶֽת־הַמְּעִ֔יל וַיִּתֵּ֥ן עָלָ֖יו אֶת־הָֽאֵפֹ֑ד וַיַּחְגֹּ֣ר אֹת֗וֹ בְּחֵ֨שֶׁב֙ הָֽאֵפֹ֔ד וַיֶּאְפֹּ֥ד ל֖וֹ בּֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וַיָּ֥שֶׂם עָלָ֖יו אֶת־הַחֹ֑שֶׁן וַיִּתֵּן֙ אֶל־הַחֹ֔שֶׁן אֶת־הָֽאוּרִ֖ים וְאֶת־הַתֻּמִּֽים׃"
          },
          {
            "num": "ט",
            "txt": "וַיָּ֥שֶׂם אֶת־הַמִּצְנֶ֖פֶת עַל־רֹאשׁ֑וֹ וַיָּ֨שֶׂם עַֽל־הַמִּצְנֶ֜פֶת אֶל־מ֣וּל פָּנָ֗יו אֵ֣ת צִ֤יץ הַזָּהָב֙ נֵ֣זֶר הַקֹּ֔דֶשׁ כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "י",
            "txt": "וַיִּקַּ֤ח מֹשֶׁה֙ אֶת־שֶׁ֣מֶן הַמִּשְׁחָ֔ה וַיִּמְשַׁ֥ח אֶת־הַמִּשְׁכָּ֖ן וְאֶת־כָּל־אֲשֶׁר־בּ֑וֹ וַיְקַדֵּ֖שׁ אֹתָֽם׃"
          },
          {
            "num": "יא",
            "txt": "וַיַּ֥ז מִמֶּ֛נּוּ עַל־הַמִּזְבֵּ֖חַ שֶׁ֣בַע פְּעָמִ֑ים וַיִּמְשַׁ֨ח אֶת־הַמִּזְבֵּ֜חַ וְאֶת־כָּל־כֵּלָ֗יו וְאֶת־הַכִּיֹּ֛ר וְאֶת־כַּנּ֖וֹ לְקַדְּשָֽׁם׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּצֹק֙ מִשֶּׁ֣מֶן הַמִּשְׁחָ֔ה עַ֖ל רֹ֣אשׁ אַֽהֲרֹ֑ן וַיִּמְשַׁ֥ח אֹת֖וֹ לְקַדְּשֽׁוֹ׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּקְרֵ֨ב מֹשֶׁ֜ה אֶת־בְּנֵ֣י אַֽהֲרֹ֗ן וַיַּלְבִּשֵׁ֤ם כֻּתֳּנֹת֙ וַיַּחְגֹּ֤ר אֹתָם֙ אַבְנֵ֔ט וַיַּֽחֲבֹ֥שׁ לָהֶ֖ם מִגְבָּע֑וֹת כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "יד",
            "txt": "וַיַּגֵּ֕שׁ אֵ֖ת פַּ֣ר הַֽחַטָּ֑את וַיִּסְמֹ֨ךְ אַֽהֲרֹ֤ן וּבָנָיו֙ אֶת־יְדֵיהֶ֔ם עַל־רֹ֖אשׁ פַּ֥ר הַֽחַטָּֽאת׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּשְׁחָ֗ט וַיִּקַּ֨ח מֹשֶׁ֤ה אֶת־הַדָּם֙ וַ֠יִּתֵּן עַל־קַרְנ֨וֹת הַמִּזְבֵּ֤חַ סָבִיב֙ בְּאֶצְבָּע֔וֹ וַיְחַטֵּ֖א אֶת־הַמִּזְבֵּ֑חַ וְאֶת־הַדָּ֗ם יָצַק֙ אֶל־יְס֣וֹד הַמִּזְבֵּ֔חַ וַֽיְקַדְּשֵׁ֖הוּ לְכַפֵּ֥ר עָלָֽיו׃"
          },
          {
            "num": "טז",
            "txt": "וַיִּקַּ֗ח אֶֽת־כָּל־הַחֵלֶב֮ אֲשֶׁ֣ר עַל־הַקֶּרֶב֒ וְאֵת֙ יֹתֶ֣רֶת הַכָּבֵ֔ד וְאֶת־שְׁתֵּ֥י הַכְּלָיֹ֖ת וְאֶֽת־חֶלְבְּהֶ֑ן וַיַּקְטֵ֥ר מֹשֶׁ֖ה הַמִּזְבֵּֽחָה׃"
          },
          {
            "num": "יז",
            "txt": "וְאֶת־הַפָּ֤ר וְאֶת־עֹרוֹ֙ וְאֶת־בְּשָׂר֣וֹ וְאֶת־פִּרְשׁ֔וֹ שָׂרַ֣ף בָּאֵ֔שׁ מִח֖וּץ לַֽמַּחֲנֶ֑ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "יח",
            "txt": "וַיַּקְרֵ֕ב אֵ֖ת אֵ֣יל הָֽעֹלָ֑ה וַֽיִּסְמְכ֞וּ אַֽהֲרֹ֧ן וּבָנָ֛יו אֶת־יְדֵיהֶ֖ם עַל־רֹ֥אשׁ הָאָֽיִל׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּשְׁחָ֑ט וַיִּזְרֹ֨ק מֹשֶׁ֧ה אֶת־הַדָּ֛ם עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "כ",
            "txt": "וְאֶ֨ת־הָאַ֔יִל נִתַּ֖ח לִנְתָחָ֑יו וַיַּקְטֵ֤ר מֹשֶׁה֙ אֶת־הָרֹ֔אשׁ וְאֶת־הַנְּתָחִ֖ים וְאֶת־הַפָּֽדֶר׃"
          },
          {
            "num": "כא",
            "txt": "וְאֶת־הַקֶּ֥רֶב וְאֶת־הַכְּרָעַ֖יִם רָחַ֣ץ בַּמָּ֑יִם וַיַּקְטֵר֩ מֹשֶׁ֨ה אֶת־כָּל־הָאַ֜יִל הַמִּזְבֵּ֗חָה עֹלָ֨ה ה֤וּא לְרֵֽיחַ־נִיחֹ֨חַ֙ אִשֶּׁ֥ה הוּא֙ לַֽיהוָ֔ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "כב",
            "txt": "וַיַּקְרֵב֙ אֶת־הָאַ֣יִל הַשֵּׁנִ֔י אֵ֖יל הַמִּלֻּאִ֑ים וַֽיִּסְמְכ֞וּ אַֽהֲרֹ֧ן וּבָנָ֛יו אֶת־יְדֵיהֶ֖ם עַל־רֹ֥אשׁ הָאָֽיִל׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּשְׁחָ֓ט ׀ וַיִּקַּ֤ח מֹשֶׁה֙ מִדָּמ֔וֹ וַיִּתֵּ֛ן עַל־תְּנ֥וּךְ אֹֽזֶן־אַהֲרֹ֖ן הַיְמָנִ֑ית וְעַל־בֹּ֤הֶן יָדוֹ֙ הַיְמָנִ֔ית וְעַל־בֹּ֥הֶן רַגְל֖וֹ הַיְמָנִֽית׃"
          },
          {
            "num": "כד",
            "txt": "וַיַּקְרֵ֞ב אֶת־בְּנֵ֣י אַֽהֲרֹ֗ן וַיִּתֵּ֨ן מֹשֶׁ֤ה מִן־הַדָּם֙ עַל־תְּנ֤וּךְ אָזְנָם֙ הַיְמָנִ֔ית וְעַל־בֹּ֤הֶן יָדָם֙ הַיְמָנִ֔ית וְעַל־בֹּ֥הֶן רַגְלָ֖ם הַיְמָנִ֑ית וַיִּזְרֹ֨ק מֹשֶׁ֧ה אֶת־הַדָּ֛ם עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּקַּ֞ח אֶת־הַחֵ֣לֶב וְאֶת־הָֽאַלְיָ֗ה וְאֶֽת־כָּל־הַחֵלֶב֮ אֲשֶׁ֣ר עַל־הַקֶּרֶב֒ וְאֵת֙ יֹתֶ֣רֶת הַכָּבֵ֔ד וְאֶת־שְׁתֵּ֥י הַכְּלָיֹ֖ת וְאֶֽת־חֶלְבְּהֶ֑ן וְאֵ֖ת שׁ֥וֹק הַיָּמִֽין׃"
          },
          {
            "num": "כו",
            "txt": "וּמִסַּ֨ל הַמַּצּ֜וֹת אֲשֶׁ֣ר ׀ לִפְנֵ֣י יְהוָ֗ה לָ֠קַח חַלַּ֨ת מַצָּ֤ה אַחַת֙ וְֽחַלַּ֨ת לֶ֥חֶם שֶׁ֛מֶן אַחַ֖ת וְרָקִ֣יק אֶחָ֑ד וַיָּ֨שֶׂם֙ עַל־הַ֣חֲלָבִ֔ים וְעַ֖ל שׁ֥וֹק הַיָּמִֽין׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּתֵּ֣ן אֶת־הַכֹּ֔ל עַ֚ל כַּפֵּ֣י אַֽהֲרֹ֔ן וְעַ֖ל כַּפֵּ֣י בָנָ֑יו וַיָּ֧נֶף אֹתָ֛ם תְּנוּפָ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וַיִּקַּ֨ח מֹשֶׁ֤ה אֹתָם֙ מֵעַ֣ל כַּפֵּיהֶ֔ם וַיַּקְטֵ֥ר הַמִּזְבֵּ֖חָה עַל־הָֽעֹלָ֑ה מִלֻּאִ֥ים הֵם֙ לְרֵ֣יחַ נִיחֹ֔חַ אִשֶּׁ֥ה ה֖וּא לַֽיהוָֽה׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּקַּ֤ח מֹשֶׁה֙ אֶת־הֶ֣חָזֶ֔ה וַיְנִיפֵ֥הוּ תְנוּפָ֖ה לִפְנֵ֣י יְהוָ֑ה מֵאֵ֣יל הַמִּלֻּאִ֗ים לְמֹשֶׁ֤ה הָיָה֙ לְמָנָ֔ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "ל",
            "txt": "וַיִּקַּ֨ח מֹשֶׁ֜ה מִשֶּׁ֣מֶן הַמִּשְׁחָ֗ה וּמִן־הַדָּם֮ אֲשֶׁ֣ר עַל־הַמִּזְבֵּחַ֒ וַיַּ֤ז עַֽל־אַהֲרֹן֙ עַל־בְּגָדָ֔יו וְעַל־בָּנָ֛יו וְעַל־בִּגְדֵ֥י בָנָ֖יו אִתּ֑וֹ וַיְקַדֵּ֤שׁ אֶֽת־אַהֲרֹן֙ אֶת־בְּגָדָ֔יו וְאֶת־בָּנָ֛יו וְאֶת־בִּגְדֵ֥י בָנָ֖יו אִתּֽוֹ׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶל־אַֽהֲרֹ֣ן וְאֶל־בָּנָ֗יו בַּשְּׁל֣וּ אֶת־הַבָּשָׂר֮ פֶּ֣תַח אֹ֣הֶל מוֹעֵד֒ וְשָׁם֙ תֹּֽאכְל֣וּ אֹת֔וֹ וְאֶ֨ת־הַלֶּ֔חֶם אֲשֶׁ֖ר בְּסַ֣ל הַמִּלֻּאִ֑ים כַּֽאֲשֶׁ֤ר צִוֵּ֨יתִי֙ לֵאמֹ֔ר אַֽהֲרֹ֥ן וּבָנָ֖יו יֹֽאכְלֻֽהוּ׃"
          },
          {
            "num": "לב",
            "txt": "וְהַנּוֹתָ֥ר בַּבָּשָׂ֖ר וּבַלָּ֑חֶם בָּאֵ֖שׁ תִּשְׂרֹֽפוּ׃"
          },
          {
            "num": "לג",
            "txt": "וּמִפֶּתַח֩ אֹ֨הֶל מוֹעֵ֜ד לֹ֤א תֵֽצְאוּ֙ שִׁבְעַ֣ת יָמִ֔ים עַ֚ד י֣וֹם מְלֹ֔את יְמֵ֖י מִלֻּֽאֵיכֶ֑ם כִּ֚י שִׁבְעַ֣ת יָמִ֔ים יְמַלֵּ֖א אֶת־יֶדְכֶֽם׃"
          },
          {
            "num": "לד",
            "txt": "כַּֽאֲשֶׁ֥ר עָשָׂ֖ה בַּיּ֣וֹם הַזֶּ֑ה צִוָּ֧ה יְהוָ֛ה לַֽעֲשֹׂ֖ת לְכַפֵּ֥ר עֲלֵיכֶֽם׃"
          },
          {
            "num": "לה",
            "txt": "וּפֶתַח֩ אֹ֨הֶל מוֹעֵ֜ד תֵּֽשְׁב֨וּ יוֹמָ֤ם וָלַ֨יְלָה֙ שִׁבְעַ֣ת יָמִ֔ים וּשְׁמַרְתֶּ֛ם אֶת־מִשְׁמֶ֥רֶת יְהוָ֖ה וְלֹ֣א תָמ֑וּתוּ כִּי־כֵ֖ן צֻוֵּֽיתִי׃"
          },
          {
            "num": "לו",
            "txt": "וַיַּ֥עַשׂ אַֽהֲרֹ֖ן וּבָנָ֑יו אֵ֚ת כָּל־הַדְּבָרִ֔ים אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה בְּיַד־מֹשֶֽׁה׃ {ס}"
          }
        ]
      },
      {
        "num": "ט",
        "verses": [
          {
            "num": "א",
            "txt": "וַֽיְהִי֙ בַּיּ֣וֹם הַשְּׁמִינִ֔י קָרָ֣א מֹשֶׁ֔ה לְאַֽהֲרֹ֖ן וּלְבָנָ֑יו וּלְזִקְנֵ֖י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֣אמֶר אֶֽל־אַהֲרֹ֗ן קַח־לְ֠ךָ עֵ֣גֶל בֶּן־בָּקָ֧ר לְחַטָּ֛את וְאַ֥יִל לְעֹלָ֖ה תְּמִימִ֑ם וְהַקְרֵ֖ב לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וְאֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל תְּדַבֵּ֣ר לֵאמֹ֑ר קְח֤וּ שְׂעִיר־עִזִּים֙ לְחַטָּ֔את וְעֵ֨גֶל וָכֶ֧בֶשׂ בְּנֵֽי־שָׁנָ֛ה תְּמִימִ֖ם לְעֹלָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וְשׁ֨וֹר וָאַ֜יִל לִשְׁלָמִ֗ים לִזְבֹּ֨חַ֙ לִפְנֵ֣י יְהוָ֔ה וּמִנְחָ֖ה בְּלוּלָ֣ה בַשָּׁ֑מֶן כִּ֣י הַיּ֔וֹם יְהוָ֖ה נִרְאָ֥ה אֲלֵיכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּקְח֗וּ אֵ֚ת אֲשֶׁ֣ר צִוָּ֣ה מֹשֶׁ֔ה אֶל־פְּנֵ֖י אֹ֣הֶל מוֹעֵ֑ד וַֽיִּקְרְבוּ֙ כָּל־הָ֣עֵדָ֔ה וַיַּֽעַמְד֖וּ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה זֶ֧ה הַדָּבָ֛ר אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה תַּֽעֲשׂ֑וּ וְיֵרָ֥א אֲלֵיכֶ֖ם כְּב֥וֹד יְהוָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶֽל־אַהֲרֹ֗ן קְרַ֤ב אֶל־הַמִּזְבֵּ֨חַ֙ וַֽעֲשֵׂ֞ה אֶת־חַטָּֽאתְךָ֙ וְאֶת־עֹ֣לָתֶ֔ךָ וְכַפֵּ֥ר בַּֽעַדְךָ֖ וּבְעַ֣ד הָעָ֑ם וַֽעֲשֵׂ֞ה אֶת־קָרְבַּ֤ן הָעָם֙ וְכַפֵּ֣ר בַּֽעֲדָ֔ם כַּֽאֲשֶׁ֖ר צִוָּ֥ה יְהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּקְרַ֥ב אַֽהֲרֹ֖ן אֶל־הַמִּזְבֵּ֑חַ וַיִּשְׁחַ֛ט אֶת־עֵ֥גֶל הַֽחַטָּ֖את אֲשֶׁר־לֽוֹ׃"
          },
          {
            "num": "ט",
            "txt": "וַ֠יַּקְרִבוּ בְּנֵ֨י אַֽהֲרֹ֣ן אֶת־הַדָּם֮ אֵלָיו֒ וַיִּטְבֹּ֤ל אֶצְבָּעוֹ֙ בַּדָּ֔ם וַיִּתֵּ֖ן עַל־קַרְנ֣וֹת הַמִּזְבֵּ֑חַ וְאֶת־הַדָּ֣ם יָצַ֔ק אֶל־יְס֖וֹד הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "י",
            "txt": "וְאֶת־הַחֵ֨לֶב וְאֶת־הַכְּלָיֹ֜ת וְאֶת־הַיֹּתֶ֤רֶת מִן־הַכָּבֵד֙ מִן־הַ֣חַטָּ֔את הִקְטִ֖יר הַמִּזְבֵּ֑חָה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "יא",
            "txt": "וְאֶת־הַבָּשָׂ֖ר וְאֶת־הָע֑וֹר שָׂרַ֣ף בָּאֵ֔שׁ מִח֖וּץ לַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּשְׁחַ֖ט אֶת־הָֽעֹלָ֑ה וַ֠יַּמְצִאוּ בְּנֵ֨י אַֽהֲרֹ֤ן אֵלָיו֙ אֶת־הַדָּ֔ם וַיִּזְרְקֵ֥הוּ עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "יג",
            "txt": "וְאֶת־הָֽעֹלָ֗ה הִמְצִ֧יאוּ אֵלָ֛יו לִנְתָחֶ֖יהָ וְאֶת־הָרֹ֑אשׁ וַיַּקְטֵ֖ר עַל־הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּרְחַ֥ץ אֶת־הַקֶּ֖רֶב וְאֶת־הַכְּרָעָ֑יִם וַיַּקְטֵ֥ר עַל־הָֽעֹלָ֖ה הַמִּזְבֵּֽחָה׃"
          },
          {
            "num": "טו",
            "txt": "וַיַּקְרֵ֕ב אֵ֖ת קָרְבַּ֣ן הָעָ֑ם וַיִּקַּ֞ח אֶת־שְׂעִ֤יר הַֽחַטָּאת֙ אֲשֶׁ֣ר לָעָ֔ם וַיִּשְׁחָטֵ֥הוּ וַֽיְחַטְּאֵ֖הוּ כָּֽרִאשֽׁוֹן׃"
          },
          {
            "num": "טז",
            "txt": "וַיַּקְרֵ֖ב אֶת־הָֽעֹלָ֑ה וַֽיַּעֲשֶׂ֖הָ כַּמִּשְׁפָּֽט׃"
          },
          {
            "num": "יז",
            "txt": "וַיַּקְרֵב֮ אֶת־הַמִּנְחָה֒ וַיְמַלֵּ֤א כַפּוֹ֙ מִמֶּ֔נָּה וַיַּקְטֵ֖ר עַל־הַמִּזְבֵּ֑חַ מִלְּבַ֖ד עֹלַ֥ת הַבֹּֽקֶר׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּשְׁחַ֤ט אֶת־הַשּׁוֹר֙ וְאֶת־הָאַ֔יִל זֶ֥בַח הַשְּׁלָמִ֖ים אֲשֶׁ֣ר לָעָ֑ם וַ֠יַּמְצִאוּ בְּנֵ֨י אַֽהֲרֹ֤ן אֶת־הַדָּם֙ אֵלָ֔יו וַיִּזְרְקֵ֥הוּ עַל־הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "יט",
            "txt": "וְאֶת־הַֽחֲלָבִ֖ים מִן־הַשּׁ֑וֹר וּמִ֨ן־הָאַ֔יִל הָֽאַלְיָ֤ה וְהַֽמְכַסֶּה֙ וְהַכְּלָיֹ֔ת וְיֹתֶ֖רֶת הַכָּבֵֽד׃"
          },
          {
            "num": "כ",
            "txt": "וַיָּשִׂ֥ימוּ אֶת־הַֽחֲלָבִ֖ים עַל־הֶֽחָז֑וֹת וַיַּקְטֵ֥ר הַֽחֲלָבִ֖ים הַמִּזְבֵּֽחָה׃"
          },
          {
            "num": "כא",
            "txt": "וְאֵ֣ת הֶֽחָז֗וֹת וְאֵת֙ שׁ֣וֹק הַיָּמִ֔ין הֵנִ֧יף אַֽהֲרֹ֛ן תְּנוּפָ֖ה לִפְנֵ֣י יְהוָ֑ה כַּֽאֲשֶׁ֖ר צִוָּ֥ה מֹשֶֽׁה׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּשָּׂ֨א אַֽהֲרֹ֧ן אֶת־יָדָ֛ו אֶל־הָעָ֖ם וַֽיְבָרְכֵ֑ם וַיֵּ֗רֶד מֵֽעֲשֹׂ֧ת הַֽחַטָּ֛את וְהָֽעֹלָ֖ה וְהַשְּׁלָמִֽים׃"
          },
          {
            "num": "כג",
            "txt": "וַיָּבֹ֨א מֹשֶׁ֤ה וְאַֽהֲרֹן֙ אֶל־אֹ֣הֶל מוֹעֵ֔ד וַיֵּ֣צְא֔וּ וַֽיְבָרְכ֖וּ אֶת־הָעָ֑ם וַיֵּרָ֥א כְבוֹד־יְהוָ֖ה אֶל־כָּל־הָעָֽם׃"
          },
          {
            "num": "כד",
            "txt": "וַתֵּ֤צֵא אֵשׁ֙ מִלִּפְנֵ֣י יְהוָ֔ה וַתֹּ֨אכַל֙ עַל־הַמִּזְבֵּ֔חַ אֶת־הָֽעֹלָ֖ה וְאֶת־הַֽחֲלָבִ֑ים וַיַּ֤רְא כָּל־הָעָם֙ וַיָּרֹ֔נּוּ וַֽיִּפְּל֖וּ עַל־פְּנֵיהֶֽם׃"
          }
        ]
      },
      {
        "num": "י",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּקְח֣וּ בְנֵֽי־אַ֠הֲרֹן נָדָ֨ב וַֽאֲבִיה֜וּא אִ֣ישׁ מַחְתָּת֗וֹ וַיִּתְּנ֤וּ בָהֵן֙ אֵ֔שׁ וַיָּשִׂ֥ימוּ עָלֶ֖יהָ קְטֹ֑רֶת וַיַּקְרִ֜יבוּ לִפְנֵ֤י יְהוָה֙ אֵ֣שׁ זָרָ֔ה אֲשֶׁ֧ר לֹ֦א צִוָּ֖ה אֹתָֽם׃"
          },
          {
            "num": "ב",
            "txt": "וַתֵּ֥צֵא אֵ֛שׁ מִלִּפְנֵ֥י יְהוָ֖ה וַתֹּ֣אכַל אוֹתָ֑ם וַיָּמֻ֖תוּ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶֽל־אַהֲרֹ֗ן הוּא֩ אֲשֶׁר־דִּבֶּ֨ר יְהוָ֤ה ׀ לֵאמֹר֙ בִּקְרֹבַ֣י אֶקָּדֵ֔שׁ וְעַל־פְּנֵ֥י כָל־הָעָ֖ם אֶכָּבֵ֑ד וַיִּדֹּ֖ם אַֽהֲרֹֽן׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּקְרָ֣א מֹשֶׁ֗ה אֶל־מִֽישָׁאֵל֙ וְאֶ֣ל אֶלְצָפָ֔ן בְּנֵ֥י עֻזִּיאֵ֖ל דֹּ֣ד אַֽהֲרֹ֑ן וַיֹּ֣אמֶר אֲלֵהֶ֗ם קִ֠רְב֞וּ שְׂא֤וּ אֶת־אֲחֵיכֶם֙ מֵאֵ֣ת פְּנֵֽי־הַקֹּ֔דֶשׁ אֶל־מִח֖וּץ לַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "ה",
            "txt": "וַֽיִּקְרְב֗וּ וַיִּשָּׂאֻם֙ בְּכֻתֳּנֹתָ֔ם אֶל־מִח֖וּץ לַֽמַּחֲנֶ֑ה כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר מֹשֶֽׁה׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֣ה אֶֽל־אַהֲרֹ֡ן וּלְאֶלְעָזָר֩ וּלְאִֽיתָמָ֨ר ׀ בָּנָ֜יו רָֽאשֵׁיכֶ֥ם אַל־תִּפְרָ֣עוּ ׀ וּבִגְדֵיכֶ֤ם לֹֽא־תִפְרֹ֨מוּ֙ וְלֹ֣א תָמֻ֔תוּ וְעַ֥ל כָּל־הָֽעֵדָ֖ה יִקְצֹ֑ף וַֽאֲחֵיכֶם֙ כָּל־בֵּ֣ית יִשְׂרָאֵ֔ל יִבְכּוּ֙ אֶת־הַשְּׂרֵפָ֔ה אֲשֶׁ֖ר שָׂרַ֥ף יְהוָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וּמִפֶּתַח֩ אֹ֨הֶל מוֹעֵ֜ד לֹ֤א תֵֽצְאוּ֙ פֶּן־תָּמֻ֔תוּ כִּי־שֶׁ֛מֶן מִשְׁחַ֥ת יְהוָ֖ה עֲלֵיכֶ֑ם וַֽיַּעֲשׂ֖וּ כִּדְבַ֥ר מֹשֶֽׁה׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "ט",
            "txt": "יַ֣יִן וְשֵׁכָ֞ר אַל־תֵּ֣שְׁתְּ ׀ אַתָּ֣ה ׀ וּבָנֶ֣יךָ אִתָּ֗ךְ בְּבֹֽאֲכֶ֛ם אֶל־אֹ֥הֶל מוֹעֵ֖ד וְלֹ֣א תָמֻ֑תוּ חֻקַּ֥ת עוֹלָ֖ם לְדֹרֹֽתֵיכֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וּֽלְהַבְדִּ֔יל בֵּ֥ין הַקֹּ֖דֶשׁ וּבֵ֣ין הַחֹ֑ל וּבֵ֥ין הַטָּמֵ֖א וּבֵ֥ין הַטָּהֽוֹר׃"
          },
          {
            "num": "יא",
            "txt": "וּלְהוֹרֹ֖ת אֶת־בְּנֵ֣י יִשְׂרָאֵ֑ל אֵ֚ת כָּל־הַ֣חֻקִּ֔ים אֲשֶׁ֨ר דִּבֶּ֧ר יְהוָ֛ה אֲלֵיהֶ֖ם בְּיַד־מֹשֶֽׁה׃ {פ}"
          },
          {
            "num": "יב",
            "txt": "וַיְדַבֵּ֨ר מֹשֶׁ֜ה אֶֽל־אַהֲרֹ֗ן וְאֶ֣ל אֶ֠לְעָזָר וְאֶל־אִ֨יתָמָ֥ר ׀ בָּנָיו֮ הַנּֽוֹתָרִים֒ קְח֣וּ אֶת־הַמִּנְחָ֗ה הַנּוֹתֶ֨רֶת֙ מֵֽאִשֵּׁ֣י יְהוָ֔ה וְאִכְל֥וּהָ מַצּ֖וֹת אֵ֣צֶל הַמִּזְבֵּ֑חַ כִּ֛י קֹ֥דֶשׁ קָֽדָשִׁ֖ים הִֽוא׃"
          },
          {
            "num": "יג",
            "txt": "וַֽאֲכַלְתֶּ֤ם אֹתָהּ֙ בְּמָק֣וֹם קָד֔וֹשׁ כִּ֣י חָקְךָ֤ וְחָק־בָּנֶ֨יךָ֙ הִ֔וא מֵֽאִשֵּׁ֖י יְהוָ֑ה כִּי־כֵ֖ן צֻוֵּֽיתִי׃"
          },
          {
            "num": "יד",
            "txt": "וְאֵת֩ חֲזֵ֨ה הַתְּנוּפָ֜ה וְאֵ֣ת ׀ שׁ֣וֹק הַתְּרוּמָ֗ה תֹּֽאכְלוּ֙ בְּמָק֣וֹם טָה֔וֹר אַתָּ֕ה וּבָנֶ֥יךָ וּבְנֹתֶ֖יךָ אִתָּ֑ךְ כִּֽי־חָקְךָ֤ וְחָק־בָּנֶ֨יךָ֙ נִתְּנ֔וּ מִזִּבְחֵ֥י שַׁלְמֵ֖י בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "טו",
            "txt": "שׁ֣וֹק הַתְּרוּמָ֞ה וַֽחֲזֵ֣ה הַתְּנוּפָ֗ה עַ֣ל אִשֵּׁ֤י הַֽחֲלָבִים֙ יָבִ֔יאוּ לְהָנִ֥יף תְּנוּפָ֖ה לִפְנֵ֣י יְהוָ֑ה וְהָיָ֨ה לְךָ֜ וּלְבָנֶ֤יךָ אִתְּךָ֙ לְחָק־עוֹלָ֔ם כַּֽאֲשֶׁ֖ר צִוָּ֥ה יְהוָֽה׃"
          },
          {
            "num": "טז",
            "txt": "וְאֵ֣ת ׀ שְׂעִ֣יר הַֽחַטָּ֗את דָּרֹ֥שׁ דָּרַ֛שׁ מֹשֶׁ֖ה וְהִנֵּ֣ה שֹׂרָ֑ף וַ֠יִּקְצֹף עַל־אֶלְעָזָ֤ר וְעַל־אִֽיתָמָר֙ בְּנֵ֣י אַֽהֲרֹ֔ן הַנּֽוֹתָרִ֖ם לֵאמֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "מַדּ֗וּעַ לֹֽא־אֲכַלְתֶּ֤ם אֶת־הַֽחַטָּאת֙ בִּמְק֣וֹם הַקֹּ֔דֶשׁ כִּ֛י קֹ֥דֶשׁ קָֽדָשִׁ֖ים הִ֑וא וְאֹתָ֣הּ ׀ נָתַ֣ן לָכֶ֗ם לָשֵׂאת֙ אֶת־עֲוֺ֣ן הָֽעֵדָ֔ה לְכַפֵּ֥ר עֲלֵיהֶ֖ם לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "יח",
            "txt": "הֵ֚ן לֹֽא־הוּבָ֣א אֶת־דָּמָ֔הּ אֶל־הַקֹּ֖דֶשׁ פְּנִ֑ימָה אָכ֨וֹל תֹּֽאכְל֥וּ אֹתָ֛הּ בַּקֹּ֖דֶשׁ כַּֽאֲשֶׁ֥ר צִוֵּֽיתִי׃"
          },
          {
            "num": "יט",
            "txt": "וַיְדַבֵּ֨ר אַֽהֲרֹ֜ן אֶל־מֹשֶׁ֗ה הֵ֣ן הַ֠יּוֹם הִקְרִ֨יבוּ אֶת־חַטָּאתָ֤ם וְאֶת־עֹֽלָתָם֙ לִפְנֵ֣י יְהוָ֔ה וַתִּקְרֶ֥אנָה אֹתִ֖י כָּאֵ֑לֶּה וְאָכַ֤לְתִּי חַטָּאת֙ הַיּ֔וֹם הַיִּיטַ֖ב בְּעֵינֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּשְׁמַ֣ע מֹשֶׁ֔ה וַיִּיטַ֖ב בְּעֵינָֽיו׃ {פ}"
          }
        ]
      },
      {
        "num": "יא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֧ר יְהוָ֛ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹ֥ר אֲלֵהֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "דַּבְּר֛וּ אֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר זֹ֤את הַֽחַיָּה֙ אֲשֶׁ֣ר תֹּֽאכְל֔וּ מִכָּל־הַבְּהֵמָ֖ה אֲשֶׁ֥ר עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "ג",
            "txt": "כֹּ֣ל ׀ מַפְרֶ֣סֶת פַּרְסָ֗ה וְשֹׁסַ֤עַת שֶׁ֨סַע֙ פְּרָסֹ֔ת מַֽעֲלַ֥ת גֵּרָ֖ה בַּבְּהֵמָ֑ה אֹתָ֖הּ תֹּאכֵֽלוּ׃"
          },
          {
            "num": "ד",
            "txt": "אַ֤ךְ אֶת־זֶה֙ לֹ֣א תֹֽאכְל֔וּ מִֽמַּעֲלֵי֙ הַגֵּרָ֔ה וּמִמַּפְרִסֵ֖י הַפַּרְסָ֑ה אֶֽת־הַ֠גָּמָל כִּֽי־מַעֲלֵ֨ה גֵרָ֜ה ה֗וּא וּפַרְסָה֙ אֵינֶ֣נּוּ מַפְרִ֔יס טָמֵ֥א ה֖וּא לָכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "וְאֶת־הַשָּׁפָ֗ן כִּֽי־מַעֲלֵ֤ה גֵרָה֙ ה֔וּא וּפַרְסָ֖ה לֹ֣א יַפְרִ֑יס טָמֵ֥א ה֖וּא לָכֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "וְאֶת־הָֽאַרְנֶ֗בֶת כִּֽי־מַעֲלַ֤ת גֵּרָה֙ הִ֔וא וּפַרְסָ֖ה לֹ֣א הִפְרִ֑יסָה טְמֵאָ֥ה הִ֖וא לָכֶֽם׃"
          },
          {
            "num": "ז",
            "txt": "וְאֶת־הַֽ֠חֲזִיר כִּֽי־מַפְרִ֨יס פַּרְסָ֜ה ה֗וּא וְשֹׁסַ֥ע שֶׁ֨סַע֙ פַּרְסָ֔ה וְה֖וּא גֵּרָ֣ה לֹֽא־יִגָּ֑ר טָמֵ֥א ה֖וּא לָכֶֽם׃"
          },
          {
            "num": "ח",
            "txt": "מִבְּשָׂרָם֙ לֹ֣א תֹאכֵ֔לוּ וּבְנִבְלָתָ֖ם לֹ֣א תִגָּ֑עוּ טְמֵאִ֥ים הֵ֖ם לָכֶֽם׃"
          },
          {
            "num": "ט",
            "txt": "אֶת־זֶה֙ תֹּֽאכְל֔וּ מִכֹּ֖ל אֲשֶׁ֣ר בַּמָּ֑יִם כֹּ֣ל אֲשֶׁר־לוֹ֩ סְנַפִּ֨יר וְקַשְׂקֶ֜שֶׂת בַּמַּ֗יִם בַּיַּמִּ֛ים וּבַנְּחָלִ֖ים אֹתָ֥ם תֹּאכֵֽלוּ׃"
          },
          {
            "num": "י",
            "txt": "וְכֹל֩ אֲשֶׁ֨ר אֵֽין־ל֜וֹ סְנַפִּ֣יר וְקַשְׂקֶ֗שֶׂת בַּיַּמִּים֙ וּבַנְּחָלִ֔ים מִכֹּל֙ שֶׁ֣רֶץ הַמַּ֔יִם וּמִכֹּ֛ל נֶ֥פֶשׁ הַֽחַיָּ֖ה אֲשֶׁ֣ר בַּמָּ֑יִם שֶׁ֥קֶץ הֵ֖ם לָכֶֽם׃"
          },
          {
            "num": "יא",
            "txt": "וְשֶׁ֖קֶץ יִֽהְי֣וּ לָכֶ֑ם מִבְּשָׂרָם֙ לֹ֣א תֹאכֵ֔לוּ וְאֶת־נִבְלָתָ֖ם תְּשַׁקֵּֽצוּ׃"
          },
          {
            "num": "יב",
            "txt": "כֹּ֣ל אֲשֶׁ֥ר אֵֽין־ל֛וֹ סְנַפִּ֥יר וְקַשְׂקֶ֖שֶׂת בַּמָּ֑יִם שֶׁ֥קֶץ ה֖וּא לָכֶֽם׃"
          },
          {
            "num": "יג",
            "txt": "וְאֶת־אֵ֨לֶּה֙ תְּשַׁקְּצ֣וּ מִן־הָע֔וֹף לֹ֥א יֵאָֽכְל֖וּ שֶׁ֣קֶץ הֵ֑ם אֶת־הַנֶּ֨שֶׁר֙ וְאֶת־הַפֶּ֔רֶס וְאֵ֖ת הָֽעָזְנִיָּֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְאֶ֨ת־הַדָּאָ֔ה וְאֶת־הָֽאַיָּ֖ה לְמִינָֽהּ׃"
          },
          {
            "num": "טו",
            "txt": "אֵ֥ת כָּל־עֹרֵ֖ב לְמִינֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "וְאֵת֙ בַּ֣ת הַֽיַּעֲנָ֔ה וְאֶת־הַתַּחְמָ֖ס וְאֶת־הַשָּׁ֑חַף וְאֶת־הַנֵּ֖ץ לְמִינֵֽהוּ׃"
          },
          {
            "num": "יז",
            "txt": "וְאֶת־הַכּ֥וֹס וְאֶת־הַשָּׁלָ֖ךְ וְאֶת־הַיַּנְשֽׁוּף׃"
          },
          {
            "num": "יח",
            "txt": "וְאֶת־הַתִּנְשֶׁ֥מֶת וְאֶת־הַקָּאָ֖ת וְאֶת־הָֽרָחָֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְאֵת֙ הַֽחֲסִידָ֔ה הָֽאֲנָפָ֖ה לְמִינָ֑הּ וְאֶת־הַדּֽוּכִיפַ֖ת וְאֶת־הָֽעֲטַלֵּֽף׃"
          },
          {
            "num": "כ",
            "txt": "כֹּ֚ל שֶׁ֣רֶץ הָע֔וֹף הַֽהֹלֵ֖ךְ עַל־אַרְבַּ֑ע שֶׁ֥קֶץ ה֖וּא לָכֶֽם׃"
          },
          {
            "num": "כא",
            "txt": "אַ֤ךְ אֶת־זֶה֙ תֹּֽאכְל֔וּ מִכֹּל֙ שֶׁ֣רֶץ הָע֔וֹף הַֽהֹלֵ֖ךְ עַל־אַרְבַּ֑ע אֲשֶׁר־לא (ל֤וֹ) כְרָעַ֨יִם֙ מִמַּ֣עַל לְרַגְלָ֔יו לְנַתֵּ֥ר בָּהֵ֖ן עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "כב",
            "txt": "אֶת־אֵ֤לֶּה מֵהֶם֙ תֹּאכֵ֔לוּ אֶת־הָֽאַרְבֶּ֣ה לְמִינ֔וֹ וְאֶת־הַסָּלְעָ֖ם לְמִינֵ֑הוּ וְאֶת־הַֽחַרְגֹּ֣ל לְמִינֵ֔הוּ וְאֶת־הֶֽחָגָ֖ב לְמִינֵֽהוּ׃"
          },
          {
            "num": "כג",
            "txt": "וְכֹל֙ שֶׁ֣רֶץ הָע֔וֹף אֲשֶׁר־ל֖וֹ אַרְבַּ֣ע רַגְלָ֑יִם שֶׁ֥קֶץ ה֖וּא לָכֶֽם׃"
          },
          {
            "num": "כד",
            "txt": "וּלְאֵ֖לֶּה תִּטַּמָּ֑אוּ כָּל־הַנֹּגֵ֥עַ בְּנִבְלָתָ֖ם יִטְמָ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כה",
            "txt": "וְכָל־הַנֹּשֵׂ֖א מִנִּבְלָתָ֑ם יְכַבֵּ֥ס בְּגָדָ֖יו וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כו",
            "txt": "לְֽכָל־הַבְּהֵמָ֡ה אֲשֶׁ֣ר הִוא֩ מַפְרֶ֨סֶת פַּרְסָ֜ה וְשֶׁ֣סַע ׀ אֵינֶ֣נָּה שֹׁסַ֗עַת וְגֵרָה֙ אֵינֶ֣נָּה מַֽעֲלָ֔ה טְמֵאִ֥ים הֵ֖ם לָכֶ֑ם כָּל־הַנֹּגֵ֥עַ בָּהֶ֖ם יִטְמָֽא׃"
          },
          {
            "num": "כז",
            "txt": "וְכֹ֣ל ׀ הוֹלֵ֣ךְ עַל־כַּפָּ֗יו בְּכָל־הַֽחַיָּה֙ הַֽהֹלֶ֣כֶת עַל־אַרְבַּ֔ע טְמֵאִ֥ים הֵ֖ם לָכֶ֑ם כָּל־הַנֹּגֵ֥עַ בְּנִבְלָתָ֖ם יִטְמָ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כח",
            "txt": "וְהַנֹּשֵׂא֙ אֶת־נִבְלָתָ֔ם יְכַבֵּ֥ס בְּגָדָ֖יו וְטָמֵ֣א עַד־הָעָ֑רֶב טְמֵאִ֥ים הֵ֖מָּה לָכֶֽם׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "וְזֶ֤ה לָכֶם֙ הַטָּמֵ֔א בַּשֶּׁ֖רֶץ הַשֹּׁרֵ֣ץ עַל־הָאָ֑רֶץ הַחֹ֥לֶד וְהָֽעַכְבָּ֖ר וְהַצָּ֥ב לְמִינֵֽהוּ׃"
          },
          {
            "num": "ל",
            "txt": "וְהָֽאֲנָקָ֥ה וְהַכֹּ֖חַ וְהַלְּטָאָ֑ה וְהַחֹ֖מֶט וְהַתִּנְשָֽׁמֶת׃"
          },
          {
            "num": "לא",
            "txt": "אֵ֛לֶּה הַטְּמֵאִ֥ים לָכֶ֖ם בְּכָל־הַשָּׁ֑רֶץ כָּל־הַנֹּגֵ֧עַ בָּהֶ֛ם בְּמֹתָ֖ם יִטְמָ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "לב",
            "txt": "וְכֹ֣ל אֲשֶׁר־יִפֹּל־עָלָיו֩ מֵהֶ֨ם ׀ בְּמֹתָ֜ם יִטְמָ֗א מִכָּל־כְּלִי־עֵץ֙ א֣וֹ בֶ֤גֶד אוֹ־עוֹר֙ א֣וֹ שָׂ֔ק כָּל־כְּלִ֕י אֲשֶׁר־יֵֽעָשֶׂ֥ה מְלָאכָ֖ה בָּהֶ֑ם בַּמַּ֧יִם יוּבָ֛א וְטָמֵ֥א עַד־הָעֶ֖רֶב וְטָהֵֽר׃"
          },
          {
            "num": "לג",
            "txt": "וְכָ֨ל־כְּלִי־חֶ֔רֶשׂ אֲשֶׁר־יִפֹּ֥ל מֵהֶ֖ם אֶל־תּוֹכ֑וֹ כֹּ֣ל אֲשֶׁ֧ר בְּתוֹכ֛וֹ יִטְמָ֖א וְאֹת֥וֹ תִשְׁבֹּֽרוּ׃"
          },
          {
            "num": "לד",
            "txt": "מִכָּל־הָאֹ֜כֶל אֲשֶׁ֣ר יֵֽאָכֵ֗ל אֲשֶׁ֨ר יָב֥וֹא עָלָ֛יו מַ֖יִם יִטְמָ֑א וְכָל־מַשְׁקֶה֙ אֲשֶׁ֣ר יִשָּׁתֶ֔ה בְּכָל־כְּלִ֖י יִטְמָֽא׃"
          },
          {
            "num": "לה",
            "txt": "וְ֠כֹל אֲשֶׁר־יִפֹּ֨ל מִנִּבְלָתָ֥ם ׀ עָלָיו֮ יִטְמָא֒ תַּנּ֧וּר וְכִירַ֛יִם יֻתָּ֖ץ טְמֵאִ֣ים הֵ֑ם וּטְמֵאִ֖ים יִֽהְי֥וּ לָכֶֽם׃"
          },
          {
            "num": "לו",
            "txt": "אַ֣ךְ מַעְיָ֥ן וּב֛וֹר מִקְוֵה־מַ֖יִם יִֽהְיֶ֣ה טָה֑וֹר וְנֹגֵ֥עַ בְּנִבְלָתָ֖ם יִטְמָֽא׃"
          },
          {
            "num": "לז",
            "txt": "וְכִ֤י יִפֹּל֙ מִנִּבְלָתָ֔ם עַל־כָּל־זֶ֥רַע זֵר֖וּעַ אֲשֶׁ֣ר יִזָּרֵ֑עַ טָה֖וֹר הֽוּא׃"
          },
          {
            "num": "לח",
            "txt": "וְכִ֤י יֻתַּן־מַ֨יִם֙ עַל־זֶ֔רַע וְנָפַ֥ל מִנִּבְלָתָ֖ם עָלָ֑יו טָמֵ֥א ה֖וּא לָכֶֽם׃ {ס}"
          },
          {
            "num": "לט",
            "txt": "וְכִ֤י יָמוּת֙ מִן־הַבְּהֵמָ֔ה אֲשֶׁר־הִ֥יא לָכֶ֖ם לְאָכְלָ֑ה הַנֹּגֵ֥עַ בְּנִבְלָתָ֖הּ יִטְמָ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "מ",
            "txt": "וְהָֽאֹכֵל֙ מִנִּבְלָתָ֔הּ יְכַבֵּ֥ס בְּגָדָ֖יו וְטָמֵ֣א עַד־הָעָ֑רֶב וְהַנֹּשֵׂא֙ אֶת־נִבְלָתָ֔הּ יְכַבֵּ֥ס בְּגָדָ֖יו וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "מא",
            "txt": "וְכָל־הַשֶּׁ֖רֶץ הַשֹּׁרֵ֣ץ עַל־הָאָ֑רֶץ שֶׁ֥קֶץ ה֖וּא לֹ֥א יֵֽאָכֵֽל׃"
          },
          {
            "num": "מב",
            "txt": "כֹּל֩ הוֹלֵ֨ךְ עַל־גָּח֜"
          },
          {
            "num": "מג",
            "txt": "ן וְכֹ֣ל ׀ הוֹלֵ֣ךְ עַל־אַרְבַּ֗ע עַ֚ד כָּל־מַרְבֵּ֣ה רַגְלַ֔יִם לְכָל־הַשֶּׁ֖רֶץ הַשֹּׁרֵ֣ץ עַל־הָאָ֑רֶץ לֹ֥א תֹֽאכְל֖וּם כִּי־שֶׁ֥קֶץ הֵֽם׃"
          },
          {
            "num": "מד",
            "txt": "אַל־תְּשַׁקְּצוּ֙ אֶת־נַפְשֹׁ֣תֵיכֶ֔ם בְּכָל־הַשֶּׁ֖רֶץ הַשֹּׁרֵ֑ץ וְלֹ֤א תִֽטַּמְּאוּ֙ בָּהֶ֔ם וְנִטְמֵתֶ֖ם בָּֽם׃"
          },
          {
            "num": "מה",
            "txt": "כִּ֣י אֲנִ֣י יְהוָה֮ אֱלֹֽהֵיכֶם֒ וְהִתְקַדִּשְׁתֶּם֙ וִֽהְיִיתֶ֣ם קְדֹשִׁ֔ים כִּ֥י קָד֖וֹשׁ אָ֑נִי וְלֹ֤א תְטַמְּאוּ֙ אֶת־נַפְשֹׁ֣תֵיכֶ֔ם בְּכָל־הַשֶּׁ֖רֶץ הָֽרֹמֵ֥שׂ עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "מו",
            "txt": "כִּ֣י ׀ אֲנִ֣י יְהוָ֗ה הַֽמַּעֲלֶ֤ה אֶתְכֶם֙ מֵאֶ֣רֶץ מִצְרַ֔יִם לִֽהְיֹ֥ת לָכֶ֖ם לֵֽאלֹהִ֑ים וִֽהְיִיתֶ֣ם קְדֹשִׁ֔ים כִּ֥י קָד֖וֹשׁ אָֽנִי׃"
          },
          {
            "num": "מז",
            "txt": "זֹ֣את תּוֹרַ֤ת הַבְּהֵמָה֙ וְהָע֔וֹף וְכֹל֙ נֶ֣פֶשׁ הַֽחַיָּ֔ה הָֽרֹמֶ֖שֶׂת בַּמָּ֑יִם וּלְכָל־נֶ֖פֶשׁ הַשֹּׁרֶ֥צֶת עַל־הָאָֽרֶץ׃"
          },
          {
            "txt": "לְהַבְדִּ֕יל בֵּ֥ין הַטָּמֵ֖א וּבֵ֣ין הַטָּהֹ֑ר וּבֵ֤ין הַֽחַיָּה֙ הַֽנֶּאֱכֶ֔לֶת וּבֵין֙ הַֽחַיָּ֔ה אֲשֶׁ֖ר לֹ֥א תֵֽאָכֵֽל׃ {פ}"
          }
        ]
      },
      {
        "num": "יב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֞ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ לֵאמֹ֔ר אִשָּׁה֙ כִּ֣י תַזְרִ֔יעַ וְיָֽלְדָ֖ה זָכָ֑ר וְטָֽמְאָה֙ שִׁבְעַ֣ת יָמִ֔ים כִּימֵ֛י נִדַּ֥ת דְּוֺתָ֖הּ תִּטְמָֽא׃"
          },
          {
            "num": "ג",
            "txt": "וּבַיּ֖וֹם הַשְּׁמִינִ֑י יִמּ֖וֹל בְּשַׂ֥ר עָרְלָתֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "וּשְׁלֹשִׁ֥ים יוֹם֙ וּשְׁלֹ֣שֶׁת יָמִ֔ים תֵּשֵׁ֖ב בִּדְמֵ֣י טָֽהֳרָ֑ה בְּכָל־קֹ֣דֶשׁ לֹֽא־תִגָּ֗ע וְאֶל־הַמִּקְדָּשׁ֙ לֹ֣א תָבֹ֔א עַד־מְלֹ֖את יְמֵ֥י טָֽהֳרָֽהּ׃"
          },
          {
            "num": "ה",
            "txt": "וְאִם־נְקֵבָ֣ה תֵלֵ֔ד וְטָֽמְאָ֥ה שְׁבֻעַ֖יִם כְּנִדָּתָ֑הּ וְשִׁשִּׁ֥ים יוֹם֙ וְשֵׁ֣שֶׁת יָמִ֔ים תֵּשֵׁ֖ב עַל־דְּמֵ֥י טָֽהֳרָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וּבִמְלֹ֣את ׀ יְמֵ֣י טָֽהֳרָ֗הּ לְבֵן֮ א֣וֹ לְבַת֒ תָּבִ֞יא כֶּ֤בֶשׂ בֶּן־שְׁנָתוֹ֙ לְעֹלָ֔ה וּבֶן־יוֹנָ֥ה אוֹ־תֹ֖ר לְחַטָּ֑את אֶל־פֶּ֥תַח אֹֽהֶל־מוֹעֵ֖ד אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "ז",
            "txt": "וְהִקְרִיב֞וֹ לִפְנֵ֤י יְהוָה֙ וְכִפֶּ֣ר עָלֶ֔יהָ וְטָֽהֲרָ֖ה מִמְּקֹ֣ר דָּמֶ֑יהָ זֹ֤את תּוֹרַת֙ הַיֹּלֶ֔דֶת לַזָּכָ֖ר א֥וֹ לַנְּקֵבָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְאִם־לֹ֨א תִמְצָ֣א יָדָהּ֮ דֵּ֣י שֶׂה֒ וְלָֽקְחָ֣ה שְׁתֵּֽי־תֹרִ֗ים א֤וֹ שְׁנֵי֙ בְּנֵ֣י יוֹנָ֔ה אֶחָ֥ד לְעֹלָ֖ה וְאֶחָ֣ד לְחַטָּ֑את וְכִפֶּ֥ר עָלֶ֛יהָ הַכֹּהֵ֖ן וְטָהֵֽרָה׃ {פ}"
          }
        ]
      },
      {
        "num": "יג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "אָדָ֗ם כִּֽי־יִהְיֶ֤ה בְעוֹר־בְּשָׂרוֹ֙ שְׂאֵ֤ת אֽוֹ־סַפַּ֨חַת֙ א֣וֹ בַהֶ֔רֶת וְהָיָ֥ה בְעוֹר־בְּשָׂר֖וֹ לְנֶ֣גַע צָרָ֑עַת וְהוּבָא֙ אֶל־אַֽהֲרֹ֣ן הַכֹּהֵ֔ן א֛וֹ אֶל־אַחַ֥ד מִבָּנָ֖יו הַכֹּֽהֲנִֽים׃"
          },
          {
            "num": "ג",
            "txt": "וְרָאָ֣ה הַכֹּהֵ֣ן אֶת־הַנֶּ֣גַע בְּעֽוֹר־הַ֠בָּשָׂר וְשֵׂעָ֨ר בַּנֶּ֜גַע הָפַ֣ךְ ׀ לָבָ֗ן וּמַרְאֵ֤ה הַנֶּ֨גַע֙ עָמֹק֙ מֵע֣וֹר בְּשָׂר֔וֹ נֶ֥גַע צָרַ֖עַת ה֑וּא וְרָאָ֥הוּ הַכֹּהֵ֖ן וְטִמֵּ֥א אֹתֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "וְאִם־בַּהֶרֶת֩ לְבָנָ֨ה הִ֜וא בְּע֣וֹר בְּשָׂר֗וֹ וְעָמֹק֙ אֵין־מַרְאֶ֣הָ מִן־הָע֔וֹר וּשְׂעָרָ֖ה לֹֽא־הָפַ֣ךְ לָבָ֑ן וְהִסְגִּ֧יר הַכֹּהֵ֛ן אֶת־הַנֶּ֖גַע שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "ה",
            "txt": "וְרָאָ֣הוּ הַכֹּהֵן֮ בַּיּ֣וֹם הַשְּׁבִיעִי֒ וְהִנֵּ֤ה הַנֶּ֨גַע֙ עָמַ֣ד בְּעֵינָ֔יו לֹֽא־פָשָׂ֥ה הַנֶּ֖גַע בָּע֑וֹר וְהִסְגִּיר֧וֹ הַכֹּהֵ֛ן שִׁבְעַ֥ת יָמִ֖ים שֵׁנִֽית׃"
          },
          {
            "num": "ו",
            "txt": "וְרָאָה֩ הַכֹּהֵ֨ן אֹת֜וֹ בַּיּ֣וֹם הַשְּׁבִיעִי֮ שֵׁנִית֒ וְהִנֵּה֙ כֵּהָ֣ה הַנֶּ֔גַע וְלֹֽא־פָשָׂ֥ה הַנֶּ֖גַע בָּע֑וֹר וְטִֽהֲר֤וֹ הַכֹּהֵן֙ מִסְפַּ֣חַת הִ֔וא וְכִבֶּ֥ס בְּגָדָ֖יו וְטָהֵֽר׃"
          },
          {
            "num": "ז",
            "txt": "וְאִם־פָּשֹׂ֨ה תִפְשֶׂ֤ה הַמִּסְפַּ֨חַת֙ בָּע֔וֹר אַֽחֲרֵ֧י הֵרָֽאֹת֛וֹ אֶל־הַכֹּהֵ֖ן לְטָֽהֳרָת֑וֹ וְנִרְאָ֥ה שֵׁנִ֖ית אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "ח",
            "txt": "וְרָאָה֙ הַכֹּהֵ֔ן וְהִנֵּ֛ה פָּֽשְׂתָ֥ה הַמִּסְפַּ֖חַת בָּע֑וֹר וְטִמְּא֥וֹ הַכֹּהֵ֖ן צָרַ֥עַת הִֽוא׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "נֶ֣גַע צָרַ֔עַת כִּ֥י תִֽהְיֶ֖ה בְּאָדָ֑ם וְהוּבָ֖א אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "י",
            "txt": "וְרָאָ֣ה הַכֹּהֵ֗ן וְהִנֵּ֤ה שְׂאֵת־לְבָנָה֙ בָּע֔וֹר וְהִ֕יא הָֽפְכָ֖ה שֵׂעָ֣ר לָבָ֑ן וּמִֽחְיַ֛ת בָּשָׂ֥ר חַ֖י בַּשְׂאֵֽת׃"
          },
          {
            "num": "יא",
            "txt": "צָרַ֨עַת נוֹשֶׁ֤נֶת הִוא֙ בְּע֣וֹר בְּשָׂר֔וֹ וְטִמְּא֖וֹ הַכֹּהֵ֑ן לֹ֣א יַסְגִּרֶ֔נּוּ כִּ֥י טָמֵ֖א הֽוּא׃"
          },
          {
            "num": "יב",
            "txt": "וְאִם־פָּר֨וֹחַ תִּפְרַ֤ח הַצָּרַ֨עַת֙ בָּע֔וֹר וְכִסְּתָ֣ה הַצָּרַ֗עַת אֵ֚ת כָּל־ע֣וֹר הַנֶּ֔גַע מֵֽרֹאשׁ֖וֹ וְעַד־רַגְלָ֑יו לְכָל־מַרְאֵ֖ה עֵינֵ֥י הַכֹּהֵֽן׃"
          },
          {
            "num": "יג",
            "txt": "וְרָאָ֣ה הַכֹּהֵ֗ן וְהִנֵּ֨ה כִסְּתָ֤ה הַצָּרַ֨עַת֙ אֶת־כָּל־בְּשָׂר֔וֹ וְטִהַ֖ר אֶת־הַנָּ֑גַע כֻּלּ֛וֹ הָפַ֥ךְ לָבָ֖ן טָה֥וֹר הֽוּא׃"
          },
          {
            "num": "יד",
            "txt": "וּבְי֨וֹם הֵֽרָא֥וֹת בּ֛וֹ בָּשָׂ֥ר חַ֖י יִטְמָֽא׃"
          },
          {
            "num": "טו",
            "txt": "וְרָאָ֧ה הַכֹּהֵ֛ן אֶת־הַבָּשָׂ֥ר הַחַ֖י וְטִמְּא֑וֹ הַבָּשָׂ֥ר הַחַ֛י טָמֵ֥א ה֖וּא צָרַ֥עַת הֽוּא׃"
          },
          {
            "num": "טז",
            "txt": "א֣וֹ כִ֥י יָשׁ֛וּב הַבָּשָׂ֥ר הַחַ֖י וְנֶהְפַּ֣ךְ לְלָבָ֑ן וּבָ֖א אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "יז",
            "txt": "וְרָאָ֨הוּ֙ הַכֹּהֵ֔ן וְהִנֵּ֛ה נֶהְפַּ֥ךְ הַנֶּ֖גַע לְלָבָ֑ן וְטִהַ֧ר הַכֹּהֵ֛ן אֶת־הַנֶּ֖גַע טָה֥וֹר הֽוּא׃ {פ}"
          },
          {
            "num": "יח",
            "txt": "וּבָשָׂ֕ר כִּֽי־יִהְיֶ֥ה בֽוֹ־בְעֹר֖וֹ שְׁחִ֑ין וְנִרְפָּֽא׃"
          },
          {
            "num": "יט",
            "txt": "וְהָיָ֞ה בִּמְק֤וֹם הַשְּׁחִין֙ שְׂאֵ֣ת לְבָנָ֔ה א֥וֹ בַהֶ֖רֶת לְבָנָ֣ה אֲדַמְדָּ֑מֶת וְנִרְאָ֖ה אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "כ",
            "txt": "וְרָאָ֣ה הַכֹּהֵ֗ן וְהִנֵּ֤ה מַרְאֶ֨הָ֙ שָׁפָ֣ל מִן־הָע֔וֹר וּשְׂעָרָ֖הּ הָפַ֣ךְ לָבָ֑ן וְטִמְּא֧וֹ הַכֹּהֵ֛ן נֶֽגַע־צָרַ֥עַת הִ֖וא בַּשְּׁחִ֥ין פָּרָֽחָה׃"
          },
          {
            "num": "כא",
            "txt": "וְאִ֣ם ׀ יִרְאֶ֣נָּה הַכֹּהֵ֗ן וְהִנֵּ֤ה אֵֽין־בָּהּ֙ שֵׂעָ֣ר לָבָ֔ן וּשְׁפָלָ֥ה אֵינֶ֛נָּה מִן־הָע֖וֹר וְהִ֣יא כֵהָ֑ה וְהִסְגִּיר֥וֹ הַכֹּהֵ֖ן שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "כב",
            "txt": "וְאִם־פָּשֹׂ֥ה תִפְשֶׂ֖ה בָּע֑וֹר וְטִמֵּ֧א הַכֹּהֵ֛ן אֹת֖וֹ נֶ֥גַע הִֽוא׃"
          },
          {
            "num": "כג",
            "txt": "וְאִם־תַּחְתֶּ֜יהָ תַּֽעֲמֹ֤ד הַבַּהֶ֨רֶת֙ לֹ֣א פָשָׂ֔תָה צָרֶ֥בֶת הַשְּׁחִ֖ין הִ֑וא וְטִֽהֲר֖וֹ הַכֹּהֵֽן׃ {ס}"
          },
          {
            "num": "כד",
            "txt": "א֣וֹ בָשָׂ֔ר כִּֽי־יִהְיֶ֥ה בְעֹר֖וֹ מִכְוַת־אֵ֑שׁ וְֽהָיְתָ֞ה מִֽחְיַ֣ת הַמִּכְוָ֗ה בַּהֶ֛רֶת לְבָנָ֥ה אֲדַמְדֶּ֖מֶת א֥וֹ לְבָנָֽה׃"
          },
          {
            "num": "כה",
            "txt": "וְרָאָ֣ה אֹתָ֣הּ הַכֹּהֵ֡ן וְהִנֵּ֣ה נֶהְפַּךְ֩ שֵׂעָ֨ר לָבָ֜ן בַּבַּהֶ֗רֶת וּמַרְאֶ֨הָ֙ עָמֹ֣ק מִן־הָע֔וֹר צָרַ֣עַת הִ֔וא בַּמִּכְוָ֖ה פָּרָ֑חָה וְטִמֵּ֤א אֹתוֹ֙ הַכֹּהֵ֔ן נֶ֥גַע צָרַ֖עַת הִֽוא׃"
          },
          {
            "num": "כו",
            "txt": "וְאִ֣ם ׀ יִרְאֶ֣נָּה הַכֹּהֵ֗ן וְהִנֵּ֤ה אֵֽין־בַּבַּהֶ֨רֶת֙ שֵׂעָ֣ר לָבָ֔ן וּשְׁפָלָ֥ה אֵינֶ֛נָּה מִן־הָע֖וֹר וְהִ֣וא כֵהָ֑ה וְהִסְגִּיר֥וֹ הַכֹּהֵ֖ן שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "כז",
            "txt": "וְרָאָ֥הוּ הַכֹּהֵ֖ן בַּיּ֣וֹם הַשְּׁבִיעִ֑י אִם־פָּשֹׂ֤ה תִפְשֶׂה֙ בָּע֔וֹר וְטִמֵּ֤א הַכֹּהֵן֙ אֹת֔וֹ נֶ֥גַע צָרַ֖עַת הִֽוא׃"
          },
          {
            "num": "כח",
            "txt": "וְאִם־תַּחְתֶּיהָ֩ תַֽעֲמֹ֨ד הַבַּהֶ֜רֶת לֹֽא־פָשְׂתָ֤ה בָעוֹר֙ וְהִ֣וא כֵהָ֔ה שְׂאֵ֥ת הַמִּכְוָ֖ה הִ֑וא וְטִֽהֲרוֹ֙ הַכֹּהֵ֔ן כִּֽי־צָרֶ֥בֶת הַמִּכְוָ֖ה הִֽוא׃ {פ}"
          },
          {
            "num": "כט",
            "txt": "וְאִישׁ֙ א֣וֹ אִשָּׁ֔ה כִּֽי־יִהְיֶ֥ה ב֖וֹ נָ֑גַע בְּרֹ֖אשׁ א֥וֹ בְזָקָֽן׃"
          },
          {
            "num": "ל",
            "txt": "וְרָאָ֨ה הַכֹּהֵ֜ן אֶת־הַנֶּ֗גַע וְהִנֵּ֤ה מַרְאֵ֨הוּ֙ עָמֹ֣ק מִן־הָע֔וֹר וּב֛וֹ שֵׂעָ֥ר צָהֹ֖ב דָּ֑ק וְטִמֵּ֨א אֹת֤וֹ הַכֹּהֵן֙ נֶ֣תֶק ה֔וּא צָרַ֧עַת הָרֹ֛אשׁ א֥וֹ הַזָּקָ֖ן הֽוּא׃"
          },
          {
            "num": "לא",
            "txt": "וְכִֽי־יִרְאֶ֨ה הַכֹּהֵ֜ן אֶת־נֶ֣גַע הַנֶּ֗תֶק וְהִנֵּ֤ה אֵין־מַרְאֵ֨הוּ֙ עָמֹ֣ק מִן־הָע֔וֹר וְשֵׂעָ֥ר שָׁחֹ֖ר אֵ֣ין בּ֑וֹ וְהִסְגִּ֧יר הַכֹּהֵ֛ן אֶת־נֶ֥גַע הַנֶּ֖תֶק שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "לב",
            "txt": "וְרָאָ֨ה הַכֹּהֵ֣ן אֶת־הַנֶּגַע֮ בַּיּ֣וֹם הַשְּׁבִיעִי֒ וְהִנֵּה֙ לֹֽא־פָשָׂ֣ה הַנֶּ֔תֶק וְלֹא־הָ֥יָה ב֖וֹ שֵׂעָ֣ר צָהֹ֑ב וּמַרְאֵ֣ה הַנֶּ֔תֶק אֵ֥ין עָמֹ֖ק מִן־הָעֽוֹר׃"
          },
          {
            "num": "לג",
            "txt": "וְהִ֨תְ"
          },
          {
            "num": "לד",
            "txt": "לָּ֔ח וְאֶת־הַנֶּ֖תֶק לֹ֣א יְגַלֵּ֑חַ וְהִסְגִּ֨יר הַכֹּהֵ֧ן אֶת־הַנֶּ֛תֶק שִׁבְעַ֥ת יָמִ֖ים שֵׁנִֽית׃"
          },
          {
            "num": "לה",
            "txt": "וְרָאָה֩ הַכֹּהֵ֨ן אֶת־הַנֶּ֜תֶק בַּיּ֣וֹם הַשְּׁבִיעִ֗י וְ֠הִנֵּה לֹֽא־פָשָׂ֤ה הַנֶּ֨תֶק֙ בָּע֔וֹר וּמַרְאֵ֕הוּ אֵינֶ֥נּוּ עָמֹ֖ק מִן־הָע֑וֹר וְטִהַ֤ר אֹתוֹ֙ הַכֹּהֵ֔ן וְכִבֶּ֥ס בְּגָדָ֖יו וְטָהֵֽר׃"
          },
          {
            "num": "לו",
            "txt": "וְאִם־פָּשֹׂ֥ה יִפְשֶׂ֛ה הַנֶּ֖תֶק בָּע֑וֹר אַֽחֲרֵ֖י טָֽהֳרָתֽוֹ׃"
          },
          {
            "num": "לז",
            "txt": "וְרָאָ֨הוּ֙ הַכֹּהֵ֔ן וְהִנֵּ֛ה פָּשָׂ֥ה הַנֶּ֖תֶק בָּע֑וֹר לֹֽא־יְבַקֵּ֧ר הַכֹּהֵ֛ן לַשֵּׂעָ֥ר הַצָּהֹ֖ב טָמֵ֥א הֽוּא׃"
          },
          {
            "num": "לח",
            "txt": "וְאִם־בְּעֵינָיו֩ עָמַ֨ד הַנֶּ֜תֶק וְשֵׂעָ֨ר שָׁחֹ֧ר צָֽמַח־בּ֛וֹ נִרְפָּ֥א הַנֶּ֖תֶק טָה֣וֹר ה֑וּא וְטִֽהֲר֖וֹ הַכֹּהֵֽן׃ {ס}"
          },
          {
            "num": "לט",
            "txt": "וְאִישׁ֙ אֽוֹ־אִשָּׁ֔ה כִּֽי־יִהְיֶ֥ה בְעוֹר־בְּשָׂרָ֖ם בֶּֽהָרֹ֑ת בֶּֽהָרֹ֖ת לְבָנֹֽת׃"
          },
          {
            "num": "מ",
            "txt": "וְרָאָ֣ה הַכֹּהֵ֗ן וְהִנֵּ֧ה בְעוֹר־בְּשָׂרָ֛ם בֶּֽהָרֹ֖ת כֵּה֣וֹת לְבָנֹ֑ת בֹּ֥הַק ה֛וּא פָּרַ֥ח בָּע֖וֹר טָה֥וֹר הֽוּא׃ {ס}"
          },
          {
            "num": "מא",
            "txt": "וְאִ֕ישׁ כִּ֥י יִמָּרֵ֖ט רֹאשׁ֑וֹ קֵרֵ֥חַ ה֖וּא טָה֥וֹר הֽוּא׃"
          },
          {
            "num": "מב",
            "txt": "וְאִם֙ מִפְּאַ֣ת פָּנָ֔יו יִמָּרֵ֖ט רֹאשׁ֑וֹ גִּבֵּ֥חַ ה֖וּא טָה֥וֹר הֽוּא׃"
          },
          {
            "num": "מג",
            "txt": "וְכִֽי־יִהְיֶ֤ה בַקָּרַ֨חַת֙ א֣וֹ בַגַּבַּ֔חַת נֶ֖גַע לָבָ֣ן אֲדַמְדָּ֑ם צָרַ֤עַת פֹּרַ֨חַת֙ הִ֔וא בְּקָֽרַחְתּ֖וֹ א֥וֹ בְגַבַּחְתּֽוֹ׃"
          },
          {
            "num": "מד",
            "txt": "וְרָאָ֨ה אֹת֜וֹ הַכֹּהֵ֗ן וְהִנֵּ֤ה שְׂאֵת־הַנֶּ֨גַע֙ לְבָנָ֣ה אֲדַמְדֶּ֔מֶת בְּקָֽרַחְתּ֖וֹ א֣וֹ בְגַבַּחְתּ֑וֹ כְּמַרְאֵ֥ה צָרַ֖עַת ע֥וֹר בָּשָֽׂר׃"
          },
          {
            "num": "מה",
            "txt": "אִישׁ־צָר֥וּעַ ה֖וּא טָמֵ֣א ה֑וּא טַמֵּ֧א יְטַמְּאֶ֛נּוּ הַכֹּהֵ֖ן בְּרֹאשׁ֥וֹ נִגְעֽוֹ׃"
          },
          {
            "num": "מו",
            "txt": "וְהַצָּר֜וּעַ אֲשֶׁר־בּ֣וֹ הַנֶּ֗גַע בְּגָדָ֞יו יִֽהְי֤וּ פְרֻמִים֙ וְרֹאשׁוֹ֙ יִֽהְיֶ֣ה פָר֔וּעַ וְעַל־שָׂפָ֖ם יַעְטֶ֑ה וְטָמֵ֥א ׀ טָמֵ֖א יִקְרָֽא׃"
          },
          {
            "num": "מז",
            "txt": "כָּל־יְמֵ֞י אֲשֶׁ֨ר הַנֶּ֥גַע בּ֛וֹ יִטְמָ֖א טָמֵ֣א ה֑וּא בָּדָ֣ד יֵשֵׁ֔ב מִח֥וּץ לַֽמַּחֲנֶ֖ה מֽוֹשָׁבֽוֹ׃ {ס}"
          },
          {
            "num": "מח",
            "txt": "וְהַבֶּ֕גֶד כִּֽי־יִהְיֶ֥ה ב֖וֹ נֶ֣גַע צָרָ֑עַת בְּבֶ֣גֶד צֶ֔מֶר א֖וֹ בְּבֶ֥גֶד פִּשְׁתִּֽים׃"
          },
          {
            "num": "מט",
            "txt": "א֤וֹ בִֽשְׁתִי֙ א֣וֹ בְעֵ֔רֶב לַפִּשְׁתִּ֖ים וְלַצָּ֑מֶר א֣וֹ בְע֔וֹר א֖וֹ בְּכָל־מְלֶ֥אכֶת עֽוֹר׃"
          },
          {
            "num": "נ",
            "txt": "וְהָיָ֨ה הַנֶּ֜גַע יְרַקְרַ֣ק ׀ א֣וֹ אֲדַמְדָּ֗ם בַּבֶּגֶד֩ א֨וֹ בָע֜וֹר אֽוֹ־בַשְּׁתִ֤י אֽוֹ־בָעֵ֨רֶב֙ א֣וֹ בְכָל־כְּלִי־ע֔וֹר נֶ֥גַע צָרַ֖עַת ה֑וּא וְהָרְאָ֖ה אֶת־הַכֹּהֵֽן׃"
          },
          {
            "num": "נא",
            "txt": "וְרָאָ֥ה הַכֹּהֵ֖ן אֶת־הַנָּ֑גַע וְהִסְגִּ֥יר אֶת־הַנֶּ֖גַע שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "נב",
            "txt": "וְרָאָ֨ה אֶת־הַנֶּ֜גַע בַּיּ֣וֹם הַשְּׁבִיעִ֗י כִּֽי־פָשָׂ֤ה הַנֶּ֨גַע֙ בַּ֠בֶּגֶד אֽוֹ־בַשְּׁתִ֤י אֽוֹ־בָעֵ֨רֶב֙ א֣וֹ בָע֔וֹר לְכֹ֛ל אֲשֶׁר־יֵֽעָשֶׂ֥ה הָע֖וֹר לִמְלָאכָ֑ה צָרַ֧עַת מַמְאֶ֛רֶת הַנֶּ֖גַע טָמֵ֥א הֽוּא׃"
          },
          {
            "num": "נג",
            "txt": "וְשָׂרַ֨ף אֶת־הַבֶּ֜גֶד א֥וֹ אֶֽת־הַשְּׁתִ֣י ׀ א֣וֹ אֶת־הָעֵ֗רֶב בַּצֶּ֨מֶר֙ א֣וֹ בַפִּשְׁתִּ֔ים א֚וֹ אֶת־כָּל־כְּלִ֣י הָע֔וֹר אֲשֶׁר־יִֽהְיֶ֥ה ב֖וֹ הַנָּ֑גַע כִּֽי־צָרַ֤עַת מַמְאֶ֨רֶת֙ הִ֔וא בָּאֵ֖שׁ תִּשָּׂרֵֽף׃"
          },
          {
            "num": "נד",
            "txt": "וְאִם֮ יִרְאֶ֣ה הַכֹּהֵן֒ וְהִנֵּה֙ לֹֽא־פָשָׂ֣ה הַנֶּ֔גַע בַּבֶּ֕גֶד א֥וֹ בַשְּׁתִ֖י א֣וֹ בָעֵ֑רֶב א֖וֹ בְּכָל־כְּלִי־עֽוֹר׃"
          },
          {
            "num": "נה",
            "txt": "וְצִוָּה֙ הַכֹּהֵ֔ן וְכִ֨בְּס֔וּ אֵ֥ת אֲשֶׁר־בּ֖וֹ הַנָּ֑גַע וְהִסְגִּיר֥וֹ שִׁבְעַת־יָמִ֖ים שֵׁנִֽית׃"
          },
          {
            "num": "נו",
            "txt": "וְרָאָ֨ה הַכֹּהֵ֜ן אַֽחֲרֵ֣י ׀ הֻכַּבֵּ֣ס אֶת־הַנֶּ֗גַע וְ֠הִנֵּה לֹֽא־הָפַ֨ךְ הַנֶּ֤גַע אֶת־עֵינוֹ֙ וְהַנֶּ֣גַע לֹֽא־פָשָׂ֔ה טָמֵ֣א ה֔וּא בָּאֵ֖שׁ תִּשְׂרְפֶ֑נּוּ פְּחֶ֣תֶת הִ֔וא בְּקָֽרַחְתּ֖וֹ א֥וֹ בְגַבַּחְתּֽוֹ׃"
          },
          {
            "num": "נז",
            "txt": "וְאִם֮ רָאָ֣ה הַכֹּהֵן֒ וְהִנֵּה֙ כֵּהָ֣ה הַנֶּ֔גַע אַֽחֲרֵ֖י הֻכַּבֵּ֣ס אֹת֑וֹ וְקָרַ֣ע אֹת֗וֹ מִן־הַבֶּ֨גֶד֙ א֣וֹ מִן־הָע֔וֹר א֥וֹ מִן־הַשְּׁתִ֖י א֥וֹ מִן־הָעֵֽרֶב׃"
          },
          {
            "num": "נח",
            "txt": "וְאִם־תֵּֽרָאֶ֨ה ע֜וֹד בַּ֠בֶּגֶד אֽוֹ־בַשְּׁתִ֤י אֽוֹ־בָעֵ֨רֶב֙ א֣וֹ בְכָל־כְּלִי־ע֔וֹר פֹּרַ֖חַת הִ֑וא בָּאֵ֣שׁ תִּשְׂרְפֶ֔נּוּ אֵ֥ת אֲשֶׁר־בּ֖וֹ הַנָּֽגַע׃"
          },
          {
            "num": "נט",
            "txt": "וְהַבֶּ֡גֶד אֽוֹ־הַשְּׁתִ֨י אֽוֹ־הָעֵ֜רֶב אֽוֹ־כָל־כְּלִ֤י הָעוֹר֙ אֲשֶׁ֣ר תְּכַבֵּ֔ס וְסָ֥ר מֵהֶ֖ם הַנָּ֑גַע וְכֻבַּ֥ס שֵׁנִ֖ית וְטָהֵֽר׃"
          },
          {
            "txt": "זֹ֠את תּוֹרַ֨ת נֶֽגַע־צָרַ֜עַת בֶּ֥גֶד הַצֶּ֣מֶר ׀ א֣וֹ הַפִּשְׁתִּ֗ים א֤וֹ הַשְּׁתִי֙ א֣וֹ הָעֵ֔רֶב א֖וֹ כָּל־כְּלִי־ע֑וֹר לְטַֽהֲר֖וֹ א֥וֹ לְטַמְּאֽוֹ׃ {פ}"
          }
        ]
      },
      {
        "num": "יד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "זֹ֤את תִּֽהְיֶה֙ תּוֹרַ֣ת הַמְּצֹרָ֔ע בְּי֖וֹם טָֽהֳרָת֑וֹ וְהוּבָ֖א אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "ג",
            "txt": "וְיָצָא֙ הַכֹּהֵ֔ן אֶל־מִח֖וּץ לַֽמַּחֲנֶ֑ה וְרָאָה֙ הַכֹּהֵ֔ן וְהִנֵּ֛ה נִרְפָּ֥א נֶֽגַע־הַצָּרַ֖עַת מִן־הַצָּרֽוּעַ׃"
          },
          {
            "num": "ד",
            "txt": "וְצִוָּה֙ הַכֹּהֵ֔ן וְלָקַ֧ח לַמִּטַּהֵ֛ר שְׁתֵּֽי־צִפֳּרִ֥ים חַיּ֖וֹת טְהֹר֑וֹת וְעֵ֣ץ אֶ֔רֶז וּשְׁנִ֥י תוֹלַ֖עַת וְאֵזֹֽב׃"
          },
          {
            "num": "ה",
            "txt": "וְצִוָּה֙ הַכֹּהֵ֔ן וְשָׁחַ֖ט אֶת־הַצִּפּ֣וֹר הָֽאֶחָ֑ת אֶל־כְּלִי־חֶ֖רֶשׂ עַל־מַ֥יִם חַיִּֽים׃"
          },
          {
            "num": "ו",
            "txt": "אֶת־הַצִּפֹּ֤ר הַֽחַיָּה֙ יִקַּ֣ח אֹתָ֔הּ וְאֶת־עֵ֥ץ הָאֶ֛רֶז וְאֶת־שְׁנִ֥י הַתּוֹלַ֖עַת וְאֶת־הָֽאֵזֹ֑ב וְטָבַ֨ל אוֹתָ֜ם וְאֵ֣ת ׀ הַצִּפֹּ֣ר הַֽחַיָּ֗ה בְּדַם֙ הַצִּפֹּ֣ר הַשְּׁחֻטָ֔ה עַ֖ל הַמַּ֥יִם הַֽחַיִּֽים׃"
          },
          {
            "num": "ז",
            "txt": "וְהִזָּ֗ה עַ֧ל הַמִּטַּהֵ֛ר מִן־הַצָּרַ֖עַת שֶׁ֣בַע פְּעָמִ֑ים וְטִ֣הֲר֔וֹ וְשִׁלַּ֛ח אֶת־הַצִּפֹּ֥ר הַֽחַיָּ֖ה עַל־פְּנֵ֥י הַשָּׂדֶֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְכִבֶּס֩ הַמִּטַּהֵ֨ר אֶת־בְּגָדָ֜יו וְגִלַּ֣ח אֶת־כָּל־שְׂעָר֗וֹ וְרָחַ֤ץ בַּמַּ֨יִם֙ וְטָהֵ֔ר וְאַחַ֖ר יָב֣וֹא אֶל־הַֽמַּחֲנֶ֑ה וְיָשַׁ֛ב מִח֥וּץ לְאָֽהֳל֖וֹ שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "ט",
            "txt": "וְהָיָה֩ בַיּ֨וֹם הַשְּׁבִיעִ֜י יְגַלַּ֣ח אֶת־כָּל־שְׂעָר֗וֹ אֶת־רֹאשׁ֤וֹ וְאֶת־זְקָנוֹ֙ וְאֵת֙ גַּבֹּ֣ת עֵינָ֔יו וְאֶת־כָּל־שְׂעָר֖וֹ יְגַלֵּ֑חַ וְכִבֶּ֣ס אֶת־בְּגָדָ֗יו וְרָחַ֧ץ אֶת־בְּשָׂר֛וֹ בַּמַּ֖יִם וְטָהֵֽר׃"
          },
          {
            "num": "י",
            "txt": "וּבַיּ֣וֹם הַשְּׁמִינִ֗י יִקַּ֤ח שְׁנֵֽי־כְבָשִׂים֙ תְּמִימִ֔ם וְכַבְשָׂ֥ה אַחַ֛ת בַּת־שְׁנָתָ֖הּ תְּמִימָ֑ה וּשְׁלֹשָׁ֣ה עֶשְׂרֹנִ֗ים סֹ֤לֶת מִנְחָה֙ בְּלוּלָ֣ה בַשֶּׁ֔מֶן וְלֹ֥ג אֶחָ֖ד שָֽׁמֶן׃"
          },
          {
            "num": "יא",
            "txt": "וְהֶֽעֱמִ֞יד הַכֹּהֵ֣ן הַֽמְטַהֵ֗ר אֵ֛ת הָאִ֥ישׁ הַמִּטַּהֵ֖ר וְאֹתָ֑ם לִפְנֵ֣י יְהוָ֔ה פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "יב",
            "txt": "וְלָקַ֨ח הַכֹּהֵ֜ן אֶת־הַכֶּ֣בֶשׂ הָֽאֶחָ֗ד וְהִקְרִ֥יב אֹת֛וֹ לְאָשָׁ֖ם וְאֶת־לֹ֣ג הַשָּׁ֑מֶן וְהֵנִ֥יף אֹתָ֛ם תְּנוּפָ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְשָׁחַ֣ט אֶת־הַכֶּ֗בֶשׂ בִּ֠מְקוֹם אֲשֶׁ֨ר יִשְׁחַ֧ט אֶת־הַֽחַטָּ֛את וְאֶת־הָֽעֹלָ֖ה בִּמְק֣וֹם הַקֹּ֑דֶשׁ כִּ֡י כַּֽ֠חַטָּאת הָֽאָשָׁ֥ם הוּא֙ לַכֹּהֵ֔ן קֹ֥דֶשׁ קָֽדָשִׁ֖ים הֽוּא׃"
          },
          {
            "num": "יד",
            "txt": "וְלָקַ֣ח הַכֹּהֵן֮ מִדַּ֣ם הָֽאָשָׁם֒ וְנָתַן֙ הַכֹּהֵ֔ן עַל־תְּנ֛וּךְ אֹ֥זֶן הַמִּטַּהֵ֖ר הַיְמָנִ֑ית וְעַל־בֹּ֤הֶן יָדוֹ֙ הַיְמָנִ֔ית וְעַל־בֹּ֥הֶן רַגְל֖וֹ הַיְמָנִֽית׃"
          },
          {
            "num": "טו",
            "txt": "וְלָקַ֥ח הַכֹּהֵ֖ן מִלֹּ֣ג הַשָּׁ֑מֶן וְיָצַ֛ק עַל־כַּ֥ף הַכֹּהֵ֖ן הַשְּׂמָאלִֽית׃"
          },
          {
            "num": "טז",
            "txt": "וְטָבַ֤ל הַכֹּהֵן֙ אֶת־אֶצְבָּע֣וֹ הַיְמָנִ֔ית מִן־הַשֶּׁ֕מֶן אֲשֶׁ֥ר עַל־כַּפּ֖וֹ הַשְּׂמָאלִ֑ית וְהִזָּ֨ה מִן־הַשֶּׁ֧מֶן בְּאֶצְבָּע֛וֹ שֶׁ֥בַע פְּעָמִ֖ים לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "יז",
            "txt": "וּמִיֶּ֨תֶר הַשֶּׁ֜מֶן אֲשֶׁ֣ר עַל־כַּפּ֗וֹ יִתֵּ֤ן הַכֹּהֵן֙ עַל־תְּנ֞וּךְ אֹ֤זֶן הַמִּטַּהֵר֙ הַיְמָנִ֔ית וְעַל־בֹּ֤הֶן יָדוֹ֙ הַיְמָנִ֔ית וְעַל־בֹּ֥הֶן רַגְל֖וֹ הַיְמָנִ֑ית עַ֖ל דַּ֥ם הָֽאָשָֽׁם׃"
          },
          {
            "num": "יח",
            "txt": "וְהַנּוֹתָ֗ר בַּשֶּׁ֨מֶן֙ אֲשֶׁר֙ עַל־כַּ֣ף הַכֹּהֵ֔ן יִתֵּ֖ן עַל־רֹ֣אשׁ הַמִּטַּהֵ֑ר וְכִפֶּ֥ר עָלָ֛יו הַכֹּהֵ֖ן לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "יט",
            "txt": "וְעָשָׂ֤ה הַכֹּהֵן֙ אֶת־הַ֣חַטָּ֔את וְכִפֶּ֕ר עַל־הַמִּטַּהֵ֖ר מִטֻּמְאָת֑וֹ וְאַחַ֖ר יִשְׁחַ֥ט אֶת־הָֽעֹלָֽה׃"
          },
          {
            "num": "כ",
            "txt": "וְהֶֽעֱלָ֧ה הַכֹּהֵ֛ן אֶת־הָֽעֹלָ֥ה וְאֶת־הַמִּנְחָ֖ה הַמִּזְבֵּ֑חָה וְכִפֶּ֥ר עָלָ֛יו הַכֹּהֵ֖ן וְטָהֵֽר׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "וְאִם־דַּ֣ל ה֗וּא וְאֵ֣ין יָדוֹ֮ מַשֶּׂגֶת֒ וְ֠לָקַח כֶּ֣בֶשׂ אֶחָ֥ד אָשָׁ֛ם לִתְנוּפָ֖ה לְכַפֵּ֣ר עָלָ֑יו וְעִשָּׂר֨וֹן סֹ֜לֶת אֶחָ֨ד בָּל֥וּל בַּשֶּׁ֛מֶן לְמִנְחָ֖ה וְלֹ֥ג שָֽׁמֶן׃"
          },
          {
            "num": "כב",
            "txt": "וּשְׁתֵּ֣י תֹרִ֗ים א֤וֹ שְׁנֵי֙ בְּנֵ֣י יוֹנָ֔ה אֲשֶׁ֥ר תַּשִּׂ֖יג יָד֑וֹ וְהָיָ֤ה אֶחָד֙ חַטָּ֔את וְהָֽאֶחָ֖ד עֹלָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וְהֵבִ֨יא אֹתָ֜ם בַּיּ֧וֹם הַשְּׁמִינִ֛י לְטָֽהֳרָת֖וֹ אֶל־הַכֹּהֵ֑ן אֶל־פֶּ֥תַח אֹֽהֶל־מוֹעֵ֖ד לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כד",
            "txt": "וְלָקַ֧ח הַכֹּהֵ֛ן אֶת־כֶּ֥בֶשׂ הָֽאָשָׁ֖ם וְאֶת־לֹ֣ג הַשָּׁ֑מֶן וְהֵנִ֨יף אֹתָ֧ם הַכֹּהֵ֛ן תְּנוּפָ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כה",
            "txt": "וְשָׁחַט֮ אֶת־כֶּ֣בֶשׂ הָֽאָשָׁם֒ וְלָקַ֤ח הַכֹּהֵן֙ מִדַּ֣ם הָֽאָשָׁ֔ם וְנָתַ֛ן עַל־תְּנ֥וּךְ אֹֽזֶן־הַמִּטַּהֵ֖ר הַיְמָנִ֑ית וְעַל־בֹּ֤הֶן יָדוֹ֙ הַיְמָנִ֔ית וְעַל־בֹּ֥הֶן רַגְל֖וֹ הַיְמָנִֽית׃"
          },
          {
            "num": "כו",
            "txt": "וּמִן־הַשֶּׁ֖מֶן יִצֹ֣ק הַכֹּהֵ֑ן עַל־כַּ֥ף הַכֹּהֵ֖ן הַשְּׂמָאלִֽית׃"
          },
          {
            "num": "כז",
            "txt": "וְהִזָּ֤ה הַכֹּהֵן֙ בְּאֶצְבָּע֣וֹ הַיְמָנִ֔ית מִן־הַשֶּׁ֕מֶן אֲשֶׁ֥ר עַל־כַּפּ֖וֹ הַשְּׂמָאלִ֑ית שֶׁ֥בַע פְּעָמִ֖ים לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וְנָתַ֨ן הַכֹּהֵ֜ן מִן־הַשֶּׁ֣מֶן ׀ אֲשֶׁ֣ר עַל־כַּפּ֗וֹ עַל־תְּנ֞וּךְ אֹ֤זֶן הַמִּטַּהֵר֙ הַיְמָנִ֔ית וְעַל־בֹּ֤הֶן יָדוֹ֙ הַיְמָנִ֔ית וְעַל־בֹּ֥הֶן רַגְל֖וֹ הַיְמָנִ֑ית עַל־מְק֖וֹם דַּ֥ם הָֽאָשָֽׁם׃"
          },
          {
            "num": "כט",
            "txt": "וְהַנּוֹתָ֗ר מִן־הַשֶּׁ֨מֶן֙ אֲשֶׁר֙ עַל־כַּ֣ף הַכֹּהֵ֔ן יִתֵּ֖ן עַל־רֹ֣אשׁ הַמִּטַּהֵ֑ר לְכַפֵּ֥ר עָלָ֖יו לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וְעָשָׂ֤ה אֶת־הָֽאֶחָד֙ מִן־הַתֹּרִ֔ים א֖וֹ מִן־בְּנֵ֣י הַיּוֹנָ֑ה מֵֽאֲשֶׁ֥ר תַּשִּׂ֖יג יָדֽוֹ׃"
          },
          {
            "num": "לא",
            "txt": "אֵ֣ת אֲשֶׁר־תַּשִּׂ֞יג יָד֗וֹ אֶת־הָֽאֶחָ֥ד חַטָּ֛את וְאֶת־הָֽאֶחָ֥ד עֹלָ֖ה עַל־הַמִּנְחָ֑ה וְכִפֶּ֧ר הַכֹּהֵ֛ן עַ֥ל הַמִּטַּהֵ֖ר לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "לב",
            "txt": "זֹ֣את תּוֹרַ֔ת אֲשֶׁר־בּ֖וֹ נֶ֣גַע צָרָ֑עַת אֲשֶׁ֛ר לֹֽא־תַשִּׂ֥יג יָד֖וֹ בְּטָֽהֳרָתֽוֹ׃ {פ}"
          },
          {
            "num": "לג",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "לד",
            "txt": "כִּ֤י תָבֹ֨אוּ֙ אֶל־אֶ֣רֶץ כְּנַ֔עַן אֲשֶׁ֥ר אֲנִ֛י נֹתֵ֥ן לָכֶ֖ם לַֽאֲחֻזָּ֑ה וְנָֽתַתִּי֙ נֶ֣גַע צָרַ֔עַת בְּבֵ֖ית אֶ֥רֶץ אֲחֻזַּתְכֶֽם׃"
          },
          {
            "num": "לה",
            "txt": "וּבָא֙ אֲשֶׁר־ל֣וֹ הַבַּ֔יִת וְהִגִּ֥יד לַכֹּהֵ֖ן לֵאמֹ֑ר כְּנֶ֕גַע נִרְאָ֥ה לִ֖י בַּבָּֽיִת׃"
          },
          {
            "num": "לו",
            "txt": "וְצִוָּ֨ה הַכֹּהֵ֜ן וּפִנּ֣וּ אֶת־הַבַּ֗יִת בְּטֶ֨רֶם יָבֹ֤א הַכֹּהֵן֙ לִרְא֣וֹת אֶת־הַנֶּ֔גַע וְלֹ֥א יִטְמָ֖א כָּל־אֲשֶׁ֣ר בַּבָּ֑יִת וְאַ֥חַר כֵּ֛ן יָבֹ֥א הַכֹּהֵ֖ן לִרְא֥וֹת אֶת־הַבָּֽיִת׃"
          },
          {
            "num": "לז",
            "txt": "וְרָאָ֣ה אֶת־הַנֶּ֗גַע וְהִנֵּ֤ה הַנֶּ֨גַע֙ בְּקִירֹ֣ת הַבַּ֔יִת שְׁקַֽעֲרוּרֹת֙ יְרַקְרַקֹּ֔ת א֖וֹ אֲדַמְדַּמֹּ֑ת וּמַרְאֵיהֶ֥ן שָׁפָ֖ל מִן־הַקִּֽיר׃"
          },
          {
            "num": "לח",
            "txt": "וְיָצָ֧א הַכֹּהֵ֛ן מִן־הַבַּ֖יִת אֶל־פֶּ֣תַח הַבָּ֑יִת וְהִסְגִּ֥יר אֶת־הַבַּ֖יִת שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "לט",
            "txt": "וְשָׁ֥ב הַכֹּהֵ֖ן בַּיּ֣וֹם הַשְּׁבִיעִ֑י וְרָאָ֕ה וְהִנֵּ֛ה פָּשָׂ֥ה הַנֶּ֖גַע בְּקִירֹ֥ת הַבָּֽיִת׃"
          },
          {
            "num": "מ",
            "txt": "וְצִוָּה֙ הַכֹּהֵ֔ן וְחִלְּצוּ֙ אֶת־הָ֣אֲבָנִ֔ים אֲשֶׁ֥ר בָּהֵ֖ן הַנָּ֑גַע וְהִשְׁלִ֤יכוּ אֶתְהֶן֙ אֶל־מִח֣וּץ לָעִ֔יר אֶל־מָק֖וֹם טָמֵֽא׃"
          },
          {
            "num": "מא",
            "txt": "וְאֶת־הַבַּ֛יִת יַקְצִ֥עַ מִבַּ֖יִת סָבִ֑יב וְשָֽׁפְכ֗וּ אֶת־הֶֽעָפָר֙ אֲשֶׁ֣ר הִקְצ֔וּ אֶל־מִח֣וּץ לָעִ֔יר אֶל־מָק֖וֹם טָמֵֽא׃"
          },
          {
            "num": "מב",
            "txt": "וְלָֽקְחוּ֙ אֲבָנִ֣ים אֲחֵר֔וֹת וְהֵבִ֖יאוּ אֶל־תַּ֣חַת הָֽאֲבָנִ֑ים וְעָפָ֥ר אַחֵ֛ר יִקַּ֖ח וְטָ֥ח אֶת־הַבָּֽיִת׃"
          },
          {
            "num": "מג",
            "txt": "וְאִם־יָשׁ֤וּב הַנֶּ֨גַע֙ וּפָרַ֣ח בַּבַּ֔יִת אַחַ֖ר חִלֵּ֣ץ אֶת־הָֽאֲבָנִ֑ים וְאַֽחֲרֵ֛י הִקְצ֥וֹת אֶת־הַבַּ֖יִת וְאַֽחֲרֵ֥י הִטּֽוֹחַ׃"
          },
          {
            "num": "מד",
            "txt": "וּבָא֙ הַכֹּהֵ֔ן וְרָאָ֕ה וְהִנֵּ֛ה פָּשָׂ֥ה הַנֶּ֖גַע בַּבָּ֑יִת צָרַ֨עַת מַמְאֶ֥רֶת הִ֛וא בַּבַּ֖יִת טָמֵ֥א הֽוּא׃"
          },
          {
            "num": "מה",
            "txt": "וְנָתַ֣ץ אֶת־הַבַּ֗יִת אֶת־אֲבָנָיו֙ וְאֶת־עֵצָ֔יו וְאֵ֖ת כָּל־עֲפַ֣ר הַבָּ֑יִת וְהוֹצִיא֙ אֶל־מִח֣וּץ לָעִ֔יר אֶל־מָק֖וֹם טָמֵֽא׃"
          },
          {
            "num": "מו",
            "txt": "וְהַבָּא֙ אֶל־הַבַּ֔יִת כָּל־יְמֵ֖י הִסְגִּ֣יר אֹת֑וֹ יִטְמָ֖א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "מז",
            "txt": "וְהַשֹּׁכֵ֣ב בַּבַּ֔יִת יְכַבֵּ֖ס אֶת־בְּגָדָ֑יו וְהָֽאֹכֵ֣ל בַּבַּ֔יִת יְכַבֵּ֖ס אֶת־בְּגָדָֽיו׃"
          },
          {
            "num": "מח",
            "txt": "וְאִם־בֹּ֨א יָבֹ֜א הַכֹּהֵ֗ן וְרָאָה֙ וְ֠הִנֵּה לֹֽא־פָשָׂ֤ה הַנֶּ֨גַע֙ בַּבַּ֔יִת אַֽחֲרֵ֖י הִטֹּ֣חַ אֶת־הַבָּ֑יִת וְטִהַ֤ר הַכֹּהֵן֙ אֶת־הַבַּ֔יִת כִּ֥י נִרְפָּ֖א הַנָּֽגַע׃"
          },
          {
            "num": "מט",
            "txt": "וְלָקַ֛ח לְחַטֵּ֥א אֶת־הַבַּ֖יִת שְׁתֵּ֣י צִפֳּרִ֑ים וְעֵ֣ץ אֶ֔רֶז וּשְׁנִ֥י תוֹלַ֖עַת וְאֵזֹֽב׃"
          },
          {
            "num": "נ",
            "txt": "וְשָׁחַ֖ט אֶת־הַצִּפֹּ֣ר הָֽאֶחָ֑ת אֶל־כְּלִי־חֶ֖רֶשׂ עַל־מַ֥יִם חַיִּֽים׃"
          },
          {
            "num": "נא",
            "txt": "וְלָקַ֣ח אֶת־עֵֽץ־הָ֠אֶרֶז וְאֶת־הָ֨אֵזֹ֜ב וְאֵ֣ת ׀ שְׁנִ֣י הַתּוֹלַ֗עַת וְאֵת֮ הַצִּפֹּ֣ר הַֽחַיָּה֒ וְטָבַ֣ל אֹתָ֗ם בְּדַם֙ הַצִּפֹּ֣ר הַשְּׁחוּטָ֔ה וּבַמַּ֖יִם הַֽחַיִּ֑ים וְהִזָּ֥ה אֶל־הַבַּ֖יִת שֶׁ֥בַע פְּעָמִֽים׃"
          },
          {
            "num": "נב",
            "txt": "וְחִטֵּ֣א אֶת־הַבַּ֔יִת בְּדַם֙ הַצִּפּ֔וֹר וּבַמַּ֖יִם הַֽחַיִּ֑ים וּבַצִּפֹּ֣ר הַֽחַיָּ֗ה וּבְעֵ֥ץ הָאֶ֛רֶז וּבָֽאֵזֹ֖ב וּבִשְׁנִ֥י הַתּוֹלָֽעַת׃"
          },
          {
            "num": "נג",
            "txt": "וְשִׁלַּ֞ח אֶת־הַצִּפֹּ֧ר הַֽחַיָּ֛ה אֶל־מִח֥וּץ לָעִ֖יר אֶל־פְּנֵ֣י הַשָּׂדֶ֑ה וְכִפֶּ֥ר עַל־הַבַּ֖יִת וְטָהֵֽר׃"
          },
          {
            "num": "נד",
            "txt": "זֹ֖את הַתּוֹרָ֑ה לְכָל־נֶ֥גַע הַצָּרַ֖עַת וְלַנָּֽתֶק׃"
          },
          {
            "num": "נה",
            "txt": "וּלְצָרַ֥עַת הַבֶּ֖גֶד וְלַבָּֽיִת׃"
          },
          {
            "num": "נו",
            "txt": "וְלַשְׂאֵ֥ת וְלַסַּפַּ֖חַת וְלַבֶּהָֽרֶת׃"
          },
          {
            "num": "נז",
            "txt": "לְהוֹרֹ֕ת בְּי֥וֹם הַטָּמֵ֖א וּבְי֣וֹם הַטָּהֹ֑ר זֹ֥את תּוֹרַ֖ת הַצָּרָֽעַת׃ {פ}"
          }
        ]
      },
      {
        "num": "טו",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבְּרוּ֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וַֽאֲמַרְתֶּ֖ם אֲלֵהֶ֑ם אִ֣ישׁ אִ֗ישׁ כִּ֤י יִֽהְיֶה֙ זָ֣ב מִבְּשָׂר֔וֹ זוֹב֖וֹ טָמֵ֥א הֽוּא׃"
          },
          {
            "num": "ג",
            "txt": "וְזֹ֛את תִּֽהְיֶ֥ה טֻמְאָת֖וֹ בְּזוֹב֑וֹ רָ֣ר בְּשָׂר֞וֹ אֶת־זוֹב֗וֹ אֽוֹ־הֶחְתִּ֤ים בְּשָׂרוֹ֙ מִזּוֹב֔וֹ טֻמְאָת֖וֹ הִֽוא׃"
          },
          {
            "num": "ד",
            "txt": "כָּל־הַמִּשְׁכָּ֗ב אֲשֶׁ֨ר יִשְׁכַּ֥ב עָלָ֛יו הַזָּ֖ב יִטְמָ֑א וְכָֽל־הַכְּלִ֛י אֲשֶׁר־יֵשֵׁ֥ב עָלָ֖יו יִטְמָֽא׃"
          },
          {
            "num": "ה",
            "txt": "וְאִ֕ישׁ אֲשֶׁ֥ר יִגַּ֖ע בְּמִשְׁכָּב֑וֹ יְכַבֵּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "ו",
            "txt": "וְהַיֹּשֵׁב֙ עַֽל־הַכְּלִ֔י אֲשֶׁר־יֵשֵׁ֥ב עָלָ֖יו הַזָּ֑ב יְכַבֵּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "ז",
            "txt": "וְהַנֹּגֵ֖עַ בִּבְשַׂ֣ר הַזָּ֑ב יְכַבֵּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "ח",
            "txt": "וְכִֽי־יָרֹ֥ק הַזָּ֖ב בַּטָּה֑וֹר וְכִבֶּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "ט",
            "txt": "וְכָל־הַמֶּרְכָּ֗ב אֲשֶׁ֨ר יִרְכַּ֥ב עָלָ֛יו הַזָּ֖ב יִטְמָֽא׃"
          },
          {
            "num": "י",
            "txt": "וְכָל־הַנֹּגֵ֗עַ בְּכֹל֙ אֲשֶׁ֣ר יִֽהְיֶ֣ה תַחְתָּ֔יו יִטְמָ֖א עַד־הָעָ֑רֶב וְהַנּוֹשֵׂ֣א אוֹתָ֔ם יְכַבֵּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "יא",
            "txt": "וְכֹ֨ל אֲשֶׁ֤ר יִגַּע־בּוֹ֙ הַזָּ֔ב וְיָדָ֖יו לֹֽא־שָׁטַ֣ף בַּמָּ֑יִם וְכִבֶּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "יב",
            "txt": "וּכְלִי־חֶ֛רֶשׂ אֲשֶׁר־יִגַּע־בּ֥וֹ הַזָּ֖ב יִשָּׁבֵ֑ר וְכָ֨ל־כְּלִי־עֵ֔ץ יִשָּׁטֵ֖ף בַּמָּֽיִם׃"
          },
          {
            "num": "יג",
            "txt": "וְכִֽי־יִטְהַ֤ר הַזָּב֙ מִזּוֹב֔וֹ וְסָ֨פַר ל֜וֹ שִׁבְעַ֥ת יָמִ֛ים לְטָֽהֳרָת֖וֹ וְכִבֶּ֣ס בְּגָדָ֑יו וְרָחַ֧ץ בְּשָׂר֛וֹ בְּמַ֥יִם חַיִּ֖ים וְטָהֵֽר׃"
          },
          {
            "num": "יד",
            "txt": "וּבַיּ֣וֹם הַשְּׁמִינִ֗י יִֽקַּֽח־לוֹ֙ שְׁתֵּ֣י תֹרִ֔ים א֥וֹ שְׁנֵ֖י בְּנֵ֣י יוֹנָ֑ה וּבָ֣א ׀ לִפְנֵ֣י יְהוָ֗ה אֶל־פֶּ֨תַח֙ אֹ֣הֶל מוֹעֵ֔ד וּנְתָנָ֖ם אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "טו",
            "txt": "וְעָשָׂ֤ה אֹתָם֙ הַכֹּהֵ֔ן אֶחָ֣ד חַטָּ֔את וְהָֽאֶחָ֖ד עֹלָ֑ה וְכִפֶּ֨ר עָלָ֧יו הַכֹּהֵ֛ן לִפְנֵ֥י יְהוָ֖ה מִזּוֹבֽוֹ׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "וְאִ֕ישׁ כִּֽי־תֵצֵ֥א מִמֶּ֖נּוּ שִׁכְבַת־זָ֑רַע וְרָחַ֥ץ בַּמַּ֛יִם אֶת־כָּל־בְּשָׂר֖וֹ וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "יז",
            "txt": "וְכָל־בֶּ֣גֶד וְכָל־ע֔וֹר אֲשֶׁר־יִֽהְיֶ֥ה עָלָ֖יו שִׁכְבַת־זָ֑רַע וְכֻבַּ֥ס בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "יח",
            "txt": "וְאִשָּׁ֕ה אֲשֶׁ֨ר יִשְׁכַּ֥ב אִ֛ישׁ אֹתָ֖הּ שִׁכְבַת־זָ֑רַע וְרָֽחֲצ֣וּ בַמַּ֔יִם וְטָֽמְא֖וּ עַד־הָעָֽרֶב׃ {פ}"
          },
          {
            "num": "יט",
            "txt": "וְאִשָּׁה֙ כִּֽי־תִהְיֶ֣ה זָבָ֔ה דָּ֛ם יִֽהְיֶ֥ה זֹבָ֖הּ בִּבְשָׂרָ֑הּ שִׁבְעַ֤ת יָמִים֙ תִּֽהְיֶ֣ה בְנִדָּתָ֔הּ וְכָל־הַנֹּגֵ֥עַ בָּ֖הּ יִטְמָ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כ",
            "txt": "וְכֹל֩ אֲשֶׁ֨ר תִּשְׁכַּ֥ב עָלָ֛יו בְּנִדָּתָ֖הּ יִטְמָ֑א וְכֹ֛ל אֲשֶׁר־תֵּשֵׁ֥ב עָלָ֖יו יִטְמָֽא׃"
          },
          {
            "num": "כא",
            "txt": "וְכָל־הַנֹּגֵ֖עַ בְּמִשְׁכָּבָ֑הּ יְכַבֵּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כב",
            "txt": "וְכָ֨ל־הַנֹּגֵ֔עַ בְּכָל־כְּלִ֖י אֲשֶׁר־תֵּשֵׁ֣ב עָלָ֑יו יְכַבֵּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כג",
            "txt": "וְאִ֨ם עַֽל־הַמִּשְׁכָּ֜ב ה֗וּא א֧וֹ עַֽל־הַכְּלִ֛י אֲשֶׁר־הִ֥וא יֹשֶֽׁבֶת־עָלָ֖יו בְּנָגְעוֹ־ב֑וֹ יִטְמָ֖א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כד",
            "txt": "וְאִ֡ם שָׁכֹב֩ יִשְׁכַּ֨ב אִ֜ישׁ אֹתָ֗הּ וּתְהִ֤י נִדָּתָהּ֙ עָלָ֔יו וְטָמֵ֖א שִׁבְעַ֣ת יָמִ֑ים וְכָל־הַמִּשְׁכָּ֛ב אֲשֶׁר־יִשְׁכַּ֥ב עָלָ֖יו יִטְמָֽא׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "וְאִשָּׁ֡ה כִּֽי־יָזוּב֩ ז֨וֹב דָּמָ֜הּ יָמִ֣ים רַבִּ֗ים בְּלֹא֙ עֶת־נִדָּתָ֔הּ א֥וֹ כִֽי־תָז֖וּב עַל־נִדָּתָ֑הּ כָּל־יְמֵ֞י ז֣וֹב טֻמְאָתָ֗הּ כִּימֵ֧י נִדָּתָ֛הּ תִּֽהְיֶ֖ה טְמֵאָ֥ה הִֽוא׃"
          },
          {
            "num": "כו",
            "txt": "כָּל־הַמִּשְׁכָּ֞ב אֲשֶׁר־תִּשְׁכַּ֤ב עָלָיו֙ כָּל־יְמֵ֣י זוֹבָ֔הּ כְּמִשְׁכַּ֥ב נִדָּתָ֖הּ יִֽהְיֶה־לָּ֑הּ וְכָֽל־הַכְּלִי֙ אֲשֶׁ֣ר תֵּשֵׁ֣ב עָלָ֔יו טָמֵ֣א יִֽהְיֶ֔ה כְּטֻמְאַ֖ת נִדָּתָֽהּ׃"
          },
          {
            "num": "כז",
            "txt": "וְכָל־הַנּוֹגֵ֥עַ בָּ֖ם יִטְמָ֑א וְכִבֶּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָמֵ֥א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כח",
            "txt": "וְאִֽם־טָהֲרָ֖ה מִזּוֹבָ֑הּ וְסָ֥פְרָה לָּ֛הּ שִׁבְעַ֥ת יָמִ֖ים וְאַחַ֥ר תִּטְהָֽר׃"
          },
          {
            "num": "כט",
            "txt": "וּבַיּ֣וֹם הַשְּׁמִינִ֗י תִּֽקַּֽח־לָהּ֙ שְׁתֵּ֣י תֹרִ֔ים א֥וֹ שְׁנֵ֖י בְּנֵ֣י יוֹנָ֑ה וְהֵֽבִיאָ֤ה אוֹתָם֙ אֶל־הַכֹּהֵ֔ן אֶל־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ל",
            "txt": "וְעָשָׂ֤ה הַכֹּהֵן֙ אֶת־הָֽאֶחָ֣ד חַטָּ֔את וְאֶת־הָֽאֶחָ֖ד עֹלָ֑ה וְכִפֶּ֨ר עָלֶ֤יהָ הַכֹּהֵן֙ לִפְנֵ֣י יְהוָ֔ה מִזּ֖וֹב טֻמְאָתָֽהּ׃"
          },
          {
            "num": "לא",
            "txt": "וְהִזַּרְתֶּ֥ם אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל מִטֻּמְאָתָ֑ם וְלֹ֤א יָמֻ֨תוּ֙ בְּטֻמְאָתָ֔ם בְּטַמְּאָ֥ם אֶת־מִשְׁכָּנִ֖י אֲשֶׁ֥ר בְּתוֹכָֽם׃"
          },
          {
            "num": "לב",
            "txt": "זֹ֥את תּוֹרַ֖ת הַזָּ֑ב וַֽאֲשֶׁ֨ר תֵּצֵ֥א מִמֶּ֛נּוּ שִׁכְבַת־זֶ֖רַע לְטָמְאָה־בָֽהּ׃"
          },
          {
            "num": "לג",
            "txt": "וְהַדָּוָה֙ בְּנִדָּתָ֔הּ וְהַזָּב֙ אֶת־זוֹב֔וֹ לַזָּכָ֖ר וְלַנְּקֵבָ֑ה וּלְאִ֕ישׁ אֲשֶׁ֥ר יִשְׁכַּ֖ב עִם־טְמֵאָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "טז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֤ר יְהוָה֙ אֶל־מֹשֶׁ֔ה אַֽחֲרֵ֣י מ֔וֹת שְׁנֵ֖י בְּנֵ֣י אַֽהֲרֹ֑ן בְּקָרְבָתָ֥ם לִפְנֵֽי־יְהוָ֖ה וַיָּמֻֽתוּ׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה דַּבֵּר֮ אֶל־אַֽהֲרֹ֣ן אָחִיךָ֒ וְאַל־יָבֹ֤א בְכָל־עֵת֙ אֶל־הַקֹּ֔דֶשׁ מִבֵּ֖ית לַפָּרֹ֑כֶת אֶל־פְּנֵ֨י הַכַּפֹּ֜רֶת אֲשֶׁ֤ר עַל־הָֽאָרֹן֙ וְלֹ֣א יָמ֔וּת כִּ֚י בֶּֽעָנָ֔ן אֵֽרָאֶ֖ה עַל־הַכַּפֹּֽרֶת׃"
          },
          {
            "num": "ג",
            "txt": "בְּזֹ֛את יָבֹ֥א אַֽהֲרֹ֖ן אֶל־הַקֹּ֑דֶשׁ בְּפַ֧ר בֶּן־בָּקָ֛ר לְחַטָּ֖את וְאַ֥יִל לְעֹלָֽה׃"
          },
          {
            "num": "ד",
            "txt": "כְּתֹֽנֶת־בַּ֨ד קֹ֜דֶשׁ יִלְבָּ֗שׁ וּמִֽכְנְסֵי־בַד֮ יִֽהְי֣וּ עַל־בְּשָׂרוֹ֒ וּבְאַבְנֵ֥ט בַּד֙ יַחְגֹּ֔ר וּבְמִצְנֶ֥פֶת בַּ֖ד יִצְנֹ֑ף בִּגְדֵי־קֹ֣דֶשׁ הֵ֔ם וְרָחַ֥ץ בַּמַּ֛יִם אֶת־בְּשָׂר֖וֹ וּלְבֵשָֽׁם׃"
          },
          {
            "num": "ה",
            "txt": "וּמֵאֵ֗ת עֲדַת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל יִקַּ֛ח שְׁנֵֽי־שְׂעִירֵ֥י עִזִּ֖ים לְחַטָּ֑את וְאַ֥יִל אֶחָ֖ד לְעֹלָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וְהִקְרִ֧יב אַֽהֲרֹ֛ן אֶת־פַּ֥ר הַֽחַטָּ֖את אֲשֶׁר־ל֑וֹ וְכִפֶּ֥ר בַּֽעֲד֖וֹ וּבְעַ֥ד בֵּיתֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וְלָקַ֖ח אֶת־שְׁנֵ֣י הַשְּׂעִירִ֑ם וְהֶֽעֱמִ֤יד אֹתָם֙ לִפְנֵ֣י יְהוָ֔ה פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ח",
            "txt": "וְנָתַ֧ן אַֽהֲרֹ֛ן עַל־שְׁנֵ֥י הַשְּׂעִירִ֖ם גֹּֽרָל֑וֹת גּוֹרָ֤ל אֶחָד֙ לַֽיהוָ֔ה וְגוֹרָ֥ל אֶחָ֖ד לַֽעֲזָאזֵֽל׃"
          },
          {
            "num": "ט",
            "txt": "וְהִקְרִ֤יב אַֽהֲרֹן֙ אֶת־הַשָּׂעִ֔יר אֲשֶׁ֨ר עָלָ֥ה עָלָ֛יו הַגּוֹרָ֖ל לַֽיהוָ֑ה וְעָשָׂ֖הוּ חַטָּֽאת׃"
          },
          {
            "num": "י",
            "txt": "וְהַשָּׂעִ֗יר אֲשֶׁר֩ עָלָ֨ה עָלָ֤יו הַגּוֹרָל֙ לַֽעֲזָאזֵ֔ל יָֽעֳמַד־חַ֛י לִפְנֵ֥י יְהוָ֖ה לְכַפֵּ֣ר עָלָ֑יו לְשַׁלַּ֥ח אֹת֛וֹ לַֽעֲזָאזֵ֖ל הַמִּדְבָּֽרָה׃"
          },
          {
            "num": "יא",
            "txt": "וְהִקְרִ֨יב אַֽהֲרֹ֜ן אֶת־פַּ֤ר הַֽחַטָּאת֙ אֲשֶׁר־ל֔וֹ וְכִפֶּ֥ר בַּֽעֲד֖וֹ וּבְעַ֣ד בֵּית֑וֹ וְשָׁחַ֛ט אֶת־פַּ֥ר הַֽחַטָּ֖את אֲשֶׁר־לֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וְלָקַ֣ח מְלֹֽא־הַ֠מַּחְתָּה גַּֽחֲלֵי־אֵ֞שׁ מֵעַ֤ל הַמִּזְבֵּ֨חַ֙ מִלִּפְנֵ֣י יְהוָ֔ה וּמְלֹ֣א חָפְנָ֔יו קְטֹ֥רֶת סַמִּ֖ים דַּקָּ֑ה וְהֵבִ֖יא מִבֵּ֥ית לַפָּרֹֽכֶת׃"
          },
          {
            "num": "יג",
            "txt": "וְנָתַ֧ן אֶֽת־הַקְּטֹ֛רֶת עַל־הָאֵ֖שׁ לִפְנֵ֣י יְהוָ֑ה וְכִסָּ֣ה ׀ עֲנַ֣ן הַקְּטֹ֗רֶת אֶת־הַכַּפֹּ֛רֶת אֲשֶׁ֥ר עַל־הָֽעֵד֖וּת וְלֹ֥א יָמֽוּת׃"
          },
          {
            "num": "יד",
            "txt": "וְלָקַח֙ מִדַּ֣ם הַפָּ֔ר וְהִזָּ֧ה בְאֶצְבָּע֛וֹ עַל־פְּנֵ֥י הַכַּפֹּ֖רֶת קֵ֑דְמָה וְלִפְנֵ֣י הַכַּפֹּ֗רֶת יַזֶּ֧ה שֶֽׁבַע־פְּעָמִ֛ים מִן־הַדָּ֖ם בְּאֶצְבָּעֽוֹ׃"
          },
          {
            "num": "טו",
            "txt": "וְשָׁחַ֞ט אֶת־שְׂעִ֤יר הַֽחַטָּאת֙ אֲשֶׁ֣ר לָעָ֔ם וְהֵבִיא֙ אֶת־דָּמ֔וֹ אֶל־מִבֵּ֖ית לַפָּרֹ֑כֶת וְעָשָׂ֣ה אֶת־דָּמ֗וֹ כַּֽאֲשֶׁ֤ר עָשָׂה֙ לְדַ֣ם הַפָּ֔ר וְהִזָּ֥ה אֹת֛וֹ עַל־הַכַּפֹּ֖רֶת וְלִפְנֵ֥י הַכַּפֹּֽרֶת׃"
          },
          {
            "num": "טז",
            "txt": "וְכִפֶּ֣ר עַל־הַקֹּ֗דֶשׁ מִטֻּמְאֹת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וּמִפִּשְׁעֵיהֶ֖ם לְכָל־חַטֹּאתָ֑ם וְכֵ֤ן יַֽעֲשֶׂה֙ לְאֹ֣הֶל מוֹעֵ֔ד הַשֹּׁכֵ֣ן אִתָּ֔ם בְּת֖וֹךְ טֻמְאֹתָֽם׃"
          },
          {
            "num": "יז",
            "txt": "וְכָל־אָדָ֞ם לֹֽא־יִהְיֶ֣ה ׀ בְּאֹ֣הֶל מוֹעֵ֗ד בְּבֹא֛וֹ לְכַפֵּ֥ר בַּקֹּ֖דֶשׁ עַד־צֵאת֑וֹ וְכִפֶּ֤ר בַּֽעֲדוֹ֙ וּבְעַ֣ד בֵּית֔וֹ וּבְעַ֖ד כָּל־קְהַ֥ל יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יח",
            "txt": "וְיָצָ֗א אֶל־הַמִּזְבֵּ֛חַ אֲשֶׁ֥ר לִפְנֵֽי־יְהוָ֖ה וְכִפֶּ֣ר עָלָ֑יו וְלָקַ֞ח מִדַּ֤ם הַפָּר֙ וּמִדַּ֣ם הַשָּׂעִ֔יר וְנָתַ֛ן עַל־קַרְנ֥וֹת הַמִּזְבֵּ֖חַ סָבִֽיב׃"
          },
          {
            "num": "יט",
            "txt": "וְהִזָּ֨ה עָלָ֧יו מִן־הַדָּ֛ם בְּאֶצְבָּע֖וֹ שֶׁ֣בַע פְּעָמִ֑ים וְטִֽהֲר֣וֹ וְקִדְּשׁ֔וֹ מִטֻּמְאֹ֖ת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כ",
            "txt": "וְכִלָּה֙ מִכַּפֵּ֣ר אֶת־הַקֹּ֔דֶשׁ וְאֶת־אֹ֥הֶל מוֹעֵ֖ד וְאֶת־הַמִּזְבֵּ֑חַ וְהִקְרִ֖יב אֶת־הַשָּׂעִ֥יר הֶחָֽי׃"
          },
          {
            "num": "כא",
            "txt": "וְסָמַ֨ךְ אַֽהֲרֹ֜ן אֶת־שְׁתֵּ֣י יָדָ֗ו עַ֣ל רֹ֣אשׁ הַשָּׂעִיר֮ הַחַי֒ וְהִתְוַדָּ֣ה עָלָ֗יו אֶת־כָּל־עֲוֺנֹת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וְאֶת־כָּל־פִּשְׁעֵיהֶ֖ם לְכָל־חַטֹּאתָ֑ם וְנָתַ֤ן אֹתָם֙ עַל־רֹ֣אשׁ הַשָּׂעִ֔יר וְשִׁלַּ֛ח בְּיַד־אִ֥ישׁ עִתִּ֖י הַמִּדְבָּֽרָה׃"
          },
          {
            "num": "כב",
            "txt": "וְנָשָׂ֨א הַשָּׂעִ֥יר עָלָ֛יו אֶת־כָּל־עֲוֺֽנֹתָ֖ם אֶל־אֶ֣רֶץ גְּזֵרָ֑ה וְשִׁלַּ֥ח אֶת־הַשָּׂעִ֖יר בַּמִּדְבָּֽר׃"
          },
          {
            "num": "כג",
            "txt": "וּבָ֤א אַֽהֲרֹן֙ אֶל־אֹ֣הֶל מוֹעֵ֔ד וּפָשַׁט֙ אֶת־בִּגְדֵ֣י הַבָּ֔ד אֲשֶׁ֥ר לָבַ֖שׁ בְּבֹא֣וֹ אֶל־הַקֹּ֑דֶשׁ וְהִנִּיחָ֖ם שָֽׁם׃"
          },
          {
            "num": "כד",
            "txt": "וְרָחַ֨ץ אֶת־בְּשָׂר֤וֹ בַמַּ֨יִם֙ בְּמָק֣וֹם קָד֔וֹשׁ וְלָבַ֖שׁ אֶת־בְּגָדָ֑יו וְיָצָ֗א וְעָשָׂ֤ה אֶת־עֹֽלָתוֹ֙ וְאֶת־עֹלַ֣ת הָעָ֔ם וְכִפֶּ֥ר בַּֽעֲד֖וֹ וּבְעַ֥ד הָעָֽם׃"
          },
          {
            "num": "כה",
            "txt": "וְאֵ֛ת חֵ֥לֶב הַֽחַטָּ֖את יַקְטִ֥יר הַמִּזְבֵּֽחָה׃"
          },
          {
            "num": "כו",
            "txt": "וְהַֽמְשַׁלֵּ֤חַ אֶת־הַשָּׂעִיר֙ לַֽעֲזָאזֵ֔ל יְכַבֵּ֣ס בְּגָדָ֔יו וְרָחַ֥ץ אֶת־בְּשָׂר֖וֹ בַּמָּ֑יִם וְאַֽחֲרֵי־כֵ֖ן יָב֥וֹא אֶל־הַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "כז",
            "txt": "וְאֵת֩ פַּ֨ר הַֽחַטָּ֜את וְאֵ֣ת ׀ שְׂעִ֣יר הַֽחַטָּ֗את אֲשֶׁ֨ר הוּבָ֤א אֶת־דָּמָם֙ לְכַפֵּ֣ר בַּקֹּ֔דֶשׁ יוֹצִ֖יא אֶל־מִח֣וּץ לַֽמַּחֲנֶ֑ה וְשָֽׂרְפ֣וּ בָאֵ֔שׁ אֶת־עֹֽרֹתָ֥ם וְאֶת־בְּשָׂרָ֖ם וְאֶת־פִּרְשָֽׁם׃"
          },
          {
            "num": "כח",
            "txt": "וְהַשֹּׂרֵ֣ף אֹתָ֔ם יְכַבֵּ֣ס בְּגָדָ֔יו וְרָחַ֥ץ אֶת־בְּשָׂר֖וֹ בַּמָּ֑יִם וְאַֽחֲרֵי־כֵ֖ן יָב֥וֹא אֶל־הַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "כט",
            "txt": "וְהָֽיְתָ֥ה לָכֶ֖ם לְחֻקַּ֣ת עוֹלָ֑ם בַּחֹ֣דֶשׁ הַ֠שְּׁבִיעִי בֶּֽעָשׂ֨וֹר לַחֹ֜דֶשׁ תְּעַנּ֣וּ אֶת־נַפְשֹֽׁתֵיכֶ֗ם וְכָל־מְלָאכָה֙ לֹ֣א תַֽעֲשׂ֔וּ הָֽאֶזְרָ֔ח וְהַגֵּ֖ר הַגָּ֥ר בְּתֽוֹכְכֶֽם׃"
          },
          {
            "num": "ל",
            "txt": "כִּֽי־בַיּ֥וֹם הַזֶּ֛ה יְכַפֵּ֥ר עֲלֵיכֶ֖ם לְטַהֵ֣ר אֶתְכֶ֑ם מִכֹּל֙ חַטֹּ֣אתֵיכֶ֔ם לִפְנֵ֥י יְהוָ֖ה תִּטְהָֽרוּ׃"
          },
          {
            "num": "לא",
            "txt": "שַׁבַּ֨ת שַׁבָּת֥וֹן הִיא֙ לָכֶ֔ם וְעִנִּיתֶ֖ם אֶת־נַפְשֹֽׁתֵיכֶ֑ם חֻקַּ֖ת עוֹלָֽם׃"
          },
          {
            "num": "לב",
            "txt": "וְכִפֶּ֨ר הַכֹּהֵ֜ן אֲשֶׁר־יִמְשַׁ֣ח אֹת֗וֹ וַֽאֲשֶׁ֤ר יְמַלֵּא֙ אֶת־יָד֔וֹ לְכַהֵ֖ן תַּ֣חַת אָבִ֑יו וְלָבַ֛שׁ אֶת־בִּגְדֵ֥י הַבָּ֖ד בִּגְדֵ֥י הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "לג",
            "txt": "וְכִפֶּר֙ אֶת־מִקְדַּ֣שׁ הַקֹּ֔דֶשׁ וְאֶת־אֹ֧הֶל מוֹעֵ֛ד וְאֶת־הַמִּזְבֵּ֖חַ יְכַפֵּ֑ר וְעַ֧ל הַכֹּֽהֲנִ֛ים וְעַל־כָּל־עַ֥ם הַקָּהָ֖ל יְכַפֵּֽר׃"
          },
          {
            "num": "לד",
            "txt": "וְהָֽיְתָה־זֹּ֨את לָכֶ֜ם לְחֻקַּ֣ת עוֹלָ֗ם לְכַפֵּ֞ר עַל־בְּנֵ֤י יִשְׂרָאֵל֙ מִכָּל־חַטֹּאתָ֔ם אַחַ֖ת בַּשָּׁנָ֑ה וַיַּ֕עַשׂ כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "יז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֨ר אֶֽל־אַהֲרֹ֜ן וְאֶל־בָּנָ֗יו וְאֶל֙ כָּל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵיהֶ֑ם זֶ֣ה הַדָּבָ֔ר אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה לֵאמֹֽר׃"
          },
          {
            "num": "ג",
            "txt": "אִ֥ישׁ אִישׁ֙ מִבֵּ֣ית יִשְׂרָאֵ֔ל אֲשֶׁ֨ר יִשְׁחַ֜ט שׁ֥וֹר אוֹ־כֶ֛שֶׂב אוֹ־עֵ֖ז בַּֽמַּחֲנֶ֑ה א֚וֹ אֲשֶׁ֣ר יִשְׁחַ֔ט מִח֖וּץ לַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "ד",
            "txt": "וְאֶל־פֶּ֜תַח אֹ֣הֶל מוֹעֵד֮ לֹ֣א הֱבִיאוֹ֒ לְהַקְרִ֤יב קָרְבָּן֙ לַֽיהוָ֔ה לִפְנֵ֖י מִשְׁכַּ֣ן יְהוָ֑ה דָּ֣ם יֵֽחָשֵׁ֞ב לָאִ֤ישׁ הַהוּא֙ דָּ֣ם שָׁפָ֔ךְ וְנִכְרַ֛ת הָאִ֥ישׁ הַה֖וּא מִקֶּ֥רֶב עַמּֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "לְמַעַן֩ אֲשֶׁ֨ר יָבִ֜יאוּ בְּנֵ֣י יִשְׂרָאֵ֗ל אֶֽת־זִבְחֵיהֶם֮ אֲשֶׁ֣ר הֵ֣ם זֹֽבְחִים֮ עַל־פְּנֵ֣י הַשָּׂדֶה֒ וֶֽהֱבִיאֻ֣ם לַֽיהוָ֗ה אֶל־פֶּ֛תַח אֹ֥הֶל מוֹעֵ֖ד אֶל־הַכֹּהֵ֑ן וְזָ֨בְח֜וּ זִבְחֵ֧י שְׁלָמִ֛ים לַֽיהוָ֖ה אוֹתָֽם׃"
          },
          {
            "num": "ו",
            "txt": "וְזָרַ֨ק הַכֹּהֵ֤ן אֶת־הַדָּם֙ עַל־מִזְבַּ֣ח יְהוָ֔ה פֶּ֖תַח אֹ֣הֶל מוֹעֵ֑ד וְהִקְטִ֣יר הַחֵ֔לֶב לְרֵ֥יחַ נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וְלֹֽא־יִזְבְּח֥וּ עוֹד֙ אֶת־זִבְחֵיהֶ֔ם לַשְּׂעִירִ֕ם אֲשֶׁ֛ר הֵ֥ם זֹנִ֖ים אַֽחֲרֵיהֶ֑ם חֻקַּ֥ת עוֹלָ֛ם תִּֽהְיֶה־זֹּ֥את לָהֶ֖ם לְדֹֽרֹתָֽם׃"
          },
          {
            "num": "ח",
            "txt": "וַֽאֲלֵהֶ֣ם תֹּאמַ֔ר אִ֥ישׁ אִישׁ֙ מִבֵּ֣ית יִשְׂרָאֵ֔ל וּמִן־הַגֵּ֖ר אֲשֶׁר־יָג֣וּר בְּתוֹכָ֑ם אֲשֶׁר־יַֽעֲלֶ֥ה עֹלָ֖ה אוֹ־זָֽבַח׃"
          },
          {
            "num": "ט",
            "txt": "וְאֶל־פֶּ֜תַח אֹ֤הֶל מוֹעֵד֙ לֹ֣א יְבִיאֶ֔נּוּ לַֽעֲשׂ֥וֹת אֹת֖וֹ לַֽיהוָ֑ה וְנִכְרַ֛ת הָאִ֥ישׁ הַה֖וּא מֵֽעַמָּֽיו׃"
          },
          {
            "num": "י",
            "txt": "וְאִ֨ישׁ אִ֜ישׁ מִבֵּ֣ית יִשְׂרָאֵ֗ל וּמִן־הַגֵּר֙ הַגָּ֣ר בְּתוֹכָ֔ם אֲשֶׁ֥ר יֹאכַ֖ל כָּל־דָּ֑ם וְנָֽתַתִּ֣י פָנַ֗י בַּנֶּ֨פֶשׁ֙ הָֽאֹכֶ֣לֶת אֶת־הַדָּ֔ם וְהִכְרַתִּ֥י אֹתָ֖הּ מִקֶּ֥רֶב עַמָּֽהּ׃"
          },
          {
            "num": "יא",
            "txt": "כִּ֣י נֶ֣פֶשׁ הַבָּשָׂר֮ בַּדָּ֣ם הִוא֒ וַֽאֲנִ֞י נְתַתִּ֤יו לָכֶם֙ עַל־הַמִּזְבֵּ֔חַ לְכַפֵּ֖ר עַל־נַפְשֹֽׁתֵיכֶ֑ם כִּֽי־הַדָּ֥ם ה֖וּא בַּנֶּ֥פֶשׁ יְכַפֵּֽר׃"
          },
          {
            "num": "יב",
            "txt": "עַל־כֵּ֤ן אָמַ֨רְתִּי֙ לִבְנֵ֣י יִשְׂרָאֵ֔ל כָּל־נֶ֥פֶשׁ מִכֶּ֖ם לֹא־תֹ֣אכַל דָּ֑ם וְהַגֵּ֛ר הַגָּ֥ר בְּתֽוֹכְכֶ֖ם לֹא־יֹ֥אכַל דָּֽם׃"
          },
          {
            "num": "יג",
            "txt": "וְאִ֨ישׁ אִ֜ישׁ מִבְּנֵ֣י יִשְׂרָאֵ֗ל וּמִן־הַגֵּר֙ הַגָּ֣ר בְּתוֹכָ֔ם אֲשֶׁ֨ר יָצ֜וּד צֵ֥יד חַיָּ֛ה אוֹ־ע֖וֹף אֲשֶׁ֣ר יֵֽאָכֵ֑ל וְשָׁפַךְ֙ אֶת־דָּמ֔וֹ וְכִסָּ֖הוּ בֶּֽעָפָֽר׃"
          },
          {
            "num": "יד",
            "txt": "כִּֽי־נֶ֣פֶשׁ כָּל־בָּשָׂ֗ר דָּמ֣וֹ בְנַפְשׁוֹ֮ הוּא֒ וָֽאֹמַר֙ לִבְנֵ֣י יִשְׂרָאֵ֔ל דַּ֥ם כָּל־בָּשָׂ֖ר לֹ֣א תֹאכֵ֑לוּ כִּ֣י נֶ֤פֶשׁ כָּל־בָּשָׂר֙ דָּמ֣וֹ הִ֔וא כָּל־אֹֽכְלָ֖יו יִכָּרֵֽת׃"
          },
          {
            "num": "טו",
            "txt": "וְכָל־נֶ֗פֶשׁ אֲשֶׁ֨ר תֹּאכַ֤ל נְבֵלָה֙ וּטְרֵפָ֔ה בָּֽאֶזְרָ֖ח וּבַגֵּ֑ר וְכִבֶּ֨ס בְּגָדָ֜יו וְרָחַ֥ץ בַּמַּ֛יִם וְטָמֵ֥א עַד־הָעֶ֖רֶב וְטָהֵֽר׃"
          },
          {
            "num": "טז",
            "txt": "וְאִם֙ לֹ֣א יְכַבֵּ֔ס וּבְשָׂר֖וֹ לֹ֣א יִרְחָ֑ץ וְנָשָׂ֖א עֲוֺנֽוֹ׃ {פ}"
          }
        ]
      },
      {
        "num": "יח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "כְּמַֽעֲשֵׂ֧ה אֶֽרֶץ־מִצְרַ֛יִם אֲשֶׁ֥ר יְשַׁבְתֶּם־בָּ֖הּ לֹ֣א תַֽעֲשׂ֑וּ וּכְמַֽעֲשֵׂ֣ה אֶֽרֶץ־כְּנַ֡עַן אֲשֶׁ֣ר אֲנִי֩ מֵבִ֨יא אֶתְכֶ֥ם שָׁ֨מָּה֙ לֹ֣א תַֽעֲשׂ֔וּ וּבְחֻקֹּֽתֵיהֶ֖ם לֹ֥א תֵלֵֽכוּ׃"
          },
          {
            "num": "ד",
            "txt": "אֶת־מִשְׁפָּטַ֧י תַּֽעֲשׂ֛וּ וְאֶת־חֻקֹּתַ֥י תִּשְׁמְר֖וּ לָלֶ֣כֶת בָּהֶ֑ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "וּשְׁמַרְתֶּ֤ם אֶת־חֻקֹּתַי֙ וְאֶת־מִשְׁפָּטַ֔י אֲשֶׁ֨ר יַֽעֲשֶׂ֥ה אֹתָ֛ם הָֽאָדָ֖ם וָחַ֣י בָּהֶ֑ם אֲנִ֖י יְהוָֽה׃ {ס}"
          },
          {
            "num": "ו",
            "txt": "אִ֥ישׁ אִישׁ֙ אֶל־כָּל־שְׁאֵ֣ר בְּשָׂר֔וֹ לֹ֥א תִקְרְב֖וּ לְגַלּ֣וֹת עֶרְוָ֑ה אֲנִ֖י יְהוָֽה׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "עֶרְוַ֥ת אָבִ֛יךָ וְעֶרְוַ֥ת אִמְּךָ֖ לֹ֣א תְגַלֵּ֑ה אִמְּךָ֣ הִ֔וא לֹ֥א תְגַלֶּ֖ה עֶרְוָתָֽהּ׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "עֶרְוַ֥ת אֵֽשֶׁת־אָבִ֖יךָ לֹ֣א תְגַלֵּ֑ה עֶרְוַ֥ת אָבִ֖יךָ הִֽוא׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "עֶרְוַ֨ת אֲחֽוֹתְךָ֤ בַת־אָבִ֨יךָ֙ א֣וֹ בַת־אִמֶּ֔ךָ מוֹלֶ֣דֶת בַּ֔יִת א֖וֹ מוֹלֶ֣דֶת ח֑וּץ לֹ֥א תְגַלֶּ֖ה עֶרְוָתָֽן׃ {ס}"
          },
          {
            "num": "י",
            "txt": "עֶרְוַ֤ת בַּת־בִּנְךָ֙ א֣וֹ בַֽת־בִּתְּךָ֔ לֹ֥א תְגַלֶּ֖ה עֶרְוָתָ֑ן כִּ֥י עֶרְוָֽתְךָ֖ הֵֽנָּה׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "עֶרְוַ֨ת בַּת־אֵ֤שֶׁת אָבִ֨יךָ֙ מוֹלֶ֣דֶת אָבִ֔יךָ אֲחֽוֹתְךָ֖ הִ֑וא לֹ֥א תְגַלֶּ֖ה עֶרְוָתָֽהּ׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "עֶרְוַ֥ת אֲחוֹת־אָבִ֖יךָ לֹ֣א תְגַלֵּ֑ה שְׁאֵ֥ר אָבִ֖יךָ הִֽוא׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "עֶרְוַ֥ת אֲחֽוֹת־אִמְּךָ֖ לֹ֣א תְגַלֵּ֑ה כִּֽי־שְׁאֵ֥ר אִמְּךָ֖ הִֽוא׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "עֶרְוַ֥ת אֲחִֽי־אָבִ֖יךָ לֹ֣א תְגַלֵּ֑ה אֶל־אִשְׁתּוֹ֙ לֹ֣א תִקְרָ֔ב דֹּדָֽתְךָ֖ הִֽוא׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "עֶרְוַ֥ת כַּלָּֽתְךָ֖ לֹ֣א תְגַלֵּ֑ה אֵ֤שֶׁת בִּנְךָ֙ הִ֔וא לֹ֥א תְגַלֶּ֖ה עֶרְוָתָֽהּ׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "עֶרְוַ֥ת אֵֽשֶׁת־אָחִ֖יךָ לֹ֣א תְגַלֵּ֑ה עֶרְוַ֥ת אָחִ֖יךָ הִֽוא׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "עֶרְוַ֥ת אִשָּׁ֛ה וּבִתָּ֖הּ לֹ֣א תְגַלֵּ֑ה אֶֽת־בַּת־בְּנָ֞הּ וְאֶת־בַּת־בִּתָּ֗הּ לֹ֤א תִקַּח֙ לְגַלּ֣וֹת עֶרְוָתָ֔הּ שַֽׁאֲרָ֥ה הֵ֖נָּה זִמָּ֥ה הִֽוא׃"
          },
          {
            "num": "יח",
            "txt": "וְאִשָּׁ֥ה אֶל־אֲחֹתָ֖הּ לֹ֣א תִקָּ֑ח לִצְרֹ֗ר לְגַלּ֧וֹת עֶרְוָתָ֛הּ עָלֶ֖יהָ בְּחַיֶּֽיהָ׃"
          },
          {
            "num": "יט",
            "txt": "וְאֶל־אִשָּׁ֖ה בְּנִדַּ֣ת טֻמְאָתָ֑הּ לֹ֣א תִקְרַ֔ב לְגַלּ֖וֹת עֶרְוָתָֽהּ׃"
          },
          {
            "num": "כ",
            "txt": "וְאֶל־אֵ֨שֶׁת֙ עֲמִֽיתְךָ֔ לֹֽא־תִתֵּ֥ן שְׁכָבְתְּךָ֖ לְזָ֑רַע לְטָמְאָה־בָֽהּ׃"
          },
          {
            "num": "כא",
            "txt": "וּמִֽזַּרְעֲךָ֥ לֹֽא־תִתֵּ֖ן לְהַֽעֲבִ֣יר לַמֹּ֑לֶךְ וְלֹ֧א תְחַלֵּ֛ל אֶת־שֵׁ֥ם אֱלֹהֶ֖יךָ אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "כב",
            "txt": "וְאֶ֨ת־זָכָ֔ר לֹ֥א תִשְׁכַּ֖ב מִשְׁכְּבֵ֣י אִשָּׁ֑ה תּֽוֹעֵבָ֖ה הִֽוא׃"
          },
          {
            "num": "כג",
            "txt": "וּבְכָל־בְּהֵמָ֛ה לֹֽא־תִתֵּ֥ן שְׁכָבְתְּךָ֖ לְטָמְאָה־בָ֑הּ וְאִשָּׁ֗ה לֹֽא־תַעֲמֹ֞ד לִפְנֵ֧י בְהֵמָ֛ה לְרִבְעָ֖הּ תֶּ֥בֶל הֽוּא׃"
          },
          {
            "num": "כד",
            "txt": "אַל־תִּֽטַּמְּא֖וּ בְּכָל־אֵ֑לֶּה כִּ֤י בְכָל־אֵ֨לֶּה֙ נִטְמְא֣וּ הַגּוֹיִ֔ם אֲשֶׁר־אֲנִ֥י מְשַׁלֵּ֖חַ מִפְּנֵיכֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַתִּטְמָ֣א הָאָ֔רֶץ וָֽאֶפְקֹ֥ד עֲוֺנָ֖הּ עָלֶ֑יהָ וַתָּקִ֥א הָאָ֖רֶץ אֶת־יֹֽשְׁבֶֽיהָ׃"
          },
          {
            "num": "כו",
            "txt": "וּשְׁמַרְתֶּ֣ם אַתֶּ֗ם אֶת־חֻקֹּתַי֙ וְאֶת־מִשְׁפָּטַ֔י וְלֹ֣א תַֽעֲשׂ֔וּ מִכֹּ֥ל הַתּֽוֹעֵבֹ֖ת הָאֵ֑לֶּה הָֽאֶזְרָ֔ח וְהַגֵּ֖ר הַגָּ֥ר בְּתֽוֹכְכֶֽם׃"
          },
          {
            "num": "כז",
            "txt": "כִּ֚י אֶת־כָּל־הַתּֽוֹעֵבֹ֣ת הָאֵ֔ל עָשׂ֥וּ אַנְשֵֽׁי־הָאָ֖רֶץ אֲשֶׁ֣ר לִפְנֵיכֶ֑ם וַתִּטְמָ֖א הָאָֽרֶץ׃"
          },
          {
            "num": "כח",
            "txt": "וְלֹֽא־תָקִ֤יא הָאָ֨רֶץ֙ אֶתְכֶ֔ם בְּטַֽמַּאֲכֶ֖ם אֹתָ֑הּ כַּֽאֲשֶׁ֥ר קָאָ֛ה אֶת־הַגּ֖וֹי אֲשֶׁ֥ר לִפְנֵיכֶֽם׃"
          },
          {
            "num": "כט",
            "txt": "כִּ֚י כָּל־אֲשֶׁ֣ר יַֽעֲשֶׂ֔ה מִכֹּ֥ל הַתּֽוֹעֵבֹ֖ת הָאֵ֑לֶּה וְנִכְרְת֛וּ הַנְּפָשׁ֥וֹת הָֽעֹשֹׂ֖ת מִקֶּ֥רֶב עַמָּֽם׃"
          },
          {
            "num": "ל",
            "txt": "וּשְׁמַרְתֶּ֣ם אֶת־מִשְׁמַרְתִּ֗י לְבִלְתִּ֨י עֲשׂ֜וֹת מֵֽחֻקּ֤וֹת הַתּֽוֹעֵבֹת֙ אֲשֶׁ֣ר נַֽעֲשׂ֣וּ לִפְנֵיכֶ֔ם וְלֹ֥א תִֽטַּמְּא֖וּ בָּהֶ֑ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "יט",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֞ר אֶל־כָּל־עֲדַ֧ת בְּנֵֽי־יִשְׂרָאֵ֛ל וְאָֽמַרְתָּ֥ אֲלֵהֶ֖ם קְדֹשִׁ֣ים תִּֽהְי֑וּ כִּ֣י קָד֔וֹשׁ אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "אִ֣ישׁ אִמּ֤וֹ וְאָבִיו֙ תִּירָ֔אוּ וְאֶת־שַׁבְּתֹתַ֖י תִּשְׁמֹ֑רוּ אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ד",
            "txt": "אַל־תִּפְנוּ֙ אֶל־הָ֣אֱלִילִ֔ם וֵֽאלֹהֵי֙ מַסֵּכָ֔ה לֹ֥א תַֽעֲשׂ֖וּ לָכֶ֑ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "וְכִ֧י תִזְבְּח֛וּ זֶ֥בַח שְׁלָמִ֖ים לַֽיהוָ֑ה לִֽרְצֹנְכֶ֖ם תִּזְבָּחֻֽהוּ׃"
          },
          {
            "num": "ו",
            "txt": "בְּי֧וֹם זִבְחֲכֶ֛ם יֵֽאָכֵ֖ל וּמִֽמָּחֳרָ֑ת וְהַנּוֹתָר֙ עַד־י֣וֹם הַשְּׁלִישִׁ֔י בָּאֵ֖שׁ יִשָּׂרֵֽף׃"
          },
          {
            "num": "ז",
            "txt": "וְאִ֛ם הֵֽאָכֹ֥ל יֵֽאָכֵ֖ל בַּיּ֣וֹם הַשְּׁלִישִׁ֑י פִּגּ֥וּל ה֖וּא לֹ֥א יֵֽרָצֶֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְאֹֽכְלָיו֙ עֲוֺנ֣וֹ יִשָּׂ֔א כִּֽי־אֶת־קֹ֥דֶשׁ יְהוָ֖ה חִלֵּ֑ל וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מֵֽעַמֶּֽיהָ׃"
          },
          {
            "num": "ט",
            "txt": "וּֽבְקֻצְרְכֶם֙ אֶת־קְצִ֣יר אַרְצְכֶ֔ם לֹ֧א תְכַלֶּ֛ה פְּאַ֥ת שָֽׂדְךָ֖ לִקְצֹ֑ר וְלֶ֥קֶט קְצִֽירְךָ֖ לֹ֥א תְלַקֵּֽט׃"
          },
          {
            "num": "י",
            "txt": "וְכַרְמְךָ֙ לֹ֣א תְעוֹלֵ֔ל וּפֶ֥רֶט כַּרְמְךָ֖ לֹ֣א תְלַקֵּ֑ט לֶֽעָנִ֤י וְלַגֵּר֙ תַּֽעֲזֹ֣ב אֹתָ֔ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "יא",
            "txt": "לֹ֖א תִּגְנֹ֑בוּ וְלֹֽא־תְכַחֲשׁ֥וּ וְלֹֽא־תְשַׁקְּר֖וּ אִ֥ישׁ בַּֽעֲמִיתֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וְלֹֽא־תִשָּׁבְע֥וּ בִשְׁמִ֖י לַשָּׁ֑קֶר וְחִלַּלְתָּ֛ אֶת־שֵׁ֥ם אֱלֹהֶ֖יךָ אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "לֹֽא־תַעֲשֹׁ֥ק אֶת־רֵֽעֲךָ֖ וְלֹ֣א תִגְזֹ֑ל לֹֽא־תָלִ֞ין פְּעֻלַּ֥ת שָׂכִ֛יר אִתְּךָ֖ עַד־בֹּֽקֶר׃"
          },
          {
            "num": "יד",
            "txt": "לֹֽא־תְקַלֵּ֣ל חֵרֵ֔שׁ וְלִפְנֵ֣י עִוֵּ֔ר לֹ֥א תִתֵּ֖ן מִכְשֹׁ֑ל וְיָרֵ֥אתָ מֵּֽאֱלֹהֶ֖יךָ אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "טו",
            "txt": "לֹֽא־תַעֲשׂ֥וּ עָ֨וֶל֙ בַּמִּשְׁפָּ֔ט לֹֽא־תִשָּׂ֣א פְנֵי־דָ֔ל וְלֹ֥א תֶהְדַּ֖ר פְּנֵ֣י גָד֑וֹל בְּצֶ֖דֶק תִּשְׁפֹּ֥ט עֲמִיתֶֽךָ׃"
          },
          {
            "num": "טז",
            "txt": "לֹֽא־תֵלֵ֤ךְ רָכִיל֙ בְּעַמֶּ֔יךָ לֹ֥א תַֽעֲמֹ֖ד עַל־דַּ֣ם רֵעֶ֑ךָ אֲנִ֖י יְהוָֽה׃"
          },
          {
            "num": "יז",
            "txt": "לֹֽא־תִשְׂנָ֥א אֶת־אָחִ֖יךָ בִּלְבָבֶ֑ךָ הוֹכֵ֤חַ תּוֹכִ֨יחַ֙ אֶת־עֲמִיתֶ֔ךָ וְלֹֽא־תִשָּׂ֥א עָלָ֖יו חֵֽטְא׃"
          },
          {
            "num": "יח",
            "txt": "לֹֽא־תִקֹּ֤ם וְלֹֽא־תִטֹּר֙ אֶת־בְּנֵ֣י עַמֶּ֔ךָ וְאָֽהַבְתָּ֥ לְרֵֽעֲךָ֖ כָּמ֑וֹךָ אֲנִ֖י יְהוָֽה׃"
          },
          {
            "num": "יט",
            "txt": "אֶֽת־חֻקֹּתַי֮ תִּשְׁמֹרוּ֒ בְּהֶמְתְּךָ֙ לֹֽא־תַרְבִּ֣יעַ כִּלְאַ֔יִם שָֽׂדְךָ֖ לֹֽא־תִזְרַ֣ע כִּלְאָ֑יִם וּבֶ֤גֶד כִּלְאַ֨יִם֙ שַֽׁעַטְנֵ֔ז לֹ֥א יַֽעֲלֶ֖ה עָלֶֽיךָ׃"
          },
          {
            "num": "כ",
            "txt": "וְ֠אִישׁ כִּֽי־יִשְׁכַּ֨ב אֶת־אִשָּׁ֜ה שִׁכְבַת־זֶ֗רַע וְהִ֤וא שִׁפְחָה֙ נֶֽחֱרֶ֣פֶת לְאִ֔ישׁ וְהָפְדֵּה֙ לֹ֣א נִפְדָּ֔תָה א֥וֹ חֻפְשָׁ֖ה לֹ֣א נִתַּן־לָ֑הּ בִּקֹּ֧רֶת תִּֽהְיֶ֛ה לֹ֥א יֽוּמְת֖וּ כִּי־לֹ֥א חֻפָּֽשָׁה׃"
          },
          {
            "num": "כא",
            "txt": "וְהֵבִ֤יא אֶת־אֲשָׁמוֹ֙ לַֽיהוָ֔ה אֶל־פֶּ֖תַח אֹ֣הֶל מוֹעֵ֑ד אֵ֖יל אָשָֽׁם׃"
          },
          {
            "num": "כב",
            "txt": "וְכִפֶּר֩ עָלָ֨יו הַכֹּהֵ֜ן בְּאֵ֤יל הָֽאָשָׁם֙ לִפְנֵ֣י יְהוָ֔ה עַל־חַטָּאת֖וֹ אֲשֶׁ֣ר חָטָ֑א וְנִסְלַ֣ח ל֔וֹ מֵֽחַטָּאת֖וֹ אֲשֶׁ֥ר חָטָֽא׃ {פ}"
          },
          {
            "num": "כג",
            "txt": "וְכִֽי־תָבֹ֣אוּ אֶל־הָאָ֗רֶץ וּנְטַעְתֶּם֙ כָּל־עֵ֣ץ מַֽאֲכָ֔ל וַֽעֲרַלְתֶּ֥ם עָרְלָת֖וֹ אֶת־פִּרְי֑וֹ שָׁלֹ֣שׁ שָׁנִ֗ים יִֽהְיֶ֥ה לָכֶ֛ם עֲרֵלִ֖ים לֹ֥א יֵֽאָכֵֽל׃"
          },
          {
            "num": "כד",
            "txt": "וּבַשָּׁנָה֙ הָֽרְבִיעִ֔ת יִֽהְיֶ֖ה כָּל־פִּרְי֑וֹ קֹ֥דֶשׁ הִלּוּלִ֖ים לַֽיהוָֽה׃"
          },
          {
            "num": "כה",
            "txt": "וּבַשָּׁנָ֣ה הַֽחֲמִישִׁ֗ת תֹּֽאכְלוּ֙ אֶת־פִּרְי֔וֹ לְהוֹסִ֥יף לָכֶ֖ם תְּבֽוּאָת֑וֹ אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "כו",
            "txt": "לֹ֥א תֹֽאכְל֖וּ עַל־הַדָּ֑ם לֹ֥א תְנַֽחֲשׁ֖וּ וְלֹ֥א תְעוֹנֵֽנוּ׃"
          },
          {
            "num": "כז",
            "txt": "לֹ֣א תַקִּ֔פוּ פְּאַ֖ת רֹֽאשְׁכֶ֑ם וְלֹ֣א תַשְׁחִ֔ית אֵ֖ת פְּאַ֥ת זְקָנֶֽךָ׃"
          },
          {
            "num": "כח",
            "txt": "וְשֶׂ֣רֶט לָנֶ֗פֶשׁ לֹ֤א תִתְּנוּ֙ בִּבְשַׂרְכֶ֔ם וּכְתֹ֣בֶת קַֽעֲקַ֔ע לֹ֥א תִתְּנ֖וּ בָּכֶ֑ם אֲנִ֖י יְהוָֽה׃"
          },
          {
            "num": "כט",
            "txt": "אַל־תְּחַלֵּ֥ל אֶֽת־בִּתְּךָ֖ לְהַזְנוֹתָ֑הּ וְלֹֽא־תִזְנֶ֣ה הָאָ֔רֶץ וּמָֽלְאָ֥ה הָאָ֖רֶץ זִמָּֽה׃"
          },
          {
            "num": "ל",
            "txt": "אֶת־שַׁבְּתֹתַ֣י תִּשְׁמֹ֔רוּ וּמִקְדָּשִׁ֖י תִּירָ֑אוּ אֲנִ֖י יְהוָֽה׃"
          },
          {
            "num": "לא",
            "txt": "אַל־תִּפְנ֤וּ אֶל־הָֽאֹבֹת֙ וְאֶל־הַיִּדְּעֹנִ֔ים אַל־תְּבַקְשׁ֖וּ לְטָמְאָ֣ה בָהֶ֑ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "מִפְּנֵ֤י שֵׂיבָה֙ תָּק֔וּם וְהָֽדַרְתָּ֖ פְּנֵ֣י זָקֵ֑ן וְיָרֵ֥אתָ מֵּֽאֱלֹהֶ֖יךָ אֲנִ֥י יְהוָֽה׃ {ס}"
          },
          {
            "num": "לג",
            "txt": "וְכִֽי־יָג֧וּר אִתְּךָ֛ גֵּ֖ר בְּאַרְצְכֶ֑ם לֹ֥א תוֹנ֖וּ אֹתֽוֹ׃"
          },
          {
            "num": "לד",
            "txt": "כְּאֶזְרָ֣ח מִכֶּם֩ יִֽהְיֶ֨ה לָכֶ֜ם הַגֵּ֣ר ׀ הַגָּ֣ר אִתְּכֶ֗ם וְאָֽהַבְתָּ֥ לוֹ֙ כָּמ֔וֹךָ כִּֽי־גֵרִ֥ים הֱיִיתֶ֖ם בְּאֶ֣רֶץ מִצְרָ֑יִם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "לה",
            "txt": "לֹֽא־תַעֲשׂ֥וּ עָ֖וֶל בַּמִּשְׁפָּ֑ט בַּמִּדָּ֕ה בַּמִּשְׁקָ֖ל וּבַמְּשׂוּרָֽה׃"
          },
          {
            "num": "לו",
            "txt": "מֹ֧אזְנֵי צֶ֣דֶק אַבְנֵי־צֶ֗דֶק אֵ֥יפַת צֶ֛דֶק וְהִ֥ין צֶ֖דֶק יִֽהְיֶ֣ה לָכֶ֑ם אֲנִי֙ יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם אֲשֶׁר־הוֹצֵ֥אתִי אֶתְכֶ֖ם מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "לז",
            "txt": "וּשְׁמַרְתֶּ֤ם אֶת־כָּל־חֻקֹּתַי֙ וְאֶת־כָּל־מִשְׁפָּטַ֔י וַֽעֲשִׂיתֶ֖ם אֹתָ֑ם אֲנִ֖י יְהוָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "כ",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "וְאֶל־בְּנֵ֣י יִשְׂרָאֵל֮ תֹּאמַר֒ אִ֣ישׁ אִישׁ֩ מִבְּנֵ֨י יִשְׂרָאֵ֜ל וּמִן־הַגֵּ֣ר ׀ הַגָּ֣ר בְּיִשְׂרָאֵ֗ל אֲשֶׁ֨ר יִתֵּ֧ן מִזַּרְע֛וֹ לַמֹּ֖לֶךְ מ֣וֹת יוּמָ֑ת עַ֥ם הָאָ֖רֶץ יִרְגְּמֻ֥הוּ בָאָֽבֶן׃"
          },
          {
            "num": "ג",
            "txt": "וַֽאֲנִ֞י אֶתֵּ֤ן אֶת־פָּנַי֙ בָּאִ֣ישׁ הַה֔וּא וְהִכְרַתִּ֥י אֹת֖וֹ מִקֶּ֣רֶב עַמּ֑וֹ כִּ֤י מִזַּרְעוֹ֙ נָתַ֣ן לַמֹּ֔לֶךְ לְמַ֗עַן טַמֵּא֙ אֶת־מִקְדָּשִׁ֔י וּלְחַלֵּ֖ל אֶת־שֵׁ֥ם קָדְשִֽׁי׃"
          },
          {
            "num": "ד",
            "txt": "וְאִ֡ם הַעְלֵ֣ם יַעְלִימֽוּ֩ עַ֨ם הָאָ֜רֶץ אֶת־עֵֽינֵיהֶם֙ מִן־הָאִ֣ישׁ הַה֔וּא בְּתִתּ֥וֹ מִזַּרְע֖וֹ לַמֹּ֑לֶךְ לְבִלְתִּ֖י הָמִ֥ית אֹתֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "וְשַׂמְתִּ֨י אֲנִ֧י אֶת־פָּנַ֛י בָּאִ֥ישׁ הַה֖וּא וּבְמִשְׁפַּחְתּ֑וֹ וְהִכְרַתִּ֨י אֹת֜וֹ וְאֵ֣ת ׀ כָּל־הַזֹּנִ֣ים אַֽחֲרָ֗יו לִזְנ֛וֹת אַֽחֲרֵ֥י הַמֹּ֖לֶךְ מִקֶּ֥רֶב עַמָּֽם׃"
          },
          {
            "num": "ו",
            "txt": "וְהַנֶּ֗פֶשׁ אֲשֶׁ֨ר תִּפְנֶ֤ה אֶל־הָֽאֹבֹת֙ וְאֶל־הַיִּדְּעֹנִ֔ים לִזְנֹ֖ת אַֽחֲרֵיהֶ֑ם וְנָֽתַתִּ֤י אֶת־פָּנַי֙ בַּנֶּ֣פֶשׁ הַהִ֔וא וְהִכְרַתִּ֥י אֹת֖וֹ מִקֶּ֥רֶב עַמּֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וְהִ֨תְקַדִּשְׁתֶּ֔ם וִֽהְיִיתֶ֖ם קְדֹשִׁ֑ים כִּ֛י אֲנִ֥י יְהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ח",
            "txt": "וּשְׁמַרְתֶּם֙ אֶת־חֻקֹּתַ֔י וַֽעֲשִׂיתֶ֖ם אֹתָ֑ם אֲנִ֥י יְהוָ֖ה מְקַדִּשְׁכֶֽם׃"
          },
          {
            "num": "ט",
            "txt": "כִּֽי־אִ֣ישׁ אִ֗ישׁ אֲשֶׁ֨ר יְקַלֵּ֧ל אֶת־אָבִ֛יו וְאֶת־אִמּ֖וֹ מ֣וֹת יוּמָ֑ת אָבִ֧יו וְאִמּ֛וֹ קִלֵּ֖ל דָּמָ֥יו בּֽוֹ׃"
          },
          {
            "num": "י",
            "txt": "וְאִ֗ישׁ אֲשֶׁ֤ר יִנְאַף֙ אֶת־אֵ֣שֶׁת אִ֔ישׁ אֲשֶׁ֥ר יִנְאַ֖ף אֶת־אֵ֣שֶׁת רֵעֵ֑הוּ מֽוֹת־יוּמַ֥ת הַנֹּאֵ֖ף וְהַנֹּאָֽפֶת׃"
          },
          {
            "num": "יא",
            "txt": "וְאִ֗ישׁ אֲשֶׁ֤ר יִשְׁכַּב֙ אֶת־אֵ֣שֶׁת אָבִ֔יו עֶרְוַ֥ת אָבִ֖יו גִּלָּ֑ה מֽוֹת־יוּמְת֥וּ שְׁנֵיהֶ֖ם דְּמֵיהֶ֥ם בָּֽם׃"
          },
          {
            "num": "יב",
            "txt": "וְאִ֗ישׁ אֲשֶׁ֤ר יִשְׁכַּב֙ אֶת־כַּלָּת֔וֹ מ֥וֹת יֽוּמְת֖וּ שְׁנֵיהֶ֑ם תֶּ֥בֶל עָשׂ֖וּ דְּמֵיהֶ֥ם בָּֽם׃"
          },
          {
            "num": "יג",
            "txt": "וְאִ֗ישׁ אֲשֶׁ֨ר יִשְׁכַּ֤ב אֶת־זָכָר֙ מִשְׁכְּבֵ֣י אִשָּׁ֔ה תּֽוֹעֵבָ֥ה עָשׂ֖וּ שְׁנֵיהֶ֑ם מ֥וֹת יוּמָ֖תוּ דְּמֵיהֶ֥ם בָּֽם׃"
          },
          {
            "num": "יד",
            "txt": "וְאִ֗ישׁ אֲשֶׁ֨ר יִקַּ֧ח אֶת־אִשָּׁ֛ה וְאֶת־אִמָּ֖הּ זִמָּ֣ה הִ֑וא בָּאֵ֞שׁ יִשְׂרְפ֤וּ אֹתוֹ֙ וְאֶתְהֶ֔ן וְלֹֽא־תִהְיֶ֥ה זִמָּ֖ה בְּתֽוֹכְכֶֽם׃"
          },
          {
            "num": "טו",
            "txt": "וְאִ֗ישׁ אֲשֶׁ֨ר יִתֵּ֧ן שְׁכָבְתּ֛וֹ בִּבְהֵמָ֖ה מ֣וֹת יוּמָ֑ת וְאֶת־הַבְּהֵמָ֖ה תַּֽהֲרֹֽגוּ׃"
          },
          {
            "num": "טז",
            "txt": "וְאִשָּׁ֗ה אֲשֶׁ֨ר תִּקְרַ֤ב אֶל־כָּל־בְּהֵמָה֙ לְרִבְעָ֣ה אֹתָ֔הּ וְהָֽרַגְתָּ֥ אֶת־הָֽאִשָּׁ֖ה וְאֶת־הַבְּהֵמָ֑ה מ֥וֹת יוּמָ֖תוּ דְּמֵיהֶ֥ם בָּֽם׃"
          },
          {
            "num": "יז",
            "txt": "וְאִ֣ישׁ אֲשֶׁר־יִקַּ֣ח אֶת־אֲחֹת֡וֹ בַּת־אָבִ֣יו א֣וֹ בַת־אִ֠מּוֹ וְרָאָ֨ה אֶת־עֶרְוָתָ֜הּ וְהִֽיא־תִרְאֶ֤ה אֶת־עֶרְוָתוֹ֙ חֶ֣סֶד ה֔וּא וְנִ֨כְרְת֔וּ לְעֵינֵ֖י בְּנֵ֣י עַמָּ֑ם עֶרְוַ֧ת אֲחֹת֛וֹ גִּלָּ֖ה עֲוֺנ֥וֹ יִשָּֽׂא׃"
          },
          {
            "num": "יח",
            "txt": "וְ֠אִישׁ אֲשֶׁר־יִשְׁכַּ֨ב אֶת־אִשָּׁ֜ה דָּוָ֗ה וְגִלָּ֤ה אֶת־עֶרְוָתָהּ֙ אֶת־מְקֹרָ֣הּ הֶֽעֱרָ֔ה וְהִ֕וא גִּלְּתָ֖ה אֶת־מְק֣וֹר דָּמֶ֑יהָ וְנִכְרְת֥וּ שְׁנֵיהֶ֖ם מִקֶּ֥רֶב עַמָּֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְעֶרְוַ֨ת אֲח֧וֹת אִמְּךָ֛ וַֽאֲח֥וֹת אָבִ֖יךָ לֹ֣א תְגַלֵּ֑ה כִּ֧י אֶת־שְׁאֵר֛וֹ הֶֽעֱרָ֖ה עֲוֺנָ֥ם יִשָּֽׂאוּ׃"
          },
          {
            "num": "כ",
            "txt": "וְאִ֗ישׁ אֲשֶׁ֤ר יִשְׁכַּב֙ אֶת־דֹּ֣דָת֔וֹ עֶרְוַ֥ת דֹּד֖וֹ גִּלָּ֑ה חֶטְאָ֥ם יִשָּׂ֖אוּ עֲרִירִ֥ים יָמֻֽתוּ׃"
          },
          {
            "num": "כא",
            "txt": "וְאִ֗ישׁ אֲשֶׁ֥ר יִקַּ֛ח אֶת־אֵ֥שֶׁת אָחִ֖יו נִדָּ֣ה הִ֑וא עֶרְוַ֥ת אָחִ֛יו גִּלָּ֖ה עֲרִירִ֥ים יִֽהְיֽוּ׃"
          },
          {
            "num": "כב",
            "txt": "וּשְׁמַרְתֶּ֤ם אֶת־כָּל־חֻקֹּתַי֙ וְאֶת־כָּל־מִשְׁפָּטַ֔י וַֽעֲשִׂיתֶ֖ם אֹתָ֑ם וְלֹֽא־תָקִ֤יא אֶתְכֶם֙ הָאָ֔רֶץ אֲשֶׁ֨ר אֲנִ֜י מֵבִ֥יא אֶתְכֶ֛ם שָׁ֖מָּה לָשֶׁ֥בֶת בָּֽהּ׃"
          },
          {
            "num": "כג",
            "txt": "וְלֹ֤א תֵֽלְכוּ֙ בְּחֻקֹּ֣ת הַגּ֔וֹי אֲשֶׁר־אֲנִ֥י מְשַׁלֵּ֖חַ מִפְּנֵיכֶ֑ם כִּ֤י אֶת־כָּל־אֵ֨לֶּה֙ עָשׂ֔וּ וָֽאָקֻ֖ץ בָּֽם׃"
          },
          {
            "num": "כד",
            "txt": "וָֽאֹמַ֣ר לָכֶ֗ם אַתֶּם֮ תִּֽירְשׁ֣וּ אֶת־אַדְמָתָם֒ וַֽאֲנִ֞י אֶתְּנֶ֤נָּה לָכֶם֙ לָרֶ֣שֶׁת אֹתָ֔הּ אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָ֑שׁ אֲנִי֙ יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם אֲשֶׁר־הִבְדַּ֥לְתִּי אֶתְכֶ֖ם מִן־הָֽעַמִּֽים׃"
          },
          {
            "num": "כה",
            "txt": "וְהִבְדַּלְתֶּ֞ם בֵּֽין־הַבְּהֵמָ֤ה הַטְּהֹרָה֙ לַטְּמֵאָ֔ה וּבֵין־הָע֥וֹף הַטָּמֵ֖א לַטָּהֹ֑ר וְלֹֽא־תְשַׁקְּצ֨וּ אֶת־נַפְשֹֽׁתֵיכֶ֜ם בַּבְּהֵמָ֣ה וּבָע֗וֹף וּבְכֹל֙ אֲשֶׁ֣ר תִּרְמֹ֣שׂ הָֽאֲדָמָ֔ה אֲשֶׁר־הִבְדַּ֥לְתִּי לָכֶ֖ם לְטַמֵּֽא׃"
          },
          {
            "num": "כו",
            "txt": "וִֽהְיִ֤יתֶם לִי֙ קְדֹשִׁ֔ים כִּ֥י קָד֖וֹשׁ אֲנִ֣י יְהוָ֑ה וָֽאַבְדִּ֥ל אֶתְכֶ֛ם מִן־הָֽעַמִּ֖ים לִֽהְי֥וֹת לִֽי׃"
          },
          {
            "num": "כז",
            "txt": "וְאִ֣ישׁ אֽוֹ־אִשָּׁ֗ה כִּֽי־יִהְיֶ֨ה בָהֶ֥ם א֛וֹב א֥וֹ יִדְּעֹנִ֖י מ֣וֹת יוּמָ֑תוּ בָּאֶ֛בֶן יִרְגְּמ֥וּ אֹתָ֖ם דְּמֵיהֶ֥ם בָּֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "כא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה אֱמֹ֥ר אֶל־הַכֹּֽהֲנִ֖ים בְּנֵ֣י אַֽהֲרֹ֑ן וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם לְנֶ֥פֶשׁ לֹֽא־יִטַּמָּ֖א בְּעַמָּֽיו׃"
          },
          {
            "num": "ב",
            "txt": "כִּ֚י אִם־לִשְׁאֵר֔וֹ הַקָּרֹ֖ב אֵלָ֑יו לְאִמּ֣וֹ וּלְאָבִ֔יו וְלִבְנ֥וֹ וּלְבִתּ֖וֹ וּלְאָחִֽיו׃"
          },
          {
            "num": "ג",
            "txt": "וְלַֽאֲחֹת֤וֹ הַבְּתוּלָה֙ הַקְּרוֹבָ֣ה אֵלָ֔יו אֲשֶׁ֥ר לֹֽא־הָיְתָ֖ה לְאִ֑ישׁ לָ֖הּ יִטַּמָּֽא׃"
          },
          {
            "num": "ד",
            "txt": "לֹ֥א יִטַּמָּ֖א בַּ֣עַל בְּעַמָּ֑יו לְהֵ֖חַלּֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "לֹֽא־יקרחה (יִקְרְח֤וּ) קָרְחָה֙ בְּרֹאשָׁ֔ם וּפְאַ֥ת זְקָנָ֖ם לֹ֣א יְגַלֵּ֑חוּ וּבִ֨בְשָׂרָ֔ם לֹ֥א יִשְׂרְט֖וּ שָׂרָֽטֶת׃"
          },
          {
            "num": "ו",
            "txt": "קְדֹשִׁ֤ים יִֽהְיוּ֙ לֵאלֹ֣הֵיהֶ֔ם וְלֹ֣א יְחַלְּל֔וּ שֵׁ֖ם אֱלֹֽהֵיהֶ֑ם כִּי֩ אֶת־אִשֵּׁ֨י יְהוָ֜ה לֶ֧חֶם אֱלֹֽהֵיהֶ֛ם הֵ֥ם מַקְרִיבִ֖ם וְהָ֥יוּ קֹֽדֶשׁ׃"
          },
          {
            "num": "ז",
            "txt": "אִשָּׁ֨ה זֹנָ֤ה וַֽחֲלָלָה֙ לֹ֣א יִקָּ֔חוּ וְאִשָּׁ֛ה גְּרוּשָׁ֥ה מֵֽאִישָׁ֖הּ לֹ֣א יִקָּ֑חוּ כִּֽי־קָדֹ֥שׁ ה֖וּא לֵֽאלֹהָֽיו׃"
          },
          {
            "num": "ח",
            "txt": "וְקִ֨דַּשְׁתּ֔וֹ כִּֽי־אֶת־לֶ֥חֶם אֱלֹהֶ֖יךָ ה֣וּא מַקְרִ֑יב קָדֹשׁ֙ יִֽהְיֶה־לָּ֔ךְ כִּ֣י קָד֔וֹשׁ אֲנִ֥י יְהוָ֖ה מְקַדִּשְׁכֶֽם׃"
          },
          {
            "num": "ט",
            "txt": "וּבַת֙ אִ֣ישׁ כֹּהֵ֔ן כִּ֥י תֵחֵ֖ל לִזְנ֑וֹת אֶת־אָבִ֨יהָ֙ הִ֣יא מְחַלֶּ֔לֶת בָּאֵ֖שׁ תִּשָּׂרֵֽף׃ {ס}"
          },
          {
            "num": "י",
            "txt": "וְהַכֹּהֵן֩ הַגָּד֨וֹל מֵֽאֶחָ֜יו אֲֽשֶׁר־יוּצַ֥ק עַל־רֹאשׁ֣וֹ ׀ שֶׁ֤מֶן הַמִּשְׁחָה֙ וּמִלֵּ֣א אֶת־יָד֔וֹ לִלְבֹּ֖שׁ אֶת־הַבְּגָדִ֑ים אֶת־רֹאשׁוֹ֙ לֹ֣א יִפְרָ֔ע וּבְגָדָ֖יו לֹ֥א יִפְרֹֽם׃"
          },
          {
            "num": "יא",
            "txt": "וְעַ֛ל כָּל־נַפְשֹׁ֥ת מֵ֖ת לֹ֣א יָבֹ֑א לְאָבִ֥יו וּלְאִמּ֖וֹ לֹ֥א יִטַּמָּֽא׃"
          },
          {
            "num": "יב",
            "txt": "וּמִן־הַמִּקְדָּשׁ֙ לֹ֣א יֵצֵ֔א וְלֹ֣א יְחַלֵּ֔ל אֵ֖ת מִקְדַּ֣שׁ אֱלֹהָ֑יו כִּ֡י נֵ֠זֶר שֶׁ֣מֶן מִשְׁחַ֧ת אֱלֹהָ֛יו עָלָ֖יו אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְה֕וּא אִשָּׁ֥ה בִבְתוּלֶ֖יהָ יִקָּֽח׃"
          },
          {
            "num": "יד",
            "txt": "אַלְמָנָ֤ה וּגְרוּשָׁה֙ וַֽחֲלָלָ֣ה זֹנָ֔ה אֶת־אֵ֖לֶּה לֹ֣א יִקָּ֑ח כִּ֛י אִם־בְּתוּלָ֥ה מֵֽעַמָּ֖יו יִקַּ֥ח אִשָּֽׁה׃"
          },
          {
            "num": "טו",
            "txt": "וְלֹֽא־יְחַלֵּ֥ל זַרְע֖וֹ בְּעַמָּ֑יו כִּ֛י אֲנִ֥י יְהוָ֖ה מְקַדְּשֽׁוֹ׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "דַּבֵּ֥ר אֶֽל־אַהֲרֹ֖ן לֵאמֹ֑ר אִ֣ישׁ מִֽזַּרְעֲךָ֞ לְדֹֽרֹתָ֗ם אֲשֶׁ֨ר יִֽהְיֶ֥ה בוֹ֙ מ֔וּם לֹ֣א יִקְרַ֔ב לְהַקְרִ֖יב לֶ֥חֶם אֱלֹהָֽיו׃"
          },
          {
            "num": "יח",
            "txt": "כִּ֥י כָל־אִ֛ישׁ אֲשֶׁר־בּ֥וֹ מ֖וּם לֹ֣א יִקְרָ֑ב אִ֤ישׁ עִוֵּר֙ א֣וֹ פִסֵּ֔חַ א֥וֹ חָרֻ֖ם א֥וֹ שָׂרֽוּעַ׃"
          },
          {
            "num": "יט",
            "txt": "א֣וֹ אִ֔ישׁ אֲשֶׁר־יִֽהְיֶ֥ה ב֖וֹ שֶׁ֣בֶר רָ֑גֶל א֖וֹ שֶׁ֥בֶר יָֽד׃"
          },
          {
            "num": "כ",
            "txt": "אֽוֹ־גִבֵּ֣ן אוֹ־דַ֔ק א֖וֹ תְּבַלֻּ֣ל בְּעֵינ֑וֹ א֤וֹ גָרָב֙ א֣וֹ יַלֶּ֔פֶת א֖וֹ מְר֥וֹחַ אָֽשֶׁךְ׃"
          },
          {
            "num": "כא",
            "txt": "כָּל־אִ֞ישׁ אֲשֶׁר־בּ֣וֹ מ֗וּם מִזֶּ֨רַע֙ אַֽהֲרֹ֣ן הַכֹּהֵ֔ן לֹ֣א יִגַּ֔שׁ לְהַקְרִ֖יב אֶת־אִשֵּׁ֣י יְהוָ֑ה מ֣וּם בּ֔וֹ אֵ֚ת לֶ֣חֶם אֱלֹהָ֔יו לֹ֥א יִגַּ֖שׁ לְהַקְרִֽיב׃"
          },
          {
            "num": "כב",
            "txt": "לֶ֣חֶם אֱלֹהָ֔יו מִקָּדְשֵׁ֖י הַקֳּדָשִׁ֑ים וּמִן־הַקֳּדָשִׁ֖ים יֹאכֵֽל׃"
          },
          {
            "num": "כג",
            "txt": "אַ֣ךְ אֶל־הַפָּרֹ֜כֶת לֹ֣א יָבֹ֗א וְאֶל־הַמִּזְבֵּ֛חַ לֹ֥א יִגַּ֖שׁ כִּֽי־מ֣וּם בּ֑וֹ וְלֹ֤א יְחַלֵּל֙ אֶת־מִקְדָּשַׁ֔י כִּ֛י אֲנִ֥י יְהוָ֖ה מְקַדְּשָֽׁם׃"
          },
          {
            "num": "כד",
            "txt": "וַיְדַבֵּ֣ר מֹשֶׁ֔ה אֶֽל־אַהֲרֹ֖ן וְאֶל־בָּנָ֑יו וְאֶֽל־כָּל־בְּנֵ֖י יִשְׂרָאֵֽל׃ {פ}"
          }
        ]
      },
      {
        "num": "כב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֨ר אֶֽל־אַהֲרֹ֜ן וְאֶל־בָּנָ֗יו וְיִנָּֽזְרוּ֙ מִקָּדְשֵׁ֣י בְנֵֽי־יִשְׂרָאֵ֔ל וְלֹ֥א יְחַלְּל֖וּ אֶת־שֵׁ֣ם קָדְשִׁ֑י אֲשֶׁ֨ר הֵ֧ם מַקְדִּשִׁ֛ים לִ֖י אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "אֱמֹ֣ר אֲלֵהֶ֗ם לְדֹרֹ֨תֵיכֶ֜ם כָּל־אִ֣ישׁ ׀ אֲשֶׁר־יִקְרַ֣ב מִכָּל־זַרְעֲכֶ֗ם אֶל־הַקֳּדָשִׁים֙ אֲשֶׁ֨ר יַקְדִּ֤ישׁוּ בְנֵֽי־יִשְׂרָאֵל֙ לַֽיהוָ֔ה וְטֻמְאָת֖וֹ עָלָ֑יו וְנִכְרְתָ֞ה הַנֶּ֧פֶשׁ הַהִ֛וא מִלְּפָנַ֖י אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "ד",
            "txt": "אִ֣ישׁ אִ֞ישׁ מִזֶּ֣רַע אַֽהֲרֹ֗ן וְה֤וּא צָר֨וּעַ֙ א֣וֹ זָ֔ב בַּקֳּדָשִׁים֙ לֹ֣א יֹאכַ֔ל עַ֖ד אֲשֶׁ֣ר יִטְהָ֑ר וְהַנֹּגֵ֨עַ֙ בְּכָל־טְמֵא־נֶ֔פֶשׁ א֣וֹ אִ֔ישׁ אֲשֶׁר־תֵּצֵ֥א מִמֶּ֖נּוּ שִׁכְבַת־זָֽרַע׃"
          },
          {
            "num": "ה",
            "txt": "אוֹ־אִישׁ֙ אֲשֶׁ֣ר יִגַּ֔ע בְּכָל־שֶׁ֖רֶץ אֲשֶׁ֣ר יִטְמָא־ל֑וֹ א֤וֹ בְאָדָם֙ אֲשֶׁ֣ר יִטְמָא־ל֔וֹ לְכֹ֖ל טֻמְאָתֽוֹ׃"
          },
          {
            "num": "ו",
            "txt": "נֶ֚פֶשׁ אֲשֶׁ֣ר תִּגַּע־בּ֔וֹ וְטָֽמְאָ֖ה עַד־הָעָ֑רֶב וְלֹ֤א יֹאכַל֙ מִן־הַקֳּדָשִׁ֔ים כִּ֛י אִם־רָחַ֥ץ בְּשָׂר֖וֹ בַּמָּֽיִם׃"
          },
          {
            "num": "ז",
            "txt": "וּבָ֥א הַשֶּׁ֖מֶשׁ וְטָהֵ֑ר וְאַחַר֙ יֹאכַ֣ל מִן־הַקֳּדָשִׁ֔ים כִּ֥י לַחְמ֖וֹ הֽוּא׃"
          },
          {
            "num": "ח",
            "txt": "נְבֵלָ֧ה וּטְרֵפָ֛ה לֹ֥א יֹאכַ֖ל לְטָמְאָה־בָ֑הּ אֲנִ֖י יְהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וְשָֽׁמְר֣וּ אֶת־מִשְׁמַרְתִּ֗י וְלֹֽא־יִשְׂא֤וּ עָלָיו֙ חֵ֔טְא וּמֵ֥תוּ ב֖וֹ כִּ֣י יְחַלְּלֻ֑הוּ אֲנִ֥י יְהוָ֖ה מְקַדְּשָֽׁם׃"
          },
          {
            "num": "י",
            "txt": "וְכָל־זָ֖ר לֹא־יֹ֣אכַל קֹ֑דֶשׁ תּוֹשַׁ֥ב כֹּהֵ֛ן וְשָׂכִ֖יר לֹא־יֹ֥אכַל קֹֽדֶשׁ׃"
          },
          {
            "num": "יא",
            "txt": "וְכֹהֵ֗ן כִּֽי־יִקְנֶ֥ה נֶ֨פֶשׁ֙ קִנְיַ֣ן כַּסְפּ֔וֹ ה֖וּא יֹ֣אכַל בּ֑וֹ וִילִ֣יד בֵּית֔וֹ הֵ֖ם יֹֽאכְל֥וּ בְלַחְמֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וּבַ֨ת־כֹּהֵ֔ן כִּ֥י תִֽהְיֶ֖ה לְאִ֣ישׁ זָ֑ר הִ֕וא בִּתְרוּמַ֥ת הַקֳּדָשִׁ֖ים לֹ֥א תֹאכֵֽל׃"
          },
          {
            "num": "יג",
            "txt": "וּבַת־כֹּהֵן֩ כִּ֨י תִֽהְיֶ֜ה אַלְמָנָ֣ה וּגְרוּשָׁ֗ה וְזֶרַע֮ אֵ֣ין לָהּ֒ וְשָׁבָ֞ה אֶל־בֵּ֤ית אָבִ֨יהָ֙ כִּנְעוּרֶ֔יהָ מִלֶּ֥חֶם אָבִ֖יהָ תֹּאכֵ֑ל וְכָל־זָ֖ר לֹא־יֹ֥אכַל בּֽוֹ׃"
          },
          {
            "num": "יד",
            "txt": "וְאִ֕ישׁ כִּֽי־יֹאכַ֥ל קֹ֖דֶשׁ בִּשְׁגָגָ֑ה וְיָסַ֤ף חֲמִֽשִׁיתוֹ֙ עָלָ֔יו וְנָתַ֥ן לַכֹּהֵ֖ן אֶת־הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "טו",
            "txt": "וְלֹ֣א יְחַלְּל֔וּ אֶת־קָדְשֵׁ֖י בְּנֵ֣י יִשְׂרָאֵ֑ל אֵ֥ת אֲשֶׁר־יָרִ֖ימוּ לַֽיהוָֽה׃"
          },
          {
            "num": "טז",
            "txt": "וְהִשִּׂ֤יאוּ אוֹתָם֙ עֲוֺ֣ן אַשְׁמָ֔ה בְּאָכְלָ֖ם אֶת־קָדְשֵׁיהֶ֑ם כִּ֛י אֲנִ֥י יְהוָ֖ה מְקַדְּשָֽׁם׃ {פ}"
          },
          {
            "num": "יז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יח",
            "txt": "דַּבֵּ֨ר אֶֽל־אַהֲרֹ֜ן וְאֶל־בָּנָ֗יו וְאֶל֙ כָּל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם אִ֣ישׁ אִישׁ֩ מִבֵּ֨ית יִשְׂרָאֵ֜ל וּמִן־הַגֵּ֣ר בְּיִשְׂרָאֵ֗ל אֲשֶׁ֨ר יַקְרִ֤יב קָרְבָּנוֹ֙ לְכָל־נִדְרֵיהֶם֙ וּלְכָל־נִדְבוֹתָ֔ם אֲשֶׁר־יַקְרִ֥יבוּ לַֽיהוָ֖ה לְעֹלָֽה׃"
          },
          {
            "num": "יט",
            "txt": "לִֽרְצֹנְכֶ֑ם תָּמִ֣ים זָכָ֔ר בַּבָּקָ֕ר בַּכְּשָׂבִ֖ים וּבָֽעִזִּֽים׃"
          },
          {
            "num": "כ",
            "txt": "כֹּ֛ל אֲשֶׁר־בּ֥וֹ מ֖וּם לֹ֣א תַקְרִ֑יבוּ כִּי־לֹ֥א לְרָצ֖וֹן יִֽהְיֶ֥ה לָכֶֽם׃"
          },
          {
            "num": "כא",
            "txt": "וְאִ֗ישׁ כִּֽי־יַקְרִ֤יב זֶֽבַח־שְׁלָמִים֙ לַֽיהוָ֔ה לְפַלֵּא־נֶ֨דֶר֙ א֣וֹ לִנְדָבָ֔ה בַּבָּקָ֖ר א֣וֹ בַצֹּ֑אן תָּמִ֤ים יִֽהְיֶה֙ לְרָצ֔וֹן כָּל־מ֖וּם לֹ֥א יִֽהְיֶה־בּֽוֹ׃"
          },
          {
            "num": "כב",
            "txt": "עַוֶּרֶת֩ א֨וֹ שָׁב֜וּר אֽוֹ־חָר֣וּץ אֽוֹ־יַבֶּ֗לֶת א֤וֹ גָרָב֙ א֣וֹ יַלֶּ֔פֶת לֹֽא־תַקְרִ֥יבוּ אֵ֖לֶּה לַֽיהוָ֑ה וְאִשֶּׁ֗ה לֹֽא־תִתְּנ֥וּ מֵהֶ֛ם עַל־הַמִּזְבֵּ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וְשׁ֥וֹר וָשֶׂ֖ה שָׂר֣וּעַ וְקָל֑וּט נְדָבָה֙ תַּֽעֲשֶׂ֣ה אֹת֔וֹ וּלְנֵ֖דֶר לֹ֥א יֵֽרָצֶֽה׃"
          },
          {
            "num": "כד",
            "txt": "וּמָע֤וּךְ וְכָתוּת֙ וְנָת֣וּק וְכָר֔וּת לֹ֥א תַקְרִ֖יבוּ לַֽיהוָ֑ה וּֽבְאַרְצְכֶ֖ם לֹ֥א תַֽעֲשֽׂוּ׃"
          },
          {
            "num": "כה",
            "txt": "וּמִיַּ֣ד בֶּן־נֵכָ֗ר לֹ֥א תַקְרִ֛יבוּ אֶת־לֶ֥חֶם אֱלֹֽהֵיכֶ֖ם מִכָּל־אֵ֑לֶּה כִּ֣י מָשְׁחָתָ֤ם בָּהֶם֙ מ֣וּם בָּ֔ם לֹ֥א יֵֽרָצ֖וּ לָכֶֽם׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כז",
            "txt": "שׁ֣וֹר אוֹ־כֶ֤שֶׂב אוֹ־עֵז֙ כִּ֣י יִוָּלֵ֔ד וְהָיָ֛ה שִׁבְעַ֥ת יָמִ֖ים תַּ֣חַת אִמּ֑וֹ וּמִיּ֤וֹם הַשְּׁמִינִי֙ וָהָ֔לְאָה יֵֽרָצֶ֕ה לְקָרְבַּ֥ן אִשֶּׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וְשׁ֖וֹר אוֹ־שֶׂ֑ה אֹת֣וֹ וְאֶת־בְּנ֔וֹ לֹ֥א תִשְׁחֲט֖וּ בְּי֥וֹם אֶחָֽד׃"
          },
          {
            "num": "כט",
            "txt": "וְכִֽי־תִזְבְּח֥וּ זֶֽבַח־תּוֹדָ֖ה לַֽיהוָ֑ה לִֽרְצֹנְכֶ֖ם תִּזְבָּֽחוּ׃"
          },
          {
            "num": "ל",
            "txt": "בַּיּ֤וֹם הַהוּא֙ יֵֽאָכֵ֔ל לֹֽא־תוֹתִ֥ירוּ מִמֶּ֖נּוּ עַד־בֹּ֑קֶר אֲנִ֖י יְהוָֽה׃"
          },
          {
            "num": "לא",
            "txt": "וּשְׁמַרְתֶּם֙ מִצְוֺתַ֔י וַֽעֲשִׂיתֶ֖ם אֹתָ֑ם אֲנִ֖י יְהוָֽה׃"
          },
          {
            "num": "לב",
            "txt": "וְלֹ֤א תְחַלְּלוּ֙ אֶת־שֵׁ֣ם קָדְשִׁ֔י וְנִ֨קְדַּשְׁתִּ֔י בְּת֖וֹךְ בְּנֵ֣י יִשְׂרָאֵ֑ל אֲנִ֥י יְהוָ֖ה מְקַדִּשְׁכֶֽם׃"
          },
          {
            "num": "לג",
            "txt": "הַמּוֹצִ֤יא אֶתְכֶם֙ מֵאֶ֣רֶץ מִצְרַ֔יִם לִֽהְי֥וֹת לָכֶ֖ם לֵֽאלֹהִ֑ים אֲנִ֖י יְהוָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "כג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֞ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם מֽוֹעֲדֵ֣י יְהוָ֔ה אֲשֶׁר־תִּקְרְא֥וּ אֹתָ֖ם מִקְרָאֵ֣י קֹ֑דֶשׁ אֵ֥לֶּה הֵ֖ם מֽוֹעֲדָֽי׃"
          },
          {
            "num": "ג",
            "txt": "שֵׁ֣שֶׁת יָמִים֮ תֵּֽעָשֶׂ֣ה מְלָאכָה֒ וּבַיּ֣וֹם הַשְּׁבִיעִ֗י שַׁבַּ֤ת שַׁבָּתוֹן֙ מִקְרָא־קֹ֔דֶשׁ כָּל־מְלָאכָ֖ה לֹ֣א תַֽעֲשׂ֑וּ שַׁבָּ֥ת הִוא֙ לַֽיהוָ֔ה בְּכֹ֖ל מוֹשְׁבֹֽתֵיכֶֽם׃ {פ}"
          },
          {
            "num": "ד",
            "txt": "אֵ֚לֶּה מֽוֹעֲדֵ֣י יְהוָ֔ה מִקְרָאֵ֖י קֹ֑דֶשׁ אֲשֶׁר־תִּקְרְא֥וּ אֹתָ֖ם בְּמֽוֹעֲדָֽם׃"
          },
          {
            "num": "ה",
            "txt": "בַּחֹ֣דֶשׁ הָֽרִאשׁ֗וֹן בְּאַרְבָּעָ֥ה עָשָׂ֛ר לַחֹ֖דֶשׁ בֵּ֣ין הָֽעַרְבָּ֑יִם פֶּ֖סַח לַֽיהוָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וּבַֽחֲמִשָּׁ֨ה עָשָׂ֥ר יוֹם֙ לַחֹ֣דֶשׁ הַזֶּ֔ה חַ֥ג הַמַּצּ֖וֹת לַֽיהוָ֑ה שִׁבְעַ֥ת יָמִ֖ים מַצּ֥וֹת תֹּאכֵֽלוּ׃"
          },
          {
            "num": "ז",
            "txt": "בַּיּוֹם֙ הָֽרִאשׁ֔וֹן מִקְרָא־קֹ֖דֶשׁ יִֽהְיֶ֣ה לָכֶ֑ם כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃"
          },
          {
            "num": "ח",
            "txt": "וְהִקְרַבְתֶּ֥ם אִשֶּׁ֛ה לַֽיהוָ֖ה שִׁבְעַ֣ת יָמִ֑ים בַּיּ֤וֹם הַשְּׁבִיעִי֙ מִקְרָא־קֹ֔דֶשׁ כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "י",
            "txt": "דַּבֵּ֞ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם כִּֽי־תָבֹ֣אוּ אֶל־הָאָ֗רֶץ אֲשֶׁ֤ר אֲנִי֙ נֹתֵ֣ן לָכֶ֔ם וּקְצַרְתֶּ֖ם אֶת־קְצִירָ֑הּ וַֽהֲבֵאתֶ֥ם אֶת־עֹ֛מֶר רֵאשִׁ֥ית קְצִֽירְכֶ֖ם אֶל־הַכֹּהֵֽן׃"
          },
          {
            "num": "יא",
            "txt": "וְהֵנִ֧יף אֶת־הָעֹ֛מֶר לִפְנֵ֥י יְהוָ֖ה לִֽרְצֹנְכֶ֑ם מִֽמָּחֳרַת֙ הַשַּׁבָּ֔ת יְנִיפֶ֖נּוּ הַכֹּהֵֽן׃"
          },
          {
            "num": "יב",
            "txt": "וַֽעֲשִׂיתֶ֕ם בְּי֥וֹם הֲנִֽיפְכֶ֖ם אֶת־הָעֹ֑מֶר כֶּ֣בֶשׂ תָּמִ֧ים בֶּן־שְׁנָת֛וֹ לְעֹלָ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וּמִנְחָתוֹ֩ שְׁנֵ֨י עֶשְׂרֹנִ֜ים סֹ֣לֶת בְּלוּלָ֥ה בַשֶּׁ֛מֶן אִשֶּׁ֥ה לַֽיהוָ֖ה רֵ֣יחַ נִיחֹ֑חַ וְנִסְכֹּ֥ה יַ֖יִן רְבִיעִ֥ת הַהִֽין׃"
          },
          {
            "num": "יד",
            "txt": "וְלֶחֶם֩ וְקָלִ֨י וְכַרְמֶ֜ל לֹ֣א תֹֽאכְל֗וּ עַד־עֶ֨צֶם֙ הַיּ֣וֹם הַזֶּ֔ה עַ֚ד הֲבִ֣יאֲכֶ֔ם אֶת־קָרְבַּ֖ן אֱלֹֽהֵיכֶ֑ם חֻקַּ֤ת עוֹלָם֙ לְדֹרֹ֣תֵיכֶ֔ם בְּכֹ֖ל מֹשְׁבֹֽתֵיכֶֽם׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וּסְפַרְתֶּ֤ם לָכֶם֙ מִמָּֽחֳרַ֣ת הַשַּׁבָּ֔ת מִיּוֹם֙ הֲבִ֣יאֲכֶ֔ם אֶת־עֹ֖מֶר הַתְּנוּפָ֑ה שֶׁ֥בַע שַׁבָּת֖וֹת תְּמִימֹ֥ת תִּֽהְיֶֽינָה׃"
          },
          {
            "num": "טז",
            "txt": "עַ֣ד מִֽמָּחֳרַ֤ת הַשַּׁבָּת֙ הַשְּׁבִיעִ֔ת תִּסְפְּר֖וּ חֲמִשִּׁ֣ים י֑וֹם וְהִקְרַבְתֶּ֛ם מִנְחָ֥ה חֲדָשָׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "יז",
            "txt": "מִמּוֹשְׁבֹ֨תֵיכֶ֜ם תָּבִ֣יאּוּ ׀ לֶ֣חֶם תְּנוּפָ֗ה שְׁ֚תַּיִם שְׁנֵ֣י עֶשְׂרֹנִ֔ים סֹ֣לֶת תִּֽהְיֶ֔ינָה חָמֵ֖ץ תֵּֽאָפֶ֑ינָה בִּכּוּרִ֖ים לַֽיהוָֽה׃"
          },
          {
            "num": "יח",
            "txt": "וְהִקְרַבְתֶּ֣ם עַל־הַלֶּ֗חֶם שִׁבְעַ֨ת כְּבָשִׂ֤ים תְּמִימִם֙ בְּנֵ֣י שָׁנָ֔ה וּפַ֧ר בֶּן־בָּקָ֛ר אֶחָ֖ד וְאֵילִ֣ם שְׁנָ֑יִם יִֽהְי֤וּ עֹלָה֙ לַֽיהוָ֔ה וּמִנְחָתָם֙ וְנִסְכֵּיהֶ֔ם אִשֵּׁ֥ה רֵֽיחַ־נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "יט",
            "txt": "וַֽעֲשִׂיתֶ֛ם שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּ֑את וּשְׁנֵ֧י כְבָשִׂ֛ים בְּנֵ֥י שָׁנָ֖ה לְזֶ֥בַח שְׁלָמִֽים׃"
          },
          {
            "num": "כ",
            "txt": "וְהֵנִ֣יף הַכֹּהֵ֣ן ׀ אֹתָ֡ם עַל֩ לֶ֨חֶם הַבִּכֻּרִ֤ים תְּנוּפָה֙ לִפְנֵ֣י יְהוָ֔ה עַל־שְׁנֵ֖י כְּבָשִׂ֑ים קֹ֛דֶשׁ יִֽהְי֥וּ לַֽיהוָ֖ה לַכֹּהֵֽן׃"
          },
          {
            "num": "כא",
            "txt": "וּקְרָאתֶ֞ם בְּעֶ֣צֶם ׀ הַיּ֣וֹם הַזֶּ֗ה מִֽקְרָא־קֹ֨דֶשׁ֙ יִֽהְיֶ֣ה לָכֶ֔ם כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֣א תַֽעֲשׂ֑וּ חֻקַּ֥ת עוֹלָ֛ם בְּכָל־מוֹשְׁבֹ֥תֵיכֶ֖ם לְדֹרֹֽתֵיכֶֽם׃"
          },
          {
            "num": "כב",
            "txt": "וּֽבְקֻצְרְכֶ֞ם אֶת־קְצִ֣יר אַרְצְכֶ֗ם לֹֽא־תְכַלֶּ֞ה פְּאַ֤ת שָֽׂדְךָ֙ בְּקֻצְרֶ֔ךָ וְלֶ֥קֶט קְצִֽירְךָ֖ לֹ֣א תְלַקֵּ֑ט לֶֽעָנִ֤י וְלַגֵּר֙ תַּֽעֲזֹ֣ב אֹתָ֔ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃ {פ}"
          },
          {
            "num": "כג",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כד",
            "txt": "דַּבֵּ֛ר אֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר בַּחֹ֨דֶשׁ הַשְּׁבִיעִ֜י בְּאֶחָ֣ד לַחֹ֗דֶשׁ יִֽהְיֶ֤ה לָכֶם֙ שַׁבָּת֔וֹן זִכְר֥וֹן תְּרוּעָ֖ה מִקְרָא־קֹֽדֶשׁ׃"
          },
          {
            "num": "כה",
            "txt": "כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֣א תַֽעֲשׂ֑וּ וְהִקְרַבְתֶּ֥ם אִשֶּׁ֖ה לַֽיהוָֽה׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כז",
            "txt": "אַ֡ךְ בֶּֽעָשׂ֣וֹר לַחֹדֶשׁ֩ הַשְּׁבִיעִ֨י הַזֶּ֜ה י֧וֹם הַכִּפֻּרִ֣ים ה֗וּא מִֽקְרָא־קֹ֨דֶשׁ֙ יִֽהְיֶ֣ה לָכֶ֔ם וְעִנִּיתֶ֖ם אֶת־נַפְשֹֽׁתֵיכֶ֑ם וְהִקְרַבְתֶּ֥ם אִשֶּׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וְכָל־מְלָאכָה֙ לֹ֣א תַֽעֲשׂ֔וּ בְּעֶ֖צֶם הַיּ֣וֹם הַזֶּ֑ה כִּ֣י י֤וֹם כִּפֻּרִים֙ ה֔וּא לְכַפֵּ֣ר עֲלֵיכֶ֔ם לִפְנֵ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "כט",
            "txt": "כִּ֤י כָל־הַנֶּ֨פֶשׁ֙ אֲשֶׁ֣ר לֹֽא־תְעֻנֶּ֔ה בְּעֶ֖צֶם הַיּ֣וֹם הַזֶּ֑ה וְנִכְרְתָ֖ה מֵֽעַמֶּֽיהָ׃"
          },
          {
            "num": "ל",
            "txt": "וְכָל־הַנֶּ֗פֶשׁ אֲשֶׁ֤ר תַּֽעֲשֶׂה֙ כָּל־מְלָאכָ֔ה בְּעֶ֖צֶם הַיּ֣וֹם הַזֶּ֑ה וְהַֽאֲבַדְתִּ֛י אֶת־הַנֶּ֥פֶשׁ הַהִ֖וא מִקֶּ֥רֶב עַמָּֽהּ׃"
          },
          {
            "num": "לא",
            "txt": "כָּל־מְלָאכָ֖ה לֹ֣א תַֽעֲשׂ֑וּ חֻקַּ֤ת עוֹלָם֙ לְדֹרֹ֣תֵיכֶ֔ם בְּכֹ֖ל מֹשְׁבֹֽתֵיכֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "שַׁבַּ֨ת שַׁבָּת֥וֹן הוּא֙ לָכֶ֔ם וְעִנִּיתֶ֖ם אֶת־נַפְשֹֽׁתֵיכֶ֑ם בְּתִשְׁעָ֤ה לַחֹ֨דֶשׁ֙ בָּעֶ֔רֶב מֵעֶ֣רֶב עַד־עֶ֔רֶב תִּשְׁבְּת֖וּ שַׁבַּתְּכֶֽם׃ {פ}"
          },
          {
            "num": "לג",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "לד",
            "txt": "דַּבֵּ֛ר אֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר בַּֽחֲמִשָּׁ֨ה עָשָׂ֜ר י֗וֹם לַחֹ֤דֶשׁ הַשְּׁבִיעִי֙ הַזֶּ֔ה חַ֧ג הַסֻּכּ֛וֹת שִׁבְעַ֥ת יָמִ֖ים לַֽיהוָֽה׃"
          },
          {
            "num": "לה",
            "txt": "בַּיּ֥וֹם הָֽרִאשׁ֖וֹן מִקְרָא־קֹ֑דֶשׁ כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃"
          },
          {
            "num": "לו",
            "txt": "שִׁבְעַ֣ת יָמִ֔ים תַּקְרִ֥יבוּ אִשֶּׁ֖ה לַֽיהוָ֑ה בַּיּ֣וֹם הַשְּׁמִינִ֡י מִקְרָא־קֹדֶשׁ֩ יִֽהְיֶ֨ה לָכֶ֜ם וְהִקְרַבְתֶּ֨ם אִשֶּׁ֤ה לַֽיהוָה֙ עֲצֶ֣רֶת הִ֔וא כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃"
          },
          {
            "num": "לז",
            "txt": "אֵ֚לֶּה מֽוֹעֲדֵ֣י יְהוָ֔ה אֲשֶׁר־תִּקְרְא֥וּ אֹתָ֖ם מִקְרָאֵ֣י קֹ֑דֶשׁ לְהַקְרִ֨יב אִשֶּׁ֜ה לַֽיהוָ֗ה עֹלָ֧ה וּמִנְחָ֛ה זֶ֥בַח וּנְסָכִ֖ים דְּבַר־י֥וֹם בְּיוֹמֽוֹ׃"
          },
          {
            "num": "לח",
            "txt": "מִלְּבַ֖ד שַׁבְּתֹ֣ת יְהוָ֑ה וּמִלְּבַ֣ד מַתְּנֽוֹתֵיכֶ֗ם וּמִלְּבַ֤ד כָּל־נִדְרֵיכֶם֙ וּמִלְּבַד֙ כָּל־נִדְבֹ֣תֵיכֶ֔ם אֲשֶׁ֥ר תִּתְּנ֖וּ לַֽיהוָֽה׃"
          },
          {
            "num": "לט",
            "txt": "אַ֡ךְ בַּֽחֲמִשָּׁה֩ עָשָׂ֨ר י֜וֹם לַחֹ֣דֶשׁ הַשְּׁבִיעִ֗י בְּאָסְפְּכֶם֙ אֶת־תְּבוּאַ֣ת הָאָ֔רֶץ תָּחֹ֥גּוּ אֶת־חַג־יְהוָ֖ה שִׁבְעַ֣ת יָמִ֑ים בַּיּ֤וֹם הָֽרִאשׁוֹן֙ שַׁבָּת֔וֹן וּבַיּ֥וֹם הַשְּׁמִינִ֖י שַׁבָּתֽוֹן׃"
          },
          {
            "num": "מ",
            "txt": "וּלְקַחְתֶּ֨ם לָכֶ֜ם בַּיּ֣וֹם הָֽרִאשׁ֗וֹן פְּרִ֨י עֵ֤ץ הָדָר֙ כַּפֹּ֣ת תְּמָרִ֔ים וַֽעֲנַ֥ף עֵץ־עָבֹ֖ת וְעַרְבֵי־נָ֑חַל וּשְׂמַחְתֶּ֗ם לִפְנֵ֛י יְהוָ֥ה אֱלֹֽהֵיכֶ֖ם שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "מא",
            "txt": "וְחַגֹּתֶ֤ם אֹתוֹ֙ חַ֣ג לַֽיהוָ֔ה שִׁבְעַ֥ת יָמִ֖ים בַּשָּׁנָ֑ה חֻקַּ֤ת עוֹלָם֙ לְדֹרֹ֣תֵיכֶ֔ם בַּחֹ֥דֶשׁ הַשְּׁבִיעִ֖י תָּחֹ֥גּוּ אֹתֽוֹ׃"
          },
          {
            "num": "מב",
            "txt": "בַּסֻּכֹּ֥ת תֵּֽשְׁב֖וּ שִׁבְעַ֣ת יָמִ֑ים כָּל־הָֽאֶזְרָח֙ בְּיִשְׂרָאֵ֔ל יֵֽשְׁב֖וּ בַּסֻּכֹּֽת׃"
          },
          {
            "num": "מג",
            "txt": "לְמַעַן֮ יֵֽדְע֣וּ דֹרֹֽתֵיכֶם֒ כִּ֣י בַסֻּכּ֗וֹת הוֹשַׁ֨בְתִּי֙ אֶת־בְּנֵ֣י יִשְׂרָאֵ֔ל בְּהֽוֹצִיאִ֥י אוֹתָ֖ם מֵאֶ֣רֶץ מִצְרָ֑יִם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "מד",
            "txt": "וַיְדַבֵּ֣ר מֹשֶׁ֔ה אֶת־מֹֽעֲדֵ֖י יְהוָ֑ה אֶל־בְּנֵ֖י יִשְׂרָאֵֽל׃ {פ}"
          }
        ]
      },
      {
        "num": "כד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "צַ֞ו אֶת־בְּנֵ֣י יִשְׂרָאֵ֗ל וְיִקְח֨וּ אֵלֶ֜יךָ שֶׁ֣מֶן זַ֥יִת זָ֛ךְ כָּתִ֖ית לַמָּא֑וֹר לְהַֽעֲלֹ֥ת נֵ֖ר תָּמִֽיד׃"
          },
          {
            "num": "ג",
            "txt": "מִחוּץ֩ לְפָרֹ֨כֶת הָֽעֵדֻ֜ת בְּאֹ֣הֶל מוֹעֵ֗ד יַֽעֲרֹךְ֩ אֹת֨וֹ אַֽהֲרֹ֜ן מֵעֶ֧רֶב עַד־בֹּ֛קֶר לִפְנֵ֥י יְהוָ֖ה תָּמִ֑יד חֻקַּ֥ת עוֹלָ֖ם לְדֹרֹֽתֵיכֶֽם׃"
          },
          {
            "num": "ד",
            "txt": "עַ֚ל הַמְּנֹרָ֣ה הַטְּהֹרָ֔ה יַֽעֲרֹ֖ךְ אֶת־הַנֵּר֑וֹת לִפְנֵ֥י יְהוָ֖ה תָּמִֽיד׃ {פ}"
          },
          {
            "num": "ה",
            "txt": "וְלָֽקַחְתָּ֣ סֹ֔לֶת וְאָֽפִיתָ֣ אֹתָ֔הּ שְׁתֵּ֥ים עֶשְׂרֵ֖ה חַלּ֑וֹת שְׁנֵי֙ עֶשְׂרֹנִ֔ים יִֽהְיֶ֖ה הַֽחַלָּ֥ה הָֽאֶחָֽת׃"
          },
          {
            "num": "ו",
            "txt": "וְשַׂמְתָּ֥ אוֹתָ֛ם שְׁתַּ֥יִם מַֽעֲרָכ֖וֹת שֵׁ֣שׁ הַֽמַּעֲרָ֑כֶת עַ֛ל הַשֻּׁלְחָ֥ן הַטָּהֹ֖ר לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וְנָֽתַתָּ֥ עַל־הַֽמַּעֲרֶ֖כֶת לְבֹנָ֣ה זַכָּ֑ה וְהָֽיְתָ֤ה לַלֶּ֨חֶם֙ לְאַזְכָּרָ֔ה אִשֶּׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "בְּי֨וֹם הַשַּׁבָּ֜ת בְּי֣וֹם הַשַּׁבָּ֗ת יַֽעַרְכֶ֛נּוּ לִפְנֵ֥י יְהוָ֖ה תָּמִ֑יד מֵאֵ֥ת בְּנֵֽי־יִשְׂרָאֵ֖ל בְּרִ֥ית עוֹלָֽם׃"
          },
          {
            "num": "ט",
            "txt": "וְהָֽיְתָה֙ לְאַֽהֲרֹ֣ן וּלְבָנָ֔יו וַֽאֲכָלֻ֖הוּ בְּמָק֣וֹם קָדֹ֑שׁ כִּ֡י קֹדֶשׁ֩ קָֽדָשִׁ֨ים ה֥וּא ל֛וֹ מֵֽאִשֵּׁ֥י יְהוָ֖ה חָק־עוֹלָֽם׃ {ס}"
          },
          {
            "num": "י",
            "txt": "וַיֵּצֵא֙ בֶּן־אִשָּׁ֣ה יִשְׂרְאֵלִ֔ית וְהוּא֙ בֶּן־אִ֣ישׁ מִצְרִ֔י בְּת֖וֹךְ בְּנֵ֣י יִשְׂרָאֵ֑ל וַיִּנָּצוּ֙ בַּֽמַּחֲנֶ֔ה בֶּ֚ן הַיִּשְׂרְאֵלִ֔ית וְאִ֖ישׁ הַיִּשְׂרְאֵלִֽי׃"
          },
          {
            "num": "יא",
            "txt": "וַ֠יִּקֹּב בֶּן־הָֽאִשָּׁ֨ה הַיִּשְׂרְאֵלִ֤ית אֶת־הַשֵּׁם֙ וַיְקַלֵּ֔ל וַיָּבִ֥יאוּ אֹת֖וֹ אֶל־מֹשֶׁ֑ה וְשֵׁ֥ם אִמּ֛וֹ שְׁלֹמִ֥ית בַּת־דִּבְרִ֖י לְמַטֵּה־דָֽן׃"
          },
          {
            "num": "יב",
            "txt": "וַיַּנִּיחֻ֖הוּ בַּמִּשְׁמָ֑ר לִפְרֹ֥שׁ לָהֶ֖ם עַל־פִּ֥י יְהוָֽה׃ {פ}"
          },
          {
            "num": "יג",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יד",
            "txt": "הוֹצֵ֣א אֶת־הַֽמְקַלֵּ֗ל אֶל־מִחוּץ֙ לַֽמַּחֲנֶ֔ה וְסָֽמְכ֧וּ כָֽל־הַשֹּׁמְעִ֛ים אֶת־יְדֵיהֶ֖ם עַל־רֹאשׁ֑וֹ וְרָֽגְמ֥וּ אֹת֖וֹ כָּל־הָֽעֵדָֽה׃"
          },
          {
            "num": "טו",
            "txt": "וְאֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל תְּדַבֵּ֣ר לֵאמֹ֑ר אִ֥ישׁ אִ֛ישׁ כִּֽי־יְקַלֵּ֥ל אֱלֹהָ֖יו וְנָשָׂ֥א חֶטְאֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "וְנֹקֵ֤ב שֵׁם־יְהוָה֙ מ֣וֹת יוּמָ֔ת רָג֥וֹם יִרְגְּמוּ־ב֖וֹ כָּל־הָֽעֵדָ֑ה כַּגֵּר֙ כָּֽאֶזְרָ֔ח בְּנָקְבוֹ־שֵׁ֖ם יוּמָֽת׃"
          },
          {
            "num": "יז",
            "txt": "וְאִ֕ישׁ כִּ֥י יַכֶּ֖ה כָּל־נֶ֣פֶשׁ אָדָ֑ם מ֖וֹת יוּמָֽת׃"
          },
          {
            "num": "יח",
            "txt": "וּמַכֵּ֥ה נֶֽפֶשׁ־בְּהֵמָ֖ה יְשַׁלְּמֶ֑נָּה נֶ֖פֶשׁ תַּ֥חַת נָֽפֶשׁ׃"
          },
          {
            "num": "יט",
            "txt": "וְאִ֕ישׁ כִּֽי־יִתֵּ֥ן מ֖וּם בַּֽעֲמִית֑וֹ כַּֽאֲשֶׁ֣ר עָשָׂ֔ה כֵּ֖ן יֵעָ֥שֶׂה לּֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "שֶׁ֚בֶר תַּ֣חַת שֶׁ֔בֶר עַ֚יִן תַּ֣חַת עַ֔יִן שֵׁ֖ן תַּ֣חַת שֵׁ֑ן כַּֽאֲשֶׁ֨ר יִתֵּ֥ן מוּם֙ בָּֽאָדָ֔ם כֵּ֖ן יִנָּ֥תֶן בּֽוֹ׃"
          },
          {
            "num": "כא",
            "txt": "וּמַכֵּ֥ה בְהֵמָ֖ה יְשַׁלְּמֶ֑נָּה וּמַכֵּ֥ה אָדָ֖ם יוּמָֽת׃"
          },
          {
            "num": "כב",
            "txt": "מִשְׁפַּ֤ט אֶחָד֙ יִֽהְיֶ֣ה לָכֶ֔ם כַּגֵּ֥ר כָּֽאֶזְרָ֖ח יִֽהְיֶ֑ה כִּ֛י אֲנִ֥י יְהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "כג",
            "txt": "וַיְדַבֵּ֣ר מֹשֶׁה֮ אֶל־בְּנֵ֣י יִשְׂרָאֵל֒ וַיּוֹצִ֣יאוּ אֶת־הַֽמְקַלֵּ֗ל אֶל־מִחוּץ֙ לַֽמַּחֲנֶ֔ה וַיִּרְגְּמ֥וּ אֹת֖וֹ אָ֑בֶן וּבְנֵֽי־יִשְׂרָאֵ֣ל עָשׂ֔וּ כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "כה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֤ר יְהוָה֙ אֶל־מֹשֶׁ֔ה בְּהַ֥ר סִינַ֖י לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֞ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם כִּ֤י תָבֹ֨אוּ֙ אֶל־הָאָ֔רֶץ אֲשֶׁ֥ר אֲנִ֖י נֹתֵ֣ן לָכֶ֑ם וְשָֽׁבְתָ֣ה הָאָ֔רֶץ שַׁבָּ֖ת לַֽיהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "שֵׁ֤שׁ שָׁנִים֙ תִּזְרַ֣ע שָׂדֶ֔ךָ וְשֵׁ֥שׁ שָׁנִ֖ים תִּזְמֹ֣ר כַּרְמֶ֑ךָ וְאָֽסַפְתָּ֖ אֶת־תְּבֽוּאָתָֽהּ׃"
          },
          {
            "num": "ד",
            "txt": "וּבַשָּׁנָ֣ה הַשְּׁבִיעִ֗ת שַׁבַּ֤ת שַׁבָּתוֹן֙ יִֽהְיֶ֣ה לָאָ֔רֶץ שַׁבָּ֖ת לַֽיהוָ֑ה שָֽׂדְךָ֙ לֹ֣א תִזְרָ֔ע וְכַרְמְךָ֖ לֹ֥א תִזְמֹֽר׃"
          },
          {
            "num": "ה",
            "txt": "אֵ֣ת סְפִ֤יחַ קְצִֽירְךָ֙ לֹ֣א תִקְצ֔וֹר וְאֶת־עִנְּבֵ֥י נְזִירֶ֖ךָ לֹ֣א תִבְצֹ֑ר שְׁנַ֥ת שַׁבָּת֖וֹן יִֽהְיֶ֥ה לָאָֽרֶץ׃"
          },
          {
            "num": "ו",
            "txt": "וְ֠הָֽיְתָה שַׁבַּ֨ת הָאָ֤רֶץ לָכֶם֙ לְאָכְלָ֔ה לְךָ֖ וּלְעַבְדְּךָ֣ וְלַֽאֲמָתֶ֑ךָ וְלִשְׂכִֽירְךָ֙ וּלְתוֹשָׁ֣בְךָ֔ הַגָּרִ֖ים עִמָּֽךְ׃"
          },
          {
            "num": "ז",
            "txt": "וְלִ֨בְהֶמְתְּךָ֔ וְלַֽחַיָּ֖ה אֲשֶׁ֣ר בְּאַרְצֶ֑ךָ תִּֽהְיֶ֥ה כָל־תְּבֽוּאָתָ֖הּ לֶֽאֱכֹֽל׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "וְסָֽפַרְתָּ֣ לְךָ֗ שֶׁ֚בַע שַׁבְּתֹ֣ת שָׁנִ֔ים שֶׁ֥בַע שָׁנִ֖ים שֶׁ֣בַע פְּעָמִ֑ים וְהָי֣וּ לְךָ֗ יְמֵי֙ שֶׁ֚בַע שַׁבְּתֹ֣ת הַשָּׁנִ֔ים תֵּ֥שַׁע וְאַרְבָּעִ֖ים שָׁנָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וְהַֽעֲבַרְתָּ֞ שׁוֹפַ֤ר תְּרוּעָה֙ בַּחֹ֣דֶשׁ הַשְּׁבִעִ֔י בֶּֽעָשׂ֖וֹר לַחֹ֑דֶשׁ בְּיוֹם֙ הַכִּפֻּרִ֔ים תַּֽעֲבִ֥ירוּ שׁוֹפָ֖ר בְּכָל־אַרְצְכֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וְקִדַּשְׁתֶּ֗ם אֵ֣ת שְׁנַ֤ת הַֽחֲמִשִּׁים֙ שָׁנָ֔ה וּקְרָאתֶ֥ם דְּר֛וֹר בָּאָ֖רֶץ לְכָל־יֹֽשְׁבֶ֑יהָ יוֹבֵ֥ל הִוא֙ תִּֽהְיֶ֣ה לָכֶ֔ם וְשַׁבְתֶּ֗ם אִ֚ישׁ אֶל־אֲחֻזָּת֔וֹ וְאִ֥ישׁ אֶל־מִשְׁפַּחְתּ֖וֹ תָּשֻֽׁבוּ׃"
          },
          {
            "num": "יא",
            "txt": "יוֹבֵ֣ל הִ֗וא שְׁנַ֛ת הַֽחֲמִשִּׁ֥ים שָׁנָ֖ה תִּֽהְיֶ֣ה לָכֶ֑ם לֹ֣א תִזְרָ֔עוּ וְלֹ֤א תִקְצְרוּ֙ אֶת־סְפִיחֶ֔יהָ וְלֹ֥א תִבְצְר֖וּ אֶת־נְזִרֶֽיהָ׃"
          },
          {
            "num": "יב",
            "txt": "כִּ֚י יוֹבֵ֣ל הִ֔וא קֹ֖דֶשׁ תִּֽהְיֶ֣ה לָכֶ֑ם מִ֨ן־הַשָּׂדֶ֔ה תֹּֽאכְל֖וּ אֶת־תְּבֽוּאָתָֽהּ׃"
          },
          {
            "num": "יג",
            "txt": "בִּשְׁנַ֥ת הַיּוֹבֵ֖ל הַזֹּ֑את תָּשֻׁ֕בוּ אִ֖ישׁ אֶל־אֲחֻזָּתֽוֹ׃"
          },
          {
            "num": "יד",
            "txt": "וְכִֽי־תִמְכְּר֤וּ מִמְכָּר֙ לַֽעֲמִיתֶ֔ךָ א֥וֹ קָנֹ֖ה מִיַּ֣ד עֲמִיתֶ֑ךָ אַל־תּוֹנ֖וּ אִ֥ישׁ אֶת־אָחִֽיו׃"
          },
          {
            "num": "טו",
            "txt": "בְּמִסְפַּ֤ר שָׁנִים֙ אַחַ֣ר הַיּוֹבֵ֔ל תִּקְנֶ֖ה מֵאֵ֣ת עֲמִיתֶ֑ךָ בְּמִסְפַּ֥ר שְׁנֵֽי־תְבוּאֹ֖ת יִמְכָּר־לָֽךְ׃"
          },
          {
            "num": "טז",
            "txt": "לְפִ֣י ׀ רֹ֣ב הַשָּׁנִ֗ים תַּרְבֶּה֙ מִקְנָת֔וֹ וּלְפִי֙ מְעֹ֣ט הַשָּׁנִ֔ים תַּמְעִ֖יט מִקְנָת֑וֹ כִּ֚י מִסְפַּ֣ר תְּבוּאֹ֔ת ה֥וּא מֹכֵ֖ר לָֽךְ׃"
          },
          {
            "num": "יז",
            "txt": "וְלֹ֤א תוֹנוּ֙ אִ֣ישׁ אֶת־עֲמִית֔וֹ וְיָרֵ֖אתָ מֵֽאֱלֹהֶ֑יךָ כִּ֛י אֲנִ֥י יְהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "יח",
            "txt": "וַֽעֲשִׂיתֶם֙ אֶת־חֻקֹּתַ֔י וְאֶת־מִשְׁפָּטַ֥י תִּשְׁמְר֖וּ וַֽעֲשִׂיתֶ֣ם אֹתָ֑ם וִֽישַׁבְתֶּ֥ם עַל־הָאָ֖רֶץ לָבֶֽטַח׃"
          },
          {
            "num": "יט",
            "txt": "וְנָֽתְנָ֤ה הָאָ֨רֶץ֙ פִּרְיָ֔הּ וַֽאֲכַלְתֶּ֖ם לָשֹׂ֑בַע וִֽישַׁבְתֶּ֥ם לָבֶ֖טַח עָלֶֽיהָ׃"
          },
          {
            "num": "כ",
            "txt": "וְכִ֣י תֹֽאמְר֔וּ מַה־נֹּאכַ֖ל בַּשָּׁנָ֣ה הַשְּׁבִיעִ֑ת הֵ֚ן לֹ֣א נִזְרָ֔ע וְלֹ֥א נֶֽאֱסֹ֖ף אֶת־תְּבֽוּאָתֵֽנוּ׃"
          },
          {
            "num": "כא",
            "txt": "וְצִוִּ֤יתִי אֶת־בִּרְכָתִי֙ לָכֶ֔ם בַּשָּׁנָ֖ה הַשִּׁשִּׁ֑ית וְעָשָׂת֙ אֶת־הַתְּבוּאָ֔ה לִשְׁלֹ֖שׁ הַשָּׁנִֽים׃"
          },
          {
            "num": "כב",
            "txt": "וּזְרַעְתֶּ֗ם אֵ֚ת הַשָּׁנָ֣ה הַשְּׁמִינִ֔ת וַֽאֲכַלְתֶּ֖ם מִן־הַתְּבוּאָ֣ה יָשָׁ֑ן עַ֣ד ׀ הַשָּׁנָ֣ה הַתְּשִׁיעִ֗ת עַד־בּוֹא֙ תְּב֣וּאָתָ֔הּ תֹּֽאכְל֖וּ יָשָֽׁן׃"
          },
          {
            "num": "כג",
            "txt": "וְהָאָ֗רֶץ לֹ֤א תִמָּכֵר֙ לִצְמִתֻ֔ת כִּי־לִ֖י הָאָ֑רֶץ כִּֽי־גֵרִ֧ים וְתֽוֹשָׁבִ֛ים אַתֶּ֖ם עִמָּדִֽי׃"
          },
          {
            "num": "כד",
            "txt": "וּבְכֹ֖ל אֶ֣רֶץ אֲחֻזַּתְכֶ֑ם גְּאֻלָּ֖ה תִּתְּנ֥וּ לָאָֽרֶץ׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "כִּֽי־יָמ֣וּךְ אָחִ֔יךָ וּמָכַ֖ר מֵֽאֲחֻזָּת֑וֹ וּבָ֤א גֹֽאֲלוֹ֙ הַקָּרֹ֣ב אֵלָ֔יו וְגָאַ֕ל אֵ֖ת מִמְכַּ֥ר אָחִֽיו׃"
          },
          {
            "num": "כו",
            "txt": "וְאִ֕ישׁ כִּ֛י לֹ֥א יִֽהְיֶה־לּ֖וֹ גֹּאֵ֑ל וְהִשִּׂ֣יגָה יָד֔וֹ וּמָצָ֖א כְּדֵ֥י גְאֻלָּתֽוֹ׃"
          },
          {
            "num": "כז",
            "txt": "וְחִשַּׁב֙ אֶת־שְׁנֵ֣י מִמְכָּר֔וֹ וְהֵשִׁיב֙ אֶת־הָ֣עֹדֵ֔ף לָאִ֖ישׁ אֲשֶׁ֣ר מָֽכַר־ל֑וֹ וְשָׁ֖ב לַֽאֲחֻזָּתֽוֹ׃"
          },
          {
            "num": "כח",
            "txt": "וְאִ֨ם לֹֽא־מָצְאָ֜ה יָד֗וֹ דֵּי֮ הָשִׁ֣יב לוֹ֒ וְהָיָ֣ה מִמְכָּר֗וֹ בְּיַד֙ הַקֹּנֶ֣ה אֹת֔וֹ עַ֖ד שְׁנַ֣ת הַיּוֹבֵ֑ל וְיָצָא֙ בַּיֹּבֵ֔ל וְשָׁ֖ב לַֽאֲחֻזָּתֽוֹ׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "וְאִ֗ישׁ כִּֽי־יִמְכֹּ֤ר בֵּית־מוֹשַׁב֙ עִ֣יר חוֹמָ֔ה וְהָֽיְתָה֙ גְּאֻלָּת֔וֹ עַד־תֹּ֖ם שְׁנַ֣ת מִמְכָּר֑וֹ יָמִ֖ים תִּֽהְיֶ֥ה גְאֻלָּתֽוֹ׃"
          },
          {
            "num": "ל",
            "txt": "וְאִ֣ם לֹֽא־יִגָּאֵ֗ל עַד־מְלֹ֣את לוֹ֮ שָׁנָ֣ה תְמִימָה֒ וְ֠קָם הַבַּ֨יִת אֲשֶׁר־בָּעִ֜יר אֲשֶׁר־לא (ל֣וֹ) חֹמָ֗ה לַצְּמִיתֻ֛ת לַקֹּנֶ֥ה אֹת֖וֹ לְדֹֽרֹתָ֑יו לֹ֥א יֵצֵ֖א בַּיֹּבֵֽל׃"
          },
          {
            "num": "לא",
            "txt": "וּבָתֵּ֣י הַֽחֲצֵרִ֗ים אֲשֶׁ֨ר אֵין־לָהֶ֤ם חֹמָה֙ סָבִ֔יב עַל־שְׂדֵ֥ה הָאָ֖רֶץ יֵֽחָשֵׁ֑ב גְּאֻלָּה֙ תִּֽהְיֶה־לּ֔וֹ וּבַיֹּבֵ֖ל יֵצֵֽא׃"
          },
          {
            "num": "לב",
            "txt": "וְעָרֵי֙ הַלְוִיִּ֔ם בָּתֵּ֖י עָרֵ֣י אֲחֻזָּתָ֑ם גְּאֻלַּ֥ת עוֹלָ֖ם תִּֽהְיֶ֥ה לַלְוִיִּֽם׃"
          },
          {
            "num": "לג",
            "txt": "וַֽאֲשֶׁ֤ר יִגְאַל֙ מִן־הַלְוִיִּ֔ם וְיָצָ֧א מִמְכַּר־בַּ֛יִת וְעִ֥יר אֲחֻזָּת֖וֹ בַּיֹּבֵ֑ל כִּ֣י בָתֵּ֞י עָרֵ֣י הַלְוִיִּ֗ם הִ֚וא אֲחֻזָּתָ֔ם בְּת֖וֹךְ בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "לד",
            "txt": "וּֽשְׂדֵ֛ה מִגְרַ֥שׁ עָֽרֵיהֶ֖ם לֹ֣א יִמָּכֵ֑ר כִּֽי־אֲחֻזַּ֥ת עוֹלָ֛ם ה֖וּא לָהֶֽם׃ {ס}"
          },
          {
            "num": "לה",
            "txt": "וְכִֽי־יָמ֣וּךְ אָחִ֔יךָ וּמָ֥טָה יָד֖וֹ עִמָּ֑ךְ וְהֶֽחֱזַ֣קְתָּ בּ֔וֹ גֵּ֧ר וְתוֹשָׁ֛ב וָחַ֖י עִמָּֽךְ׃"
          },
          {
            "num": "לו",
            "txt": "אַל־תִּקַּ֤ח מֵֽאִתּוֹ֙ נֶ֣שֶׁךְ וְתַרְבִּ֔ית וְיָרֵ֖אתָ מֵֽאֱלֹהֶ֑יךָ וְחֵ֥י אָחִ֖יךָ עִמָּֽךְ׃"
          },
          {
            "num": "לז",
            "txt": "אֶ֨ת־כַּסְפְּךָ֔ לֹֽא־תִתֵּ֥ן ל֖וֹ בְּנֶ֑שֶׁךְ וּבְמַרְבִּ֖ית לֹֽא־תִתֵּ֥ן אָכְלֶֽךָ׃"
          },
          {
            "num": "לח",
            "txt": "אֲנִ֗י יְהוָה֙ אֱלֹ֣הֵיכֶ֔ם אֲשֶׁר־הוֹצֵ֥אתִי אֶתְכֶ֖ם מֵאֶ֣רֶץ מִצְרָ֑יִם לָתֵ֤ת לָכֶם֙ אֶת־אֶ֣רֶץ כְּנַ֔עַן לִֽהְי֥וֹת לָכֶ֖ם לֵֽאלֹהִֽים׃ {ס}"
          },
          {
            "num": "לט",
            "txt": "וְכִֽי־יָמ֥וּךְ אָחִ֛יךָ עִמָּ֖ךְ וְנִמְכַּר־לָ֑ךְ לֹֽא־תַעֲבֹ֥ד בּ֖וֹ עֲבֹ֥דַת עָֽבֶד׃"
          },
          {
            "num": "מ",
            "txt": "כְּשָׂכִ֥יר כְּתוֹשָׁ֖ב יִֽהְיֶ֣ה עִמָּ֑ךְ עַד־שְׁנַ֥ת הַיֹּבֵ֖ל יַֽעֲבֹ֥ד עִמָּֽךְ׃"
          },
          {
            "num": "מא",
            "txt": "וְיָצָא֙ מֵֽעִמָּ֔ךְ ה֖וּא וּבָנָ֣יו עִמּ֑וֹ וְשָׁב֙ אֶל־מִשְׁפַּחְתּ֔וֹ וְאֶל־אֲחֻזַּ֥ת אֲבֹתָ֖יו יָשֽׁוּב׃"
          },
          {
            "num": "מב",
            "txt": "כִּֽי־עֲבָדַ֣י הֵ֔ם אֲשֶׁר־הוֹצֵ֥אתִי אֹתָ֖ם מֵאֶ֣רֶץ מִצְרָ֑יִם לֹ֥א יִמָּֽכְר֖וּ מִמְכֶּ֥רֶת עָֽבֶד׃"
          },
          {
            "num": "מג",
            "txt": "לֹֽא־תִרְדֶּ֥ה ב֖וֹ בְּפָ֑רֶךְ וְיָרֵ֖אתָ מֵֽאֱלֹהֶֽיךָ׃"
          },
          {
            "num": "מד",
            "txt": "וְעַבְדְּךָ֥ וַאֲמָֽתְךָ֖ אֲשֶׁ֣ר יִֽהְיוּ־לָ֑ךְ מֵאֵ֣ת הַגּוֹיִ֗ם אֲשֶׁר֙ סְבִיבֹ֣תֵיכֶ֔ם מֵהֶ֥ם תִּקְנ֖וּ עֶ֥בֶד וְאָמָֽה׃"
          },
          {
            "num": "מה",
            "txt": "וְ֠גַם מִבְּנֵ֨י הַתּֽוֹשָׁבִ֜ים הַגָּרִ֤ים עִמָּכֶם֙ מֵהֶ֣ם תִּקְנ֔וּ וּמִמִּשְׁפַּחְתָּם֙ אֲשֶׁ֣ר עִמָּכֶ֔ם אֲשֶׁ֥ר הוֹלִ֖ידוּ בְּאַרְצְכֶ֑ם וְהָי֥וּ לָכֶ֖ם לַֽאֲחֻזָּֽה׃"
          },
          {
            "num": "מו",
            "txt": "וְהִתְנַֽחַלְתֶּ֨ם אֹתָ֜ם לִבְנֵיכֶ֤ם אַֽחֲרֵיכֶם֙ לָרֶ֣שֶׁת אֲחֻזָּ֔ה לְעֹלָ֖ם בָּהֶ֣ם תַּֽעֲבֹ֑דוּ וּבְאַ֨חֵיכֶ֤ם בְּנֵֽי־יִשְׂרָאֵל֙ אִ֣ישׁ בְּאָחִ֔יו לֹֽא־תִרְדֶּ֥ה ב֖וֹ בְּפָֽרֶךְ׃ {ס}"
          },
          {
            "num": "מז",
            "txt": "וְכִ֣י תַשִּׂ֗יג יַ֣ד גֵּ֤ר וְתוֹשָׁב֙ עִמָּ֔ךְ וּמָ֥ךְ אָחִ֖יךָ עִמּ֑וֹ וְנִמְכַּ֗ר לְגֵ֤ר תּוֹשָׁב֙ עִמָּ֔ךְ א֥וֹ לְעֵ֖קֶר מִשְׁפַּ֥חַת גֵּֽר׃"
          },
          {
            "num": "מח",
            "txt": "אַֽחֲרֵ֣י נִמְכַּ֔ר גְּאֻלָּ֖ה תִּֽהְיֶה־לּ֑וֹ אֶחָ֥ד מֵֽאֶחָ֖יו יִגְאָלֶֽנּוּ׃"
          },
          {
            "num": "מט",
            "txt": "אֽוֹ־דֹד֞וֹ א֤וֹ בֶן־דֹּדוֹ֙ יִגְאָלֶ֔נּוּ אֽוֹ־מִשְּׁאֵ֧ר בְּשָׂר֛וֹ מִמִּשְׁפַּחְתּ֖וֹ יִגְאָלֶ֑נּוּ אֽוֹ־הִשִּׂ֥יגָה יָד֖וֹ וְנִגְאָֽל׃"
          },
          {
            "num": "נ",
            "txt": "וְחִשַּׁב֙ עִם־קֹנֵ֔הוּ מִשְּׁנַת֙ הִמָּ֣כְרוֹ ל֔וֹ עַ֖ד שְׁנַ֣ת הַיֹּבֵ֑ל וְהָיָ֞ה כֶּ֤סֶף מִמְכָּרוֹ֙ בְּמִסְפַּ֣ר שָׁנִ֔ים כִּימֵ֥י שָׂכִ֖יר יִֽהְיֶ֥ה עִמּֽוֹ׃"
          },
          {
            "num": "נא",
            "txt": "אִם־ע֥וֹד רַבּ֖וֹת בַּשָּׁנִ֑ים לְפִיהֶן֙ יָשִׁ֣יב גְּאֻלָּת֔וֹ מִכֶּ֖סֶף מִקְנָתֽוֹ׃"
          },
          {
            "num": "נב",
            "txt": "וְאִם־מְעַ֞ט נִשְׁאַ֧ר בַּשָּׁנִ֛ים עַד־שְׁנַ֥ת הַיֹּבֵ֖ל וְחִשַּׁב־ל֑וֹ כְּפִ֣י שָׁנָ֔יו יָשִׁ֖יב אֶת־גְּאֻלָּתֽוֹ׃"
          },
          {
            "num": "נג",
            "txt": "כִּשְׂכִ֥יר שָׁנָ֛ה בְּשָׁנָ֖ה יִֽהְיֶ֣ה עִמּ֑וֹ לֹֽא־יִרְדֶּ֥נּֽוּ בְּפֶ֖רֶךְ לְעֵינֶֽיךָ׃"
          },
          {
            "num": "נד",
            "txt": "וְאִם־לֹ֥א יִגָּאֵ֖ל בְּאֵ֑לֶּה וְיָצָא֙ בִּשְׁנַ֣ת הַיֹּבֵ֔ל ה֖וּא וּבָנָ֥יו עִמּֽוֹ׃"
          },
          {
            "num": "נה",
            "txt": "כִּֽי־לִ֤י בְנֵֽי־יִשְׂרָאֵל֙ עֲבָדִ֔ים עֲבָדַ֣י הֵ֔ם אֲשֶׁר־הוֹצֵ֥אתִי אוֹתָ֖ם מֵאֶ֣רֶץ מִצְרָ֑יִם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃"
          }
        ]
      },
      {
        "num": "כו",
        "verses": [
          {
            "num": "א",
            "txt": "לֹֽא־תַעֲשׂ֨וּ לָכֶ֜ם אֱלִילִ֗ם וּפֶ֤סֶל וּמַצֵּבָה֙ לֹֽא־תָקִ֣ימוּ לָכֶ֔ם וְאֶ֣בֶן מַשְׂכִּ֗ית לֹ֤א תִתְּנוּ֙ בְּאַרְצְכֶ֔ם לְהִֽשְׁתַּחֲוֺ֖ת עָלֶ֑יהָ כִּ֛י אֲנִ֥י יְהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "אֶת־שַׁבְּתֹתַ֣י תִּשְׁמֹ֔רוּ וּמִקְדָּשִׁ֖י תִּירָ֑אוּ אֲנִ֖י יְהוָֽה׃ {פ}"
          },
          {
            "num": "ג",
            "txt": "אִם־בְּחֻקֹּתַ֖י תֵּלֵ֑כוּ וְאֶת־מִצְוֺתַ֣י תִּשְׁמְר֔וּ וַֽעֲשִׂיתֶ֖ם אֹתָֽם׃"
          },
          {
            "num": "ד",
            "txt": "וְנָֽתַתִּ֥י גִשְׁמֵיכֶ֖ם בְּעִתָּ֑ם וְנָֽתְנָ֤ה הָאָ֨רֶץ֙ יְבוּלָ֔הּ וְעֵ֥ץ הַשָּׂדֶ֖ה יִתֵּ֥ן פִּרְיֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "וְהִשִּׂ֨יג לָכֶ֥ם דַּ֨יִשׁ֙ אֶת־בָּצִ֔יר וּבָצִ֖יר יַשִּׂ֣יג אֶת־זָ֑רַע וַֽאֲכַלְתֶּ֤ם לַחְמְכֶם֙ לָשֹׂ֔בַע וִֽישַׁבְתֶּ֥ם לָבֶ֖טַח בְּאַרְצְכֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "וְנָֽתַתִּ֤י שָׁלוֹם֙ בָּאָ֔רֶץ וּשְׁכַבְתֶּ֖ם וְאֵ֣ין מַֽחֲרִ֑יד וְהִשְׁבַּתִּ֞י חַיָּ֤ה רָעָה֙ מִן־הָאָ֔רֶץ וְחֶ֖רֶב לֹֽא־תַעֲבֹ֥ר בְּאַרְצְכֶֽם׃"
          },
          {
            "num": "ז",
            "txt": "וּרְדַפְתֶּ֖ם אֶת־אֹֽיְבֵיכֶ֑ם וְנָֽפְל֥וּ לִפְנֵיכֶ֖ם לֶחָֽרֶב׃"
          },
          {
            "num": "ח",
            "txt": "וְרָֽדְפ֨וּ מִכֶּ֤ם חֲמִשָּׁה֙ מֵאָ֔ה וּמֵאָ֥ה מִכֶּ֖ם רְבָבָ֣ה יִרְדֹּ֑פוּ וְנָֽפְל֧וּ אֹֽיְבֵיכֶ֛ם לִפְנֵיכֶ֖ם לֶחָֽרֶב׃"
          },
          {
            "num": "ט",
            "txt": "וּפָנִ֣יתִי אֲלֵיכֶ֔ם וְהִפְרֵיתִ֣י אֶתְכֶ֔ם וְהִרְבֵּיתִ֖י אֶתְכֶ֑ם וַהֲקִֽימֹתִ֥י אֶת־בְּרִיתִ֖י אִתְּכֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וַֽאֲכַלְתֶּ֥ם יָשָׁ֖ן נוֹשָׁ֑ן וְיָשָׁ֕ן מִפְּנֵ֥י חָדָ֖שׁ תּוֹצִֽיאוּ׃"
          },
          {
            "num": "יא",
            "txt": "וְנָֽתַתִּ֥י מִשְׁכָּנִ֖י בְּתֽוֹכְכֶ֑ם וְלֹֽא־תִגְעַ֥ל נַפְשִׁ֖י אֶתְכֶֽם׃"
          },
          {
            "num": "יב",
            "txt": "וְהִתְהַלַּכְתִּי֙ בְּת֣וֹכְכֶ֔ם וְהָיִ֥יתִי לָכֶ֖ם לֵֽאלֹהִ֑ים וְאַתֶּ֖ם תִּֽהְיוּ־לִ֥י לְעָֽם׃"
          },
          {
            "num": "יג",
            "txt": "אֲנִ֞י יְהוָ֣ה אֱלֹֽהֵיכֶ֗ם אֲשֶׁ֨ר הוֹצֵ֤אתִי אֶתְכֶם֙ מֵאֶ֣רֶץ מִצְרַ֔יִם מִֽהְיֹ֥ת לָהֶ֖ם עֲבָדִ֑ים וָֽאֶשְׁבֹּר֙ מֹטֹ֣ת עֻלְּכֶ֔ם וָֽאוֹלֵ֥ךְ אֶתְכֶ֖ם קֽוֹמְמִיּֽוּת׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "וְאִם־לֹ֥א תִשְׁמְע֖וּ לִ֑י וְלֹ֣א תַֽעֲשׂ֔וּ אֵ֥ת כָּל־הַמִּצְוֺ֖ת הָאֵֽלֶּה׃"
          },
          {
            "num": "טו",
            "txt": "וְאִם־בְּחֻקֹּתַ֣י תִּמְאָ֔סוּ וְאִ֥ם אֶת־מִשְׁפָּטַ֖י תִּגְעַ֣ל נַפְשְׁכֶ֑ם לְבִלְתִּ֤י עֲשׂוֹת֙ אֶת־כָּל־מִצְוֺתַ֔י לְהַפְרְכֶ֖ם אֶת־בְּרִיתִֽי׃"
          },
          {
            "num": "טז",
            "txt": "אַף־אֲנִ֞י אֶֽעֱשֶׂה־זֹּ֣את לָכֶ֗ם וְהִפְקַדְתִּ֨י עֲלֵיכֶ֤ם בֶּֽהָלָה֙ אֶת־הַשַּׁחֶ֣פֶת וְאֶת־הַקַּדַּ֔חַת מְכַלּ֥וֹת עֵינַ֖יִם וּמְדִיבֹ֣ת נָ֑פֶשׁ וּזְרַעְתֶּ֤ם לָרִיק֙ זַרְעֲכֶ֔ם וַֽאֲכָלֻ֖הוּ אֹֽיְבֵיכֶֽם׃"
          },
          {
            "num": "יז",
            "txt": "וְנָֽתַתִּ֤י פָנַי֙ בָּכֶ֔ם וְנִגַּפְתֶּ֖ם לִפְנֵ֣י אֹֽיְבֵיכֶ֑ם וְרָד֤וּ בָכֶם֙ שֹֽׂנְאֵיכֶ֔ם וְנַסְתֶּ֖ם וְאֵין־רֹדֵ֥ף אֶתְכֶֽם׃"
          },
          {
            "num": "יח",
            "txt": "וְאִ֨ם־עַד־אֵ֔לֶּה לֹ֥א תִשְׁמְע֖וּ לִ֑י וְיָֽסַפְתִּי֙ לְיַסְּרָ֣ה אֶתְכֶ֔ם שֶׁ֖בַע עַל־חַטֹּֽאתֵיכֶֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְשָֽׁבַרְתִּ֖י אֶת־גְּא֣וֹן עֻזְּכֶ֑ם וְנָֽתַתִּ֤י אֶת־שְׁמֵיכֶם֙ כַּבַּרְזֶ֔ל וְאֶֽת־אַרְצְכֶ֖ם כַּנְּחֻשָֽׁה׃"
          },
          {
            "num": "כ",
            "txt": "וְתַ֥ם לָרִ֖יק כֹּֽחֲכֶ֑ם וְלֹֽא־תִתֵּ֤ן אַרְצְכֶם֙ אֶת־יְבוּלָ֔הּ וְעֵ֣ץ הָאָ֔רֶץ לֹ֥א יִתֵּ֖ן פִּרְיֽוֹ׃"
          },
          {
            "num": "כא",
            "txt": "וְאִם־תֵּֽלְכ֤וּ עִמִּי֙ קֶ֔רִי וְלֹ֥א תֹאב֖וּ לִשְׁמֹ֣עַֽ לִ֑י וְיָֽסַפְתִּ֤י עֲלֵיכֶם֙ מַכָּ֔ה שֶׁ֖בַע כְּחַטֹּֽאתֵיכֶֽם׃"
          },
          {
            "num": "כב",
            "txt": "וְהִשְׁלַחְתִּ֨י בָכֶ֜ם אֶת־חַיַּ֤ת הַשָּׂדֶה֙ וְשִׁכְּלָ֣ה אֶתְכֶ֔ם וְהִכְרִ֨יתָה֙ אֶת־בְּהֶמְתְּכֶ֔ם וְהִמְעִ֖יטָה אֶתְכֶ֑ם וְנָשַׁ֖מּוּ דַּרְכֵיכֶֽם׃"
          },
          {
            "num": "כג",
            "txt": "וְאִ֨ם־בְּאֵ֔לֶּה לֹ֥א תִוָּֽסְר֖וּ לִ֑י וַֽהֲלַכְתֶּ֥ם עִמִּ֖י קֶֽרִי׃"
          },
          {
            "num": "כד",
            "txt": "וְהָֽלַכְתִּ֧י אַף־אֲנִ֛י עִמָּכֶ֖ם בְּקֶ֑רִי וְהִכֵּיתִ֤י אֶתְכֶם֙ גַּם־אָ֔נִי שֶׁ֖בַע עַל־חַטֹּֽאתֵיכֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "וְהֵֽבֵאתִ֨י עֲלֵיכֶ֜ם חֶ֗רֶב נֹקֶ֨מֶת֙ נְקַם־בְּרִ֔ית וְנֶֽאֱסַפְתֶּ֖ם אֶל־עָֽרֵיכֶ֑ם וְשִׁלַּ֤חְתִּי דֶ֨בֶר֙ בְּת֣וֹכְכֶ֔ם וְנִתַּתֶּ֖ם בְּיַד־אוֹיֵֽב׃"
          },
          {
            "num": "כו",
            "txt": "בְּשִׁבְרִ֣י לָכֶם֮ מַטֵּה־לֶחֶם֒ וְ֠אָפוּ עֶ֣שֶׂר נָשִׁ֤ים לַחְמְכֶם֙ בְּתַנּ֣וּר אֶחָ֔ד וְהֵשִׁ֥יבוּ לַחְמְכֶ֖ם בַּמִּשְׁקָ֑ל וַֽאֲכַלְתֶּ֖ם וְלֹ֥א תִשְׂבָּֽעוּ׃ {ס}"
          },
          {
            "num": "כז",
            "txt": "וְאִ֨ם־בְּזֹ֔את לֹ֥א תִשְׁמְע֖וּ לִ֑י וַֽהֲלַכְתֶּ֥ם עִמִּ֖י בְּקֶֽרִי׃"
          },
          {
            "num": "כח",
            "txt": "וְהָֽלַכְתִּ֥י עִמָּכֶ֖ם בַּֽחֲמַת־קֶ֑רִי וְיִסַּרְתִּ֤י אֶתְכֶם֙ אַף־אָ֔נִי שֶׁ֖בַע עַל־חַטֹּֽאתֵיכֶֽם׃"
          },
          {
            "num": "כט",
            "txt": "וַֽאֲכַלְתֶּ֖ם בְּשַׂ֣ר בְּנֵיכֶ֑ם וּבְשַׂ֥ר בְּנֹֽתֵיכֶ֖ם תֹּאכֵֽלוּ׃"
          },
          {
            "num": "ל",
            "txt": "וְהִשְׁמַדְתִּ֞י אֶת־בָּמֹֽתֵיכֶ֗ם וְהִכְרַתִּי֙ אֶת־חַמָּ֣נֵיכֶ֔ם וְנָֽתַתִּי֙ אֶת־פִּגְרֵיכֶ֔ם עַל־פִּגְרֵ֖י גִּלּֽוּלֵיכֶ֑ם וְגָֽעֲלָ֥ה נַפְשִׁ֖י אֶתְכֶֽם׃"
          },
          {
            "num": "לא",
            "txt": "וְנָֽתַתִּ֤י אֶת־עָֽרֵיכֶם֙ חָרְבָּ֔ה וַֽהֲשִׁמּוֹתִ֖י אֶת־מִקְדְּשֵׁיכֶ֑ם וְלֹ֣א אָרִ֔יחַ בְּרֵ֖יחַ נִיחֹֽחֲכֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "וַֽהֲשִׁמֹּתִ֥י אֲנִ֖י אֶת־הָאָ֑רֶץ וְשָֽׁמְמ֤וּ עָלֶ֨יהָ֙ אֹֽיְבֵיכֶ֔ם הַיֹּֽשְׁבִ֖ים בָּֽהּ׃"
          },
          {
            "num": "לג",
            "txt": "וְאֶתְכֶם֙ אֱזָרֶ֣ה בַגּוֹיִ֔ם וַהֲרִֽיקֹתִ֥י אַֽחֲרֵיכֶ֖ם חָ֑רֶב וְהָֽיְתָ֤ה אַרְצְכֶם֙ שְׁמָמָ֔ה וְעָֽרֵיכֶ֖ם יִֽהְי֥וּ חָרְבָּֽה׃"
          },
          {
            "num": "לד",
            "txt": "אָז֩ תִּרְצֶ֨ה הָאָ֜רֶץ אֶת־שַׁבְּתֹתֶ֗יהָ כֹּ֚ל יְמֵ֣י הָשַּׁמָּ֔ה וְאַתֶּ֖ם בְּאֶ֣רֶץ אֹֽיְבֵיכֶ֑ם אָ֚ז תִּשְׁבַּ֣ת הָאָ֔רֶץ וְהִרְצָ֖ת אֶת־שַׁבְּתֹתֶֽיהָ׃"
          },
          {
            "num": "לה",
            "txt": "כָּל־יְמֵ֥י הָשַּׁמָּ֖ה תִּשְׁבֹּ֑ת אֵ֣ת אֲשֶׁ֧ר לֹֽא־שָׁבְתָ֛ה בְּשַׁבְּתֹֽתֵיכֶ֖ם בְּשִׁבְתְּכֶ֥ם עָלֶֽיהָ׃"
          },
          {
            "num": "לו",
            "txt": "וְהַנִּשְׁאָרִ֣ים בָּכֶ֔ם וְהֵבֵ֤אתִי מֹ֨רֶךְ֙ בִּלְבָבָ֔ם בְּאַרְצֹ֖ת אֹֽיְבֵיהֶ֑ם וְרָדַ֣ף אֹתָ֗ם ק֚וֹל עָלֶ֣ה נִדָּ֔ף וְנָס֧וּ מְנֻֽסַת־חֶ֛רֶב וְנָֽפְל֖וּ וְאֵ֥ין רֹדֵֽף׃"
          },
          {
            "num": "לז",
            "txt": "וְכָֽשְׁל֧וּ אִישׁ־בְּאָחִ֛יו כְּמִפְּנֵי־חֶ֖רֶב וְרֹדֵ֣ף אָ֑יִן וְלֹא־תִֽהְיֶ֤ה לָכֶם֙ תְּקוּמָ֔ה לִפְנֵ֖י אֹֽיְבֵיכֶֽם׃"
          },
          {
            "num": "לח",
            "txt": "וַֽאֲבַדְתֶּ֖ם בַּגּוֹיִ֑ם וְאָֽכְלָ֣ה אֶתְכֶ֔ם אֶ֖רֶץ אֹֽיְבֵיכֶֽם׃"
          },
          {
            "num": "לט",
            "txt": "וְהַנִּשְׁאָרִ֣ים בָּכֶ֗ם יִמַּ֨קּוּ֙ בַּֽעֲוֺנָ֔ם בְּאַרְצֹ֖ת אֹֽיְבֵיכֶ֑ם וְאַ֛ף בַּֽעֲוֺנֹ֥ת אֲבֹתָ֖ם אִתָּ֥ם יִמָּֽקּוּ׃"
          },
          {
            "num": "מ",
            "txt": "וְהִתְוַדּ֤וּ אֶת־עֲוֺנָם֙ וְאֶת־עֲוֺ֣ן אֲבֹתָ֔ם בְּמַֽעֲלָ֖ם אֲשֶׁ֣ר מָֽעֲלוּ־בִ֑י וְאַ֕ף אֲשֶׁר־הָֽלְכ֥וּ עִמִּ֖י בְּקֶֽרִי׃"
          },
          {
            "num": "מא",
            "txt": "אַף־אֲנִ֗י אֵלֵ֤ךְ עִמָּם֙ בְּקֶ֔רִי וְהֵֽבֵאתִ֣י אֹתָ֔ם בְּאֶ֖רֶץ אֹֽיְבֵיהֶ֑ם אוֹ־אָ֣ז יִכָּנַ֗ע לְבָבָם֙ הֶֽעָרֵ֔ל וְאָ֖ז יִרְצ֥וּ אֶת־עֲוֺנָֽם׃"
          },
          {
            "num": "מב",
            "txt": "וְזָֽכַרְתִּ֖י אֶת־בְּרִיתִ֣י יַֽעֲק֑וֹב וְאַף֩ אֶת־בְּרִיתִ֨י יִצְחָ֜ק וְאַ֨ף אֶת־בְּרִיתִ֧י אַבְרָהָ֛ם אֶזְכֹּ֖ר וְהָאָ֥רֶץ אֶזְכֹּֽר׃"
          },
          {
            "num": "מג",
            "txt": "וְהָאָרֶץ֩ תֵּֽעָזֵ֨ב מֵהֶ֜ם וְתִ֣רֶץ אֶת־שַׁבְּתֹתֶ֗יהָ בָּהְשַׁמָּה֙ מֵהֶ֔ם וְהֵ֖ם יִרְצ֣וּ אֶת־עֲוֺנָ֑ם יַ֣עַן וּבְיַ֔עַן בְּמִשְׁפָּטַ֣י מָאָ֔סוּ וְאֶת־חֻקֹּתַ֖י גָּֽעֲלָ֥ה נַפְשָֽׁם׃"
          },
          {
            "num": "מד",
            "txt": "וְאַף־גַּם־זֹ֠את בִּֽהְיוֹתָ֞ם בְּאֶ֣רֶץ אֹֽיְבֵיהֶ֗ם לֹֽא־מְאַסְתִּ֤ים וְלֹֽא־גְעַלְתִּים֙ לְכַלֹּתָ֔ם לְהָפֵ֥ר בְּרִיתִ֖י אִתָּ֑ם כִּ֛י אֲנִ֥י יְהוָ֖ה אֱלֹֽהֵיהֶֽם׃"
          },
          {
            "num": "מה",
            "txt": "וְזָֽכַרְתִּ֥י לָהֶ֖ם בְּרִ֣ית רִֽאשֹׁנִ֑ים אֲשֶׁ֣ר הוֹצֵֽאתִי־אֹתָם֩ מֵאֶ֨רֶץ מִצְרַ֜יִם לְעֵינֵ֣י הַגּוֹיִ֗ם לִֽהְי֥וֹת לָהֶ֛ם לֵֽאלֹהִ֖ים אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "מו",
            "txt": "אֵ֠לֶּה הַֽחֻקִּ֣ים וְהַמִּשְׁפָּטִים֮ וְהַתּוֹרֹת֒ אֲשֶׁר֙ נָתַ֣ן יְהוָ֔ה בֵּינ֕וֹ וּבֵ֖ין בְּנֵ֣י יִשְׂרָאֵ֑ל בְּהַ֥ר סִינַ֖י בְּיַד־מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "כז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּ֞ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם אִ֕ישׁ כִּ֥י יַפְלִ֖א נֶ֑דֶר בְּעֶרְכְּךָ֥ נְפָשֹׁ֖ת לַֽיהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וְהָיָ֤ה עֶרְכְּךָ֙ הַזָּכָ֔ר מִבֶּן֙ עֶשְׂרִ֣ים שָׁנָ֔ה וְעַ֖ד בֶּן־שִׁשִּׁ֣ים שָׁנָ֑ה וְהָיָ֣ה עֶרְכְּךָ֗ חֲמִשִּׁ֛ים שֶׁ֥קֶל כֶּ֖סֶף בְּשֶׁ֥קֶל הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "ד",
            "txt": "וְאִם־נְקֵבָ֖ה הִ֑וא וְהָיָ֥ה עֶרְכְּךָ֖ שְׁלֹשִׁ֥ים שָֽׁקֶל׃"
          },
          {
            "num": "ה",
            "txt": "וְאִ֨ם מִבֶּן־חָמֵ֜שׁ שָׁנִ֗ים וְעַד֙ בֶּן־עֶשְׂרִ֣ים שָׁנָ֔ה וְהָיָ֧ה עֶרְכְּךָ֛ הַזָּכָ֖ר עֶשְׂרִ֣ים שְׁקָלִ֑ים וְלַנְּקֵבָ֖ה עֲשֶׂ֥רֶת שְׁקָלִֽים׃"
          },
          {
            "num": "ו",
            "txt": "וְאִ֣ם מִבֶּן־חֹ֗דֶשׁ וְעַד֙ בֶּן־חָמֵ֣שׁ שָׁנִ֔ים וְהָיָ֤ה עֶרְכְּךָ֙ הַזָּכָ֔ר חֲמִשָּׁ֥ה שְׁקָלִ֖ים כָּ֑סֶף וְלַנְּקֵבָ֣ה עֶרְכְּךָ֔ שְׁלֹ֥שֶׁת שְׁקָלִ֖ים כָּֽסֶף׃"
          },
          {
            "num": "ז",
            "txt": "וְ֠אִם מִבֶּן־שִׁשִּׁ֨ים שָׁנָ֤ה וָמַ֨עְלָה֙ אִם־זָכָ֔ר וְהָיָ֣ה עֶרְכְּךָ֔ חֲמִשָּׁ֥ה עָשָׂ֖ר שָׁ֑קֶל וְלַנְּקֵבָ֖ה עֲשָׂרָ֥ה שְׁקָלִֽים׃"
          },
          {
            "num": "ח",
            "txt": "וְאִם־מָ֥ךְ הוּא֙ מֵֽעֶרְכֶּ֔ךָ וְהֶֽעֱמִידוֹ֙ לִפְנֵ֣י הַכֹּהֵ֔ן וְהֶֽעֱרִ֥יךְ אֹת֖וֹ הַכֹּהֵ֑ן עַל־פִּ֗י אֲשֶׁ֤ר תַּשִּׂיג֙ יַ֣ד הַנֹּדֵ֔ר יַֽעֲרִיכֶ֖נּוּ הַכֹּהֵֽן׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "וְאִ֨ם־בְּהֵמָ֔ה אֲשֶׁ֨ר יַקְרִ֧יבוּ מִמֶּ֛נָּה קָרְבָּ֖ן לַֽיהוָ֑ה כֹּל֩ אֲשֶׁ֨ר יִתֵּ֥ן מִמֶּ֛נּוּ לַֽיהוָ֖ה יִֽהְיֶה־קֹּֽדֶשׁ׃"
          },
          {
            "num": "י",
            "txt": "לֹ֣א יַֽחֲלִיפֶ֗נּוּ וְלֹֽא־יָמִ֥יר אֹת֛וֹ ט֥וֹב בְּרָ֖ע אוֹ־רַ֣ע בְּט֑וֹב וְאִם־הָמֵ֨ר יָמִ֤יר בְּהֵמָה֙ בִּבְהֵמָ֔ה וְהָֽיָה־ה֥וּא וּתְמֽוּרָת֖וֹ יִֽהְיֶה־קֹּֽדֶשׁ׃"
          },
          {
            "num": "יא",
            "txt": "וְאִם֙ כָּל־בְּהֵמָ֣ה טְמֵאָ֔ה אֲ֠שֶׁר לֹֽא־יַקְרִ֧יבוּ מִמֶּ֛נָּה קָרְבָּ֖ן לַֽיהוָ֑ה וְהֶֽעֱמִ֥יד אֶת־הַבְּהֵמָ֖ה לִפְנֵ֥י הַכֹּהֵֽן׃"
          },
          {
            "num": "יב",
            "txt": "וְהֶֽעֱרִ֤יךְ הַכֹּהֵן֙ אֹתָ֔הּ בֵּ֥ין ט֖וֹב וּבֵ֣ין רָ֑ע כְּעֶרְכְּךָ֥ הַכֹּהֵ֖ן כֵּ֥ן יִֽהְיֶֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְאִם־גָּאֹ֖ל יִגְאָלֶ֑נָּה וְיָסַ֥ף חֲמִֽישִׁת֖וֹ עַל־עֶרְכֶּֽךָ׃"
          },
          {
            "num": "יד",
            "txt": "וְאִ֗ישׁ כִּֽי־יַקְדִּ֨שׁ אֶת־בֵּית֥וֹ קֹ֨דֶשׁ֙ לַֽיהוָ֔ה וְהֶֽעֱרִיכוֹ֙ הַכֹּהֵ֔ן בֵּ֥ין ט֖וֹב וּבֵ֣ין רָ֑ע כַּֽאֲשֶׁ֨ר יַֽעֲרִ֥יךְ אֹת֛וֹ הַכֹּהֵ֖ן כֵּ֥ן יָקֽוּם׃"
          },
          {
            "num": "טו",
            "txt": "וְאִ֨ם־הַמַּקְדִּ֔ישׁ יִגְאַ֖ל אֶת־בֵּית֑וֹ וְ֠יָסַף חֲמִישִׁ֧ית כֶּֽסֶף־עֶרְכְּךָ֛ עָלָ֖יו וְהָ֥יָה לֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "וְאִ֣ם ׀ מִשְּׂדֵ֣ה אֲחֻזָּת֗וֹ יַקְדִּ֥ישׁ אִישׁ֙ לַֽיהוָ֔ה וְהָיָ֥ה עֶרְכְּךָ֖ לְפִ֣י זַרְע֑וֹ זֶ֚רַע חֹ֣מֶר שְׂעֹרִ֔ים בַּֽחֲמִשִּׁ֖ים שֶׁ֥קֶל כָּֽסֶף׃"
          },
          {
            "num": "יז",
            "txt": "אִם־מִשְּׁנַ֥ת הַיֹּבֵ֖ל יַקְדִּ֣ישׁ שָׂדֵ֑הוּ כְּעֶרְכְּךָ֖ יָקֽוּם׃"
          },
          {
            "num": "יח",
            "txt": "וְאִם־אַחַ֣ר הַיֹּבֵל֮ יַקְדִּ֣ישׁ שָׂדֵהוּ֒ וְחִשַּׁב־ל֨וֹ הַכֹּהֵ֜ן אֶת־הַכֶּ֗סֶף עַל־פִּ֤י הַשָּׁנִים֙ הַנּ֣וֹתָרֹ֔ת עַ֖ד שְׁנַ֣ת הַיֹּבֵ֑ל וְנִגְרַ֖ע מֵֽעֶרְכֶּֽךָ׃"
          },
          {
            "num": "יט",
            "txt": "וְאִם־גָּאֹ֤ל יִגְאַל֙ אֶת־הַשָּׂדֶ֔ה הַמַּקְדִּ֖ישׁ אֹת֑וֹ וְ֠יָסַף חֲמִשִׁ֧ית כֶּֽסֶף־עֶרְכְּךָ֛ עָלָ֖יו וְקָ֥ם לֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וְאִם־לֹ֤א יִגְאַל֙ אֶת־הַשָּׂדֶ֔ה וְאִם־מָכַ֥ר אֶת־הַשָּׂדֶ֖ה לְאִ֣ישׁ אַחֵ֑ר לֹ֥א יִגָּאֵ֖ל עֽוֹד׃"
          },
          {
            "num": "כא",
            "txt": "וְהָיָ֨ה הַשָּׂדֶ֜ה בְּצֵאת֣וֹ בַיֹּבֵ֗ל קֹ֛דֶשׁ לַֽיהוָ֖ה כִּשְׂדֵ֣ה הַחֵ֑רֶם לַכֹּהֵ֖ן תִּֽהְיֶ֥ה אֲחֻזָּתֽוֹ׃"
          },
          {
            "num": "כב",
            "txt": "וְאִם֙ אֶת־שְׂדֵ֣ה מִקְנָת֔וֹ אֲשֶׁ֕ר לֹ֖א מִשְּׂדֵ֣ה אֲחֻזָּת֑וֹ יַקְדִּ֖ישׁ לַֽיהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וְחִשַּׁב־ל֣וֹ הַכֹּהֵ֗ן אֵ֚ת מִכְסַ֣ת הָֽעֶרְכְּךָ֔ עַ֖ד שְׁנַ֣ת הַיֹּבֵ֑ל וְנָתַ֤ן אֶת־הָֽעֶרְכְּךָ֙ בַּיּ֣וֹם הַה֔וּא קֹ֖דֶשׁ לַֽיהוָֽה׃"
          },
          {
            "num": "כד",
            "txt": "בִּשְׁנַ֤ת הַיּוֹבֵל֙ יָשׁ֣וּב הַשָּׂדֶ֔ה לַֽאֲשֶׁ֥ר קָנָ֖הוּ מֵֽאִתּ֑וֹ לַֽאֲשֶׁר־ל֖וֹ אֲחֻזַּ֥ת הָאָֽרֶץ׃"
          },
          {
            "num": "כה",
            "txt": "וְכָ֨ל־עֶרְכְּךָ֔ יִֽהְיֶ֖ה בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ עֶשְׂרִ֥ים גֵּרָ֖ה יִֽהְיֶ֥ה הַשָּֽׁקֶל׃"
          },
          {
            "num": "כו",
            "txt": "אַךְ־בְּכ֞וֹר אֲשֶׁר־יְבֻכַּ֤ר לַֽיהוָה֙ בִּבְהֵמָ֔ה לֹֽא־יַקְדִּ֥ישׁ אִ֖ישׁ אֹת֑וֹ אִם־שׁ֣וֹר אִם־שֶׂ֔ה לַֽיהוָ֖ה הֽוּא׃"
          },
          {
            "num": "כז",
            "txt": "וְאִ֨ם בַּבְּהֵמָ֤ה הַטְּמֵאָה֙ וּפָדָ֣ה בְעֶרְכֶּ֔ךָ וְיָסַ֥ף חֲמִֽשִׁת֖וֹ עָלָ֑יו וְאִם־לֹ֥א יִגָּאֵ֖ל וְנִמְכַּ֥ר בְּעֶרְכֶּֽךָ׃"
          },
          {
            "num": "כח",
            "txt": "אַךְ־כָּל־חֵ֡רֶם אֲשֶׁ֣ר יַֽחֲרִם֩ אִ֨ישׁ לַֽיהוָ֜ה מִכָּל־אֲשֶׁר־ל֗וֹ מֵֽאָדָ֤ם וּבְהֵמָה֙ וּמִשְּׂדֵ֣ה אֲחֻזָּת֔וֹ לֹ֥א יִמָּכֵ֖ר וְלֹ֣א יִגָּאֵ֑ל כָּל־חֵ֕רֶם קֹֽדֶשׁ־קָֽדָשִׁ֥ים ה֖וּא לַֽיהוָֽה׃"
          },
          {
            "num": "כט",
            "txt": "כָּל־חֵ֗רֶם אֲשֶׁ֧ר יָֽחֳרַ֛ם מִן־הָֽאָדָ֖ם לֹ֣א יִפָּדֶ֑ה מ֖וֹת יוּמָֽת׃"
          },
          {
            "num": "ל",
            "txt": "וְכָל־מַעְשַׂ֨ר הָאָ֜רֶץ מִזֶּ֤רַע הָאָ֨רֶץ֙ מִפְּרִ֣י הָעֵ֔ץ לַֽיהוָ֖ה ה֑וּא קֹ֖דֶשׁ לַֽיהוָֽה׃"
          },
          {
            "num": "לא",
            "txt": "וְאִם־גָּאֹ֥ל יִגְאַ֛ל אִ֖ישׁ מִמַּֽעַשְׂר֑וֹ חֲמִֽשִׁית֖וֹ יֹסֵ֥ף עָלָֽיו׃"
          },
          {
            "num": "לב",
            "txt": "וְכָל־מַעְשַׂ֤ר בָּקָר֙ וָצֹ֔אן כֹּ֥ל אֲשֶׁר־יַֽעֲבֹ֖ר תַּ֣חַת הַשָּׁ֑בֶט הָֽעֲשִׂירִ֕י יִֽהְיֶה־קֹּ֖דֶשׁ לַֽיהוָֽה׃"
          },
          {
            "num": "לג",
            "txt": "לֹ֧א יְבַקֵּ֛ר בֵּֽין־ט֥וֹב לָרַ֖ע וְלֹ֣א יְמִירֶ֑נּוּ וְאִם־הָמֵ֣ר יְמִירֶ֔נּוּ וְהָֽיָה־ה֧וּא וּתְמֽוּרָת֛וֹ יִֽהְיֶה־קֹּ֖דֶשׁ לֹ֥א יִגָּאֵֽל׃"
          },
          {
            "num": "לד",
            "txt": "אֵ֣לֶּה הַמִּצְוֺ֗ת אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֶת־מֹשֶׁ֖ה אֶל־בְּנֵ֣י יִשְׂרָאֵ֑ל בְּהַ֖ר סִינָֽי׃ {ש}"
          }
        ]
      }
    ]
  },
  "במדבר": {
    "chapters": [
      {
        "num": "א",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֨ר יְהוָ֧ה אֶל־מֹשֶׁ֛ה בְּמִדְבַּ֥ר סִינַ֖י בְּאֹ֣הֶל מוֹעֵ֑ד בְּאֶחָד֩ לַחֹ֨דֶשׁ הַשֵּׁנִ֜י בַּשָּׁנָ֣ה הַשֵּׁנִ֗ית לְצֵאתָ֛ם מֵאֶ֥רֶץ מִצְרַ֖יִם לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "שְׂא֗וּ אֶת־רֹאשׁ֙ כָּל־עֲדַ֣ת בְּנֵֽי־יִשְׂרָאֵ֔ל לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמ֔וֹת כָּל־זָכָ֖ר לְגֻלְגְּלֹתָֽם׃"
          },
          {
            "num": "ג",
            "txt": "מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כָּל־יֹצֵ֥א צָבָ֖א בְּיִשְׂרָאֵ֑ל תִּפְקְד֥וּ אֹתָ֛ם לְצִבְאֹתָ֖ם אַתָּ֥ה וְאַֽהֲרֹֽן׃"
          },
          {
            "num": "ד",
            "txt": "וְאִתְּכֶ֣ם יִֽהְי֔וּ אִ֥ישׁ אִ֖ישׁ לַמַּטֶּ֑ה אִ֛ישׁ רֹ֥אשׁ לְבֵית־אֲבֹתָ֖יו הֽוּא׃"
          },
          {
            "num": "ה",
            "txt": "וְאֵ֨לֶּה֙ שְׁמ֣וֹת הָֽאֲנָשִׁ֔ים אֲשֶׁ֥ר יַֽעַמְד֖וּ אִתְּכֶ֑ם לִרְאוּבֵ֕ן אֱלִיצ֖וּר בֶּן־שְׁדֵיאֽוּר׃"
          },
          {
            "num": "ו",
            "txt": "לְשִׁמְע֕וֹן שְׁלֻֽמִיאֵ֖ל בֶּן־צוּרִֽישַׁדָּֽי׃"
          },
          {
            "num": "ז",
            "txt": "לִֽיהוּדָ֕ה נַחְשׁ֖וֹן בֶּן־עַמִּֽינָדָֽב׃"
          },
          {
            "num": "ח",
            "txt": "לְיִ֨שָּׂשכָ֔ר נְתַנְאֵ֖ל בֶּן־צוּעָֽר׃"
          },
          {
            "num": "ט",
            "txt": "לִזְבוּלֻ֕ן אֱלִיאָ֖ב בֶּן־חֵלֹֽן׃"
          },
          {
            "num": "י",
            "txt": "לִבְנֵ֣י יוֹסֵ֔ף לְאֶפְרַ֕יִם אֱלִֽישָׁמָ֖ע בֶּן־עַמִּיה֑וּד לִמְנַשֶּׁ֕ה גַּמְלִיאֵ֖ל בֶּן־פְּדָהצֽוּר׃"
          },
          {
            "num": "יא",
            "txt": "לְבִ֨נְיָמִ֔ן אֲבִידָ֖ן בֶּן־גִּדְעֹנִֽי׃"
          },
          {
            "num": "יב",
            "txt": "לְדָ֕ן אֲחִיעֶ֖זֶר בֶּן־עַמִּֽישַׁדָּֽי׃"
          },
          {
            "num": "יג",
            "txt": "לְאָשֵׁ֕ר פַּגְעִיאֵ֖ל בֶּן־עָכְרָֽן׃"
          },
          {
            "num": "יד",
            "txt": "לְגָ֕ד אֶלְיָסָ֖ף בֶּן־דְּעוּאֵֽל׃"
          },
          {
            "num": "טו",
            "txt": "לְנַ֨פְתָּלִ֔י אֲחִירַ֖ע בֶּן־עֵינָֽן׃"
          },
          {
            "num": "טז",
            "txt": "אֵ֚לֶּה קריאי (קְרוּאֵ֣י) הָֽעֵדָ֔ה נְשִׂיאֵ֖י מַטּ֣וֹת אֲבוֹתָ֑ם רָאשֵׁ֛י אַלְפֵ֥י יִשְׂרָאֵ֖ל הֵֽם׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּקַּ֥ח מֹשֶׁ֖ה וְאַֽהֲרֹ֑ן אֵ֚ת הָֽאֲנָשִׁ֣ים הָאֵ֔לֶּה אֲשֶׁ֥ר נִקְּב֖וּ בְּשֵׁמֹֽת׃"
          },
          {
            "num": "יח",
            "txt": "וְאֵ֨ת כָּל־הָֽעֵדָ֜ה הִקְהִ֗ילוּ בְּאֶחָד֙ לַחֹ֣דֶשׁ הַשֵּׁנִ֔י וַיִּתְיַֽלְד֥וּ עַל־מִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמ֗וֹת מִבֶּ֨ן עֶשְׂרִ֥ים שָׁנָ֛ה וָמַ֖עְלָה לְגֻלְגְּלֹתָֽם׃"
          },
          {
            "num": "יט",
            "txt": "כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶׁ֑ה וַֽיִּפְקְדֵ֖ם בְּמִדְבַּ֥ר סִינָֽי׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וַיִּֽהְי֤וּ בְנֵֽי־רְאוּבֵן֙ בְּכֹ֣ר יִשְׂרָאֵ֔ל תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֤ר שֵׁמוֹת֙ לְגֻלְגְּלֹתָ֔ם כָּל־זָכָ֗ר מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "כא",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה רְאוּבֵ֑ן שִׁשָּׁ֧ה וְאַרְבָּעִ֛ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "לִבְנֵ֣י שִׁמְע֔וֹן תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם פְּקֻדָ֗יו בְּמִסְפַּ֤ר שֵׁמוֹת֙ לְגֻלְגְּלֹתָ֔ם כָּל־זָכָ֗ר מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "כג",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה שִׁמְע֑וֹן תִּשְׁעָ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וּשְׁלֹ֥שׁ מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "כד",
            "txt": "לִבְנֵ֣י גָ֔ד תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמ֗וֹת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "כה",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה גָ֑ד חֲמִשָּׁ֤ה וְאַרְבָּעִים֙ אֶ֔לֶף וְשֵׁ֥שׁ מֵא֖וֹת וַֽחֲמִשִּֽׁים׃ {פ}"
          },
          {
            "num": "כו",
            "txt": "לִבְנֵ֣י יְהוּדָ֔ה תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמֹ֗ת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "כז",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה יְהוּדָ֑ה אַרְבָּעָ֧ה וְשִׁבְעִ֛ים אֶ֖לֶף וְשֵׁ֥שׁ מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "כח",
            "txt": "לִבְנֵ֣י יִשָּׂשכָ֔ר תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמֹ֗ת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "כט",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה יִשָּׂשכָ֑ר אַרְבָּעָ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "ל",
            "txt": "לִבְנֵ֣י זְבוּלֻ֔ן תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמֹ֗ת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "לא",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה זְבוּלֻ֑ן שִׁבְעָ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "לב",
            "txt": "לִבְנֵ֤י יוֹסֵף֙ לִבְנֵ֣י אֶפְרַ֔יִם תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמֹ֗ת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "לג",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה אֶפְרָ֑יִם אַרְבָּעִ֥ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "לד",
            "txt": "לִבְנֵ֣י מְנַשֶּׁ֔ה תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמ֗וֹת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "לה",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה מְנַשֶּׁ֑ה שְׁנַ֧יִם וּשְׁלֹשִׁ֛ים אֶ֖לֶף וּמָאתָֽיִם׃ {פ}"
          },
          {
            "num": "לו",
            "txt": "לִבְנֵ֣י בִנְיָמִ֔ן תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמֹ֗ת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "לז",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה בִנְיָמִ֑ן חֲמִשָּׁ֧ה וּשְׁלֹשִׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "לח",
            "txt": "לִבְנֵ֣י דָ֔ן תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמֹ֗ת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "לט",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה דָ֑ן שְׁנַ֧יִם וְשִׁשִּׁ֛ים אֶ֖לֶף וּשְׁבַ֥ע מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "מ",
            "txt": "לִבְנֵ֣י אָשֵׁ֔ר תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמֹ֗ת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "מא",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה אָשֵׁ֑ר אֶחָ֧ד וְאַרְבָּעִ֛ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "מב",
            "txt": "בְּנֵ֣י נַפְתָּלִ֔י תּֽוֹלְדֹתָ֥ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם בְּמִסְפַּ֣ר שֵׁמֹ֗ת מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כֹּ֖ל יֹצֵ֥א צָבָֽא׃"
          },
          {
            "num": "מג",
            "txt": "פְּקֻֽדֵיהֶ֖ם לְמַטֵּ֣ה נַפְתָּלִ֑י שְׁלֹשָׁ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃ {פ}"
          },
          {
            "num": "מד",
            "txt": "אֵ֣לֶּה הַפְּקֻדִ֡ים אֲשֶׁר֩ פָּקַ֨ד מֹשֶׁ֤ה וְאַֽהֲרֹן֙ וּנְשִׂיאֵ֣י יִשְׂרָאֵ֔ל שְׁנֵ֥ים עָשָׂ֖ר אִ֑ישׁ אִישׁ־אֶחָ֥ד לְבֵית־אֲבֹתָ֖יו הָיֽוּ׃"
          },
          {
            "num": "מה",
            "txt": "וַיִּֽהְי֛וּ כָּל־פְּקוּדֵ֥י בְנֵֽי־יִשְׂרָאֵ֖ל לְבֵ֣ית אֲבֹתָ֑ם מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה כָּל־יֹצֵ֥א צָבָ֖א בְּיִשְׂרָאֵֽל׃"
          },
          {
            "num": "מו",
            "txt": "וַיִּֽהְיוּ֙ כָּל־הַפְּקֻדִ֔ים שֵׁשׁ־מֵא֥וֹת אֶ֖לֶף וּשְׁלֹ֣שֶׁת אֲלָפִ֑ים וַֽחֲמֵ֥שׁ מֵא֖וֹת וַֽחֲמִשִּֽׁים׃"
          },
          {
            "num": "מז",
            "txt": "וְהַלְוִיִּ֖ם לְמַטֵּ֣ה אֲבֹתָ֑ם לֹ֥א הָתְפָּֽקְד֖וּ בְּתוֹכָֽם׃ {פ}"
          },
          {
            "num": "מח",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "מט",
            "txt": "אַ֣ךְ אֶת־מַטֵּ֤ה לֵוִי֙ לֹ֣א תִפְקֹ֔ד וְאֶת־רֹאשָׁ֖ם לֹ֣א תִשָּׂ֑א בְּת֖וֹךְ בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "נ",
            "txt": "וְאַתָּ֡ה הַפְקֵ֣ד אֶת־הַלְוִיִּם֩ עַל־מִשְׁכַּ֨ן הָֽעֵדֻ֜ת וְעַ֣ל כָּל־כֵּלָיו֮ וְעַ֣ל כָּל־אֲשֶׁר־לוֹ֒ הֵ֜מָּה יִשְׂא֤וּ אֶת־הַמִּשְׁכָּן֙ וְאֶת־כָּל־כֵּלָ֔יו וְהֵ֖ם יְשָֽׁרְתֻ֑הוּ וְסָבִ֥יב לַמִּשְׁכָּ֖ן יַֽחֲנֽוּ׃"
          },
          {
            "num": "נא",
            "txt": "וּבִנְסֹ֣עַ הַמִּשְׁכָּ֗ן יוֹרִ֤ידוּ אֹתוֹ֙ הַלְוִיִּ֔ם וּבַֽחֲנֹת֙ הַמִּשְׁכָּ֔ן יָקִ֥ימוּ אֹת֖וֹ הַלְוִיִּ֑ם וְהַזָּ֥ר הַקָּרֵ֖ב יוּמָֽת׃"
          },
          {
            "num": "נב",
            "txt": "וְחָנ֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל אִ֧ישׁ עַֽל־מַחֲנֵ֛הוּ וְאִ֥ישׁ עַל־דִּגְל֖וֹ לְצִבְאֹתָֽם׃"
          },
          {
            "num": "נג",
            "txt": "וְהַלְוִיִּ֞ם יַֽחֲנ֤וּ סָבִיב֙ לְמִשְׁכַּ֣ן הָֽעֵדֻ֔ת וְלֹֽא־יִהְיֶ֣ה קֶ֔צֶף עַל־עֲדַ֖ת בְּנֵ֣י יִשְׂרָאֵ֑ל וְשָֽׁמְרוּ֙ הַלְוִיִּ֔ם אֶת־מִשְׁמֶ֖רֶת מִשְׁכַּ֥ן הָֽעֵדֽוּת׃"
          },
          {
            "num": "נד",
            "txt": "וַֽיַּעֲשׂ֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל כְּ֠כֹל אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֶת־מֹשֶׁ֖ה כֵּ֥ן עָשֽׂוּ׃ {פ}"
          }
        ]
      },
      {
        "num": "ב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "אִ֣ישׁ עַל־דִּגְל֤וֹ בְאֹתֹת֙ לְבֵ֣ית אֲבֹתָ֔ם יַֽחֲנ֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל מִנֶּ֕גֶד סָבִ֥יב לְאֹֽהֶל־מוֹעֵ֖ד יַֽחֲנֽוּ׃"
          },
          {
            "num": "ג",
            "txt": "וְהַֽחֹנִים֙ קֵ֣דְמָה מִזְרָ֔חָה דֶּ֛גֶל מַֽחֲנֵ֥ה יְהוּדָ֖ה לְצִבְאֹתָ֑ם וְנָשִׂיא֙ לִבְנֵ֣י יְהוּדָ֔ה נַחְשׁ֖וֹן בֶּן־עַמִּֽינָדָֽב׃"
          },
          {
            "num": "ד",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם אַרְבָּעָ֧ה וְשִׁבְעִ֛ים אֶ֖לֶף וְשֵׁ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "ה",
            "txt": "וְהַֽחֹנִ֥ים עָלָ֖יו מַטֵּ֣ה יִשָּׂשכָ֑ר וְנָשִׂיא֙ לִבְנֵ֣י יִשָּׂשכָ֔ר נְתַנְאֵ֖ל בֶּן־צוּעָֽר׃"
          },
          {
            "num": "ו",
            "txt": "וּצְבָא֖וֹ וּפְקֻדָ֑יו אַרְבָּעָ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃"
          },
          {
            "num": "ז",
            "txt": "מַטֵּ֖ה זְבוּלֻ֑ן וְנָשִׂיא֙ לִבְנֵ֣י זְבוּלֻ֔ן אֱלִיאָ֖ב בֶּן־חֵלֹֽן׃"
          },
          {
            "num": "ח",
            "txt": "וּצְבָא֖וֹ וּפְקֻדָ֑יו שִׁבְעָ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃"
          },
          {
            "num": "ט",
            "txt": "כָּֽל־הַפְּקֻדִ֞ים לְמַֽחֲנֵ֣ה יְהוּדָ֗ה מְאַ֨ת אֶ֜לֶף וּשְׁמֹנִ֥ים אֶ֛לֶף וְשֵֽׁשֶׁת־אֲלָפִ֥ים וְאַרְבַּע־מֵא֖וֹת לְצִבְאֹתָ֑ם רִֽאשֹׁנָ֖ה יִסָּֽעוּ׃ {ס}"
          },
          {
            "num": "י",
            "txt": "דֶּ֣גֶל מַֽחֲנֵ֧ה רְאוּבֵ֛ן תֵּימָ֖נָה לְצִבְאֹתָ֑ם וְנָשִׂיא֙ לִבְנֵ֣י רְאוּבֵ֔ן אֱלִיצ֖וּר בֶּן־שְׁדֵיאֽוּר׃"
          },
          {
            "num": "יא",
            "txt": "וּצְבָא֖וֹ וּפְקֻדָ֑יו שִׁשָּׁ֧ה וְאַרְבָּעִ֛ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "יב",
            "txt": "וְהַֽחוֹנִ֥ם עָלָ֖יו מַטֵּ֣ה שִׁמְע֑וֹן וְנָשִׂיא֙ לִבְנֵ֣י שִׁמְע֔וֹן שְׁלֻֽמִיאֵ֖ל בֶּן־צוּרִֽישַׁדָּֽי׃"
          },
          {
            "num": "יג",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם תִּשְׁעָ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וּשְׁלֹ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "יד",
            "txt": "וּמַטֵּ֖ה גָּ֑ד וְנָשִׂיא֙ לִבְנֵ֣י גָ֔ד אֶלְיָסָ֖ף בֶּן־רְעוּאֵֽל׃"
          },
          {
            "num": "טו",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם חֲמִשָּׁ֤ה וְאַרְבָּעִים֙ אֶ֔לֶף וְשֵׁ֥שׁ מֵא֖וֹת וַֽחֲמִשִּֽׁים׃"
          },
          {
            "num": "טז",
            "txt": "כָּֽל־הַפְּקֻדִ֞ים לְמַֽחֲנֵ֣ה רְאוּבֵ֗ן מְאַ֨ת אֶ֜לֶף וְאֶחָ֨ד וַֽחֲמִשִּׁ֥ים אֶ֛לֶף וְאַרְבַּע־מֵא֥וֹת וַֽחֲמִשִּׁ֖ים לְצִבְאֹתָ֑ם וּשְׁנִיִּ֖ם יִסָּֽעוּ׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וְנָסַ֧ע אֹֽהֶל־מוֹעֵ֛ד מַֽחֲנֵ֥ה הַלְוִיִּ֖ם בְּת֣וֹךְ הַֽמַּחֲנֹ֑ת כַּֽאֲשֶׁ֤ר יַֽחֲנוּ֙ כֵּ֣ן יִסָּ֔עוּ אִ֥ישׁ עַל־יָד֖וֹ לְדִגְלֵיהֶֽם׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "דֶּ֣גֶל מַֽחֲנֵ֥ה אֶפְרַ֛יִם לְצִבְאֹתָ֖ם יָ֑מָּה וְנָשִׂיא֙ לִבְנֵ֣י אֶפְרַ֔יִם אֱלִֽישָׁמָ֖ע בֶּן־עַמִּיהֽוּד׃"
          },
          {
            "num": "יט",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם אַרְבָּעִ֥ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "כ",
            "txt": "וְעָלָ֖יו מַטֵּ֣ה מְנַשֶּׁ֑ה וְנָשִׂיא֙ לִבְנֵ֣י מְנַשֶּׁ֔ה גַּמְלִיאֵ֖ל בֶּן־פְּדָהצֽוּר׃"
          },
          {
            "num": "כא",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם שְׁנַ֧יִם וּשְׁלֹשִׁ֛ים אֶ֖לֶף וּמָאתָֽיִם׃"
          },
          {
            "num": "כב",
            "txt": "וּמַטֵּ֖ה בִּנְיָמִ֑ן וְנָשִׂיא֙ לִבְנֵ֣י בִנְיָמִ֔ן אֲבִידָ֖ן בֶּן־גִּדְעֹנִֽי׃"
          },
          {
            "num": "כג",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם חֲמִשָּׁ֧ה וּשְׁלֹשִׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃"
          },
          {
            "num": "כד",
            "txt": "כָּֽל־הַפְּקֻדִ֞ים לְמַֽחֲנֵ֣ה אֶפְרַ֗יִם מְאַ֥ת אֶ֛לֶף וּשְׁמֹֽנַת־אֲלָפִ֥ים וּמֵאָ֖ה לְצִבְאֹתָ֑ם וּשְׁלִשִׁ֖ים יִסָּֽעוּ׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "דֶּ֣גֶל מַֽחֲנֵ֥ה דָ֛ן צָפֹ֖נָה לְצִבְאֹתָ֑ם וְנָשִׂיא֙ לִבְנֵ֣י דָ֔ן אֲחִיעֶ֖זֶר בֶּן־עַמִּֽישַׁדָּֽי׃"
          },
          {
            "num": "כו",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם שְׁנַ֧יִם וְשִׁשִּׁ֛ים אֶ֖לֶף וּשְׁבַ֥ע מֵאֽוֹת׃"
          },
          {
            "num": "כז",
            "txt": "וְהַֽחֹנִ֥ים עָלָ֖יו מַטֵּ֣ה אָשֵׁ֑ר וְנָשִׂיא֙ לִבְנֵ֣י אָשֵׁ֔ר פַּגְעִיאֵ֖ל בֶּן־עָכְרָֽן׃"
          },
          {
            "num": "כח",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם אֶחָ֧ד וְאַרְבָּעִ֛ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "כט",
            "txt": "וּמַטֵּ֖ה נַפְתָּלִ֑י וְנָשִׂיא֙ לִבְנֵ֣י נַפְתָּלִ֔י אֲחִירַ֖ע בֶּן־עֵינָֽן׃"
          },
          {
            "num": "ל",
            "txt": "וּצְבָא֖וֹ וּפְקֻֽדֵיהֶ֑ם שְׁלֹשָׁ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃"
          },
          {
            "num": "לא",
            "txt": "כָּל־הַפְּקֻדִים֙ לְמַ֣חֲנֵה דָ֔ן מְאַ֣ת אֶ֗לֶף וְשִׁבְעָ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וְשֵׁ֣שׁ מֵא֑וֹת לָאַֽחֲרֹנָ֥ה יִסְע֖וּ לְדִגְלֵיהֶֽם׃ {פ}"
          },
          {
            "num": "לב",
            "txt": "אֵ֛לֶּה פְּקוּדֵ֥י בְנֵֽי־יִשְׂרָאֵ֖ל לְבֵ֣ית אֲבֹתָ֑ם כָּל־פְּקוּדֵ֤י הַֽמַּחֲנֹת֙ לְצִבְאֹתָ֔ם שֵׁשׁ־מֵא֥וֹת אֶ֨לֶף֙ וּשְׁלֹ֣שֶׁת אֲלָפִ֔ים וַֽחֲמֵ֥שׁ מֵא֖וֹת וַֽחֲמִשִּֽׁים׃"
          },
          {
            "num": "לג",
            "txt": "וְהַ֨לְוִיִּ֔ם לֹ֣א הָתְפָּֽקְד֔וּ בְּת֖וֹךְ בְּנֵ֣י יִשְׂרָאֵ֑ל כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "לד",
            "txt": "וַֽיַּעֲשׂ֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל כְּ֠כֹל אֲשֶׁר־צִוָּ֨ה יְהוָ֜ה אֶת־מֹשֶׁ֗ה כֵּֽן־חָנ֤וּ לְדִגְלֵיהֶם֙ וְכֵ֣ן נָסָ֔עוּ אִ֥ישׁ לְמִשְׁפְּחֹתָ֖יו עַל־בֵּ֥ית אֲבֹתָֽיו׃ {פ}"
          }
        ]
      },
      {
        "num": "ג",
        "verses": [
          {
            "num": "א",
            "txt": "וְאֵ֛לֶּה תּֽוֹלְדֹ֥ת אַֽהֲרֹ֖ן וּמֹשֶׁ֑ה בְּי֗וֹם דִּבֶּ֧ר יְהוָ֛ה אֶת־מֹשֶׁ֖ה בְּהַ֥ר סִינָֽי׃"
          },
          {
            "num": "ב",
            "txt": "וְאֵ֛לֶּה שְׁמ֥וֹת בְּֽנֵי־אַהֲרֹ֖ן הַבְּכֹ֣ר ׀ נָדָ֑ב וַֽאֲבִיה֕וּא אֶלְעָזָ֖ר וְאִֽיתָמָֽר׃"
          },
          {
            "num": "ג",
            "txt": "אֵ֗לֶּה שְׁמוֹת֙ בְּנֵ֣י אַֽהֲרֹ֔ן הַכֹּֽהֲנִ֖ים הַמְּשֻׁחִ֑ים אֲשֶׁר־מִלֵּ֥א יָדָ֖ם לְכַהֵֽן׃"
          },
          {
            "num": "ד",
            "txt": "וַיָּ֣מָת נָדָ֣ב וַֽאֲבִיה֣וּא לִפְנֵ֣י יְהוָ֡ה בְּֽהַקְרִבָם֩ אֵ֨שׁ זָרָ֜ה לִפְנֵ֤י יְהוָה֙ בְּמִדְבַּ֣ר סִינַ֔י וּבָנִ֖ים לֹֽא־הָי֣וּ לָהֶ֑ם וַיְכַהֵ֤ן אֶלְעָזָר֙ וְאִ֣יתָמָ֔ר עַל־פְּנֵ֖י אַֽהֲרֹ֥ן אֲבִיהֶֽם׃ {פ}"
          },
          {
            "num": "ה",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ו",
            "txt": "הַקְרֵב֙ אֶת־מַטֵּ֣ה לֵוִ֔י וְהַֽעֲמַדְתָּ֣ אֹת֔וֹ לִפְנֵ֖י אַֽהֲרֹ֣ן הַכֹּהֵ֑ן וְשֵֽׁרְת֖וּ אֹתֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וְשָֽׁמְר֣וּ אֶת־מִשְׁמַרְתּ֗וֹ וְאֶת־מִשְׁמֶ֨רֶת֙ כָּל־הָ֣עֵדָ֔ה לִפְנֵ֖י אֹ֣הֶל מוֹעֵ֑ד לַֽעֲבֹ֖ד אֶת־עֲבֹדַ֥ת הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "ח",
            "txt": "וְשָֽׁמְר֗וּ אֶֽת־כָּל־כְּלֵי֙ אֹ֣הֶל מוֹעֵ֔ד וְאֶת־מִשְׁמֶ֖רֶת בְּנֵ֣י יִשְׂרָאֵ֑ל לַֽעֲבֹ֖ד אֶת־עֲבֹדַ֥ת הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "ט",
            "txt": "וְנָֽתַתָּה֙ אֶת־הַלְוִיִּ֔ם לְאַֽהֲרֹ֖ן וּלְבָנָ֑יו נְתוּנִ֨ם נְתוּנִ֥ם הֵ֨מָּה֙ ל֔וֹ מֵאֵ֖ת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "י",
            "txt": "וְאֶת־אַֽהֲרֹ֤ן וְאֶת־בָּנָיו֙ תִּפְקֹ֔ד וְשָֽׁמְר֖וּ אֶת־כְּהֻנָּתָ֑ם וְהַזָּ֥ר הַקָּרֵ֖ב יוּמָֽת׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יב",
            "txt": "וַֽאֲנִ֞י הִנֵּ֧ה לָקַ֣חְתִּי אֶת־הַלְוִיִּ֗ם מִתּוֹךְ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל תַּ֧חַת כָּל־בְּכ֛וֹר פֶּ֥טֶר רֶ֖חֶם מִבְּנֵ֣י יִשְׂרָאֵ֑ל וְהָ֥יוּ לִ֖י הַלְוִיִּֽם׃"
          },
          {
            "num": "יג",
            "txt": "כִּ֣י לִי֮ כָּל־בְּכוֹר֒ בְּיוֹם֩ הַכֹּתִ֨י כָל־בְּכ֜וֹר בְּאֶ֣רֶץ מִצְרַ֗יִם הִקְדַּ֨שְׁתִּי לִ֤י כָל־בְּכוֹר֙ בְּיִשְׂרָאֵ֔ל מֵֽאָדָ֖ם עַד־בְּהֵמָ֑ה לִ֥י יִֽהְי֖וּ אֲנִ֥י יְהוָֽה׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "וַיְדַבֵּ֤ר יְהוָה֙ אֶל־מֹשֶׁ֔ה בְּמִדְבַּ֥ר סִינַ֖י לֵאמֹֽר׃"
          },
          {
            "num": "טו",
            "txt": "פְּקֹד֙ אֶת־בְּנֵ֣י לֵוִ֔י לְבֵ֥ית אֲבֹתָ֖ם לְמִשְׁפְּחֹתָ֑ם כָּל־זָכָ֛ר מִבֶּן־חֹ֥דֶשׁ וָמַ֖עְלָה תִּפְקְדֵֽם׃"
          },
          {
            "num": "טז",
            "txt": "וַיִּפְקֹ֥ד אֹתָ֛ם מֹשֶׁ֖ה עַל־פִּ֣י יְהוָ֑ה כַּֽאֲשֶׁ֖ר צֻוָּֽה׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּֽהְיוּ־אֵ֥לֶּה בְנֵֽי־לֵוִ֖י בִּשְׁמֹתָ֑ם גֵּֽרְשׁ֕וֹן וּקְהָ֖ת וּמְרָרִֽי׃"
          },
          {
            "num": "יח",
            "txt": "וְאֵ֛לֶּה שְׁמ֥וֹת בְּֽנֵי־גֵרְשׁ֖וֹן לְמִשְׁפְּחֹתָ֑ם לִבְנִ֖י וְשִׁמְעִֽי׃"
          },
          {
            "num": "יט",
            "txt": "וּבְנֵ֥י קְהָ֖ת לְמִשְׁפְּחֹתָ֑ם עַמְרָ֣ם וְיִצְהָ֔ר חֶבְר֖וֹן וְעֻזִּיאֵֽל׃"
          },
          {
            "num": "כ",
            "txt": "וּבְנֵ֧י מְרָרִ֛י לְמִשְׁפְּחֹתָ֖ם מַחְלִ֣י וּמוּשִׁ֑י אֵ֥לֶּה הֵ֛ם מִשְׁפְּחֹ֥ת הַלֵּוִ֖י לְבֵ֥ית אֲבֹתָֽם׃"
          },
          {
            "num": "כא",
            "txt": "לְגֵ֣רְשׁ֔וֹן מִשְׁפַּ֨חַת֙ הַלִּבְנִ֔י וּמִשְׁפַּ֖חַת הַשִּׁמְעִ֑י אֵ֣לֶּה הֵ֔ם מִשְׁפְּחֹ֖ת הַגֵּֽרְשֻׁנִּֽי׃"
          },
          {
            "num": "כב",
            "txt": "פְּקֻֽדֵיהֶם֙ בְּמִסְפַּ֣ר כָּל־זָכָ֔ר מִבֶּן־חֹ֖דֶשׁ וָמָ֑עְלָה פְּקֻ֣דֵיהֶ֔ם שִׁבְעַ֥ת אֲלָפִ֖ים וַֽחֲמֵ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "כג",
            "txt": "מִשְׁפְּחֹ֖ת הַגֵּֽרְשֻׁנִּ֑י אַֽחֲרֵ֧י הַמִּשְׁכָּ֛ן יַֽחֲנ֖וּ יָֽמָּה׃"
          },
          {
            "num": "כד",
            "txt": "וּנְשִׂ֥יא בֵֽית־אָ֖ב לַגֵּֽרְשֻׁנִּ֑י אֶלְיָסָ֖ף בֶּן־לָאֵֽל׃"
          },
          {
            "num": "כה",
            "txt": "וּמִשְׁמֶ֤רֶת בְּנֵֽי־גֵרְשׁוֹן֙ בְּאֹ֣הֶל מוֹעֵ֔ד הַמִּשְׁכָּ֖ן וְהָאֹ֑הֶל מִכְסֵ֕הוּ וּמָסַ֕ךְ פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "כו",
            "txt": "וְקַלְעֵ֣י הֶֽחָצֵ֗ר וְאֶת־מָסַךְ֙ פֶּ֣תַח הֶֽחָצֵ֔ר אֲשֶׁ֧ר עַל־הַמִּשְׁכָּ֛ן וְעַל־הַמִּזְבֵּ֖חַ סָבִ֑יב וְאֵת֙ מֵֽיתָרָ֔יו לְכֹ֖ל עֲבֹֽדָתֽוֹ׃ {ס}"
          },
          {
            "num": "כז",
            "txt": "וְלִקְהָ֗ת מִשְׁפַּ֤חַת הַֽעַמְרָמִי֙ וּמִשְׁפַּ֣חַת הַיִּצְהָרִ֔י וּמִשְׁפַּ֨חַת֙ הַֽחֶבְרֹנִ֔י וּמִשְׁפַּ֖חַת הָעָזִּֽיאֵלִ֑י אֵ֥לֶּה הֵ֖ם מִשְׁפְּחֹ֥ת הַקְּהָתִֽי׃"
          },
          {
            "num": "כח",
            "txt": "בְּמִסְפַּר֙ כָּל־זָכָ֔ר מִבֶּן־חֹ֖דֶשׁ וָמָ֑עְלָה שְׁמֹנַ֤ת אֲלָפִים֙ וְשֵׁ֣שׁ מֵא֔וֹת שֹֽׁמְרֵ֖י מִשְׁמֶ֥רֶת הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "כט",
            "txt": "מִשְׁפְּחֹ֥ת בְּנֵֽי־קְהָ֖ת יַֽחֲנ֑וּ עַ֛ל יֶ֥רֶךְ הַמִּשְׁכָּ֖ן תֵּימָֽנָה׃"
          },
          {
            "num": "ל",
            "txt": "וּנְשִׂ֥יא בֵֽית־אָ֖ב לְמִשְׁפְּחֹ֣ת הַקְּהָתִ֑י אֱלִֽיצָפָ֖ן בֶּן־עֻזִּיאֵֽל׃"
          },
          {
            "num": "לא",
            "txt": "וּמִשְׁמַרְתָּ֗ם הָֽאָרֹ֤ן וְהַשֻּׁלְחָן֙ וְהַמְּנֹרָ֣ה וְהַֽמִּזְבְּחֹ֔ת וּכְלֵ֣י הַקֹּ֔דֶשׁ אֲשֶׁ֥ר יְשָֽׁרְת֖וּ בָּהֶ֑ם וְהַ֨מָּסָ֔ךְ וְכֹ֖ל עֲבֹֽדָתֽוֹ׃"
          },
          {
            "num": "לב",
            "txt": "וּנְשִׂיא֙ נְשִׂיאֵ֣י הַלֵּוִ֔י אֶלְעָזָ֖ר בֶּן־אַֽהֲרֹ֣ן הַכֹּהֵ֑ן פְּקֻדַּ֕ת שֹֽׁמְרֵ֖י מִשְׁמֶ֥רֶת הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "לג",
            "txt": "לִמְרָרִ֕י מִשְׁפַּ֨חַת֙ הַמַּחְלִ֔י וּמִשְׁפַּ֖חַת הַמּוּשִׁ֑י אֵ֥לֶּה הֵ֖ם מִשְׁפְּחֹ֥ת מְרָרִֽי׃"
          },
          {
            "num": "לד",
            "txt": "וּפְקֻֽדֵיהֶם֙ בְּמִסְפַּ֣ר כָּל־זָכָ֔ר מִבֶּן־חֹ֖דֶשׁ וָמָ֑עְלָה שֵׁ֥שֶׁת אֲלָפִ֖ים וּמָאתָֽיִם׃"
          },
          {
            "num": "לה",
            "txt": "וּנְשִׂ֤יא בֵֽית־אָב֙ לְמִשְׁפְּחֹ֣ת מְרָרִ֔י צֽוּרִיאֵ֖ל בֶּן־אֲבִיחָ֑יִל עַ֣ל יֶ֧רֶךְ הַמִּשְׁכָּ֛ן יַֽחֲנ֖וּ צָפֹֽנָה׃"
          },
          {
            "num": "לו",
            "txt": "וּפְקֻדַּ֣ת מִשְׁמֶרֶת֮ בְּנֵ֣י מְרָרִי֒ קַרְשֵׁי֙ הַמִּשְׁכָּ֔ן וּבְרִיחָ֖יו וְעַמֻּדָ֣יו וַֽאֲדָנָ֑יו וְכָ֨ל־כֵּלָ֔יו וְכֹ֖ל עֲבֹֽדָתֽוֹ׃"
          },
          {
            "num": "לז",
            "txt": "וְעַמֻּדֵ֧י הֶֽחָצֵ֛ר סָבִ֖יב וְאַדְנֵיהֶ֑ם וִיתֵֽדֹתָ֖ם וּמֵֽיתְרֵיהֶֽם׃"
          },
          {
            "num": "לח",
            "txt": "וְהַֽחֹנִ֣ים לִפְנֵ֣י הַמִּשְׁכָּ֡ן קֵ֣דְמָה לִפְנֵי֩ אֹֽהֶל־מוֹעֵ֨ד ׀ מִזְרָ֜חָה מֹשֶׁ֣ה ׀ וְאַֽהֲרֹ֣ן וּבָנָ֗יו שֹֽׁמְרִים֙ מִשְׁמֶ֣רֶת הַמִּקְדָּ֔שׁ לְמִשְׁמֶ֖רֶת בְּנֵ֣י יִשְׂרָאֵ֑ל וְהַזָּ֥ר הַקָּרֵ֖ב יוּמָֽת׃"
          },
          {
            "num": "לט",
            "txt": "כָּל־פְּקוּדֵ֨י הַלְוִיִּ֜ם אֲשֶׁר֩ פָּקַ֨ד מֹשֶׁ֧ה וְׄאַֽׄהֲׄרֹ֛ׄןׄ עַל־פִּ֥י יְהוָ֖ה לְמִשְׁפְּחֹתָ֑ם כָּל־זָכָר֙ מִבֶּן־חֹ֣דֶשׁ וָמַ֔עְלָה שְׁנַ֥יִם וְעֶשְׂרִ֖ים אָֽלֶף׃ {ס}"
          },
          {
            "num": "מ",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה פְּקֹ֨ד כָּל־בְּכֹ֤ר זָכָר֙ לִבְנֵ֣י יִשְׂרָאֵ֔ל מִבֶּן־חֹ֖דֶשׁ וָמָ֑עְלָה וְשָׂ֕א אֵ֖ת מִסְפַּ֥ר שְׁמֹתָֽם׃"
          },
          {
            "num": "מא",
            "txt": "וְלָֽקַחְתָּ֨ אֶת־הַלְוִיִּ֥ם לִי֙ אֲנִ֣י יְהוָ֔ה תַּ֥חַת כָּל־בְּכֹ֖ר בִּבְנֵ֣י יִשְׂרָאֵ֑ל וְאֵת֙ בֶּֽהֱמַ֣ת הַלְוִיִּ֔ם תַּ֣חַת כָּל־בְּכ֔וֹר בְּבֶֽהֱמַ֖ת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "מב",
            "txt": "וַיִּפְקֹ֣ד מֹשֶׁ֔ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֹת֑וֹ אֶֽת־כָּל־בְּכ֖וֹר בִּבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "מג",
            "txt": "וַיְהִי֩ כָל־בְּכ֨וֹר זָכָ֜ר בְּמִסְפַּ֥ר שֵׁמֹ֛ת מִבֶּן־חֹ֥דֶשׁ וָמַ֖עְלָה לִפְקֻֽדֵיהֶ֑ם שְׁנַ֤יִם וְעֶשְׂרִים֙ אֶ֔לֶף שְׁלֹשָׁ֥ה וְשִׁבְעִ֖ים וּמָאתָֽיִם׃ {פ}"
          },
          {
            "num": "מד",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "מה",
            "txt": "קַ֣ח אֶת־הַלְוִיִּ֗ם תַּ֤חַת כָּל־בְּכוֹר֙ בִּבְנֵ֣י יִשְׂרָאֵ֔ל וְאֶת־בֶּֽהֱמַ֥ת הַלְוִיִּ֖ם תַּ֣חַת בְּהֶמְתָּ֑ם וְהָֽיוּ־לִ֥י הַלְוִיִּ֖ם אֲנִ֥י יְהוָֽה׃"
          },
          {
            "num": "מו",
            "txt": "וְאֵת֙ פְּדוּיֵ֣י הַשְּׁלֹשָׁ֔ה וְהַשִּׁבְעִ֖ים וְהַמָּאתָ֑יִם הָעֹֽדְפִים֙ עַל־הַלְוִיִּ֔ם מִבְּכ֖וֹר בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "מז",
            "txt": "וְלָֽקַחְתָּ֗ חֲמֵ֧שֶׁת חֲמֵ֛שֶׁת שְׁקָלִ֖ים לַגֻּלְגֹּ֑לֶת בְּשֶׁ֤קֶל הַקֹּ֨דֶשׁ֙ תִּקָּ֔ח עֶשְׂרִ֥ים גֵּרָ֖ה הַשָּֽׁקֶל׃"
          },
          {
            "num": "מח",
            "txt": "וְנָֽתַתָּ֣ה הַכֶּ֔סֶף לְאַֽהֲרֹ֖ן וּלְבָנָ֑יו פְּדוּיֵ֕י הָעֹֽדְפִ֖ים בָּהֶֽם׃"
          },
          {
            "num": "מט",
            "txt": "וַיִּקַּ֣ח מֹשֶׁ֔ה אֵ֖ת כֶּ֣סֶף הַפִּדְי֑וֹם מֵאֵת֙ הָעֹ֣דְפִ֔ים עַ֖ל פְּדוּיֵ֥י הַלְוִיִּֽם׃"
          },
          {
            "num": "נ",
            "txt": "מֵאֵ֗ת בְּכ֛וֹר בְּנֵ֥י יִשְׂרָאֵ֖ל לָקַ֣ח אֶת־הַכָּ֑סֶף חֲמִשָּׁ֨ה וְשִׁשִּׁ֜ים וּשְׁלֹ֥שׁ מֵא֛וֹת וָאֶ֖לֶף בְּשֶׁ֥קֶל הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "נא",
            "txt": "וַיִּתֵּ֨ן מֹשֶׁ֜ה אֶת־כֶּ֧סֶף הַפְּדֻיִ֛ם לְאַֽהֲרֹ֥ן וּלְבָנָ֖יו עַל־פִּ֣י יְהוָ֑ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "ד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "נָשֹׂ֗א אֶת־רֹאשׁ֙ בְּנֵ֣י קְהָ֔ת מִתּ֖וֹךְ בְּנֵ֣י לֵוִ֑י לְמִשְׁפְּחֹתָ֖ם לְבֵ֥ית אֲבֹתָֽם׃"
          },
          {
            "num": "ג",
            "txt": "מִבֶּ֨ן שְׁלֹשִׁ֤ים שָׁנָה֙ וָמַ֔עְלָה וְעַ֖ד בֶּן־חֲמִשִּׁ֣ים שָׁנָ֑ה כָּל־בָּא֙ לַצָּבָ֔א לַֽעֲשׂ֥וֹת מְלָאכָ֖ה בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ד",
            "txt": "זֹ֛את עֲבֹדַ֥ת בְּנֵֽי־קְהָ֖ת בְּאֹ֣הֶל מוֹעֵ֑ד קֹ֖דֶשׁ הַקֳּדָשִֽׁים׃"
          },
          {
            "num": "ה",
            "txt": "וּבָ֨א אַֽהֲרֹ֤ן וּבָנָיו֙ בִּנְסֹ֣עַ הַֽמַּחֲנֶ֔ה וְהוֹרִ֕דוּ אֵ֖ת פָּרֹ֣כֶת הַמָּסָ֑ךְ וְכִ֨סּוּ־בָ֔הּ אֵ֖ת אֲרֹ֥ן הָֽעֵדֻֽת׃"
          },
          {
            "num": "ו",
            "txt": "וְנָֽתְנ֣וּ עָלָ֗יו כְּסוּי֙ ע֣וֹר תַּ֔חַשׁ וּפָֽרְשׂ֧וּ בֶֽגֶד־כְּלִ֛יל תְּכֵ֖לֶת מִלְמָ֑עְלָה וְשָׂמ֖וּ בַּדָּֽיו׃"
          },
          {
            "num": "ז",
            "txt": "וְעַ֣ל ׀ שֻׁלְחַ֣ן הַפָּנִ֗ים יִפְרְשׂוּ֮ בֶּ֣גֶד תְּכֵלֶת֒ וְנָֽתְנ֣וּ עָ֠לָיו אֶת־הַקְּעָרֹ֤ת וְאֶת־הַכַּפֹּת֙ וְאֶת־הַמְּנַקִּיֹּ֔ת וְאֵ֖ת קְשׂ֣וֹת הַנָּ֑סֶךְ וְלֶ֥חֶם הַתָּמִ֖יד עָלָ֥יו יִֽהְיֶֽה׃"
          },
          {
            "num": "ח",
            "txt": "וּפָֽרְשׂ֣וּ עֲלֵיהֶ֗ם בֶּ֚גֶד תּוֹלַ֣עַת שָׁנִ֔י וְכִסּ֣וּ אֹת֔וֹ בְּמִכְסֵ֖ה ע֣וֹר תָּ֑חַשׁ וְשָׂמ֖וּ אֶת־בַּדָּֽיו׃"
          },
          {
            "num": "ט",
            "txt": "וְלָֽקְח֣וּ ׀ בֶּ֣גֶד תְּכֵ֗לֶת וְכִסּ֞וּ אֶת־מְנֹרַ֤ת הַמָּאוֹר֙ וְאֶת־נֵ֣רֹתֶ֔יהָ וְאֶת־מַלְקָחֶ֖יהָ וְאֶת־מַחְתֹּתֶ֑יהָ וְאֵת֙ כָּל־כְּלֵ֣י שַׁמְנָ֔הּ אֲשֶׁ֥ר יְשָֽׁרְתוּ־לָ֖הּ בָּהֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וְנָֽתְנ֤וּ אֹתָהּ֙ וְאֶת־כָּל־כֵּלֶ֔יהָ אֶל־מִכְסֵ֖ה ע֣וֹר תָּ֑חַשׁ וְנָֽתְנ֖וּ עַל־הַמּֽוֹט׃"
          },
          {
            "num": "יא",
            "txt": "וְעַ֣ל ׀ מִזְבַּ֣ח הַזָּהָ֗ב יִפְרְשׂוּ֙ בֶּ֣גֶד תְּכֵ֔לֶת וְכִסּ֣וּ אֹת֔וֹ בְּמִכְסֵ֖ה ע֣וֹר תָּ֑חַשׁ וְשָׂמ֖וּ אֶת־בַּדָּֽיו׃"
          },
          {
            "num": "יב",
            "txt": "וְלָֽקְחוּ֩ אֶת־כָּל־כְּלֵ֨י הַשָּׁרֵ֜ת אֲשֶׁ֧ר יְשָֽׁרְתוּ־בָ֣ם בַּקֹּ֗דֶשׁ וְנָֽתְנוּ֙ אֶל־בֶּ֣גֶד תְּכֵ֔לֶת וְכִסּ֣וּ אוֹתָ֔ם בְּמִכְסֵ֖ה ע֣וֹר תָּ֑חַשׁ וְנָֽתְנ֖וּ עַל־הַמּֽוֹט׃"
          },
          {
            "num": "יג",
            "txt": "וְדִשְּׁנ֖וּ אֶת־הַמִּזְבֵּ֑חַ וּפָֽרְשׂ֣וּ עָלָ֔יו בֶּ֖גֶד אַרְגָּמָֽן׃"
          },
          {
            "num": "יד",
            "txt": "וְנָֽתְנ֣וּ עָ֠לָיו אֶֽת־כָּל־כֵּלָ֞יו אֲשֶׁ֣ר יְֽשָׁרְת֧וּ עָלָ֣יו בָּהֶ֗ם אֶת־הַמַּחְתֹּ֤ת אֶת־הַמִּזְלָגֹת֙ וְאֶת־הַיָּעִ֣ים וְאֶת־הַמִּזְרָקֹ֔ת כֹּ֖ל כְּלֵ֣י הַמִּזְבֵּ֑חַ וּפָֽרְשׂ֣וּ עָלָ֗יו כְּס֛וּי ע֥וֹר תַּ֖חַשׁ וְשָׂמ֥וּ בַדָּֽיו׃"
          },
          {
            "num": "טו",
            "txt": "וְכִלָּ֣ה אַֽהֲרֹן־וּ֠בָנָיו לְכַסֹּ֨ת אֶת־הַקֹּ֜דֶשׁ וְאֶת־כָּל־כְּלֵ֣י הַקֹּדֶשׁ֮ בִּנְסֹ֣עַ הַֽמַּחֲנֶה֒ וְאַֽחֲרֵי־כֵ֗ן יָבֹ֤אוּ בְנֵֽי־קְהָת֙ לָשֵׂ֔את וְלֹֽא־יִגְּע֥וּ אֶל־הַקֹּ֖דֶשׁ וָמֵ֑תוּ אֵ֛לֶּה מַשָּׂ֥א בְנֵֽי־קְהָ֖ת בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "טז",
            "txt": "וּפְקֻדַּ֞ת אֶלְעָזָ֣ר ׀ בֶּן־אַֽהֲרֹ֣ן הַכֹּהֵ֗ן שֶׁ֤מֶן הַמָּאוֹר֙ וּקְטֹ֣רֶת הַסַּמִּ֔ים וּמִנְחַ֥ת הַתָּמִ֖יד וְשֶׁ֣מֶן הַמִּשְׁחָ֑ה פְּקֻדַּ֗ת כָּל־הַמִּשְׁכָּן֙ וְכָל־אֲשֶׁר־בּ֔וֹ בְּקֹ֖דֶשׁ וּבְכֵלָֽיו׃ {פ}"
          },
          {
            "num": "יז",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "יח",
            "txt": "אַל־תַּכְרִ֕יתוּ אֶת־שֵׁ֖בֶט מִשְׁפְּחֹ֣ת הַקְּהָתִ֑י מִתּ֖וֹךְ הַלְוִיִּֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְזֹ֣את ׀ עֲשׂ֣וּ לָהֶ֗ם וְחָיוּ֙ וְלֹ֣א יָמֻ֔תוּ בְּגִשְׁתָּ֖ם אֶת־קֹ֣דֶשׁ הַקֳּדָשִׁ֑ים אַֽהֲרֹ֤ן וּבָנָיו֙ יָבֹ֔אוּ וְשָׂמ֣וּ אוֹתָ֗ם אִ֥ישׁ אִ֛ישׁ עַל־עֲבֹֽדָת֖וֹ וְאֶל־מַשָּׂאֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וְלֹֽא־יָבֹ֧אוּ לִרְא֛וֹת כְּבַלַּ֥ע אֶת־הַקֹּ֖דֶשׁ וָמֵֽתוּ׃ {פ}"
          },
          {
            "num": "כא",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כב",
            "txt": "נָשֹׂ֗א אֶת־רֹ֛אשׁ בְּנֵ֥י גֵֽרְשׁ֖וֹן גַּם־הֵ֑ם לְבֵ֥ית אֲבֹתָ֖ם לְמִשְׁפְּחֹתָֽם׃"
          },
          {
            "num": "כג",
            "txt": "מִבֶּן֩ שְׁלֹשִׁ֨ים שָׁנָ֜ה וָמַ֗עְלָה עַ֛ד בֶּן־חֲמִשִּׁ֥ים שָׁנָ֖ה תִּפְקֹ֣ד אוֹתָ֑ם כָּל־הַבָּא֙ לִצְבֹ֣א צָבָ֔א לַֽעֲבֹ֥ד עֲבֹדָ֖ה בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "כד",
            "txt": "זֹ֣את עֲבֹדַ֔ת מִשְׁפְּחֹ֖ת הַגֵּֽרְשֻׁנִּ֑י לַֽעֲבֹ֖ד וּלְמַשָּֽׂא׃"
          },
          {
            "num": "כה",
            "txt": "וְנָ֨שְׂא֜וּ אֶת־יְרִיעֹ֤ת הַמִּשְׁכָּן֙ וְאֶת־אֹ֣הֶל מוֹעֵ֔ד מִכְסֵ֕הוּ וּמִכְסֵ֛ה הַתַּ֥חַשׁ אֲשֶׁר־עָלָ֖יו מִלְמָ֑עְלָה וְאֶ֨ת־מָסַ֔ךְ פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "כו",
            "txt": "וְאֵת֩ קַלְעֵ֨י הֶֽחָצֵ֜ר וְאֶת־מָסַ֣ךְ ׀ פֶּ֣תַח ׀ שַׁ֣עַר הֶֽחָצֵ֗ר אֲשֶׁ֨ר עַל־הַמִּשְׁכָּ֤ן וְעַל־הַמִּזְבֵּ֨חַ֙ סָבִ֔יב וְאֵת֙ מֵֽיתְרֵיהֶ֔ם וְאֶֽת־כָּל־כְּלֵ֖י עֲבֹֽדָתָ֑ם וְאֵ֨ת כָּל־אֲשֶׁ֧ר יֵֽעָשֶׂ֛ה לָהֶ֖ם וְעָבָֽדוּ׃"
          },
          {
            "num": "כז",
            "txt": "עַל־פִּי֩ אַֽהֲרֹ֨ן וּבָנָ֜יו תִּֽהְיֶ֗ה כָּל־עֲבֹדַת֙ בְּנֵ֣י הַגֵּֽרְשֻׁנִּ֔י לְכָ֨ל־מַשָּׂאָ֔ם וּלְכֹ֖ל עֲבֹֽדָתָ֑ם וּפְקַדְתֶּ֤ם עֲלֵהֶם֙ בְּמִשְׁמֶ֔רֶת אֵ֖ת כָּל־מַשָּׂאָֽם׃"
          },
          {
            "num": "כח",
            "txt": "זֹ֣את עֲבֹדַ֗ת מִשְׁפְּחֹ֛ת בְּנֵ֥י הַגֵּֽרְשֻׁנִּ֖י בְּאֹ֣הֶל מוֹעֵ֑ד וּמִ֨שְׁמַרְתָּ֔ם בְּיַד֙ אִֽיתָמָ֔ר בֶּֽן־אַהֲרֹ֖ן הַכֹּהֵֽן׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "בְּנֵ֖י מְרָרִ֑י לְמִשְׁפְּחֹתָ֥ם לְבֵית־אֲבֹתָ֖ם תִּפְקֹ֥ד אֹתָֽם׃"
          },
          {
            "num": "ל",
            "txt": "מִבֶּן֩ שְׁלֹשִׁ֨ים שָׁנָ֜ה וָמַ֗עְלָה וְעַ֛ד בֶּן־חֲמִשִּׁ֥ים שָׁנָ֖ה תִּפְקְדֵ֑ם כָּל־הַבָּא֙ לַצָּבָ֔א לַֽעֲבֹ֕ד אֶת־עֲבֹדַ֖ת אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "לא",
            "txt": "וְזֹאת֙ מִשְׁמֶ֣רֶת מַשָּׂאָ֔ם לְכָל־עֲבֹֽדָתָ֖ם בְּאֹ֣הֶל מוֹעֵ֑ד קַרְשֵׁי֙ הַמִּשְׁכָּ֔ן וּבְרִיחָ֖יו וְעַמּוּדָ֥יו וַֽאֲדָנָֽיו׃"
          },
          {
            "num": "לב",
            "txt": "וְעַמּוּדֵי֩ הֶֽחָצֵ֨ר סָבִ֜יב וְאַדְנֵיהֶ֗ם וִיתֵֽדֹתָם֙ וּמֵ֣יתְרֵיהֶ֔ם לְכָ֨ל־כְּלֵיהֶ֔ם וּלְכֹ֖ל עֲבֹֽדָתָ֑ם וּבְשֵׁמֹ֣ת תִּפְקְד֔וּ אֶת־כְּלֵ֖י מִשְׁמֶ֥רֶת מַשָּׂאָֽם׃"
          },
          {
            "num": "לג",
            "txt": "זֹ֣את עֲבֹדַ֗ת מִשְׁפְּחֹת֙ בְּנֵ֣י מְרָרִ֔י לְכָל־עֲבֹֽדָתָ֖ם בְּאֹ֣הֶל מוֹעֵ֑ד בְּיַד֙ אִֽיתָמָ֔ר בֶּֽן־אַהֲרֹ֖ן הַכֹּהֵֽן׃"
          },
          {
            "num": "לד",
            "txt": "וַיִּפְקֹ֨ד מֹשֶׁ֧ה וְאַֽהֲרֹ֛ן וּנְשִׂיאֵ֥י הָֽעֵדָ֖ה אֶת־בְּנֵ֣י הַקְּהָתִ֑י לְמִשְׁפְּחֹתָ֖ם וּלְבֵ֥ית אֲבֹתָֽם׃"
          },
          {
            "num": "לה",
            "txt": "מִבֶּ֨ן שְׁלֹשִׁ֤ים שָׁנָה֙ וָמַ֔עְלָה וְעַ֖ד בֶּן־חֲמִשִּׁ֣ים שָׁנָ֑ה כָּל־הַבָּא֙ לַצָּבָ֔א לַֽעֲבֹדָ֖ה בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "לו",
            "txt": "וַיִּֽהְי֥וּ פְקֻֽדֵיהֶ֖ם לְמִשְׁפְּחֹתָ֑ם אַלְפַּ֕יִם שְׁבַ֥ע מֵא֖וֹת וַֽחֲמִשִּֽׁים׃"
          },
          {
            "num": "לז",
            "txt": "אֵ֤לֶּה פְקוּדֵי֙ מִשְׁפְּחֹ֣ת הַקְּהָתִ֔י כָּל־הָֽעֹבֵ֖ד בְּאֹ֣הֶל מוֹעֵ֑ד אֲשֶׁ֨ר פָּקַ֤ד מֹשֶׁה֙ וְאַֽהֲרֹ֔ן עַל־פִּ֥י יְהוָ֖ה בְּיַד־מֹשֶֽׁה׃ {ס}"
          },
          {
            "num": "לח",
            "txt": "וּפְקוּדֵ֖י בְּנֵ֣י גֵֽרְשׁ֑וֹן לְמִשְׁפְּחוֹתָ֖ם וּלְבֵ֥ית אֲבֹתָֽם׃"
          },
          {
            "num": "לט",
            "txt": "מִבֶּ֨ן שְׁלֹשִׁ֤ים שָׁנָה֙ וָמַ֔עְלָה וְעַ֖ד בֶּן־חֲמִשִּׁ֣ים שָׁנָ֑ה כָּל־הַבָּא֙ לַצָּבָ֔א לַֽעֲבֹדָ֖ה בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "מ",
            "txt": "וַיִּֽהְיוּ֙ פְּקֻ֣דֵיהֶ֔ם לְמִשְׁפְּחֹתָ֖ם לְבֵ֣ית אֲבֹתָ֑ם אַלְפַּ֕יִם וְשֵׁ֥שׁ מֵא֖וֹת וּשְׁלֹשִֽׁים׃"
          },
          {
            "num": "מא",
            "txt": "אֵ֣לֶּה פְקוּדֵ֗י מִשְׁפְּחֹת֙ בְּנֵ֣י גֵֽרְשׁ֔וֹן כָּל־הָֽעֹבֵ֖ד בְּאֹ֣הֶל מוֹעֵ֑ד אֲשֶׁ֨ר פָּקַ֥ד מֹשֶׁ֛ה וְאַֽהֲרֹ֖ן עַל־פִּ֥י יְהוָֽה׃"
          },
          {
            "num": "מב",
            "txt": "וּפְקוּדֵ֕י מִשְׁפְּחֹ֖ת בְּנֵ֣י מְרָרִ֑י לְמִשְׁפְּחֹתָ֖ם לְבֵ֥ית אֲבֹתָֽם׃"
          },
          {
            "num": "מג",
            "txt": "מִבֶּ֨ן שְׁלֹשִׁ֤ים שָׁנָה֙ וָמַ֔עְלָה וְעַ֖ד בֶּן־חֲמִשִּׁ֣ים שָׁנָ֑ה כָּל־הַבָּא֙ לַצָּבָ֔א לַֽעֲבֹדָ֖ה בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "מד",
            "txt": "וַיִּֽהְי֥וּ פְקֻֽדֵיהֶ֖ם לְמִשְׁפְּחֹתָ֑ם שְׁלֹ֥שֶׁת אֲלָפִ֖ים וּמָאתָֽיִם׃"
          },
          {
            "num": "מה",
            "txt": "אֵ֣לֶּה פְקוּדֵ֔י מִשְׁפְּחֹ֖ת בְּנֵ֣י מְרָרִ֑י אֲשֶׁ֨ר פָּקַ֤ד מֹשֶׁה֙ וְאַֽהֲרֹ֔ן עַל־פִּ֥י יְהוָ֖ה בְּיַד־מֹשֶֽׁה׃"
          },
          {
            "num": "מו",
            "txt": "כָּֽל־הַפְּקֻדִ֡ים אֲשֶׁר֩ פָּקַ֨ד מֹשֶׁ֧ה וְאַֽהֲרֹ֛ן וּנְשִׂיאֵ֥י יִשְׂרָאֵ֖ל אֶת־הַלְוִיִּ֑ם לְמִשְׁפְּחֹתָ֖ם וּלְבֵ֥ית אֲבֹתָֽם׃"
          },
          {
            "num": "מז",
            "txt": "מִבֶּ֨ן שְׁלֹשִׁ֤ים שָׁנָה֙ וָמַ֔עְלָה וְעַ֖ד בֶּן־חֲמִשִּׁ֣ים שָׁנָ֑ה כָּל־הַבָּ֗א לַֽעֲבֹ֨ד עֲבֹדַ֧ת עֲבֹדָ֛ה וַֽעֲבֹדַ֥ת מַשָּׂ֖א בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "מח",
            "txt": "וַיִּֽהְי֖וּ פְּקֻֽדֵיהֶ֑ם שְׁמֹנַ֣ת אֲלָפִ֔ים וַֽחֲמֵ֥שׁ מֵא֖וֹת וּשְׁמֹנִֽים׃"
          },
          {
            "num": "מט",
            "txt": "עַל־פִּ֨י יְהוָ֜ה פָּקַ֤ד אוֹתָם֙ בְּיַד־מֹשֶׁ֔ה אִ֥ישׁ אִ֛ישׁ עַל־עֲבֹֽדָת֖וֹ וְעַל־מַשָּׂא֑וֹ וּפְקֻדָ֕יו אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "ה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "צַ֚ו אֶת־בְּנֵ֣י יִשְׂרָאֵ֔ל וִֽישַׁלְּחוּ֙ מִן־הַֽמַּחֲנֶ֔ה כָּל־צָר֖וּעַ וְכָל־זָ֑ב וְכֹ֖ל טָמֵ֥א לָנָֽפֶשׁ׃"
          },
          {
            "num": "ג",
            "txt": "מִזָּכָ֤ר עַד־נְקֵבָה֙ תְּשַׁלֵּ֔חוּ אֶל־מִח֥וּץ לַֽמַּחֲנֶ֖ה תְּשַׁלְּח֑וּם וְלֹ֤א יְטַמְּאוּ֙ אֶת־מַ֣חֲנֵיהֶ֔ם אֲשֶׁ֥ר אֲנִ֖י שֹׁכֵ֥ן בְּתוֹכָֽם׃"
          },
          {
            "num": "ד",
            "txt": "וַיַּֽעֲשׂוּ־כֵן֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וַיְשַׁלְּח֣וּ אוֹתָ֔ם אֶל־מִח֖וּץ לַֽמַּחֲנֶ֑ה כַּֽאֲשֶׁ֨ר דִּבֶּ֤ר יְהוָה֙ אֶל־מֹשֶׁ֔ה כֵּ֥ן עָשׂ֖וּ בְּנֵ֥י יִשְׂרָאֵֽל׃ {פ}"
          },
          {
            "num": "ה",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ו",
            "txt": "דַּבֵּר֮ אֶל־בְּנֵ֣י יִשְׂרָאֵל֒ אִ֣ישׁ אֽוֹ־אִשָּׁ֗ה כִּ֤י יַֽעֲשׂוּ֙ מִכָּל־חַטֹּ֣את הָֽאָדָ֔ם לִמְעֹ֥ל מַ֖עַל בַּֽיהוָ֑ה וְאָֽשְׁמָ֖ה הַנֶּ֥פֶשׁ הַהִֽוא׃"
          },
          {
            "num": "ז",
            "txt": "וְהִתְוַדּ֗וּ אֶֽת־חַטָּאתָם֮ אֲשֶׁ֣ר עָשׂוּ֒ וְהֵשִׁ֤יב אֶת־אֲשָׁמוֹ֙ בְּרֹאשׁ֔וֹ וַחֲמִֽישִׁת֖וֹ יֹסֵ֣ף עָלָ֑יו וְנָתַ֕ן לַֽאֲשֶׁ֖ר אָשַׁ֥ם לֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וְאִם־אֵ֨ין לָאִ֜ישׁ גֹּאֵ֗ל לְהָשִׁ֤יב הָֽאָשָׁם֙ אֵלָ֔יו הָֽאָשָׁ֛ם הַמּוּשָׁ֥ב לַֽיהוָ֖ה לַכֹּהֵ֑ן מִלְּבַ֗ד אֵ֚יל הַכִּפֻּרִ֔ים אֲשֶׁ֥ר יְכַפֶּר־בּ֖וֹ עָלָֽיו׃"
          },
          {
            "num": "ט",
            "txt": "וְכָל־תְּרוּמָ֞ה לְכָל־קָדְשֵׁ֧י בְנֵֽי־יִשְׂרָאֵ֛ל אֲשֶׁר־יַקְרִ֥יבוּ לַכֹּהֵ֖ן ל֥וֹ יִֽהְיֶֽה׃"
          },
          {
            "num": "י",
            "txt": "וְאִ֥ישׁ אֶת־קֳדָשָׁ֖יו ל֣וֹ יִֽהְי֑וּ אִ֛ישׁ אֲשֶׁר־יִתֵּ֥ן לַכֹּהֵ֖ן ל֥וֹ יִֽהְיֶֽה׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יב",
            "txt": "דַּבֵּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם אִ֥ישׁ אִישׁ֙ כִּֽי־תִשְׂטֶ֣ה אִשְׁתּ֔וֹ וּמָֽעֲלָ֥ה ב֖וֹ מָֽעַל׃"
          },
          {
            "num": "יג",
            "txt": "וְשָׁכַ֨ב אִ֣ישׁ אֹתָהּ֮ שִׁכְבַת־זֶרַע֒ וְנֶעְלַם֙ מֵֽעֵינֵ֣י אִישָׁ֔הּ וְנִסְתְּרָ֖ה וְהִ֣יא נִטְמָ֑אָה וְעֵד֙ אֵ֣ין בָּ֔הּ וְהִ֖וא לֹ֥א נִתְפָּֽשָׂה׃"
          },
          {
            "num": "יד",
            "txt": "וְעָבַ֨ר עָלָ֧יו רֽוּחַ־קִנְאָ֛ה וְקִנֵּ֥א אֶת־אִשְׁתּ֖וֹ וְהִ֣וא נִטְמָ֑אָה אֽוֹ־עָבַ֨ר עָלָ֤יו רֽוּחַ־קִנְאָה֙ וְקִנֵּ֣א אֶת־אִשְׁתּ֔וֹ וְהִ֖יא לֹ֥א נִטְמָֽאָה׃"
          },
          {
            "num": "טו",
            "txt": "וְהֵבִ֨יא הָאִ֣ישׁ אֶת־אִשְׁתּוֹ֮ אֶל־הַכֹּהֵן֒ וְהֵבִ֤יא אֶת־קָרְבָּנָהּ֙ עָלֶ֔יהָ עֲשִׂירִ֥ת הָֽאֵיפָ֖ה קֶ֣מַח שְׂעֹרִ֑ים לֹֽא־יִצֹ֨ק עָלָ֜יו שֶׁ֗מֶן וְלֹֽא־יִתֵּ֤ן עָלָיו֙ לְבֹנָ֔ה כִּֽי־מִנְחַ֤ת קְנָאֹת֙ ה֔וּא מִנְחַ֥ת זִכָּר֖וֹן מַזְכֶּ֥רֶת עָוֺֽן׃"
          },
          {
            "num": "טז",
            "txt": "וְהִקְרִ֥יב אֹתָ֖הּ הַכֹּהֵ֑ן וְהֶֽעֱמִדָ֖הּ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "יז",
            "txt": "וְלָקַ֧ח הַכֹּהֵ֛ן מַ֥יִם קְדֹשִׁ֖ים בִּכְלִי־חָ֑רֶשׂ וּמִן־הֶֽעָפָ֗ר אֲשֶׁ֤ר יִֽהְיֶה֙ בְּקַרְקַ֣ע הַמִּשְׁכָּ֔ן יִקַּ֥ח הַכֹּהֵ֖ן וְנָתַ֥ן אֶל־הַמָּֽיִם׃"
          },
          {
            "num": "יח",
            "txt": "וְהֶֽעֱמִ֨יד הַכֹּהֵ֥ן אֶֽת־הָאִשָּׁה֮ לִפְנֵ֣י יְהוָה֒ וּפָרַע֙ אֶת־רֹ֣אשׁ הָֽאִשָּׁ֔ה וְנָתַ֣ן עַל־כַּפֶּ֗יהָ אֵ֚ת מִנְחַ֣ת הַזִּכָּר֔וֹן מִנְחַ֥ת קְנָאֹ֖ת הִ֑וא וּבְיַ֤ד הַכֹּהֵן֙ יִֽהְי֔וּ מֵ֥י הַמָּרִ֖ים הַמְאָֽרְרִֽים׃"
          },
          {
            "num": "יט",
            "txt": "וְהִשְׁבִּ֨יעַ אֹתָ֜הּ הַכֹּהֵ֗ן וְאָמַ֤ר אֶל־הָֽאִשָּׁה֙ אִם־לֹ֨א שָׁכַ֥ב אִישׁ֙ אֹתָ֔ךְ וְאִם־לֹ֥א שָׂטִ֛ית טֻמְאָ֖ה תַּ֣חַת אִישֵׁ֑ךְ הִנָּקִ֕י מִמֵּ֛י הַמָּרִ֥ים הַֽמְאָרְרִ֖ים הָאֵֽלֶּה׃"
          },
          {
            "num": "כ",
            "txt": "וְאַ֗תְּ כִּ֥י שָׂטִ֛ית תַּ֥חַת אִישֵׁ֖ךְ וְכִ֣י נִטְמֵ֑את וַיִּתֵּ֨ן אִ֥ישׁ בָּךְ֙ אֶת־שְׁכָבְתּ֔וֹ מִֽבַּלְעֲדֵ֖י אִישֵֽׁךְ׃"
          },
          {
            "num": "כא",
            "txt": "וְהִשְׁבִּ֨יעַ הַכֹּהֵ֥ן אֶֽת־הָאִשָּׁה֮ בִּשְׁבֻעַ֣ת הָֽאָלָה֒ וְאָמַ֤ר הַכֹּהֵן֙ לָֽאִשָּׁ֔ה יִתֵּ֨ן יְהוָ֥ה אוֹתָ֛ךְ לְאָלָ֥ה וְלִשְׁבֻעָ֖ה בְּת֣וֹךְ עַמֵּ֑ךְ בְּתֵ֨ת יְהוָ֤ה אֶת־יְרֵכֵךְ֙ נֹפֶ֔לֶת וְאֶת־בִּטְנֵ֖ךְ צָבָֽה׃"
          },
          {
            "num": "כב",
            "txt": "וּ֠בָאוּ הַמַּ֨יִם הַמְאָֽרְרִ֤ים הָאֵ֨לֶּה֙ בְּֽמֵעַ֔יִךְ לַצְבּ֥וֹת בֶּ֖טֶן וְלַנְפִּ֣ל יָרֵ֑ךְ וְאָֽמְרָ֥ה הָֽאִשָּׁ֖ה אָמֵ֥ן ׀ אָמֵֽן׃"
          },
          {
            "num": "כג",
            "txt": "וְ֠כָתַב אֶת־הָֽאָלֹ֥ת הָאֵ֛לֶּה הַכֹּהֵ֖ן בַּסֵּ֑פֶר וּמָחָ֖ה אֶל־מֵ֥י הַמָּרִֽים׃"
          },
          {
            "num": "כד",
            "txt": "וְהִשְׁקָה֙ אֶת־הָ֣אִשָּׁ֔ה אֶת־מֵ֥י הַמָּרִ֖ים הַמְאָֽרְרִ֑ים וּבָ֥אוּ בָ֛הּ הַמַּ֥יִם הַֽמְאָרְרִ֖ים לְמָרִֽים׃"
          },
          {
            "num": "כה",
            "txt": "וְלָקַ֤ח הַכֹּהֵן֙ מִיַּ֣ד הָֽאִשָּׁ֔ה אֵ֖ת מִנְחַ֣ת הַקְּנָאֹ֑ת וְהֵנִ֤יף אֶת־הַמִּנְחָה֙ לִפְנֵ֣י יְהוָ֔ה וְהִקְרִ֥יב אֹתָ֖הּ אֶל־הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "כו",
            "txt": "וְקָמַ֨ץ הַכֹּהֵ֤ן מִן־הַמִּנְחָה֙ אֶת־אַזְכָּ֣רָתָ֔הּ וְהִקְטִ֖יר הַמִּזְבֵּ֑חָה וְאַחַ֛ר יַשְׁקֶ֥ה אֶת־הָֽאִשָּׁ֖ה אֶת־הַמָּֽיִם׃"
          },
          {
            "num": "כז",
            "txt": "וְהִשְׁקָ֣הּ אֶת־הַמַּ֗יִם וְהָֽיְתָ֣ה אִֽם־נִטְמְאָה֮ וַתִּמְעֹ֣ל מַ֣עַל בְּאִישָׁהּ֒ וּבָ֨אוּ בָ֜הּ הַמַּ֤יִם הַמְאָֽרְרִים֙ לְמָרִ֔ים וְצָֽבְתָ֣ה בִטְנָ֔הּ וְנָֽפְלָ֖ה יְרֵכָ֑הּ וְהָֽיְתָ֧ה הָֽאִשָּׁ֛ה לְאָלָ֖ה בְּקֶ֥רֶב עַמָּֽהּ׃"
          },
          {
            "num": "כח",
            "txt": "וְאִם־לֹ֤א נִטְמְאָה֙ הָֽאִשָּׁ֔ה וּטְהֹרָ֖ה הִ֑וא וְנִקְּתָ֖ה וְנִזְרְעָ֥ה זָֽרַע׃"
          },
          {
            "num": "כט",
            "txt": "זֹ֥את תּוֹרַ֖ת הַקְּנָאֹ֑ת אֲשֶׁ֨ר תִּשְׂטֶ֥ה אִשָּׁ֛ה תַּ֥חַת אִישָׁ֖הּ וְנִטְמָֽאָה׃"
          },
          {
            "num": "ל",
            "txt": "א֣וֹ אִ֗ישׁ אֲשֶׁ֨ר תַּֽעֲבֹ֥ר עָלָ֛יו ר֥וּחַ קִנְאָ֖ה וְקִנֵּ֣א אֶת־אִשְׁתּ֑וֹ וְהֶֽעֱמִ֤יד אֶת־הָֽאִשָּׁה֙ לִפְנֵ֣י יְהוָ֔ה וְעָ֤שָׂה לָהּ֙ הַכֹּהֵ֔ן אֵ֥ת כָּל־הַתּוֹרָ֖ה הַזֹּֽאת׃"
          },
          {
            "num": "לא",
            "txt": "וְנִקָּ֥ה הָאִ֖ישׁ מֵֽעָוֺ֑ן וְהָֽאִשָּׁ֣ה הַהִ֔וא תִּשָּׂ֖א אֶת־עֲוֺנָֽהּ׃ {פ}"
          }
        ]
      },
      {
        "num": "ו",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם אִ֣ישׁ אֽוֹ־אִשָּׁ֗ה כִּ֤י יַפְלִא֙ לִנְדֹּר֙ נֶ֣דֶר נָזִ֔יר לְהַזִּ֖יר לַֽיהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "מִיַּ֤יִן וְשֵׁכָר֙ יַזִּ֔יר חֹ֥מֶץ יַ֛יִן וְחֹ֥מֶץ שֵׁכָ֖ר לֹ֣א יִשְׁתֶּ֑ה וְכָל־מִשְׁרַ֤ת עֲנָבִים֙ לֹ֣א יִשְׁתֶּ֔ה וַֽעֲנָבִ֛ים לַחִ֥ים וִֽיבֵשִׁ֖ים לֹ֥א יֹאכֵֽל׃"
          },
          {
            "num": "ד",
            "txt": "כֹּ֖ל יְמֵ֣י נִזְר֑וֹ מִכֹּל֩ אֲשֶׁ֨ר יֵֽעָשֶׂ֜ה מִגֶּ֣פֶן הַיַּ֗יִן מֵֽחַרְצַנִּ֛ים וְעַד־זָ֖ג לֹ֥א יֹאכֵֽל׃"
          },
          {
            "num": "ה",
            "txt": "כָּל־יְמֵי֙ נֶ֣דֶר נִזְר֔וֹ תַּ֖עַר לֹֽא־יַעֲבֹ֣ר עַל־רֹאשׁ֑וֹ עַד־מְלֹ֨את הַיָּמִ֜ם אֲשֶׁר־יַזִּ֤יר לַֽיהוָה֙ קָדֹ֣שׁ יִֽהְיֶ֔ה גַּדֵּ֥ל פֶּ֖רַע שְׂעַ֥ר רֹאשֽׁוֹ׃"
          },
          {
            "num": "ו",
            "txt": "כָּל־יְמֵ֥י הַזִּיר֖וֹ לַֽיהוָ֑ה עַל־נֶ֥פֶשׁ מֵ֖ת לֹ֥א יָבֹֽא׃"
          },
          {
            "num": "ז",
            "txt": "לְאָבִ֣יו וּלְאִמּ֗וֹ לְאָחִיו֙ וּלְאַ֣חֹת֔וֹ לֹֽא־יִטַּמָּ֥א לָהֶ֖ם בְּמֹתָ֑ם כִּ֛י נֵ֥זֶר אֱלֹהָ֖יו עַל־רֹאשֽׁוֹ׃"
          },
          {
            "num": "ח",
            "txt": "כֹּ֖ל יְמֵ֣י נִזְר֑וֹ קָדֹ֥שׁ ה֖וּא לַֽיהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וְכִֽי־יָמ֨וּת מֵ֤ת עָלָיו֙ בְּפֶ֣תַע פִּתְאֹ֔ם וְטִמֵּ֖א רֹ֣אשׁ נִזְר֑וֹ וְגִלַּ֤ח רֹאשׁוֹ֙ בְּי֣וֹם טָֽהֳרָת֔וֹ בַּיּ֥וֹם הַשְּׁבִיעִ֖י יְגַלְּחֶֽנּוּ׃"
          },
          {
            "num": "י",
            "txt": "וּבַיּ֣וֹם הַשְּׁמִינִ֗י יָבִא֙ שְׁתֵּ֣י תֹרִ֔ים א֥וֹ שְׁנֵ֖י בְּנֵ֣י יוֹנָ֑ה אֶ֨ל־הַכֹּהֵ֔ן אֶל־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "יא",
            "txt": "וְעָשָׂ֣ה הַכֹּהֵ֗ן אֶחָ֤ד לְחַטָּאת֙ וְאֶחָ֣ד לְעֹלָ֔ה וְכִפֶּ֣ר עָלָ֔יו מֵֽאֲשֶׁ֥ר חָטָ֖א עַל־הַנָּ֑פֶשׁ וְקִדַּ֥שׁ אֶת־רֹאשׁ֖וֹ בַּיּ֥וֹם הַהֽוּא׃"
          },
          {
            "num": "יב",
            "txt": "וְהִזִּ֤יר לַֽיהוָה֙ אֶת־יְמֵ֣י נִזְר֔וֹ וְהֵבִ֛יא כֶּ֥בֶשׂ בֶּן־שְׁנָת֖וֹ לְאָשָׁ֑ם וְהַיָּמִ֤ים הָרִֽאשֹׁנִים֙ יִפְּל֔וּ כִּ֥י טָמֵ֖א נִזְרֽוֹ׃"
          },
          {
            "num": "יג",
            "txt": "וְזֹ֥את תּוֹרַ֖ת הַנָּזִ֑יר בְּי֗וֹם מְלֹאת֙ יְמֵ֣י נִזְר֔וֹ יָבִ֣יא אֹת֔וֹ אֶל־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "יד",
            "txt": "וְהִקְרִ֣יב אֶת־קָרְבָּנ֣וֹ לַֽיהוָ֡ה כֶּבֶשׂ֩ בֶּן־שְׁנָת֨וֹ תָמִ֤ים אֶחָד֙ לְעֹלָ֔ה וְכַבְשָׂ֨ה אַחַ֧ת בַּת־שְׁנָתָ֛הּ תְּמִימָ֖ה לְחַטָּ֑את וְאַֽיִל־אֶחָ֥ד תָּמִ֖ים לִשְׁלָמִֽים׃"
          },
          {
            "num": "טו",
            "txt": "וְסַ֣ל מַצּ֗וֹת סֹ֤לֶת חַלֹּת֙ בְּלוּלֹ֣ת בַּשֶּׁ֔מֶן וּרְקִיקֵ֥י מַצּ֖וֹת מְשֻׁחִ֣ים בַּשָּׁ֑מֶן וּמִנְחָתָ֖ם וְנִסְכֵּיהֶֽם׃"
          },
          {
            "num": "טז",
            "txt": "וְהִקְרִ֥יב הַכֹּהֵ֖ן לִפְנֵ֣י יְהוָ֑ה וְעָשָׂ֥ה אֶת־חַטָּאת֖וֹ וְאֶת־עֹֽלָתֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "וְאֶת־הָאַ֜יִל יַֽעֲשֶׂ֨ה זֶ֤בַח שְׁלָמִים֙ לַֽיהוָ֔ה עַ֖ל סַ֣ל הַמַּצּ֑וֹת וְעָשָׂה֙ הַכֹּהֵ֔ן אֶת־מִנְחָת֖וֹ וְאֶת־נִסְכּֽוֹ׃"
          },
          {
            "num": "יח",
            "txt": "וְגִלַּ֣ח הַנָּזִ֗יר פֶּ֛תַח אֹ֥הֶל מוֹעֵ֖ד אֶת־רֹ֣אשׁ נִזְר֑וֹ וְלָקַ֗ח אֶת־שְׂעַר֙ רֹ֣אשׁ נִזְר֔וֹ וְנָתַן֙ עַל־הָאֵ֔שׁ אֲשֶׁר־תַּ֖חַת זֶ֥בַח הַשְּׁלָמִֽים׃"
          },
          {
            "num": "יט",
            "txt": "וְלָקַ֨ח הַכֹּהֵ֜ן אֶת־הַזְּרֹ֣עַ בְּשֵׁלָה֮ מִן־הָאַיִל֒ וְֽחַלַּ֨ת מַצָּ֤ה אַחַת֙ מִן־הַסַּ֔ל וּרְקִ֥יק מַצָּ֖ה אֶחָ֑ד וְנָתַן֙ עַל־כַּפֵּ֣י הַנָּזִ֔יר אַחַ֖ר הִֽתְגַּלְּח֥וֹ אֶת־נִזְרֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וְהֵנִיף֩ אוֹתָ֨ם הַכֹּהֵ֥ן ׀ תְּנוּפָה֮ לִפְנֵ֣י יְהוָה֒ קֹ֤דֶשׁ הוּא֙ לַכֹּהֵ֔ן עַ֚ל חֲזֵ֣ה הַתְּנוּפָ֔ה וְעַ֖ל שׁ֣וֹק הַתְּרוּמָ֑ה וְאַחַ֛ר יִשְׁתֶּ֥ה הַנָּזִ֖יר יָֽיִן׃"
          },
          {
            "num": "כא",
            "txt": "זֹ֣את תּוֹרַ֣ת הַנָּזִיר֮ אֲשֶׁ֣ר יִדֹּר֒ קָרְבָּנ֤וֹ לַֽיהוָה֙ עַל־נִזְר֔וֹ מִלְּבַ֖ד אֲשֶׁר־תַּשִּׂ֣יג יָד֑וֹ כְּפִ֤י נִדְרוֹ֙ אֲשֶׁ֣ר יִדֹּ֔ר כֵּ֣ן יַֽעֲשֶׂ֔ה עַ֖ל תּוֹרַ֥ת נִזְרֽוֹ׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כג",
            "txt": "דַּבֵּ֤ר אֶֽל־אַהֲרֹן֙ וְאֶל־בָּנָ֣יו לֵאמֹ֔ר כֹּ֥ה תְבָֽרְכ֖וּ אֶת־בְּנֵ֣י יִשְׂרָאֵ֑ל אָמ֖וֹר לָהֶֽם׃ {ס}"
          },
          {
            "num": "כד",
            "txt": "יְבָֽרֶכְךָ֥ יְהוָ֖ה וְיִשְׁמְרֶֽךָ׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "יָאֵ֨ר יְהוָ֧ה ׀ פָּנָ֛יו אֵלֶ֖יךָ וִֽיחֻנֶּֽךָּ׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "יִשָּׂ֨א יְהוָ֤ה ׀ פָּנָיו֙ אֵלֶ֔יךָ וְיָשֵׂ֥ם לְךָ֖ שָׁלֽוֹם׃ {ס}"
          },
          {
            "num": "כז",
            "txt": "וְשָׂמ֥וּ אֶת־שְׁמִ֖י עַל־בְּנֵ֣י יִשְׂרָאֵ֑ל וַֽאֲנִ֖י אֲבָֽרְכֵֽם׃ {ס}"
          }
        ]
      },
      {
        "num": "ז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֡י בְּיוֹם֩ כַּלּ֨וֹת מֹשֶׁ֜ה לְהָקִ֣ים אֶת־הַמִּשְׁכָּ֗ן וַיִּמְשַׁ֨ח אֹת֜וֹ וַיְקַדֵּ֤שׁ אֹתוֹ֙ וְאֶת־כָּל־כֵּלָ֔יו וְאֶת־הַמִּזְבֵּ֖חַ וְאֶת־כָּל־כֵּלָ֑יו וַיִּמְשָׁחֵ֖ם וַיְקַדֵּ֥שׁ אֹתָֽם׃"
          },
          {
            "num": "ב",
            "txt": "וַיַּקְרִ֨יבוּ֙ נְשִׂיאֵ֣י יִשְׂרָאֵ֔ל רָאשֵׁ֖י בֵּ֣ית אֲבֹתָ֑ם הֵ֚ם נְשִׂיאֵ֣י הַמַּטֹּ֔ת הֵ֥ם הָעֹֽמְדִ֖ים עַל־הַפְּקֻדִֽים׃"
          },
          {
            "num": "ג",
            "txt": "וַיָּבִ֨יאוּ אֶת־קָרְבָּנָ֜ם לִפְנֵ֣י יְהוָ֗ה שֵׁשׁ־עֶגְלֹ֥ת צָב֙ וּשְׁנֵ֣י עָשָׂ֣ר בָּקָ֔ר עֲגָלָ֛ה עַל־שְׁנֵ֥י הַנְּשִׂאִ֖ים וְשׁ֣וֹר לְאֶחָ֑ד וַיַּקְרִ֥יבוּ אוֹתָ֖ם לִפְנֵ֥י הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ה",
            "txt": "קַ֚ח מֵֽאִתָּ֔ם וְהָי֕וּ לַֽעֲבֹ֕ד אֶת־עֲבֹדַ֖ת אֹ֣הֶל מוֹעֵ֑ד וְנָֽתַתָּ֤ה אוֹתָם֙ אֶל־הַלְוִיִּ֔ם אִ֖ישׁ כְּפִ֥י עֲבֹֽדָתֽוֹ׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּקַּ֣ח מֹשֶׁ֔ה אֶת־הָֽעֲגָלֹ֖ת וְאֶת־הַבָּקָ֑ר וַיִּתֵּ֥ן אוֹתָ֖ם אֶל־הַלְוִיִּֽם׃"
          },
          {
            "num": "ז",
            "txt": "אֵ֣ת ׀ שְׁתֵּ֣י הָֽעֲגָל֗וֹת וְאֵת֙ אַרְבַּ֣עַת הַבָּקָ֔ר נָתַ֖ן לִבְנֵ֣י גֵֽרְשׁ֑וֹן כְּפִ֖י עֲבֹֽדָתָֽם׃"
          },
          {
            "num": "ח",
            "txt": "וְאֵ֣ת ׀ אַרְבַּ֣ע הָֽעֲגָלֹ֗ת וְאֵת֙ שְׁמֹנַ֣ת הַבָּקָ֔ר נָתַ֖ן לִבְנֵ֣י מְרָרִ֑י כְּפִי֙ עֲבֹ֣דָתָ֔ם בְּיַד֙ אִֽיתָמָ֔ר בֶּֽן־אַהֲרֹ֖ן הַכֹּהֵֽן׃"
          },
          {
            "num": "ט",
            "txt": "וְלִבְנֵ֥י קְהָ֖ת לֹ֣א נָתָ֑ן כִּֽי־עֲבֹדַ֤ת הַקֹּ֨דֶשׁ֙ עֲלֵהֶ֔ם בַּכָּתֵ֖ף יִשָּֽׂאוּ׃"
          },
          {
            "num": "י",
            "txt": "וַיַּקְרִ֣יבוּ הַנְּשִׂאִ֗ים אֵ֚ת חֲנֻכַּ֣ת הַמִּזְבֵּ֔חַ בְּי֖וֹם הִמָּשַׁ֣ח אֹת֑וֹ וַיַּקְרִ֧יבוּ הַנְּשִׂיאִ֛ם אֶת־קָרְבָּנָ֖ם לִפְנֵ֥י הַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֑ה נָשִׂ֨יא אֶחָ֜ד לַיּ֗וֹם נָשִׂ֤יא אֶחָד֙ לַיּ֔וֹם יַקְרִ֨יבוּ֙ אֶת־קָרְבָּנָ֔ם לַֽחֲנֻכַּ֖ת הַמִּזְבֵּֽחַ׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וַיְהִ֗י הַמַּקְרִ֛יב בַּיּ֥וֹם הָֽרִאשׁ֖וֹן אֶת־קָרְבָּנ֑וֹ נַחְשׁ֥וֹן בֶּן־עַמִּֽינָדָ֖ב לְמַטֵּ֥ה יְהוּדָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְקָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "יד",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "טו",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "טז",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "יז",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתּוּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן נַחְשׁ֖וֹן בֶּן־עַמִּֽינָדָֽב׃ {פ}"
          },
          {
            "num": "יח",
            "txt": "בַּיּוֹם֙ הַשֵּׁנִ֔י הִקְרִ֖יב נְתַנְאֵ֣ל בֶּן־צוּעָ֑ר נְשִׂ֖יא יִשָּׂשכָֽר׃"
          },
          {
            "num": "יט",
            "txt": "הִקְרִ֨ב אֶת־קָרְבָּנ֜וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "כ",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "כא",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "כב",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "כג",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן נְתַנְאֵ֖ל בֶּן־צוּעָֽר׃ {פ}"
          },
          {
            "num": "כד",
            "txt": "בַּיּוֹם֙ הַשְּׁלִישִׁ֔י נָשִׂ֖יא לִבְנֵ֣י זְבוּלֻ֑ן אֱלִיאָ֖ב בֶּן־חֵלֹֽן׃"
          },
          {
            "num": "כה",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "כו",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "כז",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "כח",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "כט",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן אֱלִיאָ֖ב בֶּן־חֵלֹֽן׃ {פ}"
          },
          {
            "num": "ל",
            "txt": "בַּיּוֹם֙ הָֽרְבִיעִ֔י נָשִׂ֖יא לִבְנֵ֣י רְאוּבֵ֑ן אֱלִיצ֖וּר בֶּן־שְׁדֵיאֽוּר׃"
          },
          {
            "num": "לא",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "לב",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "לג",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "לד",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "לה",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן אֱלִיצ֖וּר בֶּן־שְׁדֵיאֽוּר׃ {פ}"
          },
          {
            "num": "לו",
            "txt": "בַּיּוֹם֙ הַֽחֲמִישִׁ֔י נָשִׂ֖יא לִבְנֵ֣י שִׁמְע֑וֹן שְׁלֻֽמִיאֵ֖ל בֶּן־צוּרִֽישַׁדָּֽי׃"
          },
          {
            "num": "לז",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "לח",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "לט",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "מ",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "מא",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן שְׁלֻֽמִיאֵ֖ל בֶּן־צוּרִֽישַׁדָּֽי׃ {פ}"
          },
          {
            "num": "מב",
            "txt": "בַּיּוֹם֙ הַשִּׁשִּׁ֔י נָשִׂ֖יא לִבְנֵ֣י גָ֑ד אֶלְיָסָ֖ף בֶּן־דְּעוּאֵֽל׃"
          },
          {
            "num": "מג",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "מד",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "מה",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "מו",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "מז",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן אֶלְיָסָ֖ף בֶּן־דְּעוּאֵֽל׃ {פ}"
          },
          {
            "num": "מח",
            "txt": "בַּיּוֹם֙ הַשְּׁבִיעִ֔י נָשִׂ֖יא לִבְנֵ֣י אֶפְרָ֑יִם אֱלִֽישָׁמָ֖ע בֶּן־עַמִּיהֽוּד׃"
          },
          {
            "num": "מט",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "נ",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "נא",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "נב",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "נג",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן אֱלִֽישָׁמָ֖ע בֶּן־עַמִּיהֽוּד׃ {פ}"
          },
          {
            "num": "נד",
            "txt": "בַּיּוֹם֙ הַשְּׁמִינִ֔י נָשִׂ֖יא לִבְנֵ֣י מְנַשֶּׁ֑ה גַּמְלִיאֵ֖ל בֶּן־פְּדָהצֽוּר׃"
          },
          {
            "num": "נה",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "נו",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "נז",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "נח",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "נט",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן גַּמְלִיאֵ֖ל בֶּן־פְּדָהצֽוּר׃ {פ}"
          },
          {
            "num": "ס",
            "txt": "בַּיּוֹם֙ הַתְּשִׁיעִ֔י נָשִׂ֖יא לִבְנֵ֣י בִנְיָמִ֑ן אֲבִידָ֖ן בֶּן־גִּדְעֹנִֽי׃"
          },
          {
            "num": "סא",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "סב",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "סג",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "סד",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "סה",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן אֲבִידָ֖ן בֶּן־גִּדְעֹנִֽי׃ {פ}"
          },
          {
            "num": "סו",
            "txt": "בַּיּוֹם֙ הָֽעֲשִׂירִ֔י נָשִׂ֖יא לִבְנֵ֣י דָ֑ן אֲחִיעֶ֖זֶר בֶּן־עַמִּֽישַׁדָּֽי׃"
          },
          {
            "num": "סז",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "סח",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "סט",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "ע",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "עא",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן אֲחִיעֶ֖זֶר בֶּן־עַמִּֽישַׁדָּֽי׃ {פ}"
          },
          {
            "num": "עב",
            "txt": "בְּיוֹם֙ עַשְׁתֵּ֣י עָשָׂ֣ר י֔וֹם נָשִׂ֖יא לִבְנֵ֣י אָשֵׁ֑ר פַּגְעִיאֵ֖ל בֶּן־עָכְרָֽן׃"
          },
          {
            "num": "עג",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "עד",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "עה",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "עו",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "עז",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן פַּגְעִיאֵ֖ל בֶּן־עָכְרָֽן׃ {פ}"
          },
          {
            "num": "עח",
            "txt": "בְּיוֹם֙ שְׁנֵ֣ים עָשָׂ֣ר י֔וֹם נָשִׂ֖יא לִבְנֵ֣י נַפְתָּלִ֑י אֲחִירַ֖ע בֶּן־עֵינָֽן׃"
          },
          {
            "num": "עט",
            "txt": "קָרְבָּנ֞וֹ קַֽעֲרַת־כֶּ֣סֶף אַחַ֗ת שְׁלֹשִׁ֣ים וּמֵאָה֮ מִשְׁקָלָהּ֒ מִזְרָ֤ק אֶחָד֙ כֶּ֔סֶף שִׁבְעִ֥ים שֶׁ֖קֶל בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ שְׁנֵיהֶ֣ם ׀ מְלֵאִ֗ים סֹ֛לֶת בְּלוּלָ֥ה בַשֶּׁ֖מֶן לְמִנְחָֽה׃"
          },
          {
            "num": "פ",
            "txt": "כַּ֥ף אַחַ֛ת עֲשָׂרָ֥ה זָהָ֖ב מְלֵאָ֥ה קְטֹֽרֶת׃"
          },
          {
            "num": "פא",
            "txt": "פַּ֣ר אֶחָ֞ד בֶּן־בָּקָ֗ר אַ֧יִל אֶחָ֛ד כֶּֽבֶשׂ־אֶחָ֥ד בֶּן־שְׁנָת֖וֹ לְעֹלָֽה׃"
          },
          {
            "num": "פב",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽאת׃"
          },
          {
            "num": "פג",
            "txt": "וּלְזֶ֣בַח הַשְּׁלָמִים֮ בָּקָ֣ר שְׁנַיִם֒ אֵילִ֤ם חֲמִשָּׁה֙ עַתֻּדִ֣ים חֲמִשָּׁ֔ה כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה חֲמִשָּׁ֑ה זֶ֛ה קָרְבַּ֥ן אֲחִירַ֖ע בֶּן־עֵינָֽן׃ {פ}"
          },
          {
            "num": "פד",
            "txt": "זֹ֣את ׀ חֲנֻכַּ֣ת הַמִּזְבֵּ֗חַ בְּיוֹם֙ הִמָּשַׁ֣ח אֹת֔וֹ מֵאֵ֖ת נְשִׂיאֵ֣י יִשְׂרָאֵ֑ל קַֽעֲרֹ֨ת כֶּ֜סֶף שְׁתֵּ֣ים עֶשְׂרֵ֗ה מִֽזְרְקֵי־כֶ֨סֶף֙ שְׁנֵ֣ים עָשָׂ֔ר כַּפּ֥וֹת זָהָ֖ב שְׁתֵּ֥ים עֶשְׂרֵֽה׃"
          },
          {
            "num": "פה",
            "txt": "שְׁלֹשִׁ֣ים וּמֵאָ֗ה הַקְּעָרָ֤ה הָֽאַחַת֙ כֶּ֔סֶף וְשִׁבְעִ֖ים הַמִּזְרָ֣ק הָֽאֶחָ֑ד כֹּ֚ל כֶּ֣סֶף הַכֵּלִ֔ים אַלְפַּ֥יִם וְאַרְבַּע־מֵא֖וֹת בְּשֶׁ֥קֶל הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "פו",
            "txt": "כַּפּ֨וֹת זָהָ֤ב שְׁתֵּים־עֶשְׂרֵה֙ מְלֵאֹ֣ת קְטֹ֔רֶת עֲשָׂרָ֧ה עֲשָׂרָ֛ה הַכַּ֖ף בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ כָּל־זְהַ֥ב הַכַּפּ֖וֹת עֶשְׂרִ֥ים וּמֵאָֽה׃"
          },
          {
            "num": "פז",
            "txt": "כָּל־הַבָּקָ֨ר לָֽעֹלָ֜ה שְׁנֵ֧ים עָשָׂ֣ר פָּרִ֗ים אֵילִ֤ם שְׁנֵים־עָשָׂר֙ כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה שְׁנֵ֥ים עָשָׂ֖ר וּמִנְחָתָ֑ם וּשְׂעִירֵ֥י עִזִּ֛ים שְׁנֵ֥ים עָשָׂ֖ר לְחַטָּֽאת׃"
          },
          {
            "num": "פח",
            "txt": "וְכֹ֞ל בְּקַ֣ר ׀ זֶ֣בַח הַשְּׁלָמִ֗ים עֶשְׂרִ֣ים וְאַרְבָּעָה֮ פָּרִים֒ אֵילִ֤ם שִׁשִּׁים֙ עַתֻּדִ֣ים שִׁשִּׁ֔ים כְּבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה שִׁשִּׁ֑ים זֹ֚את חֲנֻכַּ֣ת הַמִּזְבֵּ֔חַ אַֽחֲרֵ֖י הִמָּשַׁ֥ח אֹתֽוֹ׃"
          },
          {
            "num": "פט",
            "txt": "וּבְבֹ֨א מֹשֶׁ֜ה אֶל־אֹ֣הֶל מוֹעֵד֮ לְדַבֵּ֣ר אִתּוֹ֒ וַיִּשְׁמַ֨ע אֶת־הַקּ֜וֹל מִדַּבֵּ֣ר אֵלָ֗יו מֵעַ֤ל הַכַּפֹּ֨רֶת֙ אֲשֶׁר֙ עַל־אֲרֹ֣ן הָֽעֵדֻ֔ת מִבֵּ֖ין שְׁנֵ֣י הַכְּרֻבִ֑ים וַיְדַבֵּ֖ר אֵלָֽיו׃ {פ}"
          }
        ]
      },
      {
        "num": "ח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּר֙ אֶֽל־אַהֲרֹ֔ן וְאָֽמַרְתָּ֖ אֵלָ֑יו בְּהַעֲלֹֽתְךָ֙ אֶת־הַנֵּרֹ֔ת אֶל־מוּל֙ פְּנֵ֣י הַמְּנוֹרָ֔ה יָאִ֖ירוּ שִׁבְעַ֥ת הַנֵּרֽוֹת׃"
          },
          {
            "num": "ג",
            "txt": "וַיַּ֤עַשׂ כֵּן֙ אַֽהֲרֹ֔ן אֶל־מוּל֙ פְּנֵ֣י הַמְּנוֹרָ֔ה הֶֽעֱלָ֖ה נֵֽרֹתֶ֑יהָ כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "ד",
            "txt": "וְזֶ֨ה מַֽעֲשֵׂ֤ה הַמְּנֹרָה֙ מִקְשָׁ֣ה זָהָ֔ב עַד־יְרֵכָ֥הּ עַד־פִּרְחָ֖הּ מִקְשָׁ֣ה הִ֑וא כַּמַּרְאֶ֗ה אֲשֶׁ֨ר הֶרְאָ֤ה יְהוָה֙ אֶת־מֹשֶׁ֔ה כֵּ֥ן עָשָׂ֖ה אֶת־הַמְּנֹרָֽה׃ {פ}"
          },
          {
            "num": "ה",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ו",
            "txt": "קַ֚ח אֶת־הַלְוִיִּ֔ם מִתּ֖וֹךְ בְּנֵ֣י יִשְׂרָאֵ֑ל וְטִֽהַרְתָּ֖ אֹתָֽם׃"
          },
          {
            "num": "ז",
            "txt": "וְכֹֽה־תַעֲשֶׂ֤ה לָהֶם֙ לְטַֽהֲרָ֔ם הַזֵּ֥ה עֲלֵיהֶ֖ם מֵ֣י חַטָּ֑את וְהֶֽעֱבִ֤ירוּ תַ֨עַר֙ עַל־כָּל־בְּשָׂרָ֔ם וְכִבְּס֥וּ בִגְדֵיהֶ֖ם וְהִטֶּהָֽרוּ׃"
          },
          {
            "num": "ח",
            "txt": "וְלָֽקְחוּ֙ פַּ֣ר בֶּן־בָּקָ֔ר וּמִ֨נְחָת֔וֹ סֹ֖לֶת בְּלוּלָ֣ה בַשָּׁ֑מֶן וּפַר־שֵׁנִ֥י בֶן־בָּקָ֖ר תִּקַּ֥ח לְחַטָּֽאת׃"
          },
          {
            "num": "ט",
            "txt": "וְהִקְרַבְתָּ֙ אֶת־הַלְוִיִּ֔ם לִפְנֵ֖י אֹ֣הֶל מוֹעֵ֑ד וְהִ֨קְהַלְתָּ֔ אֶֽת־כָּל־עֲדַ֖ת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "י",
            "txt": "וְהִקְרַבְתָּ֥ אֶת־הַלְוִיִּ֖ם לִפְנֵ֣י יְהוָ֑ה וְסָֽמְכ֧וּ בְנֵֽי־יִשְׂרָאֵ֛ל אֶת־יְדֵיהֶ֖ם עַל־הַלְוִיִּֽם׃"
          },
          {
            "num": "יא",
            "txt": "וְהֵנִיף֩ אַֽהֲרֹ֨ן אֶת־הַלְוִיִּ֤ם תְּנוּפָה֙ לִפְנֵ֣י יְהוָ֔ה מֵאֵ֖ת בְּנֵ֣י יִשְׂרָאֵ֑ל וְהָי֕וּ לַֽעֲבֹ֖ד אֶת־עֲבֹדַ֥ת יְהוָֽה׃"
          },
          {
            "num": "יב",
            "txt": "וְהַלְוִיִּם֙ יִסְמְכ֣וּ אֶת־יְדֵיהֶ֔ם עַ֖ל רֹ֣אשׁ הַפָּרִ֑ים וַֽ֠עֲשֵׂה אֶת־הָֽאֶחָ֨ד חַטָּ֜את וְאֶת־הָֽאֶחָ֤ד עֹלָה֙ לַֽיהוָ֔ה לְכַפֵּ֖ר עַל־הַלְוִיִּֽם׃"
          },
          {
            "num": "יג",
            "txt": "וְהַֽעֲמַדְתָּ֙ אֶת־הַלְוִיִּ֔ם לִפְנֵ֥י אַֽהֲרֹ֖ן וְלִפְנֵ֣י בָנָ֑יו וְהֵֽנַפְתָּ֥ אֹתָ֛ם תְּנוּפָ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְהִבְדַּלְתָּ֙ אֶת־הַלְוִיִּ֔ם מִתּ֖וֹךְ בְּנֵ֣י יִשְׂרָאֵ֑ל וְהָ֥יוּ לִ֖י הַלְוִיִּֽם׃"
          },
          {
            "num": "טו",
            "txt": "וְאַֽחֲרֵי־כֵן֙ יָבֹ֣אוּ הַלְוִיִּ֔ם לַֽעֲבֹ֖ד אֶת־אֹ֣הֶל מוֹעֵ֑ד וְטִֽהַרְתָּ֣ אֹתָ֔ם וְהֵֽנַפְתָּ֥ אֹתָ֖ם תְּנוּפָֽה׃"
          },
          {
            "num": "טז",
            "txt": "כִּי֩ נְתֻנִ֨ים נְתֻנִ֥ים הֵ֨מָּה֙ לִ֔י מִתּ֖וֹךְ בְּנֵ֣י יִשְׂרָאֵ֑ל תַּחַת֩ פִּטְרַ֨ת כָּל־רֶ֜חֶם בְּכ֥וֹר כֹּל֙ מִבְּנֵ֣י יִשְׂרָאֵ֔ל לָקַ֥חְתִּי אֹתָ֖ם לִֽי׃"
          },
          {
            "num": "יז",
            "txt": "כִּ֣י לִ֤י כָל־בְּכוֹר֙ בִּבְנֵ֣י יִשְׂרָאֵ֔ל בָּֽאָדָ֖ם וּבַבְּהֵמָ֑ה בְּי֗וֹם הַכֹּתִ֤י כָל־בְּכוֹר֙ בְּאֶ֣רֶץ מִצְרַ֔יִם הִקְדַּ֥שְׁתִּי אֹתָ֖ם לִֽי׃"
          },
          {
            "num": "יח",
            "txt": "וָֽאֶקַּ֖ח אֶת־הַלְוִיִּ֑ם תַּ֥חַת כָּל־בְּכ֖וֹר בִּבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יט",
            "txt": "וָֽאֶתְּנָ֨ה אֶת־הַלְוִיִּ֜ם נְתֻנִ֣ים ׀ לְאַֽהֲרֹ֣ן וּלְבָנָ֗יו מִתּוֹךְ֮ בְּנֵ֣י יִשְׂרָאֵל֒ לַֽעֲבֹ֞ד אֶת־עֲבֹדַ֤ת בְּנֵֽי־יִשְׂרָאֵל֙ בְּאֹ֣הֶל מוֹעֵ֔ד וּלְכַפֵּ֖ר עַל־בְּנֵ֣י יִשְׂרָאֵ֑ל וְלֹ֨א יִֽהְיֶ֜ה בִּבְנֵ֤י יִשְׂרָאֵל֙ נֶ֔גֶף בְּגֶ֥שֶׁת בְּנֵֽי־יִשְׂרָאֵ֖ל אֶל־הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "כ",
            "txt": "וַיַּ֨עַשׂ מֹשֶׁ֧ה וְאַֽהֲרֹ֛ן וְכָל־עֲדַ֥ת בְּנֵֽי־יִשְׂרָאֵ֖ל לַלְוִיִּ֑ם כְּ֠כֹל אֲשֶׁר־צִוָּ֨ה יְהוָ֤ה אֶת־מֹשֶׁה֙ לַלְוִיִּ֔ם כֵּן־עָשׂ֥וּ לָהֶ֖ם בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּֽתְחַטְּא֣וּ הַלְוִיִּ֗ם וַֽיְכַבְּסוּ֙ בִּגְדֵיהֶ֔ם וַיָּ֨נֶף אַֽהֲרֹ֥ן אֹתָ֛ם תְּנוּפָ֖ה לִפְנֵ֣י יְהוָ֑ה וַיְכַפֵּ֧ר עֲלֵיהֶ֛ם אַֽהֲרֹ֖ן לְטַֽהֲרָֽם׃"
          },
          {
            "num": "כב",
            "txt": "וְאַֽחֲרֵי־כֵ֞ן בָּ֣אוּ הַלְוִיִּ֗ם לַֽעֲבֹ֤ד אֶת־עֲבֹֽדָתָם֙ בְּאֹ֣הֶל מוֹעֵ֔ד לִפְנֵ֥י אַֽהֲרֹ֖ן וְלִפְנֵ֣י בָנָ֑יו כַּֽאֲשֶׁר֩ צִוָּ֨ה יְהוָ֤ה אֶת־מֹשֶׁה֙ עַל־הַלְוִיִּ֔ם כֵּ֖ן עָשׂ֥וּ לָהֶֽם׃ {ס}"
          },
          {
            "num": "כג",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כד",
            "txt": "זֹ֖את אֲשֶׁ֣ר לַלְוִיִּ֑ם מִבֶּן֩ חָמֵ֨שׁ וְעֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה יָבוֹא֙ לִצְבֹ֣א צָבָ֔א בַּֽעֲבֹדַ֖ת אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "כה",
            "txt": "וּמִבֶּן֙ חֲמִשִּׁ֣ים שָׁנָ֔ה יָשׁ֖וּב מִצְּבָ֣א הָֽעֲבֹדָ֑ה וְלֹ֥א יַֽעֲבֹ֖ד עֽוֹד׃"
          },
          {
            "num": "כו",
            "txt": "וְשֵׁרֵ֨ת אֶת־אֶחָ֜יו בְּאֹ֤הֶל מוֹעֵד֙ לִשְׁמֹ֣ר מִשְׁמֶ֔רֶת וַֽעֲבֹדָ֖ה לֹ֣א יַֽעֲבֹ֑ד כָּ֛כָה תַּֽעֲשֶׂ֥ה לַלְוִיִּ֖ם בְּמִשְׁמְרֹתָֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "ט",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֣ה אֶל־מֹשֶׁ֣ה בְמִדְבַּר־סִ֠ינַי בַּשָּׁנָ֨ה הַשֵּׁנִ֜ית לְצֵאתָ֨ם מֵאֶ֧רֶץ מִצְרַ֛יִם בַּחֹ֥דֶשׁ הָֽרִאשׁ֖וֹן לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "וְיַֽעֲשׂ֧וּ בְנֵֽי־יִשְׂרָאֵ֛ל אֶת־הַפָּ֖סַח בְּמֽוֹעֲדֽוֹ׃"
          },
          {
            "num": "ג",
            "txt": "בְּאַרְבָּעָ֣ה עָשָֽׂר־י֠וֹם בַּחֹ֨דֶשׁ הַזֶּ֜ה בֵּ֧ין הָֽעַרְבַּ֛יִם תַּֽעֲשׂ֥וּ אֹת֖וֹ בְּמֹֽעֲד֑וֹ כְּכָל־חֻקֹּתָ֥יו וּכְכָל־מִשְׁפָּטָ֖יו תַּֽעֲשׂ֥וּ אֹתֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "וַיְדַבֵּ֥ר מֹשֶׁ֛ה אֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל לַֽעֲשֹׂ֥ת הַפָּֽסַח׃"
          },
          {
            "num": "ה",
            "txt": "וַיַּֽעֲשׂ֣וּ אֶת־הַפֶּ֡סַח בָּֽרִאשׁ֡וֹן בְּאַרְבָּעָה֩ עָשָׂ֨ר י֥וֹם לַחֹ֛דֶשׁ בֵּ֥ין הָֽעַרְבַּ֖יִם בְּמִדְבַּ֣ר סִינָ֑י כְּ֠כֹל אֲשֶׁ֨ר צִוָּ֤ה יְהוָה֙ אֶת־מֹשֶׁ֔ה כֵּ֥ן עָשׂ֖וּ בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ו",
            "txt": "וַיְהִ֣י אֲנָשִׁ֗ים אֲשֶׁ֨ר הָי֤וּ טְמֵאִים֙ לְנֶ֣פֶשׁ אָדָ֔ם וְלֹֽא־יָכְל֥וּ לַֽעֲשֹׂת־הַפֶּ֖סַח בַּיּ֣וֹם הַה֑וּא וַֽיִּקְרְב֞וּ לִפְנֵ֥י מֹשֶׁ֛ה וְלִפְנֵ֥י אַֽהֲרֹ֖ן בַּיּ֥וֹם הַהֽוּא׃"
          },
          {
            "num": "ז",
            "txt": "וַ֠יֹּֽאמְרוּ הָֽאֲנָשִׁ֤ים הָהֵ֨מָּה֙ אֵלָ֔יו אֲנַ֥חְנוּ טְמֵאִ֖ים לְנֶ֣פֶשׁ אָדָ֑ם לָ֣מָּה נִגָּרַ֗ע לְבִלְתִּ֨י הַקְרִ֜יב אֶת־קָרְבַּ֤ן יְהוָה֙ בְּמֹ֣עֲד֔וֹ בְּת֖וֹךְ בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֥אמֶר אֲלֵהֶ֖ם מֹשֶׁ֑ה עִמְד֣וּ וְאֶשְׁמְעָ֔ה מַה־יְצַוֶּ֥ה יְהוָ֖ה לָכֶֽם׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "י",
            "txt": "דַּבֵּ֛ר אֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר אִ֣ישׁ אִ֣ישׁ כִּי־יִהְיֶֽה־טָמֵ֣א ׀ לָנֶ֡פֶשׁ אוֹ֩ בְדֶ֨רֶךְ רְחֹקָ֜הׄ לָכֶ֗ם א֚וֹ לְדֹרֹ֣תֵיכֶ֔ם וְעָ֥שָׂה פֶ֖סַח לַֽיהוָֽה׃"
          },
          {
            "num": "יא",
            "txt": "בַּחֹ֨דֶשׁ הַשֵּׁנִ֜י בְּאַרְבָּעָ֨ה עָשָׂ֥ר י֛וֹם בֵּ֥ין הָֽעַרְבַּ֖יִם יַֽעֲשׂ֣וּ אֹת֑וֹ עַל־מַצּ֥וֹת וּמְרֹרִ֖ים יֹֽאכְלֻֽהוּ׃"
          },
          {
            "num": "יב",
            "txt": "לֹֽא־יַשְׁאִ֤ירוּ מִמֶּ֨נּוּ֙ עַד־בֹּ֔קֶר וְעֶ֖צֶם לֹ֣א יִשְׁבְּרוּ־ב֑וֹ כְּכָל־חֻקַּ֥ת הַפֶּ֖סַח יַֽעֲשׂ֥וּ אֹתֽוֹ׃"
          },
          {
            "num": "יג",
            "txt": "וְהָאִישׁ֩ אֲשֶׁר־ה֨וּא טָה֜וֹר וּבְדֶ֣רֶךְ לֹֽא־הָיָ֗ה וְחָדַל֙ לַֽעֲשׂ֣וֹת הַפֶּ֔סַח וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מֵֽעַמֶּ֑יהָ כִּ֣י ׀ קָרְבַּ֣ן יְהוָ֗ה לֹ֤א הִקְרִיב֙ בְּמֹ֣עֲד֔וֹ חֶטְא֥וֹ יִשָּׂ֖א הָאִ֥ישׁ הַהֽוּא׃"
          },
          {
            "num": "יד",
            "txt": "וְכִֽי־יָג֨וּר אִתְּכֶ֜ם גֵּ֗ר וְעָ֤שָֽׂה פֶ֨סַח֙ לַֽיהוָ֔ה כְּחֻקַּ֥ת הַפֶּ֛סַח וּכְמִשְׁפָּט֖וֹ כֵּ֣ן יַֽעֲשֶׂ֑ה חֻקָּ֤ה אַחַת֙ יִֽהְיֶ֣ה לָכֶ֔ם וְלַגֵּ֖ר וּלְאֶזְרַ֥ח הָאָֽרֶץ׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "וּבְיוֹם֙ הָקִ֣ים אֶת־הַמִּשְׁכָּ֔ן כִּסָּ֤ה הֶֽעָנָן֙ אֶת־הַמִּשְׁכָּ֔ן לְאֹ֖הֶל הָֽעֵדֻ֑ת וּבָעֶ֜רֶב יִֽהְיֶ֧ה עַֽל־הַמִּשְׁכָּ֛ן כְּמַרְאֵה־אֵ֖שׁ עַד־בֹּֽקֶר׃"
          },
          {
            "num": "טז",
            "txt": "כֵּ֚ן יִֽהְיֶ֣ה תָמִ֔יד הֶֽעָנָ֖ן יְכַסֶּ֑נּוּ וּמַרְאֵה־אֵ֖שׁ לָֽיְלָה׃"
          },
          {
            "num": "יז",
            "txt": "וּלְפִ֞י הֵֽעָל֤וֹת הֶֽעָנָן֙ מֵעַ֣ל הָאֹ֔הֶל וְאַ֣חֲרֵי כֵ֔ן יִסְע֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל וּבִמְק֗וֹם אֲשֶׁ֤ר יִשְׁכָּן־שָׁם֙ הֶֽעָנָ֔ן שָׁ֥ם יַֽחֲנ֖וּ בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יח",
            "txt": "עַל־פִּ֣י יְהוָ֗ה יִסְעוּ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וְעַל־פִּ֥י יְהוָ֖ה יַֽחֲנ֑וּ כָּל־יְמֵ֗י אֲשֶׁ֨ר יִשְׁכֹּ֧ן הֶֽעָנָ֛ן עַל־הַמִּשְׁכָּ֖ן יַֽחֲנֽוּ׃"
          },
          {
            "num": "יט",
            "txt": "וּבְהַֽאֲרִ֧יךְ הֶֽעָנָ֛ן עַל־הַמִּשְׁכָּ֖ן יָמִ֣ים רַבִּ֑ים וְשָֽׁמְר֧וּ בְנֵֽי־יִשְׂרָאֵ֛ל אֶת־מִשְׁמֶ֥רֶת יְהוָ֖ה וְלֹ֥א יִסָּֽעוּ׃"
          },
          {
            "num": "כ",
            "txt": "וְיֵ֞שׁ אֲשֶׁ֨ר יִֽהְיֶ֧ה הֶֽעָנָ֛ן יָמִ֥ים מִסְפָּ֖ר עַל־הַמִּשְׁכָּ֑ן עַל־פִּ֤י יְהוָה֙ יַֽחֲנ֔וּ וְעַל־פִּ֥י יְהוָ֖ה יִסָּֽעוּ׃"
          },
          {
            "num": "כא",
            "txt": "וְיֵ֞שׁ אֲשֶׁר־יִֽהְיֶ֤ה הֶֽעָנָן֙ מֵעֶ֣רֶב עַד־בֹּ֔קֶר וְנַֽעֲלָ֧ה הֶֽעָנָ֛ן בַּבֹּ֖קֶר וְנָסָ֑עוּ א֚וֹ יוֹמָ֣ם וָלַ֔יְלָה וְנַֽעֲלָ֥ה הֶֽעָנָ֖ן וְנָסָֽעוּ׃"
          },
          {
            "num": "כב",
            "txt": "אֽוֹ־יֹמַ֜יִם אוֹ־חֹ֣דֶשׁ אֽוֹ־יָמִ֗ים בְּהַֽאֲרִ֨יךְ הֶֽעָנָ֤ן עַל־הַמִּשְׁכָּן֙ לִשְׁכֹּ֣ן עָלָ֔יו יַֽחֲנ֥וּ בְנֵֽי־יִשְׂרָאֵ֖ל וְלֹ֣א יִסָּ֑עוּ וּבְהֵעָֽלֹת֖וֹ יִסָּֽעוּ׃"
          },
          {
            "num": "כג",
            "txt": "עַל־פִּ֤י יְהוָה֙ יַֽחֲנ֔וּ וְעַל־פִּ֥י יְהוָ֖ה יִסָּ֑עוּ אֶת־מִשְׁמֶ֤רֶת יְהוָה֙ שָׁמָ֔רוּ עַל־פִּ֥י יְהוָ֖ה בְּיַד־מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "י",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "עֲשֵׂ֣ה לְךָ֗ שְׁתֵּי֙ חֲצֽוֹצְרֹ֣ת כֶּ֔סֶף מִקְשָׁ֖ה תַּֽעֲשֶׂ֣ה אֹתָ֑ם וְהָי֤וּ לְךָ֙ לְמִקְרָ֣א הָֽעֵדָ֔ה וּלְמַסַּ֖ע אֶת־הַֽמַּחֲנֽוֹת׃"
          },
          {
            "num": "ג",
            "txt": "וְתָֽקְע֖וּ בָּהֵ֑ן וְנֽוֹעֲד֤וּ אֵלֶ֨יךָ֙ כָּל־הָ֣עֵדָ֔ה אֶל־פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ד",
            "txt": "וְאִם־בְּאַחַ֖ת יִתְקָ֑עוּ וְנֽוֹעֲד֤וּ אֵלֶ֨יךָ֙ הַנְּשִׂיאִ֔ים רָאשֵׁ֖י אַלְפֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ה",
            "txt": "וּתְקַעְתֶּ֖ם תְּרוּעָ֑ה וְנָֽסְעוּ֙ הַֽמַּחֲנ֔וֹת הַֽחֹנִ֖ים קֵֽדְמָה׃"
          },
          {
            "num": "ו",
            "txt": "וּתְקַעְתֶּ֤ם תְּרוּעָה֙ שֵׁנִ֔ית וְנָֽסְעוּ֙ הַֽמַּחֲנ֔וֹת הַֽחֹנִ֖ים תֵּימָ֑נָה תְּרוּעָ֥ה יִתְקְע֖וּ לְמַסְעֵיהֶֽם׃"
          },
          {
            "num": "ז",
            "txt": "וּבְהַקְהִ֖יל אֶת־הַקָּהָ֑ל תִּתְקְע֖וּ וְלֹ֥א תָרִֽיעוּ׃"
          },
          {
            "num": "ח",
            "txt": "וּבְנֵ֤י אַֽהֲרֹן֙ הַכֹּ֣הֲנִ֔ים יִתְקְע֖וּ בַּחֲצֹֽצְר֑וֹת וְהָי֥וּ לָכֶ֛ם לְחֻקַּ֥ת עוֹלָ֖ם לְדֹרֹֽתֵיכֶֽם׃"
          },
          {
            "num": "ט",
            "txt": "וְכִֽי־תָבֹ֨אוּ מִלְחָמָ֜ה בְּאַרְצְכֶ֗ם עַל־הַצַּר֙ הַצֹּרֵ֣ר אֶתְכֶ֔ם וַהֲרֵֽעֹתֶ֖ם בַּחֲצֹֽצְרֹ֑ת וְנִזְכַּרְתֶּ֗ם לִפְנֵי֙ יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם וְנֽוֹשַׁעְתֶּ֖ם מֵאֹֽיְבֵיכֶֽם׃"
          },
          {
            "num": "י",
            "txt": "וּבְי֨וֹם שִׂמְחַתְכֶ֥ם וּֽבְמוֹעֲדֵיכֶם֮ וּבְרָאשֵׁ֣י חָדְשֵׁיכֶם֒ וּתְקַעְתֶּ֣ם בַּחֲצֹֽצְרֹ֗ת עַ֚ל עֹלֹ֣תֵיכֶ֔ם וְעַ֖ל זִבְחֵ֣י שַׁלְמֵיכֶ֑ם וְהָי֨וּ לָכֶ֤ם לְזִכָּרוֹן֙ לִפְנֵ֣י אֱלֹֽהֵיכֶ֔ם אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וַיְהִ֞י בַּשָּׁנָ֧ה הַשֵּׁנִ֛ית בַּחֹ֥דֶשׁ הַשֵּׁנִ֖י בְּעֶשְׂרִ֣ים בַּחֹ֑דֶשׁ נַֽעֲלָה֙ הֶֽעָנָ֔ן מֵעַ֖ל מִשְׁכַּ֥ן הָֽעֵדֻֽת׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּסְע֧וּ בְנֵֽי־יִשְׂרָאֵ֛ל לְמַסְעֵיהֶ֖ם מִמִּדְבַּ֣ר סִינָ֑י וַיִּשְׁכֹּ֥ן הֶֽעָנָ֖ן בְּמִדְבַּ֥ר פָּארָֽן׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּסְע֖וּ בָּרִֽאשֹׁנָ֑ה עַל־פִּ֥י יְהוָ֖ה בְּיַד־מֹשֶֽׁה׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּסַּ֞ע דֶּ֣גֶל מַֽחֲנֵ֧ה בְנֵֽי־יְהוּדָ֛ה בָּרִֽאשֹׁנָ֖ה לְצִבְאֹתָ֑ם וְעַ֨ל־צְבָא֔וֹ נַחְשׁ֖וֹן בֶּן־עַמִּֽינָדָֽב׃"
          },
          {
            "num": "טו",
            "txt": "וְעַ֨ל־צְבָ֔א מַטֵּ֖ה בְּנֵ֣י יִשָּׂשכָ֑ר נְתַנְאֵ֖ל בֶּן־צוּעָֽר׃"
          },
          {
            "num": "טז",
            "txt": "וְעַ֨ל־צְבָ֔א מַטֵּ֖ה בְּנֵ֣י זְבוּלֻ֑ן אֱלִיאָ֖ב בֶּן־חֵלֹֽן׃"
          },
          {
            "num": "יז",
            "txt": "וְהוּרַ֖ד הַמִּשְׁכָּ֑ן וְנָֽסְע֤וּ בְנֵֽי־גֵרְשׁוֹן֙ וּבְנֵ֣י מְרָרִ֔י נֹֽשְׂאֵ֖י הַמִּשְׁכָּֽן׃"
          },
          {
            "num": "יח",
            "txt": "וְנָסַ֗ע דֶּ֛גֶל מַֽחֲנֵ֥ה רְאוּבֵ֖ן לְצִבְאֹתָ֑ם וְעַ֨ל־צְבָא֔וֹ אֱלִיצ֖וּר בֶּן־שְׁדֵיאֽוּר׃"
          },
          {
            "num": "יט",
            "txt": "וְעַ֨ל־צְבָ֔א מַטֵּ֖ה בְּנֵ֣י שִׁמְע֑וֹן שְׁלֻֽמִיאֵ֖ל בֶּן־צוּרִֽישַׁדָּֽי׃"
          },
          {
            "num": "כ",
            "txt": "וְעַל־צְבָ֖א מַטֵּ֣ה בְנֵי־גָ֑ד אֶלְיָסָ֖ף בֶּן־דְּעוּאֵֽל׃"
          },
          {
            "num": "כא",
            "txt": "וְנָֽסְעוּ֙ הַקְּהָתִ֔ים נֹֽשְׂאֵ֖י הַמִּקְדָּ֑שׁ וְהֵקִ֥ימוּ אֶת־הַמִּשְׁכָּ֖ן עַד־בֹּאָֽם׃"
          },
          {
            "num": "כב",
            "txt": "וְנָסַ֗ע דֶּ֛גֶל מַֽחֲנֵ֥ה בְנֵֽי־אֶפְרַ֖יִם לְצִבְאֹתָ֑ם וְעַ֨ל־צְבָא֔וֹ אֱלִֽישָׁמָ֖ע בֶּן־עַמִּיהֽוּד׃"
          },
          {
            "num": "כג",
            "txt": "וְעַ֨ל־צְבָ֔א מַטֵּ֖ה בְּנֵ֣י מְנַשֶּׁ֑ה גַּמְלִיאֵ֖ל בֶּן־פְּדָהצֽוּר׃"
          },
          {
            "num": "כד",
            "txt": "וְעַ֨ל־צְבָ֔א מַטֵּ֖ה בְּנֵ֣י בִנְיָמִ֑ן אֲבִידָ֖ן בֶּן־גִּדְעוֹנִֽי׃"
          },
          {
            "num": "כה",
            "txt": "וְנָסַ֗ע דֶּ֚גֶל מַֽחֲנֵ֣ה בְנֵי־דָ֔ן מְאַסֵּ֥ף לְכָל־הַֽמַּחֲנֹ֖ת לְצִבְאֹתָ֑ם וְעַ֨ל־צְבָא֔וֹ אֲחִיעֶ֖זֶר בֶּן־עַמִּֽישַׁדָּֽי׃"
          },
          {
            "num": "כו",
            "txt": "וְעַ֨ל־צְבָ֔א מַטֵּ֖ה בְּנֵ֣י אָשֵׁ֑ר פַּגְעִיאֵ֖ל בֶּן־עָכְרָֽן׃"
          },
          {
            "num": "כז",
            "txt": "וְעַ֨ל־צְבָ֔א מַטֵּ֖ה בְּנֵ֣י נַפְתָּלִ֑י אֲחִירַ֖ע בֶּן־עֵינָֽן׃"
          },
          {
            "num": "כח",
            "txt": "אֵ֛לֶּה מַסְעֵ֥י בְנֵֽי־יִשְׂרָאֵ֖ל לְצִבְאֹתָ֑ם וַיִּסָּֽעוּ׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֗ה לְ֠חֹבָב בֶּן־רְעוּאֵ֣ל הַמִּדְיָנִי֮ חֹתֵ֣ן מֹשֶׁה֒ נֹֽסְעִ֣ים ׀ אֲנַ֗חְנוּ אֶל־הַמָּקוֹם֙ אֲשֶׁ֣ר אָמַ֣ר יְהוָ֔ה אֹת֖וֹ אֶתֵּ֣ן לָכֶ֑ם לְכָ֤ה אִתָּ֨נוּ֙ וְהֵטַ֣בְנוּ לָ֔ךְ כִּֽי־יְהוָ֥ה דִּבֶּר־ט֖וֹב עַל־יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ל",
            "txt": "וַיֹּ֥אמֶר אֵלָ֖יו לֹ֣א אֵלֵ֑ךְ כִּ֧י אִם־אֶל־אַרְצִ֛י וְאֶל־מֽוֹלַדְתִּ֖י אֵלֵֽךְ׃"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֕אמֶר אַל־נָ֖א תַּֽעֲזֹ֣ב אֹתָ֑נוּ כִּ֣י ׀ עַל־כֵּ֣ן יָדַ֗עְתָּ חֲנֹתֵ֨נוּ֙ בַּמִּדְבָּ֔ר וְהָיִ֥יתָ לָּ֖נוּ לְעֵינָֽיִם׃"
          },
          {
            "num": "לב",
            "txt": "וְהָיָ֖ה כִּֽי־תֵלֵ֣ךְ עִמָּ֑נוּ וְהָיָ֣ה ׀ הַטּ֣וֹב הַה֗וּא אֲשֶׁ֨ר יֵיטִ֧יב יְהוָ֛ה עִמָּ֖נוּ וְהֵטַ֥בְנוּ לָֽךְ׃"
          },
          {
            "num": "לג",
            "txt": "וַיִּסְעוּ֙ מֵהַ֣ר יְהוָ֔ה דֶּ֖רֶךְ שְׁלֹ֣שֶׁת יָמִ֑ים וַֽאֲר֨וֹן בְּרִית־יְהוָ֜ה נֹסֵ֣עַ לִפְנֵיהֶ֗ם דֶּ֚רֶךְ שְׁלֹ֣שֶׁת יָמִ֔ים לָת֥וּר לָהֶ֖ם מְנוּחָֽה׃"
          },
          {
            "num": "לד",
            "txt": "וַֽעֲנַ֧ן יְהוָ֛ה עֲלֵיהֶ֖ם יוֹמָ֑ם בְּנָסְעָ֖ם מִן־הַֽמַּחֲנֶֽה׃ {ס} ׆ {ס}"
          },
          {
            "num": "לה",
            "txt": "וַיְהִ֛י בִּנְסֹ֥עַ הָֽאָרֹ֖ן וַיֹּ֣אמֶר מֹשֶׁ֑ה קוּמָ֣ה ׀ יְהוָ֗ה וְיָפֻ֨צוּ֙ אֹֽיְבֶ֔יךָ וְיָנֻ֥סוּ מְשַׂנְאֶ֖יךָ מִפָּנֶֽיךָ׃"
          },
          {
            "num": "לו",
            "txt": "וּבְנֻחֹ֖ה יֹאמַ֑ר שׁוּבָ֣ה יְהוָ֔ה רִֽבְב֖וֹת אַלְפֵ֥י יִשְׂרָאֵֽל׃ {ס} ׆ {פ}"
          }
        ]
      },
      {
        "num": "יא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֤י הָעָם֙ כְּמִתְאֹ֣נְנִ֔ים רַ֖ע בְּאָזְנֵ֣י יְהוָ֑ה וַיִּשְׁמַ֤ע יְהוָה֙ וַיִּ֣חַר אַפּ֔וֹ וַתִּבְעַר־בָּם֙ אֵ֣שׁ יְהוָ֔ה וַתֹּ֖אכַל בִּקְצֵ֥ה הַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּצְעַ֥ק הָעָ֖ם אֶל־מֹשֶׁ֑ה וַיִּתְפַּלֵּ֤ל מֹשֶׁה֙ אֶל־יְהוָ֔ה וַתִּשְׁקַ֖ע הָאֵֽשׁ׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּקְרָ֛א שֵֽׁם־הַמָּק֥וֹם הַה֖וּא תַּבְעֵרָ֑ה כִּֽי־בָעֲרָ֥ה בָ֖ם אֵ֥שׁ יְהוָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וְהָֽאסַפְסֻף֙ אֲשֶׁ֣ר בְּקִרְבּ֔וֹ הִתְאַוּ֖וּ תַּֽאֲוָ֑ה וַיָּשֻׁ֣בוּ וַיִּבְכּ֗וּ גַּ֚ם בְּנֵ֣י יִשְׂרָאֵ֔ל וַיֹּ֣אמְר֔וּ מִ֥י יַֽאֲכִלֵ֖נוּ בָּשָֽׂר׃"
          },
          {
            "num": "ה",
            "txt": "זָכַ֨רְנוּ֙ אֶת־הַדָּגָ֔ה אֲשֶׁר־נֹאכַ֥ל בְּמִצְרַ֖יִם חִנָּ֑ם אֵ֣ת הַקִּשֻּׁאִ֗ים וְאֵת֙ הָֽאֲבַטִּחִ֔ים וְאֶת־הֶֽחָצִ֥יר וְאֶת־הַבְּצָלִ֖ים וְאֶת־הַשּׁוּמִֽים׃"
          },
          {
            "num": "ו",
            "txt": "וְעַתָּ֛ה נַפְשֵׁ֥נוּ יְבֵשָׁ֖ה אֵ֣ין כֹּ֑ל בִּלְתִּ֖י אֶל־הַמָּ֥ן עֵינֵֽינוּ׃"
          },
          {
            "num": "ז",
            "txt": "וְהַמָּ֕ן כִּזְרַע־גַּ֖ד ה֑וּא וְעֵינ֖וֹ כְּעֵ֥ין הַבְּדֹֽלַח׃"
          },
          {
            "num": "ח",
            "txt": "שָׁטוּ֩ הָעָ֨ם וְלָֽקְט֜וּ וְטָֽחֲנ֣וּ בָֽרֵחַ֗יִם א֤וֹ דָכוּ֙ בַּמְּדֹכָ֔ה וּבִשְּׁלוּ֙ בַּפָּר֔וּר וְעָשׂ֥וּ אֹת֖וֹ עֻג֑וֹת וְהָיָ֣ה טַעְמ֔וֹ כְּטַ֖עַם לְשַׁ֥ד הַשָּֽׁמֶן׃"
          },
          {
            "num": "ט",
            "txt": "וּבְרֶ֧דֶת הַטַּ֛ל עַל־הַֽמַּחֲנֶ֖ה לָ֑יְלָה יֵרֵ֥ד הַמָּ֖ן עָלָֽיו׃"
          },
          {
            "num": "י",
            "txt": "וַיִּשְׁמַ֨ע מֹשֶׁ֜ה אֶת־הָעָ֗ם בֹּכֶה֙ לְמִשְׁפְּחֹתָ֔יו אִ֖ישׁ לְפֶ֣תַח אָֽהֳל֑וֹ וַיִּֽחַר־אַ֤ף יְהוָה֙ מְאֹ֔ד וּבְעֵינֵ֥י מֹשֶׁ֖ה רָֽע׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶל־יְהוָ֗ה לָמָ֤ה הֲרֵעֹ֨תָ֙ לְעַבְדֶּ֔ךָ וְלָ֛מָּה לֹֽא־מָצָ֥תִי חֵ֖ן בְּעֵינֶ֑יךָ לָשׂ֗וּם אֶת־מַשָּׂ֛א כָּל־הָעָ֥ם הַזֶּ֖ה עָלָֽי׃"
          },
          {
            "num": "יב",
            "txt": "הֶאָֽנֹכִ֣י הָרִ֗יתִי אֵ֚ת כָּל־הָעָ֣ם הַזֶּ֔ה אִם־אָֽנֹכִ֖י יְלִדְתִּ֑יהוּ כִּֽי־תֹאמַ֨ר אֵלַ֜י שָׂאֵ֣הוּ בְחֵיקֶ֗ךָ כַּֽאֲשֶׁ֨ר יִשָּׂ֤א הָֽאֹמֵן֙ אֶת־הַיֹּנֵ֔ק עַ֚ל הָֽאֲדָמָ֔ה אֲשֶׁ֥ר נִשְׁבַּ֖עְתָּ לַֽאֲבֹתָֽיו׃"
          },
          {
            "num": "יג",
            "txt": "מֵאַ֤יִן לִי֙ בָּשָׂ֔ר לָתֵ֖ת לְכָל־הָעָ֣ם הַזֶּ֑ה כִּֽי־יִבְכּ֤וּ עָלַי֙ לֵאמֹ֔ר תְּנָה־לָּ֥נוּ בָשָׂ֖ר וְנֹאכֵֽלָה׃"
          },
          {
            "num": "יד",
            "txt": "לֹֽא־אוּכַ֤ל אָֽנֹכִי֙ לְבַדִּ֔י לָשֵׂ֖את אֶת־כָּל־הָעָ֣ם הַזֶּ֑ה כִּ֥י כָבֵ֖ד מִמֶּֽנִּי׃"
          },
          {
            "num": "טו",
            "txt": "וְאִם־כָּ֣כָה ׀ אַתְּ־עֹ֣שֶׂה לִּ֗י הָרְגֵ֤נִי נָא֙ הָרֹ֔ג אִם־מָצָ֥אתִי חֵ֖ן בְּעֵינֶ֑יךָ וְאַל־אֶרְאֶ֖ה בְּרָֽעָתִֽי׃ {פ}"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה אֶסְפָה־לִּ֞י שִׁבְעִ֣ים אִישׁ֮ מִזִּקְנֵ֣י יִשְׂרָאֵל֒ אֲשֶׁ֣ר יָדַ֔עְתָּ כִּי־הֵ֛ם זִקְנֵ֥י הָעָ֖ם וְשֹֽׁטְרָ֑יו וְלָֽקַחְתָּ֤ אֹתָם֙ אֶל־אֹ֣הֶל מוֹעֵ֔ד וְהִֽתְיַצְּב֥וּ שָׁ֖ם עִמָּֽךְ׃"
          },
          {
            "num": "יז",
            "txt": "וְיָֽרַדְתִּ֗י וְדִבַּרְתִּ֣י עִמְּךָ֮ שָׁם֒ וְאָֽצַלְתִּ֗י מִן־הָר֛וּחַ אֲשֶׁ֥ר עָלֶ֖יךָ וְשַׂמְתִּ֣י עֲלֵיהֶ֑ם וְנָֽשְׂא֤וּ אִתְּךָ֙ בְּמַשָּׂ֣א הָעָ֔ם וְלֹֽא־תִשָּׂ֥א אַתָּ֖ה לְבַדֶּֽךָ׃"
          },
          {
            "num": "יח",
            "txt": "וְאֶל־הָעָ֨ם תֹּאמַ֜ר הִתְקַדְּשׁ֣וּ לְמָחָר֮ וַֽאֲכַלְתֶּ֣ם בָּשָׂר֒ כִּ֡י בְּכִיתֶם֩ בְּאָזְנֵ֨י יְהוָ֜ה לֵאמֹ֗ר מִ֤י יַֽאֲכִלֵ֨נוּ֙ בָּשָׂ֔ר כִּי־ט֥וֹב לָ֖נוּ בְּמִצְרָ֑יִם וְנָתַ֨ן יְהוָ֥ה לָכֶ֛ם בָּשָׂ֖ר וַֽאֲכַלְתֶּֽם׃"
          },
          {
            "num": "יט",
            "txt": "לֹ֣א י֥וֹם אֶחָ֛ד תֹּֽאכְל֖וּן וְלֹ֣א יוֹמָ֑יִם וְלֹ֣א ׀ חֲמִשָּׁ֣ה יָמִ֗ים וְלֹא֙ עֲשָׂרָ֣ה יָמִ֔ים וְלֹ֖א עֶשְׂרִ֥ים יֽוֹם׃"
          },
          {
            "num": "כ",
            "txt": "עַ֣ד ׀ חֹ֣דֶשׁ יָמִ֗ים עַ֤ד אֲשֶׁר־יֵצֵא֙ מֵֽאַפְּכֶ֔ם וְהָיָ֥ה לָכֶ֖ם לְזָרָ֑א יַ֗עַן כִּֽי־מְאַסְתֶּ֤ם אֶת־יְהוָה֙ אֲשֶׁ֣ר בְּקִרְבְּכֶ֔ם וַתִּבְכּ֤וּ לְפָנָיו֙ לֵאמֹ֔ר לָ֥מָּה זֶּ֖ה יָצָ֥אנוּ מִמִּצְרָֽיִם׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּאמֶר֮ מֹשֶׁה֒ שֵׁשׁ־מֵא֥וֹת אֶ֨לֶף֙ רַגְלִ֔י הָעָ֕ם אֲשֶׁ֥ר אָֽנֹכִ֖י בְּקִרְבּ֑וֹ וְאַתָּ֣ה אָמַ֗רְתָּ בָּשָׂר֙ אֶתֵּ֣ן לָהֶ֔ם וְאָֽכְל֖וּ חֹ֥דֶשׁ יָמִֽים׃"
          },
          {
            "num": "כב",
            "txt": "הֲצֹ֧אן וּבָקָ֛ר יִשָּׁחֵ֥ט לָהֶ֖ם וּמָצָ֣א לָהֶ֑ם אִ֣ם אֶֽת־כָּל־דְּגֵ֥י הַיָּ֛ם יֵֽאָסֵ֥ף לָהֶ֖ם וּמָצָ֥א לָהֶֽם׃ {פ}"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה הֲיַ֥ד יְהוָ֖ה תִּקְצָ֑ר עַתָּ֥ה תִרְאֶ֛ה הֲיִקְרְךָ֥ דְבָרִ֖י אִם־לֹֽא׃"
          },
          {
            "num": "כד",
            "txt": "וַיֵּצֵ֣א מֹשֶׁ֔ה וַיְדַבֵּר֙ אֶל־הָעָ֔ם אֵ֖ת דִּבְרֵ֣י יְהוָ֑ה וַיֶּֽאֱסֹ֞ף שִׁבְעִ֥ים אִישׁ֙ מִזִּקְנֵ֣י הָעָ֔ם וַֽיַּעֲמֵ֥ד אֹתָ֖ם סְבִיבֹ֥ת הָאֹֽהֶל׃"
          },
          {
            "num": "כה",
            "txt": "וַיֵּ֨רֶד יְהוָ֥ה ׀ בֶּֽעָנָן֮ וַיְדַבֵּ֣ר אֵלָיו֒ וַיָּ֗אצֶל מִן־הָר֨וּחַ֙ אֲשֶׁ֣ר עָלָ֔יו וַיִּתֵּ֕ן עַל־שִׁבְעִ֥ים אִ֖ישׁ הַזְּקֵנִ֑ים וַיְהִ֗י כְּנ֤וֹחַ עֲלֵיהֶם֙ הָר֔וּחַ וַיִּֽתְנַבְּא֖וּ וְלֹ֥א יָסָֽפוּ׃"
          },
          {
            "num": "כו",
            "txt": "וַיִּשָּֽׁאֲר֣וּ שְׁנֵֽי־אֲנָשִׁ֣ים ׀ בַּֽמַּחֲנֶ֡ה שֵׁ֣ם הָֽאֶחָ֣ד ׀ אֶלְדָּ֡ד וְשֵׁם֩ הַשֵּׁנִ֨י מֵידָ֜ד וַתָּ֧נַח עֲלֵהֶ֣ם הָר֗וּחַ וְהֵ֨מָּה֙ בַּכְּתֻבִ֔ים וְלֹ֥א יָֽצְא֖וּ הָאֹ֑הֱלָה וַיִּֽתְנַבְּא֖וּ בַּֽמַּחֲנֶֽה׃"
          },
          {
            "num": "כז",
            "txt": "וַיָּ֣רָץ הַנַּ֔עַר וַיַּגֵּ֥ד לְמֹשֶׁ֖ה וַיֹּאמַ֑ר אֶלְדָּ֣ד וּמֵידָ֔ד מִֽתְנַבְּאִ֖ים בַּֽמַּחֲנֶֽה׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּ֜עַן יְהוֹשֻׁ֣עַ בִּן־נ֗וּן מְשָׁרֵ֥ת מֹשֶׁ֛ה מִבְּחֻרָ֖יו וַיֹּאמַ֑ר אֲדֹנִ֥י מֹשֶׁ֖ה כְּלָאֵֽם׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֤אמֶר לוֹ֙ מֹשֶׁ֔ה הַֽמְקַנֵּ֥א אַתָּ֖ה לִ֑י וּמִ֨י יִתֵּ֜ן כָּל־עַ֤ם יְהוָה֙ נְבִיאִ֔ים כִּֽי־יִתֵּ֧ן יְהוָ֛ה אֶת־רוּח֖וֹ עֲלֵיהֶֽם׃"
          },
          {
            "num": "ל",
            "txt": "וַיֵּֽאָסֵ֥ף מֹשֶׁ֖ה אֶל־הַֽמַּחֲנֶ֑ה ה֖וּא וְזִקְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "לא",
            "txt": "וְר֜וּחַ נָסַ֣ע ׀ מֵאֵ֣ת יְהוָ֗ה וַיָּ֣גָז שַׂלְוִים֮ מִן־הַיָּם֒ וַיִּטֹּ֨שׁ עַל־הַֽמַּחֲנֶ֜ה כְּדֶ֧רֶךְ י֣וֹם כֹּ֗ה וּכְדֶ֤רֶךְ יוֹם֙ כֹּ֔ה סְבִיב֖וֹת הַֽמַּחֲנֶ֑ה וּכְאַמָּתַ֖יִם עַל־פְּנֵ֥י הָאָֽרֶץ׃"
          },
          {
            "num": "לב",
            "txt": "וַיָּ֣קָם הָעָ֡ם כָּל־הַיּוֹם֩ הַה֨וּא וְכָל־הַלַּ֜יְלָה וְכֹ֣ל ׀ י֣וֹם הַֽמָּחֳרָ֗ת וַיַּֽאַסְפוּ֙ אֶת־הַשְּׂלָ֔ו הַמַּמְעִ֕יט אָסַ֖ף עֲשָׂרָ֣ה חֳמָרִ֑ים וַיִּשְׁטְח֤וּ לָהֶם֙ שָׁט֔וֹחַ סְבִיב֖וֹת הַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "לג",
            "txt": "הַבָּשָׂ֗ר עוֹדֶ֨נּוּ֙ בֵּ֣ין שִׁנֵּיהֶ֔ם טֶ֖רֶם יִכָּרֵ֑ת וְאַ֤ף יְהוָה֙ חָרָ֣ה בָעָ֔ם וַיַּ֤ךְ יְהוָה֙ בָּעָ֔ם מַכָּ֖ה רַבָּ֥ה מְאֹֽד׃"
          },
          {
            "num": "לד",
            "txt": "וַיִּקְרָ֛א אֶת־שֵֽׁם־הַמָּק֥וֹם הַה֖וּא קִבְר֣וֹת הַֽתַּאֲוָ֑ה כִּי־שָׁם֙ קָֽבְר֔וּ אֶת־הָעָ֖ם הַמִּתְאַוִּֽים׃"
          },
          {
            "num": "לה",
            "txt": "מִקִּבְר֧וֹת הַֽתַּאֲוָ֛ה נָֽסְע֥וּ הָעָ֖ם חֲצֵר֑וֹת וַיִּֽהְי֖וּ בַּֽחֲצֵרֽוֹת׃ {פ}"
          }
        ]
      },
      {
        "num": "יב",
        "verses": [
          {
            "num": "א",
            "txt": "וַתְּדַבֵּ֨ר מִרְיָ֤ם וְאַֽהֲרֹן֙ בְּמֹשֶׁ֔ה עַל־אֹד֛וֹת הָֽאִשָּׁ֥ה הַכֻּשִׁ֖ית אֲשֶׁ֣ר לָקָ֑ח כִּֽי־אִשָּׁ֥ה כֻשִׁ֖ית לָקָֽח׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּֽאמְר֗וּ הֲרַ֤ק אַךְ־בְּמֹשֶׁה֙ דִּבֶּ֣ר יְהוָ֔ה הֲלֹ֖א גַּם־בָּ֣נוּ דִבֵּ֑ר וַיִּשְׁמַ֖ע יְהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וְהָאִ֥ישׁ מֹשֶׁ֖ה עָנָ֣ו מְאֹ֑ד מִכֹּל֙ הָֽאָדָ֔ם אֲשֶׁ֖ר עַל־פְּנֵ֥י הָֽאֲדָמָֽה׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה פִּתְאֹ֗ם אֶל־מֹשֶׁ֤ה וְאֶֽל־אַהֲרֹן֙ וְאֶל־מִרְיָ֔ם צְא֥וּ שְׁלָשְׁתְּכֶ֖ם אֶל־אֹ֣הֶל מוֹעֵ֑ד וַיֵּֽצְא֖וּ שְׁלָשְׁתָּֽם׃"
          },
          {
            "num": "ה",
            "txt": "וַיֵּ֤רֶד יְהוָה֙ בְּעַמּ֣וּד עָנָ֔ן וַֽיַּעֲמֹ֖ד פֶּ֣תַח הָאֹ֑הֶל וַיִּקְרָא֙ אַֽהֲרֹ֣ן וּמִרְיָ֔ם וַיֵּֽצְא֖וּ שְׁנֵיהֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֖אמֶר שִׁמְעוּ־נָ֣א דְבָרָ֑י אִם־יִֽהְיֶה֙ נְבִ֣יאֲכֶ֔ם יְהוָ֗ה בַּמַּרְאָה֙ אֵלָ֣יו אֶתְוַדָּ֔ע בַּֽחֲל֖וֹם אֲדַבֶּר־בּֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "לֹא־כֵ֖ן עַבְדִּ֣י מֹשֶׁ֑ה בְּכָל־בֵּיתִ֖י נֶֽאֱמָ֥ן הֽוּא׃"
          },
          {
            "num": "ח",
            "txt": "פֶּ֣ה אֶל־פֶּ֞ה אֲדַבֶּר־בּ֗וֹ וּמַרְאֶה֙ וְלֹ֣א בְחִידֹ֔ת וּתְמֻנַ֥ת יְהוָ֖ה יַבִּ֑יט וּמַדּ֨וּעַ֙ לֹ֣א יְרֵאתֶ֔ם לְדַבֵּ֖ר בְּעַבְדִּ֥י בְמֹשֶֽׁה׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּֽחַר־אַ֧ף יְהוָ֛ה בָּ֖ם וַיֵּלַֽךְ׃"
          },
          {
            "num": "י",
            "txt": "וְהֶֽעָנָ֗ן סָ֚ר מֵעַ֣ל הָאֹ֔הֶל וְהִנֵּ֥ה מִרְיָ֖ם מְצֹרַ֣עַת כַּשָּׁ֑לֶג וַיִּ֧פֶן אַֽהֲרֹ֛ן אֶל־מִרְיָ֖ם וְהִנֵּ֥ה מְצֹרָֽעַת׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֥אמֶר אַֽהֲרֹ֖ן אֶל־מֹשֶׁ֑ה בִּ֣י אֲדֹנִ֔י אַל־נָ֨א תָשֵׁ֤ת עָלֵ֨ינוּ֙ חַטָּ֔את אֲשֶׁ֥ר נוֹאַ֖לְנוּ וַֽאֲשֶׁ֥ר חָטָֽאנוּ׃"
          },
          {
            "num": "יב",
            "txt": "אַל־נָ֥א תְהִ֖י כַּמֵּ֑ת אֲשֶׁ֤ר בְּצֵאתוֹ֙ מֵרֶ֣חֶם אִמּ֔וֹ וַיֵּֽאָכֵ֖ל חֲצִ֥י בְשָׂרֽוֹ׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּצְעַ֣ק מֹשֶׁ֔ה אֶל־יְהוָ֖ה לֵאמֹ֑ר אֵ֕ל נָ֛א רְפָ֥א נָ֖א לָֽהּ׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה וְאָבִ֨יהָ֙ יָרֹ֤ק יָרַק֙ בְּפָנֶ֔יהָ הֲלֹ֥א תִכָּלֵ֖ם שִׁבְעַ֣ת יָמִ֑ים תִּסָּגֵ֞ר שִׁבְעַ֤ת יָמִים֙ מִח֣וּץ לַֽמַּחֲנֶ֔ה וְאַחַ֖ר תֵּֽאָסֵֽף׃"
          },
          {
            "num": "טו",
            "txt": "וַתִּסָּגֵ֥ר מִרְיָ֛ם מִח֥וּץ לַֽמַּחֲנֶ֖ה שִׁבְעַ֣ת יָמִ֑ים וְהָעָם֙ לֹ֣א נָסַ֔ע עַד־הֵֽאָסֵ֖ף מִרְיָֽם׃"
          },
          {
            "num": "טז",
            "txt": "וְאַחַ֛ר נָֽסְע֥וּ הָעָ֖ם מֵֽחֲצֵר֑וֹת וַֽיַּחֲנ֖וּ בְּמִדְבַּ֥ר פָּארָֽן׃ {פ}"
          }
        ]
      },
      {
        "num": "יג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "שְׁלַח־לְךָ֣ אֲנָשִׁ֗ים וְיָתֻ֨רוּ֙ אֶת־אֶ֣רֶץ כְּנַ֔עַן אֲשֶׁר־אֲנִ֥י נֹתֵ֖ן לִבְנֵ֣י יִשְׂרָאֵ֑ל אִ֣ישׁ אֶחָד֩ אִ֨ישׁ אֶחָ֜ד לְמַטֵּ֤ה אֲבֹתָיו֙ תִּשְׁלָ֔חוּ כֹּ֖ל נָשִׂ֥יא בָהֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּשְׁלַ֨ח אֹתָ֥ם מֹשֶׁ֛ה מִמִּדְבַּ֥ר פָּארָ֖ן עַל־פִּ֣י יְהוָ֑ה כֻּלָּ֣ם אֲנָשִׁ֔ים רָאשֵׁ֥י בְנֵֽי־יִשְׂרָאֵ֖ל הֵֽמָּה׃"
          },
          {
            "num": "ד",
            "txt": "וְאֵ֖לֶּה שְׁמוֹתָ֑ם לְמַטֵּ֣ה רְאוּבֵ֔ן שַׁמּ֖וּעַ בֶּן־זַכּֽוּר׃"
          },
          {
            "num": "ה",
            "txt": "לְמַטֵּ֣ה שִׁמְע֔וֹן שָׁפָ֖ט בֶּן־חוֹרִֽי׃"
          },
          {
            "num": "ו",
            "txt": "לְמַטֵּ֣ה יְהוּדָ֔ה כָּלֵ֖ב בֶּן־יְפֻנֶּֽה׃"
          },
          {
            "num": "ז",
            "txt": "לְמַטֵּ֣ה יִשָּׂשכָ֔ר יִגְאָ֖ל בֶּן־יוֹסֵֽף׃"
          },
          {
            "num": "ח",
            "txt": "לְמַטֵּ֥ה אֶפְרָ֖יִם הוֹשֵׁ֥עַ בִּן־נֽוּן׃"
          },
          {
            "num": "ט",
            "txt": "לְמַטֵּ֣ה בִנְיָמִ֔ן פַּלְטִ֖י בֶּן־רָפֽוּא׃"
          },
          {
            "num": "י",
            "txt": "לְמַטֵּ֣ה זְבוּלֻ֔ן גַּדִּיאֵ֖ל בֶּן־סוֹדִֽי׃"
          },
          {
            "num": "יא",
            "txt": "לְמַטֵּ֥ה יוֹסֵ֖ף לְמַטֵּ֣ה מְנַשֶּׁ֑ה גַּדִּ֖י בֶּן־סוּסִֽי׃"
          },
          {
            "num": "יב",
            "txt": "לְמַטֵּ֣ה דָ֔ן עַמִּיאֵ֖ל בֶּן־גְּמַלִּֽי׃"
          },
          {
            "num": "יג",
            "txt": "לְמַטֵּ֣ה אָשֵׁ֔ר סְת֖וּר בֶּן־מִֽיכָאֵֽל׃"
          },
          {
            "num": "יד",
            "txt": "לְמַטֵּ֣ה נַפְתָּלִ֔י נַחְבִּ֖י בֶּן־וָפְסִֽי׃"
          },
          {
            "num": "טו",
            "txt": "לְמַטֵּ֣ה גָ֔ד גְּאוּאֵ֖ל בֶּן־מָכִֽי׃"
          },
          {
            "num": "טז",
            "txt": "אֵ֚לֶּה שְׁמ֣וֹת הָֽאֲנָשִׁ֔ים אֲשֶׁר־שָׁלַ֥ח מֹשֶׁ֖ה לָת֣וּר אֶת־הָאָ֑רֶץ וַיִּקְרָ֥א מֹשֶׁ֛ה לְהוֹשֵׁ֥עַ בִּן־נ֖וּן יְהוֹשֻֽׁעַ׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּשְׁלַ֤ח אֹתָם֙ מֹשֶׁ֔ה לָת֖וּר אֶת־אֶ֣רֶץ כְּנָ֑עַן וַיֹּ֣אמֶר אֲלֵהֶ֗ם עֲל֥וּ זֶה֙ בַּנֶּ֔גֶב וַֽעֲלִיתֶ֖ם אֶת־הָהָֽר׃"
          },
          {
            "num": "יח",
            "txt": "וּרְאִיתֶ֥ם אֶת־הָאָ֖רֶץ מַה־הִ֑וא וְאֶת־הָעָם֙ הַיֹּשֵׁ֣ב עָלֶ֔יהָ הֶֽחָזָ֥ק הוּא֙ הֲרָפֶ֔ה הַמְעַ֥ט ה֖וּא אִם־רָֽב׃"
          },
          {
            "num": "יט",
            "txt": "וּמָ֣ה הָאָ֗רֶץ אֲשֶׁר־הוּא֙ יֹשֵׁ֣ב בָּ֔הּ הֲטוֹבָ֥ה הִ֖וא אִם־רָעָ֑ה וּמָ֣ה הֶֽעָרִ֗ים אֲשֶׁר־הוּא֙ יוֹשֵׁ֣ב בָּהֵ֔נָּה הַבְּמַֽחֲנִ֖ים אִ֥ם בְּמִבְצָרִֽים׃"
          },
          {
            "num": "כ",
            "txt": "וּמָ֣ה הָ֠אָרֶץ הַשְּׁמֵנָ֨ה הִ֜וא אִם־רָזָ֗ה הֲיֵֽשׁ־בָּ֥הּ עֵץ֙ אִם־אַ֔יִן וְהִ֨תְחַזַּקְתֶּ֔ם וּלְקַחְתֶּ֖ם מִפְּרִ֣י הָאָ֑רֶץ וְהַ֨יָּמִ֔ים יְמֵ֖י בִּכּוּרֵ֥י עֲנָבִֽים׃"
          },
          {
            "num": "כא",
            "txt": "וַֽיַּעֲל֖וּ וַיָּתֻ֣רוּ אֶת־הָאָ֑רֶץ מִמִּדְבַּר־צִ֥ן עַד־רְחֹ֖ב לְבֹ֥א חֲמָֽת׃"
          },
          {
            "num": "כב",
            "txt": "וַיַּֽעֲל֣וּ בַנֶּגֶב֮ וַיָּבֹ֣א עַד־חֶבְרוֹן֒ וְשָׁ֤ם אֲחִימַן֙ שֵׁשַׁ֣י וְתַלְמַ֔י יְלִידֵ֖י הָֽעֲנָ֑ק וְחֶבְר֗וֹן שֶׁ֤בַע שָׁנִים֙ נִבְנְתָ֔ה לִפְנֵ֖י צֹ֥עַן מִצְרָֽיִם׃"
          },
          {
            "num": "כג",
            "txt": "וַיָּבֹ֜אוּ עַד־נַ֣חַל אֶשְׁכֹּ֗ל וַיִּכְרְת֨וּ מִשָּׁ֤ם זְמוֹרָה֙ וְאֶשְׁכּ֤וֹל עֲנָבִים֙ אֶחָ֔ד וַיִּשָּׂאֻ֥הוּ בַמּ֖וֹט בִּשְׁנָ֑יִם וּמִן־הָֽרִמֹּנִ֖ים וּמִן־הַתְּאֵנִֽים׃"
          },
          {
            "num": "כד",
            "txt": "לַמָּק֣וֹם הַה֔וּא קָרָ֖א נַ֣חַל אֶשְׁכּ֑וֹל עַ֚ל אֹד֣וֹת הָֽאֶשְׁכּ֔וֹל אֲשֶׁר־כָּֽרְת֥וּ מִשָּׁ֖ם בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כה",
            "txt": "וַיָּשֻׁ֖בוּ מִתּ֣וּר הָאָ֑רֶץ מִקֵּ֖ץ אַרְבָּעִ֥ים יֽוֹם׃"
          },
          {
            "num": "כו",
            "txt": "וַיֵּֽלְכ֡וּ וַיָּבֹאוּ֩ אֶל־מֹשֶׁ֨ה וְאֶֽל־אַהֲרֹ֜ן וְאֶל־כָּל־עֲדַ֧ת בְּנֵֽי־יִשְׂרָאֵ֛ל אֶל־מִדְבַּ֥ר פָּארָ֖ן קָדֵ֑שָׁה וַיָּשִׁ֨יבוּ אֹתָ֤ם דָּבָר֙ וְאֶת־כָּל־הָ֣עֵדָ֔ה וַיַּרְא֖וּם אֶת־פְּרִ֥י הָאָֽרֶץ׃"
          },
          {
            "num": "כז",
            "txt": "וַיְסַפְּרוּ־לוֹ֙ וַיֹּ֣אמְר֔וּ בָּ֕אנוּ אֶל־הָאָ֖רֶץ אֲשֶׁ֣ר שְׁלַחְתָּ֑נוּ וְ֠גַם זָבַ֨ת חָלָ֥ב וּדְבַ֛שׁ הִ֖וא וְזֶה־פִּרְיָֽהּ׃"
          },
          {
            "num": "כח",
            "txt": "אֶ֚פֶס כִּֽי־עַ֣ז הָעָ֔ם הַיֹּשֵׁ֖ב בָּאָ֑רֶץ וְהֶֽעָרִ֗ים בְּצֻר֤וֹת גְּדֹלֹת֙ מְאֹ֔ד וְגַם־יְלִדֵ֥י הָֽעֲנָ֖ק רָאִ֥ינוּ שָֽׁם׃"
          },
          {
            "num": "כט",
            "txt": "עֲמָלֵ֥ק יוֹשֵׁ֖ב בְּאֶ֣רֶץ הַנֶּ֑גֶב וְ֠הַֽחִתִּי וְהַיְבוּסִ֤י וְהָֽאֱמֹרִי֙ יוֹשֵׁ֣ב בָּהָ֔ר וְהַֽכְּנַעֲנִי֙ יוֹשֵׁ֣ב עַל־הַיָּ֔ם וְעַ֖ל יַ֥ד הַיַּרְדֵּֽן׃"
          },
          {
            "num": "ל",
            "txt": "וַיַּ֧הַס כָּלֵ֛ב אֶת־הָעָ֖ם אֶל־מֹשֶׁ֑ה וַיֹּ֗אמֶר עָלֹ֤ה נַֽעֲלֶה֙ וְיָרַ֣שְׁנוּ אֹתָ֔הּ כִּֽי־יָכ֥וֹל נוּכַ֖ל לָֽהּ׃"
          },
          {
            "num": "לא",
            "txt": "וְהָ֨אֲנָשִׁ֜ים אֲשֶׁר־עָל֤וּ עִמּוֹ֙ אָֽמְר֔וּ לֹ֥א נוּכַ֖ל לַֽעֲל֣וֹת אֶל־הָעָ֑ם כִּֽי־חָזָ֥ק ה֖וּא מִמֶּֽנּוּ׃"
          },
          {
            "num": "לב",
            "txt": "וַיֹּצִ֜יאוּ דִּבַּ֤ת הָאָ֨רֶץ֙ אֲשֶׁ֣ר תָּר֣וּ אֹתָ֔הּ אֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר הָאָ֡רֶץ אֲשֶׁר֩ עָבַ֨רְנוּ בָ֜הּ לָת֣וּר אֹתָ֗הּ אֶ֣רֶץ אֹכֶ֤לֶת יֽוֹשְׁבֶ֨יהָ֙ הִ֔וא וְכָל־הָעָ֛ם אֲשֶׁר־רָאִ֥ינוּ בְתוֹכָ֖הּ אַנְשֵׁ֥י מִדּֽוֹת׃"
          },
          {
            "num": "לג",
            "txt": "וְשָׁ֣ם רָאִ֗ינוּ אֶת־הַנְּפִילִ֛ים בְּנֵ֥י עֲנָ֖ק מִן־הַנְּפִלִ֑ים וַנְּהִ֤י בְעֵינֵ֨ינוּ֙ כַּֽחֲגָבִ֔ים וְכֵ֥ן הָיִ֖ינוּ בְּעֵֽינֵיהֶֽם׃"
          }
        ]
      },
      {
        "num": "יד",
        "verses": [
          {
            "num": "א",
            "txt": "וַתִּשָּׂא֙ כָּל־הָ֣עֵדָ֔ה וַֽיִּתְּנ֖וּ אֶת־קוֹלָ֑ם וַיִּבְכּ֥וּ הָעָ֖ם בַּלַּ֥יְלָה הַהֽוּא׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּלֹּ֨נוּ֙ עַל־מֹשֶׁ֣ה וְעַֽל־אַהֲרֹ֔ן כֹּ֖ל בְּנֵ֣י יִשְׂרָאֵ֑ל וַיֹּֽאמְר֨וּ אֲלֵהֶ֜ם כָּל־הָֽעֵדָ֗ה לוּ־מַ֨תְנוּ֙ בְּאֶ֣רֶץ מִצְרַ֔יִם א֛וֹ בַּמִּדְבָּ֥ר הַזֶּ֖ה לוּ־מָֽתְנוּ׃"
          },
          {
            "num": "ג",
            "txt": "וְלָמָ֣ה יְ֠הוָה מֵבִ֨יא אֹתָ֜נוּ אֶל־הָאָ֤רֶץ הַזֹּאת֙ לִנְפֹּ֣ל בַּחֶ֔רֶב נָשֵׁ֥ינוּ וְטַפֵּ֖נוּ יִֽהְי֣וּ לָבַ֑ז הֲל֧וֹא ט֦וֹב לָ֖נוּ שׁ֥וּב מִצְרָֽיְמָה׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּֽאמְר֖וּ אִ֣ישׁ אֶל־אָחִ֑יו נִתְּנָ֥ה רֹ֖אשׁ וְנָשׁ֥וּבָה מִצְרָֽיְמָה׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּפֹּ֥ל מֹשֶׁ֛ה וְאַֽהֲרֹ֖ן עַל־פְּנֵיהֶ֑ם לִפְנֵ֕י כָּל־קְהַ֥ל עֲדַ֖ת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ו",
            "txt": "וִֽיהוֹשֻׁ֣עַ בִּן־נ֗וּן וְכָלֵב֙ בֶּן־יְפֻנֶּ֔ה מִן־הַתָּרִ֖ים אֶת־הָאָ֑רֶץ קָֽרְע֖וּ בִּגְדֵיהֶֽם׃"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֣אמְר֔וּ אֶל־כָּל־עֲדַ֥ת בְּנֵֽי־יִשְׂרָאֵ֖ל לֵאמֹ֑ר הָאָ֗רֶץ אֲשֶׁ֨ר עָבַ֤רְנוּ בָהּ֙ לָת֣וּר אֹתָ֔הּ טוֹבָ֥ה הָאָ֖רֶץ מְאֹ֥ד מְאֹֽד׃"
          },
          {
            "num": "ח",
            "txt": "אִם־חָפֵ֥ץ בָּ֨נוּ֙ יְהוָ֔ה וְהֵבִ֤יא אֹתָ֨נוּ֙ אֶל־הָאָ֣רֶץ הַזֹּ֔את וּנְתָנָ֖הּ לָ֑נוּ אֶ֕רֶץ אֲשֶׁר־הִ֛וא זָבַ֥ת חָלָ֖ב וּדְבָֽשׁ׃"
          },
          {
            "num": "ט",
            "txt": "אַ֣ךְ בַּֽיהוָה֮ אַל־תִּמְרֹדוּ֒ וְאַתֶּ֗ם אַל־תִּֽירְאוּ֙ אֶת־עַ֣ם הָאָ֔רֶץ כִּ֥י לַחְמֵ֖נוּ הֵ֑ם סָ֣ר צִלָּ֧ם מֵֽעֲלֵיהֶ֛ם וַֽיהוָ֥ה אִתָּ֖נוּ אַל־תִּֽירָאֻֽם׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּֽאמְרוּ֙ כָּל־הָ֣עֵדָ֔ה לִרְגּ֥וֹם אֹתָ֖ם בָּֽאֲבָנִ֑ים וּכְב֣וֹד יְהוָ֗ה נִרְאָה֙ בְּאֹ֣הֶל מוֹעֵ֔ד אֶֽל־כָּל־בְּנֵ֖י יִשְׂרָאֵֽל׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה עַד־אָ֥נָה יְנַֽאֲצֻ֖נִי הָעָ֣ם הַזֶּ֑ה וְעַד־אָ֨נָה֙ לֹֽא־יַאֲמִ֣ינוּ בִ֔י בְּכֹל֙ הָֽאֹת֔וֹת אֲשֶׁ֥ר עָשִׂ֖יתִי בְּקִרְבּֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "אַכֶּ֥נּוּ בַדֶּ֖בֶר וְאֽוֹרִשֶׁ֑נּוּ וְאֶֽעֱשֶׂה֙ אֹֽתְךָ֔ לְגוֹי־גָּד֥וֹל וְעָצ֖וּם מִמֶּֽנּוּ׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֥אמֶר מֹשֶׁ֖ה אֶל־יְהוָ֑ה וְשָֽׁמְע֣וּ מִצְרַ֔יִם כִּֽי־הֶעֱלִ֧יתָ בְכֹֽחֲךָ֛ אֶת־הָעָ֥ם הַזֶּ֖ה מִקִּרְבּֽוֹ׃"
          },
          {
            "num": "יד",
            "txt": "וְאָֽמְר֗וּ אֶל־יוֹשֵׁב֮ הָאָ֣רֶץ הַזֹּאת֒ שָֽׁמְעוּ֙ כִּֽי־אַתָּ֣ה יְהוָ֔ה בְּקֶ֖רֶב הָעָ֣ם הַזֶּ֑ה אֲשֶׁר־עַ֨יִן בְּעַ֜יִן נִרְאָ֣ה ׀ אַתָּ֣ה יְהוָ֗ה וַעֲנָֽנְךָ֙ עֹמֵ֣ד עֲלֵהֶ֔ם וּבְעַמֻּ֣ד עָנָ֗ן אַתָּ֨ה הֹלֵ֤ךְ לִפְנֵיהֶם֙ יוֹמָ֔ם וּבְעַמּ֥וּד אֵ֖שׁ לָֽיְלָה׃"
          },
          {
            "num": "טו",
            "txt": "וְהֵֽמַתָּ֛ה אֶת־הָעָ֥ם הַזֶּ֖ה כְּאִ֣ישׁ אֶחָ֑ד וְאָֽמְרוּ֙ הַגּוֹיִ֔ם אֲשֶׁר־שָֽׁמְע֥וּ אֶֽת־שִׁמְעֲךָ֖ לֵאמֹֽר׃"
          },
          {
            "num": "טז",
            "txt": "מִבִּלְתִּ֞י יְכֹ֣לֶת יְהוָ֗ה לְהָבִיא֙ אֶת־הָעָ֣ם הַזֶּ֔ה אֶל־הָאָ֖רֶץ אֲשֶׁר־נִשְׁבַּ֣ע לָהֶ֑ם וַיִּשְׁחָטֵ֖ם בַּמִּדְבָּֽר׃"
          },
          {
            "num": "יז",
            "txt": "וְעַתָּ֕ה"
          },
          {
            "num": "יח",
            "txt": "גְדַּל־נָ֖א כֹּ֣חַ אֲדֹנָ֑י כַּֽאֲשֶׁ֥ר דִּבַּ֖רְתָּ לֵאמֹֽר׃"
          },
          {
            "num": "יט",
            "txt": "יְהוָ֗ה אֶ֤רֶךְ אַפַּ֨יִם֙ וְרַב־חֶ֔סֶד נֹשֵׂ֥א עָוֺ֖ן וָפָ֑שַׁע וְנַקֵּה֙ לֹ֣א יְנַקֶּ֔ה פֹּקֵ֞ד עֲוֺ֤ן אָבוֹת֙ עַל־בָּנִ֔ים עַל־שִׁלֵּשִׁ֖ים וְעַל־רִבֵּעִֽים׃"
          },
          {
            "num": "כ",
            "txt": "סְלַֽח־נָ֗א לַֽעֲוֺ֛ן הָעָ֥ם הַזֶּ֖ה כְּגֹ֣דֶל חַסְדֶּ֑ךָ וְכַֽאֲשֶׁ֤ר נָשָׂ֨אתָה֙ לָעָ֣ם הַזֶּ֔ה מִמִּצְרַ֖יִם וְעַד־הֵֽנָּה׃"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֣אמֶר יְהוָ֔ה סָלַ֖חְתִּי כִּדְבָרֶֽךָ׃"
          },
          {
            "num": "כב",
            "txt": "וְאוּלָ֖ם חַי־אָ֑נִי וְיִמָּלֵ֥א כְבוֹד־יְהוָ֖ה אֶת־כָּל־הָאָֽרֶץ׃"
          },
          {
            "num": "כג",
            "txt": "כִּ֣י כָל־הָֽאֲנָשִׁ֗ים הָֽרֹאִ֤ים אֶת־כְּבֹדִי֙ וְאֶת־אֹ֣תֹתַ֔י אֲשֶׁר־עָשִׂ֥יתִי בְמִצְרַ֖יִם וּבַמִּדְבָּ֑ר וַיְנַסּ֣וּ אֹתִ֗י זֶ֚ה עֶ֣שֶׂר פְּעָמִ֔ים וְלֹ֥א שָֽׁמְע֖וּ בְּקוֹלִֽי׃"
          },
          {
            "num": "כד",
            "txt": "אִם־יִרְאוּ֙ אֶת־הָאָ֔רֶץ אֲשֶׁ֥ר נִשְׁבַּ֖עְתִּי לַֽאֲבֹתָ֑ם וְכָל־מְנַֽאֲצַ֖י לֹ֥א יִרְאֽוּהָ׃"
          },
          {
            "num": "כה",
            "txt": "וְעַבְדִּ֣י כָלֵ֗ב עֵ֣קֶב הָֽיְתָ֞ה ר֤וּחַ אַחֶ֨רֶת֙ עִמּ֔וֹ וַיְמַלֵּ֖א אַֽחֲרָ֑י וַהֲבִֽיאֹתִ֗יו אֶל־הָאָ֨רֶץ֙ אֲשֶׁר־בָּ֣א שָׁ֔מָּה וְזַרְע֖וֹ יֽוֹרִשֶֽׁנָּה׃"
          },
          {
            "num": "כו",
            "txt": "וְהָֽעֲמָלֵקִ֥י וְהַֽכְּנַעֲנִ֖י יוֹשֵׁ֣ב בָּעֵ֑מֶק מָחָ֗ר פְּנ֨וּ וּסְע֥וּ לָכֶ֛ם הַמִּדְבָּ֖ר דֶּ֥רֶךְ יַם־סֽוּף׃ {פ}"
          },
          {
            "num": "כז",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "כח",
            "txt": "עַד־מָתַ֗י לָֽעֵדָ֤ה הָֽרָעָה֙ הַזֹּ֔את אֲשֶׁ֛ר הֵ֥מָּה מַלִּינִ֖ים עָלָ֑י אֶת־תְּלֻנּ֞וֹת בְּנֵ֣י יִשְׂרָאֵ֗ל אֲשֶׁ֨ר הֵ֧מָּה מַלִּינִ֛ים עָלַ֖י שָׁמָֽעְתִּי׃"
          },
          {
            "num": "כט",
            "txt": "אֱמֹ֣ר אֲלֵהֶ֗ם חַי־אָ֨נִי֙ נְאֻם־יְהוָ֔ה אִם־לֹ֕א כַּֽאֲשֶׁ֥ר דִּבַּרְתֶּ֖ם בְּאָזְנָ֑י כֵּ֖ן אֶֽעֱשֶׂ֥ה לָכֶֽם׃"
          },
          {
            "num": "ל",
            "txt": "בַּמִּדְבָּ֣ר הַ֠זֶּה יִפְּל֨וּ פִגְרֵיכֶ֜ם וְכָל־פְּקֻֽדֵיכֶם֙ לְכָל־מִסְפַּרְכֶ֔ם מִבֶּ֛ן עֶשְׂרִ֥ים שָׁנָ֖ה וָמָ֑עְלָה אֲשֶׁ֥ר הֲלִֽינֹתֶ֖ם עָלָֽי׃"
          },
          {
            "num": "לא",
            "txt": "אִם־אַתֶּם֙ תָּבֹ֣אוּ אֶל־הָאָ֔רֶץ אֲשֶׁ֤ר נָשָׂ֨אתִי֙ אֶת־יָדִ֔י לְשַׁכֵּ֥ן אֶתְכֶ֖ם בָּ֑הּ כִּ֚י אִם־כָּלֵ֣ב בֶּן־יְפֻנֶּ֔ה וִֽיהוֹשֻׁ֖עַ בִּן־נֽוּן׃"
          },
          {
            "num": "לב",
            "txt": "וְטַ֨פְּכֶ֔ם אֲשֶׁ֥ר אֲמַרְתֶּ֖ם לָבַ֣ז יִֽהְיֶ֑ה וְהֵֽבֵיאתִ֣י אֹתָ֔ם וְיָֽדְעוּ֙ אֶת־הָאָ֔רֶץ אֲשֶׁ֥ר מְאַסְתֶּ֖ם בָּֽהּ׃"
          },
          {
            "num": "לג",
            "txt": "וּפִגְרֵיכֶ֖ם אַתֶּ֑ם יִפְּל֖וּ בַּמִּדְבָּ֥ר הַזֶּֽה׃"
          },
          {
            "num": "לד",
            "txt": "וּ֠בְנֵיכֶם יִֽהְי֨וּ רֹעִ֤ים בַּמִּדְבָּר֙ אַרְבָּעִ֣ים שָׁנָ֔ה וְנָֽשְׂא֖וּ אֶת־זְנֽוּתֵיכֶ֑ם עַד־תֹּ֥ם פִּגְרֵיכֶ֖ם בַּמִּדְבָּֽר׃"
          },
          {
            "num": "לה",
            "txt": "בְּמִסְפַּ֨ר הַיָּמִ֜ים אֲשֶׁר־תַּרְתֶּ֣ם אֶת־הָאָרֶץ֮ אַרְבָּעִ֣ים יוֹם֒ י֣וֹם לַשָּׁנָ֞ה י֣וֹם לַשָּׁנָ֗ה תִּשְׂאוּ֙ אֶת־עֲוֺנֹ֣תֵיכֶ֔ם אַרְבָּעִ֖ים שָׁנָ֑ה וִֽידַעְתֶּ֖ם אֶת־תְּנֽוּאָתִֽי׃"
          },
          {
            "num": "לו",
            "txt": "אֲנִ֣י יְהוָה֮ דִּבַּרְתִּי֒ אִם־לֹ֣א ׀ זֹ֣את אֶֽעֱשֶׂ֗ה לְכָל־הָֽעֵדָ֤ה הָֽרָעָה֙ הַזֹּ֔את הַנּֽוֹעָדִ֖ים עָלָ֑י בַּמִּדְבָּ֥ר הַזֶּ֛ה יִתַּ֖מּוּ וְשָׁ֥ם יָמֻֽתוּ׃"
          },
          {
            "num": "לז",
            "txt": "וְהָ֣אֲנָשִׁ֔ים אֲשֶׁר־שָׁלַ֥ח מֹשֶׁ֖ה לָת֣וּר אֶת־הָאָ֑רֶץ וַיָּשֻׁ֗בוּ וילונו (וַיַּלִּ֤ינוּ) עָלָיו֙ אֶת־כָּל־הָ֣עֵדָ֔ה לְהוֹצִ֥יא דִבָּ֖ה עַל־הָאָֽרֶץ׃"
          },
          {
            "num": "לח",
            "txt": "וַיָּמֻ֨תוּ֙ הָֽאֲנָשִׁ֔ים מֽוֹצִאֵ֥י דִבַּת־הָאָ֖רֶץ רָעָ֑ה בַּמַּגֵּפָ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "לט",
            "txt": "וִֽיהוֹשֻׁ֣עַ בִּן־נ֔וּן וְכָלֵ֖ב בֶּן־יְפֻנֶּ֑ה חָיוּ֙ מִן־הָֽאֲנָשִׁ֣ים הָהֵ֔ם הַֽהֹלְכִ֖ים לָת֥וּר אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "מ",
            "txt": "וַיְדַבֵּ֤ר מֹשֶׁה֙ אֶת־הַדְּבָרִ֣ים הָאֵ֔לֶּה אֶֽל־כָּל־בְּנֵ֖י יִשְׂרָאֵ֑ל וַיִּֽתְאַבְּל֥וּ הָעָ֖ם מְאֹֽד׃"
          },
          {
            "num": "מא",
            "txt": "וַיַּשְׁכִּ֣מוּ בַבֹּ֔קֶר וַיַּֽעֲל֥וּ אֶל־רֹאשׁ־הָהָ֖ר לֵאמֹ֑ר הִנֶּ֗נּוּ וְעָלִ֛ינוּ אֶל־הַמָּק֛וֹם אֲשֶׁר־אָמַ֥ר יְהוָ֖ה כִּ֥י חָטָֽאנוּ׃"
          },
          {
            "num": "מב",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה לָ֥מָּה זֶּ֛ה אַתֶּ֥ם עֹֽבְרִ֖ים אֶת־פִּ֣י יְהוָ֑ה וְהִ֖וא לֹ֥א תִצְלָֽח׃"
          },
          {
            "num": "מג",
            "txt": "אַֽל־תַּעֲל֔וּ כִּ֛י אֵ֥ין יְהוָ֖ה בְּקִרְבְּכֶ֑ם וְלֹא֙ תִּנָּ֣גְפ֔וּ לִפְנֵ֖י אֹֽיְבֵיכֶֽם׃"
          },
          {
            "num": "מד",
            "txt": "כִּי֩ הָעֲמָֽלֵקִ֨י וְהַכְּנַֽעֲנִ֥י שָׁם֙ לִפְנֵיכֶ֔ם וּנְפַלְתֶּ֖ם בֶּחָ֑רֶב כִּֽי־עַל־כֵּ֤ן שַׁבְתֶּם֙ מֵאַֽחֲרֵ֣י יְהוָ֔ה וְלֹֽא־יִהְיֶ֥ה יְהוָ֖ה עִמָּכֶֽם׃"
          },
          {
            "num": "מה",
            "txt": "וַיַּעְפִּ֕לוּ לַֽעֲל֖וֹת אֶל־רֹ֣אשׁ הָהָ֑ר וַֽאֲר֤וֹן בְּרִית־יְהוָה֙ וּמֹשֶׁ֔ה לֹא־מָ֖שׁוּ מִקֶּ֥רֶב הַֽמַּחֲנֶֽה׃"
          },
          {
            "txt": "וַיֵּ֤רֶד הָעֲמָֽלֵקִי֙ וְהַֽכְּנַעֲנִ֔י הַיֹּשֵׁ֖ב בָּהָ֣ר הַה֑וּא וַיַּכּ֥וּם וַֽיַּכְּת֖וּם עַד־הַֽחָרְמָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "טו",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "דַּבֵּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם כִּ֣י תָבֹ֗אוּ אֶל־אֶ֨רֶץ֙ מוֹשְׁבֹ֣תֵיכֶ֔ם אֲשֶׁ֥ר אֲנִ֖י נֹתֵ֥ן לָכֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "וַֽעֲשִׂיתֶ֨ם אִשֶּׁ֤ה לַֽיהוָה֙ עֹלָ֣ה אוֹ־זֶ֔בַח לְפַלֵּא־נֶ֨דֶר֙ א֣וֹ בִנְדָבָ֔ה א֖וֹ בְּמֹֽעֲדֵיכֶ֑ם לַֽעֲשׂ֞וֹת רֵ֤יחַ נִיחֹ֨חַ֙ לַֽיהוָ֔ה מִן־הַבָּקָ֖ר א֥וֹ מִן־הַצֹּֽאן׃"
          },
          {
            "num": "ד",
            "txt": "וְהִקְרִ֛יב הַמַּקְרִ֥יב קָרְבָּנ֖וֹ לַֽיהוָ֑ה מִנְחָה֙ סֹ֣לֶת עִשָּׂר֔וֹן בָּל֕וּל בִּרְבִעִ֥ית הַהִ֖ין שָֽׁמֶן׃"
          },
          {
            "num": "ה",
            "txt": "וְיַ֤יִן לַנֶּ֨סֶךְ֙ רְבִיעִ֣ית הַהִ֔ין תַּֽעֲשֶׂ֥ה עַל־הָֽעֹלָ֖ה א֣וֹ לַזָּ֑בַח לַכֶּ֖בֶשׂ הָֽאֶחָֽד׃"
          },
          {
            "num": "ו",
            "txt": "א֤וֹ לָאַ֨יִל֙ תַּֽעֲשֶׂ֣ה מִנְחָ֔ה סֹ֖לֶת שְׁנֵ֣י עֶשְׂרֹנִ֑ים בְּלוּלָ֥ה בַשֶּׁ֖מֶן שְׁלִשִׁ֥ית הַהִֽין׃"
          },
          {
            "num": "ז",
            "txt": "וְיַ֥יִן לַנֶּ֖סֶךְ שְׁלִשִׁ֣ית הַהִ֑ין תַּקְרִ֥יב רֵֽיחַ־נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְכִֽי־תַעֲשֶׂ֥ה בֶן־בָּקָ֖ר עֹלָ֣ה אוֹ־זָ֑בַח לְפַלֵּא־נֶ֥דֶר אֽוֹ־שְׁלָמִ֖ים לַֽיהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וְהִקְרִ֤יב עַל־בֶּן־הַבָּקָר֙ מִנְחָ֔ה סֹ֖לֶת שְׁלֹשָׁ֣ה עֶשְׂרֹנִ֑ים בָּל֥וּל בַּשֶּׁ֖מֶן חֲצִ֥י הַהִֽין׃"
          },
          {
            "num": "י",
            "txt": "וְיַ֛יִן תַּקְרִ֥יב לַנֶּ֖סֶךְ חֲצִ֣י הַהִ֑ין אִשֵּׁ֥ה רֵֽיחַ־נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "יא",
            "txt": "כָּ֣כָה יֵֽעָשֶׂ֗ה לַשּׁוֹר֙ הָֽאֶחָ֔ד א֖וֹ לָאַ֣יִל הָֽאֶחָ֑ד אֽוֹ־לַשֶּׂ֥ה בַכְּבָשִׂ֖ים א֥וֹ בָֽעִזִּֽים׃"
          },
          {
            "num": "יב",
            "txt": "כַּמִּסְפָּ֖ר אֲשֶׁ֣ר תַּֽעֲשׂ֑וּ כָּ֛כָה תַּֽעֲשׂ֥וּ לָֽאֶחָ֖ד כְּמִסְפָּרָֽם׃"
          },
          {
            "num": "יג",
            "txt": "כָּל־הָֽאֶזְרָ֥ח יַֽעֲשֶׂה־כָּ֖כָה אֶת־אֵ֑לֶּה לְהַקְרִ֛יב אִשֵּׁ֥ה רֵֽיחַ־נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְכִֽי־יָגוּר֩ אִתְּכֶ֨ם גֵּ֜ר א֤וֹ אֲשֶׁר־בְּתֽוֹכְכֶם֙ לְדֹרֹ֣תֵיכֶ֔ם וְעָשָׂ֛ה אִשֵּׁ֥ה רֵֽיחַ־נִיחֹ֖חַ לַֽיהוָ֑ה כַּֽאֲשֶׁ֥ר תַּֽעֲשׂ֖וּ כֵּ֥ן יַֽעֲשֶֽׂה׃"
          },
          {
            "num": "טו",
            "txt": "הַקָּהָ֕ל חֻקָּ֥ה אַחַ֛ת לָכֶ֖ם וְלַגֵּ֣ר הַגָּ֑ר חֻקַּ֤ת עוֹלָם֙ לְדֹרֹ֣תֵיכֶ֔ם כָּכֶ֛ם כַּגֵּ֥ר יִֽהְיֶ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "טז",
            "txt": "תּוֹרָ֥ה אַחַ֛ת וּמִשְׁפָּ֥ט אֶחָ֖ד יִֽהְיֶ֣ה לָכֶ֑ם וְלַגֵּ֖ר הַגָּ֥ר אִתְּכֶֽם׃ {פ}"
          },
          {
            "num": "יז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יח",
            "txt": "דַּבֵּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם בְּבֹֽאֲכֶם֙ אֶל־הָאָ֔רֶץ אֲשֶׁ֥ר אֲנִ֛י מֵבִ֥יא אֶתְכֶ֖ם שָֽׁמָּה׃"
          },
          {
            "num": "יט",
            "txt": "וְהָיָ֕ה בַּֽאֲכָלְכֶ֖ם מִלֶּ֣חֶם הָאָ֑רֶץ תָּרִ֥ימוּ תְרוּמָ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "כ",
            "txt": "רֵאשִׁית֙ עֲרִסֹ֣תֵכֶ֔ם חַלָּ֖ה תָּרִ֣ימוּ תְרוּמָ֑ה כִּתְרוּמַ֣ת גֹּ֔רֶן כֵּ֖ן תָּרִ֥ימוּ אֹתָֽהּ׃"
          },
          {
            "num": "כא",
            "txt": "מֵֽרֵאשִׁית֙ עֲרִסֹ֣תֵיכֶ֔ם תִּתְּנ֥וּ לַֽיהוָ֖ה תְּרוּמָ֑ה לְדֹרֹ֖תֵיכֶֽם׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "וְכִ֣י תִשְׁגּ֔וּ וְלֹ֣א תַֽעֲשׂ֔וּ אֵ֥ת כָּל־הַמִּצְוֺ֖ת הָאֵ֑לֶּה אֲשֶׁר־דִּבֶּ֥ר יְהוָ֖ה אֶל־מֹשֶֽׁה׃"
          },
          {
            "num": "כג",
            "txt": "אֵת֩ כָּל־אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֲלֵיכֶ֖ם בְּיַד־מֹשֶׁ֑ה מִן־הַיּ֞וֹם אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה וָהָ֖לְאָה לְדֹרֹֽתֵיכֶֽם׃"
          },
          {
            "num": "כד",
            "txt": "וְהָיָ֗ה אִ֣ם מֵֽעֵינֵ֣י הָֽעֵדָה֮ נֶֽעֶשְׂתָ֣ה לִשְׁגָגָה֒ וְעָשׂ֣וּ כָל־הָֽעֵדָ֡ה פַּ֣ר בֶּן־בָּקָר֩ אֶחָ֨ד לְעֹלָ֜ה לְרֵ֤יחַ נִיחֹ֨חַ֙ לַֽיהוָ֔ה וּמִנְחָת֥וֹ וְנִסְכּ֖וֹ כַּמִּשְׁפָּ֑ט וּשְׂעִיר־עִזִּ֥ים אֶחָ֖ד לְחַטָּֽת׃"
          },
          {
            "num": "כה",
            "txt": "וְכִפֶּ֣ר הַכֹּהֵ֗ן עַֽל־כָּל־עֲדַ֛ת בְּנֵ֥י יִשְׂרָאֵ֖ל וְנִסְלַ֣ח לָהֶ֑ם כִּֽי־שְׁגָגָ֣ה הִ֔וא וְהֵם֩ הֵבִ֨יאוּ אֶת־קָרְבָּנָ֜ם אִשֶּׁ֣ה לַֽיהוָ֗ה וְחַטָּאתָ֛ם לִפְנֵ֥י יְהוָ֖ה עַל־שִׁגְגָתָֽם׃"
          },
          {
            "num": "כו",
            "txt": "וְנִסְלַ֗ח לְכָל־עֲדַת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל וְלַגֵּ֖ר הַגָּ֣ר בְּתוֹכָ֑ם כִּ֥י לְכָל־הָעָ֖ם בִּשְׁגָגָֽה׃ {ס}"
          },
          {
            "num": "כז",
            "txt": "וְאִם־נֶ֥פֶשׁ אַחַ֖ת תֶּֽחֱטָ֣א בִשְׁגָגָ֑ה וְהִקְרִ֛יבָה עֵ֥ז בַּת־שְׁנָתָ֖הּ לְחַטָּֽאת׃"
          },
          {
            "num": "כח",
            "txt": "וְכִפֶּ֣ר הַכֹּהֵ֗ן עַל־הַנֶּ֧פֶשׁ הַשֹּׁגֶ֛גֶת בְּחֶטְאָ֥ה בִשְׁגָגָ֖ה לִפְנֵ֣י יְהוָ֑ה לְכַפֵּ֥ר עָלָ֖יו וְנִסְלַ֥ח לֽוֹ׃"
          },
          {
            "num": "כט",
            "txt": "הָֽאֶזְרָח֙ בִּבְנֵ֣י יִשְׂרָאֵ֔ל וְלַגֵּ֖ר הַגָּ֣ר בְּתוֹכָ֑ם תּוֹרָ֤ה אַחַת֙ יִֽהְיֶ֣ה לָכֶ֔ם לָֽעֹשֶׂ֖ה בִּשְׁגָגָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וְהַנֶּ֜פֶשׁ אֲשֶֽׁר־תַּעֲשֶׂ֣ה ׀ בְּיָ֣ד רָמָ֗ה מִן־הָֽאֶזְרָח֙ וּמִן־הַגֵּ֔ר אֶת־יְהוָ֖ה ה֣וּא מְגַדֵּ֑ף וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מִקֶּ֥רֶב עַמָּֽהּ׃"
          },
          {
            "num": "לא",
            "txt": "כִּ֤י דְבַר־יְהוָה֙ בָּזָ֔ה וְאֶת־מִצְוָת֖וֹ הֵפַ֑ר הִכָּרֵ֧ת ׀ תִּכָּרֵ֛ת הַנֶּ֥פֶשׁ הַהִ֖וא עֲוֺנָ֥ה בָֽהּ׃ {פ}"
          },
          {
            "num": "לב",
            "txt": "וַיִּֽהְי֥וּ בְנֵֽי־יִשְׂרָאֵ֖ל בַּמִּדְבָּ֑ר וַֽיִּמְצְא֗וּ אִ֛ישׁ מְקֹשֵׁ֥שׁ עֵצִ֖ים בְּי֥וֹם הַשַּׁבָּֽת׃"
          },
          {
            "num": "לג",
            "txt": "וַיַּקְרִ֣יבוּ אֹת֔וֹ הַמֹּֽצְאִ֥ים אֹת֖וֹ מְקֹשֵׁ֣שׁ עֵצִ֑ים אֶל־מֹשֶׁה֙ וְאֶֽל־אַהֲרֹ֔ן וְאֶ֖ל כָּל־הָֽעֵדָֽה׃"
          },
          {
            "num": "לד",
            "txt": "וַיַּנִּ֥יחוּ אֹת֖וֹ בַּמִּשְׁמָ֑ר כִּ֚י לֹ֣א פֹרַ֔שׁ מַה־יֵּֽעָשֶׂ֖ה לֽוֹ׃ {ס}"
          },
          {
            "num": "לה",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה מ֥וֹת יוּמַ֖ת הָאִ֑ישׁ רָג֨וֹם אֹת֤וֹ בָֽאֲבָנִים֙ כָּל־הָ֣עֵדָ֔ה מִח֖וּץ לַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "לו",
            "txt": "וַיֹּצִ֨יאוּ אֹת֜וֹ כָּל־הָֽעֵדָ֗ה אֶל־מִחוּץ֙ לַֽמַּחֲנֶ֔ה וַיִּרְגְּמ֥וּ אֹת֛וֹ בָּֽאֲבָנִ֖ים וַיָּמֹ֑ת כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          },
          {
            "num": "לז",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "לח",
            "txt": "דַּבֵּ֞ר אֶל־בְּנֵ֤י יִשְׂרָאֵל֙ וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם וְעָשׂ֨וּ לָהֶ֥ם צִיצִ֛ת עַל־כַּנְפֵ֥י בִגְדֵיהֶ֖ם לְדֹֽרֹתָ֑ם וְנָ֥תְנ֛וּ עַל־צִיצִ֥ת הַכָּנָ֖ף פְּתִ֥יל תְּכֵֽלֶת׃"
          },
          {
            "num": "לט",
            "txt": "וְהָיָ֣ה לָכֶם֮ לְצִיצִת֒ וּרְאִיתֶ֣ם אֹת֗וֹ וּזְכַרְתֶּם֙ אֶת־כָּל־מִצְוֺ֣ת יְהוָ֔ה וַֽעֲשִׂיתֶ֖ם אֹתָ֑ם וְלֹֽא־תָת֜וּרוּ אַֽחֲרֵ֤י לְבַבְכֶם֙ וְאַֽחֲרֵ֣י עֵֽינֵיכֶ֔ם אֲשֶׁר־אַתֶּ֥ם זֹנִ֖ים אַֽחֲרֵיהֶֽם׃"
          },
          {
            "num": "מ",
            "txt": "לְמַ֣עַן תִּזְכְּר֔וּ וַֽעֲשִׂיתֶ֖ם אֶת־כָּל־מִצְוֺתָ֑י וִֽהְיִיתֶ֥ם קְדֹשִׁ֖ים לֵֽאלֹהֵיכֶֽם׃"
          },
          {
            "num": "מא",
            "txt": "אֲנִ֞י יְהוָ֣ה אֱלֹֽהֵיכֶ֗ם אֲשֶׁ֨ר הוֹצֵ֤אתִי אֶתְכֶם֙ מֵאֶ֣רֶץ מִצְרַ֔יִם לִֽהְי֥וֹת לָכֶ֖ם לֵֽאלֹהִ֑ים אֲנִ֖י יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "טז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּקַּ֣ח קֹ֔רַח בֶּן־יִצְהָ֥ר בֶּן־קְהָ֖ת בֶּן־לֵוִ֑י וְדָתָ֨ן וַֽאֲבִירָ֜ם בְּנֵ֧י אֱלִיאָ֛ב וְא֥וֹן בֶּן־פֶּ֖לֶת בְּנֵ֥י רְאוּבֵֽן׃"
          },
          {
            "num": "ב",
            "txt": "וַיָּקֻ֨מוּ֙ לִפְנֵ֣י מֹשֶׁ֔ה וַֽאֲנָשִׁ֥ים מִבְּנֵֽי־יִשְׂרָאֵ֖ל חֲמִשִּׁ֣ים וּמָאתָ֑יִם נְשִׂיאֵ֥י עֵדָ֛ה קְרִאֵ֥י מוֹעֵ֖ד אַנְשֵׁי־שֵֽׁם׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּֽקָּהֲל֞וּ עַל־מֹשֶׁ֣ה וְעַֽל־אַהֲרֹ֗ן וַיֹּֽאמְר֣וּ אֲלֵהֶם֮ רַב־לָכֶם֒ כִּ֤י כָל־הָֽעֵדָה֙ כֻּלָּ֣ם קְדֹשִׁ֔ים וּבְתוֹכָ֖ם יְהוָ֑ה וּמַדּ֥וּעַ תִּֽתְנַשְּׂא֖וּ עַל־קְהַ֥ל יְהוָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּשְׁמַ֣ע מֹשֶׁ֔ה וַיִּפֹּ֖ל עַל־פָּנָֽיו׃"
          },
          {
            "num": "ה",
            "txt": "וַיְדַבֵּ֨ר אֶל־קֹ֜רַח וְאֶֽל־כָּל־עֲדָתוֹ֮ לֵאמֹר֒ בֹּ֠קֶר וְיֹדַ֨ע יְהוָ֧ה אֶת־אֲשֶׁר־ל֛וֹ וְאֶת־הַקָּד֖וֹשׁ וְהִקְרִ֣יב אֵלָ֑יו וְאֵ֛ת אֲשֶׁ֥ר יִבְחַר־בּ֖וֹ יַקְרִ֥יב אֵלָֽיו׃"
          },
          {
            "num": "ו",
            "txt": "זֹ֖את עֲשׂ֑וּ קְחוּ־לָכֶ֣ם מַחְתּ֔וֹת קֹ֖רַח וְכָל־עֲדָתֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וּתְנ֣וּ בָהֵ֣ן ׀ אֵ֡שׁ וְשִׂימוּ֩ עֲלֵיהֶ֨ן ׀ קְטֹ֜רֶת לִפְנֵ֤י יְהוָה֙ מָחָ֔ר וְהָיָ֗ה הָאִ֛ישׁ אֲשֶׁר־יִבְחַ֥ר יְהוָ֖ה ה֣וּא הַקָּד֑וֹשׁ רַב־לָכֶ֖ם בְּנֵ֥י לֵוִֽי׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֥אמֶר מֹשֶׁ֖ה אֶל־קֹ֑רַח שִׁמְעוּ־נָ֖א בְּנֵ֥י לֵוִֽי׃"
          },
          {
            "num": "ט",
            "txt": "הַמְעַ֣ט מִכֶּ֗ם כִּֽי־הִבְדִּיל֩ אֱלֹהֵ֨י יִשְׂרָאֵ֤ל אֶתְכֶם֙ מֵֽעֲדַ֣ת יִשְׂרָאֵ֔ל לְהַקְרִ֥יב אֶתְכֶ֖ם אֵלָ֑יו לַֽעֲבֹ֗ד אֶת־עֲבֹדַת֙ מִשְׁכַּ֣ן יְהוָ֔ה וְלַֽעֲמֹ֛ד לִפְנֵ֥י הָֽעֵדָ֖ה לְשָֽׁרְתָֽם׃"
          },
          {
            "num": "י",
            "txt": "וַיַּקְרֵב֙ אֹֽתְךָ֔ וְאֶת־כָּל־אַחֶ֥יךָ בְנֵֽי־לֵוִ֖י אִתָּ֑ךְ וּבִקַּשְׁתֶּ֖ם גַּם־כְּהֻנָּֽה׃"
          },
          {
            "num": "יא",
            "txt": "לָכֵ֗ן אַתָּה֙ וְכָל־עֲדָ֣תְךָ֔ הַנֹּֽעָדִ֖ים עַל־יְהוָ֑ה וְאַֽהֲרֹ֣ן מַה־ה֔וּא כִּ֥י תלונו (תַלִּ֖ינוּ) עָלָֽיו׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּשְׁלַ֣ח מֹשֶׁ֔ה לִקְרֹ֛א לְדָתָ֥ן וְלַֽאֲבִירָ֖ם בְּנֵ֣י אֱלִיאָ֑ב וַיֹּֽאמְר֖וּ לֹ֥א נַֽעֲלֶֽה׃"
          },
          {
            "num": "יג",
            "txt": "הַמְעַ֗ט כִּ֤י הֶֽעֱלִיתָ֨נוּ֙ מֵאֶ֨רֶץ זָבַ֤ת חָלָב֙ וּדְבַ֔שׁ לַֽהֲמִיתֵ֖נוּ בַּמִּדְבָּ֑ר כִּֽי־תִשְׂתָּרֵ֥ר עָלֵ֖ינוּ גַּם־הִשְׂתָּרֵֽר׃"
          },
          {
            "num": "יד",
            "txt": "אַ֡ף לֹ֣א אֶל־אֶרֶץ֩ זָבַ֨ת חָלָ֤ב וּדְבַשׁ֙ הֲבִ֣יאֹתָ֔נוּ וַתִּ֨תֶּן־לָ֔נוּ נַֽחֲלַ֖ת שָׂדֶ֣ה וָכָ֑רֶם הַֽעֵינֵ֞י הָֽאֲנָשִׁ֥ים הָהֵ֛ם תְּנַקֵּ֖ר לֹ֥א נַֽעֲלֶֽה׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּ֤חַר לְמֹשֶׁה֙ מְאֹ֔ד וַיֹּ֨אמֶר֙ אֶל־יְהוָ֔ה אַל־תֵּ֖פֶן אֶל־מִנְחָתָ֑ם לֹ֠א חֲמ֨וֹר אֶחָ֤ד מֵהֶם֙ נָשָׂ֔אתִי וְלֹ֥א הֲרֵעֹ֖תִי אֶת־אַחַ֥ד מֵהֶֽם׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֤אמֶר מֹשֶׁה֙ אֶל־קֹ֔רַח אַתָּה֙ וְכָל־עֲדָ֣תְךָ֔ הֱי֖וּ לִפְנֵ֣י יְהוָ֑ה אַתָּ֥ה וָהֵ֛ם וְאַֽהֲרֹ֖ן מָחָֽר׃"
          },
          {
            "num": "יז",
            "txt": "וּקְח֣וּ ׀ אִ֣ישׁ מַחְתָּת֗וֹ וּנְתַתֶּ֤ם עֲלֵיהֶם֙ קְטֹ֔רֶת וְהִקְרַבְתֶּ֞ם לִפְנֵ֤י יְהוָה֙ אִ֣ישׁ מַחְתָּת֔וֹ חֲמִשִּׁ֥ים וּמָאתַ֖יִם מַחְתֹּ֑ת וְאַתָּ֥ה וְאַֽהֲרֹ֖ן אִ֥ישׁ מַחְתָּתֽוֹ׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּקְח֞וּ אִ֣ישׁ מַחְתָּת֗וֹ וַיִּתְּנ֤וּ עֲלֵיהֶם֙ אֵ֔שׁ וַיָּשִׂ֥ימוּ עֲלֵיהֶ֖ם קְטֹ֑רֶת וַיַּֽעַמְד֗וּ פֶּ֛תַח אֹ֥הֶל מוֹעֵ֖ד וּמֹשֶׁ֥ה וְאַֽהֲרֹֽן׃"
          },
          {
            "num": "יט",
            "txt": "וַיַּקְהֵ֨ל עֲלֵיהֶ֥ם קֹ֨רַח֙ אֶת־כָּל־הָ֣עֵדָ֔ה אֶל־פֶּ֖תַח אֹ֣הֶל מוֹעֵ֑ד וַיֵּרָ֥א כְבוֹד־יְהוָ֖ה אֶל־כָּל־הָֽעֵדָֽה׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "כא",
            "txt": "הִבָּ֣דְל֔וּ מִתּ֖וֹךְ הָֽעֵדָ֣ה הַזֹּ֑את וַֽאֲכַלֶּ֥ה אֹתָ֖ם כְּרָֽגַע׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּפְּל֤וּ עַל־פְּנֵיהֶם֙ וַיֹּ֣אמְר֔וּ אֵ֕ל אֱלֹהֵ֥י הָֽרוּחֹ֖ת לְכָל־בָּשָׂ֑ר הָאִ֤ישׁ אֶחָד֙ יֶֽחֱטָ֔א וְעַ֥ל כָּל־הָֽעֵדָ֖ה תִּקְצֹֽף׃ {ס}"
          },
          {
            "num": "כג",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כד",
            "txt": "דַּבֵּ֥ר אֶל־הָֽעֵדָ֖ה לֵאמֹ֑ר הֵֽעָלוּ֙ מִסָּבִ֔יב לְמִשְׁכַּן־קֹ֖רַח דָּתָ֥ן וַֽאֲבִירָֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַיָּ֣קָם מֹשֶׁ֔ה וַיֵּ֖לֶךְ אֶל־דָּתָ֣ן וַֽאֲבִירָ֑ם וַיֵּֽלְכ֥וּ אַֽחֲרָ֖יו זִקְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כו",
            "txt": "וַיְדַבֵּ֨ר אֶל־הָֽעֵדָ֜ה לֵאמֹ֗ר ס֣וּרוּ נָ֡א מֵעַל֩ אָֽהֳלֵ֨י הָֽאֲנָשִׁ֤ים הָֽרְשָׁעִים֙ הָאֵ֔לֶּה וְאַֽל־תִּגְּע֖וּ בְּכָל־אֲשֶׁ֣ר לָהֶ֑ם פֶּן־תִּסָּפ֖וּ בְּכָל־חַטֹּאתָֽם׃"
          },
          {
            "num": "כז",
            "txt": "וַיֵּֽעָל֗וּ מֵעַ֧ל מִשְׁכַּן־קֹ֛רַח דָּתָ֥ן וַֽאֲבִירָ֖ם מִסָּבִ֑יב וְדָתָ֨ן וַֽאֲבִירָ֜ם יָֽצְא֣וּ נִצָּבִ֗ים פֶּ֚תַח אָֽהֳלֵיהֶ֔ם וּנְשֵׁיהֶ֥ם וּבְנֵיהֶ֖ם וְטַפָּֽם׃"
          },
          {
            "num": "כח",
            "txt": "וַיֹּאמֶר֮ מֹשֶׁה֒ בְּזֹאת֙ תֵּֽדְע֔וּן כִּֽי־יְהוָ֣ה שְׁלָחַ֔נִי לַֽעֲשׂ֕וֹת אֵ֥ת כָּל־הַֽמַּעֲשִׂ֖ים הָאֵ֑לֶּה כִּי־לֹ֖א מִלִּבִּֽי׃"
          },
          {
            "num": "כט",
            "txt": "אִם־כְּמ֤וֹת כָּל־הָֽאָדָם֙ יְמֻת֣וּן אֵ֔לֶּה וּפְקֻדַּת֙ כָּל־הָ֣אָדָ֔ם יִפָּקֵ֖ד עֲלֵיהֶ֑ם לֹ֥א יְהוָ֖ה שְׁלָחָֽנִי׃"
          },
          {
            "num": "ל",
            "txt": "וְאִם־בְּרִיאָ֞ה יִבְרָ֣א יְהוָ֗ה וּפָֽצְתָ֨ה הָֽאֲדָמָ֤ה אֶת־פִּ֨יהָ֙ וּבָֽלְעָ֤ה אֹתָם֙ וְאֶת־כָּל־אֲשֶׁ֣ר לָהֶ֔ם וְיָֽרְד֥וּ חַיִּ֖ים שְׁאֹ֑לָה וִֽידַעְתֶּ֕ם כִּ֧י נִֽאֲצ֛וּ הָֽאֲנָשִׁ֥ים הָאֵ֖לֶּה אֶת־יְהוָֽה׃"
          },
          {
            "num": "לא",
            "txt": "וַֽיְהִי֙ כְּכַלֹּת֔וֹ לְדַבֵּ֕ר אֵ֥ת כָּל־הַדְּבָרִ֖ים הָאֵ֑לֶּה וַתִּבָּקַ֥ע הָֽאֲדָמָ֖ה אֲשֶׁ֥ר תַּחְתֵּיהֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "וַתִּפְתַּ֤ח הָאָ֨רֶץ֙ אֶת־פִּ֔יהָ וַתִּבְלַ֥ע אֹתָ֖ם וְאֶת־בָּֽתֵּיהֶ֑ם וְאֵ֤ת כָּל־הָֽאָדָם֙ אֲשֶׁ֣ר לְקֹ֔רַח וְאֵ֖ת כָּל־הָֽרְכֽוּשׁ׃"
          },
          {
            "num": "לג",
            "txt": "וַיֵּ֨רְד֜וּ הֵ֣ם וְכָל־אֲשֶׁ֥ר לָהֶ֛ם חַיִּ֖ים שְׁאֹ֑לָה וַתְּכַ֤ס עֲלֵיהֶם֙ הָאָ֔רֶץ וַיֹּֽאבְד֖וּ מִתּ֥וֹךְ הַקָּהָֽל׃"
          },
          {
            "num": "לד",
            "txt": "וְכָל־יִשְׂרָאֵ֗ל אֲשֶׁ֛ר סְבִיבֹֽתֵיהֶ֖ם נָ֣סוּ לְקֹלָ֑ם כִּ֣י אָֽמְר֔וּ פֶּן־תִּבְלָעֵ֖נוּ הָאָֽרֶץ׃"
          },
          {
            "num": "לה",
            "txt": "וְאֵ֥שׁ יָֽצְאָ֖ה מֵאֵ֣ת יְהוָ֑ה וַתֹּ֗אכַל אֵ֣ת הַֽחֲמִשִּׁ֤ים וּמָאתַ֨יִם֙ אִ֔ישׁ מַקְרִיבֵ֖י הַקְּטֹֽרֶת׃ {ס}"
          }
        ]
      },
      {
        "num": "יז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "אֱמֹ֨ר אֶל־אֶלְעָזָ֜ר בֶּן־אַֽהֲרֹ֣ן הַכֹּהֵ֗ן וְיָרֵ֤ם אֶת־הַמַּחְתֹּת֙ מִבֵּ֣ין הַשְּׂרֵפָ֔ה וְאֶת־הָאֵ֖שׁ זְרֵה־הָ֑לְאָה כִּ֖י קָדֵֽשׁוּ׃"
          },
          {
            "num": "ג",
            "txt": "אֵ֡ת מַחְתּוֹת֩ הַֽחַטָּאִ֨ים הָאֵ֜לֶּה בְּנַפְשֹׁתָ֗ם וְעָשׂ֨וּ אֹתָ֜ם רִקֻּעֵ֤י פַחִים֙ צִפּ֣וּי לַמִּזְבֵּ֔חַ כִּֽי־הִקְרִיבֻ֥ם לִפְנֵֽי־יְהוָ֖ה וַיִּקְדָּ֑שׁוּ וְיִֽהְי֥וּ לְא֖וֹת לִבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּקַּ֞ח אֶלְעָזָ֣ר הַכֹּהֵ֗ן אֵ֚ת מַחְתּ֣וֹת הַנְּחֹ֔שֶׁת אֲשֶׁ֥ר הִקְרִ֖יבוּ הַשְּׂרֻפִ֑ים וַֽיְרַקְּע֖וּם צִפּ֥וּי לַמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ה",
            "txt": "זִכָּר֞וֹן לִבְנֵ֣י יִשְׂרָאֵ֗ל לְ֠מַעַן אֲשֶׁ֨ר לֹֽא־יִקְרַ֜ב אִ֣ישׁ זָ֗ר אֲ֠שֶׁר לֹ֣א מִזֶּ֤רַע אַֽהֲרֹן֙ ה֔וּא לְהַקְטִ֥יר קְטֹ֖רֶת לִפְנֵ֣י יְהוָ֑ה וְלֹֽא־יִהְיֶ֤ה כְקֹ֨רַח֙ וְכַ֣עֲדָת֔וֹ כַּֽאֲשֶׁ֨ר דִּבֶּ֧ר יְהוָ֛ה בְּיַד־מֹשֶׁ֖ה לֽוֹ׃ {פ}"
          },
          {
            "num": "ו",
            "txt": "וַיִּלֹּ֜נוּ כָּל־עֲדַ֤ת בְּנֵֽי־יִשְׂרָאֵל֙ מִֽמָּחֳרָ֔ת עַל־מֹשֶׁ֥ה וְעַֽל־אַהֲרֹ֖ן לֵאמֹ֑ר אַתֶּ֥ם הֲמִתֶּ֖ם אֶת־עַ֥ם יְהוָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וַיְהִ֗י בְּהִקָּהֵ֤ל הָֽעֵדָה֙ עַל־מֹשֶׁ֣ה וְעַֽל־אַהֲרֹ֔ן וַיִּפְנוּ֙ אֶל־אֹ֣הֶל מוֹעֵ֔ד וְהִנֵּ֥ה כִסָּ֖הוּ הֶֽעָנָ֑ן וַיֵּרָ֖א כְּב֥וֹד יְהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וַיָּבֹ֤א מֹשֶׁה֙ וְאַֽהֲרֹ֔ן אֶל־פְּנֵ֖י אֹ֥הֶל מוֹעֵֽד׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "י",
            "txt": "הֵרֹ֗מּוּ מִתּוֹךְ֙ הָֽעֵדָ֣ה הַזֹּ֔את וַֽאֲכַלֶּ֥ה אֹתָ֖ם כְּרָ֑גַע וַֽיִּפְּל֖וּ עַל־פְּנֵיהֶֽם׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֶֽל־אַהֲרֹ֗ן קַ֣ח אֶת־הַ֠מַּחְתָּה וְתֶן־עָלֶ֨יהָ אֵ֜שׁ מֵעַ֤ל הַמִּזְבֵּ֨חַ֙ וְשִׂ֣ים קְטֹ֔רֶת וְהוֹלֵ֧ךְ מְהֵרָ֛ה אֶל־הָֽעֵדָ֖ה וְכַפֵּ֣ר עֲלֵיהֶ֑ם כִּֽי־יָצָ֥א הַקֶּ֛צֶף מִלִּפְנֵ֥י יְהוָ֖ה הֵחֵ֥ל הַנָּֽגֶף׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּקַּ֨ח אַֽהֲרֹ֜ן כַּֽאֲשֶׁ֣ר ׀ דִּבֶּ֣ר מֹשֶׁ֗ה וַיָּ֨רָץ֙ אֶל־תּ֣וֹךְ הַקָּהָ֔ל וְהִנֵּ֛ה הֵחֵ֥ל הַנֶּ֖גֶף בָּעָ֑ם וַיִּתֵּן֙ אֶֽת־הַקְּטֹ֔רֶת וַיְכַפֵּ֖ר עַל־הָעָֽם׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּֽעֲמֹ֥ד בֵּֽין־הַמֵּתִ֖ים וּבֵ֣ין הַֽחַיִּ֑ים וַתֵּֽעָצַ֖ר הַמַּגֵּפָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּֽהְי֗וּ הַמֵּתִים֙ בַּמַּגֵּפָ֔ה אַרְבָּעָ֥ה עָשָׂ֛ר אֶ֖לֶף וּשְׁבַ֣ע מֵא֑וֹת מִלְּבַ֥ד הַמֵּתִ֖ים עַל־דְּבַר־קֹֽרַח׃"
          },
          {
            "num": "טו",
            "txt": "וַיָּ֤שָׁב אַֽהֲרֹן֙ אֶל־מֹשֶׁ֔ה אֶל־פֶּ֖תַח אֹ֣הֶל מוֹעֵ֑ד וְהַמַּגֵּפָ֖ה נֶֽעֱצָֽרָה׃ {פ}"
          },
          {
            "num": "טז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "דַּבֵּ֣ר ׀ אֶל־בְּנֵ֣י יִשְׂרָאֵ֗ל וְקַ֣ח מֵֽאִתָּ֡ם מַטֶּ֣ה מַטֶּה֩ לְבֵ֨ית אָ֜ב מֵאֵ֤ת כָּל־נְשִֽׂיאֵהֶם֙ לְבֵ֣ית אֲבֹתָ֔ם שְׁנֵ֥ים עָשָׂ֖ר מַטּ֑וֹת אִ֣ישׁ אֶת־שְׁמ֔וֹ תִּכְתֹּ֖ב עַל־מַטֵּֽהוּ׃"
          },
          {
            "num": "יח",
            "txt": "וְאֵת֙ שֵׁ֣ם אַֽהֲרֹ֔ן תִּכְתֹּ֖ב עַל־מַטֵּ֣ה לֵוִ֑י כִּ֚י מַטֶּ֣ה אֶחָ֔ד לְרֹ֖אשׁ בֵּ֥ית אֲבוֹתָֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְהִנַּחְתָּ֖ם בְּאֹ֣הֶל מוֹעֵ֑ד לִפְנֵי֙ הָֽעֵד֔וּת אֲשֶׁ֛ר אִוָּעֵ֥ד לָכֶ֖ם שָֽׁמָּה׃"
          },
          {
            "num": "כ",
            "txt": "וְהָיָ֗ה הָאִ֛ישׁ אֲשֶׁ֥ר אֶבְחַר־בּ֖וֹ מַטֵּ֣הוּ יִפְרָ֑ח וַֽהֲשִׁכֹּתִ֣י מֵֽעָלַ֗י אֶת־תְּלֻנּוֹת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל אֲשֶׁ֛ר הֵ֥ם מַלִּינִ֖ם עֲלֵיכֶֽם׃"
          },
          {
            "num": "כא",
            "txt": "וַיְדַבֵּ֨ר מֹשֶׁ֜ה אֶל־בְּנֵ֣י יִשְׂרָאֵ֗ל וַיִּתְּנ֣וּ אֵלָ֣יו ׀ כָּֽל־נְשִֽׂיאֵיהֶ֡ם מַטֶּה֩ לְנָשִׂ֨יא אֶחָ֜ד מַטֶּ֨ה לְנָשִׂ֤יא אֶחָד֙ לְבֵ֣ית אֲבֹתָ֔ם שְׁנֵ֥ים עָשָׂ֖ר מַטּ֑וֹת וּמַטֵּ֥ה אַֽהֲרֹ֖ן בְּת֥וֹךְ מַטּוֹתָֽם׃"
          },
          {
            "num": "כב",
            "txt": "וַיַּנַּ֥ח מֹשֶׁ֛ה אֶת־הַמַּטֹּ֖ת לִפְנֵ֣י יְהוָ֑ה בְּאֹ֖הֶל הָֽעֵדֻֽת׃"
          },
          {
            "num": "כג",
            "txt": "וַיְהִ֣י מִֽמָּחֳרָ֗ת וַיָּבֹ֤א מֹשֶׁה֙ אֶל־אֹ֣הֶל הָֽעֵד֔וּת וְהִנֵּ֛ה פָּרַ֥ח מַטֵּֽה־אַהֲרֹ֖ן לְבֵ֣ית לֵוִ֑י וַיֹּ֤צֵֽא פֶ֨רַח֙ וַיָּ֣צֵֽץ צִ֔יץ וַיִּגְמֹ֖ל שְׁקֵדִֽים׃"
          },
          {
            "num": "כד",
            "txt": "וַיֹּצֵ֨א מֹשֶׁ֤ה אֶת־כָּל־הַמַּטֹּת֙ מִלִּפְנֵ֣י יְהוָ֔ה אֶֽל־כָּל־בְּנֵ֖י יִשְׂרָאֵ֑ל וַיִּרְא֥וּ וַיִּקְח֖וּ אִ֥ישׁ מַטֵּֽהוּ׃ {פ}"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה הָשֵׁ֞ב אֶת־מַטֵּ֤ה אַֽהֲרֹן֙ לִפְנֵ֣י הָֽעֵד֔וּת לְמִשְׁמֶ֥רֶת לְא֖וֹת לִבְנֵי־מֶ֑רִי וּתְכַ֧ל תְּלוּנֹּתָ֛ם מֵֽעָלַ֖י וְלֹ֥א יָמֻֽתוּ׃"
          },
          {
            "num": "כו",
            "txt": "וַיַּ֖עַשׂ מֹשֶׁ֑ה כַּֽאֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֹת֖וֹ כֵּ֥ן עָשָֽׂה׃ {פ}"
          },
          {
            "num": "כז",
            "txt": "וַיֹּֽאמְרוּ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל אֶל־מֹשֶׁ֖ה לֵאמֹ֑ר הֵ֥ן גָּוַ֛עְנוּ אָבַ֖דְנוּ כֻּלָּ֥נוּ אָבָֽדְנוּ׃"
          },
          {
            "num": "כח",
            "txt": "כֹּ֣ל הַקָּרֵ֧ב ׀ הַקָּרֵ֛ב אֶל־מִשְׁכַּ֥ן יְהוָ֖ה יָמ֑וּת הַאִ֥ם תַּ֖מְנוּ לִגְוֺֽעַ׃ {ס}"
          }
        ]
      },
      {
        "num": "יח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶֽל־אַהֲרֹ֔ן אַתָּ֗ה וּבָנֶ֤יךָ וּבֵית־אָבִ֨יךָ֙ אִתָּ֔ךְ תִּשְׂא֖וּ אֶת־עֲוֺ֣ן הַמִּקְדָּ֑שׁ וְאַתָּה֙ וּבָנֶ֣יךָ אִתָּ֔ךְ תִּשְׂא֖וּ אֶת־עֲוֺ֥ן כְּהֻנַּתְכֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "וְגַ֣ם אֶת־אַחֶיךָ֩ מַטֵּ֨ה לֵוִ֜י שֵׁ֤בֶט אָבִ֨יךָ֙ הַקְרֵ֣ב אִתָּ֔ךְ וְיִלָּו֥וּ עָלֶ֖יךָ וִֽישָׁרְת֑וּךָ וְאַתָּה֙ וּבָנֶ֣יךָ אִתָּ֔ךְ לִפְנֵ֖י אֹ֥הֶל הָֽעֵדֻֽת׃"
          },
          {
            "num": "ג",
            "txt": "וְשָֽׁמְרוּ֙ מִֽשְׁמַרְתְּךָ֔ וּמִשְׁמֶ֖רֶת כָּל־הָאֹ֑הֶל אַךְ֩ אֶל־כְּלֵ֨י הַקֹּ֤דֶשׁ וְאֶל־הַמִּזְבֵּ֨חַ֙ לֹ֣א יִקְרָ֔בוּ וְלֹֽא־יָמֻ֥תוּ גַם־הֵ֖ם גַּם־אַתֶּֽם׃"
          },
          {
            "num": "ד",
            "txt": "וְנִלְו֣וּ עָלֶ֔יךָ וְשָֽׁמְר֗וּ אֶת־מִשְׁמֶ֨רֶת֙ אֹ֣הֶל מוֹעֵ֔ד לְכֹ֖ל עֲבֹדַ֣ת הָאֹ֑הֶל וְזָ֖ר לֹֽא־יִקְרַ֥ב אֲלֵיכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "וּשְׁמַרְתֶּ֗ם אֵ֚ת מִשְׁמֶ֣רֶת הַקֹּ֔דֶשׁ וְאֵ֖ת מִשְׁמֶ֣רֶת הַמִּזְבֵּ֑חַ וְלֹֽא־יִהְיֶ֥ה ע֛וֹד קֶ֖צֶף עַל־בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ו",
            "txt": "וַֽאֲנִ֗י הִנֵּ֤ה לָקַ֨חְתִּי֙ אֶת־אֲחֵיכֶ֣ם הַלְוִיִּ֔ם מִתּ֖וֹךְ בְּנֵ֣י יִשְׂרָאֵ֑ל לָכֶ֞ם מַתָּנָ֤ה נְתֻנִים֙ לַֽיהוָ֔ה לַֽעֲבֹ֕ד אֶת־עֲבֹדַ֖ת אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ז",
            "txt": "וְאַתָּ֣ה וּבָנֶ֣יךָ אִ֠תְּךָ תִּשְׁמְר֨וּ אֶת־כְּהֻנַּתְכֶ֜ם לְכָל־דְּבַ֧ר הַמִּזְבֵּ֛חַ וּלְמִבֵּ֥ית לַפָּרֹ֖כֶת וַֽעֲבַדְתֶּ֑ם עֲבֹדַ֣ת מַתָּנָ֗ה אֶתֵּן֙ אֶת־כְּהֻנַּתְכֶ֔ם וְהַזָּ֥ר הַקָּרֵ֖ב יוּמָֽת׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "וַיְדַבֵּ֣ר יְהוָה֮ אֶֽל־אַהֲרֹן֒ וַֽאֲנִי֙ הִנֵּ֣ה נָתַ֣תִּֽי לְךָ֔ אֶת־מִשְׁמֶ֖רֶת תְּרֽוּמֹתָ֑י לְכָל־קָדְשֵׁ֣י בְנֵֽי־יִ֠שְׂרָאֵל לְךָ֨ נְתַתִּ֧ים לְמָשְׁחָ֛ה וּלְבָנֶ֖יךָ לְחָק־עוֹלָֽם׃"
          },
          {
            "num": "ט",
            "txt": "זֶֽה־יִהְיֶ֥ה לְךָ֛ מִקֹּ֥דֶשׁ הַקֳּדָשִׁ֖ים מִן־הָאֵ֑שׁ כָּל־קָ֠רְבָּנָם לְֽכָל־מִנְחָתָ֞ם וּלְכָל־חַטָּאתָ֗ם וּלְכָל־אֲשָׁמָם֙ אֲשֶׁ֣ר יָשִׁ֣יבוּ לִ֔י קֹ֣דֶשׁ קָֽדָשִׁ֥ים לְךָ֛ ה֖וּא וּלְבָנֶֽיךָ׃"
          },
          {
            "num": "י",
            "txt": "בְּקֹ֥דֶשׁ הַקֳּדָשִׁ֖ים תֹּֽאכְלֶ֑נּוּ כָּל־זָכָר֙ יֹאכַ֣ל אֹת֔וֹ קֹ֖דֶשׁ יִֽהְיֶה־לָּֽךְ׃"
          },
          {
            "num": "יא",
            "txt": "וְזֶה־לְּךָ֞ תְּרוּמַ֣ת מַתָּנָ֗ם לְכָל־תְּנוּפֹת֮ בְּנֵ֣י יִשְׂרָאֵל֒ לְךָ֣ נְתַתִּ֗ים וּלְבָנֶ֧יךָ וְלִבְנֹתֶ֛יךָ אִתְּךָ֖ לְחָק־עוֹלָ֑ם כָּל־טָה֥וֹר בְּבֵֽיתְךָ֖ יֹאכַ֥ל אֹתֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "כֹּ֚ל חֵ֣לֶב יִצְהָ֔ר וְכָל־חֵ֖לֶב תִּיר֣וֹשׁ וְדָגָ֑ן רֵֽאשִׁיתָ֛ם אֲשֶׁר־יִתְּנ֥וּ לַֽיהוָ֖ה לְךָ֥ נְתַתִּֽים׃"
          },
          {
            "num": "יג",
            "txt": "בִּכּוּרֵ֞י כָּל־אֲשֶׁ֧ר בְּאַרְצָ֛ם אֲשֶׁר־יָבִ֥יאוּ לַֽיהוָ֖ה לְךָ֣ יִֽהְיֶ֑ה כָּל־טָה֥וֹר בְּבֵֽיתְךָ֖ יֹֽאכְלֶֽנּוּ׃"
          },
          {
            "num": "יד",
            "txt": "כָּל־חֵ֥רֶם בְּיִשְׂרָאֵ֖ל לְךָ֥ יִֽהְיֶֽה׃"
          },
          {
            "num": "טו",
            "txt": "כָּל־פֶּ֣טֶר רֶ֠חֶם לְֽכָל־בָּשָׂ֞ר אֲשֶׁר־יַקְרִ֧יבוּ לַֽיהוָ֛ה בָּֽאָדָ֥ם וּבַבְּהֵמָ֖ה יִֽהְיֶה־לָּ֑ךְ אַ֣ךְ ׀ פָּדֹ֣ה תִפְדֶּ֗ה אֵ֚ת בְּכ֣וֹר הָֽאָדָ֔ם וְאֵ֛ת בְּכֽוֹר־הַבְּהֵמָ֥ה הַטְּמֵאָ֖ה תִּפְדֶּֽה׃"
          },
          {
            "num": "טז",
            "txt": "וּפְדוּיָו֙ מִבֶּן־חֹ֣דֶשׁ תִּפְדֶּ֔ה בְּעֶ֨רְכְּךָ֔ כֶּ֛סֶף חֲמֵ֥שֶׁת שְׁקָלִ֖ים בְּשֶׁ֣קֶל הַקֹּ֑דֶשׁ עֶשְׂרִ֥ים גֵּרָ֖ה הֽוּא׃"
          },
          {
            "num": "יז",
            "txt": "אַ֣ךְ בְּֽכוֹר־שׁ֡וֹר אֽוֹ־בְכ֨וֹר כֶּ֜שֶׂב אֽוֹ־בְכ֥וֹר עֵ֛ז לֹ֥א תִפְדֶּ֖ה קֹ֣דֶשׁ הֵ֑ם אֶת־דָּמָ֞ם תִּזְרֹ֤ק עַל־הַמִּזְבֵּ֨חַ֙ וְאֶת־חֶלְבָּ֣ם תַּקְטִ֔יר אִשֶּׁ֛ה לְרֵ֥יחַ נִיחֹ֖חַ לַֽיהוָֽה׃"
          },
          {
            "num": "יח",
            "txt": "וּבְשָׂרָ֖ם יִֽהְיֶה־לָּ֑ךְ כַּֽחֲזֵ֧ה הַתְּנוּפָ֛ה וּכְשׁ֥וֹק הַיָּמִ֖ין לְךָ֥ יִֽהְיֶֽה׃"
          },
          {
            "num": "יט",
            "txt": "כֹּ֣ל ׀ תְּרוּמֹ֣ת הַקֳּדָשִׁ֗ים אֲשֶׁ֨ר יָרִ֥ימוּ בְנֵֽי־יִשְׂרָאֵל֮ לַֽיהוָה֒ נָתַ֣תִּֽי לְךָ֗ וּלְבָנֶ֧יךָ וְלִבְנֹתֶ֛יךָ אִתְּךָ֖ לְחָק־עוֹלָ֑ם בְּרִית֩ מֶ֨לַח עוֹלָ֥ם הִוא֙ לִפְנֵ֣י יְהוָ֔ה לְךָ֖ וּֽלְזַרְעֲךָ֥ אִתָּֽךְ׃"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶֽל־אַהֲרֹ֗ן בְּאַרְצָם֙ לֹ֣א תִנְחָ֔ל וְחֵ֕לֶק לֹֽא־יִהְיֶ֥ה לְךָ֖ בְּתוֹכָ֑ם אֲנִ֤י חֶלְקְךָ֙ וְנַחֲלָ֣תְךָ֔ בְּת֖וֹךְ בְּנֵ֥י יִשְׂרָאֵֽל׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "וְלִבְנֵ֣י לֵוִ֔י הִנֵּ֥ה נָתַ֛תִּי כָּל־מַֽעֲשֵׂ֥ר בְּיִשְׂרָאֵ֖ל לְנַֽחֲלָ֑ה חֵ֤לֶף עֲבֹֽדָתָם֙ אֲשֶׁר־הֵ֣ם עֹֽבְדִ֔ים אֶת־עֲבֹדַ֖ת אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "כב",
            "txt": "וְלֹֽא־יִקְרְב֥וּ ע֛וֹד בְּנֵ֥י יִשְׂרָאֵ֖ל אֶל־אֹ֣הֶל מוֹעֵ֑ד לָשֵׂ֥את חֵ֖טְא לָמֽוּת׃"
          },
          {
            "num": "כג",
            "txt": "וְעָבַ֨ד הַלֵּוִ֜י ה֗וּא אֶת־עֲבֹדַת֙ אֹ֣הֶל מוֹעֵ֔ד וְהֵ֖ם יִשְׂא֣וּ עֲוֺנָ֑ם חֻקַּ֤ת עוֹלָם֙ לְדֹרֹ֣תֵיכֶ֔ם וּבְתוֹךְ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל לֹ֥א יִנְחֲל֖וּ נַֽחֲלָֽה׃"
          },
          {
            "num": "כד",
            "txt": "כִּ֞י אֶת־מַעְשַׂ֣ר בְּנֵֽי־יִשְׂרָאֵ֗ל אֲשֶׁ֨ר יָרִ֤ימוּ לַֽיהוָה֙ תְּרוּמָ֔ה נָתַ֥תִּי לַלְוִיִּ֖ם לְנַֽחֲלָ֑ה עַל־כֵּן֙ אָמַ֣רְתִּי לָהֶ֔ם בְּתוֹךְ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל לֹ֥א יִנְחֲל֖וּ נַֽחֲלָֽה׃ {פ}"
          },
          {
            "num": "כה",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כו",
            "txt": "וְאֶל־הַלְוִיִּ֣ם תְּדַבֵּר֮ וְאָֽמַרְתָּ֣ אֲלֵהֶם֒ כִּֽי־תִ֠קְחוּ מֵאֵ֨ת בְּנֵֽי־יִשְׂרָאֵ֜ל אֶת־הַֽמַּעֲשֵׂ֗ר אֲשֶׁ֨ר נָתַ֧תִּי לָכֶ֛ם מֵֽאִתָּ֖ם בְּנַֽחֲלַתְכֶ֑ם וַהֲרֵֽמֹתֶ֤ם מִמֶּ֨נּוּ֙ תְּרוּמַ֣ת יְהוָ֔ה מַֽעֲשֵׂ֖ר מִן־הַֽמַּעֲשֵֽׂר׃"
          },
          {
            "num": "כז",
            "txt": "וְנֶחְשַׁ֥ב לָכֶ֖ם תְּרֽוּמַתְכֶ֑ם כַּדָּגָן֙ מִן־הַגֹּ֔רֶן וְכַֽמְלֵאָ֖ה מִן־הַיָּֽקֶב׃"
          },
          {
            "num": "כח",
            "txt": "כֵּ֣ן תָּרִ֤ימוּ גַם־אַתֶּם֙ תְּרוּמַ֣ת יְהוָ֔ה מִכֹּל֙ מַעְשְׂרֹ֣תֵיכֶ֔ם אֲשֶׁ֣ר תִּקְח֔וּ מֵאֵ֖ת בְּנֵ֣י יִשְׂרָאֵ֑ל וּנְתַתֶּ֤ם מִמֶּ֨נּוּ֙ אֶת־תְּרוּמַ֣ת יְהוָ֔ה לְאַֽהֲרֹ֖ן הַכֹּהֵֽן׃"
          },
          {
            "num": "כט",
            "txt": "מִכֹּל֙ מַתְּנֹ֣תֵיכֶ֔ם תָּרִ֕ימוּ אֵ֖ת כָּל־תְּרוּמַ֣ת יְהוָ֑ה מִכָּ֨ל־חֶלְבּ֔וֹ אֶֽת־מִקְדְּשׁ֖וֹ מִמֶּֽנּוּ׃"
          },
          {
            "num": "ל",
            "txt": "וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם בַּהֲרִֽימְכֶ֤ם אֶת־חֶלְבּוֹ֙ מִמֶּ֔נּוּ וְנֶחְשַׁב֙ לַלְוִיִּ֔ם כִּתְבוּאַ֥ת גֹּ֖רֶן וְכִתְבוּאַ֥ת יָֽקֶב׃"
          },
          {
            "num": "לא",
            "txt": "וַֽאֲכַלְתֶּ֤ם אֹתוֹ֙ בְּכָל־מָק֔וֹם אַתֶּ֖ם וּבֵֽיתְכֶ֑ם כִּֽי־שָׂכָ֥ר הוּא֙ לָכֶ֔ם חֵ֥לֶף עֲבֹֽדַתְכֶ֖ם בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "לב",
            "txt": "וְלֹֽא־תִשְׂא֤וּ עָלָיו֙ חֵ֔טְא בַּהֲרִֽימְכֶ֥ם אֶת־חֶלְבּ֖וֹ מִמֶּ֑נּוּ וְאֶת־קָדְשֵׁ֧י בְנֵֽי־יִשְׂרָאֵ֛ל לֹ֥א תְחַלְּל֖וּ וְלֹ֥א תָמֽוּתוּ׃ {פ}"
          }
        ]
      },
      {
        "num": "יט",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֣ר יְהוָ֔ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "זֹ֚את חֻקַּ֣ת הַתּוֹרָ֔ה אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה לֵאמֹ֑ר דַּבֵּ֣ר ׀ אֶל־בְּנֵ֣י יִשְׂרָאֵ֗ל וְיִקְח֣וּ אֵלֶיךָ֩ פָרָ֨ה אֲדֻמָּ֜ה תְּמִימָ֗ה אֲשֶׁ֤ר אֵֽין־בָּהּ֙ מ֔וּם אֲשֶׁ֛ר לֹֽא־עָלָ֥ה עָלֶ֖יהָ עֹֽל׃"
          },
          {
            "num": "ג",
            "txt": "וּנְתַתֶּ֣ם אֹתָ֔הּ אֶל־אֶלְעָזָ֖ר הַכֹּהֵ֑ן וְהוֹצִ֤יא אֹתָהּ֙ אֶל־מִח֣וּץ לַֽמַּחֲנֶ֔ה וְשָׁחַ֥ט אֹתָ֖הּ לְפָנָֽיו׃"
          },
          {
            "num": "ד",
            "txt": "וְלָקַ֞ח אֶלְעָזָ֧ר הַכֹּהֵ֛ן מִדָּמָ֖הּ בְּאֶצְבָּע֑וֹ וְהִזָּ֞ה אֶל־נֹ֨כַח פְּנֵ֧י אֹֽהֶל־מוֹעֵ֛ד מִדָּמָ֖הּ שֶׁ֥בַע פְּעָמִֽים׃"
          },
          {
            "num": "ה",
            "txt": "וְשָׂרַ֥ף אֶת־הַפָּרָ֖ה לְעֵינָ֑יו אֶת־עֹרָ֤הּ וְאֶת־בְּשָׂרָהּ֙ וְאֶת־דָּמָ֔הּ עַל־פִּרְשָׁ֖הּ יִשְׂרֹֽף׃"
          },
          {
            "num": "ו",
            "txt": "וְלָקַ֣ח הַכֹּהֵ֗ן עֵ֥ץ אֶ֛רֶז וְאֵז֖וֹב וּשְׁנִ֣י תוֹלָ֑עַת וְהִשְׁלִ֕יךְ אֶל־תּ֖וֹךְ שְׂרֵפַ֥ת הַפָּרָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וְכִבֶּ֨ס בְּגָדָ֜יו הַכֹּהֵ֗ן וְרָחַ֤ץ בְּשָׂרוֹ֙ בַּמַּ֔יִם וְאַחַ֖ר יָבֹ֣א אֶל־הַֽמַּחֲנֶ֑ה וְטָמֵ֥א הַכֹּהֵ֖ן עַד־הָעָֽרֶב׃"
          },
          {
            "num": "ח",
            "txt": "וְהַשֹּׂרֵ֣ף אֹתָ֔הּ יְכַבֵּ֤ס בְּגָדָיו֙ בַּמַּ֔יִם וְרָחַ֥ץ בְּשָׂר֖וֹ בַּמָּ֑יִם וְטָמֵ֖א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "ט",
            "txt": "וְאָסַ֣ף ׀ אִ֣ישׁ טָה֗וֹר אֵ֚ת אֵ֣פֶר הַפָּרָ֔ה וְהִנִּ֛יחַ מִח֥וּץ לַֽמַּחֲנֶ֖ה בְּמָק֣וֹם טָה֑וֹר וְ֠הָֽיְתָה לַֽעֲדַ֨ת בְּנֵֽי־יִשְׂרָאֵ֧ל לְמִשְׁמֶ֛רֶת לְמֵ֥י נִדָּ֖ה חַטָּ֥את הִֽוא׃"
          },
          {
            "num": "י",
            "txt": "וְ֠כִבֶּס הָֽאֹסֵ֨ף אֶת־אֵ֤פֶר הַפָּרָה֙ אֶת־בְּגָדָ֔יו וְטָמֵ֖א עַד־הָעָ֑רֶב וְֽהָיְתָ֞ה לִבְנֵ֣י יִשְׂרָאֵ֗ל וְלַגֵּ֛ר הַגָּ֥ר בְּתוֹכָ֖ם לְחֻקַּ֥ת עוֹלָֽם׃"
          },
          {
            "num": "יא",
            "txt": "הַנֹּגֵ֥עַ בְּמֵ֖ת לְכָל־נֶ֣פֶשׁ אָדָ֑ם וְטָמֵ֖א שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "יב",
            "txt": "ה֣וּא יִתְחַטָּא־ב֞וֹ בַּיּ֧וֹם הַשְּׁלִישִׁ֛י וּבַיּ֥וֹם הַשְּׁבִיעִ֖י יִטְהָ֑ר וְאִם־לֹ֨א יִתְחַטָּ֜א בַּיּ֧וֹם הַשְּׁלִישִׁ֛י וּבַיּ֥וֹם הַשְּׁבִיעִ֖י לֹ֥א יִטְהָֽר׃"
          },
          {
            "num": "יג",
            "txt": "כָּֽל־הַנֹּגֵ֡עַ בְּמֵ֣ת בְּנֶפֶשׁ֩ הָֽאָדָ֨ם אֲשֶׁר־יָמ֜וּת וְלֹ֣א יִתְחַטָּ֗א אֶת־מִשְׁכַּ֤ן יְהוָה֙ טִמֵּ֔א וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מִיִּשְׂרָאֵ֑ל כִּי֩ מֵ֨י נִדָּ֜ה לֹֽא־זֹרַ֤ק עָלָיו֙ טָמֵ֣א יִֽהְיֶ֔ה ע֖וֹד טֻמְאָת֥וֹ בֽוֹ׃"
          },
          {
            "num": "יד",
            "txt": "זֹ֚את הַתּוֹרָ֔ה אָדָ֖ם כִּֽי־יָמ֣וּת בְּאֹ֑הֶל כָּל־הַבָּ֤א אֶל־הָאֹ֨הֶל֙ וְכָל־אֲשֶׁ֣ר בָּאֹ֔הֶל יִטְמָ֖א שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "טו",
            "txt": "וְכֹל֙ כְּלִ֣י פָת֔וּחַ אֲשֶׁ֛ר אֵין־צָמִ֥יד פָּתִ֖יל עָלָ֑יו טָמֵ֖א הֽוּא׃"
          },
          {
            "num": "טז",
            "txt": "וְכֹ֨ל אֲשֶׁר־יִגַּ֜ע עַל־פְּנֵ֣י הַשָּׂדֶ֗ה בַּֽחֲלַל־חֶ֨רֶב֙ א֣וֹ בְמֵ֔ת אֽוֹ־בְעֶ֥צֶם אָדָ֖ם א֣וֹ בְקָ֑בֶר יִטְמָ֖א שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "יז",
            "txt": "וְלָֽקְחוּ֙ לַטָּמֵ֔א מֵֽעֲפַ֖ר שְׂרֵפַ֣ת הַֽחַטָּ֑את וְנָתַ֥ן עָלָ֛יו מַ֥יִם חַיִּ֖ים אֶל־כֶּֽלִי׃"
          },
          {
            "num": "יח",
            "txt": "וְלָקַ֨ח אֵז֜וֹב וְטָבַ֣ל בַּמַּיִם֮ אִ֣ישׁ טָהוֹר֒ וְהִזָּ֤ה עַל־הָאֹ֨הֶל֙ וְעַל־כָּל־הַכֵּלִ֔ים וְעַל־הַנְּפָשׁ֖וֹת אֲשֶׁ֣ר הָֽיוּ־שָׁ֑ם וְעַל־הַנֹּגֵ֗עַ בַּעֶ֨צֶם֙ א֣וֹ בֶֽחָלָ֔ל א֥וֹ בַמֵּ֖ת א֥וֹ בַקָּֽבֶר׃"
          },
          {
            "num": "יט",
            "txt": "וְהִזָּ֤ה הַטָּהֹר֙ עַל־הַטָּמֵ֔א בַּיּ֥וֹם הַשְּׁלִישִׁ֖י וּבַיּ֣וֹם הַשְּׁבִיעִ֑י וְחִטְּאוֹ֙ בַּיּ֣וֹם הַשְּׁבִיעִ֔י וְכִבֶּ֧ס בְּגָדָ֛יו וְרָחַ֥ץ בַּמַּ֖יִם וְטָהֵ֥ר בָּעָֽרֶב׃"
          },
          {
            "num": "כ",
            "txt": "וְאִ֤ישׁ אֲשֶׁר־יִטְמָא֙ וְלֹ֣א יִתְחַטָּ֔א וְנִכְרְתָ֛ה הַנֶּ֥פֶשׁ הַהִ֖וא מִתּ֣וֹךְ הַקָּהָ֑ל כִּי֩ אֶת־מִקְדַּ֨שׁ יְהוָ֜ה טִמֵּ֗א מֵ֥י נִדָּ֛ה לֹֽא־זֹרַ֥ק עָלָ֖יו טָמֵ֥א הֽוּא׃"
          },
          {
            "num": "כא",
            "txt": "וְהָֽיְתָ֥ה לָהֶ֖ם לְחֻקַּ֣ת עוֹלָ֑ם וּמַזֵּ֤ה מֵֽי־הַנִּדָּה֙ יְכַבֵּ֣ס בְּגָדָ֔יו וְהַנֹּגֵ֨עַ֙ בְּמֵ֣י הַנִּדָּ֔ה יִטְמָ֖א עַד־הָעָֽרֶב׃"
          },
          {
            "num": "כב",
            "txt": "וְכֹ֛ל אֲשֶׁר־יִגַּע־בּ֥וֹ הַטָּמֵ֖א יִטְמָ֑א וְהַנֶּ֥פֶשׁ הַנֹּגַ֖עַת תִּטְמָ֥א עַד־הָעָֽרֶב׃ {פ}"
          }
        ]
      },
      {
        "num": "כ",
        "verses": [
          {
            "num": "א",
            "txt": "וַיָּבֹ֣אוּ בְנֵֽי־יִ֠שְׂרָאֵל כָּל־הָ֨עֵדָ֤ה מִדְבַּר־צִן֙ בַּחֹ֣דֶשׁ הָֽרִאשׁ֔וֹן וַיֵּ֥שֶׁב הָעָ֖ם בְּקָדֵ֑שׁ וַתָּ֤מָת שָׁם֙ מִרְיָ֔ם וַתִּקָּבֵ֖ר שָֽׁם׃"
          },
          {
            "num": "ב",
            "txt": "וְלֹא־הָ֥יָה מַ֖יִם לָֽעֵדָ֑ה וַיִּקָּ֣הֲל֔וּ עַל־מֹשֶׁ֖ה וְעַֽל־אַהֲרֹֽן׃"
          },
          {
            "num": "ג",
            "txt": "וַיָּ֥רֶב הָעָ֖ם עִם־מֹשֶׁ֑ה וַיֹּֽאמְר֣וּ לֵאמֹ֔ר וְל֥וּ גָוַ֛עְנוּ בִּגְוַ֥ע אַחֵ֖ינוּ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וְלָמָ֤ה הֲבֵאתֶם֙ אֶת־קְהַ֣ל יְהוָ֔ה אֶל־הַמִּדְבָּ֖ר הַזֶּ֑ה לָמ֣וּת שָׁ֔ם אֲנַ֖חְנוּ וּבְעִירֵֽנוּ׃"
          },
          {
            "num": "ה",
            "txt": "וְלָמָ֤ה הֶֽעֱלִיתֻ֨נוּ֙ מִמִּצְרַ֔יִם לְהָבִ֣יא אֹתָ֔נוּ אֶל־הַמָּק֥וֹם הָרָ֖ע הַזֶּ֑ה לֹ֣א ׀ מְק֣וֹם זֶ֗רַע וּתְאֵנָ֤ה וְגֶ֨פֶן֙ וְרִמּ֔וֹן וּמַ֥יִם אַ֖יִן לִשְׁתּֽוֹת׃"
          },
          {
            "num": "ו",
            "txt": "וַיָּבֹא֩ מֹשֶׁ֨ה וְאַֽהֲרֹ֜ן מִפְּנֵ֣י הַקָּהָ֗ל אֶל־פֶּ֨תַח֙ אֹ֣הֶל מוֹעֵ֔ד וַֽיִּפְּל֖וּ עַל־פְּנֵיהֶ֑ם וַיֵּרָ֥א כְבוֹד־יְהוָ֖ה אֲלֵיהֶֽם׃ {פ}"
          },
          {
            "num": "ז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ח",
            "txt": "קַ֣ח אֶת־הַמַּטֶּ֗ה וְהַקְהֵ֤ל אֶת־הָֽעֵדָה֙ אַתָּה֙ וְאַֽהֲרֹ֣ן אָחִ֔יךָ וְדִבַּרְתֶּ֧ם אֶל־הַסֶּ֛לַע לְעֵֽינֵיהֶ֖ם וְנָתַ֣ן מֵימָ֑יו וְהֽוֹצֵאתָ֨ לָהֶ֥ם מַ֨יִם֙ מִן־הַסֶּ֔לַע וְהִשְׁקִיתָ֥ אֶת־הָֽעֵדָ֖ה וְאֶת־בְּעִירָֽם׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּקַּ֥ח מֹשֶׁ֛ה אֶת־הַמַּטֶּ֖ה מִלִּפְנֵ֣י יְהוָ֑ה כַּֽאֲשֶׁ֖ר צִוָּֽהוּ׃"
          },
          {
            "num": "י",
            "txt": "וַיַּקְהִ֜לוּ מֹשֶׁ֧ה וְאַֽהֲרֹ֛ן אֶת־הַקָּהָ֖ל אֶל־פְּנֵ֣י הַסָּ֑לַע וַיֹּ֣אמֶר לָהֶ֗ם שִׁמְעוּ־נָא֙ הַמֹּרִ֔ים הֲמִן־הַסֶּ֣לַע הַזֶּ֔ה נוֹצִ֥יא לָכֶ֖ם מָֽיִם׃"
          },
          {
            "num": "יא",
            "txt": "וַיָּ֨רֶם מֹשֶׁ֜ה אֶת־יָד֗וֹ וַיַּ֧ךְ אֶת־הַסֶּ֛לַע בְּמַטֵּ֖הוּ פַּֽעֲמָ֑יִם וַיֵּֽצְאוּ֙ מַ֣יִם רַבִּ֔ים וַתֵּ֥שְׁתְּ הָֽעֵדָ֖ה וּבְעִירָֽם׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֣אמֶר יְהוָה֮ אֶל־מֹשֶׁ֣ה וְאֶֽל־אַהֲרֹן֒ יַ֚עַן לֹֽא־הֶאֱמַנְתֶּ֣ם בִּ֔י לְהַ֨קְדִּישֵׁ֔נִי לְעֵינֵ֖י בְּנֵ֣י יִשְׂרָאֵ֑ל לָכֵ֗ן לֹ֤א תָבִ֨יאוּ֙ אֶת־הַקָּהָ֣ל הַזֶּ֔ה אֶל־הָאָ֖רֶץ אֲשֶׁר־נָתַ֥תִּי לָהֶֽם׃"
          },
          {
            "num": "יג",
            "txt": "הֵ֚מָּה מֵ֣י מְרִיבָ֔ה אֲשֶׁר־רָב֥וּ בְנֵֽי־יִשְׂרָאֵ֖ל אֶת־יְהוָ֑ה וַיִּקָּדֵ֖שׁ בָּֽם׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "וַיִּשְׁלַ֨ח מֹשֶׁ֧ה מַלְאָכִ֛ים מִקָּדֵ֖שׁ אֶל־מֶ֣לֶךְ אֱד֑וֹם כֹּ֤ה אָמַר֙ אָחִ֣יךָ יִשְׂרָאֵ֔ל אַתָּ֣ה יָדַ֔עְתָּ אֵ֥ת כָּל־הַתְּלָאָ֖ה אֲשֶׁ֥ר מְצָאָֽתְנוּ׃"
          },
          {
            "num": "טו",
            "txt": "וַיֵּֽרְד֤וּ אֲבֹתֵ֨ינוּ֙ מִצְרַ֔יְמָה וַנֵּ֥שֶׁב בְּמִצְרַ֖יִם יָמִ֣ים רַבִּ֑ים וַיָּרֵ֥עוּ לָ֛נוּ מִצְרַ֖יִם וְלַֽאֲבֹתֵֽינוּ׃"
          },
          {
            "num": "טז",
            "txt": "וַנִּצְעַ֤ק אֶל־יְהוָה֙ וַיִּשְׁמַ֣ע קֹלֵ֔נוּ וַיִּשְׁלַ֣ח מַלְאָ֔ךְ וַיֹּֽצִאֵ֖נוּ מִמִּצְרָ֑יִם וְהִנֵּה֙ אֲנַ֣חְנוּ בְקָדֵ֔שׁ עִ֖יר קְצֵ֥ה גְבוּלֶֽךָ׃"
          },
          {
            "num": "יז",
            "txt": "נַעְבְּרָה־נָּ֣א בְאַרְצֶ֗ךָ לֹ֤א נַֽעֲבֹר֙ בְּשָׂדֶ֣ה וּבְכֶ֔רֶם וְלֹ֥א נִשְׁתֶּ֖ה מֵ֣י בְאֵ֑ר דֶּ֧רֶךְ הַמֶּ֣לֶךְ נֵלֵ֗ךְ לֹ֤א נִטֶּה֙ יָמִ֣ין וּשְׂמֹ֔אול עַ֥ד אֲשֶֽׁר־נַעֲבֹ֖ר גְּבֻלֶֽךָ׃"
          },
          {
            "num": "יח",
            "txt": "וַיֹּ֤אמֶר אֵלָיו֙ אֱד֔וֹם לֹ֥א תַֽעֲבֹ֖ר בִּ֑י פֶּן־בַּחֶ֖רֶב אֵצֵ֥א לִקְרָאתֶֽךָ׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּֽאמְר֨וּ אֵלָ֥יו בְּנֵֽי־יִשְׂרָאֵל֮ בַּֽמְסִלָּ֣ה נַֽעֲלֶה֒ וְאִם־מֵימֶ֤יךָ נִשְׁתֶּה֙ אֲנִ֣י וּמִקְנַ֔י וְנָֽתַתִּ֖י מִכְרָ֑ם רַ֥ק אֵין־דָּבָ֖ר בְּרַגְלַ֥י אֶֽעֱבֹֽרָה׃"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֖אמֶר לֹ֣א תַֽעֲבֹ֑ר וַיֵּצֵ֤א אֱדוֹם֙ לִקְרָאת֔וֹ בְּעַ֥ם כָּבֵ֖ד וּבְיָ֥ד חֲזָקָֽה׃"
          },
          {
            "num": "כא",
            "txt": "וַיְמָאֵ֣ן ׀ אֱד֗וֹם נְתֹן֙ אֶת־יִשְׂרָאֵ֔ל עֲבֹ֖ר בִּגְבֻל֑וֹ וַיֵּ֥ט יִשְׂרָאֵ֖ל מֵֽעָלָֽיו׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "וַיִּסְע֖וּ מִקָּדֵ֑שׁ וַיָּבֹ֧אוּ בְנֵֽי־יִשְׂרָאֵ֛ל כָּל־הָֽעֵדָ֖ה הֹ֥ר הָהָֽר׃"
          },
          {
            "num": "כג",
            "txt": "וַיֹּ֧אמֶר יְהוָ֛ה אֶל־מֹשֶׁ֥ה וְאֶֽל־אַהֲרֹ֖ן בְּהֹ֣ר הָהָ֑ר עַל־גְּב֥וּל אֶֽרֶץ־אֱד֖וֹם לֵאמֹֽר׃"
          },
          {
            "num": "כד",
            "txt": "יֵֽאָסֵ֤ף אַֽהֲרֹן֙ אֶל־עַמָּ֔יו כִּ֣י לֹ֤א יָבֹא֙ אֶל־הָאָ֔רֶץ אֲשֶׁ֥ר נָתַ֖תִּי לִבְנֵ֣י יִשְׂרָאֵ֑ל עַ֛ל אֲשֶׁר־מְרִיתֶ֥ם אֶת־פִּ֖י לְמֵ֥י מְרִיבָֽה׃"
          },
          {
            "num": "כה",
            "txt": "קַ֚ח אֶֽת־אַהֲרֹ֔ן וְאֶת־אֶלְעָזָ֖ר בְּנ֑וֹ וְהַ֥עַל אֹתָ֖ם הֹ֥ר הָהָֽר׃"
          },
          {
            "num": "כו",
            "txt": "וְהַפְשֵׁ֤ט אֶֽת־אַהֲרֹן֙ אֶת־בְּגָדָ֔יו וְהִלְבַּשְׁתָּ֖ם אֶת־אֶלְעָזָ֣ר בְּנ֑וֹ וְאַֽהֲרֹ֥ן יֵֽאָסֵ֖ף וּמֵ֥ת שָֽׁם׃"
          },
          {
            "num": "כז",
            "txt": "וַיַּ֣עַשׂ מֹשֶׁ֔ה כַּֽאֲשֶׁ֖ר צִוָּ֣ה יְהוָ֑ה וַֽיַּעֲלוּ֙ אֶל־הֹ֣ר הָהָ֔ר לְעֵינֵ֖י כָּל־הָֽעֵדָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וַיַּפְשֵׁט֩ מֹשֶׁ֨ה אֶֽת־אַהֲרֹ֜ן אֶת־בְּגָדָ֗יו וַיַּלְבֵּ֤שׁ אֹתָם֙ אֶת־אֶלְעָזָ֣ר בְּנ֔וֹ וַיָּ֧מָת אַֽהֲרֹ֛ן שָׁ֖ם בְּרֹ֣אשׁ הָהָ֑ר וַיֵּ֧רֶד מֹשֶׁ֛ה וְאֶלְעָזָ֖ר מִן־הָהָֽר׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּרְאוּ֙ כָּל־הָ֣עֵדָ֔ה כִּ֥י גָוַ֖ע אַֽהֲרֹ֑ן וַיִּבְכּ֤וּ אֶֽת־אַהֲרֹן֙ שְׁלֹשִׁ֣ים י֔וֹם כֹּ֖ל בֵּ֥ית יִשְׂרָאֵֽל׃ {ס}"
          }
        ]
      },
      {
        "num": "כא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּשְׁמַ֞ע הַכְּנַֽעֲנִ֤י מֶֽלֶךְ־עֲרָד֙ יֹשֵׁ֣ב הַנֶּ֔גֶב כִּ֚י בָּ֣א יִשְׂרָאֵ֔ל דֶּ֖רֶךְ הָֽאֲתָרִ֑ים וַיִּלָּ֨חֶם֙ בְּיִשְׂרָאֵ֔ל וַיִּ֥שְׁבְּ ׀ מִמֶּ֖נּוּ שֶֽׁבִי׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּדַּ֨ר יִשְׂרָאֵ֥ל נֶ֛דֶר לַֽיהוָ֖ה וַיֹּאמַ֑ר אִם־נָתֹ֨ן תִּתֵּ֜ן אֶת־הָעָ֤ם הַזֶּה֙ בְּיָדִ֔י וְהַֽחֲרַמְתִּ֖י אֶת־עָֽרֵיהֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּשְׁמַ֨ע יְהוָ֜ה בְּק֣וֹל יִשְׂרָאֵ֗ל וַיִּתֵּן֙ אֶת־הַֽכְּנַעֲנִ֔י וַיַּֽחֲרֵ֥ם אֶתְהֶ֖ם וְאֶת־עָֽרֵיהֶ֑ם וַיִּקְרָ֥א שֵׁם־הַמָּק֖וֹם חָרְמָֽה׃ {פ}"
          },
          {
            "num": "ד",
            "txt": "וַיִּסְע֞וּ מֵהֹ֤ר הָהָר֙ דֶּ֣רֶךְ יַם־ס֔וּף לִסְבֹ֖ב אֶת־אֶ֣רֶץ אֱד֑וֹם וַתִּקְצַ֥ר נֶֽפֶשׁ־הָעָ֖ם בַּדָּֽרֶךְ׃"
          },
          {
            "num": "ה",
            "txt": "וַיְדַבֵּ֣ר הָעָ֗ם בֵּֽאלֹהִים֮ וּבְמֹשֶׁה֒ לָמָ֤ה הֶֽעֱלִיתֻ֨נוּ֙ מִמִּצְרַ֔יִם לָמ֖וּת בַּמִּדְבָּ֑ר כִּ֣י אֵ֥ין לֶ֨חֶם֙ וְאֵ֣ין מַ֔יִם וְנַפְשֵׁ֣נוּ קָ֔צָה בַּלֶּ֖חֶם הַקְּלֹקֵֽל׃"
          },
          {
            "num": "ו",
            "txt": "וַיְשַׁלַּ֨ח יְהוָ֜ה בָּעָ֗ם אֵ֚ת הַנְּחָשִׁ֣ים הַשְּׂרָפִ֔ים וַֽיְנַשְּׁכ֖וּ אֶת־הָעָ֑ם וַיָּ֥מָת עַם־רָ֖ב מִיִּשְׂרָאֵֽל׃"
          },
          {
            "num": "ז",
            "txt": "וַיָּבֹא֩ הָעָ֨ם אֶל־מֹשֶׁ֜ה וַיֹּֽאמְר֣וּ חָטָ֗אנוּ כִּֽי־דִבַּ֤רְנוּ בַֽיהוָה֙ וָבָ֔ךְ הִתְפַּלֵּל֙ אֶל־יְהוָ֔ה וְיָסֵ֥ר מֵֽעָלֵ֖ינוּ אֶת־הַנָּחָ֑שׁ וַיִּתְפַּלֵּ֥ל מֹשֶׁ֖ה בְּעַ֥ד הָעָֽם׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה עֲשֵׂ֤ה לְךָ֙ שָׂרָ֔ף וְשִׂ֥ים אֹת֖וֹ עַל־נֵ֑ס וְהָיָה֙ כָּל־הַנָּשׁ֔וּךְ וְרָאָ֥ה אֹת֖וֹ וָחָֽי׃"
          },
          {
            "num": "ט",
            "txt": "וַיַּ֤עַשׂ מֹשֶׁה֙ נְחַ֣שׁ נְחֹ֔שֶׁת וַיְשִׂמֵ֖הוּ עַל־הַנֵּ֑ס וְהָיָ֗ה אִם־נָשַׁ֤ךְ הַנָּחָשׁ֙ אֶת־אִ֔ישׁ וְהִבִּ֛יט אֶל־נְחַ֥שׁ הַנְּחֹ֖שֶׁת וָחָֽי׃"
          },
          {
            "num": "י",
            "txt": "וַיִּסְע֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל וַֽיַּחֲנ֖וּ בְּאֹבֹֽת׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּסְע֖וּ מֵֽאֹבֹ֑ת וַֽיַּחֲנ֞וּ בְּעִיֵּ֣י הָֽעֲבָרִ֗ים בַּמִּדְבָּר֙ אֲשֶׁר֙ עַל־פְּנֵ֣י מוֹאָ֔ב מִמִּזְרַ֖ח הַשָּֽׁמֶשׁ׃"
          },
          {
            "num": "יב",
            "txt": "מִשָּׁ֖ם נָסָ֑עוּ וַֽיַּחֲנ֖וּ בְּנַ֥חַל זָֽרֶד׃"
          },
          {
            "num": "יג",
            "txt": "מִשָּׁם֮ נָסָעוּ֒ וַֽיַּחֲנ֗וּ מֵעֵ֤בֶר אַרְנוֹן֙ אֲשֶׁ֣ר בַּמִּדְבָּ֔ר הַיֹּצֵ֖א מִגְּבֻ֣ל הָֽאֱמֹרִ֑י כִּ֤י אַרְנוֹן֙ גְּב֣וּל מוֹאָ֔ב בֵּ֥ין מוֹאָ֖ב וּבֵ֥ין הָֽאֱמֹרִֽי׃"
          },
          {
            "num": "יד",
            "txt": "עַל־כֵּן֙ יֵֽאָמַ֔ר בְּסֵ֖פֶר מִלְחֲמֹ֣ת יְהוָ֑ה אֶת־וָהֵ֣ב בְּסוּפָ֔ה וְאֶת־הַנְּחָלִ֖ים אַרְנֽוֹן׃"
          },
          {
            "num": "טו",
            "txt": "וְאֶ֨שֶׁד֙ הַנְּחָלִ֔ים אֲשֶׁ֥ר נָטָ֖ה לְשֶׁ֣בֶת עָ֑ר וְנִשְׁעַ֖ן לִגְב֥וּל מוֹאָֽב׃"
          },
          {
            "num": "טז",
            "txt": "וּמִשָּׁ֖ם בְּאֵ֑רָה הִ֣וא הַבְּאֵ֗ר אֲשֶׁ֨ר אָמַ֤ר יְהוָה֙ לְמֹשֶׁ֔ה אֱסֹף֙ אֶת־הָעָ֔ם וְאֶתְּנָ֥ה לָהֶ֖ם מָֽיִם׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "אָ֚ז יָשִׁ֣יר יִשְׂרָאֵ֔ל אֶת־הַשִּׁירָ֖ה הַזֹּ֑את עֲלִ֥י בְאֵ֖ר עֱנוּ־לָֽהּ׃"
          },
          {
            "num": "יח",
            "txt": "בְּאֵ֞ר חֲפָר֣וּהָ שָׂרִ֗ים כָּר֨וּהָ֙ נְדִיבֵ֣י הָעָ֔ם בִּמְחֹקֵ֖ק בְּמִשְׁעֲנֹתָ֑ם וּמִמִּדְבָּ֖ר מַתָּנָֽה׃"
          },
          {
            "num": "יט",
            "txt": "וּמִמַּתָּנָ֖ה נַֽחֲלִיאֵ֑ל וּמִנַּֽחֲלִיאֵ֖ל בָּמֽוֹת׃"
          },
          {
            "num": "כ",
            "txt": "וּמִבָּמ֗וֹת הַגַּיְא֙ אֲשֶׁר֙ בִּשְׂדֵ֣ה מוֹאָ֔ב רֹ֖אשׁ הַפִּסְגָּ֑ה וְנִשְׁקָ֖פָה עַל־פְּנֵ֥י הַיְשִׁימֹֽן׃ {פ}"
          },
          {
            "num": "כא",
            "txt": "וַיִּשְׁלַ֤ח יִשְׂרָאֵל֙ מַלְאָכִ֔ים אֶל־סִיחֹ֥ן מֶֽלֶךְ־הָאֱמֹרִ֖י לֵאמֹֽר׃"
          },
          {
            "num": "כב",
            "txt": "אֶעְבְּרָ֣ה בְאַרְצֶ֗ךָ לֹ֤א נִטֶּה֙ בְּשָׂדֶ֣ה וּבְכֶ֔רֶם לֹ֥א נִשְׁתֶּ֖ה מֵ֣י בְאֵ֑ר בְּדֶ֤רֶךְ הַמֶּ֨לֶךְ֙ נֵלֵ֔ךְ עַ֥ד אֲשֶֽׁר־נַעֲבֹ֖ר גְּבֻלֶֽךָ׃"
          },
          {
            "num": "כג",
            "txt": "וְלֹֽא־נָתַ֨ן סִיחֹ֣ן אֶת־יִשְׂרָאֵל֮ עֲבֹ֣ר בִּגְבֻלוֹ֒ וַיֶּֽאֱסֹ֨ף סִיחֹ֜ן אֶת־כָּל־עַמּ֗וֹ וַיֵּצֵ֞א לִקְרַ֤את יִשְׂרָאֵל֙ הַמִּדְבָּ֔רָה וַיָּבֹ֖א יָ֑הְצָה וַיִּלָּ֖חֶם בְּיִשְׂרָאֵֽל׃"
          },
          {
            "num": "כד",
            "txt": "וַיַּכֵּ֥הוּ יִשְׂרָאֵ֖ל לְפִי־חָ֑רֶב וַיִּירַ֨שׁ אֶת־אַרְצ֜וֹ מֵֽאַרְנֹ֗ן עַד־יַבֹּק֙ עַד־בְּנֵ֣י עַמּ֔וֹן כִּ֣י עַ֔ז גְּב֖וּל בְּנֵ֥י עַמּֽוֹן׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּקַּח֙ יִשְׂרָאֵ֔ל אֵ֥ת כָּל־הֶֽעָרִ֖ים הָאֵ֑לֶּה וַיֵּ֤שֶׁב יִשְׂרָאֵל֙ בְּכָל־עָרֵ֣י הָֽאֱמֹרִ֔י בְּחֶשְׁבּ֖וֹן וּבְכָל־בְּנֹתֶֽיהָ׃"
          },
          {
            "num": "כו",
            "txt": "כִּ֣י חֶשְׁבּ֔וֹן עִ֗יר סִיחֹ֛ן מֶ֥לֶךְ הָֽאֱמֹרִ֖י הִ֑וא וְה֣וּא נִלְחַ֗ם בְּמֶ֤לֶךְ מוֹאָב֙ הָֽרִאשׁ֔וֹן וַיִּקַּ֧ח אֶת־כָּל־אַרְצ֛וֹ מִיָּד֖וֹ עַד־אַרְנֹֽן׃"
          },
          {
            "num": "כז",
            "txt": "עַל־כֵּ֛ן יֹֽאמְר֥וּ הַמֹּֽשְׁלִ֖ים בֹּ֣אוּ חֶשְׁבּ֑וֹן תִּבָּנֶ֥ה וְתִכּוֹנֵ֖ן עִ֥יר סִיחֽוֹן׃"
          },
          {
            "num": "כח",
            "txt": "כִּי־אֵשׁ֙ יָֽצְאָ֣ה מֵֽחֶשְׁבּ֔וֹן לֶֽהָבָ֖ה מִקִּרְיַ֣ת סִיחֹ֑ן אָֽכְלָה֙ עָ֣ר מוֹאָ֔ב בַּֽעֲלֵ֖י בָּמ֥וֹת אַרְנֹֽן׃"
          },
          {
            "num": "כט",
            "txt": "אוֹי־לְךָ֣ מוֹאָ֔ב אָבַ֖דְתָּ עַם־כְּמ֑וֹשׁ נָתַ֨ן בָּנָ֤יו פְּלֵיטִם֙ וּבְנֹתָ֣יו בַּשְּׁבִ֔ית לְמֶ֥לֶךְ אֱמֹרִ֖י סִיחֽוֹן׃"
          },
          {
            "num": "ל",
            "txt": "וַנִּירָ֛ם אָבַ֥ד חֶשְׁבּ֖וֹן עַד־דִּיבֹ֑ן וַנַּשִּׁ֣ים עַד־נֹ֔פַח אֲשֶׁ֖רׄ עַד־מֵֽידְבָֽא׃"
          },
          {
            "num": "לא",
            "txt": "וַיֵּ֨שֶׁב֙ יִשְׂרָאֵ֔ל בְּאֶ֖רֶץ הָֽאֱמֹרִֽי׃"
          },
          {
            "num": "לב",
            "txt": "וַיִּשְׁלַ֤ח מֹשֶׁה֙ לְרַגֵּ֣ל אֶת־יַעְזֵ֔ר וַֽיִּלְכְּד֖וּ בְּנֹתֶ֑יהָ ויירש (וַיּ֖וֹרֶשׁ) אֶת־הָֽאֱמֹרִ֥י אֲשֶׁר־שָֽׁם׃"
          },
          {
            "num": "לג",
            "txt": "וַיִּפְנוּ֙ וַֽיַּעֲל֔וּ דֶּ֖רֶךְ הַבָּשָׁ֑ן וַיֵּצֵ֣א עוֹג֩ מֶֽלֶךְ־הַבָּשָׁ֨ן לִקְרָאתָ֜ם ה֧וּא וְכָל־עַמּ֛וֹ לַמִּלְחָמָ֖ה אֶדְרֶֽעִי׃"
          },
          {
            "num": "לד",
            "txt": "וַיֹּ֨אמֶר יְהוָ֤ה אֶל־מֹשֶׁה֙ אַל־תִּירָ֣א אֹת֔וֹ כִּ֣י בְיָֽדְךָ֞ נָתַ֧תִּי אֹת֛וֹ וְאֶת־כָּל־עַמּ֖וֹ וְאֶת־אַרְצ֑וֹ וְעָשִׂ֣יתָ לּ֔וֹ כַּֽאֲשֶׁ֣ר עָשִׂ֗יתָ לְסִיחֹן֙ מֶ֣לֶךְ הָֽאֱמֹרִ֔י אֲשֶׁ֥ר יוֹשֵׁ֖ב בְּחֶשְׁבּֽוֹן׃"
          },
          {
            "num": "לה",
            "txt": "וַיַּכּ֨וּ אֹת֤וֹ וְאֶת־בָּנָיו֙ וְאֶת־כָּל־עַמּ֔וֹ עַד־בִּלְתִּ֥י הִשְׁאִֽיר־ל֖וֹ שָׂרִ֑יד וַיִּֽירְשׁ֖וּ אֶת־אַרְצֽוֹ׃"
          }
        ]
      },
      {
        "num": "כב",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּסְע֖וּ בְּנֵ֣י יִשְׂרָאֵ֑ל וַֽיַּחֲנוּ֙ בְּעַֽרְב֣וֹת מוֹאָ֔ב מֵעֵ֖בֶר לְיַרְדֵּ֥ן יְרֵחֽוֹ׃ {ס}"
          },
          {
            "num": "ב",
            "txt": "וַיַּ֥רְא בָּלָ֖ק בֶּן־צִפּ֑וֹר אֵ֛ת כָּל־אֲשֶׁר־עָשָׂ֥ה יִשְׂרָאֵ֖ל לָֽאֱמֹרִֽי׃"
          },
          {
            "num": "ג",
            "txt": "וַיָּ֨גָר מוֹאָ֜ב מִפְּנֵ֥י הָעָ֛ם מְאֹ֖ד כִּ֣י רַב־ה֑וּא וַיָּ֣קָץ מוֹאָ֔ב מִפְּנֵ֖י בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֨אמֶר מוֹאָ֜ב אֶל־זִקְנֵ֣י מִדְיָ֗ן עַתָּ֞ה יְלַֽחֲכ֤וּ הַקָּהָל֙ אֶת־כָּל־סְבִ֣יבֹתֵ֔ינוּ כִּלְחֹ֣ךְ הַשּׁ֔וֹר אֵ֖ת יֶ֣רֶק הַשָּׂדֶ֑ה וּבָלָ֧ק בֶּן־צִפּ֛וֹר מֶ֥לֶךְ לְמוֹאָ֖ב בָּעֵ֥ת הַהִֽוא׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּשְׁלַ֨ח מַלְאָכִ֜ים אֶל־בִּלְעָ֣ם בֶּן־בְּעֹ֗ר פְּ֠תוֹרָה אֲשֶׁ֧ר עַל־הַנָּהָ֛ר אֶ֥רֶץ בְּנֵֽי־עַמּ֖וֹ לִקְרֹא־ל֑וֹ לֵאמֹ֗ר הִ֠נֵּה עַ֣ם יָצָ֤א מִמִּצְרַ֨יִם֙ הִנֵּ֤ה כִסָּה֙ אֶת־עֵ֣ין הָאָ֔רֶץ וְה֥וּא יֹשֵׁ֖ב מִמֻּלִֽי׃"
          },
          {
            "num": "ו",
            "txt": "וְעַתָּה֩ לְכָה־נָּ֨א אָֽרָה־לִּ֜י אֶת־הָעָ֣ם הַזֶּ֗ה כִּֽי־עָצ֥וּם הוּא֙ מִמֶּ֔נִּי אוּלַ֤י אוּכַל֙ נַכֶּה־בּ֔וֹ וַאֲגָֽרְשֶׁ֖נּוּ מִן־הָאָ֑רֶץ כִּ֣י יָדַ֗עְתִּי אֵ֤ת אֲשֶׁר־תְּבָרֵךְ֙ מְבֹרָ֔ךְ וַֽאֲשֶׁ֥ר תָּאֹ֖ר יוּאָֽר׃"
          },
          {
            "num": "ז",
            "txt": "וַיֵּ֨לְכ֜וּ זִקְנֵ֤י מוֹאָב֙ וְזִקְנֵ֣י מִדְיָ֔ן וּקְסָמִ֖ים בְּיָדָ֑ם וַיָּבֹ֨אוּ֙ אֶל־בִּלְעָ֔ם וַיְדַבְּר֥וּ אֵלָ֖יו דִּבְרֵ֥י בָלָֽק׃"
          },
          {
            "num": "ח",
            "txt": "וַיֹּ֣אמֶר אֲלֵיהֶ֗ם לִ֤ינוּ פֹה֙ הַלַּ֔יְלָה וַהֲשִֽׁבֹתִ֤י אֶתְכֶם֙ דָּבָ֔ר כַּֽאֲשֶׁ֛ר יְדַבֵּ֥ר יְהוָ֖ה אֵלָ֑י וַיֵּֽשְׁב֥וּ שָׂרֵֽי־מוֹאָ֖ב עִם־בִּלְעָֽם׃"
          },
          {
            "num": "ט",
            "txt": "וַיָּבֹ֥א אֱלֹהִ֖ים אֶל־בִּלְעָ֑ם וַיֹּ֕אמֶר מִ֛י הָֽאֲנָשִׁ֥ים הָאֵ֖לֶּה עִמָּֽךְ׃"
          },
          {
            "num": "י",
            "txt": "וַיֹּ֥אמֶר בִּלְעָ֖ם אֶל־הָֽאֱלֹהִ֑ים בָּלָ֧ק בֶּן־צִפֹּ֛ר מֶ֥לֶךְ מוֹאָ֖ב שָׁלַ֥ח אֵלָֽי׃"
          },
          {
            "num": "יא",
            "txt": "הִנֵּ֤ה הָעָם֙ הַיֹּצֵ֣א מִמִּצְרַ֔יִם וַיְכַ֖ס אֶת־עֵ֣ין הָאָ֑רֶץ עַתָּ֗ה לְכָ֤ה קָֽבָה־לִּי֙ אֹת֔וֹ אוּלַ֥י אוּכַ֛ל לְהִלָּ֥חֶם בּ֖וֹ וְגֵֽרַשְׁתִּֽיו׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֤אמֶר אֱלֹהִים֙ אֶל־בִּלְעָ֔ם לֹ֥א תֵלֵ֖ךְ עִמָּהֶ֑ם לֹ֤א תָאֹר֙ אֶת־הָעָ֔ם כִּ֥י בָר֖וּךְ הֽוּא׃"
          },
          {
            "num": "יג",
            "txt": "וַיָּ֤קָם בִּלְעָם֙ בַּבֹּ֔קֶר וַיֹּ֨אמֶר֙ אֶל־שָׂרֵ֣י בָלָ֔ק לְכ֖וּ אֶֽל־אַרְצְכֶ֑ם כִּ֚י מֵאֵ֣ן יְהוָ֔ה לְתִתִּ֖י לַֽהֲלֹ֥ךְ עִמָּכֶֽם׃"
          },
          {
            "num": "יד",
            "txt": "וַיָּק֨וּמוּ֙ שָׂרֵ֣י מוֹאָ֔ב וַיָּבֹ֖אוּ אֶל־בָּלָ֑ק וַיֹּ֣אמְר֔וּ מֵאֵ֥ן בִּלְעָ֖ם הֲלֹ֥ךְ עִמָּֽנוּ׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֥סֶף ע֖וֹד בָּלָ֑ק שְׁלֹ֣חַ שָׂרִ֔ים רַבִּ֥ים וְנִכְבָּדִ֖ים מֵאֵֽלֶּה׃"
          },
          {
            "num": "טז",
            "txt": "וַיָּבֹ֖אוּ אֶל־בִּלְעָ֑ם וַיֹּ֣אמְרוּ ל֗וֹ כֹּ֤ה אָמַר֙ בָּלָ֣ק בֶּן־צִפּ֔וֹר אַל־נָ֥א תִמָּנַ֖ע מֵֽהֲלֹ֥ךְ אֵלָֽי׃"
          },
          {
            "num": "יז",
            "txt": "כִּֽי־כַבֵּ֤ד אֲכַבֶּדְךָ֙ מְאֹ֔ד וְכֹ֛ל אֲשֶׁר־תֹּאמַ֥ר אֵלַ֖י אֶֽעֱשֶׂ֑ה וּלְכָה־נָּא֙ קָֽבָה־לִּ֔י אֵ֖ת הָעָ֥ם הַזֶּֽה׃"
          },
          {
            "num": "יח",
            "txt": "וַיַּ֣עַן בִּלְעָ֗ם וַיֹּ֨אמֶר֙ אֶל־עַבְדֵ֣י בָלָ֔ק אִם־יִתֶּן־לִ֥י בָלָ֛ק מְלֹ֥א בֵית֖וֹ כֶּ֣סֶף וְזָהָ֑ב לֹ֣א אוּכַ֗ל לַֽעֲבֹר֙ אֶת־פִּי֙ יְהוָ֣ה אֱלֹהָ֔י לַֽעֲשׂ֥וֹת קְטַנָּ֖ה א֥וֹ גְדוֹלָֽה׃"
          },
          {
            "num": "יט",
            "txt": "וְעַתָּ֗ה שְׁב֨וּ נָ֥א בָזֶ֛ה גַּם־אַתֶּ֖ם הַלָּ֑יְלָה וְאֵ֣דְעָ֔ה מַה־יֹּסֵ֥ף יְהוָ֖ה דַּבֵּ֥ר עִמִּֽי׃"
          },
          {
            "num": "כ",
            "txt": "וַיָּבֹ֨א אֱלֹהִ֥ים ׀ אֶל־בִּלְעָם֮ לַיְלָה֒ וַיֹּ֣אמֶר ל֗וֹ אִם־לִקְרֹ֤א לְךָ֙ בָּ֣אוּ הָֽאֲנָשִׁ֔ים ק֖וּם לֵ֣ךְ אִתָּ֑ם וְאַ֗ךְ אֶת־הַדָּבָ֛ר אֲשֶׁר־אֲדַבֵּ֥ר אֵלֶ֖יךָ אֹת֥וֹ תַֽעֲשֶֽׂה׃"
          },
          {
            "num": "כא",
            "txt": "וַיָּ֤קָם בִּלְעָם֙ בַּבֹּ֔קֶר וַֽיַּחֲבֹ֖שׁ אֶת־אֲתֹנ֑וֹ וַיֵּ֖לֶךְ עִם־שָׂרֵ֥י מוֹאָֽב׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּֽחַר־אַ֣ף אֱלֹהִים֮ כִּֽי־הוֹלֵ֣ךְ הוּא֒ וַיִּתְיַצֵּ֞ב מַלְאַ֧ךְ יְהוָ֛ה בַּדֶּ֖רֶךְ לְשָׂטָ֣ן ל֑וֹ וְהוּא֙ רֹכֵ֣ב עַל־אֲתֹנ֔וֹ וּשְׁנֵ֥י נְעָרָ֖יו עִמּֽוֹ׃"
          },
          {
            "num": "כג",
            "txt": "וַתֵּ֣רֶא הָֽאָתוֹן֩ אֶת־מַלְאַ֨ךְ יְהוָ֜ה נִצָּ֣ב בַּדֶּ֗רֶךְ וְחַרְבּ֤וֹ שְׁלוּפָה֙ בְּיָד֔וֹ וַתֵּ֤ט הָֽאָתוֹן֙ מִן־הַדֶּ֔רֶךְ וַתֵּ֖לֶךְ בַּשָּׂדֶ֑ה וַיַּ֤ךְ בִּלְעָם֙ אֶת־הָ֣אָת֔וֹן לְהַטֹּתָ֖הּ הַדָּֽרֶךְ׃"
          },
          {
            "num": "כד",
            "txt": "וַֽיַּעֲמֹד֙ מַלְאַ֣ךְ יְהוָ֔ה בְּמִשְׁע֖וֹל הַכְּרָמִ֑ים גָּדֵ֥ר מִזֶּ֖ה וְגָדֵ֥ר מִזֶּֽה׃"
          },
          {
            "num": "כה",
            "txt": "וַתֵּ֨רֶא הָֽאָת֜וֹן אֶת־מַלְאַ֣ךְ יְהוָ֗ה וַתִּלָּחֵץ֙ אֶל־הַקִּ֔יר וַתִּלְחַ֛ץ אֶת־רֶ֥גֶל בִּלְעָ֖ם אֶל־הַקִּ֑יר וַיֹּ֖סֶף לְהַכֹּתָֽהּ׃"
          },
          {
            "num": "כו",
            "txt": "וַיּ֥וֹסֶף מַלְאַךְ־יְהוָ֖ה עֲב֑וֹר וַֽיַּעֲמֹד֙ בְּמָק֣וֹם צָ֔ר אֲשֶׁ֛ר אֵֽין־דֶּ֥רֶךְ לִנְט֖וֹת יָמִ֥ין וּשְׂמֹֽאול׃"
          },
          {
            "num": "כז",
            "txt": "וַתֵּ֤רֶא הָֽאָתוֹן֙ אֶת־מַלְאַ֣ךְ יְהוָ֔ה וַתִּרְבַּ֖ץ תַּ֣חַת בִּלְעָ֑ם וַיִּֽחַר־אַ֣ף בִּלְעָ֔ם וַיַּ֥ךְ אֶת־הָֽאָת֖וֹן בַּמַּקֵּֽל׃"
          },
          {
            "num": "כח",
            "txt": "וַיִּפְתַּ֥ח יְהוָ֖ה אֶת־פִּ֣י הָֽאָת֑וֹן וַתֹּ֤אמֶר לְבִלְעָם֙ מֶֽה־עָשִׂ֣יתִֽי לְךָ֔ כִּ֣י הִכִּיתַ֔נִי זֶ֖ה שָׁלֹ֥שׁ רְגָלִֽים׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֤אמֶר בִּלְעָם֙ לָֽאָת֔וֹן כִּ֥י הִתְעַלַּ֖לְתְּ בִּ֑י ל֤וּ יֶשׁ־חֶ֨רֶב֙ בְּיָדִ֔י כִּ֥י עַתָּ֖ה הֲרַגְתִּֽיךְ׃"
          },
          {
            "num": "ל",
            "txt": "וַתֹּ֨אמֶר הָֽאָת֜וֹן אֶל־בִּלְעָ֗ם הֲלוֹא֩ אָֽנֹכִ֨י אֲתֹֽנְךָ֜ אֲשֶׁר־רָכַ֣בְתָּ עָלַ֗י מֵעֽוֹדְךָ֙ עַד־הַיּ֣וֹם הַזֶּ֔ה הַֽהַסְכֵּ֣ן הִסְכַּ֔נְתִּי לַֽעֲשׂ֥וֹת לְךָ֖ כֹּ֑ה וַיֹּ֖אמֶר לֹֽא׃"
          },
          {
            "num": "לא",
            "txt": "וַיְגַ֣ל יְהוָה֮ אֶת־עֵינֵ֣י בִלְעָם֒ וַיַּ֞רְא אֶת־מַלְאַ֤ךְ יְהוָה֙ נִצָּ֣ב בַּדֶּ֔רֶךְ וְחַרְבּ֥וֹ שְׁלֻפָ֖ה בְּיָד֑וֹ וַיִּקֹּ֥ד וַיִּשְׁתַּ֖חוּ לְאַפָּֽיו׃"
          },
          {
            "num": "לב",
            "txt": "וַיֹּ֤אמֶר אֵלָיו֙ מַלְאַ֣ךְ יְהוָ֔ה עַל־מָ֗ה הִכִּ֨יתָ֙ אֶת־אֲתֹ֣נְךָ֔ זֶ֖ה שָׁל֣וֹשׁ רְגָלִ֑ים הִנֵּ֤ה אָֽנֹכִי֙ יָצָ֣אתִי לְשָׂטָ֔ן כִּֽי־יָרַ֥ט הַדֶּ֖רֶךְ לְנֶגְדִּֽי׃"
          },
          {
            "num": "לג",
            "txt": "וַתִּרְאַ֨נִי֙ הָֽאָת֔וֹן וַתֵּ֣ט לְפָנַ֔י זֶ֖ה שָׁלֹ֣שׁ רְגָלִ֑ים אוּלַי֙ נָֽטְתָ֣ה מִפָּנַ֔י כִּ֥י עַתָּ֛ה גַּם־אֹֽתְכָ֥ה הָרַ֖גְתִּי וְאוֹתָ֥הּ הֶֽחֱיֵֽיתִי׃"
          },
          {
            "num": "לד",
            "txt": "וַיֹּ֨אמֶר בִּלְעָ֜ם אֶל־מַלְאַ֤ךְ יְהוָה֙ חָטָ֔אתִי כִּ֚י לֹ֣א יָדַ֔עְתִּי כִּ֥י אַתָּ֛ה נִצָּ֥ב לִקְרָאתִ֖י בַּדָּ֑רֶךְ וְעַתָּ֛ה אִם־רַ֥ע בְּעֵינֶ֖יךָ אָשׁ֥וּבָה לִּֽי׃"
          },
          {
            "num": "לה",
            "txt": "וַיֹּאמֶר֩ מַלְאַ֨ךְ יְהוָ֜ה אֶל־בִּלְעָ֗ם לֵ֚ךְ עִם־הָ֣אֲנָשִׁ֔ים וְאֶ֗פֶס אֶת־הַדָּבָ֛ר אֲשֶׁר־אֲדַבֵּ֥ר אֵלֶ֖יךָ אֹת֣וֹ תְדַבֵּ֑ר וַיֵּ֥לֶךְ בִּלְעָ֖ם עִם־שָׂרֵ֥י בָלָֽק׃"
          },
          {
            "num": "לו",
            "txt": "וַיִּשְׁמַ֥ע בָּלָ֖ק כִּ֣י בָ֣א בִלְעָ֑ם וַיֵּצֵ֨א לִקְרָאת֜וֹ אֶל־עִ֣יר מוֹאָ֗ב אֲשֶׁר֙ עַל־גְּב֣וּל אַרְנֹ֔ן אֲשֶׁ֖ר בִּקְצֵ֥ה הַגְּבֽוּל׃"
          },
          {
            "num": "לז",
            "txt": "וַיֹּ֨אמֶר בָּלָ֜ק אֶל־בִּלְעָ֗ם הֲלֹא֩ שָׁלֹ֨חַ שָׁלַ֤חְתִּי אֵלֶ֨יךָ֙ לִקְרֹא־לָ֔ךְ לָ֥מָּה לֹֽא־הָלַ֖כְתָּ אֵלָ֑י הַֽאֻמְנָ֔ם לֹ֥א אוּכַ֖ל כַּבְּדֶֽךָ׃"
          },
          {
            "num": "לח",
            "txt": "וַיֹּ֨אמֶר בִּלְעָ֜ם אֶל־בָּלָ֗ק הִֽנֵּה־בָ֨אתִי֙ אֵלֶ֔יךָ עַתָּ֕ה הֲיָכֹ֥ל אוּכַ֖ל דַּבֵּ֣ר מְא֑וּמָה הַדָּבָ֗ר אֲשֶׁ֨ר יָשִׂ֧ים אֱלֹהִ֛ים בְּפִ֖י אֹת֥וֹ אֲדַבֵּֽר׃"
          },
          {
            "num": "לט",
            "txt": "וַיֵּ֥לֶךְ בִּלְעָ֖ם עִם־בָּלָ֑ק וַיָּבֹ֖אוּ קִרְיַ֥ת חֻצֽוֹת׃"
          },
          {
            "num": "מ",
            "txt": "וַיִּזְבַּ֥ח בָּלָ֖ק בָּקָ֣ר וָצֹ֑אן וַיְשַׁלַּ֣ח לְבִלְעָ֔ם וְלַשָּׂרִ֖ים אֲשֶׁ֥ר אִתּֽוֹ׃"
          },
          {
            "num": "מא",
            "txt": "וַיְהִ֣י בַבֹּ֔קֶר וַיִּקַּ֤ח בָּלָק֙ אֶת־בִּלְעָ֔ם וַֽיַּעֲלֵ֖הוּ בָּמ֣וֹת בָּ֑עַל וַיַּ֥רְא מִשָּׁ֖ם קְצֵ֥ה הָעָֽם׃"
          }
        ]
      },
      {
        "num": "כג",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֤אמֶר בִּלְעָם֙ אֶל־בָּלָ֔ק בְּנֵה־לִ֥י בָזֶ֖ה שִׁבְעָ֣ה מִזְבְּחֹ֑ת וְהָכֵ֥ן לִי֙ בָּזֶ֔ה שִׁבְעָ֥ה פָרִ֖ים וְשִׁבְעָ֥ה אֵילִֽים׃"
          },
          {
            "num": "ב",
            "txt": "וַיַּ֣עַשׂ בָּלָ֔ק כַּֽאֲשֶׁ֖ר דִּבֶּ֣ר בִּלְעָ֑ם וַיַּ֨עַל בָּלָ֧ק וּבִלְעָ֛ם פָּ֥ר וָאַ֖יִל בַּמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ג",
            "txt": "וַיֹּ֨אמֶר בִּלְעָ֜ם לְבָלָ֗ק הִתְיַצֵּב֮ עַל־עֹֽלָתֶךָ֒ וְאֵֽלְכָ֗ה אוּלַ֞י יִקָּרֵ֤ה יְהוָה֙ לִקְרָאתִ֔י וּדְבַ֥ר מַה־יַּרְאֵ֖נִי וְהִגַּ֣דְתִּי לָ֑ךְ וַיֵּ֖לֶךְ שֶֽׁפִי׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּקָּ֥ר אֱלֹהִ֖ים אֶל־בִּלְעָ֑ם וַיֹּ֣אמֶר אֵלָ֗יו אֶת־שִׁבְעַ֤ת הַֽמִּזְבְּחֹת֙ עָרַ֔כְתִּי וָאַ֛עַל פָּ֥ר וָאַ֖יִל בַּמִּזְבֵּֽחַ׃"
          },
          {
            "num": "ה",
            "txt": "וַיָּ֧שֶׂם יְהוָ֛ה דָּבָ֖ר בְּפִ֣י בִלְעָ֑ם וַיֹּ֛אמֶר שׁ֥וּב אֶל־בָּלָ֖ק וְכֹ֥ה תְדַבֵּֽר׃"
          },
          {
            "num": "ו",
            "txt": "וַיָּ֣שָׁב אֵלָ֔יו וְהִנֵּ֥ה נִצָּ֖ב עַל־עֹֽלָת֑וֹ ה֖וּא וְכָל־שָׂרֵ֥י מוֹאָֽב׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּשָּׂ֥א מְשָׁל֖וֹ וַיֹּאמַ֑ר מִן־אֲ֠רָם יַנְחֵ֨נִי בָלָ֤ק מֶֽלֶךְ־מוֹאָב֙ מֵֽהַרְרֵי־קֶ֔דֶם לְכָה֙ אָֽרָה־לִּ֣י יַֽעֲקֹ֔ב וּלְכָ֖ה זֹֽעֲמָ֥ה יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ח",
            "txt": "מָ֣ה אֶקֹּ֔ב לֹ֥א קַבֹּ֖ה אֵ֑ל וּמָ֣ה אֶזְעֹ֔ם לֹ֥א זָעַ֖ם יְהוָֽה׃"
          },
          {
            "num": "ט",
            "txt": "כִּֽי־מֵרֹ֤אשׁ צֻרִים֙ אֶרְאֶ֔נּוּ וּמִגְּבָע֖וֹת אֲשׁוּרֶ֑נּוּ הֶן־עָם֙ לְבָדָ֣ד יִשְׁכֹּ֔ן וּבַגּוֹיִ֖ם לֹ֥א יִתְחַשָּֽׁב׃"
          },
          {
            "num": "י",
            "txt": "מִ֤י מָנָה֙ עֲפַ֣ר יַֽעֲקֹ֔ב וּמִסְפָּ֖ר אֶת־רֹ֣בַע יִשְׂרָאֵ֑ל תָּמֹ֤ת נַפְשִׁי֙ מ֣וֹת יְשָׁרִ֔ים וּתְהִ֥י אַֽחֲרִיתִ֖י כָּמֹֽהוּ׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֤אמֶר בָּלָק֙ אֶל־בִּלְעָ֔ם מֶ֥ה עָשִׂ֖יתָ לִ֑י לָקֹ֤ב אֹֽיְבַי֙ לְקַחְתִּ֔יךָ וְהִנֵּ֖ה בֵּרַ֥כְתָּ בָרֵֽךְ׃"
          },
          {
            "num": "יב",
            "txt": "וַיַּ֖עַן וַיֹּאמַ֑ר הֲלֹ֗א אֵת֩ אֲשֶׁ֨ר יָשִׂ֤ים יְהוָה֙ בְּפִ֔י אֹת֥וֹ אֶשְׁמֹ֖ר לְדַבֵּֽר׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֨אמֶר אֵלָ֜יו בָּלָ֗ק לְךָ־נָּ֨א אִתִּ֜י אֶל־מָק֤וֹם אַחֵר֙ אֲשֶׁ֣ר תִּרְאֶ֣נּוּ מִשָּׁ֔ם אֶ֚פֶס קָצֵ֣הוּ תִרְאֶ֔ה וְכֻלּ֖וֹ לֹ֣א תִרְאֶ֑ה וְקָבְנוֹ־לִ֖י מִשָּֽׁם׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּקָּחֵ֨הוּ֙ שְׂדֵ֣ה צֹפִ֔ים אֶל־רֹ֖אשׁ הַפִּסְגָּ֑ה וַיִּ֨בֶן֙ שִׁבְעָ֣ה מִזְבְּחֹ֔ת וַיַּ֛עַל פָּ֥ר וָאַ֖יִל בַּמִּזְבֵּֽחַ׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֨אמֶר֙ אֶל־בָּלָ֔ק הִתְיַצֵּ֥ב כֹּ֖ה עַל־עֹֽלָתֶ֑ךָ וְאָֽנֹכִ֖י אִקָּ֥רֶה כֹּֽה׃"
          },
          {
            "num": "טז",
            "txt": "וַיִּקָּ֤ר יְהוָה֙ אֶל־בִּלְעָ֔ם וַיָּ֥שֶׂם דָּבָ֖ר בְּפִ֑יו וַיֹּ֛אמֶר שׁ֥וּב אֶל־בָּלָ֖ק וְכֹ֥ה תְדַבֵּֽר׃"
          },
          {
            "num": "יז",
            "txt": "וַיָּבֹ֣א אֵלָ֗יו וְהִנּ֤וֹ נִצָּב֙ עַל־עֹ֣לָת֔וֹ וְשָׂרֵ֥י מוֹאָ֖ב אִתּ֑וֹ וַיֹּ֤אמֶר לוֹ֙ בָּלָ֔ק מַה־דִּבֶּ֖ר יְהוָֽה׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּשָּׂ֥א מְשָׁל֖וֹ וַיֹּאמַ֑ר ק֤וּם בָּלָק֙ וּֽשְׁמָ֔ע הַֽאֲזִ֥ינָה עָדַ֖י בְּנ֥וֹ צִפֹּֽר׃"
          },
          {
            "num": "יט",
            "txt": "לֹ֣א אִ֥ישׁ אֵל֙ וִֽיכַזֵּ֔ב וּבֶן־אָדָ֖ם וְיִתְנֶחָ֑ם הַה֤וּא אָמַר֙ וְלֹ֣א יַֽעֲשֶׂ֔ה וְדִבֶּ֖ר וְלֹ֥א יְקִימֶֽנָּה׃"
          },
          {
            "num": "כ",
            "txt": "הִנֵּ֥ה בָרֵ֖ךְ לָקָ֑חְתִּי וּבֵרֵ֖ךְ וְלֹ֥א אֲשִׁיבֶֽנָּה׃"
          },
          {
            "num": "כא",
            "txt": "לֹֽא־הִבִּ֥יט אָ֨וֶן֙ בְּיַֽעֲקֹ֔ב וְלֹֽא־רָאָ֥ה עָמָ֖ל בְּיִשְׂרָאֵ֑ל יְהוָ֤ה אֱלֹהָיו֙ עִמּ֔וֹ וּתְרוּעַ֥ת מֶ֖לֶךְ בּֽוֹ׃"
          },
          {
            "num": "כב",
            "txt": "אֵ֖ל מֽוֹצִיאָ֣ם מִמִּצְרָ֑יִם כְּתֽוֹעֲפֹ֥ת רְאֵ֖ם לֽוֹ׃"
          },
          {
            "num": "כג",
            "txt": "כִּ֤י לֹא־נַ֨חַשׁ֙ בְּיַֽעֲקֹ֔ב וְלֹא־קֶ֖סֶם בְּיִשְׂרָאֵ֑ל כָּעֵ֗ת יֵֽאָמֵ֤ר לְיַֽעֲקֹב֙ וּלְיִשְׂרָאֵ֔ל מַה־פָּ֖עַל אֵֽל׃"
          },
          {
            "num": "כד",
            "txt": "הֶן־עָם֙ כְּלָבִ֣יא יָק֔וּם וְכַֽאֲרִ֖י יִתְנַשָּׂ֑א לֹ֤א יִשְׁכַּב֙ עַד־יֹ֣אכַל טֶ֔רֶף וְדַם־חֲלָלִ֖ים יִשְׁתֶּֽה׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֤אמֶר בָּלָק֙ אֶל־בִּלְעָ֔ם גַּם־קֹ֖ב לֹ֣א תִקֳּבֶ֑נּוּ גַּם־בָּרֵ֖ךְ לֹ֥א תְבָֽרְכֶֽנּוּ׃"
          },
          {
            "num": "כו",
            "txt": "וַיַּ֣עַן בִּלְעָ֔ם וַיֹּ֖אמֶר אֶל־בָּלָ֑ק הֲלֹ֗א דִּבַּ֤רְתִּי אֵלֶ֨יךָ֙ לֵאמֹ֔ר כֹּ֛ל אֲשֶׁר־יְדַבֵּ֥ר יְהוָ֖ה אֹת֥וֹ אֶֽעֱשֶֽׂה׃"
          },
          {
            "num": "כז",
            "txt": "וַיֹּ֤אמֶר בָּלָק֙ אֶל־בִּלְעָ֔ם לְכָה־נָּא֙ אֶקָּ֣חֲךָ֔ אֶל־מָק֖וֹם אַחֵ֑ר אוּלַ֤י יִישַׁר֙ בְּעֵינֵ֣י הָֽאֱלֹהִ֔ים וְקַבֹּ֥תוֹ לִ֖י מִשָּֽׁם׃"
          },
          {
            "num": "כח",
            "txt": "וַיִּקַּ֥ח בָּלָ֖ק אֶת־בִּלְעָ֑ם רֹ֣אשׁ הַפְּע֔וֹר הַנִּשְׁקָ֖ף עַל־פְּנֵ֥י הַיְשִׁימֹֽן׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֤אמֶר בִּלְעָם֙ אֶל־בָּלָ֔ק בְּנֵה־לִ֥י בָזֶ֖ה שִׁבְעָ֣ה מִזְבְּחֹ֑ת וְהָכֵ֥ן לִי֙ בָּזֶ֔ה שִׁבְעָ֥ה פָרִ֖ים וְשִׁבְעָ֥ה אֵילִֽם׃"
          },
          {
            "num": "ל",
            "txt": "וַיַּ֣עַשׂ בָּלָ֔ק כַּֽאֲשֶׁ֖ר אָמַ֣ר בִּלְעָ֑ם וַיַּ֛עַל פָּ֥ר וָאַ֖יִל בַּמִּזְבֵּֽחַ׃"
          }
        ]
      },
      {
        "num": "כד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּ֣רְא בִּלְעָ֗ם כִּ֣י ט֞וֹב בְּעֵינֵ֤י יְהוָה֙ לְבָרֵ֣ךְ אֶת־יִשְׂרָאֵ֔ל וְלֹֽא־הָלַ֥ךְ כְּפַֽעַם־בְּפַ֖עַם לִקְרַ֣את נְחָשִׁ֑ים וַיָּ֥שֶׁת אֶל־הַמִּדְבָּ֖ר פָּנָֽיו׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּשָּׂ֨א בִלְעָ֜ם אֶת־עֵינָ֗יו וַיַּרְא֙ אֶת־יִשְׂרָאֵ֔ל שֹׁכֵ֖ן לִשְׁבָטָ֑יו וַתְּהִ֥י עָלָ֖יו ר֥וּחַ אֱלֹהִֽים׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּשָּׂ֥א מְשָׁל֖וֹ וַיֹּאמַ֑ר נְאֻ֤ם בִּלְעָם֙ בְּנ֣וֹ בְעֹ֔ר וּנְאֻ֥ם הַגֶּ֖בֶר שְׁתֻ֥ם הָעָֽיִן׃"
          },
          {
            "num": "ד",
            "txt": "נְאֻ֕ם שֹׁמֵ֖עַ אִמְרֵי־אֵ֑ל אֲשֶׁ֨ר מַֽחֲזֵ֤ה שַׁדַּי֙ יֶֽחֱזֶ֔ה נֹפֵ֖ל וּגְל֥וּי עֵינָֽיִם׃"
          },
          {
            "num": "ה",
            "txt": "מַה־טֹּ֥בוּ אֹֽהָלֶ֖יךָ יַֽעֲקֹ֑ב מִשְׁכְּנֹתֶ֖יךָ יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ו",
            "txt": "כִּנְחָלִ֣ים נִטָּ֔יוּ כְּגַנֹּ֖ת עֲלֵ֣י נָהָ֑ר כַּֽאֲהָלִים֙ נָטַ֣ע יְהוָ֔ה כַּֽאֲרָזִ֖ים עֲלֵי־מָֽיִם׃"
          },
          {
            "num": "ז",
            "txt": "יִֽזַּל־מַ֨יִם֙ מִדָּ֣לְיָ֔ו וְזַרְע֖וֹ בְּמַ֣יִם רַבִּ֑ים וְיָרֹ֤ם מֵֽאֲגַג֙ מַלְכּ֔וֹ וְתִנַּשֵּׂ֖א מַלְכֻתֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "אֵ֚ל מֽוֹצִיא֣וֹ מִמִּצְרַ֔יִם כְּתֽוֹעֲפֹ֥ת רְאֵ֖ם ל֑וֹ יֹאכַ֞ל גּוֹיִ֣ם צָרָ֗יו וְעַצְמֹֽתֵיהֶ֛ם יְגָרֵ֖ם וְחִצָּ֥יו יִמְחָֽץ׃"
          },
          {
            "num": "ט",
            "txt": "כָּרַ֨ע שָׁכַ֧ב כַּֽאֲרִ֛י וּכְלָבִ֖יא מִ֣י יְקִימֶ֑נּוּ מְבָֽרְכֶ֣יךָ בָר֔וּךְ וְאֹֽרְרֶ֖יךָ אָרֽוּר׃"
          },
          {
            "num": "י",
            "txt": "וַיִּֽחַר־אַ֤ף בָּלָק֙ אֶל־בִּלְעָ֔ם וַיִּסְפֹּ֖ק אֶת־כַּפָּ֑יו וַיֹּ֨אמֶר בָּלָ֜ק אֶל־בִּלְעָ֗ם לָקֹ֤ב אֹֽיְבַי֙ קְרָאתִ֔יךָ וְהִנֵּה֙ בֵּרַ֣כְתָּ בָרֵ֔ךְ זֶ֖ה שָׁלֹ֥שׁ פְּעָמִֽים׃"
          },
          {
            "num": "יא",
            "txt": "וְעַתָּ֖ה בְּרַח־לְךָ֣ אֶל־מְקוֹמֶ֑ךָ אָמַ֨רְתִּי֙ כַּבֵּ֣ד אֲכַבֶּדְךָ֔ וְהִנֵּ֛ה מְנָֽעֲךָ֥ יְהוָ֖ה מִכָּבֽוֹד׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֥אמֶר בִּלְעָ֖ם אֶל־בָּלָ֑ק הֲלֹ֗א גַּ֧ם אֶל־מַלְאָכֶ֛יךָ אֲשֶׁר־שָׁלַ֥חְתָּ אֵלַ֖י דִּבַּ֥רְתִּי לֵאמֹֽר׃"
          },
          {
            "num": "יג",
            "txt": "אִם־יִתֶּן־לִ֨י בָלָ֜ק מְלֹ֣א בֵיתוֹ֮ כֶּ֣סֶף וְזָהָב֒ לֹ֣א אוּכַ֗ל לַֽעֲבֹר֙ אֶת־פִּ֣י יְהוָ֔ה לַֽעֲשׂ֥וֹת טוֹבָ֛ה א֥וֹ רָעָ֖ה מִלִּבִּ֑י אֲשֶׁר־יְדַבֵּ֥ר יְהוָ֖ה אֹת֥וֹ אֲדַבֵּֽר׃"
          },
          {
            "num": "יד",
            "txt": "וְעַתָּ֕ה הִנְנִ֥י הוֹלֵ֖ךְ לְעַמִּ֑י לְכָה֙ אִיעָ֣צְךָ֔ אֲשֶׁ֨ר יַֽעֲשֶׂ֜ה הָעָ֥ם הַזֶּ֛ה לְעַמְּךָ֖ בְּאַֽחֲרִ֥ית הַיָּמִֽים׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּשָּׂ֥א מְשָׁל֖וֹ וַיֹּאמַ֑ר נְאֻ֤ם בִּלְעָם֙ בְּנ֣וֹ בְעֹ֔ר וּנְאֻ֥ם הַגֶּ֖בֶר שְׁתֻ֥ם הָעָֽיִן׃"
          },
          {
            "num": "טז",
            "txt": "נְאֻ֗ם שֹׁמֵ֨עַ֙ אִמְרֵי־אֵ֔ל וְיֹדֵ֖עַ דַּ֣עַת עֶלְי֑וֹן מַֽחֲזֵ֤ה שַׁדַּי֙ יֶֽחֱזֶ֔ה נֹפֵ֖ל וּגְל֥וּי עֵינָֽיִם׃"
          },
          {
            "num": "יז",
            "txt": "אֶרְאֶ֨נּוּ֙ וְלֹ֣א עַתָּ֔ה אֲשׁוּרֶ֖נּוּ וְלֹ֣א קָר֑וֹב דָּרַ֨ךְ כּוֹכָ֜ב מִֽיַּעֲקֹ֗ב וְקָ֥ם שֵׁ֨בֶט֙ מִיִּשְׂרָאֵ֔ל וּמָחַץ֙ פַּֽאֲתֵ֣י מוֹאָ֔ב וְקַרְקַ֖ר כָּל־בְּנֵי־שֵֽׁת׃"
          },
          {
            "num": "יח",
            "txt": "וְהָיָ֨ה אֱד֜וֹם יְרֵשָׁ֗ה וְהָיָ֧ה יְרֵשָׁ֛ה שֵׂעִ֖יר אֹֽיְבָ֑יו וְיִשְׂרָאֵ֖ל עֹ֥שֶׂה חָֽיִל׃"
          },
          {
            "num": "יט",
            "txt": "וְיֵ֖רְדְּ מִֽיַּעֲקֹ֑ב וְהֶֽאֱבִ֥יד שָׂרִ֖יד מֵעִֽיר׃"
          },
          {
            "num": "כ",
            "txt": "וַיַּרְא֙ אֶת־עֲמָלֵ֔ק וַיִּשָּׂ֥א מְשָׁל֖וֹ וַיֹּאמַ֑ר רֵאשִׁ֤ית גּוֹיִם֙ עֲמָלֵ֔ק וְאַֽחֲרִית֖וֹ עֲדֵ֥י אֹבֵֽד׃"
          },
          {
            "num": "כא",
            "txt": "וַיַּרְא֙ אֶת־הַקֵּינִ֔י וַיִּשָּׂ֥א מְשָׁל֖וֹ וַיֹּאמַ֑ר אֵיתָן֙ מֽוֹשָׁבֶ֔ךָ וְשִׂ֥ים בַּסֶּ֖לַע קִנֶּֽךָ׃"
          },
          {
            "num": "כב",
            "txt": "כִּ֥י אִם־יִֽהְיֶ֖ה לְבָ֣עֵֽר קָ֑יִן עַד־מָ֖ה אַשּׁ֥וּר תִּשְׁבֶּֽךָּ׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּשָּׂ֥א מְשָׁל֖וֹ וַיֹּאמַ֑ר א֕וֹי מִ֥י יִֽחְיֶ֖ה מִשֻּׂמ֥וֹ אֵֽל׃"
          },
          {
            "num": "כד",
            "txt": "וְצִים֙ מִיַּ֣ד כִּתִּ֔ים וְעִנּ֥וּ אַשּׁ֖וּר וְעִנּוּ־עֵ֑בֶר וְגַם־ה֖וּא עֲדֵ֥י אֹבֵֽד׃"
          },
          {
            "num": "כה",
            "txt": "וַיָּ֣קָם בִּלְעָ֔ם וַיֵּ֖לֶךְ וַיָּ֣שָׁב לִמְקֹמ֑וֹ וְגַם־בָּלָ֖ק הָלַ֥ךְ לְדַרְכּֽוֹ׃ {פ}"
          }
        ]
      },
      {
        "num": "כה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֵּ֥שֶׁב יִשְׂרָאֵ֖ל בַּשִּׁטִּ֑ים וַיָּ֣חֶל הָעָ֔ם לִזְנ֖וֹת אֶל־בְּנ֥וֹת מוֹאָֽב׃"
          },
          {
            "num": "ב",
            "txt": "וַתִּקְרֶ֣אןָ לָעָ֔ם לְזִבְחֵ֖י אֱלֹֽהֵיהֶ֑ן וַיֹּ֣אכַל הָעָ֔ם וַיִּֽשְׁתַּחֲו֖וּ לֵֽאלֹהֵיהֶֽן׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּצָּ֥מֶד יִשְׂרָאֵ֖ל לְבַ֣עַל פְּע֑וֹר וַיִּֽחַר־אַ֥ף יְהוָ֖ה בְּיִשְׂרָאֵֽל׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה קַ֚ח אֶת־כָּל־רָאשֵׁ֣י הָעָ֔ם וְהוֹקַ֥ע אוֹתָ֛ם לַֽיהוָ֖ה נֶ֣גֶד הַשָּׁ֑מֶשׁ וְיָשֹׁ֛ב חֲר֥וֹן אַף־יְהוָ֖ה מִיִּשְׂרָאֵֽל׃"
          },
          {
            "num": "ה",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה אֶל־שֹֽׁפְטֵ֖י יִשְׂרָאֵ֑ל הִרְגוּ֙ אִ֣ישׁ אֲנָשָׁ֔יו הַנִּצְמָדִ֖ים לְבַ֥עַל פְּעֽוֹר׃"
          },
          {
            "num": "ו",
            "txt": "וְהִנֵּ֡ה אִישׁ֩ מִבְּנֵ֨י יִשְׂרָאֵ֜ל בָּ֗א וַיַּקְרֵ֤ב אֶל־אֶחָיו֙ אֶת־הַמִּדְיָנִ֔ית לְעֵינֵ֣י מֹשֶׁ֔ה וּלְעֵינֵ֖י כָּל־עֲדַ֣ת בְּנֵֽי־יִשְׂרָאֵ֑ל וְהֵ֣מָּה בֹכִ֔ים פֶּ֖תַח אֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "ז",
            "txt": "וַיַּ֗רְא פִּֽינְחָס֙ בֶּן־אֶלְעָזָ֔ר בֶּֽן־אַהֲרֹ֖ן הַכֹּהֵ֑ן וַיָּ֨קָם֙ מִתּ֣וֹךְ הָֽעֵדָ֔ה וַיִּקַּ֥ח רֹ֖מַח בְּיָדֽוֹ׃"
          },
          {
            "num": "ח",
            "txt": "וַ֠יָּבֹא אַחַ֨ר אִֽישׁ־יִשְׂרָאֵ֜ל אֶל־הַקֻּבָּ֗ה וַיִּדְקֹר֙ אֶת־שְׁנֵיהֶ֔ם אֵ֚ת אִ֣ישׁ יִשְׂרָאֵ֔ל וְאֶת־הָֽאִשָּׁ֖ה אֶל־קֳבָתָ֑הּ וַתֵּֽעָצַר֙ הַמַּגֵּפָ֔ה מֵעַ֖ל בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּֽהְי֕וּ הַמֵּתִ֖ים בַּמַּגֵּפָ֑ה אַרְבָּעָ֥ה וְעֶשְׂרִ֖ים אָֽלֶף׃ {פ}"
          },
          {
            "num": "י",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יא",
            "txt": "פִּֽינְחָ֨ס בֶּן־אֶלְעָזָ֜ר בֶּן־אַֽהֲרֹ֣ן הַכֹּהֵ֗ן הֵשִׁ֤יב אֶת־חֲמָתִי֙ מֵעַ֣ל בְּנֵֽי־יִשְׂרָאֵ֔ל בְּקַנְא֥וֹ אֶת־קִנְאָתִ֖י בְּתוֹכָ֑ם וְלֹֽא־כִלִּ֥יתִי אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל בְּקִנְאָתִֽי׃"
          },
          {
            "num": "יב",
            "txt": "לָכֵ֖ן אֱמֹ֑ר הִנְנִ֨י נֹתֵ֥ן ל֛וֹ אֶת־בְּרִיתִ֖י שָׁלֽוֹם׃"
          },
          {
            "num": "יג",
            "txt": "וְהָ֤יְתָה לּוֹ֙ וּלְזַרְע֣וֹ אַֽחֲרָ֔יו בְּרִ֖ית כְּהֻנַּ֣ת עוֹלָ֑ם תַּ֗חַת אֲשֶׁ֤ר קִנֵּא֙ לֵֽאלֹהָ֔יו וַיְכַפֵּ֖ר עַל־בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יד",
            "txt": "וְשֵׁם֩ אִ֨ישׁ יִשְׂרָאֵ֜ל הַמֻּכֶּ֗ה אֲשֶׁ֤ר הֻכָּה֙ אֶת־הַמִּדְיָנִ֔ית זִמְרִ֖י בֶּן־סָל֑וּא נְשִׂ֥יא בֵֽית־אָ֖ב לַשִּׁמְעֹנִֽי׃"
          },
          {
            "num": "טו",
            "txt": "וְשֵׁ֨ם הָֽאִשָּׁ֧ה הַמֻּכָּ֛ה הַמִּדְיָנִ֖ית כָּזְבִּ֣י בַת־צ֑וּר רֹ֣אשׁ אֻמּ֥וֹת בֵּֽית־אָ֛ב בְּמִדְיָ֖ן הֽוּא׃ {פ}"
          },
          {
            "num": "טז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "צָר֖וֹר אֶת־הַמִּדְיָנִ֑ים וְהִכִּיתֶ֖ם אוֹתָֽם׃"
          },
          {
            "num": "יח",
            "txt": "כִּ֣י צֹֽרְרִ֥ים הֵם֙ לָכֶ֔ם בְּנִכְלֵיהֶ֛ם אֲשֶׁר־נִכְּל֥וּ לָכֶ֖ם עַל־דְּבַר־פְּע֑וֹר וְעַל־דְּבַ֞ר כָּזְבִּ֨י בַת־נְשִׂ֤יא מִדְיָן֙ אֲחֹתָ֔ם הַמֻּכָּ֥ה בְיוֹם־הַמַּגֵּפָ֖ה עַל־דְּבַר־פְּעֽוֹר׃"
          }
        ]
      },
      {
        "num": "כו",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְהִ֖י אַֽחֲרֵ֣י הַמַּגֵּפָ֑ה {פ}"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה וְאֶ֧ל אֶלְעָזָ֛ר בֶּן־אַֽהֲרֹ֥ן הַכֹּהֵ֖ן לֵאמֹֽר׃"
          },
          {
            "num": "ג",
            "txt": "שְׂא֞וּ אֶת־רֹ֣אשׁ ׀ כָּל־עֲדַ֣ת בְּנֵֽי־יִשְׂרָאֵ֗ל מִבֶּ֨ן עֶשְׂרִ֥ים שָׁנָ֛ה וָמַ֖עְלָה לְבֵ֣ית אֲבֹתָ֑ם כָּל־יֹצֵ֥א צָבָ֖א בְּיִשְׂרָאֵֽל׃"
          },
          {
            "num": "ד",
            "txt": "וַיְדַבֵּ֨ר מֹשֶׁ֜ה וְאֶלְעָזָ֧ר הַכֹּהֵ֛ן אֹתָ֖ם בְּעַֽרְבֹ֣ת מוֹאָ֑ב עַל־יַרְדֵּ֥ן יְרֵח֖וֹ לֵאמֹֽר׃"
          },
          {
            "num": "ה",
            "txt": "מִבֶּ֛ן עֶשְׂרִ֥ים שָׁנָ֖ה וָמָ֑עְלָה כַּֽאֲשֶׁר֩ צִוָּ֨ה יְהוָ֤ה אֶת־מֹשֶׁה֙ וּבְנֵ֣י יִשְׂרָאֵ֔ל הַיֹּֽצְאִ֖ים מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ו",
            "txt": "רְאוּבֵ֖ן בְּכ֣וֹר יִשְׂרָאֵ֑ל בְּנֵ֣י רְאוּבֵ֗ן חֲנוֹךְ֙ מִשְׁפַּ֣חַת הַֽחֲנֹכִ֔י לְפַלּ֕וּא מִשְׁפַּ֖חַת הַפַּלֻּאִֽי׃"
          },
          {
            "num": "ז",
            "txt": "לְחֶצְרֹ֕ן מִשְׁפַּ֖חַת הַֽחֶצְרוֹנִ֑י לְכַרְמִ֕י מִשְׁפַּ֖חַת הַכַּרְמִֽי׃"
          },
          {
            "num": "ח",
            "txt": "אֵ֖לֶּה מִשְׁפְּחֹ֣ת הָרֽאוּבֵנִ֑י וַיִּֽהְי֣וּ פְקֻֽדֵיהֶ֗ם שְׁלֹשָׁ֤ה וְאַרְבָּעִים֙ אֶ֔לֶף וּשְׁבַ֥ע מֵא֖וֹת וּשְׁלֹשִֽׁים׃"
          },
          {
            "num": "ט",
            "txt": "וּבְנֵ֥י פַלּ֖וּא אֱלִיאָֽב׃"
          },
          {
            "num": "י",
            "txt": "וּבְנֵ֣י אֱלִיאָ֔ב נְמוּאֵ֖ל וְדָתָ֣ן וַֽאֲבִירָ֑ם הֽוּא־דָתָ֨ן וַֽאֲבִירָ֜ם קרואי (קְרִיאֵ֣י) הָֽעֵדָ֗ה אֲשֶׁ֨ר הִצּ֜וּ עַל־מֹשֶׁ֤ה וְעַֽל־אַהֲרֹן֙ בַּֽעֲדַת־קֹ֔רַח בְּהַצֹּתָ֖ם עַל־יְהוָֽה׃"
          },
          {
            "num": "יא",
            "txt": "וַתִּפְתַּ֨ח הָאָ֜רֶץ אֶת־פִּ֗יהָ וַתִּבְלַ֥ע אֹתָ֛ם וְאֶת־קֹ֖רַח בְּמ֣וֹת הָֽעֵדָ֑ה בַּֽאֲכֹ֣ל הָאֵ֗שׁ אֵ֣ת חֲמִשִּׁ֤ים וּמָאתַ֨יִם֙ אִ֔ישׁ וַיִּֽהְי֖וּ לְנֵֽס׃"
          },
          {
            "num": "יב",
            "txt": "וּבְנֵי־קֹ֖רַח לֹא־מֵֽתוּ׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "בְּנֵ֣י שִׁמְעוֹן֮ לְמִשְׁפְּחֹתָם֒ לִנְמוּאֵ֗ל מִשְׁפַּ֨חַת֙ הַנְּמ֣וּאֵלִ֔י לְיָמִ֕ין מִשְׁפַּ֖חַת הַיָּֽמִינִ֑י לְיָכִ֕ין מִשְׁפַּ֖חַת הַיָּֽכִינִֽי׃"
          },
          {
            "num": "יד",
            "txt": "לְזֶ֕רַח מִשְׁפַּ֖חַת הַזַּרְחִ֑י לְשָׁא֕וּל מִשְׁפַּ֖חַת הַשָּֽׁאוּלִֽי׃"
          },
          {
            "num": "טו",
            "txt": "אֵ֖לֶּה מִשְׁפְּחֹ֣ת הַשִּׁמְעֹנִ֑י שְׁנַ֧יִם וְעֶשְׂרִ֛ים אֶ֖לֶף וּמָאתָֽיִם׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "בְּנֵ֣י גָד֮ לְמִשְׁפְּחֹתָם֒ לִצְפ֗וֹן מִשְׁפַּ֨חַת֙ הַצְּפוֹנִ֔י לְחַגִּ֕י מִשְׁפַּ֖חַת הַֽחַגִּ֑י לְשׁוּנִ֕י מִשְׁפַּ֖חַת הַשּׁוּנִֽי׃"
          },
          {
            "num": "יז",
            "txt": "לְאָזְנִ֕י מִשְׁפַּ֖חַת הָֽאָזְנִ֑י לְעֵרִ֕י מִשְׁפַּ֖חַת הָֽעֵרִֽי׃"
          },
          {
            "num": "יח",
            "txt": "לַֽאֲר֕וֹד מִשְׁפַּ֖חַת הָֽאֲרוֹדִ֑י לְאַ֨רְאֵלִ֔י מִשְׁפַּ֖חַת הָֽאַרְאֵלִֽי׃"
          },
          {
            "num": "יט",
            "txt": "אֵ֛לֶּה מִשְׁפְּחֹ֥ת בְּנֵֽי־גָ֖ד לִפְקֻֽדֵיהֶ֑ם אַרְבָּעִ֥ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "בְּנֵ֥י יְהוּדָ֖ה עֵ֣ר וְאוֹנָ֑ן וַיָּ֥מָת עֵ֛ר וְאוֹנָ֖ן בְּאֶ֥רֶץ כְּנָֽעַן׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּֽהְי֣וּ בְנֵֽי־יְהוּדָה֮ לְמִשְׁפְּחֹתָם֒ לְשֵׁלָ֗ה מִשְׁפַּ֨חַת֙ הַשֵּׁ֣לָנִ֔י לְפֶ֕רֶץ מִשְׁפַּ֖חַת הַפַּרְצִ֑י לְזֶ֕רַח מִשְׁפַּ֖חַת הַזַּרְחִֽי׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּֽהְי֣וּ בְנֵי־פֶ֔רֶץ לְחֶצְרֹ֕ן מִשְׁפַּ֖חַת הַֽחֶצְרֹנִ֑י לְחָמ֕וּל מִשְׁפַּ֖חַת הֶחָֽמוּלִֽי׃"
          },
          {
            "num": "כג",
            "txt": "אֵ֛לֶּה מִשְׁפְּחֹ֥ת יְהוּדָ֖ה לִפְקֻֽדֵיהֶ֑ם שִׁשָּׁ֧ה וְשִׁבְעִ֛ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃ {ס}"
          },
          {
            "num": "כד",
            "txt": "בְּנֵ֤י יִשָּׂשכָר֙ לְמִשְׁפְּחֹתָ֔ם תּוֹלָ֕ע מִשְׁפַּ֖חַת הַתּֽוֹלָעִ֑י לְפֻוָ֕ה מִשְׁפַּ֖חַת הַפּוּנִֽי׃"
          },
          {
            "num": "כה",
            "txt": "לְיָשׁ֕וּב מִשְׁפַּ֖חַת הַיָּֽשֻׁבִ֑י לְשִׁמְרֹ֕ן מִשְׁפַּ֖חַת הַשִּׁמְרֹנִֽי׃"
          },
          {
            "num": "כו",
            "txt": "אֵ֛לֶּה מִשְׁפְּחֹ֥ת יִשָּׂשכָ֖ר לִפְקֻֽדֵיהֶ֑ם אַרְבָּעָ֧ה וְשִׁשִּׁ֛ים אֶ֖לֶף וּשְׁלֹ֥שׁ מֵאֽוֹת׃ {ס}"
          },
          {
            "num": "כז",
            "txt": "בְּנֵ֣י זְבוּלֻן֮ לְמִשְׁפְּחֹתָם֒ לְסֶ֗רֶד מִשְׁפַּ֨חַת֙ הַסַּרְדִּ֔י לְאֵל֕וֹן מִשְׁפַּ֖חַת הָאֵֽלֹנִ֑י לְיַ֨חְלְאֵ֔ל מִשְׁפַּ֖חַת הַיַּחְלְאֵלִֽי׃"
          },
          {
            "num": "כח",
            "txt": "אֵ֛לֶּה מִשְׁפְּחֹ֥ת הַזְּבֽוּלֹנִ֖י לִפְקֻֽדֵיהֶ֑ם שִׁשִּׁ֥ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "בְּנֵ֥י יוֹסֵ֖ף לְמִשְׁפְּחֹתָ֑ם מְנַשֶּׁ֖ה וְאֶפְרָֽיִם׃"
          },
          {
            "num": "ל",
            "txt": "בְּנֵ֣י מְנַשֶּׁ֗ה לְמָכִיר֙ מִשְׁפַּ֣חַת הַמָּֽכִירִ֔י וּמָכִ֖יר הוֹלִ֣יד אֶת־גִּלְעָ֑ד לְגִלְעָ֕ד מִשְׁפַּ֖חַת הַגִּלְעָדִֽי׃"
          },
          {
            "num": "לא",
            "txt": "אֵ֚לֶּה בְּנֵ֣י גִלְעָ֔ד אִיעֶ֕זֶר מִשְׁפַּ֖חַת הָאִֽיעֶזְרִ֑י לְחֵ֕לֶק מִשְׁפַּ֖חַת הַֽחֶלְקִֽי׃"
          },
          {
            "num": "לב",
            "txt": "וְאַ֨שְׂרִיאֵ֔ל מִשְׁפַּ֖חַת הָֽאַשְׂרִֽאֵלִ֑י וְשֶׁ֕כֶם מִשְׁפַּ֖חַת הַשִּׁכְמִֽי׃"
          },
          {
            "num": "לג",
            "txt": "וּשְׁמִידָ֕ע מִשְׁפַּ֖חַת הַשְּׁמִֽידָעִ֑י וְחֵ֕פֶר מִשְׁפַּ֖חַת הַֽחֶפְרִֽי׃"
          },
          {
            "num": "לד",
            "txt": "וּצְלָפְחָ֣ד בֶּן־חֵ֗פֶר לֹא־הָ֥יוּ ל֛וֹ בָּנִ֖ים כִּ֣י אִם־בָּנ֑וֹת וְשֵׁם֙ בְּנ֣וֹת צְלָפְחָ֔ד מַחְלָ֣ה וְנֹעָ֔ה חָגְלָ֥ה מִלְכָּ֖ה וְתִרְצָֽה׃"
          },
          {
            "num": "לה",
            "txt": "אֵ֖לֶּה מִשְׁפְּחֹ֣ת מְנַשֶּׁ֑ה וּפְקֻ֣דֵיהֶ֔ם שְׁנַ֧יִם וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וּשְׁבַ֥ע מֵאֽוֹת׃ {ס}"
          },
          {
            "num": "לו",
            "txt": "אֵ֣לֶּה בְנֵֽי־אֶפְרַיִם֮ לְמִשְׁפְּחֹתָם֒ לְשׁוּתֶ֗לַח מִשְׁפַּ֨חַת֙ הַשֻּׁ֣תַלְחִ֔י לְבֶ֕כֶר מִשְׁפַּ֖חַת הַבַּכְרִ֑י לְתַ֕חַן מִשְׁפַּ֖חַת הַֽתַּחֲנִֽי׃"
          },
          {
            "num": "לז",
            "txt": "וְאֵ֖לֶּה בְּנֵ֣י שׁוּתָ֑לַח לְעֵרָ֕ן מִשְׁפַּ֖חַת הָעֵֽרָנִֽי׃"
          },
          {
            "num": "לח",
            "txt": "אֵ֣לֶּה מִשְׁפְּחֹ֤ת בְּנֵֽי־אֶפְרַ֨יִם֙ לִפְקֻ֣דֵיהֶ֔ם שְׁנַ֧יִם וּשְׁלֹשִׁ֛ים אֶ֖לֶף וַֽחֲמֵ֣שׁ מֵא֑וֹת אֵ֥לֶּה בְנֵֽי־יוֹסֵ֖ף לְמִשְׁפְּחֹתָֽם׃ {ס}"
          },
          {
            "num": "לט",
            "txt": "בְּנֵ֣י בִנְיָמִן֮ לְמִשְׁפְּחֹתָם֒ לְבֶ֗לַע מִשְׁפַּ֨חַת֙ הַבַּלְעִ֔י לְאַשְׁבֵּ֕ל מִשְׁפַּ֖חַת הָֽאַשְׁבֵּלִ֑י לַֽאֲחִירָ֕ם מִשְׁפַּ֖חַת הָאֲחִֽירָמִֽי׃"
          },
          {
            "num": "מ",
            "txt": "לִשְׁפוּפָ֕ם מִשְׁפַּ֖חַת הַשּֽׁוּפָמִ֑י לְחוּפָ֕ם מִשְׁפַּ֖חַת הַחֽוּפָמִֽי׃"
          },
          {
            "num": "מא",
            "txt": "וַיִּֽהְי֥וּ בְנֵי־בֶ֖לַע אַ֣רְדְּ וְנַֽעֲמָ֑ן מִשְׁפַּ֨חַת֙ הָֽאַרְדִּ֔י לְנַֽעֲמָ֔ן מִשְׁפַּ֖חַת הַֽנַּעֲמִֽי׃"
          },
          {
            "num": "מב",
            "txt": "אֵ֥לֶּה בְנֵֽי־בִנְיָמִ֖ן לְמִשְׁפְּחֹתָ֑ם וּפְקֻ֣דֵיהֶ֔ם חֲמִשָּׁ֧ה וְאַרְבָּעִ֛ים אֶ֖לֶף וְשֵׁ֥שׁ מֵאֽוֹת׃ {ס}"
          },
          {
            "num": "מג",
            "txt": "אֵ֤לֶּה בְנֵי־דָן֙ לְמִשְׁפְּחֹתָ֔ם לְשׁוּחָ֕ם מִשְׁפַּ֖חַת הַשּֽׁוּחָמִ֑י אֵ֛לֶּה מִשְׁפְּחֹ֥ת דָּ֖ן לְמִשְׁפְּחֹתָֽם׃"
          },
          {
            "num": "מד",
            "txt": "כָּל־מִשְׁפְּחֹ֥ת הַשּֽׁוּחָמִ֖י לִפְקֻֽדֵיהֶ֑ם אַרְבָּעָ֧ה וְשִׁשִּׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃ {ס}"
          },
          {
            "num": "מה",
            "txt": "בְּנֵ֣י אָשֵׁר֮ לְמִשְׁפְּחֹתָם֒ לְיִמְנָ֗ה מִשְׁפַּ֨חַת֙ הַיִּמְנָ֔ה לְיִשְׁוִ֕י מִשְׁפַּ֖חַת הַיִּשְׁוִ֑י לִבְרִיעָ֕ה מִשְׁפַּ֖חַת הַבְּרִיעִֽי׃"
          },
          {
            "num": "מו",
            "txt": "לִבְנֵ֣י בְרִיעָ֔ה לְחֶ֕בֶר מִשְׁפַּ֖חַת הַֽחֶבְרִ֑י לְמַ֨לְכִּיאֵ֔ל מִשְׁפַּ֖חַת הַמַּלְכִּֽיאֵלִֽי׃"
          },
          {
            "num": "מז",
            "txt": "וְשֵׁ֥ם בַּת־אָשֵׁ֖ר שָֽׂרַח׃"
          },
          {
            "num": "מח",
            "txt": "אֵ֛לֶּה מִשְׁפְּחֹ֥ת בְּנֵֽי־אָשֵׁ֖ר לִפְקֻֽדֵיהֶ֑ם שְׁלֹשָׁ֧ה וַֽחֲמִשִּׁ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃ {ס}"
          },
          {
            "num": "מט",
            "txt": "בְּנֵ֤י נַפְתָּלִי֙ לְמִשְׁפְּחֹתָ֔ם לְיַ֨חְצְאֵ֔ל מִשְׁפַּ֖חַת הַיַּחְצְאֵלִ֑י לְגוּנִ֕י מִשְׁפַּ֖חַת הַגּוּנִֽי׃"
          },
          {
            "num": "נ",
            "txt": "לְיֵ֕צֶר מִשְׁפַּ֖חַת הַיִּצְרִ֑י לְשִׁלֵּ֕ם מִשְׁפַּ֖חַת הַשִּׁלֵּמִֽי׃"
          },
          {
            "num": "נא",
            "txt": "אֵ֛לֶּה מִשְׁפְּחֹ֥ת נַפְתָּלִ֖י לְמִשְׁפְּחֹתָ֑ם וּפְקֻ֣דֵיהֶ֔ם חֲמִשָּׁ֧ה וְאַרְבָּעִ֛ים אֶ֖לֶף וְאַרְבַּ֥ע מֵאֽוֹת׃"
          },
          {
            "num": "נב",
            "txt": "אֵ֗לֶּה פְּקוּדֵי֙ בְּנֵ֣י יִשְׂרָאֵ֔ל שֵׁשׁ־מֵא֥וֹת אֶ֖לֶף וָאָ֑לֶף שְׁבַ֥ע מֵא֖וֹת וּשְׁלֹשִֽׁים׃ {פ}"
          },
          {
            "num": "נג",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "נד",
            "txt": "לָאֵ֗לֶּה תֵּֽחָלֵ֥ק הָאָ֛רֶץ בְּנַֽחֲלָ֖ה בְּמִסְפַּ֥ר שֵׁמֽוֹת׃"
          },
          {
            "num": "נה",
            "txt": "לָרַ֗ב תַּרְבֶּה֙ נַֽחֲלָת֔וֹ וְלַמְעַ֕ט תַּמְעִ֖יט נַֽחֲלָת֑וֹ אִ֚ישׁ לְפִ֣י פְקֻדָ֔יו יֻתַּ֖ן נַֽחֲלָתֽוֹ׃"
          },
          {
            "num": "נו",
            "txt": "אַךְ־בְּגוֹרָ֕ל יֵֽחָלֵ֖ק אֶת־הָאָ֑רֶץ לִשְׁמ֥וֹת מַטּוֹת־אֲבֹתָ֖ם יִנְחָֽלוּ׃"
          },
          {
            "num": "נז",
            "txt": "עַל־פִּי֙ הַגּוֹרָ֔ל תֵּֽחָלֵ֖ק נַֽחֲלָת֑וֹ בֵּ֥ין רַ֖ב לִמְעָֽט׃ {ס}"
          },
          {
            "num": "נח",
            "txt": "וְאֵ֨לֶּה פְקוּדֵ֣י הַלֵּוִי֮ לְמִשְׁפְּחֹתָם֒ לְגֵֽרְשׁ֗וֹן מִשְׁפַּ֨חַת֙ הַגֵּ֣רְשֻׁנִּ֔י לִקְהָ֕ת מִשְׁפַּ֖חַת הַקְּהָתִ֑י לִמְרָרִ֕י מִשְׁפַּ֖חַת הַמְּרָרִֽי׃"
          },
          {
            "num": "נט",
            "txt": "אֵ֣לֶּה ׀ מִשְׁפְּחֹ֣ת לֵוִ֗י מִשְׁפַּ֨חַת הַלִּבְנִ֜י מִשְׁפַּ֤חַת הַֽחֶבְרֹנִי֙ מִשְׁפַּ֤חַת הַמַּחְלִי֙ מִשְׁפַּ֣חַת הַמּוּשִׁ֔י מִשְׁפַּ֖חַת הַקָּרְחִ֑י וּקְהָ֖ת הוֹלִ֥ד אֶת־עַמְרָֽם׃"
          },
          {
            "num": "ס",
            "txt": "וְשֵׁ֣ם ׀ אֵ֣שֶׁת עַמְרָ֗ם יוֹכֶ֨בֶד֙ בַּת־לֵוִ֔י אֲשֶׁ֨ר יָֽלְדָ֥ה אֹתָ֛הּ לְלֵוִ֖י בְּמִצְרָ֑יִם וַתֵּ֣לֶד לְעַמְרָ֗ם אֶֽת־אַהֲרֹן֙ וְאֶת־מֹשֶׁ֔ה וְאֵ֖ת מִרְיָ֥ם אֲחֹתָֽם׃"
          },
          {
            "num": "סא",
            "txt": "וַיִּוָּלֵ֣ד לְאַֽהֲרֹ֔ן אֶת־נָדָ֖ב וְאֶת־אֲבִיה֑וּא אֶת־אֶלְעָזָ֖ר וְאֶת־אִֽיתָמָֽר׃"
          },
          {
            "num": "סב",
            "txt": "וַיָּ֥מָת נָדָ֖ב וַֽאֲבִיה֑וּא בְּהַקְרִיבָ֥ם אֵשׁ־זָרָ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "סג",
            "txt": "וַיִּֽהְי֣וּ פְקֻֽדֵיהֶ֗ם שְׁלֹשָׁ֤ה וְעֶשְׂרִים֙ אֶ֔לֶף כָּל־זָכָ֖ר מִבֶּן־חֹ֣דֶשׁ וָמָ֑עְלָה כִּ֣י ׀ לֹ֣א הָתְפָּֽקְד֗וּ בְּתוֹךְ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל כִּ֠י לֹֽא־נִתַּ֤ן לָהֶם֙ נַֽחֲלָ֔ה בְּת֖וֹךְ בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "סד",
            "txt": "אֵ֚לֶּה פְּקוּדֵ֣י מֹשֶׁ֔ה וְאֶלְעָזָ֖ר הַכֹּהֵ֑ן אֲשֶׁ֨ר פָּֽקְד֜וּ אֶת־בְּנֵ֤י יִשְׂרָאֵל֙ בְּעַֽרְבֹ֣ת מוֹאָ֔ב עַ֖ל יַרְדֵּ֥ן יְרֵחֽוֹ׃"
          },
          {
            "num": "סה",
            "txt": "וּבְאֵ֨לֶּה֙ לֹא־הָ֣יָה אִ֔ישׁ מִפְּקוּדֵ֣י מֹשֶׁ֔ה וְאַֽהֲרֹ֖ן הַכֹּהֵ֑ן אֲשֶׁ֥ר פָּֽקְד֛וּ אֶת־בְּנֵ֥י יִשְׂרָאֵ֖ל בְּמִדְבַּ֥ר סִינָֽי׃"
          },
          {
            "txt": "כִּֽי־אָמַ֤ר יְהוָה֙ לָהֶ֔ם מ֥וֹת יָמֻ֖תוּ בַּמִּדְבָּ֑ר וְלֹֽא־נוֹתַ֤ר מֵהֶם֙ אִ֔ישׁ כִּ֚י אִם־כָּלֵ֣ב בֶּן־יְפֻנֶּ֔ה וִֽיהוֹשֻׁ֖עַ בִּן־נֽוּן׃ {ס}"
          }
        ]
      },
      {
        "num": "כז",
        "verses": [
          {
            "num": "א",
            "txt": "וַתִּקְרַ֜בְנָה בְּנ֣וֹת צְלָפְחָ֗ד בֶּן־חֵ֤פֶר בֶּן־גִּלְעָד֙ בֶּן־מָכִ֣יר בֶּן־מְנַשֶּׁ֔ה לְמִשְׁפְּחֹ֖ת מְנַשֶּׁ֣ה בֶן־יוֹסֵ֑ף וְאֵ֨לֶּה֙ שְׁמ֣וֹת בְּנֹתָ֔יו מַחְלָ֣ה נֹעָ֔ה וְחָגְלָ֥ה וּמִלְכָּ֖ה וְתִרְצָֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַֽתַּעֲמֹ֜דְנָה לִפְנֵ֣י מֹשֶׁ֗ה וְלִפְנֵי֙ אֶלְעָזָ֣ר הַכֹּהֵ֔ן וְלִפְנֵ֥י הַנְּשִׂיאִ֖ם וְכָל־הָֽעֵדָ֑ה פֶּ֥תַח אֹֽהֶל־מוֹעֵ֖ד לֵאמֹֽר׃"
          },
          {
            "num": "ג",
            "txt": "אָבִינוּ֮ מֵ֣ת בַּמִּדְבָּר֒ וְה֨וּא לֹֽא־הָיָ֜ה בְּת֣וֹךְ הָֽעֵדָ֗ה הַנּֽוֹעָדִ֛ים עַל־יְהוָ֖ה בַּֽעֲדַת־קֹ֑רַח כִּֽי־בְחֶטְא֣וֹ מֵ֔ת וּבָנִ֖ים לֹא־הָ֥יוּ לֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "לָ֣מָּה יִגָּרַ֤ע שֵׁם־אָבִ֨ינוּ֙ מִתּ֣וֹךְ מִשְׁפַּחְתּ֔וֹ כִּ֛י אֵ֥ין ל֖וֹ בֵּ֑ן תְּנָה־לָּ֣נוּ אֲחֻזָּ֔ה בְּת֖וֹךְ אֲחֵ֥י אָבִֽינוּ׃"
          },
          {
            "num": "ה",
            "txt": "וַיַּקְרֵ֥ב מֹשֶׁ֛ה אֶת־מִשְׁפָּטָ֖"
          },
          {
            "num": "ו",
            "txt": "לִפְנֵ֥י יְהוָֽה׃ {פ}"
          },
          {
            "num": "ז",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ח",
            "txt": "כֵּ֗ן בְּנ֣וֹת צְלָפְחָד֮ דֹּֽבְרֹת֒ נָתֹ֨ן תִּתֵּ֤ן לָהֶם֙ אֲחֻזַּ֣ת נַֽחֲלָ֔ה בְּת֖וֹךְ אֲחֵ֣י אֲבִיהֶ֑ם וְהַֽעֲבַרְתָּ֛ אֶת־נַֽחֲלַ֥ת אֲבִיהֶ֖ן לָהֶֽן׃"
          },
          {
            "num": "ט",
            "txt": "וְאֶל־בְּנֵ֥י יִשְׂרָאֵ֖ל תְּדַבֵּ֣ר לֵאמֹ֑ר אִ֣ישׁ כִּֽי־יָמ֗וּת וּבֵן֙ אֵ֣ין ל֔וֹ וְהַֽעֲבַרְתֶּ֥ם אֶת־נַֽחֲלָת֖וֹ לְבִתּֽוֹ׃"
          },
          {
            "num": "י",
            "txt": "וְאִם־אֵ֥ין ל֖וֹ בַּ֑ת וּנְתַתֶּ֥ם אֶת־נַֽחֲלָת֖וֹ לְאֶחָֽיו׃"
          },
          {
            "num": "יא",
            "txt": "וְאִם־אֵ֥ין ל֖וֹ אַחִ֑ים וּנְתַתֶּ֥ם אֶת־נַֽחֲלָת֖וֹ לַֽאֲחֵ֥י אָבִֽיו׃"
          },
          {
            "num": "יב",
            "txt": "וְאִם־אֵ֣ין אַחִים֮ לְאָבִיו֒ וּנְתַתֶּ֣ם אֶת־נַֽחֲלָת֗וֹ לִשְׁאֵר֞וֹ הַקָּרֹ֥ב אֵלָ֛יו מִמִּשְׁפַּחְתּ֖וֹ וְיָרַ֣שׁ אֹתָ֑הּ וְֽהָ֨יְתָ֜ה לִבְנֵ֤י יִשְׂרָאֵל֙ לְחֻקַּ֣ת מִשְׁפָּ֔ט כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה עֲלֵ֛ה אֶל־הַ֥ר הָֽעֲבָרִ֖ים הַזֶּ֑ה וּרְאֵה֙ אֶת־הָאָ֔רֶץ אֲשֶׁ֥ר נָתַ֖תִּי לִבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יד",
            "txt": "וְרָאִ֣יתָה אֹתָ֔הּ וְנֶֽאֱסַפְתָּ֥ אֶל־עַמֶּ֖יךָ גַּם־אָ֑תָּה כַּֽאֲשֶׁ֥ר נֶֽאֱסַ֖ף אַֽהֲרֹ֥ן אָחִֽיךָ׃"
          },
          {
            "num": "טו",
            "txt": "כַּֽאֲשֶׁר֩ מְרִיתֶ֨ם פִּ֜י בְּמִדְבַּר־צִ֗ן בִּמְרִיבַת֙ הָֽעֵדָ֔ה לְהַקְדִּישֵׁ֥נִי בַמַּ֖יִם לְעֵֽינֵיהֶ֑ם הֵ֛ם מֵֽי־מְרִיבַ֥ת קָדֵ֖שׁ מִדְבַּר־צִֽן׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "וַיְדַבֵּ֣ר מֹשֶׁ֔ה אֶל־יְהוָ֖ה לֵאמֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "יִפְקֹ֣ד יְהוָ֔ה אֱלֹהֵ֥י הָֽרוּחֹ֖ת לְכָל־בָּשָׂ֑ר אִ֖ישׁ עַל־הָֽעֵדָֽה׃"
          },
          {
            "num": "יח",
            "txt": "אֲשֶׁר־יֵצֵ֣א לִפְנֵיהֶ֗ם וַֽאֲשֶׁ֤ר יָבֹא֙ לִפְנֵיהֶ֔ם וַֽאֲשֶׁ֥ר יֽוֹצִיאֵ֖ם וַֽאֲשֶׁ֣ר יְבִיאֵ֑ם וְלֹ֤א תִֽהְיֶה֙ עֲדַ֣ת יְהוָ֔ה כַּצֹּ֕אן אֲשֶׁ֥ר אֵין־לָהֶ֖ם רֹעֶֽה׃"
          },
          {
            "num": "יט",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה קַח־לְךָ֙ אֶת־יְהוֹשֻׁ֣עַ בִּן־נ֔וּן אִ֖ישׁ אֲשֶׁר־ר֣וּחַ בּ֑וֹ וְסָֽמַכְתָּ֥ אֶת־יָֽדְךָ֖ עָלָֽיו׃"
          },
          {
            "num": "כ",
            "txt": "וְהַֽעֲמַדְתָּ֣ אֹת֗וֹ לִפְנֵי֙ אֶלְעָזָ֣ר הַכֹּהֵ֔ן וְלִפְנֵ֖י כָּל־הָֽעֵדָ֑ה וְצִוִּיתָ֥ה אֹת֖וֹ לְעֵֽינֵיהֶֽם׃"
          },
          {
            "num": "כא",
            "txt": "וְנָֽתַתָּ֥ה מֵהֽוֹדְךָ֖ עָלָ֑יו לְמַ֣עַן יִשְׁמְע֔וּ כָּל־עֲדַ֖ת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כב",
            "txt": "וְלִפְנֵ֨י אֶלְעָזָ֤ר הַכֹּהֵן֙ יַֽעֲמֹ֔ד וְשָׁ֥אַל ל֛וֹ בְּמִשְׁפַּ֥ט הָֽאוּרִ֖ים לִפְנֵ֣י יְהוָ֑ה עַל־פִּ֨יו יֵֽצְא֜וּ וְעַל־פִּ֣יו יָבֹ֗אוּ ה֛וּא וְכָל־בְּנֵֽי־יִשְׂרָאֵ֥ל אִתּ֖וֹ וְכָל־הָֽעֵדָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וַיַּ֣עַשׂ מֹשֶׁ֔ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֹת֑וֹ וַיִּקַּ֣ח אֶת־יְהוֹשֻׁ֗עַ וַיַּֽעֲמִדֵ֨הוּ֙ לִפְנֵי֙ אֶלְעָזָ֣ר הַכֹּהֵ֔ן וְלִפְנֵ֖י כָּל־הָֽעֵדָֽה׃"
          },
          {
            "txt": "וַיִּסְמֹ֧ךְ אֶת־יָדָ֛יו עָלָ֖יו וַיְצַוֵּ֑הוּ כַּֽאֲשֶׁ֛ר דִּבֶּ֥ר יְהוָ֖ה בְּיַד־מֹשֶֽׁה׃ {פ}"
          }
        ]
      },
      {
        "num": "כח",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "צַ֚ו אֶת־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם אֶת־קָרְבָּנִ֨י לַחְמִ֜י לְאִשַּׁ֗י רֵ֚יחַ נִֽיחֹחִ֔י תִּשְׁמְר֕וּ לְהַקְרִ֥יב לִ֖י בְּמֽוֹעֲדֽוֹ׃"
          },
          {
            "num": "ג",
            "txt": "וְאָֽמַרְתָּ֣ לָהֶ֔ם זֶ֚ה הָֽאִשֶּׁ֔ה אֲשֶׁ֥ר תַּקְרִ֖יבוּ לַֽיהוָ֑ה כְּבָשִׂ֨ים בְּנֵֽי־שָׁנָ֧ה תְמִימִ֛ם שְׁנַ֥יִם לַיּ֖וֹם עֹלָ֥ה תָמִֽיד׃"
          },
          {
            "num": "ד",
            "txt": "אֶת־הַכֶּ֥בֶשׂ אֶחָ֖ד תַּֽעֲשֶׂ֣ה בַבֹּ֑קֶר וְאֵת֙ הַכֶּ֣בֶשׂ הַשֵּׁנִ֔י תַּֽעֲשֶׂ֖ה בֵּ֥ין הָֽעַרְבָּֽיִם׃"
          },
          {
            "num": "ה",
            "txt": "וַֽעֲשִׂירִ֧ית הָֽאֵיפָ֛ה סֹ֖לֶת לְמִנְחָ֑ה בְּלוּלָ֛ה בְּשֶׁ֥מֶן כָּתִ֖ית רְבִיעִ֥ת הַהִֽין׃"
          },
          {
            "num": "ו",
            "txt": "עֹלַ֖ת תָּמִ֑יד הָֽעֲשֻׂיָה֙ בְּהַ֣ר סִינַ֔י לְרֵ֣יחַ נִיחֹ֔חַ אִשֶּׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וְנִסְכּוֹ֙ רְבִיעִ֣ת הַהִ֔ין לַכֶּ֖בֶשׂ הָֽאֶחָ֑ד בַּקֹּ֗דֶשׁ הַסֵּ֛ךְ נֶ֥סֶךְ שֵׁכָ֖ר לַֽיהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וְאֵת֙ הַכֶּ֣בֶשׂ הַשֵּׁנִ֔י תַּֽעֲשֶׂ֖ה בֵּ֣ין הָֽעַרְבָּ֑יִם כְּמִנְחַ֨ת הַבֹּ֤קֶר וּכְנִסְכּוֹ֙ תַּֽעֲשֶׂ֔ה אִשֵּׁ֛ה רֵ֥יחַ נִיחֹ֖חַ לַֽיהוָֽה׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "וּבְיוֹם֙ הַשַּׁבָּ֔ת שְׁנֵֽי־כְבָשִׂ֥ים בְּנֵֽי־שָׁנָ֖ה תְּמִימִ֑ם וּשְׁנֵ֣י עֶשְׂרֹנִ֗ים סֹ֧לֶת מִנְחָ֛ה בְּלוּלָ֥ה בַשֶּׁ֖מֶן וְנִסְכּֽוֹ׃"
          },
          {
            "num": "י",
            "txt": "עֹלַ֥ת שַׁבַּ֖ת בְּשַׁבַּתּ֑וֹ עַל־עֹלַ֥ת הַתָּמִ֖יד וְנִסְכָּֽהּ׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וּבְרָאשֵׁי֙ חָדְשֵׁיכֶ֔ם תַּקְרִ֥יבוּ עֹלָ֖ה לַֽיהוָ֑ה פָּרִ֨ים בְּנֵֽי־בָקָ֤ר שְׁנַ֨יִם֙ וְאַ֣יִל אֶחָ֔ד כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה שִׁבְעָ֖ה תְּמִימִֽם׃"
          },
          {
            "num": "יב",
            "txt": "וּשְׁלֹשָׁ֣ה עֶשְׂרֹנִ֗ים סֹ֤לֶת מִנְחָה֙ בְּלוּלָ֣ה בַשֶּׁ֔מֶן לַפָּ֖ר הָֽאֶחָ֑ד וּשְׁנֵ֣י עֶשְׂרֹנִ֗ים סֹ֤לֶת מִנְחָה֙ בְּלוּלָ֣ה בַשֶּׁ֔מֶן לָאַ֖יִל הָֽאֶחָֽד׃"
          },
          {
            "num": "יג",
            "txt": "וְעִשָּׂרֹ֣ן עִשָּׂר֗וֹן סֹ֤לֶת מִנְחָה֙ בְּלוּלָ֣ה בַשֶּׁ֔מֶן לַכֶּ֖בֶשׂ הָֽאֶחָ֑ד עֹלָה֙ רֵ֣יחַ נִיחֹ֔חַ אִשֶּׁ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְנִסְכֵּיהֶ֗ם חֲצִ֣י הַהִין֩ יִֽהְיֶ֨ה לַפָּ֜ר וּשְׁלִישִׁ֧ת הַהִ֣ין לָאַ֗יִל וּרְבִיעִ֥ת הַהִ֛ין לַכֶּ֖בֶשׂ יָ֑יִן זֹ֣את עֹלַ֥ת חֹ֨דֶשׁ֙ בְּחָדְשׁ֔וֹ לְחָדְשֵׁ֖י הַשָּׁנָֽה׃"
          },
          {
            "num": "טו",
            "txt": "וּשְׂעִ֨יר עִזִּ֥ים אֶחָ֛ד לְחַטָּ֖את לַֽיהוָ֑ה עַל־עֹלַ֧ת הַתָּמִ֛יד יֵֽעָשֶׂ֖ה וְנִסְכּֽוֹ׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "וּבַחֹ֣דֶשׁ הָֽרִאשׁ֗וֹן בְּאַרְבָּעָ֥ה עָשָׂ֛ר י֖וֹם לַחֹ֑דֶשׁ פֶּ֖סַח לַֽיהוָֽה׃"
          },
          {
            "num": "יז",
            "txt": "וּבַֽחֲמִשָּׁ֨ה עָשָׂ֥ר י֛וֹם לַחֹ֥דֶשׁ הַזֶּ֖ה חָ֑ג שִׁבְעַ֣ת יָמִ֔ים מַצּ֖וֹת יֵֽאָכֵֽל׃"
          },
          {
            "num": "יח",
            "txt": "בַּיּ֥וֹם הָֽרִאשׁ֖וֹן מִקְרָא־קֹ֑דֶשׁ כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃"
          },
          {
            "num": "יט",
            "txt": "וְהִקְרַבְתֶּ֨ם אִשֶּׁ֤ה עֹלָה֙ לַֽיהוָ֔ה פָּרִ֧ים בְּנֵֽי־בָקָ֛ר שְׁנַ֖יִם וְאַ֣יִל אֶחָ֑ד וְשִׁבְעָ֤ה כְבָשִׂים֙ בְּנֵ֣י שָׁנָ֔ה תְּמִימִ֖ם יִֽהְי֥וּ לָכֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "וּמִ֨נְחָתָ֔ם סֹ֖לֶת בְּלוּלָ֣ה בַשָּׁ֑מֶן שְׁלֹשָׁ֨ה עֶשְׂרֹנִ֜ים לַפָּ֗ר וּשְׁנֵ֧י עֶשְׂרֹנִ֛ים לָאַ֖יִל תַּֽעֲשֽׂוּ׃"
          },
          {
            "num": "כא",
            "txt": "עִשָּׂר֤וֹן עִשָּׂרוֹן֙ תַּֽעֲשֶׂ֔ה לַכֶּ֖בֶשׂ הָֽאֶחָ֑ד לְשִׁבְעַ֖ת הַכְּבָשִֽׂים׃"
          },
          {
            "num": "כב",
            "txt": "וּשְׂעִ֥יר חַטָּ֖את אֶחָ֑ד לְכַפֵּ֖ר עֲלֵיכֶֽם׃"
          },
          {
            "num": "כג",
            "txt": "מִלְּבַד֙ עֹלַ֣ת הַבֹּ֔קֶר אֲשֶׁ֖ר לְעֹלַ֣ת הַתָּמִ֑יד תַּֽעֲשׂ֖וּ אֶת־אֵֽלֶּה׃"
          },
          {
            "num": "כד",
            "txt": "כָּאֵ֜לֶּה תַּֽעֲשׂ֤וּ לַיּוֹם֙ שִׁבְעַ֣ת יָמִ֔ים לֶ֛חֶם אִשֵּׁ֥ה רֵֽיחַ־נִיחֹ֖חַ לַֽיהוָ֑ה עַל־עוֹלַ֧ת הַתָּמִ֛יד יֵֽעָשֶׂ֖ה וְנִסְכּֽוֹ׃"
          },
          {
            "num": "כה",
            "txt": "וּבַיּוֹם֙ הַשְּׁבִיעִ֔י מִקְרָא־קֹ֖דֶשׁ יִֽהְיֶ֣ה לָכֶ֑ם כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "וּבְי֣וֹם הַבִּכּוּרִ֗ים בְּהַקְרִ֨יבְכֶ֜ם מִנְחָ֤ה חֲדָשָׁה֙ לַֽיהוָ֔ה בְּשָׁבֻעֹ֖תֵיכֶ֑ם מִֽקְרָא־קֹ֨דֶשׁ֙ יִֽהְיֶ֣ה לָכֶ֔ם כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃"
          },
          {
            "num": "כז",
            "txt": "וְהִקְרַבְתֶּ֨ם עוֹלָ֜ה לְרֵ֤יחַ נִיחֹ֨חַ֙ לַֽיהוָ֔ה פָּרִ֧ים בְּנֵֽי־בָקָ֛ר שְׁנַ֖יִם אַ֣יִל אֶחָ֑ד שִׁבְעָ֥ה כְבָשִׂ֖ים בְּנֵ֥י שָׁנָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וּמִ֨נְחָתָ֔ם סֹ֖לֶת בְּלוּלָ֣ה בַשָּׁ֑מֶן שְׁלֹשָׁ֤ה עֶשְׂרֹנִים֙ לַפָּ֣ר הָֽאֶחָ֔ד שְׁנֵי֙ עֶשְׂרֹנִ֔ים לָאַ֖יִל הָֽאֶחָֽד׃"
          },
          {
            "num": "כט",
            "txt": "עִשָּׂרוֹן֙ עִשָּׂר֔וֹן לַכֶּ֖בֶשׂ הָֽאֶחָ֑ד לְשִׁבְעַ֖ת הַכְּבָשִֽׂים׃"
          },
          {
            "num": "ל",
            "txt": "שְׂעִ֥יר עִזִּ֖ים אֶחָ֑ד לְכַפֵּ֖ר עֲלֵיכֶֽם׃"
          },
          {
            "num": "לא",
            "txt": "מִלְּבַ֞ד עֹלַ֧ת הַתָּמִ֛יד וּמִנְחָת֖וֹ תַּֽעֲשׂ֑וּ תְּמִימִ֥ם יִֽהְיוּ־לָכֶ֖ם וְנִסְכֵּיהֶֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "כט",
        "verses": [
          {
            "num": "א",
            "txt": "וּבַחֹ֨דֶשׁ הַשְּׁבִיעִ֜י בְּאֶחָ֣ד לַחֹ֗דֶשׁ מִֽקְרָא־קֹ֨דֶשׁ֙ יִֽהְיֶ֣ה לָכֶ֔ם כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֣א תַֽעֲשׂ֑וּ י֥וֹם תְּרוּעָ֖ה יִֽהְיֶ֥ה לָכֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "וַֽעֲשִׂיתֶ֨ם עֹלָ֜ה לְרֵ֤יחַ נִיחֹ֨חַ֙ לַֽיהוָ֔ה פַּ֧ר בֶּן־בָּקָ֛ר אֶחָ֖ד אַ֣יִל אֶחָ֑ד כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה שִׁבְעָ֖ה תְּמִימִֽם׃"
          },
          {
            "num": "ג",
            "txt": "וּמִ֨נְחָתָ֔ם סֹ֖לֶת בְּלוּלָ֣ה בַשָּׁ֑מֶן שְׁלֹשָׁ֤ה עֶשְׂרֹנִים֙ לַפָּ֔ר שְׁנֵ֥י עֶשְׂרֹנִ֖ים לָאָֽיִל׃"
          },
          {
            "num": "ד",
            "txt": "וְעִשָּׂר֣וֹן אֶחָ֔ד לַכֶּ֖בֶשׂ הָֽאֶחָ֑ד לְשִׁבְעַ֖ת הַכְּבָשִֽׂים׃"
          },
          {
            "num": "ה",
            "txt": "וּשְׂעִיר־עִזִּ֥ים אֶחָ֖ד חַטָּ֑את לְכַפֵּ֖ר עֲלֵיכֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "מִלְּבַד֩ עֹלַ֨ת הַחֹ֜דֶשׁ וּמִנְחָתָ֗הּ וְעֹלַ֤ת הַתָּמִיד֙ וּמִנְחָתָ֔הּ וְנִסְכֵּיהֶ֖ם כְּמִשְׁפָּטָ֑ם לְרֵ֣יחַ נִיחֹ֔חַ אִשֶּׁ֖ה לַֽיהוָֽה׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "וּבֶֽעָשׂוֹר֩ לַחֹ֨דֶשׁ הַשְּׁבִיעִ֜י הַזֶּ֗ה מִֽקְרָא־קֹ֨דֶשׁ֙ יִֽהְיֶ֣ה לָכֶ֔ם וְעִנִּיתֶ֖ם אֶת־נַפְשֹֽׁתֵיכֶ֑ם כָּל־מְלָאכָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃"
          },
          {
            "num": "ח",
            "txt": "וְהִקְרַבְתֶּ֨ם עֹלָ֤ה לַֽיהוָה֙ רֵ֣יחַ נִיחֹ֔חַ פַּ֧ר בֶּן־בָּקָ֛ר אֶחָ֖ד אַ֣יִל אֶחָ֑ד כְּבָשִׂ֤ים בְּנֵֽי־שָׁנָה֙ שִׁבְעָ֔ה תְּמִימִ֖ם יִֽהְי֥וּ לָכֶֽם׃"
          },
          {
            "num": "ט",
            "txt": "וּמִ֨נְחָתָ֔ם סֹ֖לֶת בְּלוּלָ֣ה בַשָּׁ֑מֶן שְׁלֹשָׁ֤ה עֶשְׂרֹנִים֙ לַפָּ֔ר שְׁנֵי֙ עֶשְׂרֹנִ֔ים לָאַ֖יִל הָֽאֶחָֽד׃"
          },
          {
            "num": "י",
            "txt": "עִשָּׂרוֹן֙ עִשָּׂר֔וֹן לַכֶּ֖בֶשׂ הָֽאֶחָ֑ד לְשִׁבְעַ֖ת הַכְּבָשִֽׂים׃"
          },
          {
            "num": "יא",
            "txt": "שְׂעִיר־עִזִּ֥ים אֶחָ֖ד חַטָּ֑את מִלְּבַ֞ד חַטַּ֤את הַכִּפֻּרִים֙ וְעֹלַ֣ת הַתָּמִ֔יד וּמִנְחָתָ֖הּ וְנִסְכֵּיהֶֽם׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וּבַֽחֲמִשָּׁה֩ עָשָׂ֨ר י֜וֹם לַחֹ֣דֶשׁ הַשְּׁבִיעִ֗י מִֽקְרָא־קֹ֨דֶשׁ֙ יִֽהְיֶ֣ה לָכֶ֔ם כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֣א תַֽעֲשׂ֑וּ וְחַגֹּתֶ֥ם חַ֛ג לַֽיהוָ֖ה שִׁבְעַ֥ת יָמִֽים׃"
          },
          {
            "num": "יג",
            "txt": "וְהִקְרַבְתֶּ֨ם עֹלָ֜ה אִשֵּׁ֨ה רֵ֤יחַ נִיחֹ֨חַ֙ לַֽיהוָ֔ה פָּרִ֧ים בְּנֵֽי־בָקָ֛ר שְׁלֹשָׁ֥ה עָשָׂ֖ר אֵילִ֣ם שְׁנָ֑יִם כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה אַרְבָּעָ֥ה עָשָׂ֖ר תְּמִימִ֥ם יִֽהְיֽוּ׃"
          },
          {
            "num": "יד",
            "txt": "וּמִ֨נְחָתָ֔ם סֹ֖לֶת בְּלוּלָ֣ה בַשָּׁ֑מֶן שְׁלֹשָׁ֨ה עֶשְׂרֹנִ֜ים לַפָּ֣ר הָֽאֶחָ֗ד לִשְׁלֹשָׁ֤ה עָשָׂר֙ פָּרִ֔ים שְׁנֵ֤י עֶשְׂרֹנִים֙ לָאַ֣יִל הָֽאֶחָ֔ד לִשְׁנֵ֖י הָֽאֵילִֽם׃"
          },
          {
            "num": "טו",
            "txt": "וְעִשָּׂרוֹׄן֙ עִשָּׂר֔וֹן לַכֶּ֖בֶשׂ הָֽאֶחָ֑ד לְאַרְבָּעָ֥ה עָשָׂ֖ר כְּבָשִֽׂים׃"
          },
          {
            "num": "טז",
            "txt": "וּשְׂעִיר־עִזִּ֥ים אֶחָ֖ד חַטָּ֑את מִלְּבַד֙ עֹלַ֣ת הַתָּמִ֔יד מִנְחָתָ֖הּ וְנִסְכָּֽהּ׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וּבַיּ֣וֹם הַשֵּׁנִ֗י פָּרִ֧ים בְּנֵֽי־בָקָ֛ר שְׁנֵ֥ים עָשָׂ֖ר אֵילִ֣ם שְׁנָ֑יִם כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה אַרְבָּעָ֥ה עָשָׂ֖ר תְּמִימִֽם׃"
          },
          {
            "num": "יח",
            "txt": "וּמִנְחָתָ֣ם וְנִסְכֵּיהֶ֡ם לַ֠פָּרִים לָֽאֵילִ֧ם וְלַכְּבָשִׂ֛ים בְּמִסְפָּרָ֖ם כַּמִּשְׁפָּֽט׃"
          },
          {
            "num": "יט",
            "txt": "וּשְׂעִיר־עִזִּ֥ים אֶחָ֖ד חַטָּ֑את מִלְּבַד֙ עֹלַ֣ת הַתָּמִ֔יד וּמִנְחָתָ֖הּ וְנִסְכֵּיהֶֽם׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וּבַיּ֧וֹם הַשְּׁלִישִׁ֛י פָּרִ֥ים עַשְׁתֵּֽי־עָשָׂ֖ר אֵילִ֣ם שְׁנָ֑יִם כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה אַרְבָּעָ֥ה עָשָׂ֖ר תְּמִימִֽם׃"
          },
          {
            "num": "כא",
            "txt": "וּמִנְחָתָ֣ם וְנִסְכֵּיהֶ֡ם לַ֠פָּרִים לָֽאֵילִ֧ם וְלַכְּבָשִׂ֛ים בְּמִסְפָּרָ֖ם כַּמִּשְׁפָּֽט׃"
          },
          {
            "num": "כב",
            "txt": "וּשְׂעִ֥יר חַטָּ֖את אֶחָ֑ד מִלְּבַד֙ עֹלַ֣ת הַתָּמִ֔יד וּמִנְחָתָ֖הּ וְנִסְכָּֽהּ׃ {ס}"
          },
          {
            "num": "כג",
            "txt": "וּבַיּ֧וֹם הָֽרְבִיעִ֛י פָּרִ֥ים עֲשָׂרָ֖ה אֵילִ֣ם שְׁנָ֑יִם כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה אַרְבָּעָ֥ה עָשָׂ֖ר תְּמִימִֽם׃"
          },
          {
            "num": "כד",
            "txt": "מִנְחָתָ֣ם וְנִסְכֵּיהֶ֡ם לַ֠פָּרִים לָֽאֵילִ֧ם וְלַכְּבָשִׂ֛ים בְּמִסְפָּרָ֖ם כַּמִּשְׁפָּֽט׃"
          },
          {
            "num": "כה",
            "txt": "וּשְׂעִיר־עִזִּ֥ים אֶחָ֖ד חַטָּ֑את מִלְּבַד֙ עֹלַ֣ת הַתָּמִ֔יד מִנְחָתָ֖הּ וְנִסְכָּֽהּ׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "וּבַיּ֧וֹם הַֽחֲמִישִׁ֛י פָּרִ֥ים תִּשְׁעָ֖ה אֵילִ֣ם שְׁנָ֑יִם כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה אַרְבָּעָ֥ה עָשָׂ֖ר תְּמִימִֽם׃"
          },
          {
            "num": "כז",
            "txt": "וּמִנְחָתָ֣ם וְנִסְכֵּיהֶ֡ם לַ֠פָּרִים לָֽאֵילִ֧ם וְלַכְּבָשִׂ֛ים בְּמִסְפָּרָ֖ם כַּמִּשְׁפָּֽט׃"
          },
          {
            "num": "כח",
            "txt": "וּשְׂעִ֥יר חַטָּ֖את אֶחָ֑ד מִלְּבַד֙ עֹלַ֣ת הַתָּמִ֔יד וּמִנְחָתָ֖הּ וְנִסְכָּֽהּ׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "וּבַיּ֧וֹם הַשִּׁשִּׁ֛י פָּרִ֥ים שְׁמֹנָ֖ה אֵילִ֣ם שְׁנָ֑יִם כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה אַרְבָּעָ֥ה עָשָׂ֖ר תְּמִימִֽם׃"
          },
          {
            "num": "ל",
            "txt": "וּמִנְחָתָ֣ם וְנִסְכֵּיהֶ֡ם לַ֠פָּרִים לָֽאֵילִ֧ם וְלַכְּבָשִׂ֛ים בְּמִסְפָּרָ֖ם כַּמִּשְׁפָּֽט׃"
          },
          {
            "num": "לא",
            "txt": "וּשְׂעִ֥יר חַטָּ֖את אֶחָ֑ד מִלְּבַד֙ עֹלַ֣ת הַתָּמִ֔יד מִנְחָתָ֖הּ וּנְסָכֶֽיהָ׃ {ס}"
          },
          {
            "num": "לב",
            "txt": "וּבַיּ֧וֹם הַשְּׁבִיעִ֛י פָּרִ֥ים שִׁבְעָ֖ה אֵילִ֣ם שְׁנָ֑יִם כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה אַרְבָּעָ֥ה עָשָׂ֖ר תְּמִימִֽם׃"
          },
          {
            "num": "לג",
            "txt": "וּמִנְחָתָ֣ם וְנִסְכֵּהֶ֡ם לַ֠פָּרִים לָֽאֵילִ֧ם וְלַכְּבָשִׂ֛ים בְּמִסְפָּרָ֖ם כְּמִשְׁפָּטָֽם׃"
          },
          {
            "num": "לד",
            "txt": "וּשְׂעִ֥יר חַטָּ֖את אֶחָ֑ד מִלְּבַד֙ עֹלַ֣ת הַתָּמִ֔יד מִנְחָתָ֖הּ וְנִסְכָּֽהּ׃ {ס}"
          },
          {
            "num": "לה",
            "txt": "בַּיּוֹם֙ הַשְּׁמִינִ֔י עֲצֶ֖רֶת תִּֽהְיֶ֣ה לָכֶ֑ם כָּל־מְלֶ֥אכֶת עֲבֹדָ֖ה לֹ֥א תַֽעֲשֽׂוּ׃"
          },
          {
            "num": "לו",
            "txt": "וְהִקְרַבְתֶּ֨ם עֹלָ֜ה אִשֵּׁ֨ה רֵ֤יחַ נִיחֹ֨חַ֙ לַֽיהוָ֔ה פַּ֥ר אֶחָ֖ד אַ֣יִל אֶחָ֑ד כְּבָשִׂ֧ים בְּנֵֽי־שָׁנָ֛ה שִׁבְעָ֖ה תְּמִימִֽם׃"
          },
          {
            "num": "לז",
            "txt": "מִנְחָתָ֣ם וְנִסְכֵּיהֶ֗ם לַפָּ֨ר לָאַ֧יִל וְלַכְּבָשִׂ֛ים בְּמִסְפָּרָ֖ם כַּמִּשְׁפָּֽט׃"
          },
          {
            "num": "לח",
            "txt": "וּשְׂעִ֥יר חַטָּ֖את אֶחָ֑ד מִלְּבַד֙ עֹלַ֣ת הַתָּמִ֔יד וּמִנְחָתָ֖הּ וְנִסְכָּֽהּ׃"
          },
          {
            "num": "לט",
            "txt": "אֵ֛לֶּה תַּֽעֲשׂ֥וּ לַֽיהוָ֖ה בְּמֽוֹעֲדֵיכֶ֑ם לְבַ֨ד מִנִּדְרֵיכֶ֜ם וְנִדְבֹֽתֵיכֶ֗ם לְעֹלֹֽתֵיכֶם֙ וּלְמִנְחֹ֣תֵיכֶ֔ם וּלְנִסְכֵּיכֶ֖ם וּלְשַׁלְמֵיכֶֽם׃"
          }
        ]
      },
      {
        "num": "ל",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֹּ֥אמֶר מֹשֶׁ֖ה אֶל־בְּנֵ֣י יִשְׂרָאֵ֑ל כְּכֹ֛ל אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃ {פ}"
          },
          {
            "num": "ב",
            "txt": "וַיְדַבֵּ֤ר מֹשֶׁה֙ אֶל־רָאשֵׁ֣י הַמַּטּ֔וֹת לִבְנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר זֶ֣ה הַדָּבָ֔ר אֲשֶׁ֖ר צִוָּ֥ה יְהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "אִישׁ֩ כִּֽי־יִדֹּ֨ר נֶ֜דֶר לַֽיהוָ֗ה אֽוֹ־הִשָּׁ֤בַע שְׁבֻעָה֙ לֶאְסֹ֤ר אִסָּר֙ עַל־נַפְשׁ֔וֹ לֹ֥א יַחֵ֖ל דְּבָר֑וֹ כְּכָל־הַיֹּצֵ֥א מִפִּ֖יו יַֽעֲשֶֽׂה׃"
          },
          {
            "num": "ד",
            "txt": "וְאִשָּׁ֕ה כִּֽי־תִדֹּ֥ר נֶ֖דֶר לַֽיהוָ֑ה וְאָֽסְרָ֥ה אִסָּ֛ר בְּבֵ֥ית אָבִ֖יהָ בִּנְעֻרֶֽיהָ׃"
          },
          {
            "num": "ה",
            "txt": "וְשָׁמַ֨ע אָבִ֜יהָ אֶת־נִדְרָ֗הּ וֶֽאֱסָרָהּ֙ אֲשֶׁ֣ר אָֽסְרָ֣ה עַל־נַפְשָׁ֔הּ וְהֶֽחֱרִ֥ישׁ לָ֖הּ אָבִ֑יהָ וְקָ֨מוּ֙ כָּל־נְדָרֶ֔יהָ וְכָל־אִסָּ֛ר אֲשֶׁר־אָֽסְרָ֥ה עַל־נַפְשָׁ֖הּ יָקֽוּם׃"
          },
          {
            "num": "ו",
            "txt": "וְאִם־הֵנִ֨יא אָבִ֣יהָ אֹתָהּ֮ בְּי֣וֹם שָׁמְעוֹ֒ כָּל־נְדָרֶ֗יהָ וֶֽאֱסָרֶ֛יהָ אֲשֶׁר־אָֽסְרָ֥ה עַל־נַפְשָׁ֖הּ לֹ֣א יָק֑וּם וַֽיהוָה֙ יִֽסְלַח־לָ֔הּ כִּֽי־הֵנִ֥יא אָבִ֖יהָ אֹתָֽהּ׃"
          },
          {
            "num": "ז",
            "txt": "וְאִם־הָי֤וֹ תִֽהְיֶה֙ לְאִ֔ישׁ וּנְדָרֶ֖יהָ עָלֶ֑יהָ א֚וֹ מִבְטָ֣א שְׂפָתֶ֔יהָ אֲשֶׁ֥ר אָֽסְרָ֖ה עַל־נַפְשָֽׁהּ׃"
          },
          {
            "num": "ח",
            "txt": "וְשָׁמַ֥ע אִישָׁ֛הּ בְּי֥וֹם שָׁמְע֖וֹ וְהֶֽחֱרִ֣ישׁ לָ֑הּ וְקָ֣מוּ נְדָרֶ֗יהָ וֶֽאֱסָרֶ֛הָ אֲשֶׁר־אָֽסְרָ֥ה עַל־נַפְשָׁ֖הּ יָקֻֽמוּ׃"
          },
          {
            "num": "ט",
            "txt": "וְ֠אִם בְּי֨וֹם שְׁמֹ֣עַ אִישָׁהּ֮ יָנִ֣יא אוֹתָהּ֒ וְהֵפֵ֗ר אֶת־נִדְרָהּ֙ אֲשֶׁ֣ר עָלֶ֔יהָ וְאֵת֙ מִבְטָ֣א שְׂפָתֶ֔יהָ אֲשֶׁ֥ר אָֽסְרָ֖ה עַל־נַפְשָׁ֑הּ וַֽיהוָ֖ה יִֽסְלַֽח־לָֽהּ׃"
          },
          {
            "num": "י",
            "txt": "וְנֵ֥דֶר אַלְמָנָ֖ה וּגְרוּשָׁ֑ה כֹּ֛ל אֲשֶׁר־אָֽסְרָ֥ה עַל־נַפְשָׁ֖הּ יָק֥וּם עָלֶֽיהָ׃"
          },
          {
            "num": "יא",
            "txt": "וְאִם־בֵּ֥ית אִישָׁ֖הּ נָדָ֑רָה אֽוֹ־אָסְרָ֥ה אִסָּ֛ר עַל־נַפְשָׁ֖הּ בִּשְׁבֻעָֽה׃"
          },
          {
            "num": "יב",
            "txt": "וְשָׁמַ֤ע אִישָׁהּ֙ וְהֶֽחֱרִ֣שׁ לָ֔הּ לֹ֥א הֵנִ֖יא אֹתָ֑הּ וְקָ֨מוּ֙ כָּל־נְדָרֶ֔יהָ וְכָל־אִסָּ֛ר אֲשֶׁר־אָֽסְרָ֥ה עַל־נַפְשָׁ֖הּ יָקֽוּם׃"
          },
          {
            "num": "יג",
            "txt": "וְאִם־הָפֵר֩ יָפֵ֨ר אֹתָ֥ם ׀ אִישָׁהּ֮ בְּי֣וֹם שָׁמְעוֹ֒ כָּל־מוֹצָ֨א שְׂפָתֶ֧יהָ לִנְדָרֶ֛יהָ וּלְאִסַּ֥ר נַפְשָׁ֖הּ לֹ֣א יָק֑וּם אִישָׁ֣הּ הֲפֵרָ֔ם וַֽיהוָ֖ה יִֽסְלַֽח־לָֽהּ׃"
          },
          {
            "num": "יד",
            "txt": "כָּל־נֵ֛דֶר וְכָל־שְׁבֻעַ֥ת אִסָּ֖ר לְעַנֹּ֣ת נָ֑פֶשׁ אִישָׁ֥הּ יְקִימֶ֖נּוּ וְאִישָׁ֥הּ יְפֵרֶֽנּוּ׃"
          },
          {
            "num": "טו",
            "txt": "וְאִם־הַֽחֲרֵשׁ֩ יַֽחֲרִ֨ישׁ לָ֥הּ אִישָׁהּ֮ מִיּ֣וֹם אֶל־יוֹם֒ וְהֵקִים֙ אֶת־כָּל־נְדָרֶ֔יהָ א֥וֹ אֶת־כָּל־אֱסָרֶ֖יהָ אֲשֶׁ֣ר עָלֶ֑יהָ הֵקִ֣ים אֹתָ֔ם כִּֽי־הֶחֱרִ֥שׁ לָ֖הּ בְּי֥וֹם שָׁמְעֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "וְאִם־הָפֵ֥ר יָפֵ֛ר אֹתָ֖ם אַֽחֲרֵ֣י שָׁמְע֑וֹ וְנָשָׂ֖א אֶת־עֲוֺנָֽהּ׃"
          },
          {
            "num": "יז",
            "txt": "אֵ֣לֶּה הַֽחֻקִּ֗ים אֲשֶׁ֨ר צִוָּ֤ה יְהוָה֙ אֶת־מֹשֶׁ֔ה בֵּ֥ין אִ֖ישׁ לְאִשְׁתּ֑וֹ בֵּֽין־אָ֣ב לְבִתּ֔וֹ בִּנְעֻרֶ֖יהָ בֵּ֥ית אָבִֽיהָ׃ {פ}"
          }
        ]
      },
      {
        "num": "לא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "נְקֹ֗ם נִקְמַת֙ בְּנֵ֣י יִשְׂרָאֵ֔ל מֵאֵ֖ת הַמִּדְיָנִ֑ים אַחַ֖ר תֵּֽאָסֵ֥ף אֶל־עַמֶּֽיךָ׃"
          },
          {
            "num": "ג",
            "txt": "וַיְדַבֵּ֤ר מֹשֶׁה֙ אֶל־הָעָ֣ם לֵאמֹ֔ר הֵחָֽלְצ֧וּ מֵֽאִתְּכֶ֛ם אֲנָשִׁ֖ים לַצָּבָ֑א וְיִֽהְיוּ֙ עַל־מִדְיָ֔ן לָתֵ֥ת נִקְמַת־יְהוָ֖ה בְּמִדְיָֽן׃"
          },
          {
            "num": "ד",
            "txt": "אֶ֚לֶף לַמַּטֶּ֔ה אֶ֖לֶף לַמַּטֶּ֑ה לְכֹל֙ מַטּ֣וֹת יִשְׂרָאֵ֔ל תִּשְׁלְח֖וּ לַצָּבָֽא׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּמָּֽסְרוּ֙ מֵֽאַלְפֵ֣י יִשְׂרָאֵ֔ל אֶ֖לֶף לַמַּטֶּ֑ה שְׁנֵים־עָשָׂ֥ר אֶ֖לֶף חֲלוּצֵ֥י צָבָֽא׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּשְׁלַ֨ח אֹתָ֥ם מֹשֶׁ֛ה אֶ֥לֶף לַמַּטֶּ֖ה לַצָּבָ֑א אֹ֠תָם וְאֶת־פִּ֨ינְחָ֜ס בֶּן־אֶלְעָזָ֤ר הַכֹּהֵן֙ לַצָּבָ֔א וּכְלֵ֥י הַקֹּ֛דֶשׁ וַחֲצֹֽצְר֥וֹת הַתְּרוּעָ֖ה בְּיָדֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "וַֽיִּצְבְּאוּ֙ עַל־מִדְיָ֔ן כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶׁ֑ה וַיַּֽהַרְג֖וּ כָּל־זָכָֽר׃"
          },
          {
            "num": "ח",
            "txt": "וְאֶת־מַלְכֵ֨י מִדְיָ֜ן הָֽרְג֣וּ עַל־חַלְלֵיהֶ֗ם אֶת־אֱוִ֤י וְאֶת־רֶ֨קֶם֙ וְאֶת־צ֤וּר וְאֶת־חוּר֙ וְאֶת־רֶ֔בַע חֲמֵ֖שֶׁת מַלְכֵ֣י מִדְיָ֑ן וְאֵת֙ בִּלְעָ֣ם בֶּן־בְּע֔וֹר הָֽרְג֖וּ בֶּחָֽרֶב׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּשְׁבּ֧וּ בְנֵֽי־יִשְׂרָאֵ֛ל אֶת־נְשֵׁ֥י מִדְיָ֖ן וְאֶת־טַפָּ֑ם וְאֵ֨ת כָּל־בְּהֶמְתָּ֧ם וְאֶת־כָּל־מִקְנֵהֶ֛ם וְאֶת־כָּל־חֵילָ֖ם בָּזָֽזוּ׃"
          },
          {
            "num": "י",
            "txt": "וְאֵ֤ת כָּל־עָֽרֵיהֶם֙ בְּמ֣וֹשְׁבֹתָ֔ם וְאֵ֖ת כָּל־טִֽירֹתָ֑ם שָֽׂרְפ֖וּ בָּאֵֽשׁ׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּקְחוּ֙ אֶת־כָּל־הַשָּׁלָ֔ל וְאֵ֖ת כָּל־הַמַּלְק֑וֹחַ בָּֽאָדָ֖ם וּבַבְּהֵמָֽה׃"
          },
          {
            "num": "יב",
            "txt": "וַיָּבִ֡אוּ אֶל־מֹשֶׁה֩ וְאֶל־אֶלְעָזָ֨ר הַכֹּהֵ֜ן וְאֶל־עֲדַ֣ת בְּנֵֽי־יִשְׂרָאֵ֗ל אֶת־הַשְּׁבִ֧י וְאֶת־הַמַּלְק֛וֹחַ וְאֶת־הַשָּׁלָ֖ל אֶל־הַֽמַּחֲנֶ֑ה אֶל־עַֽרְבֹ֣ת מוֹאָ֔ב אֲשֶׁ֖ר עַל־יַרְדֵּ֥ן יְרֵחֽוֹ׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "וַיֵּ֨צְא֜וּ מֹשֶׁ֨ה וְאֶלְעָזָ֧ר הַכֹּהֵ֛ן וְכָל־נְשִׂיאֵ֥י הָֽעֵדָ֖ה לִקְרָאתָ֑ם אֶל־מִח֖וּץ לַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּקְצֹ֣ף מֹשֶׁ֔ה עַ֖ל פְּקוּדֵ֣י הֶחָ֑יִל שָׂרֵ֤י הָֽאֲלָפִים֙ וְשָׂרֵ֣י הַמֵּא֔וֹת הַבָּאִ֖ים מִצְּבָ֥א הַמִּלְחָמָֽה׃"
          },
          {
            "num": "טו",
            "txt": "וַיֹּ֥אמֶר אֲלֵיהֶ֖ם מֹשֶׁ֑ה הַֽחִיִּיתֶ֖ם כָּל־נְקֵבָֽה׃"
          },
          {
            "num": "טז",
            "txt": "הֵ֣ן הֵ֜נָּה הָי֨וּ לִבְנֵ֤י יִשְׂרָאֵל֙ בִּדְבַ֣ר בִּלְעָ֔ם לִמְסָר־מַ֥עַל בַּֽיהוָ֖ה עַל־דְּבַר־פְּע֑וֹר וַתְּהִ֥י הַמַּגֵּפָ֖ה בַּֽעֲדַ֥ת יְהוָֽה׃"
          },
          {
            "num": "יז",
            "txt": "וְעַתָּ֕ה הִרְג֥וּ כָל־זָכָ֖ר בַּטָּ֑ף וְכָל־אִשָּׁ֗ה יֹדַ֥עַת אִ֛ישׁ לְמִשְׁכַּ֥ב זָכָ֖ר הֲרֹֽגוּ׃"
          },
          {
            "num": "יח",
            "txt": "וְכֹל֙ הַטַּ֣ף בַּנָּשִׁ֔ים אֲשֶׁ֥ר לֹֽא־יָדְע֖וּ מִשְׁכַּ֣ב זָכָ֑ר הַֽחֲי֖וּ לָכֶֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְאַתֶּ֗ם חֲנ֛וּ מִח֥וּץ לַֽמַּחֲנֶ֖ה שִׁבְעַ֣ת יָמִ֑ים כֹּל֩ הֹרֵ֨ג נֶ֜פֶשׁ וְכֹ֣ל ׀ נֹגֵ֣עַ בֶּֽחָלָ֗ל תִּֽתְחַטְּא֞וּ בַּיּ֤וֹם הַשְּׁלִישִׁי֙ וּבַיּ֣וֹם הַשְּׁבִיעִ֔י אַתֶּ֖ם וּשְׁבִיכֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "וְכָל־בֶּ֧גֶד וְכָל־כְּלִי־ע֛וֹר וְכָל־מַֽעֲשֵׂ֥ה עִזִּ֖ים וְכָל־כְּלִי־עֵ֑ץ תִּתְחַטָּֽאוּ׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "וַיֹּ֨אמֶר אֶלְעָזָ֤ר הַכֹּהֵן֙ אֶל־אַנְשֵׁ֣י הַצָּבָ֔א הַבָּאִ֖ים לַמִּלְחָמָ֑ה זֹ֚את חֻקַּ֣ת הַתּוֹרָ֔ה אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "כב",
            "txt": "אַ֥ךְ אֶת־הַזָּהָ֖ב וְאֶת־הַכָּ֑סֶף אֶֽת־הַנְּחֹ֨שֶׁת֙ אֶת־הַבַּרְזֶ֔ל אֶֽת־הַבְּדִ֖יל וְאֶת־הָֽעֹפָֽרֶת׃"
          },
          {
            "num": "כג",
            "txt": "כָּל־דָּבָ֞ר אֲשֶׁר־יָבֹ֣א בָאֵ֗שׁ תַּֽעֲבִ֤ירוּ בָאֵשׁ֙ וְטָהֵ֔ר אַ֕ךְ בְּמֵ֥י נִדָּ֖ה יִתְחַטָּ֑א וְכֹ֨ל אֲשֶׁ֧ר לֹֽא־יָבֹ֛א בָּאֵ֖שׁ תַּֽעֲבִ֥ירוּ בַמָּֽיִם׃"
          },
          {
            "num": "כד",
            "txt": "וְכִבַּסְתֶּ֧ם בִּגְדֵיכֶ֛ם בַּיּ֥וֹם הַשְּׁבִיעִ֖י וּטְהַרְתֶּ֑ם וְאַחַ֖ר תָּבֹ֥אוּ אֶל־הַֽמַּחֲנֶֽה׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "כו",
            "txt": "שָׂ֗א אֵ֣ת רֹ֤אשׁ מַלְק֨וֹחַ֙ הַשְּׁבִ֔י בָּֽאָדָ֖ם וּבַבְּהֵמָ֑ה אַתָּה֙ וְאֶלְעָזָ֣ר הַכֹּהֵ֔ן וְרָאשֵׁ֖י אֲב֥וֹת הָֽעֵדָֽה׃"
          },
          {
            "num": "כז",
            "txt": "וְחָצִ֨יתָ֙ אֶת־הַמַּלְק֔וֹחַ בֵּ֚ין תֹּֽפְשֵׂ֣י הַמִּלְחָמָ֔ה הַיֹּֽצְאִ֖ים לַצָּבָ֑א וּבֵ֖ין כָּל־הָֽעֵדָֽה׃"
          },
          {
            "num": "כח",
            "txt": "וַהֲרֵֽמֹתָ֨ מֶ֜כֶס לַֽיהוָ֗ה מֵאֵ֞ת אַנְשֵׁ֤י הַמִּלְחָמָה֙ הַיֹּֽצְאִ֣ים לַצָּבָ֔א אֶחָ֣ד נֶ֔פֶשׁ מֵֽחֲמֵ֖שׁ הַמֵּא֑וֹת מִן־הָֽאָדָם֙ וּמִן־הַבָּקָ֔ר וּמִן־הַֽחֲמֹרִ֖ים וּמִן־הַצֹּֽאן׃"
          },
          {
            "num": "כט",
            "txt": "מִמַּֽחֲצִיתָ֖ם תִּקָּ֑חוּ וְנָֽתַתָּ֛ה לְאֶלְעָזָ֥ר הַכֹּהֵ֖ן תְּרוּמַ֥ת יְהוָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וּמִמַּֽחֲצִ֨ת בְּנֵֽי־יִשְׂרָאֵ֜ל תִּקַּ֣ח ׀ אֶחָ֣ד ׀ אָחֻ֣ז מִן־הַֽחֲמִשִּׁ֗ים מִן־הָֽאָדָ֧ם מִן־הַבָּקָ֛ר מִן־הַֽחֲמֹרִ֥ים וּמִן־הַצֹּ֖אן מִכָּל־הַבְּהֵמָ֑ה וְנָֽתַתָּ֤ה אֹתָם֙ לַלְוִיִּ֔ם שֹֽׁמְרֵ֕י מִשְׁמֶ֖רֶת מִשְׁכַּ֥ן יְהוָֽה׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּ֣עַשׂ מֹשֶׁ֔ה וְאֶלְעָזָ֖ר הַכֹּהֵ֑ן כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "לב",
            "txt": "וַֽיְהִי֙ הַמַּלְק֔וֹחַ יֶ֣תֶר הַבָּ֔ז אֲשֶׁ֥ר בָּֽזְז֖וּ עַ֣ם הַצָּבָ֑א צֹ֗אן שֵׁשׁ־מֵא֥וֹת אֶ֛לֶף וְשִׁבְעִ֥ים אֶ֖לֶף וַֽחֲמֵ֥שֶׁת אֲלָפִֽים׃"
          },
          {
            "num": "לג",
            "txt": "וּבָקָ֕ר שְׁנַ֥יִם וְשִׁבְעִ֖ים אָֽלֶף׃"
          },
          {
            "num": "לד",
            "txt": "וַֽחֲמֹרִ֕ים אֶחָ֥ד וְשִׁשִּׁ֖ים אָֽלֶף׃"
          },
          {
            "num": "לה",
            "txt": "וְנֶ֣פֶשׁ אָדָ֔ם מִ֨ן־הַנָּשִׁ֔ים אֲשֶׁ֥ר לֹֽא־יָדְע֖וּ מִשְׁכַּ֣ב זָכָ֑ר כָּל־נֶ֕פֶשׁ שְׁנַ֥יִם וּשְׁלֹשִׁ֖ים אָֽלֶף׃"
          },
          {
            "num": "לו",
            "txt": "וַתְּהִי֙ הַֽמֶּחֱצָ֔ה חֵ֕לֶק הַיֹּֽצְאִ֖ים בַּצָּבָ֑א מִסְפַּ֣ר הַצֹּ֗אן שְׁלֹשׁ־מֵא֥וֹת אֶ֨לֶף֙ וּשְׁלֹשִׁ֣ים אֶ֔לֶף וְשִׁבְעַ֥ת אֲלָפִ֖ים וַֽחֲמֵ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "לז",
            "txt": "וַיְהִ֛י הַמֶּ֥כֶס לַֽיהוָ֖ה מִן־הַצֹּ֑אן שֵׁ֥שׁ מֵא֖וֹת חָמֵ֥שׁ וְשִׁבְעִֽים׃"
          },
          {
            "num": "לח",
            "txt": "וְהַ֨בָּקָ֔ר שִׁשָּׁ֥ה וּשְׁלֹשִׁ֖ים אָ֑לֶף וּמִכְסָ֥ם לַֽיהוָ֖ה שְׁנַ֥יִם וְשִׁבְעִֽים׃"
          },
          {
            "num": "לט",
            "txt": "וַֽחֲמֹרִ֕ים שְׁלֹשִׁ֥ים אֶ֖לֶף וַֽחֲמֵ֣שׁ מֵא֑וֹת וּמִכְסָ֥ם לַֽיהוָ֖ה אֶחָ֥ד וְשִׁשִּֽׁים׃"
          },
          {
            "num": "מ",
            "txt": "וְנֶ֣פֶשׁ אָדָ֔ם שִׁשָּׁ֥ה עָשָׂ֖ר אָ֑לֶף וּמִכְסָם֙ לַֽיהוָ֔ה שְׁנַ֥יִם וּשְׁלֹשִׁ֖ים נָֽפֶשׁ׃"
          },
          {
            "num": "מא",
            "txt": "וַיִּתֵּ֣ן מֹשֶׁ֗ה אֶת־מֶ֨כֶס֙ תְּרוּמַ֣ת יְהוָ֔ה לְאֶלְעָזָ֖ר הַכֹּהֵ֑ן כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "מב",
            "txt": "וּמִֽמַּחֲצִ֖ית בְּנֵ֣י יִשְׂרָאֵ֑ל אֲשֶׁר֙ חָצָ֣ה מֹשֶׁ֔ה מִן־הָֽאֲנָשִׁ֖ים הַצֹּֽבְאִֽים׃"
          },
          {
            "num": "מג",
            "txt": "וַתְּהִ֛י מֶֽחֱצַ֥ת הָֽעֵדָ֖ה מִן־הַצֹּ֑אן שְׁלֹשׁ־מֵא֥וֹת אֶ֨לֶף֙ וּשְׁלֹשִׁ֣ים אֶ֔לֶף שִׁבְעַ֥ת אֲלָפִ֖ים וַֽחֲמֵ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "מד",
            "txt": "וּבָקָ֕ר שִׁשָּׁ֥ה וּשְׁלֹשִׁ֖ים אָֽלֶף׃"
          },
          {
            "num": "מה",
            "txt": "וַֽחֲמֹרִ֕ים שְׁלֹשִׁ֥ים אֶ֖לֶף וַֽחֲמֵ֥שׁ מֵאֽוֹת׃"
          },
          {
            "num": "מו",
            "txt": "וְנֶ֣פֶשׁ אָדָ֔ם שִׁשָּׁ֥ה עָשָׂ֖ר אָֽלֶף׃"
          },
          {
            "num": "מז",
            "txt": "וַיִּקַּ֨ח מֹשֶׁ֜ה מִמַּֽחֲצִ֣ת בְּנֵֽי־יִשְׂרָאֵ֗ל אֶת־הָֽאָחֻז֙ אֶחָ֣ד מִן־הַֽחֲמִשִּׁ֔ים מִן־הָֽאָדָ֖ם וּמִן־הַבְּהֵמָ֑ה וַיִּתֵּ֨ן אֹתָ֜ם לַלְוִיִּ֗ם שֹֽׁמְרֵי֙ מִשְׁמֶ֨רֶת֙ מִשְׁכַּ֣ן יְהוָ֔ה כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "מח",
            "txt": "וַֽיִּקְרְבוּ֙ אֶל־מֹשֶׁ֔ה הַפְּקֻדִ֕ים אֲשֶׁ֖ר לְאַלְפֵ֣י הַצָּבָ֑א שָׂרֵ֥י הָֽאֲלָפִ֖ים וְשָׂרֵ֥י הַמֵּאֽוֹת׃"
          },
          {
            "num": "מט",
            "txt": "וַיֹּֽאמְרוּ֙ אֶל־מֹשֶׁ֔ה עֲבָדֶ֣יךָ נָֽשְׂא֗וּ אֶת־רֹ֛אשׁ אַנְשֵׁ֥י הַמִּלְחָמָ֖ה אֲשֶׁ֣ר בְּיָדֵ֑נוּ וְלֹֽא־נִפְקַ֥ד מִמֶּ֖נּוּ אִֽישׁ׃"
          },
          {
            "num": "נ",
            "txt": "וַנַּקְרֵ֞ב אֶת־קָרְבַּ֣ן יְהוָ֗ה אִישׁ֩ אֲשֶׁ֨ר מָצָ֤א כְלִֽי־זָהָב֙ אֶצְעָדָ֣ה וְצָמִ֔יד טַבַּ֖עַת עָגִ֣יל וְכוּמָ֑ז לְכַפֵּ֥ר עַל־נַפְשֹׁתֵ֖ינוּ לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "נא",
            "txt": "וַיִּקַּ֨ח מֹשֶׁ֜ה וְאֶלְעָזָ֧ר הַכֹּהֵ֛ן אֶת־הַזָּהָ֖ב מֵֽאִתָּ֑ם כֹּ֖ל כְּלִ֥י מַֽעֲשֶֽׂה׃"
          },
          {
            "num": "נב",
            "txt": "וַיְהִ֣י ׀ כָּל־זְהַ֣ב הַתְּרוּמָ֗ה אֲשֶׁ֤ר הֵרִ֨ימוּ֙ לַֽיהוָ֔ה שִׁשָּׁ֨ה עָשָׂ֥ר אֶ֛לֶף שְׁבַע־מֵא֥וֹת וַֽחֲמִשִּׁ֖ים שָׁ֑קֶל מֵאֵת֙ שָׂרֵ֣י הָֽאֲלָפִ֔ים וּמֵאֵ֖ת שָׂרֵ֥י הַמֵּאֽוֹת׃"
          },
          {
            "num": "נג",
            "txt": "אַנְשֵׁי֙ הַצָּבָ֔א בָּֽזְז֖וּ אִ֥ישׁ לֽוֹ׃"
          },
          {
            "num": "נד",
            "txt": "וַיִּקַּ֨ח מֹשֶׁ֜ה וְאֶלְעָזָ֤ר הַכֹּהֵן֙ אֶת־הַזָּהָ֔ב מֵאֵ֛ת שָׂרֵ֥י הָֽאֲלָפִ֖ים וְהַמֵּא֑וֹת וַיָּבִ֤אוּ אֹתוֹ֙ אֶל־אֹ֣הֶל מוֹעֵ֔ד זִכָּר֥וֹן לִבְנֵֽי־יִשְׂרָאֵ֖ל לִפְנֵ֥י יְהוָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "לב",
        "verses": [
          {
            "num": "א",
            "txt": "וּמִקְנֶ֣ה ׀ רַ֗ב הָיָ֞ה לִבְנֵ֧י רְאוּבֵ֛ן וְלִבְנֵי־גָ֖ד עָצ֣וּם מְאֹ֑ד וַיִּרְא֞וּ אֶת־אֶ֤רֶץ יַעְזֵר֙ וְאֶת־אֶ֣רֶץ גִּלְעָ֔ד וְהִנֵּ֥ה הַמָּק֖וֹם מְק֥וֹם מִקְנֶֽה׃"
          },
          {
            "num": "ב",
            "txt": "וַיָּבֹ֥אוּ בְנֵֽי־גָ֖ד וּבְנֵ֣י רְאוּבֵ֑ן וַיֹּֽאמְר֤וּ אֶל־מֹשֶׁה֙ וְאֶל־אֶלְעָזָ֣ר הַכֹּהֵ֔ן וְאֶל־נְשִׂיאֵ֥י הָֽעֵדָ֖ה לֵאמֹֽר׃"
          },
          {
            "num": "ג",
            "txt": "עֲטָר֤וֹת וְדִיבֹן֙ וְיַעְזֵ֣ר וְנִמְרָ֔ה וְחֶשְׁבּ֖וֹן וְאֶלְעָלֵ֑ה וּשְׂבָ֥ם וּנְב֖וֹ וּבְעֹֽן׃"
          },
          {
            "num": "ד",
            "txt": "הָאָ֗רֶץ אֲשֶׁ֨ר הִכָּ֤ה יְהוָה֙ לִפְנֵי֙ עֲדַ֣ת יִשְׂרָאֵ֔ל אֶ֥רֶץ מִקְנֶ֖ה הִ֑וא וְלַֽעֲבָדֶ֖יךָ מִקְנֶֽה׃ {ס}"
          },
          {
            "num": "ה",
            "txt": "וַיֹּֽאמְר֗וּ אִם־מָצָ֤אנוּ חֵן֙ בְּעֵינֶ֔יךָ יֻתַּ֞ן אֶת־הָאָ֧רֶץ הַזֹּ֛את לַֽעֲבָדֶ֖יךָ לַֽאֲחֻזָּ֑ה אַל־תַּֽעֲבִרֵ֖נוּ אֶת־הַיַּרְדֵּֽן׃"
          },
          {
            "num": "ו",
            "txt": "וַיֹּ֣אמֶר מֹשֶׁ֔ה לִבְנֵי־גָ֖ד וְלִבְנֵ֣י רְאוּבֵ֑ן הַאַֽחֵיכֶ֗ם יָבֹ֨אוּ֙ לַמִּלְחָמָ֔ה וְאַתֶּ֖ם תֵּ֥שְׁבוּ פֹֽה׃"
          },
          {
            "num": "ז",
            "txt": "וְלָ֣מָּה תנואון (תְנִיא֔וּן) אֶת־לֵ֖ב בְּנֵ֣י יִשְׂרָאֵ֑ל מֵֽעֲבֹר֙ אֶל־הָאָ֔רֶץ אֲשֶׁר־נָתַ֥ן לָהֶ֖ם יְהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "כֹּ֥ה עָשׂ֖וּ אֲבֹֽתֵיכֶ֑ם בְּשָׁלְחִ֥י אֹתָ֛ם מִקָּדֵ֥שׁ בַּרְנֵ֖עַ לִרְא֥וֹת אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "ט",
            "txt": "וַֽיַּעֲל֞וּ עַד־נַ֣חַל אֶשְׁכּ֗וֹל וַיִּרְאוּ֙ אֶת־הָאָ֔רֶץ וַיָּנִ֕יאוּ אֶת־לֵ֖ב בְּנֵ֣י יִשְׂרָאֵ֑ל לְבִלְתִּי־בֹא֙ אֶל־הָאָ֔רֶץ אֲשֶׁר־נָתַ֥ן לָהֶ֖ם יְהוָֽה׃"
          },
          {
            "num": "י",
            "txt": "וַיִּֽחַר־אַ֥ף יְהוָ֖ה בַּיּ֣וֹם הַה֑וּא וַיִּשָּׁבַ֖ע לֵאמֹֽר׃"
          },
          {
            "num": "יא",
            "txt": "אִם־יִרְא֨וּ הָֽאֲנָשִׁ֜ים הָֽעֹלִ֣ים מִמִּצְרַ֗יִם מִבֶּ֨ן עֶשְׂרִ֤ים שָׁנָה֙ וָמַ֔עְלָה אֵ֚ת הָֽאֲדָמָ֔ה אֲשֶׁ֥ר נִשְׁבַּ֛עְתִּי לְאַבְרָהָ֥ם לְיִצְחָ֖ק וּֽלְיַעֲקֹ֑ב כִּ֥י לֹֽא־מִלְא֖וּ אַֽחֲרָֽי׃"
          },
          {
            "num": "יב",
            "txt": "בִּלְתִּ֞י כָּלֵ֤ב בֶּן־יְפֻנֶּה֙ הַקְּנִזִּ֔י וִֽיהוֹשֻׁ֖עַ בִּן־נ֑וּן כִּ֥י מִלְא֖וּ אַֽחֲרֵ֥י יְהוָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּֽחַר־אַ֤ף יְהוָה֙ בְּיִשְׂרָאֵ֔ל וַיְנִעֵם֙ בַּמִּדְבָּ֔ר אַרְבָּעִ֖ים שָׁנָ֑ה עַד־תֹּם֙ כָּל־הַדּ֔וֹר הָֽעֹשֶׂ֥ה הָרַ֖ע בְּעֵינֵ֥י יְהוָֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְהִנֵּ֣ה קַמְתֶּ֗ם תַּ֚חַת אֲבֹ֣תֵיכֶ֔ם תַּרְבּ֖וּת אֲנָשִׁ֣ים חַטָּאִ֑ים לִסְפּ֣וֹת ע֗וֹד עַ֛ל חֲר֥וֹן אַף־יְהוָ֖ה אֶל־יִשְׂרָאֵֽל׃"
          },
          {
            "num": "טו",
            "txt": "כִּ֤י תְשׁוּבֻן֙ מֵֽאַחֲרָ֔יו וְיָסַ֣ף ע֔וֹד לְהַנִּיח֖וֹ בַּמִּדְבָּ֑ר וְשִֽׁחַתֶּ֖ם לְכָל־הָעָ֥ם הַזֶּֽה׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "וַיִּגְּשׁ֤וּ אֵלָיו֙ וַיֹּ֣אמְר֔וּ גִּדְרֹ֥ת צֹ֛אן נִבְנֶ֥ה לְמִקְנֵ֖נוּ פֹּ֑ה וְעָרִ֖ים לְטַפֵּֽנוּ׃"
          },
          {
            "num": "יז",
            "txt": "וַֽאֲנַ֜חְנוּ נֵֽחָלֵ֣ץ חֻשִׁ֗ים לִפְנֵי֙ בְּנֵ֣י יִשְׂרָאֵ֔ל עַ֛ד אֲשֶׁ֥ר אִם־הֲבִֽיאֹנֻ֖ם אֶל־מְקוֹמָ֑ם וְיָשַׁ֤ב טַפֵּ֨נוּ֙ בְּעָרֵ֣י הַמִּבְצָ֔ר מִפְּנֵ֖י יֹֽשְׁבֵ֥י הָאָֽרֶץ׃"
          },
          {
            "num": "יח",
            "txt": "לֹ֥א נָשׁ֖וּב אֶל־בָּתֵּ֑ינוּ עַ֗ד הִתְנַחֵל֙ בְּנֵ֣י יִשְׂרָאֵ֔ל אִ֖ישׁ נַֽחֲלָתֽוֹ׃"
          },
          {
            "num": "יט",
            "txt": "כִּ֣י לֹ֤א נִנְחַל֙ אִתָּ֔ם מֵעֵ֥בֶר לַיַּרְדֵּ֖ן וָהָ֑לְאָה כִּ֣י בָ֤אָה נַֽחֲלָתֵ֨נוּ֙ אֵלֵ֔ינוּ מֵעֵ֥בֶר הַיַּרְדֵּ֖ן מִזְרָֽחָה׃ {פ}"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֤אמֶר אֲלֵיהֶם֙ מֹשֶׁ֔ה אִֽם־תַּעֲשׂ֖וּן אֶת־הַדָּבָ֣ר הַזֶּ֑ה אִם־תֵּחָ֥לְצ֛וּ לִפְנֵ֥י יְהוָ֖ה לַמִּלְחָמָֽה׃"
          },
          {
            "num": "כא",
            "txt": "וְעָבַ֨ר לָכֶ֧ם כָּל־חָל֛וּץ אֶת־הַיַּרְדֵּ֖ן לִפְנֵ֣י יְהוָ֑ה עַ֧ד הֽוֹרִישׁ֛וֹ אֶת־אֹֽיְבָ֖יו מִפָּנָֽיו׃"
          },
          {
            "num": "כב",
            "txt": "וְנִכְבְּשָׁ֨ה הָאָ֜רֶץ לִפְנֵ֤י יְהוָה֙ וְאַחַ֣ר תָּשֻׁ֔בוּ וִֽהְיִיתֶ֧ם נְקִיִּ֛ם מֵֽיְהוָ֖ה וּמִיִּשְׂרָאֵ֑ל וְ֠הָֽיְתָה הָאָ֨רֶץ הַזֹּ֥את לָכֶ֛ם לַֽאֲחֻזָּ֖ה לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וְאִם־לֹ֤א תַֽעֲשׂוּן֙ כֵּ֔ן הִנֵּ֥ה חֲטָאתֶ֖ם לַֽיהוָ֑ה וּדְעוּ֙ חַטַּאתְכֶ֔ם אֲשֶׁ֥ר תִּמְצָ֖א אֶתְכֶֽם׃"
          },
          {
            "num": "כד",
            "txt": "בְּנֽוּ־לָכֶ֤ם עָרִים֙ לְטַפְּכֶ֔ם וּגְדֵרֹ֖ת לְצֹנַֽאֲכֶ֑ם וְהַיֹּצֵ֥א מִפִּיכֶ֖ם תַּֽעֲשֽׂוּ׃"
          },
          {
            "num": "כה",
            "txt": "וַיֹּ֤אמֶר בְּנֵי־גָד֙ וּבְנֵ֣י רְאוּבֵ֔ן אֶל־מֹשֶׁ֖ה לֵאמֹ֑ר עֲבָדֶ֣יךָ יַֽעֲשׂ֔וּ כַּֽאֲשֶׁ֥ר אֲדֹנִ֖י מְצַוֶּֽה׃"
          },
          {
            "num": "כו",
            "txt": "טַפֵּ֣נוּ נָשֵׁ֔ינוּ מִקְנֵ֖נוּ וְכָל־בְּהֶמְתֵּ֑נוּ יִֽהְיוּ־שָׁ֖ם בְּעָרֵ֥י הַגִּלְעָֽד׃"
          },
          {
            "num": "כז",
            "txt": "וַֽעֲבָדֶ֨יךָ יַֽעַבְר֜וּ כָּל־חֲל֥וּץ צָבָ֛א לִפְנֵ֥י יְהוָ֖ה לַמִּלְחָמָ֑ה כַּֽאֲשֶׁ֥ר אֲדֹנִ֖י דֹּבֵֽר׃"
          },
          {
            "num": "כח",
            "txt": "וַיְצַ֤ו לָהֶם֙ מֹשֶׁ֔ה אֵ֚ת אֶלְעָזָ֣ר הַכֹּהֵ֔ן וְאֵ֖ת יְהוֹשֻׁ֣עַ בִּן־נ֑וּן וְאֶת־רָאשֵׁ֛י אֲב֥וֹת הַמַּטּ֖וֹת לִבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כט",
            "txt": "וַיֹּ֨אמֶר מֹשֶׁ֜ה אֲלֵהֶ֗ם אִם־יַֽעַבְר֣וּ בְנֵי־גָ֣ד וּבְנֵֽי־רְאוּבֵ֣ן ׀ אִ֠תְּכֶם אֶֽת־הַיַּרְדֵּ֞ן כָּל־חָל֤וּץ לַמִּלְחָמָה֙ לִפְנֵ֣י יְהוָ֔ה וְנִכְבְּשָׁ֥ה הָאָ֖רֶץ לִפְנֵיכֶ֑ם וּנְתַתֶּ֥ם לָהֶ֛ם אֶת־אֶ֥רֶץ הַגִּלְעָ֖ד לַֽאֲחֻזָּֽה׃"
          },
          {
            "num": "ל",
            "txt": "וְאִם־לֹ֧א יַֽעַבְר֛וּ חֲלוּצִ֖ים אִתְּכֶ֑ם וְנֹֽאחֲז֥וּ בְתֹֽכְכֶ֖ם בְּאֶ֥רֶץ כְּנָֽעַן׃"
          },
          {
            "num": "לא",
            "txt": "וַיַּֽעֲנ֧וּ בְנֵי־גָ֛ד וּבְנֵ֥י רְאוּבֵ֖ן לֵאמֹ֑ר אֵת֩ אֲשֶׁ֨ר דִּבֶּ֧ר יְהוָ֛ה אֶל־עֲבָדֶ֖יךָ כֵּ֥ן נַֽעֲשֶֽׂה׃"
          },
          {
            "num": "לב",
            "txt": "נַ֣חְנוּ נַֽעֲבֹ֧ר חֲלוּצִ֛ים לִפְנֵ֥י יְהוָ֖ה אֶ֣רֶץ כְּנָ֑עַן וְאִתָּ֨נוּ֙ אֲחֻזַּ֣ת נַֽחֲלָתֵ֔נוּ מֵעֵ֖בֶר לַיַּרְדֵּֽן׃"
          },
          {
            "num": "לג",
            "txt": "וַיִּתֵּ֣ן לָהֶ֣ם ׀ מֹשֶׁ֡ה לִבְנֵי־גָד֩ וְלִבְנֵ֨י רְאוּבֵ֜ן וְלַֽחֲצִ֣י ׀ שֵׁ֣בֶט ׀ מְנַשֶּׁ֣ה בֶן־יוֹסֵ֗ף אֶת־מַמְלֶ֨כֶת֙ סִיחֹן֙ מֶ֣לֶךְ הָֽאֱמֹרִ֔י וְאֶ֨ת־מַמְלֶ֔כֶת ע֖וֹג מֶ֣לֶךְ הַבָּשָׁ֑ן הָאָ֗רֶץ לְעָרֶ֨יהָ֙ בִּגְבֻלֹ֔ת עָרֵ֥י הָאָ֖רֶץ סָבִֽיב׃"
          },
          {
            "num": "לד",
            "txt": "וַיִּבְנ֣וּ בְנֵי־גָ֔ד אֶת־דִּיבֹ֖ן וְאֶת־עֲטָרֹ֑ת וְאֵ֖ת עֲרֹעֵֽר׃"
          },
          {
            "num": "לה",
            "txt": "וְאֶת־עַטְרֹ֥ת שׁוֹפָ֛ן וְאֶת־יַעְזֵ֖ר וְיָגְבְּהָֽה׃"
          },
          {
            "num": "לו",
            "txt": "וְאֶת־בֵּ֥ית נִמְרָ֖ה וְאֶת־בֵּ֣ית הָרָ֑ן עָרֵ֥י מִבְצָ֖ר וְגִדְרֹ֥ת צֹֽאן׃"
          },
          {
            "num": "לז",
            "txt": "וּבְנֵ֤י רְאוּבֵן֙ בָּנ֔וּ אֶת־חֶשְׁבּ֖וֹן וְאֶת־אֶלְעָלֵ֑א וְאֵ֖ת קִרְיָתָֽיִם׃"
          },
          {
            "num": "לח",
            "txt": "וְאֶת־נְב֞וֹ וְאֶת־בַּ֧עַל מְע֛וֹן מֽוּסַבֹּ֥ת שֵׁ֖ם וְאֶת־שִׂבְמָ֑ה וַיִּקְרְא֣וּ בְשֵׁמֹ֔ת אֶת־שְׁמ֥וֹת הֶֽעָרִ֖ים אֲשֶׁ֥ר בָּנֽוּ׃"
          },
          {
            "num": "לט",
            "txt": "וַיֵּ֨לְכ֜וּ בְּנֵ֨י מָכִ֧יר בֶּן־מְנַשֶּׁ֛ה גִּלְעָ֖דָה וַֽיִּלְכְּדֻ֑הָ וַיּ֖וֹרֶשׁ אֶת־הָֽאֱמֹרִ֥י אֲשֶׁר־בָּֽהּ׃"
          },
          {
            "num": "מ",
            "txt": "וַיִּתֵּ֤ן מֹשֶׁה֙ אֶת־הַגִּלְעָ֔ד לְמָכִ֖יר בֶּן־מְנַשֶּׁ֑ה וַיֵּ֖שֶׁב בָּֽהּ׃"
          },
          {
            "num": "מא",
            "txt": "וְיָאִ֤יר בֶּן־מְנַשֶּׁה֙ הָלַ֔ךְ וַיִּלְכֹּ֖ד אֶת־חַוֺּֽתֵיהֶ֑ם וַיִּקְרָ֥א אֶתְהֶ֖ן חַוֺּ֥ת יָאִֽיר׃"
          },
          {
            "num": "מב",
            "txt": "וְנֹ֣בַח הָלַ֔ךְ וַיִּלְכֹּ֥ד אֶת־קְנָ֖ת וְאֶת־בְּנֹתֶ֑יהָ וַיִּקְרָ֧א לָ֦ה נֹ֖בַח בִּשְׁמֽוֹ׃ {פ}"
          }
        ]
      },
      {
        "num": "לג",
        "verses": [
          {
            "num": "א",
            "txt": "אֵ֜לֶּה מַסְעֵ֣י בְנֵֽי־יִשְׂרָאֵ֗ל אֲשֶׁ֥ר יָֽצְא֛וּ מֵאֶ֥רֶץ מִצְרַ֖יִם לְצִבְאֹתָ֑ם בְּיַד־מֹשֶׁ֖ה וְאַֽהֲרֹֽן׃"
          },
          {
            "num": "ב",
            "txt": "וַיִּכְתֹּ֨ב מֹשֶׁ֜ה אֶת־מוֹצָֽאֵיהֶ֛ם לְמַסְעֵיהֶ֖ם עַל־פִּ֣י יְהוָ֑ה וְאֵ֥לֶּה מַסְעֵיהֶ֖ם לְמוֹצָֽאֵיהֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּסְע֤וּ מֵֽרַעְמְסֵס֙ בַּחֹ֣דֶשׁ הָֽרִאשׁ֔וֹן בַּֽחֲמִשָּׁ֥ה עָשָׂ֛ר י֖וֹם לַחֹ֣דֶשׁ הָֽרִאשׁ֑וֹן מִֽמָּחֳרַ֣ת הַפֶּ֗סַח יָֽצְא֤וּ בְנֵֽי־יִשְׂרָאֵל֙ בְּיָ֣ד רָמָ֔ה לְעֵינֵ֖י כָּל־מִצְרָֽיִם׃"
          },
          {
            "num": "ד",
            "txt": "וּמִצְרַ֣יִם מְקַבְּרִ֗ים אֵת֩ אֲשֶׁ֨ר הִכָּ֧ה יְהוָ֛ה בָּהֶ֖ם כָּל־בְּכ֑וֹר וּבֵאלֹ֣הֵיהֶ֔ם עָשָׂ֥ה יְהוָ֖ה שְׁפָטִֽים׃"
          },
          {
            "num": "ה",
            "txt": "וַיִּסְע֥וּ בְנֵֽי־יִשְׂרָאֵ֖ל מֵֽרַעְמְסֵ֑ס וַֽיַּחֲנ֖וּ בְּסֻכֹּֽת׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּסְע֖וּ מִסֻּכֹּ֑ת וַיַּֽחֲנ֣וּ בְאֵתָ֔ם אֲשֶׁ֖ר בִּקְצֵ֥ה הַמִּדְבָּֽר׃"
          },
          {
            "num": "ז",
            "txt": "וַיִּסְעוּ֙ מֵֽאֵתָ֔ם וַיָּ֨שָׁב֙ עַל־פִּ֣י הַֽחִירֹ֔ת אֲשֶׁ֥ר עַל־פְּנֵ֖י בַּ֣עַל צְפ֑וֹן וַֽיַּחֲנ֖וּ לִפְנֵ֥י מִגְדֹּֽל׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּסְעוּ֙ מִפְּנֵ֣י הַֽחִירֹ֔ת וַיַּֽעַבְר֥וּ בְתוֹךְ־הַיָּ֖ם הַמִּדְבָּ֑רָה וַיֵּ֨לְכ֜וּ דֶּ֣רֶךְ שְׁלֹ֤שֶׁת יָמִים֙ בְּמִדְבַּ֣ר אֵתָ֔ם וַֽיַּחֲנ֖וּ בְּמָרָֽה׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּסְעוּ֙ מִמָּרָ֔ה וַיָּבֹ֖אוּ אֵילִ֑מָה וּ֠בְאֵילִם שְׁתֵּ֣ים עֶשְׂרֵ֞ה עֵינֹ֥ת מַ֛יִם וְשִׁבְעִ֥ים תְּמָרִ֖ים וַיַּֽחֲנוּ־שָֽׁם׃"
          },
          {
            "num": "י",
            "txt": "וַיִּסְע֖וּ מֵֽאֵילִ֑ם וַֽיַּחֲנ֖וּ עַל־יַם־סֽוּף׃"
          },
          {
            "num": "יא",
            "txt": "וַיִּסְע֖וּ מִיַּם־ס֑וּף וַֽיַּחֲנ֖וּ בְּמִדְבַּר־סִֽין׃"
          },
          {
            "num": "יב",
            "txt": "וַיִּסְע֖וּ מִמִּדְבַּר־סִ֑ין וַֽיַּחֲנ֖וּ בְּדָפְקָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וַיִּסְע֖וּ מִדָּפְקָ֑ה וַֽיַּחֲנ֖וּ בְּאָלֽוּשׁ׃"
          },
          {
            "num": "יד",
            "txt": "וַיִּסְע֖וּ מֵֽאָל֑וּשׁ וַֽיַּחֲנוּ֙ בִּרְפִידִ֔ם וְלֹא־הָ֨יָה שָׁ֥ם מַ֛יִם לָעָ֖ם לִשְׁתּֽוֹת׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּסְע֖וּ מֵֽרְפִידִ֑ם וַֽיַּחֲנ֖וּ בְּמִדְבַּ֥ר סִינָֽי׃"
          },
          {
            "num": "טז",
            "txt": "וַיִּסְע֖וּ מִמִּדְבַּ֣ר סִינָ֑י וַֽיַּחֲנ֖וּ בְּקִבְרֹ֥ת הַֽתַּאֲוָֽה׃"
          },
          {
            "num": "יז",
            "txt": "וַיִּסְע֖וּ מִקִּבְרֹ֣ת הַֽתַּאֲוָ֑ה וַֽיַּחֲנ֖וּ בַּֽחֲצֵרֹֽת׃"
          },
          {
            "num": "יח",
            "txt": "וַיִּסְע֖וּ מֵֽחֲצֵרֹ֑ת וַֽיַּחֲנ֖וּ בְּרִתְמָֽה׃"
          },
          {
            "num": "יט",
            "txt": "וַיִּסְע֖וּ מֵֽרִתְמָ֑ה וַֽיַּחֲנ֖וּ בְּרִמֹּ֥ן פָּֽרֶץ׃"
          },
          {
            "num": "כ",
            "txt": "וַיִּסְע֖וּ מֵֽרִמֹּ֣ן פָּ֑רֶץ וַֽיַּחֲנ֖וּ בְּלִבְנָֽה׃"
          },
          {
            "num": "כא",
            "txt": "וַיִּסְע֖וּ מִלִּבְנָ֑ה וַֽיַּחֲנ֖וּ בְּרִסָּֽה׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּסְע֖וּ מֵֽרִסָּ֑ה וַֽיַּחֲנ֖וּ בִּקְהֵלָֽתָה׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּסְע֖וּ מִקְּהֵלָ֑תָה וַֽיַּחֲנ֖וּ בְּהַר־שָֽׁפֶר׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּסְע֖וּ מֵֽהַר־שָׁ֑פֶר וַֽיַּחֲנ֖וּ בַּֽחֲרָדָֽה׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּסְע֖וּ מֵֽחֲרָדָ֑ה וַֽיַּחֲנ֖וּ בְּמַקְהֵלֹֽת׃"
          },
          {
            "num": "כו",
            "txt": "וַיִּסְע֖וּ מִמַּקְהֵלֹ֑ת וַֽיַּחֲנ֖וּ בְּתָֽחַת׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּסְע֖וּ מִתָּ֑חַת וַֽיַּחֲנ֖וּ בְּתָֽרַח׃"
          },
          {
            "num": "כח",
            "txt": "וַיִּסְע֖וּ מִתָּ֑רַח וַֽיַּחֲנ֖וּ בְּמִתְקָֽה׃"
          },
          {
            "num": "כט",
            "txt": "וַיִּסְע֖וּ מִמִּתְקָ֑ה וַֽיַּחֲנ֖וּ בְּחַשְׁמֹנָֽה׃"
          },
          {
            "num": "ל",
            "txt": "וַיִּסְע֖וּ מֵֽחַשְׁמֹנָ֑ה וַֽיַּחֲנ֖וּ בְּמֹֽסֵרֽוֹת׃"
          },
          {
            "num": "לא",
            "txt": "וַיִּסְע֖וּ מִמֹּֽסֵר֑וֹת וַֽיַּחֲנ֖וּ בִּבְנֵ֥י יַֽעֲקָֽן׃"
          },
          {
            "num": "לב",
            "txt": "וַיִּסְע֖וּ מִבְּנֵ֣י יַֽעֲקָ֑ן וַֽיַּחֲנ֖וּ בְּחֹ֥ר הַגִּדְגָּֽד׃"
          },
          {
            "num": "לג",
            "txt": "וַיִּסְע֖וּ מֵחֹ֣ר הַגִּדְגָּ֑ד וַֽיַּחֲנ֖וּ בְּיָטְבָֽתָה׃"
          },
          {
            "num": "לד",
            "txt": "וַיִּסְע֖וּ מִיָּטְבָ֑תָה וַֽיַּחֲנ֖וּ בְּעַבְרֹנָֽה׃"
          },
          {
            "num": "לה",
            "txt": "וַיִּסְע֖וּ מֵֽעַבְרֹנָ֑ה וַֽיַּחֲנ֖וּ בְּעֶצְיֹ֥ן גָּֽבֶר׃"
          },
          {
            "num": "לו",
            "txt": "וַיִּסְע֖וּ מֵֽעֶצְיֹ֣ן גָּ֑בֶר וַיַּֽחֲנ֥וּ בְמִדְבַּר־צִ֖ן הִ֥וא קָדֵֽשׁ׃"
          },
          {
            "num": "לז",
            "txt": "וַיִּסְע֖וּ מִקָּדֵ֑שׁ וַֽיַּחֲנוּ֙ בְּהֹ֣ר הָהָ֔ר בִּקְצֵ֖ה אֶ֥רֶץ אֱדֽוֹם׃"
          },
          {
            "num": "לח",
            "txt": "וַיַּעַל֩ אַֽהֲרֹ֨ן הַכֹּהֵ֜ן אֶל־הֹ֥ר הָהָ֛ר עַל־פִּ֥י יְהוָ֖ה וַיָּ֣מָת שָׁ֑ם בִּשְׁנַ֣ת הָֽאַרְבָּעִ֗ים לְצֵ֤את בְּנֵֽי־יִשְׂרָאֵל֙ מֵאֶ֣רֶץ מִצְרַ֔יִם בַּחֹ֥דֶשׁ הַֽחֲמִישִׁ֖י בְּאֶחָ֥ד לַחֹֽדֶשׁ׃"
          },
          {
            "num": "לט",
            "txt": "וְאַֽהֲרֹ֔ן בֶּן־שָׁלֹ֧שׁ וְעֶשְׂרִ֛ים וּמְאַ֖ת שָׁנָ֑ה בְּמֹת֖וֹ בְּהֹ֥ר הָהָֽר׃ {ס}"
          },
          {
            "num": "מ",
            "txt": "וַיִּשְׁמַ֗ע הַֽכְּנַעֲנִי֙ מֶ֣לֶךְ עֲרָ֔ד וְהֽוּא־יֹשֵׁ֥ב בַּנֶּ֖גֶב בְּאֶ֣רֶץ כְּנָ֑עַן בְּבֹ֖א בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "מא",
            "txt": "וַיִּסְע֖וּ מֵהֹ֣ר הָהָ֑ר וַֽיַּחֲנ֖וּ בְּצַלְמֹנָֽה׃"
          },
          {
            "num": "מב",
            "txt": "וַיִּסְע֖וּ מִצַּלְמֹנָ֑ה וַֽיַּחֲנ֖וּ בְּפוּנֹֽן׃"
          },
          {
            "num": "מג",
            "txt": "וַיִּסְע֖וּ מִפּוּנֹ֑ן וַֽיַּחֲנ֖וּ בְּאֹבֹֽת׃"
          },
          {
            "num": "מד",
            "txt": "וַיִּסְע֖וּ מֵֽאֹבֹ֑ת וַֽיַּחֲנ֛וּ בְּעִיֵּ֥י הָֽעֲבָרִ֖ים בִּגְב֥וּל מוֹאָֽב׃"
          },
          {
            "num": "מה",
            "txt": "וַיִּסְע֖וּ מֵֽעִיִּ֑ים וַֽיַּחֲנ֖וּ בְּדִיבֹ֥ן גָּֽד׃"
          },
          {
            "num": "מו",
            "txt": "וַיִּסְע֖וּ מִדִּיבֹ֣ן גָּ֑ד וַֽיַּחֲנ֖וּ בְּעַלְמֹ֥ן דִּבְלָתָֽיְמָה׃"
          },
          {
            "num": "מז",
            "txt": "וַיִּסְע֖וּ מֵֽעַלְמֹ֣ן דִּבְלָתָ֑יְמָה וַֽיַּחֲנ֛וּ בְּהָרֵ֥י הָֽעֲבָרִ֖ים לִפְנֵ֥י נְבֽוֹ׃"
          },
          {
            "num": "מח",
            "txt": "וַיִּסְע֖וּ מֵֽהָרֵ֣י הָֽעֲבָרִ֑ים וַֽיַּחֲנוּ֙ בְּעַֽרְבֹ֣ת מוֹאָ֔ב עַ֖ל יַרְדֵּ֥ן יְרֵחֽוֹ׃"
          },
          {
            "num": "מט",
            "txt": "וַיַּֽחֲנ֤וּ עַל־הַיַּרְדֵּן֙ מִבֵּ֣ית הַיְשִׁמֹ֔ת עַ֖ד אָבֵ֣ל הַשִּׁטִּ֑ים בְּעַֽרְבֹ֖ת מוֹאָֽב׃ {ס}"
          },
          {
            "num": "נ",
            "txt": "וַיְדַבֵּ֧ר יְהוָ֛ה אֶל־מֹשֶׁ֖ה בְּעַֽרְבֹ֣ת מוֹאָ֑ב עַל־יַרְדֵּ֥ן יְרֵח֖וֹ לֵאמֹֽר׃"
          },
          {
            "num": "נא",
            "txt": "דַּבֵּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם כִּ֥י אַתֶּ֛ם עֹֽבְרִ֥ים אֶת־הַיַּרְדֵּ֖ן אֶל־אֶ֥רֶץ כְּנָֽעַן׃"
          },
          {
            "num": "נב",
            "txt": "וְה֨וֹרַשְׁתֶּ֜ם אֶת־כָּל־יֹֽשְׁבֵ֤י הָאָ֨רֶץ֙ מִפְּנֵיכֶ֔ם וְאִ֨בַּדְתֶּ֔ם אֵ֖ת כָּל־מַשְׂכִּיֹּתָ֑ם וְאֵ֨ת כָּל־צַלְמֵ֤י מַסֵּֽכֹתָם֙ תְּאַבֵּ֔דוּ וְאֵ֥ת כָּל־בָּֽמוֹתָ֖ם תַּשְׁמִֽידוּ׃"
          },
          {
            "num": "נג",
            "txt": "וְהֽוֹרַשְׁתֶּ֥ם אֶת־הָאָ֖רֶץ וִֽישַׁבְתֶּם־בָּ֑הּ כִּ֥י לָכֶ֛ם נָתַ֥תִּי אֶת־הָאָ֖רֶץ לָרֶ֥שֶׁת אֹתָֽהּ׃"
          },
          {
            "num": "נד",
            "txt": "וְהִתְנַֽחַלְתֶּם֩ אֶת־הָאָ֨רֶץ בְּגוֹרָ֜ל לְמִשְׁפְּחֹֽתֵיכֶ֗ם לָרַ֞ב תַּרְבּ֤וּ אֶת־נַֽחֲלָתוֹ֙ וְלַמְעַט֙ תַּמְעִ֣יט אֶת־נַֽחֲלָת֔וֹ אֶל֩ אֲשֶׁר־יֵ֨צֵא ל֥וֹ שָׁ֛מָּה הַגּוֹרָ֖ל ל֣וֹ יִֽהְיֶ֑ה לְמַטּ֥וֹת אֲבֹֽתֵיכֶ֖ם תִּתְנֶחָֽלוּ׃"
          },
          {
            "num": "נה",
            "txt": "וְאִם־לֹ֨א תוֹרִ֜ישׁוּ אֶת־יֹֽשְׁבֵ֣י הָאָרֶץ֮ מִפְּנֵיכֶם֒ וְהָיָה֙ אֲשֶׁ֣ר תּוֹתִ֣ירוּ מֵהֶ֔ם לְשִׂכִּים֙ בְּעֵ֣ינֵיכֶ֔ם וְלִצְנִינִ֖ם בְּצִדֵּיכֶ֑ם וְצָֽרְר֣וּ אֶתְכֶ֔ם עַל־הָאָ֕רֶץ אֲשֶׁ֥ר אַתֶּ֖ם יֹֽשְׁבִ֥ים בָּֽהּ׃"
          },
          {
            "num": "נו",
            "txt": "וְהָיָ֗ה כַּֽאֲשֶׁ֥ר דִּמִּ֛יתִי לַֽעֲשׂ֥וֹת לָהֶ֖ם אֶֽעֱשֶׂ֥ה לָכֶֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "לד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "צַ֞ו אֶת־בְּנֵ֤י יִשְׂרָאֵל֙ וְאָֽמַרְתָּ֣ אֲלֵהֶ֔ם כִּֽי־אַתֶּ֥ם בָּאִ֖ים אֶל־הָאָ֣רֶץ כְּנָ֑עַן זֹ֣את הָאָ֗רֶץ אֲשֶׁ֨ר תִּפֹּ֤ל לָכֶם֙ בְּֽנַחֲלָ֔ה אֶ֥רֶץ כְּנַ֖עַן לִגְבֻֽלֹתֶֽיהָ׃"
          },
          {
            "num": "ג",
            "txt": "וְהָיָ֨ה לָכֶ֧ם פְּאַת־נֶ֛גֶב מִמִּדְבַּר־צִ֖ן עַל־יְדֵ֣י אֱד֑וֹם וְהָיָ֤ה לָכֶם֙ גְּב֣וּל נֶ֔גֶב מִקְצֵ֥ה יָם־הַמֶּ֖לַח קֵֽדְמָה׃"
          },
          {
            "num": "ד",
            "txt": "וְנָסַ֣ב לָכֶם֩ הַגְּב֨וּל מִנֶּ֜גֶב לְמַֽעֲלֵ֤ה עַקְרַבִּים֙ וְעָ֣בַר צִ֔נָה והיה (וְהָיוּ֙) תּֽוֹצְאֹתָ֔יו מִנֶּ֖גֶב לְקָדֵ֣שׁ בַּרְנֵ֑עַ וְיָצָ֥א חֲצַר־אַדָּ֖ר וְעָבַ֥ר עַצְמֹֽנָה׃"
          },
          {
            "num": "ה",
            "txt": "וְנָסַ֧ב הַגְּב֛וּל מֵֽעַצְמ֖וֹן נַ֣חְלָה מִצְרָ֑יִם וְהָי֥וּ תֽוֹצְאֹתָ֖יו הַיָּֽמָּה׃"
          },
          {
            "num": "ו",
            "txt": "וּגְב֣וּל יָ֔ם וְהָיָ֥ה לָכֶ֛ם הַיָּ֥ם הַגָּד֖וֹל וּגְב֑וּל זֶֽה־יִהְיֶ֥ה לָכֶ֖ם גְּב֥וּל יָֽם׃"
          },
          {
            "num": "ז",
            "txt": "וְזֶֽה־יִהְיֶ֥ה לָכֶ֖ם גְּב֣וּל צָפ֑וֹן מִן־הַיָּם֙ הַגָּדֹ֔ל תְּתָא֥וּ לָכֶ֖ם הֹ֥ר הָהָֽר׃"
          },
          {
            "num": "ח",
            "txt": "מֵהֹ֣ר הָהָ֔ר תְּתָא֖וּ לְבֹ֣א חֲמָ֑ת וְהָי֛וּ תּֽוֹצְאֹ֥ת הַגְּבֻ֖ל צְדָֽדָה׃"
          },
          {
            "num": "ט",
            "txt": "וְיָצָ֤א הַגְּבֻל֙ זִפְרֹ֔נָה וְהָי֥וּ תֽוֹצְאֹתָ֖יו חֲצַ֣ר עֵינָ֑ן זֶֽה־יִהְיֶ֥ה לָכֶ֖ם גְּב֥וּל צָפֽוֹן׃"
          },
          {
            "num": "י",
            "txt": "וְהִתְאַוִּיתֶ֥ם לָכֶ֖ם לִגְב֣וּל קֵ֑דְמָה מֵֽחֲצַ֥ר עֵינָ֖ן שְׁפָֽמָה׃"
          },
          {
            "num": "יא",
            "txt": "וְיָרַ֨ד הַגְּבֻ֧ל מִשְּׁפָ֛ם הָֽרִבְלָ֖ה מִקֶּ֣דֶם לָעָ֑יִן וְיָרַ֣ד הַגְּבֻ֔ל וּמָחָ֛ה עַל־כֶּ֥תֶף יָם־כִּנֶּ֖רֶת קֵֽדְמָה׃"
          },
          {
            "num": "יב",
            "txt": "וְיָרַ֤ד הַגְּבוּל֙ הַיַּרְדֵּ֔נָה וְהָי֥וּ תֽוֹצְאֹתָ֖יו יָ֣ם הַמֶּ֑לַח זֹאת֩ תִּֽהְיֶ֨ה לָכֶ֥ם הָאָ֛רֶץ לִגְבֻֽלֹתֶ֖יהָ סָבִֽיב׃"
          },
          {
            "num": "יג",
            "txt": "וַיְצַ֣ו מֹשֶׁ֔ה אֶת־בְּנֵ֥י יִשְׂרָאֵ֖ל לֵאמֹ֑ר זֹ֣את הָאָ֗רֶץ אֲשֶׁ֨ר תִּתְנַֽחֲל֤וּ אֹתָהּ֙ בְּגוֹרָ֔ל אֲשֶׁר֙ צִוָּ֣ה יְהוָ֔ה לָתֵ֛ת לְתִשְׁעַ֥ת הַמַּטּ֖וֹת וַֽחֲצִ֥י הַמַּטֶּֽה׃"
          },
          {
            "num": "יד",
            "txt": "כִּ֣י לָֽקְח֞וּ מַטֵּ֨ה בְנֵ֤י הָרֽאוּבֵנִי֙ לְבֵ֣ית אֲבֹתָ֔ם וּמַטֵּ֥ה בְנֵֽי־הַגָּדִ֖י לְבֵ֣ית אֲבֹתָ֑ם וַֽחֲצִי֙ מַטֵּ֣ה מְנַשֶּׁ֔ה לָֽקְח֖וּ נַֽחֲלָתָֽם׃"
          },
          {
            "num": "טו",
            "txt": "שְׁנֵ֥י הַמַּטּ֖וֹת וַֽחֲצִ֣י הַמַּטֶּ֑ה לָֽקְח֣וּ נַֽחֲלָתָ֗ם מֵעֵ֛בֶר לְיַרְדֵּ֥ן יְרֵח֖וֹ קֵ֥דְמָה מִזְרָֽחָה׃ {פ}"
          },
          {
            "num": "טז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "אֵ֚לֶּה שְׁמ֣וֹת הָֽאֲנָשִׁ֔ים אֲשֶׁר־יִנְחֲל֥וּ לָכֶ֖ם אֶת־הָאָ֑רֶץ אֶלְעָזָר֙ הַכֹּהֵ֔ן וִֽיהוֹשֻׁ֖עַ בִּן־נֽוּן׃"
          },
          {
            "num": "יח",
            "txt": "וְנָשִׂ֥יא אֶחָ֛ד נָשִׂ֥יא אֶחָ֖ד מִמַּטֶּ֑ה תִּקְח֖וּ לִנְחֹ֥ל אֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "יט",
            "txt": "וְאֵ֖לֶּה שְׁמ֣וֹת הָֽאֲנָשִׁ֑ים לְמַטֵּ֣ה יְהוּדָ֔ה כָּלֵ֖ב בֶּן־יְפֻנֶּֽה׃"
          },
          {
            "num": "כ",
            "txt": "וּלְמַטֵּה֙ בְּנֵ֣י שִׁמְע֔וֹן שְׁמוּאֵ֖ל בֶּן־עַמִּיהֽוּד׃"
          },
          {
            "num": "כא",
            "txt": "לְמַטֵּ֣ה בִנְיָמִ֔ן אֱלִידָ֖ד בֶּן־כִּסְלֽוֹן׃"
          },
          {
            "num": "כב",
            "txt": "וּלְמַטֵּ֥ה בְנֵי־דָ֖ן נָשִׂ֑יא בֻּקִּ֖י בֶּן־יָגְלִֽי׃"
          },
          {
            "num": "כג",
            "txt": "לִבְנֵ֣י יוֹסֵ֔ף לְמַטֵּ֥ה בְנֵֽי־מְנַשֶּׁ֖ה נָשִׂ֑יא חַנִּיאֵ֖ל בֶּן־אֵפֹֽד׃"
          },
          {
            "num": "כד",
            "txt": "וּלְמַטֵּ֥ה בְנֵֽי־אֶפְרַ֖יִם נָשִׂ֑יא קְמוּאֵ֖ל בֶּן־שִׁפְטָֽן׃"
          },
          {
            "num": "כה",
            "txt": "וּלְמַטֵּ֥ה בְנֵֽי־זְבוּלֻ֖ן נָשִׂ֑יא אֱלִֽיצָפָ֖ן בֶּן־פַּרְנָֽךְ׃"
          },
          {
            "num": "כו",
            "txt": "וּלְמַטֵּ֥ה בְנֵֽי־יִשָּׂשכָ֖ר נָשִׂ֑יא פַּלְטִיאֵ֖ל בֶּן־עַזָּֽן׃"
          },
          {
            "num": "כז",
            "txt": "וּלְמַטֵּ֥ה בְנֵֽי־אָשֵׁ֖ר נָשִׂ֑יא אֲחִיה֖וּד בֶּן־שְׁלֹמִֽי׃"
          },
          {
            "num": "כח",
            "txt": "וּלְמַטֵּ֥ה בְנֵֽי־נַפְתָּלִ֖י נָשִׂ֑יא פְּדַהְאֵ֖ל בֶּן־עַמִּיהֽוּד׃"
          },
          {
            "num": "כט",
            "txt": "אֵ֕לֶּה אֲשֶׁ֖ר צִוָּ֣ה יְהוָ֑ה לְנַחֵ֥ל אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל בְּאֶ֥רֶץ כְּנָֽעַן׃ {פ}"
          }
        ]
      },
      {
        "num": "לה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְדַבֵּ֧ר יְהוָ֛ה אֶל־מֹשֶׁ֖ה בְּעַֽרְבֹ֣ת מוֹאָ֑ב עַל־יַרְדֵּ֥ן יְרֵח֖וֹ לֵאמֹֽר׃"
          },
          {
            "num": "ב",
            "txt": "צַו֮ אֶת־בְּנֵ֣י יִשְׂרָאֵל֒ וְנָֽתְנ֣וּ לַלְוִיִּ֗ם מִֽנַּחֲלַ֛ת אֲחֻזָּתָ֖ם עָרִ֣ים לָשָׁ֑בֶת וּמִגְרָ֗שׁ לֶֽעָרִים֙ סְבִיבֹ֣תֵיהֶ֔ם תִּתְּנ֖וּ לַלְוִיִּֽם׃"
          },
          {
            "num": "ג",
            "txt": "וְהָי֧וּ הֶֽעָרִ֛ים לָהֶ֖ם לָשָׁ֑בֶת וּמִגְרְשֵׁיהֶ֗ם יִֽהְי֤וּ לִבְהֶמְתָּם֙ וְלִרְכֻשָׁ֔ם וּלְכֹ֖ל חַיָּתָֽם׃"
          },
          {
            "num": "ד",
            "txt": "וּמִגְרְשֵׁי֙ הֶֽעָרִ֔ים אֲשֶׁ֥ר תִּתְּנ֖וּ לַלְוִיִּ֑ם מִקִּ֤יר הָעִיר֙ וָח֔וּצָה אֶ֥לֶף אַמָּ֖ה סָבִֽיב׃"
          },
          {
            "num": "ה",
            "txt": "וּמַדֹּתֶ֞ם מִח֣וּץ לָעִ֗יר אֶת־פְּאַת־קֵ֣דְמָה אַלְפַּ֪יִם בָּֽאַמָּ֟ה וְאֶת־פְּאַת־נֶגֶב֩ אַלְפַּ֨יִם בָּֽאַמָּ֜ה וְאֶת־פְּאַת־יָ֣ם ׀ אַלְפַּ֣יִם בָּֽאַמָּ֗ה וְאֵ֨ת פְּאַ֥ת צָפ֛וֹן אַלְפַּ֥יִם בָּֽאַמָּ֖ה וְהָעִ֣יר בַּתָּ֑וֶךְ זֶ֚ה יִֽהְיֶ֣ה לָהֶ֔ם מִגְרְשֵׁ֖י הֶֽעָרִֽים׃"
          },
          {
            "num": "ו",
            "txt": "וְאֵ֣ת הֶֽעָרִ֗ים אֲשֶׁ֤ר תִּתְּנוּ֙ לַלְוִיִּ֔ם אֵ֚ת שֵׁשׁ־עָרֵ֣י הַמִּקְלָ֔ט אֲשֶׁ֣ר תִּתְּנ֔וּ לָנֻ֥ס שָׁ֖מָּה הָֽרֹצֵ֑חַ וַֽעֲלֵיהֶ֣ם תִּתְּנ֔וּ אַרְבָּעִ֥ים וּשְׁתַּ֖יִם עִֽיר׃"
          },
          {
            "num": "ז",
            "txt": "כָּל־הֶֽעָרִ֗ים אֲשֶׁ֤ר תִּתְּנוּ֙ לַלְוִיִּ֔ם אַרְבָּעִ֥ים וּשְׁמֹנֶ֖ה עִ֑יר אֶתְהֶ֖ן וְאֶת־מִגְרְשֵׁיהֶֽן׃"
          },
          {
            "num": "ח",
            "txt": "וְהֶֽעָרִ֗ים אֲשֶׁ֤ר תִּתְּנוּ֙ מֵֽאֲחֻזַּ֣ת בְּנֵֽי־יִשְׂרָאֵ֔ל מֵאֵ֤ת הָרַב֙ תַּרְבּ֔וּ וּמֵאֵ֥ת הַמְעַ֖ט תַּמְעִ֑יטוּ אִ֗ישׁ כְּפִ֤י נַֽחֲלָתוֹ֙ אֲשֶׁ֣ר יִנְחָ֔לוּ יִתֵּ֥ן מֵֽעָרָ֖יו לַלְוִיִּֽם׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֶל־מֹשֶׁ֥ה לֵּאמֹֽר׃"
          },
          {
            "num": "י",
            "txt": "דַּבֵּר֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל וְאָֽמַרְתָּ֖ אֲלֵהֶ֑ם כִּ֥י אַתֶּ֛ם עֹֽבְרִ֥ים אֶת־הַיַּרְדֵּ֖ן אַ֥רְצָה כְּנָֽעַן׃"
          },
          {
            "num": "יא",
            "txt": "וְהִקְרִיתֶ֤ם לָכֶם֙ עָרִ֔ים עָרֵ֥י מִקְלָ֖ט תִּֽהְיֶ֣ינָה לָכֶ֑ם וְנָ֥ס שָׁ֨מָּה֙ רֹצֵ֔חַ מַכֵּה־נֶ֖פֶשׁ בִּשְׁגָגָֽה׃"
          },
          {
            "num": "יב",
            "txt": "וְהָי֨וּ לָכֶ֧ם הֶֽעָרִ֛ים לְמִקְלָ֖ט מִגֹּאֵ֑ל וְלֹ֤א יָמוּת֙ הָֽרֹצֵ֔חַ עַד־עָמְד֛וֹ לִפְנֵ֥י הָֽעֵדָ֖ה לַמִּשְׁפָּֽט׃"
          },
          {
            "num": "יג",
            "txt": "וְהֶֽעָרִ֖ים אֲשֶׁ֣ר תִּתֵּ֑נוּ שֵׁשׁ־עָרֵ֥י מִקְלָ֖ט תִּֽהְיֶ֥ינָה לָכֶֽם׃"
          },
          {
            "num": "יד",
            "txt": "אֵ֣ת ׀ שְׁלֹ֣שׁ הֶֽעָרִ֗ים תִּתְּנוּ֙ מֵעֵ֣בֶר לַיַּרְדֵּ֔ן וְאֵת֙ שְׁלֹ֣שׁ הֶֽעָרִ֔ים תִּתְּנ֖וּ בְּאֶ֣רֶץ כְּנָ֑עַן עָרֵ֥י מִקְלָ֖ט תִּֽהְיֶֽינָה׃"
          },
          {
            "num": "טו",
            "txt": "לִבְנֵ֣י יִשְׂרָאֵ֗ל וְלַגֵּ֤ר וְלַתּוֹשָׁב֙ בְּתוֹכָ֔ם תִּֽהְיֶ֛ינָה שֵׁשׁ־הֶֽעָרִ֥ים הָאֵ֖לֶּה לְמִקְלָ֑ט לָנ֣וּס שָׁ֔מָּה כָּל־מַכֵּה־נֶ֖פֶשׁ בִּשְׁגָגָֽה׃"
          },
          {
            "num": "טז",
            "txt": "וְאִם־בִּכְלִ֨י בַרְזֶ֧ל ׀ הִכָּ֛הוּ וַיָּמֹ֖ת רֹצֵ֣חַֽ ה֑וּא מ֥וֹת יוּמַ֖ת הָֽרֹצֵֽחַ׃"
          },
          {
            "num": "יז",
            "txt": "וְאִ֡ם בְּאֶ֣בֶן יָד֩ אֲשֶׁר־יָמ֨וּת בָּ֥הּ הִכָּ֛הוּ וַיָּמֹ֖ת רֹצֵ֣חַֽ ה֑וּא מ֥וֹת יוּמַ֖ת הָֽרֹצֵֽחַ׃"
          },
          {
            "num": "יח",
            "txt": "א֡וֹ בִּכְלִ֣י עֵֽץ־יָד֩ אֲשֶׁר־יָמ֨וּת בּ֥וֹ הִכָּ֛הוּ וַיָּמֹ֖ת רֹצֵ֣חַֽ ה֑וּא מ֥וֹת יוּמַ֖ת הָֽרֹצֵֽחַ׃"
          },
          {
            "num": "יט",
            "txt": "גֹּאֵ֣ל הַדָּ֔ם ה֥וּא יָמִ֖ית אֶת־הָֽרֹצֵ֑חַ בְּפִגְעוֹ־ב֖וֹ ה֥וּא יְמִתֶֽנּוּ׃"
          },
          {
            "num": "כ",
            "txt": "וְאִם־בְּשִׂנְאָ֖ה יֶהְדֳּפֶ֑נּוּ אֽוֹ־הִשְׁלִ֥יךְ עָלָ֛יו בִּצְדִיָּ֖ה וַיָּמֹֽת׃"
          },
          {
            "num": "כא",
            "txt": "א֣וֹ בְאֵיבָ֞ה הִכָּ֤הוּ בְיָדוֹ֙ וַיָּמֹ֔ת מֽוֹת־יוּמַ֥ת הַמַּכֶּ֖ה רֹצֵ֣חַֽ ה֑וּא גֹּאֵ֣ל הַדָּ֗ם יָמִ֛ית אֶת־הָֽרֹצֵ֖חַ בְּפִגְעוֹ־בֽוֹ׃"
          },
          {
            "num": "כב",
            "txt": "וְאִם־בְּפֶ֥תַע בְּלֹֽא־אֵיבָ֖ה הֲדָפ֑וֹ אֽוֹ־הִשְׁלִ֥יךְ עָלָ֛יו כָּל־כְּלִ֖י בְּלֹ֥א צְדִיָּֽה׃"
          },
          {
            "num": "כג",
            "txt": "א֣וֹ בְכָל־אֶ֜בֶן אֲשֶׁר־יָמ֥וּת בָּהּ֙ בְּלֹ֣א רְא֔וֹת וַיַּפֵּ֥ל עָלָ֖יו וַיָּמֹ֑ת וְהוּא֙ לֹֽא־אוֹיֵ֣ב ל֔וֹ וְלֹ֥א מְבַקֵּ֖שׁ רָֽעָתֽוֹ׃"
          },
          {
            "num": "כד",
            "txt": "וְשָֽׁפְטוּ֙ הָֽעֵדָ֔ה בֵּ֚ין הַמַּכֶּ֔ה וּבֵ֖ין גֹּאֵ֣ל הַדָּ֑ם עַ֥ל הַמִּשְׁפָּטִ֖ים הָאֵֽלֶּה׃"
          },
          {
            "num": "כה",
            "txt": "וְהִצִּ֨ילוּ הָֽעֵדָ֜ה אֶת־הָֽרֹצֵ֗חַ מִיַּד֮ גֹּאֵ֣ל הַדָּם֒ וְהֵשִׁ֤יבוּ אֹתוֹ֙ הָֽעֵדָ֔ה אֶל־עִ֥יר מִקְלָט֖וֹ אֲשֶׁר־נָ֣ס שָׁ֑מָּה וְיָ֣שַׁב בָּ֗הּ עַד־מוֹת֙ הַכֹּהֵ֣ן הַגָּדֹ֔ל אֲשֶׁר־מָשַׁ֥ח אֹת֖וֹ בְּשֶׁ֥מֶן הַקֹּֽדֶשׁ׃"
          },
          {
            "num": "כו",
            "txt": "וְאִם־יָצֹ֥א יֵצֵ֖א הָֽרֹצֵ֑חַ אֶת־גְּבוּל֙ עִ֣יר מִקְלָט֔וֹ אֲשֶׁ֥ר יָנ֖וּס שָֽׁמָּה׃"
          },
          {
            "num": "כז",
            "txt": "וּמָצָ֤א אֹתוֹ֙ גֹּאֵ֣ל הַדָּ֔ם מִח֕וּץ לִגְב֖וּל עִ֣יר מִקְלָט֑וֹ וְרָצַ֞ח גֹּאֵ֤ל הַדָּם֙ אֶת־הָ֣רֹצֵ֔חַ אֵ֥ין ל֖וֹ דָּֽם׃"
          },
          {
            "num": "כח",
            "txt": "כִּ֣י בְעִ֤יר מִקְלָטוֹ֙ יֵשֵׁ֔ב עַד־מ֖וֹת הַכֹּהֵ֣ן הַגָּדֹ֑ל וְאַֽחֲרֵ֥י מוֹת֙ הַכֹּהֵ֣ן הַגָּדֹ֔ל יָשׁוּב֙ הָֽרֹצֵ֔חַ אֶל־אֶ֖רֶץ אֲחֻזָּתֽוֹ׃"
          },
          {
            "num": "כט",
            "txt": "וְהָי֨וּ אֵ֧לֶּה לָכֶ֛ם לְחֻקַּ֥ת מִשְׁפָּ֖ט לְדֹרֹֽתֵיכֶ֑ם בְּכֹ֖ל מוֹשְׁבֹֽתֵיכֶֽם׃"
          },
          {
            "num": "ל",
            "txt": "כָּ֨ל־מַכֵּה־נֶ֔פֶשׁ לְפִ֣י עֵדִ֔ים יִרְצַ֖ח אֶת־הָֽרֹצֵ֑חַ וְעֵ֣ד אֶחָ֔ד לֹֽא־יַעֲנֶ֥ה בְנֶ֖פֶשׁ לָמֽוּת׃"
          },
          {
            "num": "לא",
            "txt": "וְלֹֽא־תִקְח֥וּ כֹ֨פֶר֙ לְנֶ֣פֶשׁ רֹצֵ֔חַ אֲשֶׁר־ה֥וּא רָשָׁ֖ע לָמ֑וּת כִּי־מ֖וֹת יוּמָֽת׃"
          },
          {
            "num": "לב",
            "txt": "וְלֹֽא־תִקְח֣וּ כֹ֔פֶר לָנ֖וּס אֶל־עִ֣יר מִקְלָט֑וֹ לָשׁוּב֙ לָשֶׁ֣בֶת בָּאָ֔רֶץ עַד־מ֖וֹת הַכֹּהֵֽן׃"
          },
          {
            "num": "לג",
            "txt": "וְלֹֽא־תַחֲנִ֣יפוּ אֶת־הָאָ֗רֶץ אֲשֶׁ֤ר אַתֶּם֙ בָּ֔הּ כִּ֣י הַדָּ֔ם ה֥וּא יַֽחֲנִ֖יף אֶת־הָאָ֑רֶץ וְלָאָ֣רֶץ לֹֽא־יְכֻפַּ֗ר לַדָּם֙ אֲשֶׁ֣ר שֻׁפַּךְ־בָּ֔הּ כִּי־אִ֖ם בְּדַ֥ם שֹֽׁפְכֽוֹ׃"
          },
          {
            "num": "לד",
            "txt": "וְלֹ֧א תְטַמֵּ֣א אֶת־הָאָ֗רֶץ אֲשֶׁ֤ר אַתֶּם֙ יֹֽשְׁבִ֣ים בָּ֔הּ אֲשֶׁ֥ר אֲנִ֖י שֹׁכֵ֣ן בְּתוֹכָ֑הּ כִּ֚י אֲנִ֣י יְהוָ֔ה שֹׁכֵ֕ן בְּת֖וֹךְ בְּנֵ֥י יִשְׂרָאֵֽל׃ {פ}"
          }
        ]
      },
      {
        "num": "לו",
        "verses": [
          {
            "num": "א",
            "txt": "וַֽיִּקְרְב֞וּ רָאשֵׁ֣י הָֽאָב֗וֹת לְמִשְׁפַּ֤חַת בְּנֵֽי־גִלְעָד֙ בֶּן־מָכִ֣יר בֶּן־מְנַשֶּׁ֔ה מִֽמִּשְׁפְּחֹ֖ת בְּנֵ֣י יוֹסֵ֑ף וַֽיְדַבְּר֞וּ לִפְנֵ֤י מֹשֶׁה֙ וְלִפְנֵ֣י הַנְּשִׂאִ֔ים רָאשֵׁ֥י אָב֖וֹת לִבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּֽאמְר֗וּ אֶת־אֲדֹנִי֙ צִוָּ֣ה יְהוָ֔ה לָתֵ֨ת אֶת־הָאָ֧רֶץ בְּנַֽחֲלָ֛ה בְּגוֹרָ֖ל לִבְנֵ֣י יִשְׂרָאֵ֑ל וַֽאדֹנִי֙ צֻוָּ֣ה בַֽיהוָ֔ה לָתֵ֗ת אֶֽת־נַחֲלַ֛ת צְלָפְחָ֥ד אָחִ֖ינוּ לִבְנֹתָֽיו׃"
          },
          {
            "num": "ג",
            "txt": "וְ֠הָיוּ לְאֶחָ֞ד מִבְּנֵ֨י שִׁבְטֵ֥י בְנֵֽי־יִשְׂרָאֵל֮ לְנָשִׁים֒ וְנִגְרְעָ֤ה נַֽחֲלָתָן֙ מִנַּֽחֲלַ֣ת אֲבֹתֵ֔ינוּ וְנוֹסַ֕ף עַ֚ל נַֽחֲלַ֣ת הַמַּטֶּ֔ה אֲשֶׁ֥ר תִּֽהְיֶ֖ינָה לָהֶ֑ם וּמִגֹּרַ֥ל נַֽחֲלָתֵ֖נוּ יִגָּרֵֽעַ׃"
          },
          {
            "num": "ד",
            "txt": "וְאִם־יִֽהְיֶ֣ה הַיֹּבֵל֮ לִבְנֵ֣י יִשְׂרָאֵל֒ וְנֽוֹסְפָה֙ נַֽחֲלָתָ֔ן עַ֚ל נַֽחֲלַ֣ת הַמַּטֶּ֔ה אֲשֶׁ֥ר תִּֽהְיֶ֖ינָה לָהֶ֑ם וּמִֽנַּחֲלַת֙ מַטֵּ֣ה אֲבֹתֵ֔ינוּ יִגָּרַ֖ע נַֽחֲלָתָֽן׃"
          },
          {
            "num": "ה",
            "txt": "וַיְצַ֤ו מֹשֶׁה֙ אֶת־בְּנֵ֣י יִשְׂרָאֵ֔ל עַל־פִּ֥י יְהוָ֖ה לֵאמֹ֑ר כֵּ֛ן מַטֵּ֥ה בְנֵֽי־יוֹסֵ֖ף דֹּֽבְרִֽים׃"
          },
          {
            "num": "ו",
            "txt": "זֶ֣ה הַדָּבָ֞ר אֲשֶׁר־צִוָּ֣ה יְהוָ֗ה לִבְנ֤וֹת צְלָפְחָד֙ לֵאמֹ֔ר לַטּ֥וֹב בְּעֵֽינֵיהֶ֖ם תִּֽהְיֶ֣ינָה לְנָשִׁ֑ים אַ֗ךְ לְמִשְׁפַּ֛חַת מַטֵּ֥ה אֲבִיהֶ֖ם תִּֽהְיֶ֥ינָה לְנָשִֽׁים׃"
          },
          {
            "num": "ז",
            "txt": "וְלֹֽא־תִסֹּ֤ב נַֽחֲלָה֙ לִבְנֵ֣י יִשְׂרָאֵ֔ל מִמַּטֶּ֖ה אֶל־מַטֶּ֑ה כִּ֣י אִ֗ישׁ בְּנַֽחֲלַת֙ מַטֵּ֣ה אֲבֹתָ֔יו יִדְבְּק֖וּ בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ח",
            "txt": "וְכָל־בַּ֞ת יֹרֶ֣שֶׁת נַֽחֲלָ֗ה מִמַּטּוֹת֮ בְּנֵ֣י יִשְׂרָאֵל֒ לְאֶחָ֗ד מִמִּשְׁפַּ֛חַת מַטֵּ֥ה אָבִ֖יהָ תִּֽהְיֶ֣ה לְאִשָּׁ֑ה לְמַ֗עַן יִֽירְשׁוּ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל אִ֖ישׁ נַֽחֲלַ֥ת אֲבֹתָֽיו׃"
          },
          {
            "num": "ט",
            "txt": "וְלֹֽא־תִסֹּ֧ב נַֽחֲלָ֛ה מִמַּטֶּ֖ה לְמַטֶּ֣ה אַחֵ֑ר כִּי־אִישׁ֙ בְּנַ֣חֲלָת֔וֹ יִדְבְּק֕וּ מַטּ֖וֹת בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "י",
            "txt": "כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶׁ֑ה כֵּ֥ן עָשׂ֖וּ בְּנ֥וֹת צְלָפְחָֽד׃"
          },
          {
            "num": "יא",
            "txt": "וַתִּֽהְיֶ֜ינָה מַחְלָ֣ה תִרְצָ֗ה וְחָגְלָ֧ה וּמִלְכָּ֛ה וְנֹעָ֖ה בְּנ֣וֹת צְלָפְחָ֑ד לִבְנֵ֥י דֹֽדֵיהֶ֖ן לְנָשִֽׁים׃"
          },
          {
            "num": "יב",
            "txt": "מִֽמִּשְׁפְּחֹ֛ת בְּנֵֽי־מְנַשֶּׁ֥ה בֶן־יוֹסֵ֖ף הָי֣וּ לְנָשִׁ֑ים וַתְּהִי֙ נַֽחֲלָתָ֔ן עַל־מַטֵּ֖ה מִשְׁפַּ֥חַת אֲבִיהֶֽן׃"
          },
          {
            "num": "יג",
            "txt": "אֵ֣לֶּה הַמִּצְוֺ֞ת וְהַמִּשְׁפָּטִ֗ים אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה בְּיַד־מֹשֶׁ֖ה אֶל־בְּנֵ֣י יִשְׂרָאֵ֑ל בְּעַֽרְבֹ֣ת מוֹאָ֔ב עַ֖ל יַרְדֵּ֥ן יְרֵחֽוֹ׃ {ש}"
          }
        ]
      }
    ]
  },
  "דברים": {
    "chapters": [
      {
        "num": "א",
        "verses": [
          {
            "num": "א",
            "txt": "אֵ֣לֶּה הַדְּבָרִ֗ים אֲשֶׁ֨ר דִּבֶּ֤ר מֹשֶׁה֙ אֶל־כָּל־יִשְׂרָאֵ֔ל בְּעֵ֖בֶר הַיַּרְדֵּ֑ן בַּמִּדְבָּ֡ר בָּֽעֲרָבָה֩ מ֨וֹל ס֜וּף בֵּֽין־פָּארָ֧ן וּבֵֽין־תֹּ֛פֶל וְלָבָ֥ן וַֽחֲצֵרֹ֖ת וְדִ֥י זָהָֽב׃"
          },
          {
            "num": "ב",
            "txt": "אַחַ֨ד עָשָׂ֥ר יוֹם֙ מֵֽחֹרֵ֔ב דֶּ֖רֶךְ הַר־שֵׂעִ֑יר עַ֖ד קָדֵ֥שׁ בַּרְנֵֽעַ׃"
          },
          {
            "num": "ג",
            "txt": "וַֽיְהִי֙ בְּאַרְבָּעִ֣ים שָׁנָ֔ה בְּעַשְׁתֵּֽי־עָשָׂ֥ר חֹ֖דֶשׁ בְּאֶחָ֣ד לַחֹ֑דֶשׁ דִּבֶּ֤ר מֹשֶׁה֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל כְּ֠כֹל אֲשֶׁ֨ר צִוָּ֧ה יְהוָ֛ה אֹת֖וֹ אֲלֵהֶֽם׃"
          },
          {
            "num": "ד",
            "txt": "אַֽחֲרֵ֣י הַכֹּת֗וֹ אֵ֚ת סִיחֹן֙ מֶ֣לֶךְ הָֽאֱמֹרִ֔י אֲשֶׁ֥ר יוֹשֵׁ֖ב בְּחֶשְׁבּ֑וֹן וְאֵ֗ת ע֚וֹג מֶ֣לֶךְ הַבָּשָׁ֔ן אֲשֶׁר־יוֹשֵׁ֥ב בְּעַשְׁתָּרֹ֖ת בְּאֶדְרֶֽעִי׃"
          },
          {
            "num": "ה",
            "txt": "בְּעֵ֥בֶר הַיַּרְדֵּ֖ן בְּאֶ֣רֶץ מוֹאָ֑ב הוֹאִ֣יל מֹשֶׁ֔ה בֵּאֵ֛ר אֶת־הַתּוֹרָ֥ה הַזֹּ֖את לֵאמֹֽר׃"
          },
          {
            "num": "ו",
            "txt": "יְהוָ֧ה אֱלֹהֵ֛ינוּ דִּבֶּ֥ר אֵלֵ֖ינוּ בְּחֹרֵ֣ב לֵאמֹ֑ר רַב־לָכֶ֥ם שֶׁ֖בֶת בָּהָ֥ר הַזֶּֽה׃"
          },
          {
            "num": "ז",
            "txt": "פְּנ֣וּ ׀ וּסְע֣וּ לָכֶ֗ם וּבֹ֨אוּ הַ֥ר הָֽאֱמֹרִי֮ וְאֶל־כָּל־שְׁכֵנָיו֒ בָּֽעֲרָבָ֥ה בָהָ֛ר וּבַשְּׁפֵלָ֥ה וּבַנֶּ֖גֶב וּבְח֣וֹף הַיָּ֑ם אֶ֤רֶץ הַֽכְּנַעֲנִי֙ וְהַלְּבָנ֔וֹן עַד־הַנָּהָ֥ר הַגָּדֹ֖ל נְהַר־פְּרָֽת׃"
          },
          {
            "num": "ח",
            "txt": "רְאֵ֛ה נָתַ֥תִּי לִפְנֵיכֶ֖ם אֶת־הָאָ֑רֶץ בֹּ֚אוּ וּרְשׁ֣וּ אֶת־הָאָ֔רֶץ אֲשֶׁ֣ר נִשְׁבַּ֣ע יְ֠הוָה לַאֲבֹ֨תֵיכֶ֜ם לְאַבְרָהָ֨ם לְיִצְחָ֤ק וּֽלְיַעֲקֹב֙ לָתֵ֣ת לָהֶ֔ם וּלְזַרְעָ֖ם אַֽחֲרֵיהֶֽם׃"
          },
          {
            "num": "ט",
            "txt": "וָֽאֹמַ֣ר אֲלֵכֶ֔ם בָּעֵ֥ת הַהִ֖וא לֵאמֹ֑ר לֹֽא־אוּכַ֥ל לְבַדִּ֖י שְׂאֵ֥ת אֶתְכֶֽם׃"
          },
          {
            "num": "י",
            "txt": "יְהוָ֥ה אֱלֹֽהֵיכֶ֖ם הִרְבָּ֣ה אֶתְכֶ֑ם וְהִנְּכֶ֣ם הַיּ֔וֹם כְּכֽוֹכְבֵ֥י הַשָּׁמַ֖יִם לָרֹֽב׃"
          },
          {
            "num": "יא",
            "txt": "יְהוָ֞ה אֱלֹהֵ֣י אֲבֽוֹתֵכֶ֗ם יֹסֵ֧ף עֲלֵיכֶ֛ם כָּכֶ֖ם אֶ֣לֶף פְּעָמִ֑ים וִֽיבָרֵ֣ךְ אֶתְכֶ֔ם כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר לָכֶֽם׃"
          },
          {
            "num": "יב",
            "txt": "אֵיכָ֥ה אֶשָּׂ֖א לְבַדִּ֑י טָרְחֲכֶ֥ם וּמַֽשַּׂאֲכֶ֖ם וְרִֽיבְכֶֽם׃"
          },
          {
            "num": "יג",
            "txt": "הָב֣וּ לָ֠כֶם אֲנָשִׁ֨ים חֲכָמִ֧ים וּנְבֹנִ֛ים וִֽידֻעִ֖ים לְשִׁבְטֵיכֶ֑ם וַֽאֲשִׂימֵ֖ם בְּרָֽאשֵׁיכֶֽם׃"
          },
          {
            "num": "יד",
            "txt": "וַֽתַּעֲנ֖וּ אֹתִ֑י וַתֹּ֣אמְר֔וּ טֽוֹב־הַדָּבָ֥ר אֲשֶׁר־דִּבַּ֖רְתָּ לַֽעֲשֽׂוֹת׃"
          },
          {
            "num": "טו",
            "txt": "וָֽאֶקַּ֞ח אֶת־רָאשֵׁ֣י שִׁבְטֵיכֶ֗ם אֲנָשִׁ֤ים חֲכָמִים֙ וִֽידֻעִ֔ים וָֽאֶתֵּ֥ן אוֹתָ֛ם רָאשִׁ֖ים עֲלֵיכֶ֑ם שָׂרֵ֨י אֲלָפִ֜ים וְשָׂרֵ֣י מֵא֗וֹת וְשָׂרֵ֤י חֲמִשִּׁים֙ וְשָׂרֵ֣י עֲשָׂרֹ֔ת וְשֹֽׁטְרִ֖ים לְשִׁבְטֵיכֶֽם׃"
          },
          {
            "num": "טז",
            "txt": "וָֽאֲצַוֶּה֙ אֶת־שֹׁ֣פְטֵיכֶ֔ם בָּעֵ֥ת הַהִ֖וא לֵאמֹ֑ר שָׁמֹ֤עַ בֵּין־אֲחֵיכֶם֙ וּשְׁפַטְתֶּ֣ם צֶ֔דֶק בֵּֽין־אִ֥ישׁ וּבֵין־אָחִ֖יו וּבֵ֥ין גֵּרֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "לֹֽא־תַכִּ֨ירוּ פָנִ֜ים בַּמִּשְׁפָּ֗ט כַּקָּטֹ֤ן כַּגָּדֹל֙ תִּשְׁמָע֔וּן לֹ֤א תָג֨וּרוּ֙ מִפְּנֵי־אִ֔ישׁ כִּ֥י הַמִּשְׁפָּ֖ט לֵֽאלֹהִ֣ים ה֑וּא וְהַדָּבָר֙ אֲשֶׁ֣ר יִקְשֶׁ֣ה מִכֶּ֔ם תַּקְרִב֥וּן אֵלַ֖י וּשְׁמַעְתִּֽיו׃"
          },
          {
            "num": "יח",
            "txt": "וָֽאֲצַוֶּ֥ה אֶתְכֶ֖ם בָּעֵ֣ת הַהִ֑וא אֵ֥ת כָּל־הַדְּבָרִ֖ים אֲשֶׁ֥ר תַּֽעֲשֽׂוּן׃"
          },
          {
            "num": "יט",
            "txt": "וַנִּסַּ֣ע מֵֽחֹרֵ֗ב וַנֵּ֡לֶךְ אֵ֣ת כָּל־הַמִּדְבָּ֣ר הַגָּדוֹל֩ וְהַנּוֹרָ֨א הַה֜וּא אֲשֶׁ֣ר רְאִיתֶ֗ם דֶּ֚רֶךְ הַ֣ר הָֽאֱמֹרִ֔י כַּֽאֲשֶׁ֥ר צִוָּ֛ה יְהוָ֥ה אֱלֹהֵ֖ינוּ אֹתָ֑נוּ וַנָּבֹ֕א עַ֖ד קָדֵ֥שׁ בַּרְנֵֽעַ׃"
          },
          {
            "num": "כ",
            "txt": "וָֽאֹמַ֖ר אֲלֵכֶ֑ם בָּאתֶם֙ עַד־הַ֣ר הָֽאֱמֹרִ֔י אֲשֶׁר־יְהוָ֥ה אֱלֹהֵ֖ינוּ נֹתֵ֥ן לָֽנוּ׃"
          },
          {
            "num": "כא",
            "txt": "רְ֠אֵה נָתַ֨ן יְהוָ֧ה אֱלֹהֶ֛יךָ לְפָנֶ֖יךָ אֶת־הָאָ֑רֶץ עֲלֵ֣ה רֵ֗שׁ כַּֽאֲשֶׁר֩ דִּבֶּ֨ר יְהוָ֜ה אֱלֹהֵ֤י אֲבֹתֶ֨יךָ֙ לָ֔ךְ אַל־תִּירָ֖א וְאַל־תֵּחָֽת׃"
          },
          {
            "num": "כב",
            "txt": "וַתִּקְרְב֣וּן אֵלַי֮ כֻּלְּכֶם֒ וַתֹּֽאמְר֗וּ נִשְׁלְחָ֤ה אֲנָשִׁים֙ לְפָנֵ֔ינוּ וְיַחְפְּרוּ־לָ֖נוּ אֶת־הָאָ֑רֶץ וְיָשִׁ֤בוּ אֹתָ֨נוּ֙ דָּבָ֔ר אֶת־הַדֶּ֨רֶךְ֙ אֲשֶׁ֣ר נַֽעֲלֶה־בָּ֔הּ וְאֵת֙ הֶֽעָרִ֔ים אֲשֶׁ֥ר נָבֹ֖א אֲלֵיהֶֽן׃"
          },
          {
            "num": "כג",
            "txt": "וַיִּיטַ֥ב בְּעֵינַ֖י הַדָּבָ֑ר וָֽאֶקַּ֤ח מִכֶּם֙ שְׁנֵ֣ים עָשָׂ֣ר אֲנָשִׁ֔ים אִ֥ישׁ אֶחָ֖ד לַשָּֽׁבֶט׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּפְנוּ֙ וַיַּֽעֲל֣וּ הָהָ֔רָה וַיָּבֹ֖אוּ עַד־נַ֣חַל אֶשְׁכֹּ֑ל וַֽיְרַגְּל֖וּ אֹתָֽהּ׃"
          },
          {
            "num": "כה",
            "txt": "וַיִּקְח֤וּ בְיָדָם֙ מִפְּרִ֣י הָאָ֔רֶץ וַיּוֹרִ֖דוּ אֵלֵ֑ינוּ וַיָּשִׁ֨בוּ אֹתָ֤נוּ דָבָר֙ וַיֹּ֣אמְר֔וּ טוֹבָ֣ה הָאָ֔רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹהֵ֖ינוּ נֹתֵ֥ן לָֽנוּ׃"
          },
          {
            "num": "כו",
            "txt": "וְלֹ֥א אֲבִיתֶ֖ם לַֽעֲלֹ֑ת וַתַּמְר֕וּ אֶת־פִּ֥י יְהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "כז",
            "txt": "וַתֵּרָֽגְנ֤וּ בְאָֽהֳלֵיכֶם֙ וַתֹּ֣אמְר֔וּ בְּשִׂנְאַ֤ת יְהוָה֙ אֹתָ֔נוּ הֽוֹצִיאָ֖נוּ מֵאֶ֣רֶץ מִצְרָ֑יִם לָתֵ֥ת אֹתָ֛נוּ בְּיַ֥ד הָֽאֱמֹרִ֖י לְהַשְׁמִידֵֽנוּ׃"
          },
          {
            "num": "כח",
            "txt": "אָנָ֣ה ׀ אֲנַ֣חְנוּ עֹלִ֗ים אַחֵינוּ֩ הֵמַ֨סּוּ אֶת־לְבָבֵ֜נוּ לֵאמֹ֗ר עַ֣ם גָּד֤וֹל וָרָם֙ מִמֶּ֔נּוּ עָרִ֛ים גְּדֹלֹ֥ת וּבְצוּרֹ֖ת בַּשָּׁמָ֑יִם וְגַם־בְּנֵ֥י עֲנָקִ֖ים רָאִ֥ינוּ שָֽׁם׃"
          },
          {
            "num": "כט",
            "txt": "וָֽאֹמַ֖ר אֲלֵכֶ֑ם לֹא־תַֽעַרְצ֥וּן וְֽלֹא־תִֽירְא֖וּן מֵהֶֽם׃"
          },
          {
            "num": "ל",
            "txt": "יְהוָ֤ה אֱלֹֽהֵיכֶם֙ הַֽהֹלֵ֣ךְ לִפְנֵיכֶ֔ם ה֖וּא יִלָּחֵ֣ם לָכֶ֑ם כְּ֠כֹל אֲשֶׁ֨ר עָשָׂ֧ה אִתְּכֶ֛ם בְּמִצְרַ֖יִם לְעֵֽינֵיכֶֽם׃"
          },
          {
            "num": "לא",
            "txt": "וּבַמִּדְבָּר֙ אֲשֶׁ֣ר רָאִ֔יתָ אֲשֶׁ֤ר נְשָֽׂאֲךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ כַּֽאֲשֶׁ֥ר יִשָּׂא־אִ֖ישׁ אֶת־בְּנ֑וֹ בְּכָל־הַדֶּ֨רֶךְ֙ אֲשֶׁ֣ר הֲלַכְתֶּ֔ם עַד־בֹּֽאֲכֶ֖ם עַד־הַמָּק֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "לב",
            "txt": "וּבַדָּבָ֖ר הַזֶּ֑ה אֵֽינְכֶם֙ מַֽאֲמִינִ֔ם בַּֽיהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "לג",
            "txt": "הַֽהֹלֵ֨ךְ לִפְנֵיכֶ֜ם בַּדֶּ֗רֶךְ לָת֥וּר לָכֶ֛ם מָק֖וֹם לַחֲנֹֽתְכֶ֑ם בָּאֵ֣שׁ ׀ לַ֗יְלָה לַרְאֹֽתְכֶם֙ בַּדֶּ֨רֶךְ֙ אֲשֶׁ֣ר תֵּֽלְכוּ־בָ֔הּ וּבֶֽעָנָ֖ן יוֹמָֽם׃"
          },
          {
            "num": "לד",
            "txt": "וַיִּשְׁמַ֥ע יְהוָ֖ה אֶת־ק֣וֹל דִּבְרֵיכֶ֑ם וַיִּקְצֹ֖ף וַיִּשָּׁבַ֥ע לֵאמֹֽר׃"
          },
          {
            "num": "לה",
            "txt": "אִם־יִרְאֶ֥ה אִישׁ֙ בָּֽאֲנָשִׁ֣ים הָאֵ֔לֶּה הַדּ֥וֹר הָרָ֖ע הַזֶּ֑ה אֵ֚ת הָאָ֣רֶץ הַטּוֹבָ֔ה אֲשֶׁ֣ר נִשְׁבַּ֔עְתִּי לָתֵ֖ת לַאֲבֹֽתֵיכֶֽם׃"
          },
          {
            "num": "לו",
            "txt": "זֽוּלָתִ֞י כָּלֵ֤ב בֶּן־יְפֻנֶּה֙ ה֣וּא יִרְאֶ֔נָּה וְלֽוֹ־אֶתֵּ֧ן אֶת־הָאָ֛רֶץ אֲשֶׁ֥ר דָּֽרַךְ־בָּ֖הּ וּלְבָנָ֑יו יַ֕עַן אֲשֶׁ֥ר מִלֵּ֖א אַֽחֲרֵ֥י יְהוָֽה׃"
          },
          {
            "num": "לז",
            "txt": "גַּם־בִּי֙ הִתְאַנַּ֣ף יְהוָ֔ה בִּגְלַלְכֶ֖ם לֵאמֹ֑ר גַּם־אַתָּ֖ה לֹֽא־תָבֹ֥א שָֽׁם׃"
          },
          {
            "num": "לח",
            "txt": "יְהוֹשֻׁ֤עַ בִּן־נוּן֙ הָֽעֹמֵ֣ד לְפָנֶ֔יךָ ה֖וּא יָ֣בֹא שָׁ֑מָּה אֹת֣וֹ חַזֵּ֔ק כִּי־ה֖וּא יַנְחִלֶ֥נָּה אֶת־יִשְׂרָאֵֽל׃"
          },
          {
            "num": "לט",
            "txt": "וְטַפְּכֶם֩ אֲשֶׁ֨ר אֲמַרְתֶּ֜ם לָבַ֣ז יִֽהְיֶ֗ה וּ֠בְנֵיכֶם אֲשֶׁ֨ר לֹֽא־יָדְע֤וּ הַיּוֹם֙ ט֣וֹב וָרָ֔ע הֵ֖מָּה יָבֹ֣אוּ שָׁ֑מָּה וְלָהֶ֣ם אֶתְּנֶ֔נָּה וְהֵ֖ם יִֽירָשֽׁוּהָ׃"
          },
          {
            "num": "מ",
            "txt": "וְאַתֶּ֖ם פְּנ֣וּ לָכֶ֑ם וּסְע֥וּ הַמִּדְבָּ֖רָה דֶּ֥רֶךְ יַם־סֽוּף׃"
          },
          {
            "num": "מא",
            "txt": "וַֽתַּעֲנ֣וּ ׀ וַתֹּֽאמְר֣וּ אֵלַ֗י חָטָאנוּ֮ לַֽיהוָה֒ אֲנַ֤חְנוּ נַֽעֲלֶה֙ וְנִלְחַ֔מְנוּ כְּכֹ֥ל אֲשֶׁר־צִוָּ֖נוּ יְהוָ֣ה אֱלֹהֵ֑ינוּ וַֽתַּחְגְּר֗וּ אִ֚ישׁ אֶת־כְּלֵ֣י מִלְחַמְתּ֔וֹ וַתָּהִ֖ינוּ לַֽעֲלֹ֥ת הָהָֽרָה׃"
          },
          {
            "num": "מב",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֵלַ֗י אֱמֹ֤ר לָהֶם֙ לֹ֤א תַֽעֲלוּ֙ וְלֹא־תִלָּ֣חֲמ֔וּ כִּ֥י אֵינֶ֖נִּי בְּקִרְבְּכֶ֑ם וְלֹא֙ תִּנָּ֣גְפ֔וּ לִפְנֵ֖י אֹֽיְבֵיכֶֽם׃"
          },
          {
            "num": "מג",
            "txt": "וָֽאֲדַבֵּ֥ר אֲלֵיכֶ֖ם וְלֹ֣א שְׁמַעְתֶּ֑ם וַתַּמְרוּ֙ אֶת־פִּ֣י יְהוָ֔ה וַתָּזִ֖דוּ וַתַּֽעֲל֥וּ הָהָֽרָה׃"
          },
          {
            "num": "מד",
            "txt": "וַיֵּצֵ֨א הָֽאֱמֹרִ֜י הַיֹּשֵׁ֨ב בָּהָ֤ר הַהוּא֙ לִקְרַאתְכֶ֔ם וַיִּרְדְּפ֣וּ אֶתְכֶ֔ם כַּֽאֲשֶׁ֥ר תַּֽעֲשֶׂ֖ינָה הַדְּבֹרִ֑ים וַֽיַּכְּת֥וּ אֶתְכֶ֛ם בְּשֵׂעִ֖יר עַד־חָרְמָֽה׃"
          },
          {
            "num": "מה",
            "txt": "וַתָּשֻׁ֥בוּ וַתִּבְכּ֖וּ לִפְנֵ֣י יְהוָ֑ה וְלֹֽא־שָׁמַ֤ע יְהוָה֙ בְּקֹ֣לְכֶ֔ם וְלֹ֥א הֶֽאֱזִ֖ין אֲלֵיכֶֽם׃"
          },
          {
            "num": "מו",
            "txt": "וַתֵּֽשְׁב֥וּ בְקָדֵ֖שׁ יָמִ֣ים רַבִּ֑ים כַּיָּמִ֖ים אֲשֶׁ֥ר יְשַׁבְתֶּֽם׃"
          }
        ]
      },
      {
        "num": "ב",
        "verses": [
          {
            "num": "א",
            "txt": "וַנֵּ֜פֶן וַנִּסַּ֤ע הַמִּדְבָּ֨רָה֙ דֶּ֣רֶךְ יַם־ס֔וּף כַּֽאֲשֶׁ֛ר דִּבֶּ֥ר יְהוָ֖ה אֵלָ֑י וַנָּ֥סָב אֶת־הַר־שֵׂעִ֖יר יָמִ֥ים רַבִּֽים׃ {ס}"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֵלַ֥י לֵאמֹֽר׃"
          },
          {
            "num": "ג",
            "txt": "רַב־לָכֶ֕ם סֹ֖ב אֶת־הָהָ֣ר הַזֶּ֑ה פְּנ֥וּ לָכֶ֖ם צָפֹֽנָה׃"
          },
          {
            "num": "ד",
            "txt": "וְאֶת־הָעָם֮ צַ֣ו לֵאמֹר֒ אַתֶּ֣ם עֹֽבְרִ֗ים בִּגְבוּל֙ אֲחֵיכֶ֣ם בְּנֵֽי־עֵשָׂ֔ו הַיֹּֽשְׁבִ֖ים בְּשֵׂעִ֑יר וְיִֽירְא֣וּ מִכֶּ֔ם וְנִשְׁמַרְתֶּ֖ם מְאֹֽד׃"
          },
          {
            "num": "ה",
            "txt": "אַל־תִּתְגָּר֣וּ בָ֔ם כִּ֠י לֹֽא־אֶתֵּ֤ן לָכֶם֙ מֵֽאַרְצָ֔ם עַ֖ד מִדְרַ֣ךְ כַּף־רָ֑גֶל כִּֽי־יְרֻשָּׁ֣ה לְעֵשָׂ֔ו נָתַ֖תִּי אֶת־הַ֥ר שֵׂעִֽיר׃"
          },
          {
            "num": "ו",
            "txt": "אֹ֣כֶל תִּשְׁבְּר֧וּ מֵֽאִתָּ֛ם בַּכֶּ֖סֶף וַֽאֲכַלְתֶּ֑ם וְגַם־מַ֜יִם תִּכְר֧וּ מֵֽאִתָּ֛ם בַּכֶּ֖סֶף וּשְׁתִיתֶֽם׃"
          },
          {
            "num": "ז",
            "txt": "כִּי֩ יְהוָ֨ה אֱלֹהֶ֜יךָ בֵּֽרַכְךָ֗ בְּכֹל֙ מַֽעֲשֵׂ֣ה יָדֶ֔ךָ יָדַ֣ע לֶכְתְּךָ֔ אֶת־הַמִּדְבָּ֥ר הַגָּדֹ֖ל הַזֶּ֑ה זֶ֣ה ׀ אַרְבָּעִ֣ים שָׁנָ֗ה יְהוָ֤ה אֱלֹהֶ֨יךָ֙ עִמָּ֔ךְ לֹ֥א חָסַ֖רְתָּ דָּבָֽר׃"
          },
          {
            "num": "ח",
            "txt": "וַֽנַּעֲבֹ֞ר מֵאֵ֧ת אַחֵ֣ינוּ בְנֵֽי־עֵשָׂ֗ו הַיֹּֽשְׁבִים֙ בְּשֵׂעִ֔יר מִדֶּ֨רֶךְ֙ הָֽעֲרָבָ֔ה מֵֽאֵילַ֖ת וּמֵֽעֶצְיֹ֣ן גָּ֑בֶר {ס} וַנֵּ֨פֶן֙ וַֽנַּעֲבֹ֔ר דֶּ֖רֶךְ מִדְבַּ֥ר מוֹאָֽב׃"
          },
          {
            "num": "ט",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֵלַ֗י אַל־תָּ֨צַר֙ אֶת־מוֹאָ֔ב וְאַל־תִּתְגָּ֥ר בָּ֖ם מִלְחָמָ֑ה כִּ֠י לֹֽא־אֶתֵּ֨ן לְךָ֤ מֵֽאַרְצוֹ֙ יְרֻשָּׁ֔ה כִּ֣י לִבְנֵי־ל֔וֹט נָתַ֥תִּי אֶת־עָ֖ר יְרֻשָּֽׁה׃"
          },
          {
            "num": "י",
            "txt": "הָֽאֵמִ֥ים לְפָנִ֖ים יָ֣שְׁבוּ בָ֑הּ עַ֣ם גָּד֥וֹל וְרַ֛ב וָרָ֖ם כָּֽעֲנָקִֽים׃"
          },
          {
            "num": "יא",
            "txt": "רְפָאִ֛ים יֵחָֽשְׁב֥וּ אַף־הֵ֖ם כָּֽעֲנָקִ֑ים וְהַמֹּ֣אָבִ֔ים יִקְרְא֥וּ לָהֶ֖ם אֵמִֽים׃"
          },
          {
            "num": "יב",
            "txt": "וּבְשֵׂעִ֞יר יָֽשְׁב֣וּ הַֽחֹרִים֮ לְפָנִים֒ וּבְנֵ֧י עֵשָׂ֣ו יִֽירָשׁ֗וּם וַיַּשְׁמִידוּם֙ מִפְּנֵיהֶ֔ם וַיֵּֽשְׁב֖וּ תַּחְתָּ֑ם כַּֽאֲשֶׁ֧ר עָשָׂ֣ה יִשְׂרָאֵ֗ל לְאֶ֨רֶץ֙ יְרֻשָּׁת֔וֹ אֲשֶׁר־נָתַ֥ן יְהוָ֖ה לָהֶֽם׃"
          },
          {
            "num": "יג",
            "txt": "עַתָּ֗ה קֻ֛מוּ וְעִבְר֥וּ לָכֶ֖ם אֶת־נַ֣חַל זָ֑רֶד וַֽנַּעֲבֹ֖ר אֶת־נַ֥חַל זָֽרֶד׃"
          },
          {
            "num": "יד",
            "txt": "וְהַיָּמִ֞ים אֲשֶׁר־הָלַ֣כְנוּ ׀ מִקָּדֵ֣שׁ בַּרְנֵ֗עַ עַ֤ד אֲשֶׁר־עָבַ֨רְנוּ֙ אֶת־נַ֣חַל זֶ֔רֶד שְׁלֹשִׁ֥ים וּשְׁמֹנֶ֖ה שָׁנָ֑ה עַד־תֹּ֨ם כָּל־הַדּ֜וֹר אַנְשֵׁ֤י הַמִּלְחָמָה֙ מִקֶּ֣רֶב הַֽמַּחֲנֶ֔ה כַּֽאֲשֶׁ֛ר נִשְׁבַּ֥ע יְהוָ֖ה לָהֶֽם׃"
          },
          {
            "num": "טו",
            "txt": "וְגַ֤ם יַד־יְהוָה֙ הָ֣יְתָה בָּ֔ם לְהֻמָּ֖ם מִקֶּ֣רֶב הַֽמַּחֲנֶ֑ה עַ֖ד תֻּמָּֽם׃"
          },
          {
            "num": "טז",
            "txt": "וַיְהִ֨י כַֽאֲשֶׁר־תַּ֜מּוּ כָּל־אַנְשֵׁ֧י הַמִּלְחָמָ֛ה לָמ֖וּת מִקֶּ֥רֶב הָעָֽם׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וַיְדַבֵּ֥ר יְהוָ֖ה אֵלַ֥י לֵאמֹֽר׃"
          },
          {
            "num": "יח",
            "txt": "אַתָּ֨ה עֹבֵ֥ר הַיּ֛וֹם אֶת־גְּב֥וּל מוֹאָ֖ב אֶת־עָֽר׃"
          },
          {
            "num": "יט",
            "txt": "וְקָֽרַבְתָּ֗ מ֚וּל בְּנֵ֣י עַמּ֔וֹן אַל־תְּצֻרֵ֖ם וְאַל־תִּתְגָּ֣ר בָּ֑ם כִּ֣י לֹֽא־אֶ֠תֵּן מֵאֶ֨רֶץ בְּנֵֽי־עַמּ֤וֹן לְךָ֙ יְרֻשָּׁ֔ה כִּ֥י לִבְנֵי־ל֖וֹט נְתַתִּ֥יהָ יְרֻשָּֽׁה׃"
          },
          {
            "num": "כ",
            "txt": "אֶֽרֶץ־רְפָאִ֥ים תֵּֽחָשֵׁ֖ב אַף־הִ֑וא רְפָאִ֤ים יָֽשְׁבוּ־בָהּ֙ לְפָנִ֔ים וְהָֽעַמֹּנִ֔ים יִקְרְא֥וּ לָהֶ֖ם זַמְזֻמִּֽים׃"
          },
          {
            "num": "כא",
            "txt": "עַ֣ם גָּד֥וֹל וְרַ֛ב וָרָ֖ם כָּֽעֲנָקִ֑ים וַיַּשְׁמִידֵ֤ם יְהוָה֙ מִפְּנֵיהֶ֔ם וַיִּֽירָשֻׁ֖ם וַיֵּֽשְׁב֥וּ תַחְתָּֽם׃"
          },
          {
            "num": "כב",
            "txt": "כַּֽאֲשֶׁ֤ר עָשָׂה֙ לִבְנֵ֣י עֵשָׂ֔ו הַיֹּֽשְׁבִ֖ים בְּשֵׂעִ֑יר אֲשֶׁ֨ר הִשְׁמִ֤יד אֶת־הַֽחֹרִי֙ מִפְּנֵיהֶ֔ם וַיִּֽירָשֻׁם֙ וַיֵּֽשְׁב֣וּ תַחְתָּ֔ם עַ֖ד הַיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "כג",
            "txt": "וְהָֽעַוִּ֛ים הַיֹּֽשְׁבִ֥ים בַּֽחֲצֵרִ֖ים עַד־עַזָּ֑ה כַּפְתֹּרִים֙ הַיֹּֽצְאִ֣ים מִכַּפְתֹּ֔ר הִשְׁמִידֻ֖ם וַיֵּֽשְׁב֥וּ תַחְתָּֽם׃"
          },
          {
            "num": "כד",
            "txt": "ק֣וּמוּ סְּע֗וּ וְעִבְרוּ֮ אֶת־נַ֣חַל אַרְנֹן֒ רְאֵ֣ה נָתַ֣תִּי בְ֠יָֽדְךָ אֶת־סִיחֹ֨ן מֶֽלֶךְ־חֶשְׁבּ֧וֹן הָֽאֱמֹרִ֛י וְאֶת־אַרְצ֖וֹ הָחֵ֣ל רָ֑שׁ וְהִתְגָּ֥ר בּ֖וֹ מִלְחָמָֽה׃"
          },
          {
            "num": "כה",
            "txt": "הַיּ֣וֹם הַזֶּ֗ה אָחֵל֙ תֵּ֤ת פַּחְדְּךָ֙ וְיִרְאָ֣תְךָ֔ עַל־פְּנֵי֙ הָֽעַמִּ֔ים תַּ֖חַת כָּל־הַשָּׁמָ֑יִם אֲשֶׁ֤ר יִשְׁמְעוּן֙ שִׁמְעֲךָ֔ וְרָֽגְז֥וּ וְחָל֖וּ מִפָּנֶֽיךָ׃"
          },
          {
            "num": "כו",
            "txt": "וָֽאֶשְׁלַ֤ח מַלְאָכִים֙ מִמִּדְבַּ֣ר קְדֵמ֔וֹת אֶל־סִיח֖וֹן מֶ֣לֶךְ חֶשְׁבּ֑וֹן דִּבְרֵ֥י שָׁל֖וֹם לֵאמֹֽר׃"
          },
          {
            "num": "כז",
            "txt": "אֶעְבְּרָ֣ה בְאַרְצֶ֔ךָ בַּדֶּ֥רֶךְ בַּדֶּ֖רֶךְ אֵלֵ֑ךְ לֹ֥א אָס֖וּר יָמִ֥ין וּשְׂמֹֽאול׃"
          },
          {
            "num": "כח",
            "txt": "אֹ֣כֶל בַּכֶּ֤סֶף תַּשְׁבִּרֵ֨נִי֙ וְאָכַ֔לְתִּי וּמַ֛יִם בַּכֶּ֥סֶף תִּתֶּן־לִ֖י וְשָׁתִ֑יתִי רַ֖ק אֶעְבְּרָ֥ה בְרַגְלָֽי׃"
          },
          {
            "num": "כט",
            "txt": "כַּֽאֲשֶׁ֨ר עָֽשׂוּ־לִ֜י בְּנֵ֣י עֵשָׂ֗ו הַיֹּֽשְׁבִים֙ בְּשֵׂעִ֔יר וְהַמּ֣וֹאָבִ֔ים הַיֹּֽשְׁבִ֖ים בְּעָ֑ר עַ֤ד אֲשֶֽׁר־אֶעֱבֹר֙ אֶת־הַיַּרְדֵּ֔ן אֶל־הָאָ֕רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹהֵ֖ינוּ נֹתֵ֥ן לָֽנוּ׃"
          },
          {
            "num": "ל",
            "txt": "וְלֹ֣א אָבָ֗ה סִיחֹן֙ מֶ֣לֶךְ חֶשְׁבּ֔וֹן הַֽעֲבִרֵ֖נוּ בּ֑וֹ כִּֽי־הִקְשָׁה֩ יְהוָ֨ה אֱלֹהֶ֜יךָ אֶת־רוּח֗וֹ וְאִמֵּץ֙ אֶת־לְבָב֔וֹ לְמַ֛עַן תִּתּ֥וֹ בְיָֽדְךָ֖ כַּיּ֥וֹם הַזֶּֽה׃ {ס}"
          },
          {
            "num": "לא",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֵלַ֔י רְאֵ֗ה הַֽחִלֹּ֨תִי֙ תֵּ֣ת לְפָנֶ֔יךָ אֶת־סִיחֹ֖ן וְאֶת־אַרְצ֑וֹ הָחֵ֣ל רָ֔שׁ לָרֶ֖שֶׁת אֶת־אַרְצֽוֹ׃"
          },
          {
            "num": "לב",
            "txt": "וַיֵּצֵא֩ סִיחֹ֨ן לִקְרָאתֵ֜נוּ ה֧וּא וְכָל־עַמּ֛וֹ לַמִּלְחָמָ֖ה יָֽהְצָה׃"
          },
          {
            "num": "לג",
            "txt": "וַֽיִּתְּנֵ֛הוּ יְהוָ֥ה אֱלֹהֵ֖ינוּ לְפָנֵ֑ינוּ וַנַּ֥ךְ אֹת֛וֹ וְאֶת־בָּנָ֖ו וְאֶת־כָּל־עַמּֽוֹ׃"
          },
          {
            "num": "לד",
            "txt": "וַנִּלְכֹּ֤ד אֶת־כָּל־עָרָיו֙ בָּעֵ֣ת הַהִ֔וא וַֽנַּחֲרֵם֙ אֶת־כָּל־עִ֣יר מְתִ֔ם וְהַנָּשִׁ֖ים וְהַטָּ֑ף לֹ֥א הִשְׁאַ֖רְנוּ שָׂרִֽיד׃"
          },
          {
            "num": "לה",
            "txt": "רַ֥ק הַבְּהֵמָ֖ה בָּזַ֣זְנוּ לָ֑נוּ וּשְׁלַ֥ל הֶֽעָרִ֖ים אֲשֶׁ֥ר לָכָֽדְנוּ׃"
          },
          {
            "num": "לו",
            "txt": "מֵֽעֲרֹעֵ֡ר אֲשֶׁר֩ עַל־שְׂפַת־נַ֨חַל אַרְנֹ֜ן וְהָעִ֨יר אֲשֶׁ֤ר בַּנַּ֨חַל֙ וְעַד־הַגִּלְעָ֔ד לֹ֤א הָֽיְתָה֙ קִרְיָ֔ה אֲשֶׁ֥ר שָֽׂגְבָ֖ה מִמֶּ֑נּוּ אֶת־הַכֹּ֕ל נָתַ֛ן יְהוָ֥ה אֱלֹהֵ֖ינוּ לְפָנֵֽינוּ׃"
          },
          {
            "num": "לז",
            "txt": "רַ֛ק אֶל־אֶ֥רֶץ בְּנֵֽי־עַמּ֖וֹן לֹ֣א קָרָ֑בְתָּ כָּל־יַ֞ד נַ֤חַל יַבֹּק֙ וְעָרֵ֣י הָהָ֔ר וְכֹ֥ל אֲשֶׁר־צִוָּ֖ה יְהוָ֥ה אֱלֹהֵֽינוּ׃"
          }
        ]
      },
      {
        "num": "ג",
        "verses": [
          {
            "num": "א",
            "txt": "וַנֵּ֣פֶן וַנַּ֔עַל דֶּ֖רֶךְ הַבָּשָׁ֑ן וַיֵּצֵ֣א עוֹג֩ מֶֽלֶךְ־הַבָּשָׁ֨ן לִקְרָאתֵ֜נוּ ה֧וּא וְכָל־עַמּ֛וֹ לַמִּלְחָמָ֖ה אֶדְרֶֽעִי׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֨אמֶר יְהוָ֤ה אֵלַי֙ אַל־תִּירָ֣א אֹת֔וֹ כִּ֣י בְיָֽדְךָ֞ נָתַ֧תִּי אֹת֛וֹ וְאֶת־כָּל־עַמּ֖וֹ וְאֶת־אַרְצ֑וֹ וְעָשִׂ֣יתָ לּ֔וֹ כַּֽאֲשֶׁ֣ר עָשִׂ֗יתָ לְסִיחֹן֙ מֶ֣לֶךְ הָֽאֱמֹרִ֔י אֲשֶׁ֥ר יוֹשֵׁ֖ב בְּחֶשְׁבּֽוֹן׃"
          },
          {
            "num": "ג",
            "txt": "וַיִּתֵּן֩ יְהוָ֨ה אֱלֹהֵ֜ינוּ בְּיָדֵ֗נוּ גַּ֛ם אֶת־ע֥וֹג מֶֽלֶךְ־הַבָּשָׁ֖ן וְאֶת־כָּל־עַמּ֑וֹ וַנַּכֵּ֕הוּ עַד־בִּלְתִּ֥י הִשְׁאִֽיר־ל֖וֹ שָׂרִֽיד׃"
          },
          {
            "num": "ד",
            "txt": "וַנִּלְכֹּ֤ד אֶת־כָּל־עָרָיו֙ בָּעֵ֣ת הַהִ֔וא לֹ֤א הָֽיְתָה֙ קִרְיָ֔ה אֲשֶׁ֥ר לֹֽא־לָקַ֖חְנוּ מֵֽאִתָּ֑ם שִׁשִּׁ֥ים עִיר֙ כָּל־חֶ֣בֶל אַרְגֹּ֔ב מַמְלֶ֥כֶת ע֖וֹג בַּבָּשָֽׁן׃"
          },
          {
            "num": "ה",
            "txt": "כָּל־אֵ֜לֶּה עָרִ֧ים בְּצֻרֹ֛ת חוֹמָ֥ה גְבֹהָ֖ה דְּלָתַ֣יִם וּבְרִ֑יחַ לְבַ֛ד מֵֽעָרֵ֥י הַפְּרָזִ֖י הַרְבֵּ֥ה מְאֹֽד׃"
          },
          {
            "num": "ו",
            "txt": "וַנַּֽחֲרֵ֣ם אוֹתָ֔ם כַּֽאֲשֶׁ֣ר עָשִׂ֔ינוּ לְסִיחֹ֖ן מֶ֣לֶךְ חֶשְׁבּ֑וֹן הַֽחֲרֵם֙ כָּל־עִ֣יר מְתִ֔ם הַנָּשִׁ֖ים וְהַטָּֽף׃"
          },
          {
            "num": "ז",
            "txt": "וְכָל־הַבְּהֵמָ֛ה וּשְׁלַ֥ל הֶֽעָרִ֖ים בַּזּ֥וֹנוּ לָֽנוּ׃"
          },
          {
            "num": "ח",
            "txt": "וַנִּקַּ֞ח בָּעֵ֤ת הַהִוא֙ אֶת־הָאָ֔רֶץ מִיַּ֗ד שְׁנֵי֙ מַלְכֵ֣י הָֽאֱמֹרִ֔י אֲשֶׁ֖ר בְּעֵ֣בֶר הַיַּרְדֵּ֑ן מִנַּ֥חַל אַרְנֹ֖ן עַד־הַ֥ר חֶרְמֽוֹן׃"
          },
          {
            "num": "ט",
            "txt": "צִֽידֹנִ֛ים יִקְרְא֥וּ לְחֶרְמ֖וֹן שִׂרְיֹ֑ן וְהָ֣אֱמֹרִ֔י יִקְרְאוּ־ל֖וֹ שְׂנִֽיר׃"
          },
          {
            "num": "י",
            "txt": "כֹּ֣ל ׀ עָרֵ֣י הַמִּישֹׁ֗ר וְכָל־הַגִּלְעָד֙ וְכָל־הַבָּשָׁ֔ן עַד־סַלְכָ֖ה וְאֶדְרֶ֑עִי עָרֵ֛י מַמְלֶ֥כֶת ע֖וֹג בַּבָּשָֽׁן׃"
          },
          {
            "num": "יא",
            "txt": "כִּ֣י רַק־ע֞וֹג מֶ֣לֶךְ הַבָּשָׁ֗ן נִשְׁאַר֮ מִיֶּ֣תֶר הָֽרְפָאִים֒ הִנֵּ֤ה עַרְשׂוֹ֙ עֶ֣רֶשׂ בַּרְזֶ֔ל הֲלֹ֣ה הִ֔וא בְּרַבַּ֖ת בְּנֵ֣י עַמּ֑וֹן תֵּ֧שַׁע אַמּ֣וֹת אָרְכָּ֗הּ וְאַרְבַּ֥ע אַמּ֛וֹת רָחְבָּ֖הּ בְּאַמַּת־אִֽישׁ׃"
          },
          {
            "num": "יב",
            "txt": "וְאֶת־הָאָ֧רֶץ הַזֹּ֛את יָרַ֖שְׁנוּ בָּעֵ֣ת הַהִ֑וא מֵֽעֲרֹעֵ֞ר אֲשֶׁר־עַל־נַ֣חַל אַרְנֹ֗ן וַֽחֲצִ֤י הַֽר־הַגִּלְעָד֙ וְעָרָ֔יו נָתַ֕תִּי לָרֽאוּבֵנִ֖י וְלַגָּדִֽי׃"
          },
          {
            "num": "יג",
            "txt": "וְיֶ֨תֶר הַגִּלְעָ֤ד וְכָל־הַבָּשָׁן֙ מַמְלֶ֣כֶת ע֔וֹג נָתַ֕תִּי לַֽחֲצִ֖י שֵׁ֣בֶט הַֽמְנַשֶּׁ֑ה כֹּ֣ל חֶ֤בֶל הָֽאַרְגֹּב֙ לְכָל־הַבָּשָׁ֔ן הַה֥וּא יִקָּרֵ֖א אֶ֥רֶץ רְפָאִֽים׃"
          },
          {
            "num": "יד",
            "txt": "יָאִ֣יר בֶּן־מְנַשֶּׁ֗ה לָקַח֙ אֶת־כָּל־חֶ֣בֶל אַרְגֹּ֔ב עַד־גְּב֥וּל הַגְּשׁוּרִ֖י וְהַמַּֽעֲכָתִ֑י וַיִּקְרָא֩ אֹתָ֨ם עַל־שְׁמ֤וֹ אֶת־הַבָּשָׁן֙ חַוֺּ֣ת יָאִ֔יר עַ֖ד הַיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "טו",
            "txt": "וּלְמָכִ֖יר נָתַ֥תִּי אֶת־הַגִּלְעָֽד׃"
          },
          {
            "num": "טז",
            "txt": "וְלָרֽאוּבֵנִ֨י וְלַגָּדִ֜י נָתַ֤תִּי מִן־הַגִּלְעָד֙ וְעַד־נַ֣חַל אַרְנֹ֔ן תּ֥וֹךְ הַנַּ֖חַל וּגְבֻ֑ל וְעַד֙ יַבֹּ֣ק הַנַּ֔חַל גְּב֖וּל בְּנֵ֥י עַמּֽוֹן׃"
          },
          {
            "num": "יז",
            "txt": "וְהָֽעֲרָבָ֖ה וְהַיַּרְדֵּ֣ן וּגְבֻ֑ל מִכִּנֶּ֗רֶת וְעַ֨ד יָ֤ם הָֽעֲרָבָה֙ יָ֣ם הַמֶּ֔לַח תַּ֛חַת אַשְׁדֹּ֥ת הַפִּסְגָּ֖ה מִזְרָֽחָה׃"
          },
          {
            "num": "יח",
            "txt": "וָֽאֲצַ֣ו אֶתְכֶ֔ם בָּעֵ֥ת הַהִ֖וא לֵאמֹ֑ר יְהוָ֣ה אֱלֹֽהֵיכֶ֗ם נָתַ֨ן לָכֶ֜ם אֶת־הָאָ֤רֶץ הַזֹּאת֙ לְרִשְׁתָּ֔הּ חֲלוּצִ֣ים תַּֽעַבְר֗וּ לִפְנֵ֛י אֲחֵיכֶ֥ם בְּנֵֽי־יִשְׂרָאֵ֖ל כָּל־בְּנֵי־חָֽיִל׃"
          },
          {
            "num": "יט",
            "txt": "רַ֠ק נְשֵׁיכֶ֣ם וְטַפְּכֶם֮ וּמִקְנֵכֶם֒ יָדַ֕עְתִּי כִּֽי־מִקְנֶ֥ה רַ֖ב לָכֶ֑ם יֵֽשְׁבוּ֙ בְּעָ֣רֵיכֶ֔ם אֲשֶׁ֥ר נָתַ֖תִּי לָכֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "עַ֠ד אֲשֶׁר־יָנִ֨יחַ יְהוָ֥ה ׀ לַֽאֲחֵיכֶם֮ כָּכֶם֒ וְיָֽרְשׁ֣וּ גַם־הֵ֔ם אֶת־הָאָ֕רֶץ אֲשֶׁ֨ר יְהוָ֧ה אֱלֹֽהֵיכֶ֛ם נֹתֵ֥ן לָהֶ֖ם בְּעֵ֣בֶר הַיַּרְדֵּ֑ן וְשַׁבְתֶּ֗ם אִ֚ישׁ לִֽירֻשָּׁת֔וֹ אֲשֶׁ֥ר נָתַ֖תִּי לָכֶֽם׃"
          },
          {
            "num": "כא",
            "txt": "וְאֶת־יְהוֹשׁ֣וּעַ צִוֵּ֔יתִי בָּעֵ֥ת הַהִ֖וא לֵאמֹ֑ר עֵינֶ֣יךָ הָֽרֹאֹ֗ת אֵת֩ כָּל־אֲשֶׁ֨ר עָשָׂ֜ה יְהוָ֤ה אֱלֹֽהֵיכֶם֙ לִשְׁנֵי֙ הַמְּלָכִ֣ים הָאֵ֔לֶּה כֵּֽן־יַעֲשֶׂ֤ה יְהוָה֙ לְכָל־הַמַּמְלָכ֔וֹת אֲשֶׁ֥ר אַתָּ֖ה עֹבֵ֥ר שָֽׁמָּה׃"
          },
          {
            "num": "כב",
            "txt": "לֹ֖א תִּֽירָא֑וּם כִּ֚י יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם ה֖וּא הַנִּלְחָ֥ם לָכֶֽם׃ {ס}"
          },
          {
            "num": "כג",
            "txt": "וָֽאֶתְחַנַּ֖ן אֶל־יְהוָ֑ה בָּעֵ֥ת הַהִ֖וא לֵאמֹֽר׃"
          },
          {
            "num": "כד",
            "txt": "אֲדֹנָ֣י יְהוִ֗ה אַתָּ֤ה הַֽחִלּ֨וֹתָ֙ לְהַרְא֣וֹת אֶֽת־עַבְדְּךָ֔ אֶ֨ת־גָּדְלְךָ֔ וְאֶת־יָֽדְךָ֖ הַֽחֲזָקָ֑ה אֲשֶׁ֤ר מִי־אֵל֙ בַּשָּׁמַ֣יִם וּבָאָ֔רֶץ אֲשֶׁר־יַֽעֲשֶׂ֥ה כְמַֽעֲשֶׂ֖יךָ וְכִגְבֽוּרֹתֶֽךָ׃"
          },
          {
            "num": "כה",
            "txt": "אֶעְבְּרָה־נָּ֗א וְאֶרְאֶה֙ אֶת־הָאָ֣רֶץ הַטּוֹבָ֔ה אֲשֶׁ֖ר בְּעֵ֣בֶר הַיַּרְדֵּ֑ן הָהָ֥ר הַטּ֛וֹב הַזֶּ֖ה וְהַלְּבָנֹֽן׃"
          },
          {
            "num": "כו",
            "txt": "וַיִּתְעַבֵּ֨ר יְהוָ֥ה בִּי֙ לְמַ֣עַנְכֶ֔ם וְלֹ֥א שָׁמַ֖ע אֵלָ֑י וַיֹּ֨אמֶר יְהוָ֤ה אֵלַי֙ רַב־לָ֔ךְ אַל־תּ֗וֹסֶף דַּבֵּ֥ר אֵלַ֛י ע֖וֹד בַּדָּבָ֥ר הַזֶּֽה׃"
          },
          {
            "num": "כז",
            "txt": "עֲלֵ֣ה ׀ רֹ֣אשׁ הַפִּסְגָּ֗ה וְשָׂ֥א עֵינֶ֛יךָ יָ֧מָּה וְצָפֹ֛נָה וְתֵימָ֥נָה וּמִזְרָ֖חָה וּרְאֵ֣ה בְעֵינֶ֑יךָ כִּי־לֹ֥א תַֽעֲבֹ֖ר אֶת־הַיַּרְדֵּ֥ן הַזֶּֽה׃"
          },
          {
            "num": "כח",
            "txt": "וְצַ֥ו אֶת־יְהוֹשֻׁ֖עַ וְחַזְּקֵ֣הוּ וְאַמְּצֵ֑הוּ כִּי־ה֣וּא יַֽעֲבֹ֗ר לִפְנֵי֙ הָעָ֣ם הַזֶּ֔ה וְהוּא֙ יַנְחִ֣יל אוֹתָ֔ם אֶת־הָאָ֖רֶץ אֲשֶׁ֥ר תִּרְאֶֽה׃"
          },
          {
            "num": "כט",
            "txt": "וַנֵּ֣שֶׁב בַּגָּ֔יְא מ֖וּל בֵּ֥ית פְּעֽוֹר׃ {פ}"
          }
        ]
      },
      {
        "num": "ד",
        "verses": [
          {
            "num": "א",
            "txt": "וְעַתָּ֣ה יִשְׂרָאֵ֗ל שְׁמַ֤ע אֶל־הַֽחֻקִּים֙ וְאֶל־הַמִּשְׁפָּטִ֔ים אֲשֶׁ֧ר אָֽנֹכִ֛י מְלַמֵּ֥ד אֶתְכֶ֖ם לַֽעֲשׂ֑וֹת לְמַ֣עַן תִּֽחְי֗וּ וּבָאתֶם֙ וִֽירִשְׁתֶּ֣ם אֶת־הָאָ֔רֶץ אֲשֶׁ֧ר יְהוָ֛ה אֱלֹהֵ֥י אֲבֹֽתֵיכֶ֖ם נֹתֵ֥ן לָכֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "לֹ֣א תֹסִ֗פוּ עַל־הַדָּבָר֙ אֲשֶׁ֤ר אָֽנֹכִי֙ מְצַוֶּ֣ה אֶתְכֶ֔ם וְלֹ֥א תִגְרְע֖וּ מִמֶּ֑נּוּ לִשְׁמֹ֗ר אֶת־מִצְוֺת֙ יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם אֲשֶׁ֥ר אָֽנֹכִ֖י מְצַוֶּ֥ה אֶתְכֶֽם׃"
          },
          {
            "num": "ג",
            "txt": "עֵֽינֵיכֶם֙ הָֽרֹא֔וֹת אֵ֛ת אֲשֶׁר־עָשָׂ֥ה יְהוָ֖ה בְּבַ֣עַל פְּע֑וֹר כִּ֣י כָל־הָאִ֗ישׁ אֲשֶׁ֤ר הָלַךְ֙ אַֽחֲרֵ֣י בַֽעַל־פְּע֔וֹר הִשְׁמִיד֛וֹ יְהוָ֥ה אֱלֹהֶ֖יךָ מִקִּרְבֶּֽךָ׃"
          },
          {
            "num": "ד",
            "txt": "וְאַתֶּם֙ הַדְּבֵקִ֔ים בַּֽיהוָ֖ה אֱלֹֽהֵיכֶ֑ם חַיִּ֥ים כֻּלְּכֶ֖ם הַיּֽוֹם׃"
          },
          {
            "num": "ה",
            "txt": "רְאֵ֣ה ׀ לִמַּ֣דְתִּי אֶתְכֶ֗ם חֻקִּים֙ וּמִשְׁפָּטִ֔ים כַּֽאֲשֶׁ֥ר צִוַּ֖נִי יְהוָ֣ה אֱלֹהָ֑י לַֽעֲשׂ֣וֹת כֵּ֔ן בְּקֶ֣רֶב הָאָ֔רֶץ אֲשֶׁ֥ר אַתֶּ֛ם בָּאִ֥ים שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "ו",
            "txt": "וּשְׁמַרְתֶּם֮ וַֽעֲשִׂיתֶם֒ כִּ֣י הִ֤וא חָכְמַתְכֶם֙ וּבִ֣ינַתְכֶ֔ם לְעֵינֵ֖י הָֽעַמִּ֑ים אֲשֶׁ֣ר יִשְׁמְע֗וּן אֵ֚ת כָּל־הַֽחֻקִּ֣ים הָאֵ֔לֶּה וְאָֽמְר֗וּ רַ֚ק עַם־חָכָ֣ם וְנָב֔וֹן הַגּ֥וֹי הַגָּד֖וֹל הַזֶּֽה׃"
          },
          {
            "num": "ז",
            "txt": "כִּ֚י מִי־ג֣וֹי גָּד֔וֹל אֲשֶׁר־ל֥וֹ אֱלֹהִ֖ים קְרֹבִ֣ים אֵלָ֑יו כַּֽיהוָ֣ה אֱלֹהֵ֔ינוּ בְּכָל־קָרְאֵ֖נוּ אֵלָֽיו׃"
          },
          {
            "num": "ח",
            "txt": "וּמִי֙ גּ֣וֹי גָּד֔וֹל אֲשֶׁר־ל֛וֹ חֻקִּ֥ים וּמִשְׁפָּטִ֖ים צַדִּיקִ֑ם כְּכֹל֙ הַתּוֹרָ֣ה הַזֹּ֔את אֲשֶׁ֧ר אָֽנֹכִ֛י נֹתֵ֥ן לִפְנֵיכֶ֖ם הַיּֽוֹם׃"
          },
          {
            "num": "ט",
            "txt": "רַ֡ק הִשָּׁ֣מֶר לְךָ֩ וּשְׁמֹ֨ר נַפְשְׁךָ֜ מְאֹ֗ד פֶּן־תִּשְׁכַּ֨ח אֶת־הַדְּבָרִ֜ים אֲשֶׁר־רָא֣וּ עֵינֶ֗יךָ וּפֶן־יָס֨וּרוּ֙ מִלְּבָ֣בְךָ֔ כֹּ֖ל יְמֵ֣י חַיֶּ֑יךָ וְהֽוֹדַעְתָּ֥ם לְבָנֶ֖יךָ וְלִבְנֵ֥י בָנֶֽיךָ׃"
          },
          {
            "num": "י",
            "txt": "י֗וֹם אֲשֶׁ֨ר עָמַ֜דְתָּ לִפְנֵ֨י יְהוָ֣ה אֱלֹהֶיךָ֮ בְּחֹרֵב֒ בֶּֽאֱמֹ֨ר יְהוָ֜ה אֵלַ֗י הַקְהֶל־לִי֙ אֶת־הָעָ֔ם וְאַשְׁמִעֵ֖ם אֶת־דְּבָרָ֑י אֲשֶׁ֨ר יִלְמְד֜וּן לְיִרְאָ֣ה אֹתִ֗י כָּל־הַיָּמִים֙ אֲשֶׁ֨ר הֵ֤ם חַיִּים֙ עַל־הָ֣אֲדָמָ֔ה וְאֶת־בְּנֵיהֶ֖ם יְלַמֵּדֽוּן׃"
          },
          {
            "num": "יא",
            "txt": "וַתִּקְרְב֥וּן וַתַּֽעַמְד֖וּן תַּ֣חַת הָהָ֑ר וְהָהָ֞ר בֹּעֵ֤ר בָּאֵשׁ֙ עַד־לֵ֣ב הַשָּׁמַ֔יִם חֹ֖שֶׁךְ עָנָ֥ן וַֽעֲרָפֶֽל׃"
          },
          {
            "num": "יב",
            "txt": "וַיְדַבֵּ֧ר יְהוָ֛ה אֲלֵיכֶ֖ם מִתּ֣וֹךְ הָאֵ֑שׁ ק֤וֹל דְּבָרִים֙ אַתֶּ֣ם שֹֽׁמְעִ֔ים וּתְמוּנָ֛ה אֵֽינְכֶ֥ם רֹאִ֖ים זֽוּלָתִ֥י קֽוֹל׃"
          },
          {
            "num": "יג",
            "txt": "וַיַּגֵּ֨ד לָכֶ֜ם אֶת־בְּרִית֗וֹ אֲשֶׁ֨ר צִוָּ֤ה אֶתְכֶם֙ לַֽעֲשׂ֔וֹת עֲשֶׂ֖רֶת הַדְּבָרִ֑ים וַֽיִּכְתְּבֵ֔ם עַל־שְׁנֵ֖י לֻח֥וֹת אֲבָנִֽים׃"
          },
          {
            "num": "יד",
            "txt": "וְאֹתִ֞י צִוָּ֤ה יְהוָה֙ בָּעֵ֣ת הַהִ֔וא לְלַמֵּ֣ד אֶתְכֶ֔ם חֻקִּ֖ים וּמִשְׁפָּטִ֑ים לַעֲשֹֽׂתְכֶ֣ם אֹתָ֔ם בָּאָ֕רֶץ אֲשֶׁ֥ר אַתֶּ֛ם עֹֽבְרִ֥ים שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "טו",
            "txt": "וְנִשְׁמַרְתֶּ֥ם מְאֹ֖ד לְנַפְשֹֽׁתֵיכֶ֑ם כִּ֣י לֹ֤א רְאִיתֶם֙ כָּל־תְּמוּנָ֔ה בְּי֗וֹם דִּבֶּ֨ר יְהוָ֧ה אֲלֵיכֶ֛ם בְּחֹרֵ֖ב מִתּ֥וֹךְ הָאֵֽשׁ׃"
          },
          {
            "num": "טז",
            "txt": "פֶּ֨ן־תַּשְׁחִת֔וּן וַֽעֲשִׂיתֶ֥ם לָכֶ֛ם פֶּ֖סֶל תְּמוּנַ֣ת כָּל־סָ֑מֶל תַּבְנִ֥ית זָכָ֖ר א֥וֹ נְקֵבָֽה׃"
          },
          {
            "num": "יז",
            "txt": "תַּבְנִ֕ית כָּל־בְּהֵמָ֖ה אֲשֶׁ֣ר בָּאָ֑רֶץ תַּבְנִית֙ כָּל־צִפּ֣וֹר כָּנָ֔ף אֲשֶׁ֥ר תָּע֖וּף בַּשָּׁמָֽיִם׃"
          },
          {
            "num": "יח",
            "txt": "תַּבְנִ֕ית כָּל־רֹמֵ֖שׂ בָּֽאֲדָמָ֑ה תַּבְנִ֛ית כָּל־דָּגָ֥ה אֲשֶׁר־בַּמַּ֖יִם מִתַּ֥חַת לָאָֽרֶץ׃"
          },
          {
            "num": "יט",
            "txt": "וּפֶן־תִּשָּׂ֨א עֵינֶ֜יךָ הַשָּׁמַ֗יְמָה וְֽ֠רָאִיתָ אֶת־הַשֶּׁ֨מֶשׁ וְאֶת־הַיָּרֵ֜חַ וְאֶת־הַכּֽוֹכָבִ֗ים כֹּ֚ל צְבָ֣א הַשָּׁמַ֔יִם וְנִדַּחְתָּ֛ וְהִשְׁתַּֽחֲוִ֥יתָ לָהֶ֖ם וַֽעֲבַדְתָּ֑ם אֲשֶׁ֨ר חָלַ֜ק יְהוָ֤ה אֱלֹהֶ֨יךָ֙ אֹתָ֔ם לְכֹל֙ הָֽעַמִּ֔ים תַּ֖חַת כָּל־הַשָּׁמָֽיִם׃"
          },
          {
            "num": "כ",
            "txt": "וְאֶתְכֶם֙ לָקַ֣ח יְהוָ֔ה וַיּוֹצִ֥א אֶתְכֶ֛ם מִכּ֥וּר הַבַּרְזֶ֖ל מִמִּצְרָ֑יִם לִֽהְי֥וֹת ל֛וֹ לְעַ֥ם נַֽחֲלָ֖ה כַּיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "כא",
            "txt": "וַֽיהוָ֥ה הִתְאַנַּף־בִּ֖י עַל־דִּבְרֵיכֶ֑ם וַיִּשָּׁבַ֗ע לְבִלְתִּ֤י עָבְרִי֙ אֶת־הַיַּרְדֵּ֔ן וּלְבִלְתִּי־בֹא֙ אֶל־הָאָ֣רֶץ הַטּוֹבָ֔ה אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ נַֽחֲלָֽה׃"
          },
          {
            "num": "כב",
            "txt": "כִּ֣י אָֽנֹכִ֥י מֵת֙ בָּאָ֣רֶץ הַזֹּ֔את אֵינֶ֥נִּי עֹבֵ֖ר אֶת־הַיַּרְדֵּ֑ן וְאַתֶּם֙ עֹֽבְרִ֔ים וִֽירִשְׁתֶּ֕ם אֶת־הָאָ֥רֶץ הַטּוֹבָ֖ה הַזֹּֽאת׃"
          },
          {
            "num": "כג",
            "txt": "הִשָּֽׁמְר֣וּ לָכֶ֗ם פֶּֽן־תִּשְׁכְּחוּ֙ אֶת־בְּרִ֤ית יְהוָה֙ אֱלֹ֣הֵיכֶ֔ם אֲשֶׁ֥ר כָּרַ֖ת עִמָּכֶ֑ם וַֽעֲשִׂיתֶ֨ם לָכֶ֥ם פֶּ֨סֶל֙ תְּמ֣וּנַת כֹּ֔ל אֲשֶׁ֥ר צִוְּךָ֖ יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "כד",
            "txt": "כִּ֚י יְהוָ֣ה אֱלֹהֶ֔יךָ אֵ֥שׁ אֹֽכְלָ֖ה ה֑וּא אֵ֖ל קַנָּֽא׃ {פ}"
          },
          {
            "num": "כה",
            "txt": "כִּֽי־תוֹלִ֤יד בָּנִים֙ וּבְנֵ֣י בָנִ֔ים וְנֽוֹשַׁנְתֶּ֖ם בָּאָ֑רֶץ וְהִשְׁחַתֶּ֗ם וַֽעֲשִׂ֤יתֶם פֶּ֨סֶל֙ תְּמ֣וּנַת כֹּ֔ל וַֽעֲשִׂיתֶ֥ם הָרַ֛ע בְּעֵינֵ֥י יְהוָֽה־אֱלֹהֶ֖יךָ לְהַכְעִיסֽוֹ׃"
          },
          {
            "num": "כו",
            "txt": "הַֽעִידֹתִי֩ בָכֶ֨ם הַיּ֜וֹם אֶת־הַשָּׁמַ֣יִם וְאֶת־הָאָ֗רֶץ כִּֽי־אָבֹ֣ד תֹּֽאבֵדוּן֮ מַהֵר֒ מֵעַ֣ל הָאָ֔רֶץ אֲשֶׁ֨ר אַתֶּ֜ם עֹֽבְרִ֧ים אֶת־הַיַּרְדֵּ֛ן שָׁ֖מָּה לְרִשְׁתָּ֑הּ לֹֽא־תַאֲרִיכֻ֤ן יָמִים֙ עָלֶ֔יהָ כִּ֥י הִשָּׁמֵ֖ד תִּשָּֽׁמֵדֽוּן׃"
          },
          {
            "num": "כז",
            "txt": "וְהֵפִ֧יץ יְהוָ֛ה אֶתְכֶ֖ם בָּֽעַמִּ֑ים וְנִשְׁאַרְתֶּם֙ מְתֵ֣י מִסְפָּ֔ר בַּגּוֹיִ֕ם אֲשֶׁ֨ר יְנַהֵ֧ג יְהוָ֛ה אֶתְכֶ֖ם שָֽׁמָּה׃"
          },
          {
            "num": "כח",
            "txt": "וַֽעֲבַדְתֶּם־שָׁ֣ם אֱלֹהִ֔ים מַֽעֲשֵׂ֖ה יְדֵ֣י אָדָ֑ם עֵ֣ץ וָאֶ֔בֶן אֲשֶׁ֤ר לֹֽא־יִרְאוּן֙ וְלֹ֣א יִשְׁמְע֔וּן וְלֹ֥א יֹֽאכְל֖וּן וְלֹ֥א יְרִיחֻֽן׃"
          },
          {
            "num": "כט",
            "txt": "וּבִקַּשְׁתֶּ֥ם מִשָּׁ֛ם אֶת־יְהוָ֥ה אֱלֹהֶ֖יךָ וּמָצָ֑אתָ כִּ֣י תִדְרְשֶׁ֔נּוּ בְּכָל־לְבָֽבְךָ֖ וּבְכָל־נַפְשֶֽׁךָ׃"
          },
          {
            "num": "ל",
            "txt": "בַּצַּ֣ר לְךָ֔ וּמְצָא֕וּךָ כֹּ֖ל הַדְּבָרִ֣ים הָאֵ֑לֶּה בְּאַֽחֲרִית֙ הַיָּמִ֔ים וְשַׁבְתָּ֙ עַד־יְהוָ֣ה אֱלֹהֶ֔יךָ וְשָֽׁמַעְתָּ֖ בְּקֹלֽוֹ׃"
          },
          {
            "num": "לא",
            "txt": "כִּ֣י אֵ֤ל רַחוּם֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לֹ֥א יַרְפְּךָ֖ וְלֹ֣א יַשְׁחִיתֶ֑ךָ וְלֹ֤א יִשְׁכַּח֙ אֶת־בְּרִ֣ית אֲבֹתֶ֔יךָ אֲשֶׁ֥ר נִשְׁבַּ֖ע לָהֶֽם׃"
          },
          {
            "num": "לב",
            "txt": "כִּ֣י שְׁאַל־נָא֩ לְיָמִ֨ים רִֽאשֹׁנִ֜ים אֲשֶׁר־הָי֣וּ לְפָנֶ֗יךָ לְמִן־הַיּוֹם֙ אֲשֶׁר֩ בָּרָ֨א אֱלֹהִ֤ים ׀ אָדָם֙ עַל־הָאָ֔רֶץ וּלְמִקְצֵ֥ה הַשָּׁמַ֖יִם וְעַד־קְצֵ֣ה הַשָּׁמָ֑יִם הֲנִֽהְיָ֗ה כַּדָּבָ֤ר הַגָּדוֹל֙ הַזֶּ֔ה א֖וֹ הֲנִשְׁמַ֥ע כָּמֹֽהוּ׃"
          },
          {
            "num": "לג",
            "txt": "הֲשָׁ֣מַֽע עָם֩ ק֨וֹל אֱלֹהִ֜ים מְדַבֵּ֧ר מִתּוֹךְ־הָאֵ֛שׁ כַּֽאֲשֶׁר־שָׁמַ֥עְתָּ אַתָּ֖ה וַיֶּֽחִי׃"
          },
          {
            "num": "לד",
            "txt": "א֣וֹ ׀ הֲנִסָּ֣ה אֱלֹהִ֗ים לָ֠בוֹא לָקַ֨חַת ל֣וֹ גוֹי֮ מִקֶּ֣רֶב גּוֹי֒ בְּמַסֹּת֩ בְּאֹתֹ֨ת וּבְמֽוֹפְתִ֜ים וּבְמִלְחָמָ֗ה וּבְיָ֤ד חֲזָקָה֙ וּבִזְר֣וֹעַ נְטוּיָ֔ה וּבְמֽוֹרָאִ֖ים גְּדֹלִ֑ים כְּ֠כֹל אֲשֶׁר־עָשָׂ֨ה לָכֶ֜ם יְהוָ֧ה אֱלֹֽהֵיכֶ֛ם בְּמִצְרַ֖יִם לְעֵינֶֽיךָ׃"
          },
          {
            "num": "לה",
            "txt": "אַתָּה֙ הָרְאֵ֣תָ לָדַ֔עַת כִּ֥י יְהוָ֖ה ה֣וּא הָֽאֱלֹהִ֑ים אֵ֥ין ע֖וֹד מִלְּבַדּֽוֹ׃"
          },
          {
            "num": "לו",
            "txt": "מִן־הַשָּׁמַ֛יִם הִשְׁמִֽיעֲךָ֥ אֶת־קֹל֖וֹ לְיַסְּרֶ֑ךָּ וְעַל־הָאָ֗רֶץ הֶרְאֲךָ֙ אֶת־אִשּׁ֣וֹ הַגְּדוֹלָ֔ה וּדְבָרָ֥יו שָׁמַ֖עְתָּ מִתּ֥וֹךְ הָאֵֽשׁ׃"
          },
          {
            "num": "לז",
            "txt": "וְתַ֗חַת כִּ֤י אָהַב֙ אֶת־אֲבֹתֶ֔יךָ וַיִּבְחַ֥ר בְּזַרְע֖וֹ אַֽחֲרָ֑יו וַיּוֹצִֽאֲךָ֧ בְּפָנָ֛יו בְּכֹח֥וֹ הַגָּדֹ֖ל מִמִּצְרָֽיִם׃"
          },
          {
            "num": "לח",
            "txt": "לְהוֹרִ֗ישׁ גּוֹיִ֛ם גְּדֹלִ֧ים וַֽעֲצֻמִ֛ים מִמְּךָ֖ מִפָּנֶ֑יךָ לַהֲבִֽיאֲךָ֗ לָֽתֶת־לְךָ֧ אֶת־אַרְצָ֛ם נַֽחֲלָ֖ה כַּיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "לט",
            "txt": "וְיָֽדַעְתָּ֣ הַיּ֗וֹם וַהֲשֵֽׁבֹתָ֮ אֶל־לְבָבֶךָ֒ כִּ֤י יְהוָה֙ ה֣וּא הָֽאֱלֹהִ֔ים בַּשָּׁמַ֣יִם מִמַּ֔עַל וְעַל־הָאָ֖רֶץ מִתָּ֑חַת אֵ֖ין עֽוֹד׃"
          },
          {
            "num": "מ",
            "txt": "וְשָֽׁמַרְתָּ֞ אֶת־חֻקָּ֣יו וְאֶת־מִצְוֺתָ֗יו אֲשֶׁ֨ר אָֽנֹכִ֤י מְצַוְּךָ֙ הַיּ֔וֹם אֲשֶׁר֙ יִיטַ֣ב לְךָ֔ וּלְבָנֶ֖יךָ אַֽחֲרֶ֑יךָ וּלְמַ֨עַן תַּֽאֲרִ֤יךְ יָמִים֙ עַל־הָ֣אֲדָמָ֔ה אֲשֶׁ֨ר יְהוָ֧ה אֱלֹהֶ֛יךָ נֹתֵ֥ן לְךָ֖ כָּל־הַיָּמִֽים׃ {פ}"
          },
          {
            "num": "מא",
            "txt": "אָ֣ז יַבְדִּ֤יל מֹשֶׁה֙ שָׁלֹ֣שׁ עָרִ֔ים בְּעֵ֖בֶר הַיַּרְדֵּ֑ן מִזְרְחָ֖ה שָֽׁמֶשׁ׃"
          },
          {
            "num": "מב",
            "txt": "לָנֻ֨ס שָׁ֜מָּה רוֹצֵ֗חַ אֲשֶׁ֨ר יִרְצַ֤ח אֶת־רֵעֵ֨הוּ֙ בִּבְלִי־דַ֔עַת וְה֛וּא לֹֽא־שֹׂנֵ֥א ל֖וֹ מִתְּמֹ֣ל שִׁלְשֹׁ֑ם וְנָ֗ס אֶל־אַחַ֛ת מִן־הֶֽעָרִ֥ים הָאֵ֖ל וָחָֽי׃"
          },
          {
            "num": "מג",
            "txt": "אֶת־בֶּ֧צֶר בַּמִּדְבָּ֛ר בְּאֶ֥רֶץ הַמִּישֹׁ֖ר לָרֽאוּבֵנִ֑י וְאֶת־רָאמֹ֤ת בַּגִּלְעָד֙ לַגָּדִ֔י וְאֶת־גּוֹלָ֥ן בַּבָּשָׁ֖ן לַֽמְנַשִּֽׁי׃"
          },
          {
            "num": "מד",
            "txt": "וְזֹ֖את הַתּוֹרָ֑ה אֲשֶׁר־שָׂ֣ם מֹשֶׁ֔ה לִפְנֵ֖י בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "מה",
            "txt": "אֵ֚לֶּה הָֽעֵדֹ֔ת וְהַֽחֻקִּ֖ים וְהַמִּשְׁפָּטִ֑ים אֲשֶׁ֨ר דִּבֶּ֤ר מֹשֶׁה֙ אֶל־בְּנֵ֣י יִשְׂרָאֵ֔ל בְּצֵאתָ֖ם מִמִּצְרָֽיִם׃"
          },
          {
            "num": "מו",
            "txt": "בְּעֵ֨בֶר הַיַּרְדֵּ֜ן בַּגַּ֗יְא מ֚וּל בֵּ֣ית פְּע֔וֹר בְּאֶ֗רֶץ סִיחֹן֙ מֶ֣לֶךְ הָֽאֱמֹרִ֔י אֲשֶׁ֥ר יוֹשֵׁ֖ב בְּחֶשְׁבּ֑וֹן אֲשֶׁ֨ר הִכָּ֤ה מֹשֶׁה֙ וּבְנֵ֣י יִשְׂרָאֵ֔ל בְּצֵאתָ֖ם מִמִּצְרָֽיִם׃"
          },
          {
            "num": "מז",
            "txt": "וַיִּֽירְשׁ֨וּ אֶת־אַרְצ֜וֹ וְאֶת־אֶ֣רֶץ ׀ ע֣וֹג מֶֽלֶךְ־הַבָּשָׁ֗ן שְׁנֵי֙ מַלְכֵ֣י הָֽאֱמֹרִ֔י אֲשֶׁ֖ר בְּעֵ֣בֶר הַיַּרְדֵּ֑ן מִזְרַ֖ח שָֽׁמֶשׁ׃"
          },
          {
            "num": "מח",
            "txt": "מֵֽעֲרֹעֵ֞ר אֲשֶׁ֨ר עַל־שְׂפַת־נַ֧חַל אַרְנֹ֛ן וְעַד־הַ֥ר שִׂיאֹ֖ן ה֥וּא חֶרְמֽוֹן׃"
          },
          {
            "num": "מט",
            "txt": "וְכָל־הָ֨עֲרָבָ֜ה עֵ֤בֶר הַיַּרְדֵּן֙ מִזְרָ֔חָה וְעַ֖ד יָ֣ם הָֽעֲרָבָ֑ה תַּ֖חַת אַשְׁדֹּ֥ת הַפִּסְגָּֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "ה",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּקְרָ֣א מֹשֶׁה֮ אֶל־כָּל־יִשְׂרָאֵל֒ וַיֹּ֣אמֶר אֲלֵהֶ֗ם שְׁמַ֤ע יִשְׂרָאֵל֙ אֶת־הַֽחֻקִּ֣ים וְאֶת־הַמִּשְׁפָּטִ֔ים אֲשֶׁ֧ר אָֽנֹכִ֛י דֹּבֵ֥ר בְּאָזְנֵיכֶ֖ם הַיּ֑וֹם וּלְמַדְתֶּ֣ם אֹתָ֔ם וּשְׁמַרְתֶּ֖ם לַֽעֲשֹׂתָֽם׃"
          },
          {
            "num": "ב",
            "txt": "יְהוָ֣ה אֱלֹהֵ֗ינוּ כָּרַ֥ת עִמָּ֛נוּ בְּרִ֖ית בְּחֹרֵֽב׃"
          },
          {
            "num": "ג",
            "txt": "לֹ֣א אֶת־אֲבֹתֵ֔ינוּ כָּרַ֥ת יְהוָ֖ה אֶת־הַבְּרִ֣ית הַזֹּ֑את כִּ֣י אִתָּ֔נוּ אֲנַ֨חְנוּ אֵ֥לֶּה פֹ֛ה הַיּ֖וֹם כֻּלָּ֥נוּ חַיִּֽים׃"
          },
          {
            "num": "ד",
            "txt": "פָּנִ֣ים ׀ בְּפָנִ֗ים דִּבֶּ֨ר יְהוָ֧ה עִמָּכֶ֛ם בָּהָ֖ר מִתּ֥וֹךְ הָאֵֽשׁ׃"
          },
          {
            "num": "ה",
            "txt": "אָֽ֠נֹכִי עֹמֵ֨ד בֵּין־יְהוָ֤ה וּבֵֽינֵיכֶם֙ בָּעֵ֣ת הַהִ֔וא לְהַגִּ֥יד לָכֶ֖ם אֶת־דְּבַ֣ר יְהוָ֑ה כִּ֤י יְרֵאתֶם֙ מִפְּנֵ֣י הָאֵ֔שׁ וְלֹֽא־עֲלִיתֶ֥ם בָּהָ֖ר לֵאמֹֽר׃ {ס}"
          },
          {
            "num": "ו",
            "txt": "אָֽנֹכִי֙ יְהוָ֣ה אֱלֹהֶ֔יךָ אֲשֶׁ֧ר הֽוֹצֵאתִ֛יךָ מֵאֶ֥רֶץ מִצְרַ֖יִם מִבֵּ֣ית עֲבָדִ֑ים לֹֽא־יִהְיֶ֥ה לְךָ֛ אֱלֹהִ֥ים אֲחֵרִ֖ים עַל־פָּנָֽי׃"
          },
          {
            "num": "ז",
            "txt": "לֹֽא־תַעֲשֶׂ֨ה לְךָ֥ פֶ֨סֶל֙ כָּל־תְּמוּנָ֔ה אֲשֶׁ֤ר בַּשָּׁמַ֨יִם֙ מִמַּ֔עַל וַֽאֲשֶׁ֥ר בָּאָ֖רֶץ מִתָּ֑חַת וַֽאֲשֶׁ֥ר בַּמַּ֖יִם מִתַּ֥חַת לָאָֽרֶץ׃"
          },
          {
            "num": "ח",
            "txt": "לֹֽא־תִשְׁתַּחֲוֶ֥ה לָהֶ֖ם וְלֹ֣א תָֽעָבְדֵ֑ם כִּ֣י אָֽנֹכִ֞י יְהוָ֤ה אֱלֹהֶ֨יךָ֙ אֵ֣ל קַנָּ֔א פֹּ֠קֵד עֲוֺ֨ן אָב֧וֹת עַל־בָּנִ֛ים וְעַל־שִׁלֵּשִׁ֥ים וְעַל־רִבֵּעִ֖ים לְשֹֽׂנְאָֽי׃"
          },
          {
            "num": "ט",
            "txt": "וְעֹ֥שֶׂה חֶ֖סֶד לַֽאֲלָפִ֑ים לְאֹֽהֲבַ֖י וּלְשֹֽׁמְרֵ֥י מצותו (מִצְוֺתָֽי)׃ {ס}"
          },
          {
            "num": "י",
            "txt": "לֹ֥א תִשָּׂ֛א אֶת־שֵֽׁם־יְהוָ֥ה אֱלֹהֶ֖יךָ לַשָּׁ֑וְא כִּ֣י לֹ֤א יְנַקֶּה֙ יְהוָ֔ה אֵ֛ת אֲשֶׁר־יִשָּׂ֥א אֶת־שְׁמ֖וֹ לַשָּֽׁוְא׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "שָׁמ֛וֹר אֶת־י֥וֹם הַשַּׁבָּ֖ת לְקַדְּשׁ֑וֹ כַּֽאֲשֶׁ֥ר צִוְּךָ֖ יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "יב",
            "txt": "שֵׁ֤שֶׁת יָמִים֙ תַּֽעֲבֹ֔ד וְעָשִׂ֖יתָ כָּל־מְלַאכְתֶּֽךָ׃"
          },
          {
            "num": "יג",
            "txt": "וְיוֹם֙ הַשְּׁבִיעִ֔י שַׁבָּ֖ת לַֽיהוָ֣ה אֱלֹהֶ֑יךָ לֹ֣א תַֽעֲשֶׂ֣ה כָל־מְלָאכָ֡ה אַתָּ֣ה וּבִנְךָֽ־וּבִתֶּ֣ךָ וְעַבְדְּךָֽ־וַ֠אֲמָתֶךָ וְשֽׁוֹרְךָ֨ וַחֲמֹֽרְךָ֜ וְכָל־בְּהֶמְתֶּ֗ךָ וְגֵֽרְךָ֙ אֲשֶׁ֣ר בִּשְׁעָרֶ֔יךָ לְמַ֗עַן יָנ֛וּחַ עַבְדְּךָ֥ וַאֲמָֽתְךָ֖ כָּמֽוֹךָ׃"
          },
          {
            "num": "יד",
            "txt": "וְזָֽכַרְתָּ֗ כִּ֣י עֶ֤בֶד הָיִ֨יתָ֙ בְּאֶ֣רֶץ מִצְרַ֔יִם וַיֹּצִ֨אֲךָ֜ יְהוָ֤ה אֱלֹהֶ֨יךָ֙ מִשָּׁ֔ם בְּיָ֥ד חֲזָקָ֖ה וּבִזְרֹ֣עַ נְטוּיָ֑ה עַל־כֵּ֗ן צִוְּךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לַֽעֲשׂ֖וֹת אֶת־י֥וֹם הַשַּׁבָּֽת׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "כַּבֵּ֤ד אֶת־אָבִ֨יךָ֙ וְאֶת־אִמֶּ֔ךָ כַּֽאֲשֶׁ֥ר צִוְּךָ֖ יְהוָ֣ה אֱלֹהֶ֑יךָ לְמַ֣עַן ׀ יַֽאֲרִיכֻ֣ן יָמֶ֗יךָ וּלְמַ֨עַן֙ יִ֣יטַב לָ֔ךְ עַ֚ל הָֽאֲדָמָ֔ה אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֥ן לָֽךְ׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "לֹ֥א תִרְצָ֖ח {ס} וְלֹ֣א תִנְאָ֑ף {ס} וְלֹ֣א תִגְנֹ֔ב {ס} וְלֹֽא־תַעֲנֶ֥ה בְרֵֽעֲךָ֖ עֵ֥ד שָֽׁוְא׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "וְלֹ֥א תַחְמֹ֖ד אֵ֣שֶׁת רֵעֶ֑ךָ {ס} וְלֹ֨א תִתְאַוֶּ֜ה בֵּ֣ית רֵעֶ֗ךָ שָׂדֵ֜הוּ וְעַבְדּ֤וֹ וַֽאֲמָתוֹ֙ שׁוֹר֣וֹ וַֽחֲמֹר֔וֹ וְכֹ֖ל אֲשֶׁ֥ר לְרֵעֶֽךָ׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "אֶֽת־הַדְּבָרִ֣ים הָאֵ֡לֶּה דִּבֶּר֩ יְהוָ֨ה אֶל־כָּל־קְהַלְכֶ֜ם בָּהָ֗ר מִתּ֤וֹךְ הָאֵשׁ֙ הֶֽעָנָ֣ן וְהָֽעֲרָפֶ֔ל ק֥וֹל גָּד֖וֹל וְלֹ֣א יָסָ֑ף וַֽיִּכְתְּבֵ֗ם עַל־שְׁנֵי֙ לֻחֹ֣ת אֲבָנִ֔ים וַֽיִּתְּנֵ֖ם אֵלָֽי׃"
          },
          {
            "num": "יט",
            "txt": "וַיְהִ֗י כְּשָׁמְעֲכֶ֤ם אֶת־הַקּוֹל֙ מִתּ֣וֹךְ הַחֹ֔שֶׁךְ וְהָהָ֖ר בֹּעֵ֣ר בָּאֵ֑שׁ וַתִּקְרְב֣וּן אֵלַ֔י כָּל־רָאשֵׁ֥י שִׁבְטֵיכֶ֖ם וְזִקְנֵיכֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "וַתֹּֽאמְר֗וּ הֵ֣ן הֶרְאָ֜נוּ יְהוָ֤ה אֱלֹהֵ֨ינוּ֙ אֶת־כְּבֹד֣וֹ וְאֶת־גָּדְל֔וֹ וְאֶת־קֹל֥וֹ שָׁמַ֖עְנוּ מִתּ֣וֹךְ הָאֵ֑שׁ הַיּ֤וֹם הַזֶּה֙ רָאִ֔ינוּ כִּֽי־יְדַבֵּ֧ר אֱלֹהִ֛ים אֶת־הָֽאָדָ֖ם וָחָֽי׃"
          },
          {
            "num": "כא",
            "txt": "וְעַתָּה֙ לָ֣מָּה נָמ֔וּת כִּ֣י תֹֽאכְלֵ֔נוּ הָאֵ֥שׁ הַגְּדֹלָ֖ה הַזֹּ֑את אִם־יֹֽסְפִ֣ים ׀ אֲנַ֗חְנוּ לִ֠שְׁמֹעַ אֶת־ק֨וֹל יְהוָ֧ה אֱלֹהֵ֛ינוּ ע֖וֹד וָמָֽתְנוּ׃"
          },
          {
            "num": "כב",
            "txt": "כִּ֣י מִ֣י כָל־בָּשָׂ֡ר אֲשֶׁ֣ר שָׁמַ֣ע קוֹל֩ אֱלֹהִ֨ים חַיִּ֜ים מְדַבֵּ֧ר מִתּוֹךְ־הָאֵ֛שׁ כָּמֹ֖נוּ וַיֶּֽחִי׃"
          },
          {
            "num": "כג",
            "txt": "קְרַ֤ב אַתָּה֙ וּֽשְׁמָ֔ע אֵ֛ת כָּל־אֲשֶׁ֥ר יֹאמַ֖ר יְהוָ֣ה אֱלֹהֵ֑ינוּ וְאַ֣תְּ ׀ תְּדַבֵּ֣ר אֵלֵ֗ינוּ אֵת֩ כָּל־אֲשֶׁ֨ר יְדַבֵּ֜ר יְהוָ֧ה אֱלֹהֵ֛ינוּ אֵלֶ֖יךָ וְשָׁמַ֥עְנוּ וְעָשִֽׂינוּ׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּשְׁמַ֤ע יְהוָה֙ אֶת־ק֣וֹל דִּבְרֵיכֶ֔ם בְּדַבֶּרְכֶ֖ם אֵלָ֑י וַיֹּ֨אמֶר יְהוָ֜ה אֵלַ֗י שָׁ֠מַעְתִּי אֶת־ק֨וֹל דִּבְרֵ֜י הָעָ֤ם הַזֶּה֙ אֲשֶׁ֣ר דִּבְּר֣וּ אֵלֶ֔יךָ הֵיטִ֖יבוּ כָּל־אֲשֶׁ֥ר דִּבֵּֽרוּ׃"
          },
          {
            "num": "כה",
            "txt": "מִֽי־יִתֵּ֡ן וְהָיָה֩ לְבָבָ֨ם זֶ֜ה לָהֶ֗ם לְיִרְאָ֥ה אֹתִ֛י וְלִשְׁמֹ֥ר אֶת־כָּל־מִצְוֺתַ֖י כָּל־הַיָּמִ֑ים לְמַ֨עַן יִיטַ֥ב לָהֶ֛ם וְלִבְנֵיהֶ֖ם לְעֹלָֽם׃"
          },
          {
            "num": "כו",
            "txt": "לֵ֖ךְ אֱמֹ֣ר לָהֶ֑ם שׁ֥וּבוּ לָכֶ֖ם לְאָֽהֳלֵיכֶֽם׃"
          },
          {
            "num": "כז",
            "txt": "וְאַתָּ֗ה פֹּה֮ עֲמֹ֣ד עִמָּדִי֒ וַֽאֲדַבְּרָ֣ה אֵלֶ֗יךָ אֵ֧ת כָּל־הַמִּצְוָ֛ה וְהַֽחֻקִּ֥ים וְהַמִּשְׁפָּטִ֖ים אֲשֶׁ֣ר תְּלַמְּדֵ֑ם וְעָשׂ֣וּ בָאָ֔רֶץ אֲשֶׁ֧ר אָֽנֹכִ֛י נֹתֵ֥ן לָהֶ֖ם לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "כח",
            "txt": "וּשְׁמַרְתֶּ֣ם לַֽעֲשׂ֔וֹת כַּֽאֲשֶׁ֥ר צִוָּ֛ה יְהוָ֥ה אֱלֹֽהֵיכֶ֖ם אֶתְכֶ֑ם לֹ֥א תָסֻ֖רוּ יָמִ֥ין וּשְׂמֹֽאל׃"
          },
          {
            "num": "כט",
            "txt": "בְּכָל־הַדֶּ֗רֶךְ אֲשֶׁ֨ר צִוָּ֜ה יְהוָ֧ה אֱלֹֽהֵיכֶ֛ם אֶתְכֶ֖ם תֵּלֵ֑כוּ לְמַ֤עַן תִּֽחְיוּן֙ וְט֣וֹב לָכֶ֔ם וְהַֽאֲרַכְתֶּ֣ם יָמִ֔ים בָּאָ֖רֶץ אֲשֶׁ֥ר תִּֽירָשֽׁוּן׃"
          }
        ]
      },
      {
        "num": "ו",
        "verses": [
          {
            "num": "א",
            "txt": "וְזֹ֣את הַמִּצְוָ֗ה הַֽחֻקִּים֙ וְהַמִּשְׁפָּטִ֔ים אֲשֶׁ֥ר צִוָּ֛ה יְהוָ֥ה אֱלֹֽהֵיכֶ֖ם לְלַמֵּ֣ד אֶתְכֶ֑ם לַֽעֲשׂ֣וֹת בָּאָ֔רֶץ אֲשֶׁ֥ר אַתֶּ֛ם עֹֽבְרִ֥ים שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "ב",
            "txt": "לְמַ֨עַן תִּירָ֜א אֶת־יְהוָ֣ה אֱלֹהֶ֗יךָ לִ֠שְׁמֹר אֶת־כָּל־חֻקֹּתָ֣יו וּמִצְוֺתָיו֮ אֲשֶׁ֣ר אָֽנֹכִ֣י מְצַוֶּךָ֒ אַתָּה֙ וּבִנְךָ֣ וּבֶן־בִּנְךָ֔ כֹּ֖ל יְמֵ֣י חַיֶּ֑יךָ וּלְמַ֖עַן יַֽאֲרִכֻ֥ן יָמֶֽיךָ׃"
          },
          {
            "num": "ג",
            "txt": "וְשָֽׁמַעְתָּ֤ יִשְׂרָאֵל֙ וְשָֽׁמַרְתָּ֣ לַֽעֲשׂ֔וֹת אֲשֶׁר֙ יִיטַ֣ב לְךָ֔ וַֽאֲשֶׁ֥ר תִּרְבּ֖וּן מְאֹ֑ד כַּֽאֲשֶׁר֩ דִּבֶּ֨ר יְהוָ֜ה אֱלֹהֵ֤י אֲבֹתֶ֨יךָ֙ לָ֔ךְ אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָֽשׁ׃ {פ}"
          },
          {
            "num": "ד",
            "txt": "שְׁמַ֖"
          },
          {
            "num": "ה",
            "txt": "יִשְׂרָאֵ֑ל יְהוָ֥ה אֱלֹהֵ֖ינוּ יְהוָ֥ה ׀ אֶחָֽ"
          },
          {
            "num": "ו",
            "txt": "׃"
          },
          {
            "num": "ז",
            "txt": "וְאָ֣הַבְתָּ֔ אֵ֖ת יְהוָ֣ה אֱלֹהֶ֑יךָ בְּכָל־לְבָֽבְךָ֥ וּבְכָל־נַפְשְׁךָ֖ וּבְכָל־מְאֹדֶֽךָ׃"
          },
          {
            "num": "ח",
            "txt": "וְהָי֞וּ הַדְּבָרִ֣ים הָאֵ֗לֶּה אֲשֶׁ֨ר אָֽנֹכִ֧י מְצַוְּךָ֛ הַיּ֖וֹם עַל־לְבָבֶֽךָ׃"
          },
          {
            "num": "ט",
            "txt": "וְשִׁנַּנְתָּ֣ם לְבָנֶ֔יךָ וְדִבַּרְתָּ֖ בָּ֑ם בְּשִׁבְתְּךָ֤ בְּבֵיתֶ֨ךָ֙ וּבְלֶכְתְּךָ֣ בַדֶּ֔רֶךְ וּֽבְשָׁכְבְּךָ֖ וּבְקוּמֶֽךָ׃"
          },
          {
            "num": "י",
            "txt": "וּקְשַׁרְתָּ֥ם לְא֖וֹת עַל־יָדֶ֑ךָ וְהָי֥וּ לְטֹֽטָפֹ֖ת בֵּ֥ין עֵינֶֽיךָ׃"
          },
          {
            "num": "יא",
            "txt": "וּכְתַבְתָּ֛ם עַל־מְזֻז֥וֹת בֵּיתֶ֖ךָ וּבִשְׁעָרֶֽיךָ׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "וְהָיָ֞ה כִּ֥י יְבִֽיאֲךָ֣ ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ אֶל־הָאָ֜רֶץ אֲשֶׁ֨ר נִשְׁבַּ֧ע לַֽאֲבֹתֶ֛יךָ לְאַבְרָהָ֛ם לְיִצְחָ֥ק וּֽלְיַעֲקֹ֖ב לָ֣תֶת לָ֑ךְ עָרִ֛ים גְּדֹלֹ֥ת וְטֹבֹ֖ת אֲשֶׁ֥ר לֹֽא־בָנִֽיתָ׃"
          },
          {
            "num": "יג",
            "txt": "וּבָ֨תִּ֜ים מְלֵאִ֣ים כָּל־טוּב֮ אֲשֶׁ֣ר לֹֽא־מִלֵּאתָ֒ וּבֹרֹ֤ת חֲצוּבִים֙ אֲשֶׁ֣ר לֹֽא־חָצַ֔בְתָּ כְּרָמִ֥ים וְזֵיתִ֖ים אֲשֶׁ֣ר לֹֽא־נָטָ֑עְתָּ וְאָֽכַלְתָּ֖ וְשָׂבָֽעְתָּ׃"
          },
          {
            "num": "יד",
            "txt": "הִשָּׁ֣מֶר לְךָ֔ פֶּן־תִּשְׁכַּ֖ח אֶת־יְהוָ֑ה אֲשֶׁ֧ר הוֹצִֽיאֲךָ֛ מֵאֶ֥רֶץ מִצְרַ֖יִם מִבֵּ֥ית עֲבָדִֽים׃"
          },
          {
            "num": "טו",
            "txt": "אֶת־יְהוָ֧ה אֱלֹהֶ֛יךָ תִּירָ֖א וְאֹת֣וֹ תַֽעֲבֹ֑ד וּבִשְׁמ֖וֹ תִּשָּׁבֵֽעַ׃"
          },
          {
            "num": "טז",
            "txt": "לֹ֣א תֵֽלְכ֔וּן אַֽחֲרֵ֖י אֱלֹהִ֣ים אֲחֵרִ֑ים מֵֽאֱלֹהֵי֙ הָֽעַמִּ֔ים אֲשֶׁ֖ר סְבִיבֽוֹתֵיכֶֽם׃"
          },
          {
            "num": "יז",
            "txt": "כִּ֣י אֵ֥ל קַנָּ֛א יְהוָ֥ה אֱלֹהֶ֖יךָ בְּקִרְבֶּ֑ךָ פֶּן־יֶֽ֠חֱרֶה אַף־יְהוָ֤ה אֱלֹהֶ֨יךָ֙ בָּ֔ךְ וְהִשְׁמִ֣ידְךָ֔ מֵעַ֖ל פְּנֵ֥י הָֽאֲדָמָֽה׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "לֹ֣א תְנַסּ֔וּ אֶת־יְהוָ֖ה אֱלֹֽהֵיכֶ֑ם כַּֽאֲשֶׁ֥ר נִסִּיתֶ֖ם בַּמַּסָּֽה׃"
          },
          {
            "num": "יט",
            "txt": "שָׁמ֣וֹר תִּשְׁמְר֔וּן אֶת־מִצְוֺ֖ת יְהוָ֣ה אֱלֹֽהֵיכֶ֑ם וְעֵֽדֹתָ֥יו וְחֻקָּ֖יו אֲשֶׁ֥ר צִוָּֽךְ׃"
          },
          {
            "num": "כ",
            "txt": "וְעָשִׂ֛יתָ הַיָּשָׁ֥ר וְהַטּ֖וֹב בְּעֵינֵ֣י יְהוָ֑ה לְמַ֨עַן֙ יִ֣יטַב לָ֔ךְ וּבָ֗אתָ וְיָֽרַשְׁתָּ֙ אֶת־הָאָ֣רֶץ הַטֹּבָ֔ה אֲשֶׁר־נִשְׁבַּ֥ע יְהוָ֖ה לַֽאֲבֹתֶֽיךָ׃"
          },
          {
            "num": "כא",
            "txt": "לַֽהֲדֹ֥ף אֶת־כָּל־אֹֽיְבֶ֖יךָ מִפָּנֶ֑יךָ כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר יְהוָֽה׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "כִּֽי־יִשְׁאָלְךָ֥ בִנְךָ֛ מָחָ֖ר לֵאמֹ֑ר מָ֣ה הָֽעֵדֹ֗ת וְהַֽחֻקִּים֙ וְהַמִּשְׁפָּטִ֔ים אֲשֶׁ֥ר צִוָּ֛ה יְהוָ֥ה אֱלֹהֵ֖ינוּ אֶתְכֶֽם׃"
          },
          {
            "num": "כג",
            "txt": "וְאָֽמַרְתָּ֣ לְבִנְךָ֔ עֲבָדִ֛ים הָיִ֥ינוּ לְפַרְעֹ֖ה בְּמִצְרָ֑יִם וַיֹּֽצִיאֵ֧נוּ יְהוָ֛ה מִמִּצְרַ֖יִם בְּיָ֥ד חֲזָקָֽה׃"
          },
          {
            "num": "כד",
            "txt": "וַיִּתֵּ֣ן יְהוָ֡ה אוֹתֹ֣ת וּ֠מֹֽפְתִים גְּדֹלִ֨ים וְרָעִ֧ים ׀ בְּמִצְרַ֛יִם בְּפַרְעֹ֥ה וּבְכָל־בֵּית֖וֹ לְעֵינֵֽינוּ׃"
          },
          {
            "num": "כה",
            "txt": "וְאוֹתָ֖נוּ הוֹצִ֣יא מִשָּׁ֑ם לְמַ֨עַן֙ הָבִ֣יא אֹתָ֔נוּ לָ֤תֶת לָ֨נוּ֙ אֶת־הָאָ֔רֶץ אֲשֶׁ֥ר נִשְׁבַּ֖ע לַֽאֲבֹתֵֽינוּ׃"
          },
          {
            "txt": "וַיְצַוֵּ֣נוּ יְהוָ֗ה לַֽעֲשׂוֹת֙ אֶת־כָּל־הַֽחֻקִּ֣ים הָאֵ֔לֶּה לְיִרְאָ֖ה אֶת־יְהוָ֣ה אֱלֹהֵ֑ינוּ לְט֥וֹב לָ֨נוּ֙ כָּל־הַיָּמִ֔ים לְחַיֹּתֵ֖נוּ כְּהַיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "txt": "וּצְדָקָ֖ה תִּֽהְיֶה־לָּ֑נוּ כִּֽי־נִשְׁמֹ֨ר לַֽעֲשׂ֜וֹת אֶת־כָּל־הַמִּצְוָ֣ה הַזֹּ֗את לִפְנֵ֛י יְהוָ֥ה אֱלֹהֵ֖ינוּ כַּֽאֲשֶׁ֥ר צִוָּֽנוּ׃ {ס}"
          }
        ]
      },
      {
        "num": "ז",
        "verses": [
          {
            "num": "א",
            "txt": "כִּ֤י יְבִֽיאֲךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ אֶל־הָאָ֕רֶץ אֲשֶׁר־אַתָּ֥ה בָא־שָׁ֖מָּה לְרִשְׁתָּ֑הּ וְנָשַׁ֣ל גּֽוֹיִם־רַבִּ֣ים ׀ מִפָּנֶ֡יךָ הַֽחִתִּי֩ וְהַגִּרְגָּשִׁ֨י וְהָֽאֱמֹרִ֜י וְהַכְּנַֽעֲנִ֣י וְהַפְּרִזִּ֗י וְהַֽחִוִּי֙ וְהַיְבוּסִ֔י שִׁבְעָ֣ה גוֹיִ֔ם רַבִּ֥ים וַֽעֲצוּמִ֖ים מִמֶּֽךָּ׃"
          },
          {
            "num": "ב",
            "txt": "וּנְתָנָ֞ם יְהוָ֧ה אֱלֹהֶ֛יךָ לְפָנֶ֖יךָ וְהִכִּיתָ֑ם הַֽחֲרֵ֤ם תַּֽחֲרִים֙ אֹתָ֔ם לֹֽא־תִכְרֹ֥ת לָהֶ֛ם בְּרִ֖ית וְלֹ֥א תְחָנֵּֽם׃"
          },
          {
            "num": "ג",
            "txt": "וְלֹ֥א תִתְחַתֵּ֖ן בָּ֑ם בִּתְּךָ֙ לֹֽא־תִתֵּ֣ן לִבְנ֔וֹ וּבִתּ֖וֹ לֹֽא־תִקַּ֥ח לִבְנֶֽךָ׃"
          },
          {
            "num": "ד",
            "txt": "כִּֽי־יָסִ֤יר אֶת־בִּנְךָ֙ מֵֽאַחֲרַ֔י וְעָֽבְד֖וּ אֱלֹהִ֣ים אֲחֵרִ֑ים וְחָרָ֤ה אַף־יְהוָה֙ בָּכֶ֔ם וְהִשְׁמִֽידְךָ֖ מַהֵֽר׃"
          },
          {
            "num": "ה",
            "txt": "כִּֽי־אִם־כֹּ֤ה תַֽעֲשׂוּ֙ לָהֶ֔ם מִזְבְּחֹֽתֵיהֶ֣ם תִּתֹּ֔צוּ וּמַצֵּֽבֹתָ֖ם תְּשַׁבֵּ֑רוּ וַאֲשֵֽׁירֵהֶם֙ תְּגַדֵּע֔וּן וּפְסִֽילֵיהֶ֖ם תִּשְׂרְפ֥וּן בָּאֵֽשׁ׃"
          },
          {
            "num": "ו",
            "txt": "כִּ֣י עַ֤ם קָדוֹשׁ֙ אַתָּ֔ה לַֽיהוָ֖ה אֱלֹהֶ֑יךָ בְּךָ֞ בָּחַ֣ר ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ לִֽהְי֥וֹת לוֹ֙ לְעַ֣ם סְגֻלָּ֔ה מִכֹּל֙ הָֽעַמִּ֔ים אֲשֶׁ֖ר עַל־פְּנֵ֥י הָֽאֲדָמָֽה׃"
          },
          {
            "num": "ז",
            "txt": "לֹ֣א מֵֽרֻבְּכֶ֞ם מִכָּל־הָֽעַמִּ֗ים חָשַׁ֧ק יְהוָ֛ה בָּכֶ֖ם וַיִּבְחַ֣ר בָּכֶ֑ם כִּֽי־אַתֶּ֥ם הַמְעַ֖ט מִכָּל־הָֽעַמִּֽים׃"
          },
          {
            "num": "ח",
            "txt": "כִּי֩ מֵֽאַהֲבַ֨ת יְהוָ֜ה אֶתְכֶ֗ם וּמִשָּׁמְר֤וֹ אֶת־הַשְּׁבֻעָה֙ אֲשֶׁ֤ר נִשְׁבַּע֙ לַאֲבֹ֣תֵיכֶ֔ם הוֹצִ֧יא יְהוָ֛ה אֶתְכֶ֖ם בְּיָ֣ד חֲזָקָ֑ה וַֽיִּפְדְּךָ֙ מִבֵּ֣ית עֲבָדִ֔ים מִיַּ֖ד פַּרְעֹ֥ה מֶֽלֶךְ־מִצְרָֽיִם׃"
          },
          {
            "num": "ט",
            "txt": "וְיָ֣דַעְתָּ֔ כִּֽי־יְהוָ֥ה אֱלֹהֶ֖יךָ ה֣וּא הָֽאֱלֹהִ֑ים הָאֵל֙ הַֽנֶּאֱמָ֔ן שֹׁמֵ֧ר הַבְּרִ֣ית וְהַחֶ֗סֶד לְאֹֽהֲבָ֛יו וּלְשֹֽׁמְרֵ֥י מִצְוֺתָ֖ו לְאֶ֥לֶף דּֽוֹר׃"
          },
          {
            "num": "י",
            "txt": "וּמְשַׁלֵּ֧ם לְשֹֽׂנְאָ֛יו אֶל־פָּנָ֖יו לְהַֽאֲבִיד֑וֹ לֹ֤א יְאַחֵר֙ לְשֹׂ֣נְא֔וֹ אֶל־פָּנָ֖יו יְשַׁלֶּם־לֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וְשָֽׁמַרְתָּ֨ אֶת־הַמִּצְוָ֜ה וְאֶת־הַֽחֻקִּ֣ים וְאֶת־הַמִּשְׁפָּטִ֗ים אֲשֶׁ֨ר אָֽנֹכִ֧י מְצַוְּךָ֛ הַיּ֖וֹם לַֽעֲשׂוֹתָֽם׃ {פ}"
          },
          {
            "num": "יב",
            "txt": "וְהָיָ֣ה ׀ עֵ֣קֶב תִּשְׁמְע֗וּן אֵ֤ת הַמִּשְׁפָּטִים֙ הָאֵ֔לֶּה וּשְׁמַרְתֶּ֥ם וַֽעֲשִׂיתֶ֖ם אֹתָ֑ם וְשָׁמַר֩ יְהוָ֨ה אֱלֹהֶ֜יךָ לְךָ֗ אֶֽת־הַבְּרִית֙ וְאֶת־הַחֶ֔סֶד אֲשֶׁ֥ר נִשְׁבַּ֖ע לַֽאֲבֹתֶֽיךָ׃"
          },
          {
            "num": "יג",
            "txt": "וַאֲהֵ֣בְךָ֔ וּבֵֽרַכְךָ֖ וְהִרְבֶּ֑ךָ וּבֵרַ֣ךְ פְּרִֽי־בִטְנְךָ֣ וּפְרִֽי־אַ֠דְמָתֶךָ דְּגָ֨נְךָ֜ וְתִֽירֹשְׁךָ֣ וְיִצְהָרֶ֗ךָ שְׁגַר־אֲלָפֶ֨יךָ֙ וְעַשְׁתְּרֹ֣ת צֹאנֶ֔ךָ עַ֚ל הָֽאֲדָמָ֔ה אֲשֶׁר־נִשְׁבַּ֥ע לַֽאֲבֹתֶ֖יךָ לָ֥תֶת לָֽךְ׃"
          },
          {
            "num": "יד",
            "txt": "בָּר֥וּךְ תִּֽהְיֶ֖ה מִכָּל־הָֽעַמִּ֑ים לֹֽא־יִהְיֶ֥ה בְךָ֛ עָקָ֥ר וַֽעֲקָרָ֖ה וּבִבְהֶמְתֶּֽךָ׃"
          },
          {
            "num": "טו",
            "txt": "וְהֵסִ֧יר יְהוָ֛ה מִמְּךָ֖ כָּל־חֹ֑לִי וְכָל־מַדְוֵי֩ מִצְרַ֨יִם הָֽרָעִ֜ים אֲשֶׁ֣ר יָדַ֗עְתָּ לֹ֤א יְשִׂימָם֙ בָּ֔ךְ וּנְתָנָ֖ם בְּכָל־שֹֽׂנְאֶֽיךָ׃"
          },
          {
            "num": "טז",
            "txt": "וְאָֽכַלְתָּ֣ אֶת־כָּל־הָֽעַמִּ֗ים אֲשֶׁ֨ר יְהוָ֤ה אֱלֹהֶ֨יךָ֙ נֹתֵ֣ן לָ֔ךְ לֹֽא־תָח֥וֹס עֵֽינְךָ֖ עֲלֵיהֶ֑ם וְלֹ֤א תַֽעֲבֹד֙ אֶת־אֱלֹ֣הֵיהֶ֔ם כִּֽי־מוֹקֵ֥שׁ ה֖וּא לָֽךְ׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "כִּ֤י תֹאמַר֙ בִּלְבָ֣בְךָ֔ רַבִּ֛ים הַגּוֹיִ֥ם הָאֵ֖לֶּה מִמֶּ֑נִּי אֵיכָ֥ה אוּכַ֖ל לְהֽוֹרִישָֽׁם׃"
          },
          {
            "num": "יח",
            "txt": "לֹ֥א תִירָ֖א מֵהֶ֑ם זָכֹ֣ר תִּזְכֹּ֗ר אֵ֤ת אֲשֶׁר־עָשָׂה֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לְפַרְעֹ֖ה וּלְכָל־מִצְרָֽיִם׃"
          },
          {
            "num": "יט",
            "txt": "הַמַּסֹּ֨ת הַגְּדֹלֹ֜ת אֲשֶׁר־רָא֣וּ עֵינֶ֗יךָ וְהָֽאֹתֹ֤ת וְהַמֹּֽפְתִים֙ וְהַיָּ֤ד הַֽחֲזָקָה֙ וְהַזְּרֹ֣עַ הַנְּטוּיָ֔ה אֲשֶׁ֥ר הוֹצִֽאֲךָ֖ יְהוָ֣ה אֱלֹהֶ֑יךָ כֵּֽן־יַעֲשֶׂ֞ה יְהוָ֤ה אֱלֹהֶ֨יךָ֙ לְכָל־הָ֣עַמִּ֔ים אֲשֶׁר־אַתָּ֥ה יָרֵ֖א מִפְּנֵיהֶֽם׃"
          },
          {
            "num": "כ",
            "txt": "וְגַם֙ אֶת־הַצִּרְעָ֔ה יְשַׁלַּ֛ח יְהוָ֥ה אֱלֹהֶ֖יךָ בָּ֑ם עַד־אֲבֹ֗ד הַנִּשְׁאָרִ֛ים וְהַנִּסְתָּרִ֖ים מִפָּנֶֽיךָ׃"
          },
          {
            "num": "כא",
            "txt": "לֹ֥א תַֽעֲרֹ֖ץ מִפְּנֵיהֶ֑ם כִּֽי־יְהוָ֤ה אֱלֹהֶ֨יךָ֙ בְּקִרְבֶּ֔ךָ אֵ֥ל גָּד֖וֹל וְנוֹרָֽא׃"
          },
          {
            "num": "כב",
            "txt": "וְנָשַׁל֩ יְהוָ֨ה אֱלֹהֶ֜יךָ אֶת־הַגּוֹיִ֥ם הָאֵ֛ל מִפָּנֶ֖יךָ מְעַ֣ט מְעָ֑ט לֹ֤א תוּכַל֙ כַּלֹּתָ֣ם מַהֵ֔ר פֶּן־תִּרְבֶּ֥ה עָלֶ֖יךָ חַיַּ֥ת הַשָּׂדֶֽה׃"
          },
          {
            "num": "כג",
            "txt": "וּנְתָנָ֛ם יְהוָ֥ה אֱלֹהֶ֖יךָ לְפָנֶ֑יךָ וְהָמָם֙ מְהוּמָ֣ה גְדֹלָ֔ה עַ֖ד הִשָּֽׁמְדָֽם׃"
          },
          {
            "num": "כד",
            "txt": "וְנָתַ֤ן מַלְכֵיהֶם֙ בְּיָדֶ֔ךָ וְהַֽאֲבַדְתָּ֣ אֶת־שְׁמָ֔ם מִתַּ֖חַת הַשָּׁמָ֑יִם לֹֽא־יִתְיַצֵּ֥ב אִישׁ֙ בְּפָנֶ֔יךָ עַ֥ד הִשְׁמִֽדְךָ֖ אֹתָֽם׃"
          },
          {
            "num": "כה",
            "txt": "פְּסִילֵ֥י אֱלֹֽהֵיהֶ֖ם תִּשְׂרְפ֣וּן בָּאֵ֑שׁ לֹֽא־תַחְמֹד֩ כֶּ֨סֶף וְזָהָ֤ב עֲלֵיהֶם֙ וְלָֽקַחְתָּ֣ לָ֔ךְ פֶּ֚ן תִּוָּקֵ֣שׁ בּ֔וֹ כִּ֧י תֽוֹעֲבַ֛ת יְהוָ֥ה אֱלֹהֶ֖יךָ הֽוּא׃"
          },
          {
            "num": "כו",
            "txt": "וְלֹֽא־תָבִ֤יא תֽוֹעֵבָה֙ אֶל־בֵּיתֶ֔ךָ וְהָיִ֥יתָ חֵ֖רֶם כָּמֹ֑הוּ שַׁקֵּ֧ץ ׀ תְּשַׁקְּצֶ֛נּוּ וְתַעֵ֥ב ׀ תְּֽתַעֲבֶ֖נּוּ כִּי־חֵ֥רֶם הֽוּא׃ {פ}"
          }
        ]
      },
      {
        "num": "ח",
        "verses": [
          {
            "num": "א",
            "txt": "כָּל־הַמִּצְוָ֗ה אֲשֶׁ֨ר אָֽנֹכִ֧י מְצַוְּךָ֛ הַיּ֖וֹם תִּשְׁמְר֣וּן לַֽעֲשׂ֑וֹת לְמַ֨עַן תִּֽחְי֜וּן וּרְבִיתֶ֗ם וּבָאתֶם֙ וִֽירִשְׁתֶּ֣ם אֶת־הָאָ֔רֶץ אֲשֶׁר־נִשְׁבַּ֥ע יְהוָ֖ה לַאֲבֹֽתֵיכֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "וְזָֽכַרְתָּ֣ אֶת־כָּל־הַדֶּ֗רֶךְ אֲשֶׁ֨ר הוֹלִֽיכְךָ֜ יְהוָ֧ה אֱלֹהֶ֛יךָ זֶ֛ה אַרְבָּעִ֥ים שָׁנָ֖ה בַּמִּדְבָּ֑ר לְמַ֨עַן עַנֹּֽתְךָ֜ לְנַסֹּֽתְךָ֗ לָדַ֜עַת אֶת־אֲשֶׁ֧ר בִּֽלְבָבְךָ֛ הֲתִשְׁמֹ֥ר מִצְוֺתָ֖ו אִם־לֹֽא׃"
          },
          {
            "num": "ג",
            "txt": "וַֽיְעַנְּךָ֮ וַיַּרְעִבֶךָ֒ וַיַּאֲכִֽלְךָ֤ אֶת־הַמָּן֙ אֲשֶׁ֣ר לֹֽא־יָדַ֔עְתָּ וְלֹ֥א יָֽדְע֖וּן אֲבֹתֶ֑יךָ לְמַ֣עַן הוֹדִֽיעֲךָ֗ כִּ֠י לֹ֣א עַל־הַלֶּ֤חֶם לְבַדּוֹ֙ יִֽחְיֶ֣ה הָֽאָדָ֔ם כִּ֛י עַל־כָּל־מוֹצָ֥א פִֽי־יְהוָ֖ה יִֽחְיֶ֥ה הָֽאָדָֽם׃"
          },
          {
            "num": "ד",
            "txt": "שִׂמְלָ֨תְךָ֜ לֹ֤א בָֽלְתָה֙ מֵֽעָלֶ֔יךָ וְרַגְלְךָ֖ לֹ֣א בָצֵ֑קָה זֶ֖ה אַרְבָּעִ֥ים שָׁנָֽה׃"
          },
          {
            "num": "ה",
            "txt": "וְיָֽדַעְתָּ֖ עִם־לְבָבֶ֑ךָ כִּ֗י כַּֽאֲשֶׁ֨ר יְיַסֵּ֥ר אִישׁ֙ אֶת־בְּנ֔וֹ יְהוָ֥ה אֱלֹהֶ֖יךָ מְיַסְּרֶֽךָּ׃"
          },
          {
            "num": "ו",
            "txt": "וְשָׁ֣מַרְתָּ֔ אֶת־מִצְוֺ֖ת יְהוָ֣ה אֱלֹהֶ֑יךָ לָלֶ֥כֶת בִּדְרָכָ֖יו וּלְיִרְאָ֥ה אֹתֽוֹ׃"
          },
          {
            "num": "ז",
            "txt": "כִּ֚י יְהוָ֣ה אֱלֹהֶ֔יךָ מְבִֽיאֲךָ֖ אֶל־אֶ֣רֶץ טוֹבָ֑ה אֶ֚רֶץ נַ֣חֲלֵי מָ֔יִם עֲיָנֹת֙ וּתְהֹמֹ֔ת יֹֽצְאִ֥ים בַּבִּקְעָ֖ה וּבָהָֽר׃"
          },
          {
            "num": "ח",
            "txt": "אֶ֤רֶץ חִטָּה֙ וּשְׂעֹרָ֔ה וְגֶ֥פֶן וּתְאֵנָ֖ה וְרִמּ֑וֹן אֶֽרֶץ־זֵ֥ית שֶׁ֖מֶן וּדְבָֽשׁ׃"
          },
          {
            "num": "ט",
            "txt": "אֶ֗רֶץ אֲשֶׁ֨ר לֹ֤א בְמִסְכֵּנֻת֙ תֹּֽאכַל־בָּ֣הּ לֶ֔חֶם לֹֽא־תֶחְסַ֥ר כֹּ֖ל בָּ֑הּ אֶ֚רֶץ אֲשֶׁ֣ר אֲבָנֶ֣יהָ בַרְזֶ֔ל וּמֵֽהֲרָרֶ֖יהָ תַּחְצֹ֥ב נְחֹֽשֶׁת׃"
          },
          {
            "num": "י",
            "txt": "וְאָֽכַלְתָּ֖ וְשָׂבָ֑עְתָּ וּבֵֽרַכְתָּ֙ אֶת־יְהוָ֣ה אֱלֹהֶ֔יךָ עַל־הָאָ֥רֶץ הַטֹּבָ֖ה אֲשֶׁ֥ר נָֽתַן־לָֽךְ׃"
          },
          {
            "num": "יא",
            "txt": "הִשָּׁ֣מֶר לְךָ֔ פֶּן־תִּשְׁכַּ֖ח אֶת־יְהוָ֣ה אֱלֹהֶ֑יךָ לְבִלְתִּ֨י שְׁמֹ֤ר מִצְוֺתָיו֙ וּמִשְׁפָּטָ֣יו וְחֻקֹּתָ֔יו אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּֽוֹם׃"
          },
          {
            "num": "יב",
            "txt": "פֶּן־תֹּאכַ֖ל וְשָׂבָ֑עְתָּ וּבָתִּ֥ים טֹבִ֛ים תִּבְנֶ֖ה וְיָשָֽׁבְתָּ׃"
          },
          {
            "num": "יג",
            "txt": "וּבְקָֽרְךָ֤ וְצֹֽאנְךָ֙ יִרְבְּיֻ֔ן וְכֶ֥סֶף וְזָהָ֖ב יִרְבֶּה־לָּ֑ךְ וְכֹ֥ל אֲשֶׁר־לְךָ֖ יִרְבֶּֽה׃"
          },
          {
            "num": "יד",
            "txt": "וְרָ֖ם לְבָבֶ֑ךָ וְשָֽׁכַחְתָּ֙ אֶת־יְהוָ֣ה אֱלֹהֶ֔יךָ הַמּוֹצִֽיאֲךָ֛ מֵאֶ֥רֶץ מִצְרַ֖יִם מִבֵּ֥ית עֲבָדִֽים׃"
          },
          {
            "num": "טו",
            "txt": "הַמּוֹלִ֨יכְךָ֜ בַּמִּדְבָּ֣ר ׀ הַגָּדֹ֣ל וְהַנּוֹרָ֗א נָחָ֤שׁ ׀ שָׂרָף֙ וְעַקְרָ֔ב וְצִמָּא֖וֹן אֲשֶׁ֣ר אֵֽין־מָ֑יִם הַמּוֹצִ֤יא לְךָ֙ מַ֔יִם מִצּ֖וּר הַֽחַלָּמִֽישׁ׃"
          },
          {
            "num": "טז",
            "txt": "הַמַּאֲכִ֨לְךָ֥ מָן֙ בַּמִּדְבָּ֔ר אֲשֶׁ֥ר לֹֽא־יָדְע֖וּן אֲבֹתֶ֑יךָ לְמַ֣עַן עַנֹּֽתְךָ֗ וּלְמַ֨עַן֙ נַסֹּתֶ֔ךָ לְהֵיטִֽבְךָ֖ בְּאַֽחֲרִיתֶֽךָ׃"
          },
          {
            "num": "יז",
            "txt": "וְאָֽמַרְתָּ֖ בִּלְבָבֶ֑ךָ כֹּחִי֙ וְעֹ֣צֶם יָדִ֔י עָ֥שָׂה לִ֖י אֶת־הַחַ֥יִל הַזֶּֽה׃"
          },
          {
            "num": "יח",
            "txt": "וְזָֽכַרְתָּ֙ אֶת־יְהוָ֣ה אֱלֹהֶ֔יךָ כִּ֣י ה֗וּא הַנֹּתֵ֥ן לְךָ֛ כֹּ֖חַ לַֽעֲשׂ֣וֹת חָ֑יִל לְמַ֨עַן הָקִ֧ים אֶת־בְּרִית֛וֹ אֲשֶׁר־נִשְׁבַּ֥ע לַֽאֲבֹתֶ֖יךָ כַּיּ֥וֹם הַזֶּֽה׃ {פ}"
          },
          {
            "num": "יט",
            "txt": "וְהָיָ֗ה אִם־שָׁכֹ֤חַ תִּשְׁכַּח֙ אֶת־יְהוָ֣ה אֱלֹהֶ֔יךָ וְהָֽלַכְתָּ֗ אַֽחֲרֵי֙ אֱלֹהִ֣ים אֲחֵרִ֔ים וַֽעֲבַדְתָּ֖ם וְהִשְׁתַּֽחֲוִ֣יתָ לָהֶ֑ם הַֽעִדֹ֤תִי בָכֶם֙ הַיּ֔וֹם כִּ֥י אָבֹ֖ד תֹּֽאבֵדֽוּן׃"
          },
          {
            "num": "כ",
            "txt": "כַּגּוֹיִ֗ם אֲשֶׁ֤ר יְהוָה֙ מַֽאֲבִ֣יד מִפְּנֵיכֶ֔ם כֵּ֖ן תֹּֽאבֵד֑וּן עֵ֚קֶב לֹ֣א תִשְׁמְע֔וּן בְּק֖וֹל יְהוָ֥ה אֱלֹֽהֵיכֶֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "ט",
        "verses": [
          {
            "num": "א",
            "txt": "שְׁמַ֣ע יִשְׂרָאֵ֗ל אַתָּ֨ה עֹבֵ֤ר הַיּוֹם֙ אֶת־הַיַּרְדֵּ֔ן לָבֹא֙ לָרֶ֣שֶׁת גּוֹיִ֔ם גְּדֹלִ֥ים וַֽעֲצֻמִ֖ים מִמֶּ֑ךָּ עָרִ֛ים גְּדֹלֹ֥ת וּבְצֻרֹ֖ת בַּשָּׁמָֽיִם׃"
          },
          {
            "num": "ב",
            "txt": "עַֽם־גָּד֥וֹל וָרָ֖ם בְּנֵ֣י עֲנָקִ֑ים אֲשֶׁ֨ר אַתָּ֤ה יָדַ֨עְתָּ֙ וְאַתָּ֣ה שָׁמַ֔עְתָּ מִ֣י יִתְיַצֵּ֔ב לִפְנֵ֖י בְּנֵ֥י עֲנָֽק׃"
          },
          {
            "num": "ג",
            "txt": "וְיָֽדַעְתָּ֣ הַיּ֗וֹם כִּי֩ יְהוָ֨ה אֱלֹהֶ֜יךָ הֽוּא־הָעֹבֵ֤ר לְפָנֶ֨יךָ֙ אֵ֣שׁ אֹֽכְלָ֔ה ה֧וּא יַשְׁמִידֵ֛ם וְה֥וּא יַכְנִיעֵ֖ם לְפָנֶ֑יךָ וְהֽוֹרַשְׁתָּ֤ם וְהַֽאֲבַדְתָּם֙ מַהֵ֔ר כַּֽאֲשֶׁ֛ר דִּבֶּ֥ר יְהוָ֖ה לָֽךְ׃"
          },
          {
            "num": "ד",
            "txt": "אַל־תֹּאמַ֣ר בִּלְבָֽבְךָ֗ בַּֽהֲדֹ֣ף יְהוָה֩ אֱלֹהֶ֨יךָ אֹתָ֥ם ׀ מִלְּפָנֶיךָ֮ לֵאמֹר֒ בְּצִדְקָתִי֙ הֱבִיאַ֣נִי יְהוָ֔ה לָרֶ֖שֶׁת אֶת־הָאָ֣רֶץ הַזֹּ֑את וּבְרִשְׁעַת֙ הַגּוֹיִ֣ם הָאֵ֔לֶּה יְהוָ֖ה מֽוֹרִישָׁ֥ם מִפָּנֶֽיךָ׃"
          },
          {
            "num": "ה",
            "txt": "לֹ֣א בְצִדְקָֽתְךָ֗ וּבְיֹ֨שֶׁר֙ לְבָ֣בְךָ֔ אַתָּ֥ה בָ֖א לָרֶ֣שֶׁת אֶת־אַרְצָ֑ם כִּ֞י בְּרִשְׁעַ֣ת ׀ הַגּוֹיִ֣ם הָאֵ֗לֶּה יְהוָ֤ה אֱלֹהֶ֨יךָ֙ מֽוֹרִישָׁ֣ם מִפָּנֶ֔יךָ וּלְמַ֜עַן הָקִ֣ים אֶת־הַדָּבָ֗ר אֲשֶׁ֨ר נִשְׁבַּ֤ע יְהוָה֙ לַֽאֲבֹתֶ֔יךָ לְאַבְרָהָ֥ם לְיִצְחָ֖ק וּֽלְיַעֲקֹֽב׃"
          },
          {
            "num": "ו",
            "txt": "וְיָֽדַעְתָּ֗ כִּ֠י לֹ֤א בְצִדְקָֽתְךָ֙ יְהוָ֣ה אֱ֠לֹהֶיךָ נֹתֵ֨ן לְךָ֜ אֶת־הָאָ֧רֶץ הַטּוֹבָ֛ה הַזֹּ֖את לְרִשְׁתָּ֑הּ כִּ֥י עַם־קְשֵׁה־עֹ֖רֶף אָֽתָּה׃"
          },
          {
            "num": "ז",
            "txt": "זְכֹר֙ אַל־תִּשְׁכַּ֔ח אֵ֧ת אֲשֶׁר־הִקְצַ֛פְתָּ אֶת־יְהוָ֥ה אֱלֹהֶ֖יךָ בַּמִּדְבָּ֑ר לְמִן־הַיּ֞וֹם אֲשֶׁר־יָצָ֣אתָ ׀ מֵאֶ֣רֶץ מִצְרַ֗יִם עַד־בֹּֽאֲכֶם֙ עַד־הַמָּק֣וֹם הַזֶּ֔ה מַמְרִ֥ים הֱיִיתֶ֖ם עִם־יְהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "וּבְחֹרֵ֥ב הִקְצַפְתֶּ֖ם אֶת־יְהוָ֑ה וַיִּתְאַנַּ֧ף יְהוָ֛ה בָּכֶ֖ם לְהַשְׁמִ֥יד אֶתְכֶֽם׃"
          },
          {
            "num": "ט",
            "txt": "בַּֽעֲלֹתִ֣י הָהָ֗רָה לָקַ֜חַת לוּחֹ֤ת הָֽאֲבָנִים֙ לוּחֹ֣ת הַבְּרִ֔ית אֲשֶׁר־כָּרַ֥ת יְהוָ֖ה עִמָּכֶ֑ם וָֽאֵשֵׁ֣ב בָּהָ֗ר אַרְבָּעִ֥ים יוֹם֙ וְאַרְבָּעִ֣ים לַ֔יְלָה לֶ֚חֶם לֹ֣א אָכַ֔לְתִּי וּמַ֖יִם לֹ֥א שָׁתִֽיתִי׃"
          },
          {
            "num": "י",
            "txt": "וַיִּתֵּ֨ן יְהוָ֜ה אֵלַ֗י אֶת־שְׁנֵי֙ לוּחֹ֣ת הָֽאֲבָנִ֔ים כְּתֻבִ֖ים בְּאֶצְבַּ֣ע אֱלֹהִ֑ים וַֽעֲלֵיהֶ֗ם כְּֽכָל־הַדְּבָרִ֡ים אֲשֶׁ֣ר דִּבֶּר֩ יְהוָ֨ה עִמָּכֶ֥ם בָּהָ֛ר מִתּ֥וֹךְ הָאֵ֖שׁ בְּי֥וֹם הַקָּהָֽל׃"
          },
          {
            "num": "יא",
            "txt": "וַיְהִ֗י מִקֵּץ֙ אַרְבָּעִ֣ים י֔וֹם וְאַרְבָּעִ֖ים לָ֑יְלָה נָתַ֨ן יְהוָ֜ה אֵלַ֗י אֶת־שְׁנֵ֛י לֻחֹ֥ת הָֽאֲבָנִ֖ים לֻח֥וֹת הַבְּרִֽית׃"
          },
          {
            "num": "יב",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֵלַ֗י ק֣וּם רֵ֤ד מַהֵר֙ מִזֶּ֔ה כִּ֚י שִׁחֵ֣ת עַמְּךָ֔ אֲשֶׁ֥ר הוֹצֵ֖אתָ מִמִּצְרָ֑יִם סָ֣רוּ מַהֵ֗ר מִן־הַדֶּ֨רֶךְ֙ אֲשֶׁ֣ר צִוִּיתִ֔ם עָשׂ֥וּ לָהֶ֖ם מַסֵּכָֽה׃"
          },
          {
            "num": "יג",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֵלַ֣י לֵאמֹ֑ר רָאִ֨יתִי֙ אֶת־הָעָ֣ם הַזֶּ֔ה וְהִנֵּ֥ה עַם־קְשֵׁה־עֹ֖רֶף הֽוּא׃"
          },
          {
            "num": "יד",
            "txt": "הֶ֤רֶף מִמֶּ֨נִּי֙ וְאַשְׁמִידֵ֔ם וְאֶמְחֶ֣ה אֶת־שְׁמָ֔ם מִתַּ֖חַת הַשָּׁמָ֑יִם וְאֶֽעֱשֶׂה֙ אֽוֹתְךָ֔ לְגוֹי־עָצ֥וּם וָרָ֖ב מִמֶּֽנּוּ׃"
          },
          {
            "num": "טו",
            "txt": "וָאֵ֗פֶן וָֽאֵרֵד֙ מִן־הָהָ֔ר וְהָהָ֖ר בֹּעֵ֣ר בָּאֵ֑שׁ וּשְׁנֵי֙ לוּחֹ֣ת הַבְּרִ֔ית עַ֖ל שְׁתֵּ֥י יָדָֽי׃"
          },
          {
            "num": "טז",
            "txt": "וָאֵ֗רֶא וְהִנֵּ֤ה חֲטָאתֶם֙ לַֽיהוָ֣ה אֱלֹֽהֵיכֶ֔ם עֲשִׂיתֶ֣ם לָכֶ֔ם עֵ֖גֶל מַסֵּכָ֑ה סַרְתֶּ֣ם מַהֵ֔ר מִן־הַדֶּ֕רֶךְ אֲשֶׁר־צִוָּ֥ה יְהוָ֖ה אֶתְכֶֽם׃"
          },
          {
            "num": "יז",
            "txt": "וָֽאֶתְפֹּשׂ֙ בִּשְׁנֵ֣י הַלֻּחֹ֔ת וָֽאַשְׁלִכֵ֔ם מֵעַ֖ל שְׁתֵּ֣י יָדָ֑י וָֽאֲשַׁבְּרֵ֖ם לְעֵֽינֵיכֶֽם׃"
          },
          {
            "num": "יח",
            "txt": "וָֽאֶתְנַפַּל֩ לִפְנֵ֨י יְהוָ֜ה כָּרִֽאשֹׁנָ֗ה אַרְבָּעִ֥ים יוֹם֙ וְאַרְבָּעִ֣ים לַ֔יְלָה לֶ֚חֶם לֹ֣א אָכַ֔לְתִּי וּמַ֖יִם לֹ֣א שָׁתִ֑יתִי עַ֤ל כָּל־חַטַּאתְכֶם֙ אֲשֶׁ֣ר חֲטָאתֶ֔ם לַֽעֲשׂ֥וֹת הָרַ֛ע בְּעֵינֵ֥י יְהוָ֖ה לְהַכְעִיסֽוֹ׃"
          },
          {
            "num": "יט",
            "txt": "כִּ֣י יָגֹ֗רְתִּי מִפְּנֵ֤י הָאַף֙ וְהַ֣חֵמָ֔ה אֲשֶׁ֨ר קָצַ֧ף יְהוָ֛ה עֲלֵיכֶ֖ם לְהַשְׁמִ֣יד אֶתְכֶ֑ם וַיִּשְׁמַ֤ע יְהוָה֙ אֵלַ֔י גַּ֖ם בַּפַּ֥עַם הַהִֽוא׃"
          },
          {
            "num": "כ",
            "txt": "וּֽבְאַהֲרֹ֗ן הִתְאַנַּ֧ף יְהוָ֛ה מְאֹ֖ד לְהַשְׁמִיד֑וֹ וָֽאֶתְפַּלֵּ֛ל גַּם־בְּעַ֥ד אַֽהֲרֹ֖ן בָּעֵ֥ת הַהִֽוא׃"
          },
          {
            "num": "כא",
            "txt": "וְֽאֶת־חַטַּאתְכֶ֞ם אֲשֶׁר־עֲשִׂיתֶ֣ם אֶת־הָעֵ֗גֶל לָקַחְתִּי֮ וָֽאֶשְׂרֹ֣ף אֹת֣וֹ ׀ בָּאֵשׁ֒ וָֽאֶכֹּ֨ת אֹת֤וֹ טָחוֹן֙ הֵיטֵ֔ב עַ֥ד אֲשֶׁר־דַּ֖ק לְעָפָ֑ר וָֽאַשְׁלִךְ֙ אֶת־עֲפָר֔וֹ אֶל־הַנַּ֖חַל הַיֹּרֵ֥ד מִן־הָהָֽר׃"
          },
          {
            "num": "כב",
            "txt": "וּבְתַבְעֵרָה֙ וּבְמַסָּ֔ה וּבְקִבְרֹ֖ת הַֽתַּאֲוָ֑ה מַקְצִפִ֥ים הֱיִיתֶ֖ם אֶת־יְהוָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וּבִשְׁלֹ֨חַ יְהוָ֜ה אֶתְכֶ֗ם מִקָּדֵ֤שׁ בַּרְנֵ֨עַ֙ לֵאמֹ֔ר עֲלוּ֙ וּרְשׁ֣וּ אֶת־הָאָ֔רֶץ אֲשֶׁ֥ר נָתַ֖תִּי לָכֶ֑ם וַתַּמְר֗וּ אֶת־פִּ֤י יְהוָה֙ אֱלֹ֣הֵיכֶ֔ם וְלֹ֤א הֶֽאֱמַנְתֶּם֙ ל֔וֹ וְלֹ֥א שְׁמַעְתֶּ֖ם בְּקֹלֽוֹ׃"
          },
          {
            "num": "כד",
            "txt": "מַמְרִ֥ים הֱיִיתֶ֖ם עִם־יְהוָ֑ה מִיּ֖וֹם דַּעְתִּ֥י אֶתְכֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "וָֽאֶתְנַפַּ֞ל לִפְנֵ֣י יְהוָ֗ה אֵ֣ת אַרְבָּעִ֥ים הַיּ֛וֹם וְאֶת־אַרְבָּעִ֥ים הַלַּ֖יְלָה אֲשֶׁ֣ר הִתְנַפָּ֑לְתִּי כִּֽי־אָמַ֥ר יְהוָ֖ה לְהַשְׁמִ֥יד אֶתְכֶֽם׃"
          },
          {
            "num": "כו",
            "txt": "וָֽאֶתְפַּלֵּ֣ל אֶל־יְהוָה֮ וָֽאֹמַר֒ אֲדֹנָ֣י יְהוִ֗ה אַל־תַּשְׁחֵ֤ת עַמְּךָ֙ וְנַחֲלָ֣תְךָ֔ אֲשֶׁ֥ר פָּדִ֖יתָ בְּגָדְלֶ֑ךָ אֲשֶׁר־הוֹצֵ֥אתָ מִמִּצְרַ֖יִם בְּיָ֥ד חֲזָקָֽה׃"
          },
          {
            "num": "כז",
            "txt": "זְכֹר֙ לַֽעֲבָדֶ֔יךָ לְאַבְרָהָ֥ם לְיִצְחָ֖ק וּֽלְיַעֲקֹ֑ב אַל־תֵּ֗פֶן אֶל־קְשִׁי֙ הָעָ֣ם הַזֶּ֔ה וְאֶל־רִשְׁע֖וֹ וְאֶל־חַטָּאתֽוֹ׃"
          },
          {
            "num": "כח",
            "txt": "פֶּן־יֹֽאמְר֗וּ הָאָרֶץ֮ אֲשֶׁ֣ר הֽוֹצֵאתָ֣נוּ מִשָּׁם֒ מִבְּלִי֙ יְכֹ֣לֶת יְהוָ֔ה לַֽהֲבִיאָ֕ם אֶל־הָאָ֖רֶץ אֲשֶׁר־דִּבֶּ֣ר לָהֶ֑ם וּמִשִּׂנְאָת֣וֹ אוֹתָ֔ם הֽוֹצִיאָ֖ם לַֽהֲמִתָ֥ם בַּמִּדְבָּֽר׃"
          },
          {
            "num": "כט",
            "txt": "וְהֵ֥ם עַמְּךָ֖ וְנַֽחֲלָתֶ֑ךָ אֲשֶׁ֤ר הוֹצֵ֨אתָ֙ בְּכֹֽחֲךָ֣ הַגָּדֹ֔ל וּבִֽזְרֹעֲךָ֖ הַנְּטוּיָֽה׃ {פ}"
          }
        ]
      },
      {
        "num": "י",
        "verses": [
          {
            "num": "א",
            "txt": "בָּעֵ֨ת הַהִ֜וא אָמַ֧ר יְהוָ֣ה אֵלַ֗י פְּסָל־לְךָ֞ שְׁנֵֽי־לוּחֹ֤ת אֲבָנִים֙ כָּרִ֣אשֹׁנִ֔ים וַֽעֲלֵ֥ה אֵלַ֖י הָהָ֑רָה וְעָשִׂ֥יתָ לְּךָ֖ אֲר֥וֹן עֵֽץ׃"
          },
          {
            "num": "ב",
            "txt": "וְאֶכְתֹּב֙ עַל־הַלֻּחֹ֔ת אֶ֨ת־הַדְּבָרִ֔ים אֲשֶׁ֥ר הָי֛וּ עַל־הַלֻּחֹ֥ת הָרִֽאשֹׁנִ֖ים אֲשֶׁ֣ר שִׁבַּ֑רְתָּ וְשַׂמְתָּ֖ם בָּֽאָרֽוֹן׃"
          },
          {
            "num": "ג",
            "txt": "וָאַ֤עַשׂ אֲרוֹן֙ עֲצֵ֣י שִׁטִּ֔ים וָֽאֶפְסֹ֛ל שְׁנֵֽי־לֻחֹ֥ת אֲבָנִ֖ים כָּרִֽאשֹׁנִ֑ים וָאַ֣עַל הָהָ֔רָה וּשְׁנֵ֥י הַלֻּחֹ֖ת בְּיָדִֽי׃"
          },
          {
            "num": "ד",
            "txt": "וַיִּכְתֹּ֨ב עַֽל־הַלֻּחֹ֜ת כַּמִּכְתָּ֣ב הָֽרִאשׁ֗וֹן אֵ֚ת עֲשֶׂ֣רֶת הַדְּבָרִ֔ים אֲשֶׁ֣ר דִּבֶּר֩ יְהוָ֨ה אֲלֵיכֶ֥ם בָּהָ֛ר מִתּ֥וֹךְ הָאֵ֖שׁ בְּי֣וֹם הַקָּהָ֑ל וַיִּתְּנֵ֥ם יְהוָ֖ה אֵלָֽי׃"
          },
          {
            "num": "ה",
            "txt": "וָאֵ֗פֶן וָֽאֵרֵד֙ מִן־הָהָ֔ר וָֽאָשִׂם֙ אֶת־הַלֻּחֹ֔ת בָּֽאָר֖וֹן אֲשֶׁ֣ר עָשִׂ֑יתִי וַיִּ֣הְיוּ שָׁ֔ם כַּֽאֲשֶׁ֥ר צִוַּ֖נִי יְהוָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וּבְנֵ֣י יִשְׂרָאֵ֗ל נָ֥סְע֛וּ מִבְּאֵרֹ֥ת בְּנֵֽי־יַעֲקָ֖ן מֽוֹסֵרָ֑ה שָׁ֣ם מֵ֤ת אַֽהֲרֹן֙ וַיִּקָּבֵ֣ר שָׁ֔ם וַיְכַהֵ֛ן אֶלְעָזָ֥ר בְּנ֖וֹ תַּחְתָּֽיו׃"
          },
          {
            "num": "ז",
            "txt": "מִשָּׁ֥ם נָֽסְע֖וּ הַגֻּדְגֹּ֑דָה וּמִן־הַגֻּדְגֹּ֣דָה יָטְבָ֔תָה אֶ֖רֶץ נַ֥חֲלֵי מָֽיִם׃"
          },
          {
            "num": "ח",
            "txt": "בָּעֵ֣ת הַהִ֗וא הִבְדִּ֤יל יְהוָה֙ אֶת־שֵׁ֣בֶט הַלֵּוִ֔י לָשֵׂ֖את אֶת־אֲר֣וֹן בְּרִית־יְהוָ֑ה לַֽעֲמֹד֩ לִפְנֵ֨י יְהוָ֤ה לְשָֽׁרְתוֹ֙ וּלְבָרֵ֣ךְ בִּשְׁמ֔וֹ עַ֖ד הַיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "ט",
            "txt": "עַל־כֵּ֞ן לֹֽא־הָיָ֧ה לְלֵוִ֛י חֵ֥לֶק וְנַֽחֲלָ֖ה עִם־אֶחָ֑יו יְהוָה֙ ה֣וּא נַֽחֲלָת֔וֹ כַּֽאֲשֶׁ֥ר דִּבֶּ֛ר יְהוָ֥ה אֱלֹהֶ֖יךָ לֽוֹ׃"
          },
          {
            "num": "י",
            "txt": "וְאָֽנֹכִ֞י עָמַ֣דְתִּי בָהָ֗ר כַּיָּמִים֙ הָרִ֣אשֹׁנִ֔ים אַרְבָּעִ֣ים י֔וֹם וְאַרְבָּעִ֖ים לָ֑יְלָה וַיִּשְׁמַ֨ע יְהוָ֜ה אֵלַ֗י גַּ֚ם בַּפַּ֣עַם הַהִ֔וא לֹֽא־אָבָ֥ה יְהוָ֖ה הַשְׁחִיתֶֽךָ׃"
          },
          {
            "num": "יא",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֵלַ֔י ק֛וּם לֵ֥ךְ לְמַסַּ֖ע לִפְנֵ֣י הָעָ֑ם וְיָבֹ֨אוּ֙ וְיִֽירְשׁ֣וּ אֶת־הָאָ֔רֶץ אֲשֶׁר־נִשְׁבַּ֥עְתִּי לַֽאֲבֹתָ֖ם לָתֵ֥ת לָהֶֽם׃ {פ}"
          },
          {
            "num": "יב",
            "txt": "וְעַתָּה֙ יִשְׂרָאֵ֔ל מָ֚ה יְהוָ֣ה אֱלֹהֶ֔יךָ שֹׁאֵ֖ל מֵֽעִמָּ֑ךְ כִּ֣י אִם־לְ֠יִרְאָה אֶת־יְהוָ֨ה אֱלֹהֶ֜יךָ לָלֶ֤כֶת בְּכָל־דְּרָכָיו֙ וּלְאַֽהֲבָ֣ה אֹת֔וֹ וְלַֽעֲבֹד֙ אֶת־יְהוָ֣ה אֱלֹהֶ֔יךָ בְּכָל־לְבָֽבְךָ֖ וּבְכָל־נַפְשֶֽׁךָ׃"
          },
          {
            "num": "יג",
            "txt": "לִשְׁמֹ֞ר אֶת־מִצְוֺ֤ת יְהוָה֙ וְאֶת־חֻקֹּתָ֔יו אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּ֑וֹם לְט֖וֹב לָֽךְ׃"
          },
          {
            "num": "יד",
            "txt": "הֵ֚ן לַֽיהוָ֣ה אֱלֹהֶ֔יךָ הַשָּׁמַ֖יִם וּשְׁמֵ֣י הַשָּׁמָ֑יִם הָאָ֖רֶץ וְכָל־אֲשֶׁר־בָּֽהּ׃"
          },
          {
            "num": "טו",
            "txt": "רַ֧ק בַּֽאֲבֹתֶ֛יךָ חָשַׁ֥ק יְהוָ֖ה לְאַֽהֲבָ֣ה אוֹתָ֑ם וַיִּבְחַ֞ר בְּזַרְעָ֣ם אַֽחֲרֵיהֶ֗ם בָּכֶ֛ם מִכָּל־הָֽעַמִּ֖ים כַּיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "טז",
            "txt": "וּמַלְתֶּ֕ם אֵ֖ת עָרְלַ֣ת לְבַבְכֶ֑ם וְעָ֨רְפְּכֶ֔ם לֹ֥א תַקְשׁ֖וּ עֽוֹד׃"
          },
          {
            "num": "יז",
            "txt": "כִּ֚י יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם ה֚וּא אֱלֹהֵ֣י הָֽאֱלֹהִ֔ים וַֽאֲדֹנֵ֖י הָֽאֲדֹנִ֑ים הָאֵ֨ל הַגָּדֹ֤ל הַגִּבֹּר֙ וְהַנּוֹרָ֔א אֲשֶׁר֙ לֹֽא־יִשָּׂ֣א פָנִ֔ים וְלֹ֥א יִקַּ֖ח שֹֽׁחַד׃"
          },
          {
            "num": "יח",
            "txt": "עֹשֶׂ֛ה מִשְׁפַּ֥ט יָת֖וֹם וְאַלְמָנָ֑ה וְאֹהֵ֣ב גֵּ֔ר לָ֥תֶת ל֖וֹ לֶ֥חֶם וְשִׂמְלָֽה׃"
          },
          {
            "num": "יט",
            "txt": "וַֽאֲהַבְתֶּ֖ם אֶת־הַגֵּ֑ר כִּֽי־גֵרִ֥ים הֱיִיתֶ֖ם בְּאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "כ",
            "txt": "אֶת־יְהוָ֧ה אֱלֹהֶ֛יךָ תִּירָ֖א אֹת֣וֹ תַֽעֲבֹ֑ד וּב֣וֹ תִדְבָּ֔ק וּבִשְׁמ֖וֹ תִּשָּׁבֵֽעַ׃"
          },
          {
            "num": "כא",
            "txt": "ה֥וּא תְהִלָּֽתְךָ֖ וְה֣וּא אֱלֹהֶ֑יךָ אֲשֶׁר־עָשָׂ֣ה אִתְּךָ֗ אֶת־הַגְּדֹלֹ֤ת וְאֶת־הַנּֽוֹרָאֹת֙ הָאֵ֔לֶּה אֲשֶׁ֥ר רָא֖וּ עֵינֶֽיךָ׃"
          },
          {
            "num": "כב",
            "txt": "בְּשִׁבְעִ֣ים נֶ֔פֶשׁ יָֽרְד֥וּ אֲבֹתֶ֖יךָ מִצְרָ֑יְמָה וְעַתָּ֗ה שָֽׂמְךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ כְּכֽוֹכְבֵ֥י הַשָּׁמַ֖יִם לָרֹֽב׃"
          }
        ]
      },
      {
        "num": "יא",
        "verses": [
          {
            "num": "א",
            "txt": "וְאָ֣הַבְתָּ֔ אֵ֖ת יְהוָ֣ה אֱלֹהֶ֑יךָ וְשָֽׁמַרְתָּ֣ מִשְׁמַרְתּ֗וֹ וְחֻקֹּתָ֧יו וּמִשְׁפָּטָ֛יו וּמִצְוֺתָ֖יו כָּל־הַיָּמִֽים׃"
          },
          {
            "num": "ב",
            "txt": "וִֽידַעְתֶּם֮ הַיּוֹם֒ כִּ֣י ׀ לֹ֣א אֶת־בְּנֵיכֶ֗ם אֲשֶׁ֤ר לֹֽא־יָדְעוּ֙ וַֽאֲשֶׁ֣ר לֹֽא־רָא֔וּ אֶת־מוּסַ֖ר יְהוָ֣ה אֱלֹֽהֵיכֶ֑ם אֶת־גָּדְל֕וֹ אֶת־יָדוֹ֙ הַֽחֲזָקָ֔ה וּזְרֹע֖וֹ הַנְּטוּיָֽה׃"
          },
          {
            "num": "ג",
            "txt": "וְאֶת־אֹֽתֹתָיו֙ וְאֶֽת־מַעֲשָׂ֔יו אֲשֶׁ֥ר עָשָׂ֖ה בְּת֣וֹךְ מִצְרָ֑יִם לְפַרְעֹ֥ה מֶֽלֶךְ־מִצְרַ֖יִם וּלְכָל־אַרְצֽוֹ׃"
          },
          {
            "num": "ד",
            "txt": "וַֽאֲשֶׁ֣ר עָשָׂה֩ לְחֵ֨יל מִצְרַ֜יִם לְסוּסָ֣יו וּלְרִכְבּ֗וֹ אֲשֶׁ֨ר הֵצִ֜יף אֶת־מֵ֤י יַם־סוּף֙ עַל־פְּנֵיהֶ֔ם בְּרָדְפָ֖ם אַֽחֲרֵיכֶ֑ם וַיְאַבְּדֵ֣ם יְהוָ֔ה עַ֖ד הַיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "ה",
            "txt": "וַֽאֲשֶׁ֥ר עָשָׂ֛ה לָכֶ֖ם בַּמִּדְבָּ֑ר עַד־בֹּֽאֲכֶ֖ם עַד־הַמָּק֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "ו",
            "txt": "וַֽאֲשֶׁ֨ר עָשָׂ֜ה לְדָתָ֣ן וְלַֽאֲבִירָ֗ם בְּנֵ֣י אֱלִיאָב֮ בֶּן־רְאוּבֵן֒ אֲשֶׁ֨ר פָּֽצְתָ֤ה הָאָ֨רֶץ֙ אֶת־פִּ֔יהָ וַתִּבְלָעֵ֥ם וְאֶת־בָּֽתֵּיהֶ֖ם וְאֶת־אָֽהֳלֵיהֶ֑ם וְאֵ֤ת כָּל־הַיְקוּם֙ אֲשֶׁ֣ר בְּרַגְלֵיהֶ֔ם בְּקֶ֖רֶב כָּל־יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ז",
            "txt": "כִּ֤י עֵֽינֵיכֶם֙ הָֽרֹאֹ֔ת אֶת־כָּל־מַֽעֲשֵׂ֥ה יְהוָ֖ה הַגָּדֹ֑ל אֲשֶׁ֖ר עָשָֽׂה׃"
          },
          {
            "num": "ח",
            "txt": "וּשְׁמַרְתֶּם֙ אֶת־כָּל־הַמִּצְוָ֔ה אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּ֑וֹם לְמַ֣עַן תֶּֽחֶזְק֗וּ וּבָאתֶם֙ וִֽירִשְׁתֶּ֣ם אֶת־הָאָ֔רֶץ אֲשֶׁ֥ר אַתֶּ֛ם עֹֽבְרִ֥ים שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "ט",
            "txt": "וּלְמַ֨עַן תַּֽאֲרִ֤יכוּ יָמִים֙ עַל־הָ֣אֲדָמָ֔ה אֲשֶׁר֩ נִשְׁבַּ֨ע יְהוָ֧ה לַאֲבֹֽתֵיכֶ֛ם לָתֵ֥ת לָהֶ֖ם וּלְזַרְעָ֑ם אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָֽשׁ׃ {ס}"
          },
          {
            "num": "י",
            "txt": "כִּ֣י הָאָ֗רֶץ אֲשֶׁ֨ר אַתָּ֤ה בָא־שָׁ֨מָּה֙ לְרִשְׁתָּ֔הּ לֹ֣א כְאֶ֤רֶץ מִצְרַ֨יִם֙ הִ֔וא אֲשֶׁ֥ר יְצָאתֶ֖ם מִשָּׁ֑ם אֲשֶׁ֤ר תִּזְרַע֙ אֶֽת־זַרְעֲךָ֔ וְהִשְׁקִ֥יתָ בְרַגְלְךָ֖ כְּגַ֥ן הַיָּרָֽק׃"
          },
          {
            "num": "יא",
            "txt": "וְהָאָ֗רֶץ אֲשֶׁ֨ר אַתֶּ֜ם עֹֽבְרִ֥ים שָׁ֨מָּה֙ לְרִשְׁתָּ֔הּ אֶ֥רֶץ הָרִ֖ים וּבְקָעֹ֑ת לִמְטַ֥ר הַשָּׁמַ֖יִם תִּשְׁתֶּה־מָּֽיִם׃"
          },
          {
            "num": "יב",
            "txt": "אֶ֕רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ דֹּרֵ֣שׁ אֹתָ֑הּ תָּמִ֗יד עֵינֵ֨י יְהוָ֤ה אֱלֹהֶ֨יךָ֙ בָּ֔הּ מֵֽרֵשִׁית֙ הַשָּׁנָ֔ה וְעַ֖ד אַֽחֲרִ֥ית שָׁנָֽה׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "וְהָיָ֗ה אִם־שָׁמֹ֤עַ תִּשְׁמְעוּ֙ אֶל־מִצְוֺתַ֔י אֲשֶׁ֧ר אָֽנֹכִ֛י מְצַוֶּ֥ה אֶתְכֶ֖ם הַיּ֑וֹם לְאַֽהֲבָ֞ה אֶת־יְהוָ֤ה אֱלֹֽהֵיכֶם֙ וּלְעָבְד֔וֹ בְּכָל־לְבַבְכֶ֖ם וּבְכָל־נַפְשְׁכֶֽם׃"
          },
          {
            "num": "יד",
            "txt": "וְנָֽתַתִּ֧י מְטַֽר־אַרְצְכֶ֛ם בְּעִתּ֖וֹ יוֹרֶ֣ה וּמַלְק֑וֹשׁ וְאָֽסַפְתָּ֣ דְגָנֶ֔ךָ וְתִֽירֹשְׁךָ֖ וְיִצְהָרֶֽךָ׃"
          },
          {
            "num": "טו",
            "txt": "וְנָֽתַתִּ֛י עֵ֥שֶׂב בְּשָֽׂדְךָ֖ לִבְהֶמְתֶּ֑ךָ וְאָֽכַלְתָּ֖ וְשָׂבָֽעְתָּ׃"
          },
          {
            "num": "טז",
            "txt": "הִשָּֽׁמְר֣וּ לָכֶ֔ם פֶּ֥ן יִפְתֶּ֖ה לְבַבְכֶ֑ם וְסַרְתֶּ֗ם וַֽעֲבַדְתֶּם֙ אֱלֹהִ֣ים אֲחֵרִ֔ים וְהִשְׁתַּֽחֲוִיתֶ֖ם לָהֶֽם׃"
          },
          {
            "num": "יז",
            "txt": "וְחָרָ֨ה אַף־יְהוָ֜ה בָּכֶ֗ם וְעָצַ֤ר אֶת־הַשָּׁמַ֨יִם֙ וְלֹֽא־יִהְיֶ֣ה מָטָ֔ר וְהָ֣אֲדָמָ֔ה לֹ֥א תִתֵּ֖ן אֶת־יְבוּלָ֑הּ וַֽאֲבַדְתֶּ֣ם מְהֵרָ֗ה מֵעַל֙ הָאָ֣רֶץ הַטֹּבָ֔ה אֲשֶׁ֥ר יְהוָ֖ה נֹתֵ֥ן לָכֶֽם׃"
          },
          {
            "num": "יח",
            "txt": "וְשַׂמְתֶּם֙ אֶת־דְּבָרַ֣י אֵ֔לֶּה עַל־לְבַבְכֶ֖ם וְעַֽל־נַפְשְׁכֶ֑ם וּקְשַׁרְתֶּ֨ם אֹתָ֤ם לְאוֹת֙ עַל־יֶדְכֶ֔ם וְהָי֥וּ לְטֽוֹטָפֹ֖ת בֵּ֥ין עֵֽינֵיכֶֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְלִמַּדְתֶּ֥ם אֹתָ֛ם אֶת־בְּנֵיכֶ֖ם לְדַבֵּ֣ר בָּ֑ם בְּשִׁבְתְּךָ֤ בְּבֵיתֶ֨ךָ֙ וּבְלֶכְתְּךָ֣ בַדֶּ֔רֶךְ וּֽבְשָׁכְבְּךָ֖ וּבְקוּמֶֽךָ׃"
          },
          {
            "num": "כ",
            "txt": "וּכְתַבְתָּ֛ם עַל־מְזוּז֥וֹת בֵּיתֶ֖ךָ וּבִשְׁעָרֶֽיךָ׃"
          },
          {
            "num": "כא",
            "txt": "לְמַ֨עַן יִרְבּ֤וּ יְמֵיכֶם֙ וִימֵ֣י בְנֵיכֶ֔ם עַ֚ל הָֽאֲדָמָ֔ה אֲשֶׁ֨ר נִשְׁבַּ֧ע יְהוָ֛ה לַאֲבֹֽתֵיכֶ֖ם לָתֵ֣ת לָהֶ֑ם כִּימֵ֥י הַשָּׁמַ֖יִם עַל־הָאָֽרֶץ׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "כִּי֩ אִם־שָׁמֹ֨ר תִּשְׁמְר֜וּן אֶת־כָּל־הַמִּצְוָ֣ה הַזֹּ֗את אֲשֶׁ֧ר אָֽנֹכִ֛י מְצַוֶּ֥ה אֶתְכֶ֖ם לַֽעֲשֹׂתָ֑הּ לְאַֽהֲבָ֞ה אֶת־יְהוָ֧ה אֱלֹֽהֵיכֶ֛ם לָלֶ֥כֶת בְּכָל־דְּרָכָ֖יו וּלְדָבְקָה־בֽוֹ׃"
          },
          {
            "num": "כג",
            "txt": "וְהוֹרִ֧ישׁ יְהוָ֛ה אֶת־כָּל־הַגּוֹיִ֥ם הָאֵ֖לֶּה מִלִּפְנֵיכֶ֑ם וִֽירִשְׁתֶּ֣ם גּוֹיִ֔ם גְּדֹלִ֥ים וַֽעֲצֻמִ֖ים מִכֶּֽם׃"
          },
          {
            "num": "כד",
            "txt": "כָּל־הַמָּק֗וֹם אֲשֶׁ֨ר תִּדְרֹ֧ךְ כַּֽף־רַגְלְכֶ֛ם בּ֖וֹ לָכֶ֣ם יִֽהְיֶ֑ה מִן־הַמִּדְבָּ֨ר וְהַלְּבָנ֜וֹן מִן־הַנָּהָ֣ר נְהַר־פְּרָ֗ת וְעַד֙ הַיָּ֣ם הָֽאַחֲר֔וֹן יִֽהְיֶ֖ה גְּבֻֽלְכֶֽם׃"
          },
          {
            "num": "כה",
            "txt": "לֹֽא־יִתְיַצֵּ֥ב אִ֖ישׁ בִּפְנֵיכֶ֑ם פַּחְדְּכֶ֨ם וּמֽוֹרַאֲכֶ֜ם יִתֵּ֣ן ׀ יְהוָ֣ה אֱלֹֽהֵיכֶ֗ם עַל־פְּנֵ֤י כָל־הָאָ֨רֶץ֙ אֲשֶׁ֣ר תִּדְרְכוּ־בָ֔הּ כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר לָכֶֽם׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "רְאֵ֗ה אָֽנֹכִ֛י נֹתֵ֥ן לִפְנֵיכֶ֖ם הַיּ֑וֹם בְּרָכָ֖ה וּקְלָלָֽה׃"
          },
          {
            "num": "כז",
            "txt": "אֶֽת־הַבְּרָכָ֑ה אֲשֶׁ֣ר תִּשְׁמְע֗וּ אֶל־מִצְוֺת֙ יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם אֲשֶׁ֧ר אָֽנֹכִ֛י מְצַוֶּ֥ה אֶתְכֶ֖ם הַיּֽוֹם׃"
          },
          {
            "num": "כח",
            "txt": "וְהַקְּלָלָ֗ה אִם־לֹ֤א תִשְׁמְעוּ֙ אֶל־מִצְוֺת֙ יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם וְסַרְתֶּ֣ם מִן־הַדֶּ֔רֶךְ אֲשֶׁ֧ר אָֽנֹכִ֛י מְצַוֶּ֥ה אֶתְכֶ֖ם הַיּ֑וֹם לָלֶ֗כֶת אַֽחֲרֵ֛י אֱלֹהִ֥ים אֲחֵרִ֖ים אֲשֶׁ֥ר לֹֽא־יְדַעְתֶּֽם׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "וְהָיָ֗ה כִּ֤י יְבִֽיאֲךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ אֶל־הָאָ֕רֶץ אֲשֶׁר־אַתָּ֥ה בָא־שָׁ֖מָּה לְרִשְׁתָּ֑הּ וְנָֽתַתָּ֤ה אֶת־הַבְּרָכָה֙ עַל־הַ֣ר גְּרִזִ֔ים וְאֶת־הַקְּלָלָ֖ה עַל־הַ֥ר עֵיבָֽל׃"
          },
          {
            "num": "ל",
            "txt": "הֲלֹא־הֵ֜מָּה בְּעֵ֣בֶר הַיַּרְדֵּ֗ן אַֽחֲרֵי֙ דֶּ֚רֶךְ מְב֣וֹא הַשֶּׁ֔מֶשׁ בְּאֶ֨רֶץ֙ הַֽכְּנַעֲנִ֔י הַיֹּשֵׁ֖ב בָּֽעֲרָבָ֑ה מ֚וּל הַגִּלְגָּ֔ל אֵ֖צֶל אֵֽלוֹנֵ֥י מֹרֶֽה׃"
          },
          {
            "num": "לא",
            "txt": "כִּ֤י אַתֶּם֙ עֹֽבְרִ֣ים אֶת־הַיַּרְדֵּ֔ן לָבֹא֙ לָרֶ֣שֶׁת אֶת־הָאָ֔רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹֽהֵיכֶ֖ם נֹתֵ֣ן לָכֶ֑ם וִֽירִשְׁתֶּ֥ם אֹתָ֖הּ וִֽישַׁבְתֶּם־בָּֽהּ׃"
          },
          {
            "num": "לב",
            "txt": "וּשְׁמַרְתֶּ֣ם לַֽעֲשׂ֔וֹת אֵ֥ת כָּל־הַֽחֻקִּ֖ים וְאֶת־הַמִּשְׁפָּטִ֑ים אֲשֶׁ֧ר אָֽנֹכִ֛י נֹתֵ֥ן לִפְנֵיכֶ֖ם הַיּֽוֹם׃"
          }
        ]
      },
      {
        "num": "יב",
        "verses": [
          {
            "num": "א",
            "txt": "אֵ֠לֶּה הַֽחֻקִּ֣ים וְהַמִּשְׁפָּטִים֮ אֲשֶׁ֣ר תִּשְׁמְר֣וּן לַֽעֲשׂוֹת֒ בָּאָ֕רֶץ אֲשֶׁר֩ נָתַ֨ן יְהוָ֜ה אֱלֹהֵ֧י אֲבֹתֶ֛יךָ לְךָ֖ לְרִשְׁתָּ֑הּ כָּ֨ל־הַיָּמִ֔ים אֲשֶׁר־אַתֶּ֥ם חַיִּ֖ים עַל־הָֽאֲדָמָֽה׃"
          },
          {
            "num": "ב",
            "txt": "אַבֵּ֣ד תְּ֠אַבְּדוּן אֶֽת־כָּל־הַמְּקֹמ֞וֹת אֲשֶׁ֧ר עָֽבְדוּ־שָׁ֣ם הַגּוֹיִ֗ם אֲשֶׁ֥ר אַתֶּ֛ם יֹֽרְשִׁ֥ים אֹתָ֖ם אֶת־אֱלֹֽהֵיהֶ֑ם עַל־הֶֽהָרִ֤ים הָֽרָמִים֙ וְעַל־הַגְּבָע֔וֹת וְתַ֖חַת כָּל־עֵ֥ץ רַֽעֲנָֽן׃"
          },
          {
            "num": "ג",
            "txt": "וְנִתַּצְתֶּ֣ם אֶת־מִזְבְּחֹתָ֗ם וְשִׁבַּרְתֶּם֙ אֶת־מַצֵּ֣בֹתָ֔ם וַאֲשֵֽׁרֵיהֶם֙ תִּשְׂרְפ֣וּן בָּאֵ֔שׁ וּפְסִילֵ֥י אֱלֹֽהֵיהֶ֖ם תְּגַדֵּע֑וּן וְאִבַּדְתֶּ֣ם אֶת־שְׁמָ֔ם מִן־הַמָּק֖וֹם הַהֽוּא׃"
          },
          {
            "num": "ד",
            "txt": "לֹֽא־תַעֲשׂ֣וּן כֵּ֔ן לַֽיהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "כִּ֠י אִֽם־אֶל־הַמָּק֞וֹם אֲשֶׁר־יִבְחַ֨ר יְהוָ֤ה אֱלֹֽהֵיכֶם֙ מִכָּל־שִׁבְטֵיכֶ֔ם לָשׂ֥וּם אֶת־שְׁמ֖וֹ שָׁ֑ם לְשִׁכְנ֥וֹ תִדְרְשׁ֖וּ וּבָ֥אתָ שָּֽׁמָּה׃"
          },
          {
            "num": "ו",
            "txt": "וַֽהֲבֵאתֶ֣ם שָׁ֗מָּה עֹלֹֽתֵיכֶם֙ וְזִבְחֵיכֶ֔ם וְאֵת֙ מַעְשְׂרֹ֣תֵיכֶ֔ם וְאֵ֖ת תְּרוּמַ֣ת יֶדְכֶ֑ם וְנִדְרֵיכֶם֙ וְנִדְבֹ֣תֵיכֶ֔ם וּבְכֹרֹ֥ת בְּקַרְכֶ֖ם וְצֹֽאנְכֶֽם׃"
          },
          {
            "num": "ז",
            "txt": "וַֽאֲכַלְתֶּם־שָׁ֗ם לִפְנֵי֙ יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם וּשְׂמַחְתֶּ֗ם בְּכֹל֙ מִשְׁלַ֣ח יֶדְכֶ֔ם אַתֶּ֖ם וּבָֽתֵּיכֶ֑ם אֲשֶׁ֥ר בֵּֽרַכְךָ֖ יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "ח",
            "txt": "לֹ֣א תַֽעֲשׂ֔וּן כְּ֠כֹל אֲשֶׁ֨ר אֲנַ֧חְנוּ עֹשִׂ֛ים פֹּ֖ה הַיּ֑וֹם אִ֖ישׁ כָּל־הַיָּשָׁ֥ר בְּעֵינָֽיו׃"
          },
          {
            "num": "ט",
            "txt": "כִּ֥י לֹֽא־בָאתֶ֖ם עַד־עָ֑תָּה אֶל־הַמְּנוּחָה֙ וְאֶל־הַֽנַּחֲלָ֔ה אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֥ן לָֽךְ׃"
          },
          {
            "num": "י",
            "txt": "וַֽעֲבַרְתֶּם֮ אֶת־הַיַּרְדֵּן֒ וִֽישַׁבְתֶּ֣ם בָּאָ֔רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹֽהֵיכֶ֖ם מַנְחִ֣יל אֶתְכֶ֑ם וְהֵנִ֨יחַ לָכֶ֧ם מִכָּל־אֹֽיְבֵיכֶ֛ם מִסָּבִ֖יב וִֽישַׁבְתֶּם־בֶּֽטַח׃"
          },
          {
            "num": "יא",
            "txt": "וְהָיָ֣ה הַמָּק֗וֹם אֲשֶׁר־יִבְחַר֩ יְהוָ֨ה אֱלֹֽהֵיכֶ֥ם בּוֹ֙ לְשַׁכֵּ֤ן שְׁמוֹ֙ שָׁ֔ם שָׁ֣מָּה תָבִ֔יאוּ אֵ֛ת כָּל־אֲשֶׁ֥ר אָֽנֹכִ֖י מְצַוֶּ֣ה אֶתְכֶ֑ם עוֹלֹֽתֵיכֶ֣ם וְזִבְחֵיכֶ֗ם מַעְשְׂרֹֽתֵיכֶם֙ וּתְרֻמַ֣ת יֶדְכֶ֔ם וְכֹל֙ מִבְחַ֣ר נִדְרֵיכֶ֔ם אֲשֶׁ֥ר תִּדְּר֖וּ לַֽיהוָֽה׃"
          },
          {
            "num": "יב",
            "txt": "וּשְׂמַחְתֶּ֗ם לִפְנֵי֮ יְהוָ֣ה אֱלֹֽהֵיכֶם֒ אַתֶּ֗ם וּבְנֵיכֶם֙ וּבְנֹ֣תֵיכֶ֔ם וְעַבְדֵיכֶ֖ם וְאַמְהֹֽתֵיכֶ֑ם וְהַלֵּוִי֙ אֲשֶׁ֣ר בְּשַֽׁעֲרֵיכֶ֔ם כִּ֣י אֵ֥ין ל֛וֹ חֵ֥לֶק וְנַֽחֲלָ֖ה אִתְּכֶֽם׃"
          },
          {
            "num": "יג",
            "txt": "הִשָּׁ֣מֶר לְךָ֔ פֶּֽן־תַּעֲלֶ֖ה עֹֽלֹתֶ֑יךָ בְּכָל־מָק֖וֹם אֲשֶׁ֥ר תִּרְאֶֽה׃"
          },
          {
            "num": "יד",
            "txt": "כִּ֣י אִם־בַּמָּק֞וֹם אֲשֶׁר־יִבְחַ֤ר יְהוָה֙ בְּאַחַ֣ד שְׁבָטֶ֔יךָ שָׁ֖ם תַּֽעֲלֶ֣ה עֹֽלֹתֶ֑יךָ וְשָׁ֣ם תַּֽעֲשֶׂ֔ה כֹּ֛ל אֲשֶׁ֥ר אָֽנֹכִ֖י מְצַוֶּֽךָּ׃"
          },
          {
            "num": "טו",
            "txt": "רַק֩ בְּכָל־אַוַּ֨ת נַפְשְׁךָ֜ תִּזְבַּ֣ח ׀ וְאָֽכַלְתָּ֣ בָשָׂ֗ר כְּבִרְכַּ֨ת יְהוָ֧ה אֱלֹהֶ֛יךָ אֲשֶׁ֥ר נָֽתַן־לְךָ֖ בְּכָל־שְׁעָרֶ֑יךָ הַטָּמֵ֤א וְהַטָּהוֹר֙ יֹֽאכְלֶ֔נּוּ כַּצְּבִ֖י וְכָֽאַיָּֽל׃"
          },
          {
            "num": "טז",
            "txt": "רַ֥ק הַדָּ֖ם לֹ֣א תֹאכֵ֑לוּ עַל־הָאָ֥רֶץ תִּשְׁפְּכֶ֖נּוּ כַּמָּֽיִם׃"
          },
          {
            "num": "יז",
            "txt": "לֹֽא־תוּכַ֞ל לֶֽאֱכֹ֣ל בִּשְׁעָרֶ֗יךָ מַעְשַׂ֤ר דְּגָֽנְךָ֙ וְתִֽירֹשְׁךָ֣ וְיִצְהָרֶ֔ךָ וּבְכֹרֹ֥ת בְּקָֽרְךָ֖ וְצֹאנֶ֑ךָ וְכָל־נְדָרֶ֨יךָ֙ אֲשֶׁ֣ר תִּדֹּ֔ר וְנִדְבֹתֶ֖יךָ וּתְרוּמַ֥ת יָדֶֽךָ׃"
          },
          {
            "num": "יח",
            "txt": "כִּ֡י אִם־לִפְנֵי֩ יְהוָ֨ה אֱלֹהֶ֜יךָ תֹּֽאכְלֶ֗נּוּ בַּמָּקוֹם֙ אֲשֶׁ֨ר יִבְחַ֜ר יְהוָ֣ה אֱלֹהֶיךָ֮ בּוֹ֒ אַתָּ֨ה וּבִנְךָ֤ וּבִתֶּ֨ךָ֙ וְעַבְדְּךָ֣ וַֽאֲמָתֶ֔ךָ וְהַלֵּוִ֖י אֲשֶׁ֣ר בִּשְׁעָרֶ֑יךָ וְשָֽׂמַחְתָּ֗ לִפְנֵי֙ יְהוָ֣ה אֱלֹהֶ֔יךָ בְּכֹ֖ל מִשְׁלַ֥ח יָדֶֽךָ׃"
          },
          {
            "num": "יט",
            "txt": "הִשָּׁ֣מֶר לְךָ֔ פֶּֽן־תַּעֲזֹ֖ב אֶת־הַלֵּוִ֑י כָּל־יָמֶ֖יךָ עַל־אַדְמָתֶֽךָ׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "כִּֽי־יַרְחִיב֩ יְהוָ֨ה אֱלֹהֶ֥יךָ אֶֽת־גְּבֻלְךָ֮ כַּֽאֲשֶׁ֣ר דִּבֶּר־לָךְ֒ וְאָֽמַרְתָּ֙ אֹֽכְלָ֣ה בָשָׂ֔ר כִּֽי־תְאַוֶּ֥ה נַפְשְׁךָ֖ לֶֽאֱכֹ֣ל בָּשָׂ֑ר בְּכָל־אַוַּ֥ת נַפְשְׁךָ֖ תֹּאכַ֥ל בָּשָֽׂר׃"
          },
          {
            "num": "כא",
            "txt": "כִּֽי־יִרְחַ֨ק מִמְּךָ֜ הַמָּק֗וֹם אֲשֶׁ֨ר יִבְחַ֜ר יְהוָ֣ה אֱלֹהֶיךָ֮ לָשׂ֣וּם שְׁמ֣וֹ שָׁם֒ וְזָֽבַחְתָּ֞ מִבְּקָֽרְךָ֣ וּמִצֹּֽאנְךָ֗ אֲשֶׁ֨ר נָתַ֤ן יְהוָה֙ לְךָ֔ כַּֽאֲשֶׁ֖ר צִוִּיתִ֑ךָ וְאָֽכַלְתָּ֙ בִּשְׁעָרֶ֔יךָ בְּכֹ֖ל אַוַּ֥ת נַפְשֶֽׁךָ׃"
          },
          {
            "num": "כב",
            "txt": "אַ֗ךְ כַּֽאֲשֶׁ֨ר יֵֽאָכֵ֤ל אֶֽת־הַצְּבִי֙ וְאֶת־הָ֣אַיָּ֔ל כֵּ֖ן תֹּֽאכְלֶ֑נּוּ הַטָּמֵא֙ וְהַטָּה֔וֹר יַחְדָּ֖ו יֹֽאכְלֶֽנּוּ׃"
          },
          {
            "num": "כג",
            "txt": "רַ֣ק חֲזַ֗ק לְבִלְתִּי֙ אֲכֹ֣ל הַדָּ֔ם כִּ֥י הַדָּ֖ם ה֣וּא הַנָּ֑פֶשׁ וְלֹֽא־תֹאכַ֥ל הַנֶּ֖פֶשׁ עִם־הַבָּשָֽׂר׃"
          },
          {
            "num": "כד",
            "txt": "לֹ֖א תֹּֽאכְלֶ֑נּוּ עַל־הָאָ֥רֶץ תִּשְׁפְּכֶ֖נּוּ כַּמָּֽיִם׃"
          },
          {
            "num": "כה",
            "txt": "לֹ֖א תֹּֽאכְלֶ֑נּוּ לְמַ֨עַן יִיטַ֤ב לְךָ֙ וּלְבָנֶ֣יךָ אַֽחֲרֶ֔יךָ כִּֽי־תַעֲשֶׂ֥ה הַיָּשָׁ֖ר בְּעֵינֵ֥י יְהוָֽה׃"
          },
          {
            "num": "כו",
            "txt": "רַ֧ק קָֽדָשֶׁ֛יךָ אֲשֶׁר־יִֽהְי֥וּ לְךָ֖ וּנְדָרֶ֑יךָ תִּשָּׂ֣א וּבָ֔אתָ אֶל־הַמָּק֖וֹם אֲשֶׁר־יִבְחַ֥ר יְהוָֽה׃"
          },
          {
            "num": "כז",
            "txt": "וְעָשִׂ֤יתָ עֹֽלֹתֶ֨יךָ֙ הַבָּשָׂ֣ר וְהַדָּ֔ם עַל־מִזְבַּ֖ח יְהוָ֣ה אֱלֹהֶ֑יךָ וְדַם־זְבָחֶ֗יךָ יִשָּׁפֵךְ֙ עַל־מִזְבַּח֙ יְהוָ֣ה אֱלֹהֶ֔יךָ וְהַבָּשָׂ֖ר תֹּאכֵֽל׃"
          },
          {
            "num": "כח",
            "txt": "שְׁמֹ֣ר וְשָֽׁמַעְתָּ֗ אֵ֚ת כָּל־הַדְּבָרִ֣ים הָאֵ֔לֶּה אֲשֶׁ֥ר אָֽנֹכִ֖י מְצַוֶּ֑ךָּ לְמַעַן֩ יִיטַ֨ב לְךָ֜ וּלְבָנֶ֤יךָ אַֽחֲרֶ֨יךָ֙ עַד־עוֹלָ֔ם כִּ֤י תַֽעֲשֶׂה֙ הַטּ֣וֹב וְהַיָּשָׁ֔ר בְּעֵינֵ֖י יְהוָ֥ה אֱלֹהֶֽיךָ׃ {ס}"
          },
          {
            "num": "כט",
            "txt": "כִּֽי־יַכְרִית֩ יְהוָ֨ה אֱלֹהֶ֜יךָ אֶת־הַגּוֹיִ֗ם אֲשֶׁ֨ר אַתָּ֥ה בָא־שָׁ֛מָּה לָרֶ֥שֶׁת אוֹתָ֖ם מִפָּנֶ֑יךָ וְיָֽרַשְׁתָּ֣ אֹתָ֔ם וְיָֽשַׁבְתָּ֖ בְּאַרְצָֽם׃"
          },
          {
            "num": "ל",
            "txt": "הִשָּׁ֣מֶר לְךָ֗ פֶּן־תִּנָּקֵשׁ֙ אַֽחֲרֵיהֶ֔ם אַֽחֲרֵ֖י הִשָּֽׁמְדָ֣ם מִפָּנֶ֑יךָ וּפֶן־תִּדְרֹ֨שׁ לֵֽאלֹהֵיהֶ֜ם לֵאמֹ֗ר אֵיכָ֨ה יַֽעַבְד֜וּ הַגּוֹיִ֤ם הָאֵ֨לֶּה֙ אֶת־אֱלֹ֣הֵיהֶ֔ם וְאֶֽעֱשֶׂה־כֵּ֖ן גַּם־אָֽנִי׃"
          },
          {
            "num": "לא",
            "txt": "לֹֽא־תַעֲשֶׂ֣ה כֵ֔ן לַֽיהוָ֖ה אֱלֹהֶ֑יךָ כִּי֩ כָל־תּֽוֹעֲבַ֨ת יְהוָ֜ה אֲשֶׁ֣ר שָׂנֵ֗א עָשׂוּ֙ לֵאלֹ֣הֵיהֶ֔ם כִּ֣י גַ֤ם אֶת־בְּנֵיהֶם֙ וְאֶת־בְּנֹ֣תֵיהֶ֔ם יִשְׂרְפ֥וּ בָאֵ֖שׁ לֵֽאלֹהֵיהֶֽם׃"
          }
        ]
      },
      {
        "num": "יג",
        "verses": [
          {
            "num": "א",
            "txt": "אֵ֣ת כָּל־הַדָּבָ֗ר אֲשֶׁ֤ר אָֽנֹכִי֙ מְצַוֶּ֣ה אֶתְכֶ֔ם אֹת֥וֹ תִשְׁמְר֖וּ לַֽעֲשׂ֑וֹת לֹֽא־תֹסֵ֣ף עָלָ֔יו וְלֹ֥א תִגְרַ֖ע מִמֶּֽנּוּ׃ {פ}"
          },
          {
            "num": "ב",
            "txt": "כִּֽי־יָק֤וּם בְּקִרְבְּךָ֙ נָבִ֔יא א֖וֹ חֹלֵ֣ם חֲל֑וֹם וְנָתַ֥ן אֵלֶ֛יךָ א֖וֹת א֥וֹ מוֹפֵֽת׃"
          },
          {
            "num": "ג",
            "txt": "וּבָ֤א הָאוֹת֙ וְהַמּוֹפֵ֔ת אֲשֶׁר־דִּבֶּ֥ר אֵלֶ֖יךָ לֵאמֹ֑ר נֵֽלְכָ֞ה אַֽחֲרֵ֨י אֱלֹהִ֧ים אֲחֵרִ֛ים אֲשֶׁ֥ר לֹֽא־יְדַעְתָּ֖ם וְנָֽעָבְדֵֽם׃"
          },
          {
            "num": "ד",
            "txt": "לֹ֣א תִשְׁמַ֗ע אֶל־דִּבְרֵי֙ הַנָּבִ֣יא הַה֔וּא א֛וֹ אֶל־חוֹלֵ֥ם הַֽחֲל֖וֹם הַה֑וּא כִּ֣י מְנַסֶּ֞ה יְהוָ֤ה אֱלֹֽהֵיכֶם֙ אֶתְכֶ֔ם לָדַ֗עַת הֲיִשְׁכֶ֤ם אֹֽהֲבִים֙ אֶת־יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם בְּכָל־לְבַבְכֶ֖ם וּבְכָל־נַפְשְׁכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "אַֽחֲרֵ֨י יְהוָ֧ה אֱלֹֽהֵיכֶ֛ם תֵּלֵ֖כוּ וְאֹת֣וֹ תִירָ֑אוּ וְאֶת־מִצְוֺתָ֤יו תִּשְׁמֹ֨רוּ֙ וּבְקֹל֣וֹ תִשְׁמָ֔עוּ וְאֹת֥וֹ תַֽעֲבֹ֖דוּ וּב֥וֹ תִדְבָּקֽוּן׃"
          },
          {
            "num": "ו",
            "txt": "וְהַנָּבִ֣יא הַה֡וּא א֣וֹ חֹלֵם֩ הַֽחֲל֨וֹם הַה֜וּא יוּמָ֗ת כִּ֣י דִבֶּר־סָ֠רָה עַל־יְהוָ֨ה אֱלֹֽהֵיכֶ֜ם הַמּוֹצִ֥יא אֶתְכֶ֣ם ׀ מֵאֶ֣רֶץ מִצְרַ֗יִם וְהַפֹּֽדְךָ֙ מִבֵּ֣ית עֲבָדִ֔ים לְהַדִּֽיחֲךָ֙ מִן־הַדֶּ֔רֶךְ אֲשֶׁ֧ר צִוְּךָ֛ יְהוָ֥ה אֱלֹהֶ֖יךָ לָלֶ֣כֶת בָּ֑הּ וּבִֽעַרְתָּ֥ הָרָ֖ע מִקִּרְבֶּֽךָ׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "כִּ֣י יְסִֽיתְךָ֡ אָחִ֣יךָ בֶן־אִ֠מֶּךָ אֽוֹ־בִנְךָ֨ אֽוֹ־בִתְּךָ֜ א֣וֹ ׀ אֵ֣שֶׁת חֵיקֶ֗ךָ א֧וֹ רֵֽעֲךָ֛ אֲשֶׁ֥ר כְּנַפְשְׁךָ֖ בַּסֵּ֣תֶר לֵאמֹ֑ר נֵֽלְכָ֗ה וְנַֽעַבְדָה֙ אֱלֹהִ֣ים אֲחֵרִ֔ים אֲשֶׁר֙ לֹ֣א יָדַ֔עְתָּ אַתָּ֖ה וַֽאֲבֹתֶֽיךָ׃"
          },
          {
            "num": "ח",
            "txt": "מֵֽאֱלֹהֵ֣י הָֽעַמִּ֗ים אֲשֶׁר֙ סְבִיבֹ֣תֵיכֶ֔ם הַקְּרֹבִ֣ים אֵלֶ֔יךָ א֖וֹ הָֽרְחֹקִ֣ים מִמֶּ֑ךָּ מִקְצֵ֥ה הָאָ֖רֶץ וְעַד־קְצֵ֥ה הָאָֽרֶץ׃"
          },
          {
            "num": "ט",
            "txt": "לֹֽא־תֹאבֶ֣ה ל֔וֹ וְלֹ֥א תִשְׁמַ֖ע אֵלָ֑יו וְלֹֽא־תָח֤וֹס עֵֽינְךָ֙ עָלָ֔יו וְלֹֽא־תַחְמֹ֥ל וְלֹֽא־תְכַסֶּ֖ה עָלָֽיו׃"
          },
          {
            "num": "י",
            "txt": "כִּ֤י הָרֹג֙ תַּֽהַרְגֶ֔נּוּ יָ֥דְךָ֛ תִּֽהְיֶה־בּ֥וֹ בָרִֽאשׁוֹנָ֖ה לַֽהֲמִית֑וֹ וְיַ֥ד כָּל־הָעָ֖ם בָּאַֽחֲרֹנָֽה׃"
          },
          {
            "num": "יא",
            "txt": "וּסְקַלְתּ֥וֹ בָֽאֲבָנִ֖ים וָמֵ֑ת כִּ֣י בִקֵּ֗שׁ לְהַדִּֽיחֲךָ֙ מֵעַל֙ יְהוָ֣ה אֱלֹהֶ֔יךָ הַמּוֹצִֽיאֲךָ֛ מֵאֶ֥רֶץ מִצְרַ֖יִם מִבֵּ֥ית עֲבָדִֽים׃"
          },
          {
            "num": "יב",
            "txt": "וְכָ֨ל־יִשְׂרָאֵ֔ל יִשְׁמְע֖וּ וְיִֽרָא֑וּן וְלֹֽא־יוֹסִ֣פוּ לַֽעֲשׂ֗וֹת כַּדָּבָ֥ר הָרָ֛ע הַזֶּ֖ה בְּקִרְבֶּֽךָ׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "כִּֽי־תִשְׁמַ֞ע בְּאַחַ֣ת עָרֶ֗יךָ אֲשֶׁר֩ יְהוָ֨ה אֱלֹהֶ֜יךָ נֹתֵ֥ן לְךָ֛ לָשֶׁ֥בֶת שָׁ֖ם לֵאמֹֽר׃"
          },
          {
            "num": "יד",
            "txt": "יָֽצְא֞וּ אֲנָשִׁ֤ים בְּנֵֽי־בְלִיַּ֨עַל֙ מִקִּרְבֶּ֔ךָ וַיַּדִּ֛יחוּ אֶת־יֹֽשְׁבֵ֥י עִירָ֖ם לֵאמֹ֑ר נֵֽלְכָ֗ה וְנַֽעַבְדָ֛ה אֱלֹהִ֥ים אֲחֵרִ֖ים אֲשֶׁ֥ר לֹֽא־יְדַעְתֶּֽם׃"
          },
          {
            "num": "טו",
            "txt": "וְדָֽרַשְׁתָּ֧ וְחָֽקַרְתָּ֛ וְשָֽׁאַלְתָּ֖ הֵיטֵ֑ב וְהִנֵּ֤ה אֱמֶת֙ נָכ֣וֹן הַדָּבָ֔ר נֶֽעֶשְׂתָ֛ה הַתּֽוֹעֵבָ֥ה הַזֹּ֖את בְּקִרְבֶּֽךָ׃"
          },
          {
            "num": "טז",
            "txt": "הַכֵּ֣ה תַכֶּ֗ה אֶת־יֹ֥שְׁבֵ֛י הָעִ֥יר הַהִ֖וא לְפִי־חָ֑רֶב הַֽחֲרֵ֨ם אֹתָ֧הּ וְאֶת־כָּל־אֲשֶׁר־בָּ֛הּ וְאֶת־בְּהֶמְתָּ֖הּ לְפִי־חָֽרֶב׃"
          },
          {
            "num": "יז",
            "txt": "וְאֶת־כָּל־שְׁלָלָ֗הּ תִּקְבֹּץ֮ אֶל־תּ֣וֹךְ רְחֹבָהּ֒ וְשָֽׂרַפְתָּ֨ בָאֵ֜שׁ אֶת־הָעִ֤יר וְאֶת־כָּל־שְׁלָלָהּ֙ כָּלִ֔יל לַֽיהוָ֖ה אֱלֹהֶ֑יךָ וְהָֽיְתָה֙ תֵּ֣ל עוֹלָ֔ם לֹ֥א תִבָּנֶ֖ה עֽוֹד׃"
          },
          {
            "num": "יח",
            "txt": "וְלֹֽא־יִדְבַּ֧ק בְּיָֽדְךָ֛ מְא֖וּמָה מִן־הַחֵ֑רֶם לְמַעַן֩ יָשׁ֨וּב יְהוָ֜ה מֵֽחֲר֣וֹן אַפּ֗וֹ וְנָֽתַן־לְךָ֤ רַֽחֲמִים֙ וְרִֽחַמְךָ֣ וְהִרְבֶּ֔ךָ כַּֽאֲשֶׁ֥ר נִשְׁבַּ֖ע לַֽאֲבֹתֶֽיךָ׃"
          },
          {
            "num": "יט",
            "txt": "כִּ֣י תִשְׁמַ֗ע בְּקוֹל֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לִשְׁמֹר֙ אֶת־כָּל־מִצְוֺתָ֔יו אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּ֑וֹם לַֽעֲשׂוֹת֙ הַיָּשָׁ֔ר בְּעֵינֵ֖י יְהוָ֥ה אֱלֹהֶֽיךָ׃ {ס}"
          }
        ]
      },
      {
        "num": "יד",
        "verses": [
          {
            "num": "א",
            "txt": "בָּנִ֣ים אַתֶּ֔ם לַֽיהוָ֖ה אֱלֹֽהֵיכֶ֑ם לֹ֣א תִתְגֹּֽדְד֗וּ וְלֹֽא־תָשִׂ֧ימוּ קָרְחָ֛ה בֵּ֥ין עֵֽינֵיכֶ֖ם לָמֵֽת׃"
          },
          {
            "num": "ב",
            "txt": "כִּ֣י עַ֤ם קָדוֹשׁ֙ אַתָּ֔ה לַֽיהוָ֖ה אֱלֹהֶ֑יךָ וּבְךָ֞ בָּחַ֣ר יְהוָ֗ה לִֽהְי֥וֹת לוֹ֙ לְעַ֣ם סְגֻלָּ֔ה מִכֹּל֙ הָֽעַמִּ֔ים אֲשֶׁ֖ר עַל־פְּנֵ֥י הָֽאֲדָמָֽה׃ {ס}"
          },
          {
            "num": "ג",
            "txt": "לֹ֥א תֹאכַ֖ל כָּל־תּֽוֹעֵבָֽה׃"
          },
          {
            "num": "ד",
            "txt": "זֹ֥את הַבְּהֵמָ֖ה אֲשֶׁ֣ר תֹּאכֵ֑לוּ שׁ֕וֹר שֵׂ֥ה כְשָׂבִ֖ים וְשֵׂ֥ה עִזִּֽים׃"
          },
          {
            "num": "ה",
            "txt": "אַיָּ֥ל וּצְבִ֖י וְיַחְמ֑וּר וְאַקּ֥וֹ וְדִישֹׁ֖ן וּתְא֥וֹ וָזָֽמֶר׃"
          },
          {
            "num": "ו",
            "txt": "וְכָל־בְּהֵמָ֞ה מַפְרֶ֣סֶת פַּרְסָ֗ה וְשֹׁסַ֤עַת שֶׁ֨סַע֙ שְׁתֵּ֣י פְרָס֔וֹת מַֽעֲלַ֥ת גֵּרָ֖ה בַּבְּהֵמָ֑ה אֹתָ֖הּ תֹּאכֵֽלוּ׃"
          },
          {
            "num": "ז",
            "txt": "אַ֣ךְ אֶת־זֶ֞ה לֹ֤א תֹֽאכְלוּ֙ מִמַּֽעֲלֵ֣י הַגֵּרָ֔ה וּמִמַּפְרִיסֵ֥י הַפַּרְסָ֖ה הַשְּׁסוּעָ֑ה אֶֽת־הַ֠גָּמָל וְאֶת־הָֽאַרְנֶ֨בֶת וְאֶת־הַשָּׁפָ֜ן כִּֽי־מַעֲלֵ֧ה גֵרָ֣ה הֵ֗מָּה וּפַרְסָה֙ לֹ֣א הִפְרִ֔יסוּ טְמֵאִ֥ים הֵ֖ם לָכֶֽם׃"
          },
          {
            "num": "ח",
            "txt": "וְאֶת־הַֽ֠חֲזִיר כִּֽי־מַפְרִ֨יס פַּרְסָ֥ה הוּא֙ וְלֹ֣א גֵרָ֔ה טָמֵ֥א ה֖וּא לָכֶ֑ם מִבְּשָׂרָם֙ לֹ֣א תֹאכֵ֔לוּ וּבְנִבְלָתָ֖ם לֹ֥א תִגָּֽעוּ׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "אֶת־זֶה֙ תֹּֽאכְל֔וּ מִכֹּ֖ל אֲשֶׁ֣ר בַּמָּ֑יִם כֹּ֧ל אֲשֶׁר־ל֛וֹ סְנַפִּ֥יר וְקַשְׂקֶ֖שֶׂת תֹּאכֵֽלוּ׃"
          },
          {
            "num": "י",
            "txt": "וְכֹ֨ל אֲשֶׁ֧ר אֵֽין־ל֛וֹ סְנַפִּ֥יר וְקַשְׂקֶ֖שֶׂת לֹ֣א תֹאכֵ֑לוּ טָמֵ֥א ה֖וּא לָכֶֽם׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "כָּל־צִפּ֥וֹר טְהֹרָ֖ה תֹּאכֵֽלוּ׃"
          },
          {
            "num": "יב",
            "txt": "וְזֶ֕ה אֲשֶׁ֥ר לֹֽא־תֹאכְל֖וּ מֵהֶ֑ם הַנֶּ֥שֶׁר וְהַפֶּ֖רֶס וְהָֽעָזְנִיָּֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְהָֽרָאָה֙ וְאֶת־הָ֣אַיָּ֔ה וְהַדַּיָּ֖ה לְמִינָֽהּ׃"
          },
          {
            "num": "יד",
            "txt": "וְאֵ֥ת כָּל־עֹרֵ֖ב לְמִינֽוֹ׃"
          },
          {
            "num": "טו",
            "txt": "וְאֵת֙ בַּ֣ת הַֽיַּעֲנָ֔ה וְאֶת־הַתַּחְמָ֖ס וְאֶת־הַשָּׁ֑חַף וְאֶת־הַנֵּ֖ץ לְמִינֵֽהוּ׃"
          },
          {
            "num": "טז",
            "txt": "אֶת־הַכּ֥וֹס וְאֶת־הַיַּנְשׁ֖וּף וְהַתִּנְשָֽׁמֶת׃"
          },
          {
            "num": "יז",
            "txt": "וְהַקָּאָ֥ת וְאֶֽת־הָרָחָ֖מָה וְאֶת־הַשָּׁלָֽךְ׃"
          },
          {
            "num": "יח",
            "txt": "וְהַ֣חֲסִידָ֔ה וְהָֽאֲנָפָ֖ה לְמִינָ֑הּ וְהַדּֽוּכִיפַ֖ת וְהָֽעֲטַלֵּֽף׃"
          },
          {
            "num": "יט",
            "txt": "וְכֹל֙ שֶׁ֣רֶץ הָע֔וֹף טָמֵ֥א ה֖וּא לָכֶ֑ם לֹ֖א יֵֽאָכֵֽלוּ׃"
          },
          {
            "num": "כ",
            "txt": "כָּל־ע֥וֹף טָה֖וֹר תֹּאכֵֽלוּ׃"
          },
          {
            "num": "כא",
            "txt": "לֹ֣א תֹֽאכְל֣וּ כָל־נְ֠בֵלָה לַגֵּ֨ר אֲשֶׁר־בִּשְׁעָרֶ֜יךָ תִּתְּנֶ֣נָּה וַֽאֲכָלָ֗הּ א֤וֹ מָכֹר֙ לְנָכְרִ֔י כִּ֣י עַ֤ם קָדוֹשׁ֙ אַתָּ֔ה לַֽיהוָ֖ה אֱלֹהֶ֑יךָ לֹֽא־תְבַשֵּׁ֥ל גְּדִ֖י בַּֽחֲלֵ֥ב אִמּֽוֹ׃ {פ}"
          },
          {
            "num": "כב",
            "txt": "עַשֵּׂ֣ר תְּעַשֵּׂ֔ר אֵ֖ת כָּל־תְּבוּאַ֣ת זַרְעֶ֑ךָ הַיֹּצֵ֥א הַשָּׂדֶ֖ה שָׁנָ֥ה שָׁנָֽה׃"
          },
          {
            "num": "כג",
            "txt": "וְאָֽכַלְתָּ֞ לִפְנֵ֣י ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ בַּמָּק֣וֹם אֲשֶׁר־יִבְחַר֮ לְשַׁכֵּ֣ן שְׁמ֣וֹ שָׁם֒ מַעְשַׂ֤ר דְּגָֽנְךָ֙ תִּֽירֹשְׁךָ֣ וְיִצְהָרֶ֔ךָ וּבְכֹרֹ֥ת בְּקָֽרְךָ֖ וְצֹאנֶ֑ךָ לְמַ֣עַן תִּלְמַ֗ד לְיִרְאָ֛ה אֶת־יְהוָ֥ה אֱלֹהֶ֖יךָ כָּל־הַיָּמִֽים׃"
          },
          {
            "num": "כד",
            "txt": "וְכִֽי־יִרְבֶּ֨ה מִמְּךָ֜ הַדֶּ֗רֶךְ כִּ֣י לֹ֣א תוּכַל֮ שְׂאֵתוֹ֒ כִּֽי־יִרְחַ֤ק מִמְּךָ֙ הַמָּק֔וֹם אֲשֶׁ֤ר יִבְחַר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לָשׂ֥וּם שְׁמ֖וֹ שָׁ֑ם כִּ֥י יְבָֽרֶכְךָ֖ יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "כה",
            "txt": "וְנָֽתַתָּ֖ה בַּכָּ֑סֶף וְצַרְתָּ֤ הַכֶּ֨סֶף֙ בְּיָ֣דְךָ֔ וְהָֽלַכְתָּ֙ אֶל־הַמָּק֔וֹם אֲשֶׁ֥ר יִבְחַ֛ר יְהוָ֥ה אֱלֹהֶ֖יךָ בּֽוֹ׃"
          },
          {
            "num": "כו",
            "txt": "וְנָֽתַתָּ֣ה הַכֶּ֡סֶף בְּכֹל֩ אֲשֶׁר־תְּאַוֶּ֨ה נַפְשְׁךָ֜ בַּבָּקָ֣ר וּבַצֹּ֗אן וּבַיַּ֨יִן֙ וּבַשֵּׁכָ֔ר וּבְכֹ֛ל אֲשֶׁ֥ר תִּֽשְׁאָלְךָ֖ נַפְשֶׁ֑ךָ וְאָכַ֣לְתָּ שָּׁ֗ם לִפְנֵי֙ יְהוָ֣ה אֱלֹהֶ֔יךָ וְשָֽׂמַחְתָּ֖ אַתָּ֥ה וּבֵיתֶֽךָ׃"
          },
          {
            "num": "כז",
            "txt": "וְהַלֵּוִ֥י אֲשֶׁר־בִּשְׁעָרֶ֖יךָ לֹ֣א תַֽעַזְבֶ֑נּוּ כִּ֣י אֵ֥ין ל֛וֹ חֵ֥לֶק וְנַֽחֲלָ֖ה עִמָּֽךְ׃ {ס}"
          },
          {
            "num": "כח",
            "txt": "מִקְצֵ֣ה ׀ שָׁלֹ֣שׁ שָׁנִ֗ים תּוֹצִיא֙ אֶת־כָּל־מַעְשַׂר֙ תְּבוּאָ֣תְךָ֔ בַּשָּׁנָ֖ה הַהִ֑וא וְהִנַּחְתָּ֖ בִּשְׁעָרֶֽיךָ׃"
          },
          {
            "num": "כט",
            "txt": "וּבָ֣א הַלֵּוִ֡י כִּ֣י אֵֽין־לוֹ֩ חֵ֨לֶק וְנַֽחֲלָ֜ה עִמָּ֗ךְ וְ֠הַגֵּר וְהַיָּת֤וֹם וְהָֽאַלְמָנָה֙ אֲשֶׁ֣ר בִּשְׁעָרֶ֔יךָ וְאָֽכְל֖וּ וְשָׂבֵ֑עוּ לְמַ֤עַן יְבָֽרֶכְךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ בְּכָל־מַֽעֲשֵׂ֥ה יָֽדְךָ֖ אֲשֶׁ֥ר תַּֽעֲשֶֽׂה׃ {ס}"
          }
        ]
      },
      {
        "num": "טו",
        "verses": [
          {
            "num": "א",
            "txt": "מִקֵּ֥ץ שֶֽׁבַע־שָׁנִ֖ים תַּֽעֲשֶׂ֥ה שְׁמִטָּֽה׃"
          },
          {
            "num": "ב",
            "txt": "וְזֶה֮ דְּבַ֣ר הַשְּׁמִטָּה֒ שָׁמ֗וֹט כָּל־בַּ֨עַל֙ מַשֵּׁ֣ה יָד֔וֹ אֲשֶׁ֥ר יַשֶּׁ֖ה בְּרֵעֵ֑הוּ לֹֽא־יִגֹּ֤שׂ אֶת־רֵעֵ֨הוּ֙ וְאֶת־אָחִ֔יו כִּֽי־קָרָ֥א שְׁמִטָּ֖ה לַֽיהוָֽה׃"
          },
          {
            "num": "ג",
            "txt": "אֶת־הַנָּכְרִ֖י תִּגֹּ֑שׂ וַֽאֲשֶׁ֨ר יִֽהְיֶ֥ה לְךָ֛ אֶת־אָחִ֖יךָ תַּשְׁמֵ֥ט יָדֶֽךָ׃"
          },
          {
            "num": "ד",
            "txt": "אֶ֕פֶס כִּ֛י לֹ֥א יִֽהְיֶה־בְּךָ֖ אֶבְי֑וֹן כִּֽי־בָרֵ֤ךְ יְבָֽרֶכְךָ֙ יְהוָ֔ה בָּאָ֕רֶץ אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹֽתֵן־לְךָ֥ נַֽחֲלָ֖ה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "ה",
            "txt": "רַ֚ק אִם־שָׁמ֣וֹעַ תִּשְׁמַ֔ע בְּק֖וֹל יְהוָ֣ה אֱלֹהֶ֑יךָ לִשְׁמֹ֤ר לַֽעֲשׂוֹת֙ אֶת־כָּל־הַמִּצְוָ֣ה הַזֹּ֔את אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּֽוֹם׃"
          },
          {
            "num": "ו",
            "txt": "כִּֽי־יְהוָ֤ה אֱלֹהֶ֨יךָ֙ בֵּֽרַכְךָ֔ כַּֽאֲשֶׁ֖ר דִּבֶּר־לָ֑ךְ וְהַֽעֲבַטְתָּ֞ גּוֹיִ֣ם רַבִּ֗ים וְאַתָּה֙ לֹ֣א תַֽעֲבֹ֔ט וּמָֽשַׁלְתָּ֙ בְּגוֹיִ֣ם רַבִּ֔ים וּבְךָ֖ לֹ֥א יִמְשֹֽׁלוּ׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "כִּֽי־יִהְיֶה֩ בְךָ֨ אֶבְי֜וֹן מֵֽאַחַ֤ד אַחֶ֨יךָ֙ בְּאַחַ֣ד שְׁעָרֶ֔יךָ בְּאַ֨רְצְךָ֔ אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֣ן לָ֑ךְ לֹ֧א תְאַמֵּ֣ץ אֶת־לְבָֽבְךָ֗ וְלֹ֤א תִקְפֹּץ֙ אֶת־יָ֣דְךָ֔ מֵֽאָחִ֖יךָ הָֽאֶבְיֽוֹן׃"
          },
          {
            "num": "ח",
            "txt": "כִּֽי־פָתֹ֧חַ תִּפְתַּ֛ח אֶת־יָֽדְךָ֖ ל֑וֹ וְהַֽעֲבֵט֙ תַּֽעֲבִיטֶ֔נּוּ דֵּ֚י מַחְסֹר֔וֹ אֲשֶׁ֥ר יֶחְסַ֖ר לֽוֹ׃"
          },
          {
            "num": "ט",
            "txt": "הִשָּׁ֣מֶר לְךָ֡ פֶּן־יִֽהְיֶ֣ה דָבָר֩ עִם־לְבָֽבְךָ֨ בְלִיַּ֜עַל לֵאמֹ֗ר קָֽרְבָ֣ה שְׁנַֽת־הַשֶּׁבַע֮ שְׁנַ֣ת הַשְּׁמִטָּה֒ וְרָעָ֣ה עֵֽינְךָ֗ בְּאָחִ֨יךָ֙ הָֽאֶבְי֔וֹן וְלֹ֥א תִתֵּ֖ן ל֑וֹ וְקָרָ֤א עָלֶ֨יךָ֙ אֶל־יְהוָ֔ה וְהָיָ֥ה בְךָ֖ חֵֽטְא׃"
          },
          {
            "num": "י",
            "txt": "נָת֤וֹן תִּתֵּן֙ ל֔וֹ וְלֹֽא־יֵרַ֥ע לְבָֽבְךָ֖ בְּתִתְּךָ֣ ל֑וֹ כִּ֞י בִּגְלַ֣ל ׀ הַדָּבָ֣ר הַזֶּ֗ה יְבָֽרֶכְךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ בְּכָֽל־מַעֲשֶׂ֔ךָ וּבְכֹ֖ל מִשְׁלַ֥ח יָדֶֽךָ׃"
          },
          {
            "num": "יא",
            "txt": "כִּ֛י לֹֽא־יֶחְדַּ֥ל אֶבְי֖וֹן מִקֶּ֣רֶב הָאָ֑רֶץ עַל־כֵּ֞ן אָֽנֹכִ֤י מְצַוְּךָ֙ לֵאמֹ֔ר פָּ֠תֹחַ תִּפְתַּ֨ח אֶת־יָֽדְךָ֜ לְאָחִ֧יךָ לַֽעֲנִיֶּ֛ךָ וּלְאֶבְיֹֽנְךָ֖ בְּאַרְצֶֽךָ׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "כִּֽי־יִמָּכֵ֨ר לְךָ֜ אָחִ֣יךָ הָֽעִבְרִ֗י א֚וֹ הָֽעִבְרִיָּ֔ה וַעֲבָֽדְךָ֖ שֵׁ֣שׁ שָׁנִ֑ים וּבַשָּׁנָה֙ הַשְּׁבִיעִ֔ת תְּשַׁלְּחֶ֥נּוּ חָפְשִׁ֖י מֵֽעִמָּֽךְ׃"
          },
          {
            "num": "יג",
            "txt": "וְכִֽי־תְשַׁלְּחֶ֥נּוּ חָפְשִׁ֖י מֵֽעִמָּ֑ךְ לֹ֥א תְשַׁלְּחֶ֖נּוּ רֵיקָֽם׃"
          },
          {
            "num": "יד",
            "txt": "הַֽעֲנֵ֤יק תַּֽעֲנִיק֙ ל֔וֹ מִצֹּ֣אנְךָ֔ וּמִֽגָּרְנְךָ֖ וּמִיִּקְבֶ֑ךָ אֲשֶׁ֧ר בֵּֽרַכְךָ֛ יְהוָ֥ה אֱלֹהֶ֖יךָ תִּתֶּן־לֽוֹ׃"
          },
          {
            "num": "טו",
            "txt": "וְזָֽכַרְתָּ֗ כִּ֣י עֶ֤בֶד הָיִ֨יתָ֙ בְּאֶ֣רֶץ מִצְרַ֔יִם וַֽיִּפְדְּךָ֖ יְהוָ֣ה אֱלֹהֶ֑יךָ עַל־כֵּ֞ן אָֽנֹכִ֧י מְצַוְּךָ֛ אֶת־הַדָּבָ֥ר הַזֶּ֖ה הַיּֽוֹם׃"
          },
          {
            "num": "טז",
            "txt": "וְהָיָה֙ כִּֽי־יֹאמַ֣ר אֵלֶ֔יךָ לֹ֥א אֵצֵ֖א מֵֽעִמָּ֑ךְ כִּ֤י אֲהֵֽבְךָ֙ וְאֶת־בֵּיתֶ֔ךָ כִּי־ט֥וֹב ל֖וֹ עִמָּֽךְ׃"
          },
          {
            "num": "יז",
            "txt": "וְלָֽקַחְתָּ֣ אֶת־הַמַּרְצֵ֗עַ וְנָֽתַתָּ֤ה בְאָזְנוֹ֙ וּבַדֶּ֔לֶת וְהָיָ֥ה לְךָ֖ עֶ֣בֶד עוֹלָ֑ם וְאַ֥ף לַאֲמָֽתְךָ֖ תַּֽעֲשֶׂה־כֵּֽן׃"
          },
          {
            "num": "יח",
            "txt": "לֹֽא־יִקְשֶׁ֣ה בְעֵינֶ֗ךָ בְּשַׁלֵּֽחֲךָ֨ אֹת֤וֹ חָפְשִׁי֙ מֵֽעִמָּ֔ךְ כִּ֗י מִשְׁנֶה֙ שְׂכַ֣ר שָׂכִ֔יר עֲבָֽדְךָ֖ שֵׁ֣שׁ שָׁנִ֑ים וּבֵֽרַכְךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ בְּכֹ֖ל אֲשֶׁ֥ר תַּֽעֲשֶֽׂה׃ {פ}"
          },
          {
            "num": "יט",
            "txt": "כָּֽל־הַבְּכ֡וֹר אֲשֶׁר֩ יִוָּלֵ֨ד בִּבְקָֽרְךָ֤ וּבְצֹֽאנְךָ֙ הַזָּכָ֔ר תַּקְדִּ֖ישׁ לַֽיהוָ֣ה אֱלֹהֶ֑יךָ לֹ֤א תַֽעֲבֹד֙ בִּבְכֹ֣ר שׁוֹרֶ֔ךָ וְלֹ֥א תָגֹ֖ז בְּכ֥וֹר צֹאנֶֽךָ׃"
          },
          {
            "num": "כ",
            "txt": "לִפְנֵי֩ יְהוָ֨ה אֱלֹהֶ֤יךָ תֹֽאכְלֶ֨נּוּ֙ שָׁנָ֣ה בְשָׁנָ֔ה בַּמָּק֖וֹם אֲשֶׁר־יִבְחַ֣ר יְהוָ֑ה אַתָּ֖ה וּבֵיתֶֽךָ׃"
          },
          {
            "num": "כא",
            "txt": "וְכִֽי־יִהְיֶ֨ה ב֜וֹ מ֗וּם פִּסֵּ֨חַ֙ א֣וֹ עִוֵּ֔ר כֹּ֖ל מ֣וּם רָ֑ע לֹ֣א תִזְבָּחֶ֔נּוּ לַֽיהוָ֖ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "כב",
            "txt": "בִּשְׁעָרֶ֖יךָ תֹּֽאכְלֶ֑נּוּ הַטָּמֵ֤א וְהַטָּהוֹר֙ יַחְדָּ֔ו כַּצְּבִ֖י וְכָֽאַיָּֽל׃"
          },
          {
            "num": "כג",
            "txt": "רַ֥ק אֶת־דָּמ֖וֹ לֹ֣א תֹאכֵ֑ל עַל־הָאָ֥רֶץ תִּשְׁפְּכֶ֖נּוּ כַּמָּֽיִם׃ {פ}"
          }
        ]
      },
      {
        "num": "טז",
        "verses": [
          {
            "num": "א",
            "txt": "שָׁמוֹר֙ אֶת־חֹ֣דֶשׁ הָֽאָבִ֔יב וְעָשִׂ֣יתָ פֶּ֔סַח לַֽיהוָ֖ה אֱלֹהֶ֑יךָ כִּ֞י בְּחֹ֣דֶשׁ הָֽאָבִ֗יב הוֹצִ֨יאֲךָ֜ יְהוָ֧ה אֱלֹהֶ֛יךָ מִמִּצְרַ֖יִם לָֽיְלָה׃"
          },
          {
            "num": "ב",
            "txt": "וְזָבַ֥חְתָּ פֶּ֛סַח לַֽיהוָ֥ה אֱלֹהֶ֖יךָ צֹ֣אן וּבָקָ֑ר בַּמָּקוֹם֙ אֲשֶׁר־יִבְחַ֣ר יְהוָ֔ה לְשַׁכֵּ֥ן שְׁמ֖וֹ שָֽׁם׃"
          },
          {
            "num": "ג",
            "txt": "לֹֽא־תֹאכַ֤ל עָלָיו֙ חָמֵ֔ץ שִׁבְעַ֥ת יָמִ֛ים תֹּֽאכַל־עָלָ֥יו מַצּ֖וֹת לֶ֣חֶם עֹ֑נִי כִּ֣י בְחִפָּז֗וֹן יָצָ֨אתָ֙ מֵאֶ֣רֶץ מִצְרַ֔יִם לְמַ֣עַן תִּזְכֹּ֗ר אֶת־י֤וֹם צֵֽאתְךָ֙ מֵאֶ֣רֶץ מִצְרַ֔יִם כֹּ֖ל יְמֵ֥י חַיֶּֽיךָ׃"
          },
          {
            "num": "ד",
            "txt": "וְלֹֽא־יֵרָאֶ֨ה לְךָ֥ שְׂאֹ֛ר בְּכָל־גְּבֻֽלְךָ֖ שִׁבְעַ֣ת יָמִ֑ים וְלֹֽא־יָלִ֣ין מִן־הַבָּשָׂ֗ר אֲשֶׁ֨ר תִּזְבַּ֥ח בָּעֶ֛רֶב בַּיּ֥וֹם הָֽרִאשׁ֖וֹן לַבֹּֽקֶר׃"
          },
          {
            "num": "ה",
            "txt": "לֹ֥א תוּכַ֖ל לִזְבֹּ֣חַ אֶת־הַפָּ֑סַח בְּאַחַ֣ד שְׁעָרֶ֔יךָ אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֥ן לָֽךְ׃"
          },
          {
            "num": "ו",
            "txt": "כִּ֠י אִֽם־אֶל־הַמָּק֞וֹם אֲשֶׁר־יִבְחַ֨ר יְהוָ֤ה אֱלֹהֶ֨יךָ֙ לְשַׁכֵּ֣ן שְׁמ֔וֹ שָׁ֛ם תִּזְבַּ֥ח אֶת־הַפֶּ֖סַח בָּעָ֑רֶב כְּב֣וֹא הַשֶּׁ֔מֶשׁ מוֹעֵ֖ד צֵֽאתְךָ֥ מִמִּצְרָֽיִם׃"
          },
          {
            "num": "ז",
            "txt": "וּבִשַּׁלְתָּ֙ וְאָ֣כַלְתָּ֔ בַּמָּק֕וֹם אֲשֶׁ֥ר יִבְחַ֛ר יְהוָ֥ה אֱלֹהֶ֖יךָ בּ֑וֹ וּפָנִ֣יתָ בַבֹּ֔קֶר וְהָֽלַכְתָּ֖ לְאֹֽהָלֶֽיךָ׃"
          },
          {
            "num": "ח",
            "txt": "שֵׁ֥שֶׁת יָמִ֖ים תֹּאכַ֣ל מַצּ֑וֹת וּבַיּ֣וֹם הַשְּׁבִיעִ֗י עֲצֶ֨רֶת֙ לַֽיהוָ֣ה אֱלֹהֶ֔יךָ לֹ֥א תַֽעֲשֶׂ֖ה מְלָאכָֽה׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "שִׁבְעָ֥ה שָֽׁבֻעֹ֖ת תִּסְפָּר־לָ֑ךְ מֵֽהָחֵ֤ל חֶרְמֵשׁ֙ בַּקָּמָ֔ה תָּחֵ֣ל לִסְפֹּ֔ר שִׁבְעָ֖ה שָֽׁבֻעֽוֹת׃"
          },
          {
            "num": "י",
            "txt": "וְעָשִׂ֜יתָ חַ֤ג שָֽׁבֻעוֹת֙ לַֽיהוָ֣ה אֱלֹהֶ֔יךָ מִסַּ֛ת נִדְבַ֥ת יָֽדְךָ֖ אֲשֶׁ֣ר תִּתֵּ֑ן כַּֽאֲשֶׁ֥ר יְבָֽרֶכְךָ֖ יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "יא",
            "txt": "וְשָֽׂמַחְתָּ֞ לִפְנֵ֣י ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ אַתָּ֨ה וּבִנְךָ֣ וּבִתֶּךָ֮ וְעַבְדְּךָ֣ וַֽאֲמָתֶךָ֒ וְהַלֵּוִי֙ אֲשֶׁ֣ר בִּשְׁעָרֶ֔יךָ וְהַגֵּ֛ר וְהַיָּת֥וֹם וְהָֽאַלְמָנָ֖ה אֲשֶׁ֣ר בְּקִרְבֶּ֑ךָ בַּמָּק֗וֹם אֲשֶׁ֤ר יִבְחַר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לְשַׁכֵּ֥ן שְׁמ֖וֹ שָֽׁם׃"
          },
          {
            "num": "יב",
            "txt": "וְזָ֣כַרְתָּ֔ כִּי־עֶ֥בֶד הָיִ֖יתָ בְּמִצְרָ֑יִם וְשָֽׁמַרְתָּ֣ וְעָשִׂ֔יתָ אֶת־הַֽחֻקִּ֖ים הָאֵֽלֶּה׃ {פ}"
          },
          {
            "num": "יג",
            "txt": "חַ֧ג הַסֻּכֹּ֛ת תַּֽעֲשֶׂ֥ה לְךָ֖ שִׁבְעַ֣ת יָמִ֑ים בְּאָ֨סְפְּךָ֔ מִֽגָּרְנְךָ֖ וּמִיִּקְבֶֽךָ׃"
          },
          {
            "num": "יד",
            "txt": "וְשָֽׂמַחְתָּ֖ בְּחַגֶּ֑ךָ אַתָּ֨ה וּבִנְךָ֤ וּבִתֶּ֨ךָ֙ וְעַבְדְּךָ֣ וַֽאֲמָתֶ֔ךָ וְהַלֵּוִ֗י וְהַגֵּ֛ר וְהַיָּת֥וֹם וְהָֽאַלְמָנָ֖ה אֲשֶׁ֥ר בִּשְׁעָרֶֽיךָ׃"
          },
          {
            "num": "טו",
            "txt": "שִׁבְעַ֣ת יָמִ֗ים תָּחֹג֙ לַֽיהוָ֣ה אֱלֹהֶ֔יךָ בַּמָּק֖וֹם אֲשֶׁר־יִבְחַ֣ר יְהוָ֑ה כִּ֣י יְבָֽרֶכְךָ֞ יְהוָ֣ה אֱלֹהֶ֗יךָ בְּכֹ֤ל תְּבוּאָֽתְךָ֙ וּבְכֹל֙ מַֽעֲשֵׂ֣ה יָדֶ֔יךָ וְהָיִ֖יתָ אַ֥ךְ שָׂמֵֽחַ׃"
          },
          {
            "num": "טז",
            "txt": "שָׁל֣וֹשׁ פְּעָמִ֣ים ׀ בַּשָּׁנָ֡ה יֵֽרָאֶ֨ה כָל־זְכֽוּרְךָ֜ אֶת־פְּנֵ֣י ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ בַּמָּקוֹם֙ אֲשֶׁ֣ר יִבְחָ֔ר בְּחַ֧ג הַמַּצּ֛וֹת וּבְחַ֥ג הַשָּֽׁבֻע֖וֹת וּבְחַ֣ג הַסֻּכּ֑וֹת וְלֹ֧א יֵֽרָאֶ֛ה אֶת־פְּנֵ֥י יְהוָ֖ה רֵיקָֽם׃"
          },
          {
            "num": "יז",
            "txt": "אִ֖ישׁ כְּמַתְּנַ֣ת יָד֑וֹ כְּבִרְכַּ֛ת יְהוָ֥ה אֱלֹהֶ֖יךָ אֲשֶׁ֥ר נָֽתַן־לָֽךְ׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "שֹֽׁפְטִ֣ים וְשֹֽׁטְרִ֗ים תִּֽתֶּן־לְךָ֙ בְּכָל־שְׁעָרֶ֔יךָ אֲשֶׁ֨ר יְהוָ֧ה אֱלֹהֶ֛יךָ נֹתֵ֥ן לְךָ֖ לִשְׁבָטֶ֑יךָ וְשָֽׁפְט֥וּ אֶת־הָעָ֖ם מִשְׁפַּט־צֶֽדֶק׃"
          },
          {
            "num": "יט",
            "txt": "לֹֽא־תַטֶּ֣ה מִשְׁפָּ֔ט לֹ֥א תַכִּ֖יר פָּנִ֑ים וְלֹֽא־תִקַּ֣ח שֹׁ֔חַד כִּ֣י הַשֹּׁ֗חַד יְעַוֵּר֙ עֵינֵ֣י חֲכָמִ֔ים וִֽיסַלֵּ֖ף דִּבְרֵ֥י צַדִּיקִֽם׃"
          },
          {
            "num": "כ",
            "txt": "צֶ֥דֶק צֶ֖דֶק תִּרְדֹּ֑ף לְמַ֤עַן תִּֽחְיֶה֙ וְיָֽרַשְׁתָּ֣ אֶת־הָאָ֔רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֥ן לָֽךְ׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "לֹֽא־תִטַּ֥ע לְךָ֛ אֲשֵׁרָ֖ה כָּל־עֵ֑ץ אֵ֗צֶל מִזְבַּ֛ח יְהוָ֥ה אֱלֹהֶ֖יךָ אֲשֶׁ֥ר תַּֽעֲשֶׂה־לָּֽךְ׃"
          },
          {
            "num": "כב",
            "txt": "וְלֹֽא־תָקִ֥ים לְךָ֖ מַצֵּבָ֑ה אֲשֶׁ֥ר שָׂנֵ֖א יְהוָ֥ה אֱלֹהֶֽיךָ׃ {ס}"
          }
        ]
      },
      {
        "num": "יז",
        "verses": [
          {
            "num": "א",
            "txt": "לֹֽא־תִזְבַּח֩ לַֽיהוָ֨ה אֱלֹהֶ֜יךָ שׁ֣וֹר וָשֶׂ֗ה אֲשֶׁ֨ר יִֽהְיֶ֥ה בוֹ֙ מ֔וּם כֹּ֖ל דָּבָ֣ר רָ֑ע כִּ֧י תֽוֹעֲבַ֛ת יְהוָ֥ה אֱלֹהֶ֖יךָ הֽוּא׃ {ס}"
          },
          {
            "num": "ב",
            "txt": "כִּֽי־יִמָּצֵ֤א בְקִרְבְּךָ֙ בְּאַחַ֣ד שְׁעָרֶ֔יךָ אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֣ן לָ֑ךְ אִ֣ישׁ אֽוֹ־אִשָּׁ֗ה אֲשֶׁ֨ר יַֽעֲשֶׂ֧ה אֶת־הָרַ֛ע בְּעֵינֵ֥י יְהוָֽה־אֱלֹהֶ֖יךָ לַֽעֲבֹ֥ר בְּרִיתֽוֹ׃"
          },
          {
            "num": "ג",
            "txt": "וַיֵּ֗לֶךְ וַֽיַּעֲבֹד֙ אֱלֹהִ֣ים אֲחֵרִ֔ים וַיִּשְׁתַּ֖חוּ לָהֶ֑ם וְלַשֶּׁ֣מֶשׁ ׀ א֣וֹ לַיָּרֵ֗חַ א֛וֹ לְכָל־צְבָ֥א הַשָּׁמַ֖יִם אֲשֶׁ֥ר לֹֽא־צִוִּֽיתִי׃"
          },
          {
            "num": "ד",
            "txt": "וְהֻֽגַּד־לְךָ֖ וְשָׁמָ֑עְתָּ וְדָֽרַשְׁתָּ֣ הֵיטֵ֔ב וְהִנֵּ֤ה אֱמֶת֙ נָכ֣וֹן הַדָּבָ֔ר נֶֽעֶשְׂתָ֛ה הַתּֽוֹעֵבָ֥ה הַזֹּ֖את בְּיִשְׂרָאֵֽל׃"
          },
          {
            "num": "ה",
            "txt": "וְהֽוֹצֵאתָ֣ אֶת־הָאִ֣ישׁ הַה֡וּא אוֹ֩ אֶת־הָֽאִשָּׁ֨ה הַהִ֜וא אֲשֶׁ֣ר עָ֠שׂוּ אֶת־הַדָּבָ֨ר הָרָ֤ע הַזֶּה֙ אֶל־שְׁעָרֶ֔יךָ אֶת־הָאִ֕ישׁ א֖וֹ אֶת־הָֽאִשָּׁ֑ה וּסְקַלְתָּ֥ם בָּֽאֲבָנִ֖ים וָמֵֽתוּ׃"
          },
          {
            "num": "ו",
            "txt": "עַל־פִּ֣י ׀ שְׁנַ֣יִם עֵדִ֗ים א֛וֹ שְׁלֹשָׁ֥ה עֵדִ֖ים יוּמַ֣ת הַמֵּ֑ת לֹ֣א יוּמַ֔ת עַל־פִּ֖י עֵ֥ד אֶחָֽד׃"
          },
          {
            "num": "ז",
            "txt": "יַ֣ד הָֽעֵדִ֞ים תִּֽהְיֶה־בּ֤וֹ בָרִֽאשֹׁנָה֙ לַֽהֲמִית֔וֹ וְיַ֥ד כָּל־הָעָ֖ם בָּאַֽחֲרֹנָ֑ה וּבִֽעַרְתָּ֥ הָרָ֖ע מִקִּרְבֶּֽךָ׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "כִּ֣י יִפָּלֵא֩ מִמְּךָ֨ דָבָ֜ר לַמִּשְׁפָּ֗ט בֵּֽין־דָּ֨ם ׀ לְדָ֜ם בֵּֽין־דִּ֣ין לְדִ֗ין וּבֵ֥ין נֶ֨גַע֙ לָנֶ֔גַע דִּבְרֵ֥י רִיבֹ֖ת בִּשְׁעָרֶ֑יךָ וְקַמְתָּ֣ וְעָלִ֔יתָ אֶ֨ל־הַמָּק֔וֹם אֲשֶׁ֥ר יִבְחַ֛ר יְהוָ֥ה אֱלֹהֶ֖יךָ בּֽוֹ׃"
          },
          {
            "num": "ט",
            "txt": "וּבָאתָ֗ אֶל־הַכֹּֽהֲנִים֙ הַלְוִיִּ֔ם וְאֶ֨ל־הַשֹּׁפֵ֔ט אֲשֶׁ֥ר יִֽהְיֶ֖ה בַּיָּמִ֣ים הָהֵ֑ם וְדָֽרַשְׁתָּ֙ וְהִגִּ֣ידֽוּ לְךָ֔ אֵ֖ת דְּבַ֥ר הַמִּשְׁפָּֽט׃"
          },
          {
            "num": "י",
            "txt": "וְעָשִׂ֗יתָ עַל־פִּ֤י הַדָּבָר֙ אֲשֶׁ֣ר יַגִּ֣ידֽוּ לְךָ֔ מִן־הַמָּק֣וֹם הַה֔וּא אֲשֶׁ֖ר יִבְחַ֣ר יְהוָ֑ה וְשָֽׁמַרְתָּ֣ לַֽעֲשׂ֔וֹת כְּכֹ֖ל אֲשֶׁ֥ר יוֹרֽוּךָ׃"
          },
          {
            "num": "יא",
            "txt": "עַל־פִּ֨י הַתּוֹרָ֜ה אֲשֶׁ֣ר יוֹר֗וּךָ וְעַל־הַמִּשְׁפָּ֛ט אֲשֶׁר־יֹֽאמְר֥וּ לְךָ֖ תַּֽעֲשֶׂ֑ה לֹ֣א תָס֗וּר מִן־הַדָּבָ֛ר אֲשֶׁר־יַגִּ֥ידֽוּ לְךָ֖ יָמִ֥ין וּשְׂמֹֽאל׃"
          },
          {
            "num": "יב",
            "txt": "וְהָאִ֞ישׁ אֲשֶׁר־יַֽעֲשֶׂ֣ה בְזָד֗וֹן לְבִלְתִּ֨י שְׁמֹ֤עַ אֶל־הַכֹּהֵן֙ הָֽעֹמֵ֞ד לְשָׁ֤רֶת שָׁם֙ אֶת־יְהוָ֣ה אֱלֹהֶ֔יךָ א֖וֹ אֶל־הַשֹּׁפֵ֑ט וּמֵת֙ הָאִ֣ישׁ הַה֔וּא וּבִֽעַרְתָּ֥ הָרָ֖ע מִיִּשְׂרָאֵֽל׃"
          },
          {
            "num": "יג",
            "txt": "וְכָל־הָעָ֖ם יִשְׁמְע֣וּ וְיִרָ֑אוּ וְלֹ֥א יְזִיד֖וּן עֽוֹד׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "כִּֽי־תָבֹ֣א אֶל־הָאָ֗רֶץ אֲשֶׁ֨ר יְהוָ֤ה אֱלֹהֶ֨יךָ֙ נֹתֵ֣ן לָ֔ךְ וִֽירִשְׁתָּ֖הּ וְיָשַׁ֣בְתָּה בָּ֑הּ וְאָֽמַרְתָּ֗ אָשִׂ֤ימָה עָלַי֙ מֶ֔לֶךְ כְּכָל־הַגּוֹיִ֖ם אֲשֶׁ֥ר סְבִֽיבֹתָֽי׃"
          },
          {
            "num": "טו",
            "txt": "שׂ֣וֹם תָּשִׂ֤ים עָלֶ֨יךָ֙ מֶ֔לֶךְ אֲשֶׁ֥ר יִבְחַ֛ר יְהוָ֥ה אֱלֹהֶ֖יךָ בּ֑וֹ מִקֶּ֣רֶב אַחֶ֗יךָ תָּשִׂ֤ים עָלֶ֨יךָ֙ מֶ֔לֶךְ לֹ֣א תוּכַ֗ל לָתֵ֤ת עָלֶ֨יךָ֙ אִ֣ישׁ נָכְרִ֔י אֲשֶׁ֥ר לֹֽא־אָחִ֖יךָ הֽוּא׃"
          },
          {
            "num": "טז",
            "txt": "רַק֮ לֹֽא־יַרְבֶּה־לּ֣וֹ סוּסִים֒ וְלֹֽא־יָשִׁ֤יב אֶת־הָעָם֙ מִצְרַ֔יְמָה לְמַ֖עַן הַרְבּ֣וֹת ס֑וּס וַֽיהוָה֙ אָמַ֣ר לָכֶ֔ם לֹ֣א תֹֽסִפ֗וּן לָשׁ֛וּב בַּדֶּ֥רֶךְ הַזֶּ֖ה עֽוֹד׃"
          },
          {
            "num": "יז",
            "txt": "וְלֹ֤א יַרְבֶּה־לּוֹ֙ נָשִׁ֔ים וְלֹ֥א יָס֖וּר לְבָב֑וֹ וְכֶ֣סֶף וְזָהָ֔ב לֹ֥א יַרְבֶּה־לּ֖וֹ מְאֹֽד׃"
          },
          {
            "num": "יח",
            "txt": "וְהָיָ֣ה כְשִׁבְתּ֔וֹ עַ֖ל כִּסֵּ֣א מַמְלַכְתּ֑וֹ וְכָ֨תַב ל֜וֹ אֶת־מִשְׁנֵ֨ה הַתּוֹרָ֤ה הַזֹּאת֙ עַל־סֵ֔פֶר מִלִּפְנֵ֖י הַכֹּֽהֲנִ֥ים הַלְוִיִּֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְהָֽיְתָ֣ה עִמּ֔וֹ וְקָ֥רָא ב֖וֹ כָּל־יְמֵ֣י חַיָּ֑יו לְמַ֣עַן יִלְמַ֗ד לְיִרְאָה֙ אֶת־יְהוָ֣ה אֱלֹהָ֔יו לִ֠שְׁמֹר אֶֽת־כָּל־דִּבְרֵ֞י הַתּוֹרָ֥ה הַזֹּ֛את וְאֶת־הַֽחֻקִּ֥ים הָאֵ֖לֶּה לַֽעֲשֹׂתָֽם׃"
          },
          {
            "num": "כ",
            "txt": "לְבִלְתִּ֤י רוּם־לְבָבוֹ֙ מֵֽאֶחָ֔יו וּלְבִלְתִּ֛י ס֥וּר מִן־הַמִּצְוָ֖ה יָמִ֣ין וּשְׂמֹ֑אול לְמַעַן֩ יַֽאֲרִ֨יךְ יָמִ֧ים עַל־מַמְלַכְתּ֛וֹ ה֥וּא וּבָנָ֖יו בְּקֶ֥רֶב יִשְׂרָאֵֽל׃ {ס}"
          }
        ]
      },
      {
        "num": "יח",
        "verses": [
          {
            "num": "א",
            "txt": "לֹֽא־יִ֠הְיֶה לַכֹּֽהֲנִ֨ים הַלְוִיִּ֜ם כָּל־שֵׁ֧בֶט לֵוִ֛י חֵ֥לֶק וְנַֽחֲלָ֖ה עִם־יִשְׂרָאֵ֑ל אִשֵּׁ֧י יְהוָ֛ה וְנַֽחֲלָת֖וֹ יֹֽאכֵלֽוּן׃"
          },
          {
            "num": "ב",
            "txt": "וְנַֽחֲלָ֥ה לֹא־יִֽהְיֶה־לּ֖וֹ בְּקֶ֣רֶב אֶחָ֑יו יְהוָה֙ ה֣וּא נַֽחֲלָת֔וֹ כַּֽאֲשֶׁ֖ר דִּבֶּר־לֽוֹ׃ {ס}"
          },
          {
            "num": "ג",
            "txt": "וְזֶ֡ה יִֽהְיֶה֩ מִשְׁפַּ֨ט הַכֹּֽהֲנִ֜ים מֵאֵ֣ת הָעָ֗ם מֵאֵ֛ת זֹֽבְחֵ֥י הַזֶּ֖בַח אִם־שׁ֣וֹר אִם־שֶׂ֑ה וְנָתַן֙ לַכֹּהֵ֔ן הַזְּרֹ֥עַ וְהַלְּחָיַ֖יִם וְהַקֵּבָֽה׃"
          },
          {
            "num": "ד",
            "txt": "רֵאשִׁ֨ית דְּגָֽנְךָ֜ תִּֽירֹשְׁךָ֣ וְיִצְהָרֶ֗ךָ וְרֵאשִׁ֛ית גֵּ֥ז צֹֽאנְךָ֖ תִּתֶּן־לֽוֹ׃"
          },
          {
            "num": "ה",
            "txt": "כִּ֣י ב֗וֹ בָּחַ֛ר יְהוָ֥ה אֱלֹהֶ֖יךָ מִכָּל־שְׁבָטֶ֑יךָ לַֽעֲמֹ֨ד לְשָׁרֵ֧ת בְּשֵׁם־יְהוָ֛ה ה֥וּא וּבָנָ֖יו כָּל־הַיָּמִֽים׃ {ס}"
          },
          {
            "num": "ו",
            "txt": "וְכִֽי־יָבֹ֨א הַלֵּוִ֜י מֵֽאַחַ֤ד שְׁעָרֶ֨יךָ֙ מִכָּל־יִשְׂרָאֵ֔ל אֲשֶׁר־ה֖וּא גָּ֣ר שָׁ֑ם וּבָא֙ בְּכָל־אַוַּ֣ת נַפְשׁ֔וֹ אֶל־הַמָּק֖וֹם אֲשֶׁר־יִבְחַ֥ר יְהוָֽה׃"
          },
          {
            "num": "ז",
            "txt": "וְשֵׁרֵ֕ת בְּשֵׁ֖ם יְהוָ֣ה אֱלֹהָ֑יו כְּכָל־אֶחָיו֙ הַלְוִיִּ֔ם הָעֹֽמְדִ֥ים שָׁ֖ם לִפְנֵ֥י יְהוָֽה׃"
          },
          {
            "num": "ח",
            "txt": "חֵ֥לֶק כְּחֵ֖לֶק יֹאכֵ֑לוּ לְבַ֥ד מִמְכָּרָ֖יו עַל־הָֽאָבֽוֹת׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "כִּ֤י אַתָּה֙ בָּ֣א אֶל־הָאָ֔רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֣ן לָ֑ךְ לֹֽא־תִלְמַ֣ד לַֽעֲשׂ֔וֹת כְּתֽוֹעֲבֹ֖ת הַגּוֹיִ֥ם הָהֵֽם׃"
          },
          {
            "num": "י",
            "txt": "לֹֽא־יִמָּצֵ֣א בְךָ֔ מַֽעֲבִ֥יר בְּנֽוֹ־וּבִתּ֖וֹ בָּאֵ֑שׁ קֹסֵ֣ם קְסָמִ֔ים מְעוֹנֵ֥ן וּמְנַחֵ֖שׁ וּמְכַשֵּֽׁף׃"
          },
          {
            "num": "יא",
            "txt": "וְחֹבֵ֖ר חָ֑בֶר וְשֹׁאֵ֥ל אוֹב֙ וְיִדְּעֹנִ֔י וְדֹרֵ֖שׁ אֶל־הַמֵּתִֽים׃"
          },
          {
            "num": "יב",
            "txt": "כִּֽי־תוֹעֲבַ֥ת יְהוָ֖ה כָּל־עֹ֣שֵׂה אֵ֑לֶּה וּבִגְלַל֙ הַתּֽוֹעֵבֹ֣ת הָאֵ֔לֶּה יְהוָ֣ה אֱלֹהֶ֔יךָ מוֹרִ֥ישׁ אוֹתָ֖ם מִפָּנֶֽיךָ׃"
          },
          {
            "num": "יג",
            "txt": "תָּמִ֣ים תִּֽהְיֶ֔ה עִ֖ם יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "יד",
            "txt": "כִּ֣י ׀ הַגּוֹיִ֣ם הָאֵ֗לֶּה אֲשֶׁ֤ר אַתָּה֙ יוֹרֵ֣שׁ אוֹתָ֔ם אֶל־מְעֹֽנְנִ֥ים וְאֶל־קֹֽסְמִ֖ים יִשְׁמָ֑עוּ וְאַתָּ֕ה לֹ֣א כֵ֔ן נָ֥תַן לְךָ֖ יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "טו",
            "txt": "נָבִ֨יא מִקִּרְבְּךָ֤ מֵֽאַחֶ֨יךָ֙ כָּמֹ֔נִי יָקִ֥ים לְךָ֖ יְהוָ֣ה אֱלֹהֶ֑יךָ אֵלָ֖יו תִּשְׁמָעֽוּן׃"
          },
          {
            "num": "טז",
            "txt": "כְּכֹ֨ל אֲשֶׁר־שָׁאַ֜לְתָּ מֵעִ֨ם יְהוָ֤ה אֱלֹהֶ֨יךָ֙ בְּחֹרֵ֔ב בְּי֥וֹם הַקָּהָ֖ל לֵאמֹ֑ר לֹ֣א אֹסֵ֗ף לִשְׁמֹ֨עַ֙ אֶת־קוֹל֙ יְהוָ֣ה אֱלֹהָ֔י וְאֶת־הָאֵ֨שׁ הַגְּדֹלָ֥ה הַזֹּ֛את לֹֽא־אֶרְאֶ֥ה ע֖וֹד וְלֹ֥א אָמֽוּת׃"
          },
          {
            "num": "יז",
            "txt": "וַיֹּ֥אמֶר יְהוָ֖ה אֵלָ֑י הֵיטִ֖יבוּ אֲשֶׁ֥ר דִּבֵּֽרוּ׃"
          },
          {
            "num": "יח",
            "txt": "נָבִ֨יא אָקִ֥ים לָהֶ֛ם מִקֶּ֥רֶב אֲחֵיהֶ֖ם כָּמ֑וֹךָ וְנָֽתַתִּ֤י דְבָרַי֙ בְּפִ֔יו וְדִבֶּ֣ר אֲלֵיהֶ֔ם אֵ֖ת כָּל־אֲשֶׁ֥ר אֲצַוֶּֽנּוּ׃"
          },
          {
            "num": "יט",
            "txt": "וְהָיָ֗ה הָאִישׁ֙ אֲשֶׁ֤ר לֹֽא־יִשְׁמַע֙ אֶל־דְּבָרַ֔י אֲשֶׁ֥ר יְדַבֵּ֖ר בִּשְׁמִ֑י אָֽנֹכִ֖י אֶדְרֹ֥שׁ מֵֽעִמּֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "אַ֣ךְ הַנָּבִ֡יא אֲשֶׁ֣ר יָזִיד֩ לְדַבֵּ֨ר דָּבָ֜ר בִּשְׁמִ֗י אֵ֣ת אֲשֶׁ֤ר לֹֽא־צִוִּיתִיו֙ לְדַבֵּ֔ר וַֽאֲשֶׁ֣ר יְדַבֵּ֔ר בְּשֵׁ֖ם אֱלֹהִ֣ים אֲחֵרִ֑ים וּמֵ֖ת הַנָּבִ֥יא הַהֽוּא׃"
          },
          {
            "num": "כא",
            "txt": "וְכִ֥י תֹאמַ֖ר בִּלְבָבֶ֑ךָ אֵיכָה֙ נֵדַ֣ע אֶת־הַדָּבָ֔ר אֲשֶׁ֥ר לֹֽא־דִבְּר֖וֹ יְהוָֽה׃"
          },
          {
            "num": "כב",
            "txt": "אֲשֶׁר֩ יְדַבֵּ֨ר הַנָּבִ֜יא בְּשֵׁ֣ם יְהוָ֗ה וְלֹֽא־יִהְיֶ֤ה הַדָּבָר֙ וְלֹ֣א יָבֹ֔א ה֣וּא הַדָּבָ֔ר אֲשֶׁ֥ר לֹֽא־דִבְּר֖וֹ יְהוָ֑ה בְּזָדוֹן֙ דִּבְּר֣וֹ הַנָּבִ֔יא לֹ֥א תָג֖וּר מִמֶּֽנּוּ׃ {ס}"
          }
        ]
      },
      {
        "num": "יט",
        "verses": [
          {
            "num": "א",
            "txt": "כִּֽי־יַכְרִ֞ית יְהוָ֤ה אֱלֹהֶ֨יךָ֙ אֶת־הַגּוֹיִ֔ם אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ אֶת־אַרְצָ֑ם וִֽירִשְׁתָּ֕ם וְיָֽשַׁבְתָּ֥ בְעָֽרֵיהֶ֖ם וּבְבָֽתֵּיהֶֽם׃"
          },
          {
            "num": "ב",
            "txt": "שָׁל֥וֹשׁ עָרִ֖ים תַּבְדִּ֣יל לָ֑ךְ בְּת֣וֹךְ אַרְצְךָ֔ אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "ג",
            "txt": "תָּכִ֣ין לְךָ֮ הַדֶּרֶךְ֒ וְשִׁלַּשְׁתָּ֙ אֶת־גְּב֣וּל אַרְצְךָ֔ אֲשֶׁ֥ר יַנְחִֽילְךָ֖ יְהוָ֣ה אֱלֹהֶ֑יךָ וְהָיָ֕ה לָנ֥וּס שָׁ֖מָּה כָּל־רֹצֵֽחַ׃"
          },
          {
            "num": "ד",
            "txt": "וְזֶה֙ דְּבַ֣ר הָֽרֹצֵ֔חַ אֲשֶׁר־יָנ֥וּס שָׁ֖מָּה וָחָ֑י אֲשֶׁ֨ר יַכֶּ֤ה אֶת־רֵעֵ֨הוּ֙ בִּבְלִי־דַ֔עַת וְה֛וּא לֹֽא־שֹׂנֵ֥א ל֖וֹ מִתְּמֹ֥ל שִׁלְשֹֽׁם׃"
          },
          {
            "num": "ה",
            "txt": "וַֽאֲשֶׁר֩ יָבֹ֨א אֶת־רֵעֵ֥הוּ בַיַּעַר֮ לַחְטֹ֣ב עֵצִים֒ וְנִדְּחָ֨ה יָד֤וֹ בַגַּרְזֶן֙ לִכְרֹ֣ת הָעֵ֔ץ וְנָשַׁ֤ל הַבַּרְזֶל֙ מִן־הָעֵ֔ץ וּמָצָ֥א אֶת־רֵעֵ֖הוּ וָמֵ֑ת ה֗וּא יָנ֛וּס אֶל־אַחַ֥ת הֶעָֽרִים־הָאֵ֖לֶּה וָחָֽי׃"
          },
          {
            "num": "ו",
            "txt": "פֶּן־יִרְדֹּף֩ גֹּאֵ֨ל הַדָּ֜ם אַֽחֲרֵ֣י הָֽרֹצֵ֗חַ כִּֽי־יֵחַם֮ לְבָבוֹ֒ וְהִשִּׂיג֛וֹ כִּֽי־יִרְבֶּ֥ה הַדֶּ֖רֶךְ וְהִכָּ֣הוּ נָ֑פֶשׁ וְלוֹ֙ אֵ֣ין מִשְׁפַּט־מָ֔וֶת כִּ֠י לֹ֣א שֹׂנֵ֥א ה֛וּא ל֖וֹ מִתְּמ֥וֹל שִׁלְשֽׁוֹם׃"
          },
          {
            "num": "ז",
            "txt": "עַל־כֵּ֛ן אָֽנֹכִ֥י מְצַוְּךָ֖ לֵאמֹ֑ר שָׁלֹ֥שׁ עָרִ֖ים תַּבְדִּ֥יל לָֽךְ׃"
          },
          {
            "num": "ח",
            "txt": "וְאִם־יַרְחִ֞יב יְהוָ֤ה אֱלֹהֶ֨יךָ֙ אֶת־גְּבֻ֣לְךָ֔ כַּֽאֲשֶׁ֥ר נִשְׁבַּ֖ע לַֽאֲבֹתֶ֑יךָ וְנָ֤תַן לְךָ֙ אֶת־כָּל־הָאָ֔רֶץ אֲשֶׁ֥ר דִּבֶּ֖ר לָתֵ֥ת לַֽאֲבֹתֶֽיךָ׃"
          },
          {
            "num": "ט",
            "txt": "כִּֽי־תִשְׁמֹר֩ אֶת־כָּל־הַמִּצְוָ֨ה הַזֹּ֜את לַֽעֲשֹׂתָ֗הּ אֲשֶׁ֨ר אָֽנֹכִ֣י מְצַוְּךָ֮ הַיּוֹם֒ לְאַֽהֲבָ֞ה אֶת־יְהוָ֧ה אֱלֹהֶ֛יךָ וְלָלֶ֥כֶת בִּדְרָכָ֖יו כָּל־הַיָּמִ֑ים וְיָֽסַפְתָּ֨ לְךָ֥ עוֹד֙ שָׁלֹ֣שׁ עָרִ֔ים עַ֖ל הַשָּׁלֹ֥שׁ הָאֵֽלֶּה׃"
          },
          {
            "num": "י",
            "txt": "וְלֹ֤א יִשָּׁפֵךְ֙ דָּ֣ם נָקִ֔י בְּקֶ֣רֶב אַרְצְךָ֔ אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ נַֽחֲלָ֑ה וְהָיָ֥ה עָלֶ֖יךָ דָּמִֽים׃ {פ}"
          },
          {
            "num": "יא",
            "txt": "וְכִֽי־יִהְיֶ֥ה אִישׁ֙ שֹׂנֵ֣א לְרֵעֵ֔הוּ וְאָ֤רַב לוֹ֙ וְקָ֣ם עָלָ֔יו וְהִכָּ֥הוּ נֶ֖פֶשׁ וָמֵ֑ת וְנָ֕ס אֶל־אַחַ֖ת הֶֽעָרִ֥ים הָאֵֽל׃"
          },
          {
            "num": "יב",
            "txt": "וְשָֽׁלְחוּ֙ זִקְנֵ֣י עִיר֔וֹ וְלָֽקְח֥וּ אֹת֖וֹ מִשָּׁ֑ם וְנָֽתְנ֣וּ אֹת֗וֹ בְּיַ֛ד גֹּאֵ֥ל הַדָּ֖ם וָמֵֽת׃"
          },
          {
            "num": "יג",
            "txt": "לֹֽא־תָח֥וֹס עֵֽינְךָ֖ עָלָ֑יו וּבִֽעַרְתָּ֧ דַֽם־הַנָּקִ֛י מִיִּשְׂרָאֵ֖ל וְט֥וֹב לָֽךְ׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "לֹ֤א תַסִּיג֙ גְּב֣וּל רֵֽעֲךָ֔ אֲשֶׁ֥ר גָּֽבְל֖וּ רִֽאשֹׁנִ֑ים בְּנַחֲלָֽתְךָ֙ אֲשֶׁ֣ר תִּנְחַ֔ל בָּאָ֕רֶץ אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ לְרִשְׁתָּֽהּ׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "לֹֽא־יָקוּם֩ עֵ֨ד אֶחָ֜ד בְּאִ֗ישׁ לְכָל־עָוֺן֙ וּלְכָל־חַטָּ֔את בְּכָל־חֵ֖טְא אֲשֶׁ֣ר יֶֽחֱטָ֑א עַל־פִּ֣י ׀ שְׁנֵ֣י עֵדִ֗ים א֛וֹ עַל־פִּ֥י שְׁלֹשָֽׁה־עֵדִ֖ים יָק֥וּם דָּבָֽר׃"
          },
          {
            "num": "טז",
            "txt": "כִּֽי־יָק֥וּם עֵד־חָמָ֖ס בְּאִ֑ישׁ לַֽעֲנ֥וֹת בּ֖וֹ סָרָֽה׃"
          },
          {
            "num": "יז",
            "txt": "וְעָֽמְד֧וּ שְׁנֵֽי־הָאֲנָשִׁ֛ים אֲשֶׁר־לָהֶ֥ם הָרִ֖יב לִפְנֵ֣י יְהוָ֑ה לִפְנֵ֤י הַכֹּֽהֲנִים֙ וְהַשֹּׁ֣פְטִ֔ים אֲשֶׁ֥ר יִֽהְי֖וּ בַּיָּמִ֥ים הָהֵֽם׃"
          },
          {
            "num": "יח",
            "txt": "וְדָֽרְשׁ֥וּ הַשֹּֽׁפְטִ֖ים הֵיטֵ֑ב וְהִנֵּ֤ה עֵֽד־שֶׁ֨קֶר֙ הָעֵ֔ד שֶׁ֖קֶר עָנָ֥ה בְאָחִֽיו׃"
          },
          {
            "num": "יט",
            "txt": "וַֽעֲשִׂ֣יתֶם ל֔וֹ כַּֽאֲשֶׁ֥ר זָמַ֖ם לַֽעֲשׂ֣וֹת לְאָחִ֑יו וּבִֽעַרְתָּ֥ הָרָ֖ע מִקִּרְבֶּֽךָ׃"
          },
          {
            "num": "כ",
            "txt": "וְהַנִּשְׁאָרִ֖ים יִשְׁמְע֣וּ וְיִרָ֑אוּ וְלֹֽא־יֹסִ֨פוּ לַֽעֲשׂ֜וֹת ע֗וֹד כַּדָּבָ֥ר הָרָ֛ע הַזֶּ֖ה בְּקִרְבֶּֽךָ׃"
          },
          {
            "num": "כא",
            "txt": "וְלֹ֥א תָח֖וֹס עֵינֶ֑ךָ נֶ֣פֶשׁ בְּנֶ֗פֶשׁ עַ֤יִן בְּעַ֨יִן֙ שֵׁ֣ן בְּשֵׁ֔ן יָ֥ד בְּיָ֖ד רֶ֥גֶל בְּרָֽגֶל׃ {ס}"
          }
        ]
      },
      {
        "num": "כ",
        "verses": [
          {
            "num": "א",
            "txt": "כִּֽי־תֵצֵ֨א לַמִּלְחָמָ֜ה עַל־אֹֽיְבֶ֗ךָ וְֽרָאִ֜יתָ ס֤וּס וָרֶ֨כֶב֙ עַ֚ם רַ֣ב מִמְּךָ֔ לֹ֥א תִירָ֖א מֵהֶ֑ם כִּֽי־יְהוָ֤ה אֱלֹהֶ֨יךָ֙ עִמָּ֔ךְ הַמַּֽעַלְךָ֖ מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "ב",
            "txt": "וְהָיָ֕ה כְּקָֽרָבְכֶ֖ם אֶל־הַמִּלְחָמָ֑ה וְנִגַּ֥שׁ הַכֹּהֵ֖ן וְדִבֶּ֥ר אֶל־הָעָֽם׃"
          },
          {
            "num": "ג",
            "txt": "וְאָמַ֤ר אֲלֵהֶם֙ שְׁמַ֣ע יִשְׂרָאֵ֔ל אַתֶּ֨ם קְרֵבִ֥ים הַיּ֛וֹם לַמִּלְחָמָ֖ה עַל־אֹֽיְבֵיכֶ֑ם אַל־יֵרַ֣ךְ לְבַבְכֶ֗ם אַל־תִּֽירְא֧וּ וְאַֽל־תַּחְפְּז֛וּ וְאַל־תַּֽעַרְצ֖וּ מִפְּנֵיהֶֽם׃"
          },
          {
            "num": "ד",
            "txt": "כִּ֚י יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם הַֽהֹלֵ֖ךְ עִמָּכֶ֑ם לְהִלָּחֵ֥ם לָכֶ֛ם עִם־אֹֽיְבֵיכֶ֖ם לְהוֹשִׁ֥יעַ אֶתְכֶֽם׃"
          },
          {
            "num": "ה",
            "txt": "וְדִבְּר֣וּ הַשֹּֽׁטְרִים֮ אֶל־הָעָ֣ם לֵאמֹר֒ מִֽי־הָאִ֞ישׁ אֲשֶׁ֨ר בָּנָ֤ה בַֽיִת־חָדָשׁ֙ וְלֹ֣א חֲנָכ֔וֹ יֵלֵ֖ךְ וְיָשֹׁ֣ב לְבֵית֑וֹ פֶּן־יָמוּת֙ בַּמִּלְחָמָ֔ה וְאִ֥ישׁ אַחֵ֖ר יַחְנְכֶֽנּוּ׃"
          },
          {
            "num": "ו",
            "txt": "וּמִֽי־הָאִ֞ישׁ אֲשֶׁר־נָטַ֥ע כֶּ֨רֶם֙ וְלֹ֣א חִלְּל֔וֹ יֵלֵ֖ךְ וְיָשֹׁ֣ב לְבֵית֑וֹ פֶּן־יָמוּת֙ בַּמִּלְחָמָ֔ה וְאִ֥ישׁ אַחֵ֖ר יְחַלְּלֶֽנּוּ׃"
          },
          {
            "num": "ז",
            "txt": "וּמִֽי־הָאִ֞ישׁ אֲשֶׁר־אֵרַ֤שׂ אִשָּׁה֙ וְלֹ֣א לְקָחָ֔הּ יֵלֵ֖ךְ וְיָשֹׁ֣ב לְבֵית֑וֹ פֶּן־יָמוּת֙ בַּמִּלְחָמָ֔ה וְאִ֥ישׁ אַחֵ֖ר יִקָּחֶֽנָּה׃"
          },
          {
            "num": "ח",
            "txt": "וְיָֽסְפ֣וּ הַשֹּֽׁטְרִים֮ לְדַבֵּ֣ר אֶל־הָעָם֒ וְאָֽמְר֗וּ מִֽי־הָאִ֤ישׁ הַיָּרֵא֙ וְרַ֣ךְ הַלֵּבָ֔ב יֵלֵ֖ךְ וְיָשֹׁ֣ב לְבֵית֑וֹ וְלֹ֥א יִמַּ֛ס אֶת־לְבַ֥ב אֶחָ֖יו כִּלְבָבֽוֹ׃"
          },
          {
            "num": "ט",
            "txt": "וְהָיָ֛ה כְּכַלֹּ֥ת הַשֹּֽׁטְרִ֖ים לְדַבֵּ֣ר אֶל־הָעָ֑ם וּפָ֥קְד֛וּ שָׂרֵ֥י צְבָא֖וֹת בְּרֹ֥אשׁ הָעָֽם׃ {ס}"
          },
          {
            "num": "י",
            "txt": "כִּֽי־תִקְרַ֣ב אֶל־עִ֔יר לְהִלָּחֵ֖ם עָלֶ֑יהָ וְקָרָ֥אתָ אֵלֶ֖יהָ לְשָׁלֽוֹם׃"
          },
          {
            "num": "יא",
            "txt": "וְהָיָה֙ אִם־שָׁל֣וֹם תַּֽעַנְךָ֔ וּפָֽתְחָ֖ה לָ֑ךְ וְהָיָ֞ה כָּל־הָעָ֣ם הַנִּמְצָא־בָ֗הּ יִֽהְי֥וּ לְךָ֛ לָמַ֖ס וַֽעֲבָדֽוּךָ׃"
          },
          {
            "num": "יב",
            "txt": "וְאִם־לֹ֤א תַשְׁלִים֙ עִמָּ֔ךְ וְעָֽשְׂתָ֥ה עִמְּךָ֖ מִלְחָמָ֑ה וְצַרְתָּ֖ עָלֶֽיהָ׃"
          },
          {
            "num": "יג",
            "txt": "וּנְתָנָ֛הּ יְהוָ֥ה אֱלֹהֶ֖יךָ בְּיָדֶ֑ךָ וְהִכִּיתָ֥ אֶת־כָּל־זְכוּרָ֖הּ לְפִי־חָֽרֶב׃"
          },
          {
            "num": "יד",
            "txt": "רַ֣ק הַ֠נָּשִׁים וְהַטַּ֨ף וְהַבְּהֵמָ֜ה וְכֹל֩ אֲשֶׁ֨ר יִֽהְיֶ֥ה בָעִ֛יר כָּל־שְׁלָלָ֖הּ תָּבֹ֣ז לָ֑ךְ וְאָֽכַלְתָּ֙ אֶת־שְׁלַ֣ל אֹֽיְבֶ֔יךָ אֲשֶׁ֥ר נָתַ֛ן יְהוָ֥ה אֱלֹהֶ֖יךָ לָֽךְ׃"
          },
          {
            "num": "טו",
            "txt": "כֵּ֤ן תַּֽעֲשֶׂה֙ לְכָל־הֶ֣עָרִ֔ים הָֽרְחֹקֹ֥ת מִמְּךָ֖ מְאֹ֑ד אֲשֶׁ֛ר לֹֽא־מֵעָרֵ֥י הַגּֽוֹיִם־הָאֵ֖לֶּה הֵֽנָּה׃"
          },
          {
            "num": "טז",
            "txt": "רַ֗ק מֵֽעָרֵ֤י הָֽעַמִּים֙ הָאֵ֔לֶּה אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ נַֽחֲלָ֑ה לֹ֥א תְחַיֶּ֖ה כָּל־נְשָׁמָֽה׃"
          },
          {
            "num": "יז",
            "txt": "כִּֽי־הַחֲרֵ֣ם תַּֽחֲרִימֵ֗ם הַֽחִתִּ֤י וְהָֽאֱמֹרִי֙ הַכְּנַֽעֲנִ֣י וְהַפְּרִזִּ֔י הַֽחִוִּ֖י וְהַיְבוּסִ֑י כַּֽאֲשֶׁ֥ר צִוְּךָ֖ יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "יח",
            "txt": "לְמַ֗עַן אֲשֶׁ֨ר לֹֽא־יְלַמְּד֤וּ אֶתְכֶם֙ לַֽעֲשׂ֔וֹת כְּכֹל֙ תּֽוֹעֲבֹתָ֔ם אֲשֶׁ֥ר עָשׂ֖וּ לֵֽאלֹהֵיהֶ֑ם וַֽחֲטָאתֶ֖ם לַֽיהוָ֥ה אֱלֹֽהֵיכֶֽם׃ {ס}"
          },
          {
            "num": "יט",
            "txt": "כִּֽי־תָצ֣וּר אֶל־עִיר֩ יָמִ֨ים רַבִּ֜ים לְֽהִלָּחֵ֧ם עָלֶ֣יהָ לְתָפְשָׂ֗הּ לֹֽא־תַשְׁחִ֤ית אֶת־עֵצָהּ֙ לִנְדֹּ֤חַ עָלָיו֙ גַּרְזֶ֔ן כִּ֚י מִמֶּ֣נּוּ תֹאכֵ֔ל וְאֹת֖וֹ לֹ֣א תִכְרֹ֑ת כִּ֤י הָֽאָדָם֙ עֵ֣ץ הַשָּׂדֶ֔ה לָבֹ֥א מִפָּנֶ֖יךָ בַּמָּצֽוֹר׃"
          },
          {
            "num": "כ",
            "txt": "רַ֞ק עֵ֣ץ אֲשֶׁר־תֵּדַ֗ע כִּֽי־לֹא־עֵ֤ץ מַֽאֲכָל֙ ה֔וּא אֹת֥וֹ תַשְׁחִ֖ית וְכָרָ֑תָּ וּבָנִ֣יתָ מָצ֗וֹר עַל־הָעִיר֙ אֲשֶׁר־הִ֨וא עֹשָׂ֧ה עִמְּךָ֛ מִלְחָמָ֖ה עַ֥ד רִדְתָּֽהּ׃ {פ}"
          }
        ]
      },
      {
        "num": "כא",
        "verses": [
          {
            "num": "א",
            "txt": "כִּֽי־יִמָּצֵ֣א חָלָ֗ל בָּֽאֲדָמָה֙ אֲשֶׁר֩ יְהוָ֨ה אֱלֹהֶ֜יךָ נֹתֵ֤ן לְךָ֙ לְרִשְׁתָּ֔הּ נֹפֵ֖ל בַּשָּׂדֶ֑ה לֹ֥א נוֹדַ֖ע מִ֥י הִכָּֽהוּ׃"
          },
          {
            "num": "ב",
            "txt": "וְיָֽצְא֥וּ זְקֵנֶ֖יךָ וְשֹֽׁפְטֶ֑יךָ וּמָֽדְדוּ֙ אֶל־הֶ֣עָרִ֔ים אֲשֶׁ֖ר סְבִיבֹ֥ת הֶֽחָלָֽל׃"
          },
          {
            "num": "ג",
            "txt": "וְהָיָ֣ה הָעִ֔יר הַקְּרֹבָ֖ה אֶל־הֶֽחָלָ֑ל וְלָֽקְח֡וּ זִקְנֵי֩ הָעִ֨יר הַהִ֜וא עֶגְלַ֣ת בָּקָ֗ר אֲשֶׁ֤ר לֹֽא־עֻבַּד֙ בָּ֔הּ אֲשֶׁ֥ר לֹֽא־מָשְׁכָ֖ה בְּעֹֽל׃"
          },
          {
            "num": "ד",
            "txt": "וְהוֹרִ֡דוּ זִקְנֵי֩ הָעִ֨יר הַהִ֤וא אֶת־הָֽעֶגְלָה֙ אֶל־נַ֣חַל אֵיתָ֔ן אֲשֶׁ֛ר לֹֽא־יֵעָבֵ֥ד בּ֖וֹ וְלֹ֣א יִזָּרֵ֑עַ וְעָֽרְפוּ־שָׁ֥ם אֶת־הָֽעֶגְלָ֖ה בַּנָּֽחַל׃"
          },
          {
            "num": "ה",
            "txt": "וְנִגְּשׁ֣וּ הַכֹּֽהֲנִים֮ בְּנֵ֣י לֵוִי֒ כִּ֣י בָ֗ם בָּחַ֞ר יְהוָ֤ה אֱלֹהֶ֨יךָ֙ לְשָׁ֣רְת֔וֹ וּלְבָרֵ֖ךְ בְּשֵׁ֣ם יְהוָ֑ה וְעַל־פִּיהֶ֥ם יִֽהְיֶ֖ה כָּל־רִ֥יב וְכָל־נָֽגַע׃"
          },
          {
            "num": "ו",
            "txt": "וְכֹ֗ל זִקְנֵי֙ הָעִ֣יר הַהִ֔וא הַקְּרֹבִ֖ים אֶל־הֶֽחָלָ֑ל יִרְחֲצוּ֙ אֶת־יְדֵיהֶ֔ם עַל־הָֽעֶגְלָ֖ה הָֽעֲרוּפָ֥ה בַנָּֽחַל׃"
          },
          {
            "num": "ז",
            "txt": "וְעָנ֖וּ וְאָֽמְר֑וּ יָדֵ֗ינוּ לֹ֤א שפכה (שָֽׁפְכוּ֙) אֶת־הַדָּ֣ם הַזֶּ֔ה וְעֵינֵ֖ינוּ לֹ֥א רָאֽוּ׃"
          },
          {
            "num": "ח",
            "txt": "כַּפֵּר֩ לְעַמְּךָ֨ יִשְׂרָאֵ֤ל אֲשֶׁר־פָּדִ֨יתָ֙ יְהוָ֔ה וְאַל־תִּתֵּן֙ דָּ֣ם נָקִ֔י בְּקֶ֖רֶב עַמְּךָ֣ יִשְׂרָאֵ֑ל וְנִכַּפֵּ֥ר לָהֶ֖ם הַדָּֽם׃"
          },
          {
            "num": "ט",
            "txt": "וְאַתָּ֗ה תְּבַעֵ֛ר הַדָּ֥ם הַנָּקִ֖י מִקִּרְבֶּ֑ךָ כִּֽי־תַעֲשֶׂ֥ה הַיָּשָׁ֖ר בְּעֵינֵ֥י יְהוָֽה׃ {ס}"
          },
          {
            "num": "י",
            "txt": "כִּֽי־תֵצֵ֥א לַמִּלְחָמָ֖ה עַל־אֹֽיְבֶ֑יךָ וּנְתָנ֞וֹ יְהוָ֧ה אֱלֹהֶ֛יךָ בְּיָדֶ֖ךָ וְשָׁבִ֥יתָ שִׁבְיֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "וְרָאִ֨יתָ֙ בַּשִּׁבְיָ֔ה אֵ֖שֶׁת יְפַת־תֹּ֑אַר וְחָֽשַׁקְתָּ֣ בָ֔הּ וְלָֽקַחְתָּ֥ לְךָ֖ לְאִשָּֽׁה׃"
          },
          {
            "num": "יב",
            "txt": "וַֽהֲבֵאתָ֖הּ אֶל־תּ֣וֹךְ בֵּיתֶ֑ךָ וְגִלְּחָה֙ אֶת־רֹאשָׁ֔הּ וְעָֽשְׂתָ֖ה אֶת־צִפָּרְנֶֽיהָ׃"
          },
          {
            "num": "יג",
            "txt": "וְהֵסִירָה֩ אֶת־שִׂמְלַ֨ת שִׁבְיָ֜הּ מֵֽעָלֶ֗יהָ וְיָֽשְׁבָה֙ בְּבֵיתֶ֔ךָ וּבָ֥כְתָ֛ה אֶת־אָבִ֥יהָ וְאֶת־אִמָּ֖הּ יֶ֣רַח יָמִ֑ים וְאַ֨חַר כֵּ֜ן תָּב֤וֹא אֵלֶ֨יהָ֙ וּבְעַלְתָּ֔הּ וְהָֽיְתָ֥ה לְךָ֖ לְאִשָּֽׁה׃"
          },
          {
            "num": "יד",
            "txt": "וְהָיָ֞ה אִם־לֹ֧א חָפַ֣צְתָּ בָּ֗הּ וְשִׁלַּחְתָּהּ֙ לְנַפְשָׁ֔הּ וּמָכֹ֥ר לֹֽא־תִמְכְּרֶ֖נָּה בַּכָּ֑סֶף לֹֽא־תִתְעַמֵּ֣ר בָּ֔הּ תַּ֖חַת אֲשֶׁ֥ר עִנִּיתָֽהּ׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "כִּֽי־תִהְיֶ֨יןָ לְאִ֜ישׁ שְׁתֵּ֣י נָשִׁ֗ים הָֽאַחַ֤ת אֲהוּבָה֙ וְהָֽאַחַ֣ת שְׂנוּאָ֔ה וְיָֽלְדוּ־ל֣וֹ בָנִ֔ים הָֽאֲהוּבָ֖ה וְהַשְּׂנוּאָ֑ה וְהָיָ֛ה הַבֵּ֥ן הַבְּכֹ֖ר לַשְּׂנִיאָֽה׃"
          },
          {
            "num": "טז",
            "txt": "וְהָיָ֗ה בְּיוֹם֙ הַנְחִיל֣וֹ אֶת־בָּנָ֔יו אֵ֥ת אֲשֶׁר־יִֽהְיֶ֖ה ל֑וֹ לֹ֣א יוּכַ֗ל לְבַכֵּר֙ אֶת־בֶּן־הָ֣אֲהוּבָ֔ה עַל־פְּנֵ֥י בֶן־הַשְּׂנוּאָ֖ה הַבְּכֹֽר׃"
          },
          {
            "num": "יז",
            "txt": "כִּי֩ אֶת־הַבְּכֹ֨ר בֶּן־הַשְּׂנוּאָ֜ה יַכִּ֗יר לָ֤תֶת לוֹ֙ פִּ֣י שְׁנַ֔יִם בְּכֹ֥ל אֲשֶׁר־יִמָּצֵ֖א ל֑וֹ כִּי־הוּא֙ רֵאשִׁ֣ית אֹנ֔וֹ ל֖וֹ מִשְׁפַּ֥ט הַבְּכֹרָֽה׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "כִּֽי־יִהְיֶ֣ה לְאִ֗ישׁ בֵּ֚ן סוֹרֵ֣ר וּמוֹרֶ֔ה אֵינֶ֣נּוּ שֹׁמֵ֔עַ בְּק֥וֹל אָבִ֖יו וּבְק֣וֹל אִמּ֑וֹ וְיִסְּר֣וּ אֹת֔וֹ וְלֹ֥א יִשְׁמַ֖ע אֲלֵיהֶֽם׃"
          },
          {
            "num": "יט",
            "txt": "וְתָ֥פְשׂוּ ב֖וֹ אָבִ֣יו וְאִמּ֑וֹ וְהוֹצִ֧יאוּ אֹת֛וֹ אֶל־זִקְנֵ֥י עִיר֖וֹ וְאֶל־שַׁ֥עַר מְקֹמֽוֹ׃"
          },
          {
            "num": "כ",
            "txt": "וְאָֽמְר֞וּ אֶל־זִקְנֵ֣י עִיר֗וֹ בְּנֵ֤נוּ זֶה֙ סוֹרֵ֣ר וּמֹרֶ֔ה אֵינֶ֥נּוּ שֹׁמֵ֖עַ בְּקֹלֵ֑נוּ זוֹלֵ֖ל וְסֹבֵֽא׃"
          },
          {
            "num": "כא",
            "txt": "וּ֠רְגָמֻהוּ כָּל־אַנְשֵׁ֨י עִיר֤וֹ בָֽאֲבָנִים֙ וָמֵ֔ת וּבִֽעַרְתָּ֥ הָרָ֖ע מִקִּרְבֶּ֑ךָ וְכָל־יִשְׂרָאֵ֖ל יִשְׁמְע֥וּ וְיִרָֽאוּ׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "וְכִֽי־יִהְיֶ֣ה בְאִ֗ישׁ חֵ֛טְא מִשְׁפַּט־מָ֖וֶת וְהוּמָ֑ת וְתָלִ֥יתָ אֹת֖וֹ עַל־עֵֽץ׃"
          },
          {
            "num": "כג",
            "txt": "לֹֽא־תָלִ֨ין נִבְלָת֜וֹ עַל־הָעֵ֗ץ כִּֽי־קָב֤וֹר תִּקְבְּרֶ֨נּוּ֙ בַּיּ֣וֹם הַה֔וּא כִּֽי־קִלְלַ֥ת אֱלֹהִ֖ים תָּל֑וּי וְלֹ֤א תְטַמֵּא֙ אֶת־אַדְמָ֣תְךָ֔ אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ נַֽחֲלָֽה׃ {ס}"
          }
        ]
      },
      {
        "num": "כב",
        "verses": [
          {
            "num": "א",
            "txt": "לֹֽא־תִרְאֶה֩ אֶת־שׁ֨וֹר אָחִ֜יךָ א֤וֹ אֶת־שֵׂיוֹ֙ נִדָּחִ֔ים וְהִתְעַלַּמְתָּ֖ מֵהֶ֑ם הָשֵׁ֥ב תְּשִׁיבֵ֖ם לְאָחִֽיךָ׃"
          },
          {
            "num": "ב",
            "txt": "וְאִם־לֹ֨א קָר֥וֹב אָחִ֛יךָ אֵלֶ֖יךָ וְלֹ֣א יְדַעְתּ֑וֹ וַֽאֲסַפְתּוֹ֙ אֶל־תּ֣וֹךְ בֵּיתֶ֔ךָ וְהָיָ֣ה עִמְּךָ֗ עַ֣ד דְּרֹ֤שׁ אָחִ֨יךָ֙ אֹת֔וֹ וַהֲשֵֽׁבֹת֖וֹ לֽוֹ׃"
          },
          {
            "num": "ג",
            "txt": "וְכֵ֧ן תַּֽעֲשֶׂ֣ה לַֽחֲמֹר֗וֹ וְכֵ֣ן תַּֽעֲשֶׂה֮ לְשִׂמְלָתוֹ֒ וְכֵ֣ן תַּֽעֲשֶׂ֗ה לְכָל־אֲבֵדַ֥ת אָחִ֛יךָ אֲשֶׁר־תֹּאבַ֥ד מִמֶּ֖נּוּ וּמְצָאתָ֑הּ לֹ֥א תוּכַ֖ל לְהִתְעַלֵּֽם׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "לֹֽא־תִרְאֶה֩ אֶת־חֲמ֨וֹר אָחִ֜יךָ א֤וֹ שׁוֹרוֹ֙ נֹֽפְלִ֣ים בַּדֶּ֔רֶךְ וְהִתְעַלַּמְתָּ֖ מֵהֶ֑ם הָקֵ֥ם תָּקִ֖ים עִמּֽוֹ׃ {ס}"
          },
          {
            "num": "ה",
            "txt": "לֹֽא־יִהְיֶ֤ה כְלִי־גֶ֨בֶר֙ עַל־אִשָּׁ֔ה וְלֹֽא־יִלְבַּ֥שׁ גֶּ֖בֶר שִׂמְלַ֣ת אִשָּׁ֑ה כִּ֧י תֽוֹעֲבַ֛ת יְהוָ֥ה אֱלֹהֶ֖יךָ כָּל־עֹ֥שֵׂה אֵֽלֶּה׃ {פ}"
          },
          {
            "num": "ו",
            "txt": "כִּ֣י יִקָּרֵ֣א קַן־צִפּ֣וֹר ׀ לְפָנֶ֡יךָ בַּדֶּ֜רֶךְ בְּכָל־עֵ֣ץ ׀ א֣וֹ עַל־הָאָ֗רֶץ אֶפְרֹחִים֙ א֣וֹ בֵיצִ֔ים וְהָאֵ֤ם רֹבֶ֨צֶת֙ עַל־הָֽאֶפְרֹחִ֔ים א֖וֹ עַל־הַבֵּיצִ֑ים לֹֽא־תִקַּ֥ח הָאֵ֖ם עַל־הַבָּנִֽים׃"
          },
          {
            "num": "ז",
            "txt": "שַׁלֵּ֤חַ תְּשַׁלַּח֙ אֶת־הָאֵ֔ם וְאֶת־הַבָּנִ֖ים תִּֽקַּֽח־לָ֑ךְ לְמַ֨עַן֙ יִ֣יטַב לָ֔ךְ וְהַֽאֲרַכְתָּ֖ יָמִֽים׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "כִּ֤י תִבְנֶה֙ בַּ֣יִת חָדָ֔שׁ וְעָשִׂ֥יתָ מַֽעֲקֶ֖ה לְגַגֶּ֑ךָ וְלֹֽא־תָשִׂ֤ים דָּמִים֙ בְּבֵיתֶ֔ךָ כִּֽי־יִפֹּ֥ל הַנֹּפֵ֖ל מִמֶּֽנּוּ׃"
          },
          {
            "num": "ט",
            "txt": "לֹֽא־תִזְרַ֥ע כַּרְמְךָ֖ כִּלְאָ֑יִם פֶּן־תִּקְדַּ֗שׁ הַֽמְלֵאָ֤ה הַזֶּ֨רַע֙ אֲשֶׁ֣ר תִּזְרָ֔ע וּתְבוּאַ֖ת הַכָּֽרֶם׃ {ס}"
          },
          {
            "num": "י",
            "txt": "לֹֽא־תַחֲרֹ֥שׁ בְּשׁוֹר־וּבַֽחֲמֹ֖ר יַחְדָּֽו׃"
          },
          {
            "num": "יא",
            "txt": "לֹ֤א תִלְבַּשׁ֙ שַֽׁעַטְנֵ֔ז צֶ֥מֶר וּפִשְׁתִּ֖ים יַחְדָּֽו׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "גְּדִלִ֖ים תַּֽעֲשֶׂה־לָּ֑ךְ עַל־אַרְבַּ֛ע כַּנְפ֥וֹת כְּסֽוּתְךָ֖ אֲשֶׁ֥ר תְּכַסֶּה־בָּֽהּ׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "כִּֽי־יִקַּ֥ח אִ֖ישׁ אִשָּׁ֑ה וּבָ֥א אֵלֶ֖יהָ וּשְׂנֵאָֽהּ׃"
          },
          {
            "num": "יד",
            "txt": "וְשָׂ֥ם לָהּ֙ עֲלִילֹ֣ת דְּבָרִ֔ים וְהוֹצִ֥א עָלֶ֖יהָ שֵׁ֣ם רָ֑ע וְאָמַ֗ר אֶת־הָֽאִשָּׁ֤ה הַזֹּאת֙ לָקַ֔חְתִּי וָֽאֶקְרַ֣ב אֵלֶ֔יהָ וְלֹֽא־מָצָ֥אתִי לָ֖הּ בְּתוּלִֽים׃"
          },
          {
            "num": "טו",
            "txt": "וְלָקַ֛ח אֲבִ֥י הַֽנַּעֲרָ֖ וְאִמָּ֑הּ וְהוֹצִ֜יאוּ אֶת־בְּתוּלֵ֧י הַֽנַּעֲרָ֛ אֶל־זִקְנֵ֥י הָעִ֖יר הַשָּֽׁעְרָה׃"
          },
          {
            "num": "טז",
            "txt": "וְאָמַ֛ר אֲבִ֥י הַֽנַּעֲרָ֖ אֶל־הַזְּקֵנִ֑ים אֶת־בִּתִּ֗י נָתַ֜תִּי לָאִ֥ישׁ הַזֶּ֛ה לְאִשָּׁ֖ה וַיִּשְׂנָאֶֽהָ׃"
          },
          {
            "num": "יז",
            "txt": "וְהִנֵּה־ה֡וּא שָׂם֩ עֲלִילֹ֨ת דְּבָרִ֜ים לֵאמֹ֗ר לֹֽא־מָצָ֤אתִי לְבִתְּךָ֙ בְּתוּלִ֔ים וְאֵ֖לֶּה בְּתוּלֵ֣י בִתִּ֑י וּפָֽרְשׂוּ֙ הַשִּׂמְלָ֔ה לִפְנֵ֖י זִקְנֵ֥י הָעִֽיר׃"
          },
          {
            "num": "יח",
            "txt": "וְלָ֥קְח֛וּ זִקְנֵ֥י הָֽעִיר־הַהִ֖וא אֶת־הָאִ֑ישׁ וְיִסְּר֖וּ אֹתֽוֹ׃"
          },
          {
            "num": "יט",
            "txt": "וְעָֽנְשׁ֨וּ אֹת֜וֹ מֵ֣אָה כֶ֗סֶף וְנָֽתְנוּ֙ לַֽאֲבִ֣י הַֽנַּעֲרָ֔ה כִּ֤י הוֹצִיא֙ שֵׁ֣ם רָ֔ע עַ֖ל בְּתוּלַ֣ת יִשְׂרָאֵ֑ל וְלֽוֹ־תִהְיֶ֣ה לְאִשָּׁ֔ה לֹֽא־יוּכַ֥ל לְשַׁלְּחָ֖הּ כָּל־יָמָֽיו׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וְאִם־אֱמֶ֣ת הָיָ֔ה הַדָּבָ֖ר הַזֶּ֑ה לֹֽא־נִמְצְא֥וּ בְתוּלִ֖ים לַֽנַּעֲרָֽ׃"
          },
          {
            "num": "כא",
            "txt": "וְהוֹצִ֨יאוּ אֶת־הַֽנַּעֲרָ֜ אֶל־פֶּ֣תַח בֵּית־אָבִ֗יהָ וּסְקָלוּהָ֩ אַנְשֵׁ֨י עִירָ֤הּ בָּֽאֲבָנִים֙ וָמֵ֔תָה כִּֽי־עָשְׂתָ֤ה נְבָלָה֙ בְּיִשְׂרָאֵ֔ל לִזְנ֖וֹת בֵּ֣ית אָבִ֑יהָ וּבִֽעַרְתָּ֥ הָרָ֖ע מִקִּרְבֶּֽךָ׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "כִּֽי־יִמָּצֵ֨א אִ֜ישׁ שֹׁכֵ֣ב ׀ עִם־אִשָּׁ֣ה בְעֻֽלַת־בַּ֗עַל וּמֵ֨תוּ֙ גַּם־שְׁנֵיהֶ֔ם הָאִ֛ישׁ הַשֹּׁכֵ֥ב עִם־הָֽאִשָּׁ֖ה וְהָֽאִשָּׁ֑ה וּבִֽעַרְתָּ֥ הָרָ֖ע מִיִּשְׂרָאֵֽל׃ {ס}"
          },
          {
            "num": "כג",
            "txt": "כִּ֤י יִֽהְיֶה֙ נַֽעֲרָ֣ בְתוּלָ֔ה מְאֹֽרָשָׂ֖ה לְאִ֑ישׁ וּמְצָאָ֥הּ אִ֛ישׁ בָּעִ֖יר וְשָׁכַ֥ב עִמָּֽהּ׃"
          },
          {
            "num": "כד",
            "txt": "וְהֽוֹצֵאתֶ֨ם אֶת־שְׁנֵיהֶ֜ם אֶל־שַׁ֣עַר ׀ הָעִ֣יר הַהִ֗וא וּסְקַלְתֶּ֨ם אֹתָ֥ם בָּֽאֲבָנִים֮ וָמֵתוּ֒ אֶת־הַֽנַּעֲרָ֗ עַל־דְּבַר֙ אֲשֶׁ֣ר לֹֽא־צָעֲקָ֣ה בָעִ֔יר וְאֶ֨ת־הָאִ֔ישׁ עַל־דְּבַ֥ר אֲשֶׁר־עִנָּ֖ה אֶת־אֵ֣שֶׁת רֵעֵ֑הוּ וּבִֽעַרְתָּ֥ הָרָ֖ע מִקִּרְבֶּֽךָ׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "וְֽאִם־בַּשָּׂדֶ֞ה יִמְצָ֣א הָאִ֗ישׁ אֶת־הַֽנַּעֲרָ֙ הַמְאֹ֣רָשָׂ֔ה וְהֶחֱזִֽיק־בָּ֥הּ הָאִ֖ישׁ וְשָׁכַ֣ב עִמָּ֑הּ וּמֵ֗ת הָאִ֛ישׁ אֲשֶׁר־שָׁכַ֥ב עִמָּ֖הּ לְבַדּֽוֹ׃"
          },
          {
            "num": "כו",
            "txt": "וְלַֽנַּעֲרָ֙ לֹֽא־תַעֲשֶׂ֣ה דָבָ֔ר אֵ֥ין לַֽנַּעֲרָ֖ חֵ֣טְא מָ֑וֶת כִּ֡י כַּֽאֲשֶׁר֩ יָק֨וּם אִ֤ישׁ עַל־רֵעֵ֨הוּ֙ וּרְצָח֣וֹ נֶ֔פֶשׁ כֵּ֖ן הַדָּבָ֥ר הַזֶּֽה׃"
          },
          {
            "num": "כז",
            "txt": "כִּ֥י בַשָּׂדֶ֖ה מְצָאָ֑הּ צָֽעֲקָ֗ה הַֽנַּעֲרָ֙ הַמְאֹ֣רָשָׂ֔ה וְאֵ֥ין מוֹשִׁ֖יעַ לָֽהּ׃ {ס}"
          },
          {
            "num": "כח",
            "txt": "כִּֽי־יִמְצָ֣א אִ֗ישׁ נַֽעֲרָ֤ בְתוּלָה֙ אֲשֶׁ֣ר לֹֽא־אֹרָ֔שָׂה וּתְפָשָׂ֖הּ וְשָׁכַ֣ב עִמָּ֑הּ וְנִמְצָֽאוּ׃"
          },
          {
            "num": "כט",
            "txt": "וְ֠נָתַן הָאִ֨ישׁ הַשֹּׁכֵ֥ב עִמָּ֛הּ לַֽאֲבִ֥י הַֽנַּעֲרָ֖ חֲמִשִּׁ֣ים כָּ֑סֶף וְלֽוֹ־תִהְיֶ֣ה לְאִשָּׁ֗ה תַּ֚חַת אֲשֶׁ֣ר עִנָּ֔הּ לֹֽא־יוּכַ֥ל שַׁלְּחָ֖הּ כָּל־יָמָֽיו׃ {ס}"
          }
        ]
      },
      {
        "num": "כג",
        "verses": [
          {
            "num": "א",
            "txt": "לֹֽא־יִקַּ֥ח אִ֖ישׁ אֶת־אֵ֣שֶׁת אָבִ֑יו וְלֹ֥א יְגַלֶּ֖ה כְּנַ֥ף אָבִֽיו׃ {ס}"
          },
          {
            "num": "ב",
            "txt": "לֹֽא־יָבֹ֧א פְצֽוּעַ־דַּכָּ֛א וּכְר֥וּת שָׁפְכָ֖ה בִּקְהַ֥ל יְהוָֽה׃ {ס}"
          },
          {
            "num": "ג",
            "txt": "לֹֽא־יָבֹ֥א מַמְזֵ֖ר בִּקְהַ֣ל יְהוָ֑ה גַּ֚ם דּ֣וֹר עֲשִׂירִ֔י לֹא־יָ֥בֹא ל֖וֹ בִּקְהַ֥ל יְהוָֽה׃ {ס}"
          },
          {
            "num": "ד",
            "txt": "לֹֽא־יָבֹ֧א עַמּוֹנִ֛י וּמֽוֹאָבִ֖י בִּקְהַ֣ל יְהוָ֑ה גַּ֚ם דּ֣וֹר עֲשִׂירִ֔י לֹֽא־יָבֹ֥א לָהֶ֛ם בִּקְהַ֥ל יְהוָ֖ה עַד־עוֹלָֽם׃"
          },
          {
            "num": "ה",
            "txt": "עַל־דְּבַ֞ר אֲשֶׁ֨ר לֹֽא־קִדְּמ֤וּ אֶתְכֶם֙ בַּלֶּ֣חֶם וּבַמַּ֔יִם בַּדֶּ֖רֶךְ בְּצֵֽאתְכֶ֣ם מִמִּצְרָ֑יִם וַֽאֲשֶׁר֩ שָׂכַ֨ר עָלֶ֜יךָ אֶת־בִּלְעָ֣ם בֶּן־בְּע֗וֹר מִפְּת֛וֹר אֲרַ֥ם נַֽהֲרַ֖יִם לְקַֽלְלֶֽךָּ׃"
          },
          {
            "num": "ו",
            "txt": "וְלֹֽא־אָבָ֞ה יְהוָ֤ה אֱלֹהֶ֨יךָ֙ לִשְׁמֹ֣עַ אֶל־בִּלְעָ֔ם וַיַּֽהֲפֹךְ֩ יְהוָ֨ה אֱלֹהֶ֧יךָ לְּךָ֛ אֶת־הַקְּלָלָ֖ה לִבְרָכָ֑ה כִּ֥י אֲהֵֽבְךָ֖ יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "ז",
            "txt": "לֹֽא־תִדְרֹ֥שׁ שְׁלֹמָ֖ם וְטֹֽבָתָ֑ם כָּל־יָמֶ֖יךָ לְעוֹלָֽם׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "לֹֽא־תְתַעֵ֣ב אֲדֹמִ֔י כִּ֥י אָחִ֖יךָ ה֑וּא לֹֽא־תְתַעֵ֣ב מִצְרִ֔י כִּי־גֵ֖ר הָיִ֥יתָ בְאַרְצֽוֹ׃"
          },
          {
            "num": "ט",
            "txt": "בָּנִ֛ים אֲשֶׁר־יִוָּֽלְד֥וּ לָהֶ֖ם דּ֣וֹר שְׁלִישִׁ֑י יָבֹ֥א לָהֶ֖ם בִּקְהַ֥ל יְהוָֽה׃ {ס}"
          },
          {
            "num": "י",
            "txt": "כִּֽי־תֵצֵ֥א מַֽחֲנֶ֖ה עַל־אֹֽיְבֶ֑יךָ וְנִ֨שְׁמַרְתָּ֔ מִכֹּ֖ל דָּבָ֥ר רָֽע׃"
          },
          {
            "num": "יא",
            "txt": "כִּֽי־יִהְיֶ֤ה בְךָ֙ אִ֔ישׁ אֲשֶׁ֛ר לֹֽא־יִהְיֶ֥ה טָה֖וֹר מִקְּרֵה־לָ֑יְלָה וְיָצָא֙ אֶל־מִח֣וּץ לַֽמַּחֲנֶ֔ה לֹ֥א יָבֹ֖א אֶל־תּ֥וֹךְ הַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "יב",
            "txt": "וְהָיָ֥ה לִפְנֽוֹת־עֶ֖רֶב יִרְחַ֣ץ בַּמָּ֑יִם וּכְבֹ֣א הַשֶּׁ֔מֶשׁ יָבֹ֖א אֶל־תּ֥וֹךְ הַֽמַּחֲנֶֽה׃"
          },
          {
            "num": "יג",
            "txt": "וְיָד֙ תִּֽהְיֶ֣ה לְךָ֔ מִח֖וּץ לַֽמַּחֲנֶ֑ה וְיָצָ֥אתָ שָּׁ֖מָּה חֽוּץ׃"
          },
          {
            "num": "יד",
            "txt": "וְיָתֵ֛ד תִּֽהְיֶ֥ה לְךָ֖ עַל־אֲזֵנֶ֑ךָ וְהָיָה֙ בְּשִׁבְתְּךָ֣ ח֔וּץ וְחָֽפַרְתָּ֣ה בָ֔הּ וְשַׁבְתָּ֖ וְכִסִּ֥יתָ אֶת־צֵֽאָתֶֽךָ׃"
          },
          {
            "num": "טו",
            "txt": "כִּי֩ יְהוָ֨ה אֱלֹהֶ֜יךָ מִתְהַלֵּ֣ךְ ׀ בְּקֶ֣רֶב מַֽחֲנֶ֗ךָ לְהַצִּֽילְךָ֙ וְלָתֵ֤ת אֹֽיְבֶ֨יךָ֙ לְפָנֶ֔יךָ וְהָיָ֥ה מַֽחֲנֶ֖יךָ קָד֑וֹשׁ וְלֹֽא־יִרְאֶ֤ה בְךָ֙ עֶרְוַ֣ת דָּבָ֔ר וְשָׁ֖ב מֵאַֽחֲרֶֽיךָ׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "לֹֽא־תַסְגִּ֥יר עֶ֖בֶד אֶל־אֲדֹנָ֑יו אֲשֶׁר־יִנָּצֵ֥ל אֵלֶ֖יךָ מֵעִ֥ם אֲדֹנָֽיו׃"
          },
          {
            "num": "יז",
            "txt": "עִמְּךָ֞ יֵשֵׁ֣ב בְּקִרְבְּךָ֗ בַּמָּק֧וֹם אֲשֶׁר־יִבְחַ֛ר בְּאַחַ֥ד שְׁעָרֶ֖יךָ בַּטּ֣וֹב ל֑וֹ לֹ֖א תּוֹנֶֽנּוּ׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "לֹֽא־תִהְיֶ֥ה קְדֵשָׁ֖ה מִבְּנ֣וֹת יִשְׂרָאֵ֑ל וְלֹֽא־יִהְיֶ֥ה קָדֵ֖שׁ מִבְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "יט",
            "txt": "לֹֽא־תָבִיא֩ אֶתְנַ֨ן זוֹנָ֜ה וּמְחִ֣יר כֶּ֗לֶב בֵּ֛ית יְהוָ֥ה אֱלֹהֶ֖יךָ לְכָל־נֶ֑דֶר כִּ֧י תֽוֹעֲבַ֛ת יְהוָ֥ה אֱלֹהֶ֖יךָ גַּם־שְׁנֵיהֶֽם׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "לֹֽא־תַשִּׁ֣יךְ לְאָחִ֔יךָ נֶ֥שֶׁךְ כֶּ֖סֶף נֶ֣שֶׁךְ אֹ֑כֶל נֶ֕שֶׁךְ כָּל־דָּבָ֖ר אֲשֶׁ֥ר יִשָּֽׁךְ׃"
          },
          {
            "num": "כא",
            "txt": "לַנָּכְרִ֣י תַשִּׁ֔יךְ וּלְאָחִ֖יךָ לֹ֣א תַשִּׁ֑יךְ לְמַ֨עַן יְבָֽרֶכְךָ֜ יְהוָ֣ה אֱלֹהֶ֗יךָ בְּכֹל֙ מִשְׁלַ֣ח יָדֶ֔ךָ עַל־הָאָ֕רֶץ אֲשֶׁר־אַתָּ֥ה בָא־שָׁ֖מָּה לְרִשְׁתָּֽהּ׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "כִּֽי־תִדֹּ֥ר נֶ֨דֶר֙ לַֽיהוָ֣ה אֱלֹהֶ֔יךָ לֹ֥א תְאַחֵ֖ר לְשַׁלְּמ֑וֹ כִּֽי־דָרֹ֨שׁ יִדְרְשֶׁ֜נּוּ יְהוָ֤ה אֱלֹהֶ֨יךָ֙ מֵֽעִמָּ֔ךְ וְהָיָ֥ה בְךָ֖ חֵֽטְא׃"
          },
          {
            "num": "כג",
            "txt": "וְכִ֥י תֶחְדַּ֖ל לִנְדֹּ֑ר לֹֽא־יִהְיֶ֥ה בְךָ֖ חֵֽטְא׃"
          },
          {
            "num": "כד",
            "txt": "מוֹצָ֥א שְׂפָתֶ֖יךָ תִּשְׁמֹ֣ר וְעָשִׂ֑יתָ כַּֽאֲשֶׁ֨ר נָדַ֜רְתָּ לַֽיהוָ֤ה אֱלֹהֶ֨יךָ֙ נְדָבָ֔ה אֲשֶׁ֥ר דִּבַּ֖רְתָּ בְּפִֽיךָ׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "כִּ֤י תָבֹא֙ בְּכֶ֣רֶם רֵעֶ֔ךָ וְאָֽכַלְתָּ֧ עֲנָבִ֛ים כְּנַפְשְׁךָ֖ שָׂבְעֶ֑ךָ וְאֶֽל־כֶּלְיְךָ֖ לֹ֥א תִתֵּֽן׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "כִּ֤י תָבֹא֙ בְּקָמַ֣ת רֵעֶ֔ךָ וְקָֽטַפְתָּ֥ מְלִילֹ֖ת בְּיָדֶ֑ךָ וְחֶרְמֵשׁ֙ לֹ֣א תָנִ֔יף עַ֖ל קָמַ֥ת רֵעֶֽךָ׃ {ס}"
          }
        ]
      },
      {
        "num": "כד",
        "verses": [
          {
            "num": "א",
            "txt": "כִּֽי־יִקַּ֥ח אִ֛ישׁ אִשָּׁ֖ה וּבְעָלָ֑הּ וְהָיָ֞ה אִם־לֹ֧א תִמְצָא־חֵ֣ן בְּעֵינָ֗יו כִּי־מָ֤צָא בָהּ֙ עֶרְוַ֣ת דָּבָ֔ר וְכָ֨תַב לָ֜הּ סֵ֤פֶר כְּרִיתֻת֙ וְנָתַ֣ן בְּיָדָ֔הּ וְשִׁלְּחָ֖הּ מִבֵּיתֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וְיָֽצְאָ֖ה מִבֵּית֑וֹ וְהָֽלְכָ֖ה וְהָֽיְתָ֥ה לְאִישׁ־אַחֵֽר׃"
          },
          {
            "num": "ג",
            "txt": "וּשְׂנֵאָהּ֮ הָאִ֣ישׁ הָאַֽחֲרוֹן֒ וְכָ֨תַב לָ֜הּ סֵ֤פֶר כְּרִיתֻת֙ וְנָתַ֣ן בְּיָדָ֔הּ וְשִׁלְּחָ֖הּ מִבֵּית֑וֹ א֣וֹ כִ֤י יָמוּת֙ הָאִ֣ישׁ הָאַֽחֲר֔וֹן אֲשֶׁר־לְקָחָ֥הּ ל֖וֹ לְאִשָּֽׁה׃"
          },
          {
            "num": "ד",
            "txt": "לֹֽא־יוּכַ֣ל בַּעְלָ֣הּ הָֽרִאשׁ֣וֹן אֲשֶֽׁר־שִׁ֠לְּחָהּ לָשׁ֨וּב לְקַחְתָּ֜הּ לִֽהְי֧וֹת ל֣וֹ לְאִשָּׁ֗ה אַֽחֲרֵי֙ אֲשֶׁ֣ר הֻטַּמָּ֔אָה כִּֽי־תוֹעֵבָ֥ה הִ֖וא לִפְנֵ֣י יְהוָ֑ה וְלֹ֤א תַֽחֲטִיא֙ אֶת־הָאָ֔רֶץ אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ נַֽחֲלָֽה׃ {ס}"
          },
          {
            "num": "ה",
            "txt": "כִּֽי־יִקַּ֥ח אִישׁ֙ אִשָּׁ֣ה חֲדָשָׁ֔ה לֹ֤א יֵצֵא֙ בַּצָּבָ֔א וְלֹֽא־יַעֲבֹ֥ר עָלָ֖יו לְכָל־דָּבָ֑ר נָקִ֞י יִֽהְיֶ֤ה לְבֵיתוֹ֙ שָׁנָ֣ה אֶחָ֔ת וְשִׂמַּ֖ח אֶת־אִשְׁתּ֥וֹ אֲשֶׁר־לָקָֽח׃"
          },
          {
            "num": "ו",
            "txt": "לֹֽא־יַחֲבֹ֥ל רֵחַ֖יִם וָרָ֑כֶב כִּי־נֶ֖פֶשׁ ה֥וּא חֹבֵֽל׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "כִּֽי־יִמָּצֵ֣א אִ֗ישׁ גֹּנֵ֨ב נֶ֤פֶשׁ מֵֽאֶחָיו֙ מִבְּנֵ֣י יִשְׂרָאֵ֔ל וְהִתְעַמֶּר־בּ֖וֹ וּמְכָר֑וֹ וּמֵת֙ הַגַּנָּ֣ב הַה֔וּא וּבִֽעַרְתָּ֥ הָרָ֖ע מִקִּרְבֶּֽךָ׃ {ס}"
          },
          {
            "num": "ח",
            "txt": "הִשָּׁ֧מֶר בְּנֶֽגַע־הַצָּרַ֛עַת לִשְׁמֹ֥ר מְאֹ֖ד וְלַֽעֲשׂ֑וֹת כְּכֹל֩ אֲשֶׁר־יוֹר֨וּ אֶתְכֶ֜ם הַכֹּֽהֲנִ֧ים הַלְוִיִּ֛ם כַּֽאֲשֶׁ֥ר צִוִּיתִ֖ם תִּשְׁמְר֥וּ לַֽעֲשֽׂוֹת׃"
          },
          {
            "num": "ט",
            "txt": "זָכ֕וֹר אֵ֧ת אֲשֶׁר־עָשָׂ֛ה יְהוָ֥ה אֱלֹהֶ֖יךָ לְמִרְיָ֑ם בַּדֶּ֖רֶךְ בְּצֵֽאתְכֶ֥ם מִמִּצְרָֽיִם׃ {ס}"
          },
          {
            "num": "י",
            "txt": "כִּֽי־תַשֶּׁ֥ה בְרֵֽעֲךָ֖ מַשַּׁ֣את מְא֑וּמָה לֹֽא־תָבֹ֥א אֶל־בֵּית֖וֹ לַֽעֲבֹ֥ט עֲבֹטֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "בַּח֖וּץ תַּֽעֲמֹ֑ד וְהָאִ֗ישׁ אֲשֶׁ֤ר אַתָּה֙ נֹשֶׁ֣ה ב֔וֹ יוֹצִ֥יא אֵלֶ֛יךָ אֶֽת־הַעֲב֖וֹט הַחֽוּצָה׃"
          },
          {
            "num": "יב",
            "txt": "וְאִם־אִ֥ישׁ עָנִ֖י ה֑וּא לֹ֥א תִשְׁכַּ֖ב בַּֽעֲבֹטֽוֹ׃"
          },
          {
            "num": "יג",
            "txt": "הָשֵׁב֩ תָּשִׁ֨יב ל֤וֹ אֶֽת־הַעֲבוֹט֙ כְּב֣וֹא הַשֶּׁ֔מֶשׁ וְשָׁכַ֥ב בְּשַׂלְמָת֖וֹ וּבֵֽרְכֶ֑ךָּ וּלְךָ֙ תִּֽהְיֶ֣ה צְדָקָ֔ה לִפְנֵ֖י יְהוָ֥ה אֱלֹהֶֽיךָ׃ {ס}"
          },
          {
            "num": "יד",
            "txt": "לֹֽא־תַעֲשֹׁ֥ק שָׂכִ֖יר עָנִ֣י וְאֶבְי֑וֹן מֵֽאַחֶ֕יךָ א֧וֹ מִגֵּֽרְךָ֛ אֲשֶׁ֥ר בְּאַרְצְךָ֖ בִּשְׁעָרֶֽיךָ׃"
          },
          {
            "num": "טו",
            "txt": "בְּיוֹמוֹ֩ תִתֵּ֨ן שְׂכָר֜וֹ וְֽלֹא־תָב֧וֹא עָלָ֣יו הַשֶּׁ֗מֶשׁ כִּ֤י עָנִי֙ ה֔וּא וְאֵלָ֕יו ה֥וּא נֹשֵׂ֖א אֶת־נַפְשׁ֑וֹ וְלֹֽא־יִקְרָ֤א עָלֶ֨יךָ֙ אֶל־יְהוָ֔ה וְהָיָ֥ה בְךָ֖ חֵֽטְא׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "לֹֽא־יוּמְת֤וּ אָבוֹת֙ עַל־בָּנִ֔ים וּבָנִ֖ים לֹֽא־יוּמְת֣וּ עַל־אָב֑וֹת אִ֥ישׁ בְּחֶטְא֖וֹ יוּמָֽתוּ׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "לֹ֣א תַטֶּ֔ה מִשְׁפַּ֖ט גֵּ֣ר יָת֑וֹם וְלֹ֣א תַֽחֲבֹ֔ל בֶּ֖גֶד אַלְמָנָֽה׃"
          },
          {
            "num": "יח",
            "txt": "וְזָֽכַרְתָּ֗ כִּ֣י עֶ֤בֶד הָיִ֨יתָ֙ בְּמִצְרַ֔יִם וַֽיִּפְדְּךָ֛ יְהוָ֥ה אֱלֹהֶ֖יךָ מִשָּׁ֑ם עַל־כֵּ֞ן אָֽנֹכִ֤י מְצַוְּךָ֙ לַֽעֲשׂ֔וֹת אֶת־הַדָּבָ֖ר הַזֶּֽה׃ {ס}"
          },
          {
            "num": "יט",
            "txt": "כִּ֣י תִקְצֹר֩ קְצִֽירְךָ֨ בְשָׂדֶ֜ךָ וְֽשָׁכַחְתָּ֧ עֹ֣מֶר בַּשָּׂדֶ֗ה לֹ֤א תָשׁוּב֙ לְקַחְתּ֔וֹ לַגֵּ֛ר לַיָּת֥וֹם וְלָֽאַלְמָנָ֖ה יִֽהְיֶ֑ה לְמַ֤עַן יְבָֽרֶכְךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ בְּכֹ֖ל מַֽעֲשֵׂ֥ה יָדֶֽיךָ׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "כִּ֤י תַחְבֹּט֙ זֵֽיתְךָ֔ לֹ֥א תְפַאֵ֖ר אַֽחֲרֶ֑יךָ לַגֵּ֛ר לַיָּת֥וֹם וְלָֽאַלְמָנָ֖ה יִֽהְיֶֽה׃"
          },
          {
            "num": "כא",
            "txt": "כִּ֤י תִבְצֹר֙ כַּרְמְךָ֔ לֹ֥א תְעוֹלֵ֖ל אַֽחֲרֶ֑יךָ לַגֵּ֛ר לַיָּת֥וֹם וְלָֽאַלְמָנָ֖ה יִֽהְיֶֽה׃"
          },
          {
            "num": "כב",
            "txt": "וְזָ֣כַרְתָּ֔ כִּי־עֶ֥בֶד הָיִ֖יתָ בְּאֶ֣רֶץ מִצְרָ֑יִם עַל־כֵּ֞ן אָֽנֹכִ֤י מְצַוְּךָ֙ לַֽעֲשׂ֔וֹת אֶת־הַדָּבָ֖ר הַזֶּֽה׃ {ס}"
          }
        ]
      },
      {
        "num": "כה",
        "verses": [
          {
            "num": "א",
            "txt": "כִּֽי־יִהְיֶ֥ה רִיב֙ בֵּ֣ין אֲנָשִׁ֔ים וְנִגְּשׁ֥וּ אֶל־הַמִּשְׁפָּ֖ט וּשְׁפָט֑וּם וְהִצְדִּ֨יקוּ֙ אֶת־הַצַּדִּ֔יק וְהִרְשִׁ֖יעוּ אֶת־הָֽרָשָֽׁע׃"
          },
          {
            "num": "ב",
            "txt": "וְהָיָ֛ה אִם־בִּ֥ן הַכּ֖וֹת הָֽרָשָׁ֑ע וְהִפִּיל֤וֹ הַשֹּׁפֵט֙ וְהִכָּ֣הוּ לְפָנָ֔יו כְּדֵ֥י רִשְׁעָת֖וֹ בְּמִסְפָּֽר׃"
          },
          {
            "num": "ג",
            "txt": "אַרְבָּעִ֥ים יַכֶּ֖נּוּ לֹ֣א יֹסִ֑יף פֶּן־יֹסִ֨יף לְהַכֹּת֤וֹ עַל־אֵ֨לֶּה֙ מַכָּ֣ה רַבָּ֔ה וְנִקְלָ֥ה אָחִ֖יךָ לְעֵינֶֽיךָ׃"
          },
          {
            "num": "ד",
            "txt": "לֹֽא־תַחְסֹ֥ם שׁ֖וֹר בְּדִישֽׁוֹ׃ {ס}"
          },
          {
            "num": "ה",
            "txt": "כִּֽי־יֵשְׁב֨וּ אַחִ֜ים יַחְדָּ֗ו וּמֵ֨ת אַחַ֤ד מֵהֶם֙ וּבֵ֣ן אֵֽין־ל֔וֹ לֹֽא־תִהְיֶ֧ה אֵֽשֶׁת־הַמֵּ֛ת הַח֖וּצָה לְאִ֣ישׁ זָ֑ר יְבָמָהּ֙ יָבֹ֣א עָלֶ֔יהָ וּלְקָחָ֥הּ ל֛וֹ לְאִשָּׁ֖ה וְיִבְּמָֽהּ׃"
          },
          {
            "num": "ו",
            "txt": "וְהָיָ֗ה הַבְּכוֹר֙ אֲשֶׁ֣ר תֵּלֵ֔ד יָק֕וּם עַל־שֵׁ֥ם אָחִ֖יו הַמֵּ֑ת וְלֹֽא־יִמָּחֶ֥ה שְׁמ֖וֹ מִיִּשְׂרָאֵֽל׃"
          },
          {
            "num": "ז",
            "txt": "וְאִם־לֹ֤א יַחְפֹּץ֙ הָאִ֔ישׁ לָקַ֖חַת אֶת־יְבִמְתּ֑וֹ וְעָֽלְתָה֩ יְבִמְתּ֨וֹ הַשַּׁ֜עְרָה אֶל־הַזְּקֵנִ֗ים וְאָֽמְרָה֙ מֵאֵ֨ן יְבָמִ֜י לְהָקִ֨ים לְאָחִ֥יו שֵׁם֙ בְּיִשְׂרָאֵ֔ל לֹ֥א אָבָ֖ה יַבְּמִֽי׃"
          },
          {
            "num": "ח",
            "txt": "וְקָֽרְאוּ־ל֥וֹ זִקְנֵֽי־עִיר֖וֹ וְדִבְּר֣וּ אֵלָ֑יו וְעָמַ֣ד וְאָמַ֔ר לֹ֥א חָפַ֖צְתִּי לְקַחְתָּֽהּ׃"
          },
          {
            "num": "ט",
            "txt": "וְנִגְּשָׁ֨ה יְבִמְתּ֣וֹ אֵלָיו֮ לְעֵינֵ֣י הַזְּקֵנִים֒ וְחָֽלְצָ֤ה נַֽעֲלוֹ֙ מֵעַ֣ל רַגְל֔וֹ וְיָֽרְקָ֖ה בְּפָנָ֑יו וְעָֽנְתָה֙ וְאָ֣מְרָ֔ה כָּ֚כָה יֵֽעָשֶׂ֣ה לָאִ֔ישׁ אֲשֶׁ֥ר לֹֽא־יִבְנֶ֖ה אֶת־בֵּ֥ית אָחִֽיו׃"
          },
          {
            "num": "י",
            "txt": "וְנִקְרָ֥א שְׁמ֖וֹ בְּיִשְׂרָאֵ֑ל בֵּ֖ית חֲל֥וּץ הַנָּֽעַל׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "כִּֽי־יִנָּצ֨וּ אֲנָשִׁ֤ים יַחְדָּו֙ אִ֣ישׁ וְאָחִ֔יו וְקָֽרְבָה֙ אֵ֣שֶׁת הָֽאֶחָ֔ד לְהַצִּ֥יל אֶת־אִישָׁ֖הּ מִיַּ֣ד מַכֵּ֑הוּ וְשָֽׁלְחָ֣ה יָדָ֔הּ וְהֶֽחֱזִ֖יקָה בִּמְבֻשָֽׁיו׃"
          },
          {
            "num": "יב",
            "txt": "וְקַצֹּתָ֖ה אֶת־כַּפָּ֑הּ לֹ֥א תָח֖וֹס עֵינֶֽךָ׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "לֹֽא־יִהְיֶ֥ה לְךָ֛ בְּכִֽיסְךָ֖ אֶ֣בֶן וָאָ֑בֶן גְּדוֹלָ֖ה וּקְטַנָּֽה׃"
          },
          {
            "num": "יד",
            "txt": "לֹֽא־יִהְיֶ֥ה לְךָ֛ בְּבֵֽיתְךָ֖ אֵיפָ֣ה וְאֵיפָ֑ה גְּדוֹלָ֖ה וּקְטַנָּֽה׃"
          },
          {
            "num": "טו",
            "txt": "אֶ֣בֶן שְׁלֵמָ֤ה וָצֶ֨דֶק֙ יִֽהְיֶה־לָּ֔ךְ אֵיפָ֧ה שְׁלֵמָ֛ה וָצֶ֖דֶק יִֽהְיֶה־לָּ֑ךְ לְמַ֨עַן֙ יַֽאֲרִ֣יכוּ יָמֶ֔יךָ עַ֚ל הָֽאֲדָמָ֔ה אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֥ן לָֽךְ׃"
          },
          {
            "num": "טז",
            "txt": "כִּ֧י תֽוֹעֲבַ֛ת יְהוָ֥ה אֱלֹהֶ֖יךָ כָּל־עֹ֣שֵׂה אֵ֑לֶּה כֹּ֖ל עֹ֥שֵׂה עָֽוֶל׃ {פ}"
          },
          {
            "num": "יז",
            "txt": "זָכ֕וֹר אֵ֛ת אֲשֶׁר־עָשָׂ֥ה לְךָ֖ עֲמָלֵ֑ק בַּדֶּ֖רֶךְ בְּצֵֽאתְכֶ֥ם מִמִּצְרָֽיִם׃"
          },
          {
            "num": "יח",
            "txt": "אֲשֶׁ֨ר קָֽרְךָ֜ בַּדֶּ֗רֶךְ וַיְזַנֵּ֤ב בְּךָ֙ כָּל־הַנֶּֽחֱשָׁלִ֣ים אַֽחֲרֶ֔יךָ וְאַתָּ֖ה עָיֵ֣ף וְיָגֵ֑עַ וְלֹ֥א יָרֵ֖א אֱלֹהִֽים׃"
          },
          {
            "num": "יט",
            "txt": "וְהָיָ֡ה בְּהָנִ֣יחַ יְהוָ֣ה אֱלֹהֶ֣יךָ ׀ לְ֠ךָ מִכָּל־אֹ֨יְבֶ֜יךָ מִסָּבִ֗יב בָּאָ֨רֶץ֙ אֲשֶׁ֣ר יְהוָֽה־אֱ֠לֹהֶיךָ נֹתֵ֨ן לְךָ֤ נַֽחֲלָה֙ לְרִשְׁתָּ֔הּ תִּמְחֶה֙ אֶת־זֵ֣כֶר עֲמָלֵ֔ק מִתַּ֖חַת הַשָּׁמָ֑יִם לֹ֖א תִּשְׁכָּֽח׃ {פ}"
          }
        ]
      },
      {
        "num": "כו",
        "verses": [
          {
            "num": "א",
            "txt": "וְהָיָה֙ כִּֽי־תָב֣וֹא אֶל־הָאָ֔רֶץ אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ נֹתֵ֥ן לְךָ֖ נַֽחֲלָ֑ה וִֽירִשְׁתָּ֖הּ וְיָשַׁ֥בְתָּ בָּֽהּ׃"
          },
          {
            "num": "ב",
            "txt": "וְלָֽקַחְתָּ֞ מֵֽרֵאשִׁ֣ית ׀ כָּל־פְּרִ֣י הָֽאֲדָמָ֗ה אֲשֶׁ֨ר תָּבִ֧יא מֵֽאַרְצְךָ֛ אֲשֶׁ֨ר יְהוָ֧ה אֱלֹהֶ֛יךָ נֹתֵ֥ן לָ֖ךְ וְשַׂמְתָּ֣ בַטֶּ֑נֶא וְהָֽלַכְתָּ֙ אֶל־הַמָּק֔וֹם אֲשֶׁ֤ר יִבְחַר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לְשַׁכֵּ֥ן שְׁמ֖וֹ שָֽׁם׃"
          },
          {
            "num": "ג",
            "txt": "וּבָאתָ֙ אֶל־הַכֹּהֵ֔ן אֲשֶׁ֥ר יִֽהְיֶ֖ה בַּיָּמִ֣ים הָהֵ֑ם וְאָֽמַרְתָּ֣ אֵלָ֗יו הִגַּ֤דְתִּי הַיּוֹם֙ לַֽיהוָ֣ה אֱלֹהֶ֔יךָ כִּי־בָ֨אתִי֙ אֶל־הָאָ֔רֶץ אֲשֶׁ֨ר נִשְׁבַּ֧ע יְהוָ֛ה לַֽאֲבֹתֵ֖ינוּ לָ֥תֶת לָֽנוּ׃"
          },
          {
            "num": "ד",
            "txt": "וְלָקַ֧ח הַכֹּהֵ֛ן הַטֶּ֖נֶא מִיָּדֶ֑ךָ וְהִ֨נִּיח֔וֹ לִפְנֵ֕י מִזְבַּ֖ח יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "ה",
            "txt": "וְעָנִ֨יתָ וְאָֽמַרְתָּ֜ לִפְנֵ֣י ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ אֲרַמִּי֙ אֹבֵ֣ד אָבִ֔י וַיֵּ֣רֶד מִצְרַ֔יְמָה וַיָּ֥גָר שָׁ֖ם בִּמְתֵ֣י מְעָ֑ט וַֽיְהִי־שָׁ֕ם לְג֥וֹי גָּד֖וֹל עָצ֥וּם וָרָֽב׃"
          },
          {
            "num": "ו",
            "txt": "וַיָּרֵ֧עוּ אֹתָ֛נוּ הַמִּצְרִ֖ים וַיְעַנּ֑וּנוּ וַיִּתְּנ֥וּ עָלֵ֖ינוּ עֲבֹדָ֥ה קָשָֽׁה׃"
          },
          {
            "num": "ז",
            "txt": "וַנִּצְעַ֕ק אֶל־יְהוָ֖ה אֱלֹהֵ֣י אֲבֹתֵ֑ינוּ וַיִּשְׁמַ֤ע יְהוָה֙ אֶת־קֹלֵ֔נוּ וַיַּ֧רְא אֶת־עָנְיֵ֛נוּ וְאֶת־עֲמָלֵ֖נוּ וְאֶת־לַֽחֲצֵֽנוּ׃"
          },
          {
            "num": "ח",
            "txt": "וַיּֽוֹצִאֵ֤נוּ יְהוָה֙ מִמִּצְרַ֔יִם בְּיָ֤ד חֲזָקָה֙ וּבִזְרֹ֣עַ נְטוּיָ֔ה וּבְמֹרָ֖א גָּדֹ֑ל וּבְאֹת֖וֹת וּבְמֹֽפְתִֽים׃"
          },
          {
            "num": "ט",
            "txt": "וַיְבִאֵ֖נוּ אֶל־הַמָּק֣וֹם הַזֶּ֑ה וַיִּתֶּן־לָ֨נוּ֙ אֶת־הָאָ֣רֶץ הַזֹּ֔את אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָֽשׁ׃"
          },
          {
            "num": "י",
            "txt": "וְעַתָּ֗ה הִנֵּ֤ה הֵבֵ֨אתִי֙ אֶת־רֵאשִׁית֙ פְּרִ֣י הָֽאֲדָמָ֔ה אֲשֶׁר־נָתַ֥תָּה לִּ֖י יְהוָ֑ה וְהִנַּחְתּ֗וֹ לִפְנֵי֙ יְהוָ֣ה אֱלֹהֶ֔יךָ וְהִֽשְׁתַּחֲוִ֔יתָ לִפְנֵ֖י יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "יא",
            "txt": "וְשָֽׂמַחְתָּ֣ בְכָל־הַטּ֗וֹב אֲשֶׁ֧ר נָֽתַן־לְךָ֛ יְהוָ֥ה אֱלֹהֶ֖יךָ וּלְבֵיתֶ֑ךָ אַתָּה֙ וְהַלֵּוִ֔י וְהַגֵּ֖ר אֲשֶׁ֥ר בְּקִרְבֶּֽךָ׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "כִּ֣י תְכַלֶּ֞ה לַ֠עְשֵׂר אֶת־כָּל־מַעְשַׂ֧ר תְּבוּאָֽתְךָ֛ בַּשָּׁנָ֥ה הַשְּׁלִישִׁ֖ת שְׁנַ֣ת הַֽמַּעֲשֵׂ֑ר וְנָֽתַתָּ֣ה לַלֵּוִ֗י לַגֵּר֙ לַיָּת֣וֹם וְלָֽאַלְמָנָ֔ה וְאָֽכְל֥וּ בִשְׁעָרֶ֖יךָ וְשָׂבֵֽעוּ׃"
          },
          {
            "num": "יג",
            "txt": "וְאָֽמַרְתָּ֡ לִפְנֵי֩ יְהוָ֨ה אֱלֹהֶ֜יךָ בִּעַ֧רְתִּי הַקֹּ֣דֶשׁ מִן־הַבַּ֗יִת וְגַ֨ם נְתַתִּ֤יו לַלֵּוִי֙ וְלַגֵּר֙ לַיָּת֣וֹם וְלָֽאַלְמָנָ֔ה כְּכָל־מִצְוָֽתְךָ֖ אֲשֶׁ֣ר צִוִּיתָ֑נִי לֹֽא־עָבַ֥רְתִּי מִמִּצְוֺתֶ֖יךָ וְלֹ֥א שָׁכָֽחְתִּי׃"
          },
          {
            "num": "יד",
            "txt": "לֹֽא־אָכַ֨לְתִּי בְאֹנִ֜י מִמֶּ֗נּוּ וְלֹֽא־בִעַ֤רְתִּי מִמֶּ֨נּוּ֙ בְּטָמֵ֔א וְלֹֽא־נָתַ֥תִּי מִמֶּ֖נּוּ לְמֵ֑ת שָׁמַ֗עְתִּי בְּקוֹל֙ יְהוָ֣ה אֱלֹהָ֔י עָשִׂ֕יתִי כְּכֹ֖ל אֲשֶׁ֥ר צִוִּיתָֽנִי׃"
          },
          {
            "num": "טו",
            "txt": "הַשְׁקִיפָה֩ מִמְּע֨וֹן קָדְשְׁךָ֜ מִן־הַשָּׁמַ֗יִם וּבָרֵ֤ךְ אֶֽת־עַמְּךָ֙ אֶת־יִשְׂרָאֵ֔ל וְאֵת֙ הָֽאֲדָמָ֔ה אֲשֶׁ֥ר נָתַ֖תָּה לָ֑נוּ כַּֽאֲשֶׁ֤ר נִשְׁבַּ֨עְתָּ֙ לַֽאֲבֹתֵ֔ינוּ אֶ֛רֶץ זָבַ֥ת חָלָ֖ב וּדְבָֽשׁ׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "הַיּ֣וֹם הַזֶּ֗ה יְהוָ֨ה אֱלֹהֶ֜יךָ מְצַוְּךָ֧ לַֽעֲשׂ֛וֹת אֶת־הַֽחֻקִּ֥ים הָאֵ֖לֶּה וְאֶת־הַמִּשְׁפָּטִ֑ים וְשָֽׁמַרְתָּ֤ וְעָשִׂ֨יתָ֙ אוֹתָ֔ם בְּכָל־לְבָֽבְךָ֖ וּבְכָל־נַפְשֶֽׁךָ׃"
          },
          {
            "num": "יז",
            "txt": "אֶת־יְהוָ֥ה הֶֽאֱמַ֖רְתָּ הַיּ֑וֹם לִֽהְיוֹת֩ לְךָ֨ לֵֽאלֹהִ֜ים וְלָלֶ֣כֶת בִּדְרָכָ֗יו וְלִשְׁמֹ֨ר חֻקָּ֧יו וּמִצְוֺתָ֛יו וּמִשְׁפָּטָ֖יו וְלִשְׁמֹ֥עַ בְּקֹלֽוֹ׃"
          },
          {
            "num": "יח",
            "txt": "וַֽיהוָ֞ה הֶאֱמִֽירְךָ֣ הַיּ֗וֹם לִֽהְי֥וֹת לוֹ֙ לְעַ֣ם סְגֻלָּ֔ה כַּֽאֲשֶׁ֖ר דִּבֶּר־לָ֑ךְ וְלִשְׁמֹ֖ר כָּל־מִצְוֺתָֽיו׃"
          },
          {
            "num": "יט",
            "txt": "וּֽלְתִתְּךָ֣ עֶלְי֗וֹן עַ֤ל כָּל־הַגּוֹיִם֙ אֲשֶׁ֣ר עָשָׂ֔ה לִתְהִלָּ֖ה וּלְשֵׁ֣ם וּלְתִפְאָ֑רֶת וְלִֽהְיֹתְךָ֧ עַם־קָדֹ֛שׁ לַֽיהוָ֥ה אֱלֹהֶ֖יךָ כַּֽאֲשֶׁ֥ר דִּבֵּֽר׃ {פ}"
          }
        ]
      },
      {
        "num": "כז",
        "verses": [
          {
            "num": "א",
            "txt": "וַיְצַ֤ו מֹשֶׁה֙ וְזִקְנֵ֣י יִשְׂרָאֵ֔ל אֶת־הָעָ֖ם לֵאמֹ֑ר שָׁמֹר֙ אֶת־כָּל־הַמִּצְוָ֔ה אֲשֶׁ֧ר אָֽנֹכִ֛י מְצַוֶּ֥ה אֶתְכֶ֖ם הַיּֽוֹם׃"
          },
          {
            "num": "ב",
            "txt": "וְהָיָ֗ה בַּיּוֹם֮ אֲשֶׁ֣ר תַּֽעַבְר֣וּ אֶת־הַיַּרְדֵּן֒ אֶל־הָאָ֕רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֣ן לָ֑ךְ וַהֲקֵֽמֹתָ֤ לְךָ֙ אֲבָנִ֣ים גְּדֹל֔וֹת וְשַׂדְתָּ֥ אֹתָ֖ם בַּשִּֽׂיד׃"
          },
          {
            "num": "ג",
            "txt": "וְכָֽתַבְתָּ֣ עֲלֵיהֶ֗ן אֶֽת־כָּל־דִּבְרֵ֛י הַתּוֹרָ֥ה הַזֹּ֖את בְּעָבְרֶ֑ךָ לְמַ֡עַן אֲשֶׁר֩ תָּבֹ֨א אֶל־הָאָ֜רֶץ אֲֽשֶׁר־יְהוָ֥ה אֱלֹהֶ֣יךָ ׀ נֹתֵ֣ן לְךָ֗ אֶ֣רֶץ זָבַ֤ת חָלָב֙ וּדְבַ֔שׁ כַּֽאֲשֶׁ֥ר דִּבֶּ֛ר יְהוָ֥ה אֱלֹהֵֽי־אֲבֹתֶ֖יךָ לָֽךְ׃"
          },
          {
            "num": "ד",
            "txt": "וְהָיָה֮ בְּעָבְרְכֶ֣ם אֶת־הַיַּרְדֵּן֒ תָּקִ֜ימוּ אֶת־הָֽאֲבָנִ֣ים הָאֵ֗לֶּה אֲשֶׁ֨ר אָֽנֹכִ֜י מְצַוֶּ֥ה אֶתְכֶ֛ם הַיּ֖וֹם בְּהַ֣ר עֵיבָ֑ל וְשַׂדְתָּ֥ אוֹתָ֖ם בַּשִּֽׂיד׃"
          },
          {
            "num": "ה",
            "txt": "וּבָנִ֤יתָ שָּׁם֙ מִזְבֵּ֔חַ לַֽיהוָ֖ה אֱלֹהֶ֑יךָ מִזְבַּ֣ח אֲבָנִ֔ים לֹֽא־תָנִ֥יף עֲלֵיהֶ֖ם בַּרְזֶֽל׃"
          },
          {
            "num": "ו",
            "txt": "אֲבָנִ֤ים שְׁלֵמוֹת֙ תִּבְנֶ֔ה אֶת־מִזְבַּ֖ח יְהוָ֣ה אֱלֹהֶ֑יךָ וְהַֽעֲלִ֤יתָ עָלָיו֙ עוֹלֹ֔ת לַֽיהוָ֖ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "ז",
            "txt": "וְזָֽבַחְתָּ֥ שְׁלָמִ֖ים וְאָכַ֣לְתָּ שָּׁ֑ם וְשָׂ֣מַחְתָּ֔ לִפְנֵ֖י יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "ח",
            "txt": "וְכָֽתַבְתָּ֣ עַל־הָֽאֲבָנִ֗ים אֶֽת־כָּל־דִּבְרֵ֛י הַתּוֹרָ֥ה הַזֹּ֖את בַּאֵ֥ר הֵיטֵֽב׃ {ס}"
          },
          {
            "num": "ט",
            "txt": "וַיְדַבֵּ֤ר מֹשֶׁה֙ וְהַכֹּֽהֲנִ֣ים הַלְוִיִּ֔ם אֶ֥ל כָּל־יִשְׂרָאֵ֖ל לֵאמֹ֑ר הַסְכֵּ֤ת ׀ וּשְׁמַע֙ יִשְׂרָאֵ֔ל הַיּ֤וֹם הַזֶּה֙ נִֽהְיֵ֣יתָֽ לְעָ֔ם לַֽיהוָ֖ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "י",
            "txt": "וְשָׁ֣מַעְתָּ֔ בְּק֖וֹל יְהוָ֣ה אֱלֹהֶ֑יךָ וְעָשִׂ֤יתָ אֶת־מִצְוֺתָו֙ וְאֶת־חֻקָּ֔יו אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּֽוֹם׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "וַיְצַ֤ו מֹשֶׁה֙ אֶת־הָעָ֔ם בַּיּ֥וֹם הַה֖וּא לֵאמֹֽר׃"
          },
          {
            "num": "יב",
            "txt": "אֵ֠לֶּה יַֽעַמְד֞וּ לְבָרֵ֤ךְ אֶת־הָעָם֙ עַל־הַ֣ר גְּרִזִ֔ים בְּעָבְרְכֶ֖ם אֶת־הַיַּרְדֵּ֑ן שִׁמְעוֹן֙ וְלֵוִ֣י וִֽיהוּדָ֔ה וְיִשָּׂשכָ֖ר וְיוֹסֵ֥ף וּבִנְיָמִֽן׃"
          },
          {
            "num": "יג",
            "txt": "וְאֵ֛לֶּה יַֽעַמְד֥וּ עַל־הַקְּלָלָ֖ה בְּהַ֣ר עֵיבָ֑ל רְאוּבֵן֙ גָּ֣ד וְאָשֵׁ֔ר וּזְבוּלֻ֖ן דָּ֥ן וְנַפְתָּלִֽי׃"
          },
          {
            "num": "יד",
            "txt": "וְעָנ֣וּ הַלְוִיִּ֗ם וְאָ֥מְר֛וּ אֶל־כָּל־אִ֥ישׁ יִשְׂרָאֵ֖ל ק֥וֹל רָֽם׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "אָר֣וּר הָאִ֡ישׁ אֲשֶׁ֣ר יַֽעֲשֶׂה֩ פֶ֨סֶל וּמַסֵּכָ֜ה תּֽוֹעֲבַ֣ת יְהוָ֗ה מַֽעֲשֵׂ֛ה יְדֵ֥י חָרָ֖שׁ וְשָׂ֣ם בַּסָּ֑תֶר וְעָנ֧וּ כָל־הָעָ֛ם וְאָֽמְר֖וּ אָמֵֽן׃ {ס}"
          },
          {
            "num": "טז",
            "txt": "אָר֕וּר מַקְלֶ֥ה אָבִ֖יו וְאִמּ֑וֹ וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "יז",
            "txt": "אָר֕וּר מַסִּ֖יג גְּב֣וּל רֵעֵ֑הוּ וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "אָר֕וּר מַשְׁגֶּ֥ה עִוֵּ֖ר בַּדָּ֑רֶךְ וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "יט",
            "txt": "אָר֗וּר מַטֶּ֛ה מִשְׁפַּ֥ט גֵּר־יָת֖וֹם וְאַלְמָנָ֑ה וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃"
          },
          {
            "num": "כ",
            "txt": "אָר֗וּר שֹׁכֵב֙ עִם־אֵ֣שֶׁת אָבִ֔יו כִּ֥י גִלָּ֖ה כְּנַ֣ף אָבִ֑יו וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "כא",
            "txt": "אָר֕וּר שֹׁכֵ֖ב עִם־כָּל־בְּהֵמָ֑ה וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "אָר֗וּר שֹׁכֵב֙ עִם־אֲחֹת֔וֹ בַּת־אָבִ֖יו א֣וֹ בַת־אִמּ֑וֹ וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "כג",
            "txt": "אָר֕וּר שֹׁכֵ֖ב עִם־חֹֽתַנְתּ֑וֹ וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "כד",
            "txt": "אָר֕וּר מַכֵּ֥ה רֵעֵ֖הוּ בַּסָּ֑תֶר וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "כה",
            "txt": "אָרוּר֙ לֹקֵ֣חַ שֹׁ֔חַד לְהַכּ֥וֹת נֶ֖פֶשׁ דָּ֣ם נָקִ֑י וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {ס}"
          },
          {
            "num": "כו",
            "txt": "אָר֗וּר אֲשֶׁ֧ר לֹֽא־יָקִ֛ים אֶת־דִּבְרֵ֥י הַתּוֹרָֽה־הַזֹּ֖את לַֽעֲשׂ֣וֹת אוֹתָ֑ם וְאָמַ֥ר כָּל־הָעָ֖ם אָמֵֽן׃ {פ}"
          }
        ]
      },
      {
        "num": "כח",
        "verses": [
          {
            "num": "א",
            "txt": "וְהָיָ֗ה אִם־שָׁמ֤וֹעַ תִּשְׁמַע֙ בְּקוֹל֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לִשְׁמֹ֤ר לַֽעֲשׂוֹת֙ אֶת־כָּל־מִצְוֺתָ֔יו אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּ֑וֹם וּנְתָ֨נְךָ֜ יְהוָ֤ה אֱלֹהֶ֨יךָ֙ עֶלְי֔וֹן עַ֖ל כָּל־גּוֹיֵ֥י הָאָֽרֶץ׃"
          },
          {
            "num": "ב",
            "txt": "וּבָ֧אוּ עָלֶ֛יךָ כָּל־הַבְּרָכ֥וֹת הָאֵ֖לֶּה וְהִשִּׂיגֻ֑ךָ כִּ֣י תִשְׁמַ֔ע בְּק֖וֹל יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "ג",
            "txt": "בָּר֥וּךְ אַתָּ֖ה בָּעִ֑יר וּבָר֥וּךְ אַתָּ֖ה בַּשָּׂדֶֽה׃"
          },
          {
            "num": "ד",
            "txt": "בָּר֧וּךְ פְּרִֽי־בִטְנְךָ֛ וּפְרִ֥י אַדְמָֽתְךָ֖ וּפְרִ֣י בְהֶמְתֶּ֑ךָ שְׁגַ֥ר אֲלָפֶ֖יךָ וְעַשְׁתְּר֥וֹת צֹאנֶֽךָ׃"
          },
          {
            "num": "ה",
            "txt": "בָּר֥וּךְ טַנְאֲךָ֖ וּמִשְׁאַרְתֶּֽךָ׃"
          },
          {
            "num": "ו",
            "txt": "בָּר֥וּךְ אַתָּ֖ה בְּבֹאֶ֑ךָ וּבָר֥וּךְ אַתָּ֖ה בְּצֵאתֶֽךָ׃"
          },
          {
            "num": "ז",
            "txt": "יִתֵּ֨ן יְהוָ֤ה אֶת־אֹֽיְבֶ֨יךָ֙ הַקָּמִ֣ים עָלֶ֔יךָ נִגָּפִ֖ים לְפָנֶ֑יךָ בְּדֶ֤רֶךְ אֶחָד֙ יֵֽצְא֣וּ אֵלֶ֔יךָ וּבְשִׁבְעָ֥ה דְרָכִ֖ים יָנ֥וּסוּ לְפָנֶֽיךָ׃"
          },
          {
            "num": "ח",
            "txt": "יְצַ֨ו יְהוָ֤ה אִתְּךָ֙ אֶת־הַבְּרָכָ֔ה בַּֽאֲסָמֶ֕יךָ וּבְכֹ֖ל מִשְׁלַ֣ח יָדֶ֑ךָ וּבֵ֣רַכְךָ֔ בָּאָ֕רֶץ אֲשֶׁר־יְהוָ֥ה אֱלֹהֶ֖יךָ נֹתֵ֥ן לָֽךְ׃"
          },
          {
            "num": "ט",
            "txt": "יְקִֽימְךָ֨ יְהוָ֥ה לוֹ֙ לְעַ֣ם קָד֔וֹשׁ כַּֽאֲשֶׁ֖ר נִֽשְׁבַּֽע־לָ֑ךְ כִּ֣י תִשְׁמֹ֗ר אֶת־מִצְוֺת֙ יְהוָ֣ה אֱלֹהֶ֔יךָ וְהָֽלַכְתָּ֖ בִּדְרָכָֽיו׃"
          },
          {
            "num": "י",
            "txt": "וְרָאוּ֙ כָּל־עַמֵּ֣י הָאָ֔רֶץ כִּ֛י שֵׁ֥ם יְהוָ֖ה נִקְרָ֣א עָלֶ֑יךָ וְיָֽרְא֖וּ מִמֶּֽךָּ׃"
          },
          {
            "num": "יא",
            "txt": "וְהוֹתִֽרְךָ֤ יְהוָה֙ לְטוֹבָ֔ה בִּפְרִ֧י בִטְנְךָ֛ וּבִפְרִ֥י בְהֶמְתְּךָ֖ וּבִפְרִ֣י אַדְמָתֶ֑ךָ עַ֚ל הָֽאֲדָמָ֔ה אֲשֶׁ֨ר נִשְׁבַּ֧ע יְהוָ֛ה לַֽאֲבֹתֶ֖יךָ לָ֥תֶת לָֽךְ׃"
          },
          {
            "num": "יב",
            "txt": "יִפְתַּ֣ח יְהוָ֣ה ׀ לְ֠ךָ אֶת־אֽוֹצָר֨וֹ הַטּ֜וֹב אֶת־הַשָּׁמַ֗יִם לָתֵ֤ת מְטַֽר־אַרְצְךָ֙ בְּעִתּ֔וֹ וּלְבָרֵ֕ךְ אֵ֖ת כָּל־מַֽעֲשֵׂ֣ה יָדֶ֑ךָ וְהִלְוִ֨יתָ֙ גּוֹיִ֣ם רַבִּ֔ים וְאַתָּ֖ה לֹ֥א תִלְוֶֽה׃"
          },
          {
            "num": "יג",
            "txt": "וּנְתָֽנְךָ֨ יְהוָ֤ה לְרֹאשׁ֙ וְלֹ֣א לְזָנָ֔ב וְהָיִ֨יתָ֙ רַ֣ק לְמַ֔עְלָה וְלֹ֥א תִֽהְיֶ֖ה לְמָ֑טָּה כִּֽי־תִשְׁמַ֞ע אֶל־מִצְוֺ֣ת ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ אֲשֶׁ֨ר אָֽנֹכִ֧י מְצַוְּךָ֛ הַיּ֖וֹם לִשְׁמֹ֥ר וְלַֽעֲשֽׂוֹת׃"
          },
          {
            "num": "יד",
            "txt": "וְלֹ֣א תָס֗וּר מִכָּל־הַדְּבָרִים֙ אֲשֶׁ֨ר אָֽנֹכִ֜י מְצַוֶּ֥ה אֶתְכֶ֛ם הַיּ֖וֹם יָמִ֣ין וּשְׂמֹ֑אול לָלֶ֗כֶת אַֽחֲרֵ֛י אֱלֹהִ֥ים אֲחֵרִ֖ים לְעָבְדָֽם׃ {פ}"
          },
          {
            "num": "טו",
            "txt": "וְהָיָ֗ה אִם־לֹ֤א תִשְׁמַע֙ בְּקוֹל֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לִשְׁמֹ֤ר לַֽעֲשׂוֹת֙ אֶת־כָּל־מִצְוֺתָ֣יו וְחֻקֹּתָ֔יו אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּ֑וֹם וּבָ֧אוּ עָלֶ֛יךָ כָּל־הַקְּלָל֥וֹת הָאֵ֖לֶּה וְהִשִּׂיגֽוּךָ׃"
          },
          {
            "num": "טז",
            "txt": "אָר֥וּר אַתָּ֖ה בָּעִ֑יר וְאָר֥וּר אַתָּ֖ה בַּשָּׂדֶֽה׃"
          },
          {
            "num": "יז",
            "txt": "אָר֥וּר טַנְאֲךָ֖ וּמִשְׁאַרְתֶּֽךָ׃"
          },
          {
            "num": "יח",
            "txt": "אָר֥וּר פְּרִֽי־בִטְנְךָ֖ וּפְרִ֣י אַדְמָתֶ֑ךָ שְׁגַ֥ר אֲלָפֶ֖יךָ וְעַשְׁתְּרֹ֥ת צֹאנֶֽךָ׃"
          },
          {
            "num": "יט",
            "txt": "אָר֥וּר אַתָּ֖ה בְּבֹאֶ֑ךָ וְאָר֥וּר אַתָּ֖ה בְּצֵאתֶֽךָ׃"
          },
          {
            "num": "כ",
            "txt": "יְשַׁלַּ֣ח יְהוָ֣ה ׀ בְּ֠ךָ אֶת־הַמְּאֵרָ֤ה אֶת־הַמְּהוּמָה֙ וְאֶת־הַמִּגְעֶ֔רֶת בְּכָל־מִשְׁלַ֥ח יָֽדְךָ֖ אֲשֶׁ֣ר תַּֽעֲשֶׂ֑ה עַ֣ד הִשָּֽׁמֶדְךָ֤ וְעַד־אֲבָדְךָ֙ מַהֵ֔ר מִפְּנֵ֛י רֹ֥עַ מַֽעֲלָלֶ֖יךָ אֲשֶׁ֥ר עֲזַבְתָּֽנִי׃"
          },
          {
            "num": "כא",
            "txt": "יַדְבֵּ֧ק יְהוָ֛ה בְּךָ֖ אֶת־הַדָּ֑בֶר עַ֚ד כַּלֹּת֣וֹ אֹֽתְךָ֔ מֵעַל֙ הָֽאֲדָמָ֔ה אֲשֶׁר־אַתָּ֥ה בָא־שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "כב",
            "txt": "יַכְּכָ֣ה יְ֠הוָה בַּשַּׁחֶ֨פֶת וּבַקַּדַּ֜חַת וּבַדַּלֶּ֗קֶת וּבַֽחַרְחֻר֙ וּבַחֶ֔רֶב וּבַשִּׁדָּפ֖וֹן וּבַיֵּֽרָק֑וֹן וּרְדָפ֖וּךָ עַ֥ד אָבְדֶֽךָ׃"
          },
          {
            "num": "כג",
            "txt": "וְהָי֥וּ שָׁמֶ֛יךָ אֲשֶׁ֥ר עַל־רֹֽאשְׁךָ֖ נְחֹ֑שֶׁת וְהָאָ֥רֶץ אֲשֶׁר־תַּחְתֶּ֖יךָ בַּרְזֶֽל׃"
          },
          {
            "num": "כד",
            "txt": "יִתֵּ֧ן יְהוָ֛ה אֶת־מְטַ֥ר אַרְצְךָ֖ אָבָ֣ק וְעָפָ֑ר מִן־הַשָּׁמַ֨יִם֙ יֵרֵ֣ד עָלֶ֔יךָ עַ֖ד הִשָּֽׁמְדָֽךְ׃"
          },
          {
            "num": "כה",
            "txt": "יִתֶּנְךָ֨ יְהוָ֥ה ׀ נִגָּף֮ לִפְנֵ֣י אֹֽיְבֶיךָ֒ בְּדֶ֤רֶךְ אֶחָד֙ תֵּצֵ֣א אֵלָ֔יו וּבְשִׁבְעָ֥ה דְרָכִ֖ים תָּנ֣וּס לְפָנָ֑יו וְהָיִ֣יתָ לְזַֽעֲוָ֔ה לְכֹ֖ל מַמְלְכ֥וֹת הָאָֽרֶץ׃"
          },
          {
            "num": "כו",
            "txt": "וְהָֽיְתָ֤ה נִבְלָֽתְךָ֙ לְמַֽאֲכָ֔ל לְכָל־ע֥וֹף הַשָּׁמַ֖יִם וּלְבֶֽהֱמַ֣ת הָאָ֑רֶץ וְאֵ֖ין מַֽחֲרִֽיד׃"
          },
          {
            "num": "כז",
            "txt": "יַכְּכָ֨ה יְהוָ֜ה בִּשְׁחִ֤ין מִצְרַ֨יִם֙ ובעפלים (וּבַטְּחֹרִ֔ים) וּבַגָּרָ֖ב וּבֶחָ֑רֶס אֲשֶׁ֥ר לֹֽא־תוּכַ֖ל לְהֵֽרָפֵֽא׃"
          },
          {
            "num": "כח",
            "txt": "יַכְּכָ֣ה יְהוָ֔ה בְּשִׁגָּע֖וֹן וּבְעִוָּר֑וֹן וּבְתִמְה֖וֹן לֵבָֽב׃"
          },
          {
            "num": "כט",
            "txt": "וְהָיִ֜יתָ מְמַשֵּׁ֣שׁ בַּֽצָּהֳרַ֗יִם כַּֽאֲשֶׁ֨ר יְמַשֵּׁ֤שׁ הַֽעִוֵּר֙ בָּֽאֲפֵלָ֔ה וְלֹ֥א תַצְלִ֖יחַ אֶת־דְּרָכֶ֑יךָ וְהָיִ֜יתָ אַ֣ךְ עָשׁ֧וּק וְגָז֛וּל כָּל־הַיָּמִ֖ים וְאֵ֥ין מוֹשִֽׁיעַ׃"
          },
          {
            "num": "ל",
            "txt": "אִשָּׁ֣ה תְאָרֵ֗שׂ וְאִ֤ישׁ אַחֵר֙ ישגלנה (יִשְׁכָּבֶ֔נָּה) בַּ֥יִת תִּבְנֶ֖ה וְלֹֽא־תֵשֵׁ֣ב בּ֑וֹ כֶּ֥רֶם תִּטַּ֖ע וְלֹ֥א תְחַלְּלֶֽנּוּ׃"
          },
          {
            "num": "לא",
            "txt": "שֽׁוֹרְךָ֞ טָב֣וּחַ לְעֵינֶ֗יךָ וְלֹ֣א תֹאכַל֮ מִמֶּנּוּ֒ חֲמֹֽרְךָ֙ גָּז֣וּל מִלְּפָנֶ֔יךָ וְלֹ֥א יָשׁ֖וּב לָ֑ךְ צֹֽאנְךָ֙ נְתֻנ֣וֹת לְאֹֽיְבֶ֔יךָ וְאֵ֥ין לְךָ֖ מוֹשִֽׁיעַ׃"
          },
          {
            "num": "לב",
            "txt": "בָּנֶ֨יךָ וּבְנֹתֶ֜יךָ נְתֻנִ֨ים לְעַ֤ם אַחֵר֙ וְעֵינֶ֣יךָ רֹא֔וֹת וְכָל֥וֹת אֲלֵיהֶ֖ם כָּל־הַיּ֑וֹם וְאֵ֥ין לְאֵ֖ל יָדֶֽךָ׃"
          },
          {
            "num": "לג",
            "txt": "פְּרִ֤י אַדְמָֽתְךָ֙ וְכָל־יְגִ֣יעֲךָ֔ יֹאכַ֥ל עַ֖ם אֲשֶׁ֣ר לֹֽא־יָדָ֑עְתָּ וְהָיִ֗יתָ רַ֛ק עָשׁ֥וּק וְרָצ֖וּץ כָּל־הַיָּמִֽים׃"
          },
          {
            "num": "לד",
            "txt": "וְהָיִ֖יתָ מְשֻׁגָּ֑ע מִמַּרְאֵ֥ה עֵינֶ֖יךָ אֲשֶׁ֥ר תִּרְאֶֽה׃"
          },
          {
            "num": "לה",
            "txt": "יַכְּכָ֨ה יְהוָ֜ה בִּשְׁחִ֣ין רָ֗ע עַל־הַבִּרְכַּ֨יִם֙ וְעַל־הַשֹּׁקַ֔יִם אֲשֶׁ֥ר לֹֽא־תוּכַ֖ל לְהֵֽרָפֵ֑א מִכַּ֥ף רַגְלְךָ֖ וְעַ֥ד קָדְקֳדֶֽךָ׃"
          },
          {
            "num": "לו",
            "txt": "יוֹלֵ֨ךְ יְהוָ֜ה אֹֽתְךָ֗ וְאֶֽת־מַלְכְּךָ֙ אֲשֶׁ֣ר תָּקִ֣ים עָלֶ֔יךָ אֶל־גּ֕וֹי אֲשֶׁ֥ר לֹֽא־יָדַ֖עְתָּ אַתָּ֣ה וַֽאֲבֹתֶ֑יךָ וְעָבַ֥דְתָּ שָּׁ֛ם אֱלֹהִ֥ים אֲחֵרִ֖ים עֵ֥ץ וָאָֽבֶן׃"
          },
          {
            "num": "לז",
            "txt": "וְהָיִ֣יתָ לְשַׁמָּ֔ה לְמָשָׁ֖ל וְלִשְׁנִינָ֑ה בְּכֹל֙ הָֽעַמִּ֔ים אֲשֶׁר־יְנַֽהֶגְךָ֥ יְהוָ֖ה שָֽׁמָּה׃"
          },
          {
            "num": "לח",
            "txt": "זֶ֥רַע רַ֖ב תּוֹצִ֣יא הַשָּׂדֶ֑ה וּמְעַ֣ט תֶּֽאֱסֹ֔ף כִּ֥י יַחְסְלֶ֖נּוּ הָֽאַרְבֶּֽה׃"
          },
          {
            "num": "לט",
            "txt": "כְּרָמִ֥ים תִּטַּ֖ע וְעָבָ֑דְתָּ וְיַ֤יִן לֹֽא־תִשְׁתֶּה֙ וְלֹ֣א תֶֽאֱגֹ֔ר כִּ֥י תֹֽאכְלֶ֖נּוּ הַתֹּלָֽעַת׃"
          },
          {
            "num": "מ",
            "txt": "זֵיתִ֛ים יִֽהְי֥וּ לְךָ֖ בְּכָל־גְּבוּלֶ֑ךָ וְשֶׁ֨מֶן֙ לֹ֣א תָס֔וּךְ כִּ֥י יִשַּׁ֖ל זֵיתֶֽךָ׃"
          },
          {
            "num": "מא",
            "txt": "בָּנִ֥ים וּבָנ֖וֹת תּוֹלִ֑יד וְלֹֽא־יִהְי֣וּ לָ֔ךְ כִּ֥י יֵֽלְכ֖וּ בַּשֶּֽׁבִי׃"
          },
          {
            "num": "מב",
            "txt": "כָּל־עֵֽצְךָ֖ וּפְרִ֣י אַדְמָתֶ֑ךָ יְיָרֵ֖שׁ הַצְּלָצַֽל׃"
          },
          {
            "num": "מג",
            "txt": "הַגֵּר֙ אֲשֶׁ֣ר בְּקִרְבְּךָ֔ יַֽעֲלֶ֥ה עָלֶ֖יךָ מַ֣עְלָה מָּ֑עְלָה וְאַתָּ֥ה תֵרֵ֖ד מַ֥טָּה מָּֽטָּה׃"
          },
          {
            "num": "מד",
            "txt": "ה֣וּא יַלְוְךָ֔ וְאַתָּ֖ה לֹ֣א תַלְוֶ֑נּוּ ה֚וּא יִֽהְיֶ֣ה לְרֹ֔אשׁ וְאַתָּ֖ה תִּֽהְיֶ֥ה לְזָנָֽב׃"
          },
          {
            "num": "מה",
            "txt": "וּבָ֨אוּ עָלֶ֜יךָ כָּל־הַקְּלָל֣וֹת הָאֵ֗לֶּה וּרְדָפ֨וּךָ֙ וְהִשִּׂיג֔וּךָ עַ֖ד הִשָּֽׁמְדָ֑ךְ כִּי־לֹ֣א שָׁמַ֗עְתָּ בְּקוֹל֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לִשְׁמֹ֛ר מִצְוֺתָ֥יו וְחֻקֹּתָ֖יו אֲשֶׁ֥ר צִוָּֽךְ׃"
          },
          {
            "num": "מו",
            "txt": "וְהָי֣וּ בְךָ֔ לְא֖וֹת וּלְמוֹפֵ֑ת וּֽבְזַרְעֲךָ֖ עַד־עוֹלָֽם׃"
          },
          {
            "num": "מז",
            "txt": "תַּ֗חַת אֲשֶׁ֤ר לֹֽא־עָבַ֨דְתָּ֙ אֶת־יְהוָ֣ה אֱלֹהֶ֔יךָ בְּשִׂמְחָ֖ה וּבְט֣וּב לֵבָ֑ב מֵרֹ֖ב כֹּֽל׃"
          },
          {
            "num": "מח",
            "txt": "וְעָֽבַדְתָּ֣ אֶת־אֹֽיְבֶ֗יךָ אֲשֶׁ֨ר יְשַׁלְּחֶ֤נּוּ יְהוָה֙ בָּ֔ךְ בְּרָעָ֧ב וּבְצָמָ֛א וּבְעֵירֹ֖ם וּבְחֹ֣סֶר כֹּ֑ל וְנָתַ֞ן עֹ֤ל בַּרְזֶל֙ עַל־צַוָּארֶ֔ךָ עַ֥ד הִשְׁמִיד֖וֹ אֹתָֽךְ׃"
          },
          {
            "num": "מט",
            "txt": "יִשָּׂ֣א יְהוָה֩ עָלֶ֨יךָ גּ֤וֹי מֵֽרָחֹק֙ מִקְצֵ֣ה הָאָ֔רֶץ כַּֽאֲשֶׁ֥ר יִדְאֶ֖ה הַנָּ֑שֶׁר גּ֕וֹי אֲשֶׁ֥ר לֹֽא־תִשְׁמַ֖ע לְשֹׁנֽוֹ׃"
          },
          {
            "num": "נ",
            "txt": "גּ֖וֹי עַ֣ז פָּנִ֑ים אֲשֶׁ֨ר לֹֽא־יִשָּׂ֤א פָנִים֙ לְזָקֵ֔ן וְנַ֖עַר לֹ֥א יָחֹֽן׃"
          },
          {
            "num": "נא",
            "txt": "וְ֠אָכַל פְּרִ֨י בְהֶמְתְּךָ֥ וּפְרִֽי־אַדְמָתְךָ֮ עַ֣ד הִשָּֽׁמְדָךְ֒ אֲשֶׁ֨ר לֹֽא־יַשְׁאִ֜יר לְךָ֗ דָּגָן֙ תִּיר֣וֹשׁ וְיִצְהָ֔ר שְׁגַ֥ר אֲלָפֶ֖יךָ וְעַשְׁתְּרֹ֣ת צֹאנֶ֑ךָ עַ֥ד הַֽאֲבִיד֖וֹ אֹתָֽךְ׃"
          },
          {
            "num": "נב",
            "txt": "וְהֵצַ֨ר לְךָ֜ בְּכָל־שְׁעָרֶ֗יךָ עַ֣ד רֶ֤דֶת חֹֽמֹתֶ֨יךָ֙ הַגְּבֹהֹ֣ת וְהַבְּצֻר֔וֹת אֲשֶׁ֥ר אַתָּ֛ה בֹּטֵ֥חַ בָּהֵ֖ן בְּכָל־אַרְצֶ֑ךָ וְהֵצַ֤ר לְךָ֙ בְּכָל־שְׁעָרֶ֔יךָ בְּכָ֨ל־אַרְצְךָ֔ אֲשֶׁ֥ר נָתַ֛ן יְהוָ֥ה אֱלֹהֶ֖יךָ לָֽךְ׃"
          },
          {
            "num": "נג",
            "txt": "וְאָֽכַלְתָּ֣ פְרִֽי־בִטְנְךָ֗ בְּשַׂ֤ר בָּנֶ֨יךָ֙ וּבְנֹתֶ֔יךָ אֲשֶׁ֥ר נָֽתַן־לְךָ֖ יְהוָ֣ה אֱלֹהֶ֑יךָ בְּמָצוֹר֙ וּבְמָצ֔וֹק אֲשֶׁר־יָצִ֥יק לְךָ֖ אֹֽיְבֶֽךָ׃"
          },
          {
            "num": "נד",
            "txt": "הָאִישׁ֙ הָרַ֣ךְ בְּךָ֔ וְהֶֽעָנֹ֖ג מְאֹ֑ד תֵּרַ֨ע עֵינ֤וֹ בְאָחִיו֙ וּבְאֵ֣שֶׁת חֵיק֔וֹ וּבְיֶ֥תֶר בָּנָ֖יו אֲשֶׁ֥ר יוֹתִֽיר׃"
          },
          {
            "num": "נה",
            "txt": "מִתֵּ֣ת ׀ לְאַחַ֣ד מֵהֶ֗ם מִבְּשַׂ֤ר בָּנָיו֙ אֲשֶׁ֣ר יֹאכֵ֔ל מִבְּלִ֥י הִשְׁאִֽיר־ל֖וֹ כֹּ֑ל בְּמָצוֹר֙ וּבְמָצ֔וֹק אֲשֶׁ֨ר יָצִ֥יק לְךָ֛ אֹֽיִבְךָ֖ בְּכָל־שְׁעָרֶֽיךָ׃"
          },
          {
            "num": "נו",
            "txt": "הָֽרַכָּ֨ה בְךָ֜ וְהָֽעֲנֻגָּ֗ה אֲשֶׁ֨ר לֹֽא־נִסְּתָ֤ה כַף־רַגְלָהּ֙ הַצֵּ֣ג עַל־הָאָ֔רֶץ מֵֽהִתְעַנֵּ֖ג וּמֵרֹ֑ךְ תֵּרַ֤ע עֵינָהּ֙ בְּאִ֣ישׁ חֵיקָ֔הּ וּבִבְנָ֖הּ וּבְבִתָּֽהּ׃"
          },
          {
            "num": "נז",
            "txt": "וּֽבְשִׁלְיָתָ֞הּ הַיּוֹצֵ֣ת ׀ מִבֵּ֣ין רַגְלֶ֗יהָ וּבְבָנֶ֨יהָ֙ אֲשֶׁ֣ר תֵּלֵ֔ד כִּֽי־תֹאכְלֵ֥ם בְּחֹֽסֶר־כֹּ֖ל בַּסָּ֑תֶר בְּמָצוֹר֙ וּבְמָצ֔וֹק אֲשֶׁ֨ר יָצִ֥יק לְךָ֛ אֹֽיִבְךָ֖ בִּשְׁעָרֶֽיךָ׃"
          },
          {
            "num": "נח",
            "txt": "אִם־לֹ֨א תִשְׁמֹ֜ר לַֽעֲשׂ֗וֹת אֶת־כָּל־דִּבְרֵי֙ הַתּוֹרָ֣ה הַזֹּ֔את הַכְּתֻבִ֖ים בַּסֵּ֣פֶר הַזֶּ֑ה לְ֠יִרְאָה אֶת־הַשֵּׁ֞ם הַנִּכְבָּ֤ד וְהַנּוֹרָא֙ הַזֶּ֔ה אֵ֖ת יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "נט",
            "txt": "וְהִפְלָ֤א יְהוָה֙ אֶת־מַכֹּ֣תְךָ֔ וְאֵ֖ת מַכּ֣וֹת זַרְעֶ֑ךָ מַכּ֤וֹת גְּדֹלֹת֙ וְנֶ֣אֱמָנ֔וֹת וָֽחֳלָיִ֖ם רָעִ֥ים וְנֶֽאֱמָנִֽים׃"
          },
          {
            "num": "ס",
            "txt": "וְהֵשִׁ֣יב בְּךָ֗ אֵ֚ת כָּל־מַדְוֵ֣ה מִצְרַ֔יִם אֲשֶׁ֥ר יָגֹ֖רְתָּ מִפְּנֵיהֶ֑ם וְדָֽבְק֖וּ בָּֽךְ׃"
          },
          {
            "num": "סא",
            "txt": "גַּ֤ם כָּל־חֳלִי֙ וְכָל־מַכָּ֔ה אֲשֶׁר֙ לֹ֣א כָת֔וּב בְּסֵ֖פֶר הַתּוֹרָ֣ה הַזֹּ֑את יַעְלֵ֤ם יְהוָה֙ עָלֶ֔יךָ עַ֖ד הִשָּֽׁמְדָֽךְ׃"
          },
          {
            "num": "סב",
            "txt": "וְנִשְׁאַרְתֶּם֙ בִּמְתֵ֣י מְעָ֔ט תַּ֚חַת אֲשֶׁ֣ר הֱיִיתֶ֔ם כְּכֽוֹכְבֵ֥י הַשָּׁמַ֖יִם לָרֹ֑ב כִּֽי־לֹ֣א שָׁמַ֔עְתָּ בְּק֖וֹל יְהוָ֥ה אֱלֹהֶֽיךָ׃"
          },
          {
            "num": "סג",
            "txt": "וְ֠הָיָה כַּֽאֲשֶׁר־שָׂ֨שׂ יְהוָ֜ה עֲלֵיכֶ֗ם לְהֵיטִ֣יב אֶתְכֶם֮ וּלְהַרְבּ֣וֹת אֶתְכֶם֒ כֵּ֣ן יָשִׂ֤ישׂ יְהוָה֙ עֲלֵיכֶ֔ם לְהַֽאֲבִ֥יד אֶתְכֶ֖ם וּלְהַשְׁמִ֣יד אֶתְכֶ֑ם וְנִסַּחְתֶּם֙ מֵעַ֣ל הָֽאֲדָמָ֔ה אֲשֶׁר־אַתָּ֥ה בָא־שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "סד",
            "txt": "וֶהֱפִֽיצְךָ֤ יְהוָה֙ בְּכָל־הָ֣עַמִּ֔ים מִקְצֵ֥ה הָאָ֖רֶץ וְעַד־קְצֵ֣ה הָאָ֑רֶץ וְעָבַ֨דְתָּ שָּׁ֜ם אֱלֹהִ֣ים אֲחֵרִ֗ים אֲשֶׁ֧ר לֹֽא־יָדַ֛עְתָּ אַתָּ֥ה וַֽאֲבֹתֶ֖יךָ עֵ֥ץ וָאָֽבֶן׃"
          },
          {
            "num": "סה",
            "txt": "וּבַגּוֹיִ֤ם הָהֵם֙ לֹ֣א תַרְגִּ֔יעַ וְלֹֽא־יִהְיֶ֥ה מָנ֖וֹחַ לְכַף־רַגְלֶ֑ךָ וְנָתַן֩ יְהוָ֨ה לְךָ֥ שָׁם֙ לֵ֣ב רַגָּ֔ז וְכִלְי֥וֹן עֵינַ֖יִם וְדַֽאֲב֥וֹן נָֽפֶשׁ׃"
          },
          {
            "num": "סו",
            "txt": "וְהָי֣וּ חַיֶּ֔יךָ תְּלֻאִ֥ים לְךָ֖ מִנֶּ֑גֶד וּפָֽחַדְתָּ֙ לַ֣יְלָה וְיוֹמָ֔ם וְלֹ֥א תַֽאֲמִ֖ין בְּחַיֶּֽיךָ׃"
          },
          {
            "num": "סז",
            "txt": "בַּבֹּ֤קֶר תֹּאמַר֙ מִֽי־יִתֵּ֣ן עֶ֔רֶב וּבָעֶ֥רֶב תֹּאמַ֖ר מִֽי־יִתֵּ֣ן בֹּ֑קֶר מִפַּ֤חַד לְבָֽבְךָ֙ אֲשֶׁ֣ר תִּפְחָ֔ד וּמִמַּרְאֵ֥ה עֵינֶ֖יךָ אֲשֶׁ֥ר תִּרְאֶֽה׃"
          },
          {
            "num": "סח",
            "txt": "וֶהֱשִֽׁיבְךָ֨ יְהוָ֥ה ׀ מִצְרַיִם֮ בָּֽאֳנִיּוֹת֒ בַּדֶּ֨רֶךְ֙ אֲשֶׁ֣ר אָמַ֣רְתִּֽי לְךָ֔ לֹֽא־תֹסִ֥יף ע֖וֹד לִרְאֹתָ֑הּ וְהִתְמַכַּרְתֶּ֨ם שָׁ֧ם לְאֹֽיְבֶ֛יךָ לַֽעֲבָדִ֥ים וְלִשְׁפָח֖וֹת וְאֵ֥ין קֹנֶֽה׃ {ס}"
          },
          {
            "num": "סט",
            "txt": "אֵלֶּה֩ דִבְרֵ֨י הַבְּרִ֜ית אֲֽשֶׁר־צִוָּ֧ה יְהוָ֣ה אֶת־מֹשֶׁ֗ה לִכְרֹ֛ת אֶת־בְּנֵ֥י יִשְׂרָאֵ֖ל בְּאֶ֣רֶץ מוֹאָ֑ב מִלְּבַ֣ד הַבְּרִ֔ית אֲשֶׁר־כָּרַ֥ת אִתָּ֖ם בְּחֹרֵֽב׃ {פ}"
          }
        ]
      },
      {
        "num": "כט",
        "verses": [
          {
            "num": "א",
            "txt": "וַיִּקְרָ֥א מֹשֶׁ֛ה אֶל־כָּל־יִשְׂרָאֵ֖ל וַיֹּ֣אמֶר אֲלֵהֶ֑ם אַתֶּ֣ם רְאִיתֶ֗ם אֵ֣ת כָּל־אֲשֶׁר֩ עָשָׂ֨ה יְהוָ֤ה לְעֵֽינֵיכֶם֙ בְּאֶ֣רֶץ מִצְרַ֔יִם לְפַרְעֹ֥ה וּלְכָל־עֲבָדָ֖יו וּלְכָל־אַרְצֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "הַמַּסּוֹת֙ הַגְּדֹלֹ֔ת אֲשֶׁ֥ר רָא֖וּ עֵינֶ֑יךָ הָֽאֹתֹ֧ת וְהַמֹּֽפְתִ֛ים הַגְּדֹלִ֖ים הָהֵֽם׃"
          },
          {
            "num": "ג",
            "txt": "וְלֹֽא־נָתַן֩ יְהוָ֨ה לָכֶ֥ם לֵב֙ לָדַ֔עַת וְעֵינַ֥יִם לִרְא֖וֹת וְאָזְנַ֣יִם לִשְׁמֹ֑עַ עַ֖ד הַיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "ד",
            "txt": "וָֽאוֹלֵ֥ךְ אֶתְכֶ֛ם אַרְבָּעִ֥ים שָׁנָ֖ה בַּמִּדְבָּ֑ר לֹֽא־בָל֤וּ שַׂלְמֹֽתֵיכֶם֙ מֵֽעֲלֵיכֶ֔ם וְנַֽעַלְךָ֥ לֹֽא־בָלְתָ֖ה מֵעַ֥ל רַגְלֶֽךָ׃"
          },
          {
            "num": "ה",
            "txt": "לֶ֚חֶם לֹ֣א אֲכַלְתֶּ֔ם וְיַ֥יִן וְשֵׁכָ֖ר לֹ֣א שְׁתִיתֶ֑ם לְמַ֨עַן֙ תֵּֽדְע֔וּ כִּ֛י אֲנִ֥י יְהוָ֖ה אֱלֹֽהֵיכֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "וַתָּבֹ֖אוּ אֶל־הַמָּק֣וֹם הַזֶּ֑ה וַיֵּצֵ֣א סִיחֹ֣ן מֶֽלֶךְ־חֶ֠שְׁבּוֹן וְע֨וֹג מֶֽלֶךְ־הַבָּשָׁ֧ן לִקְרָאתֵ֛נוּ לַמִּלְחָמָ֖ה וַנַּכֵּֽם׃"
          },
          {
            "num": "ז",
            "txt": "וַנִּקַּח֙ אֶת־אַרְצָ֔ם וַנִּתְּנָ֣הּ לְנַֽחֲלָ֔ה לָרֽאוּבֵנִ֖י וְלַגָּדִ֑י וְלַֽחֲצִ֖י שֵׁ֥בֶט הַֽמְנַשִּֽׁי׃"
          },
          {
            "num": "ח",
            "txt": "וּשְׁמַרְתֶּ֗ם אֶת־דִּבְרֵי֙ הַבְּרִ֣ית הַזֹּ֔את וַֽעֲשִׂיתֶ֖ם אֹתָ֑ם לְמַ֣עַן תַּשְׂכִּ֔ילוּ אֵ֖ת כָּל־אֲשֶׁ֥ר תַּֽעֲשֽׂוּן׃ {פ}"
          },
          {
            "num": "ט",
            "txt": "אַתֶּ֨ם נִצָּבִ֤ים הַיּוֹם֙ כֻּלְּכֶ֔ם לִפְנֵ֖י יְהוָ֣ה אֱלֹֽהֵיכֶ֑ם רָֽאשֵׁיכֶ֣ם שִׁבְטֵיכֶ֗ם זִקְנֵיכֶם֙ וְשֹׁ֣טְרֵיכֶ֔ם כֹּ֖ל אִ֥ישׁ יִשְׂרָאֵֽל׃"
          },
          {
            "num": "י",
            "txt": "טַפְּכֶ֣ם נְשֵׁיכֶ֔ם וְגֵ֣רְךָ֔ אֲשֶׁ֖ר בְּקֶ֣רֶב מַֽחֲנֶ֑יךָ מֵֽחֹטֵ֣ב עֵצֶ֔יךָ עַ֖ד שֹׁאֵ֥ב מֵימֶֽיךָ׃"
          },
          {
            "num": "יא",
            "txt": "לְעָבְרְךָ֗ בִּבְרִ֛ית יְהוָ֥ה אֱלֹהֶ֖יךָ וּבְאָֽלָת֑וֹ אֲשֶׁר֙ יְהוָ֣ה אֱלֹהֶ֔יךָ כֹּרֵ֥ת עִמְּךָ֖ הַיּֽוֹם׃"
          },
          {
            "num": "יב",
            "txt": "לְמַ֣עַן הָקִֽים־אֹתְךָ֩ הַיּ֨וֹם ׀ ל֜וֹ לְעָ֗ם וְה֤וּא יִֽהְיֶה־לְּךָ֙ לֵֽאלֹהִ֔ים כַּֽאֲשֶׁ֖ר דִּבֶּר־לָ֑ךְ וְכַֽאֲשֶׁ֤ר נִשְׁבַּע֙ לַֽאֲבֹתֶ֔יךָ לְאַבְרָהָ֥ם לְיִצְחָ֖ק וּֽלְיַעֲקֹֽב׃"
          },
          {
            "num": "יג",
            "txt": "וְלֹ֥א אִתְּכֶ֖ם לְבַדְּכֶ֑ם אָֽנֹכִ֗י כֹּרֵת֙ אֶת־הַבְּרִ֣ית הַזֹּ֔את וְאֶת־הָֽאָלָ֖ה הַזֹּֽאת׃"
          },
          {
            "num": "יד",
            "txt": "כִּי֩ אֶת־אֲשֶׁ֨ר יֶשְׁנ֜וֹ פֹּ֗ה עִמָּ֨נוּ֙ עֹמֵ֣ד הַיּ֔וֹם לִפְנֵ֖י יְהוָ֣ה אֱלֹהֵ֑ינוּ וְאֵ֨ת אֲשֶׁ֥ר אֵינֶ֛נּוּ פֹּ֖ה עִמָּ֥נוּ הַיּֽוֹם׃"
          },
          {
            "num": "טו",
            "txt": "כִּֽי־אַתֶּ֣ם יְדַעְתֶּ֔ם אֵ֥ת אֲשֶׁר־יָשַׁ֖בְנוּ בְּאֶ֣רֶץ מִצְרָ֑יִם וְאֵ֧ת אֲשֶׁר־עָבַ֛רְנוּ בְּקֶ֥רֶב הַגּוֹיִ֖ם אֲשֶׁ֥ר עֲבַרְתֶּֽם׃"
          },
          {
            "num": "טז",
            "txt": "וַתִּרְאוּ֙ אֶת־שִׁקּ֣וּצֵיהֶ֔ם וְאֵ֖ת גִּלֻּֽלֵיהֶ֑ם עֵ֣ץ וָאֶ֔בֶן כֶּ֥סֶף וְזָהָ֖ב אֲשֶׁ֥ר עִמָּהֶֽם׃"
          },
          {
            "num": "יז",
            "txt": "פֶּן־יֵ֣שׁ בָּ֠כֶם אִ֣ישׁ אֽוֹ־אִשָּׁ֞ה א֧וֹ מִשְׁפָּחָ֣ה אוֹ־שֵׁ֗בֶט אֲשֶׁר֩ לְבָב֨וֹ פֹנֶ֤ה הַיּוֹם֙ מֵעִם֙ יְהוָ֣ה אֱלֹהֵ֔ינוּ לָלֶ֣כֶת לַֽעֲבֹ֔ד אֶת־אֱלֹהֵ֖י הַגּוֹיִ֣ם הָהֵ֑ם פֶּן־יֵ֣שׁ בָּכֶ֗ם שֹׁ֛רֶשׁ פֹּרֶ֥ה רֹ֖אשׁ וְלַֽעֲנָֽה׃"
          },
          {
            "num": "יח",
            "txt": "וְהָיָ֡ה בְּשָׁמְעוֹ֩ אֶת־דִּבְרֵ֨י הָֽאָלָ֜ה הַזֹּ֗את וְהִתְבָּרֵ֨ךְ בִּלְבָב֤וֹ לֵאמֹר֙ שָׁל֣וֹם יִֽהְיֶה־לִּ֔י כִּ֛י בִּשְׁרִר֥וּת לִבִּ֖י אֵלֵ֑ךְ לְמַ֛עַן סְפ֥וֹת הָֽרָוָ֖ה אֶת־הַצְּמֵאָֽה׃"
          },
          {
            "num": "יט",
            "txt": "לֹֽא־יֹאבֶ֣ה יְהוָה֮ סְלֹ֣חַֽ לוֹ֒ כִּ֣י אָ֠ז יֶעְשַׁ֨ן אַף־יְהוָ֤ה וְקִנְאָתוֹ֙ בָּאִ֣ישׁ הַה֔וּא וְרָ֤בְצָה בּוֹ֙ כָּל־הָ֣אָלָ֔ה הַכְּתוּבָ֖ה בַּסֵּ֣פֶר הַזֶּ֑ה וּמָחָ֤ה יְהוָה֙ אֶת־שְׁמ֔וֹ מִתַּ֖חַת הַשָּׁמָֽיִם׃"
          },
          {
            "num": "כ",
            "txt": "וְהִבְדִּיל֤וֹ יְהוָה֙ לְרָעָ֔ה מִכֹּ֖ל שִׁבְטֵ֣י יִשְׂרָאֵ֑ל כְּכֹל֙ אָל֣וֹת הַבְּרִ֔ית הַכְּתוּבָ֕ה בְּסֵ֥פֶר הַתּוֹרָ֖ה הַזֶּֽה׃"
          },
          {
            "num": "כא",
            "txt": "וְאָמַ֞ר הַדּ֣וֹר הָאַֽחֲר֗וֹן בְּנֵיכֶם֙ אֲשֶׁ֤ר יָק֨וּמוּ֙ מֵאַ֣חֲרֵיכֶ֔ם וְהַ֨נָּכְרִ֔י אֲשֶׁ֥ר יָבֹ֖א מֵאֶ֣רֶץ רְחוֹקָ֑ה וְ֠רָאוּ אֶת־מַכּ֞וֹת הָאָ֤רֶץ הַהִוא֙ וְאֶת־תַּ֣חֲלֻאֶ֔יהָ אֲשֶׁר־חִלָּ֥ה יְהוָ֖ה בָּֽהּ׃"
          },
          {
            "num": "כב",
            "txt": "גָּפְרִ֣ית וָמֶלַח֮ שְׂרֵפָ֣ה כָל־אַרְצָהּ֒ לֹ֤א תִזָּרַע֙ וְלֹ֣א תַצְמִ֔חַ וְלֹֽא־יַעֲלֶ֥ה בָ֖הּ כָּל־עֵ֑שֶׂב כְּֽמַהְפֵּכַ֞ת סְדֹ֤ם וַֽעֲמֹרָה֙ אַדְמָ֣ה וּצְבֹיִ֔ים אֲשֶׁר֙ הָפַ֣ךְ יְהוָ֔ה בְּאַפּ֖וֹ וּבַֽחֲמָתֽוֹ׃"
          },
          {
            "num": "כג",
            "txt": "וְאָֽמְרוּ֙ כָּל־הַגּוֹיִ֔ם עַל־מֶ֨ה עָשָׂ֧ה יְהוָ֛ה כָּ֖כָה לָאָ֣רֶץ הַזֹּ֑את מֶ֥ה חֳרִ֛י הָאַ֥ף הַגָּד֖וֹל הַזֶּֽה׃"
          },
          {
            "num": "כד",
            "txt": "וְאָ֣מְר֔וּ עַ֚ל אֲשֶׁ֣ר עָֽזְב֔וּ אֶת־בְּרִ֥ית יְהוָ֖ה אֱלֹהֵ֣י אֲבֹתָ֑ם אֲשֶׁר֙ כָּרַ֣ת עִמָּ֔ם בְּהֽוֹצִיא֥וֹ אֹתָ֖ם מֵאֶ֥רֶץ מִצְרָֽיִם׃"
          },
          {
            "num": "כה",
            "txt": "וַיֵּֽלְכ֗וּ וַיַּֽעַבְדוּ֙ אֱלֹהִ֣ים אֲחֵרִ֔ים וַיִּֽשְׁתַּחֲו֖וּ לָהֶ֑ם אֱלֹהִים֙ אֲשֶׁ֣ר לֹֽא־יְדָע֔וּם וְלֹ֥א חָלַ֖ק לָהֶֽם׃"
          },
          {
            "num": "כו",
            "txt": "וַיִּֽחַר־אַ֥ף יְהוָ֖ה בָּאָ֣רֶץ הַהִ֑וא לְהָבִ֤יא עָלֶ֨יהָ֙ אֶת־כָּל־הַקְּלָלָ֔ה הַכְּתוּבָ֖ה בַּסֵּ֥פֶר הַזֶּֽה׃"
          },
          {
            "num": "כז",
            "txt": "וַיִּתְּשֵׁ֤ם יְהוָה֙ מֵעַ֣ל אַדְמָתָ֔ם בְּאַ֥ף וּבְחֵמָ֖ה וּבְקֶ֣צֶף גָּד֑וֹל וַיַּשְׁ"
          },
          {
            "num": "כח",
            "txt": "כֵ֛ם אֶל־אֶ֥רֶץ אַחֶ֖רֶת כַּיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "txt": "הַנִּ֨סְתָּרֹ֔ת לַֽיהוָ֖ה אֱלֹהֵ֑ינוּ וְהַנִּגְלֹ֞ת לָ֤ׄנׄוּׄ וּׄלְׄבָׄנֵ֨ׄיׄנׄוּ֙ׄ עַׄד־עוֹלָ֔ם לַֽעֲשׂ֕וֹת אֶת־כָּל־דִּבְרֵ֖י הַתּוֹרָ֥ה הַזֹּֽאת׃ {ס}"
          }
        ]
      },
      {
        "num": "ל",
        "verses": [
          {
            "num": "א",
            "txt": "וְהָיָה֩ כִֽי־יָבֹ֨אוּ עָלֶ֜יךָ כָּל־הַדְּבָרִ֣ים הָאֵ֗לֶּה הַבְּרָכָה֙ וְהַקְּלָלָ֔ה אֲשֶׁ֥ר נָתַ֖תִּי לְפָנֶ֑יךָ וַהֲשֵֽׁבֹתָ֙ אֶל־לְבָבֶ֔ךָ בְּכָ֨ל־הַגּוֹיִ֔ם אֲשֶׁ֧ר הִדִּֽיחֲךָ֛ יְהוָ֥ה אֱלֹהֶ֖יךָ שָֽׁמָּה׃"
          },
          {
            "num": "ב",
            "txt": "וְשַׁבְתָּ֞ עַד־יְהוָ֤ה אֱלֹהֶ֨יךָ֙ וְשָֽׁמַעְתָּ֣ בְקֹל֔וֹ כְּכֹ֛ל אֲשֶׁר־אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּ֑וֹם אַתָּ֣ה וּבָנֶ֔יךָ בְּכָל־לְבָֽבְךָ֖ וּבְכָל־נַפְשֶֽׁךָ׃"
          },
          {
            "num": "ג",
            "txt": "וְשָׁ֨ב יְהוָ֧ה אֱלֹהֶ֛יךָ אֶת־שְׁבֽוּתְךָ֖ וְרִֽחֲמֶ֑ךָ וְשָׁ֗ב וְקִבֶּצְךָ֙ מִכָּל־הָ֣עַמִּ֔ים אֲשֶׁ֧ר הֱפִֽיצְךָ֛ יְהוָ֥ה אֱלֹהֶ֖יךָ שָֽׁמָּה׃"
          },
          {
            "num": "ד",
            "txt": "אִם־יִֽהְיֶ֥ה נִֽדַּחֲךָ֖ בִּקְצֵ֣ה הַשָּׁמָ֑יִם מִשָּׁ֗ם יְקַבֶּצְךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ וּמִשָּׁ֖ם יִקָּחֶֽךָ׃"
          },
          {
            "num": "ה",
            "txt": "וֶהֱבִֽיאֲךָ֞ יְהוָ֣ה אֱלֹהֶ֗יךָ אֶל־הָאָ֛רֶץ אֲשֶׁר־יָֽרְשׁ֥וּ אֲבֹתֶ֖יךָ וִֽירִשְׁתָּ֑הּ וְהֵיטִֽבְךָ֥ וְהִרְבְּךָ֖ מֵֽאֲבֹתֶֽיךָ׃"
          },
          {
            "num": "ו",
            "txt": "וּמָ֨ל יְהוָ֧ה אֱלֹהֶ֛יךָ אֶת־לְבָֽבְךָ֖ וְאֶת־לְבַ֣ב זַרְעֶ֑ךָ לְאַֽהֲבָ֞ה אֶת־יְהוָ֧ה אֱלֹהֶ֛יךָ בְּכָל־לְבָֽבְךָ֥ וּבְכָל־נַפְשְׁךָ֖ לְמַ֥עַן חַיֶּֽיךָ׃"
          },
          {
            "num": "ז",
            "txt": "וְנָתַן֙ יְהוָ֣ה אֱלֹהֶ֔יךָ אֵ֥ת כָּל־הָֽאָל֖וֹת הָאֵ֑לֶּה עַל־אֹֽיְבֶ֥יךָ וְעַל־שֹֽׂנְאֶ֖יךָ אֲשֶׁ֥ר רְדָפֽוּךָ׃"
          },
          {
            "num": "ח",
            "txt": "וְאַתָּ֣ה תָשׁ֔וּב וְשָֽׁמַעְתָּ֖ בְּק֣וֹל יְהוָ֑ה וְעָשִׂ֨יתָ֙ אֶת־כָּל־מִצְוֺתָ֔יו אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּֽוֹם׃"
          },
          {
            "num": "ט",
            "txt": "וְהוֹתִֽירְךָ֩ יְהוָ֨ה אֱלֹהֶ֜יךָ בְּכֹ֣ל ׀ מַֽעֲשֵׂ֣ה יָדֶ֗ךָ בִּפְרִ֨י בִטְנְךָ֜ וּבִפְרִ֧י בְהֶמְתְּךָ֛ וּבִפְרִ֥י אַדְמָֽתְךָ֖ לְטֹבָ֑ה כִּ֣י ׀ יָשׁ֣וּב יְהוָ֗ה לָשׂ֤וּשׂ עָלֶ֨יךָ֙ לְט֔וֹב כַּֽאֲשֶׁר־שָׂ֖שׂ עַל־אֲבֹתֶֽיךָ׃"
          },
          {
            "num": "י",
            "txt": "כִּ֣י תִשְׁמַ֗ע בְּקוֹל֙ יְהוָ֣ה אֱלֹהֶ֔יךָ לִשְׁמֹ֤ר מִצְוֺתָיו֙ וְחֻקֹּתָ֔יו הַכְּתוּבָ֕ה בְּסֵ֥פֶר הַתּוֹרָ֖ה הַזֶּ֑ה כִּ֤י תָשׁוּב֙ אֶל־יְהוָ֣ה אֱלֹהֶ֔יךָ בְּכָל־לְבָֽבְךָ֖ וּבְכָל־נַפְשֶֽׁךָ׃ {ס}"
          },
          {
            "num": "יא",
            "txt": "כִּ֚י הַמִּצְוָ֣ה הַזֹּ֔את אֲשֶׁ֛ר אָֽנֹכִ֥י מְצַוְּךָ֖ הַיּ֑וֹם לֹֽא־נִפְלֵ֥את הִוא֙ מִמְּךָ֔ וְלֹ֥א רְחֹקָ֖ה הִֽוא׃"
          },
          {
            "num": "יב",
            "txt": "לֹ֥א בַשָּׁמַ֖יִם הִ֑וא לֵאמֹ֗ר מִ֣י יַֽעֲלֶה־לָּ֤נוּ הַשָּׁמַ֨יְמָה֙ וְיִקָּחֶ֣הָ לָּ֔נוּ וְיַשְׁמִעֵ֥נוּ אֹתָ֖הּ וְנַֽעֲשֶֽׂנָּה׃"
          },
          {
            "num": "יג",
            "txt": "וְלֹֽא־מֵעֵ֥בֶר לַיָּ֖ם הִ֑וא לֵאמֹ֗ר מִ֣י יַֽעֲבָר־לָ֜נוּ אֶל־עֵ֤בֶר הַיָּם֙ וְיִקָּחֶ֣הָ לָּ֔נוּ וְיַשְׁמִעֵ֥נוּ אֹתָ֖הּ וְנַֽעֲשֶֽׂנָּה׃"
          },
          {
            "num": "יד",
            "txt": "כִּֽי־קָר֥וֹב אֵלֶ֛יךָ הַדָּבָ֖ר מְאֹ֑ד בְּפִ֥יךָ וּבִֽלְבָבְךָ֖ לַֽעֲשֹׂתֽוֹ׃ {ס}"
          },
          {
            "num": "טו",
            "txt": "רְאֵ֨ה נָתַ֤תִּי לְפָנֶ֨יךָ֙ הַיּ֔וֹם אֶת־הַֽחַיִּ֖ים וְאֶת־הַטּ֑וֹב וְאֶת־הַמָּ֖וֶת וְאֶת־הָרָֽע׃"
          },
          {
            "num": "טז",
            "txt": "אֲשֶׁ֨ר אָֽנֹכִ֣י מְצַוְּךָ֮ הַיּוֹם֒ לְאַֽהֲבָ֞ה אֶת־יְהוָ֤ה אֱלֹהֶ֨יךָ֙ לָלֶ֣כֶת בִּדְרָכָ֔יו וְלִשְׁמֹ֛ר מִצְוֺתָ֥יו וְחֻקֹּתָ֖יו וּמִשְׁפָּטָ֑יו וְחָיִ֣יתָ וְרָבִ֔יתָ וּבֵֽרַכְךָ֙ יְהוָ֣ה אֱלֹהֶ֔יךָ בָּאָ֕רֶץ אֲשֶׁר־אַתָּ֥ה בָא־שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "יז",
            "txt": "וְאִם־יִפְנֶ֥ה לְבָֽבְךָ֖ וְלֹ֣א תִשְׁמָ֑ע וְנִדַּחְתָּ֗ וְהִֽשְׁתַּחֲוִ֛יתָ לֵֽאלֹהִ֥ים אֲחֵרִ֖ים וַֽעֲבַדְתָּֽם׃"
          },
          {
            "num": "יח",
            "txt": "הִגַּ֤דְתִּי לָכֶם֙ הַיּ֔וֹם כִּ֥י אָבֹ֖ד תֹּֽאבֵד֑וּן לֹֽא־תַאֲרִיכֻ֤ן יָמִים֙ עַל־הָ֣אֲדָמָ֔ה אֲשֶׁ֨ר אַתָּ֤ה עֹבֵר֙ אֶת־הַיַּרְדֵּ֔ן לָב֥וֹא שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "יט",
            "txt": "הַֽעִדֹ֨תִי בָכֶ֣ם הַיּוֹם֮ אֶת־הַשָּׁמַ֣יִם וְאֶת־הָאָרֶץ֒ הַֽחַיִּ֤ים וְהַמָּ֨וֶת֙ נָתַ֣תִּי לְפָנֶ֔יךָ הַבְּרָכָ֖ה וְהַקְּלָלָ֑ה וּבָֽחַרְתָּ֙ בַּֽחַיִּ֔ים לְמַ֥עַן תִּֽחְיֶ֖ה אַתָּ֥ה וְזַרְעֶֽךָ׃"
          },
          {
            "num": "כ",
            "txt": "לְאַֽהֲבָה֙ אֶת־יְהוָ֣ה אֱלֹהֶ֔יךָ לִשְׁמֹ֥עַ בְּקֹל֖וֹ וּלְדָבְקָה־ב֑וֹ כִּ֣י ה֤וּא חַיֶּ֨יךָ֙ וְאֹ֣רֶךְ יָמֶ֔יךָ לָשֶׁ֣בֶת עַל־הָֽאֲדָמָ֗ה אֲשֶׁר֩ נִשְׁבַּ֨ע יְהוָ֧ה לַֽאֲבֹתֶ֛יךָ לְאַבְרָהָ֛ם לְיִצְחָ֥ק וּֽלְיַעֲקֹ֖ב לָתֵ֥ת לָהֶֽם׃ {פ}"
          }
        ]
      },
      {
        "num": "לא",
        "verses": [
          {
            "num": "א",
            "txt": "וַיֵּ֖לֶךְ מֹשֶׁ֑ה וַיְדַבֵּ֛ר אֶת־הַדְּבָרִ֥ים הָאֵ֖לֶּה אֶל־כָּל־יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּ֣אמֶר אֲלֵהֶ֗ם בֶּן־מֵאָה֩ וְעֶשְׂרִ֨ים שָׁנָ֤ה אָֽנֹכִי֙ הַיּ֔וֹם לֹֽא־אוּכַ֥ל ע֖וֹד לָצֵ֣את וְלָב֑וֹא וַֽיהוָה֙ אָמַ֣ר אֵלַ֔י לֹ֥א תַֽעֲבֹ֖ר אֶת־הַיַּרְדֵּ֥ן הַזֶּֽה׃"
          },
          {
            "num": "ג",
            "txt": "יְהוָ֨ה אֱלֹהֶ֜יךָ ה֣וּא ׀ עֹבֵ֣ר לְפָנֶ֗יךָ הֽוּא־יַשְׁמִ֞יד אֶת־הַגּוֹיִ֥ם הָאֵ֛לֶּה מִלְּפָנֶ֖יךָ וִֽירִשְׁתָּ֑ם יְהוֹשֻׁ֗עַ ה֚וּא עֹבֵ֣ר לְפָנֶ֔יךָ כַּֽאֲשֶׁ֖ר דִּבֶּ֥ר יְהוָֽה׃"
          },
          {
            "num": "ד",
            "txt": "וְעָשָׂ֤ה יְהוָה֙ לָהֶ֔ם כַּֽאֲשֶׁ֣ר עָשָׂ֗ה לְסִיח֥וֹן וּלְע֛וֹג מַלְכֵ֥י הָֽאֱמֹרִ֖י וּלְאַרְצָ֑ם אֲשֶׁ֥ר הִשְׁמִ֖יד אֹתָֽם׃"
          },
          {
            "num": "ה",
            "txt": "וּנְתָנָ֥ם יְהוָ֖ה לִפְנֵיכֶ֑ם וַֽעֲשִׂיתֶ֣ם לָהֶ֔ם כְּכָ֨ל־הַמִּצְוָ֔ה אֲשֶׁ֥ר צִוִּ֖יתִי אֶתְכֶֽם׃"
          },
          {
            "num": "ו",
            "txt": "חִזְק֣וּ וְאִמְצ֔וּ אַל־תִּֽירְא֥וּ וְאַל־תַּֽעַרְצ֖וּ מִפְּנֵיהֶ֑ם כִּ֣י ׀ יְהוָ֣ה אֱלֹהֶ֗יךָ ה֚וּא הַֽהֹלֵ֣ךְ עִמָּ֔ךְ לֹ֥א יַרְפְּךָ֖ וְלֹ֥א יַֽעַזְבֶֽךָּ׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "וַיִּקְרָ֨א מֹשֶׁ֜ה לִֽיהוֹשֻׁ֗עַ וַיֹּ֨אמֶר אֵלָ֜יו לְעֵינֵ֣י כָל־יִשְׂרָאֵל֮ חֲזַ֣ק וֶֽאֱמָץ֒ כִּ֣י אַתָּ֗ה תָּבוֹא֙ אֶת־הָעָ֣ם הַזֶּ֔ה אֶל־הָאָ֕רֶץ אֲשֶׁ֨ר נִשְׁבַּ֧ע יְהוָ֛ה לַֽאֲבֹתָ֖ם לָתֵ֣ת לָהֶ֑ם וְאַתָּ֖ה תַּנְחִילֶ֥נָּה אוֹתָֽם׃"
          },
          {
            "num": "ח",
            "txt": "וַֽיהוָ֞ה ה֣וּא ׀ הַֽהֹלֵ֣ךְ לְפָנֶ֗יךָ ה֚וּא יִֽהְיֶ֣ה עִמָּ֔ךְ לֹ֥א יַרְפְּךָ֖ וְלֹ֣א יַֽעַזְבֶ֑ךָּ לֹ֥א תִירָ֖א וְלֹ֥א תֵחָֽת׃"
          },
          {
            "num": "ט",
            "txt": "וַיִּכְתֹּ֣ב מֹשֶׁה֮ אֶת־הַתּוֹרָ֣ה הַזֹּאת֒ וַֽיִּתְּנָ֗הּ אֶל־הַכֹּֽהֲנִים֙ בְּנֵ֣י לֵוִ֔י הַנֹּ֣שְׂאִ֔ים אֶת־אֲר֖וֹן בְּרִ֣ית יְהוָ֑ה וְאֶל־כָּל־זִקְנֵ֖י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "י",
            "txt": "וַיְצַ֥ו מֹשֶׁ֖ה אוֹתָ֣ם לֵאמֹ֑ר מִקֵּ֣ץ ׀ שֶׁ֣בַע שָׁנִ֗ים בְּמֹעֵ֛ד שְׁנַ֥ת הַשְּׁמִטָּ֖ה בְּחַ֥ג הַסֻּכּֽוֹת׃"
          },
          {
            "num": "יא",
            "txt": "בְּב֣וֹא כָל־יִשְׂרָאֵ֗ל לֵֽרָאוֹת֙ אֶת־פְּנֵי֙ יְהוָ֣ה אֱלֹהֶ֔יךָ בַּמָּק֖וֹם אֲשֶׁ֣ר יִבְחָ֑ר תִּקְרָ֞א אֶת־הַתּוֹרָ֥ה הַזֹּ֛את נֶ֥גֶד כָּל־יִשְׂרָאֵ֖ל בְּאָזְנֵיהֶֽם׃"
          },
          {
            "num": "יב",
            "txt": "הַקְהֵ֣ל אֶת־הָעָ֗ם הָֽאֲנָשִׁ֤ים וְהַנָּשִׁים֙ וְהַטַּ֔ף וְגֵֽרְךָ֖ אֲשֶׁ֣ר בִּשְׁעָרֶ֑יךָ לְמַ֨עַן יִשְׁמְע֜וּ וּלְמַ֣עַן יִלְמְד֗וּ וְיָֽרְאוּ֙ אֶת־יְהוָ֣ה אֱלֹֽהֵיכֶ֔ם וְשָֽׁמְר֣וּ לַֽעֲשׂ֔וֹת אֶת־כָּל־דִּבְרֵ֖י הַתּוֹרָ֥ה הַזֹּֽאת׃"
          },
          {
            "num": "יג",
            "txt": "וּבְנֵיהֶ֞ם אֲשֶׁ֣ר לֹֽא־יָדְע֗וּ יִשְׁמְעוּ֙ וְלָ֣מְד֔וּ לְיִרְאָ֖ה אֶת־יְהוָ֣ה אֱלֹֽהֵיכֶ֑ם כָּל־הַיָּמִ֗ים אֲשֶׁ֨ר אַתֶּ֤ם חַיִּים֙ עַל־הָ֣אֲדָמָ֔ה אֲשֶׁ֨ר אַתֶּ֜ם עֹֽבְרִ֧ים אֶת־הַיַּרְדֵּ֛ן שָׁ֖מָּה לְרִשְׁתָּֽהּ׃ {פ}"
          },
          {
            "num": "יד",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֶל־מֹשֶׁ֗ה הֵ֣ן קָֽרְב֣וּ יָמֶיךָ֮ לָמוּת֒ קְרָ֣א אֶת־יְהוֹשֻׁ֗עַ וְהִֽתְיַצְּב֛וּ בְּאֹ֥הֶל מוֹעֵ֖ד וַֽאֲצַוֶּ֑נּוּ וַיֵּ֤לֶךְ מֹשֶׁה֙ וִֽיהוֹשֻׁ֔עַ וַיִּֽתְיַצְּב֖וּ בְּאֹ֥הֶל מוֹעֵֽד׃"
          },
          {
            "num": "טו",
            "txt": "וַיֵּרָ֧א יְהוָ֛ה בָּאֹ֖הֶל בְּעַמּ֣וּד עָנָ֑ן וַֽיַּעֲמֹ֛ד עַמּ֥וּד הֶֽעָנָ֖ן עַל־פֶּ֥תַח הָאֹֽהֶל׃"
          },
          {
            "num": "טז",
            "txt": "וַיֹּ֤אמֶר יְהוָה֙ אֶל־מֹשֶׁ֔ה הִנְּךָ֥ שֹׁכֵ֖ב עִם־אֲבֹתֶ֑יךָ וְקָם֩ הָעָ֨ם הַזֶּ֜ה וְזָנָ֣ה ׀ אַֽחֲרֵ֣י ׀ אֱלֹהֵ֣י נֵֽכַר־הָאָ֗רֶץ אֲשֶׁ֨ר ה֤וּא בָא־שָׁ֨מָּה֙ בְּקִרְבּ֔וֹ וַֽעֲזָבַ֕נִי וְהֵפֵר֙ אֶת־בְּרִיתִ֔י אֲשֶׁ֥ר כָּרַ֖תִּי אִתּֽוֹ׃"
          },
          {
            "num": "יז",
            "txt": "וְחָרָ֣ה אַפִּ֣י ב֣וֹ בַיּוֹם־הַ֠הוּא וַֽעֲזַבְתִּ֞ים וְהִסְתַּרְתִּ֨י פָנַ֤י מֵהֶם֙ וְהָיָ֣ה לֶֽאֱכֹ֔ל וּמְצָאֻ֛הוּ רָע֥וֹת רַבּ֖וֹת וְצָר֑וֹת וְאָמַר֙ בַּיּ֣וֹם הַה֔וּא הֲלֹ֗א עַ֣ל כִּי־אֵ֤ין אֱלֹהַי֙ בְּקִרְבִּ֔י מְצָא֖וּנִי הָֽרָע֥וֹת הָאֵֽלֶּה׃"
          },
          {
            "num": "יח",
            "txt": "וְאָֽנֹכִ֗י הַסְתֵּ֨ר אַסְתִּ֤יר פָּנַי֙ בַּיּ֣וֹם הַה֔וּא עַ֥ל כָּל־הָֽרָעָ֖ה אֲשֶׁ֣ר עָשָׂ֑ה כִּ֣י פָנָ֔ה אֶל־אֱלֹהִ֖ים אֲחֵרִֽים׃"
          },
          {
            "num": "יט",
            "txt": "וְעַתָּ֗ה כִּתְב֤וּ לָכֶם֙ אֶת־הַשִּׁירָ֣ה הַזֹּ֔את וְלַמְּדָ֥הּ אֶת־בְּנֵֽי־יִשְׂרָאֵ֖ל שִׂימָ֣הּ בְּפִיהֶ֑ם לְמַ֨עַן תִּֽהְיֶה־לִּ֜י הַשִּׁירָ֥ה הַזֹּ֛את לְעֵ֖ד בִּבְנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כ",
            "txt": "כִּֽי־אֲבִיאֶ֜נּוּ אֶֽל־הָאֲדָמָ֣ה ׀ אֲשֶׁר־נִשְׁבַּ֣עְתִּי לַֽאֲבֹתָ֗יו זָבַ֤ת חָלָב֙ וּדְבַ֔שׁ וְאָכַ֥ל וְשָׂבַ֖ע וְדָשֵׁ֑ן וּפָנָ֞ה אֶל־אֱלֹהִ֤ים אֲחֵרִים֙ וַֽעֲבָד֔וּם וְנִ֣אֲצ֔וּנִי וְהֵפֵ֖ר אֶת־בְּרִיתִֽי׃"
          },
          {
            "num": "כא",
            "txt": "וְ֠הָיָה כִּֽי־תִמְצֶ֨אןָ אֹת֜וֹ רָע֣וֹת רַבּוֹת֮ וְצָרוֹת֒ וְ֠עָֽנְתָה הַשִּׁירָ֨ה הַזֹּ֤את לְפָנָיו֙ לְעֵ֔ד כִּ֛י לֹ֥א תִשָּׁכַ֖ח מִפִּ֣י זַרְע֑וֹ כִּ֧י יָדַ֣עְתִּי אֶת־יִצְר֗וֹ אֲשֶׁ֨ר ה֤וּא עֹשֶׂה֙ הַיּ֔וֹם בְּטֶ֣רֶם אֲבִיאֶ֔נּוּ אֶל־הָאָ֖רֶץ אֲשֶׁ֥ר נִשְׁבַּֽעְתִּי׃"
          },
          {
            "num": "כב",
            "txt": "וַיִּכְתֹּ֥ב מֹשֶׁ֛ה אֶת־הַשִּׁירָ֥ה הַזֹּ֖את בַּיּ֣וֹם הַה֑וּא וַֽיְלַמְּדָ֖הּ אֶת־בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "כג",
            "txt": "וַיְצַ֞ו אֶת־יְהוֹשֻׁ֣עַ בִּן־נ֗וּן וַיֹּאמֶר֮ חֲזַ֣ק וֶֽאֱמָץ֒ כִּ֣י אַתָּ֗ה תָּבִיא֙ אֶת־בְּנֵ֣י יִשְׂרָאֵ֔ל אֶל־הָאָ֖רֶץ אֲשֶׁר־נִשְׁבַּ֣עְתִּי לָהֶ֑ם וְאָֽנֹכִ֖י אֶֽהְיֶ֥ה עִמָּֽךְ׃"
          },
          {
            "num": "כד",
            "txt": "וַיְהִ֣י ׀ כְּכַלּ֣וֹת מֹשֶׁ֗ה לִכְתֹּ֛ב אֶת־דִּבְרֵ֥י הַתּוֹרָֽה־הַזֹּ֖את עַל־סֵ֑פֶר עַ֖ד תֻּמָּֽם׃"
          },
          {
            "num": "כה",
            "txt": "וַיְצַ֤ו מֹשֶׁה֙ אֶת־הַלְוִיִּ֔ם נֹ֥שְׂאֵ֛י אֲר֥וֹן בְּרִית־יְהוָ֖ה לֵאמֹֽר׃"
          },
          {
            "num": "כו",
            "txt": "לָקֹ֗חַ אֵ֣ת סֵ֤פֶר הַתּוֹרָה֙ הַזֶּ֔ה וְשַׂמְתֶּ֣ם אֹת֔וֹ מִצַּ֛ד אֲר֥וֹן בְּרִית־יְהוָ֖ה אֱלֹֽהֵיכֶ֑ם וְהָֽיָה־שָׁ֥ם בְּךָ֖ לְעֵֽד׃"
          },
          {
            "num": "כז",
            "txt": "כִּ֣י אָֽנֹכִ֤י יָדַ֨עְתִּי֙ אֶֽת־מֶרְיְךָ֔ וְאֶֽת־עָרְפְּךָ֖ הַקָּשֶׁ֑ה הֵ֣ן בְּעוֹדֶנִּי֩ חַ֨י עִמָּכֶ֜ם הַיּ֗וֹם מַמְרִ֤ים הֱיִתֶם֙ עִם־יְהוָ֔ה וְאַ֖ף כִּֽי־אַחֲרֵ֥י מוֹתִֽי׃"
          },
          {
            "num": "כח",
            "txt": "הַקְהִ֧ילוּ אֵלַ֛י אֶת־כָּל־זִקְנֵ֥י שִׁבְטֵיכֶ֖ם וְשֹֽׁטְרֵיכֶ֑ם וַֽאֲדַבְּרָ֣ה בְאָזְנֵיהֶ֗ם אֵ֚ת הַדְּבָרִ֣ים הָאֵ֔לֶּה וְאָעִ֣ידָה בָּ֔ם אֶת־הַשָּׁמַ֖יִם וְאֶת־הָאָֽרֶץ׃"
          },
          {
            "num": "כט",
            "txt": "כִּ֣י יָדַ֗עְתִּי אַֽחֲרֵ֤י מוֹתִי֙ כִּֽי־הַשְׁחֵ֣ת תַּשְׁחִת֔וּן וְסַרְתֶּ֣ם מִן־הַדֶּ֔רֶךְ אֲשֶׁ֥ר צִוִּ֖יתִי אֶתְכֶ֑ם וְקָרָ֨את אֶתְכֶ֤ם הָֽרָעָה֙ בְּאַֽחֲרִ֣ית הַיָּמִ֔ים כִּֽי־תַעֲשׂ֤וּ אֶת־הָרַע֙ בְּעֵינֵ֣י יְהוָ֔ה לְהַכְעִיס֖וֹ בְּמַֽעֲשֵׂ֥ה יְדֵיכֶֽם׃"
          },
          {
            "num": "ל",
            "txt": "וַיְדַבֵּ֣ר מֹשֶׁ֗ה בְּאָזְנֵי֙ כָּל־קְהַ֣ל יִשְׂרָאֵ֔ל אֶת־דִּבְרֵ֥י הַשִּׁירָ֖ה הַזֹּ֑את עַ֖ד תֻּמָּֽם׃ {ש}"
          }
        ]
      },
      {
        "num": "לב",
        "verses": [
          {
            "num": "א",
            "txt": "הַֽאֲזִ֥ינוּ הַשָּׁמַ֖יִם וַֽאֲדַבֵּ֑רָה {ס} וְתִשְׁמַ֥ע הָאָ֖רֶץ אִמְרֵי־פִֽי׃"
          },
          {
            "num": "ב",
            "txt": "יַֽעֲרֹ֤ף כַּמָּטָר֙ לִקְחִ֔י {ס} תִּזַּ֥ל כַּטַּ֖ל אִמְרָתִ֑י {ר}כִּשְׂעִירִ֣ם עֲלֵי־דֶ֔שֶׁא {ס} וְכִרְבִיבִ֖ים עֲלֵי־עֵֽשֶׂב׃"
          },
          {
            "num": "ג",
            "txt": "כִּ֛י שֵׁ֥ם יְהוָ֖ה אֶקְרָ֑א {ס} הָב֥וּ גֹ֖דֶל לֵֽאלֹהֵֽינוּ׃"
          },
          {
            "num": "ד",
            "txt": "הַצּוּר֙ תָּמִ֣ים פָּֽעֳל֔וֹ {ס} כִּ֥י כָל־דְּרָכָ֖יו מִשְׁפָּ֑ט {ר}אֵ֤ל אֱמוּנָה֙ וְאֵ֣ין עָ֔וֶל {ס} צַדִּ֥יק וְיָשָׁ֖ר הֽוּא׃"
          },
          {
            "num": "ה",
            "txt": "שִׁחֵ֥ת ל֛וֹ לֹ֖א בָּנָ֣יו מוּמָ֑ם {ס} דּ֥וֹר עִקֵּ֖שׁ וּפְתַלְתֹּֽל׃"
          },
          {
            "num": "ו",
            "txt": "לְיְהוָה֙ תִּגְמְלוּ־זֹ֔את {ס} עַ֥ם נָבָ֖ל וְלֹ֣א חָכָ֑ם {ר}הֲלוֹא־הוּא֙ אָבִ֣יךָ קָּנֶ֔ךָ {ס} ה֥וּא עָֽשְׂךָ֖ וַֽיְכֹנְנֶֽךָ׃"
          },
          {
            "num": "ז",
            "txt": "זְכֹר֙ יְמ֣וֹת עוֹלָ֔ם בִּ֖ינוּ שְׁנ֣וֹת דֹּר־וָדֹ֑ר {ס} שְׁאַ֤ל אָבִ֨יךָ֙ וְיַגֵּ֔דְךָ זְקֵנֶ֖יךָ וְיֹ֥אמְרוּ לָֽךְ׃"
          },
          {
            "num": "ח",
            "txt": "בְּהַנְחֵ֤ל עֶלְיוֹן֙ גּוֹיִ֔ם {ס} בְּהַפְרִיד֖וֹ בְּנֵ֣י אָדָ֑ם {ר}יַצֵּב֙ גְּבֻלֹ֣ת עַמִּ֔ים {ס} לְמִסְפַּ֖ר בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ט",
            "txt": "כִּ֛י חֵ֥לֶק יְהוָ֖ה עַמּ֑וֹ {ס} יַֽעֲקֹ֖ב חֶ֥בֶל נַֽחֲלָתֽוֹ׃"
          },
          {
            "num": "י",
            "txt": "יִמְצָאֵ֨הוּ֙ בְּאֶ֣רֶץ מִדְבָּ֔ר {ס} וּבְתֹ֖הוּ יְלֵ֣ל יְשִׁמֹ֑ן {ר}יְסֹֽבְבֶ֨נְהוּ֙ יְב֣וֹנְנֵ֔הוּ {ס} יִצְּרֶ֖נְהוּ כְּאִישׁ֥וֹן עֵינֽוֹ׃"
          },
          {
            "num": "יא",
            "txt": "כְּנֶ֨שֶׁר֙ יָעִ֣יר קִנּ֔וֹ עַל־גּֽוֹזָלָ֖יו יְרַחֵ֑ף {ס} יִפְרֹ֤שׂ כְּנָפָיו֙ יִקָּחֵ֔הוּ יִשָּׂאֵ֖הוּ עַל־אֶבְרָתֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "יְהוָ֖ה בָּדָ֣ד יַנְחֶ֑נּוּ {ס} וְאֵ֥ין עִמּ֖וֹ אֵ֥ל נֵכָֽר׃"
          },
          {
            "num": "יג",
            "txt": "יַרְכִּבֵ֨הוּ֙ עַל־במותי (בָּ֣מֳתֵי) אָ֔רֶץ {ס} וַיֹּאכַ֖ל תְּנוּבֹ֣ת שָׂדָ֑י {ר}וַיֵּֽנִקֵ֤הֽוּ דְבַשׁ֙ מִסֶּ֔לַע {ס} וְשֶׁ֖מֶן מֵֽחַלְמִ֥ישׁ צֽוּר׃"
          },
          {
            "num": "יד",
            "txt": "חֶמְאַ֨ת בָּקָ֜ר וַֽחֲלֵ֣ב צֹ֗אן {ס} עִם־חֵ֨לֶב כָּרִ֜ים וְאֵילִ֤ים {ר}בְּנֵֽי־בָשָׁן֙ וְעַתּוּדִ֔ים {ס} עִם־חֵ֖לֶב כִּלְי֣וֹת חִטָּ֑ה {ר}וְדַם־עֵנָ֖ב תִּשְׁתֶּה־חָֽמֶר׃"
          },
          {
            "num": "טו",
            "txt": "וַיִּשְׁמַ֤ן יְשֻׁרוּן֙ וַיִּבְעָ֔ט {ר}שָׁמַ֖נְתָּ עָבִ֣יתָ כָּשִׂ֑יתָ {ס} וַיִּטֹּשׁ֙ אֱל֣וֹהַּ עָשָׂ֔הוּ {ר}וַיְנַבֵּ֖ל צ֥וּר יְשֻֽׁעָתֽוֹ׃"
          },
          {
            "num": "טז",
            "txt": "יַקְנִאֻ֖הוּ בְּזָרִ֑ים {ר}בְּתֽוֹעֵבֹ֖ת יַכְעִיסֻֽהוּ׃"
          },
          {
            "num": "יז",
            "txt": "יִזְבְּח֗וּ לַשֵּׁדִים֙ לֹ֣א אֱלֹ֔הַּ {ר}אֱלֹהִ֖ים לֹ֣א יְדָע֑וּם {ס} חֲדָשִׁים֙ מִקָּרֹ֣ב בָּ֔אוּ {ר}לֹ֥א שְׂעָר֖וּם אֲבֹֽתֵיכֶֽם׃"
          },
          {
            "num": "יח",
            "txt": "צ֥וּר יְלָֽדְךָ֖ תֶּ֑שִׁ{ר}וַתִּשְׁכַּ֖ח אֵ֥ל מְחֹֽלְלֶֽךָ׃"
          },
          {
            "num": "יט",
            "txt": "וַיַּ֥רְא יְהוָ֖ה וַיִּנְאָ֑ץ {ר}מִכַּ֥עַס בָּנָ֖יו וּבְנֹתָֽיו׃"
          },
          {
            "num": "כ",
            "txt": "וַיֹּ֗אמֶר אַסְתִּ֤ירָה פָנַי֙ מֵהֶ֔ם {ר}אֶרְאֶ֖ה מָ֣ה אַֽחֲרִיתָ֑ם {ס} כִּ֣י ד֤וֹר תַּהְפֻּכֹת֙ הֵ֔מָּה {ר}בָּנִ֖ים לֹֽא־אֵמֻ֥ן בָּֽם׃"
          },
          {
            "num": "כא",
            "txt": "הֵ֚ם קִנְא֣וּנִי בְלֹא־אֵ֔ל {ר}כִּֽעֲס֖וּנִי בְּהַבְלֵיהֶ֑ם {ס} וַֽאֲנִי֙ אַקְנִיאֵ֣ם בְּלֹא־עָ֔ם {ר}בְּג֥וֹי נָבָ֖ל אַכְעִיסֵֽם׃"
          },
          {
            "num": "כב",
            "txt": "כִּי־אֵשׁ֙ קָֽדְחָ֣ה בְאַפִּ֔י {ר}וַתִּיקַ֖ד עַד־שְׁא֣וֹל תַּחְתִּ֑ית {ס} וַתֹּ֤אכַל אֶ֨רֶץ֙ וִֽיבֻלָ֔הּ {ר}וַתְּלַהֵ֖ט מֽוֹסְדֵ֥י הָרִֽים׃"
          },
          {
            "num": "כג",
            "txt": "אַסְפֶּ֥ה עָלֵ֖ימוֹ רָע֑וֹת {ר}חִצַּ֖י אֲכַלֶּה־בָּֽם׃"
          },
          {
            "num": "כד",
            "txt": "מְזֵ֥י רָעָ֛ב וּלְחֻ֥מֵי רֶ֖שֶׁף {ר}וְקֶ֣טֶב מְרִירִ֑י {ס} וְשֶׁן־בְּהֵמֹת֙ אֲשַׁלַּח־בָּ֔ם {ר}עִם־חֲמַ֖ת זֹֽחֲלֵ֥י עָפָֽר׃"
          },
          {
            "num": "כה",
            "txt": "מִחוּץ֙ תְּשַׁכֶּל־חֶ֔רֶב וּמֵֽחֲדָרִ֖ים אֵימָ֑ה גַּם־בָּחוּר֙ {ר}גַּם־בְּתוּלָ֔ה יוֹנֵ֖ק עִם־אִ֥ישׁ שֵׂיבָֽה׃"
          },
          {
            "num": "כו",
            "txt": "אָמַ֖רְתִּי אַפְאֵיהֶ֑ם {ר}אַשְׁבִּ֥יתָה מֵֽאֱנ֖וֹשׁ זִכְרָֽם׃"
          },
          {
            "num": "כז",
            "txt": "לוּלֵ֗י כַּ֤עַס אוֹיֵב֙ אָג֔וּר {ר}פֶּֽן־יְנַכְּר֖וּ צָרֵ֑ימוֹ {ס} פֶּן־יֹֽאמְרוּ֙ יָדֵ֣נוּ רָ֔מָה {ר}וְלֹ֥א יְהוָ֖ה פָּעַ֥ל כָּל־זֹֽאת׃"
          },
          {
            "num": "כח",
            "txt": "כִּי־ג֛וֹי אֹבַ֥ד עֵצ֖וֹת הֵ֑מָּה {ר}וְאֵ֥ין בָּהֶ֖ם תְּבוּנָֽה׃"
          },
          {
            "num": "כט",
            "txt": "ל֥וּ חָֽכְמ֖וּ יַשְׂכִּ֣ילוּ זֹ֑את {ר}יָבִ֖ינוּ לְאַֽחֲרִיתָֽם׃"
          },
          {
            "num": "ל",
            "txt": "אֵיכָ֞ה יִרְדֹּ֤ף אֶחָד֙ אֶ֔לֶף {ר}וּשְׁנַ֖יִם יָנִ֣יסוּ רְבָבָ֑ה {ס} אִם־לֹא֙ כִּֽי־צוּרָ֣ם מְכָרָ֔ם {ר}וַֽיהוָ֖ה הִסְגִּירָֽם׃"
          },
          {
            "num": "לא",
            "txt": "כִּ֛י לֹ֥א כְצוּרֵ֖נוּ צוּרָ֑ם {ר}וְאֹֽיְבֵ֖ינוּ פְּלִילִֽים׃"
          },
          {
            "num": "לב",
            "txt": "כִּֽי־מִגֶּ֤פֶן סְדֹם֙ גַּפְנָ֔ם {ר}וּמִשַּׁדְמֹ֖ת עֲמֹרָ֑ה {ס} עֲנָבֵ֨מוֹ֙ עִנְּבֵי־ר֔וֹשׁ {ר}אַשְׁכְּלֹ֥ת מְרֹרֹ֖ת לָֽמוֹ׃"
          },
          {
            "num": "לג",
            "txt": "חֲמַ֥ת תַּנִּינִ֖ם יֵינָ֑ם {ר}וְרֹ֥אשׁ פְּתָנִ֖ים אַכְזָֽר׃"
          },
          {
            "num": "לד",
            "txt": "הֲלֹא־ה֖וּא כָּמֻ֣ס עִמָּדִ֑י {ר}חָת֖וּם בְּאֽוֹצְרֹתָֽי׃"
          },
          {
            "num": "לה",
            "txt": "לִ֤י נָקָם֙ וְשִׁלֵּ֔ם {ר}לְעֵ֖ת תָּמ֣וּט רַגְלָ֑ם {ס} כִּ֤י קָרוֹב֙ י֣וֹם אֵידָ֔ם {ר}וְחָ֖שׁ עֲתִדֹ֥ת לָֽמוֹ׃"
          },
          {
            "num": "לו",
            "txt": "כִּֽי־יָדִ֤ין יְהוָה֙ עַמּ֔וֹ {ר}וְעַל־עֲבָדָ֖יו יִתְנֶחָ֑ם {ס} כִּ֤י יִרְאֶה֙ כִּֽי־אָ֣זְלַת יָ֔ד {ר}וְאֶ֖פֶס עָצ֥וּר וְעָזֽוּב׃"
          },
          {
            "num": "לז",
            "txt": "וְאָמַ֖ר אֵ֣י אֱלֹהֵ֑ימוֹ {ר}צ֖וּר חָסָ֥יוּ בֽוֹ׃"
          },
          {
            "num": "לח",
            "txt": "אֲשֶׁ֨ר חֵ֤לֶב זְבָחֵ֨ימוֹ֙ יֹאכֵ֔לוּ {ר}יִשְׁתּ֖וּ יֵ֣ין נְסִיכָ֑ם {ס} יָק֨וּמוּ֙ וְיַעְזְרֻכֶ֔ם {ר}יְהִ֥י עֲלֵיכֶ֖ם סִתְרָֽה׃"
          },
          {
            "num": "לט",
            "txt": "רְא֣וּ ׀ עַתָּ֗ה כִּ֣י אֲנִ֤י אֲנִי֙ ה֔וּא {ר}וְאֵ֥ין אֱלֹהִ֖ים עִמָּדִ֑י {ס} אֲנִ֧י אָמִ֣ית וַֽאֲחַיֶּ֗ה {ר}מָחַ֨צְתִּי֙ וַֽאֲנִ֣י אֶרְפָּ֔א {ס} וְאֵ֥ין מִיָּדִ֖י מַצִּֽיל׃"
          },
          {
            "num": "מ",
            "txt": "כִּֽי־אֶשָּׂ֥א אֶל־שָׁמַ֖יִם יָדִ֑י {ס} וְאָמַ֕רְתִּי חַ֥י אָֽנֹכִ֖י לְעֹלָֽם׃"
          },
          {
            "num": "מא",
            "txt": "אִם־שַׁנּוֹתִי֙ בְּרַ֣ק חַרְבִּ֔י {ס} וְתֹאחֵ֥ז בְּמִשְׁפָּ֖ט יָדִ֑י {ר}אָשִׁ֤יב נָקָם֙ לְצָרָ֔י {ס} וְלִמְשַׂנְאַ֖י אֲשַׁלֵּֽם׃"
          },
          {
            "num": "מב",
            "txt": "אַשְׁכִּ֤יר חִצַּי֙ מִדָּ֔ם {ס} וְחַרְבִּ֖י תֹּאכַ֣ל בָּשָׂ֑ר {ר}מִדַּ֤ם חָלָל֙ וְשִׁבְיָ֔ה {ס} מֵרֹ֖אשׁ פַּרְע֥וֹת אוֹיֵֽב׃"
          },
          {
            "num": "מג",
            "txt": "הַרְנִ֤ינוּ גוֹיִם֙ עַמּ֔וֹ {ס} כִּ֥י דַם־עֲבָדָ֖יו יִקּ֑וֹם {ר}וְנָקָם֙ יָשִׁ֣יב לְצָרָ֔יו {ס} וְכִפֶּ֥ר אַדְמָת֖וֹ עַמּֽוֹ׃"
          },
          {
            "num": "מד",
            "txt": "וַיָּבֹ֣א מֹשֶׁ֗ה וַיְדַבֵּ֛ר אֶת־כָּל־דִּבְרֵ֥י הַשִּׁירָֽה־הַזֹּ֖את בְּאָזְנֵ֣י הָעָ֑ם ה֖וּא וְהוֹשֵׁ֥עַ בִּן־נֽוּן׃"
          },
          {
            "num": "מה",
            "txt": "וַיְכַ֣ל מֹשֶׁ֗ה לְדַבֵּ֛ר אֶת־כָּל־הַדְּבָרִ֥ים הָאֵ֖לֶּה אֶל־כָּל־יִשְׂרָאֵֽל׃"
          },
          {
            "num": "מו",
            "txt": "וַיֹּ֤אמֶר אֲלֵהֶם֙ שִׂ֣ימוּ לְבַבְכֶ֔ם לְכָ֨ל־הַדְּבָרִ֔ים אֲשֶׁ֧ר אָֽנֹכִ֛י מֵעִ֥יד בָּכֶ֖ם הַיּ֑וֹם אֲשֶׁ֤ר תְּצַוֻּם֙ אֶת־בְּנֵיכֶ֔ם לִשְׁמֹ֣ר לַֽעֲשׂ֔וֹת אֶת־כָּל־דִּבְרֵ֖י הַתּוֹרָ֥ה הַזֹּֽאת׃"
          },
          {
            "num": "מז",
            "txt": "כִּ֠י לֹֽא־דָבָ֨ר רֵ֥ק הוּא֙ מִכֶּ֔ם כִּי־ה֖וּא חַיֵּיכֶ֑ם וּבַדָּבָ֣ר הַזֶּ֗ה תַּֽאֲרִ֤יכוּ יָמִים֙ עַל־הָ֣אֲדָמָ֔ה אֲשֶׁ֨ר אַתֶּ֜ם עֹֽבְרִ֧ים אֶת־הַיַּרְדֵּ֛ן שָׁ֖מָּה לְרִשְׁתָּֽהּ׃"
          },
          {
            "num": "מח",
            "txt": "וַיְדַבֵּ֤ר יְהוָה֙ אֶל־מֹשֶׁ֔ה בְּעֶ֛צֶם הַיּ֥וֹם הַזֶּ֖ה לֵאמֹֽר׃"
          },
          {
            "num": "מט",
            "txt": "עֲלֵ֡ה אֶל־הַר֩ הָֽעֲבָרִ֨ים הַזֶּ֜ה הַר־נְב֗וֹ אֲשֶׁר֙ בְּאֶ֣רֶץ מוֹאָ֔ב אֲשֶׁ֖ר עַל־פְּנֵ֣י יְרֵח֑וֹ וּרְאֵה֙ אֶת־אֶ֣רֶץ כְּנַ֔עַן אֲשֶׁ֨ר אֲנִ֥י נֹתֵ֛ן לִבְנֵ֥י יִשְׂרָאֵ֖ל לַֽאֲחֻזָּֽה׃"
          },
          {
            "num": "נ",
            "txt": "וּמֻ֗ת בָּהָר֙ אֲשֶׁ֤ר אַתָּה֙ עֹלֶ֣ה שָׁ֔מָּה וְהֵֽאָסֵ֖ף אֶל־עַמֶּ֑יךָ כַּֽאֲשֶׁר־מֵ֞ת אַֽהֲרֹ֤ן אָחִ֨יךָ֙ בְּהֹ֣ר הָהָ֔ר וַיֵּאָ֖סֶף אֶל־עַמָּֽיו׃"
          },
          {
            "num": "נא",
            "txt": "עַל֩ אֲשֶׁ֨ר מְעַלְתֶּ֜ם בִּ֗י בְּתוֹךְ֙ בְּנֵ֣י יִשְׂרָאֵ֔ל בְּמֵֽי־מְרִיבַ֥ת קָדֵ֖שׁ מִדְבַּר־צִ֑ן עַ֣ל אֲשֶׁ֤ר לֹֽא־קִדַּשְׁתֶּם֙ אוֹתִ֔י בְּת֖וֹךְ בְּנֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "נב",
            "txt": "כִּ֥י מִנֶּ֖גֶד תִּרְאֶ֣ה אֶת־הָאָ֑רֶץ וְשָׁ֨מָּה֙ לֹ֣א תָב֔וֹא אֶל־הָאָ֕רֶץ אֲשֶׁר־אֲנִ֥י נֹתֵ֖ן לִבְנֵ֥י יִשְׂרָאֵֽל׃"
          }
        ]
      },
      {
        "num": "לג",
        "verses": [
          {
            "num": "א",
            "txt": "וְזֹ֣את הַבְּרָכָ֗ה אֲשֶׁ֨ר בֵּרַ֥ךְ מֹשֶׁ֛ה אִ֥ישׁ הָֽאֱלֹהִ֖ים אֶת־בְּנֵ֣י יִשְׂרָאֵ֑ל לִפְנֵ֖י מוֹתֽוֹ׃"
          },
          {
            "num": "ב",
            "txt": "וַיֹּאמַ֗ר יְהוָ֞ה מִסִּינַ֥י בָּא֙ וְזָרַ֤ח מִשֵּׂעִיר֙ לָ֔מוֹ הוֹפִ֨יעַ֙ מֵהַ֣ר פָּארָ֔ן וְאָתָ֖ה מֵֽרִבְבֹ֣ת קֹ֑דֶשׁ מִֽימִינ֕וֹ אשדת (אֵ֥שׁ דָּ֖ת) לָֽמוֹ׃"
          },
          {
            "num": "ג",
            "txt": "אַ֚ף חֹבֵ֣ב עַמִּ֔ים כָּל־קְדֹשָׁ֖יו בְּיָדֶ֑ךָ וְהֵם֙ תֻּכּ֣וּ לְרַגְלֶ֔ךָ יִשָּׂ֖א מִדַּבְּרֹתֶֽיךָ׃"
          },
          {
            "num": "ד",
            "txt": "תּוֹרָ֥ה צִוָּה־לָ֖נוּ מֹשֶׁ֑ה מֽוֹרָשָׁ֖ה קְהִלַּ֥ת יַֽעֲקֹֽב׃"
          },
          {
            "num": "ה",
            "txt": "וַיְהִ֥י בִֽישֻׁר֖וּן מֶ֑לֶךְ בְּהִתְאַסֵּף֙ רָ֣אשֵׁי עָ֔ם יַ֖חַד שִׁבְטֵ֥י יִשְׂרָאֵֽל׃"
          },
          {
            "num": "ו",
            "txt": "יְחִ֥י רְאוּבֵ֖ן וְאַל־יָמֹ֑ת וִיהִ֥י מְתָ֖יו מִסְפָּֽר׃ {ס}"
          },
          {
            "num": "ז",
            "txt": "וְזֹ֣את לִֽיהוּדָה֮ וַיֹּאמַר֒ שְׁמַ֤ע יְהוָה֙ ק֣וֹל יְהוּדָ֔ה וְאֶל־עַמּ֖וֹ תְּבִיאֶ֑נּוּ יָדָיו֙ רָ֣ב ל֔וֹ וְעֵ֥זֶר מִצָּרָ֖יו תִּֽהְיֶֽה׃ {פ}"
          },
          {
            "num": "ח",
            "txt": "וּלְלֵוִ֣י אָמַ֔ר תֻּמֶּ֥יךָ וְאוּרֶ֖יךָ לְאִ֣ישׁ חֲסִידֶ֑ךָ אֲשֶׁ֤ר נִסִּיתוֹ֙ בְּמַסָּ֔ה תְּרִיבֵ֖הוּ עַל־מֵ֥י מְרִיבָֽה׃"
          },
          {
            "num": "ט",
            "txt": "הָֽאֹמֵ֞ר לְאָבִ֤יו וּלְאִמּוֹ֙ לֹ֣א רְאִיתִ֔יו וְאֶת־אֶחָיו֙ לֹ֣א הִכִּ֔יר וְאֶת־בָּנָ֖ו לֹ֣א יָדָ֑ע כִּ֤י שָֽׁמְרוּ֙ אִמְרָתֶ֔ךָ וּבְרִֽיתְךָ֖ יִנְצֹֽרוּ׃"
          },
          {
            "num": "י",
            "txt": "יוֹר֤וּ מִשְׁפָּטֶ֨יךָ֙ לְיַֽעֲקֹ֔ב וְתוֹרָֽתְךָ֖ לְיִשְׂרָאֵ֑ל יָשִׂ֤ימוּ קְטוֹרָה֙ בְּאַפֶּ֔ךָ וְכָלִ֖יל עַֽל־מִזְבְּחֶֽךָ׃"
          },
          {
            "num": "יא",
            "txt": "בָּרֵ֤ךְ יְהוָה֙ חֵיל֔וֹ וּפֹ֥עַל יָדָ֖יו תִּרְצֶ֑ה מְחַ֨ץ מָתְנַ֧יִם קָמָ֛יו וּמְשַׂנְאָ֖יו מִן־יְקוּמֽוּן׃ {ס}"
          },
          {
            "num": "יב",
            "txt": "לְבִנְיָמִ֣ן אָמַ֔ר יְדִ֣יד יְהוָ֔ה יִשְׁכֹּ֥ן לָבֶ֖טַח עָלָ֑יו חֹפֵ֤ף עָלָיו֙ כָּל־הַיּ֔וֹם וּבֵ֥ין כְּתֵפָ֖יו שָׁכֵֽן׃ {ס}"
          },
          {
            "num": "יג",
            "txt": "וּלְיוֹסֵ֣ף אָמַ֔ר מְבֹרֶ֥כֶת יְהוָ֖ה אַרְצ֑וֹ מִמֶּ֤גֶד שָׁמַ֨יִם֙ מִטָּ֔ל וּמִתְּה֖וֹם רֹבֶ֥צֶת תָּֽחַת׃"
          },
          {
            "num": "יד",
            "txt": "וּמִמֶּ֖גֶד תְּבוּאֹ֣ת שָׁ֑מֶשׁ וּמִמֶּ֖גֶד גֶּ֥רֶשׁ יְרָחִֽים׃"
          },
          {
            "num": "טו",
            "txt": "וּמֵרֹ֖אשׁ הַרְרֵי־קֶ֑דֶם וּמִמֶּ֖גֶד גִּבְע֥וֹת עוֹלָֽם׃"
          },
          {
            "num": "טז",
            "txt": "וּמִמֶּ֗גֶד אֶ֚רֶץ וּמְלֹאָ֔הּ וּרְצ֥וֹן שֹֽׁכְנִ֖י סְנֶ֑ה תָּב֨וֹאתָה֙ לְרֹ֣אשׁ יוֹסֵ֔ף וּלְקָדְקֹ֖ד נְזִ֥יר אֶחָֽיו׃"
          },
          {
            "num": "יז",
            "txt": "בְּכ֨וֹר שׁוֹר֜וֹ הָדָ֣ר ל֗וֹ וְקַרְנֵ֤י רְאֵם֙ קַרְנָ֔יו בָּהֶ֗ם עַמִּ֛ים יְנַגַּ֥ח יַחְדָּ֖ו אַפְסֵי־אָ֑רֶץ וְהֵם֙ רִבְב֣וֹת אֶפְרַ֔יִם וְהֵ֖ם אַלְפֵ֥י מְנַשֶּֽׁה׃ {ס}"
          },
          {
            "num": "יח",
            "txt": "וְלִזְבוּלֻ֣ן אָמַ֔ר שְׂמַ֥ח זְבוּלֻ֖ן בְּצֵאתֶ֑ךָ וְיִשָּׂשכָ֖ר בְּאֹֽהָלֶֽיךָ׃"
          },
          {
            "num": "יט",
            "txt": "עַמִּים֙ הַר־יִקְרָ֔אוּ שָׁ֖ם יִזְבְּח֣וּ זִבְחֵי־צֶ֑דֶק כִּ֣י שֶׁ֤פַע יַמִּים֙ יִינָ֔קוּ וּשְׂפֻנֵ֖י טְמ֥וּנֵי חֽוֹל׃ {ס}"
          },
          {
            "num": "כ",
            "txt": "וּלְגָ֣ד אָמַ֔ר בָּר֖וּךְ מַרְחִ֣יב גָּ֑ד כְּלָבִ֣יא שָׁכֵ֔ן וְטָרַ֥ף זְר֖וֹעַ אַף־קָדְקֹֽד׃"
          },
          {
            "num": "כא",
            "txt": "וַיַּ֤רְא רֵאשִׁית֙ ל֔וֹ כִּי־שָׁ֛ם חֶלְקַ֥ת מְחֹקֵ֖ק סָפ֑וּן וַיֵּתֵא֙ רָ֣אשֵׁי עָ֔ם צִדְקַ֤ת יְהוָה֙ עָשָׂ֔ה וּמִשְׁפָּטָ֖יו עִם־יִשְׂרָאֵֽל׃ {ס}"
          },
          {
            "num": "כב",
            "txt": "וּלְדָ֣ן אָמַ֔ר דָּ֖ן גּ֣וּר אַרְיֵ֑ה יְזַנֵּ֖ק מִן־הַבָּשָֽׁן׃"
          },
          {
            "num": "כג",
            "txt": "וּלְנַפְתָּלִ֣י אָמַ֔ר נַפְתָּלִי֙ שְׂבַ֣ע רָצ֔וֹן וּמָלֵ֖א בִּרְכַּ֣ת יְהוָ֑ה יָ֥ם וְדָר֖וֹם יְרָֽשָׁה׃ {ס}"
          },
          {
            "num": "כד",
            "txt": "וּלְאָשֵׁ֣ר אָמַ֔ר בָּר֥וּךְ מִבָּנִ֖ים אָשֵׁ֑ר יְהִ֤י רְצוּי֙ אֶחָ֔יו וְטֹבֵ֥ל בַּשֶּׁ֖מֶן רַגְלֽוֹ׃"
          },
          {
            "num": "כה",
            "txt": "בַּרְזֶ֥ל וּנְחֹ֖שֶׁת מִנְעָלֶ֑ךָ וּכְיָמֶ֖יךָ דָּבְאֶֽךָ׃"
          },
          {
            "num": "כו",
            "txt": "אֵ֥ין כָּאֵ֖ל יְשֻׁר֑וּן רֹכֵ֤ב שָׁמַ֨יִם֙ בְּעֶזְרֶ֔ךָ וּבְגַֽאֲוָת֖וֹ שְׁחָקִֽים׃"
          },
          {
            "num": "כז",
            "txt": "מְעֹנָה֙ אֱלֹ֣הֵי קֶ֔דֶם וּמִתַּ֖חַת זְרֹעֹ֣ת עוֹלָ֑ם וַיְגָ֧רֶשׁ מִפָּנֶ֛יךָ אוֹיֵ֖ב וַיֹּ֥אמֶר הַשְׁמֵֽד׃"
          },
          {
            "num": "כח",
            "txt": "וַיִּשְׁכֹּן֩ יִשְׂרָאֵ֨ל בֶּ֤טַח בָּדָד֙ עֵ֣ין יַֽעֲקֹ֔ב אֶל־אֶ֖רֶץ דָּגָ֣ן וְתִיר֑וֹשׁ אַף־שָׁמָ֖יו יַ֥עַרְפוּ טָֽל׃"
          },
          {
            "num": "כט",
            "txt": "אַשְׁרֶ֨יךָ יִשְׂרָאֵ֜ל מִ֣י כָמ֗וֹךָ עַ֚ם נוֹשַׁ֣ע בַּֽיהוָ֔ה מָגֵ֣ן עֶזְרֶ֔ךָ וַֽאֲשֶׁר־חֶ֖רֶב גַּֽאֲוָתֶ֑ךָ וְיִכָּֽחֲשׁ֤וּ אֹֽיְבֶ֨יךָ֙ לָ֔ךְ וְאַתָּ֖ה עַל־בָּֽמוֹתֵ֥ימוֹ תִדְרֹֽךְ׃ {ס}"
          }
        ]
      },
      {
        "num": "לד",
        "verses": [
          {
            "num": "א",
            "txt": "וַיַּ֨עַל מֹשֶׁ֜ה מֵֽעַרְבֹ֤ת מוֹאָב֙ אֶל־הַ֣ר נְב֔וֹ רֹ֚אשׁ הַפִּסְגָּ֔ה אֲשֶׁ֖ר עַל־פְּנֵ֣י יְרֵח֑וֹ וַיַּרְאֵ֨הוּ יְהוָ֧ה אֶת־כָּל־הָאָ֛רֶץ אֶת־הַגִּלְעָ֖ד עַד־דָּֽן׃"
          },
          {
            "num": "ב",
            "txt": "וְאֵת֙ כָּל־נַפְתָּלִ֔י וְאֶת־אֶ֥רֶץ אֶפְרַ֖יִם וּמְנַשֶּׁ֑ה וְאֵת֙ כָּל־אֶ֣רֶץ יְהוּדָ֔ה עַ֖ד הַיָּ֥ם הָאַֽחֲרֽוֹן׃"
          },
          {
            "num": "ג",
            "txt": "וְאֶת־הַנֶּ֗גֶב וְֽאֶת־הַכִּכָּ֞ר בִּקְעַ֧ת יְרֵח֛וֹ עִ֥יר הַתְּמָרִ֖ים עַד־צֹֽעַר׃"
          },
          {
            "num": "ד",
            "txt": "וַיֹּ֨אמֶר יְהוָ֜ה אֵלָ֗יו זֹ֤את הָאָ֨רֶץ֙ אֲשֶׁ֣ר נִ֠שְׁבַּעְתִּי לְאַבְרָהָ֨ם לְיִצְחָ֤ק וּֽלְיַעֲקֹב֙ לֵאמֹ֔ר לְזַרְעֲךָ֖ אֶתְּנֶ֑נָּה הֶרְאִיתִ֣יךָ בְעֵינֶ֔יךָ וְשָׁ֖מָּה לֹ֥א תַֽעֲבֹֽר׃"
          },
          {
            "num": "ה",
            "txt": "וַיָּ֨מָת שָׁ֜ם מֹשֶׁ֧ה עֶֽבֶד־יְהוָ֛ה בְּאֶ֥רֶץ מוֹאָ֖ב עַל־פִּ֥י יְהוָֽה׃"
          },
          {
            "num": "ו",
            "txt": "וַיִּקְבֹּ֨ר אֹת֤וֹ בַגַּי֙ בְּאֶ֣רֶץ מוֹאָ֔ב מ֖וּל בֵּ֣ית פְּע֑וֹר וְלֹֽא־יָדַ֥ע אִישׁ֙ אֶת־קְבֻ֣רָת֔וֹ עַ֖ד הַיּ֥וֹם הַזֶּֽה׃"
          },
          {
            "num": "ז",
            "txt": "וּמֹשֶׁ֗ה בֶּן־מֵאָ֧ה וְעֶשְׂרִ֛ים שָׁנָ֖ה בְּמֹת֑וֹ לֹֽא־כָהֲתָ֥ה עֵינ֖וֹ וְלֹא־נָ֥ס לֵחֹֽה׃"
          },
          {
            "num": "ח",
            "txt": "וַיִּבְכּוּ֩ בְנֵ֨י יִשְׂרָאֵ֧ל אֶת־מֹשֶׁ֛ה בְּעַֽרְבֹ֥ת מוֹאָ֖ב שְׁלֹשִׁ֣ים י֑וֹם וַֽיִּתְּמ֔וּ יְמֵ֥י בְכִ֖י אֵ֥בֶל מֹשֶֽׁה׃"
          },
          {
            "num": "ט",
            "txt": "וִֽיהוֹשֻׁ֣עַ בִּן־נ֗וּן מָלֵא֙ ר֣וּחַ חָכְמָ֔ה כִּֽי־סָמַ֥ךְ מֹשֶׁ֛ה אֶת־יָדָ֖יו עָלָ֑יו וַיִּשְׁמְע֨וּ אֵלָ֤יו בְּנֵֽי־יִשְׂרָאֵל֙ וַֽיַּעֲשׂ֔וּ כַּֽאֲשֶׁ֛ר צִוָּ֥ה יְהוָ֖ה אֶת־מֹשֶֽׁה׃"
          },
          {
            "num": "י",
            "txt": "וְלֹא־קָ֨ם נָבִ֥יא ע֛וֹד בְּיִשְׂרָאֵ֖ל כְּמֹשֶׁ֑ה אֲשֶׁר֙ יְדָע֣וֹ יְהוָ֔ה פָּנִ֖ים אֶל־פָּנִֽים׃"
          },
          {
            "num": "יא",
            "txt": "לְכָל־הָ֨אֹתֹ֜ת וְהַמּֽוֹפְתִ֗ים אֲשֶׁ֤ר שְׁלָחוֹ֙ יְהוָ֔ה לַֽעֲשׂ֖וֹת בְּאֶ֣רֶץ מִצְרָ֑יִם לְפַרְעֹ֥ה וּלְכָל־עֲבָדָ֖יו וּלְכָל־אַרְצֽוֹ׃"
          },
          {
            "num": "יב",
            "txt": "וּלְכֹל֙ הַיָּ֣ד הַֽחֲזָקָ֔ה וּלְכֹ֖ל הַמּוֹרָ֣א הַגָּד֑וֹל אֲשֶׁר֙ עָשָׂ֣ה מֹשֶׁ֔ה לְעֵינֵ֖י כָּל־יִשְׂרָאֵֽל׃ {ש}"
          }
        ]
      }
    ]
  }
}