export default function Footer() {
    const makeLink = (book) => `/?book=${book}&chapter=א`
    return (
        <footer className="footer">
            <a className="link" href={makeLink('בראשית')}>בראשית</a>
            <a className="link" href={makeLink('שמות')}>שמות</a>
            <a className="link" href={makeLink('ויקרא')}>ויקרא</a>
            <a className="link" href={makeLink('במדבר')}>במדבר</a>
            <a className="link" href={makeLink('דברים')}>דברים</a>
        </footer>
    )
}
